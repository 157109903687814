import {apiDelete, apiGet, apiPost, apiPut} from '../core/api';
import {ApiAnswer, IListSearchProps} from '../types';
import {
  PriceTagDTO,
  PriceTagMapper,
  IPriceTagDTOProps,
  IPriceTagListDTO,
  PriceTagPrintFormDTO,
  IProductsListStatsProps,
} from '../../struture';
import {contentApiUrl} from '../const';

export interface IPriceTagAnswer extends ApiAnswer {
  item: IPriceTagDTOProps;
}

export interface IPriceTagCreateAnswer extends ApiAnswer {
  items: IPriceTagDTOProps[];
}

export interface IPriceTagListAnswer extends ApiAnswer {
  items: IPriceTagDTOProps[];
  stats: IProductsListStatsProps;
}

export interface IPriceTagListAnswer extends ApiAnswer, IListSearchProps {
  items: IPriceTagDTOProps[];
}

export interface IPriceTagListProps extends IListSearchProps {
  companyUuid: string;
}

export interface IPrintPriceTagListAnswer extends ApiAnswer {
  html: string;
}

export const PRICE_TAG_SIZE = [{title: '60х40', uuid: '60х40'}];

export async function getPriceTagList({
  offset = 0,
  limit = 10,
  keywords,
  companyUuid,
}: IPriceTagListProps): Promise<IPriceTagListDTO> {
  const {items, total, stats} = await apiGet<
    IListSearchProps,
    IPriceTagListAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/products/price-tags`, {
    offset,
    limit,
    keywords,
  });

  return PriceTagMapper.toPriceTagListDTO(items, total || 0, [], stats);
}

export async function createPriceTagList(
  product_uuids: string[],
  companyUuid: string,
): Promise<IPriceTagListDTO> {
  const {items, total} = await apiPost<
    {product_uuids: string[]},
    IPriceTagCreateAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/products/price-tags`, {
    product_uuids,
  });

  return PriceTagMapper.toPriceTagListDTO(items, total);
}

export async function editPriceTag(
  product_uuid: string,
  item_uuid: string,
): Promise<PriceTagDTO> {
  const {item} = await apiPut<{product_uuid: string}, IPriceTagAnswer>(
    `${contentApiUrl}/products/price-tags/${item_uuid}`,
    {
      product_uuid,
    },
  );

  return PriceTagMapper.toPriceTagDTO(item);
}

export async function deletePriceTag(
  item_uuid: string,
): Promise<IPriceTagListDTO> {
  const {items} = await apiDelete<{ids: string[]}, IPriceTagListAnswer>(
    `${contentApiUrl}/products/price-tags/${item_uuid}`,
  );

  return PriceTagMapper.toPriceTagListDTO(items, 0);
}

export async function clearPriceTagList(companyUuid: string): Promise<void> {
  await apiDelete<null, IPriceTagAnswer>(
    `${contentApiUrl}/companies/${companyUuid}/products/price-tags`,
  );
}

export async function printPriceTagList(
  {price_margin_uuid, tag_size}: PriceTagPrintFormDTO,
  companyUuid: string,
): Promise<string> {
  const {html} = await apiGet<null, IPrintPriceTagListAnswer>(
    `${contentApiUrl}/companies/${companyUuid}/products/price-tags/print?tag_size=${tag_size}&price_margin_uuid=${price_margin_uuid}`,
  );

  return html;
}

export async function printPriceTag(
  {price_margin_uuid, tag_size}: PriceTagPrintFormDTO,
  productUuid: string,
): Promise<string> {
  const {html} = await apiGet<null, IPrintPriceTagListAnswer>(
    `${contentApiUrl}/products/${productUuid}/price-tags/print?tag_size=${tag_size}&price_margin_uuid=${price_margin_uuid}`,
  );

  return html;
}
