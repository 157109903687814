import * as React from 'react';
import {List} from 'immutable';
import {
  useGroupPaymentList,
  IUseGroupPaymentListProps,
  IUseGroupPaymentListReturnType,
} from './useGroupPaymentList';
import {
  GroupPaymentModel,
  GroupPaymentFormDTO,
  GroupPaymentMapper,
} from '../struture';
import {useStateEntityList} from '../components/lib/libV2/hooks';
import {
  createGroupPayment,
  updateGroupPayment,
  deleteGroupPayment,
} from '../services/api/groupPayment';
import {head} from '../services/helpers';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../contex';

export interface IUseStateGroupPaymentListProps
  extends IUseGroupPaymentListProps {}

export interface IGroupPaymentProps {
  keywords: string;
  showLoading?: boolean;
  limit?: number;
}

export interface IUseStateGroupPaymentListReturnType
  extends Omit<IUseGroupPaymentListReturnType, 'entityList'> {
  groupPayments: List<GroupPaymentModel> | null;
  handleCreateGroupPayment: (value: GroupPaymentFormDTO) => Promise<void>;
  handleUpdateGroupPayment: (value: GroupPaymentFormDTO) => Promise<void>;
  handleDeleteGroupPayments: (ids: string[], force?: boolean) => Promise<void>;
  handleSearchGroupPayments: (value: IGroupPaymentProps) => Promise<void>;
}

export function useStateGroupPaymentList(
  {
    companyUuid,
    ...rest
  }: IUseStateGroupPaymentListProps = {} as IUseStateGroupPaymentListProps,
): IUseStateGroupPaymentListReturnType {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: clientLoading,
    total,
    ...clientsParams
  } = useGroupPaymentList({
    companyUuid,
    ...rest,
  });

  const {
    entityList: groupPayments,
    loading,
    handleCreate,
    handleDelete,
    handleUpdate,
    setEntityList,
  } = useStateEntityList<GroupPaymentModel>({
    entityList,
    refresh,
    limit,
    offset,
    total,
  });

  const handleCreateGroupPayment = React.useCallback(
    async (value: GroupPaymentFormDTO) => {
      const service = await createGroupPayment(value, companyUuid);
      const serviceModel = GroupPaymentMapper.toGroupPaymentModel(service);

      handleCreate(serviceModel);

      alert('success', t('Group payment'), t('Group payment created success'));
    },
    [companyUuid, handleCreate, alert, t],
  );

  const handleUpdateGroupPayment = React.useCallback(
    async (value: GroupPaymentFormDTO) => {
      const service = await updateGroupPayment(value);
      const serviceModel = GroupPaymentMapper.toGroupPaymentModel(service);

      handleUpdate(serviceModel);

      alert('success', t('Group payment'), t('Group payment edited success'));
    },
    [alert, handleUpdate, t],
  );

  const handleDeleteGroupPayments = React.useCallback(
    async (ids: string[], force?: boolean) => {
      try {
        await deleteGroupPayment(head(ids), force);

        handleDelete(ids);
        alert('success', t('Group payment'), t('Group payment delete success'));
      } catch (error: any) {
        alert(
          'error',
          t('Group payment'),
          `${t('An error occurred during delete group payments')}: ${
            error?.message
          }`,
        );
      }
    },
    [alert, handleDelete, t],
  );

  const handleSearchGroupPayments = React.useCallback(
    async ({keywords, showLoading = true, limit = 10}: IGroupPaymentProps) => {
      const counterparty = await refresh({
        offset: 0,
        limit,
        keywords,
        showLoading,
      });

      if (counterparty) {
        setEntityList(counterparty);
      }
    },
    [refresh, setEntityList],
  );

  return {
    groupPayments,
    offset,
    limit,
    refresh,
    total,
    loading: clientLoading || loading,
    ...clientsParams,
    handleCreateGroupPayment,
    handleUpdateGroupPayment,
    handleDeleteGroupPayments,
    handleSearchGroupPayments,
  };
}
