import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {DefaultForm, IDefaultFormProps} from '../../../components/lib/General';
import {
  CompanyRequisiteDTO,
  InvoiceFormDTO,
  InvoiceModel,
} from '../../../struture';
import {useDropdownAlert} from '../../../contex';
import {useStateCompanyClientList, useStoredCompanies} from '../../../hooks';
import {Form} from 'antd';
import {StoreInvoiceFields} from './StoreInvoiceFields';
import {useTheme} from 'styled-components';
import {isFunction} from '../../../services/helpers';
import {ENTER_KEYS} from '../../../services/const';
import {useWatch} from 'antd/es/form/Form';
import {produce} from 'immer';
import {InvoiceRefObject} from '../helpers';

export interface InvoiceFormProps
  extends Omit<
    IDefaultFormProps<InvoiceFormDTO, InvoiceFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest' | 'onSuccess'
  > {
  loading?: boolean;
  invoice: InvoiceFormDTO;
  onSuccess: (value: InvoiceFormDTO) => Promise<InvoiceModel | void>;
  invoiceEditRef: React.MutableRefObject<InvoiceRefObject>;
}

export function StoreInvoiceForm({
  loading,
  invoice,
  editMode,
  onSuccess,
  onCancel,
  invoiceEditRef,
  ...rest
}: InvoiceFormProps): React.JSX.Element {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const {defaultCompanyUuid, defaultCompany} = useStoredCompanies();
  const theme: any = useTheme();
  const [instance] = Form.useForm();

  const inv_multi_currencies = useWatch('inv_multi_currencies', instance);

  const additionalValues = useMemo(
    () => ({
      uuid: invoice?.uuid,
    }),
    [invoice?.uuid],
  );

  const {
    clients,
    loading: clientsLoading,
    handleSearchClients,
    handleCreateClient,
    handleCreateClientLegal,
  } = useStateCompanyClientList({
    loadOnInit: true,
    companyUuid: defaultCompanyUuid,
    limit: 100,
    client_type: null as any,
  });

  const notifyError = useCallback(
    (ApiError: any) => {
      alert(
        'error',
        t('Bills'),
        `${
          editMode
            ? t('An error occurred during edit accounts')
            : t('An error occurred during create accounts')
        } : ${ApiError?.message}`,
      );
    },
    [alert, editMode, t],
  );

  const handleOnSuccess = useCallback(
    async (value: InvoiceFormDTO) => {
      if (isFunction(onSuccess)) {
        const updatedValue = produce(value, (draft) => {
          draft.inv_multi_currencies = inv_multi_currencies;

          if (draft?.inv_company_requisites) {
            const requisite = defaultCompany?.requisite_list?.find(
              ({uuid}) => uuid === draft.inv_company_requisites,
            );

            if (requisite) {
              const updateRequisite = produce(requisite, (draft) => {
                delete draft.uuid;
              });

              draft.inv_company_requisites =
                updateRequisite as unknown as CompanyRequisiteDTO;
            }
          }
        });

        await onSuccess(updatedValue);
      }
    },
    [defaultCompany?.requisite_list, inv_multi_currencies, onSuccess],
  );

  return (
    <DefaultForm<InvoiceFormDTO, InvoiceFormDTO>
      formKeyboardCodes={[...ENTER_KEYS]}
      withContext
      isResetLoading
      onCancel={onCancel}
      instance={instance}
      initialValues={invoice}
      onSuccess={handleOnSuccess}
      additionalValuesRequest={additionalValues}
      editMode={editMode}
      notifyError={notifyError}
      showNotify={false}
      showCancelButton={false}
      submitButtonText={t('Save as draft')}
      submitButtonProps={{style: {backgroundColor: theme.colors.primary}}}
      {...rest}>
      <StoreInvoiceFields
        editMode={!!editMode}
        loading={!!loading}
        clients={clients}
        clientsLoading={clientsLoading}
        handleCreateClient={handleCreateClient}
        handleCreateClientLegal={handleCreateClientLegal}
        handleSearchClients={handleSearchClients}
        inv_multi_currencies={inv_multi_currencies}
        requisites={defaultCompany?.requisite_list || []}
        invoiceEditRef={invoiceEditRef}
        initInvoice={invoice}
      />
    </DefaultForm>
  );
}
