import React from 'react';
import {ProductCategoryModel} from '../../../../struture';
import {Card} from 'antd';
import styled from 'styled-components';

const {Meta} = Card;

export interface StoreSaleCategoryCardProps {
  category: ProductCategoryModel;
  navigateToCategory: (categoryUuid: string) => void;
  loading?: boolean;
}

const StyledImg = styled.img`
  max-height: 160px;
  object-fit: contain;
`;

const StyledCard = styled(Card)`
  & .ant-card-cover {
    padding-top: 5px;
  }
`;

export function StoreSaleCategoryCard({
  category,
  navigateToCategory,
  loading,
  ...rest
}: StoreSaleCategoryCardProps): React.JSX.Element {
  return (
    <StyledCard
      onClick={() => navigateToCategory(category?.uuid)}
      hoverable
      loading={loading}
      cover={
        <StyledImg
          alt={category?.title}
          src={category?.logo_url || '/images-empty.png'}
          {...rest}
        />
      }>
      <Meta title={category?.title} />
    </StyledCard>
  );
}
