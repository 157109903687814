export enum IntegrationAssociationStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export interface IntegrationAssociationDTO<T> {
  uuid: string;
  attributes: T;
  status: IntegrationAssociationStatus;
  created_at: string;
  updated_at: string;
}
