import React from 'react';
import {useTranslation} from 'react-i18next';
import {
  isFunction,
  findIndex,
  FunctionArgs,
  eq,
} from '../../../services/helpers';
import {ChildrenMustBeAFunction} from '../../../components/lib/Errors';
import {IDefaultTabPanelProps, TabItem} from '../../../components/lib/General';
import {useNavigate} from 'react-router';
import {EmployeeModel} from '../../../struture';
import {Routes} from '../../../services/types';
import {IUseStateEmployeeReturnType} from '../../../hooks';
import {EmployeeOrderBonusList} from '../List';

export enum EMPLOYEE_TABS {
  SERVICE = 'service',
  DAY_OFFS = 'days_off',
  SCHEDULES = 'schedules',
  SALARY = 'Salary and bonuses',
}

export interface IEmployeeTabPanelProps {
  children: (
    value: Pick<IDefaultTabPanelProps, 'tabs'> & {calendarIndex: number},
  ) => React.ReactNode;
  employee: EmployeeModel | null;
  disabled?: boolean;
}

export default function EmployeeTabPanel({
  children,
  employee,
  disabled,
}: IEmployeeTabPanelProps): JSX.Element {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const employeeTabs = [
    {
      key: EMPLOYEE_TABS.SALARY,
      title: t(EMPLOYEE_TABS.SALARY),
      disabled: disabled,
      navigation: () => {
        navigate(Routes.rewards);
      },
      renderer: () => <></>,
    },
    {
      key: EMPLOYEE_TABS.SCHEDULES,
      title: t('Service delivery schedule'),
      disabled: disabled,
      navigation: () => {
        navigate(Routes.schedules);
      },
      renderer: () => <></>,
    },
    {
      key: EMPLOYEE_TABS.SERVICE,
      title: t('Services'),
      description: t('Services provided by the current Employee'),
      disabled: disabled,
      navigation: () => {
        navigate(Routes.employeeServices, {
          state: {employeeFullName: employee?.fullName},
        });
      },
      renderer: () => <></>,
    },
    {
      key: EMPLOYEE_TABS.DAY_OFFS,
      title: t('Day Offs'),
      disabled: disabled,
      navigation: () => {
        navigate(Routes.daysOff, {
          state: {employeeFullName: employee?.fullName},
        });
      },
      renderer: () => <></>,
    },
  ];

  if (!isFunction(children)) {
    throw new ChildrenMustBeAFunction('LoadingWithEmptyData');
  }

  const calendarIndex = findIndex<
    [FunctionArgs<TabItem, boolean>, TabItem[]],
    number
  >(({key}: TabItem) => eq(key, EMPLOYEE_TABS.SCHEDULES), employeeTabs);

  return <>{children({tabs: employeeTabs, calendarIndex})}</>;
}
