import {Moment} from 'moment';

export enum GENDER {
  MALE = 'male',
  FEMALE = 'female',
}

export type KeyOfObject<T> = {
  [P in keyof T]: T[P];
};

export type Curry1<A, R> = (a: A) => R;

export type Curry2<A, B, R> = {
  (a: A): Curry1<B, R>;
  (a: A, b: B): R;
};

export type Curry3<A, B, C, R> = {
  (a: A): Curry2<B, C, R>;
  (a: A, b: B): Curry1<C, R>;
  (a: A, b: B, c: C): R;
};

export type Curry4<A, B, C, D, R> = {
  (a: A): Curry3<B, C, D, R>;
  (a: A, b: B): Curry2<C, D, R>;
  (a: A, b: B, c: C): Curry1<D, R>;
  (a: A, b: B, c: C, d: D): R;
};

export type VariadicCurry<T, R> = T extends [any, any, any, any]
  ? Curry4<T[0], T[1], T[2], T[3], R>
  : T extends [any, any, any]
  ? Curry3<T[0], T[1], T[2], R>
  : T extends [any, any]
  ? Curry2<T[0], T[1], R>
  : T extends [any]
  ? Curry1<T[0], R>
  : unknown;

export interface ILiteralObj {
  [key: string]: any;
}

export type IsFunction<T> = T extends (...args: any[]) => any ? T : never;

export type isObjectOrArray<T> = T extends [] | {} ? T : never;

export type BasePropFormat = string | number;

export type BaseDate = string | Date;

export interface DateFormat {
  day: BasePropFormat;
  month: BasePropFormat;
  year: BasePropFormat;
  format: string;
}

export type FunctionArgs<T, U> = (item: T, index?: number, array?: T[]) => U;

export type Curried<A extends any[], R> = <
  P extends Partial<A>,
  R2 extends unknown,
>(
  ...args: P
) => P extends A
  ? R2 extends unknown
    ? R
    : R2
  : A extends [...SameLength<P>, ...infer S]
  ? S extends any[]
    ? Curried<S, R>
    : never
  : never;

export type SameLength<T extends any[]> = Extract<{[K in keyof T]: any}, any[]>;

export type DateValue = string | Moment | Date;

export interface GroupBy<T> {
  [key: string]: T[];
}

export enum OPERATION_TYPE {
  IN = 'IN',
  OUT = 'OUT',
}
