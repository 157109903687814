import React, {Suspense, useState, useCallback} from 'react';
import styled, {useTheme, css} from 'styled-components';
import {StyledDescription, StyledTitle} from '../../../components/lib/Styled';
import {Button, SuspenseEmpty} from '../../../components/lib/DataDisplay';
import {useTranslation} from 'react-i18next';
import {textToUpperCase} from '../../../services/helpers';
import {CashBoxCreateButton} from '../Buttons';
import {CashBoxListItem} from '../Show';
import {
  IUseStateCashBoxListReturnType,
  IUseStatePaymentListReturnType,
  useStoredCashBox,
  useStoredCompanies,
  useStoredProfile,
} from '../../../hooks';
import EmptyLayout from '../../../components/lib/Layout/EmptyLayout';
import {Empty, Dropdown, MenuProps} from 'antd';
import {LoadingOutlined, UpOutlined, DownOutlined} from '@ant-design/icons';
import {CashBoxMapper, CashBoxModel} from '../../../struture';
import {useAppLayout} from '../../../components/lib/Layout/AppLayout';
import {useModal} from '../../../components/lib/libV2/hooks';
import {SessionStorageItems} from '../../../services/const';

export interface ICashBoxListProps
  extends IUseStateCashBoxListReturnType,
    Pick<IUseStatePaymentListReturnType, 'handleCreatePayment'> {
  handledChangeCashBoxes: (cashBoxUuid: string | null) => Promise<void>;
}

const StyledContainer = styled.div<{$isSmallScreen: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  min-width: 320px;
  max-width: 320px;
  height: 100%;
  max-height: 800px;
  padding: 10px;

  overflow-y: scroll;

  background-color: ${({theme}) => theme.background.primary};
  border: 1px solid ${({theme}) => theme.colors.disabled};
  border-radius: 7px;

  box-shadow: rgba(0, 0, 0, 0.3) 0 0 3px 1px;

  ${({$isSmallScreen}) =>
    $isSmallScreen &&
    css`
      min-width: 220px;
      max-width: 220px;
    `}
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 45px;

  border-bottom-color: ${({theme}) => theme.colors.lightDisable};
  border-bottom-width: 1px;
  border-bottom-style: solid;
`;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  font-size: 50px;
`;

const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  width: fit-content;

  &&& {
    height: 32px;
  }
`;

const iconStyle = css`
  font-size: 15px;
  width: 20px;
`;

const StyledUpOutlined = styled(UpOutlined)`
  ${iconStyle};
`;

const StyledDownOutlined = styled(DownOutlined)`
  ${iconStyle};
`;

const StyledHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const CashBoxSideWindowLazy = React.lazy(
  () => import('../Show/CashBoxSideWindow'),
);

const CashBoxAdjustBalancesSideWindowLazy = React.lazy(
  () => import('../Show/CashBoxAdjustBalancesSideWindow'),
);

const CashBoxMovingSideWindowLazy = React.lazy(
  () => import('../Show/CashBoxMovingSideWindow'),
);

const StyledCashBoxCreateButton = styled(CashBoxCreateButton)`
  position: absolute;
  visibility: hidden;
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
`;

const StyledCreateButton = styled(Button)`
  width: fit-content;
`;

export function CashBoxList({
  cashBoxList,
  loading,
  handleDeleteCashBoxes,
  handleUpdateDefaultCashBox,
  defaultCashBox,
  handledChangeCashBoxes,

  handleCreatePayment,
}: ICashBoxListProps): React.JSX.Element {
  const {t} = useTranslation();
  const {defaultCompanyUuid, defaultCompany} = useStoredCompanies();
  const theme: any = useTheme();
  const {profile} = useStoredProfile();
  const {contentWidth} = useAppLayout();

  const [visible, setVisible] = useState(false);
  const [item, setItem] = useState<CashBoxModel | null>(null);

  const paymentsAllOperations = JSON.parse(
    sessionStorage.getItem(SessionStorageItems.paymentsAllOperations) ||
      'false',
  );

  const {
    handleCreateCashBox,
    handleUpdateCashBox,
    handleAdjustBalancesCashBox,
    handleMovingCashBox,
  } = useStoredCashBox({
    cashBoxUuid: '',
    companyUuid: defaultCompanyUuid,
    loadOnInit: false,
    handleUpdateDefaultCashBox,
    cashBoxList,
  });

  const title = cashBoxList?.first()?.box_title;

  const items: MenuProps['items'] = [];

  const isSmallScreen = contentWidth < 350;

  const {
    handleCancel,
    handleOnInit,
    handleSuccess,
    visible: cashBoxVisible,
  } = useModal({
    onSuccess: handleUpdateCashBox,
  });

  const {
    handleCancel: balanceHandleCancel,
    handleOnInit: balanceHandleOnInit,
    handleSuccess: balanceHandleSuccess,
    visible: balanceVisible,
  } = useModal({
    onSuccess: handleAdjustBalancesCashBox,
  });

  const {
    handleCancel: movingHandleCancel,
    handleOnInit: movingHandleOnInit,
    handleSuccess: movingHandleSuccess,
    visible: movingVisible,
  } = useModal({
    onSuccess: handleMovingCashBox,
  });

  const onInitUpdateCashBox = useCallback(
    (item: CashBoxModel) => {
      setItem(item);
      handleOnInit();
    },
    [handleOnInit],
  );

  const onInitAdjustBalancesCashBox = useCallback(
    (item: CashBoxModel) => {
      setItem(item);
      balanceHandleOnInit();
    },
    [balanceHandleOnInit],
  );

  const onInitMovingCashBox = useCallback(
    (item: CashBoxModel) => {
      setItem(item);
      movingHandleOnInit();
    },
    [movingHandleOnInit],
  );

  const handleFireEventCreateCashBoxButton = useCallback(() => {
    const createButton = document.body.querySelector(`.cash-box-create-button`);

    if (createButton instanceof HTMLElement) {
      const clickEvent = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
      });

      createButton?.dispatchEvent(clickEvent);
    }
  }, []);

  return (
    <>
      <Dropdown
        trigger={['click']}
        placement="bottomLeft"
        menu={{items}}
        open={visible}
        dropdownRender={() => (
          <StyledContainer $isSmallScreen={isSmallScreen}>
            {loading || !cashBoxList?.size ? (
              <EmptyLayout headerHeight={200}>
                {loading ? (
                  <StyledLoadingOutlined />
                ) : (
                  <Empty description={t('There are no cash boxes found.')}>
                    <StyledCreateButton
                      onClick={handleFireEventCreateCashBoxButton}
                      type="default"
                      size="small"
                      loading={loading && !cashBoxList?.size}>
                      <StyledTitle $color={theme.colors.white}>
                        {t('Create cash box')}
                      </StyledTitle>
                    </StyledCreateButton>
                  </Empty>
                )}
              </EmptyLayout>
            ) : (
              <>
                <StyledHeaderContainer>
                  {paymentsAllOperations ? (
                    <StyledDescription $color={theme.colors.success}>
                      {t('Movement of funds in all cash boxes')}
                    </StyledDescription>
                  ) : title ? (
                    <StyledDescription $color={theme.colors.success}>{`${t(
                      'Movement of funds by cash box',
                    )} "${title}"`}</StyledDescription>
                  ) : null}
                  <StyledTitle>{textToUpperCase(t('Cash boxes'))}</StyledTitle>
                  <StyledHeader>
                    <Button
                      type="link"
                      loading={loading}
                      disabled={paymentsAllOperations}
                      onClick={async () => {
                        await handledChangeCashBoxes(null);
                        setVisible(false);
                      }}>
                      {t('Select all cashboxes')}
                    </Button>
                    <StyledCreateButton
                      size="small"
                      type="primary"
                      loading={loading}
                      onClick={handleFireEventCreateCashBoxButton}>
                      <StyledTitle $color={theme.colors.white}>
                        {t('Create cash box')}
                      </StyledTitle>
                    </StyledCreateButton>
                  </StyledHeader>
                </StyledHeaderContainer>

                {cashBoxList?.map((value) => (
                  <CashBoxListItem
                    paymentsAllOperations={paymentsAllOperations}
                    item={value}
                    key={value?.uuid}
                    onInitUpdateCashBox={onInitUpdateCashBox}
                    handleDeleteCashBoxes={handleDeleteCashBoxes}
                    onInitAdjustBalancesCashBox={onInitAdjustBalancesCashBox}
                    onInitMovingCashBox={onInitMovingCashBox}
                    handleUpdateDefaultCashBox={async (value) => {
                      sessionStorage.removeItem(
                        SessionStorageItems.paymentsAllOperations,
                      );
                      await handleUpdateDefaultCashBox(value);
                      await handledChangeCashBoxes(value?.uuid);
                      setVisible(false);
                    }}
                    handleCreatePayment={handleCreatePayment}
                  />
                ))}
              </>
            )}
          </StyledContainer>
        )}>
        {loading || !defaultCashBox?.uuid ? (
          <div style={{visibility: 'hidden'}}>hidden</div>
        ) : (
          <StyledButton
            type="primary"
            size="small"
            onClick={() => setVisible((prevState) => !prevState)}>
            <StyledDescription $color={theme.colors.white} fontSize={15}>
              {paymentsAllOperations
                ? t('Movement of funds in all cash boxes')
                : `${t('Movement of funds by')}: "${
                    defaultCashBox?.box_title
                  }, ${defaultCompany?.currency_symbol} (${
                    defaultCashBox?.box_balance
                  })"`}
            </StyledDescription>

            {visible ? <StyledUpOutlined /> : <StyledDownOutlined />}
          </StyledButton>
        )}
      </Dropdown>

      <StyledCashBoxCreateButton
        className="cash-box-create-button"
        cashBox={CashBoxMapper.toCashBoxFormDTO({} as CashBoxModel, {
          cashier: profile?.defaultEmployeeModel,
        })}
        loading={loading}
        onSuccess={handleCreateCashBox}
        type="primary"
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <CashBoxSideWindowLazy
          editMode
          onSuccess={handleSuccess}
          visible={cashBoxVisible}
          onCancel={handleCancel}
          cashBox={CashBoxMapper.toCashBoxFormDTO(item!, {editMode: true})}
        />
      </Suspense>

      <Suspense fallback={<SuspenseEmpty />}>
        <CashBoxAdjustBalancesSideWindowLazy
          onSuccess={balanceHandleSuccess}
          visible={balanceVisible}
          onCancel={balanceHandleCancel}
          cashBox={CashBoxMapper.toCashBoxAdjustBalancesFormDTO(item!)}
        />
      </Suspense>

      <Suspense fallback={<SuspenseEmpty />}>
        <CashBoxMovingSideWindowLazy
          onSuccess={movingHandleSuccess}
          visible={movingVisible}
          onCancel={movingHandleCancel}
          cashBox={CashBoxMapper.toCashBoxMovingFormDTO(item!)}
          title={item!?.box_title}
        />
      </Suspense>
    </>
  );
}
