import {Map} from 'immutable';
import {PRICE_MARGIN, APP_STATE} from '../constants';
import {REDUX_STATUS, ApiError} from '../../services/types';
import {PriceMarginModel} from '../../struture';

interface IPriceMarginListState {
  priceMargin: PriceMarginModel | null;
  cachedPriceMarginList: Map<string, PriceMarginModel>;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
}

interface SetAction extends Pick<IPriceMarginListState, 'priceMargin'> {
  type: PRICE_MARGIN.SET_PRICE_MARGIN;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface UpdatePriceMargin {
  type: PRICE_MARGIN.UPDATE_PRICE_MARGIN;
  priceMargin: PriceMarginModel;
}

interface LoadingAction {
  type: PRICE_MARGIN.LOADING_PRICE_MARGIN;
}

interface ResetAction {
  type: PRICE_MARGIN.RESET_PRICE_MARGIN;
}

interface ErrorAction extends Pick<IPriceMarginListState, 'error'> {
  type: PRICE_MARGIN.ERROR_PRICE_MARGIN;
}

interface Handlers {
  [PRICE_MARGIN.SET_PRICE_MARGIN]: (
    state: IPriceMarginListState,
    action: SetAction,
  ) => IPriceMarginListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IPriceMarginListState,
    action: SetInitialStateAction,
  ) => IPriceMarginListState;

  [PRICE_MARGIN.RESET_PRICE_MARGIN]: (
    state: IPriceMarginListState,
    action: ResetAction,
  ) => IPriceMarginListState;

  [PRICE_MARGIN.UPDATE_PRICE_MARGIN]: (
    state: IPriceMarginListState,
    action: UpdatePriceMargin,
  ) => IPriceMarginListState;

  [PRICE_MARGIN.ERROR_PRICE_MARGIN]: (
    state: IPriceMarginListState,
    value: ErrorAction,
  ) => IPriceMarginListState;

  [PRICE_MARGIN.LOADING_PRICE_MARGIN]: (
    state: IPriceMarginListState,
    value?: LoadingAction,
  ) => IPriceMarginListState;
  DEFAULT: (state: IPriceMarginListState) => IPriceMarginListState;
}

const initState: IPriceMarginListState = {
  priceMargin: null,
  cachedPriceMarginList: Map(),
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
};

const handlers: Handlers = {
  [PRICE_MARGIN.SET_PRICE_MARGIN]: (state, {priceMargin}) => ({
    ...state,
    ...{
      priceMargin,
      cachedPriceMarginList: priceMargin
        ? state.cachedPriceMarginList.set(priceMargin?.uuid, priceMargin)
        : state.cachedPriceMarginList,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [PRICE_MARGIN.RESET_PRICE_MARGIN]: (state) => ({
    ...state,
    ...{
      status: REDUX_STATUS.IDLE,
    },
  }),

  [PRICE_MARGIN.UPDATE_PRICE_MARGIN]: (state, {priceMargin}) => ({
    ...state,
    ...{
      priceMargin,
      cachedPriceMarginList: state.cachedPriceMarginList.update(
        priceMargin?.uuid,
        () => priceMargin,
      ),
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [PRICE_MARGIN.ERROR_PRICE_MARGIN]: (
    state: IPriceMarginListState,
    {error}: ErrorAction,
  ) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [PRICE_MARGIN.LOADING_PRICE_MARGIN]: (state: IPriceMarginListState) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IPriceMarginListState) => state,
};

export default function PriceMargin(
  state: any = initState,
  action: any,
): IPriceMarginListState {
  const handler = handlers[action.type as PRICE_MARGIN] || handlers.DEFAULT;
  return handler(state, action);
}
