import React, {Suspense} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {ButtonProps} from 'antd/lib/button';
import {Button, SuspenseEmpty} from '../../../components/lib/DataDisplay';
import {useModal} from '../../../hooks';
import {PlanFormDTO} from '../../../struture';
import styled, {css} from 'styled-components';
import {isFunction} from '../../../services/helpers';

const SubscriptionSideWindowLazy = React.lazy(
  () => import('../Show/SubscriptionSideWindow'),
);

export default withTranslation()(SubscriptionButton);

export interface ISubscriptionButtonProps extends WithTranslation, ButtonProps {
  onSuccess: (value: PlanFormDTO) => Promise<void>;
  onCancel?: () => void;
  subscription: PlanFormDTO;
  title: string;
  $size?: number;
  autoWidth?: boolean;
}

const StyledActiveButton = styled(Button)<{
  $size?: number;
  $autoWidth?: boolean;
}>`
  width: 100%;

  &&& {
    height: 60px;

    ${({$size}) =>
      $size &&
      css`
        height: ${$size}px;
      `}
  }

  ${({$autoWidth}) =>
    $autoWidth &&
    css`
      width: auto;
    `}
`;

function SubscriptionButton({
  t,
  loading = false,
  onSuccess,
  subscription,
  onCancel,
  title,
  $size,
  onClick,
  disabled,
  autoWidth,
  ...rest
}: ISubscriptionButtonProps) {
  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <StyledActiveButton
        $autoWidth={autoWidth}
        type="primary"
        onClick={(e: any) => {
          if (isFunction(onClick)) {
            onClick(e);
          }
          handleOnInit();
        }}
        size="large"
        disabled={disabled}
        $size={$size}>
        {title}
      </StyledActiveButton>

      <Suspense fallback={<SuspenseEmpty />}>
        <SubscriptionSideWindowLazy
          subscription={subscription}
          loading={loading as boolean}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          visible={visible}
        />
      </Suspense>
    </>
  );
}
