import {Record, List} from 'immutable';
import {IListProps} from './List';
import {GalleryDTO, GalleryModel} from '../internal';
// import {WithoutRecursion} from '../type';

export interface IGalleryListModelProps extends IListProps {
  gallery: GalleryDTO[];
}

export interface IGalleryListModelReturnType extends IListProps {
  gallery: List<GalleryModel>;
}

export class GalleryListModel extends Record<IGalleryListModelReturnType>({
  gallery: List(),
  total: 0,
}) {
  constructor(
    {
      gallery = [],
      ...props
    }: IGalleryListModelProps = {} as IGalleryListModelProps,
  ) {
    const options: IGalleryListModelReturnType = {
      ...props,
      gallery: List(gallery.map((image) => new GalleryModel(image))),
    };

    super(options);
  }
}
