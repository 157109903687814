import React from 'react';
import {ExpenseDiscountType} from '../../../struture';
import {DetailsView} from '../../../components/lib/DataDisplay';
import {StyledDescription, StyledTitle} from '../../../components/lib/Styled';
import styled, {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useStoredCompanies} from '../../../hooks';
import {correctPrice} from '@sportix/sportix-common-modules';

export interface IExpenseFormTotalViewProps {
  className?: string;
  amount: number;
  price: number;
  discount_value: number;
  discount_type: ExpenseDiscountType;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  //padding-bottom: 10px;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
`;

const Title = styled(StyledTitle)<{$width?: number}>`
  min-width: 100px;
`;

const Description = styled(StyledDescription)`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  margin-right: 10px;
`;

const StyledDetailsView = styled(DetailsView)`
  box-shadow: none;
  border-color: #d9d9d9;
  text-align: right;
`;

export function ExpenseFormTotalView({
  amount,
  discount_value,
  discount_type,
  price,
  className,
}: IExpenseFormTotalViewProps): JSX.Element {
  const {t} = useTranslation();
  const theme: any = useTheme();
  const {defaultCompany} = useStoredCompanies();

  const priceNum = Number(price || 0);
  const total = amount * priceNum;

  const discountValue = Number(discount_value || 0);

  const percent = total * (discountValue / 100);

  const discount =
    discount_type === ExpenseDiscountType.Fixed
      ? total - discountValue
      : total - percent;

  return (
    <StyledDetailsView titles={[]} className={className}>
      <StyledContainer>
        <StyledTitleContainer>
          <Description fontSize={15} bold $color={theme.colors.link}>{`${t(
            'Total',
          )}, ${defaultCompany?.currency_symbol}:`}</Description>
          <Title fontSize={15} bold $color={theme.colors.link}>
            {correctPrice(total)}
          </Title>
        </StyledTitleContainer>

        <StyledTitleContainer>
          <Description fontSize={15} bold $color={theme.colors.success}>{`${t(
            'Discount',
          )}, ${
            discount_type === ExpenseDiscountType.Fixed
              ? defaultCompany?.currency_symbol
              : '%'
          }:`}</Description>
          <Title fontSize={15} bold $color={theme.colors.success}>
            {discount_type === ExpenseDiscountType.Fixed
              ? correctPrice(discountValue)
              : discountValue}
          </Title>
        </StyledTitleContainer>

        <StyledTitleContainer>
          <Description fontSize={15} bold $color={theme.colors.black}>{`${t(
            'To be paid',
          )}, ${defaultCompany?.currency_symbol}:`}</Description>
          <Title fontSize={15} bold $color={theme.colors.black}>
            {correctPrice(discount)}
          </Title>
        </StyledTitleContainer>
      </StyledContainer>
    </StyledDetailsView>
  );
}
