import {
  DepartmentModel,
  EmployeeModel,
  StoreBelongingTo,
  StoreModel,
  StoreType,
} from '../internal';
import {IStoreSettings} from './StoreDTO';

const storeSettings: IStoreSettings = {
  permissions: {
    input: {
      allow_in: true,
      allow_move: true,
      allow_return: true,
    },
    output: {
      allow_erase: true,
      allow_move: true,
      allow_order: true,
      allow_return: true,
      allow_sell: true,
    },
  },
};

export interface IStoreAdditionalParams {
  editMode?: boolean;
  manager?: EmployeeModel;
}

export class StoreFormDTO {
  public uuid: string;

  public store_name: string;

  public store_description: string;

  public store_type: string;

  public store_settings: IStoreSettings;

  public store_department_uuid: string | DepartmentModel;

  public store_manager_uuid: string | EmployeeModel;
  public store_belonging_to?: StoreBelongingTo;

  constructor(props: StoreModel, {editMode, manager}: IStoreAdditionalParams) {
    this.uuid = props?.uuid || '';
    this.store_name = props?.store_name || '';
    this.store_description = props?.store_description || '';
    this.store_type = props?.store_type || StoreType.PRODUCT;
    this.store_belonging_to = props?.company_department?.uuid
      ? StoreBelongingTo.DEPARTMENT
      : StoreBelongingTo.COMPANY;
    this.store_settings = props?.store_settings || storeSettings;
    this.store_department_uuid = props?.company_department?.uuid
      ? props?.companyDepartmentModel
      : '';
    this.store_manager_uuid = editMode
      ? props?.managerModel?.uuid
        ? props?.managerModel
        : ''
      : manager!;
  }
}
