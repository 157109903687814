export class SubscriptionUndefinedSubscriptionable extends Error {
  uuid = 'One or more objects';

  constructor(uuid: string) {
    super();
    Object.setPrototypeOf(
      this,
      SubscriptionUndefinedSubscriptionable.prototype,
    );
    this.message = `One or more objects in subscriptions uuid : '${uuid}' are undefined`;
  }
}
