import {Record, List} from 'immutable';
import {ListModel, ListProps} from './List';
import Client, {ClientProps} from './Client';

export interface ClientListProps extends ListProps {
  clients: ClientProps[];
}

export interface ClientListReturnType extends ListProps {
  clients: List<Client>;
}

export default class ClientList extends Record<ClientListReturnType>({
  clients: List(),
  ...ListModel.toJS(),
} as any) {
  /**
   * @constructor
   * @param {{clients:[]}} param0
   */
  constructor({clients = [], ...props}: ClientListProps) {
    const options: any = {};
    if (Array.isArray(clients)) {
      options.clients = List(clients.map((client) => new Client(client)));
    }

    super({
      ...props,
      ...options,
    });
  }
}
