import React from 'react';
import Color from 'color';
import {useTranslation} from 'react-i18next';
import styled, {css} from 'styled-components';
import {LoadingOutlined} from '@ant-design/icons';
import {useStoredCompanies} from '../../../hooks';
import {HiOutlineReceiptTax} from 'react-icons/hi';
import {StyledTitle} from '../../../components/lib/Styled';
import {correctPrice, isThereContent} from '../../../services/helpers';

import {
  DetailsActionItemView,
  IDetailsActionItemViewProps,
} from '../../../components/lib/DataDisplay';

import {
  IPaymentScheduleListStats,
  PaymentScheduleOperationType,
} from '../../../services/api/orders';

import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CheckOutlined,
} from '@ant-design/icons';

export interface IPaymentTypeViewProps
  extends Omit<IDetailsActionItemViewProps, 'onClick' | 'type' | 'children'> {
  type: PaymentScheduleOperationType;
  color: string;
  selected?: boolean;
  title: string;
  stats: IPaymentScheduleListStats;
  onClick: (type: PaymentScheduleOperationType) => void;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100%;
`;

const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  height: 100%;
`;

const StyledLoadingOutlined = styled(LoadingOutlined)<{$color: string}>`
  color: ${({$color}) => $color};
  font-size: 20px;
`;

const iconStyle = css<{$color: string}>`
  color: ${({$color}) => $color};

  font-size: 20px;
`;

const StyledCheckOutlined = styled(CheckOutlined)`
  ${iconStyle}
`;

const StyledArrowUpOutlined = styled(ArrowUpOutlined)`
  ${iconStyle}
`;

const StyledArrowDownOutlined = styled(ArrowDownOutlined)`
  ${iconStyle}
`;

export function PaymentTypeView({
  color,
  selected,
  type,
  title,
  stats,
  onClick,
  ...rest
}: IPaymentTypeViewProps): React.JSX.Element {
  const {t} = useTranslation();
  const {defaultCompany} = useStoredCompanies();

  const textColor = Color(color).alpha(1).darken(0.65).toString();

  let sum;
  let icon;
  const extraSum = correctPrice(stats?.totalInFiscalSum || 0);
  const isTypePayOut = type === PaymentScheduleOperationType.Out;

  if (type === PaymentScheduleOperationType.ALL) {
    sum = correctPrice(
      Number(stats?.totalInSum || 0) + Number(stats?.totalOutSum || 0),
    );
    icon = <StyledCheckOutlined $color={textColor} />;
  } else if (type === PaymentScheduleOperationType.In) {
    sum = correctPrice(stats?.totalInSum || 0);
    icon = <StyledArrowDownOutlined $color={textColor} />;
  } else {
    sum = correctPrice(stats?.totalOutSum || 0);
    icon = <StyledArrowUpOutlined $color={textColor} />;
  }

  return (
    <DetailsActionItemView
      color={color}
      selected={selected}
      onClick={() => onClick(type)}
      {...rest}>
      <StyledContainer>
        {icon || null}
        <StyledInfoContainer>
          <StyledTitle $color={textColor}>{t(title)}</StyledTitle>

          {isThereContent(stats) ? (
            <>
              <StyledTitle fontWeight={600} fontSize={12} $color={textColor}>
                {!isTypePayOut ? (
                  <>
                    <HiOutlineReceiptTax color={textColor} size={12} />
                    {`${extraSum}`}
                  </>
                ) : (
                  <>---</>
                )}
              </StyledTitle>
              <StyledTitle fontWeight={500} fontSize={25} $color={textColor}>
                {`${defaultCompany?.currency_symbol || ''} ${sum}`}
              </StyledTitle>
            </>
          ) : (
            <StyledLoadingOutlined $color={textColor} />
          )}
        </StyledInfoContainer>
      </StyledContainer>
    </DetailsActionItemView>
  );
}
