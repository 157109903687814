import {
  IStoreDocumentItemConfiguredPrice,
  IStoreDocumentItemModelReturnType,
  ProductModel,
} from '../internal';
import {correctPrice, head} from '../../helpers';

export interface IStoreDocumentItemAdditionalProps {
  editMode: boolean;
  price?: string;
}

export class StoreDocumentItemFormDTO {
  public product_uuid: string;

  public cell_identifier: string;

  public product_title?: string;

  public product_store_name?: string;

  public product_store_balance_total?: number;

  public product_amount: number;

  public price: string;

  public product_price: IStoreDocumentItemConfiguredPrice;

  constructor(
    props: IStoreDocumentItemModelReturnType | ProductModel,
    {editMode, price}: IStoreDocumentItemAdditionalProps,
  ) {
    this.product_uuid = editMode
      ? (props as IStoreDocumentItemModelReturnType)?.product?.uuid
      : (props as ProductModel)?.uuid;

    this.product_title = editMode
      ? (props as IStoreDocumentItemModelReturnType)?.product?.product_title
      : (props as ProductModel)?.product_title;

    this.product_store_name = editMode
      ? head(
          (props as IStoreDocumentItemModelReturnType)?.product
            ?.product_balances,
        )?.company_store?.store_name
      : head((props as ProductModel)?.product_balances)?.company_store
          ?.store_name;

    this.product_store_balance_total = editMode
      ? head(
          (props as IStoreDocumentItemModelReturnType)?.product
            ?.product_balances,
        )?.balance_total
      : head((props as ProductModel)?.product_balances)?.balance_total;

    this.cell_identifier =
      (props as IStoreDocumentItemModelReturnType)?.item_cell_identifier || '';
    this.product_amount =
      (props as IStoreDocumentItemModelReturnType)?.item_product_amount || 1;

    this.price = price
      ? price
      : editMode
      ? correctPrice(
          (props as IStoreDocumentItemModelReturnType)?.item_price || 0,
        )
      : '';

    this.product_price = editMode
      ? (props as IStoreDocumentItemModelReturnType)?.item_configured_price || {
          product: [],
          calculated: [],
        }
      : {
          product: (props as ProductModel)?.product_prices,
          calculated: (props as ProductModel)?.product_prices,
        };
  }
}
