import {
  IPaymentableModelReturnType,
  ClientMapper,
  ClientDTO,
  IClientDTOProps,
} from '../internal';

export interface IPaymentableDTOProps {
  uuid: string;
  balance_before: number;
  created_at: string;
  updated_at: string;
  client: IClientDTOProps;
}

export class PaymentableDTO {
  public uuid: string;

  public balance_before: number;

  public created_at: string;

  public updated_at: string;

  public client: ClientDTO;

  constructor(props: IPaymentableDTOProps | IPaymentableModelReturnType) {
    this.uuid = props?.uuid || '';
    this.balance_before = props?.balance_before || 0;
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
    this.client = ClientMapper.toClientDTO(props?.client);
  }
}
