import {Dispatch} from 'redux';
import {AUTH_DATA} from '../constants';
import {AuthDataDTO} from '../../struture';
import {decryptObject, encryptObject} from '../../services/helpers';
import {LocalStorageItems} from '../../services/const';

export interface ISetAuthDataParam {
  type: AUTH_DATA;
  authData: AuthDataDTO | null;
}

export interface ISetAuthTokensParam {
  type: AUTH_DATA;
  access_token: string;
  refresh_token: string;
}

export interface IUpdateAuthDataParam {
  type: AUTH_DATA;
  authData: AuthDataDTO | null;
}

export interface IResetAuthDataParam {
  type: AUTH_DATA;
}

export function setAuthData(
  authData: AuthDataDTO | null,
): (dispatch: Dispatch) => ISetAuthDataParam {
  if (authData) {
    const decryptAuthData = encryptObject(authData);

    localStorage.setItem(LocalStorageItems.authData, decryptAuthData);
  }

  return function (dispatch: Dispatch) {
    return dispatch({
      type: AUTH_DATA.SET_AUTH_DATA,
      authData,
    });
  };
}

export function initAuthData(): (dispatch: Dispatch) => ISetAuthDataParam {
  return function (dispatch: Dispatch) {
    const decryptAuthData = localStorage.getItem(LocalStorageItems.authData);

    let authData: AuthDataDTO = {} as AuthDataDTO;
    if (decryptAuthData) {
      authData = decryptObject(decryptAuthData);
    }

    return dispatch({
      type: AUTH_DATA.SET_AUTH_DATA,
      authData,
    });
  };
}

export function setAuthTokens(
  access_token: string,
  refresh_token: string,
): (dispatch: Dispatch) => ISetAuthTokensParam {
  return function (dispatch: Dispatch) {
    return dispatch({
      type: AUTH_DATA.SET_AUTH_TOKENS,
      access_token,
      refresh_token,
    });
  };
}

export function updateAuthData(
  authData: AuthDataDTO | null,
): (dispatch: Dispatch) => ISetAuthDataParam {
  return function (dispatch: Dispatch) {
    return dispatch({
      type: AUTH_DATA.UPDATE_AUTH_DATA,
      authData,
    });
  };
}

export function resetAuthData(): (dispatch: Dispatch) => IResetAuthDataParam {
  return function (dispatch: Dispatch) {
    return dispatch({
      type: AUTH_DATA.RESET_AUTH_DATA,
    });
  };
}
