import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled, {css} from 'styled-components';
import {Select, Tooltip} from 'antd';
import {listToArray} from '../../../services/helpers';
import {IUseStateStoreListReturnType} from '../../../hooks';
import {useTranslation} from 'react-i18next';

export interface IProductStoreSearchViewProps
  extends Pick<
    IUseStateStoreListReturnType,
    'storeList' | 'handleSearchStores'
  > {
  loading: boolean;
  onStoreChange: (storeUuid: string) => Promise<void>;
  storeUuid: string;
  storeListLoading: boolean;
  disabled?: boolean;
  tooltipTitle?: string;
}

const StyledSelect = styled(Select)<{disabled: boolean}>`
  width: 150px;
  
  ${({disabled}) =>
    !disabled &&
    css`
      & * > .ant-select-selection-placeholder {
        color: rgba(0, 0, 0, 0.88) !important;
      }
    `}
}
`;

export function ProductStoreSearchView({
  loading,
  onStoreChange,
  storeUuid,
  storeList,
  storeListLoading,
  handleSearchStores,
  disabled,
  tooltipTitle,
}: IProductStoreSearchViewProps): React.JSX.Element {
  const {t} = useTranslation();

  const [value, setValue] = useState<string | undefined>(undefined);

  const once = useRef(false);

  const handleStoreChange = useCallback(
    async (storeUuid: any = '') => {
      setValue(storeUuid || undefined);
      await onStoreChange(storeUuid);
    },
    [onStoreChange],
  );

  useEffect(() => {
    if (!once.current) {
      if (storeUuid) {
        setValue(storeUuid);
      }
    }
  }, [storeUuid]);

  return (
    <Tooltip title={tooltipTitle}>
      <StyledSelect
        popupMatchSelectWidth={220}
        showSearch
        allowClear
        loading={loading || storeListLoading}
        disabled={storeListLoading || loading || !!disabled}
        onSearch={(keywords) => handleSearchStores({keywords})}
        placeholder={t('Select a store')}
        options={listToArray(storeList as any) as any}
        fieldNames={{label: 'store_name', value: 'uuid'}}
        onChange={handleStoreChange}
        value={value}
        size="middle"
      />
    </Tooltip>
  );
}
