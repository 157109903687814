import React, {forwardRef, Suspense, useState} from 'react';
import styled, {css, useTheme} from 'styled-components';
import {StyledTitle} from '../../../components/lib/Styled';
import {useTranslation} from 'react-i18next';
import {
  IUseStateScheduleReturnType,
  useStoredCompanies,
  useSystemModules,
} from '../../../hooks';
import {
  ScheduleCalendarMapper,
  ScheduleDocumentType,
  ScheduleStatusesText,
  SettingsModuleStatuses,
} from '../../../struture';
import {Dropdown, MenuProps, Space, Tooltip} from 'antd';
import {
  DownOutlined,
  FileProtectOutlined,
  FileSyncOutlined,
  LoadingOutlined,
  LockOutlined,
  MessageOutlined,
  PrinterOutlined,
  SendOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import {OverviewBottomView} from '../../../components/lib/Layout';
import {useModal} from '../../../components/lib/libV2/hooks';
import {SuspenseEmpty} from '../../../components/lib/DataDisplay';
import {correctPrice} from '../../../services/helpers';

const OrderMessageSideWindowLazy = React.lazy(
  () => import('./OrderMessageSideWindow'),
);

export interface IOrderBottomViewProps
  extends Pick<
    IUseStateScheduleReturnType,
    | 'schedule'
    | 'handlePrintScheduleDocument'
    | 'handleFormingScheduleMessage'
    | 'handleSendScheduleMessage'
  > {}

const StyledPriceContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
`;

const StyledPrice = styled(StyledTitle)`
  font-size: 30px;
`;

const StyledDropdown = styled(Dropdown)<{disabled?: boolean}>`
  height: fit-content;

  padding: 10px;
  border: 1px solid ${({theme}) => theme.colors.black};
  border-radius: 5px;
  cursor: pointer;

  .ant-dropdown-menu-item {
    padding: 0 !important;
  }

  ${({disabled}) =>
    disabled &&
    css`
      border: 1px solid ${({theme}) => theme.colors.disabled};
      cursor: not-allowed;
    `}

  &&&:hover,
  &&&:active,
  &&&:focus {
    border: 1px solid ${({theme}) => theme.colors.black};
    opacity: 0.8;
  }
`;

const StyledSpace = styled(Space)`
  display: flex;
  justify-content: flex-start;
`;

const StyledPrinterOutlined = styled(PrinterOutlined)`
  font-size: 15px;
  color: ${({theme}) => theme.colors.black};
`;

const StyledMessageOutlined = styled(MessageOutlined)`
  font-size: 15px;
  color: ${({theme}) => theme.colors.black};

  ${({disabled}) =>
    disabled &&
    css`
      color: ${({theme}) => theme.colors.disabled};
    `}
`;

const StyledDownOutlined = styled(DownOutlined)`
  color: ${({theme}) => theme.colors.black};
`;

const StyledLockOutlined = styled(LockOutlined)`
  color: ${({theme}) => theme.colors.disabled};
`;

export const OrderBottomView = forwardRef(function OrderBottomView(
  {
    schedule,

    handlePrintScheduleDocument,

    handleSendScheduleMessage,
    handleFormingScheduleMessage,
  }: IOrderBottomViewProps,
  ref: any,
): JSX.Element {
  const {t} = useTranslation();
  const {defaultCompany} = useStoredCompanies();
  const theme: any = useTheme();

  const [loading, setLoading] = useState<ScheduleDocumentType | null>();

  const {visible, handleCancel, handleOnInit, handleSuccess} = useModal({
    onSuccess: handleSendScheduleMessage,
  });

  const {modulesMap} = useSystemModules();

  const sumText =
    Number(schedule?.sum_paid || 0) >= Number(schedule?.sum_total || 0)
      ? t('Paid')
      : t('Order sum');

  const isOrderCompleted =
    schedule?.status_text === ScheduleStatusesText.CLOSED;

  const printItems: MenuProps['items'] = [
    {
      key: ScheduleDocumentType.PreInvoice,
      label: <StyledTitle>{t('Pre invoice')}</StyledTitle>,
      icon:
        loading === ScheduleDocumentType.PreInvoice ? (
          <LoadingOutlined />
        ) : (
          <FileSyncOutlined />
        ),
      disabled: loading === ScheduleDocumentType.PreInvoice,
      onClick: async () => {
        setLoading(ScheduleDocumentType.PreInvoice);
        await handlePrintScheduleDocument(ScheduleDocumentType.PreInvoice);
        setLoading(null);
      },
    },
    {
      key: ScheduleDocumentType.ActCompletedWork,
      label: <StyledTitle>{t('Act of completed works')}</StyledTitle>,
      icon: <FileProtectOutlined />,
      disabled: loading === ScheduleDocumentType.ActCompletedWork,
      onClick: async () => {
        setLoading(ScheduleDocumentType.ActCompletedWork);
        await handlePrintScheduleDocument(
          ScheduleDocumentType.ActCompletedWork,
        );
        setLoading(null);
      },
    },
    ...(isOrderCompleted
      ? [
          {
            key: ScheduleDocumentType.Rewards,
            label: <StyledTitle>{t('Charge sheet')}</StyledTitle>,
            icon: <FileTextOutlined />,
            disabled: loading === ScheduleDocumentType.Rewards,
            onClick: async () => {
              setLoading(ScheduleDocumentType.Rewards);
              await handlePrintScheduleDocument(ScheduleDocumentType.Rewards);
              setLoading(null);
            },
          },
        ]
      : []),
  ];

  const messageItems: MenuProps['items'] = [
    {
      key: 'Send sms',
      icon:
        modulesMap?.sms?.enabled !== SettingsModuleStatuses?.Active ? (
          <StyledLockOutlined />
        ) : loading === ScheduleDocumentType.PreInvoice ? (
          <LoadingOutlined />
        ) : (
          <SendOutlined />
        ),
      disabled: modulesMap?.sms?.enabled !== SettingsModuleStatuses?.Active,
      label: (
        <Tooltip
          title={
            modulesMap?.sms?.enabled !== SettingsModuleStatuses?.Active
              ? t('To send SMS, please activate the SMS module')
              : ''
          }>
          <StyledTitle>{t('Send SMS')}</StyledTitle>
        </Tooltip>
      ),
      onClick: handleOnInit,
    },
  ];

  return (
    <>
      <Suspense fallback={<SuspenseEmpty />}>
        <OrderMessageSideWindowLazy
          message={ScheduleCalendarMapper.toScheduleMessageFormDTO(schedule)}
          loading={!!loading}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          visible={visible}
          handleFormingScheduleMessage={handleFormingScheduleMessage}
        />
      </Suspense>

      <div ref={ref}>
        <OverviewBottomView>
          <StyledSpace direction="horizontal" size="middle">
            <StyledDropdown
              menu={{items: printItems}}
              placement="topLeft"
              trigger={['click']}>
              <Space direction="horizontal" size="middle">
                <Space direction="horizontal" size="small">
                  {loading ? <LoadingOutlined /> : <StyledPrinterOutlined />}
                  <StyledTitle fontSize={14}>{t('Print')}</StyledTitle>
                </Space>

                <StyledDownOutlined />
              </Space>
            </StyledDropdown>
            <StyledDropdown
              menu={{items: messageItems}}
              placement="topLeft"
              trigger={['click']}>
              <Space direction="horizontal" size="middle">
                <Space direction="horizontal" size="small">
                  <StyledMessageOutlined />
                  <StyledTitle fontSize={14}>{t('Message')}</StyledTitle>
                </Space>
                <StyledDownOutlined />
              </Space>
            </StyledDropdown>
          </StyledSpace>
          <StyledPriceContainer>
            <StyledTitle fontSize={14}>{`${sumText}: `}</StyledTitle>
            <StyledPrice>
              {`${defaultCompany?.currency_symbol} ${correctPrice(
                schedule?.sum_total || 0,
              )}`}
            </StyledPrice>
          </StyledPriceContainer>
        </OverviewBottomView>
      </div>
    </>
  );
});
