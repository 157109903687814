import {apiDelete, apiGet, apiPatch, apiPost, apiPut} from '../core/api';
import {
  head,
  dateToIsoString,
  toDateByFormat,
  notEmptyFields,
} from '../helpers';
import {
  ApiAnswer,
  IEntityProps,
  IListSearchProps,
  OPERATION_TYPE,
} from '../types';
import {
  StoreDocumentDTO,
  StoreDocumentFormDTO,
  StoreDocumentMapper,
  IStoreDocumentDTOProps,
  IStoreDocumentListDTO,
  StoreDocumentType,
  StorePaymentDocumentFormDTO,
  AggregatedSumDTO,
  ExpenseMapper,
  IAggregatedSumDTOProps,
  StoreDocumentItemFormDTO,
  StoreDocumentItemDTO,
  StoreDocumentStatus,
  IPaymentListDTO,
  PaymentMapper,
  PaymentScheduleFormDTO,
  PaymentDTO,
  StoreDocumentModel,
  IStoreDocumentStatsDTOProps,
  StoreDocumentShowOnly,
  StoreDocumentStatsDTO,
  IStoreDocumentItemDTOProps,
  StorePaymentDocumentDiscountType,
  IStoreDocumentProfitStatsDTOProps,
  StoreDocumentProfitStatsDTO,
} from '../../struture';
import {contentApiUrl} from '../const';
import {
  IPaymentScheduleListAnswer,
  PaymentScheduleOperationType,
} from './orders';
import {Moment} from 'moment/moment';
import {IPaymentAnswer} from './companyPayments';

const {doc_comment, doc_input_number, doc_items, doc_date, doc_sum_total} =
  new StoreDocumentDTO({} as IStoreDocumentDTOProps);

export const POSTING_REQUIRED_FIELD = {
  doc_comment,
  doc_items,
  doc_input_number,
};

export const MOVING_REQUIRED_FIELD = {
  doc_comment,
  doc_items,
};

export const ERASE_REQUIRED_FIELD = {
  doc_comment,
  doc_items,
};

export const REALIZATION_REQUIRED_FIELD = {
  doc_comment,
  doc_items,
};

export const RETURN_REQUIRED_FIELD = {
  doc_comment,
  doc_items,
};

export const STORE_PAYMENT_DISCOUNT_TYPE: (currencySymbol: string) => {
  uuid: StorePaymentDocumentDiscountType;
  title: StorePaymentDocumentDiscountType;
  description: string;
}[] = (currencySymbol) => [
  {
    uuid: StorePaymentDocumentDiscountType.PERCENTAGE,
    title: StorePaymentDocumentDiscountType.PERCENTAGE,
    description: '%',
  },
  {
    uuid: StorePaymentDocumentDiscountType.FIXED,
    title: StorePaymentDocumentDiscountType.FIXED,
    description: currencySymbol,
  },
];

export interface IStoreDocumentAnswer extends ApiAnswer {
  document: IStoreDocumentDTOProps;
  aggregated_sum: IAggregatedSumDTOProps;
  count_of_related_documents: number;
  stats: IStoreDocumentStatsDTOProps | IStoreDocumentProfitStatsDTOProps;
}

export interface IStoreDocumentItemAnswer extends ApiAnswer {
  item: StoreDocumentItemDTO;
  document: IStoreDocumentDTOProps;
  stats: IStoreDocumentProfitStatsDTOProps;
}

export interface IStoreDocumentListAnswer extends ApiAnswer, IListSearchProps {
  documents: IStoreDocumentDTOProps[];
  stats: IStoreDocumentStatsDTOProps;
}

export interface IStoreRelatedDocumentListAnswer
  extends ApiAnswer,
    IListSearchProps {
  documents: IStoreDocumentDTOProps[];
  document: IStoreDocumentDTOProps;
}

export interface IStoreDocumentListProps extends IListSearchProps {
  companyUuid: string;
  documentType: StoreDocumentType;
  documentStatus?: StoreDocumentStatus;
  start_date?: string;
  end_date?: string;
  show_only?: StoreDocumentShowOnly;
  resident_uuid?: string;
  show_with_credits_only?: boolean;
}

export interface IStoreDocumentRelatedDocumentListProps
  extends IListSearchProps {
  documentUuid: string;
}

export interface IStoreDocumentReturnType {
  document: StoreDocumentDTO;
  aggregatedSum: AggregatedSumDTO;
  stats: StoreDocumentStatsDTO;
}

export async function getStoreDocumentList({
  offset = 0,
  limit = 10,
  keywords,
  companyUuid,
  documentType,
  documentStatus,
  start_date,
  end_date,
  show_only,
  resident_uuid,
  show_with_credits_only,
}: IStoreDocumentListProps): Promise<IStoreDocumentListDTO> {
  const {documents, total, stats} = await apiGet<
    IListSearchProps & {
      doc_type: StoreDocumentType;
      doc_status?: StoreDocumentStatus;
      date_start?: string;
      date_end?: string;
      show_only?: StoreDocumentShowOnly;
      resident_uuid?: string;
      show_with_credits_only?: boolean;
    },
    IStoreDocumentListAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/store-documents`, {
    offset,
    limit,
    keywords,
    doc_type: documentType,
    doc_status: documentStatus,
    date_start: start_date
      ? toDateByFormat(start_date, 'YYYY-MM-DDTHH:mm:ss')
      : '',
    date_end: end_date ? toDateByFormat(end_date, 'YYYY-MM-DDTHH:mm:ss') : '',
    show_only,
    resident_uuid,
    show_with_credits_only,
  });

  return StoreDocumentMapper.toStoreDocumentListDTO(
    documents,
    total || 0,
    [],
    undefined,
    StoreDocumentMapper.toStoreDocumentStatsDTO(stats),
    show_only,
    resident_uuid,
  );
}

export async function getStoreDocumentRelatedDocumentList({
  offset = 0,
  limit = 10,
  keywords,
  documentUuid,
}: IStoreDocumentRelatedDocumentListProps): Promise<IStoreDocumentListDTO> {
  const {documents, total, document} = await apiGet<
    IListSearchProps,
    IStoreRelatedDocumentListAnswer
  >(`${contentApiUrl}/store-documents/${documentUuid}/documents`, {
    offset,
    limit,
    keywords,
  });

  return StoreDocumentMapper.toStoreDocumentListDTO(
    documents,
    total || 0,
    [],
    StoreDocumentMapper.toStoreDocumentModel(
      StoreDocumentMapper.toStoreDocumentDTO({
        ...document,
        company_store: (document as any)?.store,
      }),
    ),
  );
}

export async function getStoreDocumentById(
  storeUuid: string,
): Promise<StoreDocumentDTO> {
  const {document, count_of_related_documents, stats} = await apiGet<
    null,
    IStoreDocumentAnswer
  >(`${contentApiUrl}/store-documents/${storeUuid}`);

  return StoreDocumentMapper.toStoreDocumentDTO({
    ...document,
    count_of_related_documents,
    stats: new StoreDocumentProfitStatsDTO(
      stats as IStoreDocumentProfitStatsDTOProps,
    ),
  });
}

export async function createStoreDocument(
  {
    doc_store_uuid,
    doc_client,
    doc_destination_store_uuid,
    doc_input_number,
    doc_related_document_uuid,
    doc_date,
    doc_items,
    doc_status,
    doc_type,
    doc_comment,
    doc_post_action,
    doc_extra_services,
    doc_multi_currencies,
    doc_discount_type,
    doc_discount_source,
    doc_discount_value,
  }: StoreDocumentFormDTO,
  companyUuid: string,
): Promise<IStoreDocumentReturnType> {
  const client: any = (({uuid, ...rest}) =>
    uuid.includes('create') ? notEmptyFields({...rest}) : {uuid})(doc_client);

  const {document, aggregated_sum, stats} = await apiPost<
    Omit<StoreDocumentFormDTO, 'uuid'>,
    IStoreDocumentAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/store-documents`, {
    doc_store_uuid,
    doc_client: client,
    doc_destination_store_uuid,
    doc_input_number,
    doc_related_document_uuid,
    doc_date: doc_date ? dateToIsoString(doc_date).toString() : '',
    doc_items, //: [],
    doc_status,
    doc_type,
    doc_comment,
    doc_post_action,
    doc_extra_services,
    doc_multi_currencies,
    doc_discount_type,
    doc_discount_source,
    doc_discount_value,
  });

  return {
    document: StoreDocumentMapper.toStoreDocumentDTO(document),
    aggregatedSum: ExpenseMapper.toAggregatedSumDTO(aggregated_sum),
    stats: StoreDocumentMapper.toStoreDocumentStatsDTO(
      stats as IStoreDocumentStatsDTOProps,
    ),
  };
}

export async function editStoreDocument({
  uuid: store_document_uuid,
  doc_store_uuid,
  doc_client,
  doc_destination_store_uuid,
  doc_input_number,
  doc_related_document_uuid,
  doc_date,
  doc_items,
  doc_status,
  doc_type,
  doc_comment,
  doc_post_action,
  doc_extra_services,
  doc_multi_currencies,
  doc_discount_type,
  doc_discount_source,
  doc_discount_value,
}: Omit<
  StoreDocumentFormDTO,
  'getTimingList'
>): Promise<IStoreDocumentReturnType> {
  const client: any = (({uuid, ...rest}) =>
    uuid.includes('create') ? notEmptyFields({...rest}) : {uuid})(doc_client);

  const {document, aggregated_sum, stats} = await apiPut<
    Omit<StoreDocumentFormDTO, 'uuid'>,
    IStoreDocumentAnswer
  >(`${contentApiUrl}/store-documents/${store_document_uuid}`, {
    doc_store_uuid,
    doc_client: client,
    doc_destination_store_uuid,
    doc_input_number,
    doc_related_document_uuid,
    doc_date: doc_date ? dateToIsoString(doc_date).toString() : '',
    doc_items: [],
    doc_status,
    doc_type,
    doc_comment,
    doc_post_action,
    doc_extra_services,
    doc_multi_currencies,
    doc_discount_type,
    doc_discount_source,
    doc_discount_value,
  });

  return {
    document: StoreDocumentMapper.toStoreDocumentDTO({
      ...document,
      stats: new StoreDocumentProfitStatsDTO(
        stats as IStoreDocumentProfitStatsDTOProps,
      ),
    }),
    aggregatedSum: ExpenseMapper.toAggregatedSumDTO(aggregated_sum),
    stats: StoreDocumentMapper.toStoreDocumentStatsDTO(
      stats as IStoreDocumentStatsDTOProps,
    ),
  };
}

export async function deleteStoreDocument(
  ids: string[] = [],
): Promise<IStoreDocumentReturnType> {
  const {document, aggregated_sum, stats} = await apiDelete<
    {ids: string[]},
    IStoreDocumentAnswer
  >(`${contentApiUrl}/store-documents/${head(ids)}`);

  return {
    document: StoreDocumentMapper.toStoreDocumentDTO(document),
    aggregatedSum: ExpenseMapper.toAggregatedSumDTO(aggregated_sum),
    stats: StoreDocumentMapper.toStoreDocumentStatsDTO(
      stats as IStoreDocumentStatsDTOProps,
    ),
  };
}

export interface PaymentStoreDocumentQueryParams {
  with_document?: boolean;
  with_stats?: boolean;
}

export async function paymentStoreDocument(
  {
    uuid: documentUuid,
    payment_comment,
    payment_client_uuid,
    payment_manager_uuid,
    payment_cashbox_uuid,
    payment_method,
    payment_post_action,
    payment_price,
    payment_date,
    payment_fiscal_number,
    payment_is_fiscal_create,
    payment_doc_comment,
    payment_doc_discount_source_type,
    payment_doc_discount_type,
    payment_doc_discount_value,
  }: StorePaymentDocumentFormDTO,
  {
    with_document = false,
    with_stats = false,
  }: PaymentStoreDocumentQueryParams = {},
): Promise<IStoreDocumentReturnType> {
  const {document, aggregated_sum, stats} = await apiPost<
    Omit<StorePaymentDocumentFormDTO, 'uuid'>,
    IStoreDocumentAnswer
  >(
    `${contentApiUrl}/store-documents/${documentUuid}/payments?with_document=${with_document}&with_stats=${with_stats}`,
    {
      payment_comment,
      payment_client_uuid,
      payment_manager_uuid,
      payment_cashbox_uuid,
      payment_method,
      payment_post_action,
      payment_is_fiscal_create,
      payment_price: Number(payment_price),
      payment_date: payment_date
        ? toDateByFormat(payment_date, 'YYYY-MM-DDTHH:mm:ss')
        : '',
      payment_fiscal_number,
      payment_doc_comment,
      payment_doc_discount_source_type,
      payment_doc_discount_type,
      payment_doc_discount_value,
    },
  );

  return {
    document: StoreDocumentMapper.toStoreDocumentDTO(document),
    aggregatedSum: ExpenseMapper.toAggregatedSumDTO(aggregated_sum),
    stats: StoreDocumentMapper.toStoreDocumentStatsDTO(
      stats as IStoreDocumentStatsDTOProps,
    ),
  };
}

export interface IStoreDocumentItemReturnType {
  item: StoreDocumentItemDTO;
  document: StoreDocumentModel;
}

export interface IStoreDocumentItemListReturnType {
  items: StoreDocumentItemDTO[];
  document: StoreDocumentModel;
  total: number;
}

export interface IStoreDocumentItemListProps extends IListSearchProps {
  documentUuid: string;
}

export interface IStoreDocumentItemListAnswer
  extends ApiAnswer,
    IListSearchProps {
  document: IStoreDocumentDTOProps;
  items: IStoreDocumentItemDTOProps[];
}

export async function getStoreDocumentItemList({
  offset = 0,
  limit = 10,
  keywords,
  documentUuid,
}: IStoreDocumentItemListProps): Promise<IStoreDocumentItemListReturnType> {
  const {items, document, total} = await apiGet<
    IListSearchProps,
    IStoreDocumentItemListAnswer
  >(`${contentApiUrl}/store-documents/${documentUuid}/items`, {
    offset,
    limit,
    keywords,
  });

  return {
    items:
      StoreDocumentMapper.toStoreDocumentItemListDTO(items).storeDocumentItems,
    document: StoreDocumentMapper.toStoreDocumentModel(
      StoreDocumentMapper.toStoreDocumentDTO(document),
    ),
    total,
  };
}

export async function createStoreDocumentItem(
  {
    product_uuid,
    product_amount,
    price,
    product_price,
    cell_identifier,
    price_currency,
  }: StoreDocumentItemFormDTO,
  documentUuid: string,
): Promise<IStoreDocumentItemReturnType> {
  const {item, document, stats} = await apiPost<
    Omit<StoreDocumentItemFormDTO, 'uuid'>,
    IStoreDocumentItemAnswer
  >(`${contentApiUrl}/store-documents/${documentUuid}/items`, {
    product_uuid,
    product_amount,
    price,
    product_price,
    cell_identifier,
    price_currency,
  });

  return {
    item: StoreDocumentMapper.toStoreDocumentItemDTO(item),
    document: StoreDocumentMapper.toStoreDocumentModel(
      StoreDocumentMapper.toStoreDocumentDTO({
        ...document,
        stats: new StoreDocumentProfitStatsDTO(
          stats as IStoreDocumentProfitStatsDTOProps,
        ),
      }),
    ),
  };
}

export async function editStoreDocumentItem(
  {
    uuid,
    product_uuid,
    product_amount,
    price,
    product_price,
    cell_identifier,
    price_currency,
  }: StoreDocumentItemFormDTO,
  documentUuid: string,
): Promise<IStoreDocumentItemReturnType> {
  const {item, document, stats} = await apiPut<
    Omit<StoreDocumentItemFormDTO, 'uuid'>,
    IStoreDocumentItemAnswer
  >(`${contentApiUrl}/store-documents/${documentUuid}/items/${uuid}`, {
    product_uuid,
    product_amount,
    price,
    product_price,
    cell_identifier,
    price_currency,
  });

  return {
    item: StoreDocumentMapper.toStoreDocumentItemDTO(item),
    document: StoreDocumentMapper.toStoreDocumentModel(
      StoreDocumentMapper.toStoreDocumentDTO({
        ...document,
        stats: new StoreDocumentProfitStatsDTO(
          stats as IStoreDocumentProfitStatsDTOProps,
        ),
      }),
    ),
  };
}

export async function deleteStoreDocumentItem(
  ids: string[] = [],
  documentUuid: string,
): Promise<IStoreDocumentItemReturnType> {
  const {item, document, stats} = await apiDelete<
    {ids: string[]},
    IStoreDocumentItemAnswer
  >(`${contentApiUrl}/store-documents/${documentUuid}/items/${head(ids)}`);

  return {
    item: StoreDocumentMapper.toStoreDocumentItemDTO(item),
    document: StoreDocumentMapper.toStoreDocumentModel(
      StoreDocumentMapper.toStoreDocumentDTO({
        ...document,
        stats: new StoreDocumentProfitStatsDTO(
          stats as IStoreDocumentProfitStatsDTOProps,
        ),
      }),
    ),
  };
}

/* ------------------- STORE DOCUMENT PAYMENTS ------------------- */

export interface IStoreDocumentPaymentListParam
  extends Partial<IListSearchProps>,
    IEntityProps {
  date_start?: Date | Moment | string;
  date_end?: Date | Moment | string;
  documentUuid: string;
  type?: PaymentScheduleOperationType;
}

export interface IStoreDocumentAnswer
  extends Omit<IPaymentScheduleListAnswer, 'stats'> {
  document: IStoreDocumentDTOProps;
}

export interface IPaymentStoreDocumentAnswer extends IPaymentAnswer {
  document: IStoreDocumentDTOProps;
}

export interface IPaymentStoreDocumentReturnType {
  payment: PaymentDTO;
  document: StoreDocumentModel;
}

export async function getStoreDocumentPaymentList({
  documentUuid,
  date_start,
  date_end,
  keywords,
  offset = 0,
  limit = 10,
  type = PaymentScheduleOperationType.ALL,
}: IStoreDocumentPaymentListParam): Promise<IPaymentListDTO> {
  const {payments, stats, count, document} = await apiGet<
    IListSearchProps & {
      date_start: any;
      date_end: any;
      type: PaymentScheduleOperationType;
    },
    IStoreDocumentAnswer
  >(`${contentApiUrl}/store-documents/${documentUuid}/payments`, {
    date_start: date_start ? toDateByFormat(date_start, 'YYYY-MM-DD') : '',
    date_end: date_end ? toDateByFormat(date_end, 'YYYY-MM-DD') : '',
    keywords,
    offset,
    limit,
    type,
  });

  return PaymentMapper.toPaymentListDTO(
    payments,
    count,
    stats as any,
    undefined,
    StoreDocumentMapper.toStoreDocumentModel(
      StoreDocumentMapper.toStoreDocumentDTO(document),
    ),
  );
}

export async function createStoreDocumentPayment(
  {
    payment_comment,
    payment_date,
    payment_method,
    payment_manager_uuid,
    payment_client_uuid,
    payment_price,
    payment_post_action,
    payment_cashbox_uuid,
    payment_type,
  }: PaymentScheduleFormDTO & {payment_type: OPERATION_TYPE},
  documentUuid: string,
  {
    with_document = false,
    with_stats = false,
  }: PaymentStoreDocumentQueryParams = {},
): Promise<IPaymentStoreDocumentReturnType> {
  const {payment, document} = await apiPost<
    Omit<PaymentScheduleFormDTO, 'uuid'> & {payment_type: OPERATION_TYPE},
    IPaymentStoreDocumentAnswer
  >(
    `${contentApiUrl}/store-documents/${documentUuid}/payments?with_document=${with_document}&with_stats=${with_stats}`,
    {
      payment_comment,
      payment_date: dateToIsoString(payment_date as any),
      payment_method,
      payment_manager_uuid,
      payment_client_uuid,
      payment_price,
      payment_post_action,
      payment_cashbox_uuid,
      payment_type,
    },
  );

  return {
    payment: PaymentMapper.toPaymentDTO(payment),
    document: StoreDocumentMapper.toStoreDocumentModel(
      StoreDocumentMapper.toStoreDocumentDTO(document),
    ),
  };
}

/* ------------------- STORE PRINT DOCUMENTS ------------------- */

export interface IStorePrintDocumentAnswer extends ApiAnswer {
  html: string;
}

export async function getStoreDocumentForPrint(
  documentUuid: string,
): Promise<string> {
  const {html} = await apiGet<null, IStorePrintDocumentAnswer>(
    `${contentApiUrl}/store-documents/${documentUuid}/print`,
  );

  return html;
}

export async function getStoreDocumentCheckForPrint(
  documentUuid: string,
): Promise<string> {
  const {html} = await apiGet<null, IStorePrintDocumentAnswer>(
    `${contentApiUrl}/store-documents/${documentUuid}/print/check`,
  );

  return html;
}

/* ------------------- STORE DOCUMENT COMMENT ------------------- */

export async function updateStoreDocumentComment(
  doc_comment: string,
  documentUuid: string,
): Promise<StoreDocumentDTO> {
  const {document} = await apiPatch<
    {doc_comment: string},
    IStoreDocumentAnswer
  >(`${contentApiUrl}/store-documents/${documentUuid}/details`, {doc_comment});

  return StoreDocumentMapper.toStoreDocumentDTO(document);
}

/* ------------------- STORE SALE DOCUMENT PRICE MARGIN ------------------- */

export async function editStoreDocumentSalePriceMargin(
  documentUuid: string,
  priceMarginUuid: string,
): Promise<StoreDocumentDTO> {
  const {document} = await apiPost<null, IStoreDocumentAnswer>(
    `${contentApiUrl}/store-documents/${documentUuid}/apply-price/${priceMarginUuid}`,
    null,
  );

  return StoreDocumentMapper.toStoreDocumentDTO(document);
}
