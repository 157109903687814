import {
  IFinancialSettingsDTOProps,
  FinancialSettingsDTO,
  ICompanyOrderBonusDTOProps,
  CompanyOrderBonusDTO,
} from '../internal';

export class FinancialSettingsMapper {
  public static toFinancialSettings(
    props: IFinancialSettingsDTOProps,
  ): FinancialSettingsDTO {
    return new FinancialSettingsDTO(props);
  }

  public static toCompanyOrderBonusDTO(
    props: ICompanyOrderBonusDTOProps,
  ): CompanyOrderBonusDTO {
    return new CompanyOrderBonusDTO(props);
  }

  public static toCompanyOrderBonusListDTO(
    orderBonuses: ICompanyOrderBonusDTOProps[],
  ): CompanyOrderBonusDTO[] {
    return orderBonuses.map((bonus) => new CompanyOrderBonusDTO(bonus));
  }
}
