import React, {useCallback} from 'react';
import {Col, Form, Row} from 'antd';
import {SearchSelect} from '../../../components/lib/DataDisplay';
import {isListToArray} from '../../../services/helpers';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {DatePicker} from '../../../components/lib/General';
import {
  IUseStateEmployeeListReturnType,
  IUseStateAbonementListReturnType,
} from '../../../hooks';
import {useDefaultForm} from '../../../contex';

export interface IReportingAbonementFieldsProps
  extends Pick<
      IUseStateEmployeeListReturnType,
      'employees' | 'handleSearchEmployees'
    >,
    Pick<
      IUseStateAbonementListReturnType,
      'abonementList' | 'handleSearchAbonements'
    > {
  loading: boolean;
  employeesLoading: boolean;
  abonementsLoading: boolean;
}

const StyledRangePicker = styled(DatePicker.RangePicker)`
  width: 100%;
`;
export function ReportingAbonementFields({
  loading,

  employees,
  employeesLoading,
  handleSearchEmployees,

  abonementList,
  abonementsLoading,
  handleSearchAbonements,
}: IReportingAbonementFieldsProps): JSX.Element {
  const {t} = useTranslation();
  const {handlerUpdateFormState, loadingSubmit} = useDefaultForm();

  const handleChangeEmployee = useCallback(
    async (employeeUuid: string) => {
      handlerUpdateFormState({
        report_employee_uuid: employeeUuid,
      });
    },
    [handlerUpdateFormState],
  );

  const handleChangeAbonement = useCallback(
    async (report_tariff_model_uuid: string) => {
      const abonement = abonementList?.find(
        ({uuid}) => uuid === report_tariff_model_uuid,
      );

      handlerUpdateFormState({
        report_tariff_model_uuid,
      });

      if (abonement) {
        const report_employee_uuid =
          abonement?.serviceModel?.managerModel?.uuid;

        if (report_employee_uuid) {
          handlerUpdateFormState({
            report_employee_uuid,
          });
        }
      }
    },
    [abonementList, handlerUpdateFormState],
  );

  return (
    <Row gutter={20}>
      <Col span={24}>
        <Form.Item
          tooltip={t('Interval')}
          label={t('Interval')}
          name="_date"
          rules={[
            {
              required: true,
              message: t('Interval must be specified.'),
            },
          ]}>
          <StyledRangePicker
            showTime
            format="YYYY-MM-DD"
            disabled={loading}
            placeholder={[t('Start'), t('End')]}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          tooltip={t('Tariff model')}
          label={t('Tariff model')}
          name="report_tariff_model_uuid"
          rules={[
            {
              required: true,
              message: t('Tariff model must be specified.'),
            },
          ]}>
          <SearchSelect
            selectIfOnFirst={false}
            selectFirst={false}
            name="report_tariff_model_uuid"
            getOptionValueTitle="abon_title"
            placeholder={t('Select an abonement')}
            disable={loadingSubmit || abonementsLoading}
            data={isListToArray(abonementList as any)}
            onChange={handleChangeAbonement}
            onSearch={(keywords) =>
              handleSearchAbonements({
                keywords,
                showLoading: false,
                limit: 100,
              })
            }
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          tooltip={t('Manager')}
          label={t('Manager')}
          name="report_employee_uuid">
          <SearchSelect
            selectFirst={false}
            name="report_employee_uuid"
            getOptionValueTitle="fullName"
            placeholder={t('Select an employee')}
            disable={loading || loadingSubmit || employeesLoading}
            data={isListToArray(employees as any)}
            onSearch={(keywords) =>
              handleSearchEmployees({
                keywords,
                showLoading: false,
                limit: 100,
              })
            }
            onChange={handleChangeEmployee}
          />
        </Form.Item>
      </Col>
    </Row>
  );
}
