import * as React from 'react';

export type UseDidCancelReturnType = React.MutableRefObject<boolean>;

export function useDidCancel(): UseDidCancelReturnType {
  const didCancel = React.useRef<boolean>(false);

  React.useEffect(() => {
    didCancel.current = false;

    return () => {
      didCancel.current = true;
    };
  }, []);

  return didCancel;
}
