import React, {Suspense, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Dropdown, MenuProps, Popconfirm} from 'antd';
import {useModal} from '../../../components/lib/libV2/hooks';
import {InvoiceFormDTO} from '../../../struture';
import {
  Button,
  IAddButtonProps,
  SuspenseEmpty,
} from '../../../components/lib/DataDisplay';
import {StyledTitle} from '../../../components/lib/Styled';
import {LoadingOutlined} from '@ant-design/icons';
import styled, {css, useTheme} from 'styled-components';
import {IoMenu} from 'react-icons/io5';
import {IUseStateInvoiceReturnType} from '../../../hooks';
import {MdModeEditOutline} from 'react-icons/md';
import {IoDuplicate} from 'react-icons/io5';

const InvoiceSideWindowLazy = React.lazy(
  () => import('../Show/InvoiceSideWindow'),
);

export interface InvoicePageMenuButtonProps extends IAddButtonProps {
  children?: React.ReactNode;
  onSuccess: IUseStateInvoiceReturnType['handleUpdateInvoice'];
  onDuplicate: IUseStateInvoiceReturnType['handleDuplicateInvoice'];
  onCancel?: () => void;
  invoice: InvoiceFormDTO;
}

const StyledDropdown = styled(Dropdown)<{
  disabled?: boolean;
}>`
  cursor: pointer;

  margin-right: 15px;
  .ant-dropdown-menu-item {
    padding: 0 !important;
  }

  ${({disabled}) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

const StyledPopconfirmButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;

  padding: 0;
  margin: 0;

  &&&:hover,
  &&&:active,
  &&&:focus {
    background-color: transparent;
  }
`;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  color: ${({theme}) => theme.colors.primary};
`;

const StyledIoDuplicate = styled(IoDuplicate)`
  margin-right: 8px;
`;

export function InvoicePageMenuButton({
  children,
  onSuccess,
  onCancel,
  loading,
  disabled,
  invoice,
  onDuplicate,
  ...rest
}: InvoicePageMenuButtonProps) {
  const {t} = useTranslation();
  const theme: any = useTheme();

  const [duplicateLoading, setDuplicateLoading] = useState(false);

  const handleDuplicateInvoice = useCallback(async () => {
    setDuplicateLoading(true);
    await onDuplicate(invoice?.uuid);
    setDuplicateLoading(false);
  }, [invoice?.uuid, onDuplicate]);

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  const items: MenuProps['items'] = [
    {
      key: 'edit',
      label: <StyledTitle>{t('Edit')}</StyledTitle>,
      icon: <MdModeEditOutline size={15} />,
      disabled: disabled,
      onClick: handleOnInit,
    },
    {
      key: 'duplicate',
      label: (
        <Popconfirm
          title={`${t('Create a new invoice based on the current invoice')}?`}
          okText={t('Yes')}
          cancelText={t('No')}
          onCancel={async (e) => {
            e?.preventDefault();
            e?.stopPropagation();
          }}
          onConfirm={async (e) => {
            e?.preventDefault();
            e?.stopPropagation();

            await handleDuplicateInvoice();
          }}>
          <StyledPopconfirmButton
            type="text"
            onClick={async (e: any) => {
              if (e) {
                e?.preventDefault();
                e?.stopPropagation();
              }
            }}>
            {duplicateLoading ? (
              <StyledLoadingOutlined />
            ) : (
              <StyledIoDuplicate size={15} />
            )}
            <StyledTitle>{t('Duplicate')}</StyledTitle>
          </StyledPopconfirmButton>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <StyledDropdown
        trigger={['hover']}
        disabled={!!loading}
        menu={{items}}
        placement="bottomLeft">
        {loading || duplicateLoading ? (
          <LoadingOutlined color={theme.colors.primary} />
        ) : (
          <IoMenu size={20} color={theme.colors.primary} />
        )}
      </StyledDropdown>

      <Suspense fallback={<SuspenseEmpty />}>
        <InvoiceSideWindowLazy
          editMode
          invoice={invoice}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}
