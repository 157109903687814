import * as React from 'react';
import {List} from 'immutable';
import {editEmployeeWorkSchedule} from '../services/api/employee';
import {useStateEntity} from '../components/lib/libV2/hooks';
import {WorkScheduleModel, EmployeeMapper} from '../struture';

export interface IUseStoreEmployeeScheduleProps {
  workScheduleList: List<WorkScheduleModel> | null;
  employeeUuid: string;
}

export interface IUseStoreEmployeeScheduleReturnType {
  workScheduleList: List<WorkScheduleModel> | null;
  changeWorkSchedule: (id: string) => Promise<void>;
  loading: boolean;
}

export default function useStateEmployeeSchedule({
  workScheduleList,
  employeeUuid,
}: IUseStoreEmployeeScheduleProps): IUseStoreEmployeeScheduleReturnType {
  const {
    entity: stateWorkSchedule,
    loading,
    handleUpdate,
  } = useStateEntity<List<WorkScheduleModel>>(workScheduleList);

  const changeWorkSchedule = React.useCallback(
    async (schedule_id: string): Promise<void> => {
      const employeeDTO = await editEmployeeWorkSchedule({
        employee_uuid: employeeUuid,
        schedule_id,
      });

      const employeeModel = EmployeeMapper.toEmployeeModel(employeeDTO);

      handleUpdate(employeeModel.work_schedule);
    },
    [employeeUuid, handleUpdate],
  );

  return {
    workScheduleList: stateWorkSchedule,
    changeWorkSchedule,
    loading,
  };
}
