import React from 'react';
import {ClientModel, ClientOrgType} from '../../../struture';
import {FaUserAlt} from 'react-icons/fa';
import {HiOutlineLibrary} from 'react-icons/hi';
import styled, {css} from 'styled-components';
import {StyledDescription, StyledTitle} from '../../../components/lib/Styled';
import {useTranslation} from 'react-i18next';

export interface IClientOrgTypeViewProps {
  client: ClientModel | null;
  icon?: React.ReactNode;
  title?: string;
  description?: string;
  onlyIcon?: boolean;
}

const StyledWrapper = styled.div<{$isLineHeight: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${({$isLineHeight}) =>
    $isLineHeight &&
    css`
      padding: 3px;
      line-height: 1.5;
    `}
`;

const StyledTitleIconContainer = styled(StyledDescription)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & > :first-child {
    margin-right: 5px;
  }
`;

export function ClientOrgTypeView({
  client,
  title,
  description,
  icon,
  onlyIcon = false,
  ...rest
}: IClientOrgTypeViewProps): React.JSX.Element {
  const {t} = useTranslation();

  return (
    <StyledTitleIconContainer {...rest}>
      {icon ? (
        icon
      ) : client?.client_org_type === ClientOrgType.PERSON ? (
        <FaUserAlt size={description ? 18 : 16} />
      ) : (
        <HiOutlineLibrary size={description ? 20 : 18} />
      )}
      {onlyIcon ? null : (
        <StyledWrapper $isLineHeight={!!description}>
          <StyledTitle fontSize={13}>
            {t(title || client?.client_org_type)}
          </StyledTitle>
          {description ? (
            <StyledDescription>{description}</StyledDescription>
          ) : null}
        </StyledWrapper>
      )}
    </StyledTitleIconContainer>
  );
}
