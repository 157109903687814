import {
  SystemSubscriptionDTO,
  ISystemSubscriptionDTOProps,
  RuleLimitationMapper,
  SystemSubscriptionModel,
} from '../internal';

export class SystemSubscriptionMapper {
  public static toSystemSubscriptionDTO(
    systemSubscription: SystemSubscriptionModel | ISystemSubscriptionDTOProps,
  ): SystemSubscriptionDTO {
    return new SystemSubscriptionDTO({
      subscription_key: systemSubscription?.subscription_key || '',
      subscription_price: systemSubscription?.subscription_price || '',
      subscription_title: systemSubscription?.subscription_title || '',
      subscription_description:
        systemSubscription?.subscription_description || '',
      status_txt: systemSubscription?.status_txt || '',
      rule_limitations: RuleLimitationMapper.toRuleLimitationListDTO(
        systemSubscription?.rule_limitations,
      ),
    });
  }

  public static toSystemSubscriptionModel(
    systemSubscriptionDTO: SystemSubscriptionDTO,
  ): SystemSubscriptionModel {
    return new SystemSubscriptionModel(systemSubscriptionDTO);
  }
}
