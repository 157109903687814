import {ReportModel, PAYMENT_METHOD} from '../internal';
import {
  getEndOfMonthDate,
  getStartOfMonthDate,
  toMoment,
} from '../../services/helpers';
import {Moment} from 'moment';

export enum REPORT_PAYMENT_METHOD {
  Account = 'ACCOUNT',
  Bank = 'BANK',
  Cash = 'CASHBOX',
  All = 'ALL',
}

export class ReportFormDTO {
  public uuid: string;

  public report_start_date: string;

  public report_end_date: string;

  public _date: Moment[];

  public report_employee_uuid: string;

  public report_client_uuid: string;

  public report_service_uuid: string;
  public report_tariff_model_uuid: string;

  public report_operation_type: REPORT_PAYMENT_METHOD;

  constructor(props?: ReportModel) {
    this.uuid = props?.uuid || '';
    this.report_start_date = getStartOfMonthDate();
    this.report_end_date = getEndOfMonthDate();
    this.report_employee_uuid = '';
    this.report_client_uuid = '';
    this.report_service_uuid = '';
    this.report_tariff_model_uuid = '';
    this.report_operation_type = REPORT_PAYMENT_METHOD.All;
    this._date = [
      toMoment(this.report_start_date),
      toMoment(this.report_end_date),
    ];
  }
}
