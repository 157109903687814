import React from 'react';
import {LoadingOutlined} from '@ant-design/icons';
import styled, {css} from 'styled-components';
import {FlexContainer} from '../Styled';

export default Loader;

export interface ILoaderProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  full: boolean;
}

const LoaderFlexContainer = styled(FlexContainer)<any>`
  text-align: center;
  ${({full}) =>
    full &&
    css`
      min-height: 100vh;
    `}
`;

/**
 * @name Loader
 * @desc Loader component: displays a loading icon.
 */
function Loader({full, ...rest}: ILoaderProps): JSX.Element {
  return (
    <LoaderFlexContainer
      full={full}
      flexDirection="column"
      justifyContent="center"
      {...rest}>
      <LoadingOutlined />
    </LoaderFlexContainer>
  );
}
