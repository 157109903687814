import React from 'react';
import {
  PaymentModel,
  ScheduleCalendarModel,
  StoreDocumentModel,
} from '../../../struture';
import styled, {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {StyledDescription} from '../../../components/lib/Styled';
import {Routes} from '../../../services/types';
import {Link} from '../../../components/lib/DataDisplay';
import {RELATED_ROUTES_NAME} from '../../Stores';
import {CALENDAR_ENTRY, CALENDAR} from '../../../components/lib/const';

export interface PaymentTriggerObjectViewProps {
  payment: PaymentModel;
}

const StyledLink = styled(Link)`
  display: inline-flex;
  font-size: 12px;
`;

export function PaymentTriggerObjectView({
  payment,
}: PaymentTriggerObjectViewProps): React.JSX.Element {
  const {t} = useTranslation();
  const theme: any = useTheme();

  const isContainsTriggerObject = (payment?.payment_trigger_object as any)
    ?.uuid;

  const to =
    payment?.payment_trigger_object instanceof StoreDocumentModel
      ? `/${Routes.app}/${Routes.stores}/${
          RELATED_ROUTES_NAME[payment?.payment_trigger_object?.doc_type]
        }/${payment?.payment_trigger_object?.uuid}`
      : payment?.payment_trigger_object instanceof ScheduleCalendarModel
      ? `/${Routes.app}/${Routes.orders}/${payment?.payment_trigger_object?.uuid}/${Routes.schedules}/${CALENDAR}/${CALENDAR_ENTRY}/${payment?.payment_trigger_object?.uuid}`
      : '';

  const text =
    payment?.payment_trigger_object instanceof StoreDocumentModel
      ? `№ ${payment?.payment_trigger_object?.doc_local_number}`
      : payment?.payment_trigger_object instanceof ScheduleCalendarModel
      ? `№ ${payment?.payment_trigger_object?.schedule_number}`
      : '';

  const tooltip =
    payment?.payment_trigger_object instanceof StoreDocumentModel
      ? 'Open a detailed description of the document'
      : payment?.payment_trigger_object instanceof ScheduleCalendarModel
      ? 'Open a detailed description of the order'
      : '';

  return (
    <>
      {isContainsTriggerObject ? (
        <>
          <StyledDescription>{`/ `}</StyledDescription>
          <StyledLink
            to={to}
            tooltip={t(tooltip)}
            state={{goBack: location.pathname}}>
            {text}
          </StyledLink>
        </>
      ) : null}
    </>
  );
}
