import React, {useEffect, useRef, useState} from 'react';
import {AggregatedSumDTO} from '../../../struture';
import {DetailsView} from '../../../components/lib/DataDisplay';
import {StyledTitle, StyledDescription} from '../../../components/lib/Styled';
import styled, {css, useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useSize, useStoredCompanies} from '../../../hooks';
export interface IExpenseAggregatedSumViewProps {
  aggregatedSum: AggregatedSumDTO | null;
  className?: string;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  //padding-bottom: 10px;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
`;

const Title = styled(StyledTitle)<{$width?: number}>`
  min-width: 100px;
`;

const Description = styled(StyledDescription)`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  margin-right: 10px;
`;

const StyledDetailsView = styled(DetailsView)`
  box-shadow: none;
  border-color: #d9d9d9;
  text-align: right;
`;

export function ExpenseAggregatedSumView({
  aggregatedSum,
  className,
}: IExpenseAggregatedSumViewProps): JSX.Element {
  const {t} = useTranslation();
  const theme: any = useTheme();
  const {defaultCompany} = useStoredCompanies();

  return (
    <>
      {aggregatedSum ? (
        <StyledDetailsView titles={[]} className={className}>
          <StyledContainer>
            <StyledTitleContainer>
              <Description fontSize={15} bold $color={theme.colors.link}>{`${t(
                'Discount',
              )}, ${defaultCompany?.currency_symbol}:`}</Description>
              <Title fontSize={15} bold $color={theme.colors.link}>
                {aggregatedSum?.total_discount}
              </Title>
            </StyledTitleContainer>

            <StyledTitleContainer>
              <Description
                fontSize={15}
                bold
                $color={theme.colors.success}>{`${t('Profit')}, ${
                defaultCompany?.currency_symbol
              }:`}</Description>
              <Title fontSize={15} bold $color={theme.colors.success}>
                {aggregatedSum?.total_profit}
              </Title>
            </StyledTitleContainer>

            <StyledTitleContainer>
              <Description fontSize={15} bold $color={theme.colors.black}>{`${t(
                'Total sum',
              )}, ${defaultCompany?.currency_symbol}:`}</Description>
              <Title fontSize={15} bold $color={theme.colors.black}>
                {aggregatedSum?.total_sum}
              </Title>
            </StyledTitleContainer>
          </StyledContainer>
        </StyledDetailsView>
      ) : null}
    </>
  );
}
