import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import styled from 'styled-components';
import {OverviewLayoutFlexContainer} from '../../../Styled';
import {TextFormat} from '../../../Format';

export interface IOverViewUnderHeaderContainerProps {
  children?: React.ReactNode;
  title: string;
  extra: React.ReactNode;
}

const StyledTextFormat = styled(TextFormat)`
  font-size: 1.5em;
  color: ${({theme}) => theme.colors.primary};
`;

export default function OverViewUnderHeaderContainer({
  children,
  title,
  extra,
}: IOverViewUnderHeaderContainerProps): JSX.Element {
  return (
    <OverviewLayoutFlexContainer
      flexWrap="wrap"
      alignItems="center"
      justifyContent="space-between">
      {children || null}
      <StyledTextFormat bold>{title}</StyledTextFormat>
      {extra}
    </OverviewLayoutFlexContainer>
  );
}
