import React, {useCallback, useEffect, useState} from 'react';
import {StyledDescription, StyledTitle} from '../../../components/lib/Styled';
import {SettingsModuleModel} from '../../../struture';
import {textToUpperCase} from '../../../services/helpers';
import styled, {useTheme} from 'styled-components';
import {Switch} from '../../../components/lib/DataDisplay';

export interface ISettingsModuleListItemProps {
  settingsModule: SettingsModuleModel;
  handleActivateModule: (settingsModule: SettingsModuleModel) => Promise<void>;
  handleDeactivateModule: (
    settingsModule: SettingsModuleModel,
  ) => Promise<void>;
}

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledActivityIndicator = styled.div`
  margin-right: 10px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100%;
`;

const StyledTextWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  paddin-left: 18px;
  height: 100%;
`;

export function SettingsModuleListItem({
  settingsModule,
  handleDeactivateModule,
  handleActivateModule,
}: ISettingsModuleListItemProps): JSX.Element {
  const theme: any = useTheme();

  const [isActive, setIsActive] = useState<boolean>(
    !!settingsModule?.module_enabled_for_company,
  );
  const [loading, setLoading] = useState<boolean>(false);

  const handleActivateOrDeactivate = useCallback(
    async (value: boolean) => {
      try {
        setIsActive(value);
        setLoading(true);

        if (settingsModule) {
          if (value) {
            await handleActivateModule(settingsModule);
          } else {
            await handleDeactivateModule(settingsModule);
          }
        }
        setLoading(false);
      } catch (error) {
        setIsActive(!value);
        setLoading(false);
      }
    },
    [handleActivateModule, handleDeactivateModule, settingsModule],
  );

  useEffect(() => {
    if (typeof settingsModule?.module_enabled_for_company === 'boolean') {
      setIsActive((prevState) =>
        prevState === settingsModule?.module_enabled_for_company
          ? prevState
          : settingsModule?.module_enabled_for_company,
      );
    }
  }, [settingsModule?.module_enabled_for_company]);

  return (
    <StyledContainer>
      <StyledTextWrapper>
        <StyledTextContainer>
          <StyledTitle>
            {textToUpperCase(settingsModule?.module_title)}
          </StyledTitle>
        </StyledTextContainer>
        <StyledDescription>
          {settingsModule?.module_description}
        </StyledDescription>
      </StyledTextWrapper>
      {settingsModule?.dependencyListModel?.size === 0 &&
      settingsModule?.module_input_parameters_json?.length === 0 ? (
        <Switch
          checked={isActive}
          // extra={<>{loading ? <StyledActivityIndicator /> : null}</>}
          onChange={handleActivateOrDeactivate}
          disabled={!settingsModule || loading}
        />
      ) : null}
    </StyledContainer>
  );
}
