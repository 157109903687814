import {List} from 'immutable';
import {
  GroupPaymentDTO,
  IGroupPaymentDTOProps,
  GroupPaymentFormDTO,
  GroupPaymentModel,
  GroupPaymentListModel,
} from '../internal';
import {listToArray} from '../../helpers';
import {WithoutRecursion} from '../type';

export interface IGroupPaymentListDTO {
  groups: GroupPaymentDTO[];
  total: number;
}

export class GroupPaymentMapper {
  public static toGroupPaymentDTO(
    group: GroupPaymentModel | IGroupPaymentDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): GroupPaymentDTO {
    return new GroupPaymentDTO(
      {
        uuid: group?.uuid,
        title: group?.title,
        description: group?.description,
        updated_at: group?.updated_at,
        created_at: group?.created_at,
        companies: group?.companies,
      },
      [WithoutRecursion.groupPayment, ...withoutRecursion],
    );
  }

  public static toGroupPaymentListDTO(
    groups: List<GroupPaymentModel> | IGroupPaymentDTOProps[],
    total?: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): IGroupPaymentListDTO {
    const groupList = List.isList(groups)
      ? listToArray<GroupPaymentModel>(groups)
      : groups;

    return {
      groups: groupList?.map((group) =>
        GroupPaymentMapper.toGroupPaymentDTO(group, [
          WithoutRecursion.groupPayment,
          ...withoutRecursion,
        ]),
      ),
      total: total || 0,
    };
  }

  public static toGroupPaymentModel(
    groupDTO: GroupPaymentDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): GroupPaymentModel {
    return new GroupPaymentModel(groupDTO, [
      WithoutRecursion.groupPayment,
      ...withoutRecursion,
    ]);
  }

  public static toGroupPaymentListModel(
    groupDTOs: GroupPaymentDTO[],
    total?: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): GroupPaymentListModel {
    return new GroupPaymentListModel({groups: groupDTOs, total}, [
      WithoutRecursion.groupPayment,
      ...withoutRecursion,
    ]);
  }

  public static toGroupPaymentFormDTO(
    groupModel?: GroupPaymentModel,
  ): GroupPaymentFormDTO {
    return new GroupPaymentFormDTO(groupModel);
  }
}
