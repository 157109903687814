import {List} from 'immutable';
import {
  ServiceGroupSessionDTO,
  IServiceGroupSessionDTOProps,
  ServiceGroupSessionFormDTO,
  ServiceGroupSessionModel,
  ServiceGroupSessionListModel,
} from '../internal';
import {listToArray} from '../../helpers';
import {WithoutRecursion} from '../type';

export interface IServiceGroupSessionListDTO {
  group_sessions: ServiceGroupSessionDTO[];
  total: number;
}

export class ServiceGroupSessionMapper {
  public static toServiceGroupSessionDTO(
    props: ServiceGroupSessionModel | IServiceGroupSessionDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): ServiceGroupSessionDTO {
    return new ServiceGroupSessionDTO(
      {
        uuid: props?.uuid,
        title: props?.title,
        description: props?.description,
        start_date: props?.start_date,
        end_date: props?.end_date,
        weekdays: props?.weekdays,
        max_attenders: props?.max_attenders,
        status: props?.status,
        created_at: props?.created_at,
        updated_at: props?.updated_at,
        service: props?.service,
        duration: props?.duration,
        manager: props?.manager,
        price: props?.price,
      },
      [WithoutRecursion.groupSession, ...withoutRecursion],
    );
  }

  public static toServiceGroupSessionListDTO(
    group_sessions:
      | List<ServiceGroupSessionModel>
      | IServiceGroupSessionDTOProps[],
    total?: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): IServiceGroupSessionListDTO {
    const group_sessionList = List.isList(group_sessions)
      ? listToArray<ServiceGroupSessionModel>(group_sessions)
      : group_sessions;

    return {
      group_sessions: group_sessionList?.map((group_session) =>
        ServiceGroupSessionMapper.toServiceGroupSessionDTO(group_session, [
          WithoutRecursion.groupSession,
          ...withoutRecursion,
        ]),
      ),
      total: total || 0,
    };
  }

  public static toServiceGroupSessionModel(
    group_sessionDTO: ServiceGroupSessionDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): ServiceGroupSessionModel {
    return new ServiceGroupSessionModel(group_sessionDTO, [
      WithoutRecursion.groupSession,
      ...withoutRecursion,
    ]);
  }

  public static toServiceGroupSessionListModel(
    group_sessionDTOs: ServiceGroupSessionDTO[],
    total?: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): ServiceGroupSessionListModel {
    return new ServiceGroupSessionListModel(
      {group_sessions: group_sessionDTOs, total},
      [WithoutRecursion.groupSession, ...withoutRecursion],
    );
  }

  public static toServiceGroupSessionFormDTO(
    group_sessionModel: ServiceGroupSessionModel,
  ): ServiceGroupSessionFormDTO {
    return new ServiceGroupSessionFormDTO(group_sessionModel);
  }
}
