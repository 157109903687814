import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Col, Row, Form, Input, Space} from 'antd';
import {DefaultForm, IDefaultFormProps} from '../../../components/lib/General';
import {StyledDescription} from '../../../components/lib/Styled';
import {CompanyInviteFormDTO} from '../../../struture';
import {Button} from '../../../components/lib/DataDisplay';
import {MdOutlineContentCopy, MdOutlineIosShare} from 'react-icons/md';
import styled from 'styled-components';
import {isMobile, isFunction} from '../../../services/helpers';
import {useDropdownAlert} from '../../../contex';

export interface ICompanyInviteFormProps<T, U>
  extends Pick<
    IDefaultFormProps<T, U>,
    | 'showFooter'
    | 'showNotify'
    | 'editMode'
    | 'className'
    | 'additionalValuesRequest'
  > {
  invite: CompanyInviteFormDTO;
  onCancel?: () => void;
}

const Description = styled(StyledDescription)`
  display: flex;
  margin-top: -15px;
  margin-bottom: 10px;
  color: ${({theme}) => theme.colors.accent};
`;

const CancelCol = styled(Col)`
  margin-top: 10px;
`;

export function CompanyInviteForm<T, U>({
  invite,
  editMode,
  additionalValuesRequest,
  onCancel,
  ...rest
}: ICompanyInviteFormProps<T, U>): JSX.Element {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const [joiningText, setJoiningText] = useState(
    `${t('You have been invited to the company')} "${invite?.title}".`,
  );

  const handleSuccessCopy = useCallback(
    async ({link}: {link: string}) => {
      try {
        await navigator.clipboard.writeText(
          `${link} ${t('Your registration invitation code')}: "${
            invite?.joining_code
          }". ${t('Details')}: ${invite?.joining_url}`,
        );

        alert('success', t('Copy invitation'), t('Copy invitation success'));
        setTimeout(() => {
          if (isFunction(onCancel)) {
            onCancel();
          }
        }, 500);
      } catch (error) {
        alert(
          'error',
          t('Copy invitation'),
          `${t('An error occurred during copy invitation')}`,
        );
        if (isFunction(onCancel)) {
          onCancel();
        }
      }
    },
    [alert, invite?.joining_code, invite?.joining_url, onCancel, t],
  );

  const handleSuccessShare = useCallback(
    async ({link}: {link: string}) => {
      try {
        await navigator.share({
          text: `${link} ${t('Your registration invitation code')}: "${
            invite?.joining_code
          }". ${t('Details')}: ${invite?.joining_url}`,
        });

        alert('success', t('Share invitation'), t('Share invitation success'));

        setTimeout(() => {
          if (isFunction(onCancel)) {
            onCancel();
          }
        }, 500);
      } catch (error) {
        alert(
          'error',
          t('Share invitation'),
          `${t('An error occurred during share invitation')}`,
        );
        if (isFunction(onCancel)) {
          onCancel();
        }
      }
    },
    [alert, invite?.joining_code, invite?.joining_url, onCancel, t],
  );

  return (
    <DefaultForm
      withContext
      notifyFormName={t('Invite')}
      initialValues={{link: joiningText}}
      editMode={editMode}
      {...rest}
      showFooter={false}>
      {({loadingSubmit, submit, formData}) => (
        <Row gutter={20}>
          <Col span={24}>
            <Form.Item
              label={t('Link')}
              name="link"
              tooltip={t('Brief description of the company')}>
              <Input.TextArea
                allowClear
                autoSize={{minRows: 3, maxRows: 5}}
                disabled={loadingSubmit}
                onChange={(e) => setJoiningText(e.target.value)}
              />
            </Form.Item>
            <Description>
              {t(
                "In order for a new employee to register himself and be added to the list of Employees of the current Company, it is necessary to send the text below to the person using the available messengers. After receiving this information, a person should follow the link indicated in the text, after which a page will be opened in the browser window, from which the INVITATION CODE should be taken. This INVITATION CODE must be entered on the Employee registration form (open the 'Employee Registration' option on the main screen of the application).",
              )}
            </Description>
          </Col>

          <Col span={isMobile.any() ? 12 : 24}>
            <Button onClick={() => handleSuccessCopy(formData)}>
              <MdOutlineContentCopy />
            </Button>
          </Col>
          {isMobile.any() ? (
            <Col span={12} onClick={() => handleSuccessShare(formData)}>
              <Button>
                <MdOutlineIosShare />
              </Button>
            </Col>
          ) : null}

          <CancelCol span={24}>
            <Button
              type="text"
              onClick={() => {
                if (isFunction(onCancel)) {
                  onCancel();
                }
              }}>
              {t('Cancel')}
            </Button>
          </CancelCol>
        </Row>
      )}
    </DefaultForm>
  );
}
