import React from 'react';
import {Row, Col} from 'antd';
import {StyledTitle} from '../../../components/lib/Styled';
import {DeleteButton} from '../../../components/lib/DataDisplay/DeleteButton';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {TextFormat} from '../../../components/lib/Format';

export interface IProfileDeleteTabProps {
  handleDeleteProfile?: () => Promise<void>;
}

const Title = styled(StyledTitle)`
  display: block;
  text-align: center;
  margin-bottom: 15px;
`;

const StyledDeleteButton = styled(DeleteButton)`
  width: 100%;
`;

export function ProfileDeleteTab({
  handleDeleteProfile,
}: IProfileDeleteTabProps): JSX.Element {
  const {t} = useTranslation();
  return (
    <Row>
      <Col span={24}>
        <Title danger>
          {t(
            'The monitoring system will track the Companies, Employees, Customers, Services and Orders created by the User, after which they will be disabled and then deleted.',
          )}
        </Title>
        <StyledDeleteButton
          confirmButtonText={t('Yes, delete')}
          cancelButtonText={t('No')}
          placement="top"
          type="primary"
          confirmTitle={
            <TextFormat breakWord>
              {t('Are you sure you want to delete the Profile?')}
            </TextFormat>
          }
          onConfirm={handleDeleteProfile}
          danger
          tooltipTitle={t('Delete profile')}
          title={t('Delete')}
        />
      </Col>
    </Row>
  );
}
