import {List} from 'immutable';
import {
  MaterialAndExpenseDTO,
  MaterialAndExpenseFormDTO,
  IMaterialAndExpenseDTOProps,
  MaterialAndExpenseModel,
  MaterialAndExpenseListModel,
} from '../internal';
import {listToArray} from '../../services/helpers';

export interface IMaterialAndExpenseListDTO {
  expenses: MaterialAndExpenseDTO[];
  total: number;

  sum_total: string;
}

export class MaterialAndExpenseMapper {
  public static toMaterialAndExpenseDTO(
    expense: MaterialAndExpenseModel | IMaterialAndExpenseDTOProps,
  ): MaterialAndExpenseDTO {
    return new MaterialAndExpenseDTO(expense);
  }

  public static toMaterialAndExpenseListDTO(
    expenses: List<MaterialAndExpenseModel> | IMaterialAndExpenseDTOProps[],
    total: number,
    sum_total: string,
  ): IMaterialAndExpenseListDTO {
    const expenseList = List.isList(expenses)
      ? listToArray<MaterialAndExpenseModel>(expenses)
      : expenses;

    return {
      expenses: expenseList?.map((expense) =>
        MaterialAndExpenseMapper.toMaterialAndExpenseDTO(expense),
      ),
      total,
      sum_total,
    };
  }

  public static toMaterialAndExpenseModel(
    expenseDTO: MaterialAndExpenseDTO,
  ): MaterialAndExpenseModel {
    return new MaterialAndExpenseModel(expenseDTO);
  }

  public static toMaterialAndExpenseListModel(
    expenseDTOs: MaterialAndExpenseDTO[],
    total: number,
    sum_total: string,
  ): MaterialAndExpenseListModel {
    return new MaterialAndExpenseListModel({
      schedule_expenses: expenseDTOs,
      total,
      sum_total,
    });
  }

  public static toMaterialAndExpenseFormDTO(
    expenseModel: MaterialAndExpenseModel,
  ): MaterialAndExpenseFormDTO {
    return new MaterialAndExpenseFormDTO(expenseModel);
  }
}
