import React, {useState, useEffect, useCallback} from 'react';
import {ClientMapper, ClientModel} from '../../../struture';
import {
  Table,
  TableActionCell,
} from '../../../components/lib/libV2/DataDisplay';
import {DeleteButton, ListActions} from '../../../components/lib/DataDisplay';
import {useTranslation} from 'react-i18next';
import {List} from 'immutable';
import {ClientDetailsView} from '../Show';
import {LockOutlined} from '@ant-design/icons';
import {TextFormat} from '../../../components/lib/Format';
import {IUseStoreClientReturnType} from '../../../hooks';
import {useDropdownAlert} from '../../../contex';

export interface IClientGroupMembersListProps
  extends Pick<
    IUseStoreClientReturnType,
    'handleUpdateClientGroup' | 'client'
  > {}

export function ClientGroupMembersList({
  client,
  handleUpdateClientGroup,
}: IClientGroupMembersListProps): JSX.Element {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const [membersList, setMembersList] = useState<List<ClientModel> | null>(
    null,
  );

  const [deletedClientUuid, setDeletedClientUuid] = useState('');

  const [loading, setLoading] = useState(false);

  const handleDeleteClientFromGroup = useCallback(
    async (clientUuid: string) => {
      try {
        setLoading(true);
        setDeletedClientUuid(clientUuid);

        const clientGroupFormDTO = ClientMapper.toClientGroupFormDTO(
          client!,
          true,
        );

        const client_uuids = clientGroupFormDTO.client_uuids.filter(
          (uuid: string) => uuid !== clientUuid,
        );

        const updatedClientGroupFormDTO = {...clientGroupFormDTO, client_uuids};

        await handleUpdateClientGroup(updatedClientGroupFormDTO);

        alert('success', t('Client'), t('Client delete success'));
        setLoading(false);
        setDeletedClientUuid('');
      } catch (error: any) {
        setLoading(false);
        setDeletedClientUuid('');
        alert(
          'error',
          t('Client'),
          `${t('An error occurred during delete clients')}: ${error?.message}`,
        );
      }
    },
    [alert, client, handleUpdateClientGroup, t],
  );

  const columns = [
    {
      title: t('Client'),
      key: 'client',
      render: (client: ClientModel) => <ClientDetailsView client={client} />,
    },
    {
      title: t('Actions'),
      key: 'client_actions',
      align: 'center' as any,
      fixed: 'right' as any,
      render: (client: ClientModel) => (
        <TableActionCell>
          {client?.client_strict_mode ? (
            <LockOutlined />
          ) : (
            <DeleteButton
              tooltipTitle={t('Delete company client')}
              confirmTitle={
                <TextFormat breakWord>
                  {t(`Are you sure you want to delete `)}
                  <b>{t('clienT').toLowerCase()}</b>
                </TextFormat>
              }
              onConfirm={() => handleDeleteClientFromGroup(client?.uuid)}
              loading={loading && client?.uuid === deletedClientUuid}
              cancelButtonText={t('Cancel')}
            />
          )}
        </TableActionCell>
      ),
    },
  ];

  const handleSearchClients = useCallback(
    (keywords: string) => {
      setMembersList((prevState) => {
        if (List.isList(prevState)) {
          if (!keywords) {
            return client!.memberListModel;
          }

          return prevState?.filter((client) => {
            return client.fullNameClient.toLowerCase().includes(keywords);
          });
        }

        return prevState;
      });
    },
    [client],
  );

  useEffect(() => {
    if (List.isList(client?.memberListModel) && client) {
      setMembersList(client.memberListModel);
    }
  }, [client]);

  return (
    <>
      <ListActions
        withoutPicker
        loading={false}
        handleSearch={handleSearchClients as any}
        inputTooltip={t('Search clients')}
        inputLabel={t('Search clients')}
      />
      <Table<ClientModel>
        total={membersList ? membersList.size : 0}
        pageSize={10}
        dataSource={membersList}
        columns={columns}
        loading={false}
      />
    </>
  );
}
