import React, {useCallback, useEffect} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {AuthOfficeHeader, AuthOfficeTabPanel} from '../Show';
import {AuthLayout, AuthRouteLayout} from '../../Show';
import {getPassedHourFrom} from '../../../../services/helpers';
import {Routes} from '../../../../services/types';
import {useStoredProfile, useStoreVerificationFactory} from '../../../../hooks';
import {RootState} from '../../../../store/reducers';
import {
  ISignupPageState,
  IForgotPasswordPageState,
  IEmployeeSignupPageState,
} from '../../../../store/actions';
import {useDropdownAlert} from '../../../../contex';
import {useNavigate} from 'react-router';

export default withTranslation()(AuthOffice);

export interface IAuthOfficeProps extends WithTranslation {}

const EMPLOYEE_VERIFY_CODE_TIME_EXPIRED = 8;

//19:03h

function AuthOffice({t}: IAuthOfficeProps): JSX.Element {
  const navigate = useNavigate();

  const {alert} = useDropdownAlert();

  const {
    identifier: signupIdentifier,
    initStateFactory,
    loading,
  } = useStoreVerificationFactory<Pick<ISignupPageState, 'identifier'>>({
    key: Routes.signup,
    getState: (state: RootState) => ({
      identifier: state[Routes.signup]?.identifier,
      loading: state[Routes.signup]?.loading,
    }),
  });

  const {
    identifier: forgotPasswordIdentifier,
    timeWhenCodeWasSend: forgotPasswordTimeWhenCodeWasSend,
    approveEnter: forgotPasswordApproveEnter,
    isShowWarning: forgotPasswordIsShowWarning,
    updateStateFactory,
  } = useStoreVerificationFactory<
    Pick<
      IForgotPasswordPageState,
      'identifier' | 'timeWhenCodeWasSend' | 'approveEnter' | 'isShowWarning'
    >
  >({
    key: Routes.forgotPassword,
    getState: (state: RootState) => ({
      identifier: state[Routes.forgotPassword]?.identifier,
      timeWhenCodeWasSend: state[Routes.forgotPassword]?.timeWhenCodeWasSend,
      approveEnter: state[Routes.forgotPassword]?.approveEnter,
      isShowWarning: state[Routes.forgotPassword]?.isShowWarning,
    }),
  });

  const {identifier: employeeSignupIdentifier} = useStoreVerificationFactory<
    Pick<IEmployeeSignupPageState, 'identifier'>
  >({
    key: Routes.employeeSignup,
    getState: (state: RootState) => ({
      identifier: state[Routes.employeeSignup]?.identifier,
    }),
  });

  const {resetProfile} = useStoredProfile();

  const navigateToLoginPage = useCallback(() => {
    navigate(`/${Routes.auth}/${Routes.login}`, {
      state: {from: location.pathname},
    });
  }, [navigate]);

  const navigateToSignupPage = useCallback(() => {
    navigate(`/${Routes.auth}/${Routes.signup}`, {
      state: {from: location.pathname},
    });
  }, [navigate]);

  useEffect(() => {
    (async () => {
      if (loading) {
        await initStateFactory();
      }
    })();
  }, [initStateFactory, loading]);

  useEffect(() => {
    resetProfile();
  }, [resetProfile]);

  useEffect(() => {
    if (forgotPasswordTimeWhenCodeWasSend && forgotPasswordIsShowWarning) {
      const hours = getPassedHourFrom(forgotPasswordTimeWhenCodeWasSend);

      if (hours >= EMPLOYEE_VERIFY_CODE_TIME_EXPIRED) {
        updateStateFactory({
          timeWhenCodeWasSend: null,
          identifier: null,
          approveEnter: null,
          isShowWarning: false,
        });

        alert(
          'warn',
          t('Forgot password'),
          t('The deadline for changing your password has expired'),
        );
      }
    }
  }, [
    forgotPasswordApproveEnter,
    forgotPasswordTimeWhenCodeWasSend,
    alert,
    updateStateFactory,
    t,
    forgotPasswordIsShowWarning,
  ]);

  return (
    <AuthLayout>
      <AuthOfficeTabPanel
        showForgotPasswordWarning={!!forgotPasswordIdentifier}
        showInviteWarning={!!employeeSignupIdentifier}>
        {({routes}) => (
          <AuthRouteLayout
            header={
              <AuthOfficeHeader
                handleNavigateToLoginPage={navigateToLoginPage}
                handleNavigateToSignupPage={navigateToSignupPage}
                showRegistrationWarning={!!signupIdentifier}
              />
            }
            routes={routes}
          />
        )}
      </AuthOfficeTabPanel>
    </AuthLayout>
  );
}
