import React, {RefObject, useRef} from 'react';
import styled, {css} from 'styled-components';
import {Moment} from 'moment';
import ListDateRange from './ListDateRange';
import ListSearchInput from './ListSearchInput';
import {FlexContainer, StyledRowContainer} from '../../Styled';
import {useSize} from '../../../../hooks';
import {isFunction} from '../../../../services/helpers';
import {useTranslation} from 'react-i18next';
import {ReloadOutlined, LoadingOutlined} from '@ant-design/icons';
import Button from '../../DataDisplay/Button';
import {InputProps} from 'antd/lib/input';

export interface IListActionsProps extends Omit<InputProps, 'children'> {
  handlePickRange?: (value: [Moment, Moment]) => Promise<void>;
  handleSearch: (value: string) => Promise<any>;
  defaultDateRangeValue?: [Moment, Moment] | undefined | null;
  loading: boolean;
  dateRangeTooltip?: string;
  dateRangeLabel?: string;
  inputTooltip?: string;
  inputLabel?: string;
  withoutPicker?: boolean;
  searchText?: string;
  children?:
    | React.ReactNode
    | React.ReactNode[]
    | ((value: {fullWidth: boolean}) => React.ReactNode);
  className?: string;
  toggleValue?: any;
  withSearchContent?: React.ReactNode | React.ReactNode[];
  withSearchEndContent?: React.ReactNode | React.ReactNode[];
  inputRef?: RefObject<HTMLInputElement>;
  align?: 'flex-start' | 'flex-end' | 'center';
}

const PaymentFlexContainer = styled(FlexContainer)<{$isSmallScreen: boolean}>`
  //margin-bottom: 10px;
  align-items: flex-end;
  gap: 5px;

  ${({$isSmallScreen}) =>
    $isSmallScreen &&
    css`
      flex-direction: column;
      & > :first-child {
        margin-bottom: 15px;
      }
    `}
`;

const StyledUpdateButton = styled(Button)``;

export default function ListActions({
  handlePickRange,
  handleSearch,
  defaultDateRangeValue,
  loading,
  dateRangeTooltip,
  dateRangeLabel,
  inputTooltip,
  inputLabel,
  withoutPicker = false,
  searchText = '',
  children,
  className,
  toggleValue,
  withSearchContent,
  withSearchEndContent,
  inputRef,
  align = 'flex-end',
  ...rest
}: IListActionsProps): JSX.Element {
  const {t} = useTranslation();
  const contentRef = useRef(null);

  const searchTextRef = useRef<string>(searchText);

  const {width} = useSize(contentRef);

  const isSmallScreen = width > 0 && width <= 510;

  return (
    <PaymentFlexContainer
      ref={contentRef}
      className={className}
      justifyContent="space-between"
      $isSmallScreen={isSmallScreen}>
      <StyledRowContainer $align={align}>
        {withSearchContent || null}
        <ListSearchInput
          loading={loading}
          toggleValue={toggleValue}
          handleSearch={handleSearch}
          tooltipTitle={inputTooltip}
          label={inputLabel}
          fullWidth={isSmallScreen}
          searchText={searchText}
          inputRef={inputRef}
          searchTextRef={searchTextRef}
          {...rest}
        />
        <StyledUpdateButton
          fitContent
          tooltipTitle={t('Updating the list')}
          onClick={() => handleSearch(searchTextRef?.current || '')}>
          {loading ? <LoadingOutlined /> : <ReloadOutlined />}
        </StyledUpdateButton>
        {withSearchEndContent || null}
      </StyledRowContainer>
      {withoutPicker ? null : (
        <ListDateRange
          tooltipTitle={dateRangeTooltip || ''}
          label={dateRangeLabel || ''}
          onChange={async (value: any) => {
            if (isFunction(handlePickRange)) {
              await handlePickRange(value as any);
            }
          }}
          key="list-range"
          loading={loading}
          fullWidth={isSmallScreen}
          defaultDateRangeValue={defaultDateRangeValue}
        />
      )}
      {isFunction(children)
        ? children({fullWidth: isSmallScreen})
        : children || null}
    </PaymentFlexContainer>
  );
}
