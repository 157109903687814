import {List, Map} from 'immutable';
import {EMPLOYEE, APP_STATE} from '../constants';
import {REDUX_STATUS, ApiError} from '../../services/types';
import {EmployeeModel, DayOffModel, ServiceModel} from '../../struture';
import {compose, isEqualByUuid, not} from '../../services/helpers';

interface IEmployeeListState {
  employee: EmployeeModel | null;
  cachedEmployeeList: Map<string, EmployeeModel>;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
}

interface SetAction extends Pick<IEmployeeListState, 'employee'> {
  type: EMPLOYEE.SET_EMPLOYEE;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface UpdateEmployee {
  type: EMPLOYEE.UPDATE_EMPLOYEE;
  employee: EmployeeModel;
}

interface UpdateEmployeeServiceList {
  type: EMPLOYEE.UPDATE_EMPLOYEE_SERVICES;
  employeeServiceList: List<ServiceModel>;
}

interface UpdateEmployeeDayOff {
  type: EMPLOYEE.UPDATE_EMPLOYEE;
  dayOff: DayOffModel;
}

interface CreateEmployeeDayOff {
  type: EMPLOYEE.UPDATE_EMPLOYEE;
  dayOff: DayOffModel;
}

interface DeleteEmployeeDayOff {
  type: EMPLOYEE.UPDATE_EMPLOYEE;
  dayOffUuid: string;
}

interface LoadingAction {
  type: EMPLOYEE.LOADING_EMPLOYEE;
}

interface ResetAction {
  type: EMPLOYEE.RESET_EMPLOYEE;
}

interface ErrorAction extends Pick<IEmployeeListState, 'error'> {
  type: EMPLOYEE.ERROR_EMPLOYEE;
}

interface Handlers {
  [EMPLOYEE.SET_EMPLOYEE]: (
    state: IEmployeeListState,
    action: SetAction,
  ) => IEmployeeListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IEmployeeListState,
    action: SetInitialStateAction,
  ) => IEmployeeListState;

  [EMPLOYEE.RESET_EMPLOYEE]: (
    state: IEmployeeListState,
    action: ResetAction,
  ) => IEmployeeListState;

  [EMPLOYEE.UPDATE_EMPLOYEE]: (
    state: IEmployeeListState,
    action: UpdateEmployee,
  ) => IEmployeeListState;

  [EMPLOYEE.UPDATE_EMPLOYEE_DAY_OFF]: (
    state: IEmployeeListState,
    action: UpdateEmployeeDayOff,
  ) => IEmployeeListState;

  [EMPLOYEE.UPDATE_EMPLOYEE_SERVICES]: (
    state: IEmployeeListState,
    action: UpdateEmployeeServiceList,
  ) => IEmployeeListState;

  [EMPLOYEE.CREATE_EMPLOYEE_DAY_OFF]: (
    state: IEmployeeListState,
    action: CreateEmployeeDayOff,
  ) => IEmployeeListState;

  [EMPLOYEE.DELETE_EMPLOYEE_DAY_OFF]: (
    state: IEmployeeListState,
    action: DeleteEmployeeDayOff,
  ) => IEmployeeListState;

  [EMPLOYEE.ERROR_EMPLOYEE]: (
    state: IEmployeeListState,
    value: ErrorAction,
  ) => IEmployeeListState;

  [EMPLOYEE.LOADING_EMPLOYEE]: (
    state: IEmployeeListState,
    value?: LoadingAction,
  ) => IEmployeeListState;
  DEFAULT: (state: IEmployeeListState) => IEmployeeListState;
}

const initState: IEmployeeListState = {
  employee: null,
  cachedEmployeeList: Map(),
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
};

const handlers: Handlers = {
  [EMPLOYEE.SET_EMPLOYEE]: (state, {employee}) => ({
    ...state,
    ...{
      employee,
      cachedEmployeeList: employee
        ? state.cachedEmployeeList.set(employee?.uuid, employee)
        : state.cachedEmployeeList,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [EMPLOYEE.RESET_EMPLOYEE]: (state) => ({
    ...state,
    ...{
      status: REDUX_STATUS.IDLE,
    },
  }),

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [EMPLOYEE.UPDATE_EMPLOYEE]: (state, {employee}) => ({
    ...state,
    ...{
      employee,
      status: REDUX_STATUS.SUCCEEDED,
      cachedEmployeeList: state.cachedEmployeeList.update(
        employee?.uuid,
        () => employee,
      ),
    },
  }),

  [EMPLOYEE.UPDATE_EMPLOYEE_SERVICES]: (state, {employeeServiceList}) => {
    const updatedEmployee = state.employee
      ? state.employee?.set('services', employeeServiceList)
      : null;

    return {
      ...state,
      ...{
        employee: updatedEmployee,
        status: REDUX_STATUS.SUCCEEDED,
        cachedEmployeeList: state.cachedEmployeeList.update(
          state.employee?.uuid!,
          () => updatedEmployee!,
        ),
      },
    };
  },

  [EMPLOYEE.UPDATE_EMPLOYEE_DAY_OFF]: (state, {dayOff}) => {
    const dayOffListModel = List.isList(state?.employee?.days_off)
      ? state?.employee?.dayOffListModel?.map((stateDayOff) => {
          if (stateDayOff.uuid === dayOff?.uuid) {
            return dayOff;
          }
          return stateDayOff;
        })
      : List([dayOff]);

    const updatedEmployee = state?.employee
      ? List.isList(dayOffListModel)
        ? state?.employee?.set('days_off', dayOffListModel)
        : state?.employee
      : null;

    return {
      ...state,
      ...{
        employee: updatedEmployee,
        cachedEmployeeList: state.cachedEmployeeList.update(
          state?.employee?.uuid as string,
          () => updatedEmployee as any,
        ),
      },
    };
  },

  [EMPLOYEE.CREATE_EMPLOYEE_DAY_OFF]: (state, {dayOff}) => {
    const dayOffListModel = List.isList(state?.employee?.days_off)
      ? state?.employee?.dayOffListModel.unshift(dayOff)
      : List([dayOff]);

    const updatedEmployee = state?.employee
      ? List.isList(dayOffListModel)
        ? state?.employee?.set('days_off', dayOffListModel)
        : state?.employee
      : null;

    return {
      ...state,
      ...{
        employee: updatedEmployee,
        cachedEmployeeList: state.cachedEmployeeList.update(
          state?.employee?.uuid as string,
          () => updatedEmployee as any,
        ),
      },
    };
  },

  [EMPLOYEE.DELETE_EMPLOYEE_DAY_OFF]: (state, {dayOffUuid}) => {
    const dayOffListModel = List.isList(state?.employee?.days_off)
      ? state?.employee?.dayOffListModel.filter(
          compose(not, isEqualByUuid(dayOffUuid)),
        )
      : state?.employee?.days_off;

    const updatedEmployee = state?.employee
      ? List.isList(dayOffListModel)
        ? state?.employee?.set('days_off', dayOffListModel)
        : state?.employee
      : null;

    return {
      ...state,
      ...{
        employee: updatedEmployee,
        cachedEmployeeList: state.cachedEmployeeList.update(
          state?.employee?.uuid as string,
          () => updatedEmployee as any,
        ),
      },
    };
  },

  [EMPLOYEE.ERROR_EMPLOYEE]: (
    state: IEmployeeListState,
    {error}: ErrorAction,
  ) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [EMPLOYEE.LOADING_EMPLOYEE]: (state: IEmployeeListState) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IEmployeeListState) => state,
};

export default function Employee(
  state: any = initState,
  action: any,
): IEmployeeListState {
  const handler = handlers[action.type as EMPLOYEE] || handlers.DEFAULT;
  return handler(state, action);
}
