import React from 'react';
import {Routes, Route} from 'react-router';
import {AppRouteLayout} from '../components/lib/Layout';
import {
  OrderListPage,
  OrderCalendarPage,
  OrderRelatedServiceListPage,
  OrderStatusListPage,
} from '../views/Orders';
import {MaterialAndExpenseListPage} from '../views/MaterialsAndExpenses';
import {Routes as RoutesType} from '../services/types';
import {CalendarRoutes} from './CalendarRoutes';

export function OrderRoutesNavigator() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AppRouteLayout>
            <OrderListPage />
          </AppRouteLayout>
        }
      />

      <Route
        path={`:orderId/${RoutesType.relatedServices}`}
        element={
          <AppRouteLayout>
            <OrderRelatedServiceListPage />
          </AppRouteLayout>
        }
      />

      <Route
        path={`:orderId/${RoutesType.relatedOrders}/*`}
        element={<OrderRoutesNavigator />}
      />

      <Route
        path={`:orderId/${RoutesType.materialsAndExpenses}`}
        element={
          <AppRouteLayout>
            <MaterialAndExpenseListPage />
          </AppRouteLayout>
        }
      />

      <Route
        path={`:orderId/${RoutesType.statuses}`}
        element={
          <AppRouteLayout>
            <OrderStatusListPage />
          </AppRouteLayout>
        }
      />

      <Route
        path={`:orderId/${RoutesType.schedules}/*`}
        element={<CalendarRoutes CalendarComponent={OrderCalendarPage} />}
      />
    </Routes>
  );
}
