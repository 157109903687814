import {List, Record} from 'immutable';
import {
  GENDER,
  MomentDate,
  verificationHelpers as vHelper,
  getFirstLatter,
  firstLetterToUppercase,
  compose,
  getFirstLatterToUpperCase,
  isDateLessThanNow,
  getDiffDaysFromNow,
  getDiffMinutesFromNow,
  getDiffHoursFromNow,
} from '../../services/helpers';
import {
  ProfileDTO,
  ClientDTO,
  CompanyDTO,
  ClientModel,
  CompanyModel,
  ProfileSubscriptionModel,
  ClientMapper,
  CompanyMapper,
  ProfileSubscriptionMapper,
  AclMapper,
  IAclDTOMap,
  IAclModelMap,
  EmployeeModel,
  EmployeeDTO,
  EmployeeMapper,
  EmployeeRole,
  ActiveSubscriptionModel,
  ActiveSubscriptionDTO,
  ActiveSubscriptionMapper,
  SubscriptionPlans,
} from '../internal';
import {WithoutRecursion} from '../type';
import {subscriptionExpiredDays} from '../../services/const';

export const PROFILE_STATUS_DISABLED = 0; // Заблокований аккаунт
export const PROFILE_STATUS_ENABLED = 1; // Акнивний статус
export const PROFILE_STATUS_DELIVER = 2; // Статус, який потребує певних дій для активації аккаунта

const dateHelpers = new MomentDate();

const firstLetter = compose(getFirstLatter, firstLetterToUppercase);

export interface IProfileModelReturnType {
  id: string;
  uuid: string;
  status: number;
  balance: number;
  first_name: string;
  last_name: string;
  checkout_url: string;
  middle_name: string;
  is_admin: boolean;
  phone: string;
  phone_approved: number;
  email: string;
  email_approved: number;
  picture_url: string;
  address: string;
  birthday: string;
  gender: GENDER;
  acl: IAclModelMap | IAclDTOMap;
  created_clients: List<ClientModel> | ClientDTO[];
  created_at: string;
  updated_at: string;
  companies: List<CompanyModel> | CompanyDTO[];
  default_company: CompanyModel | CompanyDTO;
  default_employee: EmployeeModel | EmployeeDTO;
  profile_subscriptions: List<ProfileSubscriptionModel>;

  active_subscription: ActiveSubscriptionModel | ActiveSubscriptionDTO;
}

export class ProfileModel extends Record<IProfileModelReturnType>({
  id: '0',
  uuid: '',
  status: PROFILE_STATUS_DISABLED,
  first_name: '',
  last_name: '',
  middle_name: '',
  checkout_url: '',
  phone: '',
  is_admin: false,
  phone_approved: 0,
  balance: 0,
  email: '',
  email_approved: 0,
  picture_url: '',
  address: '',
  birthday: '',
  gender: GENDER.MALE,
  created_clients: List(),
  acl: {} as IAclModelMap,
  created_at: '',
  updated_at: '',
  companies: List(),
  default_company: {} as any,
  default_employee: {} as any,
  profile_subscriptions: List(),
  active_subscription: {} as ActiveSubscriptionModel,
}) {
  private _keys: any;

  constructor(
    props: ProfileDTO = {} as ProfileDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IProfileModelReturnType = {
      ...props,

      acl: AclMapper.toAclMapModel(props?.acl || {}),
      active_subscription: ActiveSubscriptionMapper.toActiveSubscriptionModel(
        props?.active_subscription,
      ),
      created_clients:
        withoutRecursion.indexOf(WithoutRecursion.client) === -1
          ? props?.created_clients
          : ClientMapper.toClientListModel(
              props?.created_clients || [],
              0,
              false,
              [WithoutRecursion.profile, ...withoutRecursion],
            ).clients,
      companies:
        withoutRecursion.indexOf(WithoutRecursion.company) === -1
          ? props?.companies
          : CompanyMapper.toCompanyListModel(props?.companies || [], 0, [
              WithoutRecursion.profile,
              ...withoutRecursion,
            ]).companies,
      default_company:
        withoutRecursion.indexOf(WithoutRecursion.company) === -1
          ? props?.default_company
          : CompanyMapper.toCompanyModel(props?.default_company, [
              WithoutRecursion.profile,
              ...withoutRecursion,
            ]),

      default_employee:
        withoutRecursion.indexOf(WithoutRecursion.employee) === -1
          ? props?.default_employee
          : EmployeeMapper.toEmployeeModel(props?.default_employee, [
              WithoutRecursion.profile,
              ...withoutRecursion,
            ]),
      profile_subscriptions:
        ProfileSubscriptionMapper.toProfileSubscriptionListModel(
          props?.profile_subscriptions || [],
        ).profile_subscriptions,
    };

    super(options);
  }

  get createdClientListModel(): List<ClientModel> {
    return List.isList(this.created_clients)
      ? this.created_clients
      : ClientMapper.toClientListModel(this.created_clients, 0).clients;
  }

  get companyListModel(): List<CompanyModel> {
    return List.isList(this.companies)
      ? this.companies
      : CompanyMapper.toCompanyListModel(this.companies).companies;
  }

  get defaultCompanyModel(): CompanyModel {
    return this.default_company instanceof CompanyModel
      ? this.default_company
      : CompanyMapper.toCompanyModel(this.default_company);
  }

  get defaultEmployeeModel(): EmployeeModel {
    return this.default_employee instanceof EmployeeModel
      ? this.default_employee
      : EmployeeMapper.toEmployeeModel(this.default_employee);
  }

  /**
   * @desc Повне імя
   * @return {String}
   * */
  get fullName(): string {
    return `${this.last_name} ${this.first_name}`;
  }

  /**
   * @desc Перевіряємо чи активний поточний користувач
   * @return {Boolean}
   * */
  isEnabled(): boolean {
    return this.status === PROFILE_STATUS_ENABLED;
  }

  /**
   * @desc Перевіряємо чи потребується від користувача додатковиї дій
   * @return {Boolean}
   * */
  isDeliver(): boolean {
    return this.status === PROFILE_STATUS_DELIVER;
  }

  /**
   * @desc Перевіряємо чи заблокований Користувач
   * @return {Boolean}
   * */
  isDisabled(): boolean {
    return this.status === PROFILE_STATUS_DISABLED;
  }

  /**
   * @desc Перевіряє, якщо поле телефона, було верифіковано
   * @return {Boolean}
   * */
  isPhoneVerified(): boolean {
    return vHelper.isVerified(this.phone_approved);
  }

  /**
   * @desc Перевіряє, якщо поле email, було верифіковано
   * @return {Boolean}
   * */
  isEmailVerified(): boolean {
    return vHelper.isVerified(this.email_approved as any);
  }

  /**
   * @desc Повертає лише встановлені значення для властивостей
   * @return {JSON}
   * */
  getDefinedPropertiesJSON(): any {
    const result: any = {};

    this._keys.forEach((key: any) => {
      if (this.get(key)) {
        result[key] = this.get(key);
      }
    });

    return result;
  }

  /**
   * @desc Отримати день дня народження
   * @return {Number}
   * */
  get dayOfBirthday(): string {
    return dateHelpers.splitDateOnParts(this.birthday).day;
  }

  /**
   * @desc Отримати місяць дня народження
   * @return {Number}
   * */
  get monthOfBirthday(): string {
    return dateHelpers.splitDateOnParts(this.birthday).month;
  }

  /**
   * @desc Отримати рік дня народження
   * @return {Number}
   * */
  get yearOfBirthday(): string {
    return dateHelpers.splitDateOnParts(this.birthday).year;
  }

  get isPhoneApproved(): boolean {
    return this.phone_approved > 0;
  }

  get isEmailApproved(): boolean {
    return (this.email_approved as any) > 0;
  }

  get isAdminProfile(): boolean {
    return this.is_admin;
  }

  get shortFullName(): string {
    return `${firstLetterToUppercase(
      this.last_name,
    )} ${getFirstLatterToUpperCase(this.first_name)}. ${
      this.middle_name ? `${getFirstLatterToUpperCase(this.middle_name)}.` : ''
    }`;
  }

  get isFullName(): boolean {
    return !!this.first_name && !!this.last_name;
  }
  get isTrialSubscription(): boolean {
    return this.active_subscription.name === SubscriptionPlans.Trial;
  }

  get isEmptySubscription(): boolean {
    return this.active_subscription.name === SubscriptionPlans.Empty;
  }

  get isSubscriptionDateExpired(): boolean {
    return (
      this.active_subscription.expired_at === '' ||
      isDateLessThanNow(this.active_subscription.expired_at)
    );
  }

  get isSubscriptionExpired(): boolean {
    return this.isEmptySubscription || this.isSubscriptionDateExpired;
  }

  get subscriptionDiffDays(): number {
    return getDiffDaysFromNow(this.active_subscription.expired_at);
  }

  get subscriptionDiffHours(): number {
    return getDiffHoursFromNow(this.active_subscription.expired_at);
  }

  get subscriptionDiffMinutes(): number {
    return getDiffMinutesFromNow(this.active_subscription.expired_at);
  }

  get isShowSubscriptionExpiredTime(): boolean {
    return (
      !this.isSubscriptionExpired &&
      this.subscriptionDiffDays <= Number(subscriptionExpiredDays) &&
      this.subscriptionDiffMinutes >= 0
    );
  }

  get subscriptionExpiredAt(): (t: any) => string {
    const days = this.subscriptionDiffDays;
    const hours = this.subscriptionDiffHours;
    const minutes = this.subscriptionDiffMinutes;

    return (t) =>
      days > 0
        ? `${days} ${
            days === 1 ? t('day').toLowerCase() : t('days').toLowerCase()
          }`
        : hours > 0
        ? `${hours} ${
            hours === 1 ? t('hour').toLowerCase() : t('hours').toLowerCase()
          }`
        : minutes > 0
        ? `${minutes} ${
            minutes === 1
              ? t('minute').toLowerCase()
              : t('minutes').toLowerCase()
          }`
        : `${t('now').toLowerCase()}`;
  }

  get isShowSubscriptionWarning(): boolean {
    return (
      this.isTrialSubscription ||
      this.isSubscriptionExpired ||
      this.isShowSubscriptionExpiredTime
    );
  }

  get isAdmin(): boolean {
    return this.defaultEmployeeModel?.role === EmployeeRole.Administrator;
  }
}
