import {
  InvoiceItemPositionableType,
  InvoiceItemModel,
  StoreDocumentCurrency,
  IServiceDTOProps,
  IProductDTOProps,
  ServiceModel,
  ProductModel,
  ExpenseModel,
  StoreDocumentItemModel,
  IExpenseDTOProps,
  IStoreDocumentItemDTOProps,
} from '../internal';
import {immerable} from 'immer';
import {correctPrice} from '../../services/helpers';

export interface InvoiceItemFormAdditionalParams {
  editMode?: boolean;
  // item_positionable_uuid?: string;
  withoutPartialFields?: boolean;
  withoutUuid?: boolean;
}

export class InvoiceItemFormDTO {
  [immerable]? = true;
  public uuid?: string;
  public item_title: string;
  public item_comment: string;
  public item_positionable_uuid: string;
  public item_currency: StoreDocumentCurrency | string;
  public item_base_price: string;
  public item_price: string;
  public item_amount: number;
  public item_positionable_type: InvoiceItemPositionableType;
  public item_positionable_object?:
    | ServiceModel
    | ProductModel
    | ExpenseModel
    | StoreDocumentItemModel;
  public editMode?: boolean;

  constructor(
    props?: InvoiceItemModel,
    {
      editMode = false,
      // item_positionable_uuid,
      withoutPartialFields = false,
      withoutUuid = false,
    }: InvoiceItemFormAdditionalParams = {},
  ) {
    if (!withoutUuid) {
      this.uuid = props?.uuid || '';
    }

    this.item_title = props?.item_title || '';
    this.item_comment = props?.item_comment || '';
    this.item_positionable_uuid =
      // item_positionable_uuid ||
      (props?.item_positionable_object as any)?.uuid || '';
    this.item_currency = props?.item_currency || '';
    this.item_base_price = correctPrice(props?.item_base_price || 0);
    this.item_price = correctPrice(props?.item_price || 0);
    this.item_amount = props?.item_amount || 1;
    this.item_positionable_type =
      props?.item_positionable_type ||
      InvoiceItemFormDTO.toItemPositionableObjectTypeFactory(
        props?.item_positionable_object as any,
      );

    if (!withoutPartialFields) {
      this.item_positionable_object = props?.item_positionable_object;
      this.editMode = editMode;
    }
  }

  private static toItemPositionableObjectTypeFactory(
    item_positionable_object:
      | IServiceDTOProps
      | IProductDTOProps
      | IExpenseDTOProps
      | IStoreDocumentItemDTOProps,
  ): InvoiceItemPositionableType {
    if (item_positionable_object instanceof ServiceModel) {
      return InvoiceItemPositionableType.SERVICE;
    }

    if (item_positionable_object instanceof ProductModel) {
      return InvoiceItemPositionableType.PRODUCT;
    }

    if (item_positionable_object instanceof ExpenseModel) {
      return InvoiceItemPositionableType.ORDER;
    }

    return InvoiceItemPositionableType.STORE;
  }
}
