import {Record, List} from 'immutable';
import {ListModel, ListProps} from '../helpers/index';
import User, {IUserProps} from './User';

export interface IUserListProps extends ListProps {
  users: IUserProps[];
}

export default class UserList extends Record<IUserListProps>({
  users: List(),
  ...ListModel.toJS(),
} as any) {
  /**
   * @constructor
   * @param {{clients:[]}} param0
   */
  constructor({users = [], ...props}: IUserListProps) {
    const options: any = {};

    if (Array.isArray(users)) {
      options.users = List(users.map((user) => new User(user)));
    }

    super({
      ...props,
      ...options,
    });
  }
}
