import {List} from 'immutable';
import {DEPARTMENT_LIST} from '../constants';
import {compose, isEqualByUuid, not} from '../../helpers';
import {ApiError, REDUX_STATUS, APP_STATE} from '../types';
import {
  DepartmentListModel,
  DepartmentMapper,
  DepartmentModel,
} from '../../struture';

interface IDepartmentListState {
  departmentList: DepartmentListModel | null;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
}

interface SetActionList {
  type: DEPARTMENT_LIST.SET_DEPARTMENT_LIST;
  departmentList: DepartmentListModel;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface AddActionToList {
  type: DEPARTMENT_LIST.ADD_DEPARTMENT;
  department: DepartmentModel;
}

interface UpdateDepartmentFromList {
  type: DEPARTMENT_LIST.UPDATE_DEPARTMENT;
  department: DepartmentModel;
}

interface DeleteActionFromList {
  type: DEPARTMENT_LIST.DELETE_DEPARTMENT;
  departmentUuid: string;
}

interface LoadingActionInList {
  type: DEPARTMENT_LIST.LOADING_DEPARTMENT_LIST;
}

interface ErrorActionInList extends Pick<IDepartmentListState, 'error'> {
  type: DEPARTMENT_LIST.ERROR_DEPARTMENT_LIST;
}

interface Handlers {
  [DEPARTMENT_LIST.SET_DEPARTMENT_LIST]: (
    state: IDepartmentListState,
    action: SetActionList,
  ) => IDepartmentListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IDepartmentListState,
    action: SetInitialStateAction,
  ) => IDepartmentListState;

  [DEPARTMENT_LIST.LOAD_MORE_DEPARTMENT_LIST]: (
    state: IDepartmentListState,
    action: SetActionList,
  ) => IDepartmentListState;

  [DEPARTMENT_LIST.ADD_DEPARTMENT]: (
    state: IDepartmentListState,
    action: AddActionToList,
  ) => IDepartmentListState;

  [DEPARTMENT_LIST.UPDATE_DEPARTMENT]: (
    state: IDepartmentListState,
    action: UpdateDepartmentFromList,
  ) => IDepartmentListState;

  [DEPARTMENT_LIST.DELETE_DEPARTMENT]: (
    state: IDepartmentListState,
    action: DeleteActionFromList,
  ) => IDepartmentListState;

  [DEPARTMENT_LIST.ERROR_DEPARTMENT_LIST]: (
    state: IDepartmentListState,
    value: ErrorActionInList,
  ) => IDepartmentListState;

  [DEPARTMENT_LIST.LOADING_DEPARTMENT_LIST]: (
    state: IDepartmentListState,
    value?: LoadingActionInList,
  ) => IDepartmentListState;
  DEFAULT: (state: IDepartmentListState) => IDepartmentListState;
}

const initState: IDepartmentListState = {
  departmentList: null,
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
};

const handlers: Handlers = {
  [DEPARTMENT_LIST.SET_DEPARTMENT_LIST]: (state, {departmentList}) => {
    return {
      ...state,
      ...{
        departmentList: departmentList || null,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [DEPARTMENT_LIST.LOAD_MORE_DEPARTMENT_LIST]: (state, {departmentList}) => {
    return {
      ...state,
      ...{
        departmentList:
          List.isList(state.departmentList?.departments) &&
          List.isList(departmentList?.departments)
            ? state.departmentList.update('departments', (departments) =>
                departments.merge(departmentList.departments),
              )
            : state.departmentList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [DEPARTMENT_LIST.ADD_DEPARTMENT]: (state, {department}) => {
    return {
      ...state,
      ...{
        departmentList: List.isList(state.departmentList?.departments)
          ? state.departmentList
              .update('departments', (d) => d.unshift(department))
              .update('total', (t = 0) => t + 1)
          : DepartmentMapper.toDepartmentListModel(
              [DepartmentMapper.toDepartmentDTO(department)],
              1,
            ),
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [DEPARTMENT_LIST.UPDATE_DEPARTMENT]: (
    state: IDepartmentListState,
    {department}: UpdateDepartmentFromList,
  ) => {
    return {
      ...state,
      ...{
        departmentList: List.isList(state.departmentList?.departments)
          ? state.departmentList.update('departments', (d) =>
              d.map((stateDepartment) => {
                if (stateDepartment.uuid === department?.uuid) {
                  return stateDepartment.merge(department);
                }
                return stateDepartment;
              }),
            )
          : DepartmentMapper.toDepartmentListModel(
              [DepartmentMapper.toDepartmentDTO(department)],
              1,
            ),
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [DEPARTMENT_LIST.DELETE_DEPARTMENT]: (state, {departmentUuid}) => {
    return {
      ...state,
      ...{
        departmentList: List.isList(state.departmentList?.departments)
          ? state.departmentList
              .update('departments', (d) =>
                d.filter(compose(not, isEqualByUuid(departmentUuid))),
              )
              .update('total', (t = 0) => t - 1)
          : null,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [DEPARTMENT_LIST.ERROR_DEPARTMENT_LIST]: (
    state: IDepartmentListState,
    {error}: ErrorActionInList,
  ) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [DEPARTMENT_LIST.LOADING_DEPARTMENT_LIST]: (state: IDepartmentListState) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IDepartmentListState) => state,
};

export default function DepartmentList(
  state: any = initState,
  action: any,
): IDepartmentListState {
  const handler = handlers[action.type as DEPARTMENT_LIST] || handlers.DEFAULT;
  return handler(state, action);
}
