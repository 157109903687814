import React, {
  forwardRef,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import {useTranslation} from 'react-i18next';
import {isFunction} from '../../../services/helpers';
import {Table, ITableProps} from '../libV2/DataDisplay';
import styled from 'styled-components';

export interface IFormTableProps<T>
  extends Omit<ITableProps<T>, 'total' | 'pageSize' | 'page'> {
  keyExtractor?: (item: T) => {};
  value?: string[];
  handleChange?: (value: string[]) => void;
  total?: number;
  pageSize?: number;
  page?: number;
  refresh: any;
}

const StyledTable: any = styled(Table)`
  margin-bottom: 0;
`;

export const FormTable = forwardRef(function FormTable<T>(
  {
    value,
    handleChange,
    keyExtractor,
    dataSource,
    columns,
    total,
    page,
    pageSize,
    refresh,
    ...rest
  }: IFormTableProps<T>,
  ref: any,
): JSX.Element {
  const {t} = useTranslation();

  const [selectedEntitiesUuid, setSelectedEntitiesUuid] = useState<string[]>(
    [],
  );

  const handleOnChange = useCallback(
    (value: string[]) => {
      setSelectedEntitiesUuid(value);

      if (isFunction(handleChange)) {
        handleChange(value);
      }
    },
    [handleChange],
  );

  useEffect(() => {
    if (value && !selectedEntitiesUuid.length) {
      setSelectedEntitiesUuid(value);
    }
  }, [selectedEntitiesUuid.length, value]);

  const rowSelection = useMemo(
    () => ({
      hideSelectAll: true,
      selectedRowKeys: value,
      onSelect: (
        record: any,
        selected: boolean,
        selectedRowKeys: React.Key[],
      ) => {
        if (selected) {
          const selectedNewEntities = selectedRowKeys
            .filter(Boolean)
            .map((keyExtractor || (({uuid}: any) => uuid)) as any);

          const selectedEntities = new Set([
            ...selectedEntitiesUuid,
            ...selectedNewEntities,
          ]);

          handleOnChange(Array.from(selectedEntities) as any);
        } else {
          const id = [record].map(
            (keyExtractor || (({uuid}: any) => uuid)) as any,
          )[0];

          const selectedNewEntities = selectedRowKeys
            .filter(Boolean)
            .map((keyExtractor || (({uuid}: any) => uuid)) as any);

          const filteredEntities = new Set(
            [...selectedEntitiesUuid, ...selectedNewEntities].filter(
              (uuid) => uuid !== id,
            ),
          );

          handleOnChange(Array.from(filteredEntities) as any);
        }
      },
    }),
    [handleOnChange, keyExtractor, selectedEntitiesUuid, value],
  );

  return (
    <StyledTable<T>
      {...rest}
      rowSelection={rowSelection as any}
      total={total || (Array.isArray(dataSource) ? dataSource?.length : 0)}
      pageSize={pageSize || Infinity}
      dataSource={dataSource}
      columns={columns}
      page={page || 1}
      onChange={refresh}
    />
  );
});
