import * as React from 'react';
import {List} from 'immutable';
import {ApiError, IEntityProps, IListSearchProps} from '../services/types';
import {
  IReportListDTO,
  ReportMapper,
  ReportModel,
  ReportListModel,
} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {getReportList} from '../services/api/report';
import {useDropdownAlert} from '../contex';
import {useTranslation} from 'react-i18next';

export interface IUseReportListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  companyUuid: string;
  startDate?: string;
  endDate?: string;
  isClearDate?: boolean;
}

export interface IUseReportListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<ReportModel> | null;
  refresh: (
    value: Partial<IUseReportListProps>,
  ) => Promise<ReportListModel | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  companyUuid: string;
  startDate: string;
  endDate: string;
}

export function useReportList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    companyUuid,
    startDate = '',
    endDate = '',
  }: IUseReportListProps = {} as IUseReportListProps,
): IUseReportListReturnType {
  const {cancellablePromise, didCancel} = useCancellablePromise();
  const {alert} = useDropdownAlert();
  const {t} = useTranslation();

  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    companyUuid: listUuid,
    handleUpdate,
    startDate: listStartDate,
    endDate: listEndDate,
  } = useStateReducer<Omit<IUseReportListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    companyUuid,
    startDate,
    endDate,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      companyUuid = listUuid,
      startDate = listStartDate,
      endDate = listEndDate,
    }: Partial<IUseReportListProps> = {}): Promise<ReportListModel | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          companyUuid,
          startDate,
          endDate,
        });

        const {reports, total} = await cancellablePromise<IReportListDTO>(
          getReportList({
            limit,
            offset,
            keywords,
            companyUuid,
            startDate,
            endDate,
          }),
        );

        const reportListModel = ReportMapper.toReportListModel(reports, total);

        handleUpdate({
          entityList: reportListModel?.reports,
          total: reportListModel?.total,
          loading: false,
        });

        return reportListModel;
      } catch (e: any) {
        alert(
          'error',
          t('Reports'),
          `${t('An error occurred during get report list')} : ${e?.message}`,
        );

        handleUpdate({
          error,
          loading: false,
        });
      }
    },
    [
      alert,
      cancellablePromise,
      error,
      handleUpdate,
      listEndDate,
      listKeywords,
      listLimit,
      listOffset,
      listStartDate,
      listUuid,
      t,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && companyUuid) {
      (async () => {
        await refresh({companyUuid});
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, companyUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    companyUuid: listUuid,
    startDate: listStartDate,
    endDate: listEndDate,
  };
}
