import React from 'react';
import styled, {css} from 'styled-components';
import {COLOR_GREEN} from '../../../services/helpers';

export interface IServiceColorSchemaBoxProps {
  children: React.ReactNode;
  colorSchema: string;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
`;

const StyledBox = styled.div<{colorSchema: string}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 100%;
  background-color: ${COLOR_GREEN};
  margin-right: 5px;

  ${({colorSchema}) =>
    colorSchema &&
    css`
      background-color: ${colorSchema};
    `}
`;

export default function ServiceColorSchemaBox({
  colorSchema,
  children,
}: IServiceColorSchemaBoxProps): JSX.Element {
  return (
    <StyledContainer>
      <StyledBox colorSchema={colorSchema} />
      {children}
    </StyledContainer>
  );
}
