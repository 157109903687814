import {Empty} from 'antd';
import {OrderList} from '../List';
import {useTranslation} from 'react-i18next';
import {PlusOutlined} from '@ant-design/icons';
import styled, {useTheme} from 'styled-components';
import {ScheduleCalendarModel} from '../../../struture';
import {useAcl, useDropdownAlert} from '../../../contex';
import {ListLayout} from '../../../components/lib/Layout';
import {OrderStateView, ScheduleBottomView} from '../Show';
import React, {useCallback, useMemo, useState} from 'react';
import {CreateOrderButton, OrderFilterButton} from '../Buttons';
import {useLocation, useNavigate, useParams} from 'react-router';
import {successButtonStyle} from '../../../components/lib/Styled';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import useStoredCompanies from '../../../hooks/useStoredCompanies';
import {ORDER_SCHEDULE_STATUS} from '../../../services/api/orders';
import {GetCompanyScheduleState} from '../../../services/api/company';
import {OrderListFilterManager, useOrderListFilter} from '../Managers';
import {StyledDetailsView} from '../../../components/lib/Styled/DetailsView';
import {len, lessOrEqualThan, textToUpperCase} from '../../../services/helpers';

import {
  useKeyboardOpenForm,
  useOrderListPageActions,
  useSearchInputFocus,
  useStopLoading,
  useStoredStatusCategoryList,
  useStoredStatusList,
} from '../../../hooks';

import {
  HeaderLeft,
  ListActions,
  ListHeader,
} from '../../../components/lib/DataDisplay';

import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '../../../services/types';

export interface IRelatedOrderProps {
  scheduleNumber: string;
  scheduleDate: string;
}

const StyledCreateOrderButton = styled(CreateOrderButton)`
  ${successButtonStyle}
`;

export function OrderListContent() {
  const navigate = useNavigate();
  const location = useLocation();
  const {orderId} = useParams();
  const {t} = useTranslation();
  const {defaultCompanyUuid} = useStoredCompanies();
  const {order: orderAccess} = useAcl((acl) => acl);

  const {alert} = useDropdownAlert();
  const theme: any = useTheme();

  const {ref: listRef} = useKeyboardOpenForm({
    className: 'order-create-event',
    disabled: !orderAccess?.manage,
  });

  const [selectedSchedule, setSelectedSchedule] = useState<
    ScheduleCalendarModel[]
  >([]);
  const [state, setState] = useState<GetCompanyScheduleState>(
    location?.state?.type || GetCompanyScheduleState.ALL,
  );
  const [stateLoading, setStateLoading] = useState(false);

  const {
    loading: filtersLoading,
    date_start,
    date_end,
    client_uuid,
    manager_uuid,
    location_uuid,
    status_uuid,
  } = useOrderListFilter();

  const {
    schedules,
    loading: schedulesLoading,
    error: schedulesError,
    limit,
    total,
    refresh,
    parent,
    stats,

    handleDelete: onDelete,
    handleSearch,
    handleUpdate,
    handleCreate,
    handleUpdateScheduleState,
  } = useOrderListPageActions({
    loadOnInit: !filtersLoading,
    date_start,
    date_end,
    client_uuid,
    manager_uuid,
    location_uuid,
    status_uuid,
  });

  const {
    categoryList,
    loading: categoryListLoading,
    error: categoryListError,
  } = useStoredStatusCategoryList();

  const {
    statusList,
    loading: statusListLoading,
    error: statusListError,
  } = useStoredStatusList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
    allowedStatusesTo: [],
  });

  const loading = useStopLoading({
    loading:
      schedulesLoading ||
      stateLoading ||
      categoryListLoading ||
      statusListLoading,
    error: schedulesError || categoryListError || statusListError,
    message: orderId
      ? 'An error occurred during related orders loading'
      : 'An error occurred during orders loading',
  });

  const {inputRef, focus, daleyFocus} = useSearchInputFocus({loading});

  const handleDelete = useCallback(
    async (value: string[]) => {
      await onDelete(value);
      focus();
    },
    [focus, onDelete],
  );

  const rowSelection = useMemo(
    () => ({
      hideSelectAll: true,
      selectedRowKeys: selectedSchedule.map(({uuid}) => uuid),
      onChange: async (
        selectedRowKeys: React.Key[],
        selectedRecords: ScheduleCalendarModel[],
      ): Promise<void> => {
        if (lessOrEqualThan(len(selectedRecords), 1)) {
          setSelectedSchedule(selectedRecords);
        } else {
          alert('error', t('Delete'), t('You can delete up to 1 at a time'));
        }
      },
    }),
    [alert, selectedSchedule, t],
  );

  const routes = useBreadcrumb(
    orderId
      ? [
          {
            path: `/${Routes.orders}`,
            breadcrumbName: 'Orders',
          },
          {
            path: `/${Routes.orders}/${orderId}/${Routes.schedules}/calendar/entry/${orderId}`,
            breadcrumbName: `${
              location?.state?.relatedOrder?.scheduleNumber
                ? `#${location?.state?.relatedOrder?.scheduleNumber}`
                : ''
            }`,
          },
          {
            path: `/${Routes.orders}/${orderId}/${Routes.relatedOrders}`,
            breadcrumbName: 'Related orders',
          },
        ]
      : [
          {
            path: `${Routes.app}/${Routes.schedules}`,
            breadcrumbName: 'Orders',
          },
        ],
  );

  const onClientScheduleStateChange = useCallback(
    async (state: GetCompanyScheduleState) => {
      if (defaultCompanyUuid) {
        setStateLoading(true);
        setState(state);
        window.history.replaceState({}, document.title);
        await handleSearch({
          state,
        });
        setStateLoading(false);
      }
    },
    [defaultCompanyUuid, handleSearch],
  );

  const navigateGoBack = useCallback(() => {
    navigate(
      location?.state?.from ||
        `/${Routes.app}/${Routes.companies}/${defaultCompanyUuid}`,
    );
  }, [defaultCompanyUuid, location?.state?.from, navigate]);

  const actions = {
    companyUuid: defaultCompanyUuid,
    handleUpdate,
    handleDelete,
  };

  return (
    <ListLayout
      ref={listRef}
      aclItem={RoutesAcl[Routes.orders]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(
        orderId
          ? t(RoutesTitle[RoutesType.relatedOrders])
          : t(RoutesTitle[RoutesType.orders]),
      )}
      headerRight={
        <StyledCreateOrderButton
          relatedOrder={parent}
          disabled={!orderAccess?.manage}
          companyUuid={defaultCompanyUuid}
          loading={loading}
          onSuccess={handleCreate}
          onCancel={daleyFocus}
          className="order-create-event"
          type="primary"
          title={t('Create')}
          icon={<PlusOutlined />}
        />
      }
      routes={routes}
      empty={
        schedules?.size ? null : (
          <Empty
            description={t(
              `It looks like you don't have any order at the moment`,
            )}>
            <CreateOrderButton
              relatedOrder={parent}
              type="primary"
              disabled={!orderAccess?.manage}
              companyUuid={defaultCompanyUuid}
              loading={loading}
              onSuccess={handleCreate}
              title={t('Create company order')}
              onCancel={daleyFocus}
            />
          </Empty>
        )
      }
      headerExtra={
        <ListHeader>
          <StyledDetailsView
            titles={[]}
            selected={[
              state === GetCompanyScheduleState.ALL,
              state === GetCompanyScheduleState.UNPAID,
              state === GetCompanyScheduleState.OVERDUE,
              state === GetCompanyScheduleState.CANCELLED,
            ]}>
            <OrderStateView
              color={theme.tabs.link}
              type={GetCompanyScheduleState.ALL}
              title="All"
              stats={stats}
              onClick={onClientScheduleStateChange}
              disabled={loading}
            />
            <OrderStateView
              color={theme.tabs.warning}
              type={GetCompanyScheduleState.UNPAID}
              title="Waiting for payment"
              stats={stats}
              onClick={onClientScheduleStateChange}
              disabled={loading}
            />
            <OrderStateView
              color={theme.tabs.primary}
              type={GetCompanyScheduleState.OVERDUE}
              title="Overdue"
              stats={stats}
              onClick={onClientScheduleStateChange}
              disabled={loading}
            />
            <OrderStateView
              color={theme.tabs.error}
              type={GetCompanyScheduleState.CANCELLED}
              title="Cancelled"
              stats={stats}
              onClick={onClientScheduleStateChange}
              disabled={loading}
            />
          </StyledDetailsView>

          <ListActions
            withoutPicker
            inputRef={inputRef}
            loading={loading}
            handleSearch={(keywords) => handleSearch({keywords})}
            inputTooltip={t('Search company order')}
            inputLabel={t('Search company order')}
            align="center"
            withSearchContent={null}
            withSearchEndContent={
              orderId ? null : (
                <OrderFilterButton loading={loading} onFilter={handleSearch} />
              )
            }
          />
        </ListHeader>
      }
      outsideFooterContent={
        schedules ? <ScheduleBottomView stats={stats} state={state} /> : null
      }
      loading={loading && !schedules?.size}>
      <OrderList
        withoutActions={parent?.status_text === ORDER_SCHEDULE_STATUS.FINISHED}
        schedules={schedules}
        rowSelection={rowSelection}
        loading={loading}
        limit={limit}
        total={total}
        onRefresh={refresh}
        daleyFocus={daleyFocus}
        categoryList={categoryList}
        statusList={statusList}
        handleUpdateScheduleState={handleUpdateScheduleState}
        {...actions}
      />
    </ListLayout>
  );
}

export default function OrderListPage() {
  return (
    <OrderListFilterManager>
      <OrderListContent />
    </OrderListFilterManager>
  );
}
