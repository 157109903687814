import React, {useState, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {IConfirmActionButtonProps} from '../../../components/lib/General';
import {TextFormat} from '../../../components/lib/Format';
import {isFunction} from '../../../services/helpers';
import {EmployeeSalaryModel} from '../../../struture';
import {DeleteButton} from '../../../components/lib/DataDisplay';

export interface IRewardSalaryDeleteButtonProps
  extends Omit<IConfirmActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: EmployeeSalaryModel) => Promise<void>;
  onError?: () => void;
  salary: EmployeeSalaryModel;
  title?: string;
}

export function RewardSalaryDeleteButton({
  children,
  salary,
  onSuccess,
  onError,
  title,
  ...rest
}: IRewardSalaryDeleteButtonProps) {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleDelete = useCallback(async (): Promise<void> => {
    setLoading(true);
    try {
      if (isFunction(onSuccess)) {
        await onSuccess(salary);
      }

      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  }, [onSuccess, salary]);

  const {reportNS, tReady, defaultNS, i18nOptions, ...restProps}: any = rest;
  return (
    <DeleteButton
      title={title}
      tooltipTitle={t('Delete salary')}
      confirmTitle={
        <TextFormat breakWord>
          {t('Are you sure you want to delete ')} {t('salary')}?
        </TextFormat>
      }
      onConfirm={handleDelete}
      loading={loading}
      cancelButtonText={t('Cancel')}
      {...rest}
    />
  );
}
