import React, {MutableRefObject, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {
  DefaultForm,
  IDefaultFormProps,
} from '../../../../components/lib/General';
import {StoreDocumentFormDTO} from '../../../../struture';
import {useDropdownAlert} from '../../../../contex';
import {useStoredCompanies, useStoredStoreList} from '../../../../hooks';
import {Form} from 'antd';
import {StoreMovingFields} from './StoreMovingFields';
import {useTheme} from 'styled-components';
import {isFunction} from '../../../../services/helpers';
import {produce} from 'immer';
import {StoreDocumentRefObject} from '../../helpers';

export interface IStoreMovingFormProps
  extends Omit<
    IDefaultFormProps<StoreDocumentFormDTO, StoreDocumentFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  document: StoreDocumentFormDTO;
  fromList: boolean;
  documentEditRef?: MutableRefObject<StoreDocumentRefObject>;
}

export function StoreMovingForm({
  loading,
  document,
  editMode,
  onSuccess,
  fromList,
  documentEditRef,
  ...rest
}: IStoreMovingFormProps): React.JSX.Element {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const {defaultCompanyUuid} = useStoredCompanies();
  const theme: any = useTheme();
  const [instance] = Form.useForm();

  const additionalValues = useMemo(
    () => ({
      uuid: document?.uuid,
    }),
    [document?.uuid],
  );

  const {
    storeList,
    loading: storeListLoading,
    handleSearchStores,
  } = useStoredStoreList({companyUuid: defaultCompanyUuid, limit: 50});

  const notifyError = useCallback(
    (ApiError: any) => {
      alert(
        'error',
        t('Store'),
        `${
          editMode
            ? t('An error occurred during edit store moving')
            : t('An error occurred during create store moving')
        } : ${ApiError?.message}`,
      );
    },
    [alert, editMode, t],
  );

  const handleOnSuccess = useCallback(
    async (value: StoreDocumentFormDTO) => {
      if (isFunction(onSuccess)) {
        const updatedValue = produce(value, (draft) => {
          draft.doc_extra_services = undefined;
          draft.doc_multi_currencies = undefined;
        });

        await onSuccess(updatedValue);
      }
    },
    [onSuccess],
  );

  return (
    <DefaultForm<StoreDocumentFormDTO, StoreDocumentFormDTO>
      formKeyboardCodes={['Tab']}
      withContext
      instance={instance}
      initialValues={document}
      onSuccess={handleOnSuccess}
      additionalValuesRequest={additionalValues}
      editMode={editMode}
      notifyError={notifyError}
      showNotify={false}
      showCancelButton={false}
      submitButtonText={t('Save as draft')}
      submitButtonProps={{style: {backgroundColor: theme.colors.primary}}}
      {...rest}>
      <StoreMovingFields
        editMode={!!editMode}
        loading={!!loading}
        storeList={storeList}
        storeListLoading={storeListLoading}
        handleSearchStores={handleSearchStores}
        documentEditRef={documentEditRef}
      />
    </DefaultForm>
  );
}
