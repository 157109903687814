import {Record, List} from 'immutable';
import FeedBack, {IFeedBackProps} from './FeedBack';

export interface IFeedBackListProps {
  items: IFeedBackProps[];
}

/**
 * @class FeedBackList
 * @classdesc FeedBackList list model.
 * @extends {Record}
 * @property {List} items - list of feedback
 */
export default class FeedBackList extends Record({
  items: List(),
}) {
  /**
   * @constructor
   * @param {{items:[]}} param0
   */
  constructor({items = [], ...props}: IFeedBackListProps) {
    super({
      items: List(items.map((feedback) => new FeedBack(feedback))),
      ...props,
    });
  }
}
