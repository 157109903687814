import React, {useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {COMPANY_REQUISITE_INITIAL_DATA} from '../../../services/api/company';
import {DefaultForm, IDefaultFormProps} from '../../../components/lib/General';
import {CompanyRequisiteDTO} from '../../../struture';
import {Col, Form, Input, InputNumber, Row} from 'antd';
import {FormSwitch} from '../../../components/lib/DataDisplay';
import {useSize} from '../../../hooks';
import {phoneScreen} from '../../../services/const';
import styled from 'styled-components';

export interface ICompanyRequisiteFormProps
  extends Omit<
    IDefaultFormProps<CompanyRequisiteDTO, CompanyRequisiteDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  requisite?: CompanyRequisiteDTO;
}

const FullWidthInputNumber = styled(InputNumber)`
  width: 100%;
`;
export function CompanyRequisiteForm({
  loading,
  requisite = COMPANY_REQUISITE_INITIAL_DATA,
  editMode,
  ...rest
}: ICompanyRequisiteFormProps): React.JSX.Element {
  const {t} = useTranslation();

  const contentRef = useRef(null);
  const {width: contentWidth} = useSize(contentRef);
  const isFullWidth = contentWidth <= phoneScreen ? 24 : 12;

  const additionalValues = useMemo(
    () => ({
      uuid: requisite?.uuid,
    }),
    [requisite?.uuid],
  );

  return (
    <DefaultForm<CompanyRequisiteDTO, CompanyRequisiteDTO>
      initialValues={requisite}
      additionalValuesRequest={additionalValues}
      editMode={editMode}
      showNotify={false}
      {...rest}>
      {({loadingSubmit, getFieldValue, setFieldsValue}) => (
        <Row gutter={20} ref={contentRef}>
          <Col span={24}>
            <Form.Item
              tooltip={t('Title')}
              label={t('Title')}
              name="title"
              rules={[
                {
                  required: true,
                  message: t('Title must be specified.'),
                },
              ]}>
              <Input
                disabled={loadingSubmit}
                placeholder={t('Enter the title')}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              tooltip={t('EDRPOU')}
              label={t('EDRPOU')}
              name="code"
              rules={[
                {
                  required: true,
                  message: t('EDRPOU must be specified.'),
                },
              ]}>
              <Input
                disabled={loading || loadingSubmit}
                placeholder={t('Enter an EDRPOU')}
              />
            </Form.Item>
          </Col>

          <Col span={isFullWidth}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('Account number must be specified.'),
                },
              ]}
              tooltip={t('Account number of the organization')}
              label={t('Account number')}
              name="account_number">
              <Input
                disabled={loadingSubmit}
                placeholder={t('Enter an account number')}
              />
            </Form.Item>
          </Col>

          <Col span={isFullWidth}>
            <Form.Item
              tooltip={t('Name of bank')}
              label={t('Name of bank')}
              name="account_bank">
              <Input
                disabled={loadingSubmit}
                placeholder={t('Enter a name of bank')}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label={t('Note')} name="comment">
              <Input.TextArea
                allowClear
                autoSize={{minRows: 3, maxRows: 5}}
                disabled={loading || loadingSubmit}
                placeholder={t('Enter your note')}
              />
            </Form.Item>
          </Col>

          <FormSwitch
            loading={loadingSubmit || loading}
            disabled={loadingSubmit}
            name="enabled"
            label={null}
            title={t('Enable')}
            getFieldValue={getFieldValue}
            setFieldsValue={setFieldsValue}
          />
        </Row>
      )}
    </DefaultForm>
  );
}
