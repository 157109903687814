import * as React from 'react';
import {List} from 'immutable';
import moment from 'moment';
import {useEffect} from 'react';
import {
  useRelatedServiceList,
  IUseRelatedServiceListProps,
  IUseRelatedServiceListReturnType,
} from './useRelatedServiceList';
import {
  RelatedServiceModel,
  RelatedServiceFormDTO,
  RelatedServiceMapper,
} from '../struture';
import {useStateEntityList} from '../components/lib/libV2/hooks';
import {
  createRelatedService,
  updateRelatedService,
  deleteRelatedServices,
} from '../services/api/relatedService';

export interface IUseStateRelatedServiceListProps
  extends IUseRelatedServiceListProps {
  handleUpdateScheduleTotalPrice: (price: number) => void;
}

export interface IUseStateRelatedServiceListReturnType
  extends Omit<IUseRelatedServiceListReturnType, 'entityList'> {
  services: List<RelatedServiceModel> | null;
  handleCreateRelatedService: (value: RelatedServiceFormDTO) => Promise<void>;
  handleUpdateRelatedService: (value: RelatedServiceFormDTO) => Promise<void>;
  handleDeleteRelatedServices: (ids: string[]) => Promise<void>;
  handleSearchRelatedServices: (value: any) => Promise<void>;
}

export function useStateRelatedServiceList(
  {
    scheduleUuid,
    handleUpdateScheduleTotalPrice,
    ...rest
  }: IUseStateRelatedServiceListProps = {} as IUseStateRelatedServiceListProps,
): IUseStateRelatedServiceListReturnType {
  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: serviceListLoading,
    total: serviceListTotal,
    ...clientsParams
  } = useRelatedServiceList({
    scheduleUuid,
    ...rest,
  });

  const {
    entityList: services,
    loading,
    handleCreate,
    handleDelete,
    handleUpdate,
    setEntityList,
    total,
  } = useStateEntityList<RelatedServiceModel>({
    entityList,
    refresh,
    limit,
    offset,
    total: serviceListTotal,
  });

  useEffect(() => {
    if (List.isList(services)) {
      const relatedPrice = services.reduce((acc, curr) => {
        acc += parseFloat(curr?.schedule_service_price);
        return acc;
      }, 0);

      handleUpdateScheduleTotalPrice(relatedPrice);
    }
  }, [handleUpdateScheduleTotalPrice, services]);

  const handleCreateRelatedService = React.useCallback(
    async (value: RelatedServiceFormDTO) => {
      const service = await createRelatedService(value, scheduleUuid);
      const serviceModel = RelatedServiceMapper.toRelatedServiceModel(service);

      const updatedModel = serviceModel.set(
        'created_at',
        moment(new Date()).toString(),
      );

      handleCreate(updatedModel, true);
    },
    [handleCreate, scheduleUuid],
  );

  const handleUpdateRelatedService = React.useCallback(
    async (value: RelatedServiceFormDTO) => {
      const service = await updateRelatedService(value, scheduleUuid);
      const serviceModel = RelatedServiceMapper.toRelatedServiceModel(service);

      handleUpdate(serviceModel);
    },
    [handleUpdate, scheduleUuid],
  );

  const handleDeleteRelatedServices = React.useCallback(
    async (ids: string[]) => {
      await deleteRelatedServices(ids, scheduleUuid);

      handleDelete(ids);
    },
    [handleDelete, scheduleUuid],
  );

  const handleSearchRelatedServices = React.useCallback(
    async (keywords: string) => {
      const counterparty = await refresh({offset: 0, limit: 10, keywords});

      if (counterparty) {
        setEntityList(counterparty);
      }
    },
    [refresh, setEntityList],
  );

  return {
    services,
    offset,
    limit,
    refresh,
    total,
    loading: serviceListLoading || loading,
    ...clientsParams,
    handleCreateRelatedService,
    handleUpdateRelatedService,
    handleDeleteRelatedServices,
    handleSearchRelatedServices,
  };
}
