import {Record, List} from 'immutable';
import {
  CompanyDTO,
  CompanyMapper,
  CompanyModel,
  ProfileSubscriptionDTO,
  ProfileSubscriptionMapper,
  ProfileSubscriptionModel,
  UserDTO,
} from '../internal';
import {firstLetterToUppercase} from '../../services/helpers';
import {GENDER} from '../../services/types';
import {WithoutRecursion} from '../type';

export interface IUserModelReturnType {
  uuid: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  phone: string;
  email: string;
  address: string;
  birthday: string;
  picture_url: string;
  status_text: string;
  card_number: string;
  login: string;
  password: string;
  confirm_password: string;
  subscription_discount: number;
  phone_approved: number;
  email_approved: number;
  status: number;
  goods_discount: number;
  gender: GENDER;
  profile_subscriptions:
    | List<ProfileSubscriptionModel>
    | ProfileSubscriptionDTO[];
  default_company: CompanyModel | CompanyDTO;
  created_at: string;
  updated_at: string;
}

export class UserModel extends Record<IUserModelReturnType>({
  uuid: '',
  first_name: '',
  last_name: '',
  middle_name: '',
  phone: '',
  email: '',
  address: '',
  birthday: '',
  picture_url: '',
  status_text: '',
  card_number: '',
  login: '',
  password: '',
  confirm_password: '',
  subscription_discount: 0,
  phone_approved: 0,
  email_approved: 0,
  status: 0,
  goods_discount: 0,
  gender: GENDER.MALE,
  profile_subscriptions: [],
  default_company: {} as CompanyModel,
  created_at: '',
  updated_at: '',
}) {
  constructor(
    props = {} as UserDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IUserModelReturnType = {
      ...props,
      default_company:
        withoutRecursion.indexOf(WithoutRecursion.company) !== -1
          ? props?.default_company
          : CompanyMapper.toCompanyModel(props?.default_company, [
              WithoutRecursion.user,
              ...withoutRecursion,
            ]),
      profile_subscriptions:
        withoutRecursion.indexOf(WithoutRecursion.profileSubscriptions) !== -1
          ? props?.profile_subscriptions
          : ProfileSubscriptionMapper.toProfileSubscriptionListModel(
              props?.profile_subscriptions,
            ).profile_subscriptions,
    };
    super(options);
  }

  get fullName(): string {
    return `${firstLetterToUppercase(this.last_name)} ${
      this.middle_name ? firstLetterToUppercase(this.middle_name) : ''
    } ${firstLetterToUppercase(this.first_name)} `;
  }

  get defaultCompanyModel(): CompanyModel {
    return this.default_company instanceof CompanyModel
      ? this.default_company
      : CompanyMapper.toCompanyModel(this.default_company);
  }

  get profileSubscriptionListModel(): List<ProfileSubscriptionModel> {
    return List.isList(this.profile_subscriptions)
      ? this.profile_subscriptions
      : ProfileSubscriptionMapper.toProfileSubscriptionListModel(
          this.profile_subscriptions,
        ).profile_subscriptions;
  }
}
