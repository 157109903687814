import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {
  EditButton,
  IEditButtonProps,
  SuspenseEmpty,
} from '../../../components/lib/DataDisplay';
import {useModal} from '../../../components/lib/libV2/hooks';
import {EmployeeFormDTO} from '../../../struture';

const EmployeeSideWindowLazy = React.lazy(
  () => import('../Show/EmployeeSideWindow'),
);

export interface IEmployeeEditButtonProps extends IEditButtonProps {
  employee: EmployeeFormDTO;
  onSuccess: (value: EmployeeFormDTO) => Promise<void>;
  onCancel?: () => void;
  children?: React.ReactNode;
}

export default function EmployeeEditButton({
  children,
  employee,
  onSuccess,
  onCancel,
  ...rest
}: IEmployeeEditButtonProps) {
  const {t} = useTranslation();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <EditButton
        tooltipTitle={t('Edit employee')}
        onClick={handleOnInit}
        disabled={false}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <EmployeeSideWindowLazy
          employee={employee}
          editMode
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}
