import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Empty} from 'antd';
import {ListLayout} from '../../../components/lib/Layout';
import {CreateSchedulePaymentButton} from '../Buttons';
import {
  PaymentProps,
  useKeyboardOpenForm,
  usePaymentListPage,
  useSearchInputFocus,
  useStateSchedulePaymentList,
  useStopLoading,
  useStoredProfile,
  useStoredStatusList,
} from '../../../hooks';
import {HeaderLeft, ListActions} from '../../../components/lib/DataDisplay';
import {textToUpperCase, toMoment} from '../../../services/helpers';
import useStoredCompanies from '../../../hooks/useStoredCompanies';
import {
  ClientModel,
  PaymentFormDTO,
  PaymentMapper,
  PaymentModel,
  PaymentScheduleFormDTO,
  ScheduleCalendarMapper,
  ScheduleStatusesText,
} from '../../../struture';
import {Routes, RoutesAcl} from '../../../services/types';
import {ITableProps, Table} from '../../../components/lib/libV2/DataDisplay';
import {useLocation, useNavigate, useParams} from 'react-router';
import {useAcl} from '../../../contex';
import {PaymentTypeView, SchedulePaymentBottomView} from '../Show';
import {PaymentScheduleOperationType} from '../../../services/api/orders';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import useStateSchedule from '../../../hooks/useStateSchedule';
import {CALENDAR, CALENDAR_ENTRY} from '../../../components/lib/const';
import {
  SegmentedContainer,
  StyledButtonContainer,
  StyledListActionsContainer,
} from '../Show/ListStyledComponent';
import {StyledTitle} from '../../../components/lib/Styled';
import {DownOutlined, PlusOutlined, MinusOutlined} from '@ant-design/icons';
import {OrderTitleView} from '../../Orders';
import styled, {useTheme} from 'styled-components';
import {StyledDetailsView} from '../../../components/lib/Styled/DetailsView';
import ListDateRange from '../../../components/lib/DataDisplay/ListActions/ListDateRange';

const StyledTable = styled(Table)`
  margin-bottom: 0;
` as React.ComponentType as React.FunctionComponent<ITableProps<PaymentModel>>;

export const DISABLED_SCHEDULE_PAYMENT_ACTIONS = [
  ScheduleStatusesText.CANCELLED,
  ScheduleStatusesText.CLOSED,
];

const RangeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-top: -15px;
  margin-bottom: 5px;
`;

export function SchedulePaymentListPage() {
  const location = useLocation();
  const {t} = useTranslation();
  const {manage} = useAcl(({payment_group}) => payment_group);
  const {orderId: orderUuid, scheduleId} = useParams();
  const navigate = useNavigate();
  const theme: any = useTheme();
  const {ref: listRef} = useKeyboardOpenForm({
    className: 'order-payment-create-event',
    disabled: !manage,
  });

  const {defaultCompanyUuid} = useStoredCompanies();
  const {profile} = useStoredProfile();

  const orderId = orderUuid || scheduleId;

  const [type, setType] = useState<PaymentScheduleOperationType>(
    location?.state?.type || PaymentScheduleOperationType.ALL,
  );

  const [typeLoading, setTypeLoading] = useState(false);

  const {
    schedule,
    handleChangeScheduleStatus,
    handleUpdate,
    handleCloseSchedule,
  } = useStateSchedule({
    scheduleUuid: orderId!,
  });

  const {
    payments,
    loading: paymentsLoading,
    error: paymentsError,
    limit,
    total,
    date_start,
    date_end,
    keywords,
    stats,
    page,

    handleRefreshPayments,
    handleUpdatePayment,
    handleDeletePayments: onDeletePayments,
    handlePickPaymentRange,
    handleSearchPayments,
    handleCreateSchedulePrepayment,
    handleCreateScheduleRefunds,
  } = useStateSchedulePaymentList({
    scheduleUuid: orderId!,
    loadOnInit: !!orderId,
    type,
  });

  const isDisabledScheduleAction = DISABLED_SCHEDULE_PAYMENT_ACTIONS.includes(
    schedule?.status_text,
  );

  const loading = useStopLoading({
    loading: paymentsLoading || typeLoading,
    error: paymentsError,
    message: 'An error occurred during payments loading',
  });

  const {inputRef, focus, daleyFocus} = useSearchInputFocus({loading});

  const handleDeletePayments = useCallback(
    async (value: string[]) => {
      await onDeletePayments(value);
      focus();
    },
    [focus, onDeletePayments],
  );

  const {rowSelection, columns} = usePaymentListPage({
    type,
    handleDeletePayments,
    handleUpdatePayment,
    stats,
    isDisabledScheduleAction,
    from: Routes.schedules,
    daleyFocus,
  });

  const {allowedStatusesTo, loading: statusListLoading} = useStoredStatusList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
    allowedStatusesTo:
      schedule?.latestStatusModel?.customStatusModel?.allowed_statuses_to,
  });

  const createSchedulePrepayment = useCallback(
    async (value: PaymentScheduleFormDTO) => {
      const payment = await handleCreateSchedulePrepayment(value);

      if (payment?.schedule?.uuid) {
        const schedule = ScheduleCalendarMapper.toScheduleCalendarModel(
          payment.schedule,
        );

        handleUpdate(schedule);
      }
    },
    [handleCreateSchedulePrepayment, handleUpdate],
  );

  const createScheduleRefunds = useCallback(
    async (value: PaymentScheduleFormDTO) => {
      const payment = await handleCreateScheduleRefunds(value);

      if (payment?.schedule?.uuid) {
        const schedule = ScheduleCalendarMapper.toScheduleCalendarModel(
          payment.schedule,
        );

        handleUpdate(schedule);
      }
    },
    [handleCreateScheduleRefunds, handleUpdate],
  );

  const routes = useBreadcrumb([
    {
      path: `/${Routes.orders}`,
      breadcrumbName: 'Orders',
    },
    {
      path: `/${Routes.orders}/${orderId}/${Routes.schedules}/calendar/entry/${orderId}`,
      breadcrumbName: `${
        schedule?.schedule_number
          ? `#${schedule?.schedule_number}`
          : 'Show order'
      }`,
    },
    {
      path: `/${Routes.orders}/${orderId}/${Routes.schedules}/calendar/entry/${orderId}/${Routes.payments}`,
      breadcrumbName:
        type === PaymentScheduleOperationType.ALL
          ? 'Payments'
          : type === PaymentScheduleOperationType.In
          ? 'Receipts'
          : 'tPayments',
    },
  ]);

  const navigateGoBack = useCallback(() => {
    navigate(
      `/${Routes.app}/${Routes.orders}/${orderId}/${Routes.schedules}/${CALENDAR}/${CALENDAR_ENTRY}/${orderId}`,
    );
  }, [navigate, orderId]);

  const payment: (
    props?: PaymentProps,
  ) => PaymentFormDTO | PaymentScheduleFormDTO = (
    {
      payment_comment,
      payment_post_action,
      type,
      payment_price,
    }: PaymentProps = {} as PaymentProps,
  ) =>
    PaymentMapper.toPaymentScheduleFormDTO(
      {
        payment_comment: `${payment_comment} №${schedule?.schedule_number}${
          schedule?.schedule_title ? ` (${schedule?.schedule_title})` : ''
        }`,
        payment_agent_object: schedule?.clientModel as ClientModel,
        payment_sum: payment_price,
      } as PaymentModel,
      {
        cashier: profile?.defaultEmployeeModel,
        payment_post_action,
        schedule,
        type,
      },
    );

  const onClientPaymentTypeChange = useCallback(
    async (type: any) => {
      if (defaultCompanyUuid) {
        setTypeLoading(true);
        setType(type);
        window.history.replaceState({}, document.title);
        await handleRefreshPayments({
          type,
        });
        setTypeLoading(false);
      }
    },
    [defaultCompanyUuid, handleRefreshPayments],
  );

  return (
    <ListLayout
      ref={listRef}
      isHeaderFlickering
      aclItem={RoutesAcl[Routes.payments]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t('Cash flow'))}
      headerTitleDescription={
        schedule?.uuid
          ? `${t('for the Order')} №${schedule?.schedule_number}`
          : null
      }
      headerRight={
        isDisabledScheduleAction ? null : (
          <CreateSchedulePaymentButton
            from={Routes.orders}
            payment={payment}
            companyUuid={defaultCompanyUuid}
            loading={loading}
            onSuccess={createSchedulePrepayment}
            onSuccessRefund={createScheduleRefunds}
            scheduleOperationType={type}
            onCancel={daleyFocus}
          />
        )
      }
      routes={routes}
      empty={
        payments?.size ? null : (
          <Empty description={t('There are no payments history from range.')}>
            {isDisabledScheduleAction ? null : (
              <>
                {type === PaymentScheduleOperationType.In ||
                type === PaymentScheduleOperationType.Out ? (
                  <CreateSchedulePaymentButton
                    from={Routes.orders}
                    type="primary"
                    payment={payment}
                    companyUuid={defaultCompanyUuid}
                    loading={loading}
                    onSuccess={createSchedulePrepayment}
                    onSuccessRefund={createScheduleRefunds}
                    title={
                      type === PaymentScheduleOperationType.In
                        ? t('Create prepayment')
                        : t('Create schedulePayment')
                    }
                    scheduleOperationType={type}
                    onCancel={daleyFocus}
                  />
                ) : (
                  <CreateSchedulePaymentButton
                    from={Routes.orders}
                    type="primary"
                    payment={payment}
                    companyUuid={defaultCompanyUuid}
                    loading={loading}
                    onSuccess={createSchedulePrepayment}
                    onSuccessRefund={createScheduleRefunds}
                    title={
                      <StyledButtonContainer>
                        <StyledTitle>{t('Create payment')}</StyledTitle>
                        <DownOutlined />
                      </StyledButtonContainer>
                    }
                    scheduleOperationType={type}
                    onCancel={daleyFocus}
                  />
                )}
              </>
            )}
          </Empty>
        )
      }
      headerExtra={
        <StyledListActionsContainer>
          <RangeContainer>
            <OrderTitleView
              withSum
              schedule={schedule}
              allowedStatusesTo={allowedStatusesTo}
              loading={loading || statusListLoading}
              handleChangeScheduleStatus={handleChangeScheduleStatus}
              handleCreateSchedulePrepayment={handleCreateSchedulePrepayment}
              handleCloseSchedule={handleCloseSchedule}
            />
            <ListDateRange
              defaultDateRangeValue={
                date_start && date_end
                  ? [toMoment(date_start), toMoment(date_end)]
                  : undefined
              }
              onChange={handlePickPaymentRange}
              loading={loading}
            />
          </RangeContainer>
          <SegmentedContainer>
            <StyledDetailsView
              titles={[]}
              selected={[
                type === PaymentScheduleOperationType.ALL,
                type === PaymentScheduleOperationType.In,
                type === PaymentScheduleOperationType.Out,
              ]}>
              <PaymentTypeView
                color={theme.tabs.link}
                type={PaymentScheduleOperationType.ALL}
                title="All"
                stats={stats}
                onClick={onClientPaymentTypeChange}
                disabled={loading}
              />
              <PaymentTypeView
                color={theme.tabs.success}
                type={PaymentScheduleOperationType.In}
                title={t('Receipts')}
                stats={stats}
                onClick={onClientPaymentTypeChange}
                disabled={loading}
              />
              <PaymentTypeView
                color={theme.tabs.error}
                type={PaymentScheduleOperationType.Out}
                title={t('tPayments')}
                stats={stats}
                onClick={onClientPaymentTypeChange}
                disabled={loading}
              />
            </StyledDetailsView>
          </SegmentedContainer>

          <ListActions
            withoutPicker={true}
            inputRef={inputRef}
            searchText={keywords}
            loading={loading}
            handleSearch={handleSearchPayments}
            inputTooltip={t('Search payments')}
            inputLabel={t('Search payments')}
            withSearchContent={
              <>
                <CreateSchedulePaymentButton
                  from={Routes.orders}
                  type="primary"
                  payment={payment}
                  companyUuid={defaultCompanyUuid}
                  loading={loading}
                  onSuccess={createSchedulePrepayment}
                  onSuccessRefund={createScheduleRefunds}
                  title={t('Prepayment')}
                  scheduleOperationType={PaymentScheduleOperationType.In}
                  icon={<PlusOutlined />}
                  onCancel={daleyFocus}
                  className="order-payment-create-event"
                />
                <CreateSchedulePaymentButton
                  from={Routes.orders}
                  type="primary"
                  payment={payment}
                  companyUuid={defaultCompanyUuid}
                  loading={loading}
                  onSuccess={createSchedulePrepayment}
                  onSuccessRefund={createScheduleRefunds}
                  title={t('schedulePayment')}
                  scheduleOperationType={PaymentScheduleOperationType.Out}
                  icon={<MinusOutlined />}
                  onCancel={daleyFocus}
                />
              </>
            }
          />
        </StyledListActionsContainer>
      }
      outsideFooterContent={
        schedule?.uuid ? (
          <SchedulePaymentBottomView
            schedule={schedule}
            payment={payment}
            loading={loading}
            createScheduleRefunds={createScheduleRefunds}
          />
        ) : null
      }
      loading={loading && !payments?.size}>
      <>
        <StyledTable
          rowSelection={rowSelection}
          total={total}
          pageSize={limit}
          dataSource={payments}
          onChange={handleRefreshPayments}
          columns={columns}
          loading={loading}
          page={page}
        />
      </>
    </ListLayout>
  );
}
