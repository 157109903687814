import {correctPrice} from '../../services/helpers';

export interface IStoreDocumentStatsDTOProps {
  sum_paid: number;
  sum_total: number;
  sum_unpaid: number;
}

export class StoreDocumentStatsDTO {
  public sum_paid: string;
  public sum_total: string;
  public sum_unpaid: string;

  constructor(props: IStoreDocumentStatsDTOProps) {
    this.sum_paid = correctPrice(props?.sum_paid || 0);
    this.sum_total = correctPrice(props?.sum_total || 0);
    this.sum_unpaid = correctPrice(props?.sum_unpaid || 0);
  }
}
