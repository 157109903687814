import moment, {Moment, DurationInputArg1, unitOfTime} from 'moment';
import {Record} from 'immutable';
import {DateValue} from '../types';
import 'moment/locale/uk';
import 'moment/locale/ru';
import 'moment/locale/en-ca';
import {DateType} from './index';

export enum DATE_FORMAT {
  DATE_SHORT = 'YYYY-MM-DD',
  TIME_SHORT = 'HH:mm',
  FULL_FORMAT = `YYYY-MM-DD HH:mm`,
}

// export type DateType = string | number | Moment;

// @ts-ignore
const DateAsObject = new Record({
  day: null,
  month: null,
  year: null,
});

export class MomentDateV2 {
  private readonly moment: (date?: DateValue, format?: string) => moment.Moment;

  constructor(language = 'en-ca') {
    this.moment = (date, format) => moment(date, format).locale(language);
  }

  /**
   * @desc Форматуємо вивід дати
   * @param {Object} date
   * @param {String} defaultFormat
   * return {Moment}
   * */
  format(
    date: DateValue = moment(),
    defaultFormat = DATE_FORMAT.FULL_FORMAT,
  ): string {
    return this.moment(date).format(
      defaultFormat === null ? undefined : defaultFormat,
    );
  }

  /**
   * @desc Поточна дата
   * return {Moment}
   * */
  get currentDate() {
    return this.moment();
  }

  momentDate(date: DateValue): Moment {
    return this.moment(date);
  }

  /**
   * @desc Повернення дати початку поточного місяця
   * */

  get startOfMonth(): string {
    return this.moment().startOf('month').format('YYYY-MM-DD');
  }

  /**
   * @desc Повернення дати кінця поточного місяця
   * */

  get endOfMonth(): string {
    return this.moment().endOf('month').format('YYYY-MM-DD');
  }

  /**
   * @desc Розбиває дату на частини
   * @param {String} date
   * @return {Object}
   * */

  splitDateOnParts(date: DateValue): any {
    if (!date) return new DateAsObject({});

    const arrDate = this.moment(date).format('l').split('-');

    if (Array.isArray(arrDate) && arrDate.length > 1) {
      return new DateAsObject({
        day: arrDate[0],
        month: arrDate[1],
        year: arrDate[2],
      });
    }

    return new DateAsObject({});
  }

  /**
   * @desc Побудова дати
   * @param {Number} day
   * @param {Number} month
   * @param {Number} year
   * @param {String} format
   * @return {String}
   * */

  buildDate(
    day: DateType,
    month: DateType,
    year: number,
    format = 'YYYY-MM-DD',
  ): Moment {
    return this.moment(`${month}-${day}-${year}`, format);
  }

  /**
   * @desc Повернення дати у вказаному форматі
   * @param {Date} date
   * @param {String} format
   * @return {String}
   * */

  buildDateFormat(date: DateValue, format = 'DD.MM.YYYY - H:mm'): string {
    return this.moment(date).format(format);
  }

  /**
   * @desc Повернення дати з доданим періодом
   * @param {Date} date
   * @param {Number} number
   * @param {String} unit що додавати день, місяць, рік
   * @param {String} format
   * @return {String}
   * */

  getPlusDate(
    date: DateValue,
    number: DurationInputArg1,
    unit: unitOfTime.Base = 'days',
    format = 'YYYY-MM-DD',
  ): string {
    return this.moment(date).add(number, unit).format(format);
  }

  /**
   * @desc Повернення дати з відмінусованим періодом
   * @param {Date} date
   * @param {Number} number
   * @param {String} unit що додавати день, місяць, рік
   * @param {String} format
   * @return {String}
   * */

  getSubtractDate(
    date: DateValue,
    number: DurationInputArg1,
    unit: unitOfTime.Base = 'days',
    format = 'YYYY-MM-DD',
  ): string {
    return this.moment(date).subtract(number, unit).format(format);
  }

  /**
   * @desc Сьогоднішня дата
   * */
  get TODAY(): number {
    return this.moment().day();
  }

  /**
   * @desc Поточний місяць
   * */

  get TODAY_MONTH(): number {
    return this.moment().month();
  }

  get TODAY_WEEK(): number {
    return this.moment().week();
  }

  get TODAY_YEAR(): number {
    return this.moment().year();
  }
}
