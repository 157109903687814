import {PlanModel} from '../internal';
import {toMoment, EPlanTypeIdentifier} from '../../services/helpers';
import {Moment} from 'moment';

export class PlanTariffFormDTO {
  public uuid: string;
  public identifier: string;
  public package: string;
  public comment: string;
  public amount: number;
  public start_date: string | Moment;

  constructor(props?: PlanModel, packageName?: EPlanTypeIdentifier) {
    this.uuid = props?.id || '';
    this.identifier = props?.identifier || '';
    this.package = packageName || '';
    this.comment = '';
    this.amount = '3' as any;
    this.start_date = toMoment(new Date(), 'YYYY-MM-DD');
  }
}
