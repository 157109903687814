import {UserDTO, UserFormDTO, UserModel} from '../internal';

export class UserFormMapper {
  public static toUserDTO(userFormDTO: any): UserDTO {
    return new UserDTO({
      uuid: userFormDTO?.uuid,
      first_name: userFormDTO?.firstName,
      last_name: userFormDTO?.lastName,
      email: userFormDTO?.email,
    } as any);
  }

  public static toUserFormDTO(userModel: UserModel): UserFormDTO {
    return new UserFormDTO(userModel);
  }
}
