import React, {useState} from 'react';
import {
  PAYMENT_METHOD,
  PAYMENT_STATUS,
  PaymentFormDTO,
  PaymentMapper,
  PaymentModel,
  StoreDocumentType,
} from '../struture';
import {
  correctPrice,
  isThereContent,
  len,
  lessOrEqualThan,
  toDateByFormat,
} from '../services/helpers';
import {message} from 'antd';
import {
  TableActionCell,
  TableLinkCell,
} from '../components/lib/libV2/DataDisplay';
import {
  DeletePaymentButton,
  PaymentScheduleOperationTypeView,
  PaymentStatusTag,
  PaymentStoreDocumentCommentView,
  PaymentSumTableField,
  PaymentTriggerObjectView,
  UpdatePaymentButton,
} from '../views/Payments';
import {ColorView} from '../components/lib/DataDisplay';
import {StyledDescription} from '../components/lib/Styled';
import {OPERATION_TYPE, Routes} from '../services/types';
import styled, {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router';
import {
  IPaymentScheduleListStats,
  PaymentScheduleOperationType,
} from '../services/api/orders';
import useStoredCompanies from './useStoredCompanies';
import '../views/Payments/Pages/PaymentList.less';
import {BankOutlined} from '@ant-design/icons';
import {FaMoneyBillAlt, FaUserAlt} from 'react-icons/fa';

export interface IUsePaymentListPageProps {
  type: PaymentScheduleOperationType;
  handleUpdatePayment: (value: PaymentFormDTO) => Promise<void>;
  handleDeletePayments: (value: string[]) => Promise<void>;
  stats?: IPaymentScheduleListStats;
  isDisabledScheduleAction?: boolean;
  from?: Routes.schedules | Routes.clients | Routes.stores;
  daleyFocus?: (ms?: number) => void;
  documentType?: StoreDocumentType;
}

const StyledLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DescriptionIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 5px;
`;

const StyledBankOutlined = styled(BankOutlined)`
  font-size: 13px;
  color: ${({theme}) => theme.colors.disabled};
`;

const Description = styled(StyledDescription)`
  margin-top: 1px;
`;

const ACCOUNT_METHODS_DOCUMENT_TYPES = [
  StoreDocumentType.OUT,
  StoreDocumentType.IN,
];

export function usePaymentListPage({
  type,
  handleUpdatePayment,
  handleDeletePayments,
  stats,
  isDisabledScheduleAction,
  from,
  daleyFocus,
  documentType,
}: IUsePaymentListPageProps) {
  const theme: any = useTheme();
  const {t} = useTranslation();
  const location = useLocation();
  const {defaultCompanyUuid, defaultCompany} = useStoredCompanies();

  const [selectedPayments, setSelectedPayments] = useState<PaymentModel[]>([]);

  const rowSelection = {
    hideSelectAll: true,
    selectedRowKeys: selectedPayments.map(({uuid}) => uuid),
    onChange: async (
      selectedRowKeys: React.Key[],
      selectedRecords: PaymentModel[],
    ): Promise<void> => {
      if (lessOrEqualThan(len(selectedRecords), 1)) {
        setSelectedPayments(selectedRecords);
      } else {
        await message.error(t('You can delete up to 1 at a time'));
      }
    },
  };

  const statusColor = {
    [PAYMENT_STATUS.Draft]: theme.colors.link,
    [PAYMENT_STATUS.Completed]: theme.colors.success,
    [PAYMENT_STATUS.Cancelled]: theme.colors.error,
    [PAYMENT_STATUS.Deferred]: theme.colors.link,
    Unknown: theme.colors.primary,
  };

  const columns = [
    {
      title: '№',
      key: 'payment_number',
      className: 'payment-list-td',
      render: (payment: PaymentModel) => (
        <>
          <TableLinkCell
            withoutIcon
            tooltip=""
            fullPath={`${payment?.uuid}`}
            state={{
              from: location.pathname,
              prevState: location?.state?.prevState,
              type,
            }}>
            <PaymentStatusTag status={payment?.payment_status} />
            <StyledLinkContainer>
              {payment?.payment_number}
              <StyledDescription>
                {`${t('From').toLowerCase()} ${toDateByFormat(
                  payment?.payment_date,
                  'DD.MM.YYYY HH:mm',
                )}`}
              </StyledDescription>
            </StyledLinkContainer>
          </TableLinkCell>
        </>
      ),
    },
    {
      title: t('Comment'),
      key: 'comment',
      render: (payment: PaymentModel) => (
        <DescriptionContainer>
          <ColorView
            color={
              payment.payment_method === PAYMENT_METHOD.Account
                ? theme.colors.warning
                : payment?.payment_type === OPERATION_TYPE.OUT
                ? theme.colors.error
                : statusColor[payment?.payment_status] || statusColor.Unknown
            }>
            {payment?.payment_comment}
          </ColorView>
          <DescriptionIconContainer>
            {ACCOUNT_METHODS_DOCUMENT_TYPES.includes(documentType!) &&
            payment.payment_method === PAYMENT_METHOD.Account ? (
              <PaymentStoreDocumentCommentView
                payment={payment}
                documentType={documentType}
              />
            ) : (
              <>
                {payment.payment_method === PAYMENT_METHOD.Bank ? (
                  <>
                    <StyledBankOutlined />
                    <Description>{`${t('Cashless payments')} ( ${
                      payment?.company_cashbox?.box_title || ''
                    } )`}</Description>
                  </>
                ) : payment.payment_method === PAYMENT_METHOD.Account ? (
                  <>
                    <FaUserAlt size={11} color={theme.colors.disabled} />
                    <Description>
                      {t("Debited from the Client's personal account")}
                    </Description>
                  </>
                ) : payment.payment_method === PAYMENT_METHOD.Cash ? (
                  <>
                    <FaMoneyBillAlt size={14} color={theme.colors.disabled} />
                    <Description>{`${t('Cash settlement')} ( ${
                      payment?.company_cashbox?.box_title || ''
                    } )`}</Description>
                  </>
                ) : null}
                <PaymentTriggerObjectView payment={payment} />
              </>
            )}
          </DescriptionIconContainer>
        </DescriptionContainer>
      ),
    },
    {
      title: `${t('Sum')}, ${
        defaultCompany ? defaultCompany?.currency_symbol : ''
      }`,
      key: 'sum',
      align: 'right' as any,
      className: 'payment-list-td',
      render: (payment: PaymentModel) => (
        <PaymentSumTableField
          payment={payment}
          className={'payment-list--price'}
        />
      ),
    },
    ...(isDisabledScheduleAction ||
    from === Routes.clients ||
    from === Routes.schedules
      ? []
      : [
          {
            title: t('Actions'),
            key: 'payment_actions',
            align: 'center' as any,
            fixed: 'right' as any,
            className: 'payment-list-td',
            render: (payment: PaymentModel) => {
              return (
                <TableActionCell>
                  {from === Routes.stores ? null : (
                    <UpdatePaymentButton
                      from={Routes.orders}
                      companyUuid={defaultCompanyUuid}
                      type="text"
                      payment={PaymentMapper.toPaymentFormDTO(payment, {
                        editMode: true,
                      })}
                      data-testid="payment-edit-button"
                      onSuccess={handleUpdatePayment}
                      disabled={
                        payment?.payment_status === PAYMENT_STATUS.Completed
                      }
                      onCancel={daleyFocus}
                    />
                  )}

                  <DeletePaymentButton
                    data-testid="payment-delete-button"
                    payments={[payment]}
                    onSuccess={handleDeletePayments}
                    disabled={
                      payment?.payment_status === PAYMENT_STATUS.Completed
                    }
                  />
                </TableActionCell>
              );
            },
          },
        ]),
  ];

  const PAYMENT_SCHEDULE_TYPE = [
    {
      uuid: PaymentScheduleOperationType.ALL,
      title: (
        <PaymentScheduleOperationTypeView
          type={PaymentScheduleOperationType.ALL}
          title={`${t('All')}, ${
            isThereContent(defaultCompany)
              ? defaultCompany?.currency_symbol
              : ''
          } ${
            isThereContent(stats)
              ? correctPrice(
                  Number(stats?.totalInSum || 0) +
                    Number(stats?.totalOutSum || 0),
                )
              : ''
          }`}
        />
      ),
    },
    {
      uuid: PaymentScheduleOperationType.In,
      title: (
        <PaymentScheduleOperationTypeView
          type={PaymentScheduleOperationType.In}
          title={`${from === Routes.clients ? t('Advance') : t('Receipts')}, ${
            isThereContent(defaultCompany)
              ? defaultCompany?.currency_symbol
              : ''
          } ${
            isThereContent(stats) ? correctPrice(stats?.totalInSum || 0) : ''
          }`}
        />
      ),
    },

    {
      uuid: PaymentScheduleOperationType.Out,
      title: (
        <PaymentScheduleOperationTypeView
          type={PaymentScheduleOperationType.Out}
          title={`${t('tPayments')}: ${
            isThereContent(defaultCompany)
              ? defaultCompany?.currency_symbol
              : ''
          } ${
            isThereContent(stats) ? correctPrice(stats?.totalOutSum || 0) : ''
          }`}
        />
      ),
    },
  ];

  return {
    selectedPayments,
    rowSelection,
    columns,
    PAYMENT_SCHEDULE_TYPE,
  };
}
