import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {
  INVERT_MAPPED_PRODUCT_CATEGORY_TYPES,
  IProductsListStatsProps,
  MAPPED_PRODUCT_CATEGORY_TYPES,
  ProductCategoryListModel,
  ProductCategoryMappedType,
  ProductCategoryMapper,
  ProductCategoryModel,
  ProductCategoryType,
} from '../../struture';
import {ProductType, REDUX_STATUS} from '../../services/types';
import {RootState} from '../reducers';
import {List, Map} from 'immutable';
import {eq, neq} from '../../services/helpers';

export interface ProductCategoryListState {
  cachedProductCategoryMap: Map<
    ProductCategoryType | ProductCategoryMappedType,
    Map<string, ProductCategoryListModel>
  >;
  loading: boolean;
  status: REDUX_STATUS;
  stats: IProductsListStatsProps;
}

export interface SetProductCategoryListAction {
  productCategoryList: ProductCategoryListModel;
  parentCategoryUuid: string;
  categoryType: ProductCategoryType | ProductCategoryMappedType;
  keywords: string;
  page?: number;
}

export interface LoadingMoreProductCategoryListAction {
  productCategoryList: ProductCategoryListModel;
  categoryType: ProductCategoryType | ProductCategoryMappedType;
  parentCategoryUuid: string;
}

export interface SetProductCategoryListStatsAction {
  stats: IProductsListStatsProps;
}

export interface AddProductCategoryAction {
  productCategory: ProductCategoryModel;
  stats: IProductsListStatsProps;
}

export interface ResetProductCategoryCacheAction {
  productCategoryTypes: (ProductCategoryType | ProductCategoryMappedType)[];
}

export interface UpdateProductCategoryAction {
  productCategory: ProductCategoryModel;
}

export interface DeleteProductCategoryAction {
  productCategoryUuid: string;
  stats: IProductsListStatsProps;
}

const initialState: ProductCategoryListState = {
  cachedProductCategoryMap: Map(),
  loading: true,
  status: REDUX_STATUS.IDLE,
  stats: {
    [ProductType.Product]: '0',
    [ProductType.Category]: '0',
    product_price_tags_count: 0,
  },
};

export const productCategoryListSlice = createSlice({
  name: 'productCategoryList',
  initialState,
  reducers: {
    setProductCategoryList: (
      state,
      {payload}: PayloadAction<SetProductCategoryListAction>,
    ) => {
      const {
        productCategoryList,
        page,
        keywords,
        categoryType,
        parentCategoryUuid,
      } = payload;
      const {cachedProductCategoryMap, stats} = state;

      if (productCategoryList) {
        const updatedProductCategoryList: any = productCategoryList
          .update('keywords', () => keywords)
          .update('page', (initPage) => page || initPage);

        const categoryTypeMap =
          cachedProductCategoryMap.get(categoryType) || Map();

        return {
          ...state,
          cachedProductCategoryMap: cachedProductCategoryMap.set(
            categoryType,
            categoryTypeMap.set(
              parentCategoryUuid,
              updatedProductCategoryList,
            ) as any,
          ),
          status: REDUX_STATUS.SUCCEEDED,
          loading: false,
          stats: productCategoryList?.stats || stats,
        };
      }
    },

    setProductCategoryListStats: (
      state,
      {payload}: PayloadAction<SetProductCategoryListStatsAction>,
    ) => {
      const {stats} = payload;

      const {stats: stateStats} = state;

      return {
        ...state,
        stats: stats || stateStats,
      };
    },

    setInitialState: () => {
      return initialState;
    },

    loadMoreProductCategoryList: (
      state: any,
      {payload}: PayloadAction<LoadingMoreProductCategoryListAction>,
    ) => {
      const {productCategoryList, parentCategoryUuid, categoryType} = payload;

      const {cachedProductCategoryMap}: ProductCategoryListState = state;

      return {
        ...state,
        loading: false,
        status: REDUX_STATUS.SUCCEEDED,
        cachedProductCategoryMap: cachedProductCategoryMap.update(
          categoryType,
          (categoryTypeMap) =>
            categoryTypeMap?.update(parentCategoryUuid, (list) => {
              if (
                list &&
                List.isList(list?.categories) &&
                List.isList(productCategoryList?.categories)
              ) {
                return list.update('categories', (prevProductCategories) =>
                  prevProductCategories.merge(productCategoryList.categories),
                );
              }

              return list;
            }),
        ),
      };
    },

    resetProductCategoryListCache: (
      state: any,
      {payload}: PayloadAction<ResetProductCategoryCacheAction>,
    ) => {
      const {productCategoryTypes} = payload;
      let {cachedProductCategoryMap}: ProductCategoryListState = state;

      productCategoryTypes.forEach(
        (
          productCategoryType: ProductCategoryMappedType | ProductCategoryType,
        ) => {
          cachedProductCategoryMap =
            cachedProductCategoryMap?.remove(productCategoryType);
        },
      );

      return {
        ...state,
        loading: false,
        status: REDUX_STATUS.SUCCEEDED,
        cachedProductCategoryMap,
      };
    },

    addProductCategoryToList: (
      state: any,
      {payload}: PayloadAction<AddProductCategoryAction>,
    ) => {
      const {productCategory, stats} = payload;

      let {
        cachedProductCategoryMap,
        stats: stateStats,
      }: ProductCategoryListState = state;

      MAPPED_PRODUCT_CATEGORY_TYPES.forEach(
        (
          productCategoryType: ProductCategoryMappedType | ProductCategoryType,
        ) => {
          const originalType =
            INVERT_MAPPED_PRODUCT_CATEGORY_TYPES[
              productCategoryType as ProductCategoryType
            ] || ProductCategoryType.LIST;

          cachedProductCategoryMap = cachedProductCategoryMap?.update(
            productCategoryType,
            (categoryTypeMap) =>
              categoryTypeMap?.map((list) => {
                if (list && List.isList(list?.categories)) {
                  if (originalType === ProductCategoryType.LIST) {
                    return list
                      .update('categories', (productCategories) =>
                        productCategories.unshift(productCategory),
                      )
                      .update('total', (total = 0) => total + 1);
                  }

                  if (originalType === ProductCategoryType.TREE) {
                    const handleCreate = (
                      categories: List<ProductCategoryModel>,
                    ): any => {
                      if (!productCategory?.parent?.uuid) {
                        return categories?.size >= 10
                          ? categories?.pop()?.unshift(productCategory)
                          : categories?.unshift(productCategory);
                      }

                      return categories.map((stateCategory) => {
                        if (
                          stateCategory?.uuid === productCategory?.parent?.uuid
                        ) {
                          return stateCategory.update(
                            'children',
                            (children: any) => [
                              ...(children || []),
                              productCategory,
                            ],
                          );
                        }

                        return stateCategory;
                      });
                    };

                    return list.update('categories', (productCategories) =>
                      handleCreate(productCategories),
                    );
                  }
                }

                return ProductCategoryMapper.toProductCategoryListModel(
                  [productCategory as any],
                  1,
                );
              }),
          );
        },
      );

      return {
        ...state,
        loading: false,
        status: REDUX_STATUS.SUCCEEDED,
        cachedProductCategoryMap,
        stats: stats || stateStats,
      };
    },

    updateProductCategoryFromList: (
      state: any,
      {payload}: PayloadAction<UpdateProductCategoryAction>,
    ) => {
      const {productCategory} = payload;

      let {cachedProductCategoryMap}: ProductCategoryListState = state;

      MAPPED_PRODUCT_CATEGORY_TYPES.forEach(
        (
          productCategoryType: ProductCategoryMappedType | ProductCategoryType,
        ) => {
          const originalType =
            INVERT_MAPPED_PRODUCT_CATEGORY_TYPES[
              productCategoryType as ProductCategoryType
            ] || ProductCategoryType.LIST;

          cachedProductCategoryMap = cachedProductCategoryMap?.update(
            productCategoryType,
            (categoryTypeMap) =>
              categoryTypeMap?.map((list) => {
                if (list && List.isList(list?.categories)) {
                  if (originalType === ProductCategoryType.LIST) {
                    return list.update('categories', (productCategories) =>
                      productCategories?.map((prevProductCategory) => {
                        if (
                          eq(prevProductCategory?.uuid, productCategory?.uuid)
                        ) {
                          return prevProductCategory?.merge(productCategory);
                        }
                        return prevProductCategory;
                      }),
                    );
                  }

                  if (originalType === ProductCategoryType.TREE) {
                    const handleUpdate = (
                      categories: List<ProductCategoryModel>,
                    ): any => {
                      return categories.map((stateCategory) => {
                        if (stateCategory?.uuid === productCategory?.uuid) {
                          return productCategory;
                        }

                        if (
                          Array.isArray(stateCategory?.children) &&
                          stateCategory?.children?.length
                        ) {
                          return stateCategory.update('children', (children) =>
                            handleUpdate(children as any),
                          );
                        }

                        return stateCategory;
                      });
                    };

                    return list.update('categories', (productCategories) =>
                      handleUpdate(productCategories),
                    );
                  }
                }

                return list;
              }),
          );
        },
      );

      return {
        ...state,
        loading: false,
        status: REDUX_STATUS.SUCCEEDED,
        cachedProductCategoryMap,
      };
    },

    deleteProductCategoryFromList: (
      state: any,
      {payload}: PayloadAction<DeleteProductCategoryAction>,
    ) => {
      const {productCategoryUuid, stats} = payload;

      let {
        cachedProductCategoryMap,
        stats: stateStats,
      }: ProductCategoryListState = state;

      let isDelete = false;

      MAPPED_PRODUCT_CATEGORY_TYPES.forEach(
        (
          productCategoryType: ProductCategoryMappedType | ProductCategoryType,
        ) => {
          const originalType =
            INVERT_MAPPED_PRODUCT_CATEGORY_TYPES[
              productCategoryType as ProductCategoryType
            ] || ProductCategoryType.LIST;

          cachedProductCategoryMap = cachedProductCategoryMap?.update(
            productCategoryType,
            (categoryTypeMap) =>
              categoryTypeMap?.map((list) => {
                if (list && List.isList(list?.categories)) {
                  if (originalType === ProductCategoryType.LIST) {
                    return list
                      .update('categories', (productCategories) =>
                        productCategories.filter(({uuid}) => {
                          if (neq(productCategoryUuid, uuid)) {
                            return true;
                          }

                          isDelete = true;

                          return false;
                        }),
                      )
                      .update('total', (total = 0) =>
                        total > 0 && isDelete ? total - 1 : total,
                      );
                  }

                  if (originalType === ProductCategoryType.TREE) {
                    const handleDelete = (
                      categories: List<ProductCategoryModel>,
                    ): any => {
                      return categories
                        ?.map((category) => {
                          if (category?.uuid === productCategoryUuid) {
                            return null;
                          }

                          if (
                            Array.isArray(category?.children) &&
                            category?.children?.length
                          ) {
                            return category?.update('children', (children) => {
                              const updatedChildren = handleDelete(
                                children as any,
                              ).filter(Boolean);

                              return updatedChildren?.length
                                ? updatedChildren
                                : undefined;
                            });
                          }

                          return category;
                        })
                        ?.filter(Boolean);
                    };

                    return list.update('categories', (productCategories) =>
                      handleDelete(productCategories),
                    );
                  }
                }

                return list;
              }),
          );
        },
      );

      return {
        ...state,
        loading: false,
        status: REDUX_STATUS.SUCCEEDED,
        cachedProductCategoryMap,
        stats: stats || stateStats,
      };
    },
  },
});

export const {
  setProductCategoryList,
  setInitialState,
  loadMoreProductCategoryList,
  addProductCategoryToList,
  updateProductCategoryFromList,
  deleteProductCategoryFromList,
  setProductCategoryListStats,
  resetProductCategoryListCache,
} = productCategoryListSlice.actions;

export const selectProductCategoryList = ({
  productCategoryListReducer,
}: RootState) => productCategoryListReducer;

export default productCategoryListSlice.reducer;
