import {EmployeeModel} from '../internal';
import {
  GENDER,
  DateType,
  toDateByFormat,
  toMoment,
} from '../../services/helpers';
import {DATE_SHORT_FORMAT} from '../../services/const';

export class EmployeeFormDTO {
  public uuid: string;

  public title: string;

  public email: string;

  public gender: GENDER;

  public phone: string;

  public birthday: DateType;

  public first_name: string;

  public last_name: string;

  public middle_name: string;

  constructor(props?: EmployeeModel) {
    this.last_name = props?.last_name || '';
    this.middle_name = props?.middle_name || '';
    this.first_name = props?.first_name || '';
    this.uuid = props?.uuid || '';
    this.title = props?.title || '';
    this.email = props?.email || '';
    this.gender = props?.gender || GENDER.MALE;
    this.phone = props?.phone || '';
    this.birthday = props?.birthday ? toMoment(props?.birthday) : '';
  }

  birthdayToString(birthday: DateType) {
    return toDateByFormat(birthday, DATE_SHORT_FORMAT);
  }
}
