export enum ProductBarCodeFormat {
  EAN13 = 'EAN13',
}
export interface IProductBarCodeFormDTOProps {
  bar_code_format: ProductBarCodeFormat;
}

export class ProductBarCodeFormDTO {
  public bar_code_format: ProductBarCodeFormat;

  constructor(props?: IProductBarCodeFormDTOProps) {
    this.bar_code_format = props?.bar_code_format || ProductBarCodeFormat.EAN13;
  }
}
