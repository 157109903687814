import React, {
  JSX,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {InvoiceMappedType, InvoiceModel, InvoiceType} from '../../../struture';
import {Table} from '../../../components/lib/libV2/DataDisplay';
import {useTranslation} from 'react-i18next';
import {useStoredCompanies, useStoredInvoiceList} from '../../../hooks';
import {last, correctPrice, toDateByFormat} from '../../../services/helpers';
import {TableIndexField} from '../../../components/lib/DataDisplay';
import {InvoiceDetailsView} from '../../Invoices';
import styled from 'styled-components';

import '../../Orders/List/OrderList.less';
import '../../Products/Pages/ProductList.less';
import '../../Stores/Forms/StoreProductListField.less';

export interface PaymentInvoiceTableProps {
  selectedItem: InvoiceModel | unknown;
  residentUuid: string;
  handleSelectDestination: (
    payment_destination_object_uuid: string,
    payment_sum: string,
    comment: string,
  ) => void;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`;

export function PaymentInvoiceTable({
  residentUuid,
  handleSelectDestination,
  selectedItem,
}: PaymentInvoiceTableProps): JSX.Element {
  const {t} = useTranslation();
  const {defaultCompanyUuid, defaultCompany} = useStoredCompanies();

  const [selectedInvoices, setSelectedInvoices] = useState<InvoiceModel[]>([]);

  const isSelectOnce = useRef(false);

  const getSum = useCallback(
    (invoice: InvoiceModel) => invoice?.inv_sum_total,
    [],
  );

  const {
    invoiceList,
    limit,
    total,
    loading,
    loadingMore,
    page,
    resident_uuid,

    handleRefreshInvoices,
  } = useStoredInvoiceList({
    companyUuid: defaultCompanyUuid,
    invoiceType: InvoiceType.IN,
    resident_uuid: residentUuid,
    mappedInvoiceType: InvoiceMappedType.PAYMENT_OUT,
    alwaysSendRequest: true,
  });

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: selectedInvoices?.map(({uuid}) => uuid),
      onChange: (
        selectedRowKeys: React.Key[],
        selectedRecords: InvoiceModel[],
      ) => {
        const invoice = last(selectedRecords);

        if (invoice) {
          isSelectOnce.current = true;
          setSelectedInvoices([invoice]);
          handleSelectDestination(
            invoice?.uuid,
            getSum(invoice),
            `${t('Payment of arrears tinvoice')}  №${
              invoice?.inv_internal_number
            } ${t('From').toLowerCase()} ${toDateByFormat(
              invoice?.inv_date,
              'DD.MM.YYYY HH:mm',
            )}`,
          );
        } else {
          setSelectedInvoices([]);
          handleSelectDestination('', correctPrice(0), '');
        }
      },
    }),
    [getSum, handleSelectDestination, selectedInvoices, t],
  );

  const columns = [
    {
      ellipsis: true,
      title: `№`,
      key: 'index',
      align: 'center' as any,
      className: 'order-list-td order-list--middle-text',
      render: (invoice: InvoiceModel, items: any, index: number) => (
        <TableIndexField number={(page - 1) * limit + index + 1} />
      ),
    },
    {
      ellipsis: true,
      title: t('Name'),
      key: 'name',
      render: (invoice: InvoiceModel) => (
        <InvoiceDetailsView invoice={invoice} withStatus withoutNavigate />
      ),
    },
    {
      ellipsis: true,
      title: `${t('Sum')}, ${defaultCompany?.currency_symbol || ''}`,
      key: 'amount',
      align: 'right' as any,
      render: (invoice: InvoiceModel) => (
        <StyledContainer>{getSum(invoice)}</StyledContainer>
      ),
    },
  ];

  useEffect(() => {
    if (resident_uuid !== residentUuid) {
      (async () => {
        await handleRefreshInvoices({
          resident_uuid: residentUuid,
          page: 1,
          showLoading: true,
        });
      })();
    }
  }, [handleRefreshInvoices, residentUuid, resident_uuid]);

  useEffect(() => {
    if (
      selectedItem instanceof InvoiceModel &&
      !selectedInvoices?.length &&
      !isSelectOnce.current
    ) {
      setSelectedInvoices([selectedItem]);
      isSelectOnce.current = true;
    }
  }, [selectedInvoices?.length, selectedItem]);

  return (
    <Table<InvoiceModel>
      pageSize={limit}
      total={total}
      onChange={handleRefreshInvoices}
      rowSelection={rowSelection}
      dataSource={invoiceList}
      columns={columns}
      loading={loading || loadingMore}
      page={page}
    />
  );
}
