import * as React from 'react';
import {List} from 'immutable';
import {
  useIntegrationList,
  IUseIntegrationListProps,
  IUseIntegrationListReturnType,
} from './useIntegrationList';
import {
  IntegrationAssociationType,
  IntegrationFormDTO,
  IntegrationModel,
} from '../struture';
import {
  selectIntegrationList,
  setIntegrationList,
} from '../store/features/integrationListSlice';
import {useSelector, useDispatch} from 'react-redux';
import {REDUX_STATUS} from '../services/types';
import {useCallback, useEffect, useState} from 'react';
import {useIntegration} from './useIntegration';
import useStoredCompanies from './useStoredCompanies';
import {
  createIntegration,
  editIntegration,
  getIntegrationById,
} from '../services/api/integration';

export interface IUseStateIntegrationListProps
  extends IUseIntegrationListProps {}

export interface IIntegrationSearchProps {
  keywords: string;
  showLoading?: boolean;
  limit?: number;
}

export interface IUseStateIntegrationListReturnType
  extends Omit<IUseIntegrationListReturnType, 'entityList'> {
  integrationList: List<IntegrationModel> | null;
  handleSearchIntegrations: (value: IIntegrationSearchProps) => Promise<void>;
  handleRefreshIntegrations: (
    value: Partial<IUseIntegrationListProps> & {page: number},
  ) => Promise<void>;
  loadingMore: boolean;
  page: number;

  handleGetIntegration: <T>(
    integrationUuid: string,
  ) => Promise<IntegrationAssociationType<T>>;

  handleAddIntegration: <T>(
    value: IntegrationFormDTO<T>,
    integrationUuid: string,
  ) => Promise<void>;
  handleAddOrUpdateIntegration: <T>(
    value: IntegrationFormDTO<T>,
    integrationUuid: string,
  ) => Promise<void>;
  handleUpdateIntegration: <T>(value: IntegrationFormDTO<T>) => Promise<void>;
}

export function useStoredIntegrationList(
  {
    loadOnInit,
    ...rest
  }: IUseStateIntegrationListProps = {} as IUseStateIntegrationListProps,
): IUseStateIntegrationListReturnType {
  const [loadingMore, setLoadingMore] = useState(false);
  const {defaultCompanyUuid} = useStoredCompanies();

  const {
    status: storedIntegrationListStatus,
    loading: storedIntegrationListLoading,
    integrationList,
    ...storedIntegrationListParams
  } = useSelector(selectIntegrationList);

  const storeTotal = integrationList?.total || 0;
  const storeKeywords = integrationList?.keywords || '';
  const storedPage = integrationList?.page || 1;

  const dispatch = useDispatch<any>();

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: integrationListLoading,
    ...IntegrationsParams
  } = useIntegrationList({
    loadOnInit:
      loadOnInit && storedIntegrationListStatus !== REDUX_STATUS.SUCCEEDED,
    ...rest,
  });

  useEffect(() => {
    if (
      entityList &&
      List.isList(entityList?.integrations) &&
      storedIntegrationListStatus !== REDUX_STATUS.SUCCEEDED
    ) {
      dispatch(
        setIntegrationList({
          keywords: '',
          total: entityList?.total!,
          integrationList: entityList,
          page: 1,
        }),
      );
    }
  }, [dispatch, entityList, storedIntegrationListStatus]);

  const handleSearchIntegrations = React.useCallback(
    async ({limit = 10, keywords, showLoading}: IIntegrationSearchProps) => {
      setLoadingMore(true);
      const IntegrationListModel = await refresh({
        offset: 0,
        limit,
        keywords,
        showLoading,
      });

      if (
        IntegrationListModel &&
        List.isList(IntegrationListModel?.integrations)
      ) {
        dispatch(
          setIntegrationList({
            keywords: keywords,
            total: entityList?.total!,
            integrationList: entityList,
            page: 1,
          }),
        );
      }
      setLoadingMore(false);
    },
    [dispatch, entityList, refresh],
  );

  const handleRefreshIntegrations = useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = false,
      page,
    }: Partial<IUseIntegrationListProps> & {page: number}) => {
      const IntegrationListModel = await refresh({
        offset,
        limit,
        showLoading,
      });

      if (
        IntegrationListModel &&
        List.isList(IntegrationListModel?.integrations)
      ) {
        dispatch(
          setIntegrationList({
            keywords: '',
            total: entityList?.total!,
            integrationList: entityList,
            page,
          }),
        );
      }
    },
    [dispatch, entityList, refresh],
  );

  const handleGetIntegration = useCallback(
    async <T>(integrationUuid: string) => {
      return getIntegrationById<T>(integrationUuid, defaultCompanyUuid);
    },
    [defaultCompanyUuid],
  );

  const handleAddIntegration = useCallback(
    async <T>(value: IntegrationFormDTO<T>, integrationUuid: string) => {
      await createIntegration<T>(value, defaultCompanyUuid, integrationUuid);
    },
    [defaultCompanyUuid],
  );

  const handleUpdateIntegration = useCallback(
    async <T>(value: IntegrationFormDTO<T>) => {
      await editIntegration<T>(value);
    },
    [],
  );

  const handleAddOrUpdateIntegration = useCallback(
    async <T>(value: IntegrationFormDTO<T>, integrationUuid: string) => {
      if (value?.uuid) {
        await handleUpdateIntegration(value);
        return;
      }

      await handleAddIntegration<T>(value, integrationUuid);
    },
    [handleAddIntegration, handleUpdateIntegration],
  );

  return {
    ...IntegrationsParams,
    ...storedIntegrationListParams,
    integrationList: integrationList?.integrations || null,
    total: storeTotal,
    loadingMore,
    offset,
    limit,
    refresh,
    loading:
      (!List.isList(integrationList?.integrations) && integrationListLoading) ||
      storedIntegrationListLoading,
    handleSearchIntegrations,
    handleRefreshIntegrations,
    keywords: storeKeywords,
    page: storedPage,
    handleGetIntegration,
    handleAddIntegration,
    handleUpdateIntegration,
    handleAddOrUpdateIntegration,
  };
}
