import {
  ClientSubscriptionableDTO,
  IClientSubscriptionableDTOProps,
  ClientSubscriptionableModel,
} from '../internal';
import {WithoutRecursion} from '../type';

export class ClientSubscriptionableMapper {
  public static toClientSubscriptionableDTO(
    clientSubscriptionable:
      | ClientSubscriptionableModel
      | IClientSubscriptionableDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): ClientSubscriptionableDTO {
    return new ClientSubscriptionableDTO(
      {
        uuid: clientSubscriptionable?.uuid,
        title: clientSubscriptionable?.title,
        description: clientSubscriptionable?.description,
        weekdays: clientSubscriptionable?.weekdays,
        duration: clientSubscriptionable?.duration,
        max_attenders: clientSubscriptionable?.max_attenders,
        price: clientSubscriptionable?.price,
        status: clientSubscriptionable?.status,
        start_date: clientSubscriptionable?.start_date,
        end_date: clientSubscriptionable?.end_date,
        service: clientSubscriptionable?.service,
      },
      [WithoutRecursion.clientSubscriptionable, ...withoutRecursion],
    );
  }

  public static toClientSubscriptionableModel(
    clientSubscriptionableDTO: ClientSubscriptionableDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): ClientSubscriptionableModel {
    return new ClientSubscriptionableModel(clientSubscriptionableDTO, [
      WithoutRecursion.clientSubscriptionable,
      ...withoutRecursion,
    ]);
  }
}
