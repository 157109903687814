import {List, Record} from 'immutable';
import {GroupDTO, ServiceDTO, ServiceModel, ServiceMapper} from '../internal';
import {WithoutRecursion} from '../type';

export interface IGroupModelReturnType {
  uuid: string;
  description: string;
  title: string;
  created_at: string;
  updated_at: string;
  status: number;
  services: List<ServiceModel> | ServiceDTO[];
}

/**
 * @class Client
 * @desc Клієнт
 */
export class GroupModel extends Record<IGroupModelReturnType>({
  uuid: '',
  title: '',
  description: '',
  status: 0,
  services: List(),
  created_at: '',
  updated_at: '',
}) {
  constructor(
    {...props}: GroupDTO = {} as GroupDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IGroupModelReturnType = {
      ...props,
      services:
        withoutRecursion.indexOf(WithoutRecursion.group) !== -1
          ? props?.services
          : ServiceMapper.toServiceListModel(props?.services || [], 0, [
              WithoutRecursion.group,
              ...withoutRecursion,
            ]).services,
    };

    super(options);
  }

  get serviceListModel(): List<ServiceModel> {
    return List.isList(this.services)
      ? this.services
      : ServiceMapper.toServiceListModel(this.services).services;
  }
}
