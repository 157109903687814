import {List} from 'immutable';
import {
  StoreDTO,
  IStoreDTOProps,
  StoreModel,
  StoreListModel,
  StoreFormDTO,
  IStoreAdditionalParams,
} from '../internal';
import {listToArray} from '../../helpers';
import {WithoutRecursion} from '../type';

export interface IStoreListDTO {
  stores: StoreDTO[];
  total: number;
  keywords?: string;
  page?: number;
}

export class StoreMapper {
  public static toStoreDTO(
    store: StoreModel | IStoreDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): StoreDTO {
    return new StoreDTO(store, [WithoutRecursion.store, ...withoutRecursion]);
  }

  public static toStoreListDTO(
    stores: List<StoreModel> | IStoreDTOProps[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): IStoreListDTO {
    const storeList = List.isList(stores)
      ? listToArray<StoreModel>(stores)
      : stores;

    return {
      stores: storeList?.map((store) =>
        StoreMapper.toStoreDTO(store, [
          WithoutRecursion.store,
          ...withoutRecursion,
        ]),
      ),
      total,
    };
  }

  public static toStoreModel(
    storeDTO: StoreDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): StoreModel {
    return new StoreModel(storeDTO, [
      WithoutRecursion.store,
      ...withoutRecursion,
    ]);
  }

  public static toStoreListModel(
    storeDTOs: StoreDTO[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): StoreListModel {
    return new StoreListModel({stores: storeDTOs, total}, [
      WithoutRecursion.store,
      ...withoutRecursion,
    ]);
  }

  public static toStoreFormDTO(
    storeModel: StoreModel,
    additionalParams: IStoreAdditionalParams,
  ): StoreFormDTO {
    return new StoreFormDTO(storeModel, additionalParams);
  }
}
