import * as React from 'react';
import {
  EventType,
  IDayTimeRange,
  isThereContent,
  isFunction,
  getHourFromTime,
  eq,
  neq,
  last,
} from '../services/helpers';
import {useCalendar, useCalendarDnd} from '../contex';

export interface IUseCalendarEventProps {
  scheduleEvents: EventType;
  time?: string;
  weekdayInfo?: IDayTimeRange;
  lastTime: string;
  isToday: boolean;
}

export interface IUseCalendarEventReturnType {
  handleAddEvent: (time?: string) => void;
  handleAddedResetEvent: () => void;
}

export default function useCalendarEvent({
  scheduleEvents,
  time,
  weekdayInfo,
  lastTime,
  isToday,
}: IUseCalendarEventProps): IUseCalendarEventReturnType {
  const {withAddingEvents} = useCalendar();
  const isEmptyDndEvents = React.useRef(false);

  const {
    handleChangeTime,
    handleAddDnDEvent,
    setDndEvents,
    eventTime,
    didDrop,
    updatedTime,
    handleUpdateDnDData,
    dndCache,
  } = useCalendarDnd();

  const oneCancel = React.useRef(false);

  const handleAddedResetEvent = React.useCallback(() => {
    if (isFunction(handleChangeTime)) {
      handleChangeTime('');
    }
    handleUpdateDnDData({dndEvents: null});
    dndCache.clear();
  }, [dndCache, handleChangeTime, handleUpdateDnDData]);

  React.useEffect(() => {
    if (scheduleEvents && isThereContent(scheduleEvents)) {
      const addedEvents = dndCache.get('addedEvents');

      if (addedEvents) {
        const [[time, events]]: any = Object.entries(addedEvents);

        const dndEvent = last(events);
        const scheduleEventsTime = scheduleEvents[time] || [];

        setDndEvents({
          ...scheduleEvents,
          ...{
            [time]: [...scheduleEventsTime, dndEvent],
          },
        });
      } else {
        setDndEvents(scheduleEvents);
      }

      isEmptyDndEvents.current = false;
    }

    if (!isThereContent(scheduleEvents)) {
      const addedEvents = dndCache.get('addedEvents');

      if (addedEvents) {
        const [[time, events]]: any = Object.entries(addedEvents);

        const dndEvent: any = last(events);

        setDndEvents({
          [time]: [dndEvent],
        });
      } else {
        setDndEvents({});
      }
    }
  }, [dndCache, handleAddedResetEvent, isToday, scheduleEvents, setDndEvents]);

  const handleAddEvent = React.useCallback(
    (scheduleTime: string | undefined = time): any => {
      if (withAddingEvents) {
        handleAddDnDEvent(
          scheduleTime!,
          scheduleEvents as any,
          isToday,
          weekdayInfo?.end,
        );
      }
    },
    [
      handleAddDnDEvent,
      isToday,
      scheduleEvents,
      time,
      weekdayInfo?.end,
      withAddingEvents,
    ],
  );

  React.useEffect(() => {
    if (didDrop && eq(getHourFromTime(time), getHourFromTime(updatedTime))) {
      handleUpdateDnDData({
        didDrop: false,
        updatedTime: '',
        eventTime: `${getHourFromTime(updatedTime)}:00`,
      });
      oneCancel.current = true;
      handleAddEvent(updatedTime);
    }

    if (
      didDrop &&
      eq(getHourFromTime(time), getHourFromTime(eventTime)) &&
      neq(getHourFromTime(time), getHourFromTime(updatedTime)) &&
      oneCancel.current
    ) {
      handleAddedResetEvent();
      oneCancel.current = false;
    }
  }, [
    didDrop,
    updatedTime,
    eventTime,
    time,
    handleAddedResetEvent,
    handleUpdateDnDData,
    handleAddEvent,
  ]);

  return {
    handleAddEvent,
    handleAddedResetEvent,
  };
}
