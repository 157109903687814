import {Record, List} from 'immutable';
import GalleryItem, {GalleryItemProps} from './GalleryItem';

export interface GalleryListProps {
  gallery: GalleryItemProps[];
}

/**
 * @class GalleryList
 * @classdesc GalleryList list model.
 * @extends {Record}
 * @property {List} gallery - list of photos
 */
export default class GalleryList extends Record({
  gallery: List(),
}) {
  /**
   * @constructor
   * @param {{gallery:[]}} param0
   */
  constructor({gallery = [], ...props}: GalleryListProps) {
    super({
      gallery: List(gallery.map((photo) => new GalleryItem(photo))),
      ...props,
    });
  }
}
