import {
  CompanyDTO,
  CompanyMapper,
  EmployeeDTO,
  EmployeeMapper,
  IProductCategoryModelReturnType,
  ICompanyDTOProps,
  IEmployeeDTOProps,
  ProductCategoryMapper,
} from '../internal';
import {WithoutRecursion} from '../type';
import {listToArray} from '../../services/helpers';
import {v4 as uuidv4} from 'uuid';

export interface UnitPackaging {
  uuid?: string;
  index?: number;
  unit: string;
  base_unit_coefficient: number;
  base_cost_coefficient: number;
}

export enum ProductCategoryType {
  LIST = 'list',
  TREE = 'tree',
}

export enum ProductCategoryMappedType {
  PRODUCT_TREE = 'product tree',
  FORMS_TREE = 'forms tree',
  SUB_CATEGORY_LIST = 'sub category list',
  SALE_LIST = 'sale list',
}

export const MAPPED_PRODUCT_CATEGORY_TYPES = [
  ProductCategoryType.TREE,
  ProductCategoryType.LIST,
  ProductCategoryMappedType.PRODUCT_TREE,
  ProductCategoryMappedType.FORMS_TREE,
  ProductCategoryMappedType.SUB_CATEGORY_LIST,
  ProductCategoryMappedType.SALE_LIST,
];

export const INVERT_MAPPED_PRODUCT_CATEGORY_TYPES = {
  [ProductCategoryType.LIST]: ProductCategoryType.LIST,
  [ProductCategoryType.TREE]: ProductCategoryType.TREE,
  [ProductCategoryMappedType.PRODUCT_TREE]: ProductCategoryType.TREE,
  [ProductCategoryMappedType.FORMS_TREE]: ProductCategoryType.TREE,
  [ProductCategoryMappedType.SUB_CATEGORY_LIST]: ProductCategoryType.LIST,
  [ProductCategoryMappedType.SALE_LIST]: ProductCategoryType.LIST,
};

export interface IProductCategoryDTOProps {
  uuid: string;
  title: string;
  description: string;
  base_unit: string;
  pos: number;
  created_at: string;
  updated_at: string;
  created_by: IEmployeeDTOProps;
  company: ICompanyDTOProps;
  parent: IProductCategoryDTOProps;
  unit_packaging: UnitPackaging[];
  children: IProductCategoryDTOProps[] | null;
  logo_url: string;
  cover_url: string;
}

export class ProductCategoryDTO {
  public uuid: string;
  public title: string;
  public description: string;
  public pos: number;
  public created_at: string;
  public updated_at: string;
  public created_by: EmployeeDTO;
  public company: ICompanyDTOProps;
  public base_unit: string;
  public parent: ProductCategoryDTO;
  public unit_packaging: UnitPackaging[];
  public children: ProductCategoryDTO[] | null;
  public cover_url: string;
  public logo_url: string;

  constructor(
    props: IProductCategoryModelReturnType | IProductCategoryDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';
    this.title = props?.title || '';
    this.description = props?.description || '';
    this.pos = props?.pos || 0;
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
    this.base_unit = props?.base_unit || '';
    this.logo_url = props?.logo_url || '';
    this.cover_url = props?.cover_url || '';
    this.unit_packaging = (
      Array.isArray(props?.unit_packaging) ? props?.unit_packaging : []
    ).map((packaging, index) => ({
      ...packaging,
      uuid: uuidv4(),
      index,
    }));
    this.children =
      Array.isArray(props?.children) && props?.children?.length
        ? listToArray(
            (props?.children).map((product) =>
              ProductCategoryMapper.toProductCategoryDTO(product),
            ),
          )
        : null;

    this.created_by =
      withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
        ? (props?.created_by as EmployeeDTO)
        : EmployeeMapper.toEmployeeDTO(props?.created_by, [
            WithoutRecursion.client,
            ...withoutRecursion,
          ]);
    this.company =
      withoutRecursion.indexOf(WithoutRecursion.company) !== -1
        ? (props?.company as CompanyDTO)
        : CompanyMapper.toCompanyDTO(props?.company, [
            WithoutRecursion.client,
            ...withoutRecursion,
          ]);

    this.parent =
      withoutRecursion.indexOf(WithoutRecursion.category) !== -1
        ? (props?.parent as ProductCategoryDTO)
        : ProductCategoryMapper.toProductCategoryDTO(props?.parent, [
            WithoutRecursion.category,
            ...withoutRecursion,
          ]);
  }
}
