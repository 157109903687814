import {
  AbonementGroupModel,
  AbonementModel,
  ClientAbonementModel,
  ClientModel,
  EmployeeModel,
} from '../internal';
import {correctPrice, toMoment} from '../../services/helpers';
import {Moment} from 'moment';

export class ClientAbonementFormDTO {
  public uuid: string;
  public card_number: string;
  public start_date: string | Moment;
  public final_price: string;
  public comment: string;
  public discount: number;
  public send_notification: boolean;
  public company_abonement_uuid: AbonementModel | string;
  public manager_uuid: EmployeeModel | string;
  public client_uuid?: ClientModel | string;
  public abonement_group_uuid?: AbonementGroupModel | string;

  constructor(props?: ClientAbonementModel, editMode?: boolean) {
    this.uuid = props?.uuid || '';
    this.card_number = props?.abon_card_number || '';
    this.comment = props?.abon_comment || '';
    this.discount = props?.abon_discount || 0;
    this.final_price = correctPrice(props?.abon_final_price || 0);
    this.send_notification =
      typeof props?.abon_send_notification === 'boolean'
        ? props?.abon_send_notification
        : false;
    this.start_date = props?.abon_start_date
      ? toMoment(props?.abon_start_date, 'YYYY-MM-DD')
      : toMoment(new Date(), 'YYYY-MM-DD');
    this.company_abonement_uuid = props?.companyAbonementModel?.uuid
      ? props?.companyAbonementModel
      : '';
    this.manager_uuid = editMode
      ? props?.managerModel?.uuid
        ? props?.managerModel
        : ''
      : props?.manager?.uuid || '';

    this.abonement_group_uuid = props?.companyAbonementGroupModel?.uuid
      ? props?.companyAbonementGroupModel
      : '';

    this.client_uuid = props?.clientModel?.uuid ? props?.clientModel : '';
  }
}
