import {
  IProfileDTOProps,
  ProfileDTO,
  ProfileFormDTO,
  ProfileModel,
} from '../internal';
import {WithoutRecursion} from '../type';

export class ProfileMapper {
  public static toProfileDTO(
    profile: ProfileModel | IProfileDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): ProfileDTO {
    return new ProfileDTO(profile, [
      WithoutRecursion.profile,
      ...withoutRecursion,
    ]);
  }

  public static toProfileModel(
    profileDTO: ProfileDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): ProfileModel {
    return new ProfileModel(profileDTO, [
      WithoutRecursion.profile,
      ...withoutRecursion,
    ]);
  }

  public static toProfileFormDTO(profile?: ProfileModel): ProfileFormDTO {
    return new ProfileFormDTO(profile);
  }
}
