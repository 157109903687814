import React from 'react';
import {LoginForm} from './Forms';
import {LoginTabPanel} from './Show';
import {AuthRouteLayout} from '../Show';
import {AuthPageWrapper} from '../common';
import {useLocation} from 'react-router';
import AuthLayout from '../Show/AuthLayout';
import {Routes} from '../../../services/types';
import {RootState} from '../../../store/reducers';
import {withTranslation, WithTranslation} from 'react-i18next';
import {LoginDTO, LoginFormMapper} from '../../../struture';
import {useStoreVerificationFactory, useLogin} from '../../../hooks';

import {
  ISignupPageState,
  IForgotPasswordPageState,
} from '../../../store/actions';

export default withTranslation()(LoginPage);

export interface ILoginPageProps extends WithTranslation {}

function LoginPage({t}: ILoginPageProps): JSX.Element {
  const location = useLocation();

  const {identifier: signupIdentifier} = useStoreVerificationFactory<
    Pick<ISignupPageState, 'identifier'>
  >({
    key: Routes.signup,
    getState: (state: RootState) => ({
      identifier: state[Routes.signup]?.identifier,
    }),
  });

  const {identifier: forgotPasswordIdentifier} = useStoreVerificationFactory<
    Pick<IForgotPasswordPageState, 'identifier'>
  >({
    key: Routes.forgotPassword,
    getState: (state: RootState) => ({
      identifier: state[Routes.forgotPassword]?.identifier,
    }),
  });

  const {handleLogin, notifyError} = useLogin();

  return (
    <AuthLayout>
      <LoginTabPanel
        showForgotPasswordWarning={!!forgotPasswordIdentifier}
        showSignupWarning={!!signupIdentifier}>
        {({routes}) => (
          <AuthRouteLayout
            header={
              <>
                <AuthPageWrapper
                  title={t('The authorization form')}
                  description={t(
                    'To manage orders, organize staff and manage mailings to Customers.',
                  )}>
                  <LoginForm
                    notifyError={notifyError}
                    showNotify={false}
                    notifyFormName={t('Authorization')}
                    login={LoginFormMapper.toLoginFormDTO(new LoginDTO())}
                    additionalValuesRequest={{invite_id: location.state}}
                    onSuccess={handleLogin}
                  />
                </AuthPageWrapper>
              </>
            }
            routes={routes}
          />
        )}
      </LoginTabPanel>
    </AuthLayout>
  );
}
