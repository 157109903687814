import React from 'react';
import {IDefaultTabPanelProps} from '../../../components/lib/General';
import {PERSONALITY_TYPE, Routes, RoutesAcl} from '../../../services/types';
import {useAcl} from '../../../contex';
import {useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import {AbonementModel} from '../../../struture';

export enum TARIFF_MODEL_TABS {
  ABONEMENTS = 'Client abonements',
  GROUP = 'Groups',
}

export interface ITariffModelTabPanelProps {
  children: (value: Pick<IDefaultTabPanelProps, 'tabs'>) => React.ReactNode;
  abonement: AbonementModel | null;
}

export function TariffModelTabPanel({
  children,
  abonement,
}: ITariffModelTabPanelProps) {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {abonement: abonementAccess} = useAcl((acl) => acl);

  const abonementTabs: any = [
    ...(abonementAccess?.read
      ? [
          {
            key: TARIFF_MODEL_TABS.ABONEMENTS,
            title: t(TARIFF_MODEL_TABS.ABONEMENTS),
            disabled: false,
            refresh: () => {},
            navigation: () => {
              navigate(Routes.clientAbonements);
            },
            renderer: () => <></>,
            aclItem: RoutesAcl[Routes.tariffModels],
          },
        ]
      : []),
    ...(abonement?.serviceModel?.personality === PERSONALITY_TYPE.GROUP
      ? [
          {
            key: TARIFF_MODEL_TABS.GROUP,
            title: t(TARIFF_MODEL_TABS.GROUP),
            disabled: false,
            refresh: () => {},
            navigation: () => {
              navigate(Routes.tariffModelGroup);
            },
            renderer: () => <></>,
            aclItem: RoutesAcl[Routes.tariffModels],
          },
        ]
      : []),
  ];

  return <>{children({tabs: abonementTabs})}</>;
}
