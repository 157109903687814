import React, {useMemo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {Col, Form, Input, Row} from 'antd';
import {
  IDefaultFormProps,
  DefaultForm,
  DatePicker,
} from '../../../components/lib/General';
import {RewardFormDTO} from '../../../struture';
import {useDropdownAlert} from '../../../contex';
import {useStoredCompanies} from '../../../hooks';

export interface IRewardFormProps
  extends Omit<
    IDefaultFormProps<RewardFormDTO, RewardFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  reward: RewardFormDTO;
}

export function RewardForm({
  loading,
  editMode,
  reward,
  onCancel,
  ...rest
}: IRewardFormProps) {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const {defaultCompany} = useStoredCompanies();
  const [instance] = Form.useForm();

  const additionalValues = useMemo(
    () => ({
      uuid: reward?.uuid,
    }),
    [reward?.uuid],
  );

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Reward'),
        `${
          editMode
            ? t('An error occurred during edit reward')
            : t('An error occurred during create reward')
        } : ${apiError?.message}`,
      );
    },
    [alert, t, editMode],
  );

  return (
    <DefaultForm<any, any>
      instance={instance}
      editMode={editMode}
      initialValues={{
        ...reward,
      }}
      additionalValuesRequest={additionalValues}
      showNotify={false}
      onCancel={onCancel}
      notifyError={notifyError}
      {...rest}>
      {({loadingSubmit}) => (
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              label={t('Date and time')}
              name="date"
              rules={[
                {
                  required: true,
                  message: t('Date and time must be specified'),
                },
              ]}
              tooltip={t('Date and time')}>
              <DatePicker
                showTime
                format={'DD.MM.YYYY HH:mm'}
                disabled={loading || loadingSubmit}
                placeholder={t('Select date and time')}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('Sum')}
              name="sum"
              required
              rules={[
                () => ({
                  validator(_, price) {
                    const value = price;

                    if (Number.isNaN(Number(value))) {
                      return Promise.reject(
                        new Error(t('Sum must be a number')),
                      );
                    }

                    if (!Number(value)) {
                      return Promise.reject(
                        new Error(t('Sum must be specified')),
                      );
                    }

                    return Promise.resolve();
                  },
                }),
              ]}>
              <Input
                addonBefore={defaultCompany?.currency_symbol}
                disabled={loadingSubmit}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('Note')}
              name="comment"
              rules={[
                {
                  required: true,
                  message: t('Note must be specified'),
                },
              ]}>
              <Input.TextArea
                allowClear
                autoSize={{minRows: 2, maxRows: 5}}
                disabled={loading || loadingSubmit}
                placeholder={t('Enter your note')}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </DefaultForm>
  );
}
