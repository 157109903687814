import {
  IProfileDTOProps,
  ProfileDTO,
  CompanyDTO,
  ICompanyDTOProps,
  IServiceDTOProps,
  ServiceDTO,
  IWorkScheduleDTOProps,
  WorkScheduleDTO,
  DayOffDTO,
  CompanyMapper,
  DayOffMapper,
  ProfileMapper,
  ServiceMapper,
  WorkScheduleMapper,
  IEmployeeModelReturnType,
  InvitationDTOProps,
  InvitationDTO,
  InvitationMapper,
  EmployeeRole,
  IEmployeeServiceDTOProps,
  EmployeeServiceDTO,
  EmployeeServiceMapper,
  ICompanyOrderBonusDTOProps,
  CompanyOrderBonusDTO,
  IEmployeeSalaryDTOProps,
  EmployeeSalaryDTO,
  EmployeeMapper,
  EmployeeRoleType,
  EmployeeRoleCashierPermission,
  EmployeeRoleAdminPermission,
  EmployeeRoleManagerPermission,
} from '../internal';
import {WithoutRecursion} from '../type';
import {GENDER, toDateByFormat} from '../../services/helpers';
import {DATE_SHORT_FORMAT} from '../../services/const';
import {
  EMPLOYEE_DISABLED,
  EMPLOYEE_CREATED,
  EMPLOYEE_ACTIVATED,
  EMPLOYEE_CONFIRMED,
  EMPLOYEE_WAITING_FOR_CONFIRMATION,
  EMPLOYEE_CONFIRMATION_REJECTED,
} from '../../services/helpers';
import {correctPrice} from '@sportix/sportix-common-modules';

export enum EmployeeStatuses {
  Disabled = EMPLOYEE_DISABLED,
  Created = EMPLOYEE_CREATED,
  Activated = EMPLOYEE_ACTIVATED,
  Confirmed = EMPLOYEE_CONFIRMED,
  WaitingForConfirmation = EMPLOYEE_WAITING_FOR_CONFIRMATION,
  ConfirmationRejected = EMPLOYEE_CONFIRMATION_REJECTED,
  DELETED = -1,
  Unknown = -500,
}

export const EmployeeStatusForColor: any = {
  [EmployeeStatuses.Activated]: 'success',
  [EmployeeStatuses.Disabled]: 'disabled',
  [EmployeeStatuses.Created]: 'link',
  [EmployeeStatuses.Confirmed]: 'link',
  [EmployeeStatuses.WaitingForConfirmation]: 'warning',
  [EmployeeStatuses.ConfirmationRejected]: 'error',
  [EmployeeStatuses.Unknown]: 'default',
};

export const EmployeeStatusText = {
  [EmployeeStatuses.Activated]: 'Activated',
  [EmployeeStatuses.Disabled]: 'Disabled',
  [EmployeeStatuses.Created]: 'Created',
  [EmployeeStatuses.Confirmed]: 'Confirmed',
  [EmployeeStatuses.WaitingForConfirmation]: 'Waiting for confirmation',
  [EmployeeStatuses.ConfirmationRejected]: 'Confirmation rejected',
  [EmployeeStatuses.DELETED]: 'DELETED',
  [EmployeeStatuses.Unknown]: 'Unknown',
};

export const EmployeeStatusShows = [
  EmployeeStatuses.ConfirmationRejected,
  EmployeeStatuses.Disabled,
  EmployeeStatuses.WaitingForConfirmation,
];

export interface IEmployeeDTOProps {
  uuid: string;
  title: string;
  picture_url: string;
  email: string;
  gender: GENDER;
  phone: string;
  birthday: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  role: EmployeeRole;
  profile: IProfileDTOProps;
  company: ICompanyDTOProps;
  services: IServiceDTOProps[];
  work_schedule: IWorkScheduleDTOProps[];
  days_off: any[];
  invitation: InvitationDTOProps[];
  is_owner: boolean;
  status: EmployeeStatuses;
  status_text: string;
  created_at: string;
  updated_at: string;
  employee_service?: IEmployeeServiceDTOProps;
  bonus_list: ICompanyOrderBonusDTOProps[];

  balance: string;
  salary_settings: IEmployeeSalaryDTOProps[];
  roles: EmployeeRole[];
  role_permissions: any[];
}

export class EmployeeDTO {
  public uuid: string;

  public title: string;

  public picture_url: string;

  public email: string;

  public gender: GENDER;

  public phone: string;

  public birthday: string;

  public first_name: string;

  public last_name: string;

  public middle_name: string;

  public role: EmployeeRole;

  public profile: ProfileDTO;

  public company: CompanyDTO;

  public services: ServiceDTO[];

  public work_schedule: WorkScheduleDTO[];

  public days_off: DayOffDTO[];

  public invitation: InvitationDTO[];

  public is_owner: boolean;

  public status: number;
  public status_text: string;

  public created_at: string;

  public updated_at: string;

  public employee_service?: EmployeeServiceDTO;
  public bonus_list: CompanyOrderBonusDTO[];
  public balance: string;
  public salary_settings: EmployeeSalaryDTO[];
  public roles: EmployeeRole[];
  public role_permissions: EmployeeRoleType<
    | EmployeeRoleCashierPermission
    | EmployeeRoleAdminPermission
    | EmployeeRoleManagerPermission
  >[];

  constructor(
    props: IEmployeeModelReturnType | IEmployeeDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid;
    this.title = props?.title;
    this.picture_url = props?.picture_url;
    this.email = props?.email;
    this.gender = props?.gender;
    this.phone = props?.phone;
    this.balance = correctPrice(props?.balance || 0);
    this.role = props?.role || EmployeeRole.Manager;
    this.birthday = props?.birthday
      ? toDateByFormat(props?.birthday, DATE_SHORT_FORMAT)
      : '';
    this.first_name = props?.first_name;
    this.last_name = props?.last_name;
    this.middle_name = props?.middle_name;
    this.is_owner = props?.is_owner;
    this.status =
      typeof props?.status === 'number'
        ? props?.status
        : EmployeeStatuses.Unknown;
    this.status_text = props?.status_text || '';
    this.created_at = props?.created_at;
    this.updated_at = props?.updated_at;
    this.bonus_list = props?.bonus_list || [];
    this.roles = props?.roles || [EmployeeRole.Manager];
    this.role_permissions = props?.role_permissions || [];

    this.employee_service = props?.employee_service
      ? EmployeeServiceMapper.toEmployeeServiceDTO(props?.employee_service, [
          WithoutRecursion.service,
          ...withoutRecursion,
        ])
      : undefined;

    this.invitation = InvitationMapper.toInvitationListDTO(props?.invitation);

    this.profile = ProfileMapper.toProfileDTO(props?.profile, [
      WithoutRecursion.employee,
      ...withoutRecursion,
    ]);

    this.work_schedule = WorkScheduleMapper.toWorkScheduleListDTO(
      props?.work_schedule || [],
    );
    this.days_off = DayOffMapper.toDayOffListDTO(
      props?.days_off || [],
      0,
    ).dayOff;

    this.company =
      withoutRecursion.indexOf(WithoutRecursion.company) !== -1
        ? (props?.company as CompanyDTO)
        : CompanyMapper.toCompanyDTO(props?.company, [
            WithoutRecursion.employee,
            ...withoutRecursion,
          ]);
    this.services =
      withoutRecursion.indexOf(WithoutRecursion.service) !== -1
        ? ((props?.services || []) as ServiceDTO[])
        : ServiceMapper.toServiceListDTO(props?.services || [], 0, [
            WithoutRecursion.employee,
            ...withoutRecursion,
          ]).services;

    this.salary_settings = EmployeeMapper.toEmployeeSalaryListDTO(
      props?.salary_settings,
      (props?.salary_settings as any)?.length || 0,
    ).salary;
  }
}
