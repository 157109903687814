import React, {useRef} from 'react';
import {
  InvoiceItemModel,
  InvoiceItemPositionableType,
  ProductModel,
  ServiceModel,
} from '../../../struture';
import styled, {useTheme} from 'styled-components';
import {Routes} from '../../../services/types';
import {useLocation, useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import {useAcl} from '../../../contex';
import {Link} from '../../../components/lib/DataDisplay';
import {StyledTitle} from '../../../components/lib/Styled';
import {Tag} from 'antd';
import {CALENDAR_ENTRY} from '../../../components/lib/const';
import {ServiceTourView} from '../../Services';
import {ProductTourView} from '../../Products';
import {useModal} from '../../../components/lib/libV2/hooks';

export interface InvoiceItemDetailsViewProps {
  item: InvoiceItemModel | null;
  children?: React.ReactNode | React.ReactNode[];
  to?: string;
  onlyTitle?: boolean;
  withoutNavigate?: boolean;
  price?: string;
  withTour?: boolean;
}

const StyledSpace = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 5px;
`;

const StyledLink = styled(Link)`
  align-items: center;
`;

const StyledTitleContainer = styled.div<{}>`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const StyledTag = styled(Tag)`
  display: flex;
  gap: 10px;

  width: fit-content;
  cursor: pointer;
`;

const InvoiceItemTourStrategy = {
  [InvoiceItemPositionableType.SERVICE]: ServiceTourView,
  [InvoiceItemPositionableType.PRODUCT]: ProductTourView,
  [InvoiceItemPositionableType.ORDER]: null,
  [InvoiceItemPositionableType.STORE]: null,
  [InvoiceItemPositionableType.CUSTOM]: null,
};

export function InvoiceItemDetailsView({
  item,
  to,
  onlyTitle,
  withoutNavigate = false,
  price,
  withTour,
}: InvoiceItemDetailsViewProps): JSX.Element {
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const {manage} = useAcl(({invoices}) => invoices);
  const theme: any = useTheme();

  const type = item?.item_positionable_type!;
  const contentRef = useRef(null);

  const {visible, handleOnInit, handleCancel} = useModal();

  const itemTagColors = {
    [InvoiceItemPositionableType.SERVICE]: theme.colors.slateBlue,
    [InvoiceItemPositionableType.PRODUCT]: theme.colors.darkOrchid,
    [InvoiceItemPositionableType.ORDER]: theme.colors.greyBlackberry,
    [InvoiceItemPositionableType.STORE]: theme.colors.yellowGold,
    [InvoiceItemPositionableType.CUSTOM]: theme.colors.primary,
  };

  const itemTagTitles = {
    [InvoiceItemPositionableType.SERVICE]: 'Services',
    [InvoiceItemPositionableType.PRODUCT]: 'Goods and materials',
    [InvoiceItemPositionableType.ORDER]: 'Orders',
    [InvoiceItemPositionableType.STORE]: 'Sales invoices',
    [InvoiceItemPositionableType.CUSTOM]: 'Custom',
  };

  const itemNavigateLink = {
    [InvoiceItemPositionableType.SERVICE]: `/${Routes.app}/${Routes.services}/${
      (item?.item_positionable_object as any)?.uuid
    }`,
    [InvoiceItemPositionableType.PRODUCT]: `/${Routes.app}/${Routes.goods}/${
      (item?.item_positionable_object as any)?.uuid
    }`,
    [InvoiceItemPositionableType.ORDER]: `/${Routes.app}/${Routes.orders}/${
      (item?.item_positionable_object as any)?.uuid
    }/${Routes.schedules}/${Routes.calendar}/${CALENDAR_ENTRY}/${
      (item?.item_positionable_object as any)?.uuid
    }`,
    [InvoiceItemPositionableType.STORE]: `/${Routes.app}/${Routes.stores}/${
      Routes.storeRealization
    }/${(item?.item_positionable_object as any)?.uuid}`,
    [InvoiceItemPositionableType.CUSTOM]: 'Custom',
  };

  const InvoiceTourView = InvoiceItemTourStrategy[type];

  return (
    <>
      {item ? (
        <StyledSpace>
          <StyledTitleWrapper ref={contentRef}>
            {manage && !withoutNavigate ? (
              <StyledLink
                onClick={(e) => {
                  e?.preventDefault();
                  e?.stopPropagation();

                  if (withTour) {
                    handleOnInit();
                  } else {
                    navigate(to || itemNavigateLink[type], {
                      state: {goBack: location.pathname},
                    });
                  }
                }}
                className="item-list--link"
                to={''}
                tooltip={t('Go to details')}
                state={{goBack: location.pathname}}>
                <StyledTitleContainer className="item-list--link">
                  {item?.item_title}
                </StyledTitleContainer>
              </StyledLink>
            ) : (
              <StyledTitle>{item?.item_title}</StyledTitle>
            )}
          </StyledTitleWrapper>

          {onlyTitle ? null : (
            <StyledTag color={itemTagColors[type]}>
              {t(itemTagTitles[type])}
            </StyledTag>
          )}
        </StyledSpace>
      ) : null}

      {withTour && InvoiceTourView ? (
        <InvoiceTourView
          service={item?.item_positionable_object as ServiceModel}
          product={item?.item_positionable_object as ProductModel}
          visible={visible}
          handleCancel={handleCancel}
          price={price}
          ref={contentRef}
        />
      ) : null}
    </>
  );
}
