import styled, {css} from 'styled-components';
import {FlexContainer} from './FlexContainer';

export const StatusContainer = styled(FlexContainer)<{status: string}>`
  width: 100%;
  height: 25%;
  padding: 0 20px;
  background-color: ${({theme}) => theme.colors.primary};

  & .status__title {
    color: ${({theme}) => theme.colors.app};
  }
`;
