import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {IActionButtonProps} from '../../../components/lib/General';
import {
  ClientFormDTO,
  ClientGroupFormDTO,
  ClientLegalFormDTO,
  ClientModel,
} from '../../../struture';
import {useModal} from '../../../components/lib/libV2/hooks';
import {EditButton, SuspenseEmpty} from '../../../components/lib/DataDisplay';
import {ClientPersonalityType} from '../../../services/types';
import {ClientSuccess, ClientLegalSuccess} from './ClientCreateButton';

const ClientSideWindowLazy = React.lazy(
  () => import('../Show/ClientSideWindow'),
);

const ClientGroupSideWindowLazy = React.lazy(
  () => import('../Show/ClientGroupSideWindow'),
);

type GroupSuccess = (value: ClientGroupFormDTO) => Promise<ClientModel | void>;

export interface IClientEditButtonProps
  extends Omit<IActionButtonProps, 'children'> {
  onSuccess: ClientSuccess | ClientLegalSuccess;
  onSuccessGroup?: GroupSuccess;
  onCancel?: () => void;
  children?: React.ReactNode;
  client: ClientFormDTO | ClientGroupFormDTO | ClientLegalFormDTO;
  clientType: ClientPersonalityType;
}

export default function ClientEditButton({
  children,
  client,
  onSuccess,
  onCancel,
  onSuccessGroup,
  clientType,
  ...rest
}: IClientEditButtonProps) {
  const {t} = useTranslation();

  const {
    handleCancel: clientHandleCancel,
    handleOnInit: clientHandleOnInit,
    handleSuccess: clientHandleSuccess,
    visible: clientVisible,
  } = useModal({
    onCancel,
    onSuccess: onSuccess as any,
  });

  const {
    handleCancel: groupHandleCancel,
    handleOnInit: groupHandleOnInit,
    handleSuccess: groupHandleSuccess,
    visible: groupVisible,
  } = useModal({
    onCancel,
    onSuccess: onSuccessGroup,
  });

  return (
    <>
      <EditButton
        tooltipTitle={
          clientType === ClientPersonalityType.Individual
            ? t('Edit client')
            : t('Edit client group')
        }
        onClick={
          clientType === ClientPersonalityType.Individual
            ? clientHandleOnInit
            : groupHandleOnInit
        }
        disabled={false}
        {...rest}
      />

      {clientVisible ? (
        <Suspense fallback={<SuspenseEmpty />}>
          <ClientSideWindowLazy
            editMode
            onSuccess={clientHandleSuccess}
            onCancel={clientHandleCancel}
            client={client as ClientFormDTO | ClientLegalFormDTO}
            visible={clientVisible}
          />
        </Suspense>
      ) : null}

      <Suspense fallback={<SuspenseEmpty />}>
        <ClientGroupSideWindowLazy
          editMode
          onSuccess={groupHandleSuccess}
          onCancel={groupHandleCancel}
          client={client as ClientGroupFormDTO}
          visible={groupVisible}
        />
      </Suspense>
    </>
  );
}
