import React, {useCallback} from 'react';
import {ReportingTabPanel} from './Show';
import {OverviewLayout, SingleLayout} from '../../components/lib/Layout';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '../../services/types';
import {HeaderLeft} from '../../components/lib/DataDisplay';
import {textToUpperCase} from '../../services/helpers';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';
import {useStoredCompanies, useStoredProfile} from '../../hooks';
import {useBreadcrumb} from '../../components/lib/libV2/hooks';

export function ReportingPanelPage(): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {defaultCompanyUuid, defaultCompanyLoading} = useStoredCompanies();
  const {loading: profileLoading} = useStoredProfile();

  const routes = useBreadcrumb([
    {
      path: `/${Routes.reports}`,
      breadcrumbName: 'Reporting',
    },
  ]);

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes?.companies}/${defaultCompanyUuid}`);
  }, [defaultCompanyUuid, navigate]);

  return (
    <SingleLayout
      loading={defaultCompanyLoading || profileLoading}
      aclItem={RoutesAcl[Routes.reports]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.reports]))}
      routes={routes}>
      <ReportingTabPanel>
        {({tabs}) => (
          <OverviewLayout autoLayout tabs={tabs} data={null} loading={false} />
        )}
      </ReportingTabPanel>
    </SingleLayout>
  );
}
