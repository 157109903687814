import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {Col, Form, Input, Row} from 'antd';
import {SearchSelect} from '../../../../components/lib/DataDisplay';
import {
  listToArray,
  toDateByFormat,
  correctPrice,
} from '../../../../services/helpers';
import {
  ClientFormDTO,
  ClientLegalFormDTO,
  ClientMapper,
  ClientModel,
  ClientOrgType,
  StoreDocumentModel,
  StoreDocumentType,
  StoreModel,
} from '../../../../struture';
import {
  IUseStateCompanyClientListReturnType,
  IUseStateStoreDocumentListReturnType,
  useStoredCompanies,
} from '../../../../hooks';
import {useTranslation} from 'react-i18next';
import {useDefaultForm} from '../../../../contex';
import {ClientCreateButton} from '../../../Clients';
import {PlusOutlined} from '@ant-design/icons';
import {
  StyledDescription,
  StyledTitle,
} from '../../../../components/lib/Styled';
import styled, {useTheme} from 'styled-components';
import {StoreDocumentRefObject} from '../../helpers';
import {RelatedReturnDocumentTypeComment} from '../../../../hooks/useStateStoreDocumentRelatedDocumentList';

export interface IStoreReturnClientFieldsProps
  extends Pick<
      IUseStateCompanyClientListReturnType,
      'clients' | 'handleSearchClients' | 'handleCreateLocalClient'
    >,
    Pick<
      IUseStateStoreDocumentListReturnType,
      'documentList' | 'handleSearchStoreDocuments'
    > {
  loading: boolean;
  editMode: boolean;
  defaultEditMode: boolean;
  clientsLoading: boolean;

  documentListLoading: boolean;
  from?: StoreDocumentType;
  documentEditRef?: MutableRefObject<StoreDocumentRefObject>;
}

const StyledDocContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export function StoreReturnClientFields({
  loading,
  editMode,
  defaultEditMode,
  from,

  clients,
  clientsLoading,
  handleCreateLocalClient,
  handleSearchClients,

  documentList,
  documentListLoading,
  handleSearchStoreDocuments,
  documentEditRef,
}: IStoreReturnClientFieldsProps): React.JSX.Element {
  const {t} = useTranslation();
  const theme: any = useTheme();
  const {loadingSubmit, formData, handlerUpdateFormState}: any =
    useDefaultForm();
  const {defaultCompany} = useStoredCompanies();

  const [disabledClient, setDisabledClient] = useState(false);
  const [documentStore, setDocumentStore] = useState<StoreModel | null>(
    formData?.doc_store_uuid instanceof StoreModel
      ? formData?.doc_store_uuid
      : null,
  );

  const onceStore = useRef(!editMode);
  const onceClient = useRef(false);

  const onSaleInvoiceChange = useCallback(
    (doc_related_document_uuid: string) => {
      const relatedDoc = documentList?.find(
        ({uuid}) => doc_related_document_uuid === uuid,
      );

      if (onceStore?.current) {
        if (relatedDoc?.companyStoreModel) {
          setDocumentStore(relatedDoc?.companyStoreModel);
        } else {
          setDocumentStore(null);
        }
      }

      onceStore.current = true;

      handlerUpdateFormState({doc_related_document_uuid});

      if (relatedDoc) {
        handlerUpdateFormState({
          doc_comment: `${t('Return to the customer on the invoice')} №${
            relatedDoc?.doc_local_number
          } ${t('From').toLowerCase()} ${toDateByFormat(
            relatedDoc?.doc_date,
            'DD.MM.YYYY HH:mm',
          )}`,
        });
      }

      if (from === StoreDocumentType.OUT && formData?.doc_client?.uuid) {
        setDisabledClient(true);
      } else if (relatedDoc && relatedDoc?.clientModel?.uuid) {
        if (defaultEditMode) {
          handlerUpdateFormState({
            doc_store_uuid: relatedDoc?.company_store?.uuid,
          });
        } else {
          handlerUpdateFormState({
            doc_client: relatedDoc?.clientModel,
            doc_store_uuid: relatedDoc?.company_store?.uuid,
          });
        }

        setDisabledClient(true);
      } else if (relatedDoc && !relatedDoc?.clientModel?.uuid) {
        handlerUpdateFormState({
          doc_client: {uuid: ''},
          doc_store_uuid: relatedDoc?.company_store?.uuid,
        });
        setDisabledClient(false);
      }
    },
    [
      defaultEditMode,
      documentList,
      formData?.doc_client?.uuid,
      from,
      handlerUpdateFormState,
      t,
    ],
  );

  const handleQuicklyCreateClient = useCallback(
    async ({t, ...value}: any): Promise<void> => {
      const uuid = `create${
        (value as ClientFormDTO)?.first_name ||
        (value as ClientLegalFormDTO)?.client_org_name
      }${value.phone}`;

      await handleCreateLocalClient({
        ...value,
        uuid,
      } as any);

      handlerUpdateFormState({
        doc_client: {
          ...value,
          uuid,
        },
      });
    },
    [handleCreateLocalClient, handlerUpdateFormState],
  );

  const handleChangeClient = useCallback(
    (e: string): void => {
      onceClient.current = true;

      if (
        formData?.doc_client?.uuid !== e ||
        formData?.doc_client instanceof ClientModel
      ) {
        if (e?.includes('create')) {
          const client = clients?.find(({uuid}) => uuid === e);

          if (client) {
            let doc_client;
            if (client?.client_org_type === ClientOrgType.PERSON) {
              doc_client = ClientMapper.toClientFormDTO(client);
            } else {
              doc_client = ClientMapper.toClientLegalFormDTO(client);
            }

            handlerUpdateFormState({doc_client});
          }
          return;
        }

        handlerUpdateFormState({doc_client: {uuid: e}});
      }
    },
    [clients, formData?.doc_client, handlerUpdateFormState],
  );

  useEffect(() => {
    if (formData?.doc_store_uuid instanceof StoreModel) {
      handlerUpdateFormState({doc_store_uuid: formData?.doc_store_uuid?.uuid});
    }
  }, [formData?.doc_store_uuid, handlerUpdateFormState]);

  useEffect(() => {
    if (documentEditRef?.current) {
      const {errorFields, ...rest} = formData;

      documentEditRef.current.document = rest;
    }
  }, [documentEditRef, formData]);

  return (
    <Row gutter={20}>
      <Col span={24}>
        <Form.Item
          label={t('Sales invoice')}
          name="doc_related_document_uuid"
          tooltip={t('Sales invoice')}
          rules={[
            {
              required: true,
              message: t('Sales invoice be specified.'),
            },
          ]}
          extra={
            documentStore?.uuid ? (
              <StyledDescription $color={theme.colors.success}>
                {`${t('Store')}: "${documentStore?.store_name}"`}
              </StyledDescription>
            ) : null
          }>
          <SearchSelect
            selectFirst={false}
            selectIfOnFirst={false}
            name="doc_related_document_uuid"
            disable={
              loading ||
              documentListLoading ||
              loadingSubmit ||
              from === StoreDocumentType.OUT
            }
            placeholder={t('Select a sales invoice')}
            data={listToArray(documentList as any)}
            getOptionValueTitle={({
              doc_local_number,
              clientModel,
              doc_date,
              doc_sum_paid,
              doc_sum_total,
            }: StoreDocumentModel) => {
              const paid = Number(doc_sum_paid) || 0;
              const total = Number(doc_sum_total) || 0;

              const isPaid = paid > 0 && paid >= total;

              return (
                <>
                  <StyledDocContainer>
                    <StyledTitle>{`№ ${doc_local_number}`}</StyledTitle>
                    <StyledTitle
                      $color={
                        from === StoreDocumentType.OUT
                          ? undefined
                          : theme.colors.secondary
                      }>{` / ${
                      clientModel?.fullNameClient?.trim() || ''
                    }`}</StyledTitle>
                  </StyledDocContainer>
                  <StyledDocContainer>
                    <StyledDescription>
                      {`${t('From')} ${toDateByFormat(doc_date, 'DD.MM.YYYY')}`}
                    </StyledDescription>
                    <StyledDescription
                      $color={
                        isPaid ? theme.colors.success : theme.colors.error
                      }>{`( ${
                      defaultCompany?.currency_symbol || ''
                    } ${correctPrice(total)}, ${
                      isPaid ? t('Paid').toLowerCase() : t('unpaid')
                    } )`}</StyledDescription>
                  </StyledDocContainer>
                </>
              );
            }}
            onSearch={(keywords) =>
              handleSearchStoreDocuments({
                keywords,
                showLoading: false,
                start_date: null,
                end_date: null,
              }) as any
            }
            resetState={documentListLoading}
            onChange={onSaleInvoiceChange}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          // required={editMode}
          label={t('Client')}
          name="doc_client"
          tooltip={t('Client')}
          // rules={[
          //   {
          //     validator: () => {
          //       if (!editMode) {
          //         return Promise.resolve();
          //       }
          //
          //       if (!formData?.doc_client?.uuid) {
          //         return Promise.reject(
          //           new Error(t('Client must be specified.')),
          //         );
          //       }
          //       return Promise.resolve();
          //     },
          //   },
          // ]}
        >
          <SearchSelect
            asObject={true}
            selectFirst={clients?.size === 1}
            name="doc_client"
            disable={
              loading || clientsLoading || loadingSubmit || disabledClient
            }
            placeholder={t('Select a client')}
            data={listToArray(clients as any)}
            valuePropName="uuid"
            getOptionValueTitle="fullNameClient"
            onSearch={(keywords) => handleSearchClients({keywords}) as any}
            resetState={clientsLoading}
            onChange={handleChangeClient}
            addonAfter={
              <ClientCreateButton
                provider
                disabled={
                  loading || clientsLoading || loadingSubmit || disabledClient
                }
                onSuccess={handleQuicklyCreateClient}
                autoFocus>
                <PlusOutlined />
              </ClientCreateButton>
            }
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item label={t('Note')} name="doc_comment">
          <Input.TextArea
            allowClear
            autoSize={{minRows: 2, maxRows: 5}}
            disabled={loading || loadingSubmit}
            placeholder={t('Enter your note')}
            onChange={(e) =>
              handlerUpdateFormState({doc_comment: e?.target?.value})
            }
          />
        </Form.Item>
      </Col>
    </Row>
  );
}
