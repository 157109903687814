import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {Text} from '../../../components/lib/Format';

export interface IEmployeeRegistrationButtonProps {
  onClick: () => void | Promise<void>;
  icon?: React.ReactNode;
  title: string | React.ReactNode;
  endIcon: React.ReactNode;
}

const StyledButton = styled(Button)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  height: auto;
  background-color: rgba(64, 64, 65, 0.2);
  border-radius: 10px;
  white-space: pre-wrap;
  margin-top: 5px;

  &:active,
  &:focus {
    background-color: rgba(64, 64, 65, 0.2);
  }

  &:hover {
    background-color: rgba(64, 64, 65, 0.1);
  }
`;

const StyledLink = styled(Text)`
  font-size: 20px;
  color: ${({theme}) => theme.colors.link};
`;

export function EmployeeRegistrationButton({
  onClick,
  icon,
  title,
  endIcon,
}: IEmployeeRegistrationButtonProps): JSX.Element {
  return (
    <StyledButton type="text" onClick={onClick}>
      <StyledLink>
        {icon || null}
        &nbsp;{title}
      </StyledLink>
      <StyledLink>{endIcon}</StyledLink>
    </StyledButton>
  );
}
