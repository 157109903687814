import {Record, List} from 'immutable';
import {ListModel, ListProps} from './List';
import CompanySetting, {ICompanySettingProps} from './CompanySetting';

export interface ICompanySettingListProps extends ListProps {
  settings: ICompanySettingProps[];
}

/**
 * @class CategoryList
 * @classdesc Category list model.
 * @extends {Record}
 * @property {List} categories - list of categories
 * @property {number} total - total amount of records on backend
 */
export default class CompanySettingList extends Record({
  settings: List(),
  ...ListModel.toJS(),
}) {
  /**
   * @constructor
   * @param {{categories:[]}} param0
   */
  constructor({settings, ...props}: ICompanySettingListProps) {
    super({
      settings: List(settings.map((setting) => new CompanySetting(setting))),
      ...props,
    });
  }
}
