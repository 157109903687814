import {Record, List} from 'immutable';
import {ListModel, ListProps} from './List';
import SystemSubscription, {
  ISystemSubscriptionProps,
} from './SystemSubscription';

export interface ISystemSubscriptionListProps extends Partial<ListProps> {
  subscriptions: ISystemSubscriptionProps[];
}

export interface ISystemSubscriptionListReturnType
  extends Omit<ISystemSubscriptionListProps, 'subscriptions'> {
  subscriptions: List<ISystemSubscriptionProps[]>;
}

export default class SystemSubscriptionList extends Record<ISystemSubscriptionListReturnType>(
  {
    subscriptions: List(),
    ...ListModel.toJS(),
  } as any,
) {
  constructor({
    subscriptions = [] as any,
    ...props
  }: ISystemSubscriptionListReturnType) {
    const options: any = {};

    if (Array.isArray(subscriptions)) {
      options.subscriptions = List(
        subscriptions.map(
          (subscription: ISystemSubscriptionProps) =>
            new SystemSubscription(subscription),
        ),
      );
    }

    super({
      ...props,
      ...options,
    });
  }
}
