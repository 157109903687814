import * as React from 'react';
import {useCallback, useEffect} from 'react';
import {
  IUseProductProps,
  IUseProductReturnType,
  useProduct,
} from './useProduct';
import {
  ProductFormDTO,
  ProductMapper,
  ProductModel,
  ProductType,
} from '../struture';
import {
  changeProductStatus,
  createProduct,
  editProduct,
  setProductPhotoByDefault,
} from '../services/api/product';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../store/reducers';
import {REDUX_STATUS} from '../services/types';
import {
  resetProduct as storeResetProduct,
  setProduct as storeSetProduct,
  updateProduct as storeUpdateProduct,
  updateProductCategoryProductFromList as storeUpdateProductCategoryProductToList,
} from '../store/actions';
import {toStringDate} from '../services/helpers';
import {useStoredProductList} from './useStoredProductList';
import {List} from 'immutable';
import {useDropdownAlert} from '../contex';
import {useTranslation} from 'react-i18next';
import {
  addProductToList as storeAddProduct,
  updateProductFromList as storeUpdateProductFromList,
} from '../store/features/productListSlice';
import {setProductCategoryListStats as storeSetProductCategoryListStats} from '../store/features/productCategoryListSlice';

export interface IUseStateProductProps extends IUseProductProps {
  companyUuid: string;
  categoryUuid?: string;
}

export interface IUseStateProductReturnType
  extends Omit<IUseProductReturnType, 'entity'> {
  product: ProductModel | null;
  handleUpdateProduct: (value: ProductFormDTO) => Promise<ProductModel>;
  handleCreateProduct: (value: ProductFormDTO) => Promise<ProductModel | void>;
  handleChangeProductStatus: (status: number) => Promise<ProductModel | void>;
  handleUpdateProductDefaultPhoto: (
    photoId: string,
  ) => Promise<ProductModel | void>;
  handleRefreshProduct: () => Promise<void>;
  updateStoreProduct: (product: ProductModel) => void;
  handleResetProduct: () => void;
  status: REDUX_STATUS;
}

export function useStoredProduct({
  companyUuid,
  loadOnInit = true,
  productUuid,
  categoryUuid,
  ...rest
}: IUseStateProductProps): IUseStateProductReturnType {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const [product, setProduct] = React.useState<ProductModel | null>(null);
  const [isStarLoading, setIsStartLoading] = React.useState<boolean>(false);

  const {
    status: storedProductStatus,
    cachedProductList,
    ...storedProductParams
  } = useSelector(({product}: RootState) => product);

  const dispatch = useDispatch<any>();
  const once = React.useRef(false);

  const {entity, refresh, ...productParams} = useProduct({
    loadOnInit: loadOnInit || isStarLoading,
    productUuid,
    ...rest,
  });

  const {productList} = useStoredProductList({
    companyUuid,
    loadOnInit: false,
    productType: ProductType.DEFAULT,
  });

  React.useEffect(() => {
    if (cachedProductList?.size > 0) {
      const service = cachedProductList.get(productUuid);

      if (service) {
        setIsStartLoading(false);
        setProduct(service);
      } else {
        setIsStartLoading(true);
      }
    }
    if (cachedProductList?.size === 0 && !product) {
      setIsStartLoading(true);
    }
  }, [cachedProductList, dispatch, productUuid, product]);

  useEffect(() => {
    if (entity && isStarLoading && !once.current) {
      dispatch(storeSetProduct(entity));
      setProduct(entity);
    }
  }, [dispatch, entity, isStarLoading, storedProductStatus]);

  const updateStoreProduct = useCallback(
    (productModel: ProductModel) => {
      dispatch(storeUpdateProduct(productModel));

      if (List.isList(productList)) {
        dispatch(storeUpdateProductFromList({product: productModel}));
      }

      if (categoryUuid) {
        dispatch(
          storeUpdateProductCategoryProductToList(productModel, categoryUuid),
        );
      }

      setProduct(productModel);
    },
    [categoryUuid, dispatch, productList],
  );

  const handleUpdateProduct = React.useCallback(
    async (value: ProductFormDTO): Promise<ProductModel> => {
      const productDTO = await editProduct(value);

      const productModel = ProductMapper.toProductModel(productDTO);

      updateStoreProduct(productModel);

      return productModel;
    },
    [updateStoreProduct],
  );

  const handleCreateProduct = React.useCallback(
    async (value: ProductFormDTO) => {
      const {product: productDTO, stats} = await createProduct(
        value,
        companyUuid,
      );

      const productModel = ProductMapper.toProductModel(productDTO);

      const updatedModel = productModel.set(
        'created_at',
        toStringDate(new Date()),
      );

      if (List.isList(productList)) {
        dispatch(storeAddProduct({product: updatedModel, stats}));
      }

      dispatch(storeSetProductCategoryListStats({stats}));

      return productModel;
    },
    [companyUuid, dispatch, productList],
  );

  const handleResetProduct = React.useCallback(() => {
    once.current = true;
    dispatch(storeResetProduct());
  }, [dispatch]);

  const handleRefreshProduct = React.useCallback(async () => {
    const productModel = await refresh({showLoading: false, productUuid});

    if (productModel) {
      dispatch(storeUpdateProduct(productModel));
      dispatch(storeUpdateProductFromList({product: productModel}));
    }
  }, [productUuid, dispatch, refresh]);

  const handleChangeProductStatus = React.useCallback(
    async (status: number): Promise<ProductModel> => {
      const productDTO = await changeProductStatus(status, productUuid);

      const productModel = ProductMapper.toProductModel(productDTO);

      updateStoreProduct(productModel);

      return productModel;
    },
    [productUuid, updateStoreProduct],
  );

  const handleUpdateProductDefaultPhoto = React.useCallback(
    async (photoId: string): Promise<ProductModel | void> => {
      try {
        const productDTO = await setProductPhotoByDefault({
          productUuid,
          fileId: photoId,
        });

        const productModel = ProductMapper.toProductModel(productDTO);

        updateStoreProduct(productModel);

        alert('success', t('Photo'), t('Set default photo success'));

        return productModel;
      } catch (error: any) {
        alert(
          'error',
          t('Photo'),
          `${t('An error occurred during set default photo')}: ${
            error?.message
          }`,
        );
      } finally {
      }
    },
    [alert, productUuid, t, updateStoreProduct],
  );

  return {
    ...productParams,
    ...storedProductParams,
    product,
    loading: !product,
    handleUpdateProduct,
    handleCreateProduct,
    status: storedProductStatus,
    handleResetProduct,
    refresh,
    handleRefreshProduct,
    updateStoreProduct,
    handleChangeProductStatus,
    handleUpdateProductDefaultPhoto,
  };
}
