import {
  CompanyDTO,
  CompanyMapper,
  ICompanyDTOProps,
  IMailingTemplateModelReturnType,
} from '../internal';
import {WithoutRecursion} from '../type';

export enum MAILING_TEMPLATES_TYPE {
  SMS = 'sms',
  EMAIL = 'email',
  VIBER = 'viber',
  ALL = 'all',
}

export interface IMailingTemplateDTOProps {
  template_type: MAILING_TEMPLATES_TYPE;
  template_uuid: string;
  template_title: string;
  template_event: string;
  template_body: string;
  template_comment: string;
  company?: ICompanyDTOProps;
  created_at: string;
  updated_at: string;
}

export class MailingTemplateDTO {
  public template_type: MAILING_TEMPLATES_TYPE;

  public template_uuid: string;

  public template_title: string;

  public template_event: string;

  public template_body: string;

  public template_comment: string;

  public company?: ICompanyDTOProps;

  public created_at: string;

  public updated_at: string;

  constructor(
    props: IMailingTemplateModelReturnType | IMailingTemplateDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.template_type = props?.template_type || MAILING_TEMPLATES_TYPE.EMAIL;

    this.template_uuid = props?.template_uuid || '';

    this.template_title = props?.template_title || '';

    this.template_event = props?.template_event || '';

    this.template_body = props?.template_body || '';

    this.template_comment = props?.template_comment || '';

    this.created_at = props?.created_at || '';

    this.updated_at = props?.updated_at || '';

    this.company = props?.company
      ? withoutRecursion.indexOf(WithoutRecursion.company) !== -1
        ? (props?.company as CompanyDTO)
        : CompanyMapper.toCompanyDTO(props?.company, [
            WithoutRecursion.employee,
            ...withoutRecursion,
          ])
      : undefined;
  }
}
