import {apiDelete, apiGet, apiPost} from '../core/api';
import {head} from '../helpers';
import {ApiAnswer, IListSearchProps, IEntityProps} from '../types';
import {FileMapper, FileDTO, IFileDTOProps, IFileListDTO} from '../../struture';
import {contentApiUrl} from '../const';

export interface IFileListAnswer extends ApiAnswer {
  files: IFileDTOProps[];
}

export interface IFileListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  scheduleUuid: string;
}

export interface IFileAnswer extends ApiAnswer {
  files: IFileDTOProps[];
}

export async function getFileList({
  scheduleUuid,
  limit = 10,
  offset = 0,
  keywords,
}: IFileListProps): Promise<IFileListDTO> {
  const {files, total} = await apiGet<
    Partial<IListSearchProps>,
    IFileListAnswer
  >(`${contentApiUrl}/schedules/${scheduleUuid}/files`, {
    limit,
    offset,
    keywords,
  });

  return FileMapper.toFileListDTO(files, total);
}

export async function uploadFileList(
  uploadFiles: File[],
  scheduleUuid: string,
): Promise<IFileListDTO> {
  const formData = new FormData();
  uploadFiles.forEach(({originFileObj}: any) => {
    formData.append('files', originFileObj);
  });

  const {files, total} = await apiPost<any, IFileAnswer>(
    `${contentApiUrl}/schedules/${scheduleUuid}/files`,
    formData,
    {},
    {},
    true,
  );

  return FileMapper.toFileListDTO(files, total);
}

export async function deleteFiles(ids: string[]): Promise<FileDTO> {
  const fileUuid = head(ids);

  const {file} = await apiDelete<null, any>(
    `${contentApiUrl}/company-files/${fileUuid}`,
  );

  return FileMapper.toFileDTO(file);
}
