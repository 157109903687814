import * as React from 'react';
import {Moment} from 'moment';
import useCompanyScheduleList, {
  IUseCompanyScheduleListProps,
  IUseCompanyScheduleListReturnType,
} from './useCompanyScheduleList';
import {useStateEntityList} from '../components/lib/libV2/hooks';
import useScheduleActions, {
  IUseScheduleActionsReturnType,
} from './useScheduleActions';
import {dateToIsoString, head, last, isThereContent} from '../services/helpers';
import {ScheduleCalendarModel, ScheduleCalendarStatsDTO} from '../struture';
import {List} from 'immutable';
import {SetStateAction, useCallback, useMemo, useState} from 'react';
import {IUseServiceListProps} from './useServiceList';
import {StatusError} from '../components/lib/Errors';

export interface IUseStateScheduleListListProps
  extends Omit<Partial<IUseCompanyScheduleListProps>, 'companyUuid'> {
  companyUuid: string;
}

export interface IUseStateScheduleListListReturnType
  extends Omit<IUseCompanyScheduleListReturnType, 'list'>,
    IUseScheduleActionsReturnType {
  schedules: List<ScheduleCalendarModel> | null;
  handlePickScheduleRange: (value: [Moment, Moment]) => Promise<void>;
  handleSearchSchedule: (
    value: Partial<IUseCompanyScheduleListProps>,
  ) => Promise<List<ScheduleCalendarModel> | void>;
  isLoadingMore: boolean;
  loadingMore: boolean;
  handleLoadingMoreScheduleList: (
    value: Partial<IUseServiceListProps>,
  ) => Promise<List<ScheduleCalendarModel> | void>;
  handleSetScheduleList: React.Dispatch<
    SetStateAction<List<ScheduleCalendarModel> | null>
  >;
  handleUpdateScheduleState: (schedule: ScheduleCalendarModel) => void;
}

export default function useStateScheduleListList(
  {
    ...scheduleListProps
  }: IUseStateScheduleListListProps = {} as IUseStateScheduleListListProps,
): IUseStateScheduleListListReturnType {
  const [stats, setStats] = useState<ScheduleCalendarStatsDTO>(
    {} as ScheduleCalendarStatsDTO,
  );

  const handleUpdateStats = useCallback((stats: ScheduleCalendarStatsDTO) => {
    setStats((prevState) => ({...prevState, ...stats}));
  }, []);

  const {
    list: schedules,
    loading: serviceListLoading,
    refresh,
    total: scheduleListTotal,
    limit: scheduleListLimit,
    offset,
    stats: listStats,
    keywords: scheduleKeywords,
    ...rest
  } = useCompanyScheduleList({
    ...scheduleListProps,
  });

  const {
    entityList: stateSchedules,
    loading: stateLoading,
    handleUpdate,
    handleCreate,
    handleDelete,
    setEntityList,
    total,
  } = useStateEntityList<ScheduleCalendarModel>({
    entityList: schedules,
    refresh,
    limit: scheduleListLimit,
    offset,
    total: scheduleListTotal,
  });

  const size = useMemo(() => schedules?.size || 0, [schedules?.size]);
  const isLoadingMore = useMemo(() => size < total, [size, total]);

  const [searchLoading, setSearchLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const loading = serviceListLoading || stateLoading || searchLoading;

  const scheduleActions = useScheduleActions({
    handleUpdate,
    handleCreate,
    handleDelete,
    setStats: handleUpdateStats,
  });

  const handlePickScheduleRange = React.useCallback(
    async (value: [Moment, Moment]): Promise<void> => {
      if (value) {
        const schedules = await refresh({
          date_start: dateToIsoString(head(value)),
          date_end: dateToIsoString(last(value)),
        });

        if (List.isList(schedules)) {
          setEntityList(schedules);
        }
      } else {
        const schedules = await refresh({
          date_start: null,
          date_end: null,
        });

        if (List.isList(schedules)) {
          setEntityList(schedules);
        }
      }
    },
    [refresh, setEntityList],
  );

  const handleSearchSchedule = React.useCallback(
    async (
      {
        keywords,
        limit = 10,
        showLoading = true,
        ...rest
      }: Partial<IUseCompanyScheduleListProps>,
      withError?: boolean,
    ): Promise<any> => {
      try {
        setSearchLoading(true);
        const schedules = await refresh(
          {
            keywords,
            limit,
            showLoading,
            ...rest,
          },
          withError,
        );

        if (List.isList(schedules)) {
          setEntityList(schedules);
          setSearchLoading(false);

          return schedules;
        }
      } catch (error: any) {
        throw new StatusError(error?.message, error?.status);
      } finally {
        setSearchLoading(false);
      }
    },
    [refresh, setEntityList],
  );

  const handleLoadingMoreScheduleList = React.useCallback(
    async ({
      keywords = scheduleKeywords,
      showLoading = false,
      limit = 10,
      offset = schedules?.size,
      ...rest
    }: Omit<IUseServiceListProps, 'companyUuid'> = {}) => {
      if (size < total) {
        setLoadingMore(true);

        const apiServiceList = await refresh({
          keywords,
          showLoading,
          limit,
          offset,
          ...rest,
        });

        if (List.isList(apiServiceList)) {
          setEntityList((prevState) =>
            (prevState || List()).merge(apiServiceList),
          );
          setLoadingMore(false);

          return apiServiceList;
        }
        setLoadingMore(false);
      }
    },
    [refresh, scheduleKeywords, schedules?.size, setEntityList, size, total],
  );

  React.useEffect(() => {
    if (!isThereContent(stats) && isThereContent(listStats)) {
      setStats(listStats);
    }
  }, [listStats, stats]);

  const handleUpdateScheduleState = useCallback(
    (schedule: ScheduleCalendarModel) => {
      handleUpdate(schedule);
    },
    [handleUpdate],
  );

  return {
    loading: loading || stateLoading,
    schedules: stateSchedules,
    refresh,
    ...scheduleActions,
    handlePickScheduleRange,
    handleSearchSchedule,
    limit: scheduleListLimit,
    total,
    offset,
    stats,
    ...rest,
    keywords: scheduleKeywords,
    isLoadingMore,
    loadingMore,
    handleLoadingMoreScheduleList,
    handleSetScheduleList: setEntityList,
    handleUpdateScheduleState,
  };
}
