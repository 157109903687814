import {DateType, toMoment, toDateByFormat} from '../../services/helpers';

export interface IDayOffDTOProps {
  uuid: string;
  start_date: string;
  created_at: string;
  end_date: string;
  comment: string;
}

export class DayOffDTO {
  public uuid: string;

  public start_date: any;

  public end_date: any;

  public comment: string;

  public created_at: string;

  constructor(props?: IDayOffDTOProps) {
    this.uuid = props?.uuid || '';
    this.start_date = DayOffDTO.toDate(props?.start_date || '');
    this.end_date = DayOffDTO.toDate(props?.end_date || '');
    this.comment = props?.comment || '';
    this.created_at = props?.created_at || '';
  }

  private static toDate(date: string): any {
    return date ? toMoment(date) : '';
  }

  dateToString(date: DateType) {
    return toDateByFormat(date, 'YYYY-MM-DD');
  }
}
