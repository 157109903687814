import React, {ChangeEvent, useCallback} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {SearchInput, ISearchInputProps} from '../../../components/lib/General';
import {Label} from '../../../components/lib/DataDisplay';
import {IUserSearchProps} from '../../../services/api/companyUser';

export default withTranslation()(UsersSearchInput);

export interface IUsersSearchInputProps
  extends WithTranslation,
    ISearchInputProps {
  handleSearchUsers: (value: IUserSearchProps) => Promise<void>;
}

function UsersSearchInput({
  t,
  handleSearchUsers,
  ...rest
}: IUsersSearchInputProps): JSX.Element {
  const handleChange = useCallback(
    ({target}: ChangeEvent<HTMLInputElement>) =>
      handleSearchUsers({keywords: target?.value}),
    [handleSearchUsers],
  );

  return (
    <Label tooltipTitle={t('Search users')} label={t('Search users')}>
      <SearchInput size="small" onChange={handleChange} {...rest} />
    </Label>
  );
}
