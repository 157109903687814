import React, {useCallback, useEffect} from 'react';
import moment, {Moment} from 'moment';
import {useTranslation} from 'react-i18next';
import {CalendarHeaderView} from '../../../components/lib/Layout';
import {CalendarDay} from '../../../components/Calendar';
import {
  useCalendar,
  useCalendarDnd,
  useDefaultForm,
  useDropdownAlert,
} from '../../../contex';
import {
  toMomentString,
  ScheduleCalendar,
  some,
  isEqualByScheduledDate,
  find,
  isEqualByDate,
  compose,
  map,
  filter,
  isNotEqualByUuid,
  isIncludesErrorFields,
  neq,
  lessThan,
  FunctionArgs,
  isThereContent,
  ScheduleCalendarProps,
  IMonthFormat,
  len,
  eq,
  isDateLess,
  toDateByFormat,
} from '../../../services/helpers';
import {DateValue} from '../../../services/types';
import {useCalendarSpecifications} from '../../../hooks';
import {ScheduleCalendarModel} from '../../../struture';
import {List} from 'immutable';

export interface IOrderCalendarDayProps {
  schedules: List<ScheduleCalendarModel> | undefined;
  schedule: ScheduleCalendarModel | null;
  handleChangeDate: (date: Moment | null) => void;
  draggableScheduleUuid: string;
  scheduleDate: DateValue;
  editMode: boolean;
  scheduleLoading: boolean;
  disabled?: boolean;
  calendarWrapperStyle?: any;
}

export default function OrderCalendarDay({
  schedules,
  schedule,
  handleChangeDate,
  draggableScheduleUuid,
  scheduleDate,
  editMode,
  scheduleLoading,
  disabled,
  calendarWrapperStyle,
}: IOrderCalendarDayProps): JSX.Element {
  const {alert} = useDropdownAlert();
  const {t} = useTranslation();

  const {
    handleUpdateDate,
    selectedDate,
    addSchedulesToMonthDates,
    setDefaultScheduleItem,
    defaultMonthDates,
    limitedByDate,
  } = useCalendar();
  const {handleAddDnDEvent} = useCalendarDnd();
  const {formData} = useDefaultForm();
  const {allSpecification} = useCalendarSpecifications();

  const handleSelectToday = useCallback(async (): Promise<void> => {
    const nowDate = toMomentString(moment(), 'YYYY-MM-DD');
    const prevDate = toMomentString(new Date(selectedDate), 'YYYY-MM-DD');

    if (neq(nowDate, prevDate)) {
      handleUpdateDate(moment().toDate());
      handleChangeDate(moment());
    }
  }, [handleChangeDate, handleUpdateDate, selectedDate]);

  const handleSelectPrevDate = useCallback(async () => {
    const prevDate = moment(new Date(selectedDate)).subtract(1, 'day');

    if (
      limitedByDate &&
      isDateLess(prevDate, toDateByFormat(limitedByDate, 'YYYY-MM-DD HH:mm'))
    ) {
      alert(
        'error',
        t('Previous date'),
        t(
          'You cannot select an earlier day, because the date of the accompanying order cannot be less than the date of the parent order.',
        ),
      );

      return;
    }

    handleUpdateDate(prevDate.toDate());
    handleChangeDate(prevDate);
  }, [
    alert,
    handleChangeDate,
    handleUpdateDate,
    limitedByDate,
    selectedDate,
    t,
  ]);

  const handleSelectNextDate = useCallback(async (): Promise<void> => {
    const nextDate = moment(new Date(selectedDate)).add(1, 'day');

    handleUpdateDate(nextDate.toDate());
    handleChangeDate(nextDate);
  }, [handleChangeDate, handleUpdateDate, selectedDate]);

  useEffect(() => {
    setDefaultScheduleItem(schedule as any);
  }, [schedule, setDefaultScheduleItem]);

  useEffect(() => {
    if (
      (List.isList(schedules) || Array.isArray(schedules)) &&
      some(isEqualByScheduledDate(scheduleDate), schedules as any)
    ) {
      if (isThereContent(defaultMonthDates)) {
        const events = addSchedulesToMonthDates(
          compose<ScheduleCalendarProps[]>(
            filter<
              [FunctionArgs<ScheduleCalendar, boolean>],
              ScheduleCalendarProps[]
            >((item: ScheduleCalendar): boolean =>
              isNotEqualByUuid(item?.uuid, draggableScheduleUuid),
            ),
          )(schedules),
        );

        if (editMode) {
          const currentDayEvent: any = find<
            [FunctionArgs<IMonthFormat, boolean>, IMonthFormat[]],
            IMonthFormat
          >(isEqualByDate(scheduleDate), events);

          handleAddDnDEvent(
            moment(scheduleDate).utc().format('HH:mm'),
            currentDayEvent?.events,
          );
        }
      }

      if (Array.isArray(schedules) && eq(len(schedules), 0)) {
        addSchedulesToMonthDates([]);

        if (editMode) {
          handleAddDnDEvent(
            moment(scheduleDate).utc().format('HH:mm'),
            {} as any,
          );
        } else {
          handleAddDnDEvent(moment(scheduleDate).format('HH:mm'), {} as any);
        }
      }
    } else {
      addSchedulesToMonthDates([]);

      if (editMode) {
        handleAddDnDEvent(
          moment(scheduleDate).utc().format('HH:mm'),
          {} as any,
        );
      } else {
        handleAddDnDEvent(moment(scheduleDate).format('HH:mm'), {} as any);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedules, draggableScheduleUuid, defaultMonthDates]);

  const handleChangeCalendarDate = useCallback(
    (pickerDate: Moment | null) => {
      if (pickerDate) {
        handleChangeDate(pickerDate);
        handleUpdateDate(pickerDate.toDate());
      }
    },
    [handleChangeDate, handleUpdateDate],
  );

  return (
    <>
      <CalendarHeaderView
        showTitle={false}
        showCalendarMode={false}
        handleSelectToday={handleSelectToday}
        handleSelectPrevDate={handleSelectPrevDate}
        handleSelectNextDate={handleSelectNextDate}
        selectedDate={moment(new Date(selectedDate))}
        handleChangeDate={handleChangeCalendarDate}
        loading={scheduleLoading}
        danger={isIncludesErrorFields(formData?.errorFields, 'time')}
        specifications={allSpecification}
      />
      <CalendarDay
        disabled={disabled}
        calendarWrapperStyle={calendarWrapperStyle || {paddingLeft: '10px'}}
        loading={scheduleLoading}
      />
    </>
  );
}
