import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Empty} from 'antd';
import {ListLayout} from '../../../components/lib/Layout';
import {
  useKeyboardOpenForm,
  useSearchInputFocus,
  useStateClientOrderList,
  useStopLoading,
  useStoredStatusCategoryList,
  useStoredStatusList,
} from '../../../hooks';
import {CreateOrderButton, OrderList, ScheduleBottomView} from '../../Orders';
import {len, lessOrEqualThan, textToUpperCase} from '../../../services/helpers';
import useStoredCompanies from '../../../hooks/useStoredCompanies';
import {
  HeaderLeft,
  ListActions,
  ListHeader,
} from '../../../components/lib/DataDisplay';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '../../../services/types';
import {useAcl, useDropdownAlert} from '../../../contex';
import {ScheduleCalendarModel} from '../../../struture';
import {useLocation, useNavigate, useParams} from 'react-router';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import moment from 'moment/moment';
import {GetCompanyScheduleState} from '../../../services/api/company';
import styled, {useTheme} from 'styled-components';
import {successButtonStyle} from '../../../components/lib/Styled';
import {PlusOutlined} from '@ant-design/icons';
import {StyledDetailsView} from '../../../components/lib/Styled/DetailsView';
import {OrderStateView} from '../../Orders';

const StyledCreateOrderButton = styled(CreateOrderButton)`
  ${successButtonStyle}
`;

export function ClientOrderListPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const {clientId} = useParams();
  const {t} = useTranslation();
  const {defaultCompanyUuid} = useStoredCompanies();
  const {manage} = useAcl(({order}) => order);
  const {alert} = useDropdownAlert();
  const theme: any = useTheme();
  const {ref: listRef} = useKeyboardOpenForm({
    className: 'client-order-create-event',
    disabled: !manage,
  });

  const [selectedSchedule, setSelectedSchedule] = useState<
    ScheduleCalendarModel[]
  >([]);
  const [state, setState] = useState<GetCompanyScheduleState>(
    location?.state?.type || GetCompanyScheduleState.ALL,
  );
  const [stateLoading, setStateLoading] = useState(false);

  const {
    schedules,
    refresh,
    error: clientError,
    loading: clientLoading,
    total,
    limit,
    start_date,
    end_date,
    stats,

    handleCreateSchedule,
    handlePickScheduleRange,
    handleSearchSchedule,
    handleUpdateScheduleState,
  } = useStateClientOrderList({
    clientUuid: clientId!,
    sort: 'createdAt',
  });

  const {
    categoryList,
    loading: categoryListLoading,
    error: categoryListError,
  } = useStoredStatusCategoryList();

  const {
    statusList,
    loading: statusListLoading,
    error: statusListError,
  } = useStoredStatusList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
    allowedStatusesTo: [],
  });

  const loading = useStopLoading({
    loading:
      clientLoading || stateLoading || categoryListLoading || statusListLoading,
    error: clientError || categoryListError || statusListError,
    message: 'An error occurred during client orders loading',
  });

  const {inputRef, daleyFocus} = useSearchInputFocus({loading});

  const rowSelection = useMemo(
    () => ({
      hideSelectAll: true,
      selectedRowKeys: selectedSchedule.map(({uuid}) => uuid),
      onChange: async (
        selectedRowKeys: React.Key[],
        selectedRecords: ScheduleCalendarModel[],
      ): Promise<void> => {
        if (lessOrEqualThan(len(selectedRecords), 1)) {
          setSelectedSchedule(selectedRecords);
        } else {
          alert('error', t('Delete'), t('You can delete up to 1 at a time'));
        }
      },
    }),
    [alert, selectedSchedule, t],
  );

  const routes = useBreadcrumb([
    {
      path: `/${Routes.clients}`,
      breadcrumbName: 'Clients',
    },
    {
      path: `/${Routes.clients}/${clientId}`,
      breadcrumbName: location?.state?.clientFullName || 'Show Client',
    },
    {
      path: `/${Routes.clients}/${clientId}/${Routes.clientSchedules}`,
      breadcrumbName: 'Orders',
    },
  ]);

  const onClientScheduleStateChange = useCallback(
    async (state: GetCompanyScheduleState) => {
      if (defaultCompanyUuid) {
        setStateLoading(true);
        setState(state);
        window.history.replaceState({}, document.title);
        await handleSearchSchedule({
          state,
        });
        setStateLoading(false);
      }
    },
    [defaultCompanyUuid, handleSearchSchedule],
  );

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.clients}/${clientId}`);
  }, [clientId, navigate]);

  return (
    <ListLayout
      ref={listRef}
      aclItem={RoutesAcl[Routes.clients]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.clientSchedules]))}
      headerRight={
        <CreateOrderButton
          order={{client: {uuid: clientId!}}}
          disabled={!manage}
          companyUuid={defaultCompanyUuid}
          loading={loading}
          onSuccess={handleCreateSchedule}
          onCancel={daleyFocus}
        />
      }
      routes={routes}
      empty={
        schedules?.size ? null : (
          <Empty
            description={t(
              `It looks like you don't have any order at the moment`,
            )}>
            <CreateOrderButton
              order={{client: {uuid: clientId!}}}
              type="primary"
              disabled={!manage}
              companyUuid={defaultCompanyUuid}
              loading={loading}
              onSuccess={handleCreateSchedule}
              title={t('Create company order')}
              onCancel={daleyFocus}
            />
          </Empty>
        )
      }
      headerExtra={
        <ListHeader>
          <StyledDetailsView
            titles={[]}
            selected={[
              state === GetCompanyScheduleState.ALL,
              state === GetCompanyScheduleState.UNPAID,
              state === GetCompanyScheduleState.OVERDUE,
              state === GetCompanyScheduleState.CANCELLED,
            ]}>
            <OrderStateView
              color={theme.tabs.link}
              type={GetCompanyScheduleState.ALL}
              title="All"
              stats={stats}
              onClick={onClientScheduleStateChange}
              disabled={loading}
            />
            <OrderStateView
              color={theme.tabs.warning}
              type={GetCompanyScheduleState.UNPAID}
              title="Waiting for payment"
              stats={stats}
              onClick={onClientScheduleStateChange}
              disabled={loading}
            />
            <OrderStateView
              color={theme.tabs.primary}
              type={GetCompanyScheduleState.OVERDUE}
              title="Overdue"
              stats={stats}
              onClick={onClientScheduleStateChange}
              disabled={loading}
            />
            <OrderStateView
              color={theme.tabs.error}
              type={GetCompanyScheduleState.CANCELLED}
              title="Cancelled"
              stats={stats}
              onClick={onClientScheduleStateChange}
              disabled={loading}
            />
          </StyledDetailsView>
          <ListActions
            inputRef={inputRef}
            loading={loading}
            defaultDateRangeValue={
              start_date && end_date
                ? [moment(start_date), moment(end_date)]
                : undefined
            }
            handlePickRange={handlePickScheduleRange}
            handleSearch={(keywords) => handleSearchSchedule({keywords})}
            inputTooltip={t('Search company order')}
            inputLabel={t('Search company order')}
            withSearchContent={
              <StyledCreateOrderButton
                disabled={!manage}
                loading={loading}
                type="primary"
                companyUuid={defaultCompanyUuid}
                onSuccess={handleCreateSchedule}
                title={t('Create')}
                icon={<PlusOutlined />}
                order={{client: {uuid: clientId!}}}
                onCancel={daleyFocus}
                className="client-order-create-event"
              />
            }
          />
        </ListHeader>
      }
      outsideFooterContent={
        schedules ? <ScheduleBottomView stats={stats} state={state} /> : null
      }
      loading={loading && !schedules?.size}>
      <OrderList
        state={location.state || {}}
        pathname={location.pathname}
        withoutActions
        withoutClientField
        schedules={schedules}
        rowSelection={rowSelection}
        loading={loading}
        limit={limit}
        total={total}
        onRefresh={refresh}
        categoryList={categoryList}
        statusList={statusList}
        handleUpdateScheduleState={handleUpdateScheduleState}
      />
    </ListLayout>
  );
}
