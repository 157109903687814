import {SettingsModuleDependencyModel} from '../internal';

export enum SettingsModuleDependencyStatus {
  Disabled,
  Active,
}

export interface IDepCompanyUsageProps {
  total: number;
}

export interface ISettingsModuleDependencyDTOProps {
  uuid: string;
  dep_title: string;
  dep_description: string;
  dep_price: string;
  dep_limit: number;
  dep_status: SettingsModuleDependencyStatus;
  created_at: string;
  updated_at: string;
  dep_company_usage: IDepCompanyUsageProps | null;
}

export class SettingsModuleDependencyDTO {
  public uuid: string;

  public dep_title: string;

  public dep_description: string;

  public dep_price: string;

  public dep_limit: number;

  public dep_status: SettingsModuleDependencyStatus;

  public created_at: string;

  public updated_at: string;

  public dep_company_usage: IDepCompanyUsageProps | null;

  constructor(
    props: ISettingsModuleDependencyDTOProps | SettingsModuleDependencyModel,
  ) {
    this.uuid = props?.uuid || '';
    this.dep_title = props?.dep_title || '';
    this.dep_description = props?.dep_description || '';
    this.dep_price = props?.dep_price || '';
    this.dep_limit = props?.dep_limit || 0;
    this.dep_status =
      props?.dep_status || SettingsModuleDependencyStatus.Active;
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
    this.dep_company_usage = props?.dep_company_usage || null;
  }
}
