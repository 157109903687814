import {ClientAbonementStatusesText} from '../internal';

export interface IClientAbonementLatestStatusDTOProps {
  uuid: string;
  date: string;
  comment: string;
  previous_status: ClientAbonementStatusesText;
  current_status: ClientAbonementStatusesText;
}

export class ClientAbonementLatestStatusDTO {
  public uuid: string;
  public date: string;
  public comment: string;
  public previous_status: ClientAbonementStatusesText;
  public current_status: ClientAbonementStatusesText;

  constructor(props: IClientAbonementLatestStatusDTOProps) {
    this.uuid = props?.uuid || '';
    this.date = props?.date || '';
    this.comment = props?.comment || '';
    this.previous_status =
      props?.previous_status || ClientAbonementStatusesText?.Active;
    this.current_status =
      props?.current_status || ClientAbonementStatusesText?.Active;
  }
}
