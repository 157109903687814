import * as React from 'react';

const {matchMedia} = window;

const mediaQueryList = matchMedia('(min-width: 481px)');

export default function useMqlShowIcon(): boolean {
  const [showIcon, setShowIcon] = React.useState(false);

  React.useEffect(() => {
    mediaQueryList.addEventListener('change', ({matches}) => {
      setShowIcon(matches);
    });

    return () =>
      mediaQueryList.removeEventListener('change', ({matches}) => {
        setShowIcon(matches);
      });
  }, []);

  return showIcon;
}
