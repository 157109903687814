import * as React from 'react';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {IAclLayoutProps} from '../components/lib/Layout';
import {KeyboardReactInterface} from 'react-simple-keyboard';

export interface IKeyboardManagerProps
  extends Pick<IAclLayoutProps, 'aclItem'> {
  children: React.ReactNode;
}

export interface IKeyboardManagerContext {
  keyboardRef: MutableRefObject<KeyboardReactInterface | null>;
  keyboardControlPadRef: MutableRefObject<KeyboardReactInterface | null>;
  keyboardArrowsRef: MutableRefObject<KeyboardReactInterface | null>;
  keyboardNumPadRef: MutableRefObject<KeyboardReactInterface | null>;
  keyboardNumPadEndRef: MutableRefObject<KeyboardReactInterface | null>;
  inputRef: MutableRefObject<HTMLInputElement | null>;
  keywords: MutableRefObject<string>;
  isFocus: boolean;
  handleClearKeyboards: () => void;
  focus: () => void;
  daleyFocus: (ms?: number) => void;
  initInput: (inputParentClassName: string) => void;
  keyboardShow: boolean;
  handleToggleKeyboard: () => void;
  handleChangeShowKeyboard: (show: boolean) => void;
}

export const KeyboardManagerContext =
  React.createContext<IKeyboardManagerContext>({
    keyboardRef: {current: null},
    keyboardControlPadRef: {current: null},
    keyboardArrowsRef: {current: null},
    keyboardNumPadRef: {current: null},
    keyboardNumPadEndRef: {current: null},
    inputRef: {current: null},
    keywords: {current: ''},
    isFocus: false,
    handleClearKeyboards: () => {},
    initInput: () => {},
    focus: () => {},
    daleyFocus: () => {},
    keyboardShow: false,
    handleChangeShowKeyboard: (show: boolean) => {},
    handleToggleKeyboard: () => {},
  });

export const useKeyboard = () => React.useContext(KeyboardManagerContext);

export const KeyboardManager = React.forwardRef(function KeyboardManager(
  {children}: IKeyboardManagerProps,
  ref: any,
): React.JSX.Element {
  const keyboardRef = React.useRef<KeyboardReactInterface | null>(null);
  const keyboardControlPadRef = useRef<KeyboardReactInterface | null>(null);
  const keyboardArrowsRef = useRef<KeyboardReactInterface | null>(null);
  const keyboardNumPadRef = useRef<KeyboardReactInterface | null>(null);
  const keyboardNumPadEndRef = useRef<KeyboardReactInterface | null>(null);
  const keywords = useRef('');

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [isFocus, setIsFocus] = useState(false);
  const [keyboardShow, setKeyboardShow] = useState(false);

  const handleClearKeyboards = useCallback(() => {
    keyboardRef?.current?.setInput('');
    keyboardControlPadRef?.current?.setInput('');
    keyboardArrowsRef?.current?.setInput('');
    keyboardNumPadRef?.current?.setInput('');
    keyboardNumPadEndRef?.current?.setInput('');
    keywords.current = '';
  }, []);

  const onFocus = useCallback(
    (e: any) => {
      setIsFocus(true);

      if (keyboardShow) {
        e?.target?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }
    },
    [keyboardShow],
  );

  const onBlur = useCallback(() => {
    setIsFocus(false);
  }, []);

  const focus = useCallback(() => {
    inputRef?.current?.focus();
  }, []);

  const daleyFocus = useCallback((ms = 500) => setTimeout(focus, ms), [focus]);

  const initInput = useCallback(
    (className: string) => {
      const parent = document.body.querySelector(`.${className}`);

      if (parent instanceof HTMLElement) {
        const input = parent?.querySelector('input');

        if (input instanceof HTMLInputElement) {
          inputRef.current = input as any;

          if (inputRef.current instanceof HTMLInputElement) {
            inputRef.current.addEventListener('focus', onFocus);
            inputRef.current.addEventListener('blur', onBlur);
          }

          inputRef.current?.focus();
        }
      }
    },
    [onBlur, onFocus],
  );

  const handleChangeShowKeyboard = useCallback((keyboardShow: boolean) => {
    setKeyboardShow(keyboardShow);
  }, []);

  const handleToggleKeyboard = useCallback(() => {
    setKeyboardShow((prevState) => !prevState);
  }, []);

  useEffect(() => {
    if (inputRef.current instanceof HTMLInputElement) {
      inputRef.current.addEventListener('focus', onFocus);
      inputRef.current.addEventListener('blur', onBlur);
    }

    return () => {
      if (inputRef.current instanceof HTMLInputElement) {
        inputRef.current.removeEventListener('focus', onFocus);
        inputRef.current.removeEventListener('blur', onBlur);
      }
    };
  }, [onBlur, onFocus]);

  const value = React.useMemo(
    () => ({
      keyboardRef,
      keyboardControlPadRef,
      keyboardArrowsRef,
      keyboardNumPadRef,
      keyboardNumPadEndRef,
      inputRef,
      isFocus,
      handleClearKeyboards,
      keywords,
      initInput,
      focus,
      daleyFocus,
      handleChangeShowKeyboard,
      handleToggleKeyboard,
      keyboardShow,
    }),
    [
      isFocus,
      handleClearKeyboards,
      initInput,
      focus,
      daleyFocus,
      handleChangeShowKeyboard,
      handleToggleKeyboard,
      keyboardShow,
    ],
  );

  return (
    <KeyboardManagerContext.Provider value={value}>
      {children}
    </KeyboardManagerContext.Provider>
  );
});
