import {Record} from 'immutable';
import {SystemEventVariableDTO} from '../internal';

export interface ISystemEventVariableReturnType {
  variable_name: string;
  variable_comment: string;
}

export class SystemEventVariableModel extends Record<ISystemEventVariableReturnType>(
  {
    variable_name: '',
    variable_comment: '',
  },
) {
  constructor(props: SystemEventVariableDTO = {} as SystemEventVariableDTO) {
    super(props);
  }
}
