import React from 'react';
import {IDefaultTabPanelProps} from '../../../components/lib/General';
import {PaymentModel} from '../../../struture';

export enum PAYMENT_TABS {
  CLIENT = 'client',
  Employee = 'employee',
  ORDER = 'order',
}

export interface IPaymentTabPanelProps {
  children: (value: Pick<IDefaultTabPanelProps, 'tabs'>) => React.ReactNode;
  payment: PaymentModel | null;
}

/**
 * @decr Відображення вкладок Клієнта
 * */
export function PaymentTabPanel({children, payment}: IPaymentTabPanelProps) {
  const clientTabs: any = [];

  return <>{children({tabs: clientTabs})}</>;
}
