export interface ISystemRuleDTOProps {
  rule_title: string;
  rule_description: string;
  created_at: string;
  updated_at: string;
}

export class SystemRuleDTO {
  public rule_title: string;

  public rule_description: string;

  public created_at: string;

  public updated_at: string;

  constructor(props: ISystemRuleDTOProps) {
    this.rule_title = props?.rule_title || '';
    this.rule_description = props?.rule_description || '';
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
  }
}
