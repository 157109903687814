import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {OverviewLayout} from '../../../components/lib/Layout';
import {
  useDefaultPriceMargin,
  useRequiredFields,
  useStopLoading,
  useStoredCompanies,
  useStoredPriceTagList,
  useStoredProduct,
  useStoredProductCategory,
  useStoredProductLeftoversList,
  useStoredProductList,
  useStoredProductPhotoList,
} from '../../../hooks';
import {PRODUCT_REQUIRED_FIELD} from '../../../services/api/product';
import {
  ProductBalancesView,
  ProductCategoryDetailsView,
  ProductGallery,
  ProductPricesView,
  ProductTabPanel,
} from '../Show';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import {
  Routes,
  RoutesAcl,
  ProductType as ProductStatsType,
} from '../../../services/types';
import {useAcl, useDropdownAlert} from '../../../contex';
import {useLocation, useNavigate, useParams} from 'react-router';
import {ProductMenuButton} from '../Buttons';
import {DetailsView, HeaderLeft} from '../../../components/lib/DataDisplay';
import {
  fromBooleanToNumber,
  isThereContent,
  isValidEAN13,
  listToArray,
  textToUpperCase,
} from '../../../services/helpers';
import {
  PriceTagMapper,
  ProductBarCodeFormat,
  ProductLeftoversFormDTO,
  ProductMapper,
  ProductModel,
  ProductStatus,
  ProductType,
} from '../../../struture';
import {StyledDescription, StyledTitle} from '../../../components/lib/Styled';
import {Col, Row} from 'antd';
import styled, {css, useTheme} from 'styled-components';
import JsBarcode from 'jsbarcode';
import Switch from 'antd/es/switch';

const StyledBarCodeContainer = styled.div<{$visible: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 0;

  ${({$visible}) =>
    $visible &&
    css`
      height: 75px;
    `}
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledSvg = styled.svg`
  width: 124px !important;
`;

const StyledSwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const StyledSwitchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-end;

  width: 100%;
  margin-top: 20px;
`;

const Description = styled(StyledDescription)`
  white-space: unset;
  text-align: right;
`;

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export function ProductPage(): JSX.Element {
  const {productId, categoryId} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const theme: any = useTheme();
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const {manage} = useAcl(({product}) => product);
  const {defaultCompanyUuid} = useStoredCompanies();

  const [showBarCode, setShowBarCode] = useState(false);
  const [isRealization, setIsRealization] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);

  const once = useRef(false);
  const barcodeRef = useRef(null);

  const {category} = useStoredProductCategory({
    companyUuid: defaultCompanyUuid,
    categoryUuid: categoryId!,
    loadOnInit: !!categoryId,
  });

  const {
    product,
    loading: productLoading,
    error: productError,
    handleUpdateProduct,
    updateStoreProduct,
    handleChangeProductStatus: onChangeProductStatus,
    handleUpdateProductDefaultPhoto,
  } = useStoredProduct({
    productUuid: productId!,
    companyUuid: defaultCompanyUuid,
    categoryUuid: categoryId,
  });

  const {
    photoList,
    loading: productPhotoLoading,
    error: productPhotoError,
    handleUploadPhotos,
    handleDeletePhotos,
  } = useStoredProductPhotoList({productUuid: productId!});

  const {handleDeleteProducts: onDeleteProducts} = useStoredProductList({
    companyUuid: defaultCompanyUuid,
    loadOnInit: false,
    productType: ProductType.DEFAULT,
  });

  const {handleUpdateProductLeftover: onUpdateProductLeftover} =
    useStoredProductLeftoversList({
      companyUuid: defaultCompanyUuid,
      loadOnInit: false,
    });

  const {handlePrintPriceTag} = useStoredPriceTagList({
    companyUuid: defaultCompanyUuid,
    loadOnInit: false,
    productUuid: productId,
  });

  const {
    priceMarginList,
    loading: pmLoading,
    defaultPriceMargin,
  } = useDefaultPriceMargin({
    loadOnInit: !!product?.product_prices?.length,
  });

  const priceMarginListLoading = product?.product_prices?.length
    ? pmLoading
    : false;

  const loading = useStopLoading({
    loading: productLoading || productPhotoLoading,
    error: productError || productPhotoError,
    message: 'An error occurred during material loading',
  });

  const navigateGoBack = useCallback(() => {
    navigate(
      location?.state?.goBack ||
        (categoryId
          ? `/${Routes.app}/${Routes.goods}/${Routes.categories}`
          : `/${Routes.app}/${Routes.goods}/${Routes.products}`),
    );
  }, [categoryId, location?.state?.goBack, navigate]);

  const handleDeleteProducts = useCallback(async () => {
    await onDeleteProducts([productId!], navigateGoBack);
  }, [navigateGoBack, onDeleteProducts, productId]);

  const handleUpdateProductLeftover = useCallback(
    async (value: ProductLeftoversFormDTO) => {
      const productList = await onUpdateProductLeftover(value);

      if (productList && productList?.products?.size && product) {
        const storeBalances = productList?.products?.flatMap(
          ({product_balances}) => product_balances,
        );

        const updatedProduct = product?.update('product_balances', () =>
          listToArray(storeBalances),
        );

        updateStoreProduct(updatedProduct);
      }

      return productList;
    },
    [onUpdateProductLeftover, product, updateStoreProduct],
  );

  const handleChangeProductStatus = useCallback(
    async (status: boolean) => {
      setIsRealization(status);
      setStatusLoading(true);

      try {
        const product = await onChangeProductStatus(
          fromBooleanToNumber(status),
        );

        if (product) {
          setIsRealization(product?.product_status === ProductStatus.ENABLE);
        }
      } catch (error: any) {
        setIsRealization(false);
        alert(
          'error',
          t('Product'),
          `${t('An error occurred during change product status')} : ${
            error?.message
          }`,
        );
      } finally {
        setStatusLoading(false);
      }
    },
    [alert, onChangeProductStatus, t],
  );

  const routes = useBreadcrumb(
    categoryId
      ? [
          {
            path: `/${Routes.goods}/${Routes.products}`,
            breadcrumbName: 'Categories',
            state: {type: ProductStatsType.Category},
          },
          {
            path: `/${Routes.goods}/${Routes.categories}/${categoryId}`,
            breadcrumbName: category?.title || 'Show category',
            state: {type: ProductStatsType.Category},
          },
          {
            path: `/${Routes.goods}/${Routes.categories}/${categoryId}/${Routes.categoryProducts}`,
            breadcrumbName: 'Goods and materials values',
          },
          {
            path: `/${Routes.goods}/${Routes.categories}/${categoryId}/${Routes.categoryProducts}/${productId}`,
            breadcrumbName: product?.product_title || 'Show commodity value',
          },
        ]
      : [
          {
            path: `/${Routes.goods}/${Routes.products}`,
            breadcrumbName: 'Goods and materials values',
          },
          {
            path: `/${Routes.goods}/${Routes.products}/${productId}`,
            breadcrumbName: product?.product_title || 'Show commodity value',
          },
        ],
  );

  const changeFields = useCallback(
    (
      {
        product_prices,
        product_description,
        product_balances,
        ...rest
      }: typeof PRODUCT_REQUIRED_FIELD,
      product: ProductModel | null,
    ) => {
      return {
        ...rest,
        ...(product_description ? {product_description} : []),
      };
    },
    [],
  );

  const overviewData = useRequiredFields(
    product,
    PRODUCT_REQUIRED_FIELD,
    changeFields,
  );

  useEffect(() => {
    if (
      product &&
      product?.product_barcode &&
      isValidEAN13(product?.product_barcode) &&
      !once.current &&
      !(loading || priceMarginListLoading)
    ) {
      once.current = true;
      try {
        JsBarcode('#bar-code-page-container', product?.product_barcode, {
          format:
            product?.product_bar_code_format || ProductBarCodeFormat.EAN13,
        });
        setShowBarCode(true);
      } catch (e) {}
    }
  }, [loading, priceMarginListLoading, product, theme.background.layout]);

  const leftovers = product?.product_balances?.length
    ? ProductMapper.toProductLeftoversFormDTO(product!, {})
    : {
        ...ProductMapper.toProductLeftoversFormDTO(product!, {
          withAddedStore: true,
        }),
        store_balances: [
          {
            store_uuid: undefined,
            balance: 0,
            store_name: '',
          },
        ],
      };

  useEffect(() => {
    if (product?.product_status === ProductStatus.ENABLE) {
      setIsRealization(true);
    }
  }, [product?.product_status]);

  return (
    <ProductTabPanel>
      {({tabs}) => (
        <OverviewLayout
          isFullWidthItems={['Leftovers', 'price']}
          aclItem={RoutesAcl[Routes.products]}
          headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
          headerRight={
            <ProductMenuButton
              product={ProductMapper.toProductFormDTO(product!)}
              onSuccess={handleUpdateProduct}
              handleUpdateProductLeftover={handleUpdateProductLeftover}
              disabled={loading}
              leftover={leftovers as any}
              print={PriceTagMapper.toPriceTagPrintFormDTO({
                price_margin_uuid: defaultPriceMargin?.uuid || '',
                tag_size: '60x40',
              })}
              handlePrintPriceTag={handlePrintPriceTag}
              status={manage ? product?.product_status : undefined}
              handleDeleteProduct={handleDeleteProducts}
            />
          }
          headerTitle={textToUpperCase(t('Commodity value'))}
          disabled={!manage}
          loading={loading || priceMarginListLoading}
          data={isThereContent(overviewData) ? overviewData : null}
          routes={routes}
          tabs={tabs}
          header={
            <Row>
              <Col span={24}>
                <StyledTitleContainer>
                  <StyledTitle bold fontSize={20}>
                    {product?.product_title}
                  </StyledTitle>
                  <StyledBarCodeContainer
                    $visible={showBarCode}
                    ref={barcodeRef}>
                    <StyledSvg id="bar-code-page-container" />
                  </StyledBarCodeContainer>
                </StyledTitleContainer>
              </Col>
              <Col span={24}>
                <DetailsView titles={['Category']}>
                  {product?.category ? (
                    <ProductCategoryDetailsView
                      category={product?.categoryModel}
                    />
                  ) : null}
                </DetailsView>
              </Col>

              <Col span={24}>
                <StyledSwitchWrapper>
                  <StyledSwitchContainer>
                    <Switch
                      size="default"
                      onChange={handleChangeProductStatus}
                      checked={isRealization}
                      loading={statusLoading}
                      disabled={statusLoading}
                    />
                    <StyledTitle>
                      {t('Available for implementation')}
                    </StyledTitle>
                  </StyledSwitchContainer>
                  <Description>
                    {t(
                      'This option regulates the availability of goods in warehouse registers and the possibility of its sale.',
                    )}
                  </Description>
                </StyledSwitchWrapper>
              </Col>
              {product?.product_prices?.length ? (
                <StyledCol span={24}>
                  <StyledTitle>{`${t('price')}:`}</StyledTitle>
                  <ProductPricesView
                    product_prices={product?.product_prices}
                    priceMarginList={priceMarginList}
                  />
                </StyledCol>
              ) : null}

              {product?.product_balances?.length ? (
                <StyledCol span={24}>
                  <StyledTitle>{`${t('Leftovers')}:`}</StyledTitle>
                  <ProductBalancesView
                    product_balances={product?.product_balances}
                    product={product}
                    handleUpdateProductLeftover={handleUpdateProductLeftover}
                  />
                </StyledCol>
              ) : null}
            </Row>
          }>
          <ProductGallery
            photoList={photoList}
            handleDeletePhotos={handleDeletePhotos}
            handleUploadPhotos={handleUploadPhotos}
            handleUpdateProductDefaultPhoto={handleUpdateProductDefaultPhoto}
            selectedPhoto={product?.product_photo || ''}
          />
        </OverviewLayout>
      )}
    </ProductTabPanel>
  );
}
