import {Record} from 'immutable';

export interface ListOptionsProps {
  filters: any;
  groupBy: any;
  itemAggregate: any;
  rawConditions: any;
  order: string;
  offset: number;
  limit: number;
}

/**
 * @class ListOptions
 * @classdesc List options model.
 * https://github.com/severalnines/bar-pkg/blob/master/db/db.proto#L15
 */
export default class ListOptions extends Record({
  filters: undefined,
  groupBy: undefined,
  itemAggregate: undefined,
  rawConditions: undefined,
  order: undefined,
  offset: 1,
  limit: undefined,
}) {}
