/**
 * @desc Список днів тижня
 * @type {Array}
 * */
export const monthWeekDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];
