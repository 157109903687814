import React from 'react';
import {PageWrapper} from '../../../components/lib/Layout';

export default function TransactionPage() {
  return (
    <PageWrapper>
      <></>
    </PageWrapper>
  );
}
