import React, {useCallback} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {ScheduleCalendarProps, isFunction} from '../../../services/helpers';
import {ChildrenMustBeAFunction} from '../../../components/lib/Errors';
import {IDetailsHeaderProps} from '../../../components/lib/General';
import {CLIENTS} from '../../../components/lib/const';
import {CLIENT_TABS} from './ClientTabPanel';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import {ScheduleCalendarModel} from '../../../struture';
import {useLocation, useNavigate, useParams} from 'react-router';
import {Routes} from '../../../services/types';

export default withTranslation()(ClientScheduleDetail);

export interface IClientScheduleDetailProps
  extends ScheduleCalendarProps,
    WithTranslation {
  schedule?: ScheduleCalendarModel;
  children: (
    value: Pick<IDetailsHeaderProps, 'routes'> & {navigateGoBack: () => void},
  ) => React.ReactNode;
  clientFullName?: string;
}

function ClientScheduleDetail({
  children,
}: IClientScheduleDetailProps): JSX.Element {
  const {clientId} = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const routes = useBreadcrumb([
    {
      path: `/${Routes.clients}`,
      breadcrumbName: 'Counterparties',
    },
    {
      path: `/${Routes.clients}/${clientId}`,
      breadcrumbName: location?.state?.clientFullName || 'Show Client',
    },
    ...(location?.pathname?.includes(Routes.clientSchedules)
      ? [
          {
            path: `/${Routes.clients}/${clientId}/${Routes.clientSchedules}`,
            breadcrumbName: 'Orders',
          },
        ]
      : []),
    {
      path: ``,
      breadcrumbName: 'Calendar',
    },
  ]);

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.clients}/${clientId}`);
  }, [clientId, navigate]);

  if (!isFunction(children)) {
    throw new ChildrenMustBeAFunction('CompanyScheduleDetail');
  }

  return <>{children({routes, navigateGoBack})}</>;
}
