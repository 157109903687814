import {Col, Row} from 'antd';
import {useTranslation} from 'react-i18next';
import {StorePostingTabPanel} from '../Show';
import {useBreadcrumb, useModal} from '../../../../components/lib/libV2/hooks';
import {useAcl} from '../../../../contex';
import {TbTruckReturn} from 'react-icons/tb';
import styled, {css} from 'styled-components';
import {ClientDetailsView} from '../../../Clients';
import {StoreReturnUpdateButton} from '../../Return';
import {toDateByFormat} from '../../../../services/helpers';
import React, {Suspense, useCallback, useMemo} from 'react';
import {productStoreDocumentRoutes} from '../../../Products';
import {Routes, RoutesAcl} from '../../../../services/types';
import {OverviewLayout} from '../../../../components/lib/Layout';
import {useLocation, useNavigate, useParams} from 'react-router';
import StorePostingMenuButton from '../Buttons/StorePostingMenuButton';

import {
  CommentField,
  DetailsView,
  HeaderLeft,
  IDetailsViewItemProps,
  SuspenseEmpty,
} from '../../../../components/lib/DataDisplay';

import {
  useStateStoreDocumentItemList,
  useStopLoading,
  useStoredCompanies,
  useStoredProfile,
  useStoredStoreDocument,
  useStoredStoreDocumentList,
} from '../../../../hooks';

import {
  StoreDocumentMapper,
  StoreDocumentModel,
  StoreDocumentStatus,
  StoreDocumentType,
  StorePaymentDocumentFormDTO,
  StorePaymentDocumentPostActionType,
} from '../../../../struture';

import {
  StoreDetailsView,
  StoreDocExtraServicesView,
  StoreDocItemsView,
  StoreDocumentBottomView,
  StoreDocumentPageHeader,
} from '../../Show';

import '../../OverviewLayoutDetailsItem.less';

const CloseDocumentSideWindowLazy = React.lazy(
  () => import('../../Show/StorePaymentDocumentSideWindow'),
);

const StyledStoreReturnUpdateButton = styled(StoreReturnUpdateButton)<{
  disabled?: boolean;
}>`
  padding: 10px;
  border: 1px solid ${({theme}) => theme.colors.black};
  border-radius: 5px;
  cursor: pointer;
  height: 44px;

  ${({disabled}) =>
    disabled &&
    css`
      border: 1px solid ${({theme}) => theme.colors.disabled};
      color: ${({theme}) => theme.colors.disabled};
      cursor: not-allowed;
    `}

  &&&:hover,
  &&&:active,
  &&&:focus {
    border: 1px solid ${({theme}) => theme.colors.black};
    opacity: 0.8;
    color: ${({theme}) => theme.colors.black};
  }
`;

const StyledTbTruckReturn = styled(TbTruckReturn)`
  color: ${({theme}) => theme.colors.black};
`;

export function StorePostingPage() {
  const {documentId, productId} = useParams();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const location = useLocation();

  const {defaultCompanyUuid} = useStoredCompanies();
  const {store: storeAccess} = useAcl((acl) => acl);
  const {profile} = useStoredProfile();

  const {
    document,
    loading: storeLoading,
    error: storeError,
    handleUpdateStoreDocument,
    handlePaymentStoreDocument,

    handleCreateOrUpdateStoreDocumentItem,
    handleDeleteStoreDocumentItem,
    handlePrintStoreDocument,
    handleRefreshStoreDocument,
    handleCreateStoreDocument: handleCreateStoreReturn,
    handlePrintStoreDocumentCheck,
    handleUpdateStoreDocumentComment,
  } = useStoredStoreDocument({
    documentUuid: documentId!,
    companyUuid: defaultCompanyUuid,
    documentType: StoreDocumentType.IN,
  });

  const {
    storeDocumentItemList,
    loading: documentItemsLoading,
    refresh: storeDocumentItemListRefresh,
    limit: storeDocumentItemListLimit,
    total: storeDocumentItemListTotal,
    page: storeDocumentItemListPage,
    setStoreDocumentItemList,
  } = useStateStoreDocumentItemList({
    documentUuid: documentId!,
    limit: 100,
  });

  const {handleDeleteStoreDocuments: onDeleteStoreDocuments} =
    useStoredStoreDocumentList({
      companyUuid: defaultCompanyUuid,
      documentType: StoreDocumentType.IN,
      loadOnInit: false,
    });

  const navigateGoBack = useCallback(() => {
    navigate(
      location?.state?.goBack ||
        `/${Routes.app}/${Routes.stores}/${Routes.storePosting}`,
    );
  }, [location?.state?.goBack, navigate]);

  const handleDeleteStoreDocuments = useCallback(async () => {
    await onDeleteStoreDocuments([documentId!], navigateGoBack);
  }, [navigateGoBack, onDeleteStoreDocuments, documentId]);

  const storePayment = useMemo(
    () =>
      StoreDocumentMapper.toStorePaymentDocumentFormDTO(
        document || ({} as StoreDocumentModel),
        {
          cashier: profile?.defaultEmployeeModel,
          comment: `${t('Payment to the supplier according to the invoice')} №${
            document?.doc_local_number
          } ${t('From')} ${toDateByFormat(
            document?.doc_date,
            'DD.MM.YYYY HH:mm',
          )}`,
          payment_post_action: {
            action:
              StorePaymentDocumentPostActionType.SET_STORE_DOCUMENT_STATUS_COMMITTED,
          },
        },
      ),
    [document, profile?.defaultEmployeeModel, t],
  );

  const {
    handleCancel: storePaymentHandleCancel,
    visible: storePaymentVisible,
    handleSuccess: storePaymentHandleSuccess,
    handleOnInit: storePaymentHandleOnInit,
  } = useModal<StorePaymentDocumentFormDTO>({
    onSuccess: async (value) => {
      await handlePaymentStoreDocument(value, {with_document: true});
    },
  });

  const loading = useStopLoading({
    loading: storeLoading,
    error: storeError,
    message: 'An error occurred during store posting loading',
  });

  const routes = useBreadcrumb(
    productId
      ? productStoreDocumentRoutes(productId, (startUrl: string) => ({
          path: `${startUrl}/${Routes.stores}/${Routes.storePosting}/${documentId}`,
          breadcrumbName: document?.doc_local_number || 'Show document',
        }))
      : [
          {
            path: `/${Routes.stores}/${Routes.storePosting}`,
            breadcrumbName: 'Bill of Ladings',
          },
          {
            path: `/${Routes.stores}/${Routes.storePosting}/${documentId}`,
            breadcrumbName: document?.doc_local_number || 'Show document',
          },
        ],
  );

  const detailViewItems: IDetailsViewItemProps[] = [
    {
      title: 'Provider',
      className: 'client-details__view',
    },
    {
      title: 'Store',
      className: 'store-details__view',
    },
  ];

  return (
    <>
      <StorePostingTabPanel document={document}>
        {({tabs}) => (
          <OverviewLayout
            className="overview-details-item-store"
            aclItem={RoutesAcl[Routes.stores]}
            headerTitle={t('Bill of Lading')}
            headerLeft={
              <HeaderLeft absolute={false} onClick={navigateGoBack} />
            }
            headerRight={
              document?.doc_status_text === StoreDocumentStatus.DRAFT ? (
                <StorePostingMenuButton
                  fromList={false}
                  document={StoreDocumentMapper.toStoreDocumentFormDTO(
                    document!,
                    {
                      editMode: true,
                    },
                  )}
                  onSuccess={handleUpdateStoreDocument}
                  handlePaymentStoreDocument={handlePaymentStoreDocument}
                  handleDeleteStoreDocuments={handleDeleteStoreDocuments}
                />
              ) : null
            }
            underHeaderContent={
              document?.uuid ? (
                <StoreDocumentPageHeader document={document} />
              ) : null
            }
            header={
              <Row gutter={10}>
                <Col span={24}>
                  <DetailsView items={detailViewItems}>
                    {document?.clientModel?.uuid ? (
                      <ClientDetailsView client={document?.clientModel} />
                    ) : null}
                    {document?.companyStoreModel?.uuid ? (
                      <StoreDetailsView store={document?.companyStoreModel} />
                    ) : null}
                  </DetailsView>
                </Col>
                <Col span={24}>
                  <CommentField
                    comment={document?.doc_comment || t('Please enter note')}
                    handleUpdateComment={handleUpdateStoreDocumentComment}
                    disabled={!storeAccess?.manage}
                  />
                </Col>
                <Col span={24}>
                  <StoreDocItemsView
                    productWithBalance={false}
                    document={document!}
                    handleCreateOrUpdateStoreDocumentItem={
                      handleCreateOrUpdateStoreDocumentItem
                    }
                    handleDeleteStoreDocumentItem={
                      handleDeleteStoreDocumentItem
                    }
                    loading={documentItemsLoading}
                    storeDocumentItemList={storeDocumentItemList}
                    storeDocumentItemListRefresh={storeDocumentItemListRefresh}
                    storeDocumentItemListLimit={storeDocumentItemListLimit}
                    storeDocumentItemListTotal={storeDocumentItemListTotal}
                    storeDocumentItemListPage={storeDocumentItemListPage}
                  />
                </Col>
                {Array.isArray(document?.doc_extra_services) ? (
                  <Col span={24}>
                    <StoreDocExtraServicesView document={document!} />
                  </Col>
                ) : null}
              </Row>
            }
            outsideFooterContent={
              <StoreDocumentBottomView
                document={document}
                handlePrintStoreDocument={handlePrintStoreDocument}
                handlePaymentStoreDocument={storePaymentHandleOnInit}
                handlePrintStoreDocumentCheck={handlePrintStoreDocumentCheck}
                buttons={({isPhoneScreen}) => (
                  <StyledStoreReturnUpdateButton
                    fromList={false}
                    from={StoreDocumentType.IN}
                    loading={loading}
                    onSuccess={async (value) => {
                      const storeDocument = await handleCreateStoreReturn({
                        ...value,
                      });

                      setTimeout(async () => {
                        await handleRefreshStoreDocument();
                      }, 500);

                      return storeDocument?.document;
                    }}
                    handlePaymentStoreDocument={handlePaymentStoreDocument}
                    icon={<StyledTbTruckReturn size={18} />}
                    title={
                      <>
                        {isPhoneScreen ? t('Create return to supplier') : null}
                      </>
                    }
                    document={StoreDocumentMapper.toStoreDocumentFormDTO(
                      {
                        documentModel: document!,
                        companyStoreModel: document?.companyStoreModel,
                        client: document?.client,
                        doc_status_text: StoreDocumentStatus.DRAFT,
                        doc_type: StoreDocumentType.RETURN,
                      } as StoreDocumentModel,
                      {
                        editMode: true,
                        default_doc_items: document?.doc_items || [],
                      },
                    )}
                  />
                )}
              />
            }
            disabled={!storeAccess?.manage}
            editTooltip={t('Edit bill of lading')}
            loading={loading}
            data={null}
            routes={routes}
            tabs={tabs}
          />
        )}
      </StorePostingTabPanel>

      <Suspense fallback={<SuspenseEmpty />}>
        <CloseDocumentSideWindowLazy
          title={`${t('Closing posting')}`}
          payment={storePayment!}
          loading={loading}
          onSuccess={storePaymentHandleSuccess}
          onCancel={storePaymentHandleCancel}
          visible={storePaymentVisible}
        />
      </Suspense>
    </>
  );
}
