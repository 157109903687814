import React from 'react';
import {Text} from '../Format';
import {ButtonProps, Tooltip} from 'antd';
import {StyledIconButton} from '../Styled/Buttons';
import styled, {useTheme, css} from 'styled-components';
import {CloseCircleOutlined, LoadingOutlined} from '@ant-design/icons';
export interface ICancelButtonProps extends ButtonProps {
  title?: string;
  tooltipTitle?: string;
  color?: string;
}

const StyledTooltip = styled(Tooltip)`
  width: auto;
`;

const StyledCloseCircleOutlined = styled(CloseCircleOutlined)<{
  $color?: string;
}>`
  ${({$color}) =>
    $color &&
    css`
      color: ${$color};
    `}
`;

export function CancelButton({
  title,
  tooltipTitle,
  style,
  type = 'text',
  loading,
  color,
  disabled,
  ...props
}: ICancelButtonProps): JSX.Element {
  const theme: any = useTheme();

  return (
    <StyledTooltip title={tooltipTitle}>
      <StyledIconButton
        type={type}
        $useCustomStyle={type !== 'text'}
        style={style}
        loading={!!(title && loading)}
        disabled={disabled || !!(title && loading)}
        {...props}>
        {title ? (
          <Text color={color}>{title}</Text>
        ) : loading ? (
          <LoadingOutlined color={color || theme.colors.primary} />
        ) : (
          <StyledCloseCircleOutlined $color={color || theme.colors.primary} />
        )}
      </StyledIconButton>
    </StyledTooltip>
  );
}
