import {Record, List} from 'immutable';
import {IListProps} from './List';
import {RewardDTO, RewardModel, RewardStatsDTO} from '../internal';
import {WithoutRecursion} from '../type';

export interface IRewardListModelProps extends IListProps {
  rewards: RewardDTO[];
  stats?: RewardStatsDTO;
  keywords?: string;
  page?: number;
}

export interface IRewardListModelReturnType extends IListProps {
  rewards: List<RewardModel>;
  keywords?: string;
  page?: number;
  stats?: RewardStatsDTO;
}

export class RewardListModel extends Record<IRewardListModelReturnType>({
  rewards: List(),
  total: 0,
  stats: {} as RewardStatsDTO,
  keywords: '',
  page: 1,
}) {
  constructor(
    {
      rewards = [],
      ...props
    }: IRewardListModelProps = {} as IRewardListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IRewardListModelReturnType = {
      ...props,
      rewards: List(
        rewards.map((reward) => new RewardModel(reward, withoutRecursion)),
      ),
    };

    super(options);
  }
}
