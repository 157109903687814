import {List} from 'immutable';
import {
  CashBoxDTO,
  ICashBoxDTOProps,
  CashBoxModel,
  CashBoxListModel,
  CashBoxAdjustBalancesFormDTO,
  CashBoxMovingFormDTO,
  ICashBoxAdditionalProps,
  CashBoxFormDTO,
} from '../internal';
import {listToArray} from '../../helpers';
import {WithoutRecursion} from '../type';

export interface ICashBoxListDTO {
  cashboxes: CashBoxDTO[];
  total: number;
}

export class CashBoxMapper {
  public static toCashBoxDTO(
    cashBox: CashBoxModel | ICashBoxDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): CashBoxDTO {
    return new CashBoxDTO(cashBox, [
      WithoutRecursion.cashBox,
      ...withoutRecursion,
    ]);
  }

  public static toCashBoxListDTO(
    cashboxes: List<CashBoxModel> | ICashBoxDTOProps[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): ICashBoxListDTO {
    const cashBoxList = List.isList(cashboxes)
      ? listToArray<CashBoxModel>(cashboxes)
      : cashboxes;

    return {
      cashboxes: cashBoxList?.map((cashBox) =>
        CashBoxMapper.toCashBoxDTO(cashBox, [
          WithoutRecursion.cashBox,
          ...withoutRecursion,
        ]),
      ),
      total,
    };
  }

  public static toCashBoxModel(
    cashBoxDTO: CashBoxDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): CashBoxModel {
    return new CashBoxModel(cashBoxDTO, [
      WithoutRecursion.cashBox,
      ...withoutRecursion,
    ]);
  }

  public static toCashBoxListModel(
    cashBoxDTOs: CashBoxDTO[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): CashBoxListModel {
    return new CashBoxListModel({cashboxes: cashBoxDTOs, total}, [
      WithoutRecursion.cashBox,
      ...withoutRecursion,
    ]);
  }

  public static toCashBoxFormDTO(
    cashBoxModel: CashBoxModel,
    cashBoxAdditionalProps: ICashBoxAdditionalProps,
  ): CashBoxFormDTO {
    return new CashBoxFormDTO(cashBoxModel, cashBoxAdditionalProps);
  }

  public static toCashBoxAdjustBalancesFormDTO(
    cashBoxModel: CashBoxModel,
  ): CashBoxAdjustBalancesFormDTO {
    return new CashBoxAdjustBalancesFormDTO(cashBoxModel);
  }

  public static toCashBoxMovingFormDTO(
    cashBoxModel: CashBoxModel,
  ): CashBoxMovingFormDTO {
    return new CashBoxMovingFormDTO(cashBoxModel);
  }
}
