import React, {useCallback} from 'react';
import {OverviewLayout} from '../../../components/lib/Layout';
import {
  useStoredCompanies,
  useRequiredFields,
  useStopLoading,
} from '../../../hooks';
import {REQUIRED_INITIAL_PARAM} from '../../../services/api/company';
import CompanyPictures from '../Show/CompanyPictures';
import {useAcl} from '../../../contex';
import CompanyTabPanel from '../Show/CompanyTabPanel';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import {CompanyMapper} from '../../../struture';
import {Routes, RoutesAcl} from '../../../services/types';
import {useNavigate, useParams} from 'react-router';
import {CompanyEditButton} from '../Buttons';
import {HeaderLeft} from '../../../components/lib/DataDisplay';

export default function CompanyPage(): JSX.Element {
  const {companyId: defaultCompanyUuid} = useParams();
  const navigate = useNavigate();
  const {manage} = useAcl(({company}) => company);

  const {
    defaultCompany,
    defaultCompanyLoading,
    updateCompany,
    setCompanyLogo,
    setCompanyCover,
    defaultCompanyError,
  } = useStoredCompanies({companyUuid: defaultCompanyUuid});

  const loading = useStopLoading({
    loading: defaultCompanyLoading,
    error: defaultCompanyError,
    message: 'An error occurred during company loading',
  });

  const routes = useBreadcrumb([]);

  const handleSetCompanyLogo = useCallback(
    async (file: File): Promise<string | void> =>
      await setCompanyLogo({file, uuid: defaultCompanyUuid!}),
    [defaultCompanyUuid, setCompanyLogo],
  );

  const handleSetCompanyCover = useCallback(
    async (file: File): Promise<string | void> =>
      await setCompanyCover({file, uuid: defaultCompanyUuid!}),
    [defaultCompanyUuid, setCompanyCover],
  );

  const overviewData = useRequiredFields(
    defaultCompany,
    REQUIRED_INITIAL_PARAM,
  );

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.dashboard}`);
  }, [navigate]);

  return (
    <CompanyTabPanel
      timeRange={defaultCompany && defaultCompany?.work_schedules}>
      {({tabs}) => (
        <OverviewLayout
          aclItem={RoutesAcl[Routes.companies]}
          headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
          headerRight={
            <CompanyEditButton
              company={CompanyMapper.toCompanyFormDTO(defaultCompany!)}
              onSuccess={updateCompany as any}
            />
          }
          disabled={!manage}
          loading={loading}
          data={overviewData}
          routes={routes}
          headerTitle={defaultCompany?.title}
          tabs={tabs}
          header={
            <CompanyPictures
              disabled={!manage}
              onUploadCoverFile={handleSetCompanyCover}
              onUploadLogoFile={handleSetCompanyLogo}
              logo={defaultCompany?.logo_url}
              cover={defaultCompany?.cover_url}
              qrCode={defaultCompany?.qr_code_url}
              title={defaultCompany?.title}
              invite={CompanyMapper.toCompanyInviteFormDTO(defaultCompany!)}
            />
          }
        />
      )}
    </CompanyTabPanel>
  );
}
