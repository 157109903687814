import {Record} from 'immutable';

export interface EmployeeDayOffProps {
  uuid: string;
  start_date: string;
  end_date: string;
  comment: string;
}

/**
 * @class EmployeeDayOff
 * @classdesc
 * @extends {Record}
 * @property {string}
 */
export default class EmployeeDayOff extends Record({
  uuid: '',
  start_date: '',
  end_date: '',
  comment: '',
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(props: EmployeeDayOffProps = {} as EmployeeDayOffProps) {
    super({...props});
  }
}
