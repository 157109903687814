import React from 'react';
import {AclKey} from '../../../services/types';
import {useAcl} from '../../../contex';
import {AclDTO, AclMapper, AclModel} from '../../../struture';
import EmptyLayout from './EmptyLayout';
import {acl} from '../../../services/api/user';

export interface IAclLayoutProps {
  children: React.ReactNode | React.ReactNode[];
  aclItem?: AclKey;
}

export function AclLayout({children, aclItem}: IAclLayoutProps): JSX.Element {
  const {read} = useAcl<AclModel>((acl) => {
    if (aclItem) {
      return acl[aclItem];
    }
    return AclMapper.toAclModel({} as AclDTO);
  });

  return <>{read ? children : <EmptyLayout />}</>;
}
