/**
 * @desc Builds form data
 * @param {Object} file
 * @param {String} name
 * @param {String} platform
 * @return {Object}
 * */
export function buildImageFormData(
  file: any,
  name: string,
  platform: string = 'android',
): FormData {
  const formData = new FormData();

  if (Array.isArray(file)) {
    file.forEach((file) => {
      formData.append(name, {
        name,
        type: 'image/png',
        uri: file.uri,
      } as any);
    });
  } else {
    const uriParts = file?.uri.split('.');
    const fileType = uriParts[uriParts.length - 1];

    formData.append(name, {
      name,
      type: `${file.type}/${fileType}`,
      uri: platform === 'android' ? file.uri : file.uri.replace('file://', ''),
    } as any);
  }

  return formData;
}
