import * as React from 'react';
import {ILiteralObj} from '../services/types';

export enum STATE_REDUCER_ACTIONS {
  UPDATE = 'update',
  DEFAULT = 'default',
}

export interface UpdateStateReducerAction extends ILiteralObj {
  type: STATE_REDUCER_ACTIONS.UPDATE;
}

export type StateReducerActions = UpdateStateReducerAction;

export const handlers = {
  [STATE_REDUCER_ACTIONS.UPDATE]: <T>(
    state: T,
    {type, ...rest}: StateReducerActions,
  ): T => ({
    ...state,
    ...rest,
  }),
  [STATE_REDUCER_ACTIONS.DEFAULT]: <T>(state: T): T => state,
};

export type UseStateReducerReturnType<T> = T & {
  handleUpdate: (value: Partial<T>) => void;
};

export default function useStateReducer<T>(
  initialSate: T,
): UseStateReducerReturnType<T> {
  const [stateDict, dispatch] = React.useReducer(
    (state: T, action: StateReducerActions) => {
      const handler =
        handlers[action.type] || handlers[STATE_REDUCER_ACTIONS.DEFAULT];
      return handler<T>(state, action as any);
    },
    {
      ...initialSate,
    },
  );

  const handleUpdate = React.useCallback(
    (value: Partial<T>): void => {
      dispatch({type: STATE_REDUCER_ACTIONS.UPDATE, ...value});
    },
    [dispatch],
  );

  return {
    ...stateDict,
    handleUpdate,
  };
}
