import moment, {Moment} from 'moment';
import immutable, {List} from 'immutable';
import {FormInstance} from 'antd/lib/form/Form';
import {
  FunctionArgs,
  isThereContent,
  getArrayLength,
  isArrayGetFirstItem,
  reduce,
  compose,
  eq,
  some,
  map,
  find,
  getFileNameInUrl,
  head,
  toMomentString,
  findIndex,
  timeRangeSplitting,
  forEach,
  IFinancialReliabilityProps,
  CompanySetting,
  dateToIsoString,
  last,
  toMoment,
  setMomentHour,
  isRecordToObject,
  isObject,
  isFunction,
  every,
  SystemSubscription,
  ProfileSubscription,
  prop,
  filter,
  len,
  not,
  memoizeUnaryArity,
  greaterThan,
  ifElse,
  isEmptyV2,
  joinArr,
  lessThan,
  slice,
  split,
  neq,
  buildDateByFormat,
  curry,
  isEqualByUuid,
} from './index';
import {
  Entries,
  ILiteralObj,
  FileUploader,
  DateValue,
  RangeTime,
  GroupBy,
  PLAN_STATUS,
} from '../types';
import {EMPLOYEES, ADMIN_ROUTES} from '../../components/lib/const';
import {tokenHandler} from '../core/token';

export const isNotEqualByUuid = curry<[string, string], boolean>(
  (uuid: string, targetUuid: string): boolean => neq(targetUuid, uuid),
);

export const isObjectEqualByUuid = curry<any, any>(
  <T extends ILiteralObj>(current: T, target: T): boolean =>
    eq(target?.uuid, current?.uuid),
);

export const isNotEqualByItemUuid = curry<any, any>(
  <T extends ILiteralObj>(uuid: string, targetUuid: T): boolean =>
    targetUuid.uuid !== uuid,
);

export const updateByUuid = curry<any, any>(
  <T extends ILiteralObj>(updated: T, target: T): T => {
    if (eq(target?.uuid, updated?.uuid)) {
      return updated;
    }
    return target;
  },
);

export const updateBy = curry<any, any>(
  <T extends ILiteralObj>(prop: string, updated: T, target: T): T => {
    if (eq(target[prop], updated[prop])) {
      return updated;
    }
    return target;
  },
);

export const updateByProp = curry<any, any>(
  <T extends ILiteralObj>(findProp: string, updated: T, target: T): T => {
    if (eq(prop(findProp, target), prop(findProp, updated))) {
      return updated;
    }
    return target;
  },
);

export const getRootKey = compose<string>(joinArr(''), slice(1, 2), split('/'));

// TODO:протестити можливо змінити some на every
export const getNotEmptyValueFromEntriesObject = curry<any, any>(
  <T extends {}>(
    searchProps: string[][] | string[],
    entity: T,
    acc: any,
    [key, value]: Entries<T>,
  ): T => {
    const search = find<any, any>((item: any) => {
      if (eq(key, isArrayGetFirstItem(item))) {
        return Array.isArray(item) ? item : [item];
      }
    }, searchProps);

    if (Array.isArray(search) && head(search) && isEmptyV2(value)) {
      const isRecordValue = isRecordToObject(value);

      if (isObject(isRecordValue) ? isThereContent(isRecordValue) : true) {
        acc[key] = isObject(last(search))
          ? reduce(
              getNotEmptyValueFromEntriesObject(
                Object.entries(last(search)),
                entity,
              ),
              {},
              Object.entries(isRecordValue),
            )
          : isFunction(last(search))
          ? last(search)(value, entity)
          : value;
      }
    }

    return acc;
  },
);

// TODO: подумати як видалити toObject()
export const updatePropsOfArrays = curry<[any[], any[]], any[]>(
  <T extends ILiteralObj>(targets: T[], currents: T[]) =>
    map(
      (current: any): T => ({
        ...isRecordToObject(current),
        ...(find(isObjectEqualByUuid(isRecordToObject(current)))(targets) as T),
      }),
    )(currents),
);

// TODO: подумати як видалити toObject()
export const updatePropsOfArrays2 = curry<any, any>(
  <T extends ILiteralObj>(targets: T[], currents: T[]) =>
    map((current: any) => ({
      ...current,
      ...(find(isObjectEqualByUuid(current))(targets) as T),
    }))(currents),
);

export const fileUploadStructure = (src: string): FileUploader => ({
  uid: getFileNameInUrl(src),
  name: getFileNameInUrl(src),
  status: 'done',
  url: src,
});

export const correctBirthdayValue = <T extends ILiteralObj>({
  birthday,
  ...rest
}: T): Pick<T, Exclude<keyof T, 'birthday'>> & {
  birthday: string | undefined;
} => ({
  ...rest,
  birthday: ifElse(!!birthday, buildDateByFormat(birthday), undefined),
});

export const correctDateAndStatus = <T extends ILiteralObj>({
  start_date,
  status,
  ...rest
}: T): Pick<T, Exclude<keyof T, 'start_date' | 'status'>> & {
  start_date: string | undefined;
  status: number;
} => ({
  ...rest,
  status: Number(status),
  start_date: ifElse(
    !!start_date,
    toMomentString(start_date, 'YYYY-MM-DD'),
    undefined,
  ),
});

export const correctPaymentDate = <T extends ILiteralObj>({
  payment_date,
  ...rest
}: T): Pick<T, Exclude<keyof T, 'payment_date'>> & {
  payment_date: string | undefined;
} => ({
  ...rest,
  payment_date: ifElse(
    !!payment_date,
    toMomentString(payment_date, 'YYYY-MM-DD'),
    undefined,
  ),
});

export const checkUrlParam = (param: any, length = 2): boolean => {
  if (!param) {
    return true;
  }

  if (!Number(param)) {
    return true;
  }
  // TODO:проверить replaceAll param: string
  const ParamLength = param.replaceAll('[^0-9]', '').length;

  return lessThan(ParamLength, length) || greaterThan(ParamLength, length);
};

const splitPipe = split('|');
const splitComa = split(',');

export const checkComaSplitting = compose(getArrayLength, splitComa);
export const checkPipeSplitting = compose(getArrayLength, splitPipe);

export const checkWorkSchedule = (value: string): boolean =>
  checkComaSplitting(value) === 1 && checkPipeSplitting(value) === 1;

export const timeToApiFormat = compose(
  joinArr('-'),
  map((value: any) => toMomentString(value, 'HH:mm')),
);

export const splittingGetFirstItem = (value: string) => head(splitPipe(value));

export const findItemIndex = (find: string, arr: any[]): number =>
  findIndex((weekDay: any) => weekDay.indexOf(find) >= 0)(arr);

export const getListOfWeekday = curry<any, any>(
  (isRangePicker: boolean, acc: any, curr: string): any => {
    const index = curr ? findItemIndex(splittingGetFirstItem(curr), acc) : -1;

    if (index >= 0) {
      acc[index] = curr
        ? isRangePicker
          ? timeRangeSplitting(curr)
          : split('|', curr)
        : curr;
    }

    return acc;
  },
);

export interface IChangeWeekdays {
  weekdays: any;
  index: number;
  checked: boolean;
  time: string;
  name: string;
}

export const modifyWeekdays = ({
  weekdays,
  index,
  checked,
  time,
  name,
}: IChangeWeekdays): void | any[] => {
  if (!checked && index >= 0) {
    const deleteWeekday = [...weekdays];

    deleteWeekday.splice(index, 1);

    return deleteWeekday;
  }

  if (index < 0 && time) {
    return [...weekdays, `${name}|${time}`];
  }

  if (checked && index >= 0) {
    return weekdays.map((weekday: string[]) =>
      weekday.indexOf(name) >= 0 ? `${name}|${time}` : weekday,
    );
  }
};

export interface StepsFields {
  [key: string]: string[];
}

export const buildStepsFields = map(
  ({key, props}: any): StepsFields => ({
    [key]: props?.checkFieldsOnStepChange || [],
  }),
);

export const getErrorFields = curry<any, any>(
  (errors: any[], acc: string[], curr: StepsFields): string[] => {
    forEach((value: any) => {
      forEach(([key, currValue]: any) => {
        if (currValue.includes(head(value?.name))) {
          acc = acc.includes(key) ? acc : [...acc, key];
        }
      })(Object.entries(curr));
    })(errors);

    return acc;
  },
);

export interface FindByUuid<T> {
  array: T[] | List<T>;
  uuid: string;
}

export const memoizeFindByUuid: (
  map?: Map<any, any>,
) => <T>({array, uuid}: FindByUuid<T>) => T | undefined = (
  map: Map<any, any> = new Map(),
) =>
  memoizeUnaryArity(
    <T>({array, uuid}: FindByUuid<T>): T | undefined =>
      array.find(isEqualByUuid(uuid)),
    map,
    'memoizeFindByUuid',
  );

export const isEqualByDate = curry<any, any>(
  <T extends ILiteralObj & {date: DateValue}>(date: DateValue, find: T) =>
    eq(
      moment(date).format('YYYY-MM-DD'),
      moment(find?.date).format('YYYY-MM-DD'),
    ),
);

export const isEqualByScheduledDate = curry<any, any>(
  <T extends ILiteralObj & {date: DateValue}>(date: DateValue, find: T) =>
    eq(
      moment(date).format('YYYY-MM-DD'),
      moment(find?.scheduled_date).format('YYYY-MM-DD'),
    ),
);

export interface FindByDate<T> {
  array: T[];
  date: DateValue;
}

export const memoizeFindByDate: (
  map?: Map<any, any>,
) => <T>({array, date}: FindByDate<T>) => T | undefined = (
  map: Map<any, any> = new Map(),
) =>
  memoizeUnaryArity(
    <T>({array, date}: FindByDate<T>): T | undefined =>
      find<[any, any], T>(isEqualByDate(date), array),
    map,
    'memoizeFindByDate',
  );

export const getObjectProps = <T>(acc: any, curr: string): T | void => {
  try {
    if (acc[curr]) {
      acc = acc[curr];
    }

    return acc;
  } catch (e: any) {}
};

export const correctClientSubscriptionValue = <T extends ILiteralObj>({
  subscription_start_date,
  payment_date,
  group_session_uuid,
  ...rest
}: T): T => ({
  ...rest,
  subscription_start_date: ifElse(
    !!subscription_start_date,
    toMomentString(subscription_start_date, 'YYYY-MM-DD'),
    new Date().toString(),
  ),
  payment_date: ifElse(
    !!payment_date,
    toMomentString(payment_date, 'YYYY-MM-DD'),
    new Date().toString(),
  ),
  ...ifElse(!!group_session_uuid, {group_session_uuid}, {}),
});

export const correctPaymentValue = <T extends ILiteralObj>({
  payment_date,
  ...rest
}: T): Pick<T, Exclude<keyof T, 'payment_date'>> & {
  payment_date: string;
} => ({
  ...rest,
  payment_date: ifElse(
    !!payment_date,
    dateToIsoString(payment_date),
    dateToIsoString(new Date()),
  ),
});

export const reduceCompanySetting = (
  acc: any,
  curr: CompanySetting,
): IFinancialReliabilityProps => {
  Reflect.set(acc, curr?.setting_key, curr?.setting_value);
  return acc;
};

export const reformatCompanySetting = (value: CompanySetting[]) =>
  reduce(reduceCompanySetting, {}, value);

export const getLastItemArray = (value: any): any[] | string =>
  Array.isArray(value) && getArrayLength(value) > 0 ? last(value) : '';

export const getWeekdaysTime = (value: string[]): string =>
  compose<string>(getLastItemArray, split('|'), getLastItemArray)(value);

export const getWeekdayPickerArrayOrString = (
  value: string,
): string[] | string | void => {
  if (value) {
    const isPikerValue = split('-', value);

    if (getArrayLength(isPikerValue) > 1) {
      return isPikerValue;
    }

    return value;
  }

  return undefined;
};

export const getWeekdayPickerTimeFormat = (value: any) =>
  compose<any>(getWeekdayPickerArrayOrString, getWeekdaysTime)(value);

export const toRangeTimePikerTime = (time: RangeTime): [Moment, Moment] => [
  toMoment(head(time), 'HH:mm'),
  toMoment(last(time), 'HH:mm'),
];

export const buildRangePikerTime = (
  time: RangeTime,
  defaultTime: RangeTime | undefined,
): [Moment, Moment] | void => {
  if (time && getArrayLength(time) === 0) {
    if (Array.isArray(defaultTime)) {
      return toRangeTimePikerTime(defaultTime);
    }

    return [
      toMoment(new Date(), 'HH:mm'),
      toMoment(new Date(), 'HH:mm').add(1, 'hour'),
    ];
  }

  return toRangeTimePikerTime(time);
};

export const buildTimePickerTime = (
  time: DateValue | null,
  defaultTime: DateValue | undefined,
): Moment => {
  if (time === null) {
    if (defaultTime) {
      return toMoment(defaultTime, 'HH:mm');
    }

    return toMoment(new Date(), 'HH:mm');
  }
  return toMoment(time, 'HH:mm');
};

export const getPickerHourOrMinute = (time: string): Moment => {
  const splitTime = split(':', time);
  return setMomentHour({
    hour: head(splitTime),
    minute: last(splitTime),
  });
};

export const getRangePickerHourOrMinute = map((time: string) =>
  getPickerHourOrMinute(time),
);

export const handleReportPrint = (id: string): void => {
  const printArea = (document as any).getElementById(id).innerHTML;

  const frameName: string = `${id}-report-viewer__print`;

  let iframeWin: any = window.frames[frameName as any];
  let iframe: HTMLIFrameElement | undefined;

  if (!iframeWin) {
    iframe = document.createElement('iframe');

    iframe.setAttribute('hidden', 'true');
    iframe.setAttribute('name', frameName);
    document.body.append(iframe);

    iframeWin = window.frames[iframe.name as any];
  }

  iframeWin.document.body.innerHTML = printArea;
  iframeWin.document.close();
  iframeWin.focus();
  iframeWin.print();

  window.addEventListener('afterprint', (event) => {
    if (iframe) {
      iframe.remove();
    }
  });
};

export type IGetTransformOriginByIdReturnType = {
  offsetLeft: string;
  offsetTop: string;
};

export const getTransformOriginById = (
  id: string,
): IGetTransformOriginByIdReturnType | null => {
  const sheetContainer: HTMLElement | null = document.getElementById(id);

  if (sheetContainer) {
    const offsetLeft =
      parseInt(window.getComputedStyle(sheetContainer).width, 10) / 2;

    const offsetTop =
      parseInt(window.getComputedStyle(sheetContainer).height, 10) / 2;

    return {
      offsetLeft: `${offsetLeft}px`,
      offsetTop: `${offsetTop}px`,
    };
  }

  return null;
};

const getLongestWord = (acc: string, curr: string): string =>
  acc.length > curr.length ? acc : curr;

export const isEmployeesUrl = (url: string): boolean =>
  url.includes(`${EMPLOYEES}/show`);

export const getFromUrlEmployeeId = (url: string): string => {
  const splittingUrl = split('/', url);

  return reduce(getLongestWord, '', splittingUrl);
};

export const isEmployeesUrlGetId = (url: string): string | null =>
  isEmployeesUrl(url) ? getFromUrlEmployeeId(url) : null;

export const sortByTypeEntries = ([_, value]: any) => {
  if (isObject(value)) {
    return 1;
  }
  return -1;
};

export const filterFormErrorFields = curry<any, any>(
  <T extends ILiteralObj>(
    formFields: [string, string][],
    errorField: T,
  ): boolean => {
    const field = find(
      ([key]: [string, string]): boolean => eq(key, head(errorField?.name)),
      formFields,
    );

    return Array.isArray(field)
      ? !(getArrayLength(field) === 2 && last(field))
      : true;
  },
);

export const isIncludesErrorFields = <T extends ILiteralObj>(
  errors: T[],
  prop: string,
) =>
  some<[FunctionArgs<T, boolean>, T[]], boolean>(
    (error: T) => eq(head(error?.name), prop),
    errors,
  );

export const buildFullName = <T extends ILiteralObj>(
  callback: (value: keyof T) => any,
  {first_name, last_name, ...rest}: T,
): Pick<T, Exclude<keyof T, 'first_name' | 'last_name'>> & {
  fullName: string;
} => ({
  ...((first_name || last_name) && {
    fullName: callback(
      `${first_name ? `${first_name} ` : ''}${last_name ? last_name : ''}`,
    ),
  }),
  ...rest,
});

export const findProfileSubscription = (
  subscription: SystemSubscription,
  profileSubscriptions: ProfileSubscription[],
): ProfileSubscription | undefined =>
  find(
    (profileSubscription: ProfileSubscription) =>
      eq(
        subscription?.subscription_key,
        profileSubscription?.system_subscription?.subscription_key,
      ),
    profileSubscriptions,
  );

export const mergedSystemSubscriptions = (
  subscriptions: SystemSubscription[],
  profileSubscriptions: ProfileSubscription[],
): SystemSubscription[] =>
  map((systemSubscription: SystemSubscription) => {
    const profileSubscription = findProfileSubscription(
      systemSubscription,
      profileSubscriptions,
    );

    if (profileSubscription?.status_txt) {
      return immutable.set(
        systemSubscription,
        'status_txt',
        profileSubscription?.status_txt,
      );
    }

    return systemSubscription;
  }, subscriptions);

export const deleteFromEntriesObject = curry<any, any>(
  <T>(
    ids: string[],
    idName: string,
    acc: any,
    [key, value]: Entries<T>,
  ): GroupBy<T> => {
    acc = {
      ...acc,
      [acc[key]]: Array.isArray(acc[key])
        ? filter(
            (i: any) => every(isNotEqualByUuid(prop(idName, i)), ids),
            acc[key],
          )
        : value,
    };

    return acc;
  },
);

interface IfOnceSetField<T> extends Pick<FormInstance, 'setFieldsValue'> {
  field: string;
  array: T[];
  identifier?: string;
}

export const ifOnceSetField = <T>({
  field,
  array,
  setFieldsValue,
  identifier = 'uuid',
}: IfOnceSetField<T>): void => {
  if (Array.isArray(array) && eq(len(array), 1)) {
    setFieldsValue({[field]: head<any>(array)[identifier]});
  }
};

const isIncludesString = curry<[string[], string], boolean>(
  (array: string[], item: string): boolean => array.includes(item),
);

export const checkIsAdminRoute = (
  path: string,
  adminRoutes: string[] = ADMIN_ROUTES,
): boolean =>
  compose<boolean>(
    not,
    some(isIncludesString(adminRoutes) as any),
    split('/'),
  )(path);

export const isActivePlan = (plans: any[]): boolean | undefined => {
  if (Array.isArray(plans)) {
    const isInactive = plans.some(
      (plan) => plan?.status !== PLAN_STATUS.Active,
    );

    if (isInactive) {
      tokenHandler.setActivePlan('false');
    } else {
      tokenHandler.setActivePlan('true');
    }
    return !isInactive;
  }
};

export const printScheduleDocument = (html: string) => {
  const id = 'print-schedule-doc';

  let iframe = document.body.querySelector(`#${id}`);

  if (iframe) {
    document.body.removeChild(iframe);
  }

  iframe = document.createElement('iframe');

  if (iframe instanceof HTMLIFrameElement) {
    iframe.width = '0';
    iframe.height = '0';
    iframe.id = id;
    iframe.src = 'about:blank';

    document.body.appendChild(iframe);
  }

  if (iframe instanceof HTMLIFrameElement && iframe.contentWindow) {
    (iframe.contentWindow as any).contents = html;
    iframe.src = 'javascript:window["contents"]';

    iframe.contentWindow.addEventListener('afterprint', () => {
      if (iframe instanceof HTMLIFrameElement) {
        document.body.removeChild(iframe);
      }
    });

    iframe.onload = () => {
      if (iframe instanceof HTMLIFrameElement && iframe.contentWindow) {
        iframe.contentWindow.print();
      }
    };

    iframe.focus();
  }
};
