import React, {useCallback, useEffect} from 'react';
import {isFunction} from '../../../services/helpers';
import {ChildrenMustBeAFunction} from '../../../components/lib/Errors';
import {IAuthRouteItemProps} from '../../Authorization';
import {WithTranslation} from 'react-i18next';
import {Routes} from '../../../services/types';
import {useLocation, useNavigate} from 'react-router';
import {contactSupport} from '../../../services/const';

interface ITabChildrenProps {
  routes: Omit<IAuthRouteItemProps, keyof WithTranslation>[];
}

export interface IAppInfoTabPanelProps {
  children: (value: ITabChildrenProps) => React.ReactNode;
  showForgotPasswordWarning?: boolean;
  showSignupWarning?: boolean;
}

export enum APP_INFO_TABS {
  TERMS_AND_CONDITIONS = 'Terms and Conditions',
  PRIVACY_AND_GDPRS = 'Privacy and GDPRS',

  CONTACT_SUPPORT = 'Contact support',
}

export function AppInfoTabPanel({
  children,
  showSignupWarning,
  showForgotPasswordWarning,
}: IAppInfoTabPanelProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const [routes, setRoutes] = React.useState<
    Omit<IAuthRouteItemProps, keyof WithTranslation>[]
  >([]);

  const handleNavigateToContactSupport = useCallback(() => {
    window.open(contactSupport, '_blank');
  }, []);

  useEffect(() => {
    setRoutes([
      {
        key: APP_INFO_TABS.TERMS_AND_CONDITIONS,
        title: APP_INFO_TABS.TERMS_AND_CONDITIONS,
        navigation: () => {
          navigate(`/${Routes.app}/${Routes.termsAndConditions}`, {
            state: {
              from: location.pathname,
              prevState: location.state || {},
            },
          });
        },
      },
      {
        key: APP_INFO_TABS.PRIVACY_AND_GDPRS,
        title: APP_INFO_TABS.PRIVACY_AND_GDPRS,
        navigation: () => {
          navigate(`/${Routes.app}/${Routes.privacyAndGDPRS}`, {
            state: {from: location.pathname, prevState: location.state || {}},
          });
        },
      },

      {
        key: APP_INFO_TABS.CONTACT_SUPPORT,
        title: APP_INFO_TABS.CONTACT_SUPPORT,
        navigation: handleNavigateToContactSupport,
      },
    ]);
  }, [
    handleNavigateToContactSupport,
    location.pathname,
    location.state,
    navigate,
    showForgotPasswordWarning,
    showSignupWarning,
  ]);

  if (!isFunction(children)) {
    throw new ChildrenMustBeAFunction('AppInfoTabPanel');
  }

  return <>{children({routes})}</>;
}
