import styled, {css} from 'styled-components';

export type JustifyContent =
  | ' flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
  | 'start'
  | 'end'
  | 'left'
  | 'unsafe';
export type AlignItems =
  | 'stretch'
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'baseline'
  | 'first baseline'
  | 'last baseline'
  | 'start'
  | 'end'
  | 'self-start'
  | 'unsafe';
export type AlignContent =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
  | 'stretch'
  | 'start'
  | 'end'
  | 'baseline'
  | 'first baseline'
  | 'unsafe';

export type FlexDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';
export type FlexWrap = 'nowrap' | 'wrap' | 'wrap-reverse';

export interface IFlexContainerProps {
  justifyContent?: JustifyContent;
  flexDirection?: FlexDirection;
  flexWrap?: FlexWrap;
  alignItems?: AlignItems;
  alignContent?: AlignContent;
  width?: string;
  height?: string;
  flexGrow?: number;
}

export const flex = ({
  justifyContent,
  alignItems,
  alignContent,
  flexDirection,
  flexWrap,
  width,
  height,
  flexGrow,
}: IFlexContainerProps) => `
  display: flex;
  justify-content: ${justifyContent || 'flex-start'};
  align-items:  ${alignItems || 'flex-start'};
  align-content: ${alignContent || 'flex-start'};
  flex-direction: ${flexDirection || 'row'};
  flex-wrap: ${flexWrap || 'no-wrap'};
  height: ${height || '100%'};
  width: ${width || '100%'};
  flexGrow: ${flexGrow || 0};
`;

export const FlexContainer = styled.div<IFlexContainerProps>`
  ${flex}
`;

export const fullWidth = () => css`
  width: 100%;
`;
