import {Record} from 'immutable';

export const GALLERY_STATUS_ACTIVE = 1;

export interface GalleryItemProps {
  status: string;
  title: string;
  description: string;
  uuid: string;
  path_url: string;
  updated_at: string;
  created_at: string;
}

/**
 * @class GalleryItem
 * @classdesc
 * @extends {Record}
 */
export default class GalleryItem extends Record({
  status: '',
  title: '',
  description: '',
  uuid: '',
  path_url: '',
  updated_at: null,
  created_at: null,
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(props: GalleryItemProps = {} as GalleryItemProps) {
    super({...props});
  }
}
