import {Map} from 'immutable';
import {APP_STATE, CLIENT_ABONEMENT} from '../constants';
import {REDUX_STATUS, ApiError} from '../../services/types';
import {IClientAbonementModel} from '../../struture';

interface IClientAbonementListState {
  abonement: IClientAbonementModel | null;
  cachedClientAbonementList: Map<string, IClientAbonementModel>;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
}

interface SetAction extends Pick<IClientAbonementListState, 'abonement'> {
  type: CLIENT_ABONEMENT.SET_CLIENT_ABONEMENT;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface UpdateClientAbonement {
  type: CLIENT_ABONEMENT.UPDATE_CLIENT_ABONEMENT;
  abonement: IClientAbonementModel;
}

interface LoadingAction {
  type: CLIENT_ABONEMENT.LOADING_CLIENT_ABONEMENT;
}

interface ResetAction {
  type: CLIENT_ABONEMENT.RESET_CLIENT_ABONEMENT;
}

interface ErrorAction extends Pick<IClientAbonementListState, 'error'> {
  type: CLIENT_ABONEMENT.ERROR_CLIENT_ABONEMENT;
}

interface Handlers {
  [CLIENT_ABONEMENT.SET_CLIENT_ABONEMENT]: (
    state: IClientAbonementListState,
    action: SetAction,
  ) => IClientAbonementListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IClientAbonementListState,
    action: SetInitialStateAction,
  ) => IClientAbonementListState;

  [CLIENT_ABONEMENT.RESET_CLIENT_ABONEMENT]: (
    state: IClientAbonementListState,
    action: ResetAction,
  ) => IClientAbonementListState;

  [CLIENT_ABONEMENT.UPDATE_CLIENT_ABONEMENT]: (
    state: IClientAbonementListState,
    action: UpdateClientAbonement,
  ) => IClientAbonementListState;

  [CLIENT_ABONEMENT.ERROR_CLIENT_ABONEMENT]: (
    state: IClientAbonementListState,
    value: ErrorAction,
  ) => IClientAbonementListState;

  [CLIENT_ABONEMENT.LOADING_CLIENT_ABONEMENT]: (
    state: IClientAbonementListState,
    value?: LoadingAction,
  ) => IClientAbonementListState;
  DEFAULT: (state: IClientAbonementListState) => IClientAbonementListState;
}

const initState: IClientAbonementListState = {
  abonement: null,
  cachedClientAbonementList: Map(),
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
};

const handlers: Handlers = {
  [CLIENT_ABONEMENT.SET_CLIENT_ABONEMENT]: (state, {abonement}) => ({
    ...state,
    ...{
      abonement,
      cachedClientAbonementList: abonement
        ? state.cachedClientAbonementList.set(
            abonement?.abonement?.uuid,
            abonement,
          )
        : state.cachedClientAbonementList,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [CLIENT_ABONEMENT.RESET_CLIENT_ABONEMENT]: (state) => ({
    ...state,
    ...{
      status: REDUX_STATUS.IDLE,
    },
  }),

  [CLIENT_ABONEMENT.UPDATE_CLIENT_ABONEMENT]: (state, {abonement}) => ({
    ...state,
    ...{
      abonement,
      cachedClientAbonementList: state.cachedClientAbonementList.update(
        abonement?.abonement?.uuid,
        () => abonement,
      ),
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [CLIENT_ABONEMENT.ERROR_CLIENT_ABONEMENT]: (
    state: IClientAbonementListState,
    {error}: ErrorAction,
  ) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [CLIENT_ABONEMENT.LOADING_CLIENT_ABONEMENT]: (
    state: IClientAbonementListState,
  ) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IClientAbonementListState) => state,
};

export default function ClientAbonement(
  state: any = initState,
  action: any,
): IClientAbonementListState {
  const handler = handlers[action.type as CLIENT_ABONEMENT] || handlers.DEFAULT;
  return handler(state, action);
}
