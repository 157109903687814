import {Dispatch} from 'redux';
import {PRODUCT} from '../constants';
import {ProductModel} from '../../struture';
import {ApiError} from '../../services/types';

export interface ISetProductParam {
  type: PRODUCT;
  product: ProductModel | null;
}

export interface IUpdateProductParam {
  type: PRODUCT;
  product: ProductModel;
}

export interface IResetProductParam {
  type: PRODUCT;
}

export interface IErrorProductParam {
  type: PRODUCT;
  error: ApiError | undefined;
}

export function setProduct(
  product: ProductModel | null,
): (dispatch: Dispatch) => Promise<ISetProductParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT.SET_PRODUCT,
      product,
    });
  };
}

export function updateProduct(
  product: ProductModel,
): (dispatch: Dispatch) => Promise<IUpdateProductParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT.UPDATE_PRODUCT,
      product,
    });
  };
}

export function resetProduct(): (
  dispatch: Dispatch,
) => Promise<IResetProductParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT.RESET_PRODUCT,
    });
  };
}

export function errorProduct(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorProductParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT.ERROR_PRODUCT,
      error,
    });
  };
}
