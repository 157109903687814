import React, {useCallback} from 'react';
import {
  PaymentProps,
  usePaymentListPage,
  useStateStoreDocumentPaymentList,
  useStopLoading,
  useStoredProfile,
} from '../../../hooks';
import {
  ClientModel,
  PaymentFormDTO,
  PaymentMapper,
  PaymentModel,
  PaymentScheduleFormDTO,
  PaymentScheduleType,
  ScheduleCalendarModel,
  StoreDocumentType,
} from '../../../struture';
import {Routes} from '../../../services/types';
import {useLocation, useNavigate, useParams} from 'react-router';
import {PaymentScheduleOperationType} from '../../../services/api/orders';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import {storeDocumentRoute, storeDocumentRouteTitle} from '../../Stores';
import {
  StoreDocumentPaymentList,
  StoreDocumentPostingPaymentList,
} from '../List';
import {correctPrice} from '../../../services/helpers';

const DOC_SUM_PAID_TYPES = [StoreDocumentType.OUT, StoreDocumentType.IN];

export function StoreDocumentPaymentListPage() {
  const location = useLocation();
  const {documentId} = useParams();
  const navigate = useNavigate();

  const {profile} = useStoredProfile();

  const {
    payments,
    loading: paymentsLoading,
    error: paymentsError,
    limit,
    total,
    stats,
    document: storeDocument,

    handleUpdatePayment,
    handleDeletePayments,
    handleRefreshPayments,
    ...rest
  } = useStateStoreDocumentPaymentList({
    documentUuid: documentId!,
    loadOnInit: !!documentId,
  });

  const loading = useStopLoading({
    loading: paymentsLoading,
    error: paymentsError,
    message: 'An error occurred during payments loading',
  });

  const paymentListProps = usePaymentListPage({
    type: PaymentScheduleOperationType.ALL,
    handleDeletePayments,
    handleUpdatePayment,
    stats,
    from: Routes.stores,
    documentType: storeDocument?.doc_type,
  });

  const routes = useBreadcrumb([
    {
      path: `/${Routes.stores}/${storeDocumentRoute[storeDocument?.doc_type]}`,
      breadcrumbName:
        storeDocumentRouteTitle[storeDocument?.doc_type] || 'Documents',
    },
    {
      path: `/${Routes.stores}/${
        storeDocumentRoute[storeDocument?.doc_type]
      }/${documentId}`,
      breadcrumbName: storeDocument?.doc_local_number || 'Show document',
    },
    {
      path: `/${Routes.stores}/${
        storeDocumentRoute[storeDocument?.doc_type]
      }/${documentId}/${Routes.payments}/${Routes.stores}`,
      breadcrumbName: 'Payments',
    },
  ]);

  const navigateGoBack = useCallback(() => {
    navigate(
      location?.state?.goBack ||
        `/${Routes.app}/${Routes.stores}/${
          storeDocumentRoute[storeDocument?.doc_type]
        }/${documentId}`,
    );
  }, [navigate, location?.state?.goBack, storeDocument?.doc_type, documentId]);

  const getSumPaid = useCallback(
    (docType: StoreDocumentType, type?: PaymentScheduleType) => {
      const totalSum = Number(storeDocument?.doc_sum_total || 0);
      const sumPaid = Number(storeDocument?.doc_sum_paid || 0);
      const sumWithDiscount = Number(
        storeDocument?.doc_sum_total_with_discount || 0,
      );

      const totalSumPaidWithDiscount =
        sumWithDiscount - sumPaid > 0 ? sumWithDiscount - sumPaid : undefined;

      const totalSumPaid =
        totalSum - sumPaid > 0 ? totalSum - sumPaid : undefined;

      if (StoreDocumentType.OUT === docType) {
        if (type === PaymentScheduleType.Prepayment) {
          return correctPrice(totalSumPaidWithDiscount);
        } else {
          return storeDocument?.doc_sum_paid;
        }
      }

      if (StoreDocumentType.IN === docType) {
        if (type === PaymentScheduleType.Prepayment) {
          return;
        } else {
          return correctPrice(totalSumPaid);
        }
      }

      if (type === PaymentScheduleType.Prepayment) {
        return correctPrice(totalSumPaidWithDiscount);
      } else {
        return storeDocument?.doc_sum_paid;
      }
    },
    [
      storeDocument?.doc_sum_paid,
      storeDocument?.doc_sum_total,
      storeDocument?.doc_sum_total_with_discount,
    ],
  );

  const payment: (
    props?: PaymentProps,
  ) => PaymentFormDTO | PaymentScheduleFormDTO = (
    {
      payment_comment,
      payment_post_action,
      type,
      payment_price,
      payment_default_sum,
    }: PaymentProps = {} as PaymentProps,
  ) =>
    PaymentMapper.toPaymentScheduleFormDTO(
      {
        payment_comment: payment_comment,
        payment_agent_object: storeDocument?.clientModel as ClientModel,
        payment_sum: payment_price,
      } as PaymentModel,
      {
        cashier: profile?.defaultEmployeeModel,
        payment_post_action,
        schedule: {
          sum_total: storeDocument?.doc_sum_total,
          sum_paid: storeDocument?.doc_sum_paid,
        } as ScheduleCalendarModel,
        type,
        payment_default_sum,
        doc_sum_paid: getSumPaid(storeDocument?.doc_type, type),
        client_is_required: storeDocument?.doc_type !== StoreDocumentType.OUT,
        documentType: storeDocument?.doc_type,
      },
    );

  return (
    <>
      {storeDocument?.doc_type === StoreDocumentType.IN ? (
        <StoreDocumentPostingPaymentList
          {...rest}
          payments={payments}
          loading={loading}
          error={paymentsError}
          limit={limit}
          total={total}
          stats={stats}
          document={storeDocument}
          handleRefreshPayments={handleRefreshPayments}
          handleUpdatePayment={handleUpdatePayment}
          handleDeletePayments={handleDeletePayments}
          {...paymentListProps}
          routes={routes}
          payment={payment}
          navigateGoBack={navigateGoBack}
        />
      ) : (
        <StoreDocumentPaymentList
          {...rest}
          payments={payments}
          loading={loading}
          error={paymentsError}
          limit={limit}
          total={total}
          stats={stats}
          document={storeDocument}
          handleRefreshPayments={handleRefreshPayments}
          handleUpdatePayment={handleUpdatePayment}
          handleDeletePayments={handleDeletePayments}
          {...paymentListProps}
          routes={routes}
          payment={payment}
          navigateGoBack={navigateGoBack}
        />
      )}
    </>
  );
}
