import * as React from 'react';
import {List} from 'immutable';
import {ApiError, IEntityProps, IListSearchProps} from '../services/types';
import {ICompanyListDTO, CompanyMapper, CompanyModel} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {getCompanyList} from '../services/api/company';

export interface IUseCompanyListProps
  extends Partial<IListSearchProps>,
    IEntityProps {}

export interface IUseCompanyListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<CompanyModel> | null;
  refresh: (
    value: Partial<IUseCompanyListProps>,
  ) => Promise<List<CompanyModel> | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
}

export function useCompanyList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
  }: IUseCompanyListProps = {} as IUseCompanyListProps,
): IUseCompanyListReturnType {
  const {cancellablePromise, didCancel} = useCancellablePromise();
  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    handleUpdate,
  } = useStateReducer<Omit<IUseCompanyListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
    }: Partial<IUseCompanyListProps> = {}): Promise<List<CompanyModel> | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
        });

        const {companies, total} = await cancellablePromise<ICompanyListDTO>(
          getCompanyList({limit, offset}),
        );

        const companyListModel = CompanyMapper.toCompanyListModel(
          companies,
          total,
        );

        if (!didCancel.current) {
          handleUpdate({
            entityList: companyListModel?.companies,
            total: companyListModel?.total,
            loading: false,
          });

          return companyListModel?.companies;
        }
      } catch (e) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }
      }
    },
    [
      cancellablePromise,
      didCancel,
      error,
      handleUpdate,

      listKeywords,
      listLimit,
      listOffset,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit) {
      (async () => {
        await refresh();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
  };
}
