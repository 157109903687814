import {CompanyModel} from '../internal';

export interface ICompanyInviteFormDTOProps {
  joining_url: string;
  joining_code: number;
  title: string;
}

export class CompanyInviteFormDTO {
  public joining_url: string;
  public joining_code: number;

  public title: string;

  constructor(props: CompanyModel) {
    this.joining_url = props?.joining_url || '';

    this.joining_code = props?.joining_code || 0;

    this.title = props?.title || '';
  }
}
