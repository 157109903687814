import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {
  Button,
  IButtonProps,
  SuspenseEmpty,
} from '../../../components/lib/DataDisplay';
import {useModal} from '../../../components/lib/libV2/hooks';
import {CompanyInviteFormDTO} from '../../../struture';

const CompanyInviteSideWindowLazy = React.lazy(
  () => import('../Show/CompanyInviteSideWindow'),
);

export interface ICompanyInviteButton extends Omit<IButtonProps, 'children'> {
  onCancel?: () => void;
  invite: CompanyInviteFormDTO;
}

export function CompanyInviteButton({
  loading = false,
  onCancel,
  title,
  invite,
  ...rest
}: ICompanyInviteButton) {
  const {t} = useTranslation();

  const {handleCancel, handleOnInit, visible} = useModal({
    onCancel,
  });

  return (
    <>
      <Button
        tooltipTitle={t('Company invite')}
        type="default"
        onClick={handleOnInit}
        loading={loading}
        {...rest}>
        {t('Invite to the application')}
      </Button>

      <Suspense fallback={<SuspenseEmpty />}>
        <CompanyInviteSideWindowLazy
          invite={invite}
          visible={visible}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}
