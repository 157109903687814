import {List} from 'immutable';
import {
  SystemEventVariableDTO,
  ISystemEventVariableDTOProps,
  SystemEventVariableModel,
  SystemEventVariableListModel,
} from '../internal';
import {listToArray} from '../../helpers';

export class SystemEventVariableMapper {
  public static toSystemEventVariableDTO(
    event_variable: SystemEventVariableModel | ISystemEventVariableDTOProps,
  ): SystemEventVariableDTO {
    return new SystemEventVariableDTO({
      variable_name: event_variable?.variable_name,
      variable_comment: event_variable?.variable_comment,
    });
  }

  public static toSystemEventVariableListDTO(
    event_variables:
      | List<SystemEventVariableModel>
      | ISystemEventVariableDTOProps[],
  ): SystemEventVariableDTO[] {
    const event_variableList = List.isList(event_variables)
      ? listToArray<SystemEventVariableModel>(event_variables)
      : event_variables;

    return event_variableList?.map((event_variable) =>
      SystemEventVariableMapper.toSystemEventVariableDTO(event_variable),
    );
  }

  public static toSystemEventVariableModel(
    event_variableDTO: SystemEventVariableDTO,
  ): SystemEventVariableModel {
    return new SystemEventVariableModel(event_variableDTO);
  }

  public static toSystemEventVariableListModel(
    event_variableDTOs: SystemEventVariableDTO[],
  ): SystemEventVariableListModel {
    return new SystemEventVariableListModel({
      event_variables: event_variableDTOs,
    });
  }
}
