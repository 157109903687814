export interface IEmailVerificationFormDTOProps {
  email: string;
  it_is_mine: boolean;
}

export class EmailVerificationFormDTO {
  public email: string;

  public it_is_mine: boolean;

  constructor(props?: IEmailVerificationFormDTOProps) {
    this.email = props?.email || '';
    this.it_is_mine = props?.it_is_mine || true;
  }
}
