import {contentApiUrl} from '../const';
import {buildFormData, head} from '../helpers';
import {ApiAnswer, IListSearchProps} from '../types';
import {IStorePrintDocumentAnswer} from './storeDocument';
import {apiDelete, apiGet, apiPatch, apiPost, apiPut} from '../core/api';

import {
  ProductDTO,
  ProductFormDTO,
  ProductMapper,
  IProductDTOProps,
  IProductListDTO,
  ProductCategoryFormDTO,
  ProductCategoryDTO,
  IProductCategoryDTOProps,
  ProductCategoryMapper,
  IProductCategoryListDTO,
  IProductsListStatsProps,
  IStoreDTOProps,
  StoreMapper,
  ProductLeftoversFormDTO,
  ProductBarCodeFormat,
  StoreDocumentType,
  StoreDocumentMapper,
  IStoreDocumentDTOProps,
  IProductStoreDocumentStatsDTOProps,
  IStoreDocumentListDTO,
  ProductStoreDocumentStatsDTO,
  PhotoDTO,
  PhotoListModel,
  PhotoMapper,
  ProductCategoryType,
} from '../../struture';

export const PRODUCT_INITIAL_PARAM = new ProductFormDTO();

const {
  uuid,
  company,
  created_by,
  category,
  product_title,
  created_at,
  updated_at,
  product_net_price,
  product_retail_price,
  product_max_income_price,
  product_bar_code_format,
  product_status,
  product_photo,
  ...PRODUCT_INITIAL_PARAM_REST
} = new ProductDTO({} as IProductDTOProps);

export const PRODUCT_REQUIRED_FIELD = {
  ...PRODUCT_INITIAL_PARAM_REST,
};

export const PRODUCT_CATEGORY_INITIAL_PARAM = new ProductCategoryFormDTO();

export const PRODUCT_BAR_CODE_FORMAT = [
  {
    title: ProductBarCodeFormat.EAN13,
    uuid: ProductBarCodeFormat.EAN13,
  },
];

const {
  uuid: cUuid,
  title,
  company: cCompany,
  created_by: cCreatedBy,
  created_at: cCreatedAt,
  updated_at: cUpdatedAt,
  parent,
  unit_packaging,
  base_unit,
  children,
  pos,
  cover_url,
  logo_url,
  ...PRODUCT_CATEGORY_INITIAL_PARAM_REST
} = new ProductCategoryDTO({} as IProductCategoryDTOProps);

export const PRODUCT_CATEGORY_REQUIRED_FIELD = {
  ...PRODUCT_CATEGORY_INITIAL_PARAM_REST,
};

export interface IProductAnswer extends ApiAnswer {
  product: IProductDTOProps;
  stats: IProductsListStatsProps;
}

export interface IProductLeftoverAnswer extends ApiAnswer {
  products: IProductDTOProps[];
}

export interface IProductListAnswer extends ApiAnswer {
  products: IProductDTOProps[];
  stats: IProductsListStatsProps;
  category: IProductCategoryDTOProps;
}

export interface IProductLeftoversListAnswer extends ApiAnswer {
  products: IProductDTOProps[];
  stats: IProductsListStatsProps;
  category: IProductCategoryDTOProps;
  store: IStoreDTOProps;
  product: IProductDTOProps;
}

export interface IProductListAnswer extends ApiAnswer, IListSearchProps {
  products: IProductDTOProps[];
}

export interface IProductListProps extends IListSearchProps {
  companyUuid: string;
  with_balance?: boolean;
  store_uuid?: string;
  parent_category_uuid?: string;
}

export interface ProductListFilter {}

export const DEFAULT_PRODUCT_FILTER: ProductListFilter = {};

export interface IProductListByCategoryProps extends IListSearchProps {
  categoryUuid: string;
  with_balance?: boolean;
  store_uuid?: string;
}

export interface IProductLeftoversListProps extends IListSearchProps {
  companyUuid: string;
  product_uuid?: string;
  category_uuid?: string;
  store_uuid?: string;
  show_running_out_products?: boolean;
}

export interface IProductCategoryProductListProps extends IListSearchProps {
  categoryUuid: string;
  with_balance?: boolean;
  store_uuid?: string;
}

export interface IProductCategoryProductListProps extends IListSearchProps {
  categoryUuid: string;
  with_balance?: boolean;
  store_uuid?: string;
}

export interface IProductCategoryAnswer extends ApiAnswer {
  category: IProductCategoryDTOProps;
  stats: IProductsListStatsProps;
}

export interface IProductCategoryListAnswer extends ApiAnswer {
  categories: IProductCategoryDTOProps[];
  parent: IProductCategoryDTOProps;
  stats: IProductsListStatsProps;
}

export interface IProductCategoryListAnswer
  extends ApiAnswer,
    IListSearchProps {
  categories: IProductCategoryDTOProps[];
  stats: IProductsListStatsProps;
}

export interface IProductCategoryListProps extends IListSearchProps {
  companyUuid: string;
  view?: ProductCategoryType;
  parent_category_uuid?: string;
  show_root_only?: boolean;
}

export interface ProductReturnType {
  product: ProductDTO;
  stats: IProductsListStatsProps;
}

export interface ProductCategoryReturnType {
  category: ProductCategoryDTO;
  stats: IProductsListStatsProps;
}

/* ------------------- PRODUCT LIST ------------------- */

export async function getProductList({
  offset = 0,
  limit = 10,
  keywords,
  companyUuid,
  with_balance,
  store_uuid,
  parent_category_uuid,
}: IProductListProps): Promise<IProductListDTO> {
  const {products, stats, total, category} = await apiGet<
    Omit<IProductListProps, 'companyUuid' | 'parent_category_uuid'> & {
      category_uuid?: string;
    },
    IProductListAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/products`, {
    offset,
    limit,
    keywords,
    with_balance,
    store_uuid,
    category_uuid: parent_category_uuid,
  });

  return ProductMapper.toProductListDTO(
    products,
    total || 0,
    stats,
    [],
    ProductCategoryMapper.toProductCategoryModel(
      ProductCategoryMapper.toProductCategoryDTO(category),
    ),
  );
}

export async function getProductListByCategory({
  offset = 0,
  limit = 10,
  keywords,
  categoryUuid,
  with_balance,
  store_uuid,
}: IProductListByCategoryProps): Promise<IProductListDTO> {
  const {products, stats, total, category} = await apiGet<
    Omit<IProductListProps, 'companyUuid' | 'parent_category_uuid'> & {
      category_uuid?: string;
    },
    IProductListAnswer
  >(`${contentApiUrl}/products/categories/${categoryUuid}/products`, {
    offset,
    limit,
    keywords,
    with_balance,
    store_uuid,
  });

  return ProductMapper.toProductListDTO(
    products,
    total || 0,
    stats,
    [],
    ProductCategoryMapper.toProductCategoryModel(
      ProductCategoryMapper.toProductCategoryDTO(category),
    ),
  );
}

export async function getProductById(productUuid: string): Promise<ProductDTO> {
  const {product} = await apiGet<null, IProductAnswer>(
    `${contentApiUrl}/products/${productUuid}`,
  );

  return ProductMapper.toProductDTO(product);
}

export async function createProduct(
  {
    net_price,
    code,
    category_uuid,
    retail_price,
    vendor_code,
    bar_code,
    title,
    description,
    prices,
    bar_code_format,
    min_amount,
  }: ProductFormDTO,
  companyUuid: string,
): Promise<ProductReturnType> {
  const {product, stats} = await apiPost<
    Omit<ProductFormDTO, 'uuid'>,
    IProductAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/products`, {
    net_price,
    code,
    category_uuid,
    retail_price,
    vendor_code,
    bar_code,
    title,
    description,
    prices,
    bar_code_format,
    min_amount,
  });

  return {
    product: ProductMapper.toProductDTO(product),
    stats,
  };
}

export async function editProduct({
  uuid: product_uuid,
  net_price,
  code,
  category_uuid,
  retail_price,
  vendor_code,
  bar_code,
  title,
  description,
  prices,
  bar_code_format,
  min_amount,
}: Omit<ProductFormDTO, 'getTimingList'>): Promise<ProductDTO> {
  const {product} = await apiPut<Omit<ProductFormDTO, 'uuid'>, IProductAnswer>(
    `${contentApiUrl}/products/${product_uuid}`,
    {
      net_price,
      code,
      category_uuid,
      retail_price,
      vendor_code,
      bar_code,
      title,
      description,
      prices,
      bar_code_format,
      min_amount,
    },
  );

  return ProductMapper.toProductDTO(product);
}

export async function deleteProduct(
  ids: string[] = [],
): Promise<ProductReturnType> {
  const {product, stats} = await apiDelete<{ids: string[]}, IProductAnswer>(
    `${contentApiUrl}/products/${head(ids)}`,
  );

  return {
    product: ProductMapper.toProductDTO(product),
    stats,
  };
}

export async function changeProductStatus(
  status: number,
  productUuid: string,
): Promise<ProductDTO> {
  const {product} = await apiPatch<{status: number}, IProductAnswer>(
    `${contentApiUrl}/products/${productUuid}/status`,
    {
      status,
    },
  );

  return ProductMapper.toProductDTO(product);
}

/* ------------------- PRODUCT CATEGORY LIST ------------------- */
export async function getProductCategoryList({
  offset = 0,
  limit = 10,
  keywords,
  companyUuid,
  view,
  parent_category_uuid,
  show_root_only,
}: IProductCategoryListProps): Promise<IProductCategoryListDTO> {
  const {categories, stats, total, parent} = await apiGet<
    IListSearchProps & {
      view?: ProductCategoryType;
      parent_category_uuid?: string;
      show_root_only?: boolean;
    },
    IProductCategoryListAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/products/categories`, {
    offset,
    limit,
    keywords,
    view,
    parent_category_uuid,
    show_root_only,
  });

  return ProductCategoryMapper.toProductCategoryListDTO(
    categories,
    total || 0,
    stats,
    [],
    ProductCategoryMapper.toProductCategoryModel(
      ProductCategoryMapper.toProductCategoryDTO(parent),
    ),
  );
}

export async function getProductCategoryById(
  categoryUuid: string,
): Promise<ProductCategoryDTO> {
  const {category} = await apiGet<null, IProductCategoryAnswer>(
    `${contentApiUrl}/products/categories/${categoryUuid}`,
  );

  return ProductCategoryMapper.toProductCategoryDTO(category);
}

export async function createProductCategory(
  {
    title,
    description,
    unit_packaging,
    base_unit,
    parent_category_uuid,
  }: ProductCategoryFormDTO,
  companyUuid: string,
): Promise<ProductCategoryReturnType> {
  const {category, stats} = await apiPost<
    Omit<ProductCategoryFormDTO, 'uuid'>,
    IProductCategoryAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/products/categories`, {
    title,
    description,
    unit_packaging,
    base_unit,
    parent_category_uuid,
  });

  return {
    category: ProductCategoryMapper.toProductCategoryDTO(category),
    stats,
  };
}

export async function editProductCategory(
  {
    uuid: category_uuid,
    title,
    description,
    unit_packaging,
    base_unit,
    parent_category_uuid,
  }: Omit<ProductCategoryFormDTO, 'getTimingList'>,
  view?: string,
): Promise<ProductCategoryDTO> {
  const isView = view ? `?view=${view}` : '';

  const {category} = await apiPut<
    Omit<ProductCategoryFormDTO, 'uuid'>,
    IProductCategoryAnswer
  >(`${contentApiUrl}/products/categories/${category_uuid}${isView}`, {
    title,
    description,
    unit_packaging,
    base_unit,
    parent_category_uuid,
  });

  return ProductCategoryMapper.toProductCategoryDTO(category);
}

export async function deleteProductCategory(
  ids: string[] = [],
): Promise<ProductCategoryReturnType> {
  const {categories, stats} = await apiDelete<
    {ids: string[]},
    IProductCategoryListAnswer
  >(`${contentApiUrl}/products/categories/${head(ids)}`);

  return {
    category: ProductCategoryMapper.toProductCategoryDTO(
      {} as ProductCategoryDTO,
    ),
    stats,
  };
}

export async function getProductCategoryProductList({
  offset = 0,
  limit = 10,
  keywords,
  categoryUuid,
  with_balance,
  store_uuid,
}: IProductCategoryProductListProps): Promise<IProductListDTO> {
  const {products, stats, total, category} = await apiGet<
    Omit<IProductCategoryProductListProps, 'categoryUuid'>,
    IProductListAnswer
  >(`${contentApiUrl}/products/categories/${categoryUuid}/products`, {
    offset,
    limit,
    keywords,
    with_balance,
    store_uuid,
  });

  return ProductMapper.toProductListDTO(
    products,
    total || 0,
    stats,
    [],
    ProductCategoryMapper.toProductCategoryModel(
      ProductCategoryMapper.toProductCategoryDTO(category),
    ),
  );
}

/* ------------------- PRODUCT LEFTOVER LIST ------------------- */

export async function getProductLeftoversList({
  offset = 0,
  limit = 10,
  keywords,
  companyUuid,
  store_uuid = '',
  category_uuid = '',
  product_uuid = '',
  show_running_out_products,
}: IProductLeftoversListProps): Promise<IProductListDTO> {
  const {products, product, store, category, total, stats} = await apiGet<
    IListSearchProps & {
      store_uuid?: string;
      category_uuid?: string;
      product_uuid?: string;
      show_running_out_products?: boolean;
    },
    IProductLeftoversListAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/products/inventory`, {
    offset,
    limit,
    keywords,
    store_uuid,
    category_uuid,
    product_uuid,
    show_running_out_products,
  });

  return ProductMapper.toProductListDTO(
    products,
    total || 0,
    stats,
    [],
    ProductCategoryMapper.toProductCategoryModel(
      ProductCategoryMapper.toProductCategoryDTO(category),
    ),
    StoreMapper.toStoreModel(StoreMapper.toStoreDTO(store)),
    ProductMapper.toProductModel(ProductMapper.toProductDTO(product)),
  );
}

export async function updateProductLeftover({
  uuid,
  store_balances,
}: ProductLeftoversFormDTO): Promise<IProductListDTO> {
  const {products, total} = await apiPut<
    Omit<ProductLeftoversFormDTO, 'uuid'>,
    IProductLeftoverAnswer
  >(`${contentApiUrl}/products/${uuid}/inventory`, {
    store_balances,
  });

  return ProductMapper.toProductListDTO(products, total || 0);
}

export async function getProductLeftoverForPrint(
  companyUuid: string,
): Promise<string> {
  const {html} = await apiGet<null, IStorePrintDocumentAnswer>(
    `${contentApiUrl}/companies/${companyUuid}/products/inventory/print`,
  );

  return html;
}

/* ------------------- PRODUCT LIST ------------------- */

export interface IProductStoreDocumentListProps extends IListSearchProps {
  productUuid: string;
  doc_type?: StoreDocumentType;
  show_with_credits_only?: boolean;
}

export interface IProductStoreDocumentListAnswer extends ApiAnswer {
  documents: IStoreDocumentDTOProps[];
  stats: IProductStoreDocumentStatsDTOProps;
  product: IProductDTOProps;
}

export interface IProductStoreDocumentListReturnType {
  documents: IStoreDocumentListDTO;
  stats: ProductStoreDocumentStatsDTO;
}

export async function getProductStoreDocumentList({
  offset = 0,
  limit = 10,
  keywords,
  productUuid,
  doc_type,
  show_with_credits_only,
}: IProductStoreDocumentListProps): Promise<IProductStoreDocumentListReturnType> {
  const {documents, product, stats, total} = await apiGet<
    Omit<IProductStoreDocumentListProps, 'productUuid'>,
    IProductStoreDocumentListAnswer
  >(`${contentApiUrl}/products/${productUuid}/store-documents`, {
    offset,
    limit,
    keywords,
    doc_type,
    show_with_credits_only,
  });

  return {
    documents: StoreDocumentMapper.toStoreDocumentListDTO(documents, total),
    stats: new ProductStoreDocumentStatsDTO({
      ...stats,
      product: ProductMapper.toProductModel(
        ProductMapper.toProductDTO(product),
      ),
    }),
  };
}

/* ------------------- PRODUCT PHOTO LIST ------------------- */

export interface IProductPhotoListAnswer extends ApiAnswer {
  photos: PhotoDTO[];
  product: IProductDTOProps;
}

export interface IProductPhotoListProps extends IListSearchProps {
  productUuid: string;
}

export interface IProductPhotoProps {
  productUuid: string;
  photos: File[];
}

export interface IProductPhotoDefaultProps {
  productUuid: string;
  fileId: string;
}

export interface IProductPhotoDeleteProps {
  productUuid: string;
  photoUuid: string;
}

export async function getProductPhotoList({
  offset = 0,
  limit = 10,
  keywords,
  productUuid,
}: IProductPhotoListProps): Promise<PhotoListModel> {
  const {photos, product, total} = await apiGet<null, IProductPhotoListAnswer>(
    `${contentApiUrl}/products/${productUuid}/photos`,
    null,
  );

  return PhotoMapper.toPhotoListModel(photos, total, [], product);
}

export async function uploadProductPhoto({
  productUuid,
  photos,
}: IProductPhotoProps): Promise<PhotoListModel> {
  const formData = new FormData();

  photos.forEach(({originFileObj}: any) => {
    formData.append('photos', originFileObj);
  });

  const {photos: apiPhotos, product} = await apiPost<
    any,
    IProductPhotoListAnswer
  >(`${contentApiUrl}/products/${productUuid}/photos`, formData, {}, {}, true);

  return PhotoMapper.toPhotoListModel(
    apiPhotos,
    apiPhotos?.length,
    [],
    product,
  );
}

export async function setProductPhotoByDefault({
  productUuid,
  fileId,
}: IProductPhotoDefaultProps): Promise<ProductDTO> {
  const {product} = await apiPatch<
    Omit<IProductPhotoDefaultProps, 'productUuid'>,
    IProductAnswer
  >(`${contentApiUrl}/products/${productUuid}/photos/default`, {
    fileId,
  });

  return ProductMapper.toProductDTO(product);
}

export async function deleteProductPhoto({
  productUuid,
  photoUuid,
}: IProductPhotoDeleteProps): Promise<void> {
  await apiDelete<void, any>(
    `${contentApiUrl}/products/${productUuid}/photos/${photoUuid}`,
  );
}

/* ------------------- PRODUCT CATEGORY UPLOAD ------------------- */

export async function setProductCategoryLogo(
  logo: File,
  categoryUuid: string,
): Promise<ProductCategoryDTO> {
  const data = buildFormData('logo', logo);

  const {category} = await apiPost<FormData, IProductCategoryAnswer>(
    `${contentApiUrl}/products/categories/${categoryUuid}/logo`,
    data,
    {},
    {},
    true,
  );

  return ProductCategoryMapper.toProductCategoryDTO(category);
}

export async function setProductCategoryCover(
  cover: File,
  categoryUuid: string,
): Promise<ProductCategoryDTO> {
  const data = buildFormData('cover', cover);

  const {category} = await apiPost<FormData, IProductCategoryAnswer>(
    `${contentApiUrl}/products/categories/${categoryUuid}/cover`,
    data,
    {},
    {},
    true,
  );

  return ProductCategoryMapper.toProductCategoryDTO(category);
}
