import React from 'react';
import styled from 'styled-components';

const Empty = styled.div`
  display: none;
`;

export function SuspenseEmpty() {
  return <Empty />;
}
