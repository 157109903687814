import React, {useState, useRef, useEffect, useCallback} from 'react';
import {Form, Input} from 'antd';
import {useDefaultForm} from '../../../../contex';
import {useTranslation} from 'react-i18next';
import styled, {css} from 'styled-components';
import {SearchSelect} from '../index';
import {useOutsideClickHandler} from '../../../../hooks';
import {ItemType, EditableCellProps} from './EditableCell';
import {CompanyOrderBonusType} from '../../../../struture';
import {LoadingOutlined} from '@ant-design/icons';
import {isFunction} from '@sportix/sportix-common-modules';

const StyledSearchSelect = styled(SearchSelect)`
  width: 60px;
`;

const StyledContainer = styled.div<{isShow: boolean; $align: any}>`
  ${({isShow}) =>
    isShow &&
    css`
      display: flex;
      justify-content: space-between;
      width: 100%;
    `}

  ${({$align}) =>
    $align === 'right' &&
    css`
      justify-content: flex-end;
    `}
`;

export function EditableInputSelectCell({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  type = ItemType.Input,
  options = [],
  rules = [],
  optionsName,
  disabled = false,
  loading,
  loadingItemId,
  alignText,
  validateList,
  parent,
  autoFocus,
  cellIndex,
  ...restProps
}: EditableCellProps): React.JSX.Element {
  const {t} = useTranslation();
  const {
    validateFields,
    setFieldsValue,
    loadingSubmit,
    getFieldValue,
    setFields,
  } = useDefaultForm();

  const [editing, setEditing] = useState(false);

  const inputRef = useRef<any>(null);
  const containerRef = useRef(null);

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing, type]);

  const toggleEdit = (e: any) => {
    const target = e?.target;

    if (target.closest('.editable-cell-value-wrap')) {
      setEditing(!editing);
      setFieldsValue({
        [dataIndex]: record[dataIndex],
        [optionsName]: (record as any)[optionsName],
      });
    }
  };

  const closeEdit = () => {
    setEditing(false);
    setFieldsValue({
      [dataIndex]: record[dataIndex],
      [optionsName]: (record as any)[optionsName],
    });
  };

  const saveInputSelect = async (value: any = {}) => {
    try {
      const type = getFieldValue(optionsName);

      const inputValue = getFieldValue(dataIndex);
      const price = Number(inputValue);

      if (
        type === CompanyOrderBonusType.Percent &&
        (price < 0 || price > 100)
      ) {
        setFields([
          {
            name: dataIndex,
            errors: [t('The value must be greater than 0 or less than 100')],
          },
        ]);

        // handleSave({...record, [optionsName]: type});

        return;
      }

      const values = await validateFields([dataIndex]);

      handleSave({...record, ...values, [optionsName]: type});
    } catch (errInfo) {}
  };

  useOutsideClickHandler(
    containerRef,
    async () => {
      await saveInputSelect();
      closeEdit();
    },
    '.editable-select-popup',
  );

  return (
    <td {...restProps}>
      {editing ? (
        <div ref={containerRef} style={{minWidth: 200}}>
          <Form.Item
            style={{margin: 0}}
            name={dataIndex}
            rules={
              isFunction(rules)
                ? rules()
                : rules.length
                ? rules
                : [
                    {
                      required: true,
                      message: `${title} ${t('is required.')}`,
                    },
                  ]
            }>
            <Input
              disabled={
                loadingSubmit ||
                (loading && loadingItemId === (record as any)?.id)
              }
              ref={inputRef}
              onBlur={() => saveInputSelect()}
              onPressEnter={() => saveInputSelect()}
              addonAfter={
                <StyledSearchSelect
                  disable={
                    loadingSubmit ||
                    (loading && loadingItemId === (record as any)?.id)
                  }
                  popupClassName="editable-select-popup"
                  defaultValue={(record as any)[optionsName]}
                  popupMatchSelectWidth={false}
                  name={optionsName}
                  getOptionValueTitle="description"
                  placeholder=""
                  data={options}
                  onChange={(e: any) => {
                    setFieldsValue({[optionsName]: e});
                    saveInputSelect({[optionsName]: e});
                  }}
                  onBlur={() => saveInputSelect()}
                />
              }
            />
          </Form.Item>
        </div>
      ) : (
        <StyledContainer
          $align={alignText}
          isShow={isFunction(editable) ? editable(record) : editable}
          className={
            (isFunction(editable) ? editable(record) : editable) &&
            !disabled &&
            !(loading && loadingItemId === (record as any)?.uuid)
              ? 'editable-cell-value-wrap'
              : (isFunction(editable) ? editable(record) : editable)
              ? 'editable-cell-value'
              : ''
          }
          style={{paddingRight: 24}}
          onClick={toggleEdit}>
          {children}
          {loading && loadingItemId === (record as any)?.id ? (
            <LoadingOutlined />
          ) : null}
        </StyledContainer>
      )}
    </td>
  );
}
