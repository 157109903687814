import * as React from 'react';
import {List} from 'immutable';
import moment from 'moment';
import {
  useExpenseList,
  IUseExpenseListProps,
  IUseExpenseListReturnType,
} from './useExpenseList';
import {
  ExpenseModel,
  ExpenseFormDTO,
  ExpenseMapper,
  ExpenseCreateFormDTO,
  AggregatedSumDTO,
  ScheduleCalendarModel,
  ExpenseWorkableType,
  ProductModel,
  ServiceModel,
} from '../struture';
import {useStateEntityList} from '../components/lib/libV2/hooks';
import {
  createExpense,
  editExpense,
  deleteExpense,
  changeExpenseAmount,
} from '../services/api/orders';
import {useDropdownAlert} from '../contex';
import {useTranslation} from 'react-i18next';
import {IUseStateScheduleReturnType} from './useStateSchedule';
import {correctPrice, head, isFunction} from '../services/helpers';

export interface IUseStateExpenseListProps
  extends IUseExpenseListProps,
    Partial<Pick<IUseStateScheduleReturnType, 'schedule' | 'handleUpdate'>> {}

export interface IExpenseSearchProps {
  keywords: string;
  showLoading?: boolean;
  limit?: number;
}

export interface IUseStateExpenseListReturnType
  extends Omit<IUseExpenseListReturnType, 'entityList'> {
  expenseList: List<ExpenseModel> | null;
  handleCreateExpense: (
    value: ExpenseFormDTO | ExpenseCreateFormDTO,
  ) => Promise<void>;
  handleUpdateExpense: (value: ExpenseFormDTO) => Promise<void>;
  handleDeleteExpenses: (ids: string[], price: string) => Promise<void>;
  handleSearchExpenses: (value: IExpenseSearchProps) => Promise<void>;
  handleUpdateExpenseAmount: (
    value: Pick<ExpenseFormDTO, 'uuid' | 'amount' | 'prevPrice'>,
  ) => Promise<ExpenseModel | void>;
}

export function useStateExpenseList(
  {
    scheduleUuid,
    handleUpdate: handleUpdateSchedule,
    schedule,
    ...rest
  }: IUseStateExpenseListProps = {} as IUseStateExpenseListProps,
): IUseStateExpenseListReturnType {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const [aggregatedSum, setAggregatedSum] = React.useState<AggregatedSumDTO>(
    ExpenseMapper.toAggregatedSumDTO({}),
  );

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: expenseListLoading,
    total: expenseListTotal,
    aggregatedSum: expenseAggregatedSum,
    ...clientsParams
  } = useExpenseList({
    scheduleUuid,
    limit: 150,
    ...rest,
  });

  const {
    entityList: expenseList,
    loading,
    handleCreate,
    handleDelete,
    handleUpdate,
    setEntityList,
    total,
  } = useStateEntityList<ExpenseModel>({
    entityList,
    refresh,
    limit,
    offset,
    total: expenseListTotal,
  });

  const handleCreateExpense = React.useCallback(
    async (value: ExpenseFormDTO | ExpenseCreateFormDTO) => {
      const answer = await createExpense(value, scheduleUuid);
      const expenseModel = ExpenseMapper.toExpenseModel(answer?.expense);

      const updatedModel = expenseModel.set(
        'created_at',
        moment(new Date()).toString(),
      );

      alert(
        'success',
        t('Services or goods'),
        t('Services or goods created success'),
      );

      setAggregatedSum(answer?.aggregatedSum);

      if (
        isFunction(handleUpdateSchedule) &&
        schedule instanceof ScheduleCalendarModel
      ) {
        const updatedSchedule = schedule
          .update('sum_total', (sum_total) => {
            const sum = Number(sum_total) || 0;
            const expenseSum = Number(answer?.expense?.total) || 0;

            return correctPrice(sum + expenseSum);
          })
          .update('schedule_expenses_sum', () =>
            correctPrice(answer?.aggregatedSum?.total_sum || 0),
          );

        handleUpdateSchedule(updatedSchedule);
      }

      handleCreate(updatedModel, true);
    },
    [scheduleUuid, alert, t, handleUpdateSchedule, schedule, handleCreate],
  );

  const handleUpdateExpense = React.useCallback(
    async (value: ExpenseFormDTO) => {
      const answer = await editExpense(value);
      const expenseModel = ExpenseMapper.toExpenseModel(answer?.expense);

      setAggregatedSum(answer?.aggregatedSum);

      if (
        isFunction(handleUpdateSchedule) &&
        schedule instanceof ScheduleCalendarModel
      ) {
        const updatedSchedule = schedule
          .update('sum_total', (sum_total) => {
            const sum = Number(sum_total) || 0;
            const prevSum = Number(value?.prevPrice) || 0;
            const expenseSum = Number(answer?.expense?.total) || 0;

            return correctPrice(sum - prevSum + expenseSum);
          })
          .update('schedule_expenses_sum', () =>
            correctPrice(answer?.aggregatedSum?.total_sum || 0),
          );

        handleUpdateSchedule(updatedSchedule);
      }

      alert(
        'success',
        t('Services or goods'),
        t('Services or goods edited success'),
      );

      handleUpdate(expenseModel);
    },
    [alert, handleUpdate, handleUpdateSchedule, schedule, t],
  );

  const handleUpdateExpenseAmount = React.useCallback(
    async ({
      amount,
      uuid,
      prevPrice,
    }: Pick<ExpenseFormDTO, 'uuid' | 'amount' | 'prevPrice'>) => {
      const answer = await changeExpenseAmount(uuid, amount);
      const expenseModel = ExpenseMapper.toExpenseModel(answer?.expense);

      setAggregatedSum(answer?.aggregatedSum);

      if (
        isFunction(handleUpdateSchedule) &&
        schedule instanceof ScheduleCalendarModel
      ) {
        const updatedSchedule = schedule
          .update('sum_total', (sum_total) => {
            const sum = Number(sum_total) || 0;
            const prevSum = Number(prevPrice) || 0;
            const expenseSum = Number(answer?.expense?.total) || 0;

            return correctPrice(sum - prevSum + expenseSum);
          })
          .update('schedule_expenses_sum', () =>
            correctPrice(answer?.aggregatedSum?.total_sum || 0),
          );

        handleUpdateSchedule(updatedSchedule);
      }

      alert(
        'success',
        t('Services or goods'),
        t('Services or goods amount edited success'),
      );

      handleUpdate(expenseModel);

      return expenseModel;
    },
    [alert, handleUpdate, handleUpdateSchedule, schedule, t],
  );

  const handleDeleteExpenses = React.useCallback(
    async (ids: string[], price: string) => {
      try {
        const answer = await deleteExpense(ids);

        handleDelete(ids);

        setAggregatedSum(answer?.aggregatedSum);

        if (
          isFunction(handleUpdateSchedule) &&
          schedule instanceof ScheduleCalendarModel
        ) {
          const updatedSchedule = schedule
            .update('sum_total', (sum_total) => {
              const sum = Number(sum_total) || 0;
              const expenseSum = Number(price) || 0;

              return correctPrice(sum - expenseSum);
            })
            .update('schedule_expenses_sum', () =>
              correctPrice(answer?.aggregatedSum?.total_sum || 0),
            );

          handleUpdateSchedule(updatedSchedule);
        }

        alert(
          'success',
          t('Services or goods'),
          t('Services or goods delete success'),
        );
      } catch (error: any) {
        alert(
          'error',
          t('Expenses'),
          `${t('An error occurred during delete services or goods list')}: ${
            error?.message
          }`,
        );
      }
    },
    [alert, handleDelete, handleUpdateSchedule, schedule, t],
  );

  const handleSearchExpenses = React.useCallback(
    async ({limit = 10, keywords, showLoading = true}: IExpenseSearchProps) => {
      const expenseList = await refresh({
        offset: 0,
        limit,
        keywords,
        showLoading,
      });

      if (expenseList) {
        setEntityList(expenseList);
      }
    },
    [refresh, setEntityList],
  );

  React.useEffect(() => {
    if (expenseAggregatedSum) {
      setAggregatedSum(expenseAggregatedSum);
    }
  }, [expenseAggregatedSum]);

  return {
    expenseList,
    offset,
    limit,
    refresh,
    total,
    loading: expenseListLoading || loading,
    ...clientsParams,
    handleCreateExpense,
    handleUpdateExpense,
    handleDeleteExpenses,
    handleSearchExpenses,
    handleUpdateExpenseAmount,
    aggregatedSum,
  };
}
