import React from 'react';
import styled from 'styled-components';
import {useLocation} from 'react-router';
import {useTranslation} from 'react-i18next';
import {Link, Button} from '../../../components/lib/DataDisplay';
import {StyledDescription, StyledTitle} from '../Styled';

export interface FieldItem {
  link?: string | React.ReactNode;
  title?: string | React.ReactNode;
  text?: string | React.ReactNode;
  rightButton?: string | React.ReactNode;
  description?: string | React.ReactNode;
  icon?: React.ReactNode;
  path?: string;
  state?: any;
  onClick?: () => any;
  disabled?: boolean;
  color?: string;
}

export type IDetailsItemFields<T> = {
  [key in keyof Partial<T>]: FieldItem;
};

export interface IDetailsItemViewProps<T> {
  item: T;
  fields: IDetailsItemFields<T>;
  children?: React.ReactNode | React.ReactNode[];
  keyExtractor?: string;
}

const StyledSpace = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 5px;
`;

const StyledLink = styled(Link)`
  align-items: center;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5px;
`;

const StyledButton = styled(Button)`
  position: absolute;
  top: 5px;
  right: 5px;

  width: fit-content;
`;

export function DetailsItemView<T>({
  item,
  fields,
  children,
  keyExtractor = 'uuid',
}: IDetailsItemViewProps<T>): React.JSX.Element {
  const {t} = useTranslation();
  const location = useLocation();

  const fieldsValue: any = {
    title: (value: string, {title, icon}: FieldItem) => (
      <>
        {typeof title === 'string' ? (
          <StyledTitleContainer>
            {icon || null}

            <StyledTitle>{`${
              title ? `${t(title)}: ` : ''
            } ${value}`}</StyledTitle>
          </StyledTitleContainer>
        ) : null}
      </>
    ),
    description: (value: string, {description, icon}: FieldItem) => (
      <>
        {typeof description === 'string' ? (
          <StyledDescription>
            {icon || null}
            <StyledTitle>{`${
              description ? `${t(description)}: ` : ''
            } ${value}`}</StyledTitle>
          </StyledDescription>
        ) : (
          description
        )}
      </>
    ),
    link: (value: string, {link, text, icon, path, state}: FieldItem) => (
      <>
        {typeof link === 'string' ? (
          <StyledLink
            to={path!}
            tooltip={t(link)}
            state={{goBack: location.pathname, ...state}}>
            {icon || null}
            <StyledTitleContainer>{text || value}</StyledTitleContainer>
          </StyledLink>
        ) : (
          link
        )}
      </>
    ),
    rightButton: (
      value: string,
      {onClick, text, disabled, color}: FieldItem,
    ) => (
      <StyledButton
        type="text"
        size="small"
        disabled={disabled}
        onClick={onClick}>
        <StyledTitle $color={color}>{text || value}</StyledTitle>
      </StyledButton>
    ),
  };

  return (
    <>
      {(item as any)[keyExtractor] ? (
        <StyledSpace>
          {Object.entries(fields).map(([key, value]) => {
            const keys = Object.keys(value || {});

            const name = keys.includes('link')
              ? 'link'
              : keys.includes('title')
              ? 'title'
              : keys.includes('description')
              ? 'description'
              : keys.includes('rightButton')
              ? 'rightButton'
              : null;

            return (
              <React.Fragment key={key}>
                {name ? fieldsValue[name]((item as any)[key], value) : null}
              </React.Fragment>
            );
          })}
          {children || ''}
        </StyledSpace>
      ) : null}
    </>
  );
}
