import {List, Record} from 'immutable';
import {
  ClientDTO,
  ClientMapper,
  ClientModel,
  CompanyDTO,
  CompanyMapper,
  CompanyModel,
  MAILING_STATUSES,
  MailingDTO,
  ProfileDTO,
  ProfileMapper,
  ProfileModel,
} from '../internal';

export interface IMailingModelReturnType {
  uuid: string;
  title: string;
  body: string;
  start_date: string;
  comment: string;
  status: number;
  status_text: MAILING_STATUSES;
  created_at: string;
  updated_at: string;
  clients: List<ClientModel> | ClientDTO[];
  created_by: ProfileModel | ProfileDTO;
  company: CompanyModel | CompanyDTO;
}

export class MailingModel extends Record<IMailingModelReturnType>({
  uuid: '',
  title: '',
  body: '',
  start_date: '',
  comment: '',
  status: 0,
  status_text: MAILING_STATUSES.FAILED,
  created_at: '',
  updated_at: '',
  clients: List(),
  created_by: {} as ProfileModel,
  company: {} as CompanyModel,
}) {
  constructor(props: MailingDTO = {} as MailingDTO) {
    const options: IMailingModelReturnType = {
      ...props,
      clients: ClientMapper.toClientListModel(props?.clients, 0).clients,
      created_by: ProfileMapper.toProfileModel(props?.created_by),
      company: CompanyMapper.toCompanyModel(props?.company),
    };

    super(options);
  }

  get clientListUuid(): string[] {
    return (this.clients as List<ClientModel>).map(({uuid}) => uuid).toArray();
  }
}
