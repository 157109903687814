import React, {useCallback, useEffect, useState} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {Col, Form, Radio, Row, Space, RadioChangeEvent, Typography} from 'antd';
import {Moment} from 'moment';
import {useDefaultForm} from '../../../contex';
import {PAYMENT_METHOD} from '../../../services/api/client';
import FormCheckBox from '../../../components/lib/DataDisplay/FormCheckBox';
import {InputDisabledView} from '../../../components/lib/Styled';
import {DatePicker} from '../../../components/lib/General';
import {SERVICES_PERSONALITY} from '../../../services/api/services';
import {Segmented} from '../../../components/lib/DataDisplay';
import {FROM_PAYMENT_METHOD} from '../../../services/api/companyPayments';
import {OPERATION_TYPE} from '../../../services/types';

export default withTranslation()(ClientPaymentForm);

export interface IClientPaymentFormProps extends WithTranslation {
  isVisible?: boolean;
  isDisabledAllFields?: boolean;
}

function ClientPaymentForm({
  t,
  isVisible,
  isDisabledAllFields = false,
}: IClientPaymentFormProps): JSX.Element {
  const {
    valid,
    loadingSubmit,
    getFieldValue,
    setFieldsValue,
    handlerUpdateFormState,
  } = useDefaultForm();

  const [disabledPayment, setDisabledPayment] = useState(isDisabledAllFields);

  useEffect(() => {
    if (isVisible) {
      const total = getFieldValue('total');
      const totalSubscription = getFieldValue('subscription_total');

      setFieldsValue({final_total: totalSubscription || total});
    }
  }, [getFieldValue, isVisible, setFieldsValue]);

  const handleChangeDateOfPayment = useCallback(
    (value: Moment | null): void =>
      handlerUpdateFormState({payment_date: value}),
    [handlerUpdateFormState],
  );

  const handleChangePayment = useCallback(
    ({target: {value}}: RadioChangeEvent): void => {
      handlerUpdateFormState({payment_method: value});
    },
    [handlerUpdateFormState],
  );

  const handleChangePaymentIsDeferred = useCallback(
    (payment_is_deferred: boolean): void => {
      handlerUpdateFormState({payment_is_deferred});

      setDisabledPayment(payment_is_deferred);
    },
    [handlerUpdateFormState],
  );

  return (
    <>
      {valid ? (
        <Row gutter={20} justify="end">
          <Col span={24}>
            <Form.Item
              label={t('Date of payment')}
              name="payment_date"
              rules={[
                {
                  required: true,
                  message: t('Date of payment must be specified'),
                },
              ]}
              tooltip={{
                title: t('Subscription payment date'),
              }}>
              <DatePicker
                showTime
                className="client-subscription-start-date"
                data-testid="client-input-start-time"
                disabled={loadingSubmit || disabledPayment}
                placeholder={t('Start date')}
                onChange={handleChangeDateOfPayment}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t('Form of payment')}
              name="payment_method"
              rules={[
                {
                  required: true,
                  message: t('Form of payment must be specified'),
                },
              ]}
              tooltip={{
                title: t('Subscription payment method'),
              }}>
              <Segmented
                options={FROM_PAYMENT_METHOD(OPERATION_TYPE.IN)}
                disabled={loadingSubmit}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t('Total')}
              name="final_total"
              rules={[
                {
                  required: true,
                  message: t('Total must be specified'),
                },
              ]}
              tooltip={{
                title: t(
                  'Final price including discount and number of subscription packages',
                ),
              }}>
              <InputDisabledView
                style={{width: 'fit-content'}}
                className="client-subscription-total"
                data-testid="client-services-total"
                disabled
                addonAfter={t('UAH')}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <FormCheckBox
              label={t('Deferred payment')}
              name="payment_is_deferred"
              setFieldsValue={setFieldsValue}
              tooltipTitle={t(
                'The work with the subscription has been debugged',
              )}
              onChange={handleChangePaymentIsDeferred}>
              {({checked}) => (
                <Typography.Text type={checked ? 'danger' : undefined}>
                  {checked ? t('Activated') : t('Inactivated')}
                </Typography.Text>
              )}
            </FormCheckBox>
          </Col>
        </Row>
      ) : null}
    </>
  );
}
