export enum WithoutRecursion {
  service = 'service',
  employee = 'employee',
  company = 'company',
  profile = 'profile',
  group = 'group',
  client = 'client',
  category = 'category',
  scheduleCalendar = 'scheduleCalendar',
  employeeService = 'employeeService',
  groupSession = 'groupSession',
  groupPayment = 'groupPayment',
  clientSubscriptionable = 'clientSubscriptionable',
  user = 'user',
  profileSubscriptions = 'profileSubscriptions',
  mailingTemplate = 'mailingTemplate',
  relatedService = 'relatedService',
  counterparty = 'counterparty',
  report = 'report',
  abonement = 'abonement',
  clientAbonement = 'clientAbonement',
  visit = 'visit',
  status = 'status',
  product = 'product',
  expense = 'expense',
  cashBox = 'cashBox',
  file = 'file',
  reward = 'reward',
  department = 'department',
  store = 'store',
  storeDocument = 'storeDocument',
  priceTag = 'priceTag',
  priceMargin = 'price-margin',
  integrationCategory = 'integration-Category',
  integration = 'integration-Category',
  invoice = 'invoice',
  photo = 'photo',
}
