import {List} from 'immutable';
import {
  MailingDTO,
  MailingFormDTO,
  IMailingDTOProps,
  MailingModel,
  MailingListModel,
} from '../internal';
import {listToArray} from '../../helpers';

export interface IMailingListDTO {
  mailings: MailingDTO[];
  total: number;
}

export class MailingMapper {
  public static toMailingDTO(
    mailing: MailingModel | IMailingDTOProps,
  ): MailingDTO {
    return new MailingDTO(mailing);
  }

  public static toMailingListDTO(
    mailings: List<MailingModel> | IMailingDTOProps[],
    total: number,
  ): IMailingListDTO {
    const mailingList = List.isList(mailings)
      ? listToArray<MailingModel>(mailings)
      : mailings;

    return {
      mailings: mailingList?.map((mailing) =>
        MailingMapper.toMailingDTO(mailing),
      ),
      total,
    };
  }

  public static toMailingModel(mailingDTO: MailingDTO): MailingModel {
    return new MailingModel(mailingDTO);
  }

  public static toMailingListModel(
    mailingDTOs: MailingDTO[],
    total: number,
  ): MailingListModel {
    return new MailingListModel({mailings: mailingDTOs, total});
  }

  public static toMailingFormDTO(mailingModel?: MailingModel): MailingFormDTO {
    return new MailingFormDTO(mailingModel);
  }
}
