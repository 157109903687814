import React, {forwardRef, useRef} from 'react';
import {Drawer} from 'antd';
import {DrawerProps} from 'antd/lib/drawer';
import classNames from 'classnames';

import './SideWindow.less';

export interface ISideWindowProps extends Omit<DrawerProps, 'visible'> {
  children?: React.ReactNode;
  visible?: boolean;
}

/**
 * Extends Drawer from ant design, supports all its properties
 * @param children
 * @param title
 * @param visible
 * @param footer
 * @param onClose
 * @param width
 * @param rest
 * @param placement
 * @param className
 * @returns {*}
 * @constructor
 */
const SideWindow = forwardRef(function SideWindow(
  {
    children,
    title,
    footer,
    onClose,
    width = 540,
    placement = 'right',
    className,
    open,
    visible,
    ...rest
  }: ISideWindowProps,
  ref: any,
): JSX.Element {
  return (
    <Drawer
      rootClassName={classNames('SideWindow', className)}
      title={title}
      placement={placement}
      open={open || visible}
      width={width}
      footer={footer}
      destroyOnClose
      onClose={onClose}
      {...rest}>
      <div ref={ref}>{visible || open ? children : null}</div>
    </Drawer>
  );
});

export default SideWindow;
