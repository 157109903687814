import * as React from 'react';
import {ApiError, ErrorsStatus} from '../services/types';
import {useDropdownAlert} from '../contex';
import {useTranslation} from 'react-i18next';

export interface IUseStopLoadingProps {
  loading: boolean;
  error: ApiError | null;
  message: string;
}

const stopLoadingWithErrorStatus = [ErrorsStatus.Unprocessable_Entity];

export function useStopLoading({
  loading,
  error,
  message,
}: IUseStopLoadingProps) {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const [localLoading, setLocalLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setLocalLoading((prevState) =>
        prevState !== loading ? loading : prevState,
      );
    }, 100);
  }, [loading]);

  React.useEffect(() => {
    if (error && stopLoadingWithErrorStatus.includes(error?.status)) {
      setLocalLoading(false);
      alert('error', t('Loading error'), `${t(message)}: ${error?.message}`);
    }
  }, [alert, error, message, t]);

  return localLoading;
}
