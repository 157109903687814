import React, {useCallback} from 'react';
import {PlanModel, ProfileModel} from '../../../struture';
import {StyledTitle} from '../../../components/lib/Styled';
import styled, {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {Button} from '../../../components/lib/DataDisplay';
import {RightOutlined} from '@ant-design/icons';
import {consoleAuthUrl, systemTitles} from '../../../services/const';
import {useNavigate} from 'react-router';
import {Routes} from '../../../services/types';

export interface IProfileActiveSubscriptionViewProps {
  plan: PlanModel | null;
  profile: ProfileModel | null;
}

const StyledSpace = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 5px;
  padding-left: 5px;
`;

export function ProfileActiveSubscriptionTab({
  plan,
  profile,
}: IProfileActiveSubscriptionViewProps): JSX.Element {
  const {t} = useTranslation();
  const theme: any = useTheme();
  const navigate = useNavigate();

  const navigateToSubscriptionPage = useCallback(async () => {
    navigate(`/${Routes.app}/${Routes.subscription}`);
  }, [navigate]);

  const activeSubscriptionColor = plan?.isTrialSubscription
    ? {warning: true}
    : plan?.isSubscriptionExpired || plan?.isShowSubscriptionExpiredTime
    ? {danger: true}
    : {success: true};

  return (
    <StyledSpace>
      <StyledTitle bold {...activeSubscriptionColor} fontSize={20}>
        {plan?.isTrialSubscription
          ? t('Trial subscription')
          : plan?.isSubscriptionExpired
          ? t('Current subscription is expired')
          : plan?.isShowSubscriptionExpiredTime
          ? `${t('Subscription will expire in ')} ${plan?.subscriptionExpiredAt(
              t,
            )}`
          : `${t((systemTitles as any)[plan?.identifier as any])} ${t(
              'plan',
            ).toLowerCase()}`}
      </StyledTitle>
      {plan?.isTrialSubscription ? (
        <StyledTitle {...activeSubscriptionColor}>
          {'\t'}
          {t('You are using a')}&nbsp;
          <StyledTitle bold {...activeSubscriptionColor}>
            {`"${t('Trial subscription')}"`}&nbsp;
          </StyledTitle>
          {t('that expires')}&nbsp;
          <StyledTitle bold {...activeSubscriptionColor}>
            {`"${plan?.getFirstSubscription().date_end}"`}
          </StyledTitle>
          .&nbsp;
          {t(
            'To activate a new annual subscription, follow the link, register and pay for the new subscription.',
          )}
          &nbsp;
          {profile?.isAdmin ? '' : t('Contact the admin and fix the situation')}
        </StyledTitle>
      ) : plan?.isSubscriptionExpired ? (
        <StyledTitle {...activeSubscriptionColor}>
          {t(
            'The license agreement has expired. Access to data will be restricted in the near future. To renew an annual subscription, follow the link, register and pay for a new subscription',
          )}
          .&nbsp;
          {profile?.isAdmin ? '' : t('Contact the admin and fix the situation')}
        </StyledTitle>
      ) : profile?.isAdmin || plan?.isShowSubscriptionExpiredTime ? (
        <StyledTitle {...activeSubscriptionColor}>
          {t(
            'To change your current subscription plan, follow the link, register and pay for a new subscription.',
          )}
          &nbsp;
          {profile?.isAdmin && plan?.isShowSubscriptionExpiredTime
            ? ''
            : t('Contact the admin and fix the situation')}
        </StyledTitle>
      ) : null}

      {profile?.isAdmin ? (
        <Button type="link" onClick={navigateToSubscriptionPage}>
          <StyledTitle color={theme.link}>
            {t('Manage subscriptions')}&nbsp;&nbsp;
            <RightOutlined color={theme.link} />
          </StyledTitle>
        </Button>
      ) : null}
    </StyledSpace>
  );
}
