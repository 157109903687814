import {Button} from 'antd';
import styled, {css} from 'styled-components';

export const InvertButtonStyle = styled(Button)<{dragging: number}>`
  border: none;
  padding: 0;
  ${({dragging}) =>
    dragging &&
    css`
      background-color: ${({theme}) => theme.colors.accent};

      &:hover {
        background-color: ${({theme}) => theme.colors.primary};
      }
    `}
`;
