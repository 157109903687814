import {
  IScheduleCalendarLatestStatusModelReturnType,
  IStatusDTOProps,
  StatusDTO,
  StatusMapper,
} from '../internal';
import {WithoutRecursion} from '../type';

export interface IScheduleCalendarLatestStatusDTOProps {
  reason: string;
  status_color: string;
  status_text: string;
  created_at: string;
  custom_status: IStatusDTOProps;
}

export class ScheduleCalendarLatestStatusDTO {
  public reason: string;
  public status_color: string;
  public status_text: string;
  public created_at: string;
  public custom_status: StatusDTO;

  constructor(
    props:
      | IScheduleCalendarLatestStatusModelReturnType
      | IScheduleCalendarLatestStatusDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.reason = props?.reason || '';
    this.status_color = props?.status_color || '';
    this.status_text = props?.status_text || '';
    this.created_at = props?.created_at || '';

    this.custom_status = withoutRecursion.indexOf(WithoutRecursion.status)
      ? (props?.custom_status as StatusDTO)
      : StatusMapper.toStatusDTO(props?.custom_status, [
          WithoutRecursion.status,
          ...withoutRecursion,
        ]);
  }
}
