import {Record, List} from 'immutable';
import {IListProps} from './List';
import {ServiceGroupSessionDTO, ServiceGroupSessionModel} from '../internal';
import {WithoutRecursion} from '../type';

export interface IServiceGroupSessionListModelProps extends IListProps {
  group_sessions: ServiceGroupSessionDTO[];
}

export interface IServiceGroupSessionListModelReturnType extends IListProps {
  group_sessions: List<ServiceGroupSessionModel>;
}

export class ServiceGroupSessionListModel extends Record<IServiceGroupSessionListModelReturnType>(
  {
    group_sessions: List(),
    total: 0,
  },
) {
  constructor(
    {
      group_sessions = [],
      total,
    }: IServiceGroupSessionListModelProps = {} as IServiceGroupSessionListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IServiceGroupSessionListModelReturnType = {
      group_sessions: List(
        group_sessions.map(
          (session) => new ServiceGroupSessionModel(session, withoutRecursion),
        ),
      ),
      total,
    };

    super(options);
  }
}
