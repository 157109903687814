import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {Dropdown, MenuProps} from 'antd';
import {useModal} from '../../../components/lib/libV2/hooks';
import {
  ClientFormDTO,
  ClientGroupFormDTO,
  ClientLegalFormDTO,
  ClientModel,
} from '../../../struture';
import {
  IAddButtonProps,
  AddButton,
  SuspenseEmpty,
} from '../../../components/lib/DataDisplay';
import {StyledTitle} from '../../../components/lib/Styled';
import {LoadingOutlined} from '@ant-design/icons';
import styled, {useTheme, css} from 'styled-components';
import {isFunction} from '../../../services/helpers';
import {FaUserAlt, FaUsers, FaFileImport} from 'react-icons/fa';
import {IoMenu} from 'react-icons/io5';
import {ISendFieldsMappingProps} from '../../../services/api/client';

const ClientSideWindowLazy = React.lazy(
  () => import('../Show/ClientSideWindow'),
);

const ClientGroupSideWindowLazy = React.lazy(
  () => import('../Show/ClientGroupSideWindow'),
);

const ClientFileImportSideWindowLazy = React.lazy(
  () => import('../Show/ClientFileImportSideWindow'),
);

export type ClientSuccess = (
  value: ClientFormDTO,
) => Promise<ClientModel | void>;
export type ClientLegalSuccess = (
  value: ClientLegalFormDTO,
) => Promise<ClientModel | void>;
type GroupSuccess = (value: ClientGroupFormDTO) => Promise<ClientModel | void>;
type ImportSuccess = (
  value: ISendFieldsMappingProps,
  fileUuid: string,
) => Promise<void>;

export interface IClientCreateButtonProps extends IAddButtonProps {
  onSuccess?: ClientSuccess;
  onSuccessGroup?: GroupSuccess;
  onSuccessImport?: ImportSuccess;
  onCancel?: () => void;
  isFullForm?: boolean;
  autoFocus?: boolean;
  provider?: boolean;
  client?: ClientFormDTO;
}

const StyledDropdown = styled(Dropdown)<{
  disabled?: boolean;
}>`
  cursor: pointer;

  margin-right: 15px;
  .ant-dropdown-menu-item {
    padding: 0 !important;
  }

  ${({disabled}) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

export default function ClientCreateButton({
  loading = false,
  onSuccess,
  onSuccessGroup,
  isFullForm = true,
  autoFocus = false,
  onCancel,
  disabled,
  onSuccessImport,
  provider,
  client,
  ...rest
}: IClientCreateButtonProps) {
  const {t} = useTranslation();
  const theme: any = useTheme();

  const {
    handleCancel: clientHandleCancel,
    handleOnInit: clientHandleOnInit,
    handleSuccess: clientHandleSuccess,
    visible: clientVisible,
  } = useModal({
    onCancel,
    onSuccess: onSuccess,
  });

  const {
    handleCancel: groupHandleCancel,
    handleOnInit: groupHandleOnInit,
    handleSuccess: groupHandleSuccess,
    visible: groupVisible,
  } = useModal({
    onCancel,
    onSuccess: onSuccessGroup,
  });

  const {
    handleCancel: importHandleCancel,
    handleOnInit: importHandleOnInit,
    handleSuccess: importHandleSuccess,
    visible: importVisible,
  } = useModal({
    onCancel,
    onSuccess: onSuccessImport as any,
  });

  const items: MenuProps['items'] = [
    {
      key: 'client',
      label: <StyledTitle>{t('Create client')}</StyledTitle>,
      icon: <FaUserAlt size={15} />,
      onClick: clientHandleOnInit,
    },
    {
      key: 'group',
      label: <StyledTitle>{t('Create group')}</StyledTitle>,
      icon: <FaUsers size={18} />,
      onClick: groupHandleOnInit,
    },
    {
      key: 'import',
      label: <StyledTitle>{t('Import')}</StyledTitle>,
      icon: <FaFileImport size={15} />,
      onClick: importHandleOnInit,
    },
  ];

  return (
    <>
      {isFunction(onSuccessGroup) && isFunction(onSuccess) ? (
        <StyledDropdown
          trigger={['hover']}
          disabled={!!loading || disabled}
          menu={{items}}
          placement="bottomLeft">
          {loading ? (
            <LoadingOutlined color={theme.colors.primary} />
          ) : (
            <IoMenu size={20} color={theme.colors.primary} />
          )}
        </StyledDropdown>
      ) : (
        <AddButton
          tooltipTitle={t('Create company client')}
          onClick={
            isFunction(onSuccessGroup) ? groupHandleOnInit : clientHandleOnInit
          }
          loading={loading}
          disabled={disabled}
          {...rest}
        />
      )}

      {clientVisible ? (
        <Suspense fallback={<SuspenseEmpty />}>
          <ClientSideWindowLazy
            provider={provider}
            autoFocus={autoFocus}
            isFullForm={isFullForm}
            loading={loading as boolean}
            onSuccess={clientHandleSuccess}
            onCancel={clientHandleCancel}
            visible={clientVisible}
            client={client}
          />
        </Suspense>
      ) : null}

      <Suspense fallback={<SuspenseEmpty />}>
        <ClientGroupSideWindowLazy
          loading={loading as boolean}
          onSuccess={groupHandleSuccess}
          onCancel={groupHandleCancel}
          visible={groupVisible}
        />
      </Suspense>
      <Suspense fallback={<SuspenseEmpty />}>
        <ClientFileImportSideWindowLazy
          loading={loading as boolean}
          onSuccess={importHandleSuccess}
          onCancel={importHandleCancel}
          visible={importVisible}
        />
      </Suspense>
    </>
  );
}
