import {Record} from 'immutable';
import {
  MomentDate,
  DATE_SHORT_FORMAT,
  verificationHelpers as vHelper,
} from '../helpers';
import ClientList from './ClientList';
import EmployeeList from './EmployeeList';
import Company, {CompanyProps} from './Company';
import CompanyList, {CompanyListProps} from './CompanyList';
import {GENDER} from '../types';
import ProfileSubscriptionList, {
  IProfileSubscriptionListProps,
} from './ProfileSubscriptionList';

export const PROFILE_STATUS_DISABLED = 0; // Заблокований аккаунт
export const PROFILE_STATUS_ENABLED = 1; // Акнивний статус
export const PROFILE_STATUS_DELIVER = 2; // Статус, який потребує певних дій для активації аккаунта

const dateHelpers = new MomentDate();

export interface ProfileProps {
  id: string;
  uuid: string;
  status: number;
  first_name: string;
  last_name: string;
  middle_name: string;
  is_admin: boolean;
  phone: string;
  phone_approved: number;
  email: string;
  email_approved: string;
  picture_url: string;
  address: string;
  birthday: string;
  gender: GENDER;
  created_clients: any[];
  employee: any[];
  created_at: string;
  updated_at: string;
  companies: CompanyListProps;
  default_company: CompanyProps;
  profile_subscriptions: Pick<
    IProfileSubscriptionListProps,
    'profile_subscriptions'
  >;
}

/**
 * @class Profile
 * @classdesc
 */
export default class Profile extends Record({
  id: '0',
  uuid: '',
  status: PROFILE_STATUS_DISABLED,
  first_name: '',
  last_name: '',
  middle_name: '',
  phone: '',
  is_admin: false,
  phone_approved: 0,
  email: '',
  email_approved: '',
  picture_url: '',
  address: '',
  birthday: '',
  gender: GENDER.MALE,
  created_clients: [],
  employee: [],
  created_at: MomentDate.format(MomentDate.currentDate()),
  updated_at: MomentDate.format(MomentDate.currentDate()),
  companies: [],
  default_company: {},
  profile_subscriptions: [],
}) {
  private _keys: any;

  /**
   * @constructor
   * @param {{}} props
   */
  constructor(props: ProfileProps = {} as ProfileProps) {
    const options: any = {};

    if (Array.isArray(props.created_clients)) {
      options.created_clients = new ClientList(props.created_clients as any);
      options.total = props.created_clients ? props.created_clients.length : 0;
    }

    if (Array.isArray(props.profile_subscriptions)) {
      const subscriptions = new ProfileSubscriptionList({
        profile_subscriptions: props.profile_subscriptions,
      });

      options.profile_subscriptions = subscriptions.profile_subscriptions;
    }

    if (Array.isArray(props?.employee)) {
      options.employee = new EmployeeList({employees: props.employee} as any);

      options.total = props.employee ? props.employee.length : 0;
    }

    if (props?.birthday) {
      options.birthday = MomentDate.format(
        props.birthday as any,
        DATE_SHORT_FORMAT,
      );
    }

    if (props?.companies) {
      options.companies = new CompanyList({companies: props?.companies} as any);
    }

    if (props?.default_company) {
      options.default_company = new Company(props?.default_company);
    }

    super({...props, ...options});
  }

  /**
   * @desc Повне імя
   * @return {String}
   * */
  get fullName(): string {
    return `${this.last_name} ${this.first_name}`;
  }

  /**
   * @desc Перевіряємо чи активний поточний користувач
   * @return {Boolean}
   * */
  isEnabled(): boolean {
    return this.status === PROFILE_STATUS_ENABLED;
  }

  /**
   * @desc Перевіряємо чи потребується від користувача додатковиї дій
   * @return {Boolean}
   * */
  isDeliver(): boolean {
    return this.status === PROFILE_STATUS_DELIVER;
  }

  /**
   * @desc Перевіряємо чи заблокований Користувач
   * @return {Boolean}
   * */
  isDisabled(): boolean {
    return this.status === PROFILE_STATUS_DISABLED;
  }

  /**
   * @desc Перевіряє, якщо поле телефона, було верифіковано
   * @return {Boolean}
   * */
  isPhoneVerified(): boolean {
    return vHelper.isVerified(this.phone_approved);
  }

  /**
   * @desc Перевіряє, якщо поле email, було верифіковано
   * @return {Boolean}
   * */
  isEmailVerified(): boolean {
    return vHelper.isVerified(this.email_approved as any);
  }

  /**
   * @desc Повертає лише встановлені значення для властивостей
   * @return {JSON}
   * */
  getDefinedPropertiesJSON(): any {
    const result = {};

    this._keys.forEach((key) => {
      if (this.get(key)) {
        result[key] = this.get(key);
      }
    });

    return result;
  }

  /**
   * @desc Отримати день дня народження
   * @return {Number}
   * */
  get dayOfBirthday(): string {
    return dateHelpers.splitDateOnParts(this.birthday).day;
  }

  /**
   * @desc Отримати місяць дня народження
   * @return {Number}
   * */
  get monthOfBirthday(): string {
    return dateHelpers.splitDateOnParts(this.birthday).month;
  }

  /**
   * @desc Отримати рік дня народження
   * @return {Number}
   * */
  get yearOfBirthday(): string {
    return dateHelpers.splitDateOnParts(this.birthday).year;
  }

  get isPhoneApproved(): boolean {
    return this.phone_approved > 0;
  }

  get isEmailApproved(): boolean {
    return (this.email_approved as any) > 0;
  }
}
