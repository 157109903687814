import {Record} from 'immutable';
import Services from './Services';
import Employee from './Employee';
import {MomentDate} from '../helpers';

export interface IServiceGroupSessionProps {
  uuid: string;
  title: string;
  description: string;
  start_date: string;
  end_date: string;
  weekdays: string;
  max_attenders: number;
  status: number;
  created_at: string;
  updated_at: string;
  service?: Services;
  duration: number;
  manager: Employee;
  price: number;
}

/**
 * @class ServiceGroupSession
 * @desc Різновид послуги
 * @extends {Record}
 * @property {string}
 */
export default class ServiceGroupSession extends Record({
  uuid: '',
  status: 0,
  price: 0,
  service: new Services(),
  manager: new Employee(),
  created_at: MomentDate.format(MomentDate.currentDate()).toString(),
  updated_at: MomentDate.format(MomentDate.currentDate()).toString(),
  duration: 1,
  end_date: MomentDate.format(MomentDate.currentDate()).toString(),
  start_date: MomentDate.format(MomentDate.currentDate()).toString(),
  weekdays: '8:00-23:00',
  title: '',
  description: '',
  max_attenders: 0,
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(
    props: IServiceGroupSessionProps = {} as IServiceGroupSessionProps,
  ) {
    const options: {
      service?: Services;
      manager?: Employee;
    } = {};

    if (props?.service) {
      options.service = new Services(props.service as any);
    }

    if (props?.manager) {
      options.manager = new Employee(props.manager as any);
    }

    super({...props, ...options});
  }
}
