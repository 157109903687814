import React from 'react';
import {Col} from 'antd';
import classNames from 'classnames';
import {ITimeInDay} from '../../../services/helpers';

import './CalendarCol.less';

export interface ICalendarColProps {
  children: (value: ITimeInDay) => React.ReactNode;
  className?: string;
  span?: number;
  hourInDay: ITimeInDay;
  showTimeLimit?: boolean;
  withAddingEvents?: boolean;
  isPastDate?: boolean;
  isTodayTimeLimit?: boolean;
}

export default function CalendarCol({
  className,
  children,
  span = 24,
  hourInDay,
  showTimeLimit = false,
  withAddingEvents,
  isPastDate,
  isTodayTimeLimit,
}: ICalendarColProps): JSX.Element {
  return (
    <Col
      span={span}
      className={classNames('calendar-col', className, {
        'calendar-col--time-limit': showTimeLimit,
        'calendar-col--adding-event': withAddingEvents,
        'calendar-col--past-date': isPastDate,
        'calendar-col--time-limit-today': isTodayTimeLimit,
      })}>
      {children(hourInDay)}
    </Col>
  );
}
