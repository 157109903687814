import React, {useCallback, useMemo, useState} from 'react';
import {Empty} from 'antd';
import {useTranslation} from 'react-i18next';
import {
  useSearchInputFocus,
  useStopLoading,
  useStoredCompanies,
  useStoredReportList,
} from '../../../hooks';
import {ReportDeleteButton} from '../Buttons';
import {
  capitalize,
  len,
  lessOrEqualThan,
  textToUpperCase,
  toDateByFormat,
  toMoment,
} from '../../../services/helpers';
import {
  Table,
  TableActionCell,
  TableButtonCell,
} from '../../../components/lib/libV2/DataDisplay';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import {ReportModel, ReportStatuses} from '../../../struture';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '../../../services/types';
import {useAcl, useDropdownAlert} from '../../../contex';
import {HeaderLeft, ListActions} from '../../../components/lib/DataDisplay';
import {useNavigate} from 'react-router';
import {ListLayout} from '../../../components/lib/Layout';
import {StyledTitle} from '../../../components/lib/Styled';
import styled, {css, useTheme} from 'styled-components';
import {AiOutlineFileSync} from 'react-icons/ai';
import {TbFileX} from 'react-icons/tb';
import {RiFileSettingsLine} from 'react-icons/ri';
import {MdOutlineFilePresent} from 'react-icons/md';
import {FileUnknownOutlined} from '@ant-design/icons';

const StyledRowCellContainer = styled.div<{$bgColor: string}>`
  position: absolute;
  top: 0;
  left:0;
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 100%;
  height: 100%;
  background-color: inherit;
  
  ${({$bgColor}) =>
    $bgColor &&
    css`
      background-color: ${$bgColor};
    `}
  
}
`;

export const reportStatusColor = (theme: any) => ({
  [ReportStatuses.Completed]: theme.colors.success,
  [ReportStatuses.Processing]: theme.colors.link,
  [ReportStatuses.Initialized]: theme.colors.warning,
  [ReportStatuses.Error]: theme.colors.error,
});
export function ReportingListPage(): JSX.Element {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const navigate = useNavigate();
  const {defaultCompanyUuid} = useStoredCompanies();
  const {manage} = useAcl(({reports}) => reports);
  const theme: any = useTheme();

  const [selectedReportingLists, setSelectedReportingLists] = useState<
    ReportModel[]
  >([]);

  const {
    reportList,
    limit,
    total,
    loading: loadingReportingListList,
    error: errorReportingListList,
    keywords,
    startDate,
    endDate,
    loadingMore,
    page,

    handleDeleteReports: onDeleteReports,
    handleSearchByDates,
    handleSearchReports,
    handleRefreshReports,
  } = useStoredReportList({companyUuid: defaultCompanyUuid});

  const loading = useStopLoading({
    loading: loadingReportingListList || loadingMore,
    error: errorReportingListList,
    message: 'An error occurred during reports loading',
  });

  const {inputRef, focus} = useSearchInputFocus({loading});

  const handleDeleteReports = useCallback(
    async (value: string[]) => {
      await onDeleteReports(value);
      focus();
    },
    [focus, onDeleteReports],
  );

  const routes = useBreadcrumb([
    {
      path: `/${Routes.reports}`,
      breadcrumbName: 'Reporting',
    },
    {
      path: `/${Routes.reportsArchive}`,
      breadcrumbName: 'Archive of reports',
    },
  ]);

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: selectedReportingLists.map(({uuid}) => uuid),
      onChange: (
        selectedRowKeys: React.Key[],
        selectedRecords: ReportModel[],
      ) => {
        if (lessOrEqualThan(len(selectedRecords), 1)) {
          setSelectedReportingLists(selectedRecords);
        } else {
          alert('error', t('Delete'), t('You can delete up to 1 at a time'));
        }
      },
      renderCell: (
        checked: boolean,
        report: ReportModel,
        index: number,
        originNode: React.ReactNode,
      ) => (
        <StyledRowCellContainer
          $bgColor={
            reportStatusColor(theme)[report?.report_status] ||
            theme.colors.primary
          }
          className="row-selection--colors">
          {originNode}
        </StyledRowCellContainer>
      ),
    }),
    [alert, selectedReportingLists, t, theme],
  );

  const statusIcon = {
    [ReportStatuses.Initialized]: (
      <AiOutlineFileSync
        size={22}
        color={reportStatusColor(theme)[ReportStatuses.Initialized]}
      />
    ),
    [ReportStatuses.Completed]: (
      <MdOutlineFilePresent
        size={22}
        color={reportStatusColor(theme)[ReportStatuses.Completed]}
      />
    ),
    [ReportStatuses.Error]: (
      <TbFileX
        size={22}
        color={reportStatusColor(theme)[ReportStatuses.Error]}
      />
    ),
    [ReportStatuses.Processing]: (
      <RiFileSettingsLine
        size={22}
        color={reportStatusColor(theme)[ReportStatuses.Processing]}
      />
    ),
  };

  const columns = [
    {
      ellipsis: true,
      title: t('Title'),
      key: 'tile',
      render: (report: ReportModel) => (
        <TableButtonCell
          disabled={report?.report_status !== ReportStatuses.Completed}
          tooltip="Open pdf report"
          onClick={() => window.open(report?.report_path_url, '_blank')}
          icon={
            statusIcon[report?.report_status] || (
              <FileUnknownOutlined size={22} color={theme.colors.primary} />
            )
          }>
          <StyledTitle>
            {`${t(report?.report_title)}${
              report?.report_number ? ` №${report?.report_number}` : ''
            }`}
          </StyledTitle>
        </TableButtonCell>
      ),
    },
    {
      ellipsis: true,
      title: t('From'),
      key: 'From',
      render: (report: ReportModel) =>
        `${toDateByFormat(report?.created_at, 'YYYY-MM-DD')}`,
    },
    {
      ellipsis: true,
      title: t('Interval'),
      key: 'Interval',
      render: (report: ReportModel) =>
        `${toDateByFormat(
          report?.report_start_date,
          'YYYY-MM-DD',
        )} / ${toDateByFormat(report?.report_end_date, 'YYYY-MM-DD')}`,
    },
    {
      ellipsis: true,
      title: t('Type'),
      key: 'Type',
      render: (report: ReportModel) => capitalize(t(report?.report_type)),
    },
    {
      ellipsis: true,
      title: t('Actions'),
      key: 'report_actions',
      align: 'center' as any,
      fixed: 'right' as any,
      render: (report: ReportModel) => {
        return (
          <TableActionCell>
            <ReportDeleteButton
              disabled={!manage}
              reports={[report]}
              onSuccess={handleDeleteReports}
            />
          </TableActionCell>
        );
      },
    },
  ];

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.reports}`);
  }, [navigate]);

  return (
    <ListLayout
      aclItem={RoutesAcl[Routes.reports]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.reports]))}
      headerRight={
        <>
          {selectedReportingLists?.length ? (
            <ReportDeleteButton
              disabled={!manage}
              reports={selectedReportingLists}
              onSuccess={handleDeleteReports}
            />
          ) : null}
        </>
      }
      routes={routes}
      empty={
        reportList?.size ? null : (
          <Empty description={t('No report list found')} />
        )
      }
      headerExtra={
        <ListActions
          inputRef={inputRef}
          loading={loading}
          defaultDateRangeValue={
            startDate && endDate
              ? [toMoment(startDate), toMoment(endDate)]
              : undefined
          }
          handlePickRange={handleSearchByDates}
          handleSearch={handleSearchReports}
          inputTooltip={t('Search reports')}
          inputLabel={t('Search reports')}
          searchText={keywords}
        />
      }
      loading={loading && !reportList?.size}>
      <Table<ReportModel>
        pageSize={limit}
        total={total}
        onChange={handleRefreshReports}
        rowSelection={rowSelection}
        dataSource={reportList}
        columns={columns}
        loading={loading}
        page={page}
      />
    </ListLayout>
  );
}
