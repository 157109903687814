import {Record, List} from 'immutable';
import {IListProps} from './List';
import {StoreDocumentDTO, StoreDocumentModel} from '../internal';
import {WithoutRecursion} from '../type';

export interface IStoreDocumentListModelProps extends IListProps {
  documents: StoreDocumentDTO[];
  keywords?: string;
  page?: number;
}

export interface IStoreDocumentListModelReturnType extends IListProps {
  documents: List<StoreDocumentModel>;
  keywords?: string;
  page?: number;
}

export class StoreDocumentListModel extends Record<IStoreDocumentListModelReturnType>(
  {
    documents: List(),
    total: 0,
    keywords: '',
    page: 1,
  },
) {
  constructor(
    {
      documents = [],
      ...props
    }: IStoreDocumentListModelProps = {} as IStoreDocumentListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IStoreDocumentListModelReturnType = {
      ...props,
      documents: List(
        documents.map(
          (document) => new StoreDocumentModel(document, withoutRecursion),
        ),
      ),
    };

    super(options);
  }
}
