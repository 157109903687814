import React from 'react';
import styled from 'styled-components';
import {withTranslation, WithTranslation} from 'react-i18next';
import {FlexContainer} from '../../../components/lib/Styled';
import {TextFormat} from '../../../components/lib/Format';

export default withTranslation()(UserUnauthorizedView);

export interface IUserUnauthorizedViewProps extends WithTranslation {
  title?: string;
}

const StyledFlexContainer = styled(FlexContainer)`
  width: 100%;
  height: 50vh;
`;

const StyledTextFormat = styled(TextFormat)`
  font-size: 2rem;
  text-align: center;
`;

function UserUnauthorizedView({
  t,
  title,
}: IUserUnauthorizedViewProps): JSX.Element {
  return (
    <StyledFlexContainer justifyContent="center" alignItems="center">
      <StyledTextFormat error bold>
        {title ||
          t(
            'You do not have access to this information, contact the site administrator',
          )}
      </StyledTextFormat>
    </StyledFlexContainer>
  );
}
