import {
  buildDisplayLengthOfTime,
  compose,
  EventType,
  head,
  MutationScheduleEvent,
  split,
} from '../services/helpers';

export interface IInitialState {
  eventTime: string;
  event: MutationScheduleEvent | null;
  didDrop: boolean;
  updatedTime: string;
  dndEvents: EventType | null;
  addedEvents: EventType | null;
}

export const dndCache = new Map();

export enum CALENDAR_DND_ACTIONS {
  SET_EVENT_TIME = 'set_event_time',
  SET_UPDATE_TIME = 'set_update_time',
  SET_EVENT_DATA = 'set_event_data',
  SET_DID_DROP = 'set_did_drop',
  SET_DND_EVENTS = 'set_dnd_events',
  SET_DND_ADDED_EVENTS = 'set_dnd_added_events',
  UPDATE_ALL = 'update_all',
  DEFAULT = 'default',
}

export interface SetEventTimeActions extends Pick<IInitialState, 'eventTime'> {
  type: CALENDAR_DND_ACTIONS.SET_EVENT_TIME;
}

export interface SetEventDataActions extends Pick<IInitialState, 'event'> {
  type: CALENDAR_DND_ACTIONS.SET_EVENT_DATA;
}

export interface SetDidDropActions extends Pick<IInitialState, 'didDrop'> {
  type: CALENDAR_DND_ACTIONS.SET_DID_DROP;
}

export interface SetDnDEventsActions extends Pick<IInitialState, 'dndEvents'> {
  type: CALENDAR_DND_ACTIONS.SET_DND_EVENTS;
}

export interface SetDnDAddedEventsActions
  extends Pick<IInitialState, 'addedEvents'> {
  type: CALENDAR_DND_ACTIONS.SET_DND_ADDED_EVENTS;
}

export interface SetUpdateTimeActions
  extends Pick<IInitialState, 'updatedTime'> {
  type: CALENDAR_DND_ACTIONS.SET_UPDATE_TIME;
}

export interface SetAllActions extends Partial<IInitialState> {
  type: CALENDAR_DND_ACTIONS.UPDATE_ALL;
}

export type CalendarDndActions =
  | SetEventTimeActions
  | SetAllActions
  | SetDidDropActions
  | SetUpdateTimeActions
  | SetDnDEventsActions
  | SetDnDAddedEventsActions
  | SetEventDataActions;

export const handlers = {
  [CALENDAR_DND_ACTIONS.SET_EVENT_TIME]: (
    state: IInitialState,
    {eventTime}: SetEventTimeActions,
  ): IInitialState => {
    return {
      ...state,
      eventTime,
    };
  },

  [CALENDAR_DND_ACTIONS.SET_EVENT_DATA]: (
    state: IInitialState,
    {event}: SetEventDataActions,
  ): IInitialState => {
    return {
      ...state,
      event,
    };
  },

  [CALENDAR_DND_ACTIONS.SET_DND_EVENTS]: (
    state: IInitialState,
    {dndEvents}: SetDnDEventsActions,
  ): IInitialState => ({
    ...state,
    dndEvents,
  }),

  [CALENDAR_DND_ACTIONS.SET_DND_ADDED_EVENTS]: (
    state: IInitialState,
    {addedEvents}: SetDnDAddedEventsActions,
  ): IInitialState => {
    dndCache.set('addedEvents', addedEvents);

    return {
      ...state,
      addedEvents,
    };
  },

  [CALENDAR_DND_ACTIONS.SET_DID_DROP]: (
    state: IInitialState,
    {didDrop}: SetDidDropActions,
  ): IInitialState => ({
    ...state,
    didDrop,
  }),

  [CALENDAR_DND_ACTIONS.SET_UPDATE_TIME]: (
    state: IInitialState,
    {updatedTime}: SetUpdateTimeActions,
  ): IInitialState => {
    const newTime = buildDisplayLengthOfTime(
      updatedTime,
      state.event?.schedule_amount as any,
    );

    return {
      ...state,
      updatedTime: compose<string>(head, split(' '), head, split('-'))(newTime),
    };
  },

  [CALENDAR_DND_ACTIONS.UPDATE_ALL]: (
    state: IInitialState,
    value: SetAllActions,
  ): IInitialState => {
    return {
      ...state,
      ...value,
    };
  },

  [CALENDAR_DND_ACTIONS.DEFAULT]: (state: IInitialState): IInitialState =>
    state,
};
