import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {useModal} from '../../../components/lib/libV2/hooks';
import {StatusFormDTO, StatusModel} from '../../../struture';
import {
  AddButton,
  IAddButtonProps,
  SuspenseEmpty,
} from '../../../components/lib/DataDisplay';

const StatusSideWindowLazy = React.lazy(
  () => import('../Show/StatusSideWindow'),
);

export interface IStatusCreateButtonProps extends IAddButtonProps {
  onSuccess: (value: StatusFormDTO) => Promise<StatusModel | void>;
  onCancel?: () => void;
}

export default function StatusCreateButton({
  loading = false,
  onSuccess,
  onCancel,
  ...rest
}: IStatusCreateButtonProps) {
  const {t} = useTranslation();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <AddButton
        tooltipTitle={t('Create status')}
        onClick={handleOnInit}
        loading={loading}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <StatusSideWindowLazy
          loading={loading as boolean}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          visible={visible}
        />
      </Suspense>
    </>
  );
}
