import {
  IServiceDTOProps,
  ServiceDTO,
  ServiceMapper,
  IClientSubscriptionableModelReturnType,
} from '../internal';
import {WithoutRecursion} from '../type';
import {correctPrice} from '../../helpers';

export interface IClientSubscriptionableDTOProps {
  uuid: string;
  title: string;
  description: string;
  weekdays: string;
  duration: number;
  max_attenders: number;
  price: string;
  status: number;
  start_date: string;
  end_date: string;
  service: IServiceDTOProps;
}

export class ClientSubscriptionableDTO {
  public uuid: string;

  public title: string;

  public description: string;

  public weekdays: string;

  public duration: number;

  public max_attenders: number;

  public price: string;

  public status: number;

  public start_date: string;

  public end_date: string;

  public service: ServiceDTO;

  constructor(
    props:
      | IClientSubscriptionableDTOProps
      | IClientSubscriptionableModelReturnType,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';
    this.title = props?.title || '';
    this.description = props?.description || '';
    this.weekdays = props?.weekdays || '';
    this.duration = props?.duration || 0;
    this.max_attenders = props?.max_attenders || 0;
    this.price = correctPrice(props?.price);
    this.status = props?.status || 0;
    this.start_date = props?.start_date || '';
    this.end_date = props?.end_date || '';
    this.service =
      withoutRecursion.indexOf(WithoutRecursion.service) !== -1
        ? (props?.service as ServiceDTO)
        : ServiceMapper.toServiceDTO(props?.service, [
            WithoutRecursion.clientSubscriptionable,
            ...withoutRecursion,
          ]);
  }
}
