import React from 'react';
import {Routes, Route} from 'react-router';
import {StatusListPage, StatusPage} from '../views/Statuses';
import {AppRouteLayout} from '../components/lib/Layout';

export function StatusRoutesNavigator() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AppRouteLayout>
            <StatusListPage />
          </AppRouteLayout>
        }
      />
      <Route
        path=":statusId"
        element={
          <AppRouteLayout>
            <StatusPage />
          </AppRouteLayout>
        }
      />
    </Routes>
  );
}
