import React from 'react';
import styled from 'styled-components';

export const StyledHeaderDropdownMenuButton = styled.a`
  display: flex;
  flex-direction: row;
  border: none;
  align-items: center;
  width: 100%;
  padding: 0;
  color: ${({theme}) => theme.colors.primary};
  transition: none;
  & .anticon {
    padding-right: 5px;
    font-size: 12px;
  }
  &,
  &:disabled,
  &:hover {
    box-shadow: none !important;
    background-color: transparent !important;
    color: darken(${({theme}) => theme.colors.primary}, 10%);
  }
`;
