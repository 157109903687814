import {Text} from '../Format';
import React, {forwardRef} from 'react';
import styled, {css} from 'styled-components';
import {Button as ANTButton, ButtonProps, Tooltip} from 'antd';

export interface IButtonProps extends Omit<ButtonProps, 'children'> {
  children: string | React.ReactNode | React.ReactNode[];
  tooltipTitle?: string;
  fitContent?: boolean;
}

const StyledButton = styled(ANTButton)<{
  type: ButtonProps['type'];
  $fitContent?: boolean;
}>`
  width: 100%;

  ${({type}) =>
    type === 'default' &&
    css`
      border-color: ${({theme}) => theme.colors.link};

      &:hover {
        opacity: 0.7;
      }

      &&& * {
        //color: ${({theme}) => theme.colors.link};
      }
    `};

  ${({$fitContent}) =>
    $fitContent &&
    css`
      width: fit-content;
    `}
`;

const StyledTooltip = styled(Tooltip)<{
  $fitContent?: boolean;
}>`
  width: 100%;

  ${({$fitContent}) =>
    $fitContent &&
    css`
      width: fit-content;
    `}
`;

export default forwardRef(function Button(
  {children, tooltipTitle, type, fitContent, ...rest}: IButtonProps,
  ref: any,
): JSX.Element {
  return (
    <StyledTooltip title={tooltipTitle} $fitContent={fitContent}>
      <StyledButton type={type} ref={ref} $fitContent={fitContent} {...rest}>
        {typeof children === 'string' ? <Text>{children}</Text> : children}
      </StyledButton>
    </StyledTooltip>
  );
});
