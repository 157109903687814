import {Record, List} from 'immutable';
import {IListProps} from './List';
import {FileDTO, FileModel} from '../internal';
import {WithoutRecursion} from '../type';

export interface IFileListModelProps extends IListProps {
  files: FileDTO[];
}

export interface IFileListModelReturnType extends IListProps {
  files: List<FileModel>;
}

export class FileListModel extends Record<IFileListModelReturnType>({
  files: List(),
  total: 0,
}) {
  constructor(
    {files = [], ...props}: IFileListModelProps = {} as IFileListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IFileListModelReturnType = {
      ...props,
      files: List(files.map((file) => new FileModel(file))),
    };

    super(options);
  }
}
