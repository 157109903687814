import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {List} from 'immutable';
import {
  IUseProductCategoryListProps,
  IUseProductCategoryListReturnType,
  useProductCategoryList,
} from './useProductCategoryList';
import {
  ProductCategoryFormDTO,
  ProductCategoryMappedType,
  ProductCategoryMapper,
  ProductCategoryModel,
  ProductCategoryType,
} from '../struture';
import {
  createProductCategory,
  deleteProductCategory,
  editProductCategory,
} from '../services/api/product';
import {
  addProductCategorySubCategoryToList as storeAddProductCategorySubCategoryToList,
  addProductCategoryToList as storeAddCategory,
  deleteProductCategoryFromList as storeDeleteCategory,
  deleteProductCategorySubCategoryFromList as storeDeleteProductCategorySubCategory,
  loadMoreProductCategorySubCategoryList as storeLoadMoreProductCategorySubCategoryList,
  setProductCategorySubCategoryList as storeSetProductCategorySubCategoryList,
  updateProductCategory as storeUpdateProductCategory,
  updateProductCategoryFromList as storeUpdateProductCategoryFromList,
  updateProductCategorySubCategoryFromList as storeUpdateProductCategorySubCategoryToList,
} from '../store/actions';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../store/reducers';
import {REDUX_STATUS} from '../services/types';
import {head} from '../services/helpers';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../contex';
import {useStoredProductCategoryList} from './useStoredProductCategoryList';

export interface IUseStateProductCategorySubCategoryListProps
  extends Omit<IUseProductCategoryListProps, 'parent_category_uuid'> {
  parent_category_uuid: string;
}

export interface IProductCategorySubCategorySearchProps {
  keywords: string;
  showLoading?: boolean;
  limit?: number;
  parentCategoryUuid?: string;
}

export interface IUseStateProductCategorySubCategoryListReturnType
  extends Omit<IUseProductCategoryListReturnType, 'entityList'> {
  categoryList: List<ProductCategoryModel> | null;
  handleDeleteCategories: (ids: string[]) => Promise<void>;
  handleSearchCategories: (
    value: IProductCategorySubCategorySearchProps,
  ) => Promise<void>;
  handleLoadMoreCategories: () => Promise<void>;
  handleCreateCategory: (value: ProductCategoryFormDTO) => Promise<void>;
  handleEditCategory: (
    value: ProductCategoryFormDTO,
  ) => Promise<ProductCategoryModel | void>;
  handleRefreshCategories: (
    value: Partial<IUseProductCategoryListProps> & {page: number},
  ) => Promise<void>;
  status: REDUX_STATUS;
  loadingMore: boolean;
  page: number;
  parentCategory: ProductCategoryModel | null;
}

export function useStoredProductCategorySubCategoryList(
  {
    parent_category_uuid: localCategoryUuid,
    loadOnInit = true,
    companyUuid,
    ...rest
  }: IUseStateProductCategorySubCategoryListProps = {} as IUseStateProductCategorySubCategoryListProps,
): IUseStateProductCategorySubCategoryListReturnType {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const [loadingMore, setLoadingMore] = useState(false);
  const [categoryList, setVisitList] =
    useState<List<ProductCategoryModel> | null>(null);
  const [isStarLoading, setIsStartLoading] = React.useState<boolean>(false);
  const [storeKeywords, setStoreKeywords] = useState('');
  const [storePage, setStorePage] = useState(1);
  const [storeTotal, setStoreTotal] = useState(0);
  const [categoryUuid, setAbonementUuid] = useState(localCategoryUuid);
  const [parentCategory, setParentCategory] =
    useState<ProductCategoryModel | null>(null);

  const {
    status: storedProductCategorySubCategoryListStatus,
    loading: storedProductCategorySubCategoryListLoading,
    cachedProductList,
    ...storedProductCategorySubCategoryListParams
  } = useSelector(
    ({productCategorySubCategoryList}: RootState) =>
      productCategorySubCategoryList,
  );

  const dispatch = useDispatch<any>();

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: categoryListLoading,
    total,
    ...categoriesParams
  } = useProductCategoryList({
    parent_category_uuid: categoryUuid,
    loadOnInit: false,
    companyUuid,
    ...rest,
  });

  const {categoryList: storedCategoryList} = useStoredProductCategoryList({
    companyUuid,
    loadOnInit: false,
    productCategoryType: ProductCategoryType.LIST,
    // mappedProductCategoryType: ProductCategoryMappedType.SUB_CATEGORY_LIST,
  });

  React.useEffect(() => {
    if (cachedProductList?.size > 0) {
      const categoryList = cachedProductList.get(categoryUuid);

      if (categoryList) {
        setIsStartLoading(false);
        setVisitList(categoryList?.categories);
        setStorePage(categoryList?.page);
        setStoreKeywords(categoryList?.keywords);
        setStoreTotal(categoryList?.total || 0);
        setParentCategory(categoryList?.parentCategory || null);
      } else {
        setIsStartLoading(true);
      }
    }
    if (cachedProductList?.size === 0 && !categoryList) {
      setIsStartLoading(true);
    }
  }, [dispatch, cachedProductList, categoryUuid, storeTotal, categoryList]);

  useEffect(() => {
    if (
      !List.isList(entityList) &&
      isStarLoading &&
      loadOnInit &&
      companyUuid
    ) {
      setIsStartLoading(false);
      (async () => {
        const entityList = await refresh({
          parent_category_uuid: categoryUuid,
          companyUuid,
        });

        if (entityList) {
          dispatch(
            storeSetProductCategorySubCategoryList(
              entityList,
              categoryUuid,
              '',
              entityList?.total,
              entityList?.page,
              entityList?.parentCategory,
            ),
          );
        }
      })();
    }
  }, [
    categoryUuid,
    companyUuid,
    dispatch,
    entityList,
    isStarLoading,
    loadOnInit,
    refresh,
  ]);

  const handleDeleteCategories = React.useCallback(
    async (ids: string[]) => {
      try {
        const {stats} = await deleteProductCategory(ids);

        setTimeout(() => {
          dispatch(
            storeDeleteProductCategorySubCategory(head(ids), categoryUuid),
          );
          dispatch(storeDeleteCategory(head(ids), stats));
        }, 100);

        alert('success', t('Category'), t('Category delete success'));
      } catch (error: any) {
        alert(
          'error',
          t('Category'),
          `${t('An error occurred during delete categories')}: ${
            error?.message
          }`,
        );
      }
    },
    [alert, dispatch, t, categoryUuid],
  );

  const handleSearchCategories = React.useCallback(
    async ({
      limit = 10,
      keywords,
      parentCategoryUuid: localCategoryUuid = categoryUuid,
      showLoading,
    }: IProductCategorySubCategorySearchProps) => {
      setLoadingMore(true);
      const categoryListModel = await refresh({
        offset: 0,
        limit,
        keywords,
        parent_category_uuid: localCategoryUuid,
        showLoading,
      });

      if (categoryListModel && List.isList(categoryListModel?.categories)) {
        setAbonementUuid(localCategoryUuid);
        dispatch(
          storeSetProductCategorySubCategoryList(
            categoryListModel,
            localCategoryUuid,
            keywords,
            categoryListModel.total,
            1,
            categoryListModel?.parentCategory,
          ),
        );
      }
      setLoadingMore(false);
    },
    [categoryUuid, dispatch, refresh],
  );

  const handleLoadMoreCategories = useCallback(async () => {
    if (
      List.isList(categoryList) &&
      categoryList?.size < storeTotal &&
      !loadingMore
    ) {
      setLoadingMore(true);
      const categoryListModel = await refresh({
        offset: categoryList?.size,
        limit: 10,
        showLoading: false,
      });

      if (categoryListModel && List.isList(categoryListModel?.categories)) {
        dispatch(
          storeLoadMoreProductCategorySubCategoryList(
            categoryListModel,
            categoryUuid,
          ),
        );
      }

      setLoadingMore(false);
    }
  }, [categoryList, storeTotal, loadingMore, refresh, dispatch, categoryUuid]);

  const handleRefreshCategories = useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = false,
      page,
      parent_category_uuid: localCategoryUuid = categoryUuid,
    }: Partial<IUseProductCategoryListProps> & {page: number}) => {
      setLoadingMore(true);
      const categoryListModel = await refresh({
        offset,
        limit,
        showLoading,
        parent_category_uuid: localCategoryUuid,
      });

      if (categoryListModel && List.isList(categoryListModel?.categories)) {
        setAbonementUuid(localCategoryUuid);

        dispatch(
          storeSetProductCategorySubCategoryList(
            categoryListModel,
            localCategoryUuid,
            storeKeywords,
            categoryListModel?.total,
            page,
          ),
        );

        setLoadingMore(false);
      }
    },
    [categoryUuid, dispatch, refresh, storeKeywords],
  );

  const handleCreateCategory = React.useCallback(
    async (value: ProductCategoryFormDTO) => {
      const {category: categoryDTO, stats} = await createProductCategory(
        value,
        companyUuid,
      );

      const categoryModel =
        ProductCategoryMapper.toProductCategoryModel(categoryDTO);

      dispatch(
        storeAddProductCategorySubCategoryToList(categoryModel, categoryUuid),
      );

      if (List.isList(storedCategoryList)) {
        dispatch(storeAddCategory(categoryModel, stats));
      }
    },
    [companyUuid, dispatch, categoryUuid, storedCategoryList],
  );

  const handleEditCategory = React.useCallback(
    async (value: ProductCategoryFormDTO) => {
      const categoryDTO = await editProductCategory(value);

      const categoryModel =
        ProductCategoryMapper.toProductCategoryModel(categoryDTO);

      dispatch(
        storeUpdateProductCategorySubCategoryToList(
          categoryModel,
          categoryUuid,
        ),
      );

      dispatch(storeUpdateProductCategory(categoryModel));
      dispatch(storeUpdateProductCategoryFromList(categoryModel));

      return categoryModel;
    },
    [categoryUuid, dispatch],
  );

  return {
    ...categoriesParams,
    ...storedProductCategorySubCategoryListParams,
    categoryList,
    total: storeTotal,
    loadingMore,
    offset,
    limit,
    refresh,
    loading:
      (!List.isList(categoryList) && categoryListLoading) ||
      !storedProductCategorySubCategoryListLoading,
    handleDeleteCategories,
    handleSearchCategories,
    handleLoadMoreCategories,
    status: storedProductCategorySubCategoryListStatus,
    handleRefreshCategories,
    keywords: storeKeywords || '',
    page: storePage || 1,
    handleCreateCategory,
    handleEditCategory,
    parentCategory,
  };
}
