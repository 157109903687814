import {AbonementModel} from '../internal';
import {correctPrice, fromBooleanToNumber} from '../../helpers';

export class AbonementFormDTO {
  public uuid: string;

  public title: string;

  public service_uuid: string;

  public manager_uuid: string;

  public price: string;

  public timing: string;

  public comment: string;

  public period_amount: number;

  public visiting_limit: number;

  public can_be_paused: number;

  public max_paused_period: number;

  public can_timing_be_limited: boolean;

  public can_visiting_be_limited: boolean;

  public can_move_unused_visiting: boolean;

  public manager_required: boolean;

  constructor(props?: AbonementModel) {
    this.uuid = props?.uuid || '';
    this.title = props?.abon_title || '';
    this.service_uuid = props?.serviceModel?.uuid || '';
    this.manager_uuid = props?.managerModel?.uuid || '';
    this.price = correctPrice(props?.abon_price || 0);
    this.timing = 'props?.abon_timing_list' || '';
    this.comment = props?.abon_comment || '';
    this.period_amount = props?.abon_period_amount || 1;
    this.visiting_limit =
      fromBooleanToNumber(props?.abon_can_visiting_be_limited) || 1;
    this.can_be_paused = fromBooleanToNumber(props?.abon_can_be_paused) || 1;
    this.can_timing_be_limited = props?.abon_can_timing_be_limited || false;
    this.can_visiting_be_limited = props?.abon_can_visiting_be_limited || false;
    this.can_move_unused_visiting =
      props?.abon_can_move_unused_visiting || false;
    this.manager_required = props?.abon_manager_required || false;
    this.max_paused_period = props?.abon_max_paused_period || 1;
  }
}
