import {ClientModel} from '../internal';
import {isListToArray} from '../../services/helpers';

export class ClientGroupFormDTO {
  public uuid: string;

  public client_group_name: string;
  public client_uuids: string[];

  constructor(props?: ClientModel, editMode?: boolean) {
    this.uuid = props?.uuid || '';
    this.client_group_name = props?.client_group_name || '';
    this.client_uuids = editMode
      ? (isListToArray(props?.members as any) || [])?.map(
          (client: ClientModel) => client?.uuid,
        )
      : [];
  }
}
