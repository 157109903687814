import {Record} from 'immutable';
import {
  ProductDTO,
  ProductMapper,
  ProductModel,
  StoreDocumentDTO,
  StoreDocumentItemDTO,
} from '../internal';
import {WithoutRecursion} from '../type';
import {IStoreDocumentItemConfiguredPrice} from '../dto/StoreDocumentItemDTO';

export interface IStoreDocumentItemModelReturnType {
  uuid: string;
  item_cell_identifier: string;
  item_product_amount: number;
  item_product_returned_amount: number;
  item_price: string;
  item_base_price: string;
  created_at: string;
  updated_at: string;
  item_configured_price: IStoreDocumentItemConfiguredPrice;
  product: ProductModel | ProductDTO;
  store_document: StoreDocumentDTO;
  item_price_currency?: string;
}

export class StoreDocumentItemModel extends Record<IStoreDocumentItemModelReturnType>(
  {
    uuid: '',
    item_cell_identifier: '',
    item_product_amount: 0,
    item_product_returned_amount: 0,
    item_price: '',
    item_base_price: '',
    created_at: '',
    updated_at: '',
    item_configured_price: {
      product: [],
      calculated: [],
    },
    product: {} as ProductModel,
    store_document: {} as StoreDocumentDTO,
    item_price_currency: '₴',
  },
) {
  constructor(
    props: StoreDocumentItemDTO = {} as StoreDocumentItemDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IStoreDocumentItemModelReturnType = {
      ...props,
      product:
        withoutRecursion.indexOf(WithoutRecursion.product) !== -1
          ? (props?.product as ProductDTO)
          : ProductMapper.toProductModel(props?.product, [
              WithoutRecursion.storeDocument,
              ...withoutRecursion,
            ]),
    };

    super(options);
  }

  get productModel(): ProductModel {
    return this.product instanceof ProductModel
      ? this.product
      : ProductMapper.toProductModel(this.product);
  }
}
