import React, {MutableRefObject, useEffect, useRef, useState} from 'react';
import {
  Col,
  Form,
  Input,
  Row,
  Tooltip,
  TreeSelect,
  InputNumber,
  TreeSelectProps,
} from 'antd';
import {FormSwitch} from '../../../components/lib/DataDisplay';
import {isListToArray, listToArray} from '../../../services/helpers';
import {LoadingOutlined, WarningOutlined} from '@ant-design/icons';
import styled, {css} from 'styled-components';
import {StyledTitle} from '../../../components/lib/Styled';
import {useTranslation} from 'react-i18next';
import {
  IUseStatePriceMarginListReturnType,
  IUseStateProductCategoryListReturnType,
  useStoredCompanies,
} from '../../../hooks';
import {useDefaultForm} from '../../../contex';
import {List} from 'immutable';
import {
  PriceMarginModel,
  ProductCategoryFormDTO,
  ProductFormDTO,
} from '../../../struture';
import {ProductBarCodeField} from './ProductBarCodeField';

export interface IProductFieldsProps
  extends Pick<
      IUseStateProductCategoryListReturnType,
      'categoryList' | 'handleSearchProductCategories'
    >,
    Pick<IUseStatePriceMarginListReturnType, 'priceMarginList'>,
    Pick<TreeSelectProps, 'treeData'> {
  loading: boolean;
  disabledCategory: boolean;
  editMode: boolean;
  categoryListLoading: boolean;
  priceMarginListLoading: boolean;
  autoFocusTitle?: boolean;

  productEditRef?: MutableRefObject<ProductFormDTO>;
}

const StyledPriceMarginsContainer = styled(Row)`
  position: relative;

  border: 1px solid rgb(225, 229, 231);
  border-radius: 7px;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  padding: 15px;
`;

const Title = styled(StyledTitle)`
  position: absolute;
  top: -12px;
  background-color: ${({theme}) => theme.background.primary};
  padding-left: 5px;
  padding-right: 5px;
`;

const StyledWarningOutlined = styled(WarningOutlined)`
  color: ${({theme}) => theme.colors.error};
  font-size: 18px;
`;

const StyledPriceMarginCol = styled(Col)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const StyledPriceMarginFormItem = styled(Form.Item)`
  flex: 1;
`;

const StyledTreeSelect = styled(TreeSelect)<{disabled: boolean}>`
  ${({disabled}) =>
    !disabled &&
    css`
      & * > .ant-select-selection-placeholder {
        color: rgba(0, 0, 0, 0.88) !important;
      }
    `}
}
`;

const FullWidthInputNumber = styled(InputNumber)`
  width: 100%;
`;

export function ProductFields({
  loading: initLoading,
  editMode,
  disabledCategory,

  categoryList,
  categoryListLoading,
  handleSearchProductCategories,

  priceMarginList,
  priceMarginListLoading,

  autoFocusTitle,
  productEditRef,

  treeData,
}: IProductFieldsProps): React.JSX.Element {
  const {t} = useTranslation();
  const {
    loadingSubmit,
    formData,
    handlerUpdateFormState,
    getFieldValue,
    setFieldsValue,
  } = useDefaultForm();
  const {defaultCompany} = useStoredCompanies();

  const once = useRef(false);
  const titleRef = useRef<any>(null);

  const [loading, setLoading] = useState(initLoading);
  const [sortedPriceMarginList, setSortedPriceMarginList] =
    useState<List<PriceMarginModel> | null>(null);

  useEffect(() => {
    if (List.isList(priceMarginList) && !once?.current) {
      once.current = true;
      setLoading(true);

      if (Array.isArray(formData?.prices) && formData?.prices.length) {
        const pricesUuid = formData?.prices?.map(({price_uuid}) => price_uuid);

        const priceMarginListUuid = listToArray(
          priceMarginList.map(({uuid}) => uuid),
        );

        const diffUuids = priceMarginListUuid.filter(
          (x: string) => !pricesUuid.includes(x),
        );

        const updatedPricesUuid = [...pricesUuid, ...diffUuids];

        const sortedPriceMarginList = priceMarginList.sort(
          (a, b) =>
            updatedPricesUuid.indexOf(a?.uuid) -
            updatedPricesUuid.indexOf(b?.uuid),
        );

        setSortedPriceMarginList(sortedPriceMarginList);
      } else {
        const prices = priceMarginList.map(({uuid}) => ({
          price_uuid: uuid,
          price_value: '0.00',
        }));

        handlerUpdateFormState({prices: isListToArray(prices)});

        setSortedPriceMarginList(priceMarginList);
      }
      setLoading(false);
    }
  }, [formData?.prices, handlerUpdateFormState, priceMarginList]);

  useEffect(() => {
    if (formData?.category_uuid instanceof ProductCategoryFormDTO) {
      handlerUpdateFormState({category_uuid: formData?.category_uuid?.uuid});
    }
  }, [editMode, formData?.category_uuid, handlerUpdateFormState]);

  useEffect(() => {
    if (titleRef?.current && autoFocusTitle) {
      setTimeout(() => {
        titleRef?.current?.focus();
      }, 300);
    }
  }, [autoFocusTitle]);

  useEffect(() => {
    if (productEditRef?.current) {
      const {errorFields, ...rest}: any = formData;

      productEditRef.current = rest;
    }
  }, [productEditRef, formData]);

  return (
    <Row gutter={12}>
      <Col span={24}>
        <Form.Item
          label={t('Category')}
          name="category_uuid"
          tooltip={t('Category')}
          rules={[
            {
              required: true,
              message: t('Category must be specified.'),
            },
          ]}>
          <StyledTreeSelect
            allowClear
            showSearch
            filterTreeNode={false}
            treeDefaultExpandAll
            popupClassName="parent-node"
            dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
            loading={loading || categoryListLoading}
            disabled={loadingSubmit || loading || disabledCategory}
            onSearch={(keywords) => handleSearchProductCategories({keywords})}
            onSelect={() => handleSearchProductCategories({keywords: ''})}
            placeholder={t('Select a category')}
            treeData={treeData}
            onChange={(category_uuid) =>
              handlerUpdateFormState({category_uuid})
            }
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          label={t('name-s')}
          name="title"
          rules={[
            {
              required: true,
              message: t('Title must be specified.'),
            },
          ]}>
          <Input
            ref={titleRef}
            disabled={loading || loadingSubmit}
            placeholder={t('Enter name')}
            onChange={(e) => handlerUpdateFormState({title: e?.target?.value})}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item label={t('Note')} name="description">
          <Input.TextArea
            allowClear
            autoSize={{minRows: 2, maxRows: 5}}
            disabled={loading || loadingSubmit}
            placeholder={t('Enter your note')}
            onChange={(e) =>
              handlerUpdateFormState({description: e?.target?.value})
            }
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item label={t('Code')} name="code">
          <Input
            disabled={loading || loadingSubmit}
            placeholder={t('Enter code')}
            onChange={(e) => handlerUpdateFormState({code: e?.target?.value})}
          />
        </Form.Item>
      </Col>

      <ProductBarCodeField loading={loading} editMode={editMode} />

      <Col span={24}>
        <Form.Item label={t('Article')} name="vendor_code">
          <Input
            disabled={loading || loadingSubmit}
            placeholder={t('Enter article')}
            onChange={(e) =>
              handlerUpdateFormState({vendor_code: e?.target?.value})
            }
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item label={t('Minimum balance')} name="min_amount">
          <FullWidthInputNumber
            disabled={loading || loadingSubmit}
            placeholder={t('Enter a minimum balance')}
            onChange={(min_amount) => handlerUpdateFormState({min_amount})}
          />
        </Form.Item>
      </Col>

      <StyledPriceMarginsContainer>
        <Title>{`${t('Sale prices')}:`}</Title>

        {loading || priceMarginListLoading ? (
          <LoadingOutlined />
        ) : (
          sortedPriceMarginList?.map(({uuid, price_name}, index) => {
            return (
              <React.Fragment key={uuid}>
                <StyledPriceMarginCol span={24}>
                  <StyledPriceMarginFormItem
                    label={price_name}
                    name={['prices', index, 'price_value']}
                    rules={[
                      () => ({
                        validator(_, value) {
                          const price = Number(value);

                          if (Number.isNaN(price)) {
                            return Promise.reject(
                              new Error(t('Price must be number.')),
                            );
                          }

                          return Promise.resolve();
                        },
                      }),
                    ]}>
                    <Input
                      onFocus={(e) => e?.target?.select()}
                      addonBefore={defaultCompany?.currency_symbol}
                      disabled={loading || loadingSubmit}
                      placeholder="0.00"
                      onChange={(e) => {
                        const prices = formData?.prices;

                        const head = prices?.slice(0, index);
                        const tail = prices?.slice(index + 1);
                        const price = prices[index];

                        handlerUpdateFormState({
                          prices: [
                            ...head,
                            {
                              ...price,
                              price_uuid: uuid,
                              price_value: e?.target?.value,
                            },
                            ...tail,
                          ],
                        });
                      }}
                    />
                  </StyledPriceMarginFormItem>
                  {formData?.product_max_income_price >
                  Number(formData?.prices[index]?.price_value || 0) ? (
                    <Tooltip
                      title={t(
                        'The sale price is lower than the purchase price of one of the lots. We recommend increase the price.',
                      )}>
                      <StyledWarningOutlined />
                    </Tooltip>
                  ) : null}
                </StyledPriceMarginCol>
              </React.Fragment>
            );
          })
        )}
      </StyledPriceMarginsContainer>

      {formData?.show_switches && formData?.show_price_tag ? (
        <FormSwitch
          span={24}
          loading={loadingSubmit || loading}
          disabled={loadingSubmit}
          name="print_price_tag"
          getFieldValue={getFieldValue}
          setFieldsValue={setFieldsValue}
          label={null}
          title={t('Print a price tag')}
          onChange={(print_price_tag) =>
            handlerUpdateFormState({
              print_price_tag,
              add_another_product: false,
            })
          }
        />
      ) : null}

      {!editMode && formData?.show_switches ? (
        <FormSwitch
          span={24}
          loading={loadingSubmit || loading}
          disabled={loadingSubmit || formData?.print_price_tag}
          name="add_another_product"
          getFieldValue={getFieldValue}
          setFieldsValue={setFieldsValue}
          label={null}
          title={t('Add another product')}
          onChange={(add_another_product) =>
            handlerUpdateFormState({add_another_product})
          }
          value={formData?.add_another_product}
        />
      ) : null}
    </Row>
  );
}
