import {List} from 'immutable';
import {
  PriceTagDTO,
  IPriceTagDTOProps,
  PriceTagModel,
  PriceTagListModel,
  PriceTagFormDTO,
  IPriceTagPrintFormDTOProps,
  PriceTagPrintFormDTO,
  IProductsListStatsProps,
} from '../internal';
import {listToArray} from '../../services/helpers';
import {WithoutRecursion} from '../type';

export interface IPriceTagListDTO {
  items: PriceTagDTO[];
  total: number;
  keywords?: string;
  page?: number;
  stats?: IProductsListStatsProps;
}

export class PriceTagMapper {
  public static toPriceTagDTO(
    priceTag: PriceTagModel | IPriceTagDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): PriceTagDTO {
    return new PriceTagDTO(priceTag, [
      WithoutRecursion.priceTag,
      ...withoutRecursion,
    ]);
  }

  public static toPriceTagListDTO(
    items: List<PriceTagModel> | IPriceTagDTOProps[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
    stats?: IProductsListStatsProps,
  ): IPriceTagListDTO {
    const priceTagList = List.isList(items)
      ? listToArray<PriceTagModel>(items)
      : items;

    return {
      items: priceTagList?.map((priceTag) =>
        PriceTagMapper.toPriceTagDTO(priceTag, [
          WithoutRecursion.priceTag,
          ...withoutRecursion,
        ]),
      ),
      total,
      stats,
    };
  }

  public static toPriceTagModel(
    priceTagDTO: PriceTagDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): PriceTagModel {
    return new PriceTagModel(priceTagDTO, [
      WithoutRecursion.priceTag,
      ...withoutRecursion,
    ]);
  }

  public static toPriceTagListModel(
    priceTagDTOs: PriceTagDTO[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
    stats?: IProductsListStatsProps,
  ): PriceTagListModel {
    return new PriceTagListModel({items: priceTagDTOs, total, stats}, [
      WithoutRecursion.priceTag,
      ...withoutRecursion,
    ]);
  }

  public static toPriceTagFormDTO(
    priceTagModel: List<PriceTagModel>,
    total: number,
  ): PriceTagFormDTO {
    return new PriceTagFormDTO(priceTagModel, total);
  }

  public static toPriceTagPrintFormDTO(
    value: IPriceTagPrintFormDTOProps,
  ): PriceTagPrintFormDTO {
    return new PriceTagPrintFormDTO(value);
  }
}
