import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {Col, Form, Input, Row, TreeSelect, TreeSelectProps} from 'antd';
import {useTranslation} from 'react-i18next';
import {useDefaultForm} from '../../../contex';
import {IUseStateProductCategoryListReturnType} from '../../../hooks';
import {listToArray} from '../../../services/helpers';
import {ProductCategoryFormDTO, ProductCategoryModel} from '../../../struture';
import {ProductCategoryUnitPackagingField} from './ProductCategoryUnitPackagingField';
import {StyledDescription} from '../../../components/lib/Styled';
import styled, {css} from 'styled-components';
import {List} from 'immutable';

import './ProductCategoryFields.less';

export interface IProductCategoryFieldsProps
  extends Pick<
      IUseStateProductCategoryListReturnType,
      'categoryList' | 'handleSearchProductCategories'
    >,
    Pick<TreeSelectProps, 'treeData'> {
  loading: boolean;
  editMode: boolean;
  categoryListLoading: boolean;
  disabledCategoryField: boolean;
  currentCategoryUuid: string;
  categoryEditRef?: MutableRefObject<ProductCategoryFormDTO>;
}

const StyledTreeSelect = styled(TreeSelect)<{disabled: boolean}>`
  ${({disabled}) =>
    !disabled &&
    css`
      & * > .ant-select-selection-placeholder {
        color: rgba(0, 0, 0, 0.88) !important;
      }
    `}
}
`;

export function ProductCategoryFields({
  loading,
  editMode,
  currentCategoryUuid,
  disabledCategoryField,

  categoryList,
  categoryListLoading,
  handleSearchProductCategories,

  categoryEditRef,

  treeData,
}: IProductCategoryFieldsProps): React.JSX.Element {
  const {t} = useTranslation();
  const {loadingSubmit, handlerUpdateFormState, formData} = useDefaultForm();

  const [categories, setCategories] =
    useState<List<ProductCategoryModel> | null>(null);

  const onceParentNode = useRef(false);

  useEffect(() => {
    if (
      formData?.parent_category_uuid instanceof ProductCategoryModel &&
      !onceParentNode?.current
    ) {
      onceParentNode.current = true;
      handlerUpdateFormState({
        parent_category_uuid: formData?.parent_category_uuid?.uuid,
      });
    }
  }, [formData?.parent_category_uuid, handlerUpdateFormState]);

  const deepFilterCategories = useCallback(
    (categoryList: any) => {
      if (categoryList && categoryList?.length) {
        return categoryList
          ?.map((value: ProductCategoryModel) => {
            if (value?.uuid === currentCategoryUuid) {
              return null;
            }

            const node = listToArray(value?.children as any);

            if (Array.isArray(node) && node?.length) {
              return value?.update('children', () =>
                deepFilterCategories(node).filter(Boolean),
              );
            }

            return value;
          })
          .filter(Boolean);
      } else {
        return categoryList;
      }
    },
    [currentCategoryUuid],
  );

  useEffect(() => {
    if (categoryList?.size) {
      const categories = deepFilterCategories(listToArray(categoryList));

      setCategories(categories);
    }
  }, [categoryList, deepFilterCategories]);

  useEffect(() => {
    if (categoryEditRef?.current) {
      const {errorFields, ...rest}: any = formData;

      categoryEditRef.current = rest;
    }
  }, [categoryEditRef, formData]);

  return (
    <Row gutter={12}>
      <Col span={24}>
        <Form.Item
          label={t('Title')}
          name="title"
          rules={[
            {
              required: true,
              message: t('Title must be specified.'),
            },
          ]}>
          <Input
            disabled={loading || loadingSubmit}
            placeholder={t('Enter the title')}
            onChange={(e) => handlerUpdateFormState({title: e?.target?.value})}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item label={t('Parent node')} name="parent_category_uuid">
          <StyledTreeSelect
            showSearch
            filterTreeNode={false}
            treeDefaultExpandAll
            allowClear
            popupClassName="parent-node"
            dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
            loading={loading || categoryListLoading}
            disabled={loadingSubmit || loading || disabledCategoryField}
            onSearch={(keywords) => handleSearchProductCategories({keywords})}
            onSelect={() => handleSearchProductCategories({keywords: ''})}
            placeholder={t('Select parent category')}
            treeData={treeData}
            onChange={(parent_category_uuid) =>
              handlerUpdateFormState({parent_category_uuid})
            }
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          label={t('The basic unit of measurement')}
          name="base_unit"
          extra={
            <StyledDescription style={{whiteSpace: 'unset'}}>
              {t(
                'A unit of measurement that is assigned to all goods included in this category',
              )}
            </StyledDescription>
          }>
          <Input
            disabled={loading || loadingSubmit}
            placeholder={t('Enter the basic unit of measurement')}
            onChange={(e) =>
              handlerUpdateFormState({base_unit: e?.target?.value})
            }
          />
        </Form.Item>
      </Col>

      <ProductCategoryUnitPackagingField editMode={editMode} />

      <Col span={24}>
        <Form.Item label={t('Note')} name="description">
          <Input.TextArea
            allowClear
            autoSize={{minRows: 2, maxRows: 5}}
            disabled={loading || loadingSubmit}
            placeholder={t('Enter your note')}
            onChange={(e) =>
              handlerUpdateFormState({description: e?.target?.value})
            }
          />
        </Form.Item>
      </Col>
    </Row>
  );
}
