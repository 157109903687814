import {STORE_LIST} from '../constants';
import {StoreListModel, StoreModel} from '../../struture';
import {ApiError} from '../../services/types';
import {Dispatch} from 'redux';

export interface ISetStoreListParam {
  type: STORE_LIST;
  storeList: StoreListModel;
}

export interface IAddStoreToListParam {
  type: STORE_LIST;
  store: StoreModel;
}

export interface IUpdateStoreFromListParam {
  type: STORE_LIST;
  store: StoreModel;
}

export interface IDeleteStoreFromListParam {
  type: STORE_LIST;
  storeUuid: string;
}

export interface IErrorStoreListParam {
  type: STORE_LIST;
  error: ApiError | undefined;
}

export function setStoreList(
  storeList: StoreListModel,
): (dispatch: Dispatch) => Promise<ISetStoreListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STORE_LIST.SET_STORE_LIST,
      storeList,
    });
  };
}

export function loadMoreStoreList(
  storeList: StoreListModel,
): (dispatch: Dispatch) => Promise<ISetStoreListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STORE_LIST.LOAD_MORE_STORE_LIST,
      storeList,
    });
  };
}

export function addStoreToList(
  store: StoreModel,
): (dispatch: Dispatch) => Promise<IAddStoreToListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STORE_LIST.ADD_STORE,
      store,
    });
  };
}

export function updateStoreFromList(
  store: StoreModel,
): (dispatch: Dispatch) => Promise<IUpdateStoreFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STORE_LIST.UPDATE_STORE,
      store,
    });
  };
}

export function deleteStoreFromList(
  storeUuid: string,
): (dispatch: Dispatch) => Promise<IDeleteStoreFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STORE_LIST.DELETE_STORE,
      storeUuid,
    });
  };
}

export function errorStoreInList(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorStoreListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STORE_LIST.ERROR_STORE_LIST,
      error,
    });
  };
}
