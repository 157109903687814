import {APPROVE_ENTER_PAGE_STATE} from '../constants';
import {IApproveEnterState, approveEnterInitialState} from '../actions';

interface SetAction {
  type: APPROVE_ENTER_PAGE_STATE.SET_STATE;
  state: IApproveEnterState;
}

interface UpdateService {
  type: APPROVE_ENTER_PAGE_STATE.UPDATE_STATE;
  state: Partial<IApproveEnterState>;
}

interface ResetAction {
  type: APPROVE_ENTER_PAGE_STATE.RESET_STATE;
  state: IApproveEnterState;
}

interface Handlers {
  [APPROVE_ENTER_PAGE_STATE.SET_STATE]: (
    state: IApproveEnterState,
    action: SetAction,
  ) => IApproveEnterState;

  [APPROVE_ENTER_PAGE_STATE.RESET_STATE]: (
    state: IApproveEnterState,
    action: ResetAction,
  ) => IApproveEnterState;

  [APPROVE_ENTER_PAGE_STATE.UPDATE_STATE]: (
    state: IApproveEnterState,
    action: UpdateService,
  ) => IApproveEnterState;

  DEFAULT: (state: IApproveEnterState) => IApproveEnterState;
}

const initState: IApproveEnterState = approveEnterInitialState;

const handlers: Handlers = {
  [APPROVE_ENTER_PAGE_STATE.SET_STATE]: (state, {state: value}) => ({
    ...state,
    ...value,
    loading: false,
  }),

  [APPROVE_ENTER_PAGE_STATE.RESET_STATE]: (state, {state: value}) => ({
    ...state,
    ...value,
  }),

  [APPROVE_ENTER_PAGE_STATE.UPDATE_STATE]: (state, {state: value}) => ({
    ...state,
    ...value,
  }),

  DEFAULT: (state: IApproveEnterState) => state,
};

export default function Service(
  state: any = initState,
  action: any,
): IApproveEnterState {
  const handler =
    handlers[action.type as APPROVE_ENTER_PAGE_STATE] || handlers.DEFAULT;
  return handler(state, action);
}
