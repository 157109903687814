import {toDateByFormat} from '../../services/helpers';

export interface INotificationDTOProps {
  uuid: string;
  date: string;
  message: string;
  title: string;
  is_read: number;
  created_at: string;
  updated_at: string;
}

export enum NotificationStatuses {
  Unread = 0,
  Read = 1,
}

export class NotificationDTO {
  public uuid: string;

  public date: string;

  public message: string;

  public title: string;

  public is_read: NotificationStatuses;

  public created_at: string;

  public updated_at: string;

  constructor(props: INotificationDTOProps) {
    this.uuid = props?.uuid || '';
    this.date = props?.date
      ? toDateByFormat(props?.date, 'YYYY-MM-DD hh:mm:ss')
      : '';
    this.message = props?.message || '';
    this.title = props?.title || '';
    this.is_read = props?.is_read || NotificationStatuses.Unread;
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
  }
}
