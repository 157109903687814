/**
 * @desc Повернення першого елемента з масиву
 * @param {Array} array
 * */

export function returnFirstItem<T extends {uuid: string}>(
  array: T[],
): string | number {
  if (array && Array.isArray(array) && array?.length > 0) {
    return array[0]?.uuid;
  }
  return 0;
}

/**
 * @desc Перевірка чи є в масиві елементи
 * @param {Array} array
 * @return {Number}
 * */
export function emptyArray<T extends []>(array: T): number {
  return array?.length > 0 ? 1 : 0;
}

/**
 * @desc Повернення довжини масиву
 * @param {Array} array
 * @return {Boolean}
 * */
export function checkArrayLength<T extends []>(array: T): boolean {
  return array?.length === 0;
}

/**
 * @desc Додавання та видалення з масиву по id
 * @param {Array} checked
 * @param {String} current
 * @return {Array}
 * */

export function addOrRemoveFromArray<T extends []>(
  checked: T,
  current: never,
): any[] {
  const selectedIndexId = checked.indexOf(current);

  let newSelected = [];

  if (selectedIndexId === -1) {
    newSelected = [...checked, current];
  } else if (selectedIndexId === 0) {
    newSelected = [...checked.slice(1)];
  } else if (selectedIndexId === checked.length - 1) {
    newSelected = [...checked.slice(0, -1)];
  } else if (selectedIndexId > 0) {
    newSelected = [
      ...checked.slice(0, selectedIndexId),
      ...checked.slice(selectedIndexId + 1),
    ];
  }

  return newSelected;
}

/**
 * @desc Додавання та видалення з масиву по полю об'єкта
 * @param {Array} checked
 * @param {Object} current
 * @return {Array}
 * */

export function addOrRemoveFromArrayObject<T extends []>(
  checked: T,
  current: {id: string},
): any[] {
  const selectedIndexId = checked.findIndex((i: any) => i?.id === current?.id);

  let newSelected = [];

  if (selectedIndexId === -1) {
    newSelected = [...checked, current];
  } else if (selectedIndexId === 0) {
    newSelected = [...checked.slice(1)];
  } else if (selectedIndexId === checked.length - 1) {
    newSelected = [...checked.slice(0, -1)];
  } else if (selectedIndexId > 0) {
    newSelected = [
      ...checked.slice(0, selectedIndexId),
      ...checked.slice(selectedIndexId + 1),
    ];
  }

  return newSelected;
}

/**
 * @desc Перевірка масива та повернення першого елементу
 * @param {Array} array
 * @return {Object}
 * */
export function isArrayGetFirstItem<T>(array: T[] | T): T {
  if (Array.isArray(array) && array.length > 0) {
    return array[0];
  }

  return array as T;
}

/**
 * @desc Отримання довжини масиву
 * @param {Array} array
 * @return {Number}
 * */
export function getArrayLength<T>(array: T): number {
  if (Array.isArray(array)) {
    return array.length;
  }
}

/**
 * @desc Зміна положення елементів в масиві
 * @param {Array} array
 * @param {Number} from
 * @param {Number} to
 * @return {array}
 * */
export const moveInArray = <T>(array: T[], from: number, to: number): T[] => {
  const [remove] = array.splice(from, 1);
  array.splice(to, 0, remove);

  return array;
};
