export interface ISystemEventVariableDTOProps {
  variable_name: string;
  variable_comment: string;
}

export class SystemEventVariableDTO {
  public variable_name: string;

  public variable_comment: string;

  constructor(props?: ISystemEventVariableDTOProps) {
    this.variable_name = props?.variable_name || '';
    this.variable_comment = props?.variable_comment || '';
  }
}
