import React, {useState, useEffect, useRef, useCallback} from 'react';
import {
  ifElse,
  isFunction,
  inc,
  decr,
  sub,
  getArrayLength,
} from '../../../services/helpers';
import {ILiteralObj} from '../../../services/types';
import {ENTER_KEYS} from '../../../services/const';

export default SelectSearch;

export interface ISelectSearchProps {
  searchInput: React.ReactElement;
  searchResultDisplay: React.ReactElement;
  selectCallback: (value: any) => void;
  limit?: number;
  isQueriesHidden?: boolean;
  handleSearch: (value: any) => Promise<any>;
}

function SelectSearch({
  searchInput,
  searchResultDisplay,
  selectCallback,
  limit = 5,
  isQueriesHidden,
  handleSearch,
}: ISelectSearchProps) {
  const [queries, setQueries] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectSuggestion, setSelectSuggestion] = useState<number>(0);

  const searchResultDisplayRef = useRef<any>(null);
  const searchInputRef = useRef<any>(null);

  useEffect(() => {
    ifElse(
      getArrayLength(queries) > 0,
      () => setShowResults(true),
      () => setShowResults(false),
    );
  }, [queries]);

  useEffect(() => {
    const clickEventCallback = (e: any) => {
      if (
        !!searchInputRef.current &&
        !(searchInputRef.current.input === e.target) &&
        !!searchResultDisplayRef.current &&
        !searchResultDisplayRef.current.contains(e.target)
      ) {
        setShowResults(false);
      }
    };

    if (showResults) {
      document.addEventListener('mousedown', clickEventCallback);
    }
    return () => {
      document.removeEventListener('mousedown', clickEventCallback);
    };
  }, [showResults]);

  const handleOnLoadSearch = async ({searchInputText}: any) => {
    setLoading(true);
    const searchItem = await handleSearch({
      keywords: searchInputText,
      offset: 1,
      limit,
    } as any);

    setQueries(searchItem);
    setLoading(false);
  };

  const handleShowResultInFocus = () => {
    if (getArrayLength(queries) > 0) {
      setShowResults(true);
    }
  };

  const handleArrowUpPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>, lengthOfQueries: number) => {
      e.preventDefault();
      setSelectSuggestion((prevState) => {
        if (prevState === 0) {
          return sub(lengthOfQueries, 1);
        }
        return decr(prevState);
      });
    },
    [],
  );

  const handleArrowDownPress = useCallback(
    (
      e: React.KeyboardEvent<HTMLInputElement>,
      lengthOfQueries: number,
    ): void => {
      e.preventDefault();
      setSelectSuggestion((prevState) => {
        if (prevState === sub(lengthOfQueries, 1)) {
          return 0;
        }
        return inc(prevState);
      });
    },
    [],
  );

  const handleEscapePress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>): void => {
      e.preventDefault();
      setSearchValue('');
      setShowResults(false);
      setSelectSuggestion(-1);
    },
    [],
  );

  const handleEnterPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>, queries: any[]): void => {
      e.preventDefault();
      const clientUuid =
        queries[selectSuggestion] && queries[selectSuggestion].uuid;

      if (clientUuid) {
        selectCallback({uuid: clientUuid});
        handleEscapePress(e);
      }
    },
    [handleEscapePress, selectCallback, selectSuggestion],
  );

  const handleOnInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>, queries: any): void => {
      const lengthOfQueries = getArrayLength(queries);

      if (e.key === 'ArrowUp') {
        handleArrowUpPress(e, lengthOfQueries);
      }

      if (e.key === 'ArrowDown') {
        handleArrowDownPress(e, lengthOfQueries);
      }

      if (ENTER_KEYS.includes(e.key)) {
        handleEnterPress(e, queries);
      }

      if (e.key === 'Escape') {
        handleEscapePress(e);
      }
    },
    [
      handleArrowDownPress,
      handleArrowUpPress,
      handleEnterPress,
      handleEscapePress,
    ],
  );

  return (
    <div className="SelectSearch">
      <div
        className={`SelectSearch_content ${
          !isQueriesHidden
            ? 'content-visible-on-search'
            : 'content-hidden-on-search'
        }`}>
        {React.cloneElement(searchInput, {
          ref: searchInputRef,
          onChange: ({target}: any) => {
            if (target?.value) {
              handleOnLoadSearch({searchInputText: target.value});
            } else {
              setQueries([]);
            }
            setSelectSuggestion(0);
            setSearchValue(target?.value);
          },
          loading,
          onFocus: handleShowResultInFocus,
          handleKeyDown: (e: any) => handleOnInputKeyDown(e, queries),
          // value: searchValue,
        })}
        {showResults &&
          React.cloneElement(searchResultDisplay, {
            ref: searchResultDisplayRef,
            suggestions: queries,
            selectedUuid:
              queries &&
              queries[selectSuggestion] &&
              queries[selectSuggestion].uuid,
            onSelectSuggestion: (value: ILiteralObj) => {
              setShowResults(false);
              if (isFunction(selectCallback)) {
                selectCallback(value);
              }
            },
          })}
      </div>
    </div>
  );
}
