import React from 'react';
import {useTranslation} from 'react-i18next';
import {len, eq} from '../../../services/helpers';
import {IConfirmActionButtonProps} from '../../../components/lib/General';
import {TextFormat} from '../../../components/lib/Format';
import {Template} from '../../../services/models';
import {useDelete} from '../../../components/lib/libV2/hooks';
import {DeleteButton} from '../../../components/lib/DataDisplay';

export interface ITemplateDeleteButtonProps
  extends Omit<IConfirmActionButtonProps, 'children'> {
  template: Template[];
  onSuccess: (ids: string[]) => Promise<void>;
  onError?: () => void;
  children?: React.ReactNode;
}

export default function TemplateDeleteButton({
  children,
  template = [],
  onSuccess,
  onError,
  title,
  ...rest
}: ITemplateDeleteButtonProps): JSX.Element {
  const {t} = useTranslation();

  const {handleDelete, loading} = useDelete({
    onSuccess,
    entity: template,
    notifyTitle: 'Template',
    identifier: 'template_uuid',
  });

  return (
    <DeleteButton
      title={title}
      tooltipTitle={t('Delete Template')}
      confirmTitle={
        <TextFormat breakWord>
          {t('Are you sure you want to delete')} <b>{len(template)}</b>
          {` ${eq(len(template), 1) ? t('template') : t('templates')}`}?
        </TextFormat>
      }
      onConfirm={handleDelete}
      loading={loading}
      cancelButtonText={t('Cancel')}
      {...rest}
    />
  );
}
