import {Record} from 'immutable';
import {StatusDTO, StatusMapper, StatusModel} from '../internal';
import {WithoutRecursion} from '../type';
import {ScheduleCalendarLatestStatusDTO} from '../dto/ScheduleCalendarLatestStatusDTO';

export interface IScheduleCalendarLatestStatusModelReturnType {
  reason: string;
  status_color: string;
  status_text: string;
  created_at: string;
  custom_status: StatusDTO | StatusModel;
}

export class ScheduleCalendarLatestStatusModel extends Record<IScheduleCalendarLatestStatusModelReturnType>(
  {
    reason: '',
    status_color: '',
    status_text: '',
    created_at: '',
    custom_status: {} as StatusDTO,
  },
) {
  constructor(
    props: ScheduleCalendarLatestStatusDTO = {} as ScheduleCalendarLatestStatusDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IScheduleCalendarLatestStatusModelReturnType = {
      ...props,

      custom_status:
        withoutRecursion.indexOf(WithoutRecursion.status) !== -1
          ? props?.custom_status
          : StatusMapper.toStatusModel(props?.custom_status, [
              WithoutRecursion.status,
              ...withoutRecursion,
            ]),
    };

    super(options);
  }

  get customStatusModel(): StatusModel {
    return this.custom_status instanceof StatusModel
      ? this.custom_status
      : StatusMapper.toStatusModel(this.custom_status);
  }
}
