import {apiDelete, apiGet, apiPost, apiPut} from '../core/api';
import {contentApiUrl} from '../const';
import {head, toDateByFormat, dateToIsoString} from '../helpers';
import {ApiAnswer, IListSearchProps} from '../types';
import {
  RewardDTO,
  RewardFormDTO,
  RewardMapper,
  IRewardDTOProps,
  IEmployeeDTOProps,
  IRewardListDTO,
  IRewardStatsDTOProps,
  RewardStatsDTO,
  RewardPaymentFormDTO,
  PaymentDTO,
  PaymentMapper,
  ScheduleDocumentType,
  RewardDocumentType,
} from '../../struture';
import {IPaymentAnswer} from './companyPayments';

export const REWARD_INITIAL_PARAM = new RewardFormDTO();

const {uuid: cUuid, ...REWARD_INITIAL_PARAM_REST} = new RewardDTO(
  {} as IRewardDTOProps,
);

export const REWARD_REQUIRED_FIELD = {
  ...REWARD_INITIAL_PARAM_REST,
};

export interface IRewardAnswer extends ApiAnswer {
  reward: IRewardDTOProps;
  stats: IRewardStatsDTOProps;
  employee: IEmployeeDTOProps;
}

export interface IRewardPaymentAnswer extends IPaymentAnswer {
  stats: IRewardStatsDTOProps;
  employee: IEmployeeDTOProps;
}

export interface IRewardListAnswer extends ApiAnswer {
  rewards: IRewardDTOProps[];
  stats: IRewardStatsDTOProps;
  employee: IEmployeeDTOProps;
}

export interface IRewardListProps extends IListSearchProps {
  employeeUuid: string;
  month: number;
  year: number;
}

export interface IRewardReturnType {
  reward: RewardDTO;
  stats: RewardStatsDTO;
}

export async function getRewardList({
  offset = 0,
  limit = 10,
  keywords,
  employeeUuid,
  month = Number(toDateByFormat(new Date(), 'MM')),
  year = Number(toDateByFormat(new Date(), 'YYYY')),
}: IRewardListProps): Promise<IRewardListDTO> {
  const {rewards, employee, stats, total} = await apiGet<
    IListSearchProps & Pick<IRewardListProps, 'year' | 'month'>,
    IRewardListAnswer
  >(`${contentApiUrl}/employees/${employeeUuid}/bonuses`, {
    offset,
    limit,
    keywords,
    month,
    year,
  });

  return RewardMapper.toRewardListDTO(
    rewards,
    total || 0,
    RewardMapper.toRewardStatsDTO(stats?.total, employee, stats?.totalPaid),
  );
}

export async function getRewardById(rewardUuid: string): Promise<RewardDTO> {
  // const {reward} = await apiGet<null, IRewardAnswer>(
  //   `${contentApiUrl}/employee-bonuses/${rewardUuid}`,
  // );

  return RewardMapper.toRewardDTO({} as any);
}

export interface IRewardAdditionalProps {
  employeeUuid: string;
  keywords: string;
  month: number;
  year: number;
}

export async function createRewardAwardRecord(
  {sum, date, comment}: RewardFormDTO,
  {employeeUuid, month, year, keywords}: IRewardAdditionalProps,
): Promise<IRewardReturnType> {
  const queryMonth = month ? `month=${month}&` : '';
  const queryYear = year ? `year=${year}` : '';
  const queryKeywords = keywords ? `&keywords=${keywords}` : '';

  const {reward, stats, employee} = await apiPost<
    Omit<RewardFormDTO, 'uuid'>,
    IRewardAnswer
  >(
    `${contentApiUrl}/employees/${employeeUuid}/premium?${queryMonth}${queryYear}${queryKeywords}`,
    {
      sum,
      date: dateToIsoString(date as any),
      comment,
    },
  );

  return {
    reward: RewardMapper.toRewardDTO(reward),
    stats: RewardMapper.toRewardStatsDTO(
      stats.total,
      employee,
      stats.totalPaid,
      stats.totalAccrued,
    ),
  };
}

export async function createRewardFineRecord(
  {sum, date, comment}: RewardFormDTO,
  {employeeUuid, month, year, keywords}: IRewardAdditionalProps,
): Promise<IRewardReturnType> {
  const queryMonth = month ? `month=${month}&` : '';
  const queryYear = year ? `year=${year}` : '';
  const queryKeywords = keywords ? `&keywords=${keywords}` : '';

  const {reward, stats, employee} = await apiPost<
    Omit<RewardFormDTO, 'uuid'>,
    IRewardAnswer
  >(
    `${contentApiUrl}/employees/${employeeUuid}/penalty?${queryMonth}${queryYear}${queryKeywords}`,
    {
      sum,
      date: dateToIsoString(date as any),
      comment,
    },
  );

  return {
    reward: RewardMapper.toRewardDTO(reward),
    stats: RewardMapper.toRewardStatsDTO(
      stats.total,
      employee,
      stats.totalPaid,
      stats.totalAccrued,
    ),
  };
}

export async function createRewardPayment(
  {
    payment_price,
    payment_cashbox_uuid,
    payment_cashier_uuid,
    payment_comment,
    payment_method,
    payment_group_uuid,
    payment_date,
  }: RewardPaymentFormDTO,
  {employeeUuid, month, year, keywords}: IRewardAdditionalProps,
): Promise<IRewardReturnType['stats']> {
  const queryMonth = month ? `month=${month}&` : '';
  const queryYear = year ? `year=${year}` : '';
  const queryKeywords = keywords ? `&keywords=${keywords}` : '';

  const {payment, stats, employee} = await apiPost<
    Omit<RewardPaymentFormDTO, 'uuid'>,
    IRewardPaymentAnswer
  >(
    `${contentApiUrl}/employees/${employeeUuid}/payments?${queryMonth}${queryYear}${queryKeywords}`,
    {
      payment_price,
      payment_cashbox_uuid,
      payment_cashier_uuid,
      payment_comment,
      payment_method,
      payment_group_uuid,
      payment_date: dateToIsoString(payment_date),
    },
  );

  return RewardMapper.toRewardStatsDTO(
    stats?.total,
    employee,
    stats?.totalPaid,
    stats?.totalAccrued,
  );
}

export async function editReward({
  uuid: reward_uuid,
}: RewardFormDTO): Promise<RewardDTO> {
  const {reward, stats, employee} = await apiPut<
    Partial<RewardFormDTO>,
    IRewardAnswer
  >(`${contentApiUrl}/employee-rewards/${reward_uuid}`, {});

  return RewardMapper.toRewardDTO(reward);
}

export async function deleteReward(
  ids: string[] = [],
  {employeeUuid, month, year, keywords}: IRewardAdditionalProps,
): Promise<IRewardReturnType> {
  const queryMonth = month ? `month=${month}&` : '';
  const queryYear = year ? `year=${year}` : '';
  const queryKeywords = keywords ? `&keywords=${keywords}` : '';

  const {reward, stats, employee} = await apiDelete<
    {ids: string[]},
    IRewardAnswer
  >(
    `${contentApiUrl}/employees/${employeeUuid}/bonuses/${head(
      ids,
    )}?${queryMonth}${queryYear}${queryKeywords}`,
  );

  return {
    reward: RewardMapper.toRewardDTO(reward),
    stats: RewardMapper.toRewardStatsDTO(
      stats.total,
      employee,
      stats.totalPaid,
      stats.totalAccrued,
    ),
  };
}

export async function getRewardEstimatedAmount(
  employeeUuid: string,
): Promise<RewardStatsDTO> {
  const {employee, stats} = await apiGet<null, Omit<IRewardAnswer, 'reward'>>(
    `${contentApiUrl}/employees/${employeeUuid}/accrued-salary`,
  );

  return RewardMapper.toRewardStatsDTO(stats.total, employee, stats.totalPaid);
}

/* ------------------- REWARD DOCUMENTS ------------------- */

export interface IRewardDocumentAnswer extends ApiAnswer {
  html: string;
}

export interface IRewardDocumentProps {
  documentType?: RewardDocumentType;
  year: number;
  month: number;
}

export async function getRewardDocument(
  employeeUuid: string,
  {
    documentType = RewardDocumentType.Rewards,
    month,
    year,
  }: IRewardDocumentProps,
): Promise<string> {
  const {html} = await apiGet<
    Omit<IRewardDocumentProps, 'documentType'>,
    IRewardDocumentAnswer
  >(`${contentApiUrl}/employees/${employeeUuid}/docs/${documentType}`, {
    month,
    year,
  });

  return html;
}
