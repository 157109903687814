import React from 'react';
import {ISegmentedProps, Segmented} from '../Segmented';
export interface TimePickerProps extends Omit<ISegmentedProps, 'ref'> {}

export interface IListSegmentedProps extends Omit<TimePickerProps, 'size'> {
  label?: string;
  tooltipTitle?: string;
  loading?: boolean;
  fullWidth?: boolean;
}

export function ListSegmented({
  label,
  tooltipTitle,
  loading,
  fullWidth,
  ...rest
}: IListSegmentedProps): JSX.Element {
  return <Segmented disabled={loading} {...rest} />;
}
