import {
  DepartmentIcon,
  DepartmentModel,
  IAbonementTimingList,
} from '../internal';
import {head, last} from '../../services/helpers';

export class DepartmentFormDTO {
  public uuid: string;

  public dep_name: string;

  public dep_address: string;

  public dep_description: string;

  public dep_schedule: string;
  public dep_is_default: boolean;
  public dep_colour: string;
  public dep_icon: DepartmentIcon;

  constructor(props?: DepartmentModel) {
    this.uuid = props?.uuid || '';
    this.dep_name = props?.dep_name || '';
    this.dep_address = props?.dep_address || '';
    this.dep_description = props?.dep_description || '';
    this.dep_description = props?.dep_description || '';
    this.dep_colour = props?.dep_colour || '#4f7cac';
    this.dep_is_default = props?.dep_is_default ?? false;
    this.dep_icon = props?.dep_icon || DepartmentIcon.REGULAR;
    this.dep_schedule = DepartmentFormDTO.getTimingList(
      props?.dep_schedules_list,
    );
  }

  static getTimingWeekList(dep_schedule?: IAbonementTimingList): string | void {
    const timingMap = Object.entries(dep_schedule || {});

    if (timingMap.length === 7) {
      const isAllWeek = timingMap
        .map(([_, value]) => [value?.min, value?.max])
        .reduce((acc: any, [min, max]) => {
          acc[min] = (acc[min] || 0) + 1;
          acc[max] = (acc[min] || 0) + 1;

          return acc;
        }, {});

      const isAllWeekMap = Object.entries(isAllWeek);

      if (isAllWeekMap.length === 2) {
        const times = isAllWeekMap.map(([key]) => key);
        const start = head(times);
        const end = last(times);

        return `${start}-${end}`;
      }
    }
  }

  static getTimingList(dep_schedule?: IAbonementTimingList) {
    const timingMap = Object.entries(dep_schedule || {});
    const timingList = DepartmentFormDTO.getTimingWeekList(dep_schedule);

    if (timingList) {
      return timingList;
    } else {
      return timingMap.length
        ? timingMap
            .map(([key, value]) => {
              return `${key}|${value?.min}-${value?.max}`;
            })
            .join(',')
        : '';
    }
  }
}
