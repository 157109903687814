import {
  FinancialInformationDTO,
  IFinancialInformationDTOProps,
  FinancialInformationModel,
  FinancialInformationFromDTO,
} from '../internal';

export class FinancialInformationMapper {
  public static toFinancialInformationDTO(
    operation: IFinancialInformationDTOProps,
  ): FinancialInformationDTO {
    return new FinancialInformationDTO(operation);
  }

  public static toFinancialInformationFormDTO(
    operation: FinancialInformationModel | FinancialInformationDTO,
  ): FinancialInformationFromDTO {
    return new FinancialInformationFromDTO(operation as any);
  }

  public static toFinancialInformationModel(
    operationDTO: FinancialInformationDTO,
  ): FinancialInformationModel {
    return new FinancialInformationModel(operationDTO);
  }
}
