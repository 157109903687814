import React from 'react';
import {isFunction} from '../../../services/helpers';
import {ChildrenMustBeAFunction} from '../../../components/lib/Errors';
import {IDefaultTabPanelProps, TabItem} from '../../../components/lib/General';
import {PriceMarginModel} from '../../../struture';
import {useNavigate} from 'react-router';

export enum PriceMarginTabs {}

export interface IPriceMarginTabPanelProps {
  priceMargin: PriceMarginModel | null;
  children: (value: Pick<IDefaultTabPanelProps, 'tabs'>) => React.ReactNode;
}

export function PriceMarginTabPanel({
  children,
  priceMargin,
}: IPriceMarginTabPanelProps): React.JSX.Element {
  const navigate = useNavigate();

  const priceMarginTabs: TabItem[] = [];

  if (!isFunction(children)) {
    throw new ChildrenMustBeAFunction('LoadingWithEmptyData');
  }

  return <>{children({tabs: priceMarginTabs})}</>;
}
