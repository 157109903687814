import {getArrayLength} from './array';
import {filter, compose, prop, find} from './base';

/**
 * @desc Зміщення
 * @param {Number} offset - зміщення
 * @param {Number} intervals - номер інтервалу
 * @param {Number} width - ширина елементу
 * @return {Number}
 * */
export const getInterval = (
  offset: number,
  intervals: number,
  width: number,
): number => {
  for (let i = 1; i <= intervals; i++) {
    if (offset < (width / intervals) * i) {
      return i;
    }
    if (i === intervals) {
      return i;
    }
  }
};

/**
 * @desc Отримання розміру от фільтрованого масиву
 * @param {Number} array
 * @param {Number} callback - функція для filter
 * @return {Number}
 * */
export function getNumberOfFilterArray<T>(
  array: T[],
  callback: (i: T, index: number) => boolean,
): number | void {
  if (Array.isArray(array)) {
    return compose<number | void>(getArrayLength, filter(callback))(array);
  }
}

/**
 * @desc Отримання значення властивості в масиві об'єктів
 * @param {Number} array
 * @param {Number} callback - функція для find
 * @param {String} properties - назва властивості
 * @return {Number}
 * */
export function getFromArrayProperties<T>(
  array: T,
  callback: (...args: any[]) => any,
  properties: string,
): any {
  if (Array.isArray(array)) {
    return compose(prop(properties), find(callback))(array);
  }
}
