import {Record} from 'immutable';
import {PaymentableDTO, ClientModel, ClientMapper} from '../internal';

export interface IPaymentableModelReturnType {
  uuid: string;
  balance_before: number;
  created_at: string;
  updated_at: string;
  client: ClientModel;
}

export class PaymentableModel extends Record<IPaymentableModelReturnType>({
  uuid: '',
  balance_before: 0,
  created_at: '',
  updated_at: '',
  client: {} as ClientModel,
}) {
  constructor(props: PaymentableDTO = {} as PaymentableDTO) {
    const options: IPaymentableModelReturnType = {
      ...props,
      client: ClientMapper.toClientModel(props?.client),
    };

    super(options);
  }
}
