import {List, Map} from 'immutable';
import {APP_STATE, CLIENT_VISIT_LIST} from '../constants';
import {compose, isEqualByUuid, not} from '../../services/helpers';
import {ApiError, REDUX_STATUS} from '../../services/types';
import {
  ClientVisitListModel,
  ClientVisitMapper,
  ClientVisitModel,
  ClientAbonementMapper,
  ClientAbonementDTO,
} from '../../struture';

interface IClientVisitListState {
  visitList: List<ClientVisitModel> | null;
  cachedVisitList: Map<string, ClientVisitListModel>;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
  total: number;
}

interface SetActionList extends Pick<IClientVisitListState, 'total'> {
  type: CLIENT_VISIT_LIST.SET_CLIENT_VISIT_LIST;
  abonementUuid: string;
  visitList: ClientVisitListModel;
  keywords?: string;
  page: number;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface AddActionToList {
  type: CLIENT_VISIT_LIST.ADD_CLIENT_VISIT;
  visit: ClientVisitModel;
  abonementUuid: string;
}

interface UpdateClientVisitFromList {
  type: CLIENT_VISIT_LIST.UPDATE_CLIENT_VISIT;
  visit: ClientVisitModel;
  abonementUuid: string;
}

interface DeleteActionFromList {
  type: CLIENT_VISIT_LIST.DELETE_CLIENT_VISIT;
  visitUuid: string;
  abonementUuid: string;
}

interface LoadingActionInList {
  type: CLIENT_VISIT_LIST.LOADING_CLIENT_VISIT_LIST;
}

interface ErrorActionInList extends Pick<IClientVisitListState, 'error'> {
  type: CLIENT_VISIT_LIST.ERROR_CLIENT_VISIT_LIST;
}

interface Handlers {
  [CLIENT_VISIT_LIST.SET_CLIENT_VISIT_LIST]: (
    state: IClientVisitListState,
    action: SetActionList,
  ) => IClientVisitListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IClientVisitListState,
    action: SetInitialStateAction,
  ) => IClientVisitListState;

  [CLIENT_VISIT_LIST.LOAD_MORE_CLIENT_VISIT_LIST]: (
    state: IClientVisitListState,
    action: SetActionList,
  ) => IClientVisitListState;

  [CLIENT_VISIT_LIST.ADD_CLIENT_VISIT]: (
    state: IClientVisitListState,
    action: AddActionToList,
  ) => IClientVisitListState;

  [CLIENT_VISIT_LIST.UPDATE_CLIENT_VISIT]: (
    state: IClientVisitListState,
    action: UpdateClientVisitFromList,
  ) => IClientVisitListState;

  [CLIENT_VISIT_LIST.DELETE_CLIENT_VISIT]: (
    state: IClientVisitListState,
    action: DeleteActionFromList,
  ) => IClientVisitListState;

  [CLIENT_VISIT_LIST.ERROR_CLIENT_VISIT_LIST]: (
    state: IClientVisitListState,
    value: ErrorActionInList,
  ) => IClientVisitListState;

  [CLIENT_VISIT_LIST.LOADING_CLIENT_VISIT_LIST]: (
    state: IClientVisitListState,
    value?: LoadingActionInList,
  ) => IClientVisitListState;
  DEFAULT: (state: IClientVisitListState) => IClientVisitListState;
}

const initState: IClientVisitListState = {
  visitList: null,
  cachedVisitList: Map(),
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
  total: 0,
};

const handlers: Handlers = {
  [CLIENT_VISIT_LIST.SET_CLIENT_VISIT_LIST]: (
    state,
    {visitList, abonementUuid, keywords, page},
  ) => {
    const updatedList = visitList
      .update('keywords', () => keywords || '')
      .update('page', () => page || 1);

    return {
      ...state,
      ...{
        visitList: visitList?.visits || null,
        cachedVisitList: visitList
          ? state.cachedVisitList.set(abonementUuid, updatedList)
          : state.cachedVisitList,
        status: REDUX_STATUS.SUCCEEDED,
        total: visitList?.total! >= 0 ? visitList?.total! : state.total,
      },
    };
  },

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [CLIENT_VISIT_LIST.LOAD_MORE_CLIENT_VISIT_LIST]: (
    state,
    {visitList, abonementUuid},
  ) => {
    const relaterScheduleListById = state.cachedVisitList.get(abonementUuid);

    return {
      ...state,
      ...{
        visitList:
          List.isList(state.visitList) && List.isList(visitList)
            ? state.visitList.merge(visitList.visits)
            : state.visitList,
        cachedVisitList: visitList
          ? state.cachedVisitList.set(
              abonementUuid,
              relaterScheduleListById
                ? relaterScheduleListById.update('visits', (visits) =>
                    visits.merge(visitList.visits),
                  )
                : visitList,
            )
          : state.cachedVisitList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [CLIENT_VISIT_LIST.ADD_CLIENT_VISIT]: (state, {visit, abonementUuid}) => {
    const relaterScheduleListById = state.cachedVisitList.get(abonementUuid);

    const abonement = state.cachedVisitList.first()?.abonement;

    return {
      ...state,
      ...{
        visitList: List.isList(state.visitList)
          ? state.visitList.unshift(visit)
          : List([visit]),
        cachedVisitList: relaterScheduleListById
          ? state.cachedVisitList.update(abonementUuid, (visitList) => {
              if (visitList) {
                return visitList
                  .update('visits', (visits) => visits.unshift(visit))
                  .update('total', (total) => (total || 0) + 1);
              } else {
                return ClientVisitMapper.toClientVisitListModel(
                  [visit] as any,
                  abonement ||
                    ClientAbonementMapper.toClientAbonementModel(
                      {} as ClientAbonementDTO,
                    ).abonement,
                  1,
                );
              }
            })
          : Map({
              [abonementUuid]: ClientVisitMapper.toClientVisitListModel(
                [visit] as any,
                abonement ||
                  ClientAbonementMapper.toClientAbonementModel(
                    {} as ClientAbonementDTO,
                  ).abonement,
                1,
              ),
            }),
        status: REDUX_STATUS.SUCCEEDED,
        total: state.total + 1,
      },
    };
  },

  [CLIENT_VISIT_LIST.UPDATE_CLIENT_VISIT]: (
    state: IClientVisitListState,
    {visit, abonementUuid}: UpdateClientVisitFromList,
  ) => {
    const relaterScheduleListById = state.cachedVisitList.get(abonementUuid);

    const abonement = state.cachedVisitList.first()?.abonement;

    return {
      ...state,
      ...{
        visitList: List.isList(state.visitList)
          ? state.visitList.map((stateClientVisit) => {
              if (stateClientVisit.uuid === visit?.uuid) {
                return stateClientVisit.merge(visit);
              }
              return stateClientVisit;
            })
          : List([visit]),

        cachedVisitList: relaterScheduleListById
          ? state.cachedVisitList.update(abonementUuid, (visitList) => {
              if (visitList) {
                return visitList.update('visits', (visits) =>
                  visits.map((visitModel) => {
                    if (visitModel.uuid === visit.uuid) {
                      return visitModel.merge(visit);
                    } else {
                      return visitModel;
                    }
                  }),
                );
              } else {
                return ClientVisitMapper.toClientVisitListModel(
                  [],
                  abonement ||
                    ClientAbonementMapper.toClientAbonementModel(
                      {} as ClientAbonementDTO,
                    ).abonement,
                  0,
                );
              }
            })
          : state.cachedVisitList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [CLIENT_VISIT_LIST.DELETE_CLIENT_VISIT]: (state, {visitUuid}) => {
    return {
      ...state,
      ...{
        visitList: List.isList(state.visitList)
          ? state.visitList.filter(compose(not, isEqualByUuid(visitUuid)))
          : null,
        cachedVisitList: state.cachedVisitList.map((visits) =>
          visits.update('visits', (list) => {
            const index = list.findIndex(({uuid}) => uuid === visitUuid);

            if (~index) {
              return list.delete(index);
            }

            return list;
          }),
        ),
        status: REDUX_STATUS.SUCCEEDED,
        total: state.total > 0 ? state.total - 1 : 0,
      },
    };
  },

  [CLIENT_VISIT_LIST.ERROR_CLIENT_VISIT_LIST]: (
    state: IClientVisitListState,
    {error}: ErrorActionInList,
  ) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [CLIENT_VISIT_LIST.LOADING_CLIENT_VISIT_LIST]: (
    state: IClientVisitListState,
  ) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IClientVisitListState) => state,
};

export default function ClientVisitList(
  state: any = initState,
  action: any,
): IClientVisitListState {
  const handler =
    handlers[action.type as CLIENT_VISIT_LIST] || handlers.DEFAULT;
  return handler(state, action);
}
