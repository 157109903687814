import {Record, List} from 'immutable';
import CompanyList from './CompanyList';
import Company, {CompanyProps} from './Company';

export interface IGroupPaymentProps {
  uuid: string;
  title: string;
  description: string;
  companies: CompanyProps[];
  updated_at: string;
  created_at: string;
}

export interface IGroupPaymentRecord
  extends Omit<IGroupPaymentProps, 'companies'> {
  companies: List<Company[]>;
}

export default class GroupPayment extends Record<IGroupPaymentRecord>({
  uuid: '',
  title: '',
  description: '',
  companies: [] as any,
  updated_at: null,
  created_at: null,
}) {
  constructor(
    {companies, ...props}: IGroupPaymentProps = {} as IGroupPaymentProps,
  ) {
    const options: Partial<IGroupPaymentRecord> = {};

    if (Array.isArray(companies)) {
      const {companies: companyList} = new CompanyList({companies} as any);
      options.companies = companyList as any;
    }

    super({...props, ...options});
  }
}
