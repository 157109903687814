import {
  STATUS_VERIFIED,
  STATUS_UNVERIFIED,
  STATUS_INACTIVE,
} from '../constants';

/**
 * @desc Перевіряє чи відповідає переданий аргумент статусу верифікації
 * @param {Number} status
 * @return {Boolean}
 * */
export function isVerified(status: number): boolean {
  return status === STATUS_VERIFIED;
}

/**
 * @desc Перевіряє чи відповідає переданий аргумент неверифікованому СТАТУСУ
 * @param {Number} status
 * @return {Boolean}
 * */
export function isUnVerified(status: number): boolean {
  return status === STATUS_UNVERIFIED;
}

/**
 * @desc Перевіряє чи відповідає переданий аргумент НеАктивному статусу
 * @param {Number} status
 * @return {Boolean}
 * */
export function isInActiveVerification(status: number): boolean {
  return status === STATUS_INACTIVE;
}
