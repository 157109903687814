import React, {useCallback, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {SideWindow} from '../../../components/lib/Navigation';
import {InvoiceFormProps, StoreInvoiceForm} from '../Forms';
import {InvoiceType} from '../../../struture';
import {useModal} from '../../../components/lib/libV2/hooks';
import {StyledTitle} from '../../../components/lib/Styled';
import {CloseOutlined} from '@ant-design/icons';
import {Modal} from 'antd';
import {InvoiceRefObject, isEqualInvoiceFields} from '../helpers';

export interface InvoiceSideWindowProps
  extends Omit<InvoiceFormProps, 'invoiceEditRef'> {
  visible: boolean;
}

export default function InvoiceSideWindow({
  onSuccess,
  visible,
  editMode,
  onCancel,
  invoice,
  ...rest
}: InvoiceSideWindowProps) {
  const {t} = useTranslation();
  const [modal, contextHolder] = Modal.useModal();

  const invoiceEditRef = useRef<InvoiceRefObject>({
    invoice,
  });

  const {
    handleCancel: handleCancelCloseModal,
    handleSuccess: handleSuccessCloseModal,
  } = useModal({
    onSuccess: onCancel,
  });

  const handleCancelSideWindows = useCallback(() => {
    const {isEqual, isEqualClient} = isEqualInvoiceFields(
      invoice,
      invoiceEditRef?.current,
    );

    if (isEqual && isEqualClient) {
      return handleSuccessCloseModal(undefined);
    }

    modal.confirm({
      title: (
        <StyledTitle>{t('You really want to close the form?')}</StyledTitle>
      ),
      style: {minWidth: '40%'},
      okText: t('Yes'),
      cancelText: t('No'),
      onOk: handleSuccessCloseModal,
      onCancel: handleCancelCloseModal,
    });
  }, [invoice, modal, t, handleSuccessCloseModal, handleCancelCloseModal]);

  return (
    <>
      <SideWindow
        title={
          invoice?.inv_type === InvoiceType.IN
            ? t('Invoice')
            : t('Input invoice')
        }
        closeIcon={
          <CloseOutlined
            onClick={(e) => {
              e.stopPropagation();
              handleCancelSideWindows();
            }}
          />
        }
        onClose={handleCancelSideWindows}
        visible={visible}>
        {visible ? (
          <StoreInvoiceForm
            editMode={editMode}
            onSuccess={onSuccess}
            onCancel={onCancel}
            invoice={invoice}
            {...rest}
            invoiceEditRef={invoiceEditRef}
          />
        ) : null}
      </SideWindow>
      {contextHolder}
    </>
  );
}
