import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {SubscriptionTabPanel} from '../Show';
import {OverviewLayout} from '../../../components/lib/Layout';
import {useStoredPlanList, useInterval, useStoredProfile} from '../../../hooks';
import {Routes, RoutesAcl} from '../../../services/types';
import {HeaderLeft} from '../../../components/lib/DataDisplay';
import {sortedByDate} from '../../../services/helpers';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import {useNavigate} from 'react-router';
import {
  API_CALL_PAYMENT_TIMEOUT_DELAY,
  API_CALL_PAYMENT_TIMEOUT_CLEAR,
} from '../../../services/const';
import {useDropdownAlert} from '../../../contex';
import {PlanFormDTO, PlanTariffFormDTO} from '../../../struture';

export function SubscriptionPage(): JSX.Element {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const navigate = useNavigate();
  const {profile, loading: profileLoading} = useStoredProfile();

  const planEndDate = useRef<string | undefined>(undefined);

  const [paymentLoading, setPaymentLoading] = useState(false);

  const {
    planList,
    loading,

    handleSubscriptionTransition,
    handleSubscriptionActivate,
    handleRefreshPlanList,
  } = useStoredPlanList({loadOnInit: false});

  const plan = planList?.first() || null;

  const {intervalCallback, handleClearInterval} = useInterval(
    async () => {
      const planList = await handleRefreshPlanList({showLoading: false});

      const endDate = planList?.first()?.getFirstSubscription().date_end;

      if (endDate !== planEndDate?.current) {
        planEndDate.current = endDate;

        handleClearInterval();
        setPaymentLoading(false);
      }
    },
    API_CALL_PAYMENT_TIMEOUT_DELAY,
    API_CALL_PAYMENT_TIMEOUT_CLEAR,
    () => {
      setPaymentLoading(false);
      alert(
        'error',
        t('Tariff'),
        t('An error occurred during change tariff plan'),
      );
    },
  );

  const openLinkAndCancelCall = useCallback(
    async (url: string) => {
      window?.open(url, '_blank');

      await intervalCallback();
    },
    [intervalCallback],
  );

  const subscriptionActivate = useCallback(
    async (value: PlanFormDTO): Promise<string> => {
      const url = await handleSubscriptionActivate(value);
      await openLinkAndCancelCall(url);

      return url;
    },
    [handleSubscriptionActivate, openLinkAndCancelCall],
  );

  const subscriptionTransition = useCallback(
    async (value: PlanTariffFormDTO): Promise<string> => {
      const url = await handleSubscriptionTransition(value);

      await openLinkAndCancelCall(url);
      return url;
    },
    [handleSubscriptionTransition, openLinkAndCancelCall],
  );

  useEffect(() => {
    if (plan && !planEndDate.current) {
      planEndDate.current = plan?.subscriptions
        ?.sort(sortedByDate('date_end'))
        .last()?.date_end;
    }
  }, [plan]);

  const routes = useBreadcrumb([
    {
      path: `/${Routes.profile}/${Routes.subscription}`,
      breadcrumbName: plan?.identifier ? t(plan?.identifier) : 'tSubscription',
    },
  ]);

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.profile}`);
  }, [navigate]);

  return (
    <SubscriptionTabPanel
      profile={profile}
      paymentLoading={paymentLoading}
      plan={plan}
      handleSubscriptionActivate={subscriptionActivate}
      handleSubscriptionTransition={subscriptionTransition}>
      {({tabs, topTabs}) => (
        <OverviewLayout
          aclItem={RoutesAcl[Routes.profile]}
          headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
          headerTitle={t('tSubscription')}
          loading={loading || profileLoading}
          routes={routes}
          data={null}
          tabs={tabs}
          topTabs={topTabs}
        />
      )}
    </SubscriptionTabPanel>
  );
}
