import {List} from 'immutable';
import {
  ServiceDTO,
  IServiceDTOProps,
  ServiceFormDTO,
  ServiceModel,
  ServiceListModel,
} from '../internal';
import {listToArray} from '../../services/helpers';
import {WithoutRecursion} from '../type';

export interface IServiceListDTO {
  services: ServiceDTO[];
  total: number;
}

export class ServiceMapper {
  public static toServiceDTO(
    service: ServiceModel | IServiceDTOProps | ServiceDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): ServiceDTO {
    return new ServiceDTO(service, [
      WithoutRecursion.service,
      ...withoutRecursion,
    ]);
  }

  public static toServiceListDTO(
    services: List<ServiceModel> | IServiceDTOProps[] | ServiceDTO[],
    total?: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): IServiceListDTO {
    const serviceList = List.isList(services)
      ? listToArray<ServiceModel>(services)
      : services;

    return {
      services: serviceList?.map((service) =>
        ServiceMapper.toServiceDTO(service, [
          WithoutRecursion.service,
          ...withoutRecursion,
        ]),
      ),
      total: total || 0,
    };
  }

  public static toServiceModel(
    serviceDTO: ServiceDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): ServiceModel {
    return new ServiceModel(serviceDTO, [
      WithoutRecursion.service,
      ...withoutRecursion,
    ]);
  }

  public static toServiceListModel(
    serviceDTOs: ServiceDTO[],
    total?: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): ServiceListModel {
    return new ServiceListModel({services: serviceDTOs, total}, [
      WithoutRecursion.service,
      ...withoutRecursion,
    ]);
  }

  public static toServiceFormDTO(
    serviceModel?: ServiceModel,
    editMode?: boolean,
  ): ServiceFormDTO {
    return new ServiceFormDTO(serviceModel, editMode);
  }
}
