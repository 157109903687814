import React, {useMemo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {DefaultForm, IDefaultFormProps} from '../../../components/lib/General';
import {StoreBelongingTo, StoreFormDTO, StoreType} from '../../../struture';
import {useDropdownAlert} from '../../../contex';
import {
  useStateEmployeeList,
  useStoredCompanies,
  useStoredDepartmentList,
} from '../../../hooks';
import {MdSettings} from 'react-icons/md';
import {Col, Form, Input, Row} from 'antd';
import {
  FormSwitch,
  SearchSelect,
  Segmented,
} from '../../../components/lib/DataDisplay';
import {isListToArray} from '../../../services/helpers';
import styled from 'styled-components';
import {StyledTitle} from '../../../components/lib/Styled';
import {StoreBelongingToView} from '../Show/StoreBelongingToView';

export interface IStoreFormProps
  extends Omit<
    IDefaultFormProps<StoreFormDTO, StoreFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  store: StoreFormDTO;
}

const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 25px;
`;

const StyledSwitchRow = styled(Row)`
  border: 1px solid rgb(225, 229, 231);
  border-radius: 7px;
  margin-bottom: 20px;

  margin-left: 10px !important;
  margin-right: 10px !important;
`;

const StyledFormSwitch = styled(FormSwitch)`
  display: flex;
  align-items: center;
  height: 60px;

  &:nth-child(n):not(&:last-child):not(&:nth-last-child(2)) {
    border-bottom: 1px solid rgb(225, 229, 231);
  }

  &:nth-child(2n + 1) {
    border-right: 1px solid rgb(225, 229, 231);
  }

  .switch-form-item {
    margin-bottom: 0;
  }

  .switch-container {
    align-items: center;
  }
`;

const SwitchTitle = styled(StyledTitle)`
  margin-left: 10px;
  margin-bottom: 10px;
`;

export const STORE_BELONGING_TO = [
  {
    uuid: StoreBelongingTo.COMPANY,
    title: <StoreBelongingToView type={StoreBelongingTo.COMPANY} />,
  },
  {
    uuid: StoreBelongingTo.DEPARTMENT,
    title: <StoreBelongingToView type={StoreBelongingTo.DEPARTMENT} />,
  },
];

export function StoreForm({
  loading,
  store,
  editMode,
  ...rest
}: IStoreFormProps): React.JSX.Element {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const {defaultCompanyUuid} = useStoredCompanies();
  const [instance] = Form.useForm();

  const storeBelongingTo = Form.useWatch('store_belonging_to', instance);
  const storeType = Form.useWatch('store_type', instance);

  const additionalValues = useMemo(
    () => ({
      uuid: store?.uuid,
    }),
    [store?.uuid],
  );

  const {
    departmentList,
    loading: departmentListLoading,
    handleSearchDepartments,
  } = useStoredDepartmentList({companyUuid: defaultCompanyUuid});

  const {
    employees,
    loading: employeesLoading,
    handleSearchEmployees,
  } = useStateEmployeeList({companyUuid: defaultCompanyUuid});

  const notifyError = useCallback(
    (ApiError: any) => {
      alert(
        'error',
        t('Store'),
        `${
          editMode
            ? t('An error occurred during edit store')
            : t('An error occurred during create store')
        } : ${ApiError?.message}`,
      );
    },
    [alert, editMode, t],
  );

  const STORE_TYPE = [
    {
      uuid: StoreType.PRODUCT,
      title: t('tProduct'),
    },
    {
      uuid: StoreType.INVENTORY,
      title: t('tInventory'),
    },
    {
      uuid: StoreType.CUSTOM,
      title: (
        <StyledIconContainer>
          <MdSettings size={18} />
        </StyledIconContainer>
      ),
    },
  ];

  return (
    <DefaultForm<StoreFormDTO, StoreFormDTO>
      instance={instance}
      initialValues={{
        ...store,
      }}
      additionalValuesRequest={additionalValues}
      editMode={editMode}
      notifyError={notifyError}
      showNotify={false}
      {...rest}>
      {({loadingSubmit, getFieldValue, setFieldsValue}) => (
        <Row gutter={20}>
          <Col span={24}>
            <Form.Item
              label={t('Title')}
              name="store_name"
              rules={[
                {
                  required: true,
                  message: t('Title must be specified.'),
                },
              ]}>
              <Input
                disabled={loading || loadingSubmit}
                placeholder={t('Enter the title')}
              />
            </Form.Item>
          </Col>

          <Col span={14}>
            <Form.Item
              tooltip={t('Belonging to')}
              label={t('Belonging to')}
              name="store_belonging_to">
              <Segmented
                options={STORE_BELONGING_TO}
                disabled={loading || loadingSubmit}
              />
            </Form.Item>
          </Col>

          {storeBelongingTo === StoreBelongingTo.DEPARTMENT ? (
            <Col span={10}>
              <Form.Item
                tooltip={t('Department')}
                label={t('Department')}
                name="store_department_uuid"
                rules={[
                  {
                    required: true,
                    message: t('Department must be specified.'),
                  },
                ]}>
                <SearchSelect
                  selectFirst={false}
                  name="store_department_uuid"
                  getOptionValueTitle="dep_name"
                  placeholder={t('Select the department')}
                  disable={loading || loadingSubmit || departmentListLoading}
                  data={isListToArray(departmentList as any)}
                  onSearch={(keywords) =>
                    handleSearchDepartments({
                      keywords,
                      showLoading: false,
                      limit: 100,
                    })
                  }
                />
              </Form.Item>
            </Col>
          ) : null}

          <Col span={24}>
            <Form.Item
              tooltip={t('Type')}
              label={t('Type')}
              name="store_type"
              rules={[
                {
                  required: true,
                  message: t('Type to must be specified.'),
                },
              ]}>
              <Segmented
                options={STORE_TYPE}
                disabled={loading || loadingSubmit}
              />
            </Form.Item>
          </Col>

          {storeType === StoreType.CUSTOM ? (
            <>
              <SwitchTitle>{t('Store settings')}</SwitchTitle>

              <StyledSwitchRow gutter={20}>
                <StyledFormSwitch
                  span={12}
                  loading={loadingSubmit || loading}
                  disabled={loadingSubmit}
                  name={[
                    'store_settings',
                    'permissions',
                    'input',
                    'allow_move',
                  ]}
                  title={t('Move to store')}
                  label={null}
                  tooltipTitle={t('Move to store')}
                  getFieldValue={getFieldValue}
                  setFieldsValue={setFieldsValue}
                  formItemClassName="switch-form-item"
                  contentContainerClassName="switch-container"
                />

                <StyledFormSwitch
                  span={12}
                  loading={loadingSubmit || loading}
                  disabled={loadingSubmit}
                  name={[
                    'store_settings',
                    'permissions',
                    'output',
                    'allow_move',
                  ]}
                  label={null}
                  title={t('Move from the store')}
                  tooltipTitle={t('Move from the store')}
                  getFieldValue={getFieldValue}
                  setFieldsValue={setFieldsValue}
                  formItemClassName="switch-form-item"
                  contentContainerClassName="switch-container"
                />

                <StyledFormSwitch
                  span={12}
                  loading={loadingSubmit || loading}
                  disabled={loadingSubmit}
                  name={[
                    'store_settings',
                    'permissions',
                    'input',
                    'allow_return',
                  ]}
                  label={null}
                  title={t('Return to the store from the client')}
                  tooltipTitle={t('Return to the store from the client')}
                  getFieldValue={getFieldValue}
                  setFieldsValue={setFieldsValue}
                  formItemClassName="switch-form-item"
                  contentContainerClassName="switch-container"
                />

                <StyledFormSwitch
                  span={12}
                  loading={loadingSubmit || loading}
                  disabled={loadingSubmit}
                  name={[
                    'store_settings',
                    'permissions',
                    'output',
                    'allow_return',
                  ]}
                  label={null}
                  title={t('Return from the store to the supplier')}
                  tooltipTitle={t('Return from the store to the supplier')}
                  getFieldValue={getFieldValue}
                  setFieldsValue={setFieldsValue}
                  formItemClassName="switch-form-item"
                  contentContainerClassName="switch-container"
                />

                <StyledFormSwitch
                  span={12}
                  loading={loadingSubmit || loading}
                  disabled={loadingSubmit}
                  name={['store_settings', 'permissions', 'input', 'allow_in']}
                  label={null}
                  title={t('Arrive at the store')}
                  tooltipTitle={t('Arrive at the store')}
                  getFieldValue={getFieldValue}
                  setFieldsValue={setFieldsValue}
                  formItemClassName="switch-form-item"
                  contentContainerClassName="switch-container"
                />
                <StyledFormSwitch
                  span={12}
                  loading={loadingSubmit || loading}
                  disabled={loadingSubmit}
                  name={[
                    'store_settings',
                    'permissions',
                    'output',
                    'allow_erase',
                  ]}
                  label={null}
                  title={t('Write off from the store')}
                  tooltipTitle={t('Write off from the store')}
                  getFieldValue={getFieldValue}
                  setFieldsValue={setFieldsValue}
                  formItemClassName="switch-form-item"
                  contentContainerClassName="switch-container"
                />
                <StyledFormSwitch
                  span={12}
                  loading={loadingSubmit || loading}
                  disabled={loadingSubmit}
                  name={[
                    'store_settings',
                    'permissions',
                    'output',
                    'allow_order',
                  ]}
                  label={null}
                  title={t('Use in orders')}
                  tooltipTitle={t('Use in orders')}
                  getFieldValue={getFieldValue}
                  setFieldsValue={setFieldsValue}
                  formItemClassName="switch-form-item"
                  contentContainerClassName="switch-container"
                />
                <StyledFormSwitch
                  span={12}
                  loading={loadingSubmit || loading}
                  disabled={loadingSubmit}
                  name={[
                    'store_settings',
                    'permissions',
                    'output',
                    'allow_sell',
                  ]}
                  label={null}
                  title={t('Use in sales')}
                  tooltipTitle={t('Use in sales')}
                  getFieldValue={getFieldValue}
                  setFieldsValue={setFieldsValue}
                  formItemClassName="switch-form-item"
                  contentContainerClassName="switch-container"
                />
              </StyledSwitchRow>
            </>
          ) : null}

          <Col span={24}>
            <Form.Item
              tooltip={t('Manager')}
              label={t('Manager')}
              name="store_manager_uuid"
              rules={[
                {
                  required: true,
                  message: t('Manager must be specified.'),
                },
              ]}>
              <SearchSelect
                selectFirst={false}
                name="store_manager_uuid"
                getOptionValueTitle="fullName"
                placeholder={t('Select the manager')}
                disable={loading || loadingSubmit || employeesLoading}
                data={isListToArray(employees as any)}
                onSearch={(keywords) =>
                  handleSearchEmployees({
                    keywords,
                    showLoading: false,
                    limit: 100,
                  })
                }
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label={t('Note')} name="store_description">
              <Input.TextArea
                allowClear
                autoSize={{minRows: 2, maxRows: 5}}
                disabled={loading || loadingSubmit}
                placeholder={t('Enter your note')}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </DefaultForm>
  );
}
