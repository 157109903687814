import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {OverviewLayout} from '../../../components/lib/Layout';
import {
  useInterval,
  useRequiredFields,
  useStateClientAbonementScheduleList,
  useStopLoading,
  useStoredAbonementGroup,
} from '../../../hooks';
import {ABONEMENT_GROUP_REQUIRED_FIELD} from '../../../services/api/abonementGroup';
import {TariffModelDetailsView, TariffModelGroupTabPanel} from '../Show';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import {PERSONALITY_TYPE, Routes, RoutesAcl} from '../../../services/types';
import {
  AbonementFormDTO,
  AbonementGroupMapper,
  AbonementGroupModel,
  ClientAbonementScheduleFormDTO,
  EmployeeStatuses,
  ScheduleCalendarMapper,
} from '../../../struture';
import {useAcl} from '../../../contex';
import {useLocation, useNavigate, useParams} from 'react-router';
import {TariffModelGroupEditButton} from '../Buttons';
import {
  DetailsView,
  HeaderLeft,
  Switch,
} from '../../../components/lib/DataDisplay';
import {Col, Row, Space} from 'antd';
import {StyledTitle} from '../../../components/lib/Styled';
import {Text} from '../../../components/lib/Format';
import styled, {useTheme} from 'styled-components';
import {
  fromBooleanToNumber,
  getDatesInMonthDisplay,
  getMonth,
  getTimesOfDates,
  getYear,
  isThereContent,
} from '../../../services/helpers';
import {IOrderRequiredFieldsWithFullName} from '../../../services/api/orders';
import {EmployeeDetailsView} from '../../Employees';
import {Table} from '../../../components/lib/libV2/DataDisplay';
import {
  API_CALL_ABONEMENT_TIMEOUT_CLEAR,
  API_CALL_ABONEMENT_TIMEOUT_DELAY,
  ClientAbonementScheduleButton,
  ClientDetailsView,
} from '../../Clients';
import moment from 'moment';

const StyledSwitchWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
`;

const StyledSwitch = styled(Switch)`
  margin-right: 10px;
`;

const StyledTable = styled(Table)`
  &&& {
    max-width: 200px;

    .ant-table-cell,
    .ant-table-tbody {
      background-color: ${({theme}) => theme.background.layout};
    }
  }
`;

export function TariffModelGroupPage(): JSX.Element {
  const {tariffModelId, tariffModelGroupId} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {t} = useTranslation();
  const theme: any = useTheme();

  const {abonement: abonementAccess} = useAcl((acl) => acl);

  const [isAbonementGroupBlock, setIsAbonementGroupBlock] = useState<
    null | boolean
  >(null);
  const [isAbonementGroupBlockLoading, setIsAbonementGroupBlockLoading] =
    useState(false);

  const [formingScheduleListLoading, setFormingScheduleListLoading] =
    useState(false);

  const {
    group,
    loading: groupLoading,
    error: groupError,
    isHasScheduleList,

    handleUpdateAbonementGroup,
    handleChangeAbonementGroupStatus,
  } = useStoredAbonementGroup({
    abonementUuid: tariffModelId!,
    groupUuid: tariffModelGroupId!,
  });

  const {
    scheduleList,
    loading: scheduleListLoading,
    error: scheduleListError,
    total,
    isShowClassSchedule,
    setTotal,

    handleFormingClientAbonementSchedule,
    handleRefreshClientAbonementSchedules,
    updateClientAbonementSchedules,
  } = useStateClientAbonementScheduleList({
    abonementUuid: tariffModelId!,
    clientUuid: tariffModelGroupId!,
    loadOnInit: false,
    isAbonementHasScheduleList: isHasScheduleList,
  });

  const monthDates = getDatesInMonthDisplay({
    month: getMonth(moment(new Date())) + 1,
    year: getYear(moment(new Date())),
  });

  const {start, end} = getTimesOfDates(monthDates, new Date().toString());

  const {intervalCallback, handleClearInterval} = useInterval<string>(
    async () => {
      try {
        const scheduleLitModel = await updateClientAbonementSchedules({
          showLoading: true,
          start,
          end,
        });

        const schedulesTotal = scheduleLitModel?.total || 0;

        if (scheduleLitModel && schedulesTotal > total) {
          handleClearInterval();
          setTotal(schedulesTotal);
          setFormingScheduleListLoading(false);
        }
      } catch (error: any) {
        handleClearInterval();
        setFormingScheduleListLoading(false);
      }
    },
    API_CALL_ABONEMENT_TIMEOUT_DELAY,
    API_CALL_ABONEMENT_TIMEOUT_CLEAR,
    () => {
      setFormingScheduleListLoading(false);
    },
  );

  const formingClientAbonementSchedule = useCallback(
    async (value: ClientAbonementScheduleFormDTO) => {
      setFormingScheduleListLoading(true);
      await handleFormingClientAbonementSchedule(value);
      intervalCallback();
    },
    [handleFormingClientAbonementSchedule, intervalCallback],
  );

  const changeAbonementGroupStatus = useCallback(
    async (value: boolean) => {
      if (group) {
        setIsAbonementGroupBlockLoading(true);
        setIsAbonementGroupBlock(value);

        const groupModel = await handleChangeAbonementGroupStatus(
          group?.uuid,
          fromBooleanToNumber(value),
        );

        if (!(groupModel instanceof AbonementGroupModel)) {
          setIsAbonementGroupBlock(!value);
        }

        setIsAbonementGroupBlockLoading(false);
      }
    },
    [group, handleChangeAbonementGroupStatus],
  );

  const loading = useStopLoading({
    loading: groupLoading,
    error: groupError,
    message: 'An error occurred during group loading',
  });

  const schedulesLoading = useStopLoading({
    loading: scheduleListLoading,
    error: scheduleListError,
    message: 'An error occurred during client abonement schedules loading',
  });

  const isShowSchedules =
    Object.keys(group?.group_schedule_list || {}).length ||
    group?.company_abonement?.service?.personality === PERSONALITY_TYPE.GROUP ||
    !group?.manager?.uuid;

  const routes = useBreadcrumb([
    {
      path: `/${Routes.tariffModels}`,
      breadcrumbName: 'Tariff models',
    },
    {
      path: `/${Routes.tariffModels}/${tariffModelId}`,
      breadcrumbName: 'Show tariff model',
    },
    {
      path: `/${Routes.tariffModels}/${tariffModelId}/${Routes.tariffModelGroup}`,
      breadcrumbName: 'Tariff model groups',
    },
    {
      path: `/${Routes.tariffModels}/${tariffModelId}/${Routes.tariffModelGroup}/${tariffModelGroupId}`,
      breadcrumbName: group?.group_title || 'Tariff model group',
    },
  ]);

  const changeFields = useCallback(
    (
      {
        group_title,
        group_schedule_list,
        ...rest
      }: typeof ABONEMENT_GROUP_REQUIRED_FIELD,
      group: AbonementGroupModel,
    ): IOrderRequiredFieldsWithFullName & any => {
      const timingListSize = Object.keys(group_schedule_list || {}).length;

      const timingList =
        AbonementFormDTO.getTimingWeekList(group_schedule_list);

      const timingListArr = Object.entries(group_schedule_list || {}).reduce(
        (acc: any, [key, value]) => {
          acc = [...acc, {day: t(key), time: `${value?.min} - ${value?.max}`}];
          return acc;
        },
        [],
      );

      const timingListField = timingListSize ? (
        timingList ? (
          <StyledTitle>{`${t('Mon-Sun')}: ${timingList}`}</StyledTitle>
        ) : (
          <StyledTable
            total={timingListArr.length}
            pageSize={timingListArr.length}
            loading={false}
            rowKey="day"
            dataSource={timingListArr}
            columns={[
              {
                width: 'fit-content',
                title: t('Weekday'),
                key: 'Weekday',
                render: (c: any) => c?.day,
              },
              {
                width: 'fit-content',
                title: t('Time'),
                key: 'time',
                render: (c: any) => c?.time,
              },
            ]}
            size="small"
          />
        )
      ) : null;

      return {
        group_title,
        ...rest,
        ...(timingListSize ? {'Time limit': timingListField} : []),
      };
    },
    [t],
  );

  const correctAbonementGroupRequiredFields = {
    ...ABONEMENT_GROUP_REQUIRED_FIELD,
    group_schedule_list: {
      Sunday: '',
      Monday: '',
      Tuesday: '',
      Wednesday: '',
      Thursday: '',
      Friday: '',
      Saturday: '',
    },
  };

  const overviewData = useRequiredFields(
    group as any,
    correctAbonementGroupRequiredFields,
    changeFields,
  );

  const navigateGoBack = useCallback(() => {
    navigate(
      location?.state?.goBack ||
        `/${Routes.app}/${Routes.tariffModels}/${tariffModelId}/${Routes.tariffModelGroup}`,
    );
  }, [location?.state?.goBack, navigate, tariffModelId]);

  useEffect(() => {
    if (group && isAbonementGroupBlock === null) {
      setIsAbonementGroupBlock(!!group?.group_status);
    }
  }, [group, isAbonementGroupBlock]);

  return (
    <TariffModelGroupTabPanel
      group={group}
      routes={routes}
      scheduleList={scheduleList}
      schedulesLoading={schedulesLoading}
      formingScheduleListLoading={formingScheduleListLoading}
      isShowClassSchedule={isShowClassSchedule}
      isStartRefreshSchedules={
        !!group?.uuid &&
        !!Object.keys(group?.group_schedule_list || {}).length &&
        !!group?.manager?.uuid
      }
      handleRefreshClientAbonementSchedules={
        handleRefreshClientAbonementSchedules
      }
      handleFormingClientAbonementSchedule={formingClientAbonementSchedule}>
      {({tabs}) => (
        <OverviewLayout
          moduleItem="abonement"
          aclItem={RoutesAcl[Routes.tariffModels]}
          headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
          headerRight={
            <TariffModelGroupEditButton
              group={AbonementGroupMapper.toAbonementGroupFormDTO(group!, true)}
              onSuccess={handleUpdateAbonementGroup}
            />
          }
          headerTitle={t('Tariff model group')}
          disabled={!abonementAccess?.manage}
          loading={loading}
          data={overviewData}
          routes={routes}
          tabs={tabs}
          header={
            <Row gutter={10}>
              <Col span={24}>
                <Space direction="vertical" size="small">
                  <StyledTitle bold fontSize={20}>
                    {group?.group_title}
                  </StyledTitle>
                </Space>
              </Col>
              <Col span={24}>
                <StyledSwitchWrapper>
                  <StyledSwitch
                    onChange={changeAbonementGroupStatus}
                    checked={!!isAbonementGroupBlock}
                    disabled={
                      isAbonementGroupBlockLoading || !abonementAccess?.manage
                    }
                  />
                  <Text>{t('Activated')}</Text>
                </StyledSwitchWrapper>
              </Col>
              {/*<Col span={24}>*/}
              {/*  {isShowSchedules ? null : (*/}
              {/*    <ClientAbonementScheduleButton*/}
              {/*      schedule={ScheduleCalendarMapper.toClientAbonementScheduleFormDTO(*/}
              {/*        group as any,*/}
              {/*      )}*/}
              {/*      onSuccess={formingClientAbonementSchedule}*/}
              {/*    />*/}
              {/*  )}*/}
              {/*</Col>*/}
              <Col span={24}>
                <DetailsView
                  colors={[
                    group?.manager?.status === EmployeeStatuses.DELETED
                      ? theme.colors.lightError
                      : undefined,
                  ]}
                  titles={['Manager', 'Abonement']}>
                  {group?.manager?.uuid ? (
                    <EmployeeDetailsView employee={group?.managerModel} />
                  ) : null}

                  {group?.company_abonement?.uuid ? (
                    <TariffModelDetailsView
                      tariffModel={group?.companyAbonementModel}
                    />
                  ) : null}
                </DetailsView>
              </Col>
            </Row>
          }
        />
      )}
    </TariffModelGroupTabPanel>
  );
}
