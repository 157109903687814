import {List} from 'immutable';
import {
  UserDTO,
  UserFormDTO,
  IUserDTOProps,
  UserModel,
  UserListModel,
} from '../internal';
import {listToArray} from '../../helpers';
import {WithoutRecursion} from '../type';

export interface IUserListDTO {
  users: UserDTO[];
  total: number;
}

export class UserMapper {
  public static toUserDTO(
    user: UserModel | IUserDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): UserDTO {
    return new UserDTO(
      {
        uuid: user?.uuid,
        first_name: user?.first_name,
        last_name: user?.last_name,
        middle_name: user?.middle_name,
        phone: user?.phone,
        email: user?.email,
        address: user?.address,
        birthday: user?.birthday,
        picture_url: user?.picture_url,
        status_text: user?.status_text,
        card_number: user?.card_number,
        login: user?.login,
        password: user?.password,
        confirm_password: user?.confirm_password,
        subscription_discount: user?.subscription_discount,
        phone_approved: user?.phone_approved,
        email_approved: user?.email_approved,
        status: user?.status,
        goods_discount: user?.goods_discount,
        gender: user?.gender,
        profile_subscriptions: user?.profile_subscriptions,
        default_company: user?.default_company,
        created_at: user?.created_at,
        updated_at: user?.updated_at,
      } as any,
      [WithoutRecursion.user, ...withoutRecursion],
    );
  }

  public static toUserListDTO(
    users: List<UserModel> | IUserDTOProps[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): IUserListDTO {
    const userList = List.isList(users) ? listToArray<UserModel>(users) : users;

    return {
      users: userList?.map((user) =>
        UserMapper.toUserDTO(user, [
          WithoutRecursion.user,
          ...withoutRecursion,
        ]),
      ),
      total,
    };
  }

  public static toUserModel(
    userDTO: UserDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): UserModel {
    return new UserModel(userDTO, [WithoutRecursion.user, ...withoutRecursion]);
  }

  public static toUserListModel(
    userDTOs: UserDTO[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): UserListModel {
    return new UserListModel({users: userDTOs, total}, [
      WithoutRecursion.user,
      ...withoutRecursion,
    ]);
  }

  public static toUserFormDTO(userModel?: UserModel): UserFormDTO {
    return new UserFormDTO(userModel);
  }
}
