import * as React from 'react';
import {useEffect} from 'react';
import {compose, isFunction, multiply, sub, ifElse} from '../services/helpers';

export interface IUseTableChangeProps {
  page?: number;
  pageSize: number;
  callback?: any;
  total: number;
}

export interface IUseTableChangeReturnType {
  currentPage: number;
  handleTableChange: (
    pagination: any,
    filter: any,
    sorters: any,
  ) => Promise<void>;
}

export default function useTableChange({
  page = 1,
  pageSize,
  callback,
  total,
}: IUseTableChangeProps): IUseTableChangeReturnType {
  const [currentPage, setCurrentPage] = React.useState<number>(page);

  const getSortedParam = React.useCallback((sorters: any) => {
    if (sorters.column || sorters.field) {
      const order = sorters.column.sorterField || sorters.field;

      return ifElse(
        sorters.order === 'ascend',
        `${order} ASC`,
        `${order} DESC`,
      );
    }
  }, []);

  const handleTableChange = React.useCallback(
    async (pagination: any, filter: any, sorters: any) => {
      const order = getSortedParam(sorters);

      setCurrentPage(pagination.current);

      const offset = compose(multiply(pageSize), sub(pagination.current))(1);

      if (isFunction(callback)) {
        await callback({
          offset,
          limit: pagination?.pageSize,
          page: pagination.current,
        });
      }
    },
    [callback, getSortedParam, pageSize],
  );

  useEffect(() => {
    if (currentPage > 2 && currentPage * 10 - 10 === total) {
      const offset = compose(multiply(pageSize), sub(currentPage))(2);

      if (isFunction(callback)) {
        (async () => {
          await callback({
            offset,
          });
        })();
      }

      setCurrentPage((prevState) => sub(prevState, 1));
    }
  }, [callback, currentPage, pageSize, total]);

  // useEffect(() => {
  //   if (page > currentPage) {
  //     setCurrentPage(page);
  //   }
  // }, [currentPage, page]);

  return {
    currentPage,
    handleTableChange,
  };
}
