import {
  CompanyDTO,
  CompanyMapper,
  EmployeeDTO,
  EmployeeMapper,
  ICompanyDTOProps,
  IEmployeeDTOProps,
  ICashBoxModelReturnType,
  ProfileDTO,
  IProfileDTOProps,
  ProfileMapper,
  // IServiceDTOProps,
  // ServiceDTO,
  // IProductDTOProps,
  // ProductMapper,
  // ProductDTO,
} from '../internal';
import {WithoutRecursion, CashBoxType} from '../type';
import {correctPrice} from '../../helpers';

export interface ICashBoxDTOProps {
  uuid: string;
  box_type: CashBoxType.Cash;
  box_title: string;
  box_comment: string;
  box_pos: number;
  box_can_move_money_from: boolean;
  box_can_move_money_to: boolean;
  box_can_in_money_to: boolean;
  box_can_out_money_from: boolean;
  box_is_default_for_company: boolean;
  box_balance: string;
  created_at: string;
  updated_at: string;
  created_by: IProfileDTOProps;
  cashier: IEmployeeDTOProps;
  company: ICompanyDTOProps;
  // workable: IProductDTOProps | IServiceDTOProps;
}

export class CashBoxDTO {
  public uuid: string;

  public box_type: CashBoxType;

  public box_title: string;

  public box_comment: string;

  public box_pos: number;

  public box_can_move_money_from: boolean;

  public box_can_move_money_to: boolean;

  public box_can_in_money_to: boolean;

  public box_can_out_money_from: boolean;

  public box_is_default_for_company: boolean;

  public box_balance: string;

  public created_at: string;

  public updated_at: string;

  workable: any; //ProductDTO | ServiceDTO;

  public created_by: ProfileDTO;

  public cashier: EmployeeDTO;

  public company: CompanyDTO;

  constructor(
    props: ICashBoxModelReturnType | ICashBoxDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';

    this.box_type = props?.box_type || CashBoxType.Cash;
    this.box_title = props?.box_title || '';
    this.box_comment = props?.box_comment || '';
    this.box_pos = props?.box_pos || 0;
    this.box_can_move_money_from = props?.box_can_move_money_from || false;
    this.box_can_move_money_to = props?.box_can_move_money_to || false;
    this.box_can_in_money_to = props?.box_can_in_money_to || false;
    this.box_can_out_money_from = props?.box_can_out_money_from || false;
    this.box_is_default_for_company =
      props?.box_is_default_for_company || false;
    this.box_balance = correctPrice(props?.box_balance || 0);
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
    // this.workable =
    //   withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
    //     ? (props as any)?.workable
    //     : ProductMapper.toProductDTO((props as any)?.workable, [
    //         WithoutRecursion.cashBox,
    //         ...withoutRecursion,
    //       ]);

    this.created_by =
      withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
        ? (props?.created_by as ProfileDTO)
        : ProfileMapper.toProfileDTO(props?.created_by, [
            WithoutRecursion.cashBox,
            ...withoutRecursion,
          ]);

    this.cashier =
      withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
        ? (props?.cashier as EmployeeDTO)
        : EmployeeMapper.toEmployeeDTO(props?.cashier, [
            WithoutRecursion.cashBox,
            ...withoutRecursion,
          ]);
    this.company =
      withoutRecursion.indexOf(WithoutRecursion.company) !== -1
        ? (props?.company as CompanyDTO)
        : CompanyMapper.toCompanyDTO(props?.company, [
            WithoutRecursion.cashBox,
            ...withoutRecursion,
          ]);
  }
}
