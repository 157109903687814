import React from 'react';
import './ServiceList.less';
import {useTranslation} from 'react-i18next';
import {ServiceColorTag, ServicePersonalityView} from '../Show';
import {CompanyService} from '../../../services/helpers';
import {ServicesDeleteButton, ServicesUpdateButton} from '../Buttons';
import {ServiceFormDTO, ServiceMapper, ServiceModel} from '../../../struture';
import styled from 'styled-components';
import {useStoredCompanies} from '../../../hooks';
import {useAcl} from '../../../contex';
import {List} from 'immutable';
import {RowSelection} from '../../../services/types';
import {
  Table,
  TableActionCell,
  TableLinkCell,
} from '../../../components/lib/libV2/DataDisplay';

export interface ServiceListProps {
  services: List<ServiceModel> | null;
  loading: boolean;
  withoutActions?: boolean;
  limit?: number;
  onRefresh: (value: any) => Promise<List<any> | void>;
  rowSelection: RowSelection<any>;
  total: number;
  daleyFocus?: (ms?: number) => void;
  handleUpdate: (value: ServiceFormDTO) => Promise<ServiceModel>;
  handleDelete: (ids: string[]) => Promise<void>;
}

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const StyledSubTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default function ServiceList({
  services,
  limit = 10,
  loading,
  onRefresh = async () => {},
  total,
  withoutActions = false,
  daleyFocus,
  ...rest
}: ServiceListProps): JSX.Element {
  const {t} = useTranslation();
  const {handleUpdate, handleDelete, ...restProps} = rest;
  const {manage} = useAcl(({service}) => service);
  const {defaultCompanyUuid, defaultCompany} = useStoredCompanies();

  const columnsWithoutActions = [
    {
      key: 'name',
      ellipsis: true,
      title: t('Name'),
      width: '90%',
      className: 'service-list-td service-list--title',
      render: (service: ServiceModel) => (
        <TableLinkCell
          tooltip="Open a detailed description of the service"
          path={`${service?.uuid}`}>
          <StyledTitleContainer>
            {service?.title}
            <StyledSubTitleContainer>
              <ServiceColorTag color={service?.color_schema} />
              <ServicePersonalityView service={service} />
            </StyledSubTitleContainer>
          </StyledTitleContainer>
        </TableLinkCell>
      ),
    },
    {
      ellipsis: true,
      title: `${t('Price')}, ${defaultCompany?.currency_symbol}`,
      key: 'price',
      width: 100,
      align: 'right' as any,
      render: (service: CompanyService) => service?.price || '0.00',
    },
  ];

  const columns: any[] = withoutActions
    ? columnsWithoutActions
    : [
        ...columnsWithoutActions,
        {
          title: '',
          key: 'actions',
          fixed: 'right' as any,
          align: 'center' as any,
          className: 'service-list-td service-list-indent service-list',
          render: (service: ServiceModel) => {
            return (
              <TableActionCell className="service-list--hidden">
                <ServicesUpdateButton
                  size="small"
                  disabled={!manage}
                  service={ServiceMapper.toServiceFormDTO(service, true)}
                  onSuccess={handleUpdate}
                  companyUuid={defaultCompanyUuid}
                  onCancel={daleyFocus}
                />
                <ServicesDeleteButton
                  disabled={!manage}
                  services={[service]}
                  onSuccess={handleDelete}
                />
              </TableActionCell>
            );
          },
        },
      ];

  return (
    <Table
      {...restProps}
      tableLayout="fixed"
      loading={loading}
      rowKey="uuid"
      dataSource={services}
      columns={columns}
      pageSize={limit ?? 10}
      total={total}
      onRow={(record: any, index: number | undefined) => ({
        className: 'service-list--row',
      })}
      onChange={onRefresh}
    />
  );
}
