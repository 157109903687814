import {Record} from 'immutable';
import {
  ActiveSubscriptionDTO,
  SubscriptionName,
  SubscriptionPlans,
} from '../internal';

export interface IActiveSubscriptionModelReturnType {
  name: SubscriptionName;
  expired_at: string;
}

export class ActiveSubscriptionModel extends Record<IActiveSubscriptionModelReturnType>(
  {
    name: SubscriptionPlans.Empty,
    expired_at: '',
  },
) {
  constructor(props: ActiveSubscriptionDTO = {} as ActiveSubscriptionDTO) {
    super(props);
  }
}
