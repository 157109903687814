import {LoginForm} from '../DataEntry';
import {Modal} from 'antd';
import React, {useContext, useState} from 'react';
import {useCancellablePromise} from '../../../hooks';
import {UnauthorizedApiError} from '../../../services/error';

export const ActionErrorContext = React.createContext({});

export const ActionErrorProvider = ({children, initialError = null}) => {
  const [actionError, setActionError] = useState(initialError);

  const actionErrorContext = {
    actionError,
    setActionError,
  };

  return (
    <ActionErrorContext.Provider value={actionErrorContext}>
      {children}
    </ActionErrorContext.Provider>
  );
};

function ActionErrorHandlerInner({children}) {
  const {actionError, setActionError} = useContext(ActionErrorContext);

  const getModalContent = () => {
    if (!actionError) {
      return null;
    }
    switch (actionError.type) {
      case ActionError.ERROR_TYPE_SESSION:
        return (
          <div>
            <h2>Session expired</h2>
            <LoginForm
              onSuccess={() => {
                setActionError(null);
                window.location.reload();
              }}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    (<div>
      {children}
      <Modal
        title={null}
        open={!!actionError}
        closable={false}
        footer={null}>
        {getModalContent()}
      </Modal>
    </div>)
  );
}


export default function ActionErrorHandler(props) {
  return (
    <ActionErrorProvider>
      <ActionErrorHandlerInner {...props}/>
    </ActionErrorProvider>
  );
}

class ActionError {
  constructor(type, error) {
    this.type = type;
    this.errorObj = error;
  }
}

ActionError.ERROR_TYPE_SESSION = 'session';

export function useAction(options = {}) {
  const opts = {
    ...{
      middlewares: {...{session: true}, ...options.middlewares},
    },
  };

  const {setActionError} = useContext(ActionErrorContext);
  const {cancellablePromise} = useCancellablePromise();

  function action(p) {
    if (p.then) {
      let newP = cancellablePromise(p);
      if (opts.middlewares.session) {
        newP = sessionMiddleware(p);
      }
      return newP;
    } else {
      try {
        p();
      } catch (e) {
        throw e;
      }
    }
  }

  function sessionMiddleware(p) {
    p.catch((e) => {
      if (e instanceof UnauthorizedApiError) {
        setActionError(new ActionError(ActionError.ERROR_TYPE_SESSION, e));
      } else {
        throw e;
      }
    });
    return p;
  }

  return {action};
}
