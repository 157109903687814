import * as React from 'react';
import {
  getNotEmptyValueFromEntriesObject,
  isThereContent,
  reduce,
  isRecordToObject,
} from '../services/helpers';

export default function useRequiredFields<T, U>(
  data: T,
  fromData: U,
  mutationCallback?: (fields: any, data: T) => any,
): T {
  return React.useMemo(() => {
    if (isThereContent(data)) {
      const requiredFields = reduce(
        getNotEmptyValueFromEntriesObject(
          Object.entries(fromData as any),
          data,
        ),
        {},
      )(Object.entries(isRecordToObject(data)));

      if (mutationCallback) {
        return mutationCallback(requiredFields, data);
      }

      return requiredFields;
    }
  }, [data, fromData, mutationCallback]);
}
