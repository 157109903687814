import React, {JSX, RefObject, useCallback, useMemo} from 'react';
import {Empty, Select} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {
  ClientModel,
  EmployeeModel,
  InvoiceItemFormDTO,
  ProfileModel,
  ScheduleCalendarModel,
} from '../../../struture';
import styled from 'styled-components';
import {ITableProps, Table} from '../../../components/lib/libV2/DataDisplay';
import {useTranslation} from 'react-i18next';
import {useStateScheduleList, useStoredCompanies} from '../../../hooks';
import {
  debounce,
  toDateByFormat,
  toUtcDateInFormat,
} from '../../../services/helpers';
import {
  ColorView,
  DetailsItemView,
  LoadingMore,
} from '../../../components/lib/DataDisplay';
import {
  SelectedItemType,
  InvoiceItemListSelectState,
} from './InvoiceItemListSelect';
import {List} from 'immutable';
import {OrderDetailsView} from '../../Orders';
import {Routes} from '../../../services/types';
import {StyledDescription} from '../../../components/lib/Styled';

import '../../Orders/List/OrderList.less';
import '../../Products/Pages/ProductList.less';
import '../../Stores/Forms/StoreProductListField.less';
import {GetCompanyScheduleState} from '../../../services/api/company';

export interface InvoiceOrderListSelectProps {
  disabled: boolean;
  selectRef: RefObject<any>;
  onTableKeyboardChange: (e: any) => void;
  handleInitItemForm: (item: SelectedItemType) => void;
  resetSelectedItemState: () => void;
  selectedItem: InvoiceItemFormDTO;
  selectedItemIndex: number;
  itemListKeywords: string;
  updateItemState: (
    value:
      | ((
          value: InvoiceItemListSelectState,
        ) => Partial<InvoiceItemListSelectState>)
      | Partial<InvoiceItemListSelectState>,
  ) => void;
  isInitItemModals: React.MutableRefObject<boolean>;
  residentUuid: string | undefined;
}

const StyledTable = styled(Table)`
  margin: 0;
` as React.ComponentType as React.FC<ITableProps<ScheduleCalendarModel>>;

const StyledSelect = styled(Select)`
  display: flex;
  flex: 1;

  & .ant-select-selection-placeholder {
    white-space: unset !important;
  }
`;

const StyledSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex: 1;
`;

export function InvoiceOrderListSelect({
  disabled,
  selectRef,

  selectedItem,
  selectedItemIndex,
  itemListKeywords,

  onTableKeyboardChange,
  handleInitItemForm,
  resetSelectedItemState,
  updateItemState,

  isInitItemModals,
  residentUuid,
}: InvoiceOrderListSelectProps): JSX.Element {
  const {t} = useTranslation();
  const {defaultCompanyUuid, defaultCompany} = useStoredCompanies();

  const {
    schedules: orderList,
    loading,
    isLoadingMore,
    total,
    limit,

    handleSearchSchedule,
    handleLoadingMoreScheduleList,
    handleSetScheduleList,
  } = useStateScheduleList({
    companyUuid: defaultCompanyUuid,
    loadOnInit: false,
    minKeywordLength: 3,
    limit: 100,
    state: GetCompanyScheduleState.UNPAID,
    client_uuid: residentUuid,
  });

  const debounceHandleSearchProducts = useMemo(
    () =>
      debounce(async (value: any) => {
        const orders = await handleSearchSchedule({
          ...value,
          companyUuid: defaultCompanyUuid,
        });

        if (List.isList(orders)) {
          updateItemState({
            itemList: orders,
            selectedItem: orders?.first(),
          });
        }
      }, 1000),
    [handleSearchSchedule, defaultCompanyUuid, updateItemState],
  );

  const onSearch = useCallback(
    async (keywords: string) => {
      updateItemState({itemListKeywords: keywords});

      if (keywords?.length < 3) {
        setTimeout(() => {
          if (!isInitItemModals.current) {
            resetSelectedItemState();
            handleSetScheduleList(List());
          }
        }, 100);
        return;
      }

      await debounceHandleSearchProducts({
        keywords,
      });
    },
    [
      debounceHandleSearchProducts,
      handleSetScheduleList,
      isInitItemModals,
      resetSelectedItemState,
      updateItemState,
    ],
  );

  const onLoadingMore = useCallback(async () => {
    const orderList = await handleLoadingMoreScheduleList({});

    if (List.isList(orderList)) {
      updateItemState((prevState) => ({
        itemList: (prevState?.itemList || List()).merge(orderList) as any,
      }));
    }
  }, [handleLoadingMoreScheduleList, updateItemState]);

  const columns: any = [
    {
      title: t('Order'),
      key: 'order',
      className: 'order-list-indent',
      render: (c: ScheduleCalendarModel) => (
        <OrderDetailsView withoutNavigate withCategoryLabel schedule={c} />
      ),
    },
    {
      title: t('Client'),
      key: 'client',
      className: 'order-list-indent',
      render: (c: ScheduleCalendarModel) => (
        <DetailsItemView<ClientModel>
          item={c?.clientModel}
          fields={{
            fullNameClient: {
              link: 'Go to client details page',
              path: `/${Routes.app}/${Routes.clients}/${c?.clientModel?.uuid}`,
            },
            phone: {description: ''},
          }}
        />
      ),
    },
    {
      title: t('Manager'),
      key: 'manager',
      className: 'order-list-indent',
      render: (c: ScheduleCalendarModel) => (
        <DetailsItemView<EmployeeModel>
          item={c?.employeeModel}
          fields={{
            fullName: {
              link: 'Go to manager details page',
              path: `/${Routes.app}/${Routes.employees}/${c?.employeeModel?.uuid}`,
            },
            phone: {description: ''},
          }}
        />
      ),
    },
    {
      title: t('Created'),
      key: 'created',
      className: 'order-list-indent',
      render: (c: ScheduleCalendarModel) => {
        return (
          <DetailsItemView<ProfileModel>
            item={c?.createdByModel}
            fields={{
              fullName: {
                title: '',
              },
              uuid: {
                description: (
                  <StyledDescription>
                    {toDateByFormat(c?.created_at, 'DD.MM.YYYY HH:mm')}
                  </StyledDescription>
                ),
              },
            }}
          />
        );
      },
    },
    {
      title: t('Assigned to'),
      key: 'date',
      className: 'order-list-indent',
      render: (c: ScheduleCalendarModel) => (
        <ColorView color={c?.status_color}>
          {toUtcDateInFormat(c?.scheduled_date, 'DD.MM.YYYY HH:mm')}
        </ColorView>
      ),
    },
    {
      title: `${t('Sum')}, ${defaultCompany?.currency_symbol || ''}`,
      key: 'sum',
      fixed: 'right',
      align: 'right' as any,
      className: 'order-list-td order-list-indent',
      render: (c: ScheduleCalendarModel) => (
        <ColorView color={c?.status_color}>
          {Number(c?.sum_total) > 0 ? `${c?.sum_total}` : <>-</>}
        </ColorView>
      ),
    },
  ];

  return (
    <StyledSelectContainer>
      <StyledSelect
        popupClassName="store-sell-product-select-popup posting-select-popup"
        className="store-sell-product-select"
        ref={selectRef as any}
        loading={loading}
        disabled={disabled}
        showSearch
        onKeyUp={onTableKeyboardChange}
        searchValue={itemListKeywords}
        dropdownRender={() => (
          <div
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}>
            {loading && orderList !== null ? (
              <Empty>
                <LoadingOutlined />
              </Empty>
            ) : loading || !itemListKeywords || itemListKeywords?.length < 3 ? (
              <Empty
                description={t(
                  'To select a order, enter the search data, at least 3 characters',
                )}
              />
            ) : itemListKeywords && !orderList?.size ? (
              <Empty
                description={t(
                  'It seems that more than one order was not found for your request',
                )}
              />
            ) : (
              <>
                <StyledTable
                  className="posting-list-form"
                  pagination={false}
                  pageSize={limit}
                  total={total}
                  dataSource={orderList}
                  columns={columns}
                  onRow={(record: ScheduleCalendarModel, rowIndex: any) => {
                    return {
                      onClick: () => {
                        handleInitItemForm(record);

                        if (selectRef?.current) {
                          selectRef?.current?.blur();
                        }
                      },
                      className: `posting-list-td posting-list-td-${rowIndex} ${
                        record?.uuid === selectedItem?.uuid &&
                        selectedItemIndex === rowIndex
                          ? 'posting-list-td--selected'
                          : ''
                      }`,
                    };
                  }}
                />
                <LoadingMore
                  loading={isLoadingMore}
                  observerCallback={onLoadingMore}
                />
              </>
            )}
          </div>
        )}
        onSearch={onSearch}
        placeholder={t('Enter order details to search for it')}
      />
    </StyledSelectContainer>
  );
}
