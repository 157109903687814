import {Record} from 'immutable';
import {
  CompanyDTO,
  CompanyMapper,
  CompanyModel,
  EmployeeDTO,
  EmployeeMapper,
  EmployeeModel,
  IProductBalances,
  IProductPrice,
  ProductBarCodeFormat,
  ProductCategoryDTO,
  ProductCategoryMapper,
  ProductCategoryModel,
  ProductDTO,
  ProductStatus,
} from '../internal';
import {WithoutRecursion} from '../type';

export interface IProductModelReturnType {
  uuid: string;
  product_code: string;
  product_title: string;
  product_barcode: string;
  product_vendor_code: string;
  product_description: string;
  product_net_price: string;
  product_retail_price: string;
  created_at: string;
  updated_at: string;
  category: ProductCategoryDTO | ProductCategoryModel;
  created_by: EmployeeModel | EmployeeDTO;
  company: CompanyModel | CompanyDTO;
  product_prices: IProductPrice[];
  product_balances: IProductBalances[];
  product_max_income_price: number;
  product_bar_code_format: ProductBarCodeFormat;
  product_min_amount: number;
  product_status: ProductStatus;
  product_photo: string;
}

/**
 * @class Product
 * @desc Клієнт
 */
export class ProductModel extends Record<IProductModelReturnType>({
  uuid: '',
  product_code: '',
  product_title: '',
  product_barcode: '',
  product_vendor_code: '',
  product_description: '',
  product_net_price: '',
  product_retail_price: '',
  created_at: '',
  updated_at: '',
  category: {} as ProductCategoryModel,
  company: {} as CompanyModel,
  created_by: {} as EmployeeModel,
  product_prices: [],
  product_balances: [],
  product_max_income_price: 0,
  product_bar_code_format: ProductBarCodeFormat.EAN13,
  product_min_amount: 0,
  product_status: ProductStatus.DISABLED,
  product_photo: '',
}) {
  constructor(
    props: ProductDTO = {} as ProductDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IProductModelReturnType = {
      ...props,
      category:
        withoutRecursion.indexOf(WithoutRecursion.category) !== -1
          ? props?.category
          : ProductCategoryMapper.toProductCategoryModel(props?.category, [
              WithoutRecursion.category,
              ...withoutRecursion,
            ]),
      created_by:
        withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
          ? props?.created_by
          : EmployeeMapper.toEmployeeModel(props?.created_by, [
              WithoutRecursion.employee,
              ...withoutRecursion,
            ]),
      company:
        withoutRecursion.indexOf(WithoutRecursion.company) !== -1
          ? props?.company
          : CompanyMapper.toCompanyModel(props?.company, [
              WithoutRecursion.profile,
              ...withoutRecursion,
            ]),
    };

    super(options);
  }

  get companyModel(): CompanyModel {
    return this.company instanceof CompanyModel
      ? this.company
      : CompanyMapper.toCompanyModel(this.company);
  }

  get createdByModel(): EmployeeModel {
    return this.created_by instanceof EmployeeModel
      ? this.created_by
      : EmployeeMapper.toEmployeeModel(this.created_by);
  }

  get categoryModel(): ProductCategoryModel {
    return this.category instanceof ProductCategoryModel
      ? this.category
      : ProductCategoryMapper.toProductCategoryModel(this.category);
  }
}
