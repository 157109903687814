import React, {useCallback, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {SideWindow} from '../../../../components/lib/Navigation';
import {StoreReturnClientForm, IStoreReturnClientFormProps} from '../Forms';
import {StoreDocumentFormDTO} from '../../../../struture';
import {Modal} from 'antd';
import {useModal} from '../../../../components/lib/libV2/hooks';
import {StyledTitle} from '../../../../components/lib/Styled';
import {CloseOutlined} from '@ant-design/icons';
import {
  isEqualStoreDocumentFields,
  StoreDocumentRefObject,
} from '../../helpers';

export interface IStoreReturnClientSideWindowProps
  extends IStoreReturnClientFormProps {
  onSuccess: (value: StoreDocumentFormDTO) => Promise<void>;
  visible: boolean;
}

export default function StoreReturnClientReturnSideWindow({
  onSuccess,
  visible,
  editMode,
  onCancel,
  document,
  ...rest
}: IStoreReturnClientSideWindowProps) {
  const {t} = useTranslation();
  const [modal, contextHolder] = Modal.useModal();

  const documentEditRef = useRef<StoreDocumentRefObject>({
    document,
    initStoreUuid: '',
  });

  const {
    handleCancel: handleCancelCloseModal,
    handleSuccess: handleSuccessCloseModal,
  } = useModal({
    onSuccess: onCancel,
  });

  const handleCancelSideWindows = useCallback(() => {
    const {isEqual, isEqualClient, isEqualRelatedDocumentUuid} =
      isEqualStoreDocumentFields(document, documentEditRef?.current);

    if (isEqual && isEqualClient && isEqualRelatedDocumentUuid) {
      return handleSuccessCloseModal(undefined);
    }

    modal.confirm({
      title: (
        <StyledTitle>{t('You really want to close the form?')}</StyledTitle>
      ),
      style: {minWidth: '40%'},
      okText: t('Yes'),
      cancelText: t('No'),
      onOk: handleSuccessCloseModal,
      onCancel: handleCancelCloseModal,
    });
  }, [document, modal, t, handleSuccessCloseModal, handleCancelCloseModal]);

  return (
    <>
      <SideWindow
        title={t('Return to client')}
        visible={visible}
        closeIcon={
          <CloseOutlined
            onClick={(e) => {
              e.stopPropagation();
              handleCancelSideWindows();
            }}
          />
        }
        onClose={handleCancelSideWindows}>
        {visible ? (
          <StoreReturnClientForm
            document={document}
            documentEditRef={documentEditRef}
            editMode={editMode}
            onSuccess={onSuccess}
            onCancel={onCancel}
            {...rest}
          />
        ) : null}
      </SideWindow>
      {contextHolder}
    </>
  );
}
