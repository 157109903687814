export interface CheckboxAttributesCashier {
  cashier_title: string;
  cashier_login: string;
  cashier_password: string;
}

export interface CheckboxAttributesCashBox {
  uuid?: string;
  cashbox_name: string;
  cashbox_id: string;
  cashbox_license_key: string;
  cashbox_cashiers: CheckboxAttributesCashier[];
  cashbox_relations: string[];
}

export const INTEGRATION_CASH_BOX_FORM_PARAMS: CheckboxAttributesCashBox = {
  cashbox_name: '',
  cashbox_id: '',
  cashbox_license_key: '',
  cashbox_cashiers: [
    {
      cashier_title: '',
      cashier_login: '',
      cashier_password: '',
    },
  ],
  cashbox_relations: [],
};

export interface IntegrationCheckboxAttributesDTO {
  cashboxes: CheckboxAttributesCashBox[];
}
