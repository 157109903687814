import {
  CompanyDTO,
  CompanyMapper,
  ICompanyDTOProps,
  IStoreModelReturnType,
  IProfileDTOProps,
  ProfileDTO,
  ProfileMapper,
  DepartmentMapper,
  DepartmentDTO,
  IDepartmentDTOProps,
  IEmployeeDTOProps,
  EmployeeDTO,
  EmployeeMapper,
} from '../internal';
import {WithoutRecursion} from '../type';

export enum StoreStatusesText {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  DELETED = 'deleted',
  ERROR = 'error',
}

export interface IStoreSettings {
  permissions: {
    input: {
      allow_in: boolean;
      allow_return: boolean;
      allow_move: boolean;
    };
    output: {
      allow_erase: boolean;
      allow_order: boolean;
      allow_return: boolean;
      allow_move: boolean;
      allow_sell: boolean;
    };
  };
}

export enum StoreType {
  PRODUCT = 'product',
  INVENTORY = 'inventory',
  CUSTOM = 'custom',
}

export enum StoreBelongingTo {
  COMPANY = 'Company',
  DEPARTMENT = 'Department',
}

export interface IStoreDTOProps {
  uuid: string;
  store_name: string;
  store_description: string;
  store_status_text: StoreStatusesText;
  created_at: string;
  updated_at: string;
  company_department: IDepartmentDTOProps;
  created_by: IProfileDTOProps;
  company: ICompanyDTOProps;
  manager: IEmployeeDTOProps;
  store_settings: IStoreSettings;
  store_type: StoreType;
}

export class StoreDTO {
  public uuid: string;

  public store_name: string;

  public store_description: string;

  public store_status_text: StoreStatusesText;

  public created_at: string;

  public updated_at: string;

  public company_department: DepartmentDTO;

  public created_by: ProfileDTO;

  public company: CompanyDTO;
  public manager: EmployeeDTO;
  public store_settings: IStoreSettings;
  public store_type: StoreType;

  constructor(
    props: IStoreModelReturnType | IStoreDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';

    this.store_name = props?.store_name || '';
    this.store_description = props?.store_description || '';
    this.store_status_text =
      props?.store_status_text || StoreStatusesText.DISABLED;
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
    this.store_type = props?.store_type || StoreType.PRODUCT;
    this.store_settings = props?.store_settings || ({} as IStoreSettings);

    this.company_department =
      withoutRecursion.indexOf(WithoutRecursion.department) !== -1
        ? (props?.company_department as DepartmentDTO)
        : DepartmentMapper.toDepartmentDTO(props?.company_department, [
            WithoutRecursion.store,
            ...withoutRecursion,
          ]);
    this.created_by =
      withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
        ? (props?.created_by as ProfileDTO)
        : ProfileMapper.toProfileDTO(props?.created_by, [
            WithoutRecursion.department,
            ...withoutRecursion,
          ]);

    this.company =
      withoutRecursion.indexOf(WithoutRecursion.company) !== -1
        ? (props?.company as CompanyDTO)
        : CompanyMapper.toCompanyDTO(props?.company, [
            WithoutRecursion.department,
            ...withoutRecursion,
          ]);

    this.manager =
      withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
        ? (props?.manager as EmployeeDTO)
        : EmployeeMapper.toEmployeeDTO(props?.manager, [
            WithoutRecursion.department,
            ...withoutRecursion,
          ]);
  }
}
