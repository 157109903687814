import {PROFILE} from '../constants';
import {ProfileModel} from '../../struture';

export interface IInitialState {
  profile: ProfileModel;
  error: string | null;
  loading: boolean;
  is_admin: boolean;
}

export interface SetAction extends Pick<IInitialState, 'profile'> {
  type: PROFILE.SET_PROFILE;
}

export interface UpdateAction extends Pick<IInitialState, 'profile'> {
  type: PROFILE.UPDATE_PROFILE;
}

export interface LoadingAction {
  type: PROFILE.LOADING_PROFILE;
}

export interface ErrorAction extends Pick<IInitialState, 'error'> {
  type: PROFILE.ERROR_PROFILE;
}

export interface ResetAction {
  type: PROFILE.RESET_PROFILE;
}

export interface Handlers {
  [PROFILE.SET_PROFILE]: (
    state: IInitialState,
    action: SetAction,
  ) => IInitialState;

  [PROFILE.RESET_PROFILE]: (
    state: IInitialState,
    action: ResetAction,
  ) => IInitialState;

  [PROFILE.UPDATE_PROFILE]: (
    state: IInitialState,
    action: UpdateAction,
  ) => IInitialState;

  [PROFILE.ERROR_PROFILE]: (
    state: IInitialState,
    value: ErrorAction,
  ) => IInitialState;

  [PROFILE.LOADING_PROFILE]: (
    state: IInitialState,
    value?: LoadingAction,
  ) => IInitialState;
  DEFAULT: (state: IInitialState) => IInitialState;
}

const initState: IInitialState = {
  profile: {} as ProfileModel,
  error: null,
  loading: true,
  is_admin: false,
};

const handlers: Handlers = {
  [PROFILE.SET_PROFILE]: (state, {profile}) => ({
    ...state,
    ...{
      loading: false,
      is_admin: profile?.is_admin || false,
      profile,
    },
  }),

  [PROFILE.RESET_PROFILE]: (state) => ({
    ...state,
    ...initState,
  }),

  [PROFILE.UPDATE_PROFILE]: (state, {profile}) => ({
    ...state,
    ...{
      loading: false,
      is_admin: profile?.is_admin || false,
      profile,
    },
  }),
  [PROFILE.ERROR_PROFILE]: (state: IInitialState, {error}: ErrorAction) => ({
    ...state,
    ...{
      error,
      loading: false,
    },
  }),
  [PROFILE.LOADING_PROFILE]: (state: IInitialState) => ({
    ...state,
    ...{
      loading: true,
    },
  }),
  DEFAULT: (state: IInitialState) => state,
};

export function Profile(state: IInitialState, action: SetAction): IInitialState;

export function Profile(
  state: IInitialState,
  action: LoadingAction,
): IInitialState;

export function Profile(
  state: IInitialState,
  action: ErrorAction,
): IInitialState;

export default function Profile(
  state: any = initState,
  action: any,
): IInitialState {
  const handler = handlers[action.type as PROFILE] || handlers.DEFAULT;
  return handler(state, action);
}
