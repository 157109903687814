import {List} from 'immutable';
import {
  NotificationDTO,
  INotificationDTOProps,
  NotificationModel,
  NotificationListModel,
} from '../internal';
import {listToArray} from '../../helpers';

export interface INotificationListDTO {
  notifications: NotificationDTO[];
  total: number;
  unread: number;
}

export class NotificationMapper {
  public static toNotificationDTO(
    notification: NotificationModel | INotificationDTOProps,
  ): NotificationDTO {
    return new NotificationDTO(notification);
  }

  public static toNotificationListDTO(
    notifications: List<NotificationModel> | INotificationDTOProps[],
    total: number,
    unread: number,
  ): INotificationListDTO {
    const notificationList = List.isList(notifications)
      ? listToArray<NotificationModel>(notifications)
      : notifications;

    return {
      notifications: notificationList?.map((notification) =>
        NotificationMapper.toNotificationDTO(notification),
      ),
      total: total || 0,
      unread: unread || 0,
    };
  }

  public static toNotificationModel(
    notificationDTO: NotificationDTO,
  ): NotificationModel {
    return new NotificationModel(notificationDTO);
  }

  public static toNotificationListModel(
    notificationDTOs: NotificationDTO[],
    total: number,
    unread: number,
  ): NotificationListModel {
    return new NotificationListModel({
      notifications: notificationDTOs,
      total,
      unread,
    });
  }
}
