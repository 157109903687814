import {
  CompanyDTO,
  CompanyMapper,
  ICompanyDTOProps,
  IProfileSubscriptionDTOProps,
  IUserModelReturnType,
  ProfileSubscriptionDTO,
  ProfileSubscriptionMapper,
} from '../internal';
import {WithoutRecursion} from '../type';
import {GENDER} from '../../types';
import {toDateByFormat} from '../date';
import {DATE_SHORT_FORMAT} from '../type';

export interface IUserDTOProps {
  uuid: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  phone: string;
  email: string;
  address: string;
  birthday: string;
  picture_url: string;
  status_text: string;
  card_number: string;
  login: string;
  password: string;
  confirm_password: string;
  subscription_discount: number;
  phone_approved: number;
  email_approved: number;
  status: number;
  goods_discount: number;
  gender: GENDER;
  profile_subscriptions: IProfileSubscriptionDTOProps;
  default_company: ICompanyDTOProps;
  created_at: string;
  updated_at: string;
}

export class UserDTO {
  public uuid: string;

  public first_name: string;

  public last_name: string;

  public middle_name: string;

  public phone: string;

  public email: string;

  public address: string;

  public birthday: string;

  public picture_url: string;

  public status_text: string;

  public card_number: string;

  public login: string;

  public password: string;

  public confirm_password: string;

  public subscription_discount: number;

  public phone_approved: number;

  public email_approved: number;

  public status: number;

  public goods_discount: number;

  public gender: GENDER;

  public profile_subscriptions: ProfileSubscriptionDTO[];

  public default_company: CompanyDTO;

  public created_at: string;

  public updated_at: string;

  constructor(
    props: IUserModelReturnType | IUserDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';
    this.first_name = props?.first_name || '';
    this.last_name = props?.last_name || '';
    this.middle_name = props?.middle_name || '';
    this.phone = props?.phone || '';
    this.email = props?.email || '';
    this.address = props?.address || '';
    this.birthday = props?.birthday
      ? toDateByFormat(props?.birthday, DATE_SHORT_FORMAT)
      : '';
    this.picture_url = props?.picture_url || '';
    this.status_text = props?.status_text || '';
    this.card_number = props?.card_number || '';
    this.login = props?.login || '';
    this.password = props?.password || '';
    this.confirm_password = props?.confirm_password || '';
    this.subscription_discount = props?.subscription_discount || 0;
    this.phone_approved = props?.phone_approved || 0;
    this.email_approved = props?.email_approved || 0;
    this.status = props?.status || 0;
    this.goods_discount = props?.goods_discount || 0;
    this.gender = props?.gender || GENDER.FEMALE;
    this.profile_subscriptions =
      withoutRecursion.indexOf(WithoutRecursion.profileSubscriptions) !== -1
        ? ((props?.profile_subscriptions || []) as ProfileSubscriptionDTO[])
        : ProfileSubscriptionMapper.toProfileSubscriptionListDTO(
            (props?.profile_subscriptions || []) as any,
          );
    this.default_company =
      withoutRecursion.indexOf(WithoutRecursion.company) !== -1
        ? (props?.default_company as CompanyDTO)
        : CompanyMapper.toCompanyDTO(props?.default_company, [
            WithoutRecursion.user,
            ...withoutRecursion,
          ]);
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
  }
}
