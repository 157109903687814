import {Record, List} from 'immutable';
import {IListProps} from './List';
import {GroupPaymentDTO, GroupPaymentModel} from '../internal';
import {WithoutRecursion} from '../type';

export interface IGroupPaymentListModelProps extends IListProps {
  groups: GroupPaymentDTO[];
}

export interface IGroupPaymentListModelReturnType extends IListProps {
  groups: List<GroupPaymentModel>;
}

export class GroupPaymentListModel extends Record<IGroupPaymentListModelReturnType>(
  {
    groups: List(),
    total: 0,
  },
) {
  constructor(
    {
      groups = [],
      total,
    }: IGroupPaymentListModelProps = {} as IGroupPaymentListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IGroupPaymentListModelReturnType = {
      groups: List(
        groups.map((group) => new GroupPaymentModel(group, withoutRecursion)),
      ),
      total,
    };

    super(options);
  }
}
