import * as React from 'react';
import {List} from 'immutable';
import {Moment} from 'moment';
import {ApiError, IEntityProps, IListSearchProps} from '../services/types';
import {IPaymentListDTO, PaymentMapper, PaymentModel} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {getCompanyPaymentList} from '../services/api/companyPayments';
import {SessionStorageItems} from '../services/const';
import useStoredCompanies from './useStoredCompanies';
import {PERIOD_MOVEMENT_FUNDS, toMoment} from '../services/helpers';
import {
  IPaymentScheduleListStats,
  PaymentScheduleOperationType,
} from '../services/api/orders';
import {useMemo} from 'react';

export interface IUsePaymentListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  companyUuid: string;
  cashbox_uuid: string;
  date_start?: Date | Moment | string;
  date_end?: Date | Moment | string;
  isSearch?: boolean;
  payment_type?: PaymentScheduleOperationType;
  isStartLoading?: boolean;
  isClearDate?: boolean;
}

export interface IUsePaymentListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<PaymentModel> | null;
  refresh: (
    value: Partial<IUsePaymentListProps>,
  ) => Promise<List<PaymentModel> | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  companyUuid: string;
  cashbox_uuid: string;
  date_start: Date | Moment | string;
  date_end?: Date | Moment | string;
  payment_type?: PaymentScheduleOperationType;
  stats: IPaymentScheduleListStats;
}

export const periodStatement = {
  [PERIOD_MOVEMENT_FUNDS.Week]: [1, 'week'],
  [PERIOD_MOVEMENT_FUNDS.Two_week]: [2, 'week'],
  [PERIOD_MOVEMENT_FUNDS.Month]: [1, 'month'],
};

export function usePaymentList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    companyUuid,
    date_start,
    date_end,
    payment_type = PaymentScheduleOperationType.ALL,
    cashbox_uuid,
  }: IUsePaymentListProps = {} as IUsePaymentListProps,
): IUsePaymentListReturnType {
  const {cancellablePromise, didCancel} = useCancellablePromise();
  const {settings} = useStoredCompanies();

  const paymentRangeDates = useMemo(
    () =>
      JSON.parse(
        sessionStorage.getItem(SessionStorageItems.paymentRangeDates) ||
          JSON.stringify({}),
      ),
    [],
  );

  date_start = paymentRangeDates?.start
    ? toMoment(paymentRangeDates?.start)
    : date_start || '';
  date_end = paymentRangeDates?.end
    ? toMoment(paymentRangeDates?.end)
    : date_end || '';

  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    companyUuid: listCompanyUuid,
    date_start: listStartDate,
    date_end: listEndDate,
    stats: listStats,
    payment_type: listType,
    cashbox_uuid: listCashboxUuid,
    handleUpdate,
  } = useStateReducer<Omit<IUsePaymentListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    companyUuid,
    date_start,
    date_end,
    stats: {} as IPaymentScheduleListStats,
    payment_type,
    cashbox_uuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      companyUuid = listCompanyUuid,
      date_start = listStartDate,
      date_end = listEndDate,
      isSearch = false,
      payment_type = listType,
      cashbox_uuid = listCashboxUuid,
      isStartLoading = true,
      isClearDate = false,
    }: Partial<IUsePaymentListProps> = {}): Promise<List<PaymentModel> | void> => {
      try {
        const isCleared = paymentRangeDates?.isCleared;

        if (date_start && date_end && !isSearch) {
          sessionStorage.setItem(
            SessionStorageItems.paymentRangeDates,
            JSON.stringify({
              start: date_start,
              end: date_end,
              isCleared: false,
            }),
          );
        }

        if (isClearDate) {
          sessionStorage.setItem(
            SessionStorageItems.paymentRangeDates,
            JSON.stringify({start: '', end: '', isCleared: true}),
          );
        }

        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          companyUuid,
          date_start,
          date_end,
          cashbox_uuid,
          payment_type,
        });

        if (isStartLoading) {
          const {payments, total, stats} =
            await cancellablePromise<IPaymentListDTO>(
              getCompanyPaymentList({
                companyUuid,
                limit,
                offset,
                keywords,
                date_start,
                date_end,
                payment_type,
                cashbox_uuid,
              }),
            );

          const paymentListModel = PaymentMapper.toPaymentListModel(
            payments,
            total,
          );

          handleUpdate({
            entityList: paymentListModel?.payments,
            total: paymentListModel?.total,
            loading: false,
            stats,
          });

          return paymentListModel?.payments;
        }
      } catch (e) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }
      }
    },
    [
      cancellablePromise,
      didCancel,
      error,
      handleUpdate,
      listCashboxUuid,
      listCompanyUuid,
      listEndDate,
      listKeywords,
      listLimit,
      listOffset,
      listStartDate,
      listType,
      paymentRangeDates?.isCleared,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && companyUuid && settings) {
      (async () => {
        await refresh({companyUuid, cashbox_uuid});
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, companyUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    companyUuid: listCompanyUuid,
    date_start: listStartDate,
    date_end: listEndDate,
    stats: listStats,
    payment_type: listType,
    cashbox_uuid: listCashboxUuid,
  };
}
