// import {Locale, Calendar} from 'expo-localization';
import {reduce} from '../../helpers';

export interface ILocalizationDTOProps {
  timezone: string;
  region_code: string;
  language_code: string;
  currency_symbol: string;
}

export class LocalizationDTO {
  public timezone: string;

  public region_code: string;

  public language_code: string;

  public currency_symbol: string;

  constructor(locale: any, calendar: any) {
    this.timezone = calendar?.timeZone || '';
    this.region_code = locale?.regionCode || '';
    this.language_code = locale?.languageCode || '';
    this.currency_symbol = locale?.currencySymbol || '';
  }

  get notEmptyFields(): Partial<ILocalizationDTOProps> {
    const notEmpty = reduce(
      (acc, [key, value]) => {
        acc = value !== '' ? {...acc, [key]: value} : acc;

        return acc;
      },
      {},
      Object.entries(this),
    );

    return notEmpty;
  }
}
