import {Record, List} from 'immutable';
import {ListModel, ListProps} from './List';
import Service, {ServicesProps} from './Services';

export interface ServiceListProps extends ListProps {
  services: ServicesProps[];
}

export interface ServiceListReturnType extends ListProps {
  services: List<Service>;
}

/**
 * @class ServiceList
 * @classdesc Service list model.
 * @property {List} services - list of companies
 * @property {number} total - total amount of records on backend
 */
export default class ServiceList extends Record<ServiceListReturnType>({
  services: List(),
  ...ListModel.toJS(),
} as any) {
  /**
   * @constructor
   * @param {{services:[]}} param0
   */
  constructor({services = [], ...props}: ServiceListProps) {
    super({
      services: List(services.map((service) => new Service(service))),
      ...props,
    });
  }

  deleteItemById(uid: string): any {
    return uid;
  }
}
