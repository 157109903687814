import React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {ButtonProps} from 'antd/lib/button';
import {ActionButton} from '../../../components/lib/General';

export default withTranslation()(EventSelectButton);

export interface IEventSelectButtonProps extends WithTranslation, ButtonProps {
  children: React.ReactNode;
  tooltip: string;
}

function EventSelectButton({
  t,
  loading = false,
  children,
  tooltip,
  ...rest
}: IEventSelectButtonProps): JSX.Element {
  return (
    <ActionButton
      tooltip={t(tooltip)}
      type="primary"
      loading={loading}
      data-testid="event__button--select"
      {...rest}>
      {children}
    </ActionButton>
  );
}
