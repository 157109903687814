import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {
  SettingsModuleDependencyListItem,
  SettingsModuleListHeader,
} from '../Show';
import {SettingsModuleDependencyModel} from '../../../struture';
import {List} from 'immutable';
import {ListLayoutItem} from '../../../components/lib/Layout';
import styled from 'styled-components';

export interface ISettingsModuleDependencyListProps {
  dependencyList: List<SettingsModuleDependencyModel> | null;
  handleLinkinModuleDependency?: (dependencyUuid: string) => Promise<void>;
  isLinking: boolean;
  loadingDependencyUuid: string;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export function SettingsModuleDependencyList({
  dependencyList,
  handleLinkinModuleDependency,
  isLinking,
  loadingDependencyUuid,
}: ISettingsModuleDependencyListProps): JSX.Element {
  const {t} = useTranslation();

  return (
    <>
      {dependencyList?.size ? (
        <StyledContainer>
          <SettingsModuleListHeader
            title={t('Packages')}
            disabled={!isLinking}
          />
          {dependencyList.map((dependency, index) => {
            return (
              <ListLayoutItem<SettingsModuleDependencyModel>
                disabledItem={() => !isLinking}
                key={String(index)}
                item={dependency}>
                {(value) => (
                  <SettingsModuleDependencyListItem
                    dependency={dependency}
                    handleLinkinModuleDependency={handleLinkinModuleDependency}
                    isLinking={isLinking}
                    loadingDependencyUuid={loadingDependencyUuid}
                    {...value}
                  />
                )}
              </ListLayoutItem>
            );
          })}
        </StyledContainer>
      ) : null}
    </>
  );
}
