import React, {
  useCallback,
  useState,
  createContext,
  useContext,
  useMemo,
  useEffect,
} from 'react';
import styled, {useTheme, css} from 'styled-components';
import {Image, Button} from 'antd';
import {withTranslation, WithTranslation} from 'react-i18next';
import {FullHeightLayout} from '../Styled';
import {Text} from '../Format';
import {HeaderLeft} from '../DataDisplay';
import FooterWithoutLayout from './FooterWithoutLayout';
import {textToUpperCase, isFunction, isMobile} from '../../../services/helpers';
import {authLogoTitle, authSiteUrl} from '../../../services/const';
import {AuthRouteItem} from '../../../views/Authorization/Show';
import {AiOutlineInfoCircle} from 'react-icons/ai';
import {Routes} from '../../../services/types';
import {useModal, useStateReducer} from '../libV2/hooks';
import {useDropdownAlert} from '../../../contex';
import {useLocation, useNavigate} from 'react-router';

export default withTranslation()(AuthLayout);

export interface IAuthLayoutProps extends WithTranslation {
  children: React.ReactNode;
  withBackButton?: boolean;
}

export interface ISetOptionType {
  headerLeft: (() => React.ReactNode | React.ReactNode[]) | null;
  pathname: string;
}

export interface IAuthLayoutContext {
  visibleVerification: boolean;
  handleCancelVerification: () => void;
  handleOnInitVerification: () => void;
  handleSetVerificationStateName: (
    state: Routes.signup | Routes.forgotPassword,
  ) => void;

  setOptions: (value: Partial<ISetOptionType>) => void;
  contentWidth: number;
  siderWidth: number;
}

const AuthLayoutContext = createContext<IAuthLayoutContext>({
  visibleVerification: false,
  handleCancelVerification: () => {},
  handleOnInitVerification: () => {},
  handleSetVerificationStateName: () => {},
  setOptions: () => {},
  contentWidth: 0,
  siderWidth: 0,
});

export const useAuthLayout = () => useContext(AuthLayoutContext);

var vh = window.innerHeight * 0.01;

document.documentElement.style.setProperty('--vh', vh + 'px');

const StyledLayout = styled.div<{$isMobile: boolean}>`
  display: flex;
  width: 100vw;
  min-height: 100vh;

  ${({$isMobile}) =>
    $isMobile &&
    css`
      width: 100dvw;
      height: 100dvh;
      height: calc(var(--vh) * 100);
    `}
`;

const StyledLogoLayout = styled.div<{collapse: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  overflow: hidden;
  text-align: center;
  background-color: ${({theme}) => theme.colorsV2.corporateBgColor};
  padding-top: 10%;
  transition: all 1s;
  visibility: inherit;

  @media (max-width: 680px) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex: 0;
    width: 0;
    z-index: 2;
  }

  ${({collapse}) =>
    collapse &&
    css`
      @media (max-width: 680px) {
        flex: 1;
        width: 100%;
      }
    `};
`;

const StyledContent = styled.main`
  position: relative;
  flex: 2;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 30px;
  background-color: ${({theme}) => theme.colors.app};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  visibility: visible;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 680px) {
    box-shadow: none;
    z-index: 1;
  }
`;

const StyledLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  padding-right: 10px;
  padding-left: 10px;
`;

const StyledFooterContainer = styled.div`
  width: 90%;
`;

const StyledInfoButton = styled(Button)`
  position: absolute;
  right: 10px;
  top: 10px;
  display: none;
  width: fit-content;
  z-index: 3;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    border-color: transparent;
    opacity: 0.7;
  }

  @media (max-width: 680px) {
    display: flex;
  }
`;

const StyledImage = styled(Image)`
  width: 20vw;

  @media (max-width: 767px) {
    width: 30vw;
  }

  @media (max-width: 680px) {
    width: 55vw;
  }

  @media (max-width: 475px) {
    width: 60vw;
  }
`;

function AuthLayout({
  children,
  t,
  withBackButton = true,
}: IAuthLayoutProps): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  const [collapse, setCollapse] = useState(false);
  const [verificationStateName, setVerificationStateName] = useState<
    Routes.signup | Routes.forgotPassword
  >(Routes.signup);
  const contentRef = React.useRef<any>(null);
  const siderRef = React.useRef<any>(null);
  const [contentWidth, setContentWidth] = React.useState(0);
  const [siderWidth, setSiderWidth] = React.useState(0);

  const {setDropdownOptions} = useDropdownAlert();

  const {headerLeft, pathname, handleUpdate} = useStateReducer<ISetOptionType>({
    headerLeft: null,
    pathname: '',
  });

  const setOptions = useCallback(
    (value: Partial<ISetOptionType>) => {
      handleUpdate(value);
    },
    [handleUpdate],
  );

  const {
    handleCancel: handleCancelVerification,
    handleOnInit: handleOnInitVerification,
    visible: visibleVerification,
  } = useModal();

  const theme: any = useTheme();

  const navigateToServicityCite = useCallback(() => {
    window.open(authSiteUrl, '_blank');
  }, []);

  const toggleInfoLayout = useCallback(() => {
    setCollapse((prevState) => !prevState);
  }, []);

  const {prevTitle, ...state} = location?.state || {};

  const navigationGoBack = useCallback(() => {
    navigate(location?.state?.from || `/${Routes.auth}/${Routes.authOffice}`, {
      state: location?.state?.prevState ? location?.state?.prevState : state,
    });
  }, [location?.state, state, navigate]);

  const handleSetVerificationStateName = useCallback(
    (state: Routes.signup | Routes.forgotPassword) => {
      setVerificationStateName(state);
    },
    [],
  );

  const context = useMemo(
    () => ({
      handleCancelVerification,
      handleOnInitVerification,
      handleSetVerificationStateName,
      verificationStateName,
      visibleVerification,
      setOptions,
      contentWidth,
      siderWidth,
    }),
    [
      handleCancelVerification,
      handleOnInitVerification,
      handleSetVerificationStateName,
      verificationStateName,
      visibleVerification,
      setOptions,
      siderWidth,
      contentWidth,
    ],
  );

  const resetState = useCallback(() => {
    if (
      isFunction(headerLeft) &&
      isFunction((headerLeft() as any)?.props?.onClick)
    ) {
      (headerLeft() as any)?.props?.onClick();
    }
  }, [headerLeft]);

  useEffect(() => {
    window.addEventListener('popstate', resetState);

    return () => {
      window.removeEventListener('popstate', resetState);
    };
  }, [resetState]);

  useEffect(() => {
    if (contentRef?.current) {
      setContentWidth(contentRef.current.offsetWidth);
    }

    if (siderRef?.current) {
      setSiderWidth(siderRef.current.offsetWidth);
    }

    const getWidth = () => {
      setContentWidth(contentRef?.current?.offsetWidth);
      setSiderWidth(siderRef?.current?.offsetWidth);
    };

    window.addEventListener('resize', getWidth);
    return () => window.removeEventListener('resize', getWidth);
  }, [setDropdownOptions]);

  useEffect(() => {
    if (contentWidth) {
      setDropdownOptions({
        init: true,
        style: {
          width: contentWidth,
        },
      });
    }
  }, [contentWidth, setDropdownOptions, siderWidth]);

  return (
    <AuthLayoutContext.Provider value={context}>
      <FullHeightLayout data-testid="auth-layout">
        <StyledInfoButton type="text" onClick={toggleInfoLayout}>
          <AiOutlineInfoCircle
            size={25}
            color={
              collapse ? theme.colors.white : theme.colors.buttonPrimaryColor
            }
          />
        </StyledInfoButton>
        <StyledLayout $isMobile={!!isMobile.any()}>
          <StyledLogoLayout collapse={collapse} ref={siderRef}>
            <StyledLogoContainer>
              <StyledImage
                src={'/servicity-logo-white-ua.svg'}
                alt="logo"
                preview={false}
              />
            </StyledLogoContainer>
            <StyledFooterContainer>
              <FooterWithoutLayout />
            </StyledFooterContainer>
          </StyledLogoLayout>

          <StyledContent ref={contentRef}>
            {withBackButton ? (
              isFunction(headerLeft) && location.pathname === pathname ? (
                headerLeft()
              ) : (
                <HeaderLeft onClick={navigationGoBack} />
              )
            ) : null}

            {children || <span>no content</span>}
          </StyledContent>
        </StyledLayout>
      </FullHeightLayout>
    </AuthLayoutContext.Provider>
  );
}
