import React from 'react';
import {useTranslation} from 'react-i18next';
import {isFunction} from '../../../services/helpers';
import {ChildrenMustBeAFunction} from '../../../components/lib/Errors';
import {IDefaultTabPanelProps, TabItem} from '../../../components/lib/General';
import {useNavigate} from 'react-router';
import {AclKey, Routes} from '../../../services/types';

export enum PARAMETERS_TABS {
  PAYMENTS = 'Payments',
  LOCALIZATION = 'Localization',
  MODULES = 'Modules',
}

export interface IParametersTabPanelProps {
  children: (value: Pick<IDefaultTabPanelProps, 'tabs'>) => React.ReactNode;
  handleOnInitPayments: () => void;
  handleOnInitLocalization: () => void;
}

export function ParametersTabPanel({
  children,
  handleOnInitPayments,
  handleOnInitLocalization,
}: IParametersTabPanelProps): JSX.Element {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const serviceTabs: TabItem[] = [
    {
      key: PARAMETERS_TABS.MODULES,
      title: t(PARAMETERS_TABS.MODULES),
      disabled: false,
      navigation: () => {
        navigate(Routes.modules);
      },
      aclItem: AclKey.settings,
      renderer: () => <></>,
      bold: false,
    },
    {
      key: PARAMETERS_TABS.PAYMENTS,
      title: t(PARAMETERS_TABS.PAYMENTS),
      disabled: false,
      navigation: handleOnInitPayments,
      aclItem: AclKey.settings,
      renderer: () => <></>,
      bold: false,
    },
    {
      key: PARAMETERS_TABS.LOCALIZATION,
      title: t(PARAMETERS_TABS.LOCALIZATION),
      disabled: false,
      navigation: handleOnInitLocalization,
      aclItem: AclKey.settings,
      renderer: () => <></>,
      bold: false,
    },
  ];

  if (!isFunction(children)) {
    throw new ChildrenMustBeAFunction('LoadingWithEmptyData');
  }

  return <>{children({tabs: serviceTabs})}</>;
}
