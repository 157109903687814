import {correctPrice} from '../../services/helpers';
import {MaterialAndExpenseModel} from '../internal';

export class MaterialAndExpenseFormDTO {
  public uuid: string;

  public name: string;

  public amount: string;

  public price: string;

  public comment: string;
  public total: string;

  constructor(props?: MaterialAndExpenseModel) {
    this.uuid = props?.uuid || '';
    this.name = props?.name || '';
    this.comment = props?.comment || '';
    this.amount = correctPrice(props?.amount || 1);
    this.price = props?.price || '1';
    this.total = correctPrice(props?.total || 1);
  }
}
