import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {IDefaultFormProps, DefaultForm} from '../../../components/lib/General';
import {PlanTariffFormDTO} from '../../../struture';
import {SUBSCRIPTION_TARIFF_FORM_PARAM} from '../../../services/api/subscriptions';
import SubscriptionTariffFormFields from './SubscriptionTariffFormFields';
import {useDropdownAlert} from '../../../contex';

export interface ISubscriptionTariffFormProps
  extends Omit<
    IDefaultFormProps<PlanTariffFormDTO, PlanTariffFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  subscription: PlanTariffFormDTO;
  isFullForm?: boolean;
  autoFocus?: boolean;
}

export function SubscriptionTariffForm({
  loading,
  editMode,
  subscription = SUBSCRIPTION_TARIFF_FORM_PARAM,
  isFullForm = true,
  autoFocus,
  onCancel,
  ...rest
}: ISubscriptionTariffFormProps) {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const additionalValues = useMemo(
    () => ({
      uuid: subscription?.uuid,
    }),
    [subscription?.uuid],
  );

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Tariff change'),
        `${t('An error occurred during change tariff plan')} : ${
          apiError?.message
        }`,
      );
    },
    [alert, t],
  );

  return (
    <DefaultForm<any, any>
      withContext
      editMode={editMode}
      initialValues={{
        ...subscription,
      }}
      notifyError={notifyError}
      submitButtonText={t('Pay for the transfer')}
      showFooter={false}
      onCancel={onCancel}
      additionalValuesRequest={additionalValues}
      {...rest}>
      {() => (
        <SubscriptionTariffFormFields loading={!!loading} onCancel={onCancel} />
      )}
    </DefaultForm>
  );
}
