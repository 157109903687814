import React from 'react';
import {Moment} from 'moment';
import {
  CompanyCalendarPopover,
  CompanyScheduleDetail,
} from '../../Dashboard/Show';
import {CalendarManager, ICalendarManagerProps} from '../../../contex';
import {
  useDefaultCalendarScheduleProps,
  useStoredCompanies,
} from '../../../hooks';
import OrderCalendarDay from './OrderCalendarDay';
import {DateValue} from '../../../services/types';
import {ScheduleCalendarModel} from '../../../struture';

export interface IOrderCalendarDayManagerProps
  extends Pick<ICalendarManagerProps<ScheduleCalendarModel>, 'schedules'> {
  schedule: ScheduleCalendarModel | null;
  handleChangeDate: (date: Moment | null) => void;
  handleChangeTime?: (time: string) => void;
  scheduleDate: DateValue;
  scheduleUuid: string;
  editMode: boolean;
  scheduleLoading: boolean;
  isEdit: boolean;
  limitedByDate?: DateValue;
}

export default function OrderCalendarDayManager({
  schedules,
  schedule,
  handleChangeDate,
  handleChangeTime,
  scheduleDate,
  scheduleUuid,
  editMode,
  scheduleLoading,
  isEdit,
  limitedByDate,
}: IOrderCalendarDayManagerProps): JSX.Element {
  const defaultCalendarProps = useDefaultCalendarScheduleProps();
  const {defaultCompany} = useStoredCompanies();

  return (
    <CalendarManager<ScheduleCalendarModel>
      {...defaultCalendarProps}
      withoutStorageMode
      limitedByDate={limitedByDate}
      isForm
      isEdit={isEdit}
      localStorageName="orderForm"
      withDnDEventsProps={{handleChangeTime}}
      withoutStorageDate={scheduleDate as string}
      SchedulePopover={CompanyCalendarPopover}
      scheduleDetails={(props: any) => <CompanyScheduleDetail {...props} />}
      customDayTimeRange={defaultCompany && defaultCompany?.work_schedules}>
      <OrderCalendarDay
        disabled
        schedules={schedules}
        schedule={schedule}
        handleChangeDate={handleChangeDate}
        draggableScheduleUuid={scheduleUuid}
        scheduleDate={scheduleDate}
        editMode={editMode}
        scheduleLoading={scheduleLoading}
        calendarWrapperStyle={{paddingBottom: 0}}
      />
    </CalendarManager>
  );
}
