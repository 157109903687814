import React from 'react';
import Color from 'color';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {FaUserAlt, FaUsers} from 'react-icons/fa';
import {LoadingOutlined} from '@ant-design/icons';
import {isThereContent} from '../../../services/helpers';
import {StyledTitle} from '../../../components/lib/Styled';
import {ClientPersonalityType} from '../../../services/types';
import {IClientListStatsProps} from '../../../services/api/client';

import {
  DetailsActionItemView,
  IDetailsActionItemViewProps,
} from '../../../components/lib/DataDisplay';

export interface IClientListHeaderPersonalityProps
  extends Omit<IDetailsActionItemViewProps, 'onClick' | 'type' | 'children'> {
  type: ClientPersonalityType;
  color: string;
  selected?: boolean;
  title: string;
  stats: IClientListStatsProps;
  onClick: (type: ClientPersonalityType) => void;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100%;
`;

const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  height: 100%;
`;

const StyledLoadingOutlined = styled(LoadingOutlined)<{$color: string}>`
  color: ${({$color}) => $color};
  font-size: 20px;
`;

export function ClientListHeaderPersonality({
  color,
  selected,
  type,
  title,
  stats,
  onClick,
  loading,
  ...rest
}: IClientListHeaderPersonalityProps): React.JSX.Element {
  const {t} = useTranslation();

  const textColor = Color(color).alpha(1).darken(0.65).toString();

  const notFound =
    type === ClientPersonalityType.Group
      ? t('There are no groups')
      : t('There are no clients');

  return (
    <DetailsActionItemView
      color={color}
      selected={selected}
      onClick={() => onClick(type)}
      loading={loading}
      {...rest}>
      <StyledContainer>
        {type === ClientPersonalityType.Group ? (
          <FaUsers size={27} color={textColor} />
        ) : (
          <FaUserAlt size={27} color={textColor} />
        )}
        <StyledInfoContainer>
          <StyledTitle $color={textColor}>{t(title)}</StyledTitle>

          {isThereContent(stats) ? (
            <StyledTitle fontWeight={500} fontSize={25} $color={textColor}>
              {isThereContent(stats)
                ? Number(stats[type]) > 0
                  ? `${stats[type]}`
                  : '-'
                : undefined}
            </StyledTitle>
          ) : (
            <StyledLoadingOutlined $color={textColor} />
          )}
        </StyledInfoContainer>
      </StyledContainer>
    </DetailsActionItemView>
  );
}
