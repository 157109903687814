import React from 'react';
import styled from 'styled-components';
import {useListLayout} from '../../../../contex';

export interface IListHeaderProps {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
}

const HEADER_INDENT = 15;

const HeaderContainer = styled.div`
  height: auto;
  margin-bottom: ${HEADER_INDENT}px;
`;

export function ListHeader({
  children,
  className,
}: IListHeaderProps): React.JSX.Element {
  const {headerRef} = useListLayout();

  return (
    <>
      {children ? (
        <HeaderContainer ref={headerRef} className={className}>
          {children}
        </HeaderContainer>
      ) : null}
    </>
  );
}
