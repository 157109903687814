import {Record, List} from 'immutable';
import {ListModel, ListProps} from './List';
import ClientService, {ClientServiceProps} from './ClientService';

export interface ClientServiceListProps extends ListProps {
  services: ClientServiceProps[];
}

export interface ClientServiceListReturnType extends ListProps {
  services: List<ClientService>;
}

export default class ClientServiceList extends Record<ClientServiceListReturnType>(
  {
    services: List(),
    ...ListModel.toJS(),
  } as any,
) {
  /**
   * @constructor
   * @param {{clients:[]}} param0
   */
  constructor({services, ...rest}: ClientServiceListProps) {
    const options: any = {};
    if (Array.isArray(services)) {
      options.services = List(
        services.map((service) => new ClientService(service)),
      );
    }

    super({
      ...rest,
      ...options,
    });
  }
}
