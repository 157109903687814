import {Record} from 'immutable';

export interface ICompanyPaymentProps {
  uuid: string;
  payment_date: string;
  payment_comment: string;
  payment_sum: number;
  created_at: string;
  updated_at: string;
}

/**
 * @class Client
 * @desc Клієнт
 */
export default class CompanyPayment extends Record({
  uuid: '',
  payment_date: new Date().toString(),
  payment_sum: 0,
  payment_comment: '',
  created_at: '',
  updated_at: '',
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(props: ICompanyPaymentProps = {} as ICompanyPaymentProps) {
    super({...props});
  }
}
