import {Record, List} from 'immutable';
import {
  PlanDTO,
  SubscriptionListMapper,
  SubscriptionModel,
  SubscriptionPlans,
} from '../internal';
import {
  EPlanStatuses,
  len,
  sortedByDate,
  isThereContent,
  isDateLessThanNow,
  getDiffDaysFromNow,
  getDiffHoursFromNow,
  getDiffMinutesFromNow,
} from '../../services/helpers';
import {subscriptionExpiredDays} from '../../services/const';

export interface IPlanModelReturnType {
  id: string;
  identifier: string;
  checkout_url: string;
  subscriptions: List<SubscriptionModel>;
  status: EPlanStatuses;
  created_at: string;
}

export class PlanModel extends Record<IPlanModelReturnType>({
  id: '',
  identifier: '',
  checkout_url: '',
  subscriptions: List(),
  status: EPlanStatuses.DISABLED,
  created_at: '',
}) {
  constructor(props = {} as PlanDTO) {
    const options: IPlanModelReturnType = {
      id: props.id,
      identifier: props.identifier,
      subscriptions: SubscriptionListMapper.toSubscriptionListModel(
        props?.subscriptions || [],
        len(props?.subscriptions) || 0,
      ).subscriptions,
      status: props.status,
      checkout_url: props.checkout_url,
      created_at: props.created_at,
    };
    super(options);
  }

  getFirstSubscription(): SubscriptionModel {
    return this.subscriptions.sort(sortedByDate('date_end')).last();
  }

  getRestSubscription(): List<SubscriptionModel> {
    return this.subscriptions.sort(sortedByDate('date_end')).pop();
  }

  isEmptySubscriptionList(): boolean {
    return !this.getRestSubscription().size;
  }

  isEmptyFirstSubscription(): boolean {
    return isThereContent(this.getFirstSubscription());
  }
  get isTrialSubscription(): boolean {
    return this.identifier === SubscriptionPlans.Trial;
  }

  get isEmptySubscription(): boolean {
    return this.identifier === SubscriptionPlans.Empty;
  }

  get isSubscriptionDateExpired(): boolean {
    const dateEnd = this.getFirstSubscription().date_end;

    return dateEnd === '' || isDateLessThanNow(dateEnd);
  }

  get isSubscriptionExpired(): boolean {
    return this.isEmptySubscription || this.isSubscriptionDateExpired;
  }

  get subscriptionDiffDays(): number {
    return getDiffDaysFromNow(this.getFirstSubscription().date_end);
  }

  get subscriptionDiffHours(): number {
    return getDiffHoursFromNow(this.getFirstSubscription().date_end);
  }

  get subscriptionDiffMinutes(): number {
    return getDiffMinutesFromNow(this.getFirstSubscription().date_end);
  }

  get isShowSubscriptionExpiredTime(): boolean {
    return (
      !this.isSubscriptionExpired &&
      this.subscriptionDiffDays <= Number(subscriptionExpiredDays) &&
      this.subscriptionDiffMinutes >= 0
    );
  }

  get subscriptionExpiredAt(): (t: any) => string {
    const days = this.subscriptionDiffDays;
    const hours = this.subscriptionDiffHours;
    const minutes = this.subscriptionDiffMinutes;

    return (t) =>
      days > 0
        ? `${days} ${
            days === 1 ? t('day').toLowerCase() : t('days').toLowerCase()
          }`
        : hours > 0
        ? `${hours} ${
            hours === 1 ? t('hour').toLowerCase() : t('hours').toLowerCase()
          }`
        : minutes > 0
        ? `${minutes} ${
            minutes === 1
              ? t('minute').toLowerCase()
              : t('minutes').toLowerCase()
          }`
        : `${t('now').toLowerCase()}`;
  }

  get isShowSubscriptionWarning(): boolean {
    return (
      this.isTrialSubscription ||
      this.isSubscriptionExpired ||
      this.isShowSubscriptionExpiredTime
    );
  }
}
