import {Dispatch} from 'redux';
import {List} from 'immutable';
import {COMPANIES} from '../constants';
import {CompanyModel} from '../../struture';
import {ApiError} from '../../services/types';

export interface ISetCompanies {
  type: COMPANIES;
  companies: List<CompanyModel>;
}

export interface IAddCompany {
  type: COMPANIES;
  company: CompanyModel;
}

export interface IUpdateCompany {
  type: COMPANIES;
  company: CompanyModel;
}

export interface IDeleteCompany {
  type: COMPANIES;
  companyUuid: string;
}

export interface IErrorCompanies {
  type: COMPANIES;
  error: ApiError | undefined;
}

export function setCompanies(
  companies: List<CompanyModel>,
): (dispatch: Dispatch) => Promise<ISetCompanies> {
  return async function (dispatch: Dispatch) {
    dispatch({
      type: COMPANIES.LOADING_COMPANIES,
    });
    return dispatch({
      type: COMPANIES.SET_COMPANIES,
      companies,
    });
  };
}

export function addCompany(
  company: CompanyModel,
): (dispatch: Dispatch) => Promise<IAddCompany> {
  return async function (dispatch: Dispatch) {
    dispatch({
      type: COMPANIES.LOADING_COMPANIES,
    });
    return dispatch({
      type: COMPANIES.ADD_COMPANY,
      company,
    });
  };
}

export function updateCompany(
  company: CompanyModel,
): (dispatch: Dispatch) => Promise<IUpdateCompany> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: COMPANIES.UPDATE_COMPANY,
      company,
    });
  };
}

export function deleteCompany(
  companyUuid: string,
): (dispatch: Dispatch) => Promise<IDeleteCompany> {
  return async function (dispatch: Dispatch) {
    dispatch({
      type: COMPANIES.LOADING_COMPANIES,
    });
    return dispatch({
      type: COMPANIES.DELETE_COMPANY,
      companyUuid,
    });
  };
}

export function errorStoreCompanies(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorCompanies> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: COMPANIES.ERROR_COMPANIES,
      error,
    });
  };
}
