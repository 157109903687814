import React, {useCallback, Suspense} from 'react';
import {
  IUseSearchInputFocusReturnType,
  useBeforeUnload,
} from '../../../../hooks';
import {SuspenseEmpty} from '../../../../components/lib/DataDisplay';
import {useStoreSale} from '../Managers';
import {StoreSaleProductListTable} from './StoreSaleProductListTable';
import {StoreSaleFilterView} from '../Show';
import {IUseModalReturnType} from '../../../../hooks/useModal';
import {Routes} from '../../../../services/types';

const StoreSaleConductModalLazy = React.lazy(
  () => import('../Show/StoreSaleConductModal'),
);

const StoreRealizationListModalLazy = React.lazy(
  () => import('../Pages/StoreRealizationListModal'),
);

export interface IStoreSaleContentProps
  extends Omit<IUseSearchInputFocusReturnType, 'inputRef'>,
    Pick<IUseModalReturnType<any>, 'handleCancel' | 'handleSuccess'> {
  contentWidth: number;
  isShowFiscalCashboxesWarning: boolean;
  isShowFilters: boolean;
  updateSaleContentState: any;
  storeConductFormVisible: boolean;
  realizationVisible: boolean;
  storeConductFormHandleSuccess: any;
  storeConductFormHandleCancel: () => void;
}

export function StoreSaleContent({
  contentWidth,
  isShowFiscalCashboxesWarning,
  daleyFocus,
  focus,

  isShowFilters,
  updateSaleContentState,
  storeConductFormHandleSuccess,
  storeConductFormHandleCancel,
  storeConductFormVisible,

  realizationVisible,
  handleCancel,
  handleSuccess,
}: IStoreSaleContentProps): React.JSX.Element {
  const {documentPayment} = useStoreSale();

  return (
    <>
      <StoreSaleFilterView
        isShowFilters={isShowFilters}
        contentWidth={contentWidth}
        handleSearchSelectDaleyFocus={daleyFocus}
        updateSaleContentState={updateSaleContentState}
      />

      <StoreSaleProductListTable
        handleSearchSelectFocus={focus}
        handleSearchSelectDaleyFocus={daleyFocus}
      />

      {storeConductFormVisible ? (
        <Suspense fallback={<SuspenseEmpty />}>
          <StoreSaleConductModalLazy
            payment={documentPayment!}
            onSuccess={storeConductFormHandleSuccess as any}
            visible={storeConductFormVisible}
            onCancel={storeConductFormHandleCancel}
            isShowFiscalCashboxesWarning={isShowFiscalCashboxesWarning}
          />
        </Suspense>
      ) : null}

      {realizationVisible ? (
        <Suspense fallback={<SuspenseEmpty />}>
          <StoreRealizationListModalLazy
            visible={realizationVisible}
            handleCancel={handleCancel}
            handleSuccess={handleSuccess as any}
          />
        </Suspense>
      ) : null}
    </>
  );
}
