import React, {useRef} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {Space} from 'antd';
import AccountTopUpButton from './AccountTopUpButton';
import {ProfileModel} from '../../../struture';
import {OverViewUnderHeaderContainer} from '../../../components/lib/Layout';
import {IProfileBalanceParam} from '../../../services/api/user';
import {useSize} from '../../../hooks';
import styled, {css} from 'styled-components';

export default withTranslation()(AccountActionButtons);

export interface IAccountActionButtonsProps extends WithTranslation {
  profile: ProfileModel;
  children?: React.ReactNode;
  handleUpdateBalance: (value: IProfileBalanceParam) => Promise<void>;
  extra?: React.ReactNode | React.ReactNode[];
}

const StyledSpace = styled(Space)<{$isFullWith: boolean}>`
  ${({$isFullWith}) =>
    $isFullWith &&
    css`
      &&& {
        width: 100%;

        & .ant-space-item {
          width: 100%;
        }
      }
    `}
`;

const StyledAccountTopUpButton = styled(AccountTopUpButton)<{
  $isFullWith: boolean;
}>`
  ${({$isFullWith}) =>
    $isFullWith &&
    css`
      width: 100%;
      margin-top: 15px;
    `}
`;
function AccountActionButtons({
  t,
  profile,
  children,
  handleUpdateBalance,
  extra,
}: IAccountActionButtonsProps): JSX.Element {
  const actionsRef = useRef(null);
  const {width} = useSize(actionsRef);

  return (
    <div ref={actionsRef}>
      <OverViewUnderHeaderContainer
        title={`${t('Balance')} ${
          profile?.defaultCompanyModel?.balance || '0.00'
        } ${t('UAH')}`}
        extra={
          <StyledSpace $isFullWith={width <= 300}>
            <StyledAccountTopUpButton
              $isFullWith={width <= 300}
              size="small"
              onSuccess={handleUpdateBalance}
            />
            {extra || null}
          </StyledSpace>
        }>
        {children}
      </OverViewUnderHeaderContainer>
    </div>
  );
}
