import React, {Suspense, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ButtonProps} from 'antd/lib/button';
import {IProfileBalanceParam} from '../../../services/api/user';
import {Button, SuspenseEmpty} from '../../../components/lib/DataDisplay';
import {useDropdownAlert} from '../../../contex';
import styled from 'styled-components';
import {IoIosCash} from 'react-icons/io';
import {StyledTitle} from '../../../components/lib/Styled';

const AccountBalanceSideWindowLazy = React.lazy(
  () => import('../Show/AccountBalanceSideWindow'),
);

export interface IAccountTopUpButtonProps extends ButtonProps {
  onSuccess: (value: IProfileBalanceParam) => Promise<void>;
  onError?: () => void;
}

const StyledActionButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 10px;

  width: fit-content;
  height: 40.39px;
  margin-left: auto;
  padding: 10px;

  background-color: ${({theme}) => theme.background.primary};
  border: 1px solid ${({theme}) => theme.colors.disabled};
  border-radius: 7px;

  &:hover {
    border-color: ${({theme}) => theme.colors.link};
  }
`;

export default function AccountTopUpButton({
  loading = false,
  onSuccess,
  ...rest
}: IAccountTopUpButtonProps): JSX.Element {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const [visible, setVisible] = useState(false);

  const handleOnInit = async (): Promise<void> => {
    setVisible(true);
  };

  return (
    <>
      <StyledActionButton
        tooltipTitle={t('Top up')}
        type="default"
        loading={loading}
        onClick={handleOnInit}
        data-testid="user__button--top-up"
        {...rest}>
        <IoIosCash size={18} />
        <StyledTitle fontSize={16}>{t('Balance')}</StyledTitle>
      </StyledActionButton>

      <Suspense fallback={<SuspenseEmpty />}>
        <AccountBalanceSideWindowLazy
          loading={loading as boolean}
          onSuccess={onSuccess}
          onCancel={() => setVisible(false)}
          visible={visible}
          setVisible={setVisible}
        />
      </Suspense>
    </>
  );
}
