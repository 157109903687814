import React, {useCallback, useState} from 'react';
import {Empty} from 'antd';
import {
  ITableProps,
  Table,
  TableActionCell,
} from '../../../../components/lib/libV2/DataDisplay';
import {StyledTitle} from '../../../../components/lib/Styled';
import {
  StoreDocumentItemSaleType,
  StoreDocumentItemFormDTO,
} from '../../../../struture';
import {useTranslation} from 'react-i18next';
import {
  EditableCell,
  EditableRow,
} from '../../../../components/lib/DataDisplay/FormEditableTable';
import {useDropdownAlert} from '../../../../contex';
import {correctPrice, isFunction} from '../../../../services/helpers';
import styled, {useTheme} from 'styled-components';
import {
  DeleteButton,
  TableIndexField,
} from '../../../../components/lib/DataDisplay';
import {TextFormat} from '../../../../components/lib/Format';
import {ProductDetailsView} from '../../../Products';
import {DefaultForm} from '../../../../components/lib/General';
import {useStoredCompanies} from '../../../../hooks';
import {useStoreSale} from '../Managers';
import {ENTER_KEYS} from '../../../../services/const';

import '../../../Orders/List/OrderList.less';
import './StoreSaleProductListTable.less';

export interface IStoreSaleProductListTableProps {
  handleSearchSelectFocus: () => void;
  handleSearchSelectDaleyFocus: (ms?: number) => void;

  loading?: boolean;
}

const StyledTable = styled(Table)`
  margin: 0;
` as React.ComponentType as React.FC<ITableProps<StoreDocumentItemFormDTO>>;

const StyledNumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

const storeDocumentItemErrorTypes = [StoreDocumentItemSaleType.LOADING];

export function StoreSaleProductListTable({
  handleSearchSelectFocus,
  handleSearchSelectDaleyFocus,
  loading: defaultLoading,
}: IStoreSaleProductListTableProps): React.JSX.Element {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const {defaultCompany} = useStoredCompanies();
  const theme: any = useTheme();

  const {
    storeDocumentItemList,
    documentItemsLoading,
    storeDocumentItemListTotal,
    storeDocumentItemListLimit,
    loadingSubmit,
    disabledProductUuid,

    handleDeleteStoreDocumentItem,
    storeDocumentItemListRefresh,
    handleAddOrUpdateDocumentItems,
    storeDocumentItemListPage,
  } = useStoreSale();

  const [loading, setLoading] = useState(false);
  const [currentItem, setCurrentItem] =
    useState<StoreDocumentItemFormDTO | null>(null);

  const validateList =
    storeDocumentItemList?.map((_: any, index: number) => [
      'doc_items',
      index,
      'product_amount',
    ]) || [];

  const handleDeleteDocItem = useCallback(
    async (docItem: StoreDocumentItemFormDTO) => {
      try {
        await handleDeleteStoreDocumentItem(docItem);
      } catch (error: any) {
        alert(
          'error',
          t('Store document item'),
          `${t('An error occurred during delete store document item')}: ${
            error?.message
          }`,
        );
        return;
      }
    },
    [alert, handleDeleteStoreDocumentItem, t],
  );

  const defaultColumns = [
    {
      ellipsis: true,
      title: `№`,
      key: 'index',
      align: 'center' as any,
      className: 'order-list-td order-list--middle-text',
      render: (
        document: StoreDocumentItemFormDTO,
        items: any,
        index: number,
      ) => (
        <TableIndexField
          number={
            (storeDocumentItemListPage - 1) * storeDocumentItemListLimit +
            index +
            1
          }
          fontSize={18}
        />
      ),
    },
    {
      title: t('name-s'),
      key: 'name',
      className: 'order-list-td sale-list-td--full',
      render: (docItem: StoreDocumentItemFormDTO) =>
        isFunction(handleDeleteDocItem) ? (
          <ProductDetailsView
            fontSize={20}
            product={docItem?.product!}
            withoutNavigate={
              docItem?.product?.uuid === docItem?.product?.product_title
            }
            message={docItem?.item_error_message}
            tagType={docItem?.item_sale_type}
            withTour
            price={docItem?.price}
          />
        ) : (
          docItem?.product_title
        ),
    },
    {
      title: t('tAmount'),
      key: 'product_amount',
      editable: (item: StoreDocumentItemFormDTO) => {
        return !(disabledProductUuid === item?.product_uuid) && !loadingSubmit;
      },
      className: 'order-list-td sale-list-td',
      validateList: [...validateList, 'product_amount'],
      autoFocus: false,
      recordUuid: currentItem?.uuid,
      fontSize: 20,
      rules: [
        () => ({
          validator(_: any, price: string) {
            const value = price;

            if (Number(value) < 0) {
              return Promise.reject(
                new Error(t('Amount must be greater than 1')),
              );
            }

            if (Number(value) !== 0 && !Number(value)) {
              return Promise.reject(new Error(t('Amount must be a number')));
            }

            return Promise.resolve();
          },
        }),
      ],
      loading,
      align: 'right' as any,
      render: (docItem: StoreDocumentItemFormDTO) => (
        <StyledTitle fontSize={20}>{docItem?.product_amount}</StyledTitle>
      ),
    },
    {
      title: `${t('Price')}, ${defaultCompany?.currency_symbol || ''}`,
      key: 'price',
      className: 'order-list-td sale-list-td',
      align: 'right' as any,
      render: (docItem: StoreDocumentItemFormDTO) => (
        <StyledNumberContainer>
          <StyledTitle
            fontSize={20}
            $color={
              Number(docItem?.price || 0) <= 0 ? theme.colors.error : undefined
            }>
            {correctPrice(docItem?.price || 0)}
          </StyledTitle>
        </StyledNumberContainer>
      ),
    },
    {
      title: `${t('Total')}, ${defaultCompany?.currency_symbol || ''}`,
      key: 'total',
      className: 'order-list-td sale-list-td',
      align: 'right' as any,
      render: (docItem: StoreDocumentItemFormDTO) => (
        <StyledNumberContainer>
          <StyledTitle
            fontSize={20}
            $color={
              Number(docItem?.price || 0) <= 0 ? theme.colors.error : undefined
            }>
            {correctPrice(
              Number(docItem?.price || 0) * (docItem?.product_amount || 1),
            )}
          </StyledTitle>
        </StyledNumberContainer>
      ),
    },
    {
      ellipsis: true,
      title: t('Actions'),
      key: 'actions',
      align: 'center' as any,
      fixed: 'right' as any,
      className: 'order-list-td sale-list-td',
      render: (docItem: StoreDocumentItemFormDTO) => {
        return (
          <TableActionCell>
            <DeleteButton
              disabled={
                disabledProductUuid === docItem?.product_uuid ||
                loadingSubmit ||
                storeDocumentItemErrorTypes.includes(docItem?.item_sale_type!)
              }
              tooltipTitle={t('Delete material')}
              confirmTitle={
                <TextFormat breakWord>
                  {t('Are you sure you want to delete')} {t('material')}?
                </TextFormat>
              }
              onConfirm={async () => {
                await handleDeleteDocItem(docItem);
                handleSearchSelectFocus();
              }}
              cancelButtonText={t('Cancel')}
            />
          </TableActionCell>
        );
      },
    },
  ];

  const handleSave = useCallback(
    async (row: StoreDocumentItemFormDTO) => {
      const newData = [...(storeDocumentItemList || [])];
      const index = newData.findIndex(
        (item) => row.product_uuid === item.product_uuid,
      );

      const item = newData[index];

      setCurrentItem(item);

      const updatedItem = {
        ...item,
        product_amount: Number(row?.product_amount),
      };

      newData.splice(index, 1, updatedItem);

      if (item?.product_amount !== row?.product_amount) {
        try {
          setLoading(true);
          await handleAddOrUpdateDocumentItems(updatedItem, true);
          setLoading(false);
        } catch (error: any) {
          setLoading(false);
          alert(
            'error',
            t('Store document item'),
            `${t('An error occurred during delete store document item')}: ${
              error?.message
            }`,
          );
          return;
        }
      }

      handleSearchSelectDaleyFocus(100);
    },
    [
      alert,
      handleSearchSelectDaleyFocus,
      handleAddOrUpdateDocumentItems,
      storeDocumentItemList,
      t,
    ],
  );

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return {
        ...col,
        onCell: (record: StoreDocumentItemFormDTO) => {
          return {
            record,
            dataIndex: col.key,
          };
        },
      } as any;
    }

    return {
      ...col,
      onCell: (record: StoreDocumentItemFormDTO) => {
        return {
          record,
          editable: storeDocumentItemErrorTypes.includes(
            record?.item_sale_type!,
          )
            ? false
            : col.editable,
          dataIndex: col.key,
          title: col.title,
          rules: col.rules,
          handleSave,
          alignText: col?.align,
          className: col?.className,
          validateList: col?.validateList,
          autoFocus: col?.autoFocus,
          loading: col?.loading,
          recordUuid: col?.recordUuid,
          fontSize: col?.fontSize,
        };
      },
    };
  });

  return (
    <DefaultForm<any, any>
      formKeyboardCodes={[...ENTER_KEYS]}
      withContext
      isResetLoading
      onCancel={() => {}}
      initialValues={{}}
      onSuccess={() => {}}
      showFooter={false}
      showNotify={false}
      showCancelButton={false}>
      <StyledTable
        loading={defaultLoading || documentItemsLoading}
        components={components}
        rowClassName={() => 'editable-row'}
        dataSource={storeDocumentItemList}
        rowKey="product_uuid"
        columns={columns}
        total={storeDocumentItemListTotal || storeDocumentItemList?.length}
        pageSize={storeDocumentItemListLimit || Infinity}
        onChange={storeDocumentItemListRefresh}
        renderEmpty={
          <Empty
            description={t('It looks like you have not selected any products')}
          />
        }
        onRow={(record: StoreDocumentItemFormDTO) => ({
          className: `${
            record?.item_sale_type === StoreDocumentItemSaleType.NOT_FOUND_ERROR
              ? 'sale-list-td--warning'
              : ''
          } ${
            record?.item_sale_type === StoreDocumentItemSaleType.API_ERROR
              ? 'sale-list-td--error'
              : ''
          }`,
        })}
      />
    </DefaultForm>
  );
}
