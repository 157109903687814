import React, {useMemo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {SERVICES_INITIAL_DATA, TIME_LIST} from '../../../services/api/services';
import {DefaultForm, IDefaultFormProps} from '../../../components/lib/General';
import {useStateEmployeeList, useStateGroupPaymentList} from '../../../hooks';
import {ServiceDTO, ServiceFormDTO, ServiceMapper} from '../../../struture';
import {useDropdownAlert} from '../../../contex';
import {ServiceFormFields} from './ServicesFormFields';
import {PERSONALITY_TYPE} from '../../../services/types';
import styled from 'styled-components';
import {ServicePersonalityView} from '../Show';
import {Form} from 'antd';
import {useWatch} from 'antd/es/form/Form';
import {isFunction} from '../../../services/helpers';
import {produce} from 'immer';

export interface IServicesFormProps
  extends Omit<
    IDefaultFormProps<ServiceFormDTO, ServiceFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  companyUuid: string;
  service?: ServiceFormDTO;
  timeList?: string[];
}

export const StyledServicePersonalityView = styled(ServicePersonalityView)`
  justify-content: center;
`;

export const SERVICES_PERSONALITY = [
  {
    uuid: PERSONALITY_TYPE.INDIVIDUAL,
    title: (
      <StyledServicePersonalityView
        service={ServiceMapper.toServiceModel({
          personality: PERSONALITY_TYPE.INDIVIDUAL,
        } as ServiceDTO)}
      />
    ),
  },
  {
    uuid: PERSONALITY_TYPE.GROUP,
    title: (
      <StyledServicePersonalityView
        service={ServiceMapper.toServiceModel({
          personality: PERSONALITY_TYPE.GROUP,
        } as ServiceDTO)}
      />
    ),
  },
];

/**
 * @desc Створенння форми послуги
 * */
export default function ServiceForm({
  loading,
  editMode,
  onSuccess,
  companyUuid,
  timeList = TIME_LIST,
  service = SERVICES_INITIAL_DATA,
  ...rest
}: IServicesFormProps): JSX.Element {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const [instance] = Form.useForm();
  const multi_sessions = useWatch('multi_sessions', instance);
  const additionalValues = useMemo(
    () => ({
      uuid: service?.uuid,
    }),
    [service?.uuid],
  );

  const {
    employees,
    loading: employeeLoading,
    handleSearchEmployees,
  } = useStateEmployeeList({
    companyUuid,
    limit: 100,
  });

  const {
    groupPayments,
    loading: groupPaymentsLoading,
    handleSearchGroupPayments,
  } = useStateGroupPaymentList({
    companyUuid,
    limit: 100,
  });

  const notifyError = useCallback(
    (ApiError: any) => {
      alert(
        'error',
        t('Service'),
        `${
          editMode
            ? t('An error occurred during edit service')
            : t('An error occurred during create service')
        } : ${ApiError?.message}`,
      );
    },
    [alert, editMode, t],
  );

  const handleOnSuccess = useCallback(
    async (value: ServiceFormDTO) => {
      if (isFunction(onSuccess)) {
        const updatedValue = produce(value, (draft) => {
          if (draft.is_multi_sessionable) {
            draft.multi_sessions = multi_sessions?.map(
              (session: any, index: number) => ({
                ...session,
                index,
              }),
            );
          }
        });

        return onSuccess(updatedValue);
      }
    },
    [multi_sessions, onSuccess],
  );

  return (
    <DefaultForm<ServiceFormDTO, ServiceFormDTO>
      instance={instance}
      initialValues={{
        ...service,
      }}
      additionalValuesRequest={additionalValues}
      editMode={editMode}
      submitButtonText={editMode ? t('Save') : t('Create')}
      notifyError={notifyError}
      showNotify={false}
      onSuccess={handleOnSuccess}
      {...rest}>
      <ServiceFormFields
        loading={!!loading}
        editMode={!!editMode}
        timeList={timeList}
        employees={employees}
        employeeLoading={employeeLoading}
        handleSearchEmployees={handleSearchEmployees}
        groupPayments={groupPayments}
        groupPaymentsLoading={groupPaymentsLoading}
        handleSearchGroupPayments={handleSearchGroupPayments}
        multi_sessions={multi_sessions || []}
      />
    </DefaultForm>
  );
}
