import {Dispatch} from 'redux';
import {CLIENT} from '../constants';
import {ClientModel} from '../../struture';
import {ApiError} from '../../services/types';

export interface ISetClientParam {
  type: CLIENT;
  client: ClientModel | null;
}

export interface IUpdateClientParam {
  type: CLIENT;
  client: ClientModel;
}

export interface IResetClientParam {
  type: CLIENT;
}

export interface IErrorClientParam {
  type: CLIENT;
  error: ApiError | undefined;
}

export function setClient(
  client: ClientModel | null,
): (dispatch: Dispatch) => Promise<ISetClientParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CLIENT.SET_CLIENT,
      client,
    });
  };
}

export function updateClient(
  client: ClientModel,
): (dispatch: Dispatch) => Promise<IUpdateClientParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CLIENT.UPDATE_CLIENT,
      client,
    });
  };
}

export function resetClient(): (
  dispatch: Dispatch,
) => Promise<IResetClientParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CLIENT.RESET_CLIENT,
    });
  };
}

export function errorClient(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorClientParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CLIENT.ERROR_CLIENT,
      error,
    });
  };
}
