import React from 'react';
import {Routes, Route} from 'react-router';
import {AppRouteLayout} from '../components/lib/Layout';
import {StoreRelatedListPage} from '../views/Stores';

export function StoreRelatedRoutesNavigator() {
  return (
    <Routes>
      <Route
        path={`/`}
        element={
          <AppRouteLayout>
            <StoreRelatedListPage />
          </AppRouteLayout>
        }
      />
    </Routes>
  );
}
