import React from 'react';
import styled, {css} from 'styled-components';

export interface INotWorkingHoursProps {
  withPastDate?: boolean;
}

const StyledNotWorkingHoursView = styled.div<{withPastDate?: boolean}>`
  width: 100%;
  height: 100%;
  border: 1px solid ${({theme}) => theme.colors.primary};

  background: ${({theme}) =>
    `repeating-linear-gradient(-60deg, ${theme.colors.lightenPrimary} 0, ${theme.colors.lightenPrimary} 1px, transparent 1px, transparent 5px)`};

  ${({withPastDate}) =>
    withPastDate &&
    css`
      background: ${({theme}) =>
        `repeating-linear-gradient(-60deg, ${theme.colors.lightenPrimary} 0, ${theme.colors.lightenPrimary} 1px, ${theme.colors.disable} 1px, ${theme.colors.disable} 5px)`};
    `}
`;

export default function NotWorkingHours({
  withPastDate,
}: INotWorkingHoursProps): JSX.Element {
  return <StyledNotWorkingHoursView withPastDate={withPastDate} />;
}
