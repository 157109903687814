export enum WithoutRecursion {
  service = 'service',
  employee = 'employee',
  company = 'company',
  profile = 'profile',
  group = 'group',
  client = 'client',
  category = 'category',
  scheduleCalendar = 'scheduleCalendar',
  employeeService = 'employeeService',
  groupSession = 'groupSession',
  groupPayment = 'groupPayment',
  clientSubscriptionable = 'clientSubscriptionable',
  user = 'user',
  profileSubscriptions = 'profileSubscriptions',
  mailingTemplate = 'mailingTemplate',
  relatedService = 'relatedService',
  report = 'report',
  abonement = 'abonement',
  cashBox = 'cash-box',
  status = 'status',
  payment = 'payment',
  product = 'product',
  productCategory = 'product-category',
  expense = 'expense',
  reward = 'reward',
  file = 'file',
  department = 'department',
  store = 'store',
  priceMargin = 'price-margin',
  storeDocument = 'store-document',
}

export enum CashBoxType {
  Bank = 'bank',
  Cash = 'cash',
}

export enum ProductType {
  Product = 'products',
  Category = 'categories',
}

export const DATE_SHORT_FORMAT = 'YYYY-MM-DD';

export enum GENDER {
  MALE = 'male',
  FEMALE = 'female',
}

export enum ErrorsStatus {
  Bad_Request = 400,
  Unauthorized = 401,
  Forbidden = 403,
  Conflict = 409,
  SubscriptionExpired = 410,
  Unprocessable_Entity = 422,
  Expired_Invalid_Token = 498,
  Server_Error = 500,
  Unknown = 600,
}

export enum AclKey {
  order = 'order',
  client = 'client',
  company = 'company',
  service = 'service',
  employee = 'employee',
  calendar = 'calendar',
  settings = 'settings',
  reports = 'reports',
  template = 'template',
  payment_group = 'payment_group',
  notifications = 'notifications',
  product = 'product',
  department = 'department',
  priceMargin = 'priceMargin',
  store = 'store',
  subscription = 'subscription',
}

export const aclFallback = {
  [AclKey.order]: {
    read: false,
    manage: false,
  },
  [AclKey.client]: {
    read: false,
    manage: false,
  },
  [AclKey.company]: {
    read: false,
    manage: false,
  },
  [AclKey.service]: {
    read: false,
    manage: false,
  },
  [AclKey.employee]: {
    read: false,
    manage: false,
  },
  [AclKey.calendar]: {
    read: false,
    manage: false,
  },
  [AclKey.settings]: {
    read: false,
    manage: false,
  },
  [AclKey.template]: {
    read: false,
    manage: false,
  },
  [AclKey.payment_group]: {
    read: false,
    manage: false,
  },
  [AclKey.notifications]: {
    read: false,
    manage: false,
  },
  [AclKey.reports]: {
    read: false,
    manage: false,
  },
  [AclKey.product]: {
    read: false,
    manage: false,
  },
  [AclKey.department]: {
    read: false,
    manage: false,
  },
  [AclKey.priceMargin]: {
    read: false,
    manage: false,
  },
  [AclKey.store]: {
    read: false,
    manage: false,
  },
  [AclKey.subscription]: {
    read: false,
    manage: false,
  },
};

export enum PERSONALITY_TYPE {
  INDIVIDUAL = 'INDIVIDUAL',
  GROUP = 'GROUP',
  UNSET = '',
}

export enum TIME_LIST_SERVICES {
  MINUTE = 'MINUTE',
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export enum APPOINTMENT_METHOD {
  CASHBOX = 'CASHBOX',
  ACCOUNT = 'ACCOUNT',
  BANK = 'BANK',
}

export enum CLIENT_TYPE {
  PERSONAL = 'client_personal_session',
  GROUP = 'client_group_session',
}

export enum FINANCIAL_REPORTING {
  Day = '1',
  Week = '7',
  Month = '30',
}

export enum PERIOD_MOVEMENT_FUNDS {
  Week = '1',
  Two_week = '2',
  Month = '4',
}

export const PROFILE_STATUS_DISABLED = 0; // Заблокований аккаунт
export const PROFILE_STATUS_ENABLED = 1; // Акнивний статус
export const PROFILE_STATUS_DELIVER = 2; // Статус, який потребує певних дій для активації аккаунта

export enum SUBSCRIPTION_STATUS {
  READY = 'ready',
  DRAFT = 'draft',
  PAYED = 'payed',
  COMPLETED = 'completed',
  WAITING_FOR_PAYMENT = 'waiting_for_payment',
}

export enum OPERATION_TYPE {
  'IN' = 'IN',
  'OUT' = 'OUT',
}
