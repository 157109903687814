import React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import moment from 'moment';
import {Table} from '../../../components/lib/DataDisplay';
import {useTableChange, IUseNotificationListProps} from '../../../hooks';
import {Notification} from '../../../services/helpers';

import './NotificationList.less';

export default withTranslation()(NotificationList);

export interface INotificationListProps extends WithTranslation {
  responsive: boolean;
  notifications: Notification[];
  notificationListLoading: boolean;
  pageSize: number;
  total: number;
  onRefresh: (
    value: IUseNotificationListProps,
  ) => Promise<Notification[] | void>;
  setRead: (expanded: boolean, record: Notification) => Promise<void>;
}

function NotificationList({
  responsive,
  t,
  notifications,
  notificationListLoading,
  pageSize,
  total,
  onRefresh,
  setRead,
}: INotificationListProps) {
  const {currentPage, handleTableChange} = useTableChange({
    page: 1,
    pageSize: pageSize || 0,
    callback: onRefresh,
    total,
  });

  const expandedRowRender = (
    record: Notification,
    index: number,
    indent: number,
    expanded: boolean,
  ) => (expanded ? <p>{record.message}</p> : null);

  const columns = [
    {
      title: t('Date'),
      key: 'date',
      width: '20%',
      render: (c: Notification) => (
        <>
          {c?.is_read ? (
            moment(c?.date).format('L, LT')
          ) : (
            <b>{moment(c?.date).format('L, LT')}</b>
          )}
        </>
      ),
    },
    {
      title: t('Message'),
      key: 'message',
      render: (c: Notification) => (
        <>
          {
            c?.is_read ? <b>Message title</b> : <span>Message title</span> // FIXME: 'c => c.title' after server implementstion
          }
        </>
      ),
    },
  ];

  return (
    <Table
      responsive={responsive}
      loading={notificationListLoading}
      rowKey="uuid"
      dataSource={notifications}
      onChange={handleTableChange}
      columns={columns}
      expandedRowRender={expandedRowRender}
      onExpand={setRead}
      pagination={{
        pageSize,
        current: currentPage,
        total,
        hideOnSinglePage: pageSize >= total,
      }}
      size="middle"
      onRow={(record: Notification, index: number | undefined): any => ({
        'data-testid': `notification-list-row-${index}`,
      })}
      rowClassName={(record, index) =>
        `notification-list-row-readed-${record.is_read}`
      }
    />
  );
}
