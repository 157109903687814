export enum EMPLOYEE {
  SET_EMPLOYEE = 'set_employee',
  RESET_EMPLOYEE = 'reset_employee',
  LOADING_EMPLOYEE = 'loading_employee',
  ERROR_EMPLOYEE = 'error_employee',
  UPDATE_EMPLOYEE = 'update_employee',
  UPDATE_EMPLOYEE_SERVICES = 'update_employee_services',
  UPDATE_EMPLOYEE_DAY_OFF = 'update_employee_day_off',
  CREATE_EMPLOYEE_DAY_OFF = 'create_employee_day_off',
  DELETE_EMPLOYEE_DAY_OFF = 'delete_employee_day_off',
}
