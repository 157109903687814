import * as React from 'react';
import {Record} from 'immutable';
import {useDropdownAlert} from '../contex';
import {RootState} from '../store/reducers';
import {useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {OPERATION_TYPE, REDUX_STATUS} from '../services/types';
import {eq, correctPrice, toStringDate} from '../services/helpers';
import {useClient, IUseClientProps, IUseClientReturnType} from './useClient';

import {
  ClientModel,
  ClientMapper,
  ClientOrgType,
  ClientFormDTO,
  ClientGroupFormDTO,
  ClientLegalFormDTO,
  ClientPaymentFormDTO,
} from '../struture';

import {
  editClient,
  createClient,
  editClientLegal,
  editClientGroup,
  updateClientComment,
  operationsWithBalance,
} from '../services/api/client';

import {
  setClient as storeSetClient,
  resetClient as storeResetClient,
  updateClient as storeUpdateClient,
} from '../store/actions';
import {StatusError} from '../components/lib/Errors';

export interface IUseStoreClientProps extends IUseClientProps {
  companyUuid: string;
}
export interface IUseStoreClientReturnType
  extends Omit<IUseClientReturnType, 'entity'> {
  client: ClientModel | null;
  handleUpdateClient: (value: ClientFormDTO) => Promise<void>;
  handleUpdateClientGroup: (value: ClientGroupFormDTO) => Promise<void>;
  handleUpdateClientComment: (comment: string) => Promise<void>;
  handleCreateClient: (value: ClientFormDTO) => Promise<void>;
  handleUpdateBalance: (value: ClientPaymentFormDTO) => Promise<void>;
  handleRefreshClient: () => Promise<void>;
  handleResetClient: () => void;
  handleUploadClientImage: (file: File) => Promise<string>;
  status: REDUX_STATUS;
}

export function useStoredClient({
  companyUuid,
  loadOnInit,
  clientUuid,
  ...rest
}: IUseStoreClientProps): IUseStoreClientReturnType {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const [client, setClient] = React.useState<ClientModel | null>(null);
  const [isStarLoading, setIsStartLoading] = React.useState<boolean>(false);

  const {
    status: storedClientStatus,
    cachedClientList,
    ...storedClientParams
  } = useSelector(({client}: RootState) => client);

  const dispatch = useDispatch<any>();
  const once = React.useRef(false);

  const {entity, refresh, ...clientParams} = useClient({
    loadOnInit: loadOnInit || isStarLoading,
    clientUuid,
    ...rest,
  });

  React.useEffect(() => {
    if (cachedClientList?.size > 0 && !client) {
      const service = cachedClientList.get(clientUuid);

      if (service) {
        setIsStartLoading(false);
        setClient(service);
      } else {
        setIsStartLoading(true);
      }
    }
    if (cachedClientList?.size === 0 && !client) {
      setIsStartLoading(true);
    }
  }, [cachedClientList, dispatch, clientUuid, client]);

  useEffect(() => {
    if (entity && isStarLoading && !once.current) {
      dispatch(storeSetClient(entity));
      setClient(entity);
    }
  }, [dispatch, entity, isStarLoading, storedClientStatus]);

  const handleUpdateClientGroup = React.useCallback(
    async (value: ClientGroupFormDTO): Promise<void> => {
      const clientDTO = await editClientGroup(value);

      const clientModel = ClientMapper.toClientModel(clientDTO);

      dispatch(storeUpdateClient(clientModel));

      setClient(clientModel);
    },
    [dispatch],
  );

  const handleUpdateClient = React.useCallback(
    async (value: ClientFormDTO | ClientLegalFormDTO): Promise<void> => {
      const isPerson: boolean = value?.client_org_type === ClientOrgType.PERSON;

      const clientFormDTO = isPerson
        ? ClientMapper.toClientFormDTO(value as any)
        : ClientMapper.toClientLegalFormDTO(value as any);

      const clientDTO = isPerson
        ? await editClient(clientFormDTO as ClientFormDTO)
        : await editClientLegal(clientFormDTO as ClientLegalFormDTO);

      const clientModel = ClientMapper.toClientModel(clientDTO);

      dispatch(storeUpdateClient(clientModel));
      // dispatch(storeUpdateClientFromList(clientModel));

      setClient(clientModel);
    },
    [dispatch],
  );

  const handleCreateClient = React.useCallback(
    async (value: ClientFormDTO) => {
      const clientFormDTO = ClientMapper.toClientFormDTO(value as any);

      const clientDTO = await createClient(clientFormDTO as any, companyUuid);

      const clientModel = ClientMapper.toClientModel(clientDTO);
      clientModel.set('created_at', toStringDate(new Date()));

      // dispatch(storeAddClient(updatedModel));
    },
    [companyUuid],
  );

  const handleResetClient = React.useCallback(() => {
    once.current = true;
    dispatch(storeResetClient());
  }, [dispatch]);

  const handleRefreshClient = React.useCallback(async () => {
    const clientModel = await refresh({showLoading: false, clientUuid});

    if (clientModel) {
      setClient(clientModel);
      dispatch(storeUpdateClient(clientModel));
      // dispatch(storeUpdateClientFromList(clientModel));
    }
  }, [clientUuid, dispatch, refresh]);

  const handleUploadClientImage = useCallback(
    async (file: File): Promise<string> => {
      // const {picture_url} = await cl({file, uuid: clientId});

      return (file as any)?.url || '';
    },

    [],
  );

  const handleUpdateBalance = React.useCallback(
    async ({payment_sum, payment_type, ...rest}: ClientPaymentFormDTO) => {
      try {
        const paymentDTO = await operationsWithBalance({
          payment_sum,
          payment_type,
          ...rest,
          clientUuid,
        });

        if (Record.isRecord(client)) {
          const updatedClient = client.update('saldo', () =>
            correctPrice(paymentDTO?.payment_sum),
          );

          dispatch(storeUpdateClient(updatedClient));
          setClient(updatedClient);

          if (eq(payment_type, OPERATION_TYPE.IN)) {
            alert(
              'success',
              t('4'),
              `${payment_sum} ${t('UAH')}. ${t('Successfully added to')} ${
                client?.fullNameClient
              } ${t('personal account')}`,
            );
          }
          if (eq(payment_type, OPERATION_TYPE.OUT)) {
            alert(
              'success',
              t('Balance'),
              `${payment_sum} ${t('UAH')}. ${t(
                'Successfully written off from',
              )} ${client?.fullNameClient} ${t('personal account')}`,
            );
          }
        }
      } catch (error: any) {
        if (eq(payment_type, OPERATION_TYPE.IN)) {
          alert(
            'error',
            t('Balance'),
            `${t(
              'An error has occurred while debiting the personal account',
            )}: ${error?.message}`,
          );
        }
        if (eq(payment_type, OPERATION_TYPE.OUT)) {
          alert(
            'error',
            t('Balance'),
            `${t('An error occurred while refilling the personal account')}: ${
              error?.message
            }`,
          );
        }

        throw new Error(error);
      }
    },
    [clientUuid, client, dispatch, alert, t],
  );

  const handleUpdateClientComment = React.useCallback(
    async (comment: string): Promise<void> => {
      try {
        const clientDTO = await updateClientComment(comment, clientUuid);
        const clientModel = ClientMapper.toClientModel(clientDTO);

        dispatch(storeUpdateClient(clientModel));

        setClient(clientModel);
        alert(
          'success',
          t('Client'),
          t('The notes has been successfully updated'),
        );
      } catch (error: any) {
        alert(
          'error',
          t('Client'),
          `${t('An error occurred during notes update')} : ${error?.message}`,
        );

        throw new StatusError(error?.message, error?.status);
      }
    },
    [alert, dispatch, clientUuid, t],
  );

  return {
    ...clientParams,
    ...storedClientParams,
    client,
    loading: !client,
    handleUpdateClient,
    handleCreateClient,
    status: storedClientStatus,
    handleResetClient,
    refresh,
    handleRefreshClient,
    handleUploadClientImage,
    handleUpdateBalance,
    handleUpdateClientComment,
    handleUpdateClientGroup,
  };
}
