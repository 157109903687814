import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {LockOutlined} from '@ant-design/icons';
import {Col, Input, Form} from 'antd';

export default withTranslation()(UserAuthFormFields);

export interface IUserAuthFormFieldsProps extends WithTranslation {
  overviewMode?: boolean;
  loading: boolean;
}

function UserAuthFormFields({
  t,
  overviewMode,
  loading,
}: IUserAuthFormFieldsProps): JSX.Element {
  return (
    <>
      <Col span={24}>
        <Form.Item
          tooltip={t('The user login')}
          label={t('Login')}
          name="login"
          rules={[
            {
              required: true,
              message: t('Login must be specified.'),
            },
          ]}>
          <Input
            data-testid="user__input--login"
            disabled={loading}
            placeholder={t('Enter the user login')}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label={t('Password')}
          name="password"
          rules={[
            {
              required: true,
              message: t('Please input password!'),
            },
          ]}>
          <Input.Password
            data-testid="user__input--password"
            disabled={loading}
            placeholder={t('Enter the user password')}
            prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}} />}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label={t('Confirm Password')}
          name="confirm_password"
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: t('Please confirm password!'),
            },
            ({getFieldValue}) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    t('The two passwords that you entered do not match!'),
                  ),
                );
              },
            }),
          ]}>
          <Input.Password
            data-testid="user__input--email"
            disabled={loading}
            placeholder={t('Enter confirm password')}
            prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}} />}
          />
        </Form.Item>
      </Col>
    </>
  );
}
