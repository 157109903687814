import React from 'react';
import moment from 'moment';
import Tooltip from 'antd/es/tooltip';
import Moment, {MomentProps} from 'react-moment';

export enum FORMAT_DATE {
  SHORT = 'YYYY-MM-DD',
  FULL = 'YYYY-MM-DD HH:mm:ss',
}

export default DateFormat;

export interface IDateFormatProps extends MomentProps {
  format?: FORMAT_DATE;
  isTimezone?: boolean;
}

/**
 * @name DateFormat
 * @desc component. Uses https://www.npmjs.com/package/react-moment
 * @todo move to bar components
 */
function DateFormat({
  children,
  format = FORMAT_DATE.SHORT,
  isTimezone = false,
  ago,
  fromNow,
  toNow,
  ...rest
}: IDateFormatProps): JSX.Element | null {
  const localFormat = format || FORMAT_DATE.FULL;

  const valid =
    moment(children).isValid() &&
    !['number', 'undefined'].includes(typeof children);

  if (!valid) {
    return null;
  }

  const dateStr = (
    <span>
      <Moment format={localFormat} {...rest}>
        {children}
      </Moment>
    </span>
  );

  return ago || fromNow || toNow ? (
    <Tooltip title={dateStr} mouseEnterDelay={0.5}>
      <Moment {...rest} format={undefined}>
        {children}
      </Moment>
    </Tooltip>
  ) : (
    dateStr
  );
}
