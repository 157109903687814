import {
  InvitationDTO,
  InvitationDTOProps,
  InvitationListModel,
  InvitationModel,
} from '../internal';
import {List} from 'immutable';
import {listToArray} from '../../helpers';

export class InvitationMapper {
  public static toInvitationDTO(invitation: InvitationDTOProps): InvitationDTO {
    return new InvitationDTO(invitation);
  }

  public static toInvitationListDTO(
    invitation: List<InvitationModel> | InvitationDTOProps[],
  ): InvitationDTO[] {
    const invitationList = List.isList(invitation)
      ? listToArray<InvitationModel>(invitation)
      : invitation;

    return invitationList?.map((invitation) =>
      InvitationMapper.toInvitationDTO(invitation),
    );
  }

  public static toInvitationModel(
    invitationDTO: InvitationDTO,
  ): InvitationModel {
    return new InvitationModel(invitationDTO);
  }

  public static toInvitationListModel(
    invitationDTOs: InvitationDTO[],
  ): InvitationListModel {
    return new InvitationListModel({invitation: invitationDTOs});
  }
}
