export interface IForgotPasswordDTOProps {
  identifier: string;
}

export class ForgotPasswordDTO {
  public identifier: string;

  constructor(props?: IForgotPasswordDTOProps) {
    this.identifier = props?.identifier || '';
  }
}
