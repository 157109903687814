import {Record} from 'immutable';
import Profile, {ProfileProps} from './Profile';
import {GENDER} from '../types';
import {WorkSchedulesListProps} from './WorkSchedulesList';
import {EmployeeDayOffListProps} from './EmployeeDayOffList';
import Company, {CompanyProps} from './Company';
import ServiceList, {ServiceListProps} from './ServiceList';

export interface EmployeeProps {
  uuid: string;
  title: string;
  picture_url: string;
  email: string;
  gender: GENDER;
  phone: string;
  birthday: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  profile: ProfileProps;
  company: CompanyProps;
  services: ServiceListProps;
  work_schedule: WorkSchedulesListProps;
  days_off: EmployeeDayOffListProps;
  invitations: any[]; // TODO: додати тип
  is_owner: string;
  status: string;
  created_at: string;
  updated_at: string;
}

/**
 * @class Employee
 * @classdesc Country model.
 * @property {string} id
 * @property {string} name
 * @property {string} code
 */
export default class Employee extends Record({
  uuid: '',
  title: '',
  picture_url: '',
  email: '',
  gender: GENDER.MALE,
  phone: '',
  birthday: '',
  first_name: '',
  last_name: '',
  middle_name: '',
  profile: {} as any,
  company: {},
  services: [],
  work_schedule: [],
  days_off: [],
  invitations: [],
  is_owner: '',
  status: '',
  created_at: '',
  updated_at: '',
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(props: EmployeeProps = {} as EmployeeProps) {
    // @ts-ignore
    const services = new ServiceList(props?.services || ([] as any));

    super({
      ...props,
      profile: new Profile(props?.profile || ({} as any)),
      company: new Company(props?.company || ({} as any)),
      ...services,
    });
  }

  /**
   * @desc Імя співробітника
   * @return {String}
   * */
  get fullName(): string {
    return new Profile({
      last_name: this.last_name,
      first_name: this.first_name,
    } as any).fullName;
  }
}
