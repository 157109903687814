import React, {useCallback, useEffect, useRef} from 'react';
import {
  DetailsItemView,
  FilterButton,
} from '../../../components/lib/DataDisplay';
import styled, {css} from 'styled-components';
import ListDateRange from '../../../components/lib/DataDisplay/ListActions/ListDateRange';
import {
  dateToIsoString,
  listToArray,
  toMoment,
} from '../../../services/helpers';
import {Row, Col, Select, SelectProps} from 'antd';
import {useOrderListFilter} from '../Managers';
import {
  IUseCompanyScheduleListProps,
  useSize,
  useStateCompanyClientList,
  useStateEmployeeList,
  useStoredCompanies,
  useStoredDepartmentList,
  useStoredStatusList,
} from '../../../hooks';
import {List} from 'immutable';
import {
  ClientModel,
  DepartmentModel,
  EmployeeModel,
  ScheduleCalendarModel,
} from '../../../struture';
import {useTranslation} from 'react-i18next';
import {useStateReducer} from '../../../components/lib/libV2/hooks';
import {StatusCategoryTag} from '../../Statuses';
import {DownOutlined, LoadingOutlined} from '@ant-design/icons';
import {DEFAULT_SCHEDULE_FILTER} from '../../../services/api/company';
import {useDropdownAlert} from '../../../contex';
import {StatusError} from '../../../components/lib/Errors';
import {FilterButtonProps} from '../../common/Buttons';

import '../../Statuses/Show/StatusListSelect.less';

export interface OrderFilterButtonProps
  extends FilterButtonProps<
    IUseCompanyScheduleListProps,
    ScheduleCalendarModel
  > {}

export interface OrderFilterButtonState {
  selectClients: SelectProps['options'] | undefined;
  selectEmployees: SelectProps['options'] | undefined;
  selectDepartments: SelectProps['options'] | undefined;
  selectStatuses: SelectProps['options'] | undefined;
}

const StyledListDateRange = styled(ListDateRange)`
  width: 100%;
  min-width: unset;
`;

const StyledSelect = styled(Select)<{disabled: boolean}>`
  width: 100%;
  
  ${({disabled}) =>
    !disabled &&
    css`
      & * > .ant-select-selection-placeholder {
        color: rgba(0, 0, 0, 0.88) !important;
      }
    `}
}
`;

const iconStyle = css`
  color: ${({theme}) => theme.colors.white};
  margin-left: 10px;
`;

const StyledDownOutlined = styled(DownOutlined)`
  ${iconStyle}
`;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  ${iconStyle}
`;

const StyledRow = styled(Row)`
  & > .ant-col {
    padding: 5px;
  }
`;

export function OrderFilterButton({
  loading: initLoading,
  onFilter,
}: OrderFilterButtonProps): React.JSX.Element {
  const {t} = useTranslation();
  const {defaultCompanyUuid} = useStoredCompanies();
  const {alert} = useDropdownAlert();

  const contentRef = useRef(null);
  const {width: contentWidth} = useSize(contentRef);
  const isFullWidth = contentWidth <= 340 && contentWidth > 0 ? 24 : 12;

  const {
    selectClients,
    selectEmployees,
    selectDepartments,
    selectStatuses,
    handleUpdate: handleUpdateFilterState,
  } = useStateReducer<OrderFilterButtonState>({
    selectClients: undefined,
    selectEmployees: undefined,
    selectDepartments: undefined,
    selectStatuses: undefined,
  });

  const {
    date_start,
    date_end,
    client_uuid,
    manager_uuid,
    location_uuid,
    status_uuid,
    loading,
    count,

    handleUpdateOrderListFilter,
    handleApplyOrderListFilters,
    handleCancelChangeOrderListFilter,
    handleResetOrderListFilters,
  } = useOrderListFilter();

  const {
    clients,
    loading: clientListLoading,
    handleSearchClients,
  } = useStateCompanyClientList({
    companyUuid: defaultCompanyUuid,
    provisioner_only: true,
  });

  const {
    employees,
    loading: employeeListLoading,
    handleSearchEmployees,
  } = useStateEmployeeList({
    companyUuid: defaultCompanyUuid,
  });

  const {
    departmentList,
    loading: loadingDepartmentList,
    handleSearchDepartments,
  } = useStoredDepartmentList({companyUuid: defaultCompanyUuid});

  const {
    statusList,
    loading: statusesListLoading,

    handleSearchStatuses,
  } = useStoredStatusList({companyUuid: defaultCompanyUuid});

  const handleFilterOrderList = useCallback(async () => {
    try {
      const schedules = await onFilter(
        {
          date_start,
          date_end,
          client_uuid,
          manager_uuid,
          location_uuid,
          status_uuid,
        },
        true,
      );

      if (List.isList(schedules)) {
        handleApplyOrderListFilters();
      }
    } catch (error: any) {
      alert(
        'error',
        t('Order'),
        `${t('An error occurred during order filter')}: ${error?.message}`,
      );
      throw new StatusError(error?.message, error?.status);
    }
  }, [
    alert,
    client_uuid,
    date_end,
    date_start,
    handleApplyOrderListFilters,
    location_uuid,
    manager_uuid,
    onFilter,
    status_uuid,
    t,
  ]);

  const handleResetOrderList = useCallback(async () => {
    try {
      const schedules = await onFilter(DEFAULT_SCHEDULE_FILTER, true);

      if (List.isList(schedules)) {
        handleResetOrderListFilters();
      }
    } catch (error: any) {
      alert(
        'error',
        t('Order'),
        `${t('An error occurred during order filter')}: ${error?.message}`,
      );
      throw new StatusError(error?.message, error?.status);
    }
  }, [alert, handleResetOrderListFilters, onFilter, t]);

  useEffect(() => {
    if (List.isList(clients)) {
      const clientsSelectList = clients?.map((client) => ({
        label: (
          <DetailsItemView<ClientModel>
            item={client}
            fields={{
              fullNameClient: {
                title: '',
              },
              phone: {description: ''},
            }}
          />
        ),
        value: client?.uuid,
      }));

      handleUpdateFilterState({selectClients: listToArray(clientsSelectList)});
    }
  }, [clients, handleUpdateFilterState]);

  useEffect(() => {
    if (List.isList(employees)) {
      const employeesSelectList = employees?.map((employee) => ({
        label: (
          <DetailsItemView<EmployeeModel>
            item={employee}
            fields={{
              fullName: {
                title: '',
              },
              phone: {description: ''},
            }}
          />
        ),
        value: employee?.uuid,
      }));

      handleUpdateFilterState({
        selectEmployees: listToArray(employeesSelectList),
      });
    }
  }, [employees, handleUpdateFilterState]);

  useEffect(() => {
    if (List.isList(departmentList)) {
      const departmentsSelectList = departmentList?.map((department) => ({
        label: (
          <DetailsItemView<DepartmentModel>
            item={department}
            fields={{
              dep_name: {
                title: '',
              },
              dep_address: {description: ''},
            }}
          />
        ),
        value: department?.uuid,
      }));

      handleUpdateFilterState({
        selectDepartments: listToArray(departmentsSelectList),
      });
    }
  }, [departmentList, employees, handleUpdateFilterState]);

  useEffect(() => {
    if (List.isList(statusList)) {
      const statusSelectList = statusList?.map((status) => ({
        value: status?.uuid,
        label: (
          <StatusCategoryTag
            category={status?.category!}
            title={status?.title!}
            icon={loading ? <StyledLoadingOutlined /> : <StyledDownOutlined />}
          />
        ),
      }));

      handleUpdateFilterState({
        selectStatuses: listToArray(statusSelectList),
      });
    }
  }, [departmentList, employees, handleUpdateFilterState, loading, statusList]);

  return (
    <FilterButton
      count={count}
      ref={contentRef}
      loading={initLoading}
      disabled={loading}
      onSuccess={handleFilterOrderList}
      onCancel={handleCancelChangeOrderListFilter}
      onReset={handleResetOrderList}>
      <StyledRow wrap>
        <Col span={24}>
          <StyledListDateRange
            size="large"
            defaultDateRangeValue={
              date_start && date_end
                ? [toMoment(date_start), toMoment(date_end)]
                : undefined
            }
            onChange={async (value) => {
              const [date_start = null, date_end = null] = value || [];

              await handleUpdateOrderListFilter({
                date_start: dateToIsoString(date_start),
                date_end: dateToIsoString(date_end),
              });
            }}
            loading={loading}
            disabled={loading}
          />
        </Col>
        <Col span={isFullWidth}>
          <StyledSelect
            allowClear
            size="large"
            optionLabelProp="label"
            popupMatchSelectWidth={false}
            popupClassName="status-select"
            value={status_uuid}
            placeholder={t('Status')}
            onSelect={(keywords: any) => handleSearchStatuses({keywords})}
            onChange={(status_uuid: any) =>
              handleUpdateOrderListFilter({status_uuid})
            }
            options={selectStatuses}
            loading={statusesListLoading || loading}
            disabled={statusesListLoading || loading}
          />
        </Col>
        <Col span={isFullWidth}>
          <StyledSelect
            size="large"
            popupMatchSelectWidth={200}
            showSearch
            allowClear
            loading={clientListLoading || loading}
            disabled={clientListLoading || loading}
            onSearch={(keywords) => handleSearchClients({keywords})}
            placeholder={t('Client')}
            options={selectClients}
            onChange={(client_uuid: any) =>
              handleUpdateOrderListFilter({client_uuid})
            }
            value={client_uuid}
          />
        </Col>
        <Col span={isFullWidth}>
          <StyledSelect
            popupMatchSelectWidth={200}
            showSearch
            allowClear
            loading={employeeListLoading || loading}
            disabled={employeeListLoading || loading}
            onSearch={(keywords) => handleSearchEmployees({keywords})}
            placeholder={t('Manager')}
            options={selectEmployees}
            onChange={(manager_uuid: any) =>
              handleUpdateOrderListFilter({manager_uuid})
            }
            value={manager_uuid}
            size="large"
          />
        </Col>
        <Col span={isFullWidth}>
          <StyledSelect
            popupMatchSelectWidth={200}
            showSearch
            allowClear
            loading={loadingDepartmentList || loading}
            disabled={loadingDepartmentList || loading}
            onSearch={(keywords) => handleSearchDepartments({keywords})}
            placeholder={t('Location')}
            options={selectDepartments}
            onChange={(location_uuid: any) =>
              handleUpdateOrderListFilter({location_uuid})
            }
            value={location_uuid}
            size="large"
          />
        </Col>
      </StyledRow>
    </FilterButton>
  );
}
