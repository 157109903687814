import {Record, List} from 'immutable';
import {IListProps} from './List';
import {
  StoreDocumentItemDTO,
  StoreDocumentItemModel,
  StoreDocumentModel,
} from '../internal';
import {WithoutRecursion} from '../type';

export interface IStoreDocumentItemListModelProps extends IListProps {
  doc_items: StoreDocumentItemDTO[];
  total: number;
  document?: StoreDocumentModel;
}

export interface IStoreDocumentItemListModelReturnType extends IListProps {
  doc_items: List<StoreDocumentItemModel>;
  total: number;
  document?: StoreDocumentModel;
}

export class StoreDocumentItemListModel extends Record<IStoreDocumentItemListModelReturnType>(
  {
    doc_items: List(),
    total: 0,
    document: undefined,
  },
) {
  constructor(
    {
      doc_items = [],
      ...props
    }: IStoreDocumentItemListModelProps = {} as IStoreDocumentItemListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IStoreDocumentItemListModelReturnType = {
      ...props,
      doc_items: List(
        doc_items.map(
          (doc_item) => new StoreDocumentItemModel(doc_item, withoutRecursion),
        ),
      ),
    };

    super(options);
  }
}
