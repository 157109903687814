import {Record} from 'immutable';
import Company, {CompanyProps} from './Company';
import Profile, {ProfileProps} from './Profile';
import {IClientsGroupSessionsProps} from './ClientsGroupSessions';
import {GENDER} from '../types';
import {DATE_SHORT_FORMAT, MomentDate} from '../helpers';

export interface ClientProps {
  id: string;
  uuid: string;
  status: number;
  first_name: string;
  last_name: string;
  middle_name: string;
  phone: string;
  email: string;
  saldo: number;
  picture_url: string;
  address: string;
  birthday: string;
  gender: GENDER;
  invitation: any[]; // TODO: додати тип
  created_at: string;
  updated_at: string;
  profile: ProfileProps;
  company: CompanyProps;
  clients_group_sessions: IClientsGroupSessionsProps;
}

/**
 * @class Client
 * @desc Клієнт
 */
export default class Client extends Record({
  id: 0,
  uuid: '',
  status: 0,
  first_name: '',
  last_name: '',
  middle_name: '',
  phone: '',
  email: '',
  picture_url: '',
  address: '',
  birthday: null,
  gender: GENDER.MALE,
  invitation: [],
  saldo: 0,
  created_at: MomentDate.format(MomentDate.currentDate()),
  updated_at: MomentDate.format(MomentDate.currentDate()),
  profile: {},
  company: {} as Company,
  clients_group_sessions: {},
  // clients_group_sessions: new ClientsGroupSessions({}),
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(props: ClientProps = {} as ClientProps) {
    const options = {
      // @ts-ignore
      company: new Company(props.company || props.Company),
      profile: {},
    };

    // @ts-ignore
    if (props.profile || props.Profile) {
      // @ts-ignore
      options.profile = new Profile(props.profile || props.Profile);
    }

    if (props && props.birthday) {
      // @ts-ignore
      options.birthday = MomentDate.format(props.birthday, DATE_SHORT_FORMAT);
    }

    super({...props, ...options} as any);
  }

  /**
   * @desc Повне Ім'я
   * @return {String}
   */

  get fullNameClient(): string {
    return `${this.first_name} ${this.last_name}`;
  }
}
