import * as React from 'react';
import {getReportById} from '../services/api/report';
import {ReportModel, ReportMapper, ReportDTO} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {IEntityProps} from '../services/types';
import {useDropdownAlert} from '../contex';
import {useTranslation} from 'react-i18next';

export interface IUseReportProps extends IEntityProps {
  reportUuid: string;
}

export interface IUseReportReturnType {
  error: null | any;
  loading: boolean;
  entity: ReportModel | null;
  refresh: (value: Partial<IUseReportProps>) => Promise<ReportModel | void>;
  reportUuid: string;
}

export function useReport(
  {loadOnInit = true, reportUuid}: IUseReportProps = {} as IUseReportProps,
): IUseReportReturnType {
  const {cancellablePromise} = useCancellablePromise();
  const {alert} = useDropdownAlert();
  const {t} = useTranslation();

  const {
    entity,
    loading,
    error,
    handleUpdate,
    reportUuid: entityUuid,
  } = useStateReducer<Omit<IUseReportReturnType, 'refresh'>>({
    entity: null,
    reportUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading,
      reportUuid = entityUuid,
    }: Partial<IUseReportProps> = {}): Promise<ReportModel | void> => {
      try {
        handleUpdate({loading: showLoading, error: null, reportUuid});

        const report = await cancellablePromise<ReportDTO>(
          getReportById(reportUuid),
        );

        const reportModel = ReportMapper.toReportModel(report);

        handleUpdate({
          entity: reportModel,
          loading: false,
        });
        return reportModel;
      } catch (err: any) {
        alert(
          'error',
          t('Report'),
          `${t('An error occurred during get report')} : ${err?.message}`,
        );

        handleUpdate({
          error: err,
          loading: false,
        });
      }
    },

    [alert, cancellablePromise, entityUuid, handleUpdate, t],
  );

  React.useEffect(() => {
    if (loadOnInit && reportUuid) {
      (async () => {
        await refresh({reportUuid});
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, reportUuid]);

  return {
    error,
    loading,
    refresh,
    entity,
    reportUuid,
  };
}
