import {List} from 'immutable';
import {CompanyModel, CategoryModel, CompanyRequisiteDTO} from '../internal';
import {immerable} from 'immer';

export class CompanyFormDTO {
  [immerable]? = true;
  public uuid: string;

  public title: string;

  public description: string;

  public phone: string;

  public email: string;

  public address: string;

  public work_schedules: string;

  public category_uuid: string;

  public currency_symbol: string;

  public notification_language: string;
  public requisites: string;
  public requisite_list?: CompanyRequisiteDTO[];
  public requisites_v2: CompanyRequisiteDTO[];

  constructor(props?: CompanyModel) {
    this.uuid = props?.uuid || '';
    this.title = props?.title || '';
    this.description = props?.description || '';
    this.phone = props?.phone || '';
    this.email = props?.email || '';
    this.address = props?.address || '';
    this.currency_symbol = props?.currency_symbol || '';
    this.notification_language = props?.notification_language || '';
    this.requisites = props?.requisites || '';
    this.work_schedules = props?.work_schedules || '08:00-23:00';
    this.category_uuid =
      this.getFirstCategoryUuid(
        props?.categoryListModel as List<CategoryModel>,
      ) || '';
    this.requisite_list = props?.requisite_list || [];
    this.requisites_v2 = props?.requisite_list || [];
  }

  private getFirstCategoryUuid(categories: List<CategoryModel>) {
    return categories?.first()?.uuid;
  }
}
