import {Record, List} from 'immutable';
import {ListModel, ListProps} from './List';
import EmployeeDayOff, {EmployeeDayOffProps} from './EmployeeDayOff';

export interface EmployeeDayOffListProps extends ListProps {
  days_off: EmployeeDayOffProps[];
}

/**
 * @class EmployeeDayOffList
 * @classdesc DayOff list model.
 * @extends {Record}
 * @property {number} total - total amount of records on backend
 */
export default class EmployeeDayOffList extends Record({
  days_off: List(),
  ...ListModel.toJS(),
}) {
  /**
   * @constructor
   * @param {{days_off:[]}} param0
   */
  constructor({days_off = [], ...props}: EmployeeDayOffListProps) {
    super({
      days_off: List(days_off.map((day) => new EmployeeDayOff(day))),
      ...props,
    });
  }
}
