import {Empty} from 'antd';
import {CashBoxList} from '../List';
import {useAcl} from '../../../contex';
import {useTranslation} from 'react-i18next';
import styled, {useTheme} from 'styled-components';
import {ListLayout} from '../../../components/lib/Layout';
import {StyledTitle} from '../../../components/lib/Styled';
import {SessionStorageItems} from '../../../services/const';
import {PaymentListBottomView, PaymentTypeView} from '../Show';
import {useLocation, useNavigate, useParams} from 'react-router';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import useStoredCompanies from '../../../hooks/useStoredCompanies';
import {CreatePaymentButton, DeletePaymentButton} from '../Buttons';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {DownOutlined, MinusOutlined, PlusOutlined} from '@ant-design/icons';
import {PaymentScheduleOperationType} from '../../../services/api/orders';
import {ITableProps, Table} from '../../../components/lib/libV2/DataDisplay';
import {HeaderLeft, ListActions} from '../../../components/lib/DataDisplay';
import {StyledDetailsView} from '../../../components/lib/Styled/DetailsView';
import ListDateRange from '../../../components/lib/DataDisplay/ListActions/ListDateRange';

import {
  PaymentProps,
  useKeyboardOpenForm,
  usePaymentListPage,
  useSearchInputFocus,
  useStatePaymentList,
  useStopLoading,
  useStoredCashBoxList,
  useStoredProfile,
} from '../../../hooks';

import {
  textToUpperCase,
  toMoment,
  isThereContent,
} from '../../../services/helpers';

import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '../../../services/types';

import {
  StyledButtonContainer,
  StyledListActionsContainer,
} from '../Show/ListStyledComponent';

import {
  PaymentFormDTO,
  PaymentMapper,
  PaymentModel,
  PaymentScheduleFormDTO,
} from '../../../struture';

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-bottom: 20px;
`;

const CashBoxContainer = styled.div<{$withCashBox: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: ${({$withCashBox}) =>
    $withCashBox ? 'space-between' : 'flex-end'};
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-top: -15px;
  margin-bottom: 5px;
`;

const StyledTable = styled(Table)`
  margin-bottom: 0;
` as React.ComponentType as React.FunctionComponent<ITableProps<PaymentModel>>;

export default function PaymentListPage() {
  const location = useLocation();
  const {t} = useTranslation();
  const {manage} = useAcl(({payment}) => payment);
  const {clientId} = useParams();
  const navigate = useNavigate();
  const {profile} = useStoredProfile();
  const {defaultCompanyUuid} = useStoredCompanies();
  const theme: any = useTheme();

  const {ref: listRef} = useKeyboardOpenForm({
    className: 'payment-create-event',
    disabled: !manage,
  });

  const [type, setType] = useState<PaymentScheduleOperationType>(
    location?.state?.type || PaymentScheduleOperationType.ALL,
  );
  const [typeLoading, setTypeLoading] = useState(false);
  const [isStartPaymentLoading, setIsStartPaymentLoading] = useState(
    !location?.state?.cashBoxUuid,
  );

  const once = useRef(false);

  const {
    defaultCashBox,
    handleUpdateDefaultCashBox,
    cashBoxList,
    ...cashBoxListParams
  } = useStoredCashBoxList({
    companyUuid: defaultCompanyUuid,
  });

  const paymentsAllOperations = JSON.parse(
    sessionStorage.getItem(SessionStorageItems.paymentsAllOperations) ||
      'false',
  );

  const {
    payments,
    loading: paymentsLoading,
    error: paymentsError,
    limit,
    total,
    refresh,
    date_start,
    date_end,
    keywords,
    stats,
    page,

    handleCreatePayment,
    handleUpdatePayment,
    handleDeletePayments: onDeletePayments,
    handlePickPaymentRange,
    handleSearchPayments,
    handleRefreshPayments,
  } = useStatePaymentList({
    companyUuid: defaultCompanyUuid,
    loadOnInit:
      (!!defaultCashBox && isStartPaymentLoading) || paymentsAllOperations,
    payment_type: type,
    cashBox: paymentsAllOperations ? null : defaultCashBox,
    // handleUpdateDefaultCashBox,
  });

  const loading = useStopLoading({
    loading: paymentsLoading || typeLoading,
    error: paymentsError,
    message: 'An error occurred during payments loading',
  });

  const {inputRef, focus, daleyFocus} = useSearchInputFocus({loading});

  const handleDeletePayments = useCallback(
    async (value: string[]) => {
      await onDeletePayments(value);
      focus();
    },
    [focus, onDeletePayments],
  );

  const handledChangeCashBoxes = useCallback(
    async (cashbox_uuid: string | null) => {
      if (!cashbox_uuid) {
        sessionStorage.setItem(
          SessionStorageItems.paymentsAllOperations,
          JSON.stringify(true),
        );
      }

      await handleRefreshPayments({cashbox_uuid: cashbox_uuid as any});
    },
    [handleRefreshPayments],
  );

  useEffect(() => {
    if (location?.state?.cashBoxUuid && cashBoxList?.size && !once.current) {
      once.current = true;
      window.history.replaceState({}, document.title);
      const cashBox = cashBoxList?.find(
        ({uuid}) => uuid === location?.state?.cashBoxUuid,
      );

      if (cashBox) {
        handleUpdateDefaultCashBox(cashBox);

        (async () => {
          await refresh({
            cashbox_uuid: location?.state?.cashBoxUuid,
            date_start: location?.state?.date,
            date_end: location?.state?.date,
            isSearch: true,
            isStartLoading: false,
          });
        })();

        setIsStartPaymentLoading(true);
      }
    }
  }, [
    cashBoxList,
    handleUpdateDefaultCashBox,
    location?.state?.cashBoxUuid,
    location?.state?.date,
    refresh,
  ]);

  const {rowSelection, columns, selectedPayments} = usePaymentListPage({
    type,
    handleUpdatePayment,
    handleDeletePayments,
    stats,
    daleyFocus,
  });

  const routes = useBreadcrumb([
    {
      path: `/${Routes.payments}`,
      breadcrumbName: 'Cash flow',
    },
  ]);

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.companies}/${defaultCompanyUuid}`);
  }, [defaultCompanyUuid, navigate]);

  const payment: (
    props?: PaymentProps,
  ) => PaymentFormDTO | PaymentScheduleFormDTO = (
    {payment_type}: PaymentProps = {} as PaymentProps,
  ) =>
    ({
      ...PaymentMapper.toPaymentFormDTO(
        {
          payment_type,
        } as PaymentModel,
        {cashier: profile?.defaultEmployeeModel},
      ),
    } as PaymentFormDTO);

  const onClientPaymentTypeChange = useCallback(
    async (type: any) => {
      if (defaultCompanyUuid) {
        setTypeLoading(true);
        setType(type);
        window.history.replaceState({}, document.title);
        await refresh({
          payment_type: type,
        });
        setTypeLoading(false);
      }
    },
    [defaultCompanyUuid, refresh],
  );

  return (
    <ListLayout
      ref={listRef}
      aclItem={RoutesAcl[Routes.payments]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.payments]))}
      headerRight={
        <>
          {selectedPayments.length ? (
            <DeletePaymentButton
              disabled={!manage}
              payments={selectedPayments}
              onSuccess={handleDeletePayments}
            />
          ) : (
            <CreatePaymentButton
              disabled={defaultCashBox === null || !manage}
              payment={payment}
              companyUuid={defaultCompanyUuid}
              loading={loading}
              onSuccess={handleCreatePayment}
              paymentOperationType={type}
              onCancel={daleyFocus}
            />
          )}
        </>
      }
      routes={routes}
      empty={
        payments?.size ? null : (
          <Empty
            description={
              defaultCashBox === null
                ? t('To create a payment, you need to add a cash box')
                : t('There are no payments history from range.')
            }>
            {defaultCashBox === null ? null : type ===
                PaymentScheduleOperationType.In ||
              type === PaymentScheduleOperationType.Out ? (
              <CreatePaymentButton
                type="primary"
                payment={payment}
                companyUuid={defaultCompanyUuid}
                loading={loading}
                onSuccess={handleCreatePayment}
                disabled={!manage}
                title={
                  type === PaymentScheduleOperationType.In
                    ? t('Create receipts')
                    : t('Create schedulePayment')
                }
                paymentOperationType={type}
                onCancel={daleyFocus}
              />
            ) : (
              <CreatePaymentButton
                paymentOperationType={type}
                type="default"
                payment={payment}
                companyUuid={defaultCompanyUuid}
                loading={loading}
                onSuccess={handleCreatePayment}
                disabled={!manage}
                title={
                  <StyledButtonContainer>
                    <StyledTitle>{t('Create payment')}</StyledTitle>
                    <DownOutlined />
                  </StyledButtonContainer>
                }
                onCancel={daleyFocus}
              />
            )}
          </Empty>
        )
      }
      headerExtra={
        <StyledListActionsContainer>
          <CashBoxContainer $withCashBox={!clientId}>
            {clientId ? null : (
              <CashBoxList
                {...cashBoxListParams}
                cashBoxList={cashBoxList}
                defaultCashBox={defaultCashBox}
                handleUpdateDefaultCashBox={handleUpdateDefaultCashBox}
                handledChangeCashBoxes={handledChangeCashBoxes}
                handleCreatePayment={handleCreatePayment}
              />
            )}
            <ListDateRange
              defaultDateRangeValue={
                date_start && date_end
                  ? [toMoment(date_start), toMoment(date_end)]
                  : undefined
              }
              onChange={handlePickPaymentRange}
              loading={loading}
            />
          </CashBoxContainer>

          <HeaderContainer>
            <StyledDetailsView
              titles={[]}
              selected={[
                type === PaymentScheduleOperationType.ALL,
                type === PaymentScheduleOperationType.In,
                type === PaymentScheduleOperationType.Out,
              ]}>
              <PaymentTypeView
                color={theme.colorsV2.allBgColor}
                type={PaymentScheduleOperationType.ALL}
                title="All"
                stats={stats}
                onClick={onClientPaymentTypeChange}
                disabled={loading}
              />
              <PaymentTypeView
                color={theme.tabs.success}
                type={PaymentScheduleOperationType.In}
                title={t('Receipts')}
                stats={stats}
                onClick={onClientPaymentTypeChange}
                disabled={loading}
              />
              <PaymentTypeView
                color={theme.colorsV2.outlayBgColor}
                type={PaymentScheduleOperationType.Out}
                title={t('tPayments')}
                stats={stats}
                onClick={onClientPaymentTypeChange}
                disabled={loading}
              />
            </StyledDetailsView>
          </HeaderContainer>
          <ListActions
            withoutPicker={true}
            inputRef={inputRef}
            searchText={keywords}
            loading={loading}
            handleSearch={handleSearchPayments}
            inputTooltip={t('Search payments')}
            inputLabel={t('Search payments')}
            withSearchContent={
              <>
                <CreatePaymentButton
                  type="primary"
                  payment={payment}
                  companyUuid={defaultCompanyUuid}
                  loading={loading}
                  onSuccess={handleCreatePayment}
                  title={t('Receipts')}
                  paymentOperationType={PaymentScheduleOperationType.In}
                  icon={<PlusOutlined />}
                  className="payment-create-event"
                  onCancel={daleyFocus}
                  disabled={!manage}
                />
                <CreatePaymentButton
                  type="primary"
                  payment={payment}
                  companyUuid={defaultCompanyUuid}
                  loading={loading}
                  onSuccess={handleCreatePayment}
                  title={t('schedulePayment')}
                  paymentOperationType={PaymentScheduleOperationType.Out}
                  icon={<MinusOutlined />}
                  onCancel={daleyFocus}
                  disabled={!manage}
                />
              </>
            }
          />
        </StyledListActionsContainer>
      }
      outsideFooterContent={
        isThereContent(stats) ? <PaymentListBottomView stats={stats} /> : null
      }
      loading={defaultCashBox === null ? false : loading && !payments?.size}>
      <>
        <StyledTable
          rowSelection={rowSelection}
          total={total}
          pageSize={limit}
          dataSource={payments}
          onChange={handleRefreshPayments}
          columns={columns}
          loading={loading}
          page={page}
        />
      </>
    </ListLayout>
  );
}
