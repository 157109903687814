import styled from 'styled-components';

export const StyledListActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SegmentedContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 20px;

  width: 100%;
`;

export const RangeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-top: -15px;
  margin-bottom: 5px;
`;
