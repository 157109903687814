import {Record} from 'immutable';
import ServiceList, {ServiceListProps} from './ServiceList';
import {MomentDate} from '../helpers';

export interface GroupProps extends Pick<ServiceListProps, 'services'> {
  uuid: string;
  description: string;
  title: string;
  created_at: string;
  updated_at: string;
  status: number;
}

/**
 * @class Client
 * @desc Клієнт
 */
export default class Group extends Record({
  uuid: '',
  title: '',
  description: '',
  status: 0,
  services: [],
  created_at: MomentDate.format(MomentDate.currentDate()),
  updated_at: MomentDate.format(MomentDate.currentDate()),
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(props: GroupProps = {} as GroupProps) {
    const options: any = {};

    if (props?.services) {
      options.services = new ServiceList({services: props?.services} as any);
    }

    super({...props, ...options});
  }
}
