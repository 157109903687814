import {correctPrice, toMoment} from '../../helpers';
import {
  IEmployeeSalaryModelReturnType,
  IEmployeeSalaryAccrualAt,
} from '../internal';
import {Moment} from 'moment';

export class EmployeeSalaryFormDTO {
  public sum: string;

  public comment: string;

  public start_date: string | Moment;

  public enabled: boolean;

  public accrual_at: IEmployeeSalaryAccrualAt[];

  constructor(props: IEmployeeSalaryModelReturnType) {
    this.start_date = props?.start_date ? toMoment(props?.start_date) : '';
    this.comment = props?.comment || '';
    this.sum = correctPrice(props?.sum || 0);
    this.accrual_at = props?.accrual_at?.length
      ? props?.accrual_at
      : [{sum: correctPrice(0), day_of_month: undefined} as any];
    this.enabled = typeof props?.enabled === 'boolean' ? props?.enabled : true;
  }
}
