import {Record} from 'immutable';
import Company, {CompanyProps} from './Company';

export interface ITemplateProps {
  template_type: string;
  template_uuid: string;
  template_title: string;
  template_event: string;
  template_body: string;
  template_comment: string;
  company?: CompanyProps;
  created_at: string;
  updated_at: string;
}

export interface ITemplateReturnType extends Omit<ITemplateProps, 'company'> {
  company?: Company;
}

/**
 * @class Company
 * @classdesc
 * @extends {Record}
 * @property {string} id - backup id
 */
export default class Template extends Record<ITemplateReturnType>({
  template_uuid: '',
  template_type: '',
  template_title: '',
  template_event: '',
  template_body: '',
  template_comment: '',
  company: {} as Company,
  created_at: '',
  updated_at: '',
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(props: ITemplateProps = {} as ITemplateProps) {
    const options: any = {};

    // @ts-ignore
    if (props?.company) {
      options.company = new Company(props?.company);
    }

    super({
      ...props,
      ...options,
    });
  }
}
