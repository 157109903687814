import {Record, Map} from 'immutable';
import {toImmutableMap, toTimestamp} from '../services/core';

import JobMessage from './JobMessage';

export const JOB_TYPE_UNKNOWN = 0;
export const JOB_TYPE_CREATE = 1;
export const JOB_TYPE_RESTORE = 2;
export const JOB_TYPE_DELETE = 3;

export const JOB_STATUS_UNKNOWN = 0;
export const JOB_STATUS_QUEUED = 1;
export const JOB_STATUS_RUNNING = 2;
export const JOB_STATUS_COMPLETE = 3;
export const JOB_STATUS_CLEANING = 4;
export const JOB_STATUS_FAILED = 5;
export const JOB_STATUS_TIMEOUT = 6;

export interface JobProps {
  userId: string;
  agentId: string;
  backupId: string;
  id: string;
  type: number;
  status: number;
  data: string;
  createdAt: string;
  startedAt: string;
  endedAt: null;
  messages: Map<any, any>;
}

/**
 * @class Job
 * @classdesc
 * Storage location model.
 * @property {string} name The storage location name
 * @property {string} type The storage location type (default mysql)
 */
export default class Job extends Record({
  userId: '',
  agentId: '',
  backupId: '',
  id: '',
  type: JOB_TYPE_UNKNOWN,
  status: JOB_STATUS_UNKNOWN,
  data: '',
  createdAt: null,
  startedAt: null,
  endedAt: null,
  // @ts-ignore
  messages: new Map(),
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(props: JobProps = {} as JobProps) {
    super({
      ...props,
      // @ts-ignore
      createdAt: toTimestamp(props?.createdAt),
      // @ts-ignore
      startedAt: toTimestamp(props?.startedAt),
      // @ts-ignore
      endedAt: toTimestamp(props?.endedAt),
      messages: toImmutableMap(JobMessage, props?.messages),
    });
  }

  getStatusDescription(): string {
    switch (this.status) {
      case JOB_STATUS_COMPLETE:
        return 'Complete';
      case JOB_STATUS_FAILED:
        return 'Failed';
      case JOB_STATUS_TIMEOUT:
        return 'Timeout';
      case JOB_STATUS_QUEUED:
        return 'Queued';
      case JOB_STATUS_RUNNING:
        return 'Running';
      case JOB_STATUS_CLEANING:
        return 'Cleaning';
      case JOB_STATUS_UNKNOWN:
      default:
        return 'Unknown';
    }
  }
}
