import React from 'react';
import {Button as AntButton, ButtonProps} from 'antd';
import styled, {css} from 'styled-components';

export interface IButtonProps extends ButtonProps {}

export const styleButton = css`
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: ${({theme}) => theme.colors.thirdTextColor};
  cursor: pointer;
  background: -webkit-linear-gradient(
    295deg,
    ${({theme}) => theme.colors.buttonPrimaryColor} 0%,
    ${({theme}) => theme.colors.buttonSecondaryColor} 100%
  );
  background: -o-linear-gradient(
    295deg,
    ${({theme}) => theme.colors.buttonPrimaryColor} 0%,
    ${({theme}) => theme.colors.buttonSecondaryColor} 100%
  );
  background: linear-gradient(
    25deg,
    ${({theme}) => theme.colors.buttonPrimaryColor} 0%,
    ${({theme}) => theme.colors.buttonSecondaryColor} 100%
  );
  box-shadow: 0 0 20px ${({theme}) => theme.colors.buttonShadowColor};
  border: none;
  border-radius: ${({theme}) => theme.border.baseBorderRadius};

  &:hover {
    background: -webkit-linear-gradient(
      295deg,
      ${({theme}) => theme.colors.buttonPrimaryColor} 0%,
      ${({theme}) => theme.colors.buttonSecondaryColor} 100%
    );
    background: -o-linear-gradient(
      295deg,
      ${({theme}) => theme.colors.buttonPrimaryColor} 0%,
      ${({theme}) => theme.colors.buttonSecondaryColor} 100%
    );
    background: linear-gradient(
      25deg,
      ${({theme}) => theme.colors.buttonPrimaryColor} 0%,
      ${({theme}) => theme.colors.buttonSecondaryColor} 100%
    );
    transition: 0.3s;
    box-shadow: 0 0 30px #fe6f28;
    color: ${({theme}) => theme.colors.thirdTextColor} !important;
    border: none;
  }

  &:active,
  &:focus {
    background: -webkit-linear-gradient(
      295deg,
      ${({theme}) => theme.colors.buttonActivePrimaryColor} 0%,
      ${({theme}) => theme.colors.buttonActiveSecondaryColor} 100%
    );
    background: -o-linear-gradient(
      295deg,
      ${({theme}) => theme.colors.buttonActivePrimaryColor} 0%,
      ${({theme}) => theme.colors.buttonActiveSecondaryColor} 100%
    );
    background: linear-gradient(
      25deg,
      ${({theme}) => theme.colors.buttonActivePrimaryColor} 0%,
      ${({theme}) => theme.colors.buttonActiveSecondaryColor} 100%
    );
    transition: 0.3s;
    box-shadow: 0 0 10px ${({theme}) => theme.colors.buttonActiveSecondaryColor};
    border: none;
    color: ${({theme}) => theme.colors.thirdTextColor} !important;
  }
`;

const StyledAntButton = styled(AntButton)`
  ${styleButton}
`;

export default function AccentButton({
  children,
  type = 'primary',
  ...rest
}: IButtonProps) {
  return (
    <StyledAntButton type={type} {...rest}>
      {children || null}
    </StyledAntButton>
  );
}
