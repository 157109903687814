import React from 'react';
import {Text} from '../Format';
import {Button, ButtonProps} from 'antd';
import {useLocation} from 'react-router';
import {useTranslation} from 'react-i18next';
import {LeftOutlined} from '@ant-design/icons';
import styled, {useTheme, css} from 'styled-components';

export interface IHeaderLeftProps extends ButtonProps {
  absolute?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  title?: string;
}

const StyledBackButton = styled(Button)<{$absolute?: boolean}>`
  width: fit-content;
  z-index: 2;
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  ${({$absolute}) =>
    $absolute &&
    css`
      position: absolute;
      left: 10px;
      top: 10px;
    `}
  &&:hover,
  &&:active,
  &&:focus {
    background-color: transparent;
    border-color: transparent;
    opacity: 0.7;
  }
`;

export function HeaderLeft({
  absolute = true,
  children,
  title,
  ...props
}: IHeaderLeftProps): JSX.Element {
  const {t} = useTranslation();
  const theme: any = useTheme();
  const location = useLocation();

  return (
    <>
      <StyledBackButton $absolute={absolute} type="text" {...props}>
        <LeftOutlined color={theme.colors.primary} />
        {location?.state?.prevTitle || title ? (
          <Text>{t(location?.state?.prevTitle || title)}</Text>
        ) : null}
      </StyledBackButton>
      {children || null}
    </>
  );
}
