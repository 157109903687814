import {List} from 'immutable';
import {
  StatusDTO,
  IStatusDTOProps,
  StatusFormDTO,
  StatusModel,
  StatusListModel,
  IStatusCategoryDTOProps,
  StatusCategoryDTO,
  StatusCategoryModel,
  StatusCategoryListModel,
  IScheduleCalendarLatestStatusDTOProps,
  ScheduleCalendarLatestStatusDTO,
  ScheduleCalendarLatestStatusModel,
  StatusCommentFormDTO,
} from '../internal';
import {listToArray} from '../../helpers';
import {WithoutRecursion} from '../type';

export interface IStatusListDTO {
  statuses: StatusDTO[];
  total: number;
}

export interface IStatusCategoryListDTO {
  categories: StatusCategoryDTO[];
  total: number;
}

export class StatusMapper {
  public static toStatusDTO(
    status: StatusModel | IStatusDTOProps | StatusDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): StatusDTO {
    return new StatusDTO(status, [
      WithoutRecursion.status,
      ...withoutRecursion,
    ]);
  }

  public static toStatusListDTO(
    statuses: List<StatusModel> | IStatusDTOProps[] | StatusDTO[],
    total?: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): IStatusListDTO {
    const statusList = List.isList(statuses)
      ? listToArray<StatusModel>(statuses)
      : statuses;

    return {
      statuses: statusList?.map((status) =>
        StatusMapper.toStatusDTO(status, [
          WithoutRecursion.status,
          ...withoutRecursion,
        ]),
      ),
      total: total || 0,
    };
  }

  public static toStatusModel(
    statusDTO: StatusDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): StatusModel {
    return new StatusModel(statusDTO, [
      WithoutRecursion.status,
      ...withoutRecursion,
    ]);
  }

  public static toStatusListModel(
    statusDTOs: StatusDTO[],
    total?: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): StatusListModel {
    return new StatusListModel({statuses: statusDTOs, total}, [
      WithoutRecursion.status,
      ...withoutRecursion,
    ]);
  }

  public static toStatusFormDTO(
    statusModel?: StatusModel,
    editMode?: boolean,
  ): StatusFormDTO {
    return new StatusFormDTO(statusModel as StatusModel, editMode);
  }

  public static toStatusCategoryDTO(
    category: IStatusCategoryDTOProps,
  ): StatusCategoryDTO {
    return new StatusCategoryDTO(category);
  }

  public static toStatusCategoryListDTO(
    categories: IStatusCategoryDTOProps[],
    total?: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): IStatusCategoryListDTO {
    return {
      categories: categories?.map((category) =>
        StatusMapper.toStatusCategoryDTO(category),
      ),
      total: total || 0,
    };
  }

  public static toStatusCategoryModel(
    statusCategoryDTO: StatusCategoryDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): StatusCategoryModel {
    return new StatusCategoryModel(statusCategoryDTO, [
      WithoutRecursion.status,
      ...withoutRecursion,
    ]);
  }

  public static toStatusCategoryListModel(
    statusCategoriesDTOs: StatusCategoryDTO[],
    total?: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): StatusCategoryListModel {
    return new StatusCategoryListModel(
      {categories: statusCategoriesDTOs, total},
      [WithoutRecursion.status, ...withoutRecursion],
    );
  }

  public static toScheduleCalendarLatestStatusDTO(
    latestStatus?:
      | IScheduleCalendarLatestStatusDTOProps
      | ScheduleCalendarLatestStatusModel,
  ): ScheduleCalendarLatestStatusDTO {
    return new ScheduleCalendarLatestStatusDTO(
      latestStatus as IScheduleCalendarLatestStatusDTOProps,
    );
  }

  public static toScheduleCalendarLatestStatusModel(
    latestStatus?: ScheduleCalendarLatestStatusDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): ScheduleCalendarLatestStatusModel {
    return new ScheduleCalendarLatestStatusModel(
      latestStatus as ScheduleCalendarLatestStatusDTO,
    );
  }

  public static toStatusCommentFormDTO(
    status?: StatusModel,
  ): StatusCommentFormDTO {
    return new StatusCommentFormDTO(status);
  }
}
