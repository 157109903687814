import {Record, List} from 'immutable';
import {ListModel, ListProps} from './List';
import GoogleCalendar, {IGoogleCalendarProps} from './GoogleCalendar';

export interface IGoogleCalendarListProps extends ListProps {
  tokens: IGoogleCalendarProps[];
}

export default class GoogleCalendarList extends Record({
  tokens: List(),
  ...ListModel.toJS(),
}) {
  /**
   * @constructor
   * @param {{templates:[]}} param0
   */
  constructor({tokens = [], ...props}: IGoogleCalendarListProps) {
    super({
      tokens: List(tokens.map((token) => new GoogleCalendar(token))),
      ...props,
    });
  }
}
