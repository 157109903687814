import React, {useMemo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {Col, Form, Input, Row} from 'antd';
import {
  IDefaultFormProps,
  DefaultForm,
  DatePicker,
} from '../../../components/lib/General';
import {CashBoxAdjustBalancesFormDTO} from '../../../struture';
import {useDropdownAlert} from '../../../contex';
import {useStateEmployeeList, useStoredCompanies} from '../../../hooks';
import {SearchSelect} from '../../../components/lib/DataDisplay';
import {isListToArray} from '../../../services/helpers';

export interface ICashBoxAdjustBalancesFormProps
  extends Omit<
    IDefaultFormProps<
      CashBoxAdjustBalancesFormDTO,
      CashBoxAdjustBalancesFormDTO
    >,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  cashBox: CashBoxAdjustBalancesFormDTO;
}

export function CashBoxAdjustBalancesForm({
  loading,
  editMode,
  cashBox,
  onCancel,
  ...rest
}: ICashBoxAdjustBalancesFormProps) {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const {defaultCompanyUuid} = useStoredCompanies();
  const [instance] = Form.useForm();
  const {defaultCompany} = useStoredCompanies();

  const additionalValues = useMemo(
    () => ({
      uuid: cashBox?.uuid,
    }),
    [cashBox?.uuid],
  );

  const {
    employees,
    loading: employeesLoading,
    handleSearchEmployees,
  } = useStateEmployeeList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Cash box'),
        `${t('An error occurred during edit cash box adjust balances')} : ${
          apiError?.message
        }`,
      );
    },
    [alert, t],
  );

  return (
    <DefaultForm<any, any>
      instance={instance}
      editMode={editMode}
      initialValues={{
        ...cashBox,
      }}
      additionalValuesRequest={additionalValues}
      showNotify={false}
      onCancel={onCancel}
      notifyError={notifyError}
      {...rest}>
      {({loadingSubmit}) => (
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              label={t('Date')}
              name="date"
              rules={[
                {
                  required: true,
                  message: t('Date must be specified'),
                },
              ]}
              tooltip={t('Date')}>
              <DatePicker
                showTime
                format="YYYY.MM.DD HH:mm"
                disabled={loadingSubmit}
                placeholder={t('Date')}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('Sum')}
              name="sum"
              required
              rules={[
                () => ({
                  validator(_, price) {
                    const value = price;

                    if (Number.isNaN(Number(value))) {
                      return Promise.reject(
                        new Error(t('Sum must be a number')),
                      );
                    }

                    if (!Number(value)) {
                      return Promise.reject(
                        new Error(t('Sum must be specified')),
                      );
                    }

                    return Promise.resolve();
                  },
                }),
              ]}>
              <Input
                addonBefore={defaultCompany?.currency_symbol}
                disabled={loadingSubmit}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('Cashier')}
              name="cashier_uuid"
              rules={[
                {
                  required: true,
                  message: t('Cashier must be specified'),
                },
              ]}
              tooltip={t('Cashier')}>
              <SearchSelect
                onSearch={(keywords) =>
                  handleSearchEmployees({
                    keywords,
                    limit: 100,
                    showLoading: false,
                  })
                }
                getOptionValueTitle="fullName"
                name="cashier_uuid"
                placeholder={t('Select a cashier')}
                disable={loadingSubmit || employeesLoading}
                data={isListToArray(employees as any)}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label={t('note')} name="comment" tooltip={t('note')}>
              <Input.TextArea
                allowClear
                autoSize={{minRows: 2, maxRows: 5}}
                disabled={loadingSubmit}
                placeholder={t('Enter your note')}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </DefaultForm>
  );
}
