import {Record} from 'immutable';

export enum PERIOD_MOVEMENT_FUNDS {
  Day = '0',
  Week = '1',
  Two_week = '2',
  Month = '4',
}

export enum FINANCIAL_REPORTING {
  Day = '1',
  Week = '7',
  Month = '30',
}

export interface IFinancialReliabilityProps {
  period_of_financial_statement: PERIOD_MOVEMENT_FUNDS;
  period_of_payments_movement: FINANCIAL_REPORTING;
  financial_client_uuid: string;
  sms_notification_enabled: boolean;
  email_notification_enabled: boolean;
}

/**
 * @class
 * @desc Клієнт
 */
export default class FinancialReliability extends Record({
  period_of_financial_statement: PERIOD_MOVEMENT_FUNDS.Two_week,
  period_of_payments_movement: FINANCIAL_REPORTING.Day,
  financial_client_uuid: '',
  sms_notification_enabled: false,
  email_notification_enabled: false,
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(
    props: IFinancialReliabilityProps = {} as IFinancialReliabilityProps,
  ) {
    super({
      ...props,
      email_notification_enabled: Boolean(
        Number(props?.email_notification_enabled),
      ),
      sms_notification_enabled: Boolean(
        Number(props?.sms_notification_enabled),
      ),
    });
  }
}
