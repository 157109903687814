import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {HeaderLeft} from '../../../components/lib/DataDisplay';
import {
  useRequiredFields,
  useStoredPriceMargin,
  useStopLoading,
  useStoredCompanies,
  useStoredPriceMarginList,
} from '../../../hooks';
import {PRICE_MARGIN_REQUIRED_FIELD} from '../../../services/api/priceMargin';
import {OverviewLayout} from '../../../components/lib/Layout';
import {StyledTitle, StyledDescription} from '../../../components/lib/Styled';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import {PriceMarginTabPanel} from '../Show';
import {Routes, RoutesAcl} from '../../../services/types';
import {PriceMarginMapper, PriceMarginModel} from '../../../struture';
import {useAcl} from '../../../contex';
import {useLocation, useNavigate, useParams} from 'react-router';
import {PriceMarginUpdateButton} from '../Buttons';
import {Col, Row, Space, Tooltip} from 'antd';
import styled, {useTheme} from 'styled-components';
import {InfoCircleOutlined} from '@ant-design/icons';

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  color: ${({theme}) => theme.colors.success};

  font-size: 15px;
`;

export function PriceMarginPage() {
  const {priceMarginId, tabsId} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {t} = useTranslation();
  const theme: any = useTheme();

  const {defaultCompanyUuid} = useStoredCompanies();

  const {priceMargin: priceMarginAccess} = useAcl((acl) => acl);

  const {
    priceMargin,
    loading: priceMarginLoading,
    error: priceMarginError,
    handleUpdatePriceMargin,
  } = useStoredPriceMargin({
    priceMarginUuid: priceMarginId!,
    companyUuid: defaultCompanyUuid,
  });

  const {total} = useStoredPriceMarginList({
    companyUuid: defaultCompanyUuid,
    loadOnInit: false,
  });

  const isActiveByDefault = total <= 0;

  const loading = useStopLoading({
    loading: priceMarginLoading,
    error: priceMarginError,
    message: 'An error occurred during price margin loading',
  });

  const routes = useBreadcrumb([
    {
      path: `/${Routes.priceMargins}`,
      breadcrumbName: 'Price margins',
    },
    {
      path: `/${Routes.priceMargins}/${priceMarginId}`,
      breadcrumbName: priceMargin?.price_name || 'Show price margin',
    },
  ]);

  const changeFields = useCallback(
    (
      {price_margin, ...rest}: typeof PRICE_MARGIN_REQUIRED_FIELD,
      priceMargin: PriceMarginModel,
    ) => {
      return {
        price_margin: `% ${price_margin}`,
        ...rest,
      };
    },
    [],
  );

  const overviewData = useRequiredFields(
    priceMargin as any,
    PRICE_MARGIN_REQUIRED_FIELD,
    changeFields,
  );

  const navigateGoBack = useCallback(() => {
    navigate(
      location?.state?.goBack || `/${Routes.app}/${Routes.priceMargins}`,
    );
  }, [location?.state?.goBack, navigate]);

  return (
    <PriceMarginTabPanel priceMargin={priceMargin}>
      {({tabs}) => (
        <OverviewLayout
          aclItem={RoutesAcl[Routes.priceMargins]}
          headerTitle={t('Price margin')}
          headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
          headerRight={
            <PriceMarginUpdateButton
              isActiveByDefault={isActiveByDefault}
              priceMargin={PriceMarginMapper.toPriceMarginFormDTO(priceMargin!)}
              onSuccess={handleUpdatePriceMargin}
            />
          }
          header={
            <Row gutter={10}>
              <Col span={24}>
                <Space direction="vertical" size="small">
                  <StyledTitleContainer>
                    <StyledTitle bold fontSize={20}>
                      {priceMargin?.price_name}
                    </StyledTitle>
                    {priceMargin?.price_is_default ? (
                      <Tooltip
                        title={t(
                          'The current price is used by default for all transactions with goods and services.',
                        )}>
                        <StyledInfoCircleOutlined />
                      </Tooltip>
                    ) : null}
                  </StyledTitleContainer>

                  {priceMargin?.price_is_default ? (
                    <StyledDescription $color={theme.colors.success}>
                      {t('default')}
                    </StyledDescription>
                  ) : null}
                </Space>
              </Col>
            </Row>
          }
          disabled={!priceMarginAccess?.manage}
          editTooltip={t('Edit price margin')}
          loading={loading}
          data={overviewData}
          routes={routes}
          tabsDefaultActiveKey={tabsId}
          tabs={tabs}
        />
      )}
    </PriceMarginTabPanel>
  );
}
