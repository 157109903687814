import React from 'react';
import {
  StoreDocumentModel,
  StoreDocumentStatus,
  StoreDocumentType,
} from '../../../../struture';
import styled, {useTheme, css} from 'styled-components';
import {StyledDescription} from '../../../../components/lib/Styled';
import {useLocation, useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import {Link} from '../../../../components/lib/DataDisplay';
import {Routes} from '../../../../services/types';
import {toDateByFormat} from '../../../../services/helpers';
import {MdEditDocument} from 'react-icons/md';
import {IoIosMove, IoMdDocument} from 'react-icons/io';
import Color from 'color';
import {RELATED_ROUTES_NAME, RELATED_ROUTES_TITLE} from '../Pages';
import {Tag} from 'antd';
import {AiOutlineFileAdd, AiOutlineOrderedList} from 'react-icons/ai';
import {RiDeleteBin7Line} from 'react-icons/ri';
import {BsBag} from 'react-icons/bs';
import {PiUserSwitchFill} from 'react-icons/pi';
import {TbTruckReturn} from 'react-icons/tb';

export interface IStoreRelatedDetailsViewProps {
  document: StoreDocumentModel | null;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`;

const StyledSpace = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 5px;
`;

const StyledLink = styled(Link)<{$draft: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
  gap: 5px;
  justify-content: flex-start;

  ${({$draft}) =>
    $draft &&
    css`
      color: ${({theme}) => theme.colors.warning};

      &:hover {
        color: ${({theme}) =>
          Color(theme.colors.warning).alpha(1).lighten(0.2).toString()};
      }
    `}
`;

const StyledTag = styled(Tag)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

export function StoreRelatedDetailsView({
  document,
}: IStoreRelatedDetailsViewProps): JSX.Element {
  const {t} = useTranslation();
  const location = useLocation();
  const theme: any = useTheme();
  const navigate = useNavigate();

  const colors = {
    [StoreDocumentType.IN]: 'green',
    [StoreDocumentType.OUT]: 'red',
    [StoreDocumentType.ERASE]: 'red',
    [StoreDocumentType.RETURN]: 'blue',
    [StoreDocumentType.CRETURN]: 'blue',
    [StoreDocumentType.MOVE]: 'blue',
    [StoreDocumentType.SALE]: 'blue',
    [StoreDocumentType.PREORDER]: 'blue',
  };

  const icons = {
    [StoreDocumentType.IN]: <AiOutlineFileAdd fontSize={15} />,
    [StoreDocumentType.OUT]: <BsBag fontSize={15} />,
    [StoreDocumentType.ERASE]: <RiDeleteBin7Line fontSize={15} />,
    [StoreDocumentType.RETURN]: <TbTruckReturn fontSize={15} />,
    [StoreDocumentType.CRETURN]: <PiUserSwitchFill fontSize={15} />,
    [StoreDocumentType.MOVE]: <IoIosMove fontSize={15} />,
    [StoreDocumentType.SALE]: <AiOutlineOrderedList fontSize={15} />,
    [StoreDocumentType.PREORDER]: <></>,
  };

  return (
    <>
      {document ? (
        <StyledContainer>
          <StyledSpace>
            <StyledLink
              $draft={document?.doc_status_text === StoreDocumentStatus.DRAFT}
              to={`/${Routes.app}/${Routes.stores}/${
                RELATED_ROUTES_NAME[document?.doc_type]
              }/${document?.uuid}`}
              tooltip={t('Open a detailed description of the document')}
              state={{goBack: location.pathname}}>
              {document?.doc_status_text === StoreDocumentStatus.DRAFT ? (
                <MdEditDocument color={theme.colors.warning} />
              ) : (
                <IoMdDocument color={theme.colors.link} />
              )}
              {document?.doc_local_number}
              {document?.doc_status_text === StoreDocumentStatus.DRAFT ? (
                <StyledDescription $color={theme.colors.warning}>
                  {` ( ${t('Draft')} )`}
                </StyledDescription>
              ) : null}
            </StyledLink>
            {document?.doc_date ? (
              <StyledDescription>
                {toDateByFormat(document?.doc_date, 'DD.MM.YYYY HH:mm')}
              </StyledDescription>
            ) : null}
            <StyledTag
              color={colors[document?.doc_type]}
              onClick={() =>
                navigate(
                  `/${Routes?.app}/${Routes.stores}/${
                    RELATED_ROUTES_NAME[document?.doc_type]
                  }`,
                )
              }>
              {icons[document?.doc_type]}
              {t(RELATED_ROUTES_TITLE[document?.doc_type])}
            </StyledTag>
          </StyledSpace>
        </StyledContainer>
      ) : null}
    </>
  );
}
