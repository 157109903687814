import {Record, List} from 'immutable';
import {ListModel, ListProps} from './List';
import LogEntry, {LogEntryProps} from './LogEntry';

export interface LogEntriesProps extends ListProps {
  entries: LogEntryProps[];
}

export interface LogEntriesReturnType extends ListProps {
  userAuditLog: List<LogEntry>;
}
/**
 * @class LogEntries
 * @classdesc LogEntries model
 */
export default class LogEntries extends Record<LogEntriesReturnType>({
  userAuditLog: List(),
  total: 0,
  ...ListModel.toJS(),
} as any) {
  /**
   * @constructor
   * @param {{entries:[],total:number}} param0
   */
  constructor(
    {entries, total, ...props}: LogEntriesProps = {
      entries: [],
      total: 0,
    } as LogEntriesProps,
  ) {
    super({
      userAuditLog: List(entries.map((entry) => new LogEntry(entry))),
      total,
      ...props,
    });
  }
}
