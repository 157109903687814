import {List} from 'immutable';
import {
  SettingDTO,
  ISettingDTOProps,
  SettingModel,
  SettingListModel,
} from '../internal';
import {listToArray} from '../../helpers';

export class SettingMapper {
  public static toSettingDTO(
    setting: SettingModel | ISettingDTOProps,
  ): SettingDTO {
    return new SettingDTO({
      uuid: setting?.uuid || '',
      created_at: setting?.created_at || '',
      updated_at: setting?.updated_at || '',
      setting_key: setting?.setting_key || '',
      setting_value: setting?.setting_value || '',
    });
  }

  public static toSettingListDTO(
    settings: List<SettingModel> | ISettingDTOProps[],
  ): SettingDTO[] {
    const settingList = List.isList(settings)
      ? listToArray<SettingModel>(settings)
      : settings;

    return settingList?.map((setting) => SettingMapper.toSettingDTO(setting));
  }

  public static toSettingModel(settingDTO: SettingDTO): SettingModel {
    return new SettingModel(settingDTO);
  }

  public static toSettingListModel(
    settingDTOs: SettingDTO[],
  ): SettingListModel {
    return new SettingListModel({settings: settingDTOs});
  }
}
