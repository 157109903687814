import {Record, List} from 'immutable';
import {ListModel, ListProps} from './List';
import CompanyPayment, {ICompanyPaymentProps} from './CompanyPayment';

export interface ICompanyPaymentListProps extends Partial<ListProps> {
  payments: ICompanyPaymentProps[];
}

export default class CompanyPaymentList extends Record({
  payments: List(),
  ...ListModel.toJS(),
}) {
  /**
   * @constructor
   * @param {{clients:[]}} param0
   */
  constructor({payments = [], ...props}: ICompanyPaymentListProps) {
    const options: any = {};
    if (Array.isArray(payments)) {
      options.payments = List(
        payments.map((payment) => new CompanyPayment(payment)),
      );
    }

    super({
      ...props,
      ...options,
    });
  }
}
