import React from 'react';
import {useTranslation} from 'react-i18next';
import {CompanyInviteForm, ICompanyInviteFormProps} from '../Forms';
import {SideWindow} from '../../../components/lib/Navigation';
import {ICON_NAME, IconTittle} from '../../../components/lib/DataDisplay';

export interface ICompanySideWindowProps
  extends ICompanyInviteFormProps<any, any> {
  visible: boolean;
}

export default function CompanySideWindow({
  visible,
  onCancel,
  ...rest
}: ICompanySideWindowProps) {
  const {t} = useTranslation();

  return (
    <SideWindow
      title={
        <IconTittle
          title={t('Link to the app')}
          iconName={ICON_NAME.EMPLOYEE}
        />
      }
      open={visible}
      onClose={onCancel}>
      <CompanyInviteForm onCancel={onCancel} {...rest} />
    </SideWindow>
  );
}
