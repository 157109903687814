import {correctPrice} from '../../helpers';
import {IUserOperationModelReturnType} from '../internal';
import {OPERATION_TYPE} from '../type';

export interface IUserOperationDTOProps {
  uuid: string;
  operation_type: OPERATION_TYPE;
  operation_price: string;
  operation_comment: string;
  created_at: string;
  updated_at: string;
}

export class UserOperationDTO {
  public uuid: string;

  public operation_type: OPERATION_TYPE;

  public operation_price: string;

  public operation_comment: string;

  public created_at: string;

  public updated_at: string;

  constructor(props: IUserOperationModelReturnType | IUserOperationDTOProps) {
    this.uuid = props?.uuid || '';
    this.operation_type = props?.operation_type || OPERATION_TYPE.IN;
    this.operation_price = correctPrice(props?.operation_price);
    this.operation_comment = props?.operation_comment || '';
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
  }
}
