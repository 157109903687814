import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {
  AddButton,
  IAddButtonProps,
  SuspenseEmpty,
} from '../../../components/lib/DataDisplay';
import {useModal} from '../../../components/lib/libV2/hooks';
import {CompanyFormDTO, CompanyModel} from '../../../struture';

const CompanySideWindowLazy = React.lazy(
  () => import('../Show/CompanySideWindow'),
);

export interface ICompanyCreateButton extends IAddButtonProps {
  loading: boolean;
  onSuccess: (value: Partial<CompanyFormDTO>) => Promise<CompanyModel | void>;
  onCancel?: () => void;
  title?: string;
}

export function CompanyCreateButton({
  loading = false,
  onSuccess,
  onCancel,
  ...rest
}: ICompanyCreateButton) {
  const {t} = useTranslation();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <AddButton
        tooltipTitle={t('Create company')}
        onClick={handleOnInit}
        loading={loading}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <CompanySideWindowLazy
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}
