import * as React from 'react';
import {useStore, IUseStoreProps, IUseStoreReturnType} from './useStore';
import {StoreFormDTO, StoreModel, StoreMapper} from '../struture';
import {createStore, editStore} from '../services/api/store';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../store/reducers';
import {REDUX_STATUS, ApiError} from '../services/types';
import {useEffect} from 'react';
import {
  addStoreToList as storeAddStore,
  setStore as storeSetStore,
  updateStore as storeUpdateStore,
  updateStoreFromList as storeUpdateStoreFromList,
  resetStore as storeResetStore,
} from '../store/actions';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../contex';

export interface IUseStateStoreProps extends IUseStoreProps {
  companyUuid: string;
}

export interface IUseStateStoreReturnType
  extends Omit<IUseStoreReturnType, 'entity'> {
  store: StoreModel | null;
  handleUpdateStore: (value: StoreFormDTO) => Promise<void>;
  handleCreateStore: (value: StoreFormDTO) => Promise<void>;
  handleRefreshStore: () => Promise<void>;
  handleResetStore: () => void;
  status: REDUX_STATUS;
}

export function useStoredStore({
  companyUuid,
  loadOnInit,
  storeUuid,
  ...rest
}: IUseStateStoreProps): {
  handleUpdateStore: (value: StoreFormDTO) => Promise<void>;
  handleResetStore: () => void;
  handleCreateStore: (value: StoreFormDTO) => Promise<void>;
  handleRefreshStore: () => Promise<void>;
  refresh: (value: Partial<IUseStoreProps>) => Promise<StoreModel | void>;
  error: ApiError | null;
  loading: boolean;
  store: StoreModel | null;
  storeUuid: string;
} {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const [store, setStore] = React.useState<StoreModel | null>(null);
  const [isStarLoading, setIsStartLoading] = React.useState<boolean>(false);

  const {cachedStoreList, status, ...storedStoreParams} = useSelector(
    ({store}: RootState) => store,
  );

  const dispatch = useDispatch<any>();
  const once = React.useRef(false);

  const {entity, refresh, ...storeParams} = useStore({
    loadOnInit: loadOnInit || isStarLoading,
    storeUuid,
    ...rest,
  });

  React.useEffect(() => {
    if (cachedStoreList?.size > 0) {
      const store = cachedStoreList.get(storeUuid);

      if (store) {
        setIsStartLoading(false);
        setStore(store);
      } else {
        setIsStartLoading(true);
      }
    }
    if (cachedStoreList?.size === 0 && !store) {
      setIsStartLoading(true);
    }
  }, [cachedStoreList, dispatch, storeUuid, store]);

  useEffect(() => {
    if (entity && isStarLoading && !once.current) {
      dispatch(storeSetStore(entity));
      setStore(entity);
    }
  }, [dispatch, entity, isStarLoading]);

  const handleUpdateStore = React.useCallback(
    async (value: StoreFormDTO): Promise<void> => {
      const storeDTO = await editStore(value);

      const storeModel = StoreMapper.toStoreModel(storeDTO);

      dispatch(storeUpdateStore(storeModel));
      dispatch(storeUpdateStoreFromList(storeModel));

      setStore(storeModel);
    },
    [dispatch],
  );

  const handleCreateStore = React.useCallback(
    async (value: StoreFormDTO) => {
      const storeDTO = await createStore(value, companyUuid);

      const storeModel = StoreMapper.toStoreModel(storeDTO);

      dispatch(storeAddStore(storeModel));
    },
    [companyUuid, dispatch],
  );

  const handleResetStore = React.useCallback(() => {
    once.current = true;
    dispatch(storeResetStore());
  }, [dispatch]);

  const handleRefreshStore = React.useCallback(async () => {
    const storeModel = await refresh({showLoading: false, storeUuid});

    if (storeModel) {
      dispatch(storeUpdateStore(storeModel));
      dispatch(storeUpdateStoreFromList(storeModel));
    }
  }, [storeUuid, dispatch, refresh]);

  return {
    ...storeParams,
    ...storedStoreParams,
    store,
    loading: !store,
    handleUpdateStore,
    handleCreateStore,
    handleResetStore,
    refresh,
    handleRefreshStore,
  };
}
