import React from 'react';
import {ScheduleCalendarModel} from '../../../struture';
import styled, {useTheme} from 'styled-components';
import {StyledDescription, StyledTitle} from '../../../components/lib/Styled';
import {Routes} from '../../../services/types';
import {Link} from '../../../components/lib/DataDisplay';
import {useLocation} from 'react-router';
import {useTranslation} from 'react-i18next';
import {CALENDAR, CALENDAR_ENTRY} from '../../../components/lib/const';
import {TableLinkCell} from '../../../components/lib/libV2/DataDisplay';
import {StatusCategoryTag} from '../../Statuses';

export interface IOrderDetailsViewProps {
  schedule: ScheduleCalendarModel | null;
  pathname?: string;
  state?: any;
  schedulePage?: boolean;
  withCategoryLabel?: boolean;
  withoutNavigate?: boolean;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding-right: 15px;
`;
// padding-bottom: 15px;

const StyledSpace = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 2px;
`;

const StyledLink = styled(Link)`
  &&& {
    & > * {
      font-size: 12px;
    }
  }
`;

const StyledStatusCategoryTag = styled(StatusCategoryTag)`
  width: fit-content;
`;

const StyledCategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export function OrderDetailsView({
  schedule,
  pathname,
  state,
  schedulePage,
  withCategoryLabel,
  withoutNavigate,
}: IOrderDetailsViewProps): JSX.Element {
  const {t} = useTranslation();
  const location = useLocation();
  const theme: any = useTheme();

  return (
    <>
      {schedule?.uuid ? (
        <StyledContainer>
          <StyledSpace>
            {withoutNavigate ? (
              <StyledTitle>
                {schedule?.schedule_title || t('Unknown')}
              </StyledTitle>
            ) : (
              <TableLinkCell
                fullPath={
                  pathname
                    ? `${pathname}/${Routes.schedules}/${CALENDAR}/${CALENDAR_ENTRY}/${schedule?.uuid}`
                    : `${
                        schedulePage
                          ? `/${Routes.app}/${Routes.orders}`
                          : location.pathname
                      }/${schedule?.uuid}/${
                        Routes.schedules
                      }/${CALENDAR}/${CALENDAR_ENTRY}/${schedule?.uuid}`
                }
                state={{
                  from: location.pathname,
                  ...state,
                  goBack: pathname,
                  prevState: location?.state?.prevState,
                }}
                tooltip={t('Go to schedule details page')}>
                {schedule?.schedule_title || t('Unknown')}
              </TableLinkCell>
            )}

            {schedule?.parent?.schedule_number ? (
              <StyledLink
                tooltip={t('Go to prent schedule details page')}
                to={
                  pathname
                    ? `${pathname}/${Routes.schedules}/${CALENDAR}/${CALENDAR_ENTRY}/${schedule?.parent?.uuid}`
                    : `${location.pathname}/${schedule?.parent?.uuid}/${Routes.schedules}/${CALENDAR}/${CALENDAR_ENTRY}/${schedule?.parent?.uuid}`
                }
                state={{
                  from: location.pathname,
                  ...state,
                  goBack: pathname,
                  prevState: location?.state?.prevState,
                }}>
                {`${t('Parent order')} - № ${
                  schedule?.parent?.schedule_number
                }`}
              </StyledLink>
            ) : null}
            <StyledDescription>{`№ ${schedule?.schedule_number}`}</StyledDescription>
          </StyledSpace>
        </StyledContainer>
      ) : null}
    </>
  );
}
