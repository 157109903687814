import {CashboxNotificationRecipientDTO} from './CashBoxNotificationRecipientDTO';

import {
  CashBoxModel,
  CashBoxType,
  DepartmentModel,
  EmployeeModel,
} from '../internal';
import {CashboxAutoTransferItemDTO} from './CashboxAutoTransferItemDTO';

export interface ICashBoxAdditionalProps {
  editMode?: boolean;
  cashier?: EmployeeModel;
}

export class CashBoxFormDTO {
  public uuid: string;
  public title: string;
  public comment: string;
  public balance?: number;
  public can_move_money_to: boolean;
  public can_move_money_from: boolean;
  public can_in_money_to: boolean;
  public can_be_closed_automatically: boolean;
  public cashbox_close_at: string;
  public cashbox_date_to_close_at?: string | null;
  public notification_recipient_list?: CashboxNotificationRecipientDTO[];
  public automation_money_transfer_items?: CashboxAutoTransferItemDTO[];
  public is_email_notification_enabled?: boolean;
  public can_out_money_from: boolean;
  public is_default_for_company: boolean;
  public type: CashBoxType;
  public cashier_uuid: string | EmployeeModel;
  public fiscal_cashbox_id: string | EmployeeModel;
  public is_virtual: boolean;
  public is_locational: boolean;
  public location_uuid: DepartmentModel | string;

  constructor(
    props?: CashBoxModel,
    {cashier, editMode}: ICashBoxAdditionalProps = {},
  ) {
    this.uuid = props?.uuid || '';
    this.title = props?.box_title || '';
    this.comment = props?.box_comment || '';
    this.balance = props?.box_balance ?? 0;
    this.cashbox_date_to_close_at = props?.box_date_and_time_to_closed_at;
    this.is_email_notification_enabled =
      props?.box_is_email_notification_enabled;
    this.cashbox_close_at = props?.box_utc_time_to_closed_at
      ? props?.box_utc_time_to_closed_at
      : '20:00';
    this.type = props?.box_type || CashBoxType.Cash;
    this.is_virtual = props?.box_is_virtual ?? false;
    this.can_move_money_to =
      typeof props?.box_can_move_money_to === 'boolean'
        ? props?.box_can_move_money_to
        : true;
    this.notification_recipient_list =
      props?.box_notification_options?.recipient_list?.map((item) => item) ||
      [];
    this.automation_money_transfer_items =
      props?.box_auto_transfer_options?.cashboxes?.map((item) => item) || [];
    this.can_move_money_from =
      typeof props?.box_can_move_money_from === 'boolean'
        ? props?.box_can_move_money_from
        : true;
    this.can_in_money_to =
      typeof props?.box_can_in_money_to === 'boolean'
        ? props?.box_can_in_money_to
        : true;
    this.can_out_money_from =
      typeof props?.box_can_out_money_from === 'boolean'
        ? props?.box_can_out_money_from
        : true;
    this.can_be_closed_automatically =
      typeof props?.box_is_closed_automatically === 'boolean'
        ? props?.box_is_closed_automatically
        : false;
    this.is_default_for_company = props?.box_is_default_for_company || false;
    this.cashier_uuid = editMode
      ? props?.cashier?.uuid
        ? props?.cashierModel
        : ''
      : cashier || '';

    this.fiscal_cashbox_id = props?.box_fiscal_cashbox_id || '';
    this.is_locational = props?.box_is_locational ?? false;
    this.location_uuid = editMode
      ? props?.location?.uuid
        ? props?.locationModel
        : ''
      : props?.location?.uuid || '';
  }
}
