import React, {useCallback, useEffect, useState} from 'react';
import {List} from 'immutable';
import {FlexContainer} from '../../../components/lib/Styled';
import DefaultCompanyCard, {
  IDefaultCompanyCardProps,
} from './DefaultCompanyCard';
import {IDefaultFormReturnedProps} from '../../../components/lib/General';
import {isRecordToObject} from '../../../services/helpers';
import {CompanyModel} from '../../../struture';

export interface IDefaultCompanySelectProps
  extends IDefaultFormReturnedProps,
    Pick<IDefaultCompanyCardProps, 'dataTestId'> {
  companies: List<CompanyModel>;
  defaultValue?: string;
}

export default function DefaultCompanySelect({
  companies,
  setFieldsValue,
  dataTestId,
  defaultValue,
}: IDefaultCompanySelectProps): JSX.Element {
  const [selectCompanyUuid, setSelectCompanyUuid] = useState<string>('');

  useEffect(() => {
    if (defaultValue) {
      setSelectCompanyUuid(defaultValue);
    }
  }, [defaultValue]);

  const handleSelectByDefault = useCallback(
    (default_company: string): void => {
      setSelectCompanyUuid(default_company);
      setFieldsValue({default_company});
    },
    [setFieldsValue],
  );

  return (
    <FlexContainer flexWrap="wrap" justifyContent="center">
      {companies.map((company, index) => (
        <DefaultCompanyCard
          key={company?.uuid}
          selectCompanyUuid={selectCompanyUuid}
          handleSelectByDefault={handleSelectByDefault}
          {...isRecordToObject(company)}
          dataTestId={`${dataTestId}-${index}`}
        />
      ))}
    </FlexContainer>
  );
}
