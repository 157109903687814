import {Tag} from 'antd';
import React from 'react';
import {FlagOutlined} from '@ant-design/icons';

export interface ServiceColorTagProps {
  text?: string;
  color?: string;
}

export function ServiceColorTag({
  color,
  text,
}: ServiceColorTagProps): JSX.Element {
  return (
    <Tag bordered={false} icon={<FlagOutlined />} color={color ?? '#2db7f5'}>
      {text ?? ''}
    </Tag>
  );
}
