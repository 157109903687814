import * as React from 'react';
import {List} from 'immutable';
import {
  useClientVisitList,
  IUseClientVisitListProps,
  IUseClientVisitListReturnType,
} from './useClientVisitList';
import {
  ClientVisitModel,
  ClientAbonementModel,
  ClientVisitFormDTO,
  ClientVisitMapper,
} from '../struture';
import {
  createClientVisit,
  editClientVisit,
  deleteClientVisit,
} from '../services/api/client';
import {
  setClientVisitList as storeSetClientVisitList,
  deleteClientVisitFromList as storeDeleteClientVisit,
  loadMoreClientVisitList as storeLoadMoreClientVisitList,
  addClientVisitToList as storeAddClientVisitToList,
  updateClientVisitFromList as storeUpdateClientVisitToList,
} from '../store/actions';
import {useSelector, useDispatch} from 'react-redux';
import {RootState} from '../store/reducers';
import {REDUX_STATUS} from '../services/types';
import {head, toDateByFormat} from '../services/helpers';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../contex';

export interface IUseStateClientVisitListProps
  extends IUseClientVisitListProps {}

export interface IUseStateClientVisitListReturnType
  extends Omit<IUseClientVisitListReturnType, 'entityList'> {
  visitList: List<ClientVisitModel> | null;
  handleDeleteClientVisits: (ids: string[]) => Promise<void>;
  handleSearchClientVisits: (keywords: string) => Promise<void>;
  handleLoadMoreClientVisits: () => Promise<void>;
  handleCreateClientVisit: (value: ClientVisitFormDTO) => Promise<void>;
  handleEditClientVisit: (value: ClientVisitFormDTO) => Promise<void>;
  handleRefreshClientVisits: (
    value: Partial<IUseClientVisitListProps> & {page: number},
  ) => Promise<void>;
  status: REDUX_STATUS;
  loadingMore: boolean;
  page: number;
  abonement: ClientAbonementModel | null;
}

export function useStoredClientVisitList(
  {
    abonementUuid,
    loadOnInit = true,
    ...rest
  }: IUseStateClientVisitListProps = {} as IUseStateClientVisitListProps,
): IUseStateClientVisitListReturnType {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const [loadingMore, setLoadingMore] = useState(false);
  const [visitList, setVisitList] = useState<List<ClientVisitModel> | null>(
    null,
  );
  const [isStarLoading, setIsStartLoading] = React.useState<boolean>(false);
  const [storeKeywords, setStoreKeywords] = useState('');
  const [storePage, setStorePage] = useState(1);
  const [storeTotal, setStoreTotal] = useState(0);
  const [abonement, setAbonement] = useState<ClientAbonementModel | null>(null);

  const {
    status: storedClientVisitListStatus,
    loading: storedClientVisitListLoading,
    cachedVisitList,
    ...storedClientVisitListParams
  } = useSelector(({clientVisitList}: RootState) => clientVisitList);

  const dispatch = useDispatch<any>();

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: visitListLoading,
    total,
    ...visitsParams
  } = useClientVisitList({
    abonementUuid,
    loadOnInit: false,
    ...rest,
  });

  React.useEffect(() => {
    if (cachedVisitList?.size > 0) {
      const visitList = cachedVisitList.get(abonementUuid);

      if (visitList) {
        setIsStartLoading(false);
        setVisitList(visitList?.visits);
        setStorePage(visitList?.page);
        setStoreKeywords(visitList?.keywords);
        setStoreTotal(visitList?.total || 0);
        setAbonement(visitList?.abonement || null);
      } else {
        setIsStartLoading(true);
      }
    }
    if (cachedVisitList?.size === 0 && !visitList) {
      setIsStartLoading(true);
    }
  }, [dispatch, cachedVisitList, abonementUuid, storeTotal, visitList]);

  useEffect(() => {
    if (!List.isList(entityList) && isStarLoading && loadOnInit) {
      setIsStartLoading(false);
      (async () => {
        const entityList = await refresh({abonementUuid});

        if (entityList) {
          dispatch(storeSetClientVisitList(entityList, abonementUuid, ''));
        }
      })();
    }
  }, [abonementUuid, dispatch, entityList, isStarLoading, loadOnInit, refresh]);

  const handleDeleteClientVisits = React.useCallback(
    async (ids: string[]) => {
      try {
        await deleteClientVisit(ids);

        setTimeout(() => {
          dispatch(storeDeleteClientVisit(head(ids), abonementUuid));
        }, 100);
        alert('success', t('Client visit'), t('Client visit delete success'));
      } catch (error: any) {
        alert(
          'error',
          t('Client visit'),
          `${t('An error occurred during delete client visits')}: ${
            error?.message
          }`,
        );
      }
    },
    [alert, dispatch, t, abonementUuid],
  );

  const handleSearchClientVisits = React.useCallback(
    async (keywords: string) => {
      setLoadingMore(true);
      const visitListModel = await refresh({
        offset: 0,
        limit: 10,
        keywords,
      });

      if (visitListModel && List.isList(visitListModel?.visits)) {
        dispatch(
          storeSetClientVisitList(
            visitListModel,
            abonementUuid,
            keywords,
            visitListModel.total,
          ),
        );
      }
      setLoadingMore(false);
    },
    [abonementUuid, dispatch, refresh],
  );

  const handleLoadMoreClientVisits = useCallback(async () => {
    if (
      List.isList(visitList) &&
      visitList?.size < storeTotal &&
      !loadingMore
    ) {
      setLoadingMore(true);
      const visitListModel = await refresh({
        offset: visitList?.size,
        limit: 10,
        showLoading: false,
      });

      if (visitListModel && List.isList(visitListModel?.visits)) {
        dispatch(storeLoadMoreClientVisitList(visitListModel, abonementUuid));
      }

      setLoadingMore(false);
    }
  }, [visitList, storeTotal, loadingMore, refresh, dispatch, abonementUuid]);

  const handleRefreshClientVisits = useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = false,
      page,
    }: Partial<IUseClientVisitListProps> & {page: number}) => {
      const visitListModel = await refresh({
        offset,
        limit,
        showLoading,
      });

      if (visitListModel && List.isList(visitListModel?.visits)) {
        dispatch(
          storeSetClientVisitList(
            visitListModel,
            abonementUuid,
            storeKeywords,
            visitListModel?.total,
            page,
          ),
        );
      }
    },
    [abonementUuid, dispatch, refresh, storeKeywords],
  );

  const handleCreateClientVisit = React.useCallback(
    async (value: ClientVisitFormDTO) => {
      const visitDTO = await createClientVisit(value, abonementUuid);

      const visitModel = ClientVisitMapper.toClientVisitModel(visitDTO);

      dispatch(storeAddClientVisitToList(visitModel, abonementUuid));
    },
    [abonementUuid, dispatch],
  );

  const handleEditClientVisit = React.useCallback(
    async (value: ClientVisitFormDTO) => {
      const visitDTO = await editClientVisit(value);

      const visitModel = ClientVisitMapper.toClientVisitModel(visitDTO);

      dispatch(storeUpdateClientVisitToList(visitModel, abonementUuid));
    },
    [abonementUuid, dispatch],
  );

  return {
    ...visitsParams,
    ...storedClientVisitListParams,
    visitList,
    total: storeTotal,
    loadingMore,
    offset,
    limit,
    refresh,
    loading:
      (!List.isList(visitList) && visitListLoading) ||
      !storedClientVisitListLoading,
    handleDeleteClientVisits,
    handleSearchClientVisits,
    handleLoadMoreClientVisits,
    status: storedClientVisitListStatus,
    handleRefreshClientVisits,
    keywords: storeKeywords || '',
    page: storePage || 1,
    abonement,
    handleCreateClientVisit,
    handleEditClientVisit,
  };
}
