import React from 'react';
import {HiTruck} from 'react-icons/hi';
import {ClientIcon} from './ClientIcon';
import {ClientModel} from '../../../struture';
import {FaHandshakeSlash} from 'react-icons/fa';
import {AuditOutlined} from '@ant-design/icons';
import styled, {css, useTheme} from 'styled-components';
import {StyledTitle} from '../../../components/lib/Styled';

export interface IClientTitleProps {
  client: ClientModel | null;
}

const StyledTitleContainer = styled.div<{
  $color?: string;
}>`
  display: flex;
  align-items: center;
  gap: 5px;
  ${({$color}) =>
    $color &&
    css`
      color: ${$color};
    `}
`;

export function ClientTitle({client}: IClientTitleProps): JSX.Element {
  const theme: any = useTheme();

  return (
    <StyledTitleContainer
      $color={!!client?.client_is_conflict ? theme.colors.error : undefined}>
      <ClientIcon client={client} />
      {client?.client_is_provisioner ? <HiTruck size={19} /> : null}
      {client?.client_is_tav_payer ? <AuditOutlined size={18} /> : null}
      {client?.client_is_conflict ? <FaHandshakeSlash size={19} /> : null}
      <StyledTitle fontSize={20} bold>
        {client?.fullNameClient}
      </StyledTitle>
    </StyledTitleContainer>
  );
}
