import React from 'react';
import {ErrorBoundary} from '.';

/**
 * @desc AppErrorBoundary component
 * @param children
 * @param rest
 * @returns {*}
 * @constructor
 */

export interface IAppErrorBoundaryProps {
  transparentContent?: boolean;
  contentSize?: string;
  children: React.ReactNode | React.ReactNode[];
}

export default function AppErrorBoundary({
  children,
  ...rest
}: IAppErrorBoundaryProps): JSX.Element {
  return (
    <ErrorBoundary fallback={<div {...rest}> </div>} homeUrl="/">
      {children}
    </ErrorBoundary>
  );
}
