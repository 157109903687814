import {Dispatch} from 'redux';
import {List} from 'immutable';
import {EMPLOYEE_ATTACHED_SERVICE_LIST} from '../constants';
import {ServiceModel} from '../../struture';
import {ApiError} from '../../services/types';

export interface ISetEmployeeAttachedListParam {
  type: EMPLOYEE_ATTACHED_SERVICE_LIST;
  employeeAttachedServiceList: List<ServiceModel>;
  employeeUuid: string;
  keywords?: string;
  total?: number;
}

export interface IAddEmployeeAttachedToListParam {
  type: EMPLOYEE_ATTACHED_SERVICE_LIST;
  employeeAttachedService: ServiceModel;
  employeeUuid: string;
}

export interface IUpdateEmployeeAttachedFromListParam {
  type: EMPLOYEE_ATTACHED_SERVICE_LIST;
  employeeAttachedService: ServiceModel;
  employeeUuid: string;
}

export interface IDeleteEmployeeAttachedFromListParam {
  type: EMPLOYEE_ATTACHED_SERVICE_LIST;
  employeeAttachedServiceUuid: string;

  employeeUuid: string;
}

export interface IErrorEmployeeAttachedListParam {
  type: EMPLOYEE_ATTACHED_SERVICE_LIST;
  error: ApiError | undefined;
}

export function setEmployeeAttachedServiceList(
  employeeAttachedServiceList: List<ServiceModel>,
  employeeUuid: string,
  keywords?: string,
  total?: number,
): (dispatch: Dispatch) => Promise<ISetEmployeeAttachedListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: EMPLOYEE_ATTACHED_SERVICE_LIST.SET_EMPLOYEE_ATTACHED_SERVICE_LIST,
      employeeAttachedServiceList,
      employeeUuid,
      keywords,
      total,
    });
  };
}

export function loadMoreEmployeeAttachedServiceList(
  employeeAttachedServiceList: List<ServiceModel>,
  employeeUuid: string,
): (dispatch: Dispatch) => Promise<ISetEmployeeAttachedListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: EMPLOYEE_ATTACHED_SERVICE_LIST.LOAD_MORE_EMPLOYEE_ATTACHED_SERVICE_LIST,
      employeeAttachedServiceList,
      employeeUuid,
    });
  };
}

export function addAttachedServiceToListEmployee(
  employeeAttachedService: ServiceModel,
): (
  dispatch: Dispatch,
) => Promise<Omit<IAddEmployeeAttachedToListParam, 'employeeUuid'>> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: EMPLOYEE_ATTACHED_SERVICE_LIST.ADD_ATTACHED_SERVICE,
      employeeAttachedService,
    });
  };
}

export function addAttachedServiceToEmployeeList(
  employeeAttachedService: ServiceModel,
  employeeUuid: string,
): (dispatch: Dispatch) => Promise<IAddEmployeeAttachedToListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: EMPLOYEE_ATTACHED_SERVICE_LIST.ADD_ATTACHED_SERVICE_TO_EMPLOYEE,
      employeeAttachedService,
      employeeUuid,
    });
  };
}

export function updateEmployeeAttachedServiceFromList(
  employeeAttachedService: ServiceModel,
  employeeUuid: string,
): (dispatch: Dispatch) => Promise<IUpdateEmployeeAttachedFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: EMPLOYEE_ATTACHED_SERVICE_LIST.UPDATE_ATTACHED_SERVICE,
      employeeAttachedService,
      employeeUuid,
    });
  };
}

export function updateAttachedServiceFromEmployeeList(
  employeeAttachedService: ServiceModel,
  employeeUuid: string,
): (dispatch: Dispatch) => Promise<IUpdateEmployeeAttachedFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: EMPLOYEE_ATTACHED_SERVICE_LIST.UPDATE_ATTACHED_SERVICE_FOR_EMPLOYEE,
      employeeAttachedService,
      employeeUuid,
    });
  };
}

export function deleteEmployeeAttachedServiceFromList(
  employeeAttachedServiceUuid: string,
  employeeUuid: string,
): (dispatch: Dispatch) => Promise<IDeleteEmployeeAttachedFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: EMPLOYEE_ATTACHED_SERVICE_LIST.DELETE_ATTACHED_SERVICE,
      employeeAttachedServiceUuid,
      employeeUuid,
    });
  };
}

export function deleteAttachedServiceFromEmployeeList(
  employeeAttachedServiceUuid: string,
): (
  dispatch: Dispatch,
) => Promise<Omit<IDeleteEmployeeAttachedFromListParam, 'employeeUuid'>> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: EMPLOYEE_ATTACHED_SERVICE_LIST.DELETE_ATTACHED_SERVICE_FOR_EMPLOYEE,
      employeeAttachedServiceUuid,
    });
  };
}

export function errorEmployeeAttachedServiceInList(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorEmployeeAttachedListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: EMPLOYEE_ATTACHED_SERVICE_LIST.ERROR_EMPLOYEE_ATTACHED_SERVICE_LIST,
      error,
    });
  };
}
