import {sortedByDate} from './date';
import {MomentDate} from './MomentDate';
import {checkArrayLength} from './array';

/**
 * @desc Повернення масиву schedules з датою більшою чим є на даний момент
 * @param {Array} schedules
 * @return {Array}
 * */
export function getScheduleFuture(schedules) {
  try {
    if (checkArrayLength(schedules)) {
      return null;
    }
    const dateNow = new Date();

    const futureOrders = schedules.reduce((acc, cur) => {
      const schedule = new Date(cur.scheduled_date);
      if (schedule.getTime() >= dateNow.getTime() && cur?.status > 0) {
        acc.push(cur);
      }
      return acc;
    }, []);

    return futureOrders.sort(sortedByDate('scheduled_date'));
  } catch (e) {
    console.error(e.message);
  }
}

/**
 * @desc Повернення масиву schedules з датою меншою чим є на даний момент
 * @param {Array} schedules
 * @return {Array}
 * */
export function getSchedulePast(schedules) {
  try {
    if (checkArrayLength(schedules)) {
      return null;
    }
    const dateNow = new Date();

    const futureOrders = schedules.reduce((acc, cur) => {
      const schedule = new Date(cur.scheduled_date);
      if (schedule.getTime() <= dateNow.getTime() || cur?.status <= 0) {
        acc.push(cur);
      }
      return acc;
    }, []);

    return futureOrders.sort(sortedByDate('scheduled_date'));
  } catch (e) {
    console.error(e.message);
  }
}

/**
 * @desc Оновлення масиву schedules
 * @param {Array} prevSchedules
 * @param {Object} schedule
 * @return {Array}
 * */
export function changeSchedules(prevSchedules, schedule) {
  try {
    // if (checkArrayLength(prevSchedules)) {
    //   return null;
    // }

    const newSate = (prevSchedule) => {
      if (prevSchedule.uuid === schedule.uuid) {
        return schedule;
      }
      return prevSchedule;
    };

    return prevSchedules?.map(newSate);
  } catch (e) {
    console.error(e.message);
  }
}

/**
 * @desc Повернення schedules за вибраний період
 * @param {Array} schedules
 * @param {Date} startPeriod
 * @param {Date} endPeriod
 * @return {Object}
 * */
export function getScheduleForCalendar(schedules, startPeriod, endPeriod) {
  const momentDate = new MomentDate();

  const scheduleDate = schedules?.map((schedule) =>
    momentDate.buildDateFormat(
      new Date(schedule?.scheduled_date),
      'YYYY-MM-DD',
    ),
  );

  const uniqueDate = Array.from(new Set(scheduleDate));

  const newObject = uniqueDate?.reduce((acc: any, cur: any) => {
    const arr = [];

    schedules?.forEach((schedule) => {
      if (
        cur ===
        momentDate.buildDateFormat(
          new Date(schedule?.scheduled_date),
          'YYYY-MM-DD',
        )
      ) {
        arr.push(schedule);
      }
    });
    acc[cur] = arr;
    return acc;
  }, {});

  const datePeriod = momentDate
    .getDatesByPeriod(startPeriod, endPeriod)
    .reduce((acc, cur) => {
      acc[cur] = [];
      return acc;
    }, {});

  return {...datePeriod, ...(newObject as any)};
}
