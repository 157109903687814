import {Record} from 'immutable';

export interface IClientOperationProps {
  uuid: string;
  balance_before: number;
  created_at: string;
  updated_at: string;
}

/**
 * @class Client
 * @desc Клієнт
 */
export default class ClientOperation extends Record({
  uuid: '',
  balance_before: 0,
  created_at: '',
  updated_at: '',
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(props: IClientOperationProps = {} as IClientOperationProps) {
    super({...props});
  }
}
