import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {PlusOutlined} from '@ant-design/icons';
import {Label} from '../../../components/lib/DataDisplay';
import {FlexContainer} from '../../../components/lib/Styled';
import {ClientCreateButton} from '../../Clients';
import {ClientFormDTO, ClientModel} from '../../../struture';

export default withTranslation()(OrderClientLabel);

export interface IOrderClientLabelProps extends WithTranslation {
  handleCreate: (value: ClientFormDTO) => Promise<ClientModel | void>;
  autoFocus?: boolean;
  disabled?: boolean;
}

function OrderClientLabel({
  t,
  handleCreate,
  autoFocus,
  disabled,
}: IOrderClientLabelProps): JSX.Element {
  return (
    <FlexContainer justifyContent="space-between" width="100%">
      <Label label={t('Client')} tooltipTitle={t('Company client')} />
      <ClientCreateButton
        disabled={disabled}
        onSuccess={handleCreate}
        isFullForm={false}
        autoFocus={autoFocus}>
        <PlusOutlined />
      </ClientCreateButton>
    </FlexContainer>
  );
}
