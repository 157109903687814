import React from 'react';
import {Text} from '../Format';
import {LoadingOutlined} from '@ant-design/icons';
import {StyledIconButton} from '../Styled/Buttons';
import {PopconfirmProps} from 'antd/lib/popconfirm';
import {ButtonProps, Tooltip, Popconfirm} from 'antd';
import styled, {css, useTheme} from 'styled-components';
import {RenderFunction} from 'antd/lib/_util/getRenderPropValue';
export interface IConfirmButtonProps
  extends ButtonProps,
    Pick<PopconfirmProps, 'onConfirm' | 'cancelButtonProps' | 'okButtonProps'> {
  title?: string;
  tooltipTitle?: string;
  confirmTitle?: React.ReactNode | RenderFunction;
  cancelButtonText?: React.ReactNode;
  confirmButtonText?: React.ReactNode;
  children: React.ReactNode;
}

const StyledConfirmButton = styled(StyledIconButton)<{
  $useCustomStyle: boolean;
}>`
  ${({$useCustomStyle}) =>
    !$useCustomStyle &&
    css`
      &&&&& {
        background-color: transparent;
        z-index: 2;
      }
    `}
`;

const StyledTooltip = styled(Tooltip)`
  width: auto;
`;

export function ConfirmButton({
  title,
  tooltipTitle,
  style,
  type = 'text',
  confirmTitle,
  onConfirm,
  okButtonProps,
  cancelButtonProps,
  cancelButtonText,
  confirmButtonText,
  danger,
  loading,
  children,
  disabled,
  ...props
}: IConfirmButtonProps): JSX.Element {
  const theme: any = useTheme();

  return (
    <StyledTooltip title={tooltipTitle}>
      <Popconfirm
        placement="leftTop"
        okType={danger ? 'danger' : undefined}
        okText={confirmButtonText}
        cancelText={cancelButtonText}
        title={confirmTitle}
        onConfirm={onConfirm}
        okButtonProps={okButtonProps}
        cancelButtonProps={cancelButtonProps}>
        <StyledConfirmButton
          danger={danger}
          type={type}
          $useCustomStyle={type !== 'text'}
          style={style}
          loading={!!(title && loading)}
          disabled={disabled || !!(title && loading)}
          {...props}>
          {title ? (
            <Text>{title}</Text>
          ) : loading ? (
            <LoadingOutlined color={theme.colors.primary} />
          ) : (
            children
          )}
        </StyledConfirmButton>
      </Popconfirm>
    </StyledTooltip>
  );
}
