import React from 'react';
import {StoreBelongingTo} from '../../../struture';
import {BsBuildingFill, BsFillBuildingsFill} from 'react-icons/bs';
import styled, {css} from 'styled-components';
import {StyledDescription, StyledTitle} from '../../../components/lib/Styled';
import {useTranslation} from 'react-i18next';

export interface IStoreBelongingToViewProps {
  type: StoreBelongingTo;
  icon?: React.ReactNode;
  title?: string;
  description?: string;
  onlyIcon?: boolean;
}

const StyledWrapper = styled.div<{$isLineHeight: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${({$isLineHeight}) =>
    $isLineHeight &&
    css`
      padding: 3px;
      line-height: 1.5;
    `}
`;

const StyledTitleIconContainer = styled(StyledDescription)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & > :first-child {
    margin-right: 5px;
  }
`;

export function StoreBelongingToView({
  type,
  title,
  description,
  icon,
  onlyIcon = false,
  ...rest
}: IStoreBelongingToViewProps): React.JSX.Element {
  const {t} = useTranslation();

  return (
    <StyledTitleIconContainer {...rest}>
      {icon ? (
        icon
      ) : type === StoreBelongingTo.COMPANY ? (
        <BsFillBuildingsFill size={description ? 14 : 12} />
      ) : (
        <BsBuildingFill size={description ? 14 : 12} />
      )}
      {onlyIcon ? null : (
        <StyledWrapper $isLineHeight={!!description}>
          <StyledTitle fontSize={13}>{t(title || type)}</StyledTitle>
          {description ? (
            <StyledDescription>{description}</StyledDescription>
          ) : null}
        </StyledWrapper>
      )}
    </StyledTitleIconContainer>
  );
}
