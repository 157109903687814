import * as React from 'react';
import {useStatus, IUseStatusProps, IUseStatusReturnType} from './useStatus';
import {StatusFormDTO, StatusModel, StatusMapper} from '../struture';
import {createStatus, editStatus} from '../services/api/status';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../store/reducers';
import {REDUX_STATUS} from '../services/types';
import {useEffect} from 'react';
import {
  addStatusToList as storeAddStatus,
  setStatus as storeSetStatus,
  updateStatus as storeUpdateStatus,
  updateStatusFromList as storeUpdateStatusFromList,
  resetStatus as storeResetStatus,
} from '../store/actions';
import {toStringDate} from '../services/helpers';

export interface IUseStateStatusProps extends IUseStatusProps {
  companyUuid: string;
}
export interface IUseStateStatusReturnType
  extends Omit<IUseStatusReturnType, 'entity'> {
  status: StatusModel | null;
  handleUpdateStatus: (value: StatusFormDTO) => Promise<void>;
  handleCreateStatus: (value: StatusFormDTO) => Promise<void>;
  handleRefreshStatus: () => Promise<void>;
  handleResetStatus: () => void;
  reduxStatus: REDUX_STATUS;
}

export function useStoredStatus({
  companyUuid,
  loadOnInit,
  statusUuid,
  ...rest
}: IUseStateStatusProps): IUseStateStatusReturnType {
  const [status, setStatus] = React.useState<StatusModel | null>(null);
  const [isStarLoading, setIsStartLoading] = React.useState<boolean>(false);

  const {
    reduxStatus: storedStatusStatus,
    cachedStatusList,
    ...storedStatusParams
  } = useSelector(({status}: RootState) => status);

  const dispatch = useDispatch<any>();
  const once = React.useRef(false);

  const {entity, refresh, ...statusParams} = useStatus({
    loadOnInit: loadOnInit || isStarLoading,
    statusUuid,
    ...rest,
  });

  React.useEffect(() => {
    if (cachedStatusList?.size > 0) {
      const service = cachedStatusList.get(statusUuid);

      if (service) {
        setIsStartLoading(false);
        setStatus(service);
      } else {
        setIsStartLoading(true);
      }
    }
    if (cachedStatusList?.size === 0 && !status) {
      setIsStartLoading(true);
    }
  }, [cachedStatusList, dispatch, statusUuid, status]);

  useEffect(() => {
    if (entity && isStarLoading && !once.current) {
      dispatch(storeSetStatus(entity));
      setStatus(entity);
    }
  }, [dispatch, entity, isStarLoading, storedStatusStatus]);

  const handleUpdateStatus = React.useCallback(
    async (value: StatusFormDTO): Promise<void> => {
      const statusDTO = await editStatus(value);

      const statusModel = StatusMapper.toStatusModel(statusDTO);

      dispatch(storeUpdateStatus(statusModel));
      dispatch(storeUpdateStatusFromList(statusModel));

      setStatus(statusModel);
    },
    [dispatch],
  );

  const handleCreateStatus = React.useCallback(
    async (value: StatusFormDTO) => {
      const statusDTO = await createStatus(value as any, companyUuid);

      const statusModel = StatusMapper.toStatusModel(statusDTO);

      const updatedModel = statusModel.set(
        'created_at',
        toStringDate(new Date()),
      );

      dispatch(storeAddStatus(updatedModel));
    },
    [companyUuid, dispatch],
  );

  const handleResetStatus = React.useCallback(() => {
    once.current = true;
    dispatch(storeResetStatus());
  }, [dispatch]);

  const handleRefreshStatus = React.useCallback(async () => {
    const statusModel = await refresh({showLoading: false, statusUuid});

    if (statusModel) {
      dispatch(storeUpdateStatus(statusModel));
      dispatch(storeUpdateStatusFromList(statusModel));
    }
  }, [statusUuid, dispatch, refresh]);

  return {
    ...statusParams,
    ...storedStatusParams,
    status,
    loading: !status,
    handleUpdateStatus,
    handleCreateStatus,
    reduxStatus: storedStatusStatus,
    handleResetStatus,
    refresh,
    handleRefreshStatus,
  };
}
