import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {RootState} from '../store/reducers';
import {IInitialState} from '../store/reducers/profile';
import {
  setProfile,
  updateProfile as updateProfileAction,
  resetProfile as storeResetProfile,
} from '../store/actions';
import {correctBirthdayValue} from '../services/helpers';
import {
  editUser,
  setProfilePicture as setProfilePictureApi,
  IProfileBalanceParam,
  linkTopUpBalance,
  accountDeletion,
  logout,
} from '../services/api/user';
import {ProfileFormDTO, ProfileMapper, ProfileModel} from '../struture';
import {useDropdownAlert} from '../contex/DropdownAlertManager';
import {Routes} from '../services/types';
import {useNavigate} from 'react-router';
import {tokenHandler} from '../services/core/token';

export interface IUseStoredProfileReturnType extends IInitialState {
  storeProfile: (profile: ProfileModel) => void;
  updateProfile: (value: ProfileFormDTO) => Promise<void>;
  updateProfileBalance: (value: IProfileBalanceParam) => Promise<void>;
  setProfilePicture: (file: File) => Promise<string>;
  handleRemoveAccount: () => Promise<void>;
  resetProfile: () => void;
}

export default function useStoredProfile(): IUseStoredProfileReturnType {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const value = useSelector(({profile}: RootState) => profile);

  const dispatch = useDispatch<any>();

  const storeProfile = React.useCallback(
    (profile: ProfileModel): void => {
      dispatch(setProfile(profile));
    },
    [dispatch],
  );

  const updateProfile = React.useCallback(
    async (value: ProfileFormDTO): Promise<void> => {
      const profileFormDTO = ProfileMapper.toProfileFormDTO(value as any);

      const profile = await editUser(correctBirthdayValue(profileFormDTO));

      const profileModel = ProfileMapper.toProfileModel(profile);

      dispatch(updateProfileAction(profileModel));
      alert('success', t('Profile'), t('Profile edit success'));
    },
    [alert, dispatch, t],
  );

  const updateProfileBalance = React.useCallback(
    async (value: IProfileBalanceParam): Promise<void> => {
      const {checkout_url} = await linkTopUpBalance(Number(value?.balance));
      window?.open(checkout_url, '_blank');
    },
    [],
  );

  const setProfilePicture = React.useCallback(
    async (file: File) => {
      const profile = await setProfilePictureApi({file});

      const profileModel = ProfileMapper.toProfileModel(profile);

      dispatch(updateProfileAction(profileModel));

      return profile?.picture_url;
    },
    [dispatch],
  );

  const handleRemoveAccount = React.useCallback(async () => {
    try {
      await accountDeletion();

      alert('success', t('Delete profile'), t('Profile delete success'));

      setTimeout(async () => {
        await logout();
        tokenHandler.destroyToken();
        navigate(`/${Routes.auth}`);
      }, 500);
    } catch (error: any) {
      alert(
        'error',
        t('Delete profile'),
        `${t('An error occurred delete profile')} : ${error?.message}`,
      );
    }
  }, [alert, navigate, t]);

  const resetProfile = React.useCallback(() => {
    dispatch(storeResetProfile());
  }, [dispatch]);

  return {
    ...value,
    storeProfile,
    updateProfile,
    setProfilePicture,
    handleRemoveAccount,
    updateProfileBalance,
    resetProfile,
  };
}
