import React, {useCallback, useMemo, useState} from 'react';
import {Empty} from 'antd';
import {useTranslation} from 'react-i18next';
import {
  useStateRelatedServiceList,
  useStopLoading,
  useStoredCompanies,
} from '../../../hooks';
import {RelatedServiceMapper, RelatedServiceModel} from '../../../struture';
import {
  RelatedServicesCreateButton,
  RelatedServicesDeleteButton,
  RelatedServicesUpdateButton,
} from '../../../views/RelatedServices';
import {RELATED_SERVICES_INITIAL_DATA} from '../../../services/api/relatedService';
import {textToUpperCase} from '../../../services/helpers';
import {
  Table,
  TableActionCell,
} from '../../../components/lib/libV2/DataDisplay';
import {ORDER_SCHEDULE_STATUS} from '../../../services/api/orders';
import {useLocation, useNavigate, useParams} from 'react-router';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '../../../services/types';
import {HeaderLeft, ListActions} from '../../../components/lib/DataDisplay';
import ListSearchInput from '../../../components/lib/DataDisplay/ListActions/ListSearchInput';
import {ListLayout} from '../../../components/lib/Layout';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import {useAcl} from '../../../contex';

export function OrderRelatedServiceListPage(): JSX.Element {
  const {t} = useTranslation();
  const {orderId} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {manage} = useAcl(({order}) => order);
  const {defaultCompanyUuid} = useStoredCompanies();

  const [selectedRelatedService, setSelectedRelatedService] = useState<
    RelatedServiceModel[]
  >([]);

  const disabledActions = !(
    location?.state?.scheduleStatus === ORDER_SCHEDULE_STATUS.ACTIVE ||
    location?.state?.scheduleStatus === ORDER_SCHEDULE_STATUS.REQUESTED
  );

  const {
    services: relatedServices,
    refresh,
    loading: relatedServicesLoading,
    error: relatedServicesError,
    total,
    limit: pageSize,

    handleUpdateRelatedService,
    handleCreateRelatedService,
    handleDeleteRelatedServices,
    handleSearchRelatedServices,
  } = useStateRelatedServiceList({
    scheduleUuid: orderId!,
    handleUpdateScheduleTotalPrice: () => {},
    limit: 1,
  });

  const loading = useStopLoading({
    loading: relatedServicesLoading,
    error: relatedServicesError,
    message: 'An error occurred during related services loading',
  });

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: selectedRelatedService.map(({uuid}) => uuid),
      onChange: (
        selectedRowKeys: React.Key[],
        selectedRecords: RelatedServiceModel[],
      ) => {
        setSelectedRelatedService(selectedRecords);
      },
    }),
    [selectedRelatedService],
  );

  const routes = useBreadcrumb([
    {
      path: `/${Routes.orders}`,
      breadcrumbName: t('Orders'),
    },
    {
      path: `/${Routes.orders}/${orderId}/${Routes.schedules}/calendar/entry/${orderId}`,
      breadcrumbName: `${
        location?.state?.scheduleNumber
          ? `#${location?.state?.scheduleNumber}`
          : ''
      }`,
    },
    {
      path: `/${Routes.orders}/${orderId}/${Routes.relatedServices}`,
      breadcrumbName: 'Related services',
    },
  ]);

  const columns = [
    {
      title: t('Service'),
      key: 'service',
      render: (service: RelatedServiceModel) => service?.service?.title,
    },
    {
      ellipsis: true,
      title: t('Price'),
      key: 'price',
      render: (service: RelatedServiceModel) => service?.schedule_service_price,
    },

    {
      ellipsis: true,
      title: t('Quantity'),
      key: 'quantity',
      render: (service: RelatedServiceModel) =>
        service?.schedule_service_quantity,
    },

    {
      ellipsis: true,
      title: t('Total'),
      key: 'total',
      render: (service: RelatedServiceModel) =>
        service?.schedule_service_price_total,
    },

    {
      title: t('Actions'),
      key: 'related-service_actions',
      align: 'center' as any,
      render: (service: RelatedServiceModel) => (
        <TableActionCell>
          <RelatedServicesUpdateButton
            service={RelatedServiceMapper.toRelatedServiceFormDTO(service)}
            onSuccess={handleUpdateRelatedService}
            companyUuid={defaultCompanyUuid}
            disabled={disabledActions || !manage}
            relatedServices={relatedServices}
            serviceUuid={location?.state?.serviceUuid}
          />
          <RelatedServicesDeleteButton
            disabled={disabledActions || !manage}
            services={[service]}
            onSuccess={handleDeleteRelatedServices}
          />
        </TableActionCell>
      ),
    },
  ];

  const navigateGoBack = useCallback(() => {
    navigate(
      location?.state?.from ||
        `/${Routes.app}/${Routes.orders}/${orderId}/${Routes.schedules}/calendar/entry/${orderId}`,
      {
        state: location?.state?.prevState
          ? location?.state?.prevState
          : location?.state,
      },
    );
  }, [location?.state, navigate, orderId]);

  return (
    <ListLayout
      aclItem={RoutesAcl[Routes.orders]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.relatedServices]))}
      headerRight={
        <>
          {selectedRelatedService.length ? (
            <RelatedServicesDeleteButton
              disabled={disabledActions || !manage}
              services={selectedRelatedService}
              onSuccess={handleDeleteRelatedServices}
            />
          ) : (
            <RelatedServicesCreateButton
              onSuccess={handleCreateRelatedService}
              loading={loading}
              service={{...RELATED_SERVICES_INITIAL_DATA}}
              companyUuid={defaultCompanyUuid}
              disabled={disabledActions || !manage}
              relatedServices={relatedServices}
              serviceUuid={location?.state?.serviceUuid}
            />
          )}
        </>
      }
      routes={routes}
      empty={
        relatedServices?.size ? null : (
          <Empty
            description={t(
              `It looks like you don't have any related services at the moment.`,
            )}>
            <RelatedServicesCreateButton
              type="primary"
              onSuccess={handleCreateRelatedService}
              loading={loading}
              service={{...RELATED_SERVICES_INITIAL_DATA}}
              companyUuid={defaultCompanyUuid}
              disabled={disabledActions || !manage}
              relatedServices={relatedServices}
              serviceUuid={location?.state?.serviceUuid}
              title={t('Create related service')}
            />
          </Empty>
        )
      }
      headerExtra={
        <ListActions
          withoutPicker
          loading={loading}
          handleSearch={handleSearchRelatedServices}
          inputTooltip={t('Search related services')}
          inputLabel={t('Search related services')}
        />
      }
      loading={loading && !relatedServices?.size}>
      <Table<RelatedServiceModel>
        rowSelection={rowSelection}
        total={total}
        pageSize={pageSize}
        dataSource={relatedServices}
        onChange={refresh}
        columns={columns}
        loading={loading}
      />
    </ListLayout>
  );
}
