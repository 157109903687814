import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {map} from '../../../../services/helpers';
import {Routes} from '../../../../services/types';
import useStoredCompanies from '../../../../hooks/useStoredCompanies';

export type UseBreadcrumbProps = {
  path: string;
  state?: any;
  breadcrumbName: string;
  withoutCompany?: boolean;
}[];

export default function useBreadcrumb(
  routes: UseBreadcrumbProps,
  withoutCompany = false,
): UseBreadcrumbProps {
  const {t} = useTranslation();

  const {defaultCompanyBreadcrumb} = useStoredCompanies();

  const [defaultBreadcrumb] = React.useState<UseBreadcrumbProps>([
    {
      path: `/${Routes.app}/${Routes.dashboard}`,
      breadcrumbName: t('Home'),
    },
  ]);

  const addDefaultBreadcrumb = React.useCallback(
    (
      routes: UseBreadcrumbProps,
      defaultBreadcrumb: UseBreadcrumbProps,
    ): UseBreadcrumbProps => {
      const value = map(
        (routes) => ({
          path: `/${Routes.app}${routes?.path}`,
          breadcrumbName: t(routes?.breadcrumbName),
          state: routes?.state,
        }),
        routes,
      );

      if (withoutCompany) {
        return [...defaultBreadcrumb, ...value];
      }

      return [...defaultBreadcrumb, defaultCompanyBreadcrumb, ...value];
    },
    [defaultCompanyBreadcrumb, t, withoutCompany],
  );

  return addDefaultBreadcrumb(routes, defaultBreadcrumb);
}
