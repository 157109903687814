import {Record} from 'immutable';
import {
  ClientAbonementSubscriptionDTO,
  ClientAbonementModel,
  ClientAbonementDTO,
  ClientAbonementMapper,
  ClientAbonementSubscriptionStatusesText,
  IClientAbonementLatestStatusDTOProps,
} from '../internal';
import {WithoutRecursion} from '../type';
import {Moment} from 'moment';

export interface IClientAbonementSubscriptionModelReturnType {
  uuid: string;
  subscription_start_date: string | Moment;
  subscription_end_date: string | Moment;
  subscription_price: string;
  subscription_visiting_balance: string;
  subscription_guest_balance: string;
  subscription_status_text: ClientAbonementSubscriptionStatusesText;
  created_at: string;
  updated_at: string;
  client_abonement: ClientAbonementModel | ClientAbonementDTO;
}

export class ClientAbonementSubscriptionModel extends Record<IClientAbonementSubscriptionModelReturnType>(
  {
    uuid: '',
    subscription_start_date: '',
    subscription_end_date: '',
    subscription_price: '',
    subscription_visiting_balance: '',
    subscription_guest_balance: '',
    subscription_status_text: ClientAbonementSubscriptionStatusesText.Active,
    created_at: '',
    updated_at: '',
    client_abonement: {} as ClientAbonementModel,
  },
) {
  constructor(
    props: ClientAbonementSubscriptionDTO = {} as ClientAbonementSubscriptionDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IClientAbonementSubscriptionModelReturnType = {
      ...props,

      client_abonement:
        withoutRecursion.indexOf(WithoutRecursion.clientAbonement) !== -1
          ? props?.client_abonement
          : ClientAbonementMapper.toClientAbonementModel(
              props?.client_abonement,
              {} as IClientAbonementLatestStatusDTOProps,
              [WithoutRecursion.abonement, ...withoutRecursion],
            ).abonement,
    };

    super(options);
  }

  get clientAbonement(): ClientAbonementModel {
    return this.client_abonement instanceof ClientAbonementModel
      ? this.client_abonement
      : ClientAbonementMapper.toClientAbonementModel(this.client_abonement)
          .abonement;
  }
}
