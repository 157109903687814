import {List} from 'immutable';
import {
  GroupDTO,
  IGroupDTOProps,
  GroupModel,
  GroupListModel,
  ServiceMapper,
} from '../internal';
import {listToArray} from '../../helpers';
import {WithoutRecursion} from '../type';

export class GroupMapper {
  public static toGroupDTO(
    group: GroupModel | IGroupDTOProps | GroupDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): GroupDTO {
    return new GroupDTO(
      {
        uuid: group?.uuid,
        description: group?.description,
        title: group?.title,
        created_at: group?.created_at,
        updated_at: group?.updated_at,
        status: group?.status,
        services: ServiceMapper.toServiceListDTO(group?.services, 0, [
          WithoutRecursion.group,
          ...withoutRecursion,
        ]).services,
      },
      [WithoutRecursion.group, ...withoutRecursion],
    );
  }

  public static toGroupListDTO(
    groups: List<GroupModel> | IGroupDTOProps[] | GroupDTO[],
    withoutRecursion: WithoutRecursion[] = [],
  ): GroupDTO[] {
    const groupList = List.isList(groups)
      ? listToArray<GroupModel>(groups)
      : groups;

    return groupList?.map((group) =>
      GroupMapper.toGroupDTO(group, [
        WithoutRecursion.group,
        ...withoutRecursion,
      ]),
    );
  }

  public static toGroupModel(
    groupDTO: GroupDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): GroupModel {
    return new GroupModel(groupDTO, [
      WithoutRecursion.group,
      ...withoutRecursion,
    ]);
  }

  public static toGroupListModel(
    groupDTOs: GroupDTO[],
    withoutRecursion: WithoutRecursion[] = [],
  ): GroupListModel {
    return new GroupListModel({groups: groupDTOs}, [
      WithoutRecursion.group,
      ...withoutRecursion,
    ]);
  }
}
