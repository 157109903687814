import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {IActionButtonProps} from '../../../components/lib/General';
import {useModal} from '../../../components/lib/libV2/hooks';
import {DayOffDTO} from '../../../struture';
import {AddButton, SuspenseEmpty} from '../../../components/lib/DataDisplay';

const DayOffSideWindowLazy = React.lazy(
  () => import('../Show/DaysOffSideWindow'),
);

export interface IDayOffCreateButtonProps
  extends Omit<IActionButtonProps, 'children'> {
  employeeUuid: string;
  onSuccess: (value: DayOffDTO) => Promise<void>;
  loading?: boolean;
  onCancel?: () => void;
}

export default function DayOffCreateButton({
  employeeUuid,
  onSuccess,
  loading,
  onCancel,
  ...rest
}: IDayOffCreateButtonProps) {
  const {t} = useTranslation();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <AddButton
        tooltipTitle={t('Create employee day off')}
        onClick={handleOnInit}
        loading={loading}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <DayOffSideWindowLazy
          employeeUuid={employeeUuid}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}
