import React from 'react';
import {Routes, Route} from 'react-router';
import {
  ProductPage,
  ProductCategoryPage,
  ProductRoutePage,
  ProductStoreDocumentListPage,
} from '../views/Products';
import {AppRouteLayout} from '../components/lib/Layout';
import {Routes as RoutesTypes} from '../services/types';
import {ProductCategoryProductRoutesNavigator} from './ProductCategoryProductRoutesNavigator';
import {ProductCategorySubCategoryRoutesNavigator} from './ProductCategorySubCategoryRoutesNavigator';
import {StoreRoutesNavigator} from './StoreRoutesNavigator';

export function ProductRoutesNavigator() {
  return (
    <Routes>
      <Route
        path="*"
        element={
          <AppRouteLayout>
            <ProductRoutePage />
          </AppRouteLayout>
        }
      />

      <Route
        path={`${RoutesTypes.products}/:productId`}
        element={
          <AppRouteLayout>
            <ProductPage />
          </AppRouteLayout>
        }
      />

      <Route
        path={`${RoutesTypes.products}/:productId/${RoutesTypes.movementGoods}`}
        element={
          <AppRouteLayout>
            <ProductStoreDocumentListPage />
          </AppRouteLayout>
        }
      />

      <Route
        path={`${RoutesTypes.products}/:productId/${RoutesTypes.movementGoods}/${RoutesTypes.stores}/*`}
        element={<StoreRoutesNavigator />}
      />

      <Route
        path={`${RoutesTypes.categories}/:categoryId`}
        element={
          <AppRouteLayout>
            <ProductCategoryPage />
          </AppRouteLayout>
        }
      />

      <Route
        path={`${RoutesTypes.categories}/:categoryId/${RoutesTypes.subCategories}`}
        element={<ProductCategorySubCategoryRoutesNavigator />}
      />

      <Route
        path={`${RoutesTypes.categories}/:categoryId/${RoutesTypes.categoryProducts}/*`}
        element={<ProductCategoryProductRoutesNavigator />}
      />
    </Routes>
  );
}
