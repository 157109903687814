import {apiDelete, apiGet, apiPost} from '../core/api';
import {toDateByFormat} from '../helpers';
import {ApiAnswer, IListSearchProps} from '../types';
import {
  ReportDTO,
  ReportFormDTO,
  ReportMapper,
  IReportDTOProps,
  IReportListDTO,
  ReportTypes,
  REPORT_PAYMENT_METHOD,
} from '../../struture';
import {contentApiUrl} from '../const';

export const REPORT_INITIAL_PARAM = new ReportFormDTO();

const {uuid, ...REPORT_INITIAL_PARAM_REST} = REPORT_INITIAL_PARAM;

export const REPORT_FROM_PAYMENT_METHOD = [
  {
    title: REPORT_PAYMENT_METHOD.All,
    uuid: REPORT_PAYMENT_METHOD.All,
  },
  {
    title: REPORT_PAYMENT_METHOD.Cash,
    uuid: REPORT_PAYMENT_METHOD.Cash,
  },
  {
    title: REPORT_PAYMENT_METHOD.Bank,
    uuid: REPORT_PAYMENT_METHOD.Bank,
  },
  {
    title: REPORT_PAYMENT_METHOD.Account,
    uuid: REPORT_PAYMENT_METHOD.Account,
  },
];

export const REPORT_REQUIRED_FIELD = {
  ...REPORT_INITIAL_PARAM_REST,
};

export interface IReportListProps extends IListSearchProps {
  companyUuid: string;
  startDate?: string;
  endDate?: string;
}

export interface IReportAnswer extends ApiAnswer {
  report: IReportDTOProps;
}

export interface IReportListAnswer extends ApiAnswer {
  reports: IReportDTOProps[];
}

export interface IReportSearchProps extends IListSearchProps {
  date_end?: string;
  date_start?: string;
}

export async function getReportList({
  offset = 0,
  limit = 10,
  keywords,
  companyUuid,
  endDate,
  startDate,
}: IReportListProps): Promise<IReportListDTO> {
  const {reports, count} = await apiGet<IReportSearchProps, IReportListAnswer>(
    `${contentApiUrl}/companies/${companyUuid}/reports`,
    {
      offset,
      limit,
      keywords,
      date_end: endDate,
      date_start: startDate,
    },
  );

  return ReportMapper.toReportListDTO(reports, count || 0);
}

export async function getReportById(reportId: string): Promise<ReportDTO> {
  const {report} = await apiGet<null, IReportAnswer>(
    `${contentApiUrl}/reports/${reportId}`,
  );

  return ReportMapper.toReportDTO(report);
}

export async function createReport(
  {
    report_start_date,
    report_end_date,
    report_employee_uuid,
    report_service_uuid,
    report_client_uuid,
    report_operation_type,
    report_tariff_model_uuid,
  }: ReportFormDTO,
  companyUuid: string,
  reportType: ReportTypes,
): Promise<ReportDTO> {
  const {report} = await apiPost<
    Omit<ReportFormDTO, 'uuid' | '_date'>,
    IReportAnswer
  >(`${contentApiUrl}/reports/by/${reportType}/companies/${companyUuid}`, {
    report_start_date: toDateByFormat(report_start_date, 'YYYY-MM-DD'),
    report_end_date: toDateByFormat(report_end_date, 'YYYY-MM-DD'),
    report_employee_uuid,
    report_service_uuid,
    report_client_uuid,
    report_tariff_model_uuid,
    report_operation_type:
      report_operation_type === REPORT_PAYMENT_METHOD.All
        ? ('' as REPORT_PAYMENT_METHOD)
        : report_operation_type,
  });

  return ReportMapper.toReportDTO(report);
}

export async function deleteReport(
  reportUuid: string,
): Promise<IReportListDTO> {
  const {reports} = await apiDelete<{ids: string[]}, IReportListAnswer>(
    `${contentApiUrl}/reports/${reportUuid}`,
  );

  return ReportMapper.toReportListDTO(reports, 0);
}
