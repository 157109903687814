import {List} from 'immutable';
import {STORE_LIST, APP_STATE} from '../constants';
import {compose, isEqualByUuid, not} from '../../services/helpers';
import {ApiError, REDUX_STATUS} from '../../services/types';
import {StoreListModel, StoreMapper, StoreModel} from '../../struture';

interface IStoreListState {
  storeList: StoreListModel | null;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
}

interface SetActionList {
  type: STORE_LIST.SET_STORE_LIST;
  storeList: StoreListModel;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface AddActionToList {
  type: STORE_LIST.ADD_STORE;
  store: StoreModel;
}

interface UpdateStoreFromList {
  type: STORE_LIST.UPDATE_STORE;
  store: StoreModel;
}

interface DeleteActionFromList {
  type: STORE_LIST.DELETE_STORE;
  storeUuid: string;
}

interface LoadingActionInList {
  type: STORE_LIST.LOADING_STORE_LIST;
}

interface ErrorActionInList extends Pick<IStoreListState, 'error'> {
  type: STORE_LIST.ERROR_STORE_LIST;
}

interface Handlers {
  [STORE_LIST.SET_STORE_LIST]: (
    state: IStoreListState,
    action: SetActionList,
  ) => IStoreListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IStoreListState,
    action: SetInitialStateAction,
  ) => IStoreListState;

  [STORE_LIST.LOAD_MORE_STORE_LIST]: (
    state: IStoreListState,
    action: SetActionList,
  ) => IStoreListState;

  [STORE_LIST.ADD_STORE]: (
    state: IStoreListState,
    action: AddActionToList,
  ) => IStoreListState;

  [STORE_LIST.UPDATE_STORE]: (
    state: IStoreListState,
    action: UpdateStoreFromList,
  ) => IStoreListState;

  [STORE_LIST.DELETE_STORE]: (
    state: IStoreListState,
    action: DeleteActionFromList,
  ) => IStoreListState;

  [STORE_LIST.ERROR_STORE_LIST]: (
    state: IStoreListState,
    value: ErrorActionInList,
  ) => IStoreListState;

  [STORE_LIST.LOADING_STORE_LIST]: (
    state: IStoreListState,
    value?: LoadingActionInList,
  ) => IStoreListState;
  DEFAULT: (state: IStoreListState) => IStoreListState;
}

const initState: IStoreListState = {
  storeList: null,
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
};

const handlers: Handlers = {
  [STORE_LIST.SET_STORE_LIST]: (state, {storeList}) => {
    return {
      ...state,
      ...{
        storeList: storeList || null,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [STORE_LIST.LOAD_MORE_STORE_LIST]: (state, {storeList}) => {
    return {
      ...state,
      ...{
        storeList:
          state.storeList &&
          List.isList(state.storeList?.stores) &&
          List.isList(storeList?.stores)
            ? state.storeList.update('stores', (stores) =>
                stores.merge(storeList.stores),
              )
            : state.storeList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [STORE_LIST.ADD_STORE]: (state, {store}) => {
    return {
      ...state,
      ...{
        storeList:
          state.storeList && List.isList(state.storeList?.stores)
            ? state.storeList
                .update('stores', (stores) => stores.unshift(store))
                .update('total', (t = 0) => t + 1)
            : StoreMapper.toStoreListModel([StoreMapper.toStoreDTO(store)], 1),
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [STORE_LIST.UPDATE_STORE]: (
    state: IStoreListState,
    {store}: UpdateStoreFromList,
  ) => {
    return {
      ...state,
      ...{
        storeList:
          state.storeList && List.isList(state.storeList?.stores)
            ? state.storeList.update('stores', (stores) =>
                stores.map((stateStore) => {
                  if (stateStore.uuid === store?.uuid) {
                    return stateStore.merge(store);
                  }
                  return stateStore;
                }),
              )
            : StoreMapper.toStoreListModel([StoreMapper.toStoreDTO(store)], 1),
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [STORE_LIST.DELETE_STORE]: (state, {storeUuid}) => {
    return {
      ...state,
      ...{
        storeList:
          state.storeList && List.isList(state.storeList?.stores)
            ? state.storeList
                .update('stores', (stores) =>
                  stores.filter(compose(not, isEqualByUuid(storeUuid))),
                )
                .update('total', (t = 0) => t - 1)
            : null,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [STORE_LIST.ERROR_STORE_LIST]: (
    state: IStoreListState,
    {error}: ErrorActionInList,
  ) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [STORE_LIST.LOADING_STORE_LIST]: (state: IStoreListState) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IStoreListState) => state,
};

export default function StoreList(
  state: any = initState,
  action: any,
): IStoreListState {
  const handler = handlers[action.type as STORE_LIST] || handlers.DEFAULT;
  return handler(state, action);
}
