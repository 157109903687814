import * as React from 'react';
import {PAYMENT_METHOD} from '../struture';
import {IEntityProps} from '../services/types';
import useLocalStorage from './useLocalStorage';
import {LocalStorageItems} from '../services/const';

export interface IUseStoredSaleFormProps extends IEntityProps {
  companyUuid: string;
}

export interface IStoredSaleForm {
  paymentMethod: PAYMENT_METHOD;
  cashboxUuid: string;
  isOperationFiscal: boolean;
}

export interface IUseStoredSaleFormReturnType {
  storedSalesFormToLocalStorage: IStoredSaleForm;
  setCompanySaleFormDataToLocalStorage: (
    paymentMethod: PAYMENT_METHOD,
    cashboxUuid?: string,
    isOperationFiscal?: boolean,
  ) => Promise<any>;
  getCompanySaleFormData: () => Promise<IStoredSaleForm>;
}

export function useStoredSaleForm(
  params: IUseStoredSaleFormProps,
): IUseStoredSaleFormReturnType {
  const {companyUuid = 'default'} = params || {};
  const [saleFormData, setSaleFormData] = useLocalStorage(
    LocalStorageItems.companySalesFormData,
    {[companyUuid]: {}},
  );

  const setCompanySaleFormData = React.useCallback(
    async (
      paymentMethod: PAYMENT_METHOD,
      cashboxUuid?: string,
      isOperationFiscal?: boolean,
    ): Promise<any> => {
      return setSaleFormData({
        [companyUuid]: {
          ...((saleFormData && saleFormData[companyUuid]) ?? {}),
          paymentMethod,
          [paymentMethod]: {
            cashboxUuid,
            isOperationFiscal,
          },
        },
      });
    },
    [companyUuid, saleFormData, setSaleFormData],
  );

  const getCompanySaleFormData =
    React.useCallback(async (): Promise<IStoredSaleForm> => {
      return Promise.resolve({
        paymentMethod: PAYMENT_METHOD.Cash,
        cashboxUuid: '',
        isOperationFiscal: false,
        ...(saleFormData || {}),
      });
    }, [saleFormData]);

  return {
    storedSalesFormToLocalStorage: {
      ...(saleFormData[companyUuid] || {}),
    },
    getCompanySaleFormData,
    setCompanySaleFormDataToLocalStorage: setCompanySaleFormData,
  };
}
