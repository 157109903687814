import * as React from 'react';
import {getAbonementGroupById} from '../services/api/abonementGroup';
import {
  AbonementGroupModel,
  AbonementGroupMapper,
  AbonementGroupDTO,
} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {IEntityProps} from '../services/types';
import {useDropdownAlert} from '../contex';
import {useTranslation} from 'react-i18next';

export interface IUseAbonementGroupProps extends IEntityProps {
  groupUuid: string;
}

export interface IUseAbonementGroupReturnType {
  error: null | any;
  loading: boolean;
  entity: AbonementGroupModel | null;
  refresh: (
    value: Partial<IUseAbonementGroupProps>,
  ) => Promise<AbonementGroupModel | void>;
  groupUuid: string;
}

export function useAbonementGroup(
  {
    loadOnInit = true,
    groupUuid,
  }: IUseAbonementGroupProps = {} as IUseAbonementGroupProps,
): IUseAbonementGroupReturnType {
  const {cancellablePromise} = useCancellablePromise();
  const {alert} = useDropdownAlert();
  const {t} = useTranslation();

  const {
    entity,
    loading,
    error,
    handleUpdate,
    groupUuid: entityUuid,
  } = useStateReducer<Omit<IUseAbonementGroupReturnType, 'refresh'>>({
    entity: null,
    groupUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading,
      groupUuid = entityUuid,
    }: Partial<IUseAbonementGroupProps> = {}): Promise<AbonementGroupModel | void> => {
      try {
        handleUpdate({loading: showLoading, error: null, groupUuid});

        const group = await cancellablePromise<AbonementGroupDTO>(
          getAbonementGroupById(groupUuid),
        );

        const abonementGroupModel =
          AbonementGroupMapper.toAbonementGroupModel(group);

        handleUpdate({
          entity: abonementGroupModel,
          loading: false,
        });
        return abonementGroupModel;
      } catch (err: any) {
        alert(
          'error',
          t('Abonement group'),
          `${t('An error occurred during get abonement group')} : ${
            err?.message
          }`,
        );

        handleUpdate({
          error: err,
          loading: false,
        });
      }
    },

    [alert, cancellablePromise, entityUuid, handleUpdate, t],
  );

  React.useEffect(() => {
    if (loadOnInit && groupUuid) {
      (async () => {
        await refresh({groupUuid});
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, groupUuid]);

  return {
    error,
    loading,
    refresh,
    entity,
    groupUuid,
  };
}
