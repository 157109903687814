import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {Col, Row, Button} from 'antd';
import {Text} from '../../../components/lib/Format';
import {textToUpperCase} from '../../../services/helpers';
import {StyledDescription} from '../../../components/lib/Styled';
import styled, {css, useTheme} from 'styled-components';
import {LockOutlined, RightOutlined} from '@ant-design/icons';
import {FullWidthSpace} from '../Show';

export default withTranslation()(ReSendButton);

export interface IReSendButtonProps extends WithTranslation {
  disabled: boolean;
  onClick: () => Promise<void> | void;
  seconds: number;
  title: string;
  loading?: boolean;
  mode?: 'light' | 'dark';
  color?: string;
}

const StyledButton = styled(Button)<{disabled: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
  opacity: 1;

  &:hover,
  &:active,
  &:focus {
    background-color: ${({theme}) => theme.colors.app};
    opacity: 0.7;
  }
`;

const StyledRightOutlined = styled(RightOutlined)<{color: string}>`
  ${({color}) =>
    color &&
    css`
      color: ${color};
    `}
`;

function ReSendButton({
  disabled,
  onClick,
  seconds,
  title,
  t,
  loading,
  mode = 'light',
  color,
}: IReSendButtonProps): JSX.Element {
  const theme: any = useTheme();

  return (
    <Row>
      <Col span={24}>
        <FullWidthSpace direction="vertical" size="small">
          <StyledButton
            onClick={onClick}
            type="text"
            disabled={disabled || !!loading}>
            <Text
              color={
                disabled || loading
                  ? theme.colors.textSecondary
                  : color
                  ? color
                  : theme.colors.primary
              }>
              {textToUpperCase(title)}
            </Text>
            {disabled || loading ? (
              <LockOutlined color={theme.colors.textSecondary} size={20} />
            ) : (
              <StyledRightOutlined
                color={
                  disabled || loading
                    ? theme.colors.textSecondary
                    : color
                    ? color
                    : theme.colors.primary
                }
                size={20}
              />
            )}
          </StyledButton>
          {disabled ? (
            <StyledDescription color={theme.colors.disable}>
              {textToUpperCase(t('Resend in'))}&nbsp;
              {seconds}&nbsp;
              {textToUpperCase(t('seconds'))}
            </StyledDescription>
          ) : null}
        </FullWidthSpace>
      </Col>
    </Row>
  );
}
