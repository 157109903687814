import React from 'react';

export const svgServers = (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4844 0H3.51562C2.81602 0 2.25 0.566016 2.25 1.26562V16.7344C2.25 17.434 2.81602 18 3.51562 18H14.4844C15.184 18 15.75 17.434 15.75 16.7344V1.26562C15.75 0.566016 15.184 0 14.4844 0ZM14.4844 16.5762H3.51562V12.2871C3.51562 12.2959 3.52266 12.3047 3.5332 12.3047H14.4668C14.4756 12.3047 14.4844 12.2977 14.4844 12.2871V16.5762ZM14.4668 11.0391C14.4773 11.0391 14.4844 11.0479 14.4844 11.0566V6.76758C14.4844 6.77812 14.4756 6.78516 14.4668 6.78516H3.5332C3.52266 6.78516 3.51562 6.77637 3.51562 6.76758V11.0566C3.51562 11.0461 3.52441 11.0391 3.5332 11.0391H14.4668ZM14.4844 5.53711C14.4844 5.52832 14.4773 5.51953 14.4668 5.51953H3.5332C3.52441 5.51953 3.51562 5.52656 3.51562 5.53711V1.26562H14.4844V5.53711ZM5.64258 15.0645C5.29309 15.0645 5.00977 14.7811 5.00977 14.4316C5.00977 14.0821 5.29309 13.7988 5.64258 13.7988C5.99207 13.7988 6.27539 14.0821 6.27539 14.4316C6.27539 14.7811 5.99207 15.0645 5.64258 15.0645ZM5.00977 8.91211C5.00977 9.2616 5.29309 9.54492 5.64258 9.54492C5.99207 9.54492 6.27539 9.2616 6.27539 8.91211C6.27539 8.56262 5.99207 8.2793 5.64258 8.2793C5.29309 8.2793 5.00977 8.56262 5.00977 8.91211ZM5.64258 4.02539C5.29309 4.02539 5.00977 3.74207 5.00977 3.39258C5.00977 3.04309 5.29309 2.75977 5.64258 2.75977C5.99207 2.75977 6.27539 3.04309 6.27539 3.39258C6.27539 3.74207 5.99207 4.02539 5.64258 4.02539Z"
      fill="currentColor"
    />
  </svg>
);

