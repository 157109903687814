import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {IActionButtonProps} from '../../../components/lib/General';
import {useModal} from '../../../components/lib/libV2/hooks';
import {PAYMENT_STATUS, PaymentFormDTO} from '../../../struture';
import {SuspenseEmpty} from '../../../components/lib/DataDisplay';
import {Routes} from '../../../services/types';
import {Dropdown, MenuProps} from 'antd';
import {StyledTitle} from '../../../components/lib/Styled';
import {LoadingOutlined, LockOutlined} from '@ant-design/icons';
import {IoMenu} from 'react-icons/io5';
import styled, {useTheme, css} from 'styled-components';
import {MdModeEditOutline} from 'react-icons/md';
import {FaCashRegister} from 'react-icons/fa';

const PaymentSideWindowLazy = React.lazy(
  () => import('../Show/PaymentSideWindow'),
);

const PaymentCashBoxModalLazy = React.lazy(
  () => import('../Show/PaymentCashBoxModal'),
);

export interface IUpdatePaymentMenuButtonProps
  extends Omit<IActionButtonProps, 'children'> {
  onSuccess: (value: PaymentFormDTO) => Promise<void>;
  onCancel?: () => void;
  children?: React.ReactNode;
  payment: PaymentFormDTO;
  companyUuid: string;
  from?: Routes.clients | Routes.orders;
  handleUpdatePaymentCashBox: (value: PaymentFormDTO) => Promise<void>;
  status: PAYMENT_STATUS;
}

const StyledDropdown = styled(Dropdown)<{
  disabled?: boolean;
}>`
  cursor: pointer;

  margin-right: 15px;
  .ant-dropdown-menu-item {
    padding: 0 !important;
  }

  ${({disabled}) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

const StyledLockOutlined = styled(LockOutlined)`
  color: ${({theme}) => theme.colors.disabled};
`;

export default function UpdatePaymentMenuButton({
  children,
  payment,
  onSuccess,
  onCancel,
  companyUuid,
  from,
  handleUpdatePaymentCashBox,
  disabled,
  loading,
  status,
  ...rest
}: IUpdatePaymentMenuButtonProps) {
  const {t} = useTranslation();
  const theme: any = useTheme();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  const {
    visible: cashBoxVisible,
    handleSuccess: cashBoxHandleSuccess,
    handleOnInit: cashBoxHandleOnInit,
    handleCancel: cashBoxHandleCancel,
  } = useModal({
    onSuccess: handleUpdatePaymentCashBox,
  });

  const items: MenuProps['items'] = [
    {
      key: 'edit',
      label: <StyledTitle>{t('Edit')}</StyledTitle>,
      icon:
        status === PAYMENT_STATUS.Completed ? (
          <StyledLockOutlined />
        ) : (
          <MdModeEditOutline size={15} />
        ),
      onClick: handleOnInit,
      disabled: status === PAYMENT_STATUS.Completed,
    },
    {
      key: 'group',
      label: (
        <StyledTitle>
          {t('Changing the method and cash register of the operation')}
        </StyledTitle>
      ),
      icon: <FaCashRegister size={13} />,
      onClick: cashBoxHandleOnInit,
    },
  ];

  return (
    <>
      <StyledDropdown
        trigger={['hover']}
        disabled={!!loading || disabled}
        menu={{items}}
        placement="bottomLeft">
        {loading ? (
          <LoadingOutlined color={theme.colors.primary} />
        ) : (
          <IoMenu
            size={20}
            color={disabled ? theme.colors.disabled : theme.colors.primary}
          />
        )}
      </StyledDropdown>

      <Suspense fallback={<SuspenseEmpty />}>
        <PaymentSideWindowLazy
          from={from}
          companyUuid={companyUuid}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          editMode
          payment={payment}
          visible={visible}
        />
      </Suspense>

      <Suspense fallback={<SuspenseEmpty />}>
        <PaymentCashBoxModalLazy
          payment={payment}
          visible={cashBoxVisible}
          onCancel={cashBoxHandleCancel}
          onSuccess={cashBoxHandleSuccess}
        />
      </Suspense>
    </>
  );
}
