import {
  EmployeeModel,
  ExpenseDiscountType,
  ExpenseModel,
  ExpenseWorkableType,
  ProductModel,
  ServiceModel,
} from '../internal';
import {correctPrice} from '../../helpers';

export class ExpenseFormDTO {
  public uuid: string;

  public name?: string;

  public prevPrice?: string;

  public price: string;

  public net_price: string;

  public comment: string;

  public amount: number;

  public discount: number;

  public discount_type: ExpenseDiscountType;

  public workable_type: ExpenseWorkableType;

  public workable_uuid: string;

  public manager_uuid: EmployeeModel | string;

  constructor(props?: ExpenseModel, editMode?: boolean) {
    this.uuid = props?.uuid || '';
    this.name =
      props?.workable instanceof ProductModel
        ? props?.workable?.product_title
        : props?.workable instanceof ServiceModel
        ? props?.workable?.title
        : '';
    this.comment = props?.comment || '';
    this.discount = props?.discount || 0;
    this.discount_type = props?.discount_type || ExpenseDiscountType.Fixed;
    this.workable_type = props?.workable_type || ExpenseWorkableType.Product;

    this.amount = props?.amount || 0;
    this.prevPrice = correctPrice(props?.total || 0);
    this.price = correctPrice(props?.price || 0);
    this.net_price = correctPrice(
      props?.workable instanceof ProductModel
        ? props?.workable?.product_net_price
        : props?.workable instanceof ServiceModel
        ? props?.workable?.net_price
        : 0,
    );

    this.workable_uuid = props?.workable?.uuid ? props?.workable?.uuid : '';

    this.manager_uuid = editMode
      ? props?.manager?.uuid
        ? props?.managerModel
        : ''
      : props?.manager?.uuid || '';
  }
}
