import {IProductBalances, ProductModel} from '../internal';
import {head} from '../../helpers';

export interface IProductLeftoversAdditionalParams {
  storeUuid?: string;
  withAddedStore?: boolean;
}

export interface StoreBalance {
  store_uuid: string;
  balance: 0;
  store_name?: string;
}

export class ProductLeftoversFormDTO {
  public uuid: string;

  public store_balances: StoreBalance[];

  public product_title?: string;

  public with_added_store?: boolean;

  constructor(
    props: ProductModel,
    {storeUuid, withAddedStore}: IProductLeftoversAdditionalParams,
  ) {
    this.uuid = props?.uuid || '';
    this.product_title = props?.product_title || '';
    this.with_added_store = withAddedStore ?? false;
    this.store_balances = this.buildStoreBalances(
      props?.product_balances || [],
      storeUuid,
    );
  }

  private buildStoreBalances(
    productBalance: IProductBalances[],
    storeUuid?: string,
  ): StoreBalance[] {
    const storeBalances = productBalance.map(
      ({company_store, balance_total}): any => ({
        store_uuid: company_store?.uuid || '',
        store_name: company_store?.store_name || '',
        balance: balance_total || 0,
      }),
    );

    if (storeUuid) {
      return [head(storeBalances)];
    }

    return storeBalances;
  }
}
