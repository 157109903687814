import React from 'react';
import {
  EmployeeModel,
  RewardModel,
  RewardRewardableType,
} from '../../../struture';
import styled, {useTheme} from 'styled-components';
import {StyledTitle} from '../../../components/lib/Styled';
import {useTranslation} from 'react-i18next';
import {Routes} from '../../../services/types';
import {Link} from 'react-router-dom';
import {Tooltip} from 'antd';
import {useLocation, useParams} from 'react-router';
import {toDateByFormat} from '../../../services/helpers';
import {useStoredCompanies} from '../../../hooks';
import {correctPrice} from '@sportix/sportix-common-modules';

export interface IRewardColumnDetailsFieldProps {
  reward: RewardModel;
  employee: EmployeeModel | null;
  page: number;
}

const rewardTypeText = {
  [RewardRewardableType.Schedule]: 'Bonus',
  [RewardRewardableType.Salary]: 'Accrual of salary',
  [RewardRewardableType.Premium]: 'tPremium',
  [RewardRewardableType.Penalty]: 'Fine',
  Unknown: 'Unknown',
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const StyledPaymentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export function RewardColumnDetailsField({
  reward,
  employee,
  page,
}: IRewardColumnDetailsFieldProps): React.JSX.Element {
  const {t} = useTranslation();
  const {employeeId} = useParams();
  const theme: any = useTheme();
  const {defaultCompany} = useStoredCompanies();
  const location = useLocation();

  const price = Number(reward?.price);
  const sumPaid = Number(reward?.sum_paid);

  const isPartialSumPaid = sumPaid > 0;

  const sum = sumPaid - price;

  const isPaid = sumPaid >= price;

  return (
    <StyledContainer>
      <StyledTitle fontSize={15} fontWeight={500}>
        {`${t(
          rewardTypeText[reward?.rewardable_type] || rewardTypeText.Unknown,
        )}: ${reward?.hint}`}
      </StyledTitle>
      {reward?.payment?.uuid ? (
        <>
          <StyledPaymentContainer>
            <Link
              to={{
                pathname: `/${Routes.app}/${Routes.employees}/${employeeId}/${Routes.rewards}/${Routes.payments}/${reward?.payment?.uuid}`,
              }}
              state={{
                fullName: employee?.fullName,
                from: location.pathname,
                page,
              }}>
              <Tooltip title={t('Open a details of the payment')}>
                <StyledTitle>{reward?.payment?.payment_number}</StyledTitle>
              </Tooltip>
            </Link>
            {isPartialSumPaid ? (
              <StyledTitle
                $color={
                  isPaid ? theme.colors.success : theme.colors.primary
                }>{`/ ${t('payed')} ${
                defaultCompany?.currency_symbol || ''
              } ${correctPrice(sumPaid)} ${t('with')} ${correctPrice(
                price,
              )}`}</StyledTitle>
            ) : null}

            <StyledTitle
              $color={
                isPaid
                  ? isPartialSumPaid
                    ? theme.colors.success
                    : theme.colors.error
                  : theme.colors.primary
              }>{`- ${toDateByFormat(
              reward?.payment?.payment_date,
              'DD.MM.YYYY HH:mm',
            )}`}</StyledTitle>
          </StyledPaymentContainer>
          {isPartialSumPaid ? (
            <>
              {sumPaid > price ? (
                <StyledTitle $color={theme.colors.error}>{`${t(
                  "Withdrawn from the client's account",
                )} ${defaultCompany?.currency_symbol || ''} ${correctPrice(
                  sum,
                )}`}</StyledTitle>
              ) : null}
            </>
          ) : null}
        </>
      ) : null}
    </StyledContainer>
  );
}
