import * as React from 'react';
import {List} from 'immutable';
import {
  useAbonementList,
  IUseAbonementListProps,
  IUseAbonementListReturnType,
} from './useAbonementList';
import {AbonementModel} from '../struture';
import {deleteAbonement} from '../services/api/abonement';
import {
  setAbonementList as storeSetAbonementList,
  deleteAbonementFromList as storeDeleteAbonement,
  loadMoreAbonementList as storeLoadMoreAbonementList,
} from '../store/actions';
import {useSelector, useDispatch} from 'react-redux';
import {RootState} from '../store/reducers';
import {REDUX_STATUS} from '../services/types';
import {head} from '../services/helpers';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../contex';

export interface IUseStateAbonementListProps extends IUseAbonementListProps {}

export interface IAbonementSearchProps {
  keywords: string;
  showLoading?: boolean;
  limit?: number;
}

export interface IUseStateAbonementListReturnType
  extends Omit<IUseAbonementListReturnType, 'entityList'> {
  abonementList: List<AbonementModel> | null;
  handleDeleteAbonements: (ids: string[]) => Promise<void>;
  handleSearchAbonements: (value: IAbonementSearchProps) => Promise<void>;
  handleLoadMoreAbonements: () => Promise<void>;
  handleRefreshAbonements: (
    value: Partial<IUseAbonementListProps> & {page: number},
  ) => Promise<void>;
  status: REDUX_STATUS;
  loadingMore: boolean;
  page: number;
}

export function useStoredAbonementList(
  {
    companyUuid,
    loadOnInit,
    ...rest
  }: IUseStateAbonementListProps = {} as IUseStateAbonementListProps,
): IUseStateAbonementListReturnType {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const [loadingMore, setLoadingMore] = useState(false);

  const {
    status: storedAbonementListStatus,
    loading: storedAbonementListLoading,
    abonementList,
    total: storeTotal,
    keywords: storeKeywords,
    page: storedPage,
    ...storedAbonementListParams
  } = useSelector(({abonementList}: RootState) => abonementList);

  const dispatch = useDispatch<any>();

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: abonementListLoading,
    total,
    ...abonementsParams
  } = useAbonementList({
    companyUuid,
    loadOnInit:
      loadOnInit || storedAbonementListStatus !== REDUX_STATUS.SUCCEEDED,
    ...rest,
  });

  useEffect(() => {
    if (
      List.isList(entityList) &&
      storedAbonementListStatus !== REDUX_STATUS.SUCCEEDED
    ) {
      dispatch(storeSetAbonementList(entityList, '', total));
    }
  }, [dispatch, entityList, storedAbonementListStatus, total]);

  const handleDeleteAbonements = React.useCallback(
    async (ids: string[]) => {
      try {
        await deleteAbonement(ids);

        setTimeout(() => {
          dispatch(storeDeleteAbonement(head(ids)));
        }, 100);
        alert('success', t('Tariff models'), t('Tariff models delete success'));
      } catch (error: any) {
        alert(
          'error',
          t('Tariff models'),
          `${t('An error occurred during delete tariff models')}: ${
            error?.message
          }`,
        );
      }
    },
    [alert, dispatch, t],
  );

  const handleSearchAbonements = React.useCallback(
    async ({limit = 10, keywords, showLoading}: IAbonementSearchProps) => {
      setLoadingMore(true);
      const abonementListModel = await refresh({
        offset: 0,
        limit,
        keywords,
        showLoading,
      });

      if (abonementListModel && List.isList(abonementListModel?.abonements)) {
        dispatch(
          storeSetAbonementList(
            abonementListModel.abonements,
            keywords,
            abonementListModel.total,
          ),
        );
      }
      setLoadingMore(false);
    },
    [dispatch, refresh],
  );

  const handleLoadMoreAbonements = useCallback(async () => {
    if (
      List.isList(abonementList) &&
      abonementList?.size < storeTotal &&
      !loadingMore
    ) {
      setLoadingMore(true);
      const abonementListModel = await refresh({
        offset: abonementList?.size,
        limit: 10,
        showLoading: false,
      });

      if (abonementListModel && List.isList(abonementListModel?.abonements)) {
        dispatch(storeLoadMoreAbonementList(abonementListModel?.abonements));
      }

      setLoadingMore(false);
    }
  }, [dispatch, loadingMore, refresh, abonementList, storeTotal]);

  const handleRefreshAbonements = useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = false,
      page,
    }: Partial<IUseAbonementListProps> & {page: number}) => {
      setLoadingMore(true);
      const abonementListModel = await refresh({
        offset,
        limit,
        showLoading,
      });

      if (abonementListModel && List.isList(abonementListModel?.abonements)) {
        dispatch(
          storeSetAbonementList(
            abonementListModel?.abonements,
            '',
            abonementListModel?.total,
            page,
          ),
        );
      }
      setLoadingMore(false);
    },
    [dispatch, refresh],
  );

  return {
    ...abonementsParams,
    ...storedAbonementListParams,
    abonementList,
    total: storeTotal,
    loadingMore,
    offset,
    limit,
    refresh,
    loading:
      (!List.isList(abonementList) && abonementListLoading) ||
      !storedAbonementListLoading,
    handleDeleteAbonements,
    handleSearchAbonements,
    handleLoadMoreAbonements,
    status: storedAbonementListStatus,
    handleRefreshAbonements,
    keywords: storeKeywords || '',
    page: storedPage || 1,
  };
}
