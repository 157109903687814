import {Record} from 'immutable';

export const VERIFICATION_BY_PHONE = 'phone';
export const VERIFICATION_BY_EMAIL = 'email';

export interface VerificationProps {
  uuid: string;
  status: string;
  field: string;
  code: string;
  created_at: string;
  updated_at: string;
}

/**
 * @class Verification
 * @classdesc
 */
export default class Verification extends Record({
  uuid: '',
  status: '',
  field: '',
  code: '',
  created_at: '',
  updated_at: '',
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(props: VerificationProps = {} as VerificationProps) {
    super({
      ...props,
    });
  }
}
