import * as React from 'react';
import {List} from 'immutable';
import {ApiError, IEntityProps, IListSearchProps} from '../services/types';
import {
  IRewardListDTO,
  RewardMapper,
  RewardModel,
  RewardListModel,
  IRewardStatsDTOProps,
} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {getRewardEstimatedAmount, getRewardList} from '../services/api/reward';
import {useDropdownAlert} from '../contex';
import {useTranslation} from 'react-i18next';
import {toDateByFormat} from '../services/helpers';
import {updateRewardStatsFromList as storeUpdateRewardStatsFromList} from '../store/actions';

export interface IUseRewardListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  employeeUuid: string;
  month?: number;
  year?: number;
  totalAccrued?: string;
}

export interface IUseRewardListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<RewardModel> | null;
  refresh: (
    value: Partial<IUseRewardListProps>,
  ) => Promise<RewardListModel | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  employeeUuid: string;
  month: number;
  year: number;
  stats: IRewardStatsDTOProps;
}

export function useRewardList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    employeeUuid,
    month = Number(toDateByFormat(new Date(), 'MM')),
    year = Number(toDateByFormat(new Date(), 'YYYY')),
  }: IUseRewardListProps = {} as IUseRewardListProps,
): IUseRewardListReturnType {
  const {cancellablePromise} = useCancellablePromise();
  const {alert} = useDropdownAlert();
  const {t} = useTranslation();

  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    employeeUuid: listUuid,
    month: listMonth,
    year: listYear,
    stats: listStats,
    handleUpdate,
  } = useStateReducer<Omit<IUseRewardListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    employeeUuid,
    month,
    year,
    stats: {} as IRewardStatsDTOProps,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      employeeUuid = listUuid,
      month = listMonth,
      year = listYear,
      totalAccrued,
    }: Partial<IUseRewardListProps> = {}): Promise<RewardListModel | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          employeeUuid,
          month,
          year,
        });

        const {rewards, stats, total} =
          await cancellablePromise<IRewardListDTO>(
            getRewardList({limit, offset, keywords, employeeUuid, month, year}),
          );

        let updatedStats: any;

        if (listStats?.totalAccrued) {
          updatedStats = {...stats, totalAccrued: listStats?.totalAccrued};
        } else {
          const {total} = await getRewardEstimatedAmount(employeeUuid);

          updatedStats = {...stats, totalAccrued: total};
        }

        if (totalAccrued) {
          updatedStats = {...stats, totalAccrued};
        }

        const abonementListModel = RewardMapper.toRewardListModel(
          rewards,
          total,
          updatedStats,
        );

        handleUpdate({
          entityList: abonementListModel?.rewards,
          total: abonementListModel?.total,
          loading: false,
          stats: updatedStats,
        });

        return abonementListModel;
      } catch (e: any) {
        alert(
          'error',
          t('Rewards'),
          `${t('An error occurred during get reward list')} : ${e?.message}`,
        );

        handleUpdate({
          error,
          loading: false,
        });
      }
    },
    [
      alert,
      cancellablePromise,
      error,
      handleUpdate,
      listKeywords,
      listLimit,
      listMonth,
      listOffset,
      listStats?.totalAccrued,
      listUuid,
      listYear,
      t,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && employeeUuid) {
      (async () => {
        await refresh({employeeUuid});
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, employeeUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    employeeUuid: listUuid,
    month: listMonth,
    year: listYear,
    stats: listStats,
  };
}
