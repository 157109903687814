import React, {useCallback, useEffect, useRef} from 'react';
import {IDefaultFormProps, DefaultForm} from '../../../components/lib/General';
import {StoreDocumentItemFormDTO} from '../../../struture';
import {Col, Form, Input, InputNumber, Row, Tooltip} from 'antd';
import styled, {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {StyledDescription} from '../../../components/lib/Styled';
import {WarningOutlined} from '@ant-design/icons';
import {isFunction} from '../../../services/helpers';
import {ENTER_KEYS} from '../../../services/const';

export interface IStoreProductAmountFormProps
  extends Omit<
    IDefaultFormProps<StoreDocumentItemFormDTO, StoreDocumentItemFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  product: StoreDocumentItemFormDTO;
  withCell?: boolean;
}

const FullWidthInputNumber = styled(InputNumber)`
  width: 100%;
`;

const StyledPriceMarginCol = styled(Col)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const StyledPriceMarginFormItem = styled(Form.Item)`
  flex: 1;
`;

const StyledWarningOutlined = styled(WarningOutlined)`
  color: ${({theme}) => theme.colors.warning};
  font-size: 18px;
  margin-bottom: 0px;
`;

export function StoreProductAmountForm({
  loading,
  editMode,
  product,
  onCancel,
  onSuccess,
  withCell,
  ...rest
}: IStoreProductAmountFormProps) {
  const {t} = useTranslation();
  const theme: any = useTheme();
  const [instance] = Form.useForm();

  const productAmount = Form.useWatch('product_amount', instance);
  const productCell = Form.useWatch('cell_identifier', instance);
  const amountRef = useRef<HTMLInputElement>(null);

  const notifyError = useCallback((apiError: any) => {}, []);

  const handleSuccess = useCallback(async () => {
    if (isFunction(onSuccess)) {
      await onSuccess({
        ...product,
        product_amount: productAmount,
        cell_identifier: productCell,
      });
    }
  }, [onSuccess, product, productAmount, productCell]);

  useEffect(() => {
    if (amountRef?.current) {
      amountRef.current?.focus();
    }
  }, []);

  return (
    <DefaultForm<any, any>
      formKeyboardCodes={['Tab', ...ENTER_KEYS]}
      instance={instance}
      editMode={editMode}
      showCancelButton={false}
      initialValues={{
        ...product,
      }}
      showNotify={false}
      onCancel={onCancel}
      notifyError={notifyError}
      {...rest}
      onSuccess={handleSuccess}>
      {({loadingSubmit}) => (
        <Row gutter={12}>
          <StyledPriceMarginCol span={24}>
            <StyledPriceMarginFormItem
              tooltip={t('tAmount')}
              label={t('tAmount')}
              name="product_amount"
              rules={[
                ({}) => ({
                  validator(_, amount) {
                    if (Number.isNaN(Number(amount))) {
                      return Promise.reject(
                        new Error(t('tAmount must be number.')),
                      );
                    }

                    if (!amount) {
                      return Promise.reject(
                        new Error(t('tAmount must be specified.')),
                      );
                    }

                    if (amount < 1) {
                      return Promise.reject(
                        new Error(t('tAmount must not be less than 1')),
                      );
                    }

                    const max_amount = product?.max_amount;

                    if (max_amount && amount > max_amount) {
                      return Promise.reject(
                        new Error(
                          `${t(
                            'tAmount must be less or equal than',
                          )} ${max_amount}`,
                        ),
                      );
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
              extra={
                product?.product_store_name ? (
                  <StyledDescription
                    $color={
                      productAmount >
                      Number(product?.product_store_balance_total || 0)
                        ? theme.colors.warning
                        : theme.colors.success
                    }>
                    {`${t('Quantity in stock')} `}
                    <StyledDescription
                      $color={
                        productAmount >
                        Number(product?.product_store_balance_total || 0)
                          ? theme.colors.warning
                          : theme.colors.success
                      }
                      fontWeight={
                        500
                      }>{`"${product?.product_store_name}": `}</StyledDescription>
                    {Number(product?.product_store_balance_total || 0)}
                  </StyledDescription>
                ) : null
              }>
              <FullWidthInputNumber
                ref={amountRef}
                onFocus={(e) => e?.target?.select()}
                type="number"
                disabled={loading || loadingSubmit}
                placeholder={t('Enter an amount')}
              />
            </StyledPriceMarginFormItem>
            {productAmount >
            Number(product?.product_store_balance_total || 0) ? (
              <Tooltip
                title={t(
                  'The indicated quantity of the product is greater than the quantity in stock',
                )}>
                <StyledWarningOutlined />
              </Tooltip>
            ) : null}
          </StyledPriceMarginCol>
          {withCell ? (
            <Col span={24}>
              <Form.Item label={t('Cell')} name="cell_identifier">
                <Input
                  disabled={loading || loadingSubmit}
                  placeholder={t('Enter a cell')}
                />
              </Form.Item>
            </Col>
          ) : null}
        </Row>
      )}
    </DefaultForm>
  );
}
