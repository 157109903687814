import {Record, List} from 'immutable';
import {ListModel, ListProps} from './List';
import ProfileSubscription, {
  IProfileSubscriptionProps,
} from './ProfileSubscription';

export interface IProfileSubscriptionListProps extends Partial<ListProps> {
  profile_subscriptions: IProfileSubscriptionProps[];
}

export interface IProfileSubscriptionListReturnType
  extends Omit<IProfileSubscriptionListProps, 'profile_subscriptions'> {
  profile_subscriptions: List<ProfileSubscription[]>;
}

export default class ProfileSubscriptionList extends Record<IProfileSubscriptionListReturnType>(
  {
    profile_subscriptions: List(),
    ...ListModel.toJS(),
  } as any,
) {
  constructor({
    profile_subscriptions = [],
    ...props
  }: IProfileSubscriptionListProps) {
    const options: any = {};

    if (Array.isArray(profile_subscriptions)) {
      options.profile_subscriptions = List(
        profile_subscriptions.map((group) => new ProfileSubscription(group)),
      );
    }

    super({
      ...props,
      ...options,
    });
  }
}
