import {
  COLOR_GREEN,
  COLOR_GREEN_APPLE,
  COLOR_PURPLE,
  COLOR_BLUE,
  COLOR_YELLOW,
  COLOR_GRAPEFRUIT,
  COLOR_RED,
  COLOR_ORANGE,
  COLOR_KIWI,
  COLOR_WATER,
  COLOR_LILAC,
  COLOR_GRAY,
} from '../constants';

export function getRandomServiceColour(): string {
  const arrColours: string[] = [
    COLOR_GREEN,
    COLOR_GREEN_APPLE,
    COLOR_PURPLE,
    COLOR_BLUE,
    COLOR_YELLOW,
    COLOR_GRAPEFRUIT,
    COLOR_RED,
    COLOR_ORANGE,
    COLOR_KIWI,
    COLOR_WATER,
    COLOR_LILAC,
    COLOR_GRAY,
  ];

  return (
    arrColours[Math.floor(Math.random() * arrColours.length)] || COLOR_GREEN
  );
}
