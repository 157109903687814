import {Record} from 'immutable';

export interface IClientsPersonalAccountsProps {
  uuid: string;
  balance_before: number;
  created_at: string;
  updated_at: string;
}

/**
 * @class ClientsPersonalAccounts
 * @desc
 * @extends {Record}
 */
export default class ClientsPersonalAccounts extends Record({
  uuid: '',
  balance_before: 0,
  created_at: '',
  updated_at: '',
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(
    props: IClientsPersonalAccountsProps = {} as IClientsPersonalAccountsProps,
  ) {
    super({...props});
  }
}
