import React from 'react';
import {Input as AntInput, InputProps} from 'antd';
import styled from 'styled-components';

export interface IInputProps extends InputProps {}

const StyledAntInput = styled(AntInput)`
  border-color: rgb(236, 236, 236);
  height: 42px;
  border-radius: ${({theme}) => theme.border.baseBorderRadius};
`;

export default function Input({
  placeholder,
  disabled,
  ...rest
}: IInputProps): JSX.Element {
  return (
    <StyledAntInput
      disabled={disabled}
      placeholder={disabled ? '' : placeholder}
      {...rest}
    />
  );
}
