import {Record, List} from 'immutable';
import {ListModel, ListProps} from './List';
import ServiceGroupSession, {
  IServiceGroupSessionProps,
} from './ServiceGroupSession';

export interface IServiceGroupSessionListProps extends ListProps {
  group_sessions: IServiceGroupSessionProps[];
}

/**
 * @class ServiceGroupSessionList
 * @classdesc Service sessions list model.
 * @property {List} services - list of companies
 * @property {number} total - total amount of records on backend
 */
export default class ServiceGroupSessionList extends Record({
  group_sessions: List(),
  ...ListModel.toJS(),
}) {
  /**
   * @constructor
   * @param {{service_sessions:[]}} param0
   */
  constructor({group_sessions = [], ...props}: IServiceGroupSessionListProps) {
    super({
      group_sessions: List(
        group_sessions.map(
          (groupSession) => new ServiceGroupSession(groupSession),
        ),
      ),
      ...props,
    });
  }
}
