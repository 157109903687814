import * as React from 'react';
import {
  useStoredClientAbonementList,
  IUseStateClientAbonementListReturnType,
} from './useStoredClientAbonementList';
import {useStoredCompanyClientsAbonementList} from './useStoredCompanyClientsAbonementList';
import {useStoredTariffModelGroupClientsAbonementList} from './useStoredTariffModelGroupClientsAbonementList';
import {useLocation, useNavigate, useParams} from 'react-router';
import {useStopLoading} from './useStopLoading';
import {useBreadcrumb} from '../components/lib/libV2/hooks';
import {Routes as RoutesType, Routes, RoutesTitle} from '../services/types';
import {IBreadcrumbProps} from '../components/lib/DataDisplay';
import {textToUpperCase} from '../services/helpers';
import {useTranslation} from 'react-i18next';
import {
  AbonementGroupModel,
  AbonementModel,
  ClientAbonementModel,
  ClientModel,
} from '../struture';
import useStoredCompanies from './useStoredCompanies';
import {useStoredTariffModelClientsAbonementList} from './useStoredTariffModelClientsAbonementList';
import {useCallback} from 'react';

export interface IUseClientsAbonementListFactoryReturnType
  extends Pick<
      IUseStateClientAbonementListReturnType,
      | 'abonementList'
      | 'loading'
      | 'limit'
      | 'total'
      | 'keywords'
      | 'page'
      | 'handleRefreshClientAbonements'
      | 'handleDeleteClientAbonements'
      | 'handleSearchClientAbonements'
    >,
    Pick<IBreadcrumbProps, 'routes'> {
  title: string;
  path: (abonement: ClientAbonementModel) => string;
  client: ClientModel | null;
  tariffModel: AbonementModel | null;
  tariffModelGroup: AbonementGroupModel | null;
  navigateGoBack: () => void;
  disabledField: 'abonements' | 'clients' | 'group' | undefined;
}
export function useClientsAbonementListFactory(): IUseClientsAbonementListFactoryReturnType {
  const {clientId, companyId, tariffModelId, tariffModelGroupId} = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {defaultCompanyUuid} = useStoredCompanies();

  const {
    abonementList: clientAbonementList,
    loading: clientAbonementsListLoading,
    error: errorAbonementsListError,
    limit: clientPageSize,
    total: clientTotal,
    loadingMore: clientLoadingMore,
    keywords: clientKeywords,
    page: clientPage,
    client,

    handleRefreshClientAbonements: clientHandleRefreshClientAbonements,
    handleDeleteClientAbonements: clientHandleDeleteClientAbonements,
    handleSearchClientAbonements: clientHandleSearchClientAbonements,
  } = useStoredClientAbonementList({
    clientUuid: clientId!,
    loadOnInit: !!clientId,
  });

  const {
    abonementList: companyClientAbonementList,
    loading: companyClientAbonementsListLoading,
    error: companyClientAbonementsListError,
    limit: companyClientPageSize,
    total: companyClientTotal,
    loadingMore: companyClientLoadingMore,
    keywords: companyClientKeywords,
    page: companyClientPage,

    handleRefreshClientAbonements: companyClientHandleRefreshClientAbonements,
    handleDeleteClientAbonements: companyClientHandleDeleteClientAbonements,
    handleSearchClientAbonements: companyClientHandleSearchClientAbonements,
  } = useStoredCompanyClientsAbonementList({
    companyUuid: companyId!,
    loadOnInit: !!companyId,
  });

  const {
    abonementList: tariffModelClientAbonementList,
    loading: tariffModelClientAbonementsListLoading,
    error: tariffModelClientAbonementsListError,
    limit: tariffModelClientPageSize,
    total: tariffModelClientTotal,
    loadingMore: tariffModelClientLoadingMore,
    keywords: tariffModelClientKeywords,
    page: tariffModelClientPage,
    tariffModel,

    handleRefreshClientAbonements:
      tariffModelClientHandleRefreshClientAbonements,
    handleDeleteClientAbonements: tariffModelClientHandleDeleteClientAbonements,
    handleSearchClientAbonements: tariffModelClientHandleSearchClientAbonements,
  } = useStoredTariffModelClientsAbonementList({
    abonementUuid: tariffModelId!,
    loadOnInit: !!tariffModelId && !tariffModelGroupId,
  });

  const {
    abonementList: tariffModelGroupClientAbonementList,
    loading: tariffModelGroupClientAbonementsListLoading,
    error: tariffModelGroupClientAbonementsListError,
    limit: tariffModelGroupClientPageSize,
    total: tariffModelGroupClientTotal,
    loadingMore: tariffModelGroupClientLoadingMore,
    keywords: tariffModelGroupClientKeywords,
    page: tariffModelGroupClientPage,
    tariffModelGroup,

    handleRefreshClientAbonements:
      tariffModelGroupClientHandleRefreshClientAbonements,
    handleDeleteClientAbonements:
      tariffModelGroupClientHandleDeleteClientAbonements,
    handleSearchClientAbonements:
      tariffModelGroupClientHandleSearchClientAbonements,
  } = useStoredTariffModelGroupClientsAbonementList({
    groupUuid: tariffModelGroupId!,
    loadOnInit: !!tariffModelId && !!tariffModelGroupId,
  });

  const loading = useStopLoading({
    error: tariffModelGroupId
      ? tariffModelGroupClientAbonementsListError
      : tariffModelId
      ? tariffModelClientAbonementsListError
      : companyId
      ? companyClientAbonementsListError
      : errorAbonementsListError,
    loading: tariffModelGroupId
      ? tariffModelGroupClientAbonementsListLoading ||
        tariffModelGroupClientLoadingMore
      : tariffModelId
      ? tariffModelClientAbonementsListLoading || tariffModelClientLoadingMore
      : companyId
      ? companyClientAbonementsListLoading || companyClientLoadingMore
      : clientAbonementsListLoading || clientLoadingMore,
    message: 'An error occurred during abonements loading',
  });

  const abonementList = tariffModelGroupId
    ? tariffModelGroupClientAbonementList
    : tariffModelId
    ? tariffModelClientAbonementList
    : companyId
    ? companyClientAbonementList
    : clientAbonementList;

  const limit = tariffModelGroupId
    ? tariffModelGroupClientPageSize
    : tariffModelId
    ? tariffModelClientPageSize
    : companyId
    ? companyClientPageSize
    : clientPageSize;

  const total = tariffModelGroupId
    ? tariffModelGroupClientTotal
    : tariffModelId
    ? tariffModelClientTotal
    : companyId
    ? companyClientTotal
    : clientTotal;

  const keywords = tariffModelGroupId
    ? tariffModelGroupClientKeywords
    : tariffModelId
    ? tariffModelClientKeywords
    : companyId
    ? companyClientKeywords
    : clientKeywords;

  const page = tariffModelGroupId
    ? tariffModelGroupClientPage
    : tariffModelId
    ? tariffModelClientPage
    : companyId
    ? companyClientPage
    : clientPage;

  const handleRefreshClientAbonements = tariffModelGroupId
    ? tariffModelGroupClientHandleRefreshClientAbonements
    : tariffModelId
    ? tariffModelClientHandleRefreshClientAbonements
    : companyId
    ? companyClientHandleRefreshClientAbonements
    : clientHandleRefreshClientAbonements;

  const handleDeleteClientAbonements = tariffModelGroupId
    ? tariffModelGroupClientHandleDeleteClientAbonements
    : tariffModelId
    ? tariffModelClientHandleDeleteClientAbonements
    : companyId
    ? companyClientHandleDeleteClientAbonements
    : clientHandleDeleteClientAbonements;

  const handleSearchClientAbonements = tariffModelGroupId
    ? tariffModelGroupClientHandleSearchClientAbonements
    : tariffModelId
    ? tariffModelClientHandleSearchClientAbonements
    : companyId
    ? companyClientHandleSearchClientAbonements
    : clientHandleSearchClientAbonements;

  const title = tariffModelGroupId
    ? textToUpperCase(t('Participants'))
    : tariffModelId
    ? textToUpperCase(t('Tariff model clients abonements'))
    : companyId
    ? textToUpperCase(t('Clients abonements'))
    : textToUpperCase(t(RoutesTitle[RoutesType.clientAbonements]));

  const path = (abonement: ClientAbonementModel) =>
    tariffModelGroupId
      ? `/${Routes.app}/${Routes.tariffModels}/${tariffModelId}/${Routes.tariffModelGroup}/${tariffModelGroupId}/${Routes.clientAbonements}/${abonement?.uuid}`
      : tariffModelId
      ? `/${Routes.app}/${Routes.tariffModels}/${tariffModelId}/${Routes.clientAbonements}/${abonement?.uuid}`
      : companyId
      ? `/${Routes.app}/${Routes.clientAbonements}/${abonement?.uuid}/${Routes.clients}/${abonement?.client?.uuid}/${Routes.companies}/${defaultCompanyUuid}`
      : `/${Routes.app}/${Routes.clients}/${clientId}/${Routes.clientAbonements}/${abonement?.uuid}`;

  const routes = useBreadcrumb(
    tariffModelGroupId
      ? [
          {
            path: `/${Routes.tariffModels}`,
            breadcrumbName: 'Tariff models',
          },
          {
            path: `/${Routes.tariffModels}/${tariffModelId}`,
            breadcrumbName: tariffModel
              ? tariffModel?.abon_title
              : 'Show tariff model',
          },
          {
            path: `/${Routes.tariffModels}/${tariffModelId}/${Routes.tariffModelGroup}`,
            breadcrumbName: 'Tariff model groups',
          },
          {
            path: `/${Routes.tariffModels}/${tariffModelId}/${Routes.tariffModelGroup}/${tariffModelGroupId}`,
            breadcrumbName: 'Tariff model group',
          },
          {
            path: `/${Routes.clientAbonements}`,
            breadcrumbName: 'Clients abonements',
          },
        ]
      : tariffModelId
      ? [
          {
            path: `/${Routes.tariffModels}`,
            breadcrumbName: 'Tariff models',
          },
          {
            path: `/${Routes.tariffModels}/${tariffModelId}`,
            breadcrumbName: tariffModel
              ? tariffModel?.abon_title
              : 'Show tariff model',
          },
          {
            path: `/${Routes.clientAbonements}`,
            breadcrumbName: 'Clients abonements',
          },
        ]
      : companyId
      ? [
          {
            path: `/${Routes.clientAbonements}/${Routes.companies}/${defaultCompanyUuid}`,
            breadcrumbName: 'Clients abonements',
          },
        ]
      : [
          {
            path: `/${Routes.clients}`,
            breadcrumbName: 'Counterparties',
          },
          {
            path: `/${Routes.clients}/${clientId}`,
            breadcrumbName: location?.state?.clientFullName || 'Show Client',
          },
          {
            path: `/${Routes.clientAbonements}`,
            breadcrumbName: 'Client abonements',
          },
        ],
  );

  const navigateGoBack = useCallback(() => {
    navigate(
      tariffModelGroupId
        ? `/${Routes.app}/${Routes.tariffModels}/${tariffModelId}/${Routes.tariffModelGroup}/${tariffModelGroupId}`
        : tariffModelId
        ? `/${Routes.app}/${Routes.tariffModels}/${tariffModelId}`
        : companyId
        ? `/${Routes.app}/${Routes.companies}/${defaultCompanyUuid}`
        : `/${Routes.app}/${Routes.clients}/${clientId}`,
    );
  }, [
    clientId,
    companyId,
    defaultCompanyUuid,
    navigate,
    tariffModelGroupId,
    tariffModelId,
  ]);

  const disabledField = tariffModelGroupId
    ? 'group'
    : tariffModelId
    ? 'abonements'
    : companyId
    ? undefined
    : 'clients';

  return {
    abonementList,
    loading,
    limit,
    total,
    keywords,
    page,

    client,
    tariffModel,
    tariffModelGroup,

    title,
    routes,
    disabledField,
    path,

    handleRefreshClientAbonements,
    handleDeleteClientAbonements,
    handleSearchClientAbonements,
    navigateGoBack,
  };
}
