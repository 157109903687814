import {Record, List} from 'immutable';
import {ListModel, ListProps} from './List';
import {UnreadModel, IUnreadProps} from './Unread';
import {INotificationProps, Notification} from './Notification';

export interface NotificationListProps extends ListProps, IUnreadProps {
  notifications: INotificationProps[];
}

export class NotificationList extends Record({
  notifications: List(),
  ...ListModel.toJS(),
  ...UnreadModel.toJS(),
}) {
  /**
   * @constructor
   * @param {{clients:[]}} param0
   */
  constructor({notifications = [], unread, ...props}: NotificationListProps) {
    const options: any = {};
    if (Array.isArray(notifications)) {
      options.clients = List(
        notifications.map((notification) => new Notification(notification)),
      );
    }

    super({
      ...props,
      ...options,
    });
  }
}
