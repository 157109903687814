import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {HeaderText} from '../../Show';
import {useLogin, useApproveEntry} from '../../../../hooks';
import Lottie from 'lottie-react';
import {LoginFormMapper} from '../../../../struture';
import {LoginForm} from '../../Login';
import {AuthLayout, FullWidthSpace} from '../../Show';
import {ReSendButton} from '../../Buttons';
import WaitingInAQueue from '../../../assets/lottie/waiting-in-a-queue.json';
import {useLocation} from 'react-router';

export default withTranslation()(ApproveEnterPage);

export interface IApproveEnterPageProps extends WithTranslation {}

function ApproveEnterPage({t}: IApproveEnterPageProps): JSX.Element {
  const location = useLocation();

  const {
    isShowLoginForm,
    disabled,
    identifier,
    seconds,

    handleCheckStatus,
    resetEmployeeSignupData,
  } = useApproveEntry({
    loadingOnInit: location?.state?.loadingOnInit,
  });

  const {handleLogin, notifyError} = useLogin();

  return (
    <AuthLayout>
      <FullWidthSpace direction="vertical" size="middle">
        <HeaderText
          title={t('Approve enter')}
          description={`${t(
            'Your request for registration as an Employee of the Company is under consideration by the Administrator. As soon as it is confirmed, you will receive a notification about it and can start working.',
          )} ${identifier}`}
        />
        <Lottie
          // autoPlay
          // style={{
          //   width: 1000, //SCREEN_WIDTH - 20,
          //   marginBottom: 20,
          // }}
          // source={require('../../../../assets/lottie/waiting-in-a-queue.json')}
          animationData={WaitingInAQueue}
        />
        <ReSendButton
          disabled={disabled}
          seconds={seconds}
          onClick={handleCheckStatus}
          title={t('Check status')}
        />
        {isShowLoginForm ? (
          <LoginForm
            notifyError={notifyError}
            showNotify={false}
            notifyFormName={t('Authorization')}
            onSuccess={async (data) => {
              await handleLogin(data);
              await resetEmployeeSignupData();
            }}
            login={LoginFormMapper.toLoginFormDTO(
              LoginFormMapper.toLoginDTO({
                identifier: location?.state?.identifier || identifier || '',
                password: '',
              }),
            )}
          />
        ) : null}
      </FullWidthSpace>
    </AuthLayout>
  );
}
