import {List} from 'immutable';
import {
  ClientAbonementDTO,
  ClientAbonementFormDTO,
  IClientAbonementDTOProps,
  ClientAbonementModel,
  ClientAbonementListModel,
  ClientAbonementSubscriptionModel,
  ClientAbonementSubscriptionDTO,
  IClientAbonementSubscriptionDTOProps,
  ClientAbonementSubscriptionListModel,
  ClientAbonementSubscriptionFormDTO,
  ClientModel,
  AbonementModel,
  ClientRefundsFormDTO,
  ClientAbonementLatestStatusDTO,
  IClientAbonementLatestStatusDTOProps,
  ClientRefundsDTO,
  IClientRefundsDTOProps,
  AbonementGroupModel,
} from '../internal';
import {listToArray} from '../../services/helpers';
import {WithoutRecursion} from '../type';

export interface IClientAbonementListDTOs {
  abonements: ClientAbonementDTO[];
  total: number;
}
export interface ICompanyClientAbonementListDTO
  extends IClientAbonementListDTOs {
  client: ClientModel;
}

export interface ICompanyAbonementClientAbonementListDTO
  extends IClientAbonementListDTOs {
  company_abonement: AbonementModel;
}

export interface ITariffModelGroupClientAbonementListDTO
  extends IClientAbonementListDTOs {
  company_abonement_group: AbonementGroupModel;
}

export type ClientAbonementListDTO<
  T extends AbonementModel | AbonementGroupModel | ClientModel | unknown,
> = T extends AbonementModel
  ? ICompanyAbonementClientAbonementListDTO
  : T extends ClientModel
  ? ICompanyClientAbonementListDTO
  : T extends AbonementGroupModel
  ? ITariffModelGroupClientAbonementListDTO
  : IClientAbonementListDTOs;

export interface IClientAbonementSubscriptionListDTO {
  subscriptions: ClientAbonementSubscriptionDTO[];
  total: number;
}

export interface IClientAbonementDTO {
  abonement: ClientAbonementDTO;
  latest_status: ClientAbonementLatestStatusDTO;
}

export interface IClientAbonementModel {
  abonement: ClientAbonementModel;
  latest_status: ClientAbonementLatestStatusDTO;
}

export class ClientAbonementMapper {
  public static toClientAbonementDTO(
    abonement: ClientAbonementModel | IClientAbonementDTOProps,
    latest_status?: IClientAbonementLatestStatusDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): IClientAbonementDTO {
    return {
      abonement: new ClientAbonementDTO(abonement, [
        WithoutRecursion.abonement,
        ...withoutRecursion,
      ]),
      latest_status: ClientAbonementMapper.toClientAbonementLatestStatusDTO(
        (latest_status || {}) as IClientAbonementLatestStatusDTOProps,
      ),
    };
  }

  public static toClientAbonementListDTO<
    T extends AbonementModel | ClientModel | AbonementGroupModel | unknown,
  >(
    abonements: List<ClientAbonementModel> | IClientAbonementDTOProps[],
    total: number,
    payload?: T,
    withoutRecursion: WithoutRecursion[] = [],
  ): ClientAbonementListDTO<T> {
    const abonementList = List.isList(abonements)
      ? listToArray<ClientAbonementModel>(abonements)
      : abonements;

    const data: any = {
      abonements: abonementList?.map(
        (abonement) =>
          ClientAbonementMapper.toClientAbonementDTO(
            abonement,
            {} as IClientAbonementLatestStatusDTOProps,
            [WithoutRecursion.abonement, ...withoutRecursion],
          ).abonement,
      ),

      total,
    };

    return payload instanceof AbonementModel
      ? {...data, company_abonement: payload}
      : payload instanceof ClientModel
      ? {...data, client: payload}
      : payload instanceof AbonementGroupModel
      ? {...data, company_abonement_group: payload}
      : data;
  }

  public static toClientAbonementModel(
    abonementDTO: ClientAbonementDTO,
    latest_status?: IClientAbonementLatestStatusDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): IClientAbonementModel {
    return {
      abonement: new ClientAbonementModel(abonementDTO, [
        WithoutRecursion.abonement,
        ...withoutRecursion,
      ]),
      latest_status: ClientAbonementMapper.toClientAbonementLatestStatusDTO(
        (latest_status || {}) as IClientAbonementLatestStatusDTOProps,
      ),
    };
  }

  public static toClientAbonementListModel<
    T extends AbonementModel | ClientModel | unknown,
  >(
    abonementDTOs: ClientAbonementDTO[],
    total: number,
    payload?: T,
    withoutRecursion: WithoutRecursion[] = [],
  ): ClientAbonementListModel<T> {
    return new ClientAbonementListModel<T>(
      {abonements: abonementDTOs, payload, total},
      [WithoutRecursion.abonement, ...withoutRecursion],
    );
  }

  public static toClientAbonementFormDTO(
    abonementModel: ClientAbonementModel,
    editMode?: boolean,
  ): ClientAbonementFormDTO {
    return new ClientAbonementFormDTO(abonementModel, editMode);
  }

  public static toClientAbonementSubscriptionDTO(
    subscription:
      | ClientAbonementSubscriptionModel
      | IClientAbonementSubscriptionDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): ClientAbonementSubscriptionDTO {
    return new ClientAbonementSubscriptionDTO(subscription, [
      WithoutRecursion.abonement,
      ...withoutRecursion,
    ]);
  }

  public static toClientAbonementSubscriptionListDTO(
    subscriptions:
      | List<ClientAbonementSubscriptionModel>
      | IClientAbonementSubscriptionDTOProps[],
    total: number,
  ): IClientAbonementSubscriptionListDTO {
    const subscriptionsList = List.isList(subscriptions)
      ? listToArray<ClientAbonementSubscriptionModel>(subscriptions)
      : subscriptions;

    return {
      subscriptions: subscriptionsList?.map((subscription) =>
        ClientAbonementMapper.toClientAbonementSubscriptionDTO(subscription),
      ),
      total,
    };
  }

  public static toClientAbonementSubscriptionModel(
    subscriptionDTO: ClientAbonementSubscriptionDTO,
  ): ClientAbonementSubscriptionModel {
    return new ClientAbonementSubscriptionModel(subscriptionDTO);
  }

  public static toClientAbonementSubscriptionListModel(
    subscriptionDTOs: ClientAbonementSubscriptionDTO[],
    total: number,
  ): ClientAbonementSubscriptionListModel {
    return new ClientAbonementSubscriptionListModel({
      subscriptions: subscriptionDTOs,
      total,
    });
  }

  public static toClientAbonementSubscriptionFormDTO(
    subscriptionModel: ClientAbonementModel,
  ): ClientAbonementSubscriptionFormDTO {
    return new ClientAbonementSubscriptionFormDTO(subscriptionModel);
  }

  public static toClientRefundFormDTO(
    clientAbonementModel: ClientAbonementModel,
  ): ClientRefundsFormDTO {
    return new ClientRefundsFormDTO(clientAbonementModel);
  }

  public static toClientRefundDTO(
    refund: IClientRefundsDTOProps,
  ): ClientRefundsDTO {
    return new ClientRefundsDTO(refund);
  }

  public static toClientAbonementLatestStatusDTO(
    latestStatus: IClientAbonementLatestStatusDTOProps,
  ): ClientAbonementLatestStatusDTO {
    return new ClientAbonementLatestStatusDTO(latestStatus);
  }
}
