import React from 'react';
import {FileUploader} from '../../../components/lib/Layout/common';
import styled, {css} from 'styled-components';
import {
  fileUploadStructure,
  firstLetterToUppercase,
  getFirstLatter,
  ifElse,
} from '../../../services/helpers';

export interface ProductCategoryPicturesProps {
  cover: string | undefined;
  logo: string | undefined;
  onUploadCoverFile?: (file: File) => Promise<string | void>;
  onUploadLogoFile?: (file: File) => Promise<string | void>;
  title?: string;
  disabled?: boolean;
  coverHeight?: number;
}

const StyledContainer = styled.div<{$coverHeight: number}>`
  position: relative;
  width: 100%;
  height: 280px;
  perspective: 1000px;
  cursor: pointer;
  overflow: hidden;

  && {
    border-radius: 6px;
  }

  ${({$coverHeight}) =>
    $coverHeight &&
    css`
      height: ${$coverHeight + 80}px;
    `}
`;

const StyledLogoContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -50px;
  background-color: #e2e2e3;
  border-radius: 50%;
  z-index: 1;
`;

const StyledContent = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  backface-visibility: hidden;
  background-color: ${({theme}) => theme.colors.background};
`;

export function ProductCategoryPictures({
  cover,
  onUploadCoverFile,
  onUploadLogoFile,
  logo,
  title,
  disabled,
  coverHeight = 200,
}: ProductCategoryPicturesProps): React.JSX.Element {
  return (
    <StyledContainer $coverHeight={coverHeight}>
      <StyledContent>
        <FileUploader
          coverHeight={coverHeight}
          isCover
          disabled={disabled}
          onUpload={onUploadCoverFile}
          file={ifElse(!!cover, fileUploadStructure(cover as any), '')}
        />

        <StyledLogoContainer>
          <FileUploader
            title={getFirstLatter(firstLetterToUppercase(title || ''))}
            disabled={disabled}
            onUpload={onUploadLogoFile}
            file={ifElse(!!logo, fileUploadStructure(logo as any), '')}
          />
        </StyledLogoContainer>
      </StyledContent>
    </StyledContainer>
  );
}
