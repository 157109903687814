import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';

export default withTranslation()(BillOfLadingPage);

export interface IBillOfLadingPageProps extends WithTranslation {}

function BillOfLadingPage({}: IBillOfLadingPageProps): JSX.Element {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <p style={{fontSize: 30}}>Bill Of Lading Page</p>
    </div>
  );
}
