import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {InvoiceModel} from '../../struture';
import {REDUX_STATUS} from '../../services/types';
import {RootState} from '../reducers';
import {Map} from 'immutable';

export interface InvoiceListState {
  invoice: InvoiceModel | null;
  cachedInvoiceMap: Map<string, InvoiceModel>;
  loading: boolean;
  status: REDUX_STATUS;
}

export interface SetInvoiceAction {
  invoice: InvoiceModel;
}

export interface UpdateInvoiceAction {
  invoice: InvoiceModel;
}

const initialState: InvoiceListState = {
  invoice: null,
  cachedInvoiceMap: Map(),
  loading: true,
  status: REDUX_STATUS.IDLE,
};

export const invoiceListSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    setInvoice: (state: any, {payload}: PayloadAction<SetInvoiceAction>) => {
      const {invoice} = payload;
      const {cachedInvoiceMap}: InvoiceListState = state;

      if (invoice) {
        return {
          ...state,
          invoice,
          cachedInvoiceMap: cachedInvoiceMap.set(invoice?.uuid, invoice),
          status: REDUX_STATUS.SUCCEEDED,
          loading: false,
        };
      }
    },

    setInitialState: () => {
      return initialState;
    },

    updateInvoice: (
      state: any,
      {payload}: PayloadAction<UpdateInvoiceAction>,
    ) => {
      const {invoice} = payload;

      let {cachedInvoiceMap}: InvoiceListState = state;

      return {
        ...state,
        invoice,
        cachedInvoiceMap: cachedInvoiceMap.update(invoice?.uuid, () => invoice),
        loading: false,
        status: REDUX_STATUS.SUCCEEDED,
      };
    },
  },
});

export const {setInvoice, setInitialState, updateInvoice} =
  invoiceListSlice.actions;

export const selectInvoice = ({invoiceReducer}: RootState) => invoiceReducer;

export default invoiceListSlice.reducer;
