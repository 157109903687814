import {Record, List} from 'immutable';
import {ListModel, ListProps} from './List';
import Group, {GroupProps} from './Group';

export interface IGroupSessionsListProps extends ListProps {
  group_sessions: GroupProps[];
}

/**
 * @class GroupSessionsList
 * @classdesc GroupSessions list model.
 * @extends {Record}
 * @property {List} group_sessions - list of sessions
 */
export default class GroupSessionsList extends Record({
  group_sessions: List(),
  ...ListModel.toJS(),
}) {
  /**
   * @constructor
   * @param {{group_sessions:[]}} param0
   */
  constructor({group_sessions = [], ...props}: IGroupSessionsListProps) {
    super({
      group_sessions: List(group_sessions.map((session) => new Group(session))),
      ...props,
    });
  }
}
