import * as React from 'react';
import {List} from 'immutable';
import {ApiError, IEntityProps, IListSearchProps} from '../services/types';
import {
  IMaterialAndExpenseListDTO,
  MaterialAndExpenseMapper,
  MaterialAndExpenseModel,
  MaterialAndExpenseListModel,
} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {getMaterialAndExpenseList} from '../services/api/materialAndExpense';
import {useDropdownAlert} from '../contex';
import {useTranslation} from 'react-i18next';

export interface IUseMaterialAndExpenseListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  scheduleUuid: string;
}

export interface IUseMaterialAndExpenseListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<MaterialAndExpenseModel> | null;
  refresh: (
    value: Partial<IUseMaterialAndExpenseListProps>,
  ) => Promise<MaterialAndExpenseListModel | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  scheduleUuid: string;

  sumTotal: string;
}

export function useMaterialAndExpenseList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    scheduleUuid,
  }: IUseMaterialAndExpenseListProps = {} as IUseMaterialAndExpenseListProps,
): IUseMaterialAndExpenseListReturnType {
  const {cancellablePromise} = useCancellablePromise();
  const {alert} = useDropdownAlert();
  const {t} = useTranslation();

  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    sumTotal: listSumTotal,
    keywords: listKeywords,
    scheduleUuid: listCompanyUuid,
    handleUpdate,
  } = useStateReducer<Omit<IUseMaterialAndExpenseListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    scheduleUuid,
    sumTotal: '0.00',
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      scheduleUuid = listCompanyUuid,
    }: Partial<IUseMaterialAndExpenseListProps> = {}): Promise<MaterialAndExpenseListModel | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          scheduleUuid,
        });

        const {expenses, total, sum_total} =
          await cancellablePromise<IMaterialAndExpenseListDTO>(
            getMaterialAndExpenseList({
              scheduleUuid,
              limit,
              offset,
              keywords,
            }),
          );

        const materialAndExpenseListModel =
          MaterialAndExpenseMapper.toMaterialAndExpenseListModel(
            expenses,
            total,
            sum_total,
          );

        handleUpdate({
          entityList: materialAndExpenseListModel?.schedule_expenses,
          total: materialAndExpenseListModel?.total,
          loading: false,
          sumTotal: sum_total,
        });

        return materialAndExpenseListModel;
      } catch (e: any) {
        alert(
          'error',
          t('Materials and expenses'),
          `${t('An error occurred during get material and expense list')} : ${
            e?.message
          }`,
        );

        handleUpdate({
          error,
          loading: false,
        });
      }
    },
    [
      alert,
      cancellablePromise,
      error,
      handleUpdate,
      listCompanyUuid,
      listKeywords,
      listLimit,
      listOffset,
      t,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && scheduleUuid) {
      (async () => {
        await refresh({scheduleUuid});
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, scheduleUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    scheduleUuid: listCompanyUuid,
    sumTotal: listSumTotal,
  };
}
