import * as React from 'react';
import {List} from 'immutable';
import {ApiError, IEntityProps, IListSearchProps} from '../services/types';
import {
  IScheduleCalendarListDTO,
  ScheduleCalendarMapper,
  ScheduleCalendarModel,
  ScheduleCalendarListModel,
  ScheduleCalendarParentDTO,
} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {getRelatedScheduleList} from '../services/api/orders';
import {useDropdownAlert} from '../contex';
import {useTranslation} from 'react-i18next';

export interface IUseRelatedOrderListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  scheduleUuid: string;
}

export interface IUseRelatedOrderListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<ScheduleCalendarModel> | null;
  refresh: (
    value: Partial<IUseRelatedOrderListProps>,
  ) => Promise<ScheduleCalendarListModel | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  scheduleUuid: string;
  parent: ScheduleCalendarParentDTO | null;
}

export function useRelatedOrderList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    scheduleUuid,
  }: IUseRelatedOrderListProps = {} as IUseRelatedOrderListProps,
): IUseRelatedOrderListReturnType {
  const {cancellablePromise} = useCancellablePromise();
  const {alert} = useDropdownAlert();
  const {t} = useTranslation();

  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    scheduleUuid: listCompanyUuid,
    handleUpdate,
    parent,
  } = useStateReducer<Omit<IUseRelatedOrderListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    scheduleUuid,
    parent: null,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      scheduleUuid = listCompanyUuid,
    }: Partial<IUseRelatedOrderListProps> = {}): Promise<ScheduleCalendarListModel | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          scheduleUuid,
        });

        const {schedules, total, parent} = await cancellablePromise<
          IScheduleCalendarListDTO & {parent: ScheduleCalendarParentDTO}
        >(
          getRelatedScheduleList({
            scheduleUuid,
            limit,
            offset,
            keywords,
          }),
        );

        const relatedOrderListModel =
          ScheduleCalendarMapper.toScheduleCalendarListModel(schedules, total);

        handleUpdate({
          entityList: relatedOrderListModel?.schedules,
          total: relatedOrderListModel?.total,
          loading: false,
          parent,
        });

        return relatedOrderListModel;
      } catch (e: any) {
        alert(
          'error',
          t('Related order'),
          `${t('An error occurred during get related order list')} : ${
            e?.message
          }`,
        );

        handleUpdate({
          error,
          loading: false,
        });
      }
    },
    [
      alert,
      cancellablePromise,
      error,
      handleUpdate,
      listCompanyUuid,
      listKeywords,
      listLimit,
      listOffset,
      t,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && scheduleUuid) {
      (async () => {
        await refresh({scheduleUuid});
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, scheduleUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    scheduleUuid: listCompanyUuid,
    parent,
  };
}
