import React from 'react';
import {ExecuteOnSet} from '../../../struture';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {MdPayment, MdOutlineComment} from 'react-icons/md';
import {StyledTitle} from '../../../components/lib/Styled';
import {firstLetterToUppercase} from '../../../services/helpers';

export interface IStatusExecuteOnSetViewProps {
  executeOnSet: ExecuteOnSet;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
`;

export function StatusExecuteOnSetView({
  executeOnSet,
}: IStatusExecuteOnSetViewProps): JSX.Element {
  const {t} = useTranslation();

  return (
    <StyledContainer>
      {executeOnSet === ExecuteOnSet.Payment ? (
        <MdPayment size={18} />
      ) : (
        <MdOutlineComment size={18} />
      )}
      <StyledTitle>{firstLetterToUppercase(t(executeOnSet))}</StyledTitle>
    </StyledContainer>
  );
}
