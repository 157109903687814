import {Dispatch} from 'redux';
import {SERVICE_EMPLOYEE_LIST} from '../constants';
import {EmployeeListModel, EmployeeModel} from '../../struture';
import {ApiError} from '../../services/types';

export interface ISetServiceEmployeeListParam {
  type: SERVICE_EMPLOYEE_LIST;
  serviceEmployeeList: EmployeeListModel | null;
  serviceUuid: string;
  keywords?: string;
  total?: number;
  page?: number;
}

export interface IAddServiceEmployeeToListParam {
  type: SERVICE_EMPLOYEE_LIST;
  employeeService: EmployeeModel;
  serviceUuid: string;
}

export interface IAddEmployeeToServiceParam {
  type: SERVICE_EMPLOYEE_LIST;
  employeeService: EmployeeModel;
  serviceUuid: string;
}

export interface IUpdateServiceEmployeeFromListParam {
  type: SERVICE_EMPLOYEE_LIST;
  employeeService: EmployeeModel;
  serviceUuid: string;
}

export interface IUpdateEmployeeFromServiceParam {
  type: SERVICE_EMPLOYEE_LIST;
  employeeService: EmployeeModel;
  serviceUuid: string;
}

export interface IDeleteServiceEmployeeFromListParam {
  type: SERVICE_EMPLOYEE_LIST;
  employeeService: EmployeeModel;
  serviceUuid: string;
}

export interface IDeleteEmployeeFromListServiceParam {
  type: SERVICE_EMPLOYEE_LIST;
  serviceEmployeeUuid: string;

  serviceUuid: string;
}

export interface IErrorServiceEmployeeListParam {
  type: SERVICE_EMPLOYEE_LIST;
  error: ApiError | undefined;
}

export function setServiceEmployeeList(
  serviceEmployeeList: EmployeeListModel | null,
  serviceUuid: string,
  keywords?: string,
  total?: number,
  page?: number,
): (dispatch: Dispatch) => Promise<ISetServiceEmployeeListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: SERVICE_EMPLOYEE_LIST.SET_SERVICE_EMPLOYEE_LIST,
      serviceEmployeeList,
      serviceUuid,
      keywords,
      total,
      page,
    });
  };
}

export function loadMoreServiceEmployeeList(
  serviceEmployeeList: EmployeeListModel,
  serviceUuid: string,
): (dispatch: Dispatch) => Promise<ISetServiceEmployeeListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: SERVICE_EMPLOYEE_LIST.LOAD_MORE_SERVICE_EMPLOYEE_LIST,
      serviceEmployeeList,
      serviceUuid,
    });
  };
}

export function addEmployeeToListService(
  employeeService: EmployeeModel,
  serviceUuid: string,
): (dispatch: Dispatch) => Promise<IAddServiceEmployeeToListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: SERVICE_EMPLOYEE_LIST.ADD_SERVICE_EMPLOYEE,
      employeeService,
      serviceUuid,
    });
  };
}

export function addEmployeeToServiceList(
  employeeService: EmployeeModel,
): (
  dispatch: Dispatch,
) => Promise<Omit<IAddEmployeeToServiceParam, 'serviceUuid'>> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: SERVICE_EMPLOYEE_LIST.ADD_EMPLOYEE_TO_SERVICE,
      employeeService,
    });
  };
}

export function updateServiceEmployeeFromList(
  employeeService: EmployeeModel,
  serviceUuid: string,
): (dispatch: Dispatch) => Promise<IUpdateServiceEmployeeFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: SERVICE_EMPLOYEE_LIST.UPDATE_SERVICE_EMPLOYEE,
      employeeService,
      serviceUuid,
    });
  };
}

export function updateEmployeeFromServiceList(
  employeeService: EmployeeModel,
): (
  dispatch: Dispatch,
) => Promise<Omit<IUpdateEmployeeFromServiceParam, 'serviceUuid'>> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: SERVICE_EMPLOYEE_LIST.UPDATE_EMPLOYEE_FOR_SERVICE,
      employeeService,
    });
  };
}

export function deleteServiceEmployeeFromList(
  serviceEmployeeUuid: string,
  serviceUuid: string,
): (dispatch: Dispatch) => Promise<IDeleteEmployeeFromListServiceParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: SERVICE_EMPLOYEE_LIST.DELETE_EMPLOYEE_FROM_SERVICE,
      serviceEmployeeUuid,
      serviceUuid,
    });
  };
}

export function deleteEmployeeFromServiceList(
  serviceEmployeeUuid: string,
): (
  dispatch: Dispatch,
) => Promise<Omit<IDeleteEmployeeFromListServiceParam, 'serviceUuid'>> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: SERVICE_EMPLOYEE_LIST.DELETE_SERVICE_EMPLOYEE,
      serviceEmployeeUuid,
    });
  };
}

export function errorServiceEmployeeInList(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorServiceEmployeeListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: SERVICE_EMPLOYEE_LIST.ERROR_SERVICE_EMPLOYEE_LIST,
      error,
    });
  };
}
