import * as React from 'react';
import {LoginFormDTO, LoginFormMapper} from '../struture';
import {login} from '../services/api/user';
import {ErrorsStatus, Routes} from '../services/types';
import {useTranslation} from 'react-i18next';
import {useStoredAuthData} from './useStoredAuthData';
import {languageCode} from '../i18n/i18n';
import {useLocation, useNavigate} from 'react-router';
import {useDropdownAlert} from '../contex';
import {StatusError} from '../components/lib/Errors';

export interface IUseLoginReturnType {
  notifyError: (apiError: any) => void;
  handleLogin: (value: LoginFormDTO) => Promise<void>;
}

export function useLogin(): IUseLoginReturnType {
  const {t, i18n} = useTranslation();
  const {authRedirect} = useStoredAuthData();
  const location = useLocation();
  const navigate = useNavigate();
  const {alert} = useDropdownAlert();

  const notifyError = React.useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Entry'),
        `${t('An error occurred during entry')} : ${apiError?.message}`,
      );
    },
    [alert, t],
  );

  const handleLogin = React.useCallback(
    async (value: LoginFormDTO): Promise<void> => {
      try {
        const loginDTO = LoginFormMapper.toLoginDTO(value);

        const authDataDTO = await login({
          ...loginDTO,
        });

        await authRedirect(authDataDTO, {
          from: location.pathname,
          loadingOnInit: true,
        });

        if (authDataDTO?.user?.lang) {
          (i18n as any).changeLanguage(
            languageCode[authDataDTO?.user?.lang] || languageCode.uk,
          );
        }

        alert('success', t('Entry'), t('Success entry'));

        // if (authDataDTO?.required_redirect && !eq(authDataDTO?.user?.status, 1)) {
        //   notify.notifyMessage(authDataDTO?.user?.status_message);
        //   setTimeout(() => {
        //     window.location.href = authDataDTO?.required_redirect;
        //   }, 1500);
        //   return;
        // }
      } catch (error: any) {
        throw new StatusError(
          error?.response?.message || error?.message,
          error?.response?.status || error?.status,
        );
      }
    },
    [alert, authRedirect, i18n, location.pathname, t],
  );

  return {
    notifyError,
    handleLogin,
  };
}
