import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {SubscriptionExpiredTabPanel} from '../Show';
import {AuthLayout, FullWidthSpace, HeaderText} from '../../Show';
import {StyledTitle} from '../../../../components/lib/Styled';
import {RightOutlined} from '@ant-design/icons';
import {Button} from '../../../../components/lib/DataDisplay';
import styled, {useTheme} from 'styled-components';
import {useNavigate} from 'react-router';
import {Routes} from '../../../../services/types';
import {useStoredProfile} from '../../../../hooks';
import {OverviewLayout} from '../../../../components/lib/Layout';

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

const StyledFullWidthSpace = styled(FullWidthSpace)`
  display: flex;
  align-items: center;
`;

export function SubscriptionExpiredPage(): JSX.Element {
  const {t} = useTranslation();
  const theme: any = useTheme();
  const navigate = useNavigate();
  const {profile} = useStoredProfile();

  const navigateToSubscriptionPage = useCallback(async () => {
    navigate(`/${Routes.app}/${Routes.subscription}`);
  }, [navigate]);

  return (
    <SubscriptionExpiredTabPanel>
      {({tabs}) => (
        <OverviewLayout
          autoLayout
          loading={false}
          header={
            <StyledContainer>
              <AuthLayout>
                <StyledFullWidthSpace direction="vertical" size="middle">
                  <HeaderText
                    withoutLogo
                    title={t('Current subscription is expired')}
                    color={theme.colors.error}
                  />
                  <StyledTitle color={theme.colors.error}>
                    {t(
                      'The license agreement has expired. Access to data will be restricted in the near future. To renew an annual subscription, follow the link, register and pay for a new subscription',
                    )}
                    .&nbsp;
                    {profile?.isAdmin
                      ? ''
                      : t('Contact the admin and fix the situation')}
                  </StyledTitle>
                  <Button type="link" onClick={navigateToSubscriptionPage}>
                    <StyledTitle color={theme.link}>
                      {t('Manage subscriptions')}&nbsp;&nbsp;
                      <RightOutlined color={theme.link} />
                    </StyledTitle>
                  </Button>
                </StyledFullWidthSpace>
              </AuthLayout>
            </StyledContainer>
          }
          data={null}
          tabs={tabs}
          routes={[]}
        />
      )}
    </SubscriptionExpiredTabPanel>
  );
}
