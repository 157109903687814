import {SystemRuleDTO, ISystemRuleDTOProps} from '../internal';

export interface IRuleLimitationDTOProps {
  system_rule_limit: number;
  system_rule: ISystemRuleDTOProps;
}

export class RuleLimitationDTO {
  public system_rule_limit: number;

  public system_rule: SystemRuleDTO;

  constructor(props: IRuleLimitationDTOProps) {
    this.system_rule_limit = props?.system_rule_limit || 0;
    this.system_rule = new SystemRuleDTO(props?.system_rule);
  }
}
