import {Map} from 'immutable';
import {DEPARTMENT} from '../constants';
import {REDUX_STATUS, ApiError, APP_STATE} from '../types';
import {DepartmentModel} from '../../struture';

interface IDepartmentListState {
  department: DepartmentModel | null;
  cachedDepartmentList: Map<string, DepartmentModel>;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
}

interface SetAction extends Pick<IDepartmentListState, 'department'> {
  type: DEPARTMENT.SET_DEPARTMENT;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface UpdateDepartment {
  type: DEPARTMENT.UPDATE_DEPARTMENT;
  department: DepartmentModel;
}

interface LoadingAction {
  type: DEPARTMENT.LOADING_DEPARTMENT;
}

interface ResetAction {
  type: DEPARTMENT.RESET_DEPARTMENT;
}

interface ErrorAction extends Pick<IDepartmentListState, 'error'> {
  type: DEPARTMENT.ERROR_DEPARTMENT;
}

interface Handlers {
  [DEPARTMENT.SET_DEPARTMENT]: (
    state: IDepartmentListState,
    action: SetAction,
  ) => IDepartmentListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IDepartmentListState,
    action: SetInitialStateAction,
  ) => IDepartmentListState;

  [DEPARTMENT.RESET_DEPARTMENT]: (
    state: IDepartmentListState,
    action: ResetAction,
  ) => IDepartmentListState;

  [DEPARTMENT.UPDATE_DEPARTMENT]: (
    state: IDepartmentListState,
    action: UpdateDepartment,
  ) => IDepartmentListState;

  [DEPARTMENT.ERROR_DEPARTMENT]: (
    state: IDepartmentListState,
    value: ErrorAction,
  ) => IDepartmentListState;

  [DEPARTMENT.LOADING_DEPARTMENT]: (
    state: IDepartmentListState,
    value?: LoadingAction,
  ) => IDepartmentListState;
  DEFAULT: (state: IDepartmentListState) => IDepartmentListState;
}

const initState: IDepartmentListState = {
  department: null,
  cachedDepartmentList: Map(),
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
};

const handlers: Handlers = {
  [DEPARTMENT.SET_DEPARTMENT]: (state, {department}) => ({
    ...state,
    ...{
      department,
      cachedDepartmentList: department
        ? state.cachedDepartmentList.set(department?.uuid, department)
        : state.cachedDepartmentList,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [DEPARTMENT.RESET_DEPARTMENT]: (state) => ({
    ...state,
    ...{
      status: REDUX_STATUS.IDLE,
    },
  }),

  [DEPARTMENT.UPDATE_DEPARTMENT]: (state, {department}) => ({
    ...state,
    ...{
      department,
      cachedDepartmentList: state.cachedDepartmentList.update(
        department?.uuid,
        () => department,
      ),
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [DEPARTMENT.ERROR_DEPARTMENT]: (
    state: IDepartmentListState,
    {error}: ErrorAction,
  ) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [DEPARTMENT.LOADING_DEPARTMENT]: (state: IDepartmentListState) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IDepartmentListState) => state,
};

export default function Department(
  state: any = initState,
  action: any,
): IDepartmentListState {
  const handler = handlers[action.type as DEPARTMENT] || handlers.DEFAULT;
  return handler(state, action);
}
