import * as React from 'react';

export default function useOutsideClickElements(
  callback: any,
  ignoreElementClassNames: string[] = [],
): void {
  React.useEffect(() => {
    const handleClickOutside = (evt: Event) => {
      const target = evt?.target;

      if (
        target instanceof HTMLElement &&
        ignoreElementClassNames.some((item) => {
          return target.closest(`.${item}`);
        })
      ) {
        return;
      }

      evt.stopPropagation();

      callback();
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}
