import * as React from 'react';
import {getStoreById} from '../services/api/store';
import {StoreModel, StoreMapper, StoreDTO} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {IEntityProps} from '../services/types';
import {useDropdownAlert} from '../contex';
import {useTranslation} from 'react-i18next';

export interface IUseStoreProps extends IEntityProps {
  storeUuid: string;
}

export interface IUseStoreReturnType {
  error: null | any;
  loading: boolean;
  entity: StoreModel | null;
  refresh: (value: Partial<IUseStoreProps>) => Promise<StoreModel | void>;
  storeUuid: string;
}

export function useStore(
  {loadOnInit = true, storeUuid}: IUseStoreProps = {} as IUseStoreProps,
): IUseStoreReturnType {
  const {alert} = useDropdownAlert();
  const {t} = useTranslation();
  const {cancellablePromise, didCancel} = useCancellablePromise();
  const {
    entity,
    loading,
    error,
    handleUpdate,
    storeUuid: entityUuid,
  } = useStateReducer<Omit<IUseStoreReturnType, 'refresh'>>({
    entity: null,
    storeUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading,
      storeUuid = entityUuid,
    }: Partial<IUseStoreProps> = {}): Promise<StoreModel | void> => {
      try {
        handleUpdate({loading: showLoading, error: null, storeUuid});

        const store = await cancellablePromise<StoreDTO>(
          getStoreById(storeUuid),
        );

        const storeModel = StoreMapper.toStoreModel(store);

        if (!didCancel.current) {
          handleUpdate({
            entity: storeModel,
            loading: false,
          });
          return storeModel;
        }
      } catch (err: any) {
        if (!didCancel.current) {
          handleUpdate({
            error: err,
            loading: false,
          });
        }
        alert(
          'error',
          t('Store'),
          `${t('An error occurred during get store')} : ${err?.message}`,
        );
      }
    },

    [alert, cancellablePromise, didCancel, entityUuid, handleUpdate, t],
  );

  React.useEffect(() => {
    if (loadOnInit && storeUuid) {
      (async () => {
        await refresh({storeUuid});
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, storeUuid]);

  return {
    error,
    loading,
    refresh,
    entity,
    storeUuid,
  };
}
