import * as React from 'react';
import {List} from 'immutable';
import {
  useEmployeeAttachedServiceList,
  IUseEmployeeAttachedServiceListProps,
  IUseEmployeeAttachedServiceListReturnType,
} from './useEmployeeAttachedServiceList';
import {ServiceListModel, ServiceModel} from '../struture';
import {
  setEmployeeAttachedServiceList as storeSetEmployeeAttachedServiceList,
  loadMoreEmployeeAttachedServiceList as storeLoadMoreEmployeeAttachedServiceList,
} from '../store/actions';
import {useSelector, useDispatch} from 'react-redux';
import {RootState} from '../store/reducers';
import {REDUX_STATUS} from '../services/types';
import {useCallback, useEffect, useState} from 'react';

export interface IUseStateEmployeeAttachedServiceListProps
  extends IUseEmployeeAttachedServiceListProps {
  isOrderScreen?: boolean;
}

export interface IRefreshParams {
  limit?: number;
  showLoading?: boolean;
  employeeUuid?: string;
  keywords?: string;
}

export interface IUseStateEmployeeAttachedServiceListReturnType
  extends Omit<IUseEmployeeAttachedServiceListReturnType, 'entityList'> {
  employeeAttachedServiceList: List<ServiceModel> | null;
  handleSearchEmployeeAttachedServices: (
    value: IRefreshParams,
  ) => Promise<void>;
  handleLoadMoreEmployeeAttachedServices: () => Promise<void>;
  handleRefreshEmployeeAttachedServices: (
    value: IRefreshParams,
  ) => Promise<ServiceListModel | void>;
  handleRefreshStoreEmployeeAttachedServices: (
    value: IRefreshParams,
  ) => Promise<any>;
  status: REDUX_STATUS;
  loadingMore: boolean;
}

export function useStoredEmployeeAttachedServiceList(
  {
    employeeUuid,
    loadOnInit,
    isOrderScreen = false,
    ...rest
  }: IUseStateEmployeeAttachedServiceListProps = {} as IUseStateEmployeeAttachedServiceListProps,
): IUseStateEmployeeAttachedServiceListReturnType {
  const [loadingMore, setLoadingMore] = useState(false);
  const [employeeAttachedServiceList, setEmployeeAttachedServiceList] =
    useState<List<ServiceModel> | null>(null);
  const [isStarLoading, setIsStartLoading] = React.useState<boolean>(false);

  const {
    status: storedEmployeeAttachedServiceListStatus,
    cachedEmployeeAttachedServiceList,
    total: storeTotal,
    ...storedEmployeeAttachedServiceListParams
  } = useSelector(
    ({employeeAttachedServiceList}: RootState) => employeeAttachedServiceList,
  );

  const dispatch = useDispatch<any>();

  const [localTotal, setLocalTotal] = useState(storeTotal);

  const {
    entityList,
    offset,
    limit,
    refresh,
    total,
    ...employeeAttachedServicesParams
  } = useEmployeeAttachedServiceList({
    employeeUuid,
    loadOnInit: loadOnInit && isStarLoading,
    ...rest,
  });

  React.useEffect(() => {
    if (cachedEmployeeAttachedServiceList?.size > 0) {
      const employeeAttachedServiceList =
        cachedEmployeeAttachedServiceList.get(employeeUuid);

      if (employeeAttachedServiceList) {
        setIsStartLoading(false);
        setEmployeeAttachedServiceList(employeeAttachedServiceList);
        setLocalTotal(storeTotal);
      } else {
        setIsStartLoading(true);
      }
    }
    if (
      cachedEmployeeAttachedServiceList?.size === 0 &&
      !employeeAttachedServiceList
    ) {
      setIsStartLoading(true);
    }
  }, [
    dispatch,
    cachedEmployeeAttachedServiceList,
    employeeUuid,
    storeTotal,
    employeeAttachedServiceList,
  ]);

  useEffect(() => {
    if (List.isList(entityList) && isStarLoading && !isOrderScreen) {
      dispatch(
        storeSetEmployeeAttachedServiceList(
          entityList,
          employeeUuid,
          '',
          total,
        ),
      );
    }
  }, [
    dispatch,
    entityList,
    isStarLoading,
    employeeUuid,
    storedEmployeeAttachedServiceListStatus,
    total,
    isOrderScreen,
  ]);

  const handleSearchEmployeeAttachedServices = React.useCallback(
    async ({
      keywords,
      employeeUuid: employee_uuid = employeeUuid,
      limit = 10,
      showLoading = false,
    }: IRefreshParams) => {
      const employeeAttachedServiceListModel = await refresh({
        offset: 0,
        limit,
        keywords,
        showLoading,
        employeeUuid: employee_uuid,
      });

      if (
        employeeAttachedServiceListModel &&
        List.isList(employeeAttachedServiceListModel?.services)
      ) {
        setEmployeeAttachedServiceList(
          employeeAttachedServiceListModel?.services,
        );

        setLocalTotal(employeeAttachedServiceListModel?.total!);
      }
    },
    [employeeUuid, refresh],
  );

  const handleLoadMoreEmployeeAttachedServices = useCallback(async () => {
    if (
      List.isList(employeeAttachedServiceList) &&
      employeeAttachedServiceList?.size < storeTotal &&
      !loadingMore
    ) {
      setLoadingMore(true);
      const employeeAttachedServiceListModel = await refresh({
        offset: employeeAttachedServiceList?.size,
        limit: 10,
        showLoading: false,
      });

      if (
        employeeAttachedServiceListModel &&
        List.isList(employeeAttachedServiceListModel?.services)
      ) {
        dispatch(
          storeLoadMoreEmployeeAttachedServiceList(
            employeeAttachedServiceListModel?.services,
            employeeUuid,
          ),
        );
      }

      setLoadingMore(false);
    }
  }, [
    dispatch,
    loadingMore,
    refresh,
    employeeAttachedServiceList,
    employeeUuid,
    storeTotal,
  ]);

  const handleRefreshEmployeeAttachedServices = useCallback(
    async ({
      limit = 10,
      showLoading = false,
      employeeUuid: localEmployeeUuid,
    }: IRefreshParams = {}) => {
      const employeeAttachedServiceListModel = await refresh({
        offset: 0,
        limit,
        showLoading,
        employeeUuid: localEmployeeUuid || employeeUuid,
      });

      if (
        employeeAttachedServiceListModel &&
        List.isList(employeeAttachedServiceListModel?.services)
      ) {
        dispatch(
          storeSetEmployeeAttachedServiceList(
            employeeAttachedServiceListModel?.services,
            localEmployeeUuid || employeeUuid,
            '',
            employeeAttachedServiceListModel?.total,
          ),
        );

        return employeeAttachedServiceListModel;
      }
    },
    [dispatch, refresh, employeeUuid],
  );

  const handleRefreshStoreEmployeeAttachedServices = useCallback(
    async ({
      limit = 10,
      showLoading = false,
      employeeUuid: localEmployeeUuid,
    }: IRefreshParams = {}) => {
      setLoadingMore(true);

      const employeeAttachedServiceList = cachedEmployeeAttachedServiceList.get(
        localEmployeeUuid || employeeUuid,
      );

      if (!employeeAttachedServiceList) {
        const employeeAttachedServiceListModel = await refresh({
          offset: 0,
          limit,
          showLoading,
          employeeUuid: localEmployeeUuid || employeeUuid,
        });

        if (
          employeeAttachedServiceListModel &&
          List.isList(employeeAttachedServiceListModel?.services)
        ) {
          dispatch(
            storeSetEmployeeAttachedServiceList(
              employeeAttachedServiceListModel?.services,
              localEmployeeUuid || employeeUuid,
              '',
              employeeAttachedServiceListModel?.total,
            ),
          );

          setEmployeeAttachedServiceList(
            employeeAttachedServiceListModel?.services,
          );

          setTimeout(setLoadingMore, 0, false);

          return employeeAttachedServiceListModel.services;
        }
      } else {
        setEmployeeAttachedServiceList(employeeAttachedServiceList);

        setTimeout(setLoadingMore, 0, false);
        return employeeAttachedServiceList;
      }
    },
    [cachedEmployeeAttachedServiceList, employeeUuid, refresh, dispatch],
  );

  return {
    ...employeeAttachedServicesParams,
    ...storedEmployeeAttachedServiceListParams,
    employeeAttachedServiceList,
    loadingMore,
    total: localTotal,
    offset,
    limit,
    refresh,
    loading: !employeeAttachedServiceList,
    handleSearchEmployeeAttachedServices,
    handleLoadMoreEmployeeAttachedServices,
    status: storedEmployeeAttachedServiceListStatus,
    handleRefreshEmployeeAttachedServices,
    handleRefreshStoreEmployeeAttachedServices,
  };
}
