import React from 'react';
import styled, {css} from 'styled-components';
import {Text} from '../../../components/lib/Format';

export interface IEmployeeRegistrationItemProps {
  icon: React.ReactNode;
  title: string | React.ReactNode;
  index: number;
  extraContent?: React.ReactNode;
}

const textStyle = css`
  color: ${({theme}) => theme.colors.primary};
  font-size: 22px;
`;

const StyledLi = styled.li`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${({theme}) => theme.colors.primary};

  ${textStyle};
`;

const StyledText = styled(Text)`
  ${textStyle};
`;

const StyledExtraContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export function EmployeeRegistrationItem({
  index,
  icon,
  title,
  extraContent,
}: IEmployeeRegistrationItemProps): JSX.Element {
  return (
    <StyledLi>
      <StyledText>
        {index}.&nbsp;
        {icon}
        &nbsp;
        {title}
      </StyledText>
      {extraContent ? (
        <StyledExtraContent>{extraContent}</StyledExtraContent>
      ) : null}
    </StyledLi>
  );
}
