import styled, {css} from 'styled-components';
import React, {ForwardedRef, forwardRef} from 'react';

export interface ITextFormatProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  > {
  children: React.ReactNode;
  breakWord?: boolean;
  bold?: boolean;
  muted?: boolean;
  info?: boolean;
  success?: boolean;
  warning?: boolean;
  error?: boolean;
  danger?: boolean;
  primary?: boolean;
  invert?: boolean;
  className?: string;
  fontSize?: number;
  fontWeight?: number;
}

export const StyledTexFormat = styled.span<any>`
  ${({breakWord}) =>
    breakWord &&
    css`
      word-break: break-word;
    `}
  ${({bold}) =>
    bold &&
    css`
      font-weight: 700;
    `}
  
  ${({fontWeight}) =>
    fontWeight &&
    css`
      font-weight: ${fontWeight};
    `}
  ${({muted}) =>
    muted &&
    css`
      color: ${({theme}) => theme.colors.disable};
    `}
  ${({info}) =>
    info &&
    css`
      color: ${({theme}) => theme.colors.info};
    `}
  ${({success}) =>
    success &&
    css`
      color: ${({theme}) => theme.colors.success};
    `}
  ${({warning}) =>
    warning &&
    css`
      color: ${({theme}) => theme.colors.warning};
    `}
  ${({danger, error}) =>
    (danger || error) &&
    css`
      color: ${({theme}) => theme.colors.error};
    `}
  ${({primary}) =>
    primary &&
    css`
      color: ${({theme}) => theme.colors.primary};
    `};

  ${({invert}) =>
    invert &&
    css`
      color: ${({theme}) => theme.colors.app};
    `};

  ${({fontSize}) =>
    fontSize &&
    css`
      font-size: ${fontSize}px;
    `};

  ${({color}) =>
    color &&
    css`
      color: ${color};
    `};
`;

/**
 * @name TextFormat
 */
export default forwardRef(function TextFormat(
  {
    children,
    breakWord,
    bold,
    muted,
    info,
    success,
    warning,
    error,
    danger,
    primary,
    invert,
    className,
    ...rest
  }: ITextFormatProps,
  ref?: ForwardedRef<HTMLSpanElement>,
): JSX.Element {
  return (
    <StyledTexFormat
      className={className}
      breakWord={breakWord}
      bold={bold}
      muted={muted}
      info={info}
      success={success}
      warning={warning}
      error={error}
      danger={danger}
      primary={primary}
      invert={invert}
      ref={ref}
      {...rest}>
      {children}
    </StyledTexFormat>
  );
});
