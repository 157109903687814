import React from 'react';
import {Space} from 'antd';
import {HeaderText} from '../Show';
import {FaUserCircle} from 'react-icons/fa';
import styled, {useTheme} from 'styled-components';
import {withTranslation, WithTranslation} from 'react-i18next';

export default withTranslation()(AuthPageWrapper);

const StyledContainer = styled(Space)`
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: ${({theme}) => theme.colors.app};

  & .ant-space-item {
    text-align: center;
    width: 100%;

    & .office-icon {
      width: 100%;
      text-align: center;
    }
  }
`;

export interface IAuthPageWrapperProps extends WithTranslation {
  title: string;
  description?: string;
  showLogo?: boolean;
  children: React.ReactNode;
}

function AuthPageWrapper({
  title,
  children,
  description,
  showLogo = false,
}: IAuthPageWrapperProps): JSX.Element {
  const theme: any = useTheme();

  return (
    <StyledContainer direction="vertical" size="middle">
      {!showLogo ? (
        <FaUserCircle
          size={44}
          color={theme.colors.primary}
          className="office-icon"
        />
      ) : null}

      <HeaderText
        title={title}
        withoutLogo={!showLogo}
        description={description}
        upperCase={false}
      />

      {children}
    </StyledContainer>
  );
}
