import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {Col, Form, Input, Row} from 'antd';
import {
  DetailsView,
  SearchSelect,
  IDetailsViewItemProps,
} from '../../../components/lib/DataDisplay';
import {listToArray, decryptObject} from '../../../services/helpers';
import {
  ClientFormDTO,
  ClientLegalFormDTO,
  ClientMapper,
  ClientModel,
  ClientOrgType,
  PriceMarginModel,
  StoreDocumentFormDTO,
  StoreDTO,
  StoreMapper,
} from '../../../struture';
import {
  IUseStateCompanyClientListReturnType,
  IUseStatePriceMarginListReturnType,
  IUseStateProductListReturnType,
  IUseStateStoreListReturnType,
  useSize,
  UseStateProductListReturnType,
} from '../../../hooks';
import {useTranslation} from 'react-i18next';
import {useDefaultForm} from '../../../contex';
import {StoreProductListField} from '../../Stores';
import {ClientCreateButton, ClientDetailsView} from '../../Clients';
import {PlusOutlined} from '@ant-design/icons';
import {phoneScreen, SessionStorageItems} from '../../../services/const';
import styled from 'styled-components';

const StyledClientCol = styled(Col)`
  margin-bottom: 20px;
`;

export interface IStoreRealizationFieldsProps
  extends Pick<
      IUseStateCompanyClientListReturnType,
      'clients' | 'handleSearchClients' | 'handleCreateLocalClient'
    >,
    Pick<IUseStateStoreListReturnType, 'storeList' | 'handleSearchStores'>,
    Pick<
      IUseStateProductListReturnType,
      'productList' | 'handleSearchProducts'
    >,
    Pick<
      IUseStatePriceMarginListReturnType,
      'priceMarginList' | 'handleSearchPriceMargins'
    >,
    Pick<
      UseStateProductListReturnType,
      'isLoadingMore' | 'handleLoadingMoreProductList' | 'handleSetProductList'
    > {
  loading: boolean;
  editMode: boolean;
  clientsLoading: boolean;
  storeListLoading: boolean;
  productListLoading: boolean;
  productListLoadingMore: boolean;
  productListKeywords: string;
  priceMarginListLoading: boolean;
  setDefaultPriceMargin: React.Dispatch<
    SetStateAction<PriceMarginModel | null>
  >;
  defaultPriceMargin: PriceMarginModel | null;
  document: StoreDocumentFormDTO;
}

export function OrderRealizationFields({
  loading,
  editMode,

  clients,
  clientsLoading,
  handleCreateLocalClient,
  handleSearchClients,

  storeList,
  storeListLoading,
  handleSearchStores,

  productList,
  productListLoading,
  productListLoadingMore,
  productListKeywords,
  handleSearchProducts,
  handleSetProductList,

  priceMarginList,
  priceMarginListLoading,
  handleSearchPriceMargins,
  setDefaultPriceMargin,
  defaultPriceMargin,

  isLoadingMore,
  handleLoadingMoreProductList,
  document,
}: IStoreRealizationFieldsProps): React.JSX.Element {
  const {t} = useTranslation();
  const {loadingSubmit, formData, handlerUpdateFormState}: any =
    useDefaultForm();

  const [client, setClient] = useState<ClientModel | null>(null);

  const onceStore = useRef(false);
  const contentRef = useRef(null);
  const onceClient = useRef(false);
  const {width: contentWidth} = useSize(contentRef);
  const isFullWidth = contentWidth <= phoneScreen ? 24 : 12;

  const onPriceMarginChange = useCallback(
    (priceMarginUuid: string) => {
      const defaultPriceMargin = priceMarginList?.find(
        ({uuid}) => priceMarginUuid === uuid,
      );

      if (defaultPriceMargin) {
        setDefaultPriceMargin(defaultPriceMargin);
      }
    },
    [priceMarginList, setDefaultPriceMargin],
  );

  const handleQuicklyCreateClient = useCallback(
    async ({t, ...value}: any): Promise<void> => {
      const uuid = `create${
        (value as ClientFormDTO)?.first_name ||
        (value as ClientLegalFormDTO)?.client_org_name
      }${value.phone}`;

      await handleCreateLocalClient({
        ...value,
        uuid,
      } as any);

      handlerUpdateFormState({
        doc_client: {
          ...value,
          uuid,
        },
      });
    },
    [handleCreateLocalClient, handlerUpdateFormState],
  );

  const handleChangeClient = useCallback(
    (e: string): void => {
      onceClient.current = true;

      if (
        formData?.doc_client?.uuid !== e ||
        formData?.doc_client instanceof ClientModel
      ) {
        if (e?.includes('create')) {
          const client = clients?.find(({uuid}) => uuid === e);

          if (client) {
            let doc_client;
            if (client?.client_org_type === ClientOrgType.PERSON) {
              doc_client = ClientMapper.toClientFormDTO(client);
            } else {
              doc_client = ClientMapper.toClientLegalFormDTO(client);
            }

            handlerUpdateFormState({doc_client});
          }
          return;
        }

        handlerUpdateFormState({doc_client: {uuid: e}});
      }
    },
    [clients, formData?.doc_client, handlerUpdateFormState],
  );

  useEffect(() => {
    const serializeStoreData = sessionStorage.getItem(
      SessionStorageItems.realizationOrderFormStoreData,
    );

    if (!editMode && serializeStoreData && !onceStore?.current) {
      onceStore.current = true;
      const storeDTO: StoreDTO = decryptObject(serializeStoreData);

      const storeModel = StoreMapper.toStoreModel(storeDTO);

      handlerUpdateFormState({doc_store_uuid: storeModel});
    }
  }, [editMode, handlerUpdateFormState]);

  useEffect(() => {
    if (!editMode && formData?.doc_client instanceof ClientModel) {
      setClient(formData?.doc_client);
      handlerUpdateFormState({doc_client: {uuid: formData?.doc_client?.uuid}});
    }
  }, [editMode, formData?.doc_client, handlerUpdateFormState]);

  const detailViewItems: IDetailsViewItemProps[] = [
    {
      title: 'Client',
      className: 'client-details__view',
    },
  ];

  return (
    <Row gutter={20} ref={contentRef}>
      <Col span={isFullWidth}>
        <Form.Item
          label={t('Store')}
          name="doc_store_uuid"
          tooltip={t('Store')}
          rules={[
            {
              required: true,
              message: t('Store must be specified.'),
            },
          ]}>
          <SearchSelect
            selectFirst={storeList?.size === 1}
            name="doc_store_uuid"
            disable={loading || storeListLoading || loadingSubmit}
            placeholder={t('Select a warehouse')}
            data={listToArray(storeList as any)}
            getOptionValueTitle="store_name"
            onSearch={(keywords) => handleSearchStores({keywords})}
            resetState={storeListLoading}
            onChange={(doc_store_uuid) =>
              handlerUpdateFormState({doc_store_uuid})
            }
          />
        </Form.Item>
      </Col>

      <Col span={isFullWidth}>
        <Form.Item
          label={t('Price margin')}
          name="doc_margin_uuid"
          tooltip={t('Price margin')}>
          <SearchSelect
            selectFirst={storeList?.size === 1}
            name="doc_margin_uuid"
            disable={loading || priceMarginListLoading || loadingSubmit}
            placeholder={t('Select a price margin')}
            data={listToArray(priceMarginList as any)}
            getOptionValueTitle="price_name"
            onSearch={(keywords) => handleSearchPriceMargins({keywords})}
            resetState={storeListLoading}
            onChange={onPriceMarginChange}
          />
        </Form.Item>
      </Col>

      {client ? (
        <StyledClientCol span={24}>
          <DetailsView items={detailViewItems}>
            <ClientDetailsView client={client} />
          </DetailsView>
        </StyledClientCol>
      ) : (
        <Col span={24}>
          <Form.Item
            label={t('Client')}
            name="doc_client"
            tooltip={t('Client')}>
            <SearchSelect
              asObject={
                editMode && formData?.doc_client?.uuid
                  ? onceClient.current
                  : true
              }
              selectFirst={clients?.size === 1}
              name="doc_client"
              disable={loading || clientsLoading || loadingSubmit}
              placeholder={t('Select a client')}
              data={listToArray(clients as any)}
              valuePropName="uuid"
              getOptionValueTitle="fullNameClient"
              onSearch={(keywords) =>
                handleSearchClients({keywords, showLoading: false}) as any
              }
              resetState={clientsLoading}
              onChange={handleChangeClient}
              addonAfter={
                <ClientCreateButton
                  provider
                  disabled={loading || loadingSubmit}
                  onSuccess={handleQuicklyCreateClient}
                  autoFocus>
                  <PlusOutlined />
                </ClientCreateButton>
              }
            />
          </Form.Item>
        </Col>
      )}

      <StoreProductListField
        isOrderFields
        disabled={!formData?.doc_store_uuid}
        asAmountModal
        loading={loading}
        editMode={editMode}
        productList={productList}
        productListLoading={productListLoading}
        productListLoadingMore={productListLoadingMore}
        handleSearchProducts={handleSearchProducts}
        productListKeywords={productListKeywords}
        defaultPriceMargin={defaultPriceMargin}
        isLoadingMore={isLoadingMore}
        handleLoadingMoreProductList={handleLoadingMoreProductList}
        document={document}
        handleSetProductList={handleSetProductList}
      />

      <Col span={24}>
        <Form.Item label={t('Note')} name="doc_comment">
          <Input.TextArea
            allowClear
            autoSize={{minRows: 2, maxRows: 5}}
            disabled={loading || loadingSubmit}
            placeholder={t('Enter your note')}
            onChange={(e) =>
              handlerUpdateFormState({doc_comment: e?.target?.value})
            }
          />
        </Form.Item>
      </Col>
    </Row>
  );
}
