import * as React from 'react';
import {Dispatch, SetStateAction} from 'react';

export interface IUseDataItemStateReturnedType<T> {
  data: T;
  handleUpdate: (item: T) => void;
  setData: Dispatch<SetStateAction<T>>;
  loading: boolean;
}

export default function useDataItemState<T>(
  data: T,
  isModels = true,
): IUseDataItemStateReturnedType<T> {
  const [inputData, setInputData] = React.useState<T>(null as any);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    let didCancel = false;
    if (data && !didCancel) {
      setInputData(data);
      setLoading(false);
    }

    return () => {
      didCancel = true;
    };
  }, [data]);

  const handleUpdate = React.useCallback(
    (item: T) => {
      if (isModels) {
        return setInputData(item);
      }
      setInputData((prevState) => ({
        ...prevState,
        ...item,
      }));
    },
    [isModels],
  );

  return {
    data: inputData,
    setData: setInputData,
    handleUpdate,
    loading,
  };
}
