import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {IActionButtonProps} from '../../../components/lib/General';
import {useModal} from '../../../components/lib/libV2/hooks';
import {DayOffDTO} from '../../../struture';
import {EditButton, SuspenseEmpty} from '../../../components/lib/DataDisplay';

const DayOffSideWindowLazy = React.lazy(
  () => import('../Show/DaysOffSideWindow'),
);

export interface IEmployeeDayOffPanelProps
  extends Omit<IActionButtonProps, 'children'> {
  employeeUuid: string;
  dayOff: DayOffDTO;
  onSuccess: (value: DayOffDTO) => Promise<void>;
  onCancel?: () => void;
  children?: React.ReactNode;
}

export default function EditDayOffButton({
  children,
  dayOff,
  employeeUuid,
  onSuccess,
  onCancel,
  ...rest
}: IEmployeeDayOffPanelProps) {
  const {t} = useTranslation();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <EditButton
        tooltipTitle={t('Edit employee day off')}
        onClick={handleOnInit}
        disabled={false}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <DayOffSideWindowLazy
          dayOff={dayOff}
          employeeUuid={employeeUuid}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
          editMode
        />
      </Suspense>
    </>
  );
}
