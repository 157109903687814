import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {ScheduleCalendarProps, isFunction} from '../../../services/helpers';
import {ChildrenMustBeAFunction} from '../../../components/lib/Errors';
import {IDetailsHeaderProps} from '../../../components/lib/General';
import {ScheduleCalendarModel} from '../../../struture';
import {useNavigate, useParams} from 'react-router';
import {Routes} from '../../../services/types';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import {IBreadcrumbProps, Route} from '../../../components/lib/DataDisplay';

export interface IClientAbonementScheduleDetailProps
  extends ScheduleCalendarProps {
  schedule: ScheduleCalendarModel;
  children: (
    value: Pick<IDetailsHeaderProps, 'routes'> & {navigateGoBack: () => void},
  ) => React.ReactNode;
  routes: Route[];
}

export function ClientAbonementScheduleDetail({
  schedule,
  children,
  routes,
}: IClientAbonementScheduleDetailProps): JSX.Element {
  const navigate = useNavigate();

  const clientRoutes = [
    ...(routes || []),
    {
      path: `path`,
      breadcrumbName: `${
        schedule?.schedule_number ? `#${schedule?.schedule_number}` : ''
      }`,
    },
  ];

  const navigateGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  if (!isFunction(children)) {
    throw new ChildrenMustBeAFunction('ClientAbonementScheduleDetail');
  }

  return <>{children({routes: clientRoutes, navigateGoBack})}</>;
}
