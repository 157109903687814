import React, {MutableRefObject, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {DefaultForm, IDefaultFormProps} from '../../../components/lib/General';
import {PRODUCT_INITIAL_PARAM} from '../../../services/api/product';
import {
  ProductCategoryMappedType,
  ProductCategoryType,
  ProductFormDTO,
} from '../../../struture';
import {useDropdownAlert} from '../../../contex';
import {
  useProductCategoryListToSelectTree,
  useStoredCompanies,
  useStoredPriceMarginList,
  useStoredProductCategoryList,
} from '../../../hooks';
import {ProductFields} from './ProductFields';

export interface IProductFormProps
  extends Omit<
    IDefaultFormProps<ProductFormDTO, ProductFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  product?: ProductFormDTO;
  autoFocusTitle?: boolean;
  productEditRef?: MutableRefObject<ProductFormDTO>;
}

export function ProductForm({
  loading,
  editMode,
  product = PRODUCT_INITIAL_PARAM,
  onCancel,
  autoFocusTitle,
  productEditRef,
  ...rest
}: IProductFormProps) {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const {defaultCompanyUuid} = useStoredCompanies();

  const {
    categoryList,
    loading: categoryListLoading,
    handleSearchProductCategories,
  } = useStoredProductCategoryList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
    productCategoryType: ProductCategoryType.TREE,
    mappedProductCategoryType: ProductCategoryMappedType.FORMS_TREE,
  });

  const {treeData} = useProductCategoryListToSelectTree({
    categories: categoryList,
  });

  const {priceMarginList, loading: priceMarginListLoading} =
    useStoredPriceMarginList({
      companyUuid: defaultCompanyUuid,
    });

  const additionalValues = useMemo(
    () => ({
      uuid: product?.uuid,
    }),
    [product?.uuid],
  );

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Material'),
        `${
          editMode
            ? t('An error occurred during edit material')
            : t('An error occurred during create material')
        } : ${apiError?.message}`,
      );
    },
    [alert, t, editMode],
  );

  return (
    <DefaultForm<any, any>
      withContext
      editMode={editMode}
      initialValues={{
        ...product,
      }}
      additionalValuesRequest={additionalValues}
      showNotify={false}
      onCancel={onCancel}
      notifyError={notifyError}
      {...rest}>
      <ProductFields
        loading={!!loading}
        editMode={!!editMode}
        categoryList={categoryList}
        categoryListLoading={categoryListLoading}
        handleSearchProductCategories={handleSearchProductCategories}
        priceMarginList={priceMarginList}
        priceMarginListLoading={priceMarginListLoading}
        disabledCategory={
          typeof product?.disabledCategory === 'boolean'
            ? product?.disabledCategory
            : !editMode && !!product?.category_uuid
        }
        autoFocusTitle={autoFocusTitle}
        productEditRef={productEditRef}
        treeData={treeData}
      />
    </DefaultForm>
  );
}
