import React from 'react';
import {
  DetailsItemView,
  DetailsView,
  Link,
} from '../../../components/lib/DataDisplay';
import {StyledDescription, StyledTitle} from '../../../components/lib/Styled';
import styled, {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useStoredCompanies} from '../../../hooks';
import {
  EmployeeModel,
  EmployeeStatuses,
  RewardStatsDTO,
} from '../../../struture';
import {
  isThereContent,
  correctPrice,
  textToUpperCase,
} from '../../../services/helpers';
import {Routes} from '../../../services/types';
import {Tooltip} from 'antd';
import {DeleteOutlined, InfoCircleOutlined} from '@ant-design/icons';
export interface IRewardBalanceViewProps {
  stats: RewardStatsDTO | null;
}

const StyledDetailsView = styled(DetailsView)`
  width: fit-content;
  border-color: #d9d9d9;
  margin-bottom: 40px;
`;

const StyledClientBalanceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)<{$color: string}>`
  color: ${({$color}) => $color};
`;

const StyledDeleteContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const StyledDeleteOutlined = styled(DeleteOutlined)`
  color: ${({theme}) => theme.colors.error};
  font-size: 12px;
`;

export function RewardBalanceView({
  stats,
}: IRewardBalanceViewProps): React.JSX.Element {
  const {t} = useTranslation();
  const {defaultCompany} = useStoredCompanies();
  const theme: any = useTheme();

  const isBalance = Number(stats?.employee?.balance || 0) >= 0;

  return (
    <>
      {isThereContent(stats) ? (
        <StyledDetailsView
          colors={[
            stats?.employee?.status === EmployeeStatuses.DELETED
              ? theme.colors.lightError
              : undefined,
          ]}
          titles={['Employee']}>
          <DetailsItemView<EmployeeModel>
            item={stats?.employee}
            fields={{
              fullName: {
                link: 'Go to employee details page',
                path: `/${Routes.app}/${Routes.employees}/${stats?.employee?.uuid}`,
              },
              balance: {
                description: (
                  <StyledClientBalanceContainer>
                    <StyledDescription
                      $color={
                        isBalance ? theme.colors.success : theme.colors.error
                      }>
                      {`${t('Balance')}(${
                        defaultCompany?.currency_symbol
                      }): ${correctPrice(stats?.employee?.balance)}`}
                    </StyledDescription>
                    {Number(stats?.employee?.balance) > 0 ||
                    Number(stats?.employee?.balance) < 0 ? (
                      <Tooltip
                        title={
                          isBalance ? t('We are guilty') : t('We are to blame')
                        }>
                        <StyledInfoCircleOutlined
                          $color={
                            isBalance
                              ? theme.colors.success
                              : theme.colors.error
                          }
                        />
                      </Tooltip>
                    ) : null}
                  </StyledClientBalanceContainer>
                ),
              },
              phone: {description: ''},
            }}>
            {stats?.employee?.status === EmployeeStatuses.DELETED ? (
              <StyledDeleteContainer>
                <StyledDeleteOutlined />
                <StyledDescription $color={theme.colors.error}>
                  {textToUpperCase(t('deleted'))}
                </StyledDescription>
              </StyledDeleteContainer>
            ) : null}
          </DetailsItemView>
        </StyledDetailsView>
      ) : null}
    </>
  );
}
