import {Map} from 'immutable';
import {APP_STATE, STATUS} from '../constants';
import {REDUX_STATUS, ApiError} from '../../services/types';
import {StatusModel} from '../../struture';

interface IStatusListState {
  status: StatusModel | null;
  cachedStatusList: Map<string, StatusModel>;
  error: ApiError | null;
  loading: boolean;
  reduxStatus: REDUX_STATUS;
}

interface SetAction extends Pick<IStatusListState, 'status'> {
  type: STATUS.SET_STATUS;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface UpdateStatus {
  type: STATUS.UPDATE_STATUS;
  status: StatusModel;
}

interface LoadingAction {
  type: STATUS.LOADING_STATUS;
}

interface ResetAction {
  type: STATUS.RESET_STATUS;
}

interface ErrorAction extends Pick<IStatusListState, 'error'> {
  type: STATUS.ERROR_STATUS;
}

interface Handlers {
  [STATUS.SET_STATUS]: (
    state: IStatusListState,
    action: SetAction,
  ) => IStatusListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IStatusListState,
    action: SetInitialStateAction,
  ) => IStatusListState;

  [STATUS.RESET_STATUS]: (
    state: IStatusListState,
    action: ResetAction,
  ) => IStatusListState;

  [STATUS.UPDATE_STATUS]: (
    state: IStatusListState,
    action: UpdateStatus,
  ) => IStatusListState;

  [STATUS.ERROR_STATUS]: (
    state: IStatusListState,
    value: ErrorAction,
  ) => IStatusListState;

  [STATUS.LOADING_STATUS]: (
    state: IStatusListState,
    value?: LoadingAction,
  ) => IStatusListState;
  DEFAULT: (state: IStatusListState) => IStatusListState;
}

const initState: IStatusListState = {
  status: null,
  cachedStatusList: Map(),
  error: null,
  loading: true,
  reduxStatus: REDUX_STATUS.IDLE,
};

const handlers: Handlers = {
  [STATUS.SET_STATUS]: (state, {status}) => ({
    ...state,
    ...{
      status,
      cachedStatusList: status
        ? state.cachedStatusList.set(status?.uuid, status)
        : state.cachedStatusList,
      reduxStatus: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [STATUS.RESET_STATUS]: (state) => ({
    ...state,
    ...{
      reduxStatus: REDUX_STATUS.IDLE,
    },
  }),

  [STATUS.UPDATE_STATUS]: (state, {status}) => ({
    ...state,
    ...{
      status,
      cachedStatusList: state.cachedStatusList.update(
        status?.uuid,
        () => status,
      ),
      reduxStatus: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [STATUS.ERROR_STATUS]: (state: IStatusListState, {error}: ErrorAction) => ({
    ...state,
    ...{
      error,
      reduxStatus: REDUX_STATUS.FAILED,
    },
  }),

  [STATUS.LOADING_STATUS]: (state: IStatusListState) => ({
    ...state,
    ...{
      loading: true,
      reduxStatus: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IStatusListState) => state,
};

export default function Status(
  state: any = initState,
  action: any,
): IStatusListState {
  const handler = handlers[action.type as STATUS] || handlers.DEFAULT;
  return handler(state, action);
}
