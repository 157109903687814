import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {IActionButtonProps} from '../../../../components/lib/General';
import {useModal} from '../../../../components/lib/libV2/hooks';
import {AddButton, SuspenseEmpty} from '../../../../components/lib/DataDisplay';
import {StoreDocumentFormDTO, StoreDocumentType} from '../../../../struture';
import {IUseStateStoredDocumentReturnType} from '../../../../hooks';

const StoreReturnSideWindowLazy = React.lazy(
  () => import('../Show/StoreReturnSideWindow'),
);

export interface IStoreReturnCreateButtonProps
  extends Omit<IActionButtonProps, 'children'>,
    Pick<IUseStateStoredDocumentReturnType, 'handlePaymentStoreDocument'> {
  children?: React.ReactNode;
  onSuccess: IUseStateStoredDocumentReturnType['handleCreateOrUpdateStoreDocument'];
  onCancel?: () => void;
  document: StoreDocumentFormDTO;
  from?: StoreDocumentType;
}

export function StoreReturnCreateButton({
  children,
  onSuccess,
  onCancel,
  loading,
  document,
  handlePaymentStoreDocument,
  from,
  ...rest
}: IStoreReturnCreateButtonProps): React.JSX.Element {
  const {t} = useTranslation();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <AddButton
        tooltipTitle={t('Create return to supplier')}
        onClick={handleOnInit}
        loading={loading}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <StoreReturnSideWindowLazy
          from={from}
          document={document}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
          handlePaymentStoreDocument={handlePaymentStoreDocument}
          fromList={false}
        />
      </Suspense>
    </>
  );
}
