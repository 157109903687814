import React from 'react';
import {InvoiceStatus} from '../../../struture';
import {StyledTitle, StyledDescription} from '../../../components/lib/Styled';
import styled, {useTheme, css} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {toDateByFormat} from '../../../services/helpers';
import {InvoiceStatusListSelect} from '../Show/InvoiceStatusListSelect';
import {IUseStateInvoiceReturnType} from '../../../hooks';

export interface InvoicePageHeaderProps
  extends Pick<
    IUseStateInvoiceReturnType,
    'invoice' | 'handleUpdateInvoiceStatus'
  > {
  withoutIndent?: boolean;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const StyledWrapper = styled.div<{$withoutIndent: boolean}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;

  ${({$withoutIndent}) =>
    !$withoutIndent &&
    css`
      padding-left: 24px;
      padding-right: 24px;
    `}
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const Title = styled(StyledTitle)`
  margin-bottom: 3px;
`;

const StyledInvoiceStatusListSelect = styled(InvoiceStatusListSelect)`
  &&& {
    .ant-select-selection-item {
      padding: 0 !important;
      padding-inline-end: 0 !important;
    }
    .ant-select-selector {
      padding: 0px !important;
    }
  }
`;

export function InvoicePageHeader({
  invoice,
  handleUpdateInvoiceStatus,
  withoutIndent,
}: InvoicePageHeaderProps): React.JSX.Element {
  const {t} = useTranslation();
  const theme: any = useTheme();

  return (
    <StyledWrapper $withoutIndent={!!withoutIndent}>
      <StyledContainer>
        <StyledTitleContainer>
          <Title
            bold
            fontSize={20}
            $color={
              invoice?.inv_status === InvoiceStatus.DRAFT
                ? theme.colors.warning
                : undefined
            }>
            {invoice?.inv_status === InvoiceStatus.DRAFT
              ? `№${invoice?.inv_internal_number} ( ${t('Draft')} )`
              : `№${invoice?.inv_internal_number}`}
          </Title>

          <StyledInvoiceStatusListSelect
            loading={false}
            invoice={invoice}
            handleUpdateInvoiceStatus={handleUpdateInvoiceStatus}
          />
        </StyledTitleContainer>
        <StyledDescription>
          {`${t('From').toLowerCase()} ${toDateByFormat(
            invoice?.inv_date,
            'DD.MM.YYYY HH:mm',
          )}`}
        </StyledDescription>
      </StyledContainer>
    </StyledWrapper>
  );
}
