import React from 'react';
import {useTranslation} from 'react-i18next';
import {IConfirmActionButtonProps} from '../../../../components/lib/General';
import {TextFormat} from '../../../../components/lib/Format';
import {len, eq} from '../../../../services/helpers';
import {StoreDocumentModel} from '../../../../struture';
import {useDelete} from '../../../../components/lib/libV2/hooks';
import {DeleteButton} from '../../../../components/lib/DataDisplay';

export interface IStoreWriteOffDeleteButtonProps
  extends Omit<IConfirmActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: string[]) => Promise<void>;
  onError?: () => void;
  documents: StoreDocumentModel[];
  title?: string;
}

export function StoreWriteOffDeleteButton({
  children,
  documents = [],
  onSuccess,
  onError,
  title,
  ...rest
}: IStoreWriteOffDeleteButtonProps) {
  const {t} = useTranslation();

  const {handleDelete, loading} = useDelete({
    onSuccess,
    entity: documents,
    notifyTitle: 'Write off',
  });

  return (
    <DeleteButton
      title={title}
      tooltipTitle={t('Delete write off')}
      confirmTitle={
        <TextFormat breakWord>
          {t('Are you sure you want to delete')} <b>{len(documents)}</b>{' '}
          {t('write off')}?
        </TextFormat>
      }
      onConfirm={handleDelete}
      loading={loading}
      cancelButtonText={t('Cancel')}
      {...rest}
    />
  );
}
