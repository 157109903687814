import React from 'react';
import styled, {css} from 'styled-components';
import {useListLayout} from '../../../../contex';

export interface IListOutsideContentProps {
  children: React.ReactNode | React.ReactNode[];
}

const StyledOutsideContainer = styled.div<{$width: number}>`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2;

  ${({$width}) =>
    $width &&
    css`
      width: ${$width}px;
    `}
`;

export function ListOutsideContent({
  children,
  ...rest
}: IListOutsideContentProps): React.JSX.Element {
  const {contentRef, outsideContentRef} = useListLayout();

  return (
    <StyledOutsideContainer
      {...rest}
      ref={outsideContentRef}
      $width={contentRef?.current?.getBoundingClientRect()?.width || 0}>
      {children}
    </StyledOutsideContainer>
  );
}
