import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Empty} from 'antd';
import {ListLayout} from '../../../components/lib/Layout';
import {
  useStoredProductCategorySubCategoryList,
  useStopLoading,
  useStoredCompanies,
  useKeyboardOpenForm,
  useSearchInputFocus,
} from '../../../hooks';
import {textToUpperCase} from '../../../services/helpers';
import {ProductCategoryMapper, ProductCategoryModel} from '../../../struture';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import {
  ProductType,
  Routes,
  Routes as RoutesType,
  RoutesAcl,
  RoutesTitle,
} from '../../../services/types';
import {
  Table,
  TableActionCell,
} from '../../../components/lib/libV2/DataDisplay';
import {StyledTitle, successButtonStyle} from '../../../components/lib/Styled';
import {HeaderLeft, ListActions} from '../../../components/lib/DataDisplay';
import {useLocation, useNavigate, useParams} from 'react-router';
import {ProductCategoryDetailsView} from '../Show';
import {
  ProductCategoryCreateButton,
  ProductCategoryEditButton,
  ProductCategoriesDeleteButton,
} from '../Buttons';
import {useAcl} from '../../../contex';
import styled from 'styled-components';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';

const StyledCategoryCreateButton = styled(ProductCategoryCreateButton)`
  ${successButtonStyle}
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export function ProductCategorySubCategoryListPage() {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {manage} = useAcl(({product}) => product);
  const {categoryId} = useParams();
  const location = useLocation();
  const {defaultCompanyUuid} = useStoredCompanies();
  const {ref: listRef} = useKeyboardOpenForm({
    className: 'product-category-sub-category-create-event',
    disabled: !manage,
  });

  const [selectedCategories, setSelectedCategories] = useState<
    ProductCategoryModel[]
  >([]);

  const {
    categoryList,
    limit: pageSize,
    error: productListError,
    loading: productListLoading,
    total,
    keywords,
    page,
    parentCategory,
    loadingMore,

    handleRefreshCategories,
    handleCreateCategory,
    handleSearchCategories,
    handleEditCategory,
    handleDeleteCategories: onDeleteCategories,
  } = useStoredProductCategorySubCategoryList({
    parent_category_uuid: categoryId!,
    companyUuid: defaultCompanyUuid,
    loadOnInit: !!defaultCompanyUuid,
  });

  const loading = useStopLoading({
    error: productListError,
    loading: productListLoading || loadingMore,
    message: 'An error occurred during categories loading',
  });

  const {inputRef, focus, daleyFocus} = useSearchInputFocus({loading});

  const handleDeleteCategories = useCallback(
    async (value: string[]) => {
      await onDeleteCategories(value);
      focus();
    },
    [focus, onDeleteCategories],
  );

  const productRowSelection = useMemo(
    () => ({
      hideSelectAll: true,
      selectedRowKeys: selectedCategories.map(({uuid}) => uuid),
      onChange: (
        selectedRowKeys: React.Key[],
        selectedRecords: ProductCategoryModel[],
      ) => {
        setSelectedCategories(selectedRecords);
      },
    }),
    [selectedCategories],
  );

  const columns = [
    {
      title: t('Title'),
      key: 'title',
      render: (category: ProductCategoryModel) => (
        <ProductCategoryDetailsView category={category} />
      ),
    },
    {
      title: t('Actions'),
      key: 'actions',
      align: 'center' as any,
      fixed: 'right' as any,
      render: (category: ProductCategoryModel) => (
        <TableActionCell>
          <>
            <ProductCategoryEditButton
              // disabled={!manage}
              type="text"
              category={ProductCategoryMapper.toProductCategoryFormDTO(
                category,
                {disabledCategoryField: true},
              )}
              onSuccess={handleEditCategory as any}
              onCancel={daleyFocus}
            />
            <ProductCategoriesDeleteButton
              // disabled={!manage}
              categories={[category]}
              onSuccess={handleDeleteCategories}>
              <DeleteOutlined />
            </ProductCategoriesDeleteButton>
          </>
        </TableActionCell>
      ),
    },
  ];

  const routes = useBreadcrumb([
    {
      path: `/${Routes.goods}/${Routes.categories}`,
      breadcrumbName: 'Categories',
      state: {type: ProductType.Category},
    },
    {
      path: `/${Routes.goods}/${Routes.categories}/${categoryId}`,
      breadcrumbName: parentCategory?.title || 'Show category',
      state: {type: ProductType.Category},
    },
    {
      path: `/${Routes.goods}/${Routes.categories}/${categoryId}/${Routes.subCategories}`,
      breadcrumbName: 'Sub categories',
    },
  ]);

  const navigateGoBack = useCallback(() => {
    navigate(
      location?.state?.goBack ||
        `/${Routes.app}/${Routes.goods}/${Routes.categories}/${categoryId}`,
    );
  }, [categoryId, location?.state?.goBack, navigate]);

  return (
    <ListLayout
      ref={listRef}
      aclItem={RoutesAcl[Routes.products]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.subCategories]))}
      headerRight={
        <ProductCategoryCreateButton
          disabled={!manage}
          loading={loading}
          onSuccess={handleCreateCategory}
          category={ProductCategoryMapper.toProductCategoryFormDTO(
            {
              parentCategoryModel: parentCategory,
            } as ProductCategoryModel,
            {disabledCategoryField: true},
          )}
          className="product-category-sub-category-create-event"
          onCancel={daleyFocus}
        />
      }
      routes={routes}
      empty={
        categoryList?.size ? null : (
          <Empty
            description={t(
              `It looks like you don't have any ${ProductType.Category} at the moment.`,
            )}>
            <ProductCategoryCreateButton
              type="primary"
              disabled={!manage}
              loading={loading}
              onSuccess={handleCreateCategory}
              category={ProductCategoryMapper.toProductCategoryFormDTO(
                {
                  parentCategoryModel: parentCategory,
                } as ProductCategoryModel,
                {disabledCategoryField: true},
              )}
              onCancel={daleyFocus}>
              {t('Create category')}
            </ProductCategoryCreateButton>
          </Empty>
        )
      }
      headerExtra={
        <StyledHeader>
          <StyledTitle fontWeight={400} fontSize={25}>
            {parentCategory?.title}
          </StyledTitle>

          <ListActions
            inputRef={inputRef}
            searchText={keywords}
            withoutPicker
            loading={loading}
            handleSearch={(keywords) => handleSearchCategories({keywords})}
            inputTooltip={t('Search')}
            inputLabel={t('Search')}
            withSearchContent={
              <StyledCategoryCreateButton
                type="primary"
                disabled={!manage}
                loading={loading}
                onSuccess={handleCreateCategory}
                title={t('Create')}
                icon={<PlusOutlined />}
                category={ProductCategoryMapper.toProductCategoryFormDTO(
                  {
                    parentCategoryModel: parentCategory,
                  } as ProductCategoryModel,
                  {disabledCategoryField: true},
                )}
                onCancel={daleyFocus}
              />
            }
          />
        </StyledHeader>
      }
      loading={loading && !categoryList?.size}>
      <Table<ProductCategoryModel>
        rowSelection={productRowSelection}
        total={total}
        pageSize={pageSize}
        dataSource={categoryList}
        onChange={handleRefreshCategories}
        columns={columns}
        loading={loading}
        page={page}
      />
    </ListLayout>
  );
}
