import React from 'react';
import {AuthPageWrapper} from '../../common';
import {withTranslation, WithTranslation} from 'react-i18next';
import styled, {useTheme} from 'styled-components';
import {Text} from '../../../../components/lib/Format';
import {Row, Col, Button} from 'antd';
import {StyledWarningOutlined} from '../../Show';

export default withTranslation()(AuthOfficeHeader);

const StyledLoginButton = styled(Button)`
  width: 100%;
  background-color: ${({theme}) => theme.colorsV2.buttonPrimaryColor};
  border-color: ${({theme}) => theme.colorsV2.buttonPrimaryBorderColor};
  border-radius: 5px;
  box-shadow: none;

  &&:hover,
  &&:active,
  &&:focus {
    opacity: 0.8;
    border-color: ${({theme}) => theme.colorsV2.buttonPrimaryBorderColor};
    background-color: ${({theme}) =>
      theme.colorsV2.buttonPrimaryActiveColor} !important;
  }
`;

const StyledSignupButton = styled(Button)`
  width: 100%;
  border-width: 1px;
  border-style: solid;
  color: ${({theme}) => theme.colorsV2.buttonSecondaryTextColor} !important;
  background: ${({theme}) => theme.colorsV2.buttonSecondaryBgColor};
  border-radius: 5px;

  &:hover,
  &:active,
  &:focus {
    opacity: 0.8;
    border-color: ${({theme}) => theme.colorsV2.buttonSecondaryBorderColor};
    color: ${({theme}) => theme.colors.white} !important;
    background-color: ${({theme}) =>
      theme.colorsV2.buttonSecondaryActiveColor} !important;
  }
`;

const StyledSignupContainer = styled.div`
  position: relative;
`;

const StyledRow = styled(Row)`
  width: 100%;
  justify-content: space-between;
  padding: 0;
  margin: 0;
`;

const StyledBadgeContainer = styled.div`
  position: absolute;
  top: -10px;
  right: -8px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.background.primary};
  border-color: ${({theme}) => theme.colors.warning};
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  padding-bottom: 1px;
  padding-left: 0.5px;
`;

export interface IAuthOfficeHeaderProps extends WithTranslation {
  handleNavigateToLoginPage: () => void;
  handleNavigateToSignupPage: () => void;
  showRegistrationWarning?: boolean;
}

function AuthOfficeHeader({
  t,
  handleNavigateToSignupPage,
  handleNavigateToLoginPage,
  showRegistrationWarning,
}: IAuthOfficeHeaderProps): JSX.Element {
  const theme: any = useTheme();

  return (
    <AuthPageWrapper
      title={t('User space')}
      description={t(
        'CRM to manage the orders flow, communication with clients, products and processes. Sign in or sign up in order to start using it.',
      )}>
      <StyledRow gutter={20}>
        <Col span={12}>
          <StyledSignupContainer>
            <StyledSignupButton
              type="text"
              size="large"
              onClick={handleNavigateToSignupPage}>
              <Text>{t('Sign up')}</Text>
            </StyledSignupButton>
            {showRegistrationWarning ? (
              <StyledBadgeContainer>
                <StyledWarningOutlined size={15} />
              </StyledBadgeContainer>
            ) : null}
          </StyledSignupContainer>
        </Col>

        <Col span={12}>
          <StyledLoginButton
            type="primary"
            size="large"
            onClick={handleNavigateToLoginPage}>
            <Text>{t('Sign in')}</Text>
          </StyledLoginButton>
        </Col>
      </StyledRow>
    </AuthPageWrapper>
  );
}
