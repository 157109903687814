import {ClientModel, ClientOperationModel} from '../internal';
import {correctPrice} from '../../services/helpers';

export class ClientOperationFormDTO {
  public sum: string;
  public comment: string;

  constructor(props?: ClientModel) {
    this.sum = correctPrice(props?.saldo || 0);
    this.comment = '';
  }
}
