import {List} from 'immutable';
import {
  SystemEventDTO,
  ISystemEventDTOProps,
  SystemEventModel,
  SystemEventListModel,
} from '../internal';
import {listToArray} from '../../helpers';

export interface ISystemEventListDTO {
  events: SystemEventDTO[];
  total: number;
}

export class SystemEventMapper {
  public static toSystemEventDTO(
    event: SystemEventModel | ISystemEventDTOProps,
  ): SystemEventDTO {
    return new SystemEventDTO({
      event_key: event?.event_key,
      event_title: event?.event_title,
      event_variables: event?.event_variables,
    });
  }

  public static toSystemEventListDTO(
    events: List<SystemEventModel> | ISystemEventDTOProps[],
    total?: number,
  ): ISystemEventListDTO {
    const eventList = List.isList(events)
      ? listToArray<SystemEventModel>(events)
      : events;

    return {
      events: eventList?.map((event) =>
        SystemEventMapper.toSystemEventDTO(event),
      ),
      total: total || 0,
    };
  }

  public static toSystemEventModel(eventDTO: SystemEventDTO): SystemEventModel {
    return new SystemEventModel(eventDTO);
  }

  public static toSystemEventListModel(
    eventDTOs: SystemEventDTO[],
    total?: number,
  ): SystemEventListModel {
    return new SystemEventListModel({events: eventDTOs, total});
  }
}
