import {
  PlanMapper,
  SubscriptionMapper,
  SubscriptionDTO,
  ISubscriptionDTOProps,
  IPlanDTOProps,
  PlanDTO,
} from '../internal';

export interface IAuthorizeUserDTOProps {
  uuid: string;
  login: string;
  first_name: string;
  last_name: string;
  full_Name: string;
  phone: string;
  status: number;
  emailConfirmed: boolean;
  subscription: ISubscriptionDTOProps;
  allowNewsletters: boolean;
  invite_id: string;
  status_message: string;
  last_login: string;
  lang: string;
  identifier: string;
  terms_accepted: boolean;
  plans: IPlanDTOProps[];
}

export class AuthorizeUserDTO {
  public uuid: string;

  public login: string;

  public first_name: string;

  public last_name: string;

  public phone: string;

  public full_Name: string;

  public identifier: string;

  public status: number;

  public emailConfirmed: boolean;

  public subscription: SubscriptionDTO;

  public allowNewsletters: boolean;

  public invite_id: string;

  public status_message: string;

  public last_login: string;

  public lang: string;

  public terms_accepted: boolean;

  public plans: PlanDTO[];

  constructor(props: IAuthorizeUserDTOProps) {
    this.uuid = props?.uuid || '';
    this.login = props?.login || '';
    this.first_name = props?.first_name || '';
    this.last_name = props?.last_name || '';
    this.identifier = props?.identifier || '';
    this.phone = props?.phone || '';
    this.full_Name = props?.full_Name || '';
    this.status = props?.status || 0;
    this.emailConfirmed = props?.emailConfirmed || false;
    this.allowNewsletters = props?.allowNewsletters || false;
    this.invite_id = props?.invite_id || '';
    this.status_message = props?.status_message || '';
    this.last_login = props?.last_login || '';
    this.lang = props?.lang || '';
    this.terms_accepted = props?.terms_accepted || false;

    this.subscription = SubscriptionMapper.toSubscriptionDTO(
      props?.subscription,
    );
    this.plans = PlanMapper.toPlanListDTO(props?.plans || [], 0).plans;
  }
}
