import React from 'react';
import {useTranslation} from 'react-i18next';
import {Modal} from 'antd';
import {InvoiceItemForm, InvoiceItemFormProps} from '../Forms';
import {InvoiceItemFormDTO} from '../../../struture';
import styled from 'styled-components';

export interface InvoiceItemModalProps extends InvoiceItemFormProps {
  onSuccess: (
    value: InvoiceItemFormDTO | InvoiceItemFormDTO[],
  ) => Promise<void>;
  visible: boolean;
  title: string;
}

const StyledModal = styled(Modal)`
  position: relative;

  & .ant-modal-body {
    max-height: 75vh;
    overflow-x: hidden;
  }
`;

export default function InvoiceItemModal({
  onSuccess,
  visible,
  editMode,
  onCancel,
  item,
  title,
  ...rest
}: InvoiceItemModalProps) {
  const {t} = useTranslation();

  return (
    <>
      {visible ? (
        <StyledModal
          footer={[]}
          title={title}
          open={visible}
          onCancel={onCancel}>
          <InvoiceItemForm
            editMode={editMode}
            onSuccess={onSuccess}
            onCancel={onCancel}
            item={item}
            {...rest}
          />
        </StyledModal>
      ) : null}
    </>
  );
}
