import {apiGet, apiPost, apiPut, apiDelete} from './core/api';
import {ListOptions, WorkSchedulesList, ScheduleCalendarList} from './models';
import {contentApiUrl} from './const';
import Validate from './validate/Validate';

/**
 * @name listSchedules
 * @description Get a list of work schedules
 * @param {*} listOptions
 */
export async function listSchedules(listOptions = new ListOptions()) {
  const {
    offset = 0,
    limit = undefined,
    order = 'name ASC',
  } = listOptions.toObject();

  const res = await apiGet(`${contentApiUrl}/work-schedules`, {
    offset,
    limit,
    order,
  });
  return new WorkSchedulesList(res);
}

/**
 * @name createSchedule
 * @description Post request to create a new work schedule
 * @param {ListOptions} listOptions
 * @throws {ApiError}
 */
export async function createSchedule({
  title,
  description = '',
  is_default = 0,
  work_days,
  rest_days,
  status = '1',
}) {
  Validate.string(title);

  return await apiPost(`${contentApiUrl}/work-schedules`, {
    title,
    description,
    work_days,
    rest_days,
    is_default,
    status,
  });
}

/**
 * @name editSchedule
 * @description Put request to edit an existed schedule
 * @param {String} uuid
 * @param {String} title
 * @param is_default
 * @param description
 * @param work_days
 * @param rest_days
 * @param status
 */
export async function editSchedule({
  uuid,
  title,
  is_default = 0,
  description = '',
  work_days,
  rest_days,
  status = '1',
}) {
  Validate.string(title);
  Validate.string(uuid);

  return await apiPut(`${contentApiUrl}/work-schedules/${uuid}`, {
    title,
    description,
    work_days,
    rest_days,
    is_default,
    status,
  });
}

export async function deleteSchedules(scheduleIds) {
  Validate.arrayOfStrings(scheduleIds);

  await apiDelete(`${contentApiUrl}/work-schedules`, {
    ids: scheduleIds,
  });
}

/**
 * @name listSchedulesCurrentUser
 * @description Get a list of schedules for current user(owner, admin)
 * @param {*} listOptions
 */
export async function listCurrentUserSchedule(listOptions = new ListOptions()) {
  const res = await apiGet(`${contentApiUrl}/schedules`);

  return new ScheduleCalendarList(res || {});
}
