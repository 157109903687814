export enum STORE_DOCUMENT_LIST {
  SET_STORE_DOCUMENT_LIST = 'set_store_document_list',
  LOADING_STORE_DOCUMENT_LIST = 'loading_store_document_list',
  ERROR_STORE_DOCUMENT_LIST = 'error_store_document_list',
  LOAD_MORE_STORE_DOCUMENT_LIST = 'load_more_store_document_list',
  ADD_STORE_DOCUMENT = 'add_store_document',
  UPDATE_STORE_DOCUMENT = 'update_store_document',
  UPDATE_STORE_DOCUMENT_STATS = 'update_store_document_stats',
  DELETE_STORE_DOCUMENT = 'delete_store_document',
}
