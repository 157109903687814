import React from 'react';
import {List, Avatar} from 'antd';
import {withTranslation} from 'react-i18next';
import styled, {css} from 'styled-components';
import {ILiteralObj} from '../../../services/types';

const {Item} = List;

export interface ISuggestionInputProps {
  suggestion: ILiteralObj;
  onSelect: (value: ILiteralObj) => void;
  select?: string;
}

const styleItem = css`
  &&& {
    border-radius: 7px;
    background-color: ${({theme}) => theme.colors.lightDisable};

    & .suggestions-list-item-avatar {
      background-color: ${({theme}) => theme.colors.app};

      & > span {
        color: ${({theme}) => theme.colors.app};
      }
    }
  }
`;

const NativeListItem = styled(Item)<{select: number}>`
  width: 100%;
  z-index: 1000;
  cursor: pointer;

  &:hover {
    ${styleItem}
  }

  ${({select}) => select && styleItem}
`;

const NativeListItemMeta = styled(Item?.Meta)<{select: number}>`
  z-index: 1010;
`;

const SuggestionInput = ({
  suggestion,
  onSelect,
  select,
}: ISuggestionInputProps): JSX.Element => (
  <NativeListItem
    select={select === suggestion?.uuid ? 1 : 0}
    key={suggestion.uuid}
    onClick={() => onSelect(suggestion)}
    className="suggestions-list-item-details">
    <NativeListItemMeta
      select={select === suggestion?.uuid ? 1 : 0}
      avatar={
        <Avatar
          size={40}
          shape="square"
          className="suggestions-list-item-avatar">
          {`${suggestion.first_name.charAt(0)}${suggestion.last_name.charAt(
            0,
          )}`.toUpperCase()}
        </Avatar>
      }
      title={`${suggestion.first_name} ${suggestion.last_name}`}
      description={`${suggestion.phone}`}
    />
  </NativeListItem>
);

export default withTranslation()(SuggestionInput);
