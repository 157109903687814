import React, {useState, useEffect} from 'react';
import {Alert, Form} from 'antd';
import {withTranslation, WithTranslation} from 'react-i18next';
import {tokenHandler} from '../../../services/core/token';

import {logout} from '../../../services/api/user';
import {Routes} from '../../../services/types';
import {useNavigate} from 'react-router';

export default withTranslation()(LogoutPage);

export interface ILogoutPageProps extends WithTranslation {}

function LogoutPage({t}: ILogoutPageProps): JSX.Element {
  const navigate = useNavigate();

  const [error, setError] = useState<any>(null);

  useEffect(() => {
    (async () => {
      try {
        tokenHandler.destroyToken();

        await logout();
      } catch (err: any) {
        setError(
          `${t(
            'An error has occurred and your account has not been logged out, please refresh page',
          )}: ${err.message}`,
        );
      } finally {
        navigate(`${Routes.auth}/${Routes.login}`);
      }
    })();
  }, [navigate, t]);

  return (
    <>
      {error ? (
        // <SinglePageForm.Manager>
        //   <SinglePageForm.Content>
        //     <SinglePageForm.Header>
        //       <Logo />
        //     </SinglePageForm.Header>
        //     <Form.Item>
        //       <Alert data-testid="logout-error" message={error} type="error" />
        //     </Form.Item>
        //   </SinglePageForm.Content>
        // </SinglePageForm.Manager>
        <></>
      ) : null}
    </>
  );
}
