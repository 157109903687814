import {useCallback} from 'react';
import moment from 'moment';
import {isFunction} from '../services/helpers';
import {ClientSubscriptionTransition} from '../services/models';
import {useDefaultForm} from '../contex';

export interface IUseClientServiceTransitionHelperProps {
  isStartTransition?: boolean;
  handleUpdateTotalField?: (value: number) => void;
}

export type UseClientServiceTransitionHelperReturnType = () => Promise<void>;

export default function useClientServiceTransitionHelper({
  isStartTransition,
  handleUpdateTotalField,
}: IUseClientServiceTransitionHelperProps): UseClientServiceTransitionHelperReturnType {
  const {formData, setFieldsValue, getFieldsValue} = useDefaultForm();

  const setFieldTransition = useCallback(
    (value?: void | ClientSubscriptionTransition): void => {
      if (value) {
        setFieldsValue({
          ...value.toObject(),
        });
      }
    },
    [setFieldsValue],
  );

  return useCallback(async () => {
    if (isFunction(formData?.onChangeServiceTransition) && isStartTransition) {
      const {
        group_session_uuid,
        service_uuid,
        subscription_start_date = moment(),
        subscription_number_of_packages = 1,
      } = getFieldsValue();

      const transition = await formData?.onChangeServiceTransition({
        group_session_uuid,
        service_uuid,
        subscription_number_of_packages,
        subscription_start_date: moment(subscription_start_date).toISOString(),
      });

      if (isFunction(handleUpdateTotalField) && transition) {
        handleUpdateTotalField(transition?.surcharge);
      }

      setFieldTransition(transition);
    }
  }, [
    formData,
    isStartTransition,
    getFieldsValue,
    handleUpdateTotalField,
    setFieldTransition,
  ]);
}
