import {
  AlphaNameFormDTO,
  FinancialSettingsDTO,
  IAlphaNameDTOProps,
  AlphaNameDTO,
  AlphaNameAmount,
} from '../internal';

export class AlphaNameMapper {
  public static toAlphaNameDTO(props: IAlphaNameDTOProps): AlphaNameDTO {
    return new AlphaNameDTO(props);
  }

  public static toAlphaNameFormDTO(
    props: FinancialSettingsDTO,
    additionalProps?: {price: string; period: AlphaNameAmount},
  ): AlphaNameFormDTO {
    return new AlphaNameFormDTO(props, additionalProps);
  }
}
