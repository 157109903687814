import {List} from 'immutable';
import {
  ClientDTO,
  ClientFormDTO,
  IClientDTOProps,
  ClientModel,
  ClientListModel,
  ClientGroupFormDTO,
  ClientPaymentFormDTO,
  IClientListStatsProps,
  ClientLegalFormDTO,
} from '../internal';
import {listToArray} from '../../helpers';
import {WithoutRecursion} from '../type';

export interface IClientListDTO {
  clients: ClientDTO[];
  total: number;
  stats?: IClientListStatsProps;
}

export interface IClientListDTOProps {
  clients: List<ClientModel> | IClientDTOProps[];
  total: number;
  withoutMembers?: boolean;
  withoutRecursion?: WithoutRecursion[];
  stats?: IClientListStatsProps;
}

export interface IClientListModelProps {
  clients: ClientDTO[];
  total: number;
  withoutMembers?: boolean;
  withoutRecursion?: WithoutRecursion[];
  stats?: IClientListStatsProps;
}

export class ClientMapper {
  public static toClientDTO(
    client: ClientModel | IClientDTOProps,
    withoutMembers = false,
    withoutRecursion: WithoutRecursion[] = [],
  ): ClientDTO {
    return new ClientDTO(client, withoutMembers, [
      WithoutRecursion.client,
      ...withoutRecursion,
    ]);
  }

  public static toClientListDTO({
    clients,
    withoutMembers = false,
    withoutRecursion = [],
    total,
    stats,
  }: IClientListDTOProps): IClientListDTO {
    const clientList = List.isList(clients)
      ? listToArray<ClientModel>(clients)
      : clients;

    return {
      clients: clientList?.map((client) =>
        ClientMapper.toClientDTO(client, withoutMembers, [
          WithoutRecursion.client,
          ...withoutRecursion,
        ]),
      ),
      total,
      stats,
    };
  }

  public static toClientModel(
    clientDTO: ClientDTO,
    withoutMembers = false,
    withoutRecursion: WithoutRecursion[] = [],
  ): ClientModel {
    return new ClientModel(clientDTO, withoutMembers, [
      WithoutRecursion.client,
      ...withoutRecursion,
    ]);
  }

  public static toClientListModel({
    stats = {} as IClientListStatsProps,
    clients,
    withoutRecursion = [],
    withoutMembers = false,
    total,
  }: IClientListModelProps): ClientListModel {
    return new ClientListModel({clients, total, stats}, withoutMembers, [
      WithoutRecursion.client,
      ...withoutRecursion,
    ]);
  }

  public static toClientFormDTO(clientModel: ClientModel): ClientFormDTO {
    return new ClientFormDTO(clientModel);
  }

  public static toClientGroupFormDTO(
    clientModel: ClientModel,
    editMode?: boolean,
  ): ClientGroupFormDTO {
    return new ClientGroupFormDTO(clientModel, editMode);
  }

  public static toClientPaymentFormDTO(): ClientPaymentFormDTO {
    return new ClientPaymentFormDTO();
  }

  public static toClientLegalFormDTO(
    clientModel: ClientModel,
  ): ClientLegalFormDTO {
    return new ClientLegalFormDTO(clientModel);
  }
}
