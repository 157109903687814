import {Record, List} from 'immutable';
import {ListModel, ListProps} from './List';
import ClientOperation, {IClientOperationProps} from './ClientOperation';

export interface ClientOperationListProps extends ListProps {
  clientAccountOperations: IClientOperationProps[];
}

export interface ClientOperationListReturnType extends ListProps {
  clientAccountOperations: List<ClientOperation>;
}

export default class ClientOperationList extends Record<ClientOperationListReturnType>(
  {
    clientAccountOperations: List(),
    ...ListModel.toJS(),
  } as any,
) {
  /**
   * @constructor
   * @param {{clients:[]}} param0
   */
  constructor({
    clientAccountOperations = [],
    ...props
  }: ClientOperationListProps) {
    const options: any = {};
    if (Array.isArray(clientAccountOperations)) {
      options.clientAccountOperations = List(
        clientAccountOperations.map((client) => new ClientOperation(client)),
      );
    }

    super({
      ...props,
      ...options,
    });
  }
}
