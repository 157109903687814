import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {Empty} from 'antd';
import {ListEmpty} from '../../../components/lib/Layout';
import {
  useKeyboardOpenForm,
  useSearchInputFocus,
  useStopLoading,
  useStoredCompanies,
  useStoredProduct,
  useStoredProductCategory,
  useStoredProductCategoryList,
} from '../../../hooks';
import {textToUpperCase, isThereContent} from '../../../services/helpers';
import {
  IProductsListStatsProps,
  ProductCategoryMapper,
  ProductCategoryModel,
  ProductCategoryType,
  ProductFormDTO,
  ProductMapper,
  ProductModel,
} from '../../../struture';
import {useBreadcrumb, useModal} from '../../../components/lib/libV2/hooks';
import {
  ProductType,
  Routes,
  Routes as RoutesType,
  RoutesTitle,
} from '../../../services/types';
import {
  Table,
  TableActionCell,
} from '../../../components/lib/libV2/DataDisplay';
import {successButtonStyle} from '../../../components/lib/Styled';
import {
  HeaderLeft,
  ListActions,
  ListHeader as ListInputHeader,
  Segmented,
  SuspenseEmpty,
} from '../../../components/lib/DataDisplay';
import {useNavigate} from 'react-router';
import {ProductCategoryDetailsView} from '../Show';
import {
  ProductCategoriesDeleteButton,
  ProductCategoryEditButton,
  ProductCreateButton,
} from '../Buttons';
import {useAcl} from '../../../contex';
import styled from 'styled-components';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import {AppHeader} from '../../../components/lib/Layout/AppHeader';
import {ListHeader, ListContent} from '../../../components/lib/Layout';
import {LocalStorageItems} from '../../../services/const';
import {ProductCategoryView} from '../Show';

import '../../Orders/List/OrderList.less';

const ProductSideWindowLazy = React.lazy(
  () => import('../Show/ProductSideWindow'),
);

export interface IProductCategoryListPageProps {
  onUpdateStats: (stats: IProductsListStatsProps) => void;
  onUpdateLoading: (loading: boolean) => void;
}

const StyledProductCreateButton = styled(ProductCreateButton)`
  ${successButtonStyle}
`;

const StyledListHeader = styled(ListHeader)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`;

export const CATEGORIES_VIEW = [
  {
    uuid: ProductCategoryType.LIST,
    title: <ProductCategoryView title="List" view={ProductCategoryType.LIST} />,
  },
  {
    uuid: ProductCategoryType.TREE,
    title: <ProductCategoryView title="Tree" view={ProductCategoryType.TREE} />,
  },
];

export function ProductCategoryListPage({
  onUpdateLoading,
  onUpdateStats,
}: IProductCategoryListPageProps): React.JSX.Element {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {defaultCompanyUuid} = useStoredCompanies();
  const {manage} = useAcl(({product}) => product);

  const [selectedCategories, setSelectedCategories] = useState<
    ProductCategoryModel[]
  >([]);
  const [category, setCategory] = useState<ProductCategoryModel | null>(null);

  const {ref: listRef} = useKeyboardOpenForm({
    className: 'category-create-event',
    disabled: !manage,
  });

  const [view, setView] = useState<ProductCategoryType>(
    (localStorage.getItem(
      LocalStorageItems.productCategoryView,
    ) as ProductCategoryType) || ProductCategoryType.LIST,
  );

  const {
    categoryList,
    loading: categoryListLoading,
    loadingMore: categoryListLoadingMore,
    error: categoryListError,
    limit: pageSize,
    total,
    keywords,
    page,
    stats,

    handleSearchProductCategories,
    handleDeleteProductCategories: onDeleteProductCategories,
    handleRefreshProductCategories,
  } = useStoredProductCategoryList({
    companyUuid: defaultCompanyUuid,
    productCategoryType: view,
  });

  const {handleCreateProductCategory, handleUpdateProductCategory} =
    useStoredProductCategory({
      companyUuid: defaultCompanyUuid,
      loadOnInit: false,
      categoryUuid: '',
      view,
    });

  const {handleCreateProduct: onCreateProduct} = useStoredProduct({
    companyUuid: defaultCompanyUuid,
    loadOnInit: false,
    productUuid: '',
  });

  const loading = useStopLoading({
    error: categoryListError,
    loading: categoryListLoading || categoryListLoadingMore,
    message: 'An error occurred during materials categories loading',
  });

  const {inputRef, focus, daleyFocus} = useSearchInputFocus({loading});

  const handleDeleteProductCategories = useCallback(
    async (value: string[]) => {
      await onDeleteProductCategories(value);
      focus();
    },
    [focus, onDeleteProductCategories],
  );

  const {
    handleCancel: productHandleCancel,
    handleOnInit: productHandleOnInit,
    visible: productVisible,
  } = useModal({
    onCancel: () => {},
    onSuccess: () => {},
  });

  const handleCreateProduct = useCallback(
    async (value: ProductFormDTO) => {
      const product = await onCreateProduct(value);

      productHandleCancel();

      if (value?.add_another_product) {
        setCategory(product?.categoryModel || null);
        setTimeout(productHandleOnInit, 250);
      }
    },
    [onCreateProduct, productHandleCancel, productHandleOnInit],
  );

  const routes = useBreadcrumb([
    {
      path: `/${Routes.goods}/${Routes.categories}`,
      breadcrumbName: 'Categories',
    },
  ]);

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.companies}/${defaultCompanyUuid}`);
  }, [defaultCompanyUuid, navigate]);

  const rowSelection = useMemo(
    () => ({
      hideSelectAll: true,
      selectedRowKeys: selectedCategories.map(({uuid}) => uuid),
      onChange: (
        selectedRowKeys: React.Key[],
        selectedRecords: ProductCategoryModel[],
      ) => {
        setSelectedCategories(selectedRecords);
      },
    }),
    [selectedCategories],
  );

  const categoryColumns = [
    {
      title: t('Title'),
      key: 'title',
      render: (category: ProductCategoryModel) => (
        <ProductCategoryDetailsView category={category} />
      ),
    },
    {
      title: t('Actions'),
      key: 'actions',
      align: 'center' as any,
      fixed: 'right' as any,
      className: 'order-list-td',
      render: (category: ProductCategoryModel) => (
        <TableActionCell>
          <>
            <ProductCategoryEditButton
              disabled={!manage}
              type="text"
              category={ProductCategoryMapper.toProductCategoryFormDTO(
                category,
              )}
              onSuccess={handleUpdateProductCategory}
              onCancel={daleyFocus}
            />
            <ProductCategoriesDeleteButton
              // disabled={!manage}
              categories={[category]}
              onSuccess={handleDeleteProductCategories}>
              <DeleteOutlined />
            </ProductCategoriesDeleteButton>
          </>
        </TableActionCell>
      ),
    },
  ];

  useEffect(() => {
    if (isThereContent(stats)) {
      onUpdateStats(stats);
    }
  }, [onUpdateStats, stats]);

  useEffect(() => {
    onUpdateLoading(loading);
  }, [loading, onUpdateLoading]);

  const handleChangeCategoriesView = useCallback(
    async (view: ProductCategoryType) => {
      if (defaultCompanyUuid) {
        setView(view);

        localStorage.setItem(LocalStorageItems.productCategoryView, view);
      }
    },
    [defaultCompanyUuid],
  );

  return (
    <>
      <AppHeader
        headerRight={
          <ProductCreateButton
            disabled={!manage}
            loading={loading}
            onSuccess={handleCreateProduct}
            onSuccessCategory={handleCreateProductCategory}
            onCancel={daleyFocus}
          />
        }
        headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.products]))}
        headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
        routes={routes}
      />

      <ListContent>
        <StyledListHeader>
          <ListInputHeader ref={listRef}>
            <ListActions
              inputRef={inputRef}
              searchText={keywords}
              withoutPicker
              loading={loading}
              handleSearch={async (keywords) =>
                await handleSearchProductCategories({keywords})
              }
              inputTooltip={t('Search')}
              inputLabel={t('Search')}
              withSearchContent={
                <StyledProductCreateButton
                  type="primary"
                  tooltipTitle={t('Create category')}
                  disabled={!manage}
                  loading={loading}
                  onSuccessCategory={handleCreateProductCategory}
                  title={t('Create category')}
                  icon={<PlusOutlined />}
                  className="category-create-event"
                  onCancel={daleyFocus}
                />
              }
            />
          </ListInputHeader>

          <Segmented
            options={CATEGORIES_VIEW}
            disabled={loading}
            value={view}
            onChange={async (view: any) => {
              await handleChangeCategoriesView(view);
            }}
          />
        </StyledListHeader>

        <ListEmpty
          loading={loading && !categoryList?.size}
          empty={
            categoryList?.size ? null : (
              <Empty
                description={t(
                  `It looks like you don't have any ${ProductType.Category} at the moment.`,
                )}>
                <ProductCreateButton
                  type="primary"
                  tooltipTitle={t('Create category')}
                  disabled={!manage}
                  loading={loading}
                  onSuccessCategory={handleCreateProductCategory}
                  onCancel={daleyFocus}>
                  {t('Create category')}
                </ProductCreateButton>
              </Empty>
            )
          }>
          <Table<ProductCategoryModel>
            rowSelection={rowSelection}
            total={total}
            pageSize={pageSize}
            dataSource={categoryList}
            onChange={handleRefreshProductCategories}
            columns={categoryColumns}
            loading={loading}
            page={page}
            pagination={view !== 'tree'}
          />
        </ListEmpty>
      </ListContent>

      <Suspense fallback={<SuspenseEmpty />}>
        <ProductSideWindowLazy
          loading={loading as boolean}
          onSuccess={handleCreateProduct}
          onCancel={productHandleCancel}
          visible={productVisible}
          product={ProductMapper.toProductFormDTO(
            {
              category,
            } as ProductModel,
            {addAnotherProduct: true, disabledCategory: false},
          )}
        />
      </Suspense>
    </>
  );
}
