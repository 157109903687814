import React, {useCallback} from 'react';
import {Empty} from 'antd';
import {useTranslation} from 'react-i18next';
import {
  useStoredSettingsModuleList,
  useStopLoading,
  useStoredCompanies,
} from '../../../hooks';
import {textToUpperCase} from '../../../services/helpers';
import {
  Table,
  TableActionCell,
} from '../../../components/lib/libV2/DataDisplay';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import {SettingsModuleModel} from '../../../struture';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '../../../services/types';
import {HeaderLeft, ListActions} from '../../../components/lib/DataDisplay';
import {useLocation, useNavigate} from 'react-router';
import {ListLayout} from '../../../components/lib/Layout';
import {SettingsModuleListItemActions} from '../Show';
import {SETTING_TABS} from '../../Settings';

export interface ISettingsModuleListPageProps {}

export function SettingsModuleListPage({}: ISettingsModuleListPageProps): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const {defaultCompanyUuid} = useStoredCompanies();

  const {
    settingsModuleList,
    loading: loadingSettingsModuleList,
    loadingMore,
    total,
    error: errorSettingsModuleList,

    handleSearchSettingsModules,
    handleDeactivateModule,
    handleActivateModule,
  } = useStoredSettingsModuleList();

  const loading = useStopLoading({
    loading: loadingSettingsModuleList || loadingMore,
    error: errorSettingsModuleList,
    message: 'An error occurred during settings module list loading',
  });

  const routes = useBreadcrumb([
    {
      path: `/${Routes.settings}/${SETTING_TABS.PARAMETERS}/${Routes.modules}`,
      breadcrumbName: 'Modules',
    },
  ]);

  const columns = [
    {
      ellipsis: true,
      title: t('Title'),
      key: 'tile',
      render: (settingsModuleModel: SettingsModuleModel) =>
        settingsModuleModel?.module_title,
    },
    {
      ellipsis: true,
      title: t('Description'),
      key: 'description',
      render: (settingsModuleModel: SettingsModuleModel) =>
        settingsModuleModel?.module_description,
    },
    {
      ellipsis: true,
      title: t('Actions'),
      key: 'service_actions',
      align: 'center' as any,
      fixed: 'right' as any,
      width: 250,
      render: (settingsModuleModel: SettingsModuleModel) => {
        return (
          <TableActionCell>
            <SettingsModuleListItemActions
              settingsModule={settingsModuleModel}
              handleActivateModule={handleActivateModule}
              handleDeactivateModule={handleDeactivateModule}
            />
          </TableActionCell>
        );
      },
    },
  ];

  const from = location?.state?.prevState
    ? location?.state?.prevState?.from
    : location?.state?.from;

  const navigateGoBack = useCallback(() => {
    navigate(
      from ? from : `/${Routes.app}/${Routes?.companies}/${defaultCompanyUuid}`,
      {
        state: {prevState: location?.state},
      },
    );
  }, [defaultCompanyUuid, from, location?.state, navigate]);

  return (
    <ListLayout
      aclItem={RoutesAcl[Routes.services]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.modules]))}
      routes={routes}
      empty={
        settingsModuleList?.size ? null : (
          <Empty description={t('There are no system module created')} />
        )
      }
      headerExtra={
        <ListActions
          withoutPicker
          loading={loading}
          handleSearch={handleSearchSettingsModules}
          inputTooltip={t('Search system modules')}
          inputLabel={t('Search system modules')}
        />
      }
      loading={loading && !settingsModuleList?.size}>
      <Table<SettingsModuleModel>
        pageSize={Infinity}
        total={total}
        dataSource={settingsModuleList}
        columns={columns}
        loading={loading}
      />
    </ListLayout>
  );
}
