import {DateType} from '../../helpers';
import {ClientModel, ClientOrgType, ClientDiscountType} from '../internal';
import {DATE_SHORT_FORMAT, GENDER} from '../type';
import {toDateByFormat} from '../date';

export class ClientFormDTO {
  public uuid: string;

  public first_name: string;

  public middle_name: string;

  public last_name: string;

  public phone: string;

  public email: string;

  public address: string;

  public birthday: DateType;

  public gender: GENDER;

  public client_org_type: ClientOrgType;

  public client_discount: string;

  public client_discount_type: ClientDiscountType;

  public client_comment: string;

  public client_is_provisioner: boolean;

  constructor(props?: ClientModel) {
    this.uuid = props?.uuid || '';
    this.first_name = props?.first_name || '';
    this.middle_name = props?.middle_name || '';
    this.last_name = props?.last_name || '';
    this.phone = props?.phone || '';
    this.email = props?.email || '';
    this.address = props?.address || '';
    this.birthday = props?.birthday || '';
    this.gender = props?.gender || GENDER.MALE;
    this.client_discount = props?.client_discount || '';
    this.client_discount_type =
      props?.client_discount_type || ClientDiscountType.Fixed;
    this.client_comment = props?.client_comment || '';
    this.client_org_type = props?.client_org_type || ClientOrgType.PERSON;
    this.client_is_provisioner =
      typeof props?.client_is_provisioner === 'boolean'
        ? props?.client_is_provisioner
        : false;
  }

  birthdayToString(birthday: DateType) {
    return toDateByFormat(birthday, DATE_SHORT_FORMAT);
  }

  get fullName(): string {
    return `${this.first_name} ${this.last_name}`;
  }
}
