import {
  ISettingsModuleDependencyDTOProps,
  SettingsModuleDependencyMapper,
  SettingsModuleStatuses,
  CompanyModuleModel,
} from '../internal';

export interface ICompanyModuleDTOProps {
  module: string;
  enabled: SettingsModuleStatuses;
  dependencies: ISettingsModuleDependencyDTOProps[];
}

export class CompanyModuleDTO {
  public module: string;
  public enabled: SettingsModuleStatuses;
  public dependencies: ISettingsModuleDependencyDTOProps[];

  constructor(props: ICompanyModuleDTOProps | CompanyModuleModel) {
    this.module = props?.module || '';
    this.enabled =
      typeof props?.enabled === 'number'
        ? props?.enabled
        : SettingsModuleStatuses.Disabled;
    this.dependencies =
      SettingsModuleDependencyMapper.toSettingsModuleDependencyListDTO(
        props?.dependencies,
      );
  }
}
