import {PlanModel} from '../internal';
import {toDateByFormat} from '../date';

export class PlanTariffFormDTO {
  public uuid: string;

  public identifier: string;

  public package: string;

  public comment: string;

  public amount: number;

  public start_date: string;

  constructor(props?: PlanModel) {
    this.uuid = props?.id || '';
    this.identifier = props?.identifier || '';
    this.package = '';
    this.comment = '';
    this.amount = '3' as any;
    this.start_date = toDateByFormat(new Date(), 'YYYY-MM-DD');
  }
}
