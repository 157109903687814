import {Dispatch} from 'redux';
import {PRODUCT_CATEGORY} from '../constants';
import {ProductCategoryModel} from '../../struture';
import {ApiError} from '../../services/types';

export interface ISetProductCategoryParam {
  type: PRODUCT_CATEGORY;
  category: ProductCategoryModel | null;
}

export interface IUpdateProductCategoryParam {
  type: PRODUCT_CATEGORY;
  category: ProductCategoryModel;
}

export interface IResetProductCategoryParam {
  type: PRODUCT_CATEGORY;
}

export interface IErrorProductCategoryParam {
  type: PRODUCT_CATEGORY;
  error: ApiError | undefined;
}

export function setProductCategory(
  category: ProductCategoryModel | null,
): (dispatch: Dispatch) => Promise<ISetProductCategoryParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_CATEGORY.SET_PRODUCT_CATEGORY,
      category,
    });
  };
}

export function updateProductCategory(
  category: ProductCategoryModel,
): (dispatch: Dispatch) => Promise<IUpdateProductCategoryParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_CATEGORY.UPDATE_PRODUCT_CATEGORY,
      category,
    });
  };
}

export function resetProductCategory(): (
  dispatch: Dispatch,
) => Promise<IResetProductCategoryParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_CATEGORY.RESET_PRODUCT_CATEGORY,
    });
  };
}

export function errorProductCategory(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorProductCategoryParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_CATEGORY.ERROR_PRODUCT_CATEGORY,
      error,
    });
  };
}
