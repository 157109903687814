import {useCallback, useEffect, useRef} from 'react';
import {getSystemEventList} from '../services/api/system';
import {ListOptionsProps, isListToArray} from '../services/helpers';
import useCancellablePromise from './useCancellablePromise';
import useStateReducer from './useStateReducer';
import {Event, EventList} from '../services/models';

export interface IUseSystemEventListProps extends Partial<ListOptionsProps> {
  loadOnInit?: boolean;
  showLoading?: boolean;
}

export interface IUseSystemEventListReturnType {
  error: Error | null;
  loading: boolean;
  list: Event[];
  refresh: (
    value: Partial<IUseSystemEventListProps>,
  ) => Promise<Event[] | void>;
  total: number;
  offset: number;
  limit: number;
  order: string;
  filters: string[];
}

/**
 * @desc Список Співробітників Компанії
 * */
export default function useSystemEventList(
  {
    offset = 0,
    order = 'created_at DESC',
    limit = 20,
    filters = [],
    loadOnInit = true,
  }: IUseSystemEventListProps = {} as IUseSystemEventListProps,
): IUseSystemEventListReturnType {
  const {cancellablePromise} = useCancellablePromise();
  const didCancel = useRef<boolean>();

  const {
    offset: listPage,
    order: listOrder,
    limit: listPageSize,
    filters: listFilters,
    handleUpdate,
    ...rest
  } = useStateReducer<Omit<IUseSystemEventListReturnType, 'refresh'>>({
    error: null,
    loading: true,
    list: [],
    offset,
    limit,
    order,
    filters,
    total: 0,
  });

  const refresh = useCallback(
    async (
      {
        offset = listPage,
        order = listOrder,
        limit = listPageSize,
        filters = listFilters,
        showLoading = true,
        ...rest
      }: Partial<IUseSystemEventListProps> = {} as IUseSystemEventListProps,
    ): Promise<Event[] | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          offset,
          limit,
          order,
          filters,
        });

        const {events, total} = await cancellablePromise<EventList>(
          getSystemEventList(),
        );

        const list = isListToArray(events);
        if (!didCancel.current) {
          handleUpdate({
            list,
            loading: false,
          });

          return list;
        }
      } catch (error: any) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    didCancel.current = false;

    if (loadOnInit) {
      (async () => {
        await refresh();
      })();
    }

    return () => {
      didCancel.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit]);

  return {
    refresh,
    offset: listPage,
    limit: listPageSize,
    order: listOrder,
    filters,
    ...rest,
  };
}
