import * as React from 'react';
import {getAbonementById} from '../services/api/abonement';
import {AbonementModel, AbonementMapper, AbonementDTO} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {IEntityProps} from '../services/types';
import {useDropdownAlert} from '../contex';
import {useTranslation} from 'react-i18next';

export interface IUseAbonementProps extends IEntityProps {
  abonementUuid: string;
}

export interface IUseAbonementReturnType {
  error: null | any;
  loading: boolean;
  entity: AbonementModel | null;
  refresh: (
    value: Partial<IUseAbonementProps>,
  ) => Promise<AbonementModel | void>;
  abonementUuid: string;
}

export function useAbonement(
  {
    loadOnInit = true,
    abonementUuid,
  }: IUseAbonementProps = {} as IUseAbonementProps,
): IUseAbonementReturnType {
  const {cancellablePromise} = useCancellablePromise();
  const {alert} = useDropdownAlert();
  const {t} = useTranslation();

  const {
    entity,
    loading,
    error,
    handleUpdate,
    abonementUuid: entityUuid,
  } = useStateReducer<Omit<IUseAbonementReturnType, 'refresh'>>({
    entity: null,
    abonementUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading,
      abonementUuid = entityUuid,
    }: Partial<IUseAbonementProps> = {}): Promise<AbonementModel | void> => {
      try {
        handleUpdate({loading: showLoading, error: null, abonementUuid});

        const abonement = await cancellablePromise<AbonementDTO>(
          getAbonementById(abonementUuid),
        );

        const abonementModel = AbonementMapper.toAbonementModel(abonement);

        handleUpdate({
          entity: abonementModel,
          loading: false,
        });
        return abonementModel;
      } catch (err: any) {
        alert(
          'error',
          t('TariffModels'),
          `${t('An error occurred during get abonement')} : ${err?.message}`,
        );

        handleUpdate({
          error: err,
          loading: false,
        });
      }
    },

    [alert, cancellablePromise, entityUuid, handleUpdate, t],
  );

  React.useEffect(() => {
    if (loadOnInit && abonementUuid) {
      (async () => {
        await refresh({abonementUuid});
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, abonementUuid]);

  return {
    error,
    loading,
    refresh,
    entity,
    abonementUuid,
  };
}
