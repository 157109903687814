import {Record} from 'immutable';

export interface IClientSubscriptionTransitionProps {
  balanceFromPreviousSubscription: number;
  priceOfNewSubscription: number;
  surcharge: number;
  moveToPersonalAccount: number;
}

/**
 * @class Client
 * @desc Клієнт
 */
export default class ClientSubscriptionTransition extends Record({
  balanceFromPreviousSubscription: 197.89,
  priceOfNewSubscription: 560,
  surcharge: 362.11,
  moveToPersonalAccount: 0,
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(
    props: IClientSubscriptionTransitionProps = {} as IClientSubscriptionTransitionProps,
  ) {
    super({...props});
  }
}
