import {
  ClientFormDTO,
  ClientLegalFormDTO,
  ClientMapper,
  ClientModel,
  StoreDocumentItemDTO,
  StoreDocumentItemModel,
  StoreDocumentMapper,
  StoreDocumentModel,
  StoreDocumentStatus,
  StoreDocumentType,
  StoreModel,
  StoreDocumentMultiCurrencyDTO,
  StoreDocumentExtraServiceDTO,
  StorePaymentDocumentDiscountType,
  StorePaymentDocumentDiscountSourceType,
  StoreDocumentProfitStatsDTO,
} from '../internal';
import {Moment} from 'moment';
import {toMoment, listToArray, correctPrice} from '../../services/helpers';
import {StoreDocumentItemFormDTO} from './StoreDocumentItemFormDTO';
import {List} from 'immutable';
import {immerable} from 'immer';

export enum StoreDocumentPostActionType {
  SET_TRIGGEBLE_ORDER_TYPE = 'set_triggerable_order_type',
}

export interface IStoreDocumentPostAction {
  action: StoreDocumentPostActionType;
  value: string;
}

export interface IStoreDocumentAdditionalParams {
  editMode?: boolean;
  doc_post_action?: IStoreDocumentPostAction | null;
  default_doc_items?: List<StoreDocumentItemModel> | StoreDocumentItemDTO[];
  final?: boolean;
  store_uuid?: string;
  client?: ClientModel | null;
}

export const DEFAULT_EXTRA_SERVICES: StoreDocumentExtraServiceDTO[] = [
  {title: '', price: correctPrice(0)},
];

export const DEFAULT_MULTI_CURRENCY: StoreDocumentMultiCurrencyDTO[] = [
  {title: 'USD', rate: correctPrice(0)},
  {title: 'EURO', rate: correctPrice(0)},
];

export class StoreDocumentFormDTO {
  [immerable]? = true;

  public uuid: string;
  public doc_store_uuid: StoreModel | string;
  public doc_destination_store_uuid: StoreModel | string;
  public doc_client:
    | ClientModel
    | {uuid: string}
    | ClientFormDTO
    | ClientLegalFormDTO;
  public doc_input_number: string;
  public doc_related_document_uuid: StoreDocumentModel | string;
  public doc_date: string | Moment;
  public doc_comment: string;
  public doc_type: StoreDocumentType;
  public doc_status: StoreDocumentStatus;
  public doc_items: StoreDocumentItemFormDTO[];
  public doc_is_paymentable?: boolean;
  public doc_post_action?: IStoreDocumentPostAction | null;
  public is_final_save?: boolean;
  public default_doc_items?: StoreDocumentItemFormDTO[];
  public is_doc_extra_services?: boolean;
  public doc_extra_services?: StoreDocumentExtraServiceDTO[];
  public is_doc_multi_currencies?: boolean;
  public doc_multi_currencies?: StoreDocumentMultiCurrencyDTO[];
  public doc_discount_type?: StorePaymentDocumentDiscountType;
  public doc_discount_value?: string;
  public doc_discount_source?: StorePaymentDocumentDiscountSourceType;
  public is_apply_doc_discount?: boolean;
  public stats?: StoreDocumentProfitStatsDTO;

  constructor(
    props: StoreDocumentModel,
    {
      editMode,
      doc_post_action,
      default_doc_items,
      final,
      store_uuid,
      client,
    }: IStoreDocumentAdditionalParams,
  ) {
    this.uuid = props?.uuid || '';
    this.doc_input_number = props?.doc_input_number || '';
    this.doc_date = props?.doc_date
      ? toMoment(props?.doc_date)
      : toMoment(new Date());
    this.doc_comment = props?.doc_comment || '';
    this.is_doc_extra_services = !!props?.doc_extra_services?.length;
    this.doc_extra_services = final
      ? undefined
      : props?.doc_extra_services?.length
      ? props?.doc_extra_services
      : [];
    this.is_doc_multi_currencies = !!props?.doc_multi_currencies?.length;
    this.doc_multi_currencies = final
      ? undefined
      : props?.doc_multi_currencies?.length
      ? props?.doc_multi_currencies
      : [];
    this.doc_items = final
      ? (undefined as any)
      : listToArray(
          props?.doc_items?.map((item) =>
            StoreDocumentMapper.toStoreDocumentItemFormDTO(item, {
              editMode: !!editMode,
              max_amount: default_doc_items
                ? item?.item_product_amount
                : undefined,
            }),
          ),
        );
    this.default_doc_items = listToArray(
      (default_doc_items || [])?.map((item) =>
        StoreDocumentMapper.toStoreDocumentItemFormDTO(item, {
          editMode: !!editMode,
          max_amount: default_doc_items ? item?.item_product_amount : undefined,
        }),
      ),
    );
    this.doc_related_document_uuid = final
      ? props?.documentModel?.uuid || ''
      : props?.documentModel?.uuid
      ? props?.documentModel
      : '';
    this.doc_type = props?.doc_type || '';
    this.doc_status = props?.doc_status_text || StoreDocumentStatus.DRAFT;
    this.doc_store_uuid =
      store_uuid ||
      (final
        ? props?.companyStoreModel?.uuid || ''
        : props?.companyStoreModel?.uuid
        ? props?.companyStoreModel
        : '');
    this.doc_destination_store_uuid = final
      ? props?.companyDestinationStoreModel?.uuid || ''
      : props?.companyDestinationStoreModel?.uuid
      ? props?.companyDestinationStoreModel
      : '';

    this.doc_client = client
      ? client
      : final
      ? props?.client?.uuid
        ? {uuid: props?.client?.uuid}
        : {uuid: ''}
      : editMode
      ? props?.client?.uuid
        ? props?.client instanceof ClientModel
          ? props?.client
          : ClientMapper.toClientModel(props?.client as any)
        : {uuid: ''}
      : {uuid: ''};

    this.doc_is_paymentable =
      typeof props?.doc_is_paymentable === 'boolean'
        ? props?.doc_is_paymentable
        : false;

    this.doc_post_action = doc_post_action || null;
    this.is_final_save = true;
    this.doc_discount_type = props?.doc_discount_type;
    this.doc_discount_value = props?.doc_discount_value;
    this.doc_discount_source = props?.doc_discount_source;
    this.is_apply_doc_discount = !!props?.doc_discount_value;
    this.stats = props?.stats;
  }
}
