import {CashBoxModel, EmployeeModel} from '../internal';
import {Moment} from 'moment';
import {correctPrice, toMoment} from '../../helpers';

export class CashBoxAdjustBalancesFormDTO {
  public uuid: string;

  public date: string | Moment;

  public comment: string;

  public sum: string;

  public cashier_uuid: string | EmployeeModel;

  constructor(props?: CashBoxModel) {
    this.uuid = props?.uuid || '';
    this.date = toMoment(new Date());
    this.comment = '';
    this.sum = correctPrice(props?.box_balance);
    this.cashier_uuid = props?.cashier?.uuid ? props?.cashierModel : '';
  }
}
