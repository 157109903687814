import './ErrorPage.less';
import React from 'react';
import {cdnUrl} from '../const';
import {Link} from 'react-router-dom';
import {Button} from 'antd';

// eslint-disable-next-line no-func-assign
export default ErrorPage;

ErrorPage.ERROR_TYPE_403 = '403';
ErrorPage.ERROR_TYPE_404 = '404';
ErrorPage.ERROR_TYPE_500 = '500';

/**
 * @name ErrorPage
 */
function ErrorPage({type = ErrorPage.ERROR_TYPE_404}) {
  let errorDesc, altText;

  switch (type) {
    case ErrorPage.ERROR_TYPE_403:
      errorDesc = 'Sorry, you are not authorized to access this page.';
      altText = 'Unauthorized';
      break;
    case ErrorPage.ERROR_TYPE_500:
      errorDesc =
        'Sorry, there is something wrong in our servers and we cannot complete your request.';
      altText = 'Internal Server Error';
      break;
    case ErrorPage.ERROR_TYPE_404:
    default:
      errorDesc = 'Sorry, the page you visited does not exist.';
      altText = 'Not Found';
  }
  return (
    <div className={'ErrorPage'}>
      <img src={`${cdnUrl}/error/${type}.png`} alt={altText} />
      <h1>{type}</h1>
      <p>{errorDesc}</p>
      <p>
        <Link to={'/'}>
          <Button type={'primary'}>Back Home</Button>
        </Link>
      </p>
    </div>
  );
}
