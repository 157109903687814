import {Record} from 'immutable';
import {MomentDate} from '../helpers';

export interface ICompanySettingProps {
  uuid: string;
  created_at: string;
  updated_at: string;
  setting_key: string;
  setting_value: string;
}

/**
 * @class Client
 * @desc Клієнт
 */
export default class CompanySetting extends Record({
  uuid: '',
  created_at: MomentDate.format(MomentDate.currentDate()),
  updated_at: MomentDate.format(MomentDate.currentDate()),
  setting_key: '',
  setting_value: '',
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(props: ICompanySettingProps = {} as ICompanySettingProps) {
    super(props);
  }
}
