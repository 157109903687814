import React, {forwardRef} from 'react';
import styled from 'styled-components';

export interface IListHeaderProps {
  children: React.ReactNode | React.ReactNode[];
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  width: 100%;
`;

export const ListHeader = forwardRef(function ListHeader(
  {children}: IListHeaderProps,
  ref: any,
): React.JSX.Element {
  return <Header ref={ref}>{children}</Header>;
});
