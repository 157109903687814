import {Map} from 'immutable';

/**
 * @class Timestamp
 * @classdesc
 * Server timestamp model.
 * @extends {Date}
 */
export class Timestamp extends Date {
  /**
   * @constructor
   * @param {{seconds:number,nanos:number}} props
   */
  constructor(props = {seconds: 0, nanos: 0}) {
    super(props.seconds * 1000);
  }
}

/**
 * Returns new Timestamp for seconds >= 0, otherwise it returns null
 * @param props
 * @param defaultTimestamp
 * @returns {null|Timestamp}
 */
export function toTimestamp(
  props = {seconds: -1, nanos: -1},
  defaultTimestamp = '',
) {
  // TODO: some day we will be able to remove this negative seconds check
  // currently we cannot easily migrate current data in backend to remove negative dates
  // 2019-08-29 06:37:00 UYT
  if (!props || props.seconds < 0) {
    if (defaultTimestamp) {
      return defaultTimestamp;
    }

    return null;
  }
  return new Timestamp(props);
}

/**
 * @name toImmutableMap
 * @desc Convert object to an immutable map of constructors.
 * @param {Constructor} constructor
 * @param {{[string]:object}} obj
 * @param {string} key
 * @returns {Map}
 * @public
 */
export function toImmutableMap(constructor, obj = {}, key = 'id') {
  // some comment
  const ids = Object.keys(obj);

  const value = Object.values(obj).reduce((data, item, index) => {
    const id = item[key] || ids[index];
    if (!id) {
      throw new Error(`could not determine id for ${item}`);
    }
    data[id] = new constructor(item);
    return data;
  }, {});

  // @ts-ignore
  return new Map(value);
}
