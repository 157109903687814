import moment from 'moment';
import {Record} from 'immutable';
import 'moment/locale/uk';
import 'moment/locale/ru';
import 'moment/locale/en-ca';

export const DEFAULT_TIME_FIRST_FORMAT = 'hh:mm:ss DD/MM/YYYY';
export const DATE_SHORT_FORMAT = 'YYYY-MM-DD';
export const TIME_SHORT_FORMAT = 'HH:mm';
export const DEFAULT_FULL_FORMAT = `${DATE_SHORT_FORMAT} ${TIME_SHORT_FORMAT}`;

// @ts-ignore
export const DateAsObject = new Record({
  day: null,
  month: null,
  year: null,
});

export class MomentDate {
  private moment: (date?) => moment.Moment;

  constructor(language = 'en-ca') {
    this.moment = (date) => moment(date).locale(language);
  }

  /**
   * @desc Форматуємо вивід дати
   * @param {Object} date
   * @param {String} defaultFormat
   * return {Moment}
   * */
  static format(date = moment(), defaultFormat = DEFAULT_FULL_FORMAT) {
    return moment(date).format(
      defaultFormat === null ? undefined : defaultFormat,
    );
  }

  /**
   * @desc Поточна дата
   * return {Moment}
   * */
  static currentDate() {
    return moment();
  }

  /**
   * @desc Поточний місяць
   * */

  get TODAY_MONTH() {
    return this.moment().format('MMMM');
  }

  /**
   * @desc Повернення дати початку поточного місяця
   * */

  get startOfMonth() {
    return this.moment().startOf('month').format('YYYY-MM-DD');
  }

  /**
   * @desc Повернення дати кінця поточного місяця
   * */

  get endOfMonth() {
    return this.moment().endOf('month').format('YYYY-MM-DD');
  }

  /**
   * @desc Розбиває дату на частини
   * @param {String} date
   * @return {Object}
   * */

  splitDateOnParts(date) {
    if (!date) return new DateAsObject({});

    const arrDate = this.moment(date).format('l').split('-');

    if (Array.isArray(arrDate) && arrDate.length > 1) {
      return new DateAsObject({
        day: arrDate[0],
        month: arrDate[1],
        year: arrDate[2],
      });
    }

    return new DateAsObject({});
  }

  /**
   * @desc Побудова дати
   * @param {Number} day
   * @param {Number} month
   * @param {Number} year
   * @param {String} format
   * @return {String}
   * */

  buildDate(day, month, year, format = 'YYYY-MM-DD') {
    return this.moment().day(day).year(year).month(month).format(format);
  }

  /**
   * @desc Побудова дати
   * @param {Number} day
   * @param {Number} month
   * @param {Number} year
   * @param {String} format
   * @return {String}
   * */
  buildNewDate(day, month, year, format = 'YYYY-MM-DD') {
    return this.moment([year, month - 1, day]).format(format);
  }

  /**
   * @desc Повернення дати у вказаному форматі
   * @param {Date} date
   * @param {String} format
   * @return {String}
   * */

  buildDateFormat(date, format = 'DD.MM.YYYY - H:mm') {
    return this.moment(date).format(format);
  }

  /**
   * @desc Повернення масиву дат за вибраний період
   * @param {Date} start
   * @param {Date} stop
   * @return {Array}
   * */

  getDatesByPeriod(start, stop) {
    const dateArray = [];
    let currentDate = start;

    while (new Date(currentDate) <= new Date(stop)) {
      dateArray.push(this.moment(currentDate).format('YYYY-MM-DD'));
      currentDate = this.moment(currentDate).add(1, 'days');
    }
    return dateArray;
  }

  /**
   * @desc Повернення дати з доданим періодом
   * @param {Date} date
   * @param {Number} dayPlus
   * @param {String} unit що додавати день, місяць, рік
   * @param {String} format
   * @return {String}
   * */

  getPlusDate(date, dayPlus, unit = 'days', format = 'YYYY-MM-DD') {
    return this.moment(date).add(dayPlus, unit).format(format);
  }

  /**
   * @desc Повернення дати з відмінусованим періодом
   * @param {Date} date
   * @param {Number} number
   * @param {String} unit що додавати день, місяць, рік
   * @param {String} format
   * @return {String}
   * */

  getSubtractDate(date, number, unit = 'days', format = 'YYYY-MM-DD') {
    return this.moment(date).subtract(number, unit).format(format);
  }

  /**
   * @desc Сьогоднішня дата
   * */
  get TODAY() {
    return this.buildDateFormat(new Date(), 'YYYY-MM-DD');
  }

  /**
   * @desc Сьогоднішнє число
   * */
  get TODAY_NUMBER() {
    return this.buildDateFormat(new Date(), 'DD');
  }
}
