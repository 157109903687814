import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {IActionButtonProps} from '../../../components/lib/General';
import {useModal} from '../../../components/lib/libV2/hooks';
import {AddButton, SuspenseEmpty} from '../../../components/lib/DataDisplay';
import {IoIosCash} from 'react-icons/io';
import {ClientOperationFormDTO} from '../../../struture';

const ClientBalanceOperationSideWindowLazy = React.lazy(
  () => import('../Show/ClientBalanceOperationSideWindow'),
);

export interface IClientBalanceOperationButtonProps
  extends Omit<IActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: ClientOperationFormDTO) => Promise<void>;
  onCancel?: () => void;
  balance: ClientOperationFormDTO;
}

export function ClientBalanceOperationButton({
  children,
  onSuccess,
  onCancel,
  loading,
  balance,
  ...rest
}: IClientBalanceOperationButtonProps): React.JSX.Element {
  const {t} = useTranslation();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <AddButton
        tooltipTitle={t('Adjustment')}
        onClick={handleOnInit}
        loading={loading}
        {...rest}>
        <IoIosCash size={18} />
      </AddButton>

      <Suspense fallback={<SuspenseEmpty />}>
        <ClientBalanceOperationSideWindowLazy
          balance={balance}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}
