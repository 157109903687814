import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {Space} from 'antd';
import {ClientModel, ClientPaymentFormDTO} from '../../../struture';
import {OverViewUnderHeaderContainer} from '../../../components/lib/Layout';
import {IProfileBalanceParam} from '../../../services/api/user';
import {useSize} from '../../../hooks';
import styled, {css} from 'styled-components';
import {BalanceButton} from '../Buttons';
import {isFunction} from '@sportix/sportix-common-modules';

export interface IClientBalanceHeaderProps {
  client: ClientModel | null;
  children?: React.ReactNode;
  handleUpdateBalance?: (value: ClientPaymentFormDTO) => Promise<void>;
  extra?: React.ReactNode | React.ReactNode[];
  disabled?: boolean;
  title?: string;
}

const StyledSpace = styled(Space)<{$isFullWith: boolean}>`
  ${({$isFullWith}) =>
    $isFullWith &&
    css`
      &&& {
        width: 100%;

        & .ant-space-item {
          width: 100%;
        }
      }
    `}
`;

const StyledAccountTopUpButton = styled(BalanceButton)<{
  $isFullWith: boolean;
}>`
  ${({$isFullWith}) =>
    $isFullWith &&
    css`
      width: 100%;
      margin-top: 15px;
    `}
`;
export function ClientBalanceHeader({
  client,
  children,
  handleUpdateBalance,
  extra,
  disabled,
  title,
}: IClientBalanceHeaderProps): JSX.Element {
  const actionsRef = useRef(null);
  const {width} = useSize(actionsRef);
  const {t} = useTranslation();

  return (
    <div ref={actionsRef}>
      <OverViewUnderHeaderContainer
        title={`${title || t('Balance')} ${client?.saldo || '0.00'} ${t(
          'UAH',
        )}`}
        extra={
          <>
            {isFunction(handleUpdateBalance) ? (
              <StyledSpace $isFullWith={width <= 300}>
                <StyledAccountTopUpButton
                  disabled={disabled}
                  $isFullWith={width <= 300}
                  onSuccess={handleUpdateBalance}
                  client={client}>
                  {t('Balance operations')}
                </StyledAccountTopUpButton>
                {extra || null}
              </StyledSpace>
            ) : null}
          </>
        }>
        {children}
      </OverViewUnderHeaderContainer>
    </div>
  );
}
