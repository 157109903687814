import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {useTranslation} from 'react-i18next';
import {
  DefaultForm,
  IDefaultFormProps,
} from '../../../../components/lib/General';
import {
  StoreDocumentFormDTO,
  StoreDocumentModel,
  StoreDocumentStatus,
  StoreDocumentType,
  StoreModel,
} from '../../../../struture';
import {useDropdownAlert} from '../../../../contex';
import {
  IUseStateStoredDocumentReturnType,
  useStateCompanyClientList,
  useStoredCompanies,
  useStoredStoreDocumentList,
} from '../../../../hooks';
import {Form} from 'antd';
import {List} from 'immutable';
import {StoreReturnClientFields} from './StoreReturnClientFields';
import {useTheme} from 'styled-components';
import {isFunction} from '../../../../services/helpers';
import {produce} from 'immer';
import {StoreDocumentRefObject} from '../../helpers';

export interface IStoreReturnClientFormProps
  extends Omit<
      IDefaultFormProps<StoreDocumentFormDTO, StoreDocumentFormDTO>,
      'children' | 'initialValues' | 'additionalValuesRequest' | 'onSuccess'
    >,
    Pick<IUseStateStoredDocumentReturnType, 'handlePaymentStoreDocument'> {
  loading?: boolean;
  document: StoreDocumentFormDTO;
  onSuccess: (
    value: StoreDocumentFormDTO,
    closeble?: boolean,
  ) => Promise<StoreDocumentModel | void>;
  fromList: boolean;
  from?: StoreDocumentType;
  documentEditRef?: MutableRefObject<StoreDocumentRefObject>;
}

export function StoreReturnClientForm({
  loading,
  document,
  editMode,
  onSuccess,
  onCancel,
  handlePaymentStoreDocument,
  fromList,
  from,
  documentEditRef,
  ...rest
}: IStoreReturnClientFormProps): React.JSX.Element {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const {defaultCompanyUuid} = useStoredCompanies();
  const theme: any = useTheme();
  const [instance] = Form.useForm();

  const once = useRef(false);

  const additionalValues = useMemo(
    () => ({
      uuid: document?.uuid,
      ...(from === StoreDocumentType.IN
        ? {
            doc_store_uuid:
              document?.doc_store_uuid instanceof StoreModel
                ? document?.doc_store_uuid?.uuid
                : document?.doc_store_uuid,
          }
        : []),
    }),
    [document?.doc_store_uuid, document?.uuid, from],
  );

  const {
    clients,
    loading: clientsLoading,
    handleSearchClients,
    handleCreateLocalClient,
  } = useStateCompanyClientList({
    loadOnInit: true,
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const {
    documentList,
    keywords: documentListKeywords,
    loading: documentListLoading,
    handleSearchStoreDocuments,
  } = useStoredStoreDocumentList({
    companyUuid: defaultCompanyUuid,
    loadOnInit: false,
    documentType: StoreDocumentType.OUT,
  });

  useEffect(() => {
    if (!List.isList(documentList)) {
      (async () => {
        await handleSearchStoreDocuments({
          keywords: '',
          start_date: null as any,
          end_date: null as any,
        });
      })();
    }
  }, [documentList, handleSearchStoreDocuments]);

  const notifyError = useCallback(
    (ApiError: any) => {
      alert(
        'error',
        t('Store'),
        `${
          editMode
            ? t('An error occurred during edit store return client')
            : t('An error occurred during create store return client')
        } : ${ApiError?.message}`,
      );
    },
    [alert, editMode, t],
  );

  useEffect(() => {
    if (!documentListLoading && documentListKeywords && !once?.current) {
      once.current = true;
      (async () => {
        await handleSearchStoreDocuments({keywords: ''});
      })();
    }
    if (!documentListLoading && !documentListKeywords && !once?.current) {
      once.current = true;
    }
  }, [handleSearchStoreDocuments, documentListKeywords, documentListLoading]);

  const handleOnSuccess = useCallback(
    async (value: StoreDocumentFormDTO) => {
      if (isFunction(onSuccess)) {
        const updatedValue = produce(value, (draft) => {
          draft.doc_extra_services = undefined;
          draft.doc_multi_currencies = undefined;
          draft.doc_status = StoreDocumentStatus.DRAFT;
        });

        await onSuccess(updatedValue);
      }
    },
    [onSuccess],
  );

  return (
    <DefaultForm<StoreDocumentFormDTO, StoreDocumentFormDTO>
      formKeyboardCodes={['Tab']}
      isResetLoading
      withContext
      instance={instance}
      initialValues={document}
      onCancel={onCancel}
      onSuccess={handleOnSuccess}
      additionalValuesRequest={additionalValues}
      editMode={editMode}
      notifyError={notifyError}
      showNotify={false}
      showCancelButton={false}
      submitButtonText={t('Save as draft')}
      submitButtonProps={{style: {backgroundColor: theme.colors.primary}}}
      {...rest}>
      <StoreReturnClientFields
        from={from}
        editMode={!!editMode}
        defaultEditMode={!!editMode}
        loading={!!loading}
        clients={clients}
        clientsLoading={clientsLoading}
        handleCreateLocalClient={handleCreateLocalClient}
        handleSearchClients={handleSearchClients}
        documentList={documentList}
        documentListLoading={documentListLoading}
        handleSearchStoreDocuments={handleSearchStoreDocuments}
        documentEditRef={documentEditRef}
      />
    </DefaultForm>
  );
}
