import * as React from 'react';
import {
  useClientAbonement,
  IUseClientAbonementProps,
  IUseClientAbonementReturnType,
} from './useClientAbonement';
import {
  ClientAbonementFormDTO,
  ClientAbonementModel,
  ClientAbonementMapper,
  ClientModel,
  ClientRefundsFormDTO,
  ClientAbonementLatestStatusDTO,
  ClientRefundsDTO,
  ClientAbonementDTO,
} from '../struture';
import {
  createClientAbonement,
  editClientAbonement,
  changeClientAbonementNotify,
  refundsClientAbonement,
  calculationRefundsClientAbonement,
  ICalculationRefundsClientAbonementProps,
} from '../services/api/client';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../store/reducers';
import {REDUX_STATUS} from '../services/types';
import {useCallback, useEffect, useState} from 'react';
import {
  addClientAbonementToList as storeAddClientAbonementToList,
  setClientAbonement as storeSetClientAbonement,
  updateClientAbonement as storeUpdateClientAbonement,
  updateClientAbonementFromList as storeUpdateClientAbonementFromList,
  resetClientAbonement as storeResetClientAbonement,
} from '../store/actions';
import {toStringDate} from '../services/helpers';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../contex';
import {useNavigate} from 'react-router';
import {
  dateToIsoString,
  isFunction,
  isThereContent,
} from '@sportix/sportix-common-modules';
import {Moment} from 'moment';
import {IUseClientVisitListProps} from './useClientVisitList';
import useStoredCompanies from './useStoredCompanies';
import {useStoredCashBoxList} from './useStoredCashBoxList';

export interface IUseStateClientAbonementProps
  extends IUseClientAbonementProps {
  clientUuid: string;
  path?: (abonement: ClientAbonementModel) => string;
  handleRefreshClientAbonement?: (
    value: Partial<IUseClientVisitListProps> & {page: number},
  ) => Promise<void>;
}

export interface IUseStateClientAbonementReturnType
  extends Omit<IUseClientAbonementReturnType, 'entity'> {
  abonement: ClientAbonementModel | null;
  latestStatus: ClientAbonementLatestStatusDTO | null;
  handleUpdateClientAbonement: (value: ClientAbonementFormDTO) => Promise<void>;
  updateStoreClientAbonement: (value: ClientAbonementDTO) => void;
  handleCreateClientAbonement: (value: ClientAbonementFormDTO) => Promise<void>;
  handleRefundsClientAbonement: (value: ClientRefundsFormDTO) => Promise<void>;
  handleCalculateRefundsClientAbonement: (
    value: ICalculationRefundsClientAbonementProps,
  ) => Promise<ClientRefundsDTO>;
  handleRefreshClientAbonement: () => Promise<ClientAbonementModel | void>;
  handleChangeClientAbonementNotify: (
    abonementUuid: string,
    send_notification: boolean,
  ) => Promise<ClientAbonementModel | void>;
  handleResetClientAbonement: () => void;
  status: REDUX_STATUS;
  isHasScheduleList: boolean;
}

export function useStoredClientAbonement({
  clientUuid,
  loadOnInit,
  abonementUuid,
  path,
  ...rest
}: IUseStateClientAbonementProps): IUseStateClientAbonementReturnType {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const navigate = useNavigate();
  const {defaultCompanyUuid} = useStoredCompanies();

  const {handleRefreshCashBoxes} = useStoredCashBoxList({
    companyUuid: defaultCompanyUuid,
    loadOnInit: false,
  });

  const [abonement, setClientAbonement] =
    React.useState<ClientAbonementModel | null>(null);
  const [latestStatus, setLatestStatus] =
    React.useState<ClientAbonementLatestStatusDTO | null>(null);
  const [isStarLoading, setIsStartLoading] = React.useState<boolean>(false);
  const [isHasScheduleList, setIsHasScheduleList] = useState(false);

  const {
    status: storedClientAbonementStatus,
    cachedClientAbonementList,
    ...storedClientAbonementParams
  } = useSelector(({clientAbonement}: RootState) => clientAbonement);

  const dispatch = useDispatch<any>();
  const once = React.useRef(false);
  const onceSchedules = React.useRef(false);

  const {entity, refresh, ...abonementParams} = useClientAbonement({
    loadOnInit: loadOnInit || isStarLoading,
    abonementUuid,
    ...rest,
  });

  React.useEffect(() => {
    if (cachedClientAbonementList?.size > 0) {
      const abon = cachedClientAbonementList.get(abonementUuid);

      if (abon) {
        setIsStartLoading(false);
        setClientAbonement(abon?.abonement);
        setLatestStatus(abon?.latest_status);

        if (!onceSchedules.current) {
          onceSchedules.current = true;
          setIsHasScheduleList(
            isThereContent(abon?.abonement?.abon_schedule_list),
          );
        }
      } else {
        setIsStartLoading(true);
      }
    }
    if (cachedClientAbonementList?.size === 0 && !abonement) {
      setIsStartLoading(true);
    }
  }, [cachedClientAbonementList, dispatch, abonementUuid, abonement]);

  useEffect(() => {
    if (entity && isStarLoading && !once.current) {
      dispatch(storeSetClientAbonement(entity));
      setClientAbonement(entity.abonement);
    }
  }, [dispatch, entity, isStarLoading, storedClientAbonementStatus]);

  const updateStoreClientAbonement = useCallback(
    (abonementDTO: ClientAbonementDTO) => {
      const abonementModel = ClientAbonementMapper.toClientAbonementModel(
        abonementDTO,
        latestStatus as any,
      );

      dispatch(storeUpdateClientAbonement(abonementModel));
      dispatch(
        storeUpdateClientAbonementFromList(
          abonementModel.abonement,
          clientUuid,
        ),
      );

      setClientAbonement(abonementModel.abonement);
    },
    [clientUuid, dispatch, latestStatus],
  );

  const handleUpdateClientAbonement = React.useCallback(
    async (value: ClientAbonementFormDTO): Promise<void> => {
      const abonementDTO = await editClientAbonement(value);
      updateStoreClientAbonement(abonementDTO);
    },
    [updateStoreClientAbonement],
  );

  const handleCreateClientAbonement = React.useCallback(
    async (value: ClientAbonementFormDTO) => {
      const clientUuid =
        value?.client_uuid instanceof ClientModel
          ? (value?.client_uuid?.uuid as string)
          : (value?.client_uuid as string);

      const abonementDTO = await createClientAbonement(value, clientUuid);

      const abonementModel =
        ClientAbonementMapper.toClientAbonementModel(abonementDTO).abonement;

      const updatedModel = abonementModel.set(
        'created_at',
        toStringDate(new Date()),
      );

      dispatch(storeAddClientAbonementToList(updatedModel, clientUuid));

      navigate(
        isFunction(path) ? path(abonementModel) : `${abonementModel?.uuid}`,
      );
    },
    [dispatch, navigate, path],
  );

  const handleResetClientAbonement = React.useCallback(() => {
    once.current = true;
    dispatch(storeResetClientAbonement());
  }, [dispatch]);

  const handleRefreshClientAbonement = React.useCallback(async () => {
    const abonementModel = await refresh({showLoading: false, abonementUuid});

    if (abonementModel) {
      dispatch(storeUpdateClientAbonement(abonementModel));
      dispatch(
        storeUpdateClientAbonementFromList(
          abonementModel.abonement,
          clientUuid,
        ),
      );

      setTimeout(async () => {
        await handleRefreshCashBoxes({page: 1, limit: 100});
      }, 300);

      return abonementModel.abonement;
    }
  }, [refresh, abonementUuid, dispatch, clientUuid, handleRefreshCashBoxes]);

  const handleChangeClientAbonementNotify = React.useCallback(
    async (
      abonementUuid: string,
      send_notification: boolean,
    ): Promise<ClientAbonementModel | void> => {
      try {
        const abonementDTO = await changeClientAbonementNotify(
          abonementUuid,
          send_notification,
        );
        const abonementModel =
          ClientAbonementMapper.toClientAbonementModel(abonementDTO);

        if (abonementModel) {
          dispatch(storeUpdateClientAbonement(abonementModel));
          dispatch(
            storeUpdateClientAbonementFromList(
              abonementModel.abonement,
              clientUuid,
            ),
          );
        }

        alert('success', t('Client abonement'), t('Notify change success'));

        return abonementModel.abonement;
      } catch (error: any) {
        alert(
          'error',
          t('Client abonement'),
          `${t(`An error occurred during change client abonement notify`)} : ${
            error?.message
          }`,
        );
      }
    },
    [alert, dispatch, t, clientUuid],
  );

  const handleRefundsClientAbonement = React.useCallback(
    async (value: ClientRefundsFormDTO) => {
      const abonementDTO = await refundsClientAbonement(value);

      const abonementModel = ClientAbonementMapper.toClientAbonementModel(
        abonementDTO.abonement,
        abonementDTO.latest_status,
      );

      if (abonementModel) {
        dispatch(storeUpdateClientAbonement(abonementModel));
        dispatch(
          storeUpdateClientAbonementFromList(
            abonementModel.abonement,
            clientUuid,
          ),
        );
      }
    },
    [clientUuid, dispatch],
  );

  const handleCalculateRefundsClientAbonement = React.useCallback(
    async ({start_date, ...rest}: ICalculationRefundsClientAbonementProps) => {
      return await calculationRefundsClientAbonement(
        {start_date: dateToIsoString(start_date), ...rest},
        abonementUuid,
      );
    },
    [abonementUuid],
  );

  return {
    ...abonementParams,
    ...storedClientAbonementParams,
    abonement,
    loading: !abonement,
    handleUpdateClientAbonement,
    handleCreateClientAbonement,
    status: storedClientAbonementStatus,
    handleResetClientAbonement,
    refresh,
    handleRefreshClientAbonement,
    handleChangeClientAbonementNotify,
    handleRefundsClientAbonement,
    handleCalculateRefundsClientAbonement,
    latestStatus,
    updateStoreClientAbonement,
    isHasScheduleList,
  };
}
