import React, {
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {Col, Form, Input, Row} from 'antd';
import {
  Button,
  DetailsView,
  FormSwitch,
  SearchSelect,
} from '../../../../components/lib/DataDisplay';
import {
  listToArray,
  decryptObject,
  correctPrice,
} from '../../../../services/helpers';
import {
  ClientDiscountType,
  ClientFormDTO,
  ClientLegalFormDTO,
  ClientMapper,
  ClientModel,
  ClientOrgType,
  DEFAULT_EXTRA_SERVICES,
  PriceMarginModel,
  StoreDocumentFormDTO,
  StoreDTO,
  StoreMapper,
  StorePaymentDocumentDiscountSourceType,
  StorePaymentDocumentDiscountType,
} from '../../../../struture';
import {
  IUseStateCompanyClientListReturnType,
  IUseStatePriceMarginListReturnType,
  IUseStateStoreListReturnType,
  useSize,
  useStoredCompanies,
} from '../../../../hooks';
import {useTranslation} from 'react-i18next';
import {useDefaultForm} from '../../../../contex';
import {ClientCreateButton} from '../../../Clients';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import {phoneScreen, SessionStorageItems} from '../../../../services/const';
import {
  StyledDescription,
  StyledTitle,
} from '../../../../components/lib/Styled';
import styled, {useTheme} from 'styled-components';
import {STORE_PAYMENT_DISCOUNT_TYPE} from '../../../../services/api/storeDocument';
import {StoreDocumentRefObject} from '../../helpers';

export interface IStoreRealizationFieldsProps
  extends Pick<
      IUseStateCompanyClientListReturnType,
      'clients' | 'handleSearchClients' | 'handleCreateLocalClient'
    >,
    Pick<IUseStateStoreListReturnType, 'storeList' | 'handleSearchStores'>,
    Pick<
      IUseStatePriceMarginListReturnType,
      'priceMarginList' | 'handleSearchPriceMargins'
    > {
  loading: boolean;
  editMode: boolean;
  clientsLoading: boolean;
  storeListLoading: boolean;
  priceMarginListLoading: boolean;
  setDefaultPriceMargin: React.Dispatch<
    SetStateAction<PriceMarginModel | null>
  >;
  doc_extra_services: any;

  documentEditRef?: MutableRefObject<StoreDocumentRefObject>;
}

const StyledDeleteOutlined = styled(DeleteOutlined)`
  color: ${({theme}) => theme.colors.error};
`;

const StyledDeleteCol = styled(Col)`
  display: flex;
  align-self: center;
`;

export const StyledFieldsContainer = styled(Row)`
  position: relative;
  padding: 20px 20px 0 20px;

  width: 100%;
  margin-bottom: 15px;
  margin-left: 10px !important;
  margin-right: 10px !important;

  border: 1px solid #d9d9d9;
`;

export const Title = styled(StyledTitle)`
  position: absolute;
  top: -14px;
  padding-right: 5px;
  padding-left: 5px;
  background-color: ${({theme}) => theme.background.primary};

  font-size: 16px;
`;

const StyledStatsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const StyledDetailsView = styled(DetailsView)`
  box-shadow: none;
  border-color: #d9d9d9;
  text-align: right;
`;

const StyledSearchSelect = styled(SearchSelect)`
  width: 60px;
`;

export function StoreRealizationFields({
  loading,
  editMode,

  clients,
  clientsLoading,
  handleCreateLocalClient,
  handleSearchClients,

  storeList,
  storeListLoading,
  handleSearchStores,

  priceMarginList,
  priceMarginListLoading,
  handleSearchPriceMargins,
  setDefaultPriceMargin,
  doc_extra_services,

  documentEditRef,
}: IStoreRealizationFieldsProps): React.JSX.Element {
  const {t} = useTranslation();
  const {
    loadingSubmit,
    formData,
    handlerUpdateFormState,
    setFieldsValue,
    getFieldValue,
    validateFields,
  }: any = useDefaultForm();
  const {defaultCompany} = useStoredCompanies();
  const theme: any = useTheme();

  const onceClient = useRef(false);
  const onceStore = useRef(false);
  const contentRef = useRef(null);
  const {width: contentWidth} = useSize(contentRef);
  const isFullWidth = contentWidth <= phoneScreen ? 24 : 12;

  const [client, setClient] = useState<ClientModel | null>(
    formData?.doc_client instanceof ClientModel ? formData?.doc_client : null,
  );

  const onPriceMarginChange = useCallback(
    (priceMarginUuid: string) => {
      const defaultPriceMargin = priceMarginList?.find(
        ({uuid}) => priceMarginUuid === uuid,
      );

      if (defaultPriceMargin) {
        setDefaultPriceMargin(defaultPriceMargin);
      }
    },
    [priceMarginList, setDefaultPriceMargin],
  );

  const handleUpdateClientDiscount = useCallback(
    (client: any) => {
      if (client) {
        setClient(client);

        if (
          client?.client_discount &&
          Number(formData?.doc_discount_value || 0) <= 0
        ) {
          handlerUpdateFormState({
            doc_discount_value: client?.client_discount,
            doc_discount_type: client?.client_discount_type,
            doc_discount_source: StorePaymentDocumentDiscountSourceType.CLIENT,
          });
        }
      }
    },
    [formData?.doc_discount_value, handlerUpdateFormState],
  );

  const handleQuicklyCreateClient = useCallback(
    async ({t, ...value}: any): Promise<void> => {
      const uuid = `create${
        (value as ClientFormDTO)?.first_name ||
        (value as ClientLegalFormDTO)?.client_org_name
      }${value.phone}`;

      const client = await handleCreateLocalClient({
        ...value,
        uuid,
      } as any);

      handleUpdateClientDiscount(client);

      handlerUpdateFormState({
        doc_client: {
          ...value,
          uuid,
        },
      });
    },
    [
      handleCreateLocalClient,
      handleUpdateClientDiscount,
      handlerUpdateFormState,
    ],
  );

  const handleChangeClient = useCallback(
    (e: string): void => {
      onceClient.current = true;

      const client = clients?.find(({uuid}) => uuid === e);

      handleUpdateClientDiscount(client);

      if (
        formData?.doc_client?.uuid !== e ||
        formData?.doc_client instanceof ClientModel
      ) {
        if (e?.includes('create')) {
          if (client) {
            let doc_client;
            if (client?.client_org_type === ClientOrgType.PERSON) {
              doc_client = ClientMapper.toClientFormDTO(client);
            } else {
              doc_client = ClientMapper.toClientLegalFormDTO(client);
            }

            handlerUpdateFormState({doc_client});
          }
          return;
        }

        handlerUpdateFormState({doc_client: {uuid: e}});
      }
    },
    [
      clients,
      formData?.doc_client,
      handleUpdateClientDiscount,
      handlerUpdateFormState,
    ],
  );

  const handleChangeIsApplyPaymentDocDiscount = useCallback(
    (is_apply_doc_discount: boolean) => {
      if (is_apply_doc_discount) {
        handlerUpdateFormState({
          is_apply_doc_discount,
          doc_discount_type:
            client?.client_discount && client?.client_discount_type
              ? client?.client_discount_type
              : StorePaymentDocumentDiscountType.PERCENTAGE,
          doc_discount_value: client?.client_discount
            ? client?.client_discount
            : '0.00',
          doc_discount_source:
            client?.client_discount === formData?.doc_discount_value
              ? StorePaymentDocumentDiscountSourceType.CLIENT
              : StorePaymentDocumentDiscountSourceType.CUSTOM,
        });
      } else {
        handlerUpdateFormState({
          doc_discount_value: undefined,
          doc_discount_type: undefined,
          doc_discount_source: undefined,
          is_apply_doc_discount,
        });
      }
    },
    [
      client?.client_discount,
      client?.client_discount_type,
      formData?.doc_discount_value,
      handlerUpdateFormState,
    ],
  );

  const handleChangeDiscount = useCallback(
    (e: any) => {
      const doc_discount_value = e?.target?.value;

      if (client) {
        if (client?.client_discount !== doc_discount_value) {
          handlerUpdateFormState({
            doc_discount_source: StorePaymentDocumentDiscountSourceType.CUSTOM,
          });
        } else {
          handlerUpdateFormState({
            doc_discount_source: StorePaymentDocumentDiscountSourceType.CLIENT,
          });
        }
      }

      handlerUpdateFormState({
        doc_discount_value: e?.target?.value,
      });
    },
    [client, handlerUpdateFormState],
  );

  useEffect(() => {
    const serializeStoreData = sessionStorage.getItem(
      SessionStorageItems.realizationFormStoreData,
    );

    if (!editMode && serializeStoreData && !onceStore?.current) {
      onceStore.current = true;
      const storeDTO: StoreDTO = decryptObject(serializeStoreData);

      const storeModel = StoreMapper.toStoreModel(storeDTO);

      if (documentEditRef?.current) {
        documentEditRef.current.initStoreUuid = storeDTO?.uuid;
      }

      handlerUpdateFormState({doc_store_uuid: storeModel});
    }
  }, [documentEditRef, editMode, handlerUpdateFormState]);

  const totalServices = doc_extra_services?.reduce(
    (acc: number, {price}: any) => {
      acc += Number(price || 0);
      return acc;
    },
    0,
  );

  useEffect(() => {
    if (documentEditRef?.current) {
      const {errorFields, ...rest} = formData;

      documentEditRef.current.document = {
        ...rest,
        doc_extra_services: doc_extra_services || [],
      };
    }
  }, [doc_extra_services, documentEditRef, formData]);

  return (
    <Row gutter={20} ref={contentRef}>
      <Col span={isFullWidth}>
        <Form.Item
          label={t('Store')}
          name="doc_store_uuid"
          tooltip={t('Store')}
          rules={[
            {
              required: true,
              message: t('Store must be specified.'),
            },
          ]}>
          <SearchSelect
            selectFirst={storeList?.size === 1}
            name="doc_store_uuid"
            disable={
              loading ||
              storeListLoading ||
              loadingSubmit ||
              formData?.doc_items?.length
            }
            placeholder={t('Select a warehouse')}
            data={listToArray(storeList as any)}
            getOptionValueTitle="store_name"
            onSearch={(keywords) => handleSearchStores({keywords})}
            resetState={storeListLoading}
            onChange={(doc_store_uuid) =>
              handlerUpdateFormState({doc_store_uuid})
            }
          />
        </Form.Item>
      </Col>

      <Col span={isFullWidth}>
        <Form.Item
          label={t('Price margin')}
          name="doc_margin_uuid"
          tooltip={t('Price margin')}>
          <SearchSelect
            selectFirst={storeList?.size === 1}
            name="doc_margin_uuid"
            disable={loading || priceMarginListLoading || loadingSubmit}
            placeholder={t('Select a price margin')}
            data={listToArray(priceMarginList as any)}
            getOptionValueTitle="price_name"
            onSearch={(keywords) => handleSearchPriceMargins({keywords})}
            resetState={storeListLoading}
            onChange={onPriceMarginChange}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          label={t('Client')}
          name="doc_client"
          tooltip={t('Client')}
          //   rules={[
          //     {
          //       validator: () => {
          //         if (!formData?.doc_client?.uuid) {
          //           return Promise.reject(
          //             new Error(t('Client must be specified.')),
          //           );
          //         }
          //         return Promise.resolve();
          //       },
          //     },
          //   ]
          //
          // }
          extra={
            client?.client_discount ? (
              <StyledDescription
                style={{whiteSpace: 'unset'}}
                $color={theme.colors.success}>
                {`${t('Discount')}: ${
                  client?.client_discount_type === ClientDiscountType.Percentage
                    ? `${client?.client_discount}%`
                    : `${defaultCompany?.currency_symbol || ''} ${correctPrice(
                        client?.client_discount,
                      )}`
                }`}
              </StyledDescription>
            ) : null
          }>
          <SearchSelect
            asObject={
              editMode && formData?.doc_client?.uuid ? onceClient.current : true
            }
            selectFirst={clients?.size === 1}
            name="doc_client"
            disable={loading || clientsLoading || loadingSubmit}
            placeholder={t('Select a client')}
            data={listToArray(clients as any)}
            valuePropName="uuid"
            getOptionValueTitle="fullNameClient"
            onSearch={(keywords) =>
              handleSearchClients({keywords, showLoading: false}) as any
            }
            resetState={clientsLoading}
            onChange={handleChangeClient}
            addonAfter={
              <ClientCreateButton
                provider
                disabled={loading || loadingSubmit}
                onSuccess={handleQuicklyCreateClient}
                autoFocus>
                <PlusOutlined />
              </ClientCreateButton>
            }
          />
        </Form.Item>
      </Col>

      <FormSwitch
        span={24}
        loading={loadingSubmit || loading}
        disabled={loadingSubmit}
        name="is_doc_extra_services"
        getFieldValue={getFieldValue}
        setFieldsValue={setFieldsValue}
        label={null}
        title={t('Add services to the document')}
        onChange={(is_doc_extra_services) => {
          handlerUpdateFormState({is_doc_extra_services});

          if (is_doc_extra_services) {
            handlerUpdateFormState({
              doc_extra_services: DEFAULT_EXTRA_SERVICES,
            });
          } else {
            handlerUpdateFormState({doc_extra_services: []});
          }
        }}
      />

      {formData?.is_doc_extra_services ? (
        <StyledFieldsContainer>
          <Title>{`${t('Additional services')}:`}</Title>
          <Col span={24}>
            <Form.List name="doc_extra_services">
              {(fields, {add, remove}) => (
                <>
                  {fields.map((field) => {
                    return (
                      <Row gutter={12} align="top" key={field?.key}>
                        <Col span={15}>
                          <Form.Item
                            {...field}
                            required
                            label={t('Service title')}
                            name={[field.name, 'title']}
                            rules={[
                              {
                                required: true,
                                message: t('Service title must be specified.'),
                              },
                            ]}>
                            <Input
                              disabled={loading || loadingSubmit}
                              placeholder={t('Enter a service title')}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <Form.Item
                            {...field}
                            required
                            label={t('Sum')}
                            name={[field.name, 'price']}
                            rules={[
                              () => ({
                                validator(_, sum) {
                                  const value = sum;

                                  if (Number.isNaN(Number(value))) {
                                    return Promise.reject(
                                      new Error(t('Sum must be a number')),
                                    );
                                  }

                                  if (!value) {
                                    return Promise.reject(
                                      new Error(t('Sum must be specified.')),
                                    );
                                  }

                                  return Promise.resolve();
                                },
                              }),
                            ]}>
                            <Input
                              onFocus={(e) => e?.target?.select()}
                              disabled={loading || loadingSubmit}
                              placeholder={t('Enter sum')}
                              addonBefore={defaultCompany?.currency_symbol}
                            />
                          </Form.Item>
                        </Col>

                        {doc_extra_services?.length > 1 ? (
                          <StyledDeleteCol span={2}>
                            <StyledDeleteOutlined
                              onClick={() => remove(field.name)}
                            />
                          </StyledDeleteCol>
                        ) : null}
                      </Row>
                    );
                  })}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add({title: undefined, price: undefined})}
                      block
                      icon={<PlusOutlined />}>
                      {t('Add another service')}
                    </Button>
                  </Form.Item>

                  {doc_extra_services?.length ? (
                    <Form.Item>
                      <StyledDetailsView titles={[]}>
                        <StyledStatsContainer>
                          <StyledTitle fontSize={15} bold>{`${t(
                            'Amount of services',
                          )}, ${
                            defaultCompany?.currency_symbol || ''
                          }: ${correctPrice(totalServices || 0)}`}</StyledTitle>
                        </StyledStatsContainer>
                      </StyledDetailsView>
                    </Form.Item>
                  ) : null}
                </>
              )}
            </Form.List>
          </Col>
        </StyledFieldsContainer>
      ) : null}

      <Col span={24}>
        <Form.Item label={t('Note')} name="doc_comment">
          <Input.TextArea
            allowClear
            autoSize={{minRows: 2, maxRows: 5}}
            disabled={loading || loadingSubmit}
            placeholder={t('Enter your note')}
            onChange={(e) =>
              handlerUpdateFormState({doc_comment: e?.target?.value})
            }
          />
        </Form.Item>
      </Col>

      <FormSwitch
        span={formData?.is_apply_doc_discount ? 12 : 24}
        loading={loadingSubmit || loading}
        disabled={loadingSubmit || loading}
        name="is_apply_doc_discount"
        getFieldValue={getFieldValue}
        setFieldsValue={setFieldsValue}
        label={null}
        title={t('Apply discount')}
        onChange={handleChangeIsApplyPaymentDocDiscount}
        value={!!formData?.is_apply_doc_discount}
      />

      {formData?.is_apply_doc_discount ? (
        <Col span={12}>
          <Form.Item
            tooltip={t('Discount')}
            label={null}
            name="doc_discount_value"
            rules={
              formData?.doc_discount_type === ClientDiscountType.Fixed
                ? [
                    {
                      transform: (value) => Number(value),
                      type: 'number',
                      required: false,
                      message: t('Discount must be specified.'),
                    },
                  ]
                : [
                    {
                      transform: (value) => Number(value),
                      type: 'number',
                      min: 0,
                      max: 100,
                      required: false,
                      message: t(
                        'Discount must be a number and bigger than -1 and less 100',
                      ),
                    },
                  ]
            }
            extra={
              <StyledDescription style={{whiteSpace: 'unset'}}>
                {t(
                  'The discount is applied exclusively to the cost of goods and material values Bof the invoice.',
                )}
              </StyledDescription>
            }>
            <Input
              addonAfter={
                <Form.Item noStyle name="doc_discount_type">
                  <StyledSearchSelect
                    popupMatchSelectWidth={false}
                    name="doc_discount_type"
                    placeholder={''}
                    getOptionValueTitle="description"
                    disable={loadingSubmit}
                    data={STORE_PAYMENT_DISCOUNT_TYPE(
                      defaultCompany?.currency_symbol!,
                    )}
                    onChange={async (doc_discount_type) => {
                      handlerUpdateFormState({doc_discount_type});
                      try {
                        await validateFields(['doc_discount_value']);
                      } catch (error) {}
                    }}
                  />
                </Form.Item>
              }
              type="number"
              disabled={loadingSubmit}
              placeholder={t('Enter discount')}
              onChange={handleChangeDiscount}
            />
          </Form.Item>
        </Col>
      ) : null}
    </Row>
  );
}
