import {List} from 'immutable';
import {useTranslation} from 'react-i18next';
import {useDefaultForm} from '../../../contex';
import styled, {useTheme} from 'styled-components';
import {Col, Form, Input, InputNumber, Row} from 'antd';
import {SessionStorageItems} from '../../../services/const';
import {LoadingOutlined, SwapOutlined} from '@ant-design/icons';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {areDeeplyEqual, isListToArray} from '../../../services/helpers';
import {Button, SearchSelect} from '../../../components/lib/DataDisplay';
import {correctPrice, listToArray} from '@sportix/sportix-common-modules';
import {StyledTitle, StyledDescription} from '../../../components/lib/Styled';

import {
  useStoredCompanies,
  IUseStatePriceMarginListReturnType,
} from '../../../hooks';

import {
  DEFAULT_UAH_CURRENCY,
  PriceMarginModel,
  StoreDocumentMultiCurrencyDTO,
} from '../../../struture';

export interface IStoreProductFieldsProps
  extends Pick<IUseStatePriceMarginListReturnType, 'priceMarginList'> {
  loading: boolean;
  priceMarginListLoading: boolean;
  doc_multi_currencies: StoreDocumentMultiCurrencyDTO[];
}

const StyledPriceMarginsContainer = styled(Row)`
  position: relative;

  border: 1px solid rgb(225, 229, 231);
  border-radius: 7px;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  padding: 15px;
`;

const Title = styled(StyledTitle)`
  position: absolute;
  top: -12px;
  background-color: ${({theme}) => theme.background.primary};
  padding-left: 5px;
  padding-right: 5px;
`;

const StyledPriceMarginCol = styled(Col)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const FullWidthInputNumber = styled(InputNumber)`
  width: 100%;
`;

const StyledSearchSelect = styled(SearchSelect)`
  width: 100px;
`;

export function StoreProductFields({
  loading: initLoading,
  priceMarginList,
  priceMarginListLoading,
  doc_multi_currencies,
}: IStoreProductFieldsProps): React.JSX.Element {
  const {t} = useTranslation();
  const {loadingSubmit, formData, handlerUpdateFormState} = useDefaultForm();
  const {defaultCompany} = useStoredCompanies();
  const theme: any = useTheme();
  const contentRef = useRef(null);
  const amountRef = useRef<HTMLInputElement>(null);
  // const {width: contentWidth} = useSize(contentRef);
  // const isFullWidth = contentWidth <= phoneScreen ? 24 : 12;
  const once = useRef(false);

  const [loading, setLoading] = useState(initLoading);
  const [sortedPriceMarginList, setSortedPriceMarginList] =
    useState<List<PriceMarginModel> | null>(null);

  const calculated = formData?.product_price?.calculated;
  const product = formData?.product_price?.product;

  const numberCalculated = formData?.product_price?.calculated?.map(
    ({price_value, ...rest}: any) => ({
      ...rest,
      price_value: Number(price_value),
    }),
  );
  const numberProduct = formData?.product_price?.product?.map(
    ({price_value, ...rest}: any) => ({
      ...rest,
      price_value: Number(price_value),
    }),
  );

  const handleUpdatePrice = useCallback(
    (price: string) => {
      const currency = doc_multi_currencies?.find(
        ({title}) => title === formData?.price_currency,
      );

      const rate = Number(currency?.rate || 1);

      const numPrice = Number(price || 0) * rate;

      const calculated = sortedPriceMarginList?.map(({uuid, price_margin}) => {
        return {
          price_uuid: uuid,
          price_value: (price_margin / 100) * numPrice + numPrice,
        };
      });

      const product_price = formData?.product_price;

      handlerUpdateFormState({
        price: price,
        product_price: {
          ...product_price,
          calculated: isListToArray(calculated!),
        },
      });
    },
    [
      doc_multi_currencies,
      formData?.price_currency,
      formData?.product_price,
      handlerUpdateFormState,
      sortedPriceMarginList,
    ],
  );

  const handleUpdateCurrency = useCallback(
    (price_currency: string) => {
      const currency = doc_multi_currencies?.find(
        ({title}) => title === price_currency,
      );

      if (price_currency && doc_multi_currencies?.length) {
        sessionStorage.setItem(
          SessionStorageItems.storeDocumentProductModalCurrency,
          price_currency,
        );
      }

      const price = formData?.price;

      const rate = Number(currency?.rate || 1);

      const numPrice = Number(price || 0) * rate;

      const calculated = sortedPriceMarginList?.map(({uuid, price_margin}) => {
        return {
          price_uuid: uuid,
          price_value: (price_margin / 100) * numPrice + numPrice,
        };
      });

      const product_price = formData?.product_price;

      handlerUpdateFormState({
        price: price,
        product_price: {
          ...product_price,
          calculated: isListToArray(calculated!),
        },
        price_currency,
      });
    },
    [
      doc_multi_currencies,
      formData?.price,
      formData?.product_price,
      handlerUpdateFormState,
      sortedPriceMarginList,
    ],
  );

  const handleEqualizePrices = useCallback(() => {
    const product_price = formData?.product_price;

    handlerUpdateFormState({
      product_price: {
        ...product_price,
        product: isListToArray(calculated!),
      },
    });
  }, [calculated, formData?.product_price, handlerUpdateFormState]);

  useEffect(() => {
    if (List.isList(priceMarginList) && !once?.current) {
      once.current = true;
      setLoading(true);

      if (
        Array.isArray(formData?.product_price?.product) &&
        formData?.product_price?.product.length
      ) {
        const pricesUuid = formData?.product_price?.product?.map(
          ({price_uuid}: any) => price_uuid,
        );

        const priceMarginListUuid = listToArray(
          priceMarginList.map(({uuid}) => uuid),
        );

        const diffUuids = priceMarginListUuid.filter(
          (x: string) => !pricesUuid.includes(x),
        );

        const updatedPricesUuid = [...pricesUuid, ...diffUuids];

        const sortedPriceMarginList = priceMarginList.sort((a, b) => {
          return (
            updatedPricesUuid.indexOf(a?.uuid) -
            updatedPricesUuid.indexOf(b?.uuid)
          );
        });

        setSortedPriceMarginList(sortedPriceMarginList);
      } else {
        const prices = priceMarginList.map(({uuid}) => ({
          price_uuid: uuid,
          price_value: '0.00',
        }));

        handlerUpdateFormState({
          product_price: {
            product: isListToArray(prices),
            calculated: isListToArray(prices),
          },
        });

        setSortedPriceMarginList(priceMarginList);
      }
      setLoading(false);
    }
  }, [
    formData?.product_price?.product,
    handlerUpdateFormState,
    priceMarginList,
  ]);

  useEffect(() => {
    if (amountRef?.current) {
      amountRef.current?.focus();
    }
  }, []);

  const isShowSelect = !!doc_multi_currencies?.length;
  const totalExtra = `${t('Total')}: ${correctPrice(
    Number(formData?.price || 0) * Number(formData?.product_amount || 1),
  )}`;

  return (
    <Row gutter={10} ref={contentRef}>
      <Col span={12}>
        <Form.Item
          tooltip={t('tAmount')}
          label={t('tAmount')}
          name="product_amount"
          rules={[
            ({}) => ({
              validator(_, amount) {
                if (Number.isNaN(Number(amount))) {
                  return Promise.reject(
                    new Error(t('tAmount must be number.')),
                  );
                }

                if (!amount) {
                  return Promise.reject(
                    new Error(t('tAmount must be specified.')),
                  );
                }

                if (amount < 1) {
                  return Promise.reject(
                    new Error(t('tAmount must not be less than 1')),
                  );
                }

                return Promise.resolve();
              },
            }),
          ]}>
          <FullWidthInputNumber
            size={'large'}
            onFocus={(e) => e?.target?.select()}
            ref={amountRef}
            type="number"
            disabled={loading || loadingSubmit}
            onChange={(product_amount) =>
              handlerUpdateFormState({product_amount})
            }
            placeholder={t('Enter an amount')}
          />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          label={t('Purchase price')}
          name="price"
          required
          extra={totalExtra}
          rules={[
            () => ({
              validator(_, price) {
                if (Number.isNaN(Number(price))) {
                  return Promise.reject(
                    new Error(t('Purchase price must be a number')),
                  );
                }

                if (!price) {
                  return Promise.reject(
                    new Error(t('Purchase price must be a specified')),
                  );
                }

                return Promise.resolve();
              },
            }),
          ]}>
          <Input
            size={'large'}
            autoComplete="off"
            onFocus={(e) => e?.target?.select()}
            addonBefore={isShowSelect ? null : defaultCompany?.currency_symbol}
            addonAfter={
              isShowSelect ? (
                <Form.Item noStyle name="price_currency">
                  <StyledSearchSelect
                    popupMatchSelectWidth={false}
                    name="price_currency"
                    placeholder={''}
                    disable={loadingSubmit}
                    getOptionValueProps="title"
                    data={[DEFAULT_UAH_CURRENCY, ...doc_multi_currencies]}
                    onChange={handleUpdateCurrency}
                  />
                </Form.Item>
              ) : null
            }
            disabled={loading || loadingSubmit}
            onChange={(e) => handleUpdatePrice(e?.target?.value)}
            placeholder={t('Enter a price')}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          label={t('Cell')}
          tooltip={t('An identifier of a cell where this product is placed.')}
          name="cell_identifier">
          <Input
            size={'large'}
            disabled={loading || loadingSubmit}
            placeholder={t('Enter a cell')}
            onChange={(e) =>
              handlerUpdateFormState({cell_identifier: e?.target?.value})
            }
          />
        </Form.Item>
      </Col>

      <StyledPriceMarginsContainer>
        <Title>{`${t('Current prices')}:`}</Title>

        {loading || priceMarginListLoading ? (
          <LoadingOutlined />
        ) : (
          <>
            {sortedPriceMarginList?.map(
              ({uuid, price_name, price_margin, price_description}, index) => {
                return (
                  <React.Fragment key={uuid}>
                    <StyledPriceMarginCol span={24}>
                      <Form.Item
                        style={{flex: 1}}
                        tooltip={`${price_description} (${price_margin}%).`}
                        label={price_name}
                        name={[
                          'product_price',
                          'product',
                          index,
                          'price_value',
                        ]}
                        rules={[
                          () => ({
                            validator(_, value) {
                              const price = Number(value);

                              if (Number.isNaN(price)) {
                                return Promise.reject(
                                  new Error(t('Price must be number.')),
                                );
                              }

                              return Promise.resolve();
                            },
                          }),
                        ]}
                        extra={
                          <StyledDescription
                            $color={
                              Number(calculated[index]?.price_value) >
                              Number(product[index]?.price_value)
                                ? theme.colors.error
                                : Number(calculated[index]?.price_value) <
                                  Number(product[index]?.price_value)
                                ? theme.colors.warning
                                : theme.colors.success
                            }>
                            {`${t('Estimated price')} (${price_margin}%): ${
                              defaultCompany?.currency_symbol
                            } ${
                              calculated[index]?.price_value?.toFixed(2) || 0
                            }`}
                          </StyledDescription>
                        }>
                        <Input
                          size={'large'}
                          addonBefore={defaultCompany?.currency_symbol}
                          disabled={loading || loadingSubmit}
                          placeholder="0.00"
                          onChange={(e) => {
                            const product_price = formData?.product_price;

                            const prices = formData?.product_price?.product;

                            const head = prices?.slice(0, index);
                            const tail = prices?.slice(index + 1);
                            const price = prices[index];

                            handlerUpdateFormState({
                              product_price: {
                                ...product_price,
                                product: [
                                  ...head,
                                  {
                                    ...price,
                                    price_uuid: uuid,
                                    price_value: e?.target?.value,
                                  },
                                  ...tail,
                                ],
                              },
                            });
                          }}
                        />
                      </Form.Item>
                    </StyledPriceMarginCol>
                  </React.Fragment>
                );
              },
            )}
            <Button
              onClick={handleEqualizePrices}
              icon={<SwapOutlined />}
              disabled={areDeeplyEqual(numberCalculated, numberProduct)}>
              <StyledTitle>{t('Equalize prices')}</StyledTitle>
            </Button>
          </>
        )}
      </StyledPriceMarginsContainer>
    </Row>
  );
}
