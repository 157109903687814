import {Record} from 'immutable';
import {
  ServiceDTO,
  ClientSubscriptionableDTO,
  ServiceModel,
  ServiceMapper,
} from '../internal';
import {WithoutRecursion} from '../type';

export interface IClientSubscriptionableModelReturnType {
  uuid: string;
  title: string;
  description: string;
  weekdays: string;
  duration: number;
  max_attenders: number;
  price: string;
  status: number;
  start_date: string;
  end_date: string;
  service: ServiceModel | ServiceDTO;
}

export class ClientSubscriptionableModel extends Record<IClientSubscriptionableModelReturnType>(
  {
    uuid: '',
    title: '',
    description: '',
    weekdays: '',
    duration: 0,
    max_attenders: 0,
    price: '',
    status: 0,
    start_date: '',
    end_date: '',
    service: {} as ServiceModel,
  },
) {
  constructor(
    props: ClientSubscriptionableDTO = {} as ClientSubscriptionableDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IClientSubscriptionableModelReturnType = {
      ...props,
      service:
        withoutRecursion.indexOf(WithoutRecursion.service) !== -1
          ? props?.service
          : ServiceMapper.toServiceDTO(props?.service, [
              WithoutRecursion.employee,
              ...withoutRecursion,
            ]),
    };

    super(options);
  }

  get serviceModel(): ServiceModel {
    return this.service instanceof ServiceModel
      ? this.service
      : ServiceMapper.toServiceModel(this.service);
  }
}
