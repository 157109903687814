import {
  CompanyDTO,
  ServiceDTO,
  EmployeeDTO,
  ICompanyDTOProps,
  CompanyMapper,
  ServiceMapper,
  EmployeeMapper,
  IServiceDTOProps,
  IEmployeeDTOProps,
  IAbonementModelReturnType,
} from '../internal';
import {WithoutRecursion} from '../type';
import {correctPrice} from '../../services/helpers';

export interface IAbonementTiming {
  min: string;
  max: string;
}

export interface IAbonementTimingList {
  Sunday?: IAbonementTiming;
  Monday?: IAbonementTiming;
  Tuesday?: IAbonementTiming;
  Wednesday?: IAbonementTiming;
  Thursday?: IAbonementTiming;
  Friday?: IAbonementTiming;
  Saturday?: IAbonementTiming;
}

export enum AbonementStatuses {
  Enable = 1,
  Disabled = 0,
}

export enum AbonementStatusesText {
  Enable = 'enable',
  Disabled = 'disabled',
}

export enum MaxPausedPeriodType {
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH',
}

export interface IAbonementDTOProps {
  uuid: string;
  abon_title: string;
  abon_period_amount: number;
  abon_price: string;
  abon_session_price: string;
  abon_can_timing_be_limited: boolean;
  abon_max_paused_period_type: MaxPausedPeriodType;
  abon_timing_list: IAbonementTimingList;
  abon_group_schedule: IAbonementTimingList;
  abon_can_visiting_be_limited: boolean;
  abon_visiting_limit: number;
  abon_can_move_unused_visiting: boolean;
  abon_can_be_paused: boolean;
  abon_can_refund_money: boolean;
  abon_max_paused_period: number;
  abon_manager_required: boolean;
  abon_comment: string;
  abon_status: AbonementStatuses;
  abon_status_text: AbonementStatusesText;
  created_at: string;
  updated_at: string;
  manager: IEmployeeDTOProps;
  service: IServiceDTOProps;
  company: ICompanyDTOProps;
}

export class AbonementDTO {
  public uuid: string;
  public abon_title: string;
  public abon_period_amount: number;
  public abon_price: string;
  public abon_session_price: string;
  public abon_can_timing_be_limited: boolean;
  public abon_max_paused_period_type: MaxPausedPeriodType;
  public abon_timing_list: IAbonementTimingList;
  public abon_group_schedule: IAbonementTimingList;
  public abon_can_visiting_be_limited: boolean;
  public abon_visiting_limit: number;
  public abon_can_move_unused_visiting: boolean;
  public abon_can_be_paused: boolean;
  public abon_max_paused_period: number;
  public abon_manager_required: boolean;
  public abon_can_refund_money: boolean;
  public abon_comment: string;
  public abon_status: AbonementStatuses;
  public abon_status_text: AbonementStatusesText;
  public created_at: string;
  public updated_at: string;
  public manager: IEmployeeDTOProps;
  public service: IServiceDTOProps;
  public company: CompanyDTO;

  constructor(
    props: IAbonementModelReturnType | IAbonementDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';

    this.abon_title = props?.abon_title || '';
    this.abon_period_amount =
      typeof props?.abon_period_amount === 'number'
        ? props?.abon_period_amount
        : 1;
    this.abon_price = correctPrice(props?.abon_price || 0);
    this.abon_session_price = correctPrice(props?.abon_session_price || 0);
    this.abon_can_timing_be_limited =
      props?.abon_can_timing_be_limited || false;
    this.abon_timing_list =
      props?.abon_timing_list || ({} as IAbonementTimingList);
    this.abon_group_schedule =
      props?.abon_group_schedule || ({} as IAbonementTimingList);
    this.abon_can_visiting_be_limited =
      props?.abon_can_visiting_be_limited || false;
    this.abon_visiting_limit =
      typeof props?.abon_visiting_limit === 'number'
        ? props?.abon_visiting_limit
        : 1;
    this.abon_can_move_unused_visiting =
      props?.abon_can_move_unused_visiting || false;
    this.abon_can_be_paused = props?.abon_can_be_paused || false;
    this.abon_max_paused_period =
      typeof props?.abon_max_paused_period === 'number'
        ? props?.abon_max_paused_period
        : 1;
    this.abon_manager_required = props?.abon_manager_required || false;
    this.abon_max_paused_period_type =
      props?.abon_max_paused_period_type || MaxPausedPeriodType.Day;
    this.abon_can_refund_money =
      typeof props?.abon_can_refund_money === 'boolean'
        ? props?.abon_can_refund_money
        : true;
    this.abon_comment = props?.abon_comment || '';
    this.abon_status =
      typeof props?.abon_status === 'number'
        ? props?.abon_status
        : AbonementStatuses.Enable;
    this.abon_status_text =
      props?.abon_status_text || AbonementStatusesText.Enable;
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
    this.manager =
      withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
        ? (props?.manager as EmployeeDTO)
        : EmployeeMapper.toEmployeeDTO(props?.manager, [
            WithoutRecursion.abonement,
            ...withoutRecursion,
          ]);
    this.service =
      withoutRecursion.indexOf(WithoutRecursion.service) !== -1
        ? (props?.service as ServiceDTO)
        : ServiceMapper.toServiceDTO(props?.service, [
            WithoutRecursion.abonement,
            ...withoutRecursion,
          ]);
    this.company =
      withoutRecursion.indexOf(WithoutRecursion.company) !== -1
        ? (props?.company as CompanyDTO)
        : CompanyMapper.toCompanyDTO(props?.company, [
            WithoutRecursion.abonement,
            ...withoutRecursion,
          ]);
  }
}
