import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Form, {FormInstance} from 'antd/es/form';
import {ChildrenMustBeAFunction} from '../Errors';
import React, {useCallback, useState} from 'react';
import {InfoCircleOutlined} from '@ant-design/icons';
import {isFunction} from '../../../services/helpers';
import {withTranslation, WithTranslation} from 'react-i18next';
import Checkbox, {CheckboxProps, CheckboxChangeEvent} from 'antd/es/checkbox';

export type ReturnedProps = {
  checked: boolean;
};

export interface IProps
  extends Partial<FormInstance>,
    WithTranslation,
    Omit<CheckboxProps, 'onChange' | 'children'> {
  isChecked?: boolean;
  title?: string;
  label?: string;
  tooltipTitle?: string;
  name?: string;
  children?: (data: ReturnedProps) => React.ReactNode;
  rules?: any[];
  noStyle?: boolean;
  onChange?: (value: boolean) => void;
  span?: number;
}

export default withTranslation()(FormCheckBox);

function FormCheckBox({
  t,
  isChecked = false,
  setFieldsValue,
  title = '',
  label = '',
  name = 'checkbox',
  tooltipTitle = '',
  children,
  rules,
  noStyle = false,
  onChange,
  span = 24,
  ...rest
}: IProps): JSX.Element {
  const [checked, setChecked] = useState<boolean>(isChecked);

  const onCheckBoxChange = useCallback(
    (event: CheckboxChangeEvent): void => {
      setChecked((prevState) => {
        if (isFunction(setFieldsValue)) {
          setFieldsValue({[name]: !prevState});
        }

        if (isFunction(onChange)) {
          onChange(event?.target?.checked);
        }

        return !prevState;
      });
    },
    [name, onChange, setFieldsValue],
  );

  if (!isFunction(children)) {
    throw new ChildrenMustBeAFunction('LoadingWithEmptyData');
  }

  return (
    <Row gutter={20}>
      <Col span={span}>
        <Form.Item
          noStyle={noStyle}
          name={name}
          label={label}
          rules={
            rules || [
              {
                required: false,
              },
            ]
          }
          tooltip={{
            title: tooltipTitle || t('Open the form again'),
            icon: <InfoCircleOutlined />,
          }}>
          <Checkbox checked={checked} onChange={onCheckBoxChange} {...rest}>
            {children({checked}) || title || null}
          </Checkbox>
        </Form.Item>
      </Col>
    </Row>
  );
}
