import {Record} from 'immutable';
import {NotificationDTO, NotificationStatuses} from '../internal';

export interface INotificationReturnType {
  uuid: string;
  date: string;
  message: string;
  title: string;
  is_read: number;
  created_at: string;
  updated_at: string;
}

export class NotificationModel extends Record<INotificationReturnType>({
  uuid: '',
  date: '',
  message: '',
  title: '',
  is_read: NotificationStatuses.Unread,
  created_at: '',
  updated_at: '',
}) {
  constructor(props: NotificationDTO = {} as NotificationDTO) {
    super(props);
  }
}
