import {Record, List} from 'immutable';
import {ListModel, ListProps} from './List';
import ClientOrder, {ClientOrderProps} from './ClientOrder';

export interface ClientOrdersListProps extends ListProps {
  orders: ClientOrderProps[];
}

export interface ClientOrdersListReturnType extends ListProps {
  orders: List<ClientOrder>;
}
/**
 * @class ClientOrdersList
 * @desc Список замовлень клієнта
 * @extends {Record}
 */
export default class ClientOrdersList extends Record<ClientOrdersListReturnType>(
  {
    orders: List(),
    ...ListModel.toJS(),
  } as any,
) {
  /**
   * @constructor
   * @param {{orders:[]}} param0
   */
  constructor({orders = [], ...props}: ClientOrdersListProps) {
    super({
      // orders: List(orders.map(order => new ClientOrder(order))),
      orders: List(orders.map((order) => new ClientOrder(order))),
      ...props,
    });
  }
}
