import React from 'react';
import {ClientModel} from '../../../struture';
import styled, {useTheme} from 'styled-components';
import {StyledDescription} from '../../../components/lib/Styled';
import {Routes} from '../../../services/types';
import {Link} from '../../../components/lib/DataDisplay';
import {useLocation} from 'react-router';
import {useTranslation} from 'react-i18next';
import {textToUpperCase, toDateByFormat} from '../../../services/helpers';
import {useStoredCompanies} from '../../../hooks';
import {useAcl} from '../../../contex';

export interface IClientDetailsCreatedViewProps {
  client: ClientModel | null;
}

const StyledSpace = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  margin-left: 20px;
`;
export function ClientDetailsCreatedView({
  client,
}: IClientDetailsCreatedViewProps): JSX.Element {
  const {t} = useTranslation();
  const location = useLocation();
  const theme: any = useTheme();
  const {defaultCompany} = useStoredCompanies();
  const {manage} = useAcl(({client}) => client);

  return (
    <>
      {client ? (
        <StyledSpace>
          <Link
            to={`/${Routes.app}/${Routes.employees}/${client?.createdByModel?.uuid}`}
            tooltip={t('Go to manager details page')}
            state={{goBack: location.pathname}}>
            {client?.createdByModel.fullName}
          </Link>

          <StyledDescription>
            {toDateByFormat(client?.created_at, 'YYYY.MM.DD HH:mm')}
          </StyledDescription>
        </StyledSpace>
      ) : null}
    </>
  );
}
