import {Dispatch} from 'redux';
import {CLIENT_ABONEMENT_SUBSCRIPTION_LIST} from '../constants';
import {
  ClientAbonementSubscriptionListModel,
  ClientAbonementSubscriptionModel,
} from '../../struture';
import {ApiError} from '../../services/types';

export interface ISetClientAbonementSubscriptionListParam {
  type: CLIENT_ABONEMENT_SUBSCRIPTION_LIST;
  subscriptionList: ClientAbonementSubscriptionListModel;
  abonementUuid: string;
  keywords?: string;
  total?: number;
  page?: number;
}

export interface IAddClientAbonementSubscriptionToListParam {
  type: CLIENT_ABONEMENT_SUBSCRIPTION_LIST;
  subscription: ClientAbonementSubscriptionModel;
}

export interface IUpdateClientAbonementSubscriptionFromListParam {
  type: CLIENT_ABONEMENT_SUBSCRIPTION_LIST;
  subscription: ClientAbonementSubscriptionModel;
}

export interface IDeleteClientAbonementSubscriptionFromListParam {
  type: CLIENT_ABONEMENT_SUBSCRIPTION_LIST;
  subscriptionUuid: string;
}

export interface IErrorClientAbonementSubscriptionListParam {
  type: CLIENT_ABONEMENT_SUBSCRIPTION_LIST;
  error: ApiError | undefined;
}

export function setClientAbonementSubscriptionList(
  subscriptionList: ClientAbonementSubscriptionListModel,
  abonementUuid: string,
  keywords?: string,
  total?: number,
  page?: number,
): (dispatch: Dispatch) => Promise<ISetClientAbonementSubscriptionListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CLIENT_ABONEMENT_SUBSCRIPTION_LIST.SET_CLIENT_ABONEMENT_SUBSCRIPTION_LIST,
      subscriptionList,
      keywords,
      total,
      page,
      abonementUuid,
    });
  };
}

export function loadMoreClientAbonementSubscriptionList(
  subscriptionList: ClientAbonementSubscriptionListModel,
  abonementUuid: string,
): (dispatch: Dispatch) => Promise<ISetClientAbonementSubscriptionListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CLIENT_ABONEMENT_SUBSCRIPTION_LIST.LOAD_MORE_CLIENT_ABONEMENT_SUBSCRIPTION_LIST,
      subscriptionList,
      abonementUuid,
    });
  };
}

export function addClientAbonementSubscriptionToList(
  subscription: ClientAbonementSubscriptionModel,
  abonementUuid: string,
): (dispatch: Dispatch) => Promise<IAddClientAbonementSubscriptionToListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CLIENT_ABONEMENT_SUBSCRIPTION_LIST.ADD_CLIENT_ABONEMENT_SUBSCRIPTION,
      subscription,
      abonementUuid,
    });
  };
}

export function updateClientAbonementSubscriptionFromList(
  subscription: ClientAbonementSubscriptionModel,
  abonementUuid: string,
): (
  dispatch: Dispatch,
) => Promise<IUpdateClientAbonementSubscriptionFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CLIENT_ABONEMENT_SUBSCRIPTION_LIST.UPDATE_CLIENT_ABONEMENT_SUBSCRIPTION,
      subscription,
      abonementUuid,
    });
  };
}

export function deleteClientAbonementSubscriptionFromList(
  subscriptionUuid: string,
  abonementUuid: string,
): (
  dispatch: Dispatch,
) => Promise<IDeleteClientAbonementSubscriptionFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CLIENT_ABONEMENT_SUBSCRIPTION_LIST.DELETE_CLIENT_ABONEMENT_SUBSCRIPTION,
      subscriptionUuid,
      abonementUuid,
    });
  };
}

export function errorClientAbonementSubscriptionInList(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorClientAbonementSubscriptionListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CLIENT_ABONEMENT_SUBSCRIPTION_LIST.ERROR_CLIENT_ABONEMENT_SUBSCRIPTION_LIST,
      error,
    });
  };
}
