import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {IActionButtonProps} from '../../../components/lib/General';
import {useModal} from '../../../components/lib/libV2/hooks';
import {ServiceGroupSessionFormDTO} from '../../../struture';
import {EditButton, SuspenseEmpty} from '../../../components/lib/DataDisplay';

const GroupSessionSideWindowLazy = React.lazy(
  () => import('../Show/GroupSessionSideWindow'),
);

export interface IGroupSessionUpdateButtonProps
  extends Partial<IActionButtonProps> {
  groupSession: ServiceGroupSessionFormDTO;
  onSuccess: (value: ServiceGroupSessionFormDTO) => Promise<void>;
  companyUuid: string;
  onCancel?: () => void;
}

/**
 * @desc Реалізаці редагування сессії
 * */
export default function GroupSessionUpdateButton({
  children,
  groupSession,
  onSuccess,
  loading,
  companyUuid,
  onCancel,
  ...rest
}: IGroupSessionUpdateButtonProps) {
  const {t} = useTranslation();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <EditButton
        tooltipTitle={t('Edit Session')}
        onClick={handleOnInit}
        disabled={false}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <GroupSessionSideWindowLazy
          editMode
          groupSession={groupSession}
          onSuccess={handleSuccess}
          visible={visible}
          companyUuid={companyUuid}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}
