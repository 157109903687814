import React from 'react';
import {WithTranslation} from 'react-i18next';
import styled from 'styled-components';
import AuthHeader from './AuthHeader';
import AuthFooter, {IAuthFooterProps} from './AuthFooter';

export interface IAuthLayoutProps {
  children: React.ReactNode;
  className?: string;
}

const Container = styled.div`
  width: 35vw;
  padding: 20px 0;
  overflow-y: auto;
  overflow-x: hidden;
  height: 86vh;

  @media (max-width: 1200px) {
    width: 45vw;
  }

  @media (max-width: 767px) {
    width: 50vw;
  }

  @media (max-width: 680px) {
    width: 70vw;
  }

  @media (max-width: 475px) {
    width: 90vw;
  }
`;

export default function AuthLayout({
  children,
  className,
}: IAuthLayoutProps): JSX.Element {
  return <Container className={className}>{children}</Container>;
}
