import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {IActionButtonProps} from '../../../components/lib/General';
import {useModal} from '../../../components/lib/libV2/hooks';
import {RewardPaymentFormDTO} from '../../../struture';
import {AddButton, SuspenseEmpty} from '../../../components/lib/DataDisplay';
import styled, {css} from 'styled-components';

const RewardPaymentSideWindowLazy = React.lazy(
  () => import('../Show/RewardPaymentSideWindow'),
);

export interface IRewardPaymentButtonProps
  extends Omit<IActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: RewardPaymentFormDTO) => Promise<void>;
  onCancel?: () => void;
  payment: RewardPaymentFormDTO;
}

const StyledAddButton = styled(AddButton)<{disabled?: boolean}>`
  ${({disabled}) =>
    !disabled &&
    css`
      background-color: ${({theme}) => theme.colors.success};

      &&&:hover,
      &&&:active,
      &&&:focus {
        background-color: ${({theme}) => theme.colors.success};
        opacity: 0.7;
      }
    `}
`;

export function RewardPaymentButton({
  children,
  onSuccess,
  onCancel,
  loading,
  payment,
  ...rest
}: IRewardPaymentButtonProps): React.JSX.Element {
  const {t} = useTranslation();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <StyledAddButton
        tooltipTitle={t('Payment of wages')}
        onClick={handleOnInit}
        loading={loading}
        type="primary"
        {...rest}>
        {t('Payment of wages')}
      </StyledAddButton>

      <Suspense fallback={<SuspenseEmpty />}>
        <RewardPaymentSideWindowLazy
          payment={payment}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}
