import {
  EmployeeDTO,
  IAbonementGroupModelReturnType,
  EmployeeMapper,
  IAbonementDTOProps,
  IEmployeeDTOProps,
  AbonementDTO,
  AbonementMapper,
  IAbonementTimingList,
} from '../internal';
import {WithoutRecursion} from '../type';
import {head, last} from '../../services/helpers';

export enum AbonementGroupStatuses {
  Enable = 1,
  Disabled = 0,
}

export enum AbonementGroupStatusesText {
  Enable = 'enabled',
  Disabled = 'disabled',
  Error = 'error',
}

export interface IAbonementGroupDTOProps {
  uuid: string;
  group_title: string;
  group_schedule_list: IAbonementTimingList;
  group_comment: string;
  group_status: AbonementGroupStatuses;
  group_status_text: AbonementGroupStatusesText;
  created_at: string;
  updated_at: string;
  manager: IEmployeeDTOProps;
  company_abonement: IAbonementDTOProps;
}

export class AbonementGroupDTO {
  public uuid: string;
  public group_title: string;
  public group_schedule_list: IAbonementTimingList;
  public group_comment: string;
  public group_status: AbonementGroupStatuses;
  public group_status_text: AbonementGroupStatusesText;
  public created_at: string;
  public updated_at: string;
  public manager: IEmployeeDTOProps;
  public company_abonement: AbonementDTO;

  constructor(
    props: IAbonementGroupModelReturnType | IAbonementGroupDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';
    this.group_title = props?.group_title || '';
    this.group_status_text =
      props?.group_status_text || AbonementGroupStatusesText.Enable;
    this.group_schedule_list =
      props?.group_schedule_list || ({} as IAbonementTimingList);
    this.group_comment = props?.group_comment || '';
    this.group_status =
      typeof props?.group_status === 'number'
        ? props?.group_status
        : AbonementGroupStatuses.Enable;
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';

    this.manager =
      withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
        ? (props?.manager as EmployeeDTO)
        : EmployeeMapper.toEmployeeDTO(props?.manager, [
            WithoutRecursion.abonement,
            ...withoutRecursion,
          ]);
    this.company_abonement =
      withoutRecursion.indexOf(WithoutRecursion.abonement) !== -1
        ? (props?.company_abonement as AbonementDTO)
        : AbonementMapper.toAbonementDTO(props?.company_abonement, [
            WithoutRecursion.clientAbonement,
            ...withoutRecursion,
          ]);
  }
}
