import {Record} from 'immutable';
import {
  ScheduleStatusDTO,
  ProfileDTO,
  ProfileModel,
  ProfileMapper,
} from '../internal';

export interface IScheduleStatusModelReturnType {
  reason: string;
  status_text: string;
  status_color: string;
  created_at: string;
  groupBy?: string;
  created_by: ProfileDTO | ProfileModel;
}

export class ScheduleStatusModel extends Record<IScheduleStatusModelReturnType>(
  {
    reason: '',
    status_text: '',
    status_color: '',
    created_at: '',
    groupBy: '',
    created_by: {} as ProfileModel,
  },
) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(props: ScheduleStatusDTO = {} as ScheduleStatusDTO) {
    const options: IScheduleStatusModelReturnType = {
      ...props,
      created_by: ProfileMapper.toProfileModel(props?.created_by),
    };

    super(options);
  }
}
