import React, {useEffect, useMemo, useState} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {List} from 'immutable';
import {RELATED_SERVICES_INITIAL_DATA} from '../../../services/api/relatedService';
import {DefaultForm, IDefaultFormProps} from '../../../components/lib/General';
import {useServiceList} from '../../../hooks';
import {
  RelatedServiceFormDTO,
  RelatedServiceModel,
  ServiceMapper,
  ServiceModel,
} from '../../../struture';
import {eq} from '../../../services/helpers';
import RelatedServicesFields from './RelatedServicesFields';

export default withTranslation()(RelatedServiceForm);

export interface IRelatedServicesFormProps
  extends WithTranslation,
    Omit<
      IDefaultFormProps<RelatedServiceFormDTO, RelatedServiceFormDTO>,
      'children' | 'initialValues' | 'additionalValuesRequest'
    > {
  loading?: boolean;
  service?: RelatedServiceFormDTO;
  companyUuid: string;
  relatedServices: List<RelatedServiceModel> | null;
  serviceUuid: string;
}

function RelatedServiceForm({
  t,
  loading,
  service = RELATED_SERVICES_INITIAL_DATA,
  companyUuid,
  editMode,
  relatedServices,
  serviceUuid,
  ...rest
}: IRelatedServicesFormProps): JSX.Element {
  const [unSelectedServices, setUnSelectedServices] =
    useState<List<ServiceModel> | null>(null);

  const [unSelectedServicesLoading, setUnSelectedServicesLoading] =
    useState(true);

  const additionalValues = useMemo(
    () => ({
      uuid: service?.uuid,
    }),
    [service?.uuid],
  );

  const {entityList: services, loading: servicesLoading} = useServiceList({
    companyUuid,
  });

  useEffect(() => {
    if (List.isList(services) && List.isList(relatedServices)) {
      setUnSelectedServicesLoading(true);

      const unSelected = services.filter((service) => {
        return (
          !relatedServices.some(({service: relatedService}) => {
            return eq(relatedService?.uuid, service?.uuid);
          }) && !eq(serviceUuid, service?.uuid)
        );
      });

      if (editMode) {
        const editService = services.find(
          ({uuid}) => uuid === service?.service_uuid,
        );

        if (editService) {
          setUnSelectedServices(unSelected.push(editService));
        } else {
          setUnSelectedServices(unSelected);
        }
      } else {
        setUnSelectedServices(unSelected);
      }

      setUnSelectedServicesLoading(false);
    }
  }, [editMode, relatedServices, services, serviceUuid, service?.service_uuid]);

  return (
    <DefaultForm<RelatedServiceFormDTO, RelatedServiceFormDTO>
      notifyFormName={t('RelatedService')}
      initialValues={{
        ...service,
      }}
      additionalValuesRequest={additionalValues}
      editMode={editMode}
      disabledSubmitButton={
        !unSelectedServicesLoading && eq(unSelectedServices?.size, 0)
      }
      {...rest}>
      {() => (
        <RelatedServicesFields
          loading={!!loading}
          services={unSelectedServices}
          servicesLoading={servicesLoading}
          serviceUuid={service && (service?.service_uuid as string)}
        />
      )}
    </DefaultForm>
  );
}
