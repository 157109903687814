import React from 'react';
import {StoreDocumentModel, StoreDocumentStatus} from '../../../../struture';
import styled, {useTheme, css} from 'styled-components';
import {StyledDescription} from '../../../../components/lib/Styled';
import {useLocation} from 'react-router';
import {useTranslation} from 'react-i18next';
import {Link} from '../../../../components/lib/DataDisplay';
import {Routes} from '../../../../services/types';
import {toDateByFormat} from '../../../../services/helpers';
import {MdEditDocument} from 'react-icons/md';
import {IoMdDocument} from 'react-icons/io';
import Color from 'color';

export interface IStoreSaleDetailsViewProps {
  document: StoreDocumentModel | null;
}
const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`;

const StyledSpace = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 5px;
`;

const StyledLink = styled(Link)<{$draft: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
  gap: 5px;
  justify-content: flex-start;

  ${({$draft}) =>
    $draft &&
    css`
      color: ${({theme}) => theme.colors.warning};

      &:hover {
        color: ${({theme}) =>
          Color(theme.colors.warning).alpha(1).lighten(0.2).toString()};
      }
    `}
`;

export function StoreSaleDetailsView({
  document,
}: IStoreSaleDetailsViewProps): JSX.Element {
  const {t} = useTranslation();
  const location = useLocation();
  const theme: any = useTheme();

  return (
    <>
      {document ? (
        <StyledContainer>
          <StyledSpace>
            <StyledLink
              $draft={document?.doc_status_text === StoreDocumentStatus.DRAFT}
              to={`/${Routes.app}/${Routes.stores}/${Routes.storeSale}/${document?.uuid}`}
              tooltip={t('Open a detailed description of the sale')}
              state={{goBack: location.pathname}}>
              {document?.doc_status_text === StoreDocumentStatus.DRAFT ? (
                <MdEditDocument color={theme.colors.warning} />
              ) : (
                <IoMdDocument color={theme.colors.link} />
              )}
              {document?.doc_local_number}
              {document?.doc_status_text === StoreDocumentStatus.DRAFT ? (
                <StyledDescription $color={theme.colors.warning}>
                  {` ( ${t('Draft')} )`}
                </StyledDescription>
              ) : null}
            </StyledLink>
            {document?.doc_date ? (
              <StyledDescription>
                {toDateByFormat(document?.doc_date, 'DD.MM.YYYY HH:mm')}
              </StyledDescription>
            ) : null}
          </StyledSpace>
        </StyledContainer>
      ) : null}
    </>
  );
}
