import {Record, List} from 'immutable';
import {ScheduleCalendarDTO, ScheduleCalendarModel} from '../internal';
import {WithoutRecursion} from '../type';
import {IListProps} from './List';

export interface IScheduleCalendarListModelProps extends IListProps {
  schedules: ScheduleCalendarDTO[];
}

export interface IScheduleCalendarListModelReturnType extends IListProps {
  schedules: List<ScheduleCalendarModel>;
}

export class ScheduleCalendarListModel extends Record<IScheduleCalendarListModelReturnType>(
  {
    schedules: List(),
    total: 0,
  },
) {
  constructor(
    {
      schedules = [],
      total,
      ...props
    }: IScheduleCalendarListModelProps = {} as IScheduleCalendarListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    super({
      schedules: List(
        schedules.map(
          (schedule) => new ScheduleCalendarModel(schedule, withoutRecursion),
        ),
      ),
      total,
      ...props,
    });
  }
}
