import React, {ChangeEvent, useCallback} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {SearchInput, ISearchInputProps} from '../../../components/lib/General';
import {Label} from '../../../components/lib/DataDisplay';

export default withTranslation()(GroupPaymentSearchInput);

export interface IGroupPaymentSearchInputProps
  extends WithTranslation,
    ISearchInputProps {
  handleSearchGroupPayments: (value: string) => Promise<void>;
}

function GroupPaymentSearchInput({
  t,
  handleSearchGroupPayments,
  ...rest
}: IGroupPaymentSearchInputProps): JSX.Element {
  const handleChange = useCallback(
    ({target}: ChangeEvent<HTMLInputElement>) =>
      handleSearchGroupPayments(target?.value),
    [handleSearchGroupPayments],
  );

  return (
    <Label
      tooltipTitle={t('Search group payments')}
      label={t('Search group payments')}>
      <SearchInput size="small" onChange={handleChange} {...rest} />
    </Label>
  );
}
