import React, {useRef, useState} from 'react';
import {Col, Form, Input, Row} from 'antd';
import {StyledDescription} from '../../../components/lib/Styled';
import {DatePicker} from '../../../components/lib/General';
import {SearchSelect, Segmented} from '../../../components/lib/DataDisplay';
import {useDefaultForm} from '../../../contex';
import {useTranslation} from 'react-i18next';
import {isListToArray, correctPrice} from '../../../services/helpers';
import {
  IUseStateCashBoxListReturnType,
  IUseStateCompanyClientListReturnType,
  IUseStateEmployeeListReturnType,
  useSize,
  useStoredCompanies,
} from '../../../hooks';
import {CashBoxModel, PAYMENT_METHOD} from '../../../struture';
import {convertedPaymentMethodToCashBox} from '../../../services/api/companyPayments';
import {phoneScreen} from '../../../services/const';
import {CashBoxTitleView} from '../Show/CashBoxTitleView';
import styled, {css} from 'styled-components';

export interface IPaymentStoreDocumentPostingFieldsProps
  extends Pick<
      IUseStateCompanyClientListReturnType,
      'clients' | 'handleSearchClients'
    >,
    Pick<
      IUseStateEmployeeListReturnType,
      'employees' | 'handleSearchEmployees'
    >,
    Pick<
      IUseStateCashBoxListReturnType,
      'cashBoxList' | 'handleSearchCashBoxes'
    > {
  clientsLoading: boolean;
  employeesLoading: boolean;
  cashBoxListLoading: boolean;
}

export const FROM_PAYMENT_METHOD = [
  {
    title: 'tCash',
    uuid: PAYMENT_METHOD.Cash,
  },
  {
    title: 'Cashless',
    uuid: PAYMENT_METHOD.Bank,
  },
];

const StyledPaymentInput = styled(Input)<{$showWarning: boolean}>`
  ${({$showWarning, theme}) =>
    $showWarning &&
    css`
      & .ant-input-group-addon {
        color: ${theme.colors.warning};
        border-color: ${theme.colors.warning};
      }

      & .ant-input {
        border-color: ${theme.colors.warning};
      }
    `}
`;

export function PaymentStoreDocumentPostingFields({
  clients,
  handleSearchClients,

  employees,
  employeesLoading,
  handleSearchEmployees,

  cashBoxList,
  cashBoxListLoading,
  handleSearchCashBoxes,
}: IPaymentStoreDocumentPostingFieldsProps): JSX.Element {
  const {loadingSubmit, handlerUpdateFormState, formData} = useDefaultForm();
  const {t} = useTranslation();
  const {defaultCompany} = useStoredCompanies();

  const [showPaymentWarning, setShowPaymentWarning] = useState(false);
  const [resetCashBoxState, setResetCashBoxState] = useState(false);

  const contentRef = useRef(null);
  const {width: contentWidth} = useSize(contentRef);
  const isFullWidth = contentWidth <= phoneScreen ? 24 : 12;

  return (
    <Row gutter={20} ref={contentRef}>
      <Col span={24}>
        <Form.Item
          label={t('Date')}
          name="payment_date"
          rules={[
            {
              required: true,
              message: t('Date must be specified'),
            },
          ]}
          tooltip={{
            title: t('The date of the financial transaction'),
          }}
          extra={
            <StyledDescription>
              {t('The date of the financial transaction')}
            </StyledDescription>
          }>
          <DatePicker
            disabled={loadingSubmit}
            placeholder={t('Date')}
            onChange={(payment_date) => handlerUpdateFormState({payment_date})}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          label={t('Provider')}
          name="payment_client_uuid"
          rules={[
            {
              required: true,
              message: t('Provider must be specified'),
            },
          ]}
          tooltip={{
            title: t('Provider'),
          }}>
          <SearchSelect
            onSearch={async (keywords) => {
              await handleSearchClients({
                keywords,
                limit: 100,
                showLoading: false,
              });
            }}
            name="payment_client_uuid"
            getOptionValueTitle="fullNameClient"
            placeholder={t('Select a provider')}
            disable
            data={isListToArray(clients as any)}
            onChange={(payment_client_uuid) =>
              handlerUpdateFormState({payment_client_uuid})
            }
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          label={t('Sum')}
          name="payment_price"
          required
          rules={[
            ({getFieldValue}) => ({
              validator(_, price) {
                const value = price;

                const netPrice = getFieldValue('net_price');

                if (Number.isNaN(Number(value))) {
                  return Promise.reject(new Error(t('Sum must be a number')));
                }

                if (formData?.payment_max_sum !== -1) {
                  if (Number(value) > formData?.payment_max_sum) {
                    setShowPaymentWarning(true);
                  } else {
                    setShowPaymentWarning(false);
                  }
                }

                if (Number(value) < Number(netPrice)) {
                  return Promise.reject(
                    new Error(t('The sum cannot be grate than net price')),
                  );
                }

                if (Number(value) <= 0) {
                  return Promise.reject(
                    new Error(t('The sum must be greater than 0')),
                  );
                }

                return Promise.resolve();
              },
            }),
          ]}
          extra={
            <StyledDescription style={{whiteSpace: 'unset'}}>
              {`${t('The total amount on the invoice')}: ${correctPrice(
                formData?.payment_default_sum,
              )}`}
            </StyledDescription>
          }>
          <StyledPaymentInput
            $showWarning={showPaymentWarning}
            addonBefore={defaultCompany?.currency_symbol}
            disabled={loadingSubmit}
            onChange={(e) =>
              handlerUpdateFormState({payment_price: e?.target?.value})
            }
            placeholder={t('Enter sum')}
          />
        </Form.Item>
      </Col>

      <Col span={isFullWidth}>
        <Form.Item
          label={t('Form of payment')}
          name="payment_method"
          rules={[
            {
              required: true,
              message: t('Form of payment must be specified'),
            },
          ]}
          tooltip={t('Form of payment')}>
          <Segmented
            options={FROM_PAYMENT_METHOD}
            disabled={loadingSubmit}
            onChange={(payment_method) => {
              handlerUpdateFormState({payment_method});

              if (typeof formData?.payment_method === 'string') {
                handlerUpdateFormState({
                  payment_cashbox_uuid: '',
                });
                setResetCashBoxState(true);
                setTimeout(() => {
                  setResetCashBoxState(false);
                }, 100);
              }
            }}
          />
        </Form.Item>
      </Col>

      <Col span={isFullWidth}>
        <Form.Item
          label={t('Cash boxes')}
          name="payment_cashbox_uuid"
          rules={[
            {
              required: true,
              message: t('Cash boxes must be specified'),
            },
          ]}
          tooltip={t('Cash boxes')}>
          <SearchSelect
            resetState={resetCashBoxState}
            selectFirst={false}
            onSearch={(keywords) =>
              handleSearchCashBoxes({
                keywords,
                limit: 100,
                showLoading: false,
              })
            }
            getOptionValueTitle={(cashBox: CashBoxModel) => (
              <CashBoxTitleView
                cashBox={cashBox}
                disable={loadingSubmit || cashBoxListLoading}
              />
            )}
            name="payment_cashbox_uuid"
            placeholder={t('Select a cash box')}
            disable={loadingSubmit || cashBoxListLoading}
            data={isListToArray(
              cashBoxList?.filter(({box_type, box_can_out_money_from}) => {
                return (
                  box_type ===
                    convertedPaymentMethodToCashBox[
                      formData?.payment_method as PAYMENT_METHOD
                    ] && box_can_out_money_from
                );
              }) as any,
            )}
            onChange={(payment_cashbox_uuid) =>
              handlerUpdateFormState({payment_cashbox_uuid})
            }
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          label={t('note')}
          name="payment_comment"
          tooltip={t('Note')}
          rules={[
            {
              required: true,
              message: t('Note must be specified'),
            },
          ]}>
          <Input.TextArea
            allowClear
            autoSize={{minRows: 2, maxRows: 5}}
            disabled={loadingSubmit}
            placeholder={t('Enter your note')}
            onChange={(e) =>
              handlerUpdateFormState({payment_comment: e.target.value})
            }
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          label={t('Cashier')}
          name="payment_manager_uuid"
          rules={[
            {
              required: true,
              message: t('Cashier must be specified'),
            },
          ]}
          tooltip={t('Cashier')}>
          <SearchSelect
            onSearch={(keywords) =>
              handleSearchEmployees({
                keywords,
                limit: 100,
                showLoading: false,
              })
            }
            getOptionValueTitle="fullName"
            name="payment_manager_uuid"
            placeholder={t('Select a cashier')}
            disable={loadingSubmit || employeesLoading}
            data={isListToArray(employees as any)}
            onChange={(payment_manager_uuid) =>
              handlerUpdateFormState({payment_manager_uuid})
            }
          />
        </Form.Item>
      </Col>
    </Row>
  );
}
