import {Record} from 'immutable';
import Profile from './Profile';
import Client, {ClientProps} from './Client';
import Employee, {EmployeeProps} from './Employee';
import Company, {CompanyProps} from './Company';
import Services, {ServicesProps} from './Services';
import {toFloat} from '../helpers';

export const SCHEDULE_CALENDAR_STATUS_ACTIVE = 1;

export interface ScheduleCalendarProps {
  scheduled_date: string;
  status: string;
  uuid: string;
  employee: EmployeeProps;
  client: ClientProps;
  service: ServicesProps;
  updated_at: string;
  created_at: string;
  status_text: string;
  status_color: string;
  price: string;
  company: CompanyProps;
  schedule_number: string;
  sum_total: string;
}

/**
 * @class ScheduleCalendar
 * @classdesc
 * @extends {Record}
 */
export default class ScheduleCalendar extends Record({
  scheduled_date: '',
  status: '',
  uuid: '',
  employee: {
    profile: undefined,
  } as Employee,
  client: {} as Client,
  service: {} as Services,
  updated_at: null,
  created_at: null,
  status_text: '',
  price: '',
  sum_total: '',
  company: {} as Company,
  schedule_number: '',
  status_color: '',
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(props: ScheduleCalendarProps = {} as ScheduleCalendarProps) {
    super({
      ...props,
      ...{
        price: toFloat(props?.price || 0),
        sum_total: toFloat(props?.sum_total || 0),
        // @ts-ignore
        company: new Company(props.company || props.Company),
        // @ts-ignore
        service: new Services(props.service || props.Services),
        // @ts-ignore
        client: new Client(props.client || props.Client),
        // @ts-ignore
        employee: new Employee(props.employee || props.Employee),
      },
    });
  }

  /**
   * @desc Імя співробітника
   * @return {String}
   * */
  get fullName() {
    // @ts-ignore
    return new Profile(this.employee?.profile || {}).fullName;
  }

  /**
   * @desc Імя клієнта
   * @return {String}
   * */
  get fullNameClient(): string {
    return new Client(this.client || ({} as any)).fullNameClient;
  }
}
