import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Col, Form, Input, InputNumber, Row} from 'antd';
import {StyledDescription} from '../../../components/lib/Styled';
import {DatePicker} from '../../../components/lib/General';
import {SearchSelect, Segmented} from '../../../components/lib/DataDisplay';
import {
  PAYMENT_METHOD_FORM,
  convertedPaymentMethodToCashBox,
} from '../../../services/api/companyPayments';
import {
  CLIENT_REFUNDS_TYPE,
  ICalculationRefundsClientAbonementProps,
} from '../../../services/api/client';
import {useTranslation} from 'react-i18next';
import {useDefaultForm} from '../../../contex';
import {
  IUseStateCashBoxListReturnType,
  useSize,
  useStoredCompanies,
} from '../../../hooks';
import {
  CashBoxModel,
  ClientRefundsDTO,
  ClientRefundsType,
  PAYMENT_METHOD,
} from '../../../struture';
import styled from 'styled-components';
import {isListToArray} from '../../../services/helpers';
import {phoneScreen} from '../../../services/const';
import {CashBoxTitleView} from '../../Payments/Show/CashBoxTitleView';

export interface IClientRefundsFieldsProps
  extends Pick<
    IUseStateCashBoxListReturnType,
    'cashBoxList' | 'handleSearchCashBoxes'
  > {
  handleCalculateRefundsClientAbonement: (
    value: ICalculationRefundsClientAbonementProps,
  ) => Promise<ClientRefundsDTO>;
  editMode: boolean;

  cashBoxListLoading: boolean;
}

const FullWidthInputNumber = styled(InputNumber)`
  width: 100%;
`;

const StyledFormInputNumber = styled(Form.Item)`
  & {
    .ant-input-number {
      border-color: ${({theme}) => theme.colors.success};
    }
  }
`;
const StyledFormInputPrice = styled(Form.Item)`
  & {
    .ant-input-group-addon,
    .ant-input {
      border-color: ${({theme}) => theme.colors.success};
    }
  }

  &.ant-form-item-has-error {
    .ant-input-group-addon {
      border-color: ${({theme}) => theme.colors.error};
    }
  }
`;

export function ClientRefundsFields({
  handleCalculateRefundsClientAbonement,
  editMode,

  cashBoxList,
  cashBoxListLoading,
  handleSearchCashBoxes,
}: IClientRefundsFieldsProps): JSX.Element {
  const {t} = useTranslation();
  const {loadingSubmit, handlerUpdateFormState, formData} = useDefaultForm();
  const {defaultCompany} = useStoredCompanies();

  const contentRef = useRef(null);
  const {width: contentWidth} = useSize(contentRef);
  const isFullWidth = contentWidth <= phoneScreen ? 24 : 12;

  const [init, setInit] = useState(false);
  const [loadingRefunds, setLoadingRefunds] = useState(false);
  const [disabledRefundTypeField, setDisabledRefundTypeField] = useState(false);
  const [resetCashBoxState, setResetCashBoxState] = useState(false);

  const once = useRef(false);

  const handleRecalculateRefunds = useCallback(
    async ({
      start_date = formData?.refund_start_date,
      refund_type = formData?.refund_type,
      refund_visits = formData?.refund_visits,
    }: Partial<ICalculationRefundsClientAbonementProps>): Promise<ClientRefundsDTO> => {
      return await handleCalculateRefundsClientAbonement({
        start_date,
        refund_type,
        ...(refund_visits && refund_type === ClientRefundsType.Visit
          ? {refund_visits}
          : {}),
      });
    },
    [
      formData?.refund_start_date,
      formData?.refund_type,
      formData?.refund_visits,
      handleCalculateRefundsClientAbonement,
    ],
  );

  const handleChangeType = useCallback(
    async (refund_type: ClientRefundsType) => {
      if (init) {
        setLoadingRefunds(true);
        handlerUpdateFormState({refund_type});

        if (!editMode) {
          const {sum, unused_days, unused_visits} =
            await handleRecalculateRefunds({
              refund_type,
            });

          handlerUpdateFormState({
            refund_sum: sum,
            unused_days,
            current_sum: sum,
            refund_visits:
              refund_type === ClientRefundsType.Date
                ? null
                : formData?.refund_visits || unused_visits,
            current_unused_visits: unused_visits,
          });
        }

        setLoadingRefunds(false);
      }
    },
    [
      editMode,
      formData?.refund_visits,
      handleRecalculateRefunds,
      handlerUpdateFormState,
      init,
    ],
  );

  const handleChangeVisits = useCallback(
    async (refund_visits: number) => {
      if (
        refund_visits &&
        refund_visits > 0 &&
        refund_visits <= formData?.current_unused_visits &&
        init
      ) {
        setLoadingRefunds(true);
        handlerUpdateFormState({refund_visits});

        if (!editMode) {
          const {sum, unused_days, unused_visits} =
            await handleRecalculateRefunds({
              refund_visits,
            });

          handlerUpdateFormState({
            refund_sum: sum,
            unused_days,
            current_sum: sum,
            current_unused_visits: unused_visits,
          });
        }

        setLoadingRefunds(false);
      }
    },
    [
      editMode,
      formData?.current_unused_visits,
      handleRecalculateRefunds,
      handlerUpdateFormState,
      init,
    ],
  );

  const handleChangeDate = useCallback(
    async (refund_start_date: string) => {
      if (init) {
        setLoadingRefunds(true);
        handlerUpdateFormState({refund_start_date});

        if (!editMode) {
          const {sum, unused_days, unused_visits} =
            await handleRecalculateRefunds({
              start_date: refund_start_date,
            });

          handlerUpdateFormState({
            refund_sum: sum,
            unused_days,
            refund_visits: formData?.refund_visits || unused_visits,
            current_sum: sum,
            current_unused_visits: unused_visits,
          });
        }

        setLoadingRefunds(false);
      }
    },
    [
      editMode,
      formData?.refund_visits,
      handleRecalculateRefunds,
      handlerUpdateFormState,
      init,
    ],
  );

  const handleChangePrice = useCallback(() => {}, []);

  useEffect(() => {
    if (!init && formData?.refund_start_date && !editMode) {
      (async () => {
        setLoadingRefunds(true);
        const {sum, unused_days, unused_visits} =
          await handleRecalculateRefunds({
            start_date: formData?.refund_start_date,
          });
        handlerUpdateFormState({
          refund_sum: sum,
          unused_days,
          current_sum: sum,
          refund_visits: unused_visits,
          current_unused_visits: unused_visits,
        });
        setLoadingRefunds(false);
        setInit(true);
      })();
    }
  }, [
    editMode,
    formData?.refund_start_date,
    handleChangeDate,
    handleRecalculateRefunds,
    handlerUpdateFormState,
    init,
  ]);

  useEffect(() => {
    if (!once.current && formData?.refund_type) {
      once.current = true;
      setDisabledRefundTypeField(
        formData?.refund_type === ClientRefundsType.Visit,
      );
    }
  }, [formData?.refund_type]);

  return (
    <Row gutter={12} ref={contentRef}>
      <Col span={24}>
        <Form.Item
          label={t('Type')}
          name="refund_type"
          rules={[
            {
              required: true,
              message: t('Type method must be specified'),
            },
          ]}
          tooltip={{
            title: t('Type'),
          }}>
          <Segmented
            options={CLIENT_REFUNDS_TYPE}
            disabled={loadingSubmit || disabledRefundTypeField}
            onChange={handleChangeType as any}
          />
        </Form.Item>
      </Col>
      {formData?.refund_type === ClientRefundsType.Date ? null : (
        <Col span={24}>
          <StyledFormInputNumber
            label={t('Number of classes')}
            name="refund_visits"
            rules={[
              () => ({
                validator(_, value) {
                  if (Number(value) < 0) {
                    return Promise.reject(
                      new Error(
                        t(
                          'Number of classes must be a number and bigger than 0',
                        ),
                      ),
                    );
                  }

                  if (Number(value) > Number(formData?.current_unused_visits)) {
                    return Promise.reject(
                      new Error(
                        `${t('Number of classes must be less than')} ${
                          formData?.current_unused_visits
                        }`,
                      ),
                    );
                  }

                  if (typeof value !== 'number' && !Number(value)) {
                    return Promise.reject(
                      new Error(t('Number of classes must be specified')),
                    );
                  }

                  return Promise.resolve();
                },
              }),
            ]}
            tooltip={{
              title: t('Number of classes'),
            }}
            extra={
              formData?.current_unused_visits ? (
                <StyledDescription style={{whiteSpace: 'unset'}}>
                  {`${t('Base number of classes')}: ${
                    formData?.current_unused_visits
                  }`}
                </StyledDescription>
              ) : null
            }>
            <FullWidthInputNumber
              disabled={loadingSubmit}
              placeholder={t('Enter the number of classes')}
              onChange={handleChangeVisits as any}
            />
          </StyledFormInputNumber>
        </Col>
      )}
      <Col span={24}>
        <Form.Item
          tooltip={t('Date')}
          label={t('Date')}
          name="refund_start_date"
          rules={[
            {
              required: true,
              message: t('Date must be specified.'),
              type: 'date',
            },
          ]}
          extra={
            <StyledDescription style={{whiteSpace: 'unset'}}>
              {t(
                'The date of disconnection of the subscription and the beginning of the recalculation interval',
              )}
            </StyledDescription>
          }>
          <DatePicker
            showTime
            format="YYYY-MM-DD HH:mm"
            disabled={loadingSubmit}
            placeholder={t('Date')}
            onChange={handleChangeDate as any}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <StyledFormInputPrice
          label={t('Price')}
          name="refund_sum"
          rules={[
            () => ({
              validator(_, amount) {
                const value = amount.replace(/[a-zA-Z]+/g, '');

                if (Number(value) < 0) {
                  return Promise.reject(
                    new Error(t('The price must be greater than 0')),
                  );
                }

                if (Number(value) > Number(formData?.current_sum)) {
                  return Promise.reject(
                    new Error(
                      `${t('The price must be less than')} ${
                        formData?.current_sum
                      }`,
                    ),
                  );
                }

                if (Number(value) !== 0 && !Number(value)) {
                  return Promise.reject(
                    new Error(t('The price must be a number')),
                  );
                }

                return Promise.resolve();
              },
            }),
          ]}
          extra={
            <StyledDescription style={{whiteSpace: 'unset'}}>
              {`${t('The basic cost of the abonement')}: ${
                defaultCompany?.currency_symbol
              } ${formData?.price}${
                formData?.unused_days
                  ? `, ${t('the balance of the abonement')} ${
                      formData?.unused_days
                    } ${t('days')}`
                  : ''
              }`}
            </StyledDescription>
          }>
          <Input
            addonBefore={defaultCompany?.currency_symbol}
            disabled={loadingSubmit || loadingRefunds}
            onChange={handleChangePrice}
          />
        </StyledFormInputPrice>
      </Col>

      <Col span={isFullWidth}>
        <Form.Item
          label={t('Forms of payment')}
          name="refund_payment_method"
          tooltip={t('Forms of payment')}>
          <Segmented
            options={PAYMENT_METHOD_FORM}
            disabled={loadingSubmit}
            onChange={(refund_payment_method) => {
              handlerUpdateFormState({
                refund_payment_method,
              });

              if (typeof formData?.refund_payment_method === 'string') {
                handlerUpdateFormState({
                  payment_cashbox_uuid: '',
                });

                setResetCashBoxState(true);
                setTimeout(() => {
                  setResetCashBoxState(false);
                }, 100);
              }
            }}
          />
        </Form.Item>
      </Col>
      <Col span={isFullWidth}>
        <Form.Item
          label={t('Cash boxes')}
          name="refund_cashbox_uuid"
          rules={[
            {
              required: true,
              message: t('Cash boxes must be specified'),
            },
          ]}
          tooltip={t('Cash boxes')}>
          <SearchSelect
            resetState={resetCashBoxState}
            selectFirst={false}
            onSearch={(keywords) =>
              handleSearchCashBoxes({
                keywords,
                limit: 100,
                showLoading: false,
              })
            }
            getOptionValueTitle={(cashBox: CashBoxModel) => (
              <CashBoxTitleView
                cashBox={cashBox}
                disable={loadingSubmit || cashBoxListLoading}
              />
            )}
            name="refund_cashbox_uuid"
            placeholder={t('Select a cash box')}
            disable={loadingSubmit || cashBoxListLoading}
            data={isListToArray(
              cashBoxList?.filter(
                ({box_type, box_can_out_money_from}) =>
                  box_type ===
                    convertedPaymentMethodToCashBox[
                      formData?.refund_payment_method as PAYMENT_METHOD
                    ] && box_can_out_money_from,
              ) as any,
            )}
            onChange={(refund_cashbox_uuid) =>
              handlerUpdateFormState({refund_cashbox_uuid})
            }
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item label={t('Note')} name="refund_comment">
          <Input.TextArea
            allowClear
            autoSize={{minRows: 2, maxRows: 5}}
            disabled={loadingSubmit}
            placeholder={t('Enter your note')}
            onChange={(e) => handlerUpdateFormState({comment: e.target.value})}
          />
        </Form.Item>
      </Col>
    </Row>
  );
}
