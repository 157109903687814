import React from 'react';
import {PAYMENT_METHOD, PAYMENT_STATUS, PaymentModel} from '../../../struture';
import {OPERATION_TYPE} from '../../../services/types';
import {ColorView} from '../../../components/lib/DataDisplay';
import {StyledTitle} from '../../../components/lib/Styled';
import styled, {useTheme} from 'styled-components';
import {InfoCircleOutlined} from '@ant-design/icons';
import {Tooltip} from 'antd';
import {useTranslation} from 'react-i18next';

export interface IPaymentSumTableFieldProps {
  payment: PaymentModel;
  className?: string;
}

const PaymentSumContainer = styled.div``;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  justify-content: flex-end;

  width: 100%;
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  color: ${({theme}) => theme.colors.error};
`;

const Title = styled(StyledTitle)`
  text-decoration: line-through;
`;

export function PaymentSumTableField({
  payment,
  className,
}: IPaymentSumTableFieldProps): React.JSX.Element {
  const {t} = useTranslation();
  const theme: any = useTheme();

  const statusColor = {
    [PAYMENT_STATUS.Draft]: theme.colors.link,
    [PAYMENT_STATUS.Completed]: theme.colors.success,
    [PAYMENT_STATUS.Cancelled]: theme.colors.error,
    [PAYMENT_STATUS.Deferred]: theme.colors.link,
    Unknown: theme.colors.primary,
  };

  return (
    <PaymentSumContainer className={className}>
      {payment?.payment_is_credit_operation ? (
        <StyledContainer>
          <Title $color={theme.colors.error}>{payment?.payment_sum}</Title>
          <Tooltip title={t('The operation was carried out in debt')}>
            <StyledInfoCircleOutlined />
          </Tooltip>
        </StyledContainer>
      ) : (
        <ColorView
          color={
            payment.payment_method === PAYMENT_METHOD.Account
              ? theme.colors.disabled
              : payment?.payment_type === OPERATION_TYPE.OUT
              ? theme.colors.error
              : statusColor[payment?.payment_status] || statusColor.Unknown
          }>
          {`${payment?.payment_sum}`}
        </ColorView>
      )}
    </PaymentSumContainer>
  );
}
