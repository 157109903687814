import styled from 'styled-components';
import React, {forwardRef} from 'react';
import {StyledDescription} from '../Styled/Text';
import {Switch as ANTDSwitch, SwitchProps} from 'antd';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';

export interface ISwitchProps extends SwitchProps {
  title?: string;
  position?: 'left' | 'right';
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export const Switch = forwardRef(function Switch(
  {title, position = 'right', ...props}: ISwitchProps,
  ref,
): JSX.Element {
  return (
    <StyledContainer>
      {position === 'left' && title ? (
        <StyledDescription fontSize={15}>{title}</StyledDescription>
      ) : null}
      <ANTDSwitch
        ref={ref as any}
        checkedChildren={title ? null : <CheckOutlined />}
        unCheckedChildren={title ? null : <CloseOutlined />}
        {...props}
      />
      {position === 'right' && title ? (
        <StyledDescription fontSize={15}>{title}</StyledDescription>
      ) : null}
    </StyledContainer>
  );
});
