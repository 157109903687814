import React, {useCallback, useRef, useState} from 'react';
import {Col, Form, Input, Row} from 'antd';
import {SearchSelect} from '../../../components/lib/DataDisplay';
import {
  checkWorkSchedule,
  curry,
  findItemIndex,
  head,
  ifElse,
  isListToArray,
  joinArr,
  modifyWeekdays,
  split,
  toMoment,
} from '../../../services/helpers';
import {
  DatePicker,
  WeekdayTimePickerForm,
} from '../../../components/lib/General';
import {useTranslation} from 'react-i18next';
import {useDefaultForm} from '../../../contex';
import {IUseStateEmployeeListReturnType} from '../../../hooks';
import {IOnChangeDaysOfWeekSchedule, WorkScheduleLabel} from '../../Company';
import styled from 'styled-components';
import './ClientAbonementScheduleFields.less';
import {Moment} from 'moment';

export interface IClientAbonementScheduleFieldsProps
  extends Pick<
    IUseStateEmployeeListReturnType,
    'employees' | 'handleSearchEmployees'
  > {
  employeesLoading: boolean;
  minDate: string;
}
const StyledRangePicker = styled(DatePicker.RangePicker)`
  width: 100%;
`;

export function ClientAbonementScheduleFields({
  employees,
  employeesLoading,
  handleSearchEmployees,
  minDate,
}: IClientAbonementScheduleFieldsProps): JSX.Element {
  const {t} = useTranslation();
  const {loadingSubmit, formData, handlerUpdateFormState} = useDefaultForm();

  const [isFixedRange, setIsFixedRange] = useState(
    checkWorkSchedule(formData?.schedule ? formData?.schedule : ''),
  );

  const fixedRange = useRef<any>(
    ifElse<[boolean, string[], string[]], string>(
      checkWorkSchedule(formData?.schedule || ''),
      split(',', formData?.schedule || ''),
      [],
    ),
  );

  const weekRange = useRef(
    ifElse(
      checkWorkSchedule(formData?.schedule || ''),
      [],
      split(',', formData?.schedule || ''),
    ),
  );

  const handleChangeDateRangeView = useCallback((): void => {
    setIsFixedRange((prevState) => {
      return !prevState;
    });
  }, []);

  const updateWeekRange = useCallback(
    ({
      time,
      handlerUpdateFormState,
    }: Pick<IOnChangeDaysOfWeekSchedule, 'time'> & {
      handlerUpdateFormState: any;
    }): void => {
      const updatedWeekRange = [joinArr('-', time as string[])];

      fixedRange.current = head(updatedWeekRange) ? updatedWeekRange : [];
      handlerUpdateFormState({
        weekdays: updatedWeekRange,
        schedule:
          Array.isArray(updatedWeekRange) && updatedWeekRange.length
            ? updatedWeekRange.join(',')
            : '',
      });
    },
    [fixedRange],
  );

  const updateCustomRange = useCallback(
    ({
      name,
      time,
      handlerUpdateFormState,
      fieldValue,
      ...rest
    }: IOnChangeDaysOfWeekSchedule & {
      handlerUpdateFormState: any;
    }): void => {
      const weekdays = Array.isArray(fieldValue) ? fieldValue : [];
      const index = findItemIndex(name, weekdays);

      const updatedWeekRange = modifyWeekdays({
        weekdays,
        index,
        name,
        time: Array.isArray(time) ? joinArr('-', time as string[]) : '',
        ...rest,
      });

      weekRange.current = updatedWeekRange;

      handlerUpdateFormState({
        weekdays: updatedWeekRange,
        schedule:
          Array.isArray(updatedWeekRange) && updatedWeekRange.length
            ? updatedWeekRange.join(',')
            : '',
      });
    },
    [weekRange],
  );

  const onChangeDaysOfWeek = curry<any, any>(
    ({handlerUpdateFormState}, value: IOnChangeDaysOfWeekSchedule) => {
      if (isFixedRange) {
        updateWeekRange({time: value?.time, handlerUpdateFormState});
      }

      if (!isFixedRange) {
        updateCustomRange({...value, handlerUpdateFormState});
      }
    },
  );

  const handleChangeDates = useCallback(
    (dates: [Moment, Moment]) => {
      const [start, end] = dates;

      handlerUpdateFormState({
        schedule_start_date: start.toISOString(),
        schedule_end_date: end.toISOString(),
      });
    },
    [handlerUpdateFormState],
  );

  return (
    <Row gutter={12}>
      <Col span={24}>
        <Col span={24}>
          <Form.Item
            tooltip={t('Start and end date')}
            label={t('Start and end date')}
            name="_date"
            rules={[
              {
                required: true,
                message: t('Start and end date must be specified.'),
              },
            ]}>
            <StyledRangePicker
              popupClassName="client-schedule-range-picker"
              showTime
              format="YYYY-MM-DD"
              disabled={loadingSubmit}
              placeholder={[t('Start'), t('End')]}
              onChange={handleChangeDates as any}
              disabledDate={(current) => {
                return current && current < toMoment(minDate).startOf('day');
              }}
            />
          </Form.Item>
        </Col>
        <Form.Item
          tooltip={t('Manager')}
          label={t('Manager')}
          name="manager_uuid"
          rules={[
            {
              required: true,
              message: t('Manager must be specified.'),
            },
          ]}>
          <SearchSelect
            selectIfOnFirst={false}
            selectFirst={false}
            name="manager_uuid"
            getOptionValueTitle="fullName"
            placeholder={t('Select an employee')}
            disable={loadingSubmit || employeesLoading}
            data={isListToArray(employees as any)}
            onChange={(manager_uuid) => handlerUpdateFormState({manager_uuid})}
            onSearch={(keywords) =>
              handleSearchEmployees({
                keywords,
                showLoading: false,
                limit: 100,
              })
            }
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          tooltip={t('Weekly schedule')}
          className="company-form__work-schedule"
          label={
            <WorkScheduleLabel
              label={'Weekly schedule'}
              disabled={loadingSubmit}
              isFixedRange={isFixedRange}
              handleChangeView={handleChangeDateRangeView}
            />
          }
          rules={[
            {
              validator: () => {
                if (!formData?.weekdays) {
                  return Promise.reject(
                    new Error(t('Weekly schedule must be specified.')),
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
          name="weekdays">
          <WeekdayTimePickerForm
            checkboxDisable={false}
            isRangePicker
            isFixedRange={isFixedRange}
            editMode={true}
            disabled={loadingSubmit}
            onChange={onChangeDaysOfWeek({
              handlerUpdateFormState,
            })}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item label={t('Note')} name="comment">
          <Input.TextArea
            allowClear
            autoSize={{minRows: 2, maxRows: 5}}
            disabled={loadingSubmit}
            placeholder={t('Enter your note')}
            onChange={(e) => handlerUpdateFormState({comment: e.target.value})}
          />
        </Form.Item>
      </Col>
    </Row>
  );
}
