import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {PhotoListModel} from '../../struture';
import {REDUX_STATUS} from '../../services/types';
import {RootState} from '../reducers';
import {List, Map} from 'immutable';
import {neq} from '../../services/helpers';

export interface PhotoListState {
  cachedPhotoMap: Map<string, PhotoListModel>;
  loading: boolean;
  status: REDUX_STATUS;
}

export interface SetPhotoListAction {
  photoList: PhotoListModel;
  triggerObjectUuid: string;
}

export interface DeletePhotoAction {
  photoUuid: string;
  triggerObjectUuid: string;
}

const initialState: PhotoListState = {
  cachedPhotoMap: Map(),
  loading: true,
  status: REDUX_STATUS.IDLE,
};

export const photoListSlice = createSlice({
  name: 'photoList',
  initialState,
  reducers: {
    setPhotoList: (state, {payload}: PayloadAction<SetPhotoListAction>) => {
      const {photoList, triggerObjectUuid}: any = payload;
      const {cachedPhotoMap} = state;

      if (photoList) {
        return {
          ...state,
          cachedPhotoMap: cachedPhotoMap.set(triggerObjectUuid, photoList),
          status: REDUX_STATUS.SUCCEEDED,
          loading: false,
        };
      }
    },

    setInitialState: () => {
      return initialState;
    },

    addPhotoToList: (
      state: any,
      {payload}: PayloadAction<SetPhotoListAction>,
    ) => {
      const {photoList, triggerObjectUuid} = payload;

      let {cachedPhotoMap}: PhotoListState = state;

      return {
        ...state,
        loading: false,
        status: REDUX_STATUS.SUCCEEDED,
        cachedPhotoMap: cachedPhotoMap?.update(triggerObjectUuid, (list) => {
          if (list && List.isList(list?.photos)) {
            return list
              .update('photos', (photos) => photos.merge(photoList?.photos))
              .update('total', (total = 0) => total + (photoList?.total || 0));
          }

          return photoList;
        }),
      };
    },

    deletePhotoFromList: (
      state: any,
      {payload}: PayloadAction<DeletePhotoAction>,
    ) => {
      const {photoUuid, triggerObjectUuid} = payload;

      let {cachedPhotoMap}: PhotoListState = state;

      let isDelete = false;

      return {
        ...state,
        loading: false,
        status: REDUX_STATUS.SUCCEEDED,
        cachedPhotoMap: cachedPhotoMap?.update(triggerObjectUuid, (list) => {
          if (list && List.isList(list?.photos)) {
            return list
              .update('photos', (photos) =>
                photos.filter(({uuid}) => {
                  if (neq(photoUuid, uuid)) {
                    return true;
                  }

                  isDelete = true;

                  return false;
                }),
              )
              .update('total', (total = 0) =>
                total > 0 && isDelete ? total - 1 : total,
              );
          }

          return list;
        }),
      };
    },
  },
});

export const {
  setPhotoList,
  setInitialState,
  addPhotoToList,
  deletePhotoFromList,
} = photoListSlice.actions;

export const selectPhotoList = ({photoListReducer}: RootState) =>
  photoListReducer;

export default photoListSlice.reducer;
