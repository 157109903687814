import * as React from 'react';
import {List} from 'immutable';
import {ApiError, IEntityProps, IListSearchProps} from '../services/types';
import {
  IStatusCategoryListDTO,
  StatusMapper,
  StatusCategoryModel,
  StatusCategoryListModel,
} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {getStatusCategoryList} from '../services/api/system';
import {useDropdownAlert} from '../contex';
import {useTranslation} from 'react-i18next';

export interface IUseStatusCategoryListProps
  extends Partial<IListSearchProps>,
    IEntityProps {}

export interface IUseStatusCategoryListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<StatusCategoryModel> | null;
  refresh: (
    value: Partial<IUseStatusCategoryListProps>,
  ) => Promise<StatusCategoryListModel | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
}

export function useStatusCategoryList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
  }: IUseStatusCategoryListProps = {} as IUseStatusCategoryListProps,
): IUseStatusCategoryListReturnType {
  const {cancellablePromise} = useCancellablePromise();
  const {alert} = useDropdownAlert();
  const {t} = useTranslation();

  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    handleUpdate,
  } = useStateReducer<Omit<IUseStatusCategoryListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
    }: Partial<IUseStatusCategoryListProps> = {}): Promise<StatusCategoryListModel | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
        });

        const {categories, total} =
          await cancellablePromise<IStatusCategoryListDTO>(
            getStatusCategoryList(),
          );

        const statusListModel = StatusMapper.toStatusCategoryListModel(
          categories,
          total,
        );

        handleUpdate({
          entityList: statusListModel?.categories,
          total: statusListModel?.total,
          loading: false,
        });

        return statusListModel;
      } catch (e: any) {
        alert(
          'error',
          t('Status categories'),
          `${t('An error occurred during get status category list')} : ${
            e?.message
          }`,
        );

        handleUpdate({
          error,
          loading: false,
        });
      }
    },
    [
      listLimit,
      listOffset,
      listKeywords,
      handleUpdate,
      cancellablePromise,
      alert,
      t,
      error,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit) {
      (async () => {
        await refresh();
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
  };
}
