import React, {
  JSX,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {Col, Empty, Form, Select} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {
  PriceMarginModel,
  ProductCategoryModel,
  ProductFormDTO,
  ProductMapper,
  ProductModel,
  ProductStatus,
  StoreDocumentFormDTO,
  StoreDocumentItemFormDTO,
  StoreDocumentItemListModel,
  StoreDocumentMapper,
} from '../../../struture';
import {StoreDocItemsField} from './StoreDocItemsField';
import styled, {useTheme, css} from 'styled-components';
import {Table} from '../../../components/lib/libV2/DataDisplay';
import {useTranslation} from 'react-i18next';
import {useDefaultForm, useDropdownAlert} from '../../../contex';
import {
  DocumentItemReturnType,
  IUseStateProductListReturnType,
  IUseStateProductReturnType,
  IUseStoreDocumentItemListProps,
  UseStateProductListReturnType,
  UseStateStoreDocumentItemListReturnType,
} from '../../../hooks';
import {
  correctPrice,
  head,
  listToArray,
  isFunction,
  debounce,
} from '../../../services/helpers';
import {List} from 'immutable';
import {useModal} from '../../../components/lib/libV2/hooks';
import {ProductTableView} from '../Show';
import {StyledDescription, StyledTitle} from '../../../components/lib/Styled';
import {produce} from 'immer';
import {ProductCreateButton} from '../../Products';
import {
  SearchProductStatus,
  SearchProductStatusType,
} from '../../../services/types';
import {ENTER_KEYS} from '../../../services/const';
import {LoadingMore, SuspenseEmpty} from '../../../components/lib/DataDisplay';

import './StoreProductListField.less';
import '../../Orders/List/OrderList.less';
import '../../Products/Pages/ProductList.less';

const StoreProductModalLazy = React.lazy(
  () => import('../Show/StoreProductModal'),
);
const StoreProductAmountModalLazy = React.lazy(
  () => import('../Show/StoreProductAmountModal'),
);

const ProductSideWindowLazy = React.lazy(
  () => import('../../Products/Show/ProductSideWindow'),
);

export interface IStoreProductListFieldProps
  extends Pick<
      IUseStateProductListReturnType,
      'productList' | 'handleSearchProducts'
    >,
    Partial<Pick<IUseStateProductReturnType, 'handleCreateProduct'>>,
    Pick<
      UseStateProductListReturnType,
      'isLoadingMore' | 'handleLoadingMoreProductList' | 'handleSetProductList'
    > {
  loading: boolean;
  editMode: boolean;
  productListLoading: boolean;
  productListLoadingMore: boolean;
  productListKeywords: string;

  defaultPriceMargin: PriceMarginModel | null;
  asAmountModal?: boolean;
  withCell?: boolean;
  withoutStoreUuid?: boolean;
  withoutCreateProduct?: boolean;
  disabled?: boolean;
  onSuccess?: (
    value: StoreDocumentItemFormDTO,
  ) => Promise<DocumentItemReturnType | void>;
  onDelete?: (ids: string[]) => Promise<void>;
  isCenterCreateProduct?: boolean;
  storeDocumentItemListRefresh?: (
    value: Partial<IUseStoreDocumentItemListProps>,
  ) => Promise<StoreDocumentItemListModel | void>;
  storeDocumentItemListLimit?: number;
  storeDocumentItemListTotal?: number;
  storeDocumentItemListPage?: number;
  withEditablePrice?: boolean;
  productWithBalance?: boolean;
  document: StoreDocumentFormDTO;
  isShowRelatedDocumentItems?: boolean;
  isOrderFields?: boolean;
}

const StyledTable = styled(Table)`
  margin: 0;
` as any;

const StyledProductListCol = styled(Col)`
  background: ${({theme}) => theme.background.layout};
  padding: 5px;
  border-radius: 7px;
`;

const StyledProductListContainer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  width: 100%;
`;

const StyledSelectContainer = styled.div<{$isCenterCreateProduct: boolean}>`
  display: flex;
  flex-direction: row;

  gap: 5px;

  ${({$isCenterCreateProduct}) =>
    $isCenterCreateProduct &&
    css`
      align-items: center;
    `}
`;

const StyledProductCreateButton = styled(ProductCreateButton)`
  margin-top: 3px;
  width: fit-content;
  height: fit-content;
`;

const StyledFormItem = styled(Form.Item)`
  flex: 1;
`;

export function StoreProductListField({
  loading,
  editMode,
  disabled,

  productList,
  productListLoading,
  productListLoadingMore,
  handleSearchProducts,
  productListKeywords,
  withoutCreateProduct,
  handleCreateProduct: onCreateProduct,
  handleSetProductList,

  defaultPriceMargin,
  asAmountModal,
  withCell,
  withoutStoreUuid,
  onSuccess,
  onDelete,
  isCenterCreateProduct = true,

  isLoadingMore,
  handleLoadingMoreProductList,
  storeDocumentItemListRefresh,
  storeDocumentItemListLimit,
  storeDocumentItemListTotal,
  storeDocumentItemListPage,
  withEditablePrice,

  productWithBalance,
  document: storeDocument,

  isShowRelatedDocumentItems,
  isOrderFields,
}: IStoreProductListFieldProps): JSX.Element {
  const {t} = useTranslation();
  const {loadingSubmit, formData, handlerUpdateFormState} = useDefaultForm();
  const theme: any = useTheme();
  const {alert} = useDropdownAlert();

  const tableRef = useRef<HTMLTableElement>(null);
  const selectRef = useRef<HTMLInputElement>(null);
  const isDisabledResetSelectedItem = useRef(false);
  const inputStatusRef = useRef<SearchProductStatusType>({
    status: SearchProductStatus.END_REQUEST,
    isEnterPress: false,
  });

  const [selectedProductItem, setSelectedProductItem] =
    useState<StoreDocumentItemFormDTO | null>(null);
  const [selectedProductIndex, setSelectedProductIndex] = useState<number>(0);
  const [storeProductList, setStoreProductList] = useState<ProductModel[]>([]);
  const [isCreateProduct, setIsCreateProduct] = useState(false);
  const [selectedProductItemUuid, setSelectedProductItemUuid] = useState('');
  const [category, setCategory] = useState<ProductCategoryModel | null>(null);
  const [isAddAnotherProduct, setIsAddAnotherProduct] = useState(false);

  const handleChangeInputStatus = useCallback(
    (status: SearchProductStatus, isEnterPress: boolean = false) => {
      if (
        inputStatusRef.current.status === SearchProductStatus.PRESS_ENTER &&
        status === SearchProductStatus.START_INPUT
      ) {
        return;
      }

      if (
        inputStatusRef.current.status === SearchProductStatus.END_REQUEST &&
        status === SearchProductStatus.PRESS_ENTER
      ) {
        return;
      }

      inputStatusRef.current.isEnterPress =
        status === SearchProductStatus.PRESS_ENTER
          ? isEnterPress
          : inputStatusRef.current.isEnterPress;
      inputStatusRef.current.status = status;
    },
    [],
  );

  const getProductPriceMargin = useCallback(
    (product: ProductModel, priceMargin: PriceMarginModel | null) => {
      if (!priceMargin) {
        return correctPrice(product?.product_prices[0]?.price_value || 0);
      }

      const price = product?.product_prices?.find(
        ({price_uuid}) => price_uuid === priceMargin?.uuid,
      );

      return correctPrice(price?.price_value || 0);
    },
    [],
  );

  const handledSuccess = useCallback(
    async (
      docItem: StoreDocumentItemFormDTO,
    ): Promise<StoreDocumentItemFormDTO | void> => {
      if (isFunction(onSuccess)) {
        try {
          const documentItemModel = await onSuccess(docItem);

          const {documentItem: item} = documentItemModel || {};

          if (item) {
            const itemForm = StoreDocumentMapper.toStoreDocumentItemFormDTO(
              item,
              {
                editMode: true,
              },
            );

            alert(
              'success',
              t('Store document item'),
              t('Document item edit success'),
            );
            return itemForm;
          }
        } catch (error: any) {
          alert(
            'error',
            t('Store document item'),
            `${
              editMode
                ? t('An error occurred during edit document item')
                : t('An error occurred during create document item')
            } : ${error?.message}`,
          );
        }
      }
    },
    [alert, editMode, onSuccess, t],
  );

  const {
    handleCancel: productHandleCancel,
    handleOnInit: productHandleOnInit,
    visible: productVisible,
  } = useModal({
    onSuccess: () => {},
  });

  const handleAddOrUpdateDocItems = useCallback(
    async (docItem: StoreDocumentItemFormDTO) => {
      let docItems = formData?.doc_items || [];

      const index = docItems?.findIndex(
        ({product_uuid}: any) => product_uuid === docItem?.product_uuid,
      );

      if (isFunction(onSuccess)) {
        let value;

        const item = docItems?.find(
          ({product_uuid}: any) => product_uuid === docItem?.product_uuid,
        );

        if (!item) {
          value = produce(docItem, (draft) => {
            draft.uuid = '';
          });
        } else {
          value = produce(docItem, (draft) => {
            draft.uuid = item?.uuid;
          });
        }

        const updatedItem = await handledSuccess(value);

        if (updatedItem) {
          docItem = updatedItem;
        } else {
          return;
        }
      } else {
        docItem = produce(docItem, (draft) => {
          draft.base_price = draft?.price;
        });
      }

      if (~index) {
        docItems = docItems.map((item: any) => {
          if (item?.product_uuid === docItem?.product_uuid) {
            return docItem;
          }
          return item;
        });
      } else {
        docItems = [...docItems, docItem];
      }

      handlerUpdateFormState({doc_items: docItems});

      if (isAddAnotherProduct) {
        setTimeout(productHandleOnInit, 250);
      }

      selectRef?.current?.focus();
    },
    [
      formData?.doc_items,
      handledSuccess,
      handlerUpdateFormState,
      isAddAnotherProduct,
      onSuccess,
      productHandleOnInit,
    ],
  );

  const {visible, handleSuccess, handleCancel, handleOnInit} = useModal({
    onSuccess: handleAddOrUpdateDocItems,
    onCancel: () => {
      setSelectedProductItem(null);
      setIsAddAnotherProduct(false);
    },
  });

  const {
    visible: amountVisible,
    handleSuccess: amountHandleSuccess,
    handleCancel: amountHandleCancel,
    handleOnInit: amountHandleOnInit,
  } = useModal({
    onSuccess: handleAddOrUpdateDocItems,
    onCancel: () => {
      setSelectedProductItem(null);
      setIsAddAnotherProduct(false);
    },
  });

  const handleInitModal = useCallback(
    (isCheck: boolean = true) => {
      if (isCheck) {
        if (
          selectedProductItem?.product?.product_status !== ProductStatus.ENABLE
        ) {
          alert('error', t('Product'), t('Operations with goods are blocked.'));
          return;
        }
      }

      if (asAmountModal) {
        amountHandleOnInit();
      } else {
        handleOnInit();
      }

      setSelectedProductIndex(0);
    },
    [
      alert,
      amountHandleOnInit,
      asAmountModal,
      handleOnInit,
      selectedProductItem,
      t,
    ],
  );

  const handleSetSelectedProductItem = useCallback(
    (product: any) => {
      setSelectedProductItem(
        StoreDocumentMapper.toStoreDocumentItemFormDTO(product, {
          editMode: false,
          price: asAmountModal
            ? getProductPriceMargin(product, defaultPriceMargin)
            : correctPrice(product?.product_max_income_price || 0),
        }),
      );
    },
    [asAmountModal, defaultPriceMargin, getProductPriceMargin],
  );

  const onProductSelect = useCallback(
    (record: any) => {
      if (record?.product_status !== ProductStatus.ENABLE) {
        alert('error', t('Product'), t('Operations with goods are blocked.'));
        return;
      }

      handleSetSelectedProductItem(record);

      handleInitModal(false);
    },
    [alert, handleInitModal, handleSetSelectedProductItem, t],
  );

  const handleCreateProduct = useCallback(
    async (value: ProductFormDTO) => {
      if (isFunction(onCreateProduct)) {
        const product = await onCreateProduct(value);

        if (product) {
          onProductSelect(product);
        }

        if (value?.add_another_product) {
          setCategory(product?.categoryModel || null);
          setIsAddAnotherProduct(true);
        }
      }
    },
    [onCreateProduct, onProductSelect],
  );

  const handleEditDocItem = useCallback(
    (docItem: StoreDocumentItemFormDTO) => {
      if (docItem?.product?.product_status !== ProductStatus.ENABLE) {
        alert('error', t('Product'), t('Operations with goods are blocked.'));
        return;
      }

      setSelectedProductItem(docItem);

      handleInitModal(false);
    },
    [alert, handleInitModal, t],
  );

  const isAmountLessThanNormal = useCallback(
    (product: ProductModel) =>
      head(product?.product_balances)?.balance_total <
      (product?.product_min_amount || 0)
        ? theme.colors.error
        : theme.colors.black,
    [theme.colors.black, theme.colors.error],
  );

  const columns = useMemo(
    () => [
      {
        title: t('name-s'),
        key: 'name',
        className: 'order-list-td',
        render: (product: ProductModel) => (
          <ProductTableView
            product={product}
            price={getProductPriceMargin(product, defaultPriceMargin)}
          />
        ),
      },
      ...(productWithBalance
        ? [
            {
              title: t('tAmount'),
              key: 'amount',
              align: 'right' as any,
              render: (product: ProductModel) => (
                <StyledTitle
                  fontSize={18}
                  $color={isAmountLessThanNormal(product)}>
                  {head(product?.product_balances)?.balance_total}
                </StyledTitle>
              ),
            },
          ]
        : []),
    ],
    [
      defaultPriceMargin,
      getProductPriceMargin,
      isAmountLessThanNormal,
      productWithBalance,
      t,
    ],
  );

  useEffect(() => {
    const storeProductList: ProductModel[] = [];

    if (
      List.isList(productList) &&
      (productListKeywords?.length >= 3 || isShowRelatedDocumentItems)
    ) {
      if (withoutStoreUuid) {
        const product = productList?.first();

        if (product && !isDisabledResetSelectedItem?.current) {
          handleSetSelectedProductItem(product);
        }

        setStoreProductList(listToArray(productList));
      } else {
        productList?.forEach((product, index) => {
          if (index === 0 && !isDisabledResetSelectedItem?.current) {
            handleSetSelectedProductItem(product);
          }

          if (Array.isArray(product?.product_balances)) {
            product?.product_balances?.forEach((store) => {
              const updatedProduct = product.update('product_balances', () => [
                store,
              ]);
              storeProductList.push(updatedProduct);
            });
          } else {
            storeProductList.push(product);
          }
        });

        setStoreProductList(storeProductList);
      }
      isDisabledResetSelectedItem.current = false;
    }
  }, [
    handleSetSelectedProductItem,
    isShowRelatedDocumentItems,
    productList,
    productListKeywords?.length,
    withoutStoreUuid,
  ]);

  const onTableKeyboardChange = useCallback(
    (e: KeyboardEvent) => {
      const length = storeProductList?.length - 1;

      if (e.key === 'ArrowUp' && selectedProductIndex === 0) {
        const product = storeProductList[length];
        if (product) {
          handleSetSelectedProductItem(product);
          setSelectedProductIndex(() => length);
        }

        const selected = document.body.querySelector(
          `.posting-list-td-${length}`,
        );

        if (selected instanceof HTMLElement) {
          selected.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest',
          });
        }

        return;
      }

      if (e.key === 'ArrowUp' && selectedProductIndex <= length) {
        const product = storeProductList[selectedProductIndex - 1];

        if (product) {
          handleSetSelectedProductItem(product);
          setSelectedProductIndex((prevState) => --prevState);
        }

        const selected = document.body.querySelector(
          `.posting-list-td-${selectedProductIndex - 1}`,
        );

        if (selected instanceof HTMLElement) {
          selected.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest',
          });
        }

        return;
      }

      if (e.key === 'ArrowDown' && selectedProductIndex === length) {
        const product = storeProductList[0];
        if (product) {
          handleSetSelectedProductItem(product);
          setSelectedProductIndex(() => 0);
        }

        const selected = document.body.querySelector(`.posting-list-td-${0}`);

        if (selected instanceof HTMLElement) {
          selected.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest',
          });
        }

        return;
      }

      if (e.key === 'ArrowDown' && selectedProductIndex <= length) {
        const product = storeProductList[selectedProductIndex + 1];
        if (product) {
          handleSetSelectedProductItem(product);
          setSelectedProductIndex((prevState) => ++prevState);
        }

        const selected = document.body.querySelector(
          `.posting-list-td-${selectedProductIndex + 1}`,
        );

        if (selected instanceof HTMLElement) {
          selected.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest',
          });
        }

        return;
      }

      if (ENTER_KEYS.includes(e.key)) {
        handleInitModal();
        if (selectRef?.current) {
          selectRef?.current?.blur();
        }
      }
    },
    [
      handleInitModal,
      handleSetSelectedProductItem,
      selectedProductIndex,
      storeProductList,
    ],
  );

  useEffect(() => {
    if (
      productListKeywords?.length >= 3 &&
      storeProductList?.length &&
      !productListLoading
    ) {
      document.addEventListener('keydown', onTableKeyboardChange);

      return () => {
        document.removeEventListener('keydown', onTableKeyboardChange);
      };
    }
  }, [
    onTableKeyboardChange,
    productListKeywords?.length,
    productListLoading,
    storeProductList?.length,
  ]);

  const debounceHandleSearchProducts = useMemo(
    () =>
      debounce(async (value: any) => {
        const productList: any = await handleSearchProducts(value);
        handleChangeInputStatus(SearchProductStatus.END_REQUEST);

        if (
          productList &&
          productList?.products?.size === 1 &&
          inputStatusRef.current?.isEnterPress
        ) {
          const input = document.body.querySelector('#doc');

          const clickEvent = new KeyboardEvent('keydown', {
            bubbles: true,
            cancelable: true,
            key: 'Enter',
          });

          if (input instanceof HTMLElement) {
            setTimeout(() => {
              input.dispatchEvent(clickEvent);
            }, 250);
          }
        }

        handleChangeInputStatus(SearchProductStatus.PRESS_ENTER, false);
        handleChangeInputStatus(SearchProductStatus.END_REQUEST);
      }, 1000),
    [handleChangeInputStatus, handleSearchProducts, inputStatusRef],
  );

  const resetSelectedProductState = useCallback(() => {
    setStoreProductList([]);
    handleSetProductList(List());
    setSelectedProductIndex((index) => (index === 0 ? index : 0));
    inputStatusRef.current.isEnterPress = false;
  }, [handleSetProductList]);

  useEffect(() => {
    if (productListLoadingMore) {
      isDisabledResetSelectedItem.current = true;
    }
  }, [productListLoadingMore]);

  useEffect(() => {
    if (productListKeywords?.length < 3) {
      resetSelectedProductState();
    }
  }, [productListKeywords?.length, resetSelectedProductState]);

  return (
    <>
      <StyledProductListContainer ref={tableRef}>
        <StyledProductListCol span={24}>
          <StyledSelectContainer $isCenterCreateProduct={isCenterCreateProduct}>
            <StyledFormItem
              required
              label={isFunction(onSuccess) ? undefined : t('List of products')}
              name="doc"
              tooltip={
                isFunction(onSuccess) ? undefined : t('List of products')
              }
              extra={
                disabled ? (
                  <StyledDescription
                    $color={theme.colors.error}
                    style={{whiteSpace: 'unset'}}>
                    {t('To search for goods, you need to select a warehouse')}
                  </StyledDescription>
                ) : null
              }>
              <Select
                popupClassName="posting-select-popup"
                className="posting-select-input"
                ref={selectRef as any}
                loading={productListLoadingMore}
                disabled={loadingSubmit || editMode || disabled || loading}
                showSearch
                onKeyDown={(e) => {
                  if (
                    inputStatusRef.current?.status !==
                      SearchProductStatus.END_REQUEST &&
                    ENTER_KEYS.includes(e.key)
                  ) {
                    handleChangeInputStatus(
                      SearchProductStatus.PRESS_ENTER,
                      true,
                    );
                  }
                }}
                onBlur={async () => {
                  setTimeout(() => {
                    if (inputStatusRef?.current) {
                      inputStatusRef.current.isEnterPress = false;
                    }
                  }, 200);
                }}
                onSearch={async (keywords) => {
                  handleChangeInputStatus(SearchProductStatus.START_INPUT);

                  if (!isCreateProduct) {
                    if (keywords?.length < 3) {
                      resetSelectedProductState();
                    }

                    await debounceHandleSearchProducts({
                      keywords,
                      ...(withoutStoreUuid
                        ? []
                        : {store_uuid: formData?.doc_store_uuid}),
                    });
                  }
                }}
                placeholder={t('Enter product details to search for it')}
                dropdownRender={() => (
                  <div
                    onMouseDown={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}>
                    {productListLoading ? (
                      <Empty>
                        <LoadingOutlined />
                      </Empty>
                    ) : (productListLoading ||
                        !productListKeywords ||
                        productListKeywords?.length < 3) &&
                      !isShowRelatedDocumentItems ? (
                      <Empty
                        description={t(
                          'To select a product, enter the search data, at least 3 characters',
                        )}
                      />
                    ) : (productListKeywords && !storeProductList?.length) ||
                      (isShowRelatedDocumentItems &&
                        !storeProductList?.length) ? (
                      <Empty
                        description={t(
                          'It seems that more than one product was not found for your request',
                        )}>
                        {withoutCreateProduct &&
                        isFunction(handleCreateProduct) ? (
                          <ProductCreateButton
                            type="primary"
                            disabled={loadingSubmit}
                            product={ProductMapper.toProductFormDTO(
                              {
                                product_title: productListKeywords,
                              } as ProductModel,
                              {
                                showSwitches: true,
                                showPriceTag: false,
                                addAnotherProduct: false,
                                printPriceTag: false,
                              },
                            )}
                            loading={loading}
                            onClick={() => {
                              setIsCreateProduct(true);

                              const popup = document.body.querySelector(
                                '.posting-select-popup',
                              );

                              if (popup instanceof HTMLElement) {
                                popup.style.zIndex = '-1';
                              }
                            }}
                            onSuccess={handleCreateProduct}
                            title={t('Create product')}
                            onCancel={() => {
                              const popup = document.body.querySelector(
                                '.posting-select-popup',
                              );
                              if (popup instanceof HTMLElement) {
                                popup.style.zIndex = '9999999';
                              }

                              setIsCreateProduct(false);
                            }}
                          />
                        ) : null}
                      </Empty>
                    ) : (
                      <>
                        <StyledTable
                          className="posting-list-form"
                          pagination={false}
                          pageSize={Infinity}
                          total={storeProductList?.length}
                          dataSource={storeProductList}
                          columns={columns}
                          loading={loading}
                          onRow={(record: ProductModel, rowIndex: number) => ({
                            onClick: () => {
                              onProductSelect(record);

                              if (selectRef?.current) {
                                selectRef?.current?.blur();
                              }
                            },
                            className: `posting-list-td posting-list-td-${rowIndex} ${
                              record?.uuid ===
                                selectedProductItem?.product_uuid &&
                              selectedProductIndex === rowIndex
                                ? 'posting-list-td--selected'
                                : ''
                            } ${
                              record?.product_status !== ProductStatus.ENABLE
                                ? 'product-list--disabled'
                                : ''
                            }`,
                          })}
                        />
                        <LoadingMore
                          loading={isLoadingMore}
                          observerCallback={handleLoadingMoreProductList}
                        />
                      </>
                    )}
                  </div>
                )}
              />
            </StyledFormItem>

            {withoutCreateProduct && !isFunction(handleCreateProduct) ? null : (
              <StyledProductCreateButton
                type="text"
                disabled={loadingSubmit || editMode || disabled}
                product={ProductMapper.toProductFormDTO(
                  {
                    product_title: productListKeywords,
                  } as ProductModel,
                  {
                    showSwitches: true,
                    showPriceTag: false,
                    addAnotherProduct: false,
                    printPriceTag: false,
                  },
                )}
                loading={loading}
                onSuccess={handleCreateProduct}
              />
            )}
          </StyledSelectContainer>

          <StoreDocItemsField
            loading={loading}
            handleEditDocItem={handleEditDocItem}
            handleDeleteItem={onDelete}
            handleEditItem={async (value) => {
              setSelectedProductItemUuid(value?.product_uuid);
              const item = await handledSuccess(value);
              setSelectedProductItemUuid('');
              return item;
            }}
            editMode={editMode}
            selectedProductItemUuid={selectedProductItemUuid}
            storeDocumentItemListRefresh={storeDocumentItemListRefresh}
            storeDocumentItemListLimit={storeDocumentItemListLimit}
            storeDocumentItemListTotal={storeDocumentItemListTotal}
            storeDocumentItemListPage={storeDocumentItemListPage}
            withEditablePrice={withEditablePrice}
            document={storeDocument}
            isOrderFields={isOrderFields}
          />
        </StyledProductListCol>
      </StyledProductListContainer>

      {visible ? (
        <Suspense fallback={<SuspenseEmpty />}>
          <StoreProductModalLazy
            product={selectedProductItem as any}
            visible={visible}
            onSuccess={handleSuccess}
            onCancel={handleCancel}
            doc_multi_currencies={formData?.doc_multi_currencies}
          />
        </Suspense>
      ) : null}

      {amountVisible ? (
        <Suspense fallback={<SuspenseEmpty />}>
          <StoreProductAmountModalLazy
            withCell={withCell}
            product={selectedProductItem as any}
            visible={amountVisible}
            onSuccess={amountHandleSuccess}
            onCancel={amountHandleCancel}
          />
        </Suspense>
      ) : null}

      {productVisible ? (
        <Suspense fallback={<SuspenseEmpty />}>
          <ProductSideWindowLazy
            autoFocusTitle
            loading={loading as boolean}
            onSuccess={async (value: ProductFormDTO) => {
              await handleCreateProduct(value);
              productHandleCancel();
            }}
            onCancel={productHandleCancel}
            visible={productVisible}
            product={ProductMapper.toProductFormDTO(
              {
                category,
                categoryModel: category,
              } as ProductModel,
              {
                addAnotherProduct: true,
                disabledCategory: false,
                showSwitches: true,
                showPriceTag: false,
                printPriceTag: false,
                editMode: true,
              },
            )}
          />
        </Suspense>
      ) : null}
    </>
  );
}
