import {Record} from 'immutable';

export interface ListProps {
  total: number;
  page: number;
  perPage: number;
}

/**
 * @name ListModel
 * @desc Represents an abstract list response from the backend.
 * @constant
 */
export const ListModel = new (Record({
  total: 0,
  page: 0,
  perPage: 0,
}))();
