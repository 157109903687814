import {LayoutItem} from './types';

/**
 * Layout: English
 */
export default <LayoutItem>{
  layout: {
    default: [
      '{escape} {f1} {f2} {f3} {f4} {f5} {f6} {f7} {f8} {f9} {f10} {f11} {f12}',
      '` 1 2 3 4 5 6 7 8 9 0 - = {backspace}',
      '{tab} q w e r t y u i o p [ ] \\',
      "{capslock} a s d f g h j k l ; ' {enter}",
      '{shiftleft} z x c v b n m , . / {shiftright}',
      '{controlleft} {altleft} {metaleft} {space} {metaright} {altright}',
    ],
    shift: [
      '{escape} {f1} {f2} {f3} {f4} {f5} {f6} {f7} {f8} {f9} {f10} {f11} {f12}',
      '~ ! @ # $ % ^ & * ( ) _ + {backspace}',
      '{tab} Q W E R T Y U I O P { } |',
      '{capslock} A S D F G H J K L : " {enter}',
      '{shiftleft} Z X C V B N M < > ? {shiftright}',
      '{controlleft} {altleft} {metaleft} {space} {metaright} {altright}',
    ],
  },
  display: {
    '{escape}': 'esc ⎋',
    '{tab}': 'tab ⇥',
    '{backspace}': 'backspace ⌫',
    '{enter}': 'enter ↵',
    '{capslock}': 'caps lock ⇪',
    '{shiftleft}': 'shift ⇧',
    '{shiftright}': 'shift ⇧',
    '{controlleft}': 'ctrl ⌃',
    '{controlright}': 'ctrl ⌃',
    '{altleft}': 'alt ⌥',
    '{altright}': 'alt ⌥',
    '{metaleft}': '&#127760;',
    '{metaright}': 'cmd ⌘',
  },
  name: 'english',
};

export const mappedKeyboardKey = {
  '{escape}': 'Escape',
  '{tab}': 'Tab',
  '{backspace}': 'Backspace',
  '{enter}': 'Enter',
  '{capslock}': 'CapsLock',
  '{shiftleft}': 'Shift',
  '{shiftright}': 'Shift',
  '{controlleft}': 'Control',
  '{controlright}': 'Control',
  '{altleft}': 'Alt',
  '{altright}': 'Alt',
  '{metaleft}': 'Meta',
  '{metaright}': 'Meta',
  '{prtscr}': 'Prtscr',
  '{scrolllock}': 'ScrollLock',
  '{pause}': 'Pause',
  '{insert}': 'Insert',
  '{home}': 'Home',
  '{pageup}': 'PageUp',
  '{delete}': 'Delete',
  '{end}': 'End',
  '{pagedown}': 'PageDown',
  '{arrowup}': 'ArrowUp',
  '{arrowleft}': 'ArrowLeft',
  '{arrowdown}': 'ArrowDown',
  '{arrowright}': 'ArrowRight',
  '{numlock}': 'NumLock',
  '{numpaddivide}': '/',
  '{numpadmultiply}': '*',
  '{numpaddecimal}': ',',
  '{numpadsubtract}': '-',
  '{numpadadd}': '+',
  '{numpadenter}': 'NumpadEnter',
  '{numpad7}': '7',
  '{numpad8}': '8',
  '{numpad9}': '9',
  '{numpad6}': '6',
  '{numpad5}': '5',
  '{numpad4}': '4',
  '{numpad3}': '3',
  '{numpad2}': '2',
  '{numpad1}': '1',
  '{numpad0}': '0',
  '{f1}': 'F1',
  '{f2}': 'F2',
  '{f3}': 'F3',
  '{f4}': 'F4',
  '{f5}': 'F5',
  '{f6}': 'F6',
  '{f7}': 'F7',
  '{f8}': 'F8',
  '{f9}': 'F9',
  '{f10}': 'F10',
  '{f11}': 'F11',
  '{f12}': 'F12',
};

export const keyboardControlPad = {
  layout: {
    default: [
      '{prtscr} {scrolllock} {pause}',
      '{insert} {home} {pageup}',
      '{delete} {end} {pagedown}',
    ],
  },
};

export const keyboardArrows = {
  layout: {
    default: ['{arrowup}', '{arrowleft} {arrowdown} {arrowright}'],
  },
};

export const keyboardNumPad = {
  layout: {
    default: [
      '{numlock} {numpaddivide} {numpadmultiply}',
      '{numpad7} {numpad8} {numpad9}',
      '{numpad4} {numpad5} {numpad6}',
      '{numpad1} {numpad2} {numpad3}',
      '{numpad0} {numpaddecimal}',
    ],
  },
};

export const keyboardNumPadEnd = {
  layout: {
    default: ['{numpadsubtract}', '{numpadadd}', '{numpadenter}'],
  },
};
