import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {IActionButtonProps} from '../../../components/lib/General';
import {MaterialAndExpenseFormDTO} from '../../../struture';
import {useModal} from '../../../components/lib/libV2/hooks';
import {EditButton, SuspenseEmpty} from '../../../components/lib/DataDisplay';

const MaterialAndExpenseSideWindowLazy = React.lazy(
  () => import('../Show/MaterialAndExpenseSideWindow'),
);

export interface IMaterialAndExpenseUpdateButtonProps
  extends Omit<IActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: MaterialAndExpenseFormDTO) => Promise<void>;
  onCancel?: () => void;
  materialAndExpense: MaterialAndExpenseFormDTO;
  materialAndExpenseUuid: string;
}

export default function MaterialAndExpenseUpdateButton({
  children,
  onSuccess,
  onCancel,
  loading,
  materialAndExpense,
  materialAndExpenseUuid,
  ...rest
}: IMaterialAndExpenseUpdateButtonProps): JSX.Element {
  const {t} = useTranslation();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <EditButton
        tooltipTitle={t('Edit material and expense')}
        onClick={handleOnInit}
        disabled={false}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <MaterialAndExpenseSideWindowLazy
          editMode
          materialAndExpense={materialAndExpense}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
          materialAndExpenseUuid={materialAndExpenseUuid}
        />
      </Suspense>
    </>
  );
}
