import * as React from 'react';
import {isFunction} from '../services/helpers';

export interface IUseModalProps<T> {
  onSuccess?: (value: T) => any;
  onCancel?: () => void;
}

export interface IUseModalReturnType<T> {
  handleSuccess: (value: T) => Promise<void> | Promise<() => void>;
  handleCancel: () => void;
  handleOnInit: () => void;
  visible: boolean;
}

export default function useModal<T>({
  onCancel,
  onSuccess,
}: IUseModalProps<T> = {}): IUseModalReturnType<T> {
  const [visible, setVisible] = React.useState(false);

  const handleSuccess = React.useCallback(
    async (value: T): Promise<void> => {
      let afterSuccess: undefined;
      if (isFunction(onSuccess)) {
        afterSuccess = await onSuccess(value);
      }
      setVisible(false);
      if (isFunction(afterSuccess)) {
        return afterSuccess;
      }
    },
    [onSuccess],
  );

  const handleCancel = React.useCallback(
    (event?: any) => {
      if (event) {
        event?.stopPropagation();
      }

      if (isFunction(onCancel)) {
        onCancel();
      }
      setVisible(false);
    },
    [onCancel],
  );

  const handleOnInit = React.useCallback(async (event?: any): Promise<void> => {
    if (event) {
      event?.stopPropagation();
    }

    setVisible(true);
  }, []);

  return {
    handleSuccess,
    handleCancel,
    handleOnInit,
    visible,
  };
}
