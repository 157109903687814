import * as React from 'react';
import {List} from 'immutable';
import {
  useEmployeeServiceList,
  IUseEmployeeServiceListProps,
  IUseEmployeeServiceListReturnType,
} from './useEmployeeServiceList';
import {
  EmployeeMapper,
  EmployeeModel,
  RewardType,
  ServiceMapper,
  ServiceModel,
  ServiceParametersFormDTO,
} from '../struture';
import {
  deleteEmployeeServices,
  editEmployeeServicesPrice,
  addEmployeeServices,
} from '../services/api/employee';
import {
  setEmployeeServiceList as storeSetEmployeeServiceList,
  loadMoreEmployeeServiceList as storeLoadMoreEmployeeServiceList,
  deleteEmployeeAttachedServiceFromList as storeDeleteEmployeeAttachedServiceFromList,
  updateEmployeeServiceFromList as storeUpdateEmployeeServiceFromList,
  updateServiceEmployeeFromList as storeUpdateServiceEmployeeFromList,
  addAttachedServiceToEmployeeList as storeAddAttachedServiceToEmployeeList,
  updateAttachedServiceFromEmployeeList as storeUpdateAttachedServiceFromEmployeeList,
  updateServiceFromEmployeeList as storeUpdateServiceFromEmployeeList,
} from '../store/actions';
import {useSelector, useDispatch} from 'react-redux';
import {RootState} from '../store/reducers';
import {ApiError, REDUX_STATUS} from '../services/types';
import {head} from '../services/helpers';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../contex';
import {StatusError} from '../components/lib/Errors';

export interface IUseStateEmployeeServiceListProps
  extends IUseEmployeeServiceListProps {
  employee: EmployeeModel | null;
}

export interface IUseStateEmployeeServiceListReturnType
  extends Omit<IUseEmployeeServiceListReturnType, 'entityList'> {
  employeeServiceList: List<ServiceModel> | null;
  handleSearchEmployeeServices: (keywords: string) => Promise<void>;
  handleLoadMoreEmployeeServices: () => Promise<void>;
  handleRefreshEmployeeServices: (
    value: Partial<IUseEmployeeServiceListProps> & {page: number},
  ) => Promise<void>;
  handleDeleteServicesFromListEmployee: (
    ids: string[],
    employee_uuid?: string,
  ) => Promise<ApiError | void>;
  handleAddServiceToListEmployee: (
    service: ServiceModel,
    employeeUuid?: string,
  ) => Promise<ApiError | void>;
  handleUpdateServiceFromListEmployee: (
    service: ServiceParametersFormDTO,
    employee_uuid?: string,
  ) => Promise<ApiError | void>;
  status: REDUX_STATUS;
  loadingMore: boolean;
  page: number;
}

export function useStoredEmployeeServiceList(
  {
    employeeUuid,
    loadOnInit = true,
    employee,
    ...rest
  }: IUseStateEmployeeServiceListProps = {} as IUseStateEmployeeServiceListProps,
): IUseStateEmployeeServiceListReturnType {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const [loadingMore, setLoadingMore] = useState(false);
  const [isStarLoading, setIsStartLoading] = React.useState<boolean>(false);
  const [employeeServiceList, setEmployeeServiceList] =
    useState<List<ServiceModel> | null>(null);
  const [storeKeywords, setStoreKeywords] = useState('');
  const [storePage, setStorePage] = useState(1);

  const {
    status: storedEmployeeServiceListStatus,
    cachedEmployeeServiceList,
    total: storeTotal,
    keywords,
    ...storedEmployeeServiceListParams
  } = useSelector(({employeeServiceList}: RootState) => employeeServiceList);

  const dispatch = useDispatch<any>();

  const {entityList, offset, limit, refresh, ...employeeServicesParams} =
    useEmployeeServiceList({
      employeeUuid,
      loadOnInit: false,
      ...rest,
    });

  React.useEffect(() => {
    if (cachedEmployeeServiceList?.size > 0) {
      const cachedServiceList = cachedEmployeeServiceList.get(employeeUuid);

      if (cachedServiceList) {
        setIsStartLoading(false);
        dispatch(storeSetEmployeeServiceList(cachedServiceList, employeeUuid));
        setEmployeeServiceList(cachedServiceList.services);
        setStoreKeywords(cachedServiceList?.keywords || '');
        setStorePage(cachedServiceList?.page || 1);
      } else {
        setIsStartLoading(true);
      }
    }
    if (cachedEmployeeServiceList?.size === 0) {
      setIsStartLoading(true);
    }
  }, [cachedEmployeeServiceList, dispatch, employeeUuid, keywords]);

  useEffect(() => {
    if (!List.isList(entityList) && isStarLoading && loadOnInit) {
      (async () => {
        const entityList = await refresh({employeeUuid});

        if (entityList) {
          dispatch(storeSetEmployeeServiceList(entityList, employeeUuid, ''));
        }
      })();
    }
  }, [dispatch, employeeUuid, entityList, isStarLoading, loadOnInit, refresh]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(storeSetEmployeeServiceList(null, employeeUuid, ''));
  //   };
  // }, [dispatch, employeeUuid]);

  const handleSearchEmployeeServices = React.useCallback(
    async (keywords: string) => {
      setLoadingMore(true);

      const employeeServiceListModel = await refresh({
        offset: 0,
        limit: 10,
        keywords,
        showLoading: false,
      });

      if (
        employeeServiceListModel &&
        List.isList(employeeServiceListModel?.services)
      ) {
        dispatch(
          storeSetEmployeeServiceList(
            employeeServiceListModel,
            employeeUuid,
            keywords,
          ),
        );
        setEmployeeServiceList(employeeServiceListModel.services);
      }

      setLoadingMore(false);
    },
    [dispatch, employeeUuid, refresh],
  );

  const handleLoadMoreEmployeeServices = useCallback(async () => {
    if (
      List.isList(employeeServiceList) &&
      employeeServiceList?.size < storeTotal &&
      !loadingMore
    ) {
      setLoadingMore(true);
      const employeeServiceListModel = await refresh({
        offset: employeeServiceList?.size,
        limit: 10,
        showLoading: false,
      });

      if (
        employeeServiceListModel &&
        List.isList(employeeServiceListModel?.services)
      ) {
        dispatch(
          storeLoadMoreEmployeeServiceList(
            employeeServiceListModel,
            employeeUuid,
          ),
        );
      }

      setLoadingMore(false);
    }
  }, [
    dispatch,
    loadingMore,
    refresh,
    employeeServiceList,
    employeeUuid,
    storeTotal,
  ]);

  const handleRefreshEmployeeServices = useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = false,
      page,
    }: Partial<IUseEmployeeServiceListProps> & {page: number}) => {
      const employeeServiceListModel = await refresh({
        offset,
        limit,
        showLoading,
      });

      if (
        employeeServiceListModel &&
        List.isList(employeeServiceListModel?.services)
      ) {
        dispatch(
          storeSetEmployeeServiceList(
            employeeServiceListModel,
            employeeUuid,
            '',
            employeeServiceListModel.total,
            page,
          ),
        );
      }
    },
    [dispatch, refresh, employeeUuid],
  );

  const handleAddServiceToListEmployee = useCallback(
    async (value: ServiceModel, employee_uuid = employeeUuid) => {
      try {
        const employeeServiceFormDTO =
          EmployeeMapper.toEmployeeServiceFormDTO(value);

        const serviceDTO = await addEmployeeServices({
          employeeUuid: employee_uuid,
          serviceUuid: value?.uuid as string,
          service: employeeServiceFormDTO,
        });

        const serviceModel = ServiceMapper.toServiceModel(serviceDTO);

        if (employee) {
          const updatedEmployee = employee.update(
            'employee_service',
            () =>
              ({
                ...(serviceModel?.employee_service || {}),
                updated_at: new Date().toString(),
              } as any),
          );

          dispatch(
            storeUpdateServiceEmployeeFromList(
              updatedEmployee,
              serviceDTO?.uuid,
            ),
          );
        }

        dispatch(
          storeUpdateEmployeeServiceFromList(serviceModel, employee_uuid),
        );

        // dispatch(storeUpdateServiceFromEmployeeList(serviceModel));

        dispatch(
          storeAddAttachedServiceToEmployeeList(serviceModel, employee_uuid),
        );

        alert(
          'success',
          t('Add service'),
          t('Service add to employee success'),
        );
      } catch (error: any) {
        alert(
          'error',
          t('Add service'),
          `${t('An error occurred during add service to Employee')}: ${
            error?.message
          }`,
        );

        return error;
      }
    },
    [alert, dispatch, employee, employeeUuid, t],
  );

  const handleUpdateServiceFromListEmployee = useCallback(
    async (value: ServiceParametersFormDTO, employee_uuid = employeeUuid) => {
      try {
        const serviceDTO = await editEmployeeServicesPrice({
          serviceUuid: value?.uuid as string,
          employeeUuid: employee_uuid,
          service: value,
        });

        const serviceModel = ServiceMapper.toServiceModel(serviceDTO);

        if (employee) {
          const updatedEmployee = employee.update(
            'employee_service',
            () => serviceModel.employee_service,
          );

          dispatch(
            storeUpdateServiceEmployeeFromList(
              updatedEmployee,
              serviceDTO?.uuid,
            ),
          );
        }

        dispatch(
          storeUpdateEmployeeServiceFromList(serviceModel, employee_uuid),
        );

        dispatch(
          storeUpdateAttachedServiceFromEmployeeList(
            serviceModel,
            employee_uuid,
          ),
        );

        alert(
          'success',
          t('Update service price'),
          t('Service price update success'),
        );
      } catch (error: any) {
        alert(
          'success',
          t('Update service price'),
          `${t('An error occurred during update service price')}: ${
            error?.message
          }`,
        );

        throw new StatusError(error?.message, error?.status);
      }
    },
    [alert, dispatch, employee, employeeUuid, t],
  );

  const handleDeleteServicesFromListEmployee = React.useCallback(
    async (ids: string[], employee_uuid = employeeUuid) => {
      try {
        const serviceDTO = await deleteEmployeeServices({
          employeeUuid: employee_uuid,
          serviceUuid: head(ids),
        });

        const serviceModel = ServiceMapper.toServiceModel({
          ...serviceDTO,
          employee_service: {} as any,
        });

        if (employee) {
          const updatedEmployee = employee.update(
            'employee_service',
            () =>
              ({
                price: '0.00',
                duration: 0,
                reward_value: 0,
                updated_at: '',
                reward_type: RewardType.Percentage,
              } as any),
          );

          dispatch(
            storeUpdateServiceEmployeeFromList(updatedEmployee, head(ids)),
          );
        }

        dispatch(
          storeUpdateEmployeeServiceFromList(serviceModel, employee_uuid),
        );

        dispatch(
          storeDeleteEmployeeAttachedServiceFromList(
            serviceModel?.uuid,
            employee_uuid,
          ),
        );

        alert(
          'success',
          t('Delete service from employee'),
          t('Delete service from employee success'),
        );
      } catch (error: any) {
        alert(
          'error',
          t('Delete service from employee'),
          `${t('An error occurred during delete service from employee')}: ${
            error?.message
          }`,
        );
        return error;
      }
    },
    [alert, dispatch, employee, employeeUuid, t],
  );

  return {
    ...employeeServicesParams,
    ...storedEmployeeServiceListParams,
    employeeServiceList,
    loadingMore,
    total: storeTotal,
    offset,
    limit,
    refresh,
    loading: !employeeServiceList,
    status: storedEmployeeServiceListStatus,
    keywords: storeKeywords,
    page: storePage,

    handleSearchEmployeeServices,
    handleLoadMoreEmployeeServices,
    handleAddServiceToListEmployee,
    handleUpdateServiceFromListEmployee,
    handleDeleteServicesFromListEmployee,
    handleRefreshEmployeeServices,
  };
}
