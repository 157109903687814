import React, {useCallback, useState} from 'react';
import {Route, Routes, useLocation, useNavigate} from 'react-router';
import {ProductListPage} from './ProductListPage';
import {
  ProductType,
  Routes as RoutesType,
  RoutesAcl,
} from '../../../services/types';
import {ProductPriceTagTypeView, ProductTypeView} from '../Show';
import {StyledDetailsView} from '../../../components/lib/Styled/DetailsView';
import styled, {useTheme} from 'styled-components';
import {IProductsListStatsProps} from '../../../struture';
import {ListLayoutManager} from '../../../contex';
import {ProductCategoryListPage} from './ProductCategoryListPage';
import {PriceTagListPage} from '../PriceTags';
import {isThereContent} from '@sportix/sportix-common-modules';

const StyledContainer = styled.div`
  margin: -20px 20px 20px 20px;
`;

const DetailsView = styled(StyledDetailsView)`
  &.price-tag-field {
    flex: unset !important;
    width: 100px !important;
  }
`;

export function ProductRoutePage() {
  const theme: any = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const [stats, setStats] = useState<IProductsListStatsProps>(
    {} as IProductsListStatsProps,
  );
  const [loading, setLoading] = useState(false);

  const isProductCategoryPage = location.pathname.includes(
    RoutesType.categories,
  );
  const isPriceTagsPage = location.pathname.includes(RoutesType.priceTags);

  const onProductRouteChange = useCallback(
    (path: RoutesType | string) => {
      navigate(`/${RoutesType.app}/${RoutesType.goods}/${path}`);
    },
    [navigate],
  );

  const onUpdateStats = useCallback(
    (
      stats: IProductsListStatsProps = {} as IProductsListStatsProps,
      isPriceTag?: boolean,
    ) => {
      setStats((prevState) => {
        if (isPriceTag) {
          if (isThereContent(prevState)) {
            return {
              ...prevState,
              product_price_tags_count: stats?.product_price_tags_count,
            };
          }

          return {...stats};
        }

        return {...prevState, ...stats};
      });
    },
    [],
  );

  const onUpdateLoading = useCallback((loading: boolean) => {
    setLoading(loading);
  }, []);

  return (
    <ListLayoutManager aclItem={RoutesAcl[RoutesType.products]}>
      <StyledContainer>
        <DetailsView
          titles={[]}
          selected={[
            !isProductCategoryPage && !isPriceTagsPage,
            isProductCategoryPage,
            isPriceTagsPage,
          ]}>
          <ProductTypeView
            color={theme.tabs.link}
            type={ProductType.Product}
            title="My goods"
            stats={stats}
            onClick={() => onProductRouteChange(RoutesType.products)}
            disabled={loading}
          />
          <ProductTypeView
            color={theme.tabs.success}
            type={ProductType.Category}
            title="My categories"
            stats={stats}
            onClick={() => onProductRouteChange(`${RoutesType.categories}`)}
            disabled={loading}
          />
          <ProductPriceTagTypeView
            className="price-tag-field"
            color={theme.tabs.warning}
            title="Print price tags"
            stats={stats}
            onClick={() => onProductRouteChange(`${RoutesType.priceTags}`)}
            disabled={loading}
          />
        </DetailsView>
      </StyledContainer>

      <Routes>
        <Route
          path={`${RoutesType.products}`}
          element={
            <ProductListPage
              onUpdateStats={onUpdateStats}
              onUpdateLoading={onUpdateLoading}
            />
          }
        />
        <Route
          path={`${RoutesType.categories}`}
          element={
            <ProductCategoryListPage
              onUpdateStats={onUpdateStats}
              onUpdateLoading={onUpdateLoading}
            />
          }
        />
        <Route
          path={`${RoutesType.priceTags}`}
          element={
            <PriceTagListPage
              onUpdateStats={onUpdateStats}
              onUpdateLoading={onUpdateLoading}
            />
          }
        />
      </Routes>
    </ListLayoutManager>
  );
}
