import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {List} from 'immutable';
import {OrderStatusListTabPanel} from '../Show';
import {OverviewLayout} from '../../../components/lib/Layout';
import {StyledTitle} from '../../../components/lib/Styled';
import {useScheduleStatusList} from '../../../hooks';
import {reduce, groupByPropV2, compose} from '../../../services/helpers';
import {useLocation, useNavigate, useParams} from 'react-router';
import {HeaderLeft} from '../../../components/lib/DataDisplay';
import {Routes} from '../../../services/types';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import styled from 'styled-components';

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({theme}) => theme.background.layout};
`;
export function OrderStatusListPage() {
  const {t} = useTranslation();
  const {orderId} = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const {entityList, loading} = useScheduleStatusList({
    scheduleUuid: orderId || '',
  });

  const groupedScheduleStatuses = useMemo(
    () =>
      List.isList(entityList)
        ? compose<any>(
            (value) => Object.fromEntries(Object.entries(value).reverse()),
            reduce(groupByPropV2('groupBy'), {}),
          )(entityList)
        : undefined,
    [entityList],
  );

  const routes = useBreadcrumb([
    {
      path: `/${Routes.orders}`,
      breadcrumbName: t('Orders'),
    },
    {
      path: `/${Routes.orders}/${orderId}/${Routes.schedules}/calendar/entry/${orderId}`,
      breadcrumbName: `${
        location?.state?.scheduleNumber
          ? `#${location?.state?.scheduleNumber}`
          : ''
      }`,
    },
    {
      path: `/${Routes.orders}/${orderId}/${Routes.statuses}`,
      breadcrumbName: 'Order status',
    },
  ]);

  const navigateGoBack = useCallback(() => {
    navigate(
      location?.state?.from ||
        `/${Routes.app}/${Routes.orders}/${orderId}/${Routes.schedules}/calendar/entry/${orderId}`,
      {
        state: location?.state?.prevState
          ? location?.state?.prevState
          : location?.state,
      },
    );
  }, [location?.state, navigate, orderId]);

  return (
    <StyledContainer>
      <OrderStatusListTabPanel groupedStatuses={groupedScheduleStatuses}>
        {({tabs}) => (
          <OverviewLayout
            headerLeft={
              <HeaderLeft absolute={false} onClick={navigateGoBack} />
            }
            data={null}
            loading={loading}
            routes={routes}
            tabs={tabs}
            headerTitle={t('Order status')}
            header={
              <StyledTitle fontSize={27} bold>
                {t('History')}
              </StyledTitle>
            }
          />
        )}
      </OrderStatusListTabPanel>
    </StyledContainer>
  );
}
