import {Col, Form, Input, Row} from 'antd';
import {useTranslation} from 'react-i18next';
import {useDefaultForm} from '../../../../contex';
import {ClientCreateButton} from '../../../Clients';
import {phoneScreen} from '../../../../services/const';
import styled, {css, useTheme} from 'styled-components';
import {CashBoxTitleView} from '../../../Payments/Show/CashBoxTitleView';
import {STORE_PAYMENT_DISCOUNT_TYPE} from '../../../../services/api/storeDocument';
import {FROM_PAYMENT_METHOD_WITH_IN_DEBT} from '../../../Payments/Forms/PaymentScheduleFields';
import {convertedPaymentMethodToCashBox} from '../../../../services/api/companyPayments';

import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Collapse,
  FormSwitch,
  SearchSelect,
} from '../../../../components/lib/DataDisplay';

import {
  correctPrice,
  isListToArray,
  listToArray,
} from '../../../../services/helpers';

import {
  CashBoxModel,
  ClientDiscountType,
  ClientMapper,
  ClientModel,
  ClientOrgType,
  PAYMENT_METHOD,
  StorePaymentDocumentDiscountSourceType,
  StorePaymentDocumentDiscountType,
} from '../../../../struture';

import {
  IStoredSaleForm,
  IUseStateCashBoxListReturnType,
  IUseStateCompanyClientListReturnType,
  useSearchInputFocus,
  useSize,
  useStoredCompanies,
} from '../../../../hooks';

import {
  StyledTitle,
  StyledDescription,
} from '../../../../components/lib/Styled';

export interface IStoreSaleConductFormFieldsProps
  extends Pick<
      IUseStateCompanyClientListReturnType,
      | 'clients'
      | 'handleSearchClients'
      | 'handleCreateClient'
      | 'handleCreateClientLegal'
    >,
    Pick<
      IUseStateCashBoxListReturnType,
      'cashBoxList' | 'handleSearchCashBoxes'
    > {
  loading: boolean;
  clientsLoading: boolean;
  cashBoxListLoading: boolean;
  cashBoxRef: RefObject<any>;
  clientRef: RefObject<any>;
  inputRef: RefObject<any>;
  commentRef: RefObject<any>;
  minSum: string | number;
  isShowFiscalCashboxesWarning: boolean;
  disabledDiscount?: boolean;

  preStoredSalesForm?: IStoredSaleForm;
}

const StyledPriceInput = styled(Input)`
  & .ant-input-group-addon {
    font-size: 30px;
    font-weight: 300;
  }

  & .ant-input {
    font-size: 45px;
    font-weight: 500;
  }
`;

const StyledSearchSelect = styled(SearchSelect)`
  width: 60px;
`;

const StyledPriceDiscountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Title = styled(StyledTitle)`
  text-decoration: line-through;
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 15px;
`;

const StyledSwitch = styled(FormSwitch)<{$disabledDiscount: boolean}>`
  ${({$disabledDiscount}) => !$disabledDiscount && css``}
`;

const DISABLED_SUM_METHODS = [PAYMENT_METHOD.Account, PAYMENT_METHOD.Bank];

export function StoreSaleConductFormFields({
  loading,

  clients,
  clientsLoading,
  handleSearchClients,
  handleCreateClient: onCreateClient,
  handleCreateClientLegal,

  cashBoxList,
  cashBoxListLoading,
  handleSearchCashBoxes,

  clientRef,
  cashBoxRef,
  inputRef: sumRef,
  commentRef,

  minSum,
  isShowFiscalCashboxesWarning,
  disabledDiscount,
  preStoredSalesForm,
}: IStoreSaleConductFormFieldsProps): React.JSX.Element {
  const {t} = useTranslation();
  const {
    loadingSubmit,
    formData,
    handlerUpdateFormState,
    getFieldValue,
    setFieldsValue,
    validateFields,
  }: any = useDefaultForm();
  const theme: any = useTheme();
  const {defaultCompany} = useStoredCompanies();

  const isAccountPaymentMethod =
    formData.payment_method === PAYMENT_METHOD.Account;
  const isFiscalOperationDisabled = !cashBoxList?.find(
    ({uuid}) => uuid === formData.payment_cashbox_uuid,
  )?.box_fiscal_cashbox_id;

  const [resetCashBoxState] = useState(false);

  const [client, setClient] = useState<ClientModel | null>(
    formData?.payment_client_uuid instanceof ClientModel
      ? formData?.payment_client_uuid
      : null,
  );
  const [discountText, setDiscountText] = useState('');

  const onceClient = useRef(false);
  const onceMethod = useRef(false);

  const {inputRef} = useSearchInputFocus({loading: false});
  const contentRef = useRef(null);
  const {width: contentWidth} = useSize(contentRef);
  const isFullWidth = contentWidth <= phoneScreen ? 24 : 12;

  const discountRemaining =
    formData?.payment_doc_discount_type ===
    StorePaymentDocumentDiscountType.FIXED
      ? (Number(minSum) || 0) -
        ((Number(minSum) || 0) -
          (Number(formData?.payment_doc_discount_value) || 0))
      : formData?.payment_doc_discount_type ===
        StorePaymentDocumentDiscountType.PERCENTAGE
      ? (Number(minSum) || 0) *
        ((Number(formData?.payment_doc_discount_value) || 0) / 100)
      : 0;

  const remaining =
    Number(formData?.price || 0) - (Number(minSum) || 0) + discountRemaining;

  const handleUpdateClientDiscount = useCallback(
    (client: any) => {
      if (client) {
        setClient(client);
        if (
          client?.client_discount &&
          formData?.is_apply_payment_doc_discount
        ) {
          const discountRemaining =
            formData?.payment_doc_discount_type ===
            StorePaymentDocumentDiscountType.FIXED
              ? (Number(minSum) || 0) -
                ((Number(minSum) || 0) - (Number(client?.client_discount) || 0))
              : formData?.payment_doc_discount_type ===
                StorePaymentDocumentDiscountType.PERCENTAGE
              ? (Number(minSum) || 0) *
                ((Number(client?.client_discount) || 0) / 100)
              : 0;

          handlerUpdateFormState({
            payment_doc_discount_value: client?.client_discount,
            payment_doc_discount_type: client?.client_discount_type,
            payment_doc_discount_source_type:
              StorePaymentDocumentDiscountSourceType.CLIENT,
            price: correctPrice(
              (Number(minSum || 0) - discountRemaining).toFixed(2),
            ),
          });
        }
      }
    },
    [
      formData?.is_apply_payment_doc_discount,
      formData?.payment_doc_discount_type,
      handlerUpdateFormState,
      minSum,
    ],
  );

  const handleChangeClient = useCallback(
    (e: string): void => {
      onceClient.current = true;

      const client = clients?.find(({uuid}) => uuid === e);

      handleUpdateClientDiscount(client);

      if (
        formData?.payment_client_uuid !== e ||
        formData?.payment_client_uuid instanceof ClientModel
      ) {
        if (e?.includes('create')) {
          if (client) {
            let payment_client_uuid;
            if (client?.client_org_type === ClientOrgType.PERSON) {
              payment_client_uuid = ClientMapper.toClientFormDTO(client);
            } else {
              payment_client_uuid = ClientMapper.toClientLegalFormDTO(client);
            }

            handlerUpdateFormState({payment_client_uuid});
          }
          return;
        }

        handlerUpdateFormState({payment_client_uuid: e});
      }
    },
    [
      clients,
      formData?.payment_client_uuid,
      handleUpdateClientDiscount,
      handlerUpdateFormState,
    ],
  );

  const handleCreateClient = useCallback(
    async ({t, ...value}: any): Promise<void> => {
      let client;
      if (value?.client_org_type === ClientOrgType.PERSON) {
        client = await onCreateClient(value);
      } else {
        client = await handleCreateClientLegal(value as any);
      }

      handleUpdateClientDiscount(client);

      handlerUpdateFormState({
        payment_client_uuid: client?.uuid,
      });
    },
    [
      handleCreateClientLegal,
      handleUpdateClientDiscount,
      handlerUpdateFormState,
      onCreateClient,
    ],
  );

  const buildSalesFieldSetFromLocalStorage = useCallback(
    (paymentMethod: PAYMENT_METHOD) => {
      const salesForm =
        preStoredSalesForm && paymentMethod in preStoredSalesForm
          ? Reflect.get(preStoredSalesForm, paymentMethod)
          : null;

      const selectedCashBox = salesForm
        ? cashBoxList?.find(({uuid}) => uuid === salesForm.cashboxUuid)
        : null;

      return {
        payment_method: paymentMethod,
        payment_fiscal_number:
          selectedCashBox && selectedCashBox?.box_fiscal_cashbox_id
            ? selectedCashBox?.box_fiscal_cashbox_id
            : '',
        payment_cashbox_uuid: salesForm ? salesForm.cashboxUuid : '',
        payment_is_fiscal_create: salesForm
          ? !!salesForm.isOperationFiscal
          : false,
      };
    },
    [cashBoxList, preStoredSalesForm],
  );

  const handleChangePaymentMethod = useCallback(
    (payment_method: any) => {
      if (payment_method !== PAYMENT_METHOD.Account) {
        setClient(null);
      }

      if (typeof formData?.payment_method === 'string') {
        handlerUpdateFormState({
          payment_client_uuid: '',
          price: correctPrice(
            (Number(minSum || 0) - discountRemaining).toFixed(2),
          ),
          ...buildSalesFieldSetFromLocalStorage(payment_method),
        });

        // setResetCashBoxState(true);
        // setTimeout(() => {
        //   setResetCashBoxState(false);
        // }, 100);
      }
    },
    [
      discountRemaining,
      formData?.payment_method,
      buildSalesFieldSetFromLocalStorage,
      handlerUpdateFormState,
      minSum,
    ],
  );

  const handleChangeCashBox = useCallback(
    (payment_cashbox_uuid: string) => {
      const selectedCashBox = cashBoxList?.find(
        ({uuid}) => uuid === payment_cashbox_uuid,
      );

      // setDisabledFiscalCasBboxSwitch(
      //   !(selectedCashBox && selectedCashBox?.box_fiscal_cashbox_id),
      // );
      handlerUpdateFormState({
        payment_cashbox_uuid,
        payment_is_fiscal_create: false,
        payment_fiscal_number:
          selectedCashBox && selectedCashBox?.box_fiscal_cashbox_id
            ? selectedCashBox?.box_fiscal_cashbox_id
            : '',
      });
    },
    [cashBoxList, handlerUpdateFormState],
  );

  const handleChangeIsApplyPaymentDocDiscount = useCallback(
    (is_apply_payment_doc_discount: boolean) => {
      const minValue = Number(minSum || 0);

      if (is_apply_payment_doc_discount) {
        const discountRemaining =
          client?.client_discount_type === ClientDiscountType.Fixed
            ? minValue - (minValue - (Number(client?.client_discount) || 0))
            : client?.client_discount_type === ClientDiscountType.Percentage
            ? minValue * ((Number(client?.client_discount) || 0) / 100)
            : 0;

        handlerUpdateFormState({
          is_apply_payment_doc_discount,
          payment_doc_discount_type:
            client?.client_discount && client?.client_discount_type
              ? client?.client_discount_type
              : StorePaymentDocumentDiscountType.PERCENTAGE,
          payment_doc_discount_value: client?.client_discount
            ? client?.client_discount
            : '0.00',
          payment_doc_discount_source_type:
            client?.client_discount === formData?.payment_doc_discount_value
              ? StorePaymentDocumentDiscountSourceType.CLIENT
              : StorePaymentDocumentDiscountSourceType.CUSTOM,
          price:
            Number(client?.client_discount) > 0
              ? correctPrice((minValue - discountRemaining).toFixed(2))
              : correctPrice(minValue),
        });
      } else {
        handlerUpdateFormState({
          payment_doc_discount_value: undefined,
          payment_doc_discount_type: undefined,
          payment_doc_discount_source_type: undefined,
          is_apply_payment_doc_discount,
          price: correctPrice(minValue),
        });
      }
    },
    [
      client?.client_discount,
      client?.client_discount_type,
      formData?.payment_doc_discount_value,
      handlerUpdateFormState,
      minSum,
    ],
  );

  const handleChangeDiscount = useCallback(
    (e: any) => {
      const payment_doc_discount_value = e?.target?.value;

      if (client) {
        if (client?.client_discount !== payment_doc_discount_value) {
          handlerUpdateFormState({
            payment_doc_discount_source_type:
              StorePaymentDocumentDiscountSourceType.CUSTOM,
          });
        } else {
          handlerUpdateFormState({
            payment_doc_discount_source_type:
              StorePaymentDocumentDiscountSourceType.CLIENT,
          });
        }
      }

      const discountRemaining =
        formData?.payment_doc_discount_type ===
        StorePaymentDocumentDiscountType.FIXED
          ? (Number(minSum) || 0) -
            ((Number(minSum) || 0) - (Number(e?.target?.value) || 0))
          : formData?.payment_doc_discount_type ===
            StorePaymentDocumentDiscountType.PERCENTAGE
          ? (Number(minSum) || 0) * ((Number(e?.target?.value) || 0) / 100)
          : 0;

      handlerUpdateFormState({
        payment_doc_discount_value: e?.target?.value,
        price: correctPrice(
          (Number(minSum || 0) - discountRemaining).toFixed(2),
        ),
      });
    },
    [
      client,
      formData?.payment_doc_discount_type,
      handlerUpdateFormState,
      minSum,
    ],
  );

  useEffect(() => {
    if (preStoredSalesForm && !onceMethod?.current) {
      onceMethod.current = true;
      const paymentMethod =
        preStoredSalesForm.paymentMethod ?? PAYMENT_METHOD.Cash;

      handlerUpdateFormState(buildSalesFieldSetFromLocalStorage(paymentMethod));
    }
  }, [
    preStoredSalesForm,
    handlerUpdateFormState,
    buildSalesFieldSetFromLocalStorage,
  ]);

  useEffect(() => {
    if (disabledDiscount && formData?.is_apply_payment_doc_discount) {
      setDiscountText(
        `${formData?.payment_doc_discount_value}${
          formData?.payment_doc_discount_type ===
          StorePaymentDocumentDiscountType.PERCENTAGE
            ? '%'
            : defaultCompany?.currency_symbol || ''
        }`,
      );
      handlerUpdateFormState({
        is_apply_payment_doc_discount: false,
        payment_doc_discount_type: undefined,
        payment_doc_discount_value: undefined,
        payment_doc_discount_source_type: undefined,
      });
    }
  }, [
    defaultCompany?.currency_symbol,
    disabledDiscount,
    formData?.is_apply_payment_doc_discount,
    formData?.payment_doc_discount_type,
    formData?.payment_doc_discount_value,
    handlerUpdateFormState,
  ]);

  return (
    <Row gutter={24} ref={contentRef} justify="space-between">
      <Col span={isAccountPaymentMethod ? 24 : isFullWidth}>
        <Form.Item
          label={t('Form of payment')}
          name="payment_method"
          rules={[
            {
              required: true,
              message: t('Form of payment must be specified'),
            },
          ]}
          tooltip={t('Form of payment')}>
          <SearchSelect
            ref={inputRef}
            selectFirst={false}
            name="payment_method"
            placeholder={t('Select a form of payment')}
            disable={loadingSubmit}
            data={FROM_PAYMENT_METHOD_WITH_IN_DEBT}
            onChange={handleChangePaymentMethod}
          />
        </Form.Item>
      </Col>

      {isAccountPaymentMethod ? null : (
        <Col span={isFullWidth}>
          <Form.Item
            label={t('Cashbox')}
            name="payment_cashbox_uuid"
            rules={[
              {
                required: true,
                message: t('Cash boxes must be specified'),
              },
            ]}
            tooltip={t('Cashbox for the specified payment method')}>
            <SearchSelect
              resetState={resetCashBoxState}
              ref={cashBoxRef}
              selectFirst={false}
              selectIfOnFirst={false}
              onSearch={(keywords) =>
                handleSearchCashBoxes({
                  keywords,
                  limit: 100,
                  showLoading: false,
                })
              }
              getOptionValueTitle={(cashBox: CashBoxModel) => (
                <CashBoxTitleView
                  cashBox={cashBox}
                  disable={loadingSubmit || cashBoxListLoading}
                />
              )}
              name="payment_cashbox_uuid"
              placeholder={t('Select a cash box')}
              disable={loadingSubmit || cashBoxListLoading}
              data={isListToArray(
                cashBoxList?.filter(({box_type, box_can_in_money_to}) => {
                  return (
                    box_type ===
                      convertedPaymentMethodToCashBox[
                        formData?.payment_method as PAYMENT_METHOD
                      ] && box_can_in_money_to
                  );
                }) as any,
              )}
              onChange={handleChangeCashBox}
            />
          </Form.Item>
        </Col>
      )}

      {isAccountPaymentMethod ? (
        <Col span={24}>
          <StyledFormItem
            label={t('Client')}
            name="payment_client_uuid"
            tooltip={t('Client')}
            extra={
              client?.client_discount ? (
                <StyledDescription
                  style={{whiteSpace: 'unset'}}
                  $color={theme.colors.success}>
                  {`${t('Discount')}: ${
                    client?.client_discount_type ===
                    ClientDiscountType.Percentage
                      ? `${client?.client_discount}%`
                      : `${
                          defaultCompany?.currency_symbol || ''
                        } ${correctPrice(client?.client_discount)}`
                  }`}
                </StyledDescription>
              ) : null
            }>
            <SearchSelect
              allowClear
              ref={clientRef}
              selectFirst={false}
              selectIfOnFirst={false}
              name="payment_client_uuid"
              disable={loading || clientsLoading || loadingSubmit}
              placeholder={t('Select a client')}
              data={listToArray(clients as any)}
              getOptionValueTitle="fullNameClient"
              onSearch={(keywords) =>
                handleSearchClients({keywords, showLoading: false}) as any
              }
              resetState={clientsLoading}
              onChange={handleChangeClient}
              addonAfter={
                <ClientCreateButton
                  disabled={loadingSubmit}
                  className="client-create-event"
                  type="text"
                  loading={loading}
                  onSuccess={handleCreateClient}
                />
              }
            />
          </StyledFormItem>
        </Col>
      ) : null}

      <Col span={24}>
        <StyledFormItem
          label={t('Amount')}
          name="price"
          extra={
            Number(remaining.toFixed(2)) > 0 ? (
              <StyledDescription
                $color={theme.colors.success}
                style={{whiteSpace: 'unset', fontSize: 28}}>
                {`${t('The remaining')}: ${correctPrice(remaining.toFixed(2))}`}
              </StyledDescription>
            ) : null
          }
          rules={[
            () => ({
              validator(_, amount) {
                const value = amount.replace(/[a-zA-Z]+/g, '');

                const min = (Number(minSum) || 0) - discountRemaining;

                if (!Number(value) && Number(value) !== 0) {
                  return Promise.reject(
                    new Error(t('The amount must be a number')),
                  );
                }

                if (Number(value) < min) {
                  return Promise.reject(
                    new Error(
                      `${t('The amount must be greater or equal than')} ${
                        defaultCompany?.currency_symbol
                      }${correctPrice(min)}`,
                    ),
                  );
                }

                if (Number(value) < 0) {
                  return Promise.reject(
                    new Error(t('The amount must be greater than 0')),
                  );
                }

                return Promise.resolve();
              },
            }),
          ]}>
          <StyledPriceInput
            autoComplete="off"
            addonAfter={
              formData?.is_apply_payment_doc_discount &&
              discountRemaining > 0 &&
              Number(minSum || 0) >= discountRemaining ? (
                <Title $color={theme.colors.error}>
                  {correctPrice(minSum || 0)}
                </Title>
              ) : remaining > 0 ? (
                `${correctPrice(minSum)}`
              ) : null
            }
            ref={sumRef}
            onFocus={(e) => e?.target?.select()}
            addonBefore={defaultCompany?.currency_symbol}
            disabled={
              loadingSubmit ||
              DISABLED_SUM_METHODS.includes(formData?.payment_method)
            }
            onChange={(e) => {
              handlerUpdateFormState({price: e?.target?.value});
            }}
          />
        </StyledFormItem>
      </Col>

      <FormSwitch
        span={24}
        loading={loadingSubmit || loading}
        disabled={loadingSubmit || loading}
        name="is_print_check"
        getFieldValue={getFieldValue}
        setFieldsValue={setFieldsValue}
        label={null}
        title={t('Create internal check')}
        onChange={(is_print_check) => {
          handlerUpdateFormState({is_print_check});
        }}
        value={!!formData?.is_print_check}
      />

      {isAccountPaymentMethod ? null : (
        <>
          <FormSwitch
            tooltipTitle={
              'To start using the PRRO go to the Settings -> Integrations and activate the service provider.'
            }
            span={isFiscalOperationDisabled ? 24 : 13}
            loading={loadingSubmit || loading}
            disabled={
              loadingSubmit ||
              isShowFiscalCashboxesWarning ||
              isFiscalOperationDisabled
            }
            name="payment_is_fiscal_create"
            getFieldValue={getFieldValue}
            setFieldsValue={setFieldsValue}
            label={null}
            title={t('Create fiscal check')}
            onChange={(payment_is_fiscal_create) => {
              handlerUpdateFormState({payment_is_fiscal_create});
              if (!payment_is_fiscal_create) {
                return;
              }

              const selectedCashBox = cashBoxList?.find(
                ({uuid}) => uuid === formData?.payment_cashbox_uuid,
              );

              if (selectedCashBox && selectedCashBox?.box_fiscal_cashbox_id) {
                handlerUpdateFormState({
                  payment_fiscal_number: selectedCashBox?.box_fiscal_cashbox_id,
                });
              }
            }}
            value={
              isShowFiscalCashboxesWarning
                ? false
                : !!formData.payment_is_fiscal_create
            }
          />

          {!isFiscalOperationDisabled ? (
            <Col span={9}>
              <Form.Item
                label={t('Cashbox number')}
                name="payment_fiscal_number"
                tooltip={t(
                  'The fiscal cashbox number which is configured on the integration section',
                )}>
                <Input
                  disabled={true}
                  onChange={(e) =>
                    handlerUpdateFormState({
                      payment_fiscal_number: e?.target?.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
          ) : null}
        </>
      )}
      <Col span={24}>
        <Collapse
          title={(isExpanded) =>
            isExpanded ? t('Hide') : t('Additional parameters')
          }
          height={250}>
          <Row>
            <StyledSwitch
              $disabledDiscount={!!disabledDiscount}
              span={formData?.is_apply_payment_doc_discount ? 12 : 24}
              loading={loadingSubmit || loading}
              disabled={loadingSubmit || loading || disabledDiscount}
              name="is_apply_payment_doc_discount"
              getFieldValue={getFieldValue}
              setFieldsValue={setFieldsValue}
              label={null}
              title={t('Apply discount')}
              onChange={handleChangeIsApplyPaymentDocDiscount}
              value={!!formData?.is_apply_payment_doc_discount}
              extraOutside={
                disabledDiscount ? (
                  <StyledDescription $color={theme.colors.warning}>
                    {`${t(
                      'A discount has already been applied to the document',
                    )} ${discountText}`}
                  </StyledDescription>
                ) : null
              }
            />

            {formData?.is_apply_payment_doc_discount ? (
              <Col span={12}>
                <Form.Item
                  tooltip={t('Discount')}
                  label={null}
                  name="payment_doc_discount_value"
                  rules={
                    formData?.payment_doc_discount_type ===
                    ClientDiscountType.Fixed
                      ? [
                          {
                            transform: (value) => Number(value),
                            type: 'number',
                            required: false,
                            max: Number(minSum) || Infinity,
                            message: Number(minSum)
                              ? t('The discount cannot be more than the price')
                              : t('Discount must be specified.'),
                          },
                        ]
                      : [
                          {
                            transform: (value) => Number(value),
                            type: 'number',
                            min: 0,
                            max: 100,
                            required: false,
                            message: t(
                              'Discount must be a number and bigger than -1 and less 100',
                            ),
                          },
                        ]
                  }
                  extra={
                    discountRemaining > 0 &&
                    formData?.is_apply_payment_doc_discount &&
                    Number(minSum || 0) >= discountRemaining ? (
                      <StyledPriceDiscountContainer>
                        <StyledDescription
                          bold
                          style={{whiteSpace: 'unset'}}
                          $color={theme.colors.error}>
                          {`${t('The discount total')}: ${correctPrice(
                            discountRemaining.toFixed(2),
                          )}`}
                        </StyledDescription>
                      </StyledPriceDiscountContainer>
                    ) : null
                  }>
                  <Input
                    autoFocus
                    onFocus={(e) => e?.target?.select()}
                    addonAfter={
                      <Form.Item noStyle name="payment_doc_discount_type">
                        <StyledSearchSelect
                          popupMatchSelectWidth={false}
                          name="payment_doc_discount_type"
                          placeholder={''}
                          getOptionValueTitle="description"
                          disable={loadingSubmit}
                          data={STORE_PAYMENT_DISCOUNT_TYPE(
                            defaultCompany?.currency_symbol!,
                          )}
                          onChange={async (payment_doc_discount_type) => {
                            handlerUpdateFormState({payment_doc_discount_type});
                            try {
                              await validateFields([
                                'payment_doc_discount_value',
                              ]);
                            } catch (error) {}
                          }}
                        />
                      </Form.Item>
                    }
                    type="number"
                    disabled={loadingSubmit}
                    placeholder={t('Enter discount')}
                    onChange={handleChangeDiscount}
                  />
                </Form.Item>
              </Col>
            ) : null}

            <Col span={24}>
              <Form.Item label={t('Note')} name="payment_doc_comment">
                <Input
                  ref={commentRef}
                  disabled={loading || loadingSubmit}
                  placeholder={t('Enter your note')}
                  onChange={(e) =>
                    handlerUpdateFormState({
                      payment_doc_comment: e?.target?.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Collapse>
      </Col>

      {isAccountPaymentMethod &&
      !formData?.payment_doc_comment &&
      !formData?.payment_client_uuid ? (
        <Col span={24}>
          <StyledDescription
            $color={theme.colors.error}
            style={{whiteSpace: 'unset'}}>
            {t(
              'For a loan transaction, you must fill in either the Client field or a note',
            )}
          </StyledDescription>
        </Col>
      ) : null}
    </Row>
  );
}
