import React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import moment from 'moment';

export default withTranslation()(CategoryPage);

export interface ICategoryPageProps extends WithTranslation {
  category: Partial<any>;
  expanded?: boolean;
}

function CategoryPage({
  category: {description = '', created_at = '', updated_at = ''},
  expanded,
  t,
}: ICategoryPageProps) {
  return expanded ? (
    <>
      <div>
        <strong>{t('Created')}: </strong>
        <span>{moment(created_at).format('L')}</span>
      </div>
      {updated_at && (
        <div>
          <strong>{t('Updated')}: </strong>
          <span>{moment(updated_at).format('L')} </span>
        </div>
      )}
      {description && (
        <div>
          <strong>{t('Description')}: </strong>
          <span>{description} </span>
        </div>
      )}
    </>
  ) : null;
}
