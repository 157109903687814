import {AclDTO, IAclDTOProps, AclModel} from '../internal';
import {AclKey} from '../../services/types';

const aclMap = {
  [AclKey.order]: {},
  [AclKey.client]: {},
  [AclKey.company]: {},
  [AclKey.service]: {},
  [AclKey.employee]: {},
  [AclKey.calendar]: {},
  [AclKey.settings]: {},
  [AclKey.template]: {},
  [AclKey.payment_group]: {},
  [AclKey.notifications]: {},
  [AclKey.reports]: {},
  [AclKey.department]: {},
  [AclKey.store]: {},
  [AclKey.priceMargin]: {},
  [AclKey.orderStats]: {},
};

export type IAclModelMap = {
  [key in AclKey]: AclModel;
};

export type IAclDTOMap = {
  [key in AclKey]: AclDTO;
};

export type IAclProp = {
  [key in AclKey]: IAclDTOProps;
};

export class AclMapper {
  public static toAclDTO(acl: AclModel | IAclDTOProps): AclDTO {
    return new AclDTO(acl);
  }

  public static toAclModel(aclDTO: AclDTO): AclModel {
    return new AclModel(aclDTO);
  }

  public static toAclMapDTO(acl: IAclProp): IAclDTOMap {
    return Object.fromEntries(
      Object.entries({...aclMap, ...acl}).map(([key, value]) => {
        return [key as any, AclMapper.toAclDTO(value)];
      }),
    );
  }

  public static toAclMapModel(acl: IAclProp): IAclModelMap {
    return Object.fromEntries(
      Object.entries({...aclMap, ...acl}).map(([key, value]) => {
        return [key as any, AclMapper.toAclModel(value)];
      }),
    );
  }
}
