import {
  IServiceDTOProps,
  ServiceDTO,
  ServiceMapper,
  IRelatedServiceModelReturnType,
} from '../internal';
import {WithoutRecursion} from '../type';
import {correctPrice} from '../../helpers';

export interface IRelatedServiceDTOProps {
  uuid: string;
  schedule_service_quantity: string;
  schedule_service_price: string;
  schedule_service_price_total: string;
  schedule_comment: string;
  created_at: string;
  updated_at: string;
  service: IServiceDTOProps;
}

export class RelatedServiceDTO {
  public uuid: string;

  public schedule_service_quantity: string;

  public schedule_service_price: string;

  public schedule_service_price_total: string;

  public schedule_comment: string;

  public created_at: string;

  public updated_at: string;

  public service: ServiceDTO;

  constructor(
    props: IRelatedServiceModelReturnType | IRelatedServiceDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';
    this.schedule_service_quantity = correctPrice(
      props?.schedule_service_quantity,
    );
    this.schedule_service_price = correctPrice(props?.schedule_service_price);
    this.schedule_service_price_total = correctPrice(
      props?.schedule_service_price_total,
    );
    this.schedule_comment = props?.schedule_comment || '';
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';

    this.service = ServiceMapper.toServiceDTO(props?.service, [
      WithoutRecursion.relatedService,
      ...withoutRecursion,
    ]);
  }
}
