import React, {useCallback, useMemo, useState} from 'react';
import {Empty} from 'antd';
import {useTranslation} from 'react-i18next';
import {
  useStoredStoreList,
  useStopLoading,
  useStoredCompanies,
  useStoredStore,
  useStoredProfile,
  useKeyboardOpenForm,
  useSearchInputFocus,
} from '../../../hooks';
import {
  StoreCreateButton,
  StoreDeleteButton,
  StoreUpdateButton,
} from '../Buttons';
import {textToUpperCase} from '../../../services/helpers';
import {
  Table,
  TableActionCell,
} from '../../../components/lib/libV2/DataDisplay';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import {EmployeeModel, StoreMapper, StoreModel} from '../../../struture';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '../../../services/types';
import {useAcl} from '../../../contex';
import {
  DetailsItemView,
  HeaderLeft,
  ListActions,
} from '../../../components/lib/DataDisplay';
import {useNavigate} from 'react-router';
import {ListLayout} from '../../../components/lib/Layout';
import {StoreDetailsView} from '../Show';
import '../../Orders/List/OrderList.less';
import styled from 'styled-components';
import {PlusOutlined} from '@ant-design/icons';
import {successButtonStyle} from '../../../components/lib/Styled';

export interface IStoreListPageProps {}

const StyledStoreCreateButton = styled(StoreCreateButton)`
  ${successButtonStyle}
`;

export function StoreListPage(): JSX.Element {
  const navigate = useNavigate();
  const {defaultCompanyUuid} = useStoredCompanies();
  const {manage} = useAcl(({store}) => store);
  const {t} = useTranslation();
  const {profile} = useStoredProfile();
  const {ref: listRef} = useKeyboardOpenForm({
    className: 'store-create-event',
    disabled: !manage,
  });

  const [selectedStore, setSelectedStore] = useState<StoreModel[]>([]);

  const {
    storeList,
    limit,
    total,
    loading: loadingStoreList,
    error: errorStoreList,
    loadingMore,

    handleDeleteStores: onDeleteStores,
    handleSearchStores,
    handleRefreshStores,
  } = useStoredStoreList({companyUuid: defaultCompanyUuid});

  const {handleUpdateStore, handleCreateStore} = useStoredStore({
    loadOnInit: false,
    companyUuid: defaultCompanyUuid,
    storeUuid: '',
  });

  const loading = useStopLoading({
    loading: loadingStoreList || loadingMore,
    error: errorStoreList,
    message: 'An error occurred during stores loading',
  });

  const {inputRef, focus, daleyFocus} = useSearchInputFocus({loading});

  const handleDeleteStores = useCallback(
    async (value: string[]) => {
      await onDeleteStores(value);
      focus();
    },
    [focus, onDeleteStores],
  );

  const routes = useBreadcrumb([
    {
      path: `/${Routes.stores}`,
      breadcrumbName: 'Stores',
    },
  ]);

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: selectedStore.map(({uuid}) => uuid),
      onChange: (
        selectedRowKeys: React.Key[],
        selectedRecords: StoreModel[],
      ) => {
        setSelectedStore(selectedRecords);
      },
    }),
    [selectedStore],
  );

  const columns = [
    {
      ellipsis: true,
      title: t('Name'),
      width: '80%',
      key: 'name',
      render: (store: StoreModel) => <StoreDetailsView store={store} />,
    },
    {
      ellipsis: true,
      title: t('Actions'),
      key: 'store_actions',
      align: 'center' as any,
      fixed: 'right' as any,
      className: 'order-list-td',
      render: (store: StoreModel) => {
        return (
          <TableActionCell>
            <StoreUpdateButton
              disabled={!manage}
              store={StoreMapper.toStoreFormDTO(store, {editMode: true})}
              onSuccess={handleUpdateStore}
              onCancel={daleyFocus}
            />
            <StoreDeleteButton
              disabled={!manage}
              stores={[store]}
              onSuccess={handleDeleteStores}
            />
          </TableActionCell>
        );
      },
    },
  ];

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.companies}/${defaultCompanyUuid}`);
  }, [defaultCompanyUuid, navigate]);

  return (
    <ListLayout
      ref={listRef}
      aclItem={RoutesAcl[Routes.stores]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.stores]))}
      headerRight={
        <>
          {selectedStore?.length ? (
            <StoreDeleteButton
              disabled={!manage}
              stores={selectedStore}
              onSuccess={handleDeleteStores}
            />
          ) : (
            <StyledStoreCreateButton
              disabled={!manage}
              loading={loading}
              type="primary"
              onSuccess={handleCreateStore as any}
              title={t('Create')}
              icon={<PlusOutlined />}
              store={StoreMapper.toStoreFormDTO({} as StoreModel, {
                manager: profile?.defaultEmployeeModel,
              })}
              onCancel={daleyFocus}
            />
          )}
        </>
      }
      routes={routes}
      empty={
        storeList?.size ? null : (
          <Empty description={t('There are no stores created')}>
            <StyledStoreCreateButton
              disabled={!manage}
              loading={loading}
              type="primary"
              onSuccess={handleCreateStore as any}
              title={t('Create')}
              icon={<PlusOutlined />}
              store={StoreMapper.toStoreFormDTO({} as StoreModel, {
                manager: profile?.defaultEmployeeModel,
              })}
              onCancel={daleyFocus}
            />
          </Empty>
        )
      }
      headerExtra={
        <ListActions
          inputRef={inputRef}
          withoutPicker={true}
          loading={loading}
          handleSearch={(keywords) => handleSearchStores({keywords})}
          inputTooltip={t('Search stores')}
          inputLabel={t('Search stores')}
          withSearchContent={null}
        />
      }
      loading={loading && !storeList?.size}>
      <Table<StoreModel>
        pageSize={limit}
        total={total}
        onChange={handleRefreshStores}
        rowSelection={rowSelection}
        dataSource={storeList}
        columns={columns}
        loading={loading}
      />
    </ListLayout>
  );
}
