import {correctPrice, toMoment} from '../../services/helpers';
import {
  CashBoxModel,
  EmployeeModel,
  ClientModel,
  PAYMENT_METHOD,
  StoreDocumentModel,
  StoreDocumentType,
} from '../internal';
import {Moment} from 'moment';
import {immerable} from 'immer';

export enum StorePaymentDocumentPostActionType {
  SET_STORE_DOCUMENT_STATUS_COMMITTED = 'set_store_document_status_committed',
}

export interface IStorePaymentDocumentPostAction {
  action: StorePaymentDocumentPostActionType;
  value?: string;
}

export interface IStorePaymentDocumentFormAdditionalParams {
  editMode?: boolean;
  payment_post_action?: IStorePaymentDocumentPostAction | null;
  comment?: string;
  cashier: EmployeeModel | string;
  withoutDiscount?: boolean;
  onlyTotal?: boolean;
  payment_cashbox_uuid?: string;
  payment_is_fiscal_create?: boolean;
  payment_method?: PAYMENT_METHOD;
}

export enum StorePaymentDocumentDiscountType {
  FIXED = 'fixed',
  PERCENTAGE = 'percentage',
}

export enum StorePaymentDocumentDiscountSourceType {
  CLIENT = 'client',
  CUSTOM = 'custom',
}

export class StorePaymentDocumentFormDTO {
  [immerable]? = true;

  public uuid: string;
  public payment_date: string | Moment;
  public payment_method: PAYMENT_METHOD;
  public payment_price: number | string;
  public payment_comment: string;
  public payment_doc_comment: string;
  public payment_manager_uuid: string | EmployeeModel;
  public payment_cashbox_uuid: string | CashBoxModel;
  public payment_post_action?: IStorePaymentDocumentPostAction | null;
  public payment_client_uuid?: ClientModel | string;
  public payment_fiscal_number: string;
  public is_print_check?: boolean;
  public payment_is_fiscal_create?: boolean;
  public doc_type?: StoreDocumentType | null;
  public payment_doc_discount_type?: StorePaymentDocumentDiscountType;
  public payment_doc_discount_value?: string;
  public payment_doc_discount_source_type?: StorePaymentDocumentDiscountSourceType;
  public is_apply_payment_doc_discount?: boolean;

  constructor(
    props: StoreDocumentModel,
    {
      payment_post_action,
      comment,
      cashier,
      withoutDiscount,
      onlyTotal,
      payment_cashbox_uuid = '',
      payment_is_fiscal_create = false,
      payment_method = PAYMENT_METHOD.Cash,
    }: IStorePaymentDocumentFormAdditionalParams,
  ) {
    this.uuid = props?.uuid || '';
    this.payment_date = toMoment(new Date());
    this.payment_method = payment_method;
    this.payment_price = this.calculatePaymentSum(props, onlyTotal);
    this.payment_comment = comment || '';
    this.payment_doc_comment = props?.doc_comment || '';
    this.payment_manager_uuid = cashier;
    this.payment_cashbox_uuid = payment_cashbox_uuid;
    this.payment_fiscal_number = '';
    this.is_print_check = false;
    this.payment_is_fiscal_create = payment_is_fiscal_create;
    this.payment_post_action = payment_post_action || null;
    this.doc_type = props?.doc_type || null;
    this.payment_client_uuid = props?.clientModel?.uuid
      ? props?.clientModel
      : '';
    this.payment_doc_discount_type = withoutDiscount
      ? undefined
      : props?.doc_discount_type;
    this.payment_doc_discount_value = withoutDiscount
      ? undefined
      : props?.doc_discount_value;
    this.payment_doc_discount_source_type = withoutDiscount
      ? undefined
      : props?.doc_discount_source;
    this.is_apply_payment_doc_discount = withoutDiscount
      ? undefined
      : !!props?.doc_discount_value;
  }

  private calculatePaymentSum(
    {
      doc_sum_total,
      doc_sum_paid,
      doc_sum_total_with_discount,
      doc_discount_value,
    }: StoreDocumentModel,
    onlyTotal?: boolean,
  ): string {
    const total = Number(doc_sum_total);
    const paid = Number(doc_sum_paid);
    const sumWithDiscount = Number(doc_sum_total_with_discount || 0);

    const isDocDiscountValue = Number(doc_discount_value || 0) > 0;

    const totalDiscount =
      isDocDiscountValue && !onlyTotal ? sumWithDiscount : total;

    const result = totalDiscount - paid;

    if (paid >= totalDiscount) {
      return correctPrice(0);
    }

    return correctPrice(result);
  }
}
