import {
  IClientAbonementSubscriptionModelReturnType,
  IClientAbonementDTOProps,
  ClientAbonementDTO,
  ClientAbonementMapper,
  IClientAbonementLatestStatusDTOProps,
} from '../internal';
import {WithoutRecursion} from '../type';
import {correctPrice, toMoment} from '../../services/helpers';
import {Moment} from 'moment';

export enum ClientAbonementSubscriptionStatusesText {
  Active = 'active',
  Error = 'error',
  Deferred = 'deferred',
  Terminated = 'terminated',
}

export interface IClientAbonementSubscriptionDTOProps {
  uuid: string;
  subscription_start_date: string;
  subscription_end_date: string;
  subscription_price: string;
  subscription_visiting_balance: string;
  subscription_guest_balance: string;
  subscription_status_text: ClientAbonementSubscriptionStatusesText;
  created_at: string;
  updated_at: string;
  client_abonement: IClientAbonementDTOProps;
}

export class ClientAbonementSubscriptionDTO {
  public uuid: string;
  public subscription_start_date: string | Moment;
  public subscription_end_date: string | Moment;
  public subscription_price: string;
  public subscription_visiting_balance: string;
  public subscription_guest_balance: string;
  public subscription_status_text: ClientAbonementSubscriptionStatusesText;
  public created_at: string;
  public updated_at: string;
  public client_abonement: ClientAbonementDTO;

  constructor(
    props:
      | IClientAbonementSubscriptionModelReturnType
      | IClientAbonementSubscriptionDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';
    this.subscription_start_date = toMoment(props?.subscription_start_date);
    this.subscription_end_date = toMoment(props?.subscription_end_date);
    this.subscription_price = correctPrice(props?.subscription_price || 0);
    this.subscription_visiting_balance = correctPrice(
      props?.subscription_visiting_balance || 0,
    );
    this.subscription_guest_balance = correctPrice(
      props?.subscription_guest_balance || 0,
    );
    this.subscription_status_text =
      props?.subscription_status_text ||
      ClientAbonementSubscriptionStatusesText.Active;
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
    this.client_abonement =
      withoutRecursion.indexOf(WithoutRecursion.abonement) !== -1
        ? (props?.client_abonement as ClientAbonementDTO)
        : ClientAbonementMapper.toClientAbonementDTO(
            props?.client_abonement,
            {} as IClientAbonementLatestStatusDTOProps,
            [WithoutRecursion.clientAbonement, ...withoutRecursion],
          ).abonement;
  }
}
