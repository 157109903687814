import React, {forwardRef} from 'react';
import styled, {useTheme} from 'styled-components';
import {StyledTitle} from '../../../components/lib/Styled';
import {useTranslation} from 'react-i18next';
import {
  IUseStateScheduleReturnType,
  PaymentProps,
  useStoredCompanies,
} from '../../../hooks';
import {
  PaymentFormDTO,
  PaymentScheduleFormDTO,
  ScheduleStatusesText,
} from '../../../struture';
import {OverviewBottomView} from '../../../components/lib/Layout';
import {correctPrice} from '../../../services/helpers';
import {Routes} from '../../../services/types';
import {
  IPaymentScheduleReturnType,
  PaymentScheduleOperationType,
} from '../../../services/api/orders';
import {CreateSchedulePaymentButton} from '../Buttons';

export interface ISchedulePaymentBottomViewProps
  extends Pick<IUseStateScheduleReturnType, 'schedule'> {
  payment: (props?: PaymentProps) => PaymentFormDTO | PaymentScheduleFormDTO;
  loading: boolean;
  createScheduleRefunds: (
    value: PaymentScheduleFormDTO,
  ) => Promise<void | IPaymentScheduleReturnType>;
}

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  gap: 2px;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 2px;
`;

const StyledPrice = styled(StyledTitle)`
  font-size: 30px;
`;

const StyledPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 5px;
`;

const Title = styled(StyledTitle)`
  margin-bottom: 5px;
`;

export const SchedulePaymentBottomView = forwardRef(
  function SchedulePaymentBottomView(
    {
      schedule,
      payment,
      loading,
      createScheduleRefunds,
    }: ISchedulePaymentBottomViewProps,
    ref: any,
  ): JSX.Element {
    const {t} = useTranslation();
    const {defaultCompany, defaultCompanyUuid} = useStoredCompanies();
    const theme: any = useTheme();

    const sumPaid = Number(schedule?.sum_paid || 0);
    const sumTotal = Number(schedule?.sum_total || 0);

    return (
      <>
        <div ref={ref}>
          <OverviewBottomView>
            {sumPaid < sumTotal &&
            schedule?.status_text === ScheduleStatusesText.CLOSED ? (
              <CreateSchedulePaymentButton
                from={Routes.orders}
                type="primary"
                payment={payment}
                companyUuid={defaultCompanyUuid}
                loading={loading}
                onSuccess={async () => {}}
                onSuccessRefund={createScheduleRefunds}
                title={t('Create schedulePayment')}
                scheduleOperationType={PaymentScheduleOperationType.Out}
                paymentPrice={correctPrice(sumTotal - sumPaid || 0)}
              />
            ) : null}

            <StyledWrapper>
              <StyledContainer>
                <StyledTitle fontSize={14}>{`${t('Paid')}: `}</StyledTitle>
                <StyledPriceContainer>
                  <StyledPrice>
                    {`${defaultCompany?.currency_symbol || ''} ${correctPrice(
                      schedule?.sum_paid || 0,
                    )}`}
                  </StyledPrice>
                  <Title>
                    {`${t('with')} ${correctPrice(schedule?.sum_total || 0)}`}
                  </Title>
                </StyledPriceContainer>
                {sumPaid === 0 ? null : sumPaid < sumTotal ? (
                  <StyledTitle $color={theme.colors.success}>
                    {`${t('Balance due').toLowerCase()} ${
                      defaultCompany?.currency_symbol || ''
                    }, ${correctPrice(sumTotal - sumPaid)}`}
                  </StyledTitle>
                ) : sumPaid > sumTotal ? (
                  <StyledTitle $color={theme.colors.error}>
                    {`${t('amount to be refunded')} ${
                      defaultCompany?.currency_symbol || ''
                    }, ${correctPrice(sumTotal - sumPaid)}`}
                  </StyledTitle>
                ) : null}
              </StyledContainer>
            </StyledWrapper>
          </OverviewBottomView>
        </div>
      </>
    );
  },
);
