import {Record} from 'immutable';
import {SystemRuleDTO} from '../internal';

export interface ISystemRuleModelReturnType {
  rule_title: string;
  rule_description: string;
  created_at: string;
  updated_at: string;
}

export class SystemRuleModel extends Record<ISystemRuleModelReturnType>({
  rule_title: '',
  rule_description: '',
  created_at: '',
  updated_at: '',
}) {
  constructor(props: SystemRuleDTO = {} as SystemRuleDTO) {
    const option: ISystemRuleModelReturnType = {
      ...props,
    };

    super(option);
  }
}
