import React from 'react';
import {useTranslation} from 'react-i18next';
import {Empty} from 'antd';
import {ListLayout} from '../../../components/lib/Layout';
import {CreateStoreDocumentPostingPaymentButton} from '../Buttons';
import {
  IUseStateStoreDocumentPaymentListReturnType,
  PaymentProps,
} from '../../../hooks';
import {HeaderLeft} from '../../../components/lib/DataDisplay';
import {textToUpperCase} from '../../../services/helpers';
import useStoredCompanies from '../../../hooks/useStoredCompanies';
import {
  PaymentFormDTO,
  PaymentModel,
  PaymentScheduleFormDTO,
} from '../../../struture';
import {Routes, RoutesAcl} from '../../../services/types';
import {ITableProps, Table} from '../../../components/lib/libV2/DataDisplay';
import {StoreDocumentPaymentBottomView} from '../Show';
import {StyledListActionsContainer} from '../Show/ListStyledComponent';
import {StyledTitle} from '../../../components/lib/Styled';
import {PlusOutlined} from '@ant-design/icons';
import styled from 'styled-components';
import {StoreDocumentPageHeader} from '../../Stores';
import {correctPrice} from '@sportix/sportix-common-modules';

export interface StoreDocumentPostingPaymentListProps
  extends IUseStateStoreDocumentPaymentListReturnType {
  navigateGoBack: () => void;
  payment: (props?: PaymentProps) => PaymentFormDTO | PaymentScheduleFormDTO;
  routes: any[];
  rowSelection: any;
  columns: any[];
}

const StyledTable = styled(Table)`
  margin-bottom: 0;
` as React.ComponentType as React.FunctionComponent<ITableProps<PaymentModel>>;

const ListActionsContainer = styled(StyledListActionsContainer)`
  gap: 10px;
`;

export function StoreDocumentPostingPaymentList({
  document: storeDocument,
  payments,
  loading,
  limit,
  total,
  page,

  handleRefreshPayments,

  handleCreateStoreDocumentRefunds,

  navigateGoBack,

  payment,

  routes,
  rowSelection,
  columns,
}: StoreDocumentPostingPaymentListProps): React.JSX.Element {
  const {t} = useTranslation();
  const {defaultCompanyUuid} = useStoredCompanies();

  const paidSum = Number(storeDocument?.doc_sum_paid || 0);
  const totalSum = Number(storeDocument?.doc_sum_total || 0);

  const isShowPaymentButton = totalSum > 0 && paidSum < totalSum;

  return (
    <ListLayout
      isHeaderFlickering
      aclItem={RoutesAcl[Routes.stores]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t('Cash flow'))}
      headerRight={
        isShowPaymentButton ? (
          <CreateStoreDocumentPostingPaymentButton
            payment={payment}
            companyUuid={defaultCompanyUuid}
            loading={loading}
            onSuccess={handleCreateStoreDocumentRefunds}
            document={storeDocument}
            paymentPrice={correctPrice(totalSum - paidSum)}
          />
        ) : null
      }
      headerTitleDescription={
        storeDocument?.uuid
          ? `${t('for the document')} №${storeDocument?.doc_local_number}`
          : null
      }
      routes={routes}
      empty={
        payments?.size ? null : (
          <Empty description={t('There are no payments history.')}>
            {isShowPaymentButton ? (
              <CreateStoreDocumentPostingPaymentButton
                type="primary"
                payment={payment}
                companyUuid={defaultCompanyUuid}
                loading={loading}
                onSuccess={handleCreateStoreDocumentRefunds}
                title={<StyledTitle>{t('Create tPayment')}</StyledTitle>}
                document={storeDocument}
                paymentPrice={correctPrice(totalSum - paidSum)}
              />
            ) : null}
          </Empty>
        )
      }
      headerExtra={
        <ListActionsContainer>
          {storeDocument?.uuid ? (
            <StoreDocumentPageHeader
              withoutPayment
              withoutIndent
              document={storeDocument}
            />
          ) : null}
          {loading || !isShowPaymentButton ? null : (
            <CreateStoreDocumentPostingPaymentButton
              type="primary"
              payment={payment}
              companyUuid={defaultCompanyUuid}
              loading={loading}
              onSuccess={handleCreateStoreDocumentRefunds}
              title={t('schedulePayment')}
              icon={<PlusOutlined />}
              className="store-document-payment-create-event"
              document={storeDocument}
              paymentPrice={correctPrice(totalSum - paidSum)}
            />
          )}
        </ListActionsContainer>
      }
      outsideFooterContent={
        storeDocument?.uuid ? (
          <StoreDocumentPaymentBottomView
            document={storeDocument}
            payment={payment}
            loading={loading}
            createScheduleRefunds={handleCreateStoreDocumentRefunds}
          />
        ) : null
      }
      loading={loading && !payments?.size}>
      <StyledTable
        rowSelection={rowSelection}
        total={total}
        pageSize={limit}
        dataSource={payments}
        onChange={handleRefreshPayments}
        columns={columns}
        loading={loading}
        page={page}
      />
    </ListLayout>
  );
}
