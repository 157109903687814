import React, {useEffect} from 'react';
import {Routes, useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import {EventEmitter, getCookie} from '../services/helpers';
import {EventEmitterItems} from '../services/const';
import {Routes as RoutesTypes} from '../services/types';
import {tokenHandler} from '../services/core/token';

export interface IAuthRoutesProps {
  children: React.ReactNode | React.ReactNode[];
}

export function AuthRoutes({children}: IAuthRoutesProps): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `ServicitY: ${t('Corporate accounting system')}`;
  }, [t]);

  React.useEffect(() => {
    EventEmitter.clear(EventEmitterItems.auth_navigate_to_access_denied_page);

    EventEmitter.on(
      EventEmitterItems.auth_navigate_to_access_denied_page,
      (pathName: string, accessDeniedPageMessage: string) => {
        const isAuth = pathName.includes(RoutesTypes.auth);

        if (isAuth) {
          navigate(`/${RoutesTypes.auth}/${RoutesTypes.accessDenied}`, {
            state: {accessDeniedPageMessage},
          });
        }
      },
    );
  }, [navigate]);

  useEffect(() => {
    const access_token_local = tokenHandler.getAccessToken();
    if (access_token_local) {
      navigate(`/${RoutesTypes.app}`);
    }
  }, [navigate]);

  return <Routes>{children}</Routes>;
}
