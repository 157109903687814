import {Record} from 'immutable';
import {
  ProfileSubscriptionDTO,
  SystemSubscriptionModel,
  SystemSubscriptionMapper,
} from '../internal';

export interface IProfileSubscriptionModelReturnType {
  status_txt: string;
  system_subscription: SystemSubscriptionModel;
  created_at: string;
  updated_at: string;
}

export class ProfileSubscriptionModel extends Record<IProfileSubscriptionModelReturnType>(
  {
    status_txt: '',
    system_subscription: {} as SystemSubscriptionModel,
    created_at: '',
    updated_at: '',
  },
) {
  constructor(props: ProfileSubscriptionDTO = {} as ProfileSubscriptionDTO) {
    const option: IProfileSubscriptionModelReturnType = {
      ...props,
      system_subscription: SystemSubscriptionMapper.toSystemSubscriptionModel(
        (props as any)?.system_subscription || [],
      ),
    };

    super(option);
  }
}
