import React, {useRef} from 'react';
import {Col, Collapse} from 'antd';
import styled, {css} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {
  isObject,
  textToUpperCase,
  isFunction,
} from '../../../../../services/helpers';
import OverviewLayoutDetailsItem from './OverviewLayoutDetailsItem';
import {ColorsSchemaType, IDefaultTabPanelProps} from '../../../General';
import {StyledDescription, StyledTitle} from '../../../Styled';
import {useSize} from '../../../../../hooks';
import {RightOutlined, LockOutlined} from '@ant-design/icons';
import {useAcl} from '../../../../../contex';
import {AclDTO, AclMapper} from '../../../../../struture';

export interface OverviewLayoutDetailsPros<T>
  extends Pick<IDefaultTabPanelProps, 'tabs'> {
  data: T;
  header?: string | React.ReactNode;
  topTabs?: IDefaultTabPanelProps['tabs'];
  footerContentExtra?: React.ReactNode;
  dataContentExtra?: React.ReactNode;
  dataWithoutTab?: boolean;
  defaultActiveKey?: string;
  className?: string;
  isFullWidthItems?: string[];
  children?: React.ReactNode | React.ReactNode[];
}

const DetailsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  height: fit-content;
  width: auto;
  background: transparent;
`;

const NestedTitleTextFormat = styled(StyledTitle)<{
  $type?: ColorsSchemaType;
  disabled?: boolean;
}>`
  color: ${({theme}) => theme.colors.primary};

  ${({$type}) =>
    $type === 'error' &&
    css`
      color: ${({theme}) => theme.colors.error};
    `}

  ${({disabled}) =>
    disabled &&
    css`
      color: ${({theme}) => theme.colors.disabled};
    `}
`;

const StyledCollapse = styled(Collapse)<{
  $type?: ColorsSchemaType;
  $topType?: ColorsSchemaType;
}>`
  display: flex;
  width: 100%;
  min-height: 65px;

  .ant-collapse-header {
    display: flex;
    border-bottom: 1px solid rgba(64, 64, 65, 0.25);
    min-height: 65px;
    align-items: center !important;
  }

  .ant-collapse-content-box {
    background-color: ${({theme}) => theme.background.layout};
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  ${({$type}) =>
    $type === 'error' &&
    css`
      & * > .ant-collapse-expand-icon {
        color: ${({theme}) => theme.colors.error};
      }
    `}

  ${({$topType}) =>
    $topType === 'error' &&
    css`
      &&& .ant-collapse-header {
        background-color: ${({theme}) => theme.colors.error};

        & .ant-collapse-header-text > div > div > * {
          color: ${({theme}) => theme.colors.white};
        }
      }

      & * > .ant-collapse-expand-icon {
        color: ${({theme}) => theme.colors.white};
      }
    `}

  ${({$topType}) =>
    $topType === 'success' &&
    css`
      &&& .ant-collapse-header {
        background-color: ${({theme}) => theme.colors.success};

        & .ant-collapse-header-text > div > div > * {
          color: ${({theme}) => theme.colors.white};
        }
      }

      & * > .ant-collapse-expand-icon {
        color: ${({theme}) => theme.colors.white};
      }
    `}

  ${({$topType}) =>
    $topType === 'warning' &&
    css`
      &&& .ant-collapse-header {
        background-color: ${({theme}) => theme.colors.warning};

        & .ant-collapse-header-text > div > div > * {
          color: ${({theme}) => theme.colors.white};
        }
      }

      & * > .ant-collapse-expand-icon {
        color: ${({theme}) => theme.colors.white};
      }
    `}

  ${({$topType}) =>
    $topType === 'abonement' &&
    css`
      &&& .ant-collapse-header {
        background-color: #c0aea1;

        & .ant-collapse-header-text > div > div > * {
          color: ${({theme}) => theme.colors.white};
        }
      }

      & * > .ant-collapse-expand-icon {
        color: ${({theme}) => theme.colors.white};
      }
    `}
`;

const StyledPanel = styled(Collapse.Panel)`
  width: 100%;

  & .ant-collapse-content-box {
    display: flex;
    flex-wrap: wrap;
  }
`;

const StyledTabsPanel = styled(Collapse.Panel)<{$isShowContent?: boolean}>`
  width: 100%;

  ${({$isShowContent}) =>
    !$isShowContent &&
    css`
      & .ant-collapse-content-box {
        display: none;
      }
    `}

  ${({$isShowContent}) =>
    $isShowContent &&
    css`
      & .ant-collapse-content-box {
        display: flex;
        flex-wrap: wrap;
      }
    `}
`;

const StyledTabsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  margin-bottom: 15px;
  align-items: flex-start;
  gap: 20px;
`;

const StyledHeaderContent = styled.div`
  flex: 1;
  min-width: 250px;
`;

const StyledNestedContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledNestedItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const StyledDataContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const StyledLockOutlined = styled(LockOutlined)`
  font-size: 30px;
  color: ${({theme}) => theme.colors.disabled};
`;

const StyledHiddenContainer = styled.div<{$hidden: boolean}>`
  width: 100%;
  height: 100%;

  ${({$hidden}) =>
    $hidden &&
    css`
      visibility: hidden;
      height: 0;
      overflow: hidden;
    `}
`;

const StyledHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
`;

export default function OverviewLayoutDetails<T>({
  data,
  header,
  tabs = [],
  topTabs,
  footerContentExtra,
  dataWithoutTab,
  defaultActiveKey,
  dataContentExtra,
  className,
  isFullWidthItems,
  children,
}: OverviewLayoutDetailsPros<T>): JSX.Element {
  const {t} = useTranslation();

  const detailsRef = useRef(null);
  const {width: detailsWidth} = useSize(detailsRef);
  const acl = useAcl((acl) => acl);

  const renderData = (data: any) => (
    <>
      {Object.entries(data).map(([key, value]: [string, any]) =>
        !isObject(value) || React.isValidElement(value) ? (
          <OverviewLayoutDetailsItem
            isFullWidthItems={isFullWidthItems}
            key={`${key}`}
            label={key}
            title={value}
            detailsWidth={detailsWidth}
            className={className}
          />
        ) : (
          <StyledNestedContainer key={`${key}`}>
            {key.includes('ignore') ? null : (
              <StyledTitle bold>{`${t(key)}:`}</StyledTitle>
            )}

            <StyledNestedItemContainer>
              {Object.entries(value).map(([key, value]: [string, any]) => (
                <OverviewLayoutDetailsItem
                  isFullWidthItems={isFullWidthItems}
                  key={`${key}`}
                  label={key}
                  title={value}
                  detailsWidth={detailsWidth}
                  className={className}
                />
              ))}
            </StyledNestedItemContainer>
          </StyledNestedContainer>
        ),
      )}
    </>
  );

  return (
    <Col span={24}>
      <DetailsContainer>
        <>
          {header ? (
            <StyledHeaderContainer>
              {children || null}

              <StyledHeaderContent>
                {typeof header === 'string' ? (
                  <NestedTitleTextFormat bold style={{fontSize: 25}}>
                    {textToUpperCase(header)}
                  </NestedTitleTextFormat>
                ) : (
                  header
                )}
              </StyledHeaderContent>
            </StyledHeaderContainer>
          ) : null}

          {topTabs?.length
            ? topTabs.map(
                ({
                  key,
                  title,
                  description,
                  navigation,
                  renderer,
                  type,
                  defaultActiveKey,
                  buttonIcon,
                  stopPropagation,
                  extraHeader,
                }) => (
                  <React.Fragment key={key}>
                    <StyledCollapse
                      $topType={type}
                      defaultActiveKey={defaultActiveKey}
                      accordion
                      bordered={false}
                      expandIconPosition="end"
                      onChange={navigation}
                      expandIcon={buttonIcon ? () => buttonIcon : undefined}>
                      <StyledTabsPanel
                        header={
                          <StyledHeaderWrapper
                            onClick={(e) => {
                              const isStopPropagation =
                                e?.currentTarget?.dataset?.stopPropagation;

                              if (isStopPropagation === 'true') {
                                e.stopPropagation();
                              }
                            }}
                            data-stop-propagation={stopPropagation}>
                            <StyledTabsContainer>
                              <NestedTitleTextFormat bold $type={type}>
                                {textToUpperCase(t(title))}
                              </NestedTitleTextFormat>
                              {typeof description === 'string' ? (
                                <StyledDescription>
                                  {description}
                                </StyledDescription>
                              ) : React.isValidElement(description) ? (
                                description
                              ) : typeof description === 'object' ? (
                                <StyledDescription
                                  $color={(description as any)?.color}>
                                  {(description as any)?.text}
                                </StyledDescription>
                              ) : null}
                            </StyledTabsContainer>
                            {extraHeader || null}
                          </StyledHeaderWrapper>
                        }
                        key={key}
                        $isShowContent={
                          isFunction(renderer) && !isFunction(navigation)
                        }>
                        {isFunction(renderer) && !isFunction(navigation)
                          ? renderer()
                          : null}
                      </StyledTabsPanel>
                    </StyledCollapse>
                  </React.Fragment>
                ),
              )
            : null}
          {data ? (
            dataWithoutTab ? (
              <StyledDataContainer ref={detailsRef}>
                {renderData(data)}
              </StyledDataContainer>
            ) : (
              <StyledCollapse
                ref={detailsRef}
                accordion
                bordered={false}
                defaultActiveKey={
                  defaultActiveKey || (topTabs?.length ? 'key1' : 'key1')
                }
                expandIconPosition="end">
                <StyledPanel
                  header={
                    <NestedTitleTextFormat bold>
                      {textToUpperCase(t('Details'))}
                    </NestedTitleTextFormat>
                  }
                  key="key1">
                  {renderData(data)}
                  {dataContentExtra || null}
                </StyledPanel>
              </StyledCollapse>
            )
          ) : null}

          {tabs.map(
            ({
              key,
              title,
              navigation,
              renderer,
              type,
              description,
              defaultActiveKey,
              bold = true,
              buttonIcon,
              aclItem,
              disabled,
              extraHeader,
              stopPropagation = false,
            }) => {
              const {read} = aclItem
                ? acl[aclItem]
                : AclMapper.toAclModel({} as AclDTO);

              return (
                <StyledCollapse
                  key={key}
                  ghost
                  accordion
                  bordered={false}
                  expandIcon={
                    read
                      ? buttonIcon
                        ? isFunction(buttonIcon)
                          ? buttonIcon
                          : () => buttonIcon
                        : isFunction(renderer) && !isFunction(navigation)
                        ? undefined
                        : () => <RightOutlined />
                      : () => <StyledLockOutlined />
                  }
                  expandIconPosition="end"
                  defaultActiveKey={defaultActiveKey}
                  $type={type}
                  onChange={navigation}>
                  <StyledTabsPanel
                    collapsible={!read || disabled ? 'disabled' : undefined}
                    header={
                      <StyledHeaderWrapper
                        onClick={(e) => {
                          const isStopPropagation =
                            e?.currentTarget?.dataset?.stopPropagation;

                          if (isStopPropagation === 'true') {
                            e.stopPropagation();
                          }
                        }}
                        data-stop-propagation={stopPropagation}>
                        <StyledTabsContainer>
                          <NestedTitleTextFormat
                            bold={bold}
                            $type={type}
                            disabled={!read || disabled}>
                            {textToUpperCase(t(title))}
                          </NestedTitleTextFormat>
                          {typeof description === 'string' ? (
                            <StyledDescription>{description}</StyledDescription>
                          ) : typeof description === 'object' ? (
                            <StyledDescription
                              $color={(description as any)?.color}>
                              {(description as any)?.text}
                            </StyledDescription>
                          ) : null}
                        </StyledTabsContainer>
                        {extraHeader || null}
                      </StyledHeaderWrapper>
                    }
                    key={key}
                    $isShowContent={
                      isFunction(renderer) && !isFunction(navigation)
                    }>
                    <StyledHiddenContainer $hidden={!!disabled}>
                      {isFunction(renderer) && !isFunction(navigation)
                        ? renderer({detailsWidth})
                        : null}
                    </StyledHiddenContainer>
                  </StyledTabsPanel>
                </StyledCollapse>
              );
            },
          )}
        </>
      </DetailsContainer>
      {footerContentExtra || null}
    </Col>
  );
}
