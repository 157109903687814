import React, {useMemo} from 'react';
import {correctPrice} from '../../../../services/helpers';
import {StyledDescription} from '../../../../components/lib/Styled';
import styled, {useTheme, css} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useStoredCompanies} from '../../../../hooks';
import {
  StoreDocumentModel,
  StorePaymentDocumentDiscountType,
} from '../../../../struture';

export interface StoreRealizationPriceViewProps {
  document: StoreDocumentModel;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const Description = styled(StyledDescription)<{$withDiscount: boolean}>`
  ${({$withDiscount}) =>
    $withDiscount &&
    css`
      text-decoration: line-through;
    `}
`;

export function StoreRealizationPriceView({
  document,
}: StoreRealizationPriceViewProps): React.JSX.Element {
  const {t} = useTranslation();
  const theme: any = useTheme();
  const {defaultCompany} = useStoredCompanies();

  const extraServicesSumTotal = useMemo(
    () =>
      (document?.doc_extra_services || [])?.reduce((acc, curr) => {
        acc += Number(curr?.price || 0);

        return acc;
      }, 0),
    [document?.doc_extra_services],
  );

  const sumTotal = Number(document?.doc_sum_total || 0) - extraServicesSumTotal;

  const discountTotal =
    document?.doc_discount_type === StorePaymentDocumentDiscountType.FIXED
      ? sumTotal - (sumTotal - (Number(document?.doc_discount_value) || 0))
      : document?.doc_discount_type ===
        StorePaymentDocumentDiscountType.PERCENTAGE
      ? sumTotal * ((Number(document?.doc_discount_value) || 0) / 100)
      : 0;

  const sumTotalWithDiscount = sumTotal - discountTotal;

  const isShowWarningDescription = sumTotalWithDiscount > 0;

  const discountType =
    document?.doc_discount_type === StorePaymentDocumentDiscountType.PERCENTAGE
      ? '%'
      : defaultCompany?.currency_symbol || '';

  const discount =
    document?.doc_discount_type === StorePaymentDocumentDiscountType.PERCENTAGE
      ? document?.doc_discount_value
      : correctPrice(document?.doc_discount_value || 0);

  const isShowDiscount = Number(document?.doc_discount_value || 0) > 0;

  return (
    <StyledContainer>
      <StyledDescription
        $color={
          isShowWarningDescription ? theme.colors.black : theme.colors.warning
        }>
        {isShowWarningDescription
          ? `${t('In the amount of')}, ${
              defaultCompany?.currency_symbol || ''
            }:`
          : t('There are no products')}
      </StyledDescription>

      {isShowWarningDescription ? (
        <Description
          $withDiscount={isShowWarningDescription && isShowDiscount}
          $color={
            isShowWarningDescription ? theme.colors.black : theme.colors.warning
          }>
          {` ${correctPrice(sumTotal)}`}
        </Description>
      ) : null}

      {isShowDiscount && isShowWarningDescription ? (
        <StyledDescription
          $color={
            isShowWarningDescription ? theme.colors.black : theme.colors.warning
          }>
          {`${sumTotalWithDiscount.toFixed(2)} ${
            isShowDiscount ? `(-${discount}${discountType})` : ''
          }`}
        </StyledDescription>
      ) : null}
    </StyledContainer>
  );
}
