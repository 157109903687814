import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {
  CommentField,
  DetailsView,
  HeaderLeft,
  IDetailsViewItemProps,
} from '../../../../components/lib/DataDisplay';
import {
  useStateStoreDocumentItemList,
  useStopLoading,
  useStoredCompanies,
  useStoredStoreDocument,
  useStoredStoreDocumentList,
} from '../../../../hooks';
import {OverviewLayout} from '../../../../components/lib/Layout';
import {StorePreorderTabPanel} from '../Show';
import {useBreadcrumb} from '../../../../components/lib/libV2/hooks';
import {Routes, RoutesAcl} from '../../../../services/types';
import {
  StoreDocumentMapper,
  StoreDocumentStatus,
  StoreDocumentType,
} from '../../../../struture';
import {useAcl} from '../../../../contex';
import {useLocation, useNavigate, useParams} from 'react-router';
import {Col, Row} from 'antd';
import {ClientDetailsView} from '../../../Clients';
import {
  StoreDetailsView,
  StoreDocExtraServicesView,
  StoreDocItemsView,
  StoreDocumentBottomView,
  StoreDocumentPageHeader,
} from '../../Show';

import '../../OverviewLayoutDetailsItem.less';
import {productStoreDocumentRoutes} from '../../../Products';
import StorePreorderMenuButton from '../Buttons/StorePreorderMenuButton';

export function StorePreorderPage() {
  const {documentId, productId} = useParams();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const location = useLocation();

  const {defaultCompanyUuid} = useStoredCompanies();
  const {store: storeAccess} = useAcl((acl) => acl);

  const {
    document,
    loading: storeLoading,
    error: storeError,
    handleUpdateStoreDocument,
    handlePaymentStoreDocument,

    handleCreateOrUpdateStoreDocumentItem,
    handleDeleteStoreDocumentItem,
    handlePrintStoreDocument,
    handlePrintStoreDocumentCheck,
    handleUpdateStoreDocumentComment,
  } = useStoredStoreDocument({
    documentUuid: documentId!,
    companyUuid: defaultCompanyUuid,
    documentType: StoreDocumentType.PREORDER,
  });

  const {
    storeDocumentItemList,
    loading: documentItemsLoading,
    refresh: storeDocumentItemListRefresh,
    limit: storeDocumentItemListLimit,
    total: storeDocumentItemListTotal,
    page: storeDocumentItemListPage,
  } = useStateStoreDocumentItemList({
    documentUuid: documentId!,
    limit: 100,
  });

  const loading = useStopLoading({
    loading: storeLoading,
    error: storeError,
    message:
      'An error occurred during invoice for order to the provider loading',
  });

  const {handleDeleteStoreDocuments: onDeleteStoreDocuments} =
    useStoredStoreDocumentList({
      companyUuid: defaultCompanyUuid,
      documentType: StoreDocumentType.PREORDER,
      loadOnInit: false,
    });

  const navigateGoBack = useCallback(() => {
    navigate(
      location?.state?.goBack ||
        `/${Routes.app}/${Routes.stores}/${Routes.storeOrderProvider}`,
    );
  }, [location?.state?.goBack, navigate]);

  const handleDeleteStoreDocuments = useCallback(async () => {
    await onDeleteStoreDocuments([documentId!], navigateGoBack);
  }, [navigateGoBack, onDeleteStoreDocuments, documentId]);

  const routes = useBreadcrumb(
    productId
      ? productStoreDocumentRoutes(productId, (startUrl: string) => ({
          path: `${startUrl}/${Routes.stores}/${Routes.storeOrderProvider}/${documentId}`,
          breadcrumbName: document?.doc_local_number || 'Show document',
        }))
      : [
          {
            path: `/${Routes.stores}/${Routes.storeOrderProvider}`,
            breadcrumbName: 'Invoices for order to the provider',
          },
          {
            path: `/${Routes.stores}/${Routes.storeOrderProvider}/${documentId}`,
            breadcrumbName: document?.doc_local_number || 'Show document',
          },
        ],
  );

  const detailViewItems: IDetailsViewItemProps[] = [
    {
      title: 'Provider',
      className: 'client-details__view',
    },
    {
      title: 'Store',
      className: 'store-details__view',
    },
  ];

  return (
    <StorePreorderTabPanel document={document}>
      {({tabs}) => (
        <OverviewLayout
          className="overview-details-item-store"
          aclItem={RoutesAcl[Routes.stores]}
          headerTitle={t('Invoice for order to the provider')}
          headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
          headerRight={
            document?.doc_status_text === StoreDocumentStatus.DRAFT ? (
              <StorePreorderMenuButton
                fromList={false}
                document={StoreDocumentMapper.toStoreDocumentFormDTO(
                  document!,
                  {
                    editMode: true,
                  },
                )}
                onSuccess={handleUpdateStoreDocument}
                handlePaymentStoreDocument={handlePaymentStoreDocument}
                handleDeleteStoreDocuments={handleDeleteStoreDocuments}
              />
            ) : null
          }
          underHeaderContent={
            document?.uuid ? (
              <StoreDocumentPageHeader document={document} />
            ) : null
          }
          header={
            <Row gutter={10}>
              <Col span={24}>
                <DetailsView items={detailViewItems}>
                  {document?.clientModel?.uuid ? (
                    <ClientDetailsView client={document?.clientModel} />
                  ) : null}
                  {document?.companyStoreModel?.uuid ? (
                    <StoreDetailsView store={document?.companyStoreModel} />
                  ) : null}
                </DetailsView>
              </Col>
              <Col span={24}>
                <CommentField
                  comment={document?.doc_comment || t('Please enter note')}
                  handleUpdateComment={handleUpdateStoreDocumentComment}
                  disabled={!storeAccess?.manage}
                />
              </Col>
              <Col span={24}>
                <StoreDocItemsView
                  withEditablePrice
                  document={document!}
                  handleCreateOrUpdateStoreDocumentItem={
                    handleCreateOrUpdateStoreDocumentItem
                  }
                  handleDeleteStoreDocumentItem={handleDeleteStoreDocumentItem}
                  loading={documentItemsLoading}
                  storeDocumentItemList={storeDocumentItemList}
                  storeDocumentItemListRefresh={storeDocumentItemListRefresh}
                  storeDocumentItemListLimit={storeDocumentItemListLimit}
                  storeDocumentItemListTotal={storeDocumentItemListTotal}
                  storeDocumentItemListPage={storeDocumentItemListPage}
                />
              </Col>
              {Array.isArray(document?.doc_extra_services) ? (
                <Col span={24}>
                  <StoreDocExtraServicesView document={document!} />
                </Col>
              ) : null}
            </Row>
          }
          outsideFooterContent={
            <StoreDocumentBottomView
              document={document}
              handlePrintStoreDocument={handlePrintStoreDocument}
              handlePaymentStoreDocument={() => {}}
              handlePrintStoreDocumentCheck={handlePrintStoreDocumentCheck}
            />
          }
          disabled={!storeAccess?.manage}
          editTooltip={t('Edit invoice for order to the provider')}
          loading={loading}
          data={null}
          routes={routes}
          tabs={tabs}
        />
      )}
    </StorePreorderTabPanel>
  );
}
