import React from 'react';
import {useTranslation} from 'react-i18next';
import PaymentsForm, {IPaymentsFormProps} from '../Forms/PaymentsForm';
import {SideWindow} from '../../../components/lib/Navigation';
import {FinancialSettingsDTO} from '../../../struture';

export interface IPaymentsSideWindowProps extends IPaymentsFormProps {
  onSuccess: (value: Partial<FinancialSettingsDTO>) => Promise<void>;
  visible: boolean;
}

export default function PaymentsSideWindow({
  onSuccess,
  visible,
  onCancel,
  ...rest
}: IPaymentsSideWindowProps) {
  const {t} = useTranslation();

  return (
    <SideWindow
      title={t('Finances')}
      visible={visible}
      onClose={onCancel}
      data-testid="payments-form-side-window">
      <PaymentsForm onSuccess={onSuccess} onCancel={onCancel} {...rest} />
    </SideWindow>
  );
}
