import {List} from 'immutable';
import {
  AbonementDTO,
  AbonementFormDTO,
  IAbonementDTOProps,
  AbonementModel,
  AbonementListModel,
} from '../internal';
import {listToArray} from '../../services/helpers';
import {WithoutRecursion} from '../type';

export interface IAbonementListDTO {
  abonements: AbonementDTO[];
  total: number;
}

export class AbonementMapper {
  public static toAbonementDTO(
    abonement: AbonementModel | IAbonementDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): AbonementDTO {
    return new AbonementDTO(abonement, [
      WithoutRecursion.abonement,
      ...withoutRecursion,
    ]);
  }

  public static toAbonementListDTO(
    abonements: List<AbonementModel> | IAbonementDTOProps[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): IAbonementListDTO {
    const abonementList = List.isList(abonements)
      ? listToArray<AbonementModel>(abonements)
      : abonements;

    return {
      abonements: abonementList?.map((abonement) =>
        AbonementMapper.toAbonementDTO(abonement, [
          WithoutRecursion.abonement,
          ...withoutRecursion,
        ]),
      ),
      total,
    };
  }

  public static toAbonementModel(
    abonementDTO: AbonementDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): AbonementModel {
    return new AbonementModel(abonementDTO, [
      WithoutRecursion.abonement,
      ...withoutRecursion,
    ]);
  }

  public static toAbonementListModel(
    abonementDTOs: AbonementDTO[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): AbonementListModel {
    return new AbonementListModel({abonements: abonementDTOs, total}, [
      WithoutRecursion.abonement,
      ...withoutRecursion,
    ]);
  }

  public static toAbonementFormDTO(
    abonementModel: AbonementModel,
    editMode?: boolean,
  ): AbonementFormDTO {
    return new AbonementFormDTO(abonementModel, editMode);
  }
}
