import {
  IAuthorizeUserDTOProps,
  AuthorizeUserDTO,
  AuthorizeUserMapper,
} from '../internal';
import {ErrorsStatus} from '../type';

export interface IAuthDataDTOProps {
  code: ErrorsStatus;
  success: boolean;
  user: IAuthorizeUserDTOProps;
  invite_id?: string;
  access_token: string;
  refresh_token: string;
  verification_code?: string;
}

export class AuthDataDTO {
  public code: ErrorsStatus;

  public success: boolean;

  public user: AuthorizeUserDTO;

  public verification_code: string;

  public access_token: string;

  public refresh_token: string;

  public invite_id: string;

  constructor(props: IAuthDataDTOProps) {
    this.code = props?.code || ErrorsStatus.Unauthorized;
    this.success = props?.success || false;
    this.user = AuthorizeUserMapper.toAuthorizeUserDTO(
      props?.user || ({} as any),
    );
    this.verification_code = props?.verification_code || '';
    this.access_token = props?.access_token || '';
    this.refresh_token = props?.refresh_token || '';
    this.invite_id = props?.invite_id || '';
  }
}
