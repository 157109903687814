import React from 'react';
import {Tooltip} from 'antd';
import styled, {css, useTheme} from 'styled-components';
import {StyledTitle, StyledDescription} from '../../../components/lib/Styled';
import {correctPrice} from '../../../services/helpers';
import {EmployeeModel} from '../../../struture';
import {InfoCircleOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {useStoredCompanies} from '../../../hooks';

export interface IEmployeeHeaderProps {
  employee: EmployeeModel | null;
  extra?: React.ReactNode | React.ReactNode[];
  className?: string;
}

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

const StyledEmployeeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const StyledEmployeeBalanceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)<{$color: string}>`
  color: ${({$color}) => $color};
`;

const StyledButtonContainer = styled.div<{$isVisible: boolean}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;

  ${({$isVisible}) =>
    !$isVisible &&
    css`
      visibility: hidden;
    `}
`;

export function EmployeeHeader({
  extra,
  className,
  employee,
}: IEmployeeHeaderProps): JSX.Element {
  const theme: any = useTheme();
  const {t} = useTranslation();
  const {defaultCompany} = useStoredCompanies();

  const balance = employee?.balance?.startsWith('-')
    ? employee?.balance.slice(1)
    : employee?.balance;

  return (
    <StyledButtonContainer $isVisible={!!employee}>
      <StyledWrapper className={className}>
        <StyledContainer className={className}>
          <StyledEmployeeContainer>
            <StyledTitle fontSize={20} bold>
              {employee?.fullName}
            </StyledTitle>
            <StyledEmployeeBalanceContainer>
              <StyledDescription
                fontSize={15}
                $color={
                  Number(employee?.balance) === 0
                    ? theme.colors.black
                    : Number(employee?.balance) >= 0
                    ? theme.colors.error
                    : theme.colors.success
                }>
                {`${t('Balance')}(${
                  defaultCompany?.currency_symbol || 0
                }): ${correctPrice(balance)}`}
              </StyledDescription>
              {Number(employee?.balance) > 0 ||
              Number(employee?.balance) < 0 ? (
                <Tooltip
                  title={
                    Number(employee?.balance) === 0
                      ? undefined
                      : Number(employee?.balance) > 0
                      ? t('We are guilty')
                      : t('We are to blame')
                  }>
                  <StyledInfoCircleOutlined
                    $color={
                      Number(employee?.balance) === 0
                        ? theme.colors.black
                        : Number(employee?.balance) > 0
                        ? theme.colors.error
                        : theme.colors.success
                    }
                  />
                </Tooltip>
              ) : null}
            </StyledEmployeeBalanceContainer>
            {employee?.phone ? (
              <StyledTitle>{t(employee?.phone || '')}</StyledTitle>
            ) : null}
          </StyledEmployeeContainer>
        </StyledContainer>
        {extra || null}
      </StyledWrapper>
    </StyledButtonContainer>
  );
}
