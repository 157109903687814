import {Record} from 'immutable';

export interface INotificationProps {
  uuid: string;
  date: string;
  message: string;
  is_read: number;
  created_at: string;
  updated_at: string;
}

/**
 * @class Client
 * @desc Клієнт
 */
export class Notification extends Record({
  uuid: '',
  date: '',
  message: '',
  is_read: 0,
  created_at: '',
  updated_at: '',
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(props: INotificationProps = {} as INotificationProps) {
    super({...props});
  }
}
