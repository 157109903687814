import React from 'react';
import {useTranslation} from 'react-i18next';
import {IConfirmActionButtonProps} from '../../../components/lib/General';
import {TextFormat} from '../../../components/lib/Format';
import {len, eq} from '../../../services/helpers';
import {InvoiceModel} from '../../../struture';
import {useDelete} from '../../../components/lib/libV2/hooks';
import {DeleteButton} from '../../../components/lib/DataDisplay';

export interface IInvoiceDeleteButtonProps
  extends Omit<IConfirmActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: string[]) => Promise<void>;
  onError?: () => void;
  invoices: InvoiceModel[];
  title?: string;
}

export function InvoiceDeleteButton({
  children,
  invoices = [],
  onSuccess,
  onError,
  title,
  ...rest
}: IInvoiceDeleteButtonProps) {
  const {t} = useTranslation();

  const {handleDelete, loading} = useDelete({
    onSuccess,
    entity: invoices,
    notifyTitle: 'Bills',
  });

  return (
    <DeleteButton
      title={title}
      tooltipTitle={t('Delete invoices')}
      confirmTitle={
        <TextFormat breakWord>
          {t('Are you sure you want to delete')} <b>{len(invoices)}</b>{' '}
          {eq(len(invoices), 1) ? t('tinvoice') : t('tinvoices')}?
        </TextFormat>
      }
      onConfirm={handleDelete}
      loading={loading}
      cancelButtonText={t('Cancel')}
      {...rest}
    />
  );
}
