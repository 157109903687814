import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Empty} from 'antd';
import {ListLayout} from '../../../components/lib/Layout';
import {
  EmployeeCreateButton,
  EmployeeEditButton,
  EmployeesDeleteButton,
  EmployeeAcceptInviteButton,
  EmployeeCancelInviteButton,
} from '../Buttons';
import {
  useStoreEmployee,
  useStateEmployeeList,
  useStopLoading,
  useStoredCompanies,
  useKeyboardOpenForm,
  useSearchInputFocus,
} from '../../../hooks';
import {textToUpperCase} from '../../../services/helpers';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import {
  Table,
  TableActionCell,
  TableLinkCell,
} from '../../../components/lib/libV2/DataDisplay';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '../../../services/types';
import {
  EmployeeMapper,
  EmployeeModel,
  EmployeeStatuses,
} from '../../../struture';
import {useAcl} from '../../../contex';
import {HeaderLeft, ListActions} from '../../../components/lib/DataDisplay';
import {
  StyledDescription,
  successButtonStyle,
} from '../../../components/lib/Styled';
import {useNavigate} from 'react-router';
import styled from 'styled-components';
import {EmployeeRoleTag} from '../Show';
import {PlusOutlined} from '@ant-design/icons';

import './EmployeeList.less';

const StyledEmployeeCreateButton = styled(EmployeeCreateButton)`
  ${successButtonStyle}
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledDescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
`;

const StyledEmployeeRoleTag = styled(EmployeeRoleTag)`
  height: 17px;
  line-height: 1.3;
`;

export default function EmployeeListPage(): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [selectedEmployees, setSelectedEmployees] = useState<EmployeeModel[]>(
    [],
  );

  const {defaultCompanyUuid} = useStoredCompanies();
  const {manage} = useAcl(({employee}) => employee);

  const {ref: listRef} = useKeyboardOpenForm({
    className: 'employee-create-event',
    disabled: !manage,
  });

  const {
    employees,
    loading: loadingEmployees,
    error: errorEmployees,
    refresh,
    total,
    limit: pageSize,
    handleCreateEmployee,
    handleUpdateEmployee,
    handleDeleteEmployees: onDeleteEmployees,
    handleUpdate,
    handleSearchEmployees,
  } = useStateEmployeeList({companyUuid: defaultCompanyUuid});

  const {handleCancelEmployeeInvite, handleAcceptEmployeeInvite} =
    useStoreEmployee({employeeUuid: '', companyUuid: '', loadOnInit: false});

  const loading = useStopLoading({
    loading: loadingEmployees,
    error: errorEmployees,
    message: 'An error occurred during employees loading',
  });

  const {inputRef, focus, daleyFocus} = useSearchInputFocus({loading});

  const handleDeleteEmployees = useCallback(
    async (value: string[]) => {
      await onDeleteEmployees(value);
      focus();
    },
    [focus, onDeleteEmployees],
  );

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: selectedEmployees.map(({uuid}) => uuid),
      onChange: (
        selectedRowKeys: React.Key[],
        selectedRecords: EmployeeModel[],
      ) => {
        setSelectedEmployees(selectedRecords);
      },
    }),
    [selectedEmployees],
  );

  const routes = useBreadcrumb([
    {
      path: `/${Routes.employees}`,
      breadcrumbName: 'Employees',
    },
  ]);

  const columns = [
    {
      key: 'Employee',
      title: t('Employee'),
      width: '80%',
      className: 'employee-list-td employee-list--title',
      render: (employee: EmployeeModel) => (
        <StyledTitleContainer>
          <TableLinkCell
            tooltip="Open a detailed description of the employee"
            path={`${employee?.uuid}`}>
            {employee.fullName}
          </TableLinkCell>
          <StyledDescriptionContainer>
            {employee?.role_permissions?.length ? (
              employee?.role_permissions?.map(({role}) => (
                <StyledEmployeeRoleTag
                  disabled={employee?.status === EmployeeStatuses.DELETED}
                  key={role}
                  value={role}
                  label={t(role)}
                />
              ))
            ) : (
              <StyledEmployeeRoleTag
                disabled={employee?.status === EmployeeStatuses.DELETED}
                value={employee?.role!}
                label={t(employee?.role)}
              />
            )}
            <StyledDescription>{employee?.title}</StyledDescription>
          </StyledDescriptionContainer>
        </StyledTitleContainer>
      ),
    },
    {
      title: t('Phone'),
      key: 'phone',
      className: 'employee-list-td',
      render: (employee: EmployeeModel) => employee?.phone,
    },
    {
      align: 'center' as any,
      fixed: 'right' as any,
      title: '',
      key: 'actions',
      className: 'employee-list-td employee-list-indent employee-list',
      render: (employee: EmployeeModel) => (
        <TableActionCell className="employee-list--hidden">
          {employee?.status === EmployeeStatuses.WaitingForConfirmation ? (
            <>
              <EmployeeAcceptInviteButton
                size="small"
                className="employee-list--delete"
                tooltipTitle={t('Confirm the request')}
                disabled={!manage}
                employee={employee}
                handleAcceptEmployeeInvite={handleAcceptEmployeeInvite}
                handleUpdate={handleUpdate}
              />
              <EmployeeCancelInviteButton
                size="small"
                className="employee-list--delete"
                tooltipTitle={t('Reject the request')}
                disabled={!manage}
                employee={employee}
                handleCancelEmployeeInvite={handleCancelEmployeeInvite}
                handleUpdate={handleUpdate}
              />
            </>
          ) : (
            <>
              <EmployeeEditButton
                size="small"
                disabled={!manage}
                employee={EmployeeMapper.toEmployeeFormDTO(employee)}
                onSuccess={handleUpdateEmployee}
                onCancel={daleyFocus}
              />

              <EmployeesDeleteButton
                size="small"
                className="employee-list--delete"
                disabled={!manage}
                employees={[employee]}
                onSuccess={handleDeleteEmployees}
              />
            </>
          )}
        </TableActionCell>
      ),
    },
  ];

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes?.companies}/${defaultCompanyUuid}`);
  }, [defaultCompanyUuid, navigate]);

  return (
    <ListLayout
      ref={listRef}
      aclItem={RoutesAcl[Routes.employees]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.employees]))}
      headerRight={
        <StyledEmployeeCreateButton
          type="primary"
          tooltipTitle={t('Create employee')}
          disabled={!manage}
          onSuccess={handleCreateEmployee}
          loading={loading}
          title={t('Create')}
          icon={<PlusOutlined />}
          className="employee-create-event"
          onCancel={daleyFocus}
        />
      }
      routes={routes}
      empty={
        employees?.size ? null : (
          <Empty
            description={t(
              `It looks like you don't have any employee at the moment.`,
            )}>
            <EmployeeCreateButton
              type="primary"
              disabled={!manage}
              onSuccess={handleCreateEmployee}
              loading={loading}
              title={t('Add Employee')}
              onCancel={daleyFocus}
            />
          </Empty>
        )
      }
      headerExtra={
        <ListActions
          inputRef={inputRef}
          withoutPicker={true}
          loading={loading}
          handleSearch={(keywords) => handleSearchEmployees({keywords})}
          inputTooltip={t('Search employees')}
          inputLabel={t('Search employees')}
        />
      }
      loading={loading && !employees?.size}>
      <Table<EmployeeModel>
        rowSelection={rowSelection}
        total={total}
        pageSize={pageSize}
        dataSource={employees}
        onChange={refresh}
        columns={columns}
        loading={loading}
        onRow={() => ({
          className: 'employee-list--row',
        })}
      />
    </ListLayout>
  );
}
