import {apiDelete, apiGet, apiPost, apiPut} from '../core/api';
import {head} from '../helpers';
import {ApiAnswer, IListSearchProps} from '../types';
import {
  PriceMarginDTO,
  PriceMarginFormDTO,
  PriceMarginMapper,
  IPriceMarginDTOProps,
  IPriceMarginListDTO,
} from '../../struture';
import {contentApiUrl} from '../const';

export const PRICE_MARGIN_INITIAL_PARAM = new PriceMarginFormDTO();

const {
  uuid,
  created_at,
  updated_at,
  created_by,
  company,
  price_is_default,
  price_name,
  ...PRICE_MARGIN_INITIAL_PARAM_REST
} = new PriceMarginDTO({} as IPriceMarginDTOProps);

export const PRICE_MARGIN_REQUIRED_FIELD = {
  ...PRICE_MARGIN_INITIAL_PARAM_REST,
};

export interface IPriceMarginAnswer extends ApiAnswer {
  price: IPriceMarginDTOProps;
}

export interface IPriceMarginListAnswer extends ApiAnswer {
  prices: IPriceMarginDTOProps[];
}

export interface IPriceMarginListAnswer extends ApiAnswer, IListSearchProps {
  prices: IPriceMarginDTOProps[];
}

export interface IPriceMarginListProps extends IListSearchProps {
  companyUuid: string;
}

export async function getPriceMarginList({
  offset = 0,
  limit = 10,
  keywords,
  companyUuid,
}: IPriceMarginListProps): Promise<IPriceMarginListDTO> {
  const {prices, total} = await apiGet<
    IListSearchProps,
    IPriceMarginListAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/price-margins`, {
    offset,
    limit,
    keywords,
  });

  return PriceMarginMapper.toPriceMarginListDTO(prices, total || 0);
}

export async function getPriceMarginById(
  priceUuid: string,
): Promise<PriceMarginDTO> {
  const {price} = await apiGet<null, IPriceMarginAnswer>(
    `${contentApiUrl}/price-margins/${priceUuid}`,
  );

  return PriceMarginMapper.toPriceMarginDTO(price);
}

export async function createPriceMargin(
  {
    price_margin,
    price_description,
    price_name,
    price_default,
  }: PriceMarginFormDTO,
  companyUuid: string,
): Promise<PriceMarginDTO> {
  const {price} = await apiPost<
    Omit<PriceMarginFormDTO, 'uuid'>,
    IPriceMarginAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/price-margins`, {
    price_margin,
    price_description,
    price_name,
    price_default,
  });

  return PriceMarginMapper.toPriceMarginDTO(price);
}

export async function editPriceMargin({
  uuid: price_uuid,
  price_margin,
  price_description,
  price_name,
  price_default,
}: Omit<PriceMarginFormDTO, 'getTimingList'>): Promise<PriceMarginDTO> {
  const {price} = await apiPut<
    Omit<PriceMarginFormDTO, 'uuid'>,
    IPriceMarginAnswer
  >(`${contentApiUrl}/price-margins/${price_uuid}`, {
    price_margin,
    price_description,
    price_name,
    price_default,
  });

  return PriceMarginMapper.toPriceMarginDTO(price);
}

export async function deletePriceMargin(
  ids: string[] = [],
): Promise<IPriceMarginListDTO> {
  const {prices} = await apiDelete<{ids: string[]}, IPriceMarginListAnswer>(
    `${contentApiUrl}/price-margins/${head(ids)}`,
  );

  return PriceMarginMapper.toPriceMarginListDTO(prices, 0);
}
