import {Record} from 'immutable';
import {
  AbonementDTO,
  AbonementGroupDTO,
  AbonementGroupMapper,
  AbonementGroupModel,
  AbonementMapper,
  AbonementModel,
  AbonementStatuses,
  ClientAbonementDTO,
  ClientAbonementScheduleStatusesText,
  ClientAbonementStatusesText,
  ClientDTO,
  ClientMapper,
  ClientModel,
  EmployeeDTO,
  EmployeeMapper,
  EmployeeModel,
  IAbonementTimingList,
  ProfileDTO,
  ProfileMapper,
  ProfileModel,
} from '../internal';
import {WithoutRecursion} from '../type';
import {
  getDiffDaysFromNow,
  getDiffHoursFromNow,
  getDiffMinutesFromNow,
  isDateLessThanNow,
} from '../../services/helpers';
import {clientSubscriptionExpiredDays} from '../../services/const';

export interface IClientAbonementModelReturnType {
  uuid: string;
  abon_title: string;
  abon_card_number: string;
  abon_start_date: string;
  abon_end_date: string;
  abon_final_price: string;
  abon_discount: number;
  abon_send_notification: boolean;
  abon_comment: string;
  abon_schedule_end_date: string;
  abon_schedule_start_date: string;
  abon_schedule_comment: string;
  abon_schedule_list: IAbonementTimingList;
  abon_status: AbonementStatuses;
  abon_status_text: ClientAbonementStatusesText;
  abon_schedule_status_text: ClientAbonementScheduleStatusesText;
  created_at: string;
  updated_at: string;
  client: ClientModel | ClientDTO;
  company_abonement: AbonementModel | AbonementDTO;
  company_abonement_group: AbonementGroupModel | AbonementGroupDTO;
  manager: EmployeeModel | EmployeeDTO;
  created_by: ProfileModel | ProfileDTO;
}

export class ClientAbonementModel extends Record<IClientAbonementModelReturnType>(
  {
    uuid: '',
    abon_title: '',
    abon_card_number: '',
    abon_start_date: '',
    abon_end_date: '',
    abon_final_price: '0.00',
    abon_discount: 0,
    abon_send_notification: false,
    abon_comment: '',
    abon_status: AbonementStatuses.Disabled,
    abon_status_text: ClientAbonementStatusesText.Initiated,
    abon_schedule_status_text: ClientAbonementScheduleStatusesText.Init,
    created_at: '',
    updated_at: '',
    client: {} as ClientModel,
    company_abonement: {} as AbonementModel,
    company_abonement_group: {} as AbonementGroupModel,
    created_by: {} as ProfileModel,
    manager: {} as EmployeeModel,
    abon_schedule_end_date: '',
    abon_schedule_start_date: '',
    abon_schedule_comment: '',
    abon_schedule_list: {},
  },
) {
  constructor(
    props: ClientAbonementDTO = {} as ClientAbonementDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IClientAbonementModelReturnType = {
      ...props,

      client:
        withoutRecursion.indexOf(WithoutRecursion.client) !== -1
          ? props?.client
          : ClientMapper.toClientModel(props?.client, false, [
              WithoutRecursion.clientAbonement,
              ...withoutRecursion,
            ]),

      company_abonement:
        withoutRecursion.indexOf(WithoutRecursion.abonement) !== -1
          ? props?.company_abonement
          : AbonementMapper.toAbonementModel(props?.company_abonement, [
              WithoutRecursion.clientAbonement,
              ...withoutRecursion,
            ]),

      company_abonement_group:
        withoutRecursion.indexOf(WithoutRecursion.abonement) !== -1
          ? props?.company_abonement_group
          : AbonementGroupMapper.toAbonementGroupModel(
              props?.company_abonement_group,
              [WithoutRecursion.clientAbonement, ...withoutRecursion],
            ),

      created_by:
        withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
          ? props?.created_by
          : ProfileMapper.toProfileModel(props?.created_by, [
              WithoutRecursion.clientAbonement,
              ...withoutRecursion,
            ]),

      manager:
        withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
          ? props?.manager
          : EmployeeMapper.toEmployeeModel(props?.manager, [
              WithoutRecursion.abonement,
              ...withoutRecursion,
            ]),
    };

    super(options);
  }

  get clientModel(): ClientModel {
    return this.client instanceof ClientModel
      ? this.client
      : ClientMapper.toClientModel(this.client);
  }

  get companyAbonementModel(): AbonementModel {
    return this.company_abonement instanceof AbonementModel
      ? this.company_abonement
      : AbonementMapper.toAbonementModel(this.company_abonement);
  }
  get companyAbonementGroupModel(): AbonementGroupModel {
    return this.company_abonement_group instanceof AbonementGroupModel
      ? this.company_abonement_group
      : AbonementGroupMapper.toAbonementGroupModel(
          this.company_abonement_group,
        );
  }

  get createdByModel(): ProfileModel {
    return this.created_by instanceof ProfileModel
      ? this.created_by
      : ProfileMapper.toProfileModel(this.created_by);
  }

  get managerModel(): EmployeeModel {
    return this.manager instanceof EmployeeModel
      ? this.manager
      : EmployeeMapper.toEmployeeModel(this.manager);
  }

  get isSubscriptionDateExpired(): boolean {
    return this.abon_end_date !== '' && isDateLessThanNow(this.abon_end_date);
  }

  get subscriptionDiffDays(): number {
    return getDiffDaysFromNow(this.abon_end_date);
  }

  get subscriptionDiffHours(): number {
    return getDiffHoursFromNow(this.abon_end_date);
  }

  get subscriptionDiffMinutes(): number {
    return getDiffMinutesFromNow(this.abon_end_date);
  }

  get isShowSubscriptionExpiredTime(): boolean {
    return (
      !this.isSubscriptionDateExpired &&
      this.subscriptionDiffDays <= Number(clientSubscriptionExpiredDays) &&
      this.subscriptionDiffMinutes >= 0
    );
  }

  get isSubscriptionWillDateExpired(): boolean {
    return (
      !this.isSubscriptionDateExpired &&
      this.abon_end_date !== '' &&
      this.subscriptionDiffDays <= Number(clientSubscriptionExpiredDays) &&
      this.subscriptionDiffMinutes >= 0
    );
  }

  get subscriptionExpiredAt(): (t: any) => string {
    const days = Math.abs(this.subscriptionDiffDays);
    const hours = Math.abs(this.subscriptionDiffHours);
    const minutes = Math.abs(this.subscriptionDiffMinutes);

    return (t) =>
      days > 0
        ? `${days} ${
            days === 1 ? t('day').toLowerCase() : t('days').toLowerCase()
          }`
        : hours > 0
        ? `${hours} ${
            hours === 1 ? t('hour').toLowerCase() : t('hours').toLowerCase()
          }`
        : minutes > 0
        ? `${minutes} ${
            minutes === 1
              ? t('minute').toLowerCase()
              : t('minutes').toLowerCase()
          }`
        : `${t('now').toLowerCase()}`;
  }

  get isShowSubscriptionWarning(): boolean {
    return this.isShowSubscriptionExpiredTime;
  }
}
