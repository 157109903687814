import {Record, List} from 'immutable';
import {ListModel, ListProps} from './List';
import Event, {IEventProps} from './Event';

export interface IEventListProps extends ListProps {
  events: IEventProps[];
}

export default class EventList extends Record({
  events: List(),
  ...ListModel.toJS(),
}) {
  /**
   * @constructor
   * @param {{templates:[]}} param0
   */
  constructor({events = [], ...props}: IEventListProps) {
    super({
      events: List(events.map((event) => new Event(event))),
      ...props,
    });
  }
}
