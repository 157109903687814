import React from 'react';
import {Routes, Route} from 'react-router';
import {
  ProductCategorySubCategoryListPage,
  ProductCategoryPage,
} from '../views/Products';
import {AppRouteLayout} from '../components/lib/Layout';
import {Routes as RoutesTypes} from '../services/types';

export function ProductCategorySubCategoryRoutesNavigator() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AppRouteLayout>
            <ProductCategorySubCategoryListPage />
          </AppRouteLayout>
        }
      />
    </Routes>
  );
}
