import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {IActionButtonProps} from '../../../../components/lib/General';
import {useModal} from '../../../../components/lib/libV2/hooks';
import {AddButton, SuspenseEmpty} from '../../../../components/lib/DataDisplay';
import {StoreDocumentFormDTO} from '../../../../struture';
import {IUseStateStoredDocumentReturnType} from '../../../../hooks';

const StoreMovingSideWindowLazy = React.lazy(
  () => import('../Show/StoreMovingSideWindow'),
);

export interface IStoreMovingCreateButtonProps
  extends Omit<IActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: IUseStateStoredDocumentReturnType['handleCreateStoreDocument'];
  onCancel?: () => void;
  document: StoreDocumentFormDTO;
}

export function StoreMovingCreateButton({
  children,
  onSuccess,
  onCancel,
  loading,
  document,
  ...rest
}: IStoreMovingCreateButtonProps): React.JSX.Element {
  const {t} = useTranslation();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <AddButton
        tooltipTitle={t('Create moving')}
        onClick={handleOnInit}
        loading={loading}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <StoreMovingSideWindowLazy
          document={document}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
          fromList={false}
        />
      </Suspense>
    </>
  );
}
