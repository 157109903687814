import {correctPrice} from '../../services/helpers';

export enum StoreDocumentCurrency {
  USD = 'USD',
  EURO = 'EURO',
}

export interface StoreDocumentMultiCurrencyDTOProps {
  title: string;
  rate: StoreDocumentCurrency | string;
}

export const DEFAULT_UAH_CURRENCY: StoreDocumentMultiCurrencyDTO = {
  title: '₴',
  rate: correctPrice(1),
};

export class StoreDocumentMultiCurrencyDTO {
  public title: string;

  public rate: string;

  constructor(props: StoreDocumentMultiCurrencyDTOProps) {
    this.title = props?.title || '';
    this.rate = correctPrice(props?.rate || 0);
  }
}
