import {Record, List} from 'immutable';
import {IListProps} from './List';
import {SettingDTO, SettingModel} from '../internal';

export interface ISettingListModelProps extends IListProps {
  settings: SettingDTO[];
}

export interface ISettingListModelReturnType extends IListProps {
  settings: List<SettingModel>;
}

export class SettingListModel extends Record<ISettingListModelReturnType>({
  settings: List(),
  total: 0,
}) {
  constructor(
    {settings, ...props}: ISettingListModelProps = {} as ISettingListModelProps,
  ) {
    super({
      ...props,
      settings: List(settings.map((setting) => new SettingModel(setting))),
    });
  }
}
