import { SmileOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import React from 'react';
import { cdnUrl } from '../../const';

export default AppIcon;

AppIcon.ICON_SIZE_XSMALL = 'xsmall';
AppIcon.ICON_SIZE_SMALL = 'small';
AppIcon.ICON_SIZE_MEDIUM = 'medium';
AppIcon.ICON_SIZE_LARGE = 'large';
AppIcon.ICON_SIZE_XLARGE = 'xlarge';

function AppIcon({ icon, className, size = 'small', sizePx, ...rest }) {
  let sizeStr = sizePx ? `${sizePx}px` : '';
  if (!sizeStr) {
    switch (size) {
      case AppIcon.ICON_SIZE_XSMALL:
        sizeStr = '16px';
        break;
      case AppIcon.ICON_SIZE_MEDIUM:
        sizeStr = '24px';
        break;
      case AppIcon.ICON_SIZE_LARGE:
        sizeStr = '32px';
        break;
      case AppIcon.ICON_SIZE_XLARGE:
        sizeStr = '64px';
        break;
      case AppIcon.ICON_SIZE_SMALL:
      default:
        sizeStr = '18px';
        break;
    }
  }

  return (
    <SmileOutlined
      className={classNames('AppIcon', className)}
      component={() =>
        typeof icon === 'string' ? (
          <img
            src={`${cdnUrl}/icon/${icon}.svg`}
            alt={icon}
            width={sizeStr}
            height={sizeStr}
          />
        ) : (
          React.cloneElement(icon, {
            width: sizeStr,
            height: sizeStr,
          })
        )
      }
      {...rest}
    />
  );
}
