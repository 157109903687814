import {Record, List} from 'immutable';
import {IListProps} from './List';
import {ScheduleStatusDTO, ScheduleStatusModel} from '../internal';

export interface IScheduleStatusListModelProps extends IListProps {
  statuses: ScheduleStatusDTO[];
}

export interface IScheduleStatusListModelReturnType extends IListProps {
  statuses: List<ScheduleStatusModel>;
}

export class ScheduleStatusListModel extends Record<IScheduleStatusListModelReturnType>(
  {
    statuses: List(),
    total: 0,
  },
) {
  constructor(
    {
      statuses,
      ...props
    }: IScheduleStatusListModelProps = {} as IScheduleStatusListModelProps,
  ) {
    super({
      ...props,
      statuses: List(statuses.map((status) => new ScheduleStatusModel(status))),
    });
  }
}
