import * as React from 'react';
import {ApiError, IEntityProps, IListSearchProps} from '../services/types';
import {
  StoreDocumentMapper,
  StoreDocumentListModel,
  IStoreDocumentListDTO,
  StoreDocumentModel,
} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {getStoreDocumentRelatedDocumentList} from '../services/api/storeDocument';
import {useDropdownAlert} from '../contex';
import {useTranslation} from 'react-i18next';

export interface IUseStoreDocumentRelatedDocumentListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  documentUuid: string;
}

export interface IUseStoreDocumentRelatedDocumentListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: StoreDocumentListModel | null;
  refresh: (
    value: Partial<IUseStoreDocumentRelatedDocumentListProps>,
  ) => Promise<StoreDocumentListModel | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  documentUuid: string;
  document: StoreDocumentModel | null;
}

export function useStoreDocumentRelatedDocumentList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    documentUuid,
  }: IUseStoreDocumentRelatedDocumentListProps = {} as IUseStoreDocumentRelatedDocumentListProps,
): IUseStoreDocumentRelatedDocumentListReturnType {
  const {cancellablePromise} = useCancellablePromise();
  const {alert} = useDropdownAlert();
  const {t} = useTranslation();

  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    documentUuid: listUuid,
    document: listDocument,
    handleUpdate,
  } = useStateReducer<
    Omit<IUseStoreDocumentRelatedDocumentListReturnType, 'refresh'>
  >({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    documentUuid,
    document: null,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      documentUuid = listUuid,
    }: Partial<IUseStoreDocumentRelatedDocumentListProps> = {}): Promise<StoreDocumentListModel | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          documentUuid,
        });

        const {documents, total, document} =
          await cancellablePromise<IStoreDocumentListDTO>(
            getStoreDocumentRelatedDocumentList({
              limit,
              offset,
              keywords,
              documentUuid,
            }),
          );

        const storeDocumentListModel =
          StoreDocumentMapper.toStoreDocumentListModel(
            documents,
            total,
            [],
            document,
          );

        handleUpdate({
          entityList: storeDocumentListModel,
          total: storeDocumentListModel?.total,
          loading: false,
          document,
        });

        return storeDocumentListModel;
      } catch (e: any) {
        alert(
          'error',
          t('Materials'),
          `${t('An error occurred during get materials list')} : ${e?.message}`,
        );

        handleUpdate({
          error,
          loading: false,
        });
      }
    },
    [
      listLimit,
      listOffset,
      listKeywords,
      listUuid,
      handleUpdate,
      cancellablePromise,
      alert,
      t,
      error,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && documentUuid) {
      (async () => {
        await refresh({documentUuid});
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, documentUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    documentUuid: listUuid,
    document: listDocument,
  };
}
