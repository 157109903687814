import React from 'react';
import styled from 'styled-components';
import {StyledDescription, StyledTitle} from '../../../components/lib/Styled';
import {useTranslation} from 'react-i18next';
import {ClientFileType} from '../../../struture';

export interface IClientFileViewProps {
  icon?: React.ReactNode;
  title?: string;
  view?: ClientFileType;
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const StyledTitleIconContainer = styled(StyledDescription)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & > :first-child {
    margin-right: 5px;
  }
`;

export function ClientFileView({
  title,
  icon,
  view,
  ...rest
}: IClientFileViewProps): React.JSX.Element {
  const {t} = useTranslation();

  return (
    <StyledTitleIconContainer {...rest}>
      {icon ? icon : null}
      <StyledWrapper>
        <StyledTitle fontSize={13}>{t(title)}</StyledTitle>
      </StyledWrapper>
    </StyledTitleIconContainer>
  );
}
