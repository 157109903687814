import {
  EmployeeDTO,
  IEmployeeDTOProps,
  IClientDTOProps,
  ClientDTO,
  IServiceDTOProps,
  ServiceDTO,
  ICompanyDTOProps,
  CompanyDTO,
  IScheduleCalendarModelReturnType,
  ClientMapper,
  CompanyMapper,
  EmployeeMapper,
  ServiceMapper,
  IProfileDTOProps,
  ProfileDTO,
  ProfileMapper,
  IScheduleCalendarParentDTOProps,
  ScheduleCalendarParentDTO,
  StatusMapper,
  IScheduleCalendarLatestStatusDTOProps,
  ScheduleCalendarLatestStatusDTO,
} from '../internal';
import {WithoutRecursion} from '../type';
import {correctPrice} from '../../helpers';
import {correctDate} from '../date';

export enum ScheduleDocumentType {
  PreInvoice = 'pre-invoice',
  ActCompletedWork = 'act-completed-work',
}

export interface IScheduleStats {
  files: string;
}

export enum ScheduleStatusesText {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  CLOSED = 'CLOSED',
  NEW = 'NEW',
}

export interface IScheduleCalendarDTOProps {
  scheduled_date: string;
  status: string;
  uuid: string;
  schedule_title: string;
  updated_at: string;
  created_at: string;
  status_text: ScheduleStatusesText;
  price: string;
  sum_total: string;
  comment: string;
  schedule_number: string;
  status_color: string;
  schedule_expenses_sum: string;
  is_child: boolean;
  schedule_has_strict_mode: boolean;
  company: ICompanyDTOProps;
  employee: IEmployeeDTOProps;
  client: IClientDTOProps;
  service: IServiceDTOProps;
  created_by: IProfileDTOProps;
  parent: IScheduleCalendarParentDTOProps;
  latest_status: IScheduleCalendarLatestStatusDTOProps;
  sum_paid: string;
  stats: IScheduleStats;
}

export class ScheduleCalendarDTO {
  public scheduled_date: string;

  public status: string;

  public schedule_title: string;

  public uuid: string;

  public updated_at: string;

  public created_at: string;

  public status_text: ScheduleStatusesText;

  public price: string;

  public sum_total: string;

  public sum_related: string;

  public schedule_number: string;

  public status_color: string;

  public schedule_expenses_sum: string;

  public comment: string;

  public is_child: boolean;

  public schedule_has_strict_mode: boolean;

  public company: CompanyDTO;

  public employee: EmployeeDTO;

  public client: ClientDTO;

  public service: ServiceDTO;

  public created_by: ProfileDTO;

  public parent: ScheduleCalendarParentDTO;

  public latest_status: ScheduleCalendarLatestStatusDTO;

  public sum_paid: string;

  public stats: IScheduleStats;

  constructor(
    props: IScheduleCalendarModelReturnType | IScheduleCalendarDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.scheduled_date = props?.scheduled_date
      ? correctDate(props?.scheduled_date)
      : '';
    this.status = props?.status || '';
    this.schedule_title = props?.schedule_title || '';
    this.uuid = props?.uuid || '';
    this.updated_at = props?.updated_at || '';
    this.created_at = props?.created_at || '';
    this.status_text = props?.status_text || ScheduleStatusesText.NEW;
    this.comment = props?.comment || '';
    this.is_child = props?.is_child || false;
    this.schedule_has_strict_mode = props?.schedule_has_strict_mode || false;
    this.price = correctPrice(props?.price);
    this.sum_total = correctPrice(props?.sum_total);
    this.schedule_expenses_sum = correctPrice(props?.schedule_expenses_sum);
    this.sum_related = correctPrice(
      Number(props?.sum_total || 0) -
        Number(props?.price || 0) -
        Number(props?.schedule_expenses_sum || 0),
    );
    this.schedule_number = props?.schedule_number || '';
    this.status_color = props?.status_color || '';
    this.parent = new ScheduleCalendarParentDTO(props?.parent);
    this.company = withoutRecursion.indexOf(WithoutRecursion.company)
      ? (props?.company as CompanyDTO)
      : CompanyMapper.toCompanyDTO(props?.company, [
          WithoutRecursion.scheduleCalendar,
          ...withoutRecursion,
        ]);
    this.employee = withoutRecursion.indexOf(WithoutRecursion.employee)
      ? (props?.employee as EmployeeDTO)
      : EmployeeMapper.toEmployeeDTO(props?.employee, [
          WithoutRecursion.scheduleCalendar,
          ...withoutRecursion,
        ]);
    this.client = withoutRecursion.indexOf(WithoutRecursion.client)
      ? (props?.client as ClientDTO)
      : ClientMapper.toClientDTO(props?.client, false, [
          WithoutRecursion.scheduleCalendar,
          ...withoutRecursion,
        ]);
    this.service = withoutRecursion.indexOf(WithoutRecursion.service)
      ? (props?.service as ServiceDTO)
      : ServiceMapper.toServiceDTO(props?.service, [
          WithoutRecursion.scheduleCalendar,
          ...withoutRecursion,
        ]);

    this.created_by = withoutRecursion.indexOf(WithoutRecursion.profile)
      ? (props?.created_by as ProfileDTO)
      : ProfileMapper.toProfileDTO(props?.created_by, [
          WithoutRecursion.scheduleCalendar,
          ...withoutRecursion,
        ]);

    this.latest_status = withoutRecursion.indexOf(WithoutRecursion.status)
      ? (props?.latest_status as ScheduleCalendarLatestStatusDTO)
      : StatusMapper.toScheduleCalendarLatestStatusDTO(props?.latest_status);

    this.sum_paid = correctPrice(props?.sum_paid || 0);
    this.stats = props?.stats || {files: '0'};
  }
}
