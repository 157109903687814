import {Record, List} from 'immutable';
import {IListProps} from './List';
import {
  ClientAbonementModel,
  ClientVisitDTO,
  ClientVisitModel,
} from '../internal';
import {WithoutRecursion} from '../type';

export interface IClientVisitListModelProps extends IListProps {
  visits: ClientVisitDTO[];
  abonement: ClientAbonementModel;
}

export interface IClientVisitListModelReturnType extends IListProps {
  visits: List<ClientVisitModel>;
  abonement: ClientAbonementModel;
  keywords: string;
  page: number;
}

export class ClientVisitListModel extends Record<IClientVisitListModelReturnType>(
  {
    visits: List(),
    total: 0,
    keywords: '',
    page: 1,
    abonement: {} as ClientAbonementModel,
  },
) {
  constructor(
    {
      visits = [],
      ...props
    }: IClientVisitListModelProps = {} as IClientVisitListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IClientVisitListModelReturnType = {
      ...props,
      keywords: '',
      page: 1,
      visits: List(
        visits.map((visit) => new ClientVisitModel(visit, withoutRecursion)),
      ),
    };

    super(options);
  }
}
