import moment from 'moment';
import {List} from 'immutable';
import {apiDelete, apiGet, apiPost, apiPut, apiPatch} from '../core/api';
import {contentApiUrl} from '../const';
import {
  CompanyServiceList,
  Employee,
  ListOptions,
  ScheduleCalendarList,
  Services,
} from '../models';
import Validate from '../validate/Validate';
import {
  ListOptionsProps,
  GENDER,
  WorkSchedulesListProps,
  ifElse,
  CompanyServiceListProps,
  buildFormData,
  ProfileProps,
  ServicesProps,
  toMomentString,
  memoizeUnaryArity,
  isEqualByUuid,
} from '../helpers';
import {
  ApiAnswer,
  DateValue,
  DeletePrams,
  IListSearchProps,
  IEntityProps,
} from '../types';
import {isServicePersonalityIndividual} from './services';
import {ICompanyScheduleListAnswer} from './company';
import {
  EmployeeDTO,
  EmployeeFormDTO,
  EmployeeMapper,
  EmployeeModel,
  EmployeeServiceFormDTO,
  IEmployeeDTOProps,
  IEmployeeListDTO,
  ServiceDTO,
  ServiceMapper,
  ServiceModel,
  EmployeeUserDataFormDTO,
  EmployeeRoleFormDTO,
  EmployeeRole,
  IScheduleCalendarListDTO,
  ScheduleCalendarMapper,
  IServiceListDTO,
  InvitationDTO,
  InvitationDTOProps,
  InvitationMapper,
  ServiceParametersFormDTO,
  CompanyOrderBonusDTO,
  EmployeeSalaryDTO,
  EmployeeRoleType,
  EmployeeRoleCashierPermission,
  EmployeeRoleAdminPermission,
  EmployeeRoleManagerPermission,
} from '../../struture';
import {IServiceListAnswer} from './services';

export interface IGetEmployeesSchedule {
  start?: DateValue;
  end?: DateValue;
  employeeUuid: string;
  limit?: number;
}

export const EMPLOYEE_INITIAL_DATA = new EmployeeFormDTO();

export const EMPLOYEE_USER_DATA_INITIAL_DATA = new EmployeeUserDataFormDTO();

export const EMPLOYEE_ROLE_INITIAL_DATA = new EmployeeRoleFormDTO();

export const ROLE_FORM_PARAM = [
  {
    uuid: EmployeeRole.Administrator,
    title: EmployeeRole.Administrator,
  },
  {
    uuid: EmployeeRole.Manager,
    title: EmployeeRole.Manager,
  },
  {
    uuid: EmployeeRole.CASHIER,
    title: EmployeeRole.CASHIER,
  },
];

const {gender, uuid, ...EMPLOYEE_REQUIRED_FIELD_REST} = EMPLOYEE_INITIAL_DATA;

export const EMPLOYEE_REQUIRED_FIELD = {
  ...EMPLOYEE_REQUIRED_FIELD_REST,
  birthday: (value: string) =>
    typeof value === 'string' ? moment(value).format('YYYY-MM-DD') : '',
};

export const EMPLOYEE_SERVICES_INITIAL_DATA: Pick<any, 'price'> = {
  price: 0,
};

export const profileEmployees = (profile: ProfileProps & any) =>
  profile.employee.employees.toArray();

export const flattenEmployeeServices = ({
  employee_service = {},
  ...rest
}: Services): Partial<Services> & {isOwnService: boolean} => ({
  ...rest,
  ...employee_service,
  isOwnService: true,
});

export const correctEmployeeService = ({
  price,
  uuid,
}: ServicesProps): IEmployeeService => ({
  service_price: price,
  service_uuid: uuid,
});

export interface IFindEmployeesServicesProps {
  employees: List<EmployeeModel> | null;
  employeeUuid: string;
}

export const memoizeFindEmployeesServices: (
  map?: Map<any, any>,
) => ({
  employees,
  employeeUuid,
}: IFindEmployeesServicesProps) => List<ServiceModel> = (
  map: Map<any, any> = new Map(),
) =>
  memoizeUnaryArity(
    <T>({
      employees,
      employeeUuid,
    }: IFindEmployeesServicesProps): List<ServiceModel> | void => {
      if (List.isList(employees)) {
        const employee = employees.find(isEqualByUuid(employeeUuid));
        if (employee) {
          return employee.serviceListModel.filter(
            isServicePersonalityIndividual,
          );
        }
      }
    },
    map,
    'memoizeFilterServices',
  );

export interface IEmployeeServiceListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  employeeUuid: string;
}
export interface IEmployeeListProps extends IListSearchProps {
  companyUuid: string;
  serviceUuid?: string;
}

export interface IEmployeeListAnswer extends ApiAnswer {
  employees: IEmployeeDTOProps[];
}

export interface IEmployeeAnswer extends ApiAnswer {
  employee: IEmployeeDTOProps;
}

export async function getEmployeeList({
  companyUuid,
  offset = 0,
  limit = 10,
  keywords,
  serviceUuid,
}: IEmployeeListProps): Promise<IEmployeeListDTO> {
  const {employees, total} = await apiGet<
    Partial<IListSearchProps & {service_uuid: string}>,
    IEmployeeListAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/employees`, {
    offset,
    limit,
    keywords,
    service_uuid: serviceUuid,
  });

  return EmployeeMapper.toEmployeeListDTO(employees, total);
}

export async function getEmployeeById(
  employeeUuid: string,
): Promise<EmployeeDTO> {
  const {employee} = await apiGet<null, IEmployeeAnswer>(
    `${contentApiUrl}/employees/${employeeUuid}`,
  );

  return EmployeeMapper.toEmployeeDTO(employee);
}

export async function createEmployee(
  value: EmployeeFormDTO,
  companyUuid: string,
): Promise<EmployeeDTO> {
  const {
    title,
    email,
    phone,
    birthday,
    first_name,
    middle_name,
    last_name,
    gender,
  } = value;

  const {employee} = await apiPost<
    Omit<EmployeeFormDTO, 'uuid' | 'birthdayToString'>,
    IEmployeeAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/employees`, {
    title,
    first_name,
    middle_name,
    last_name,
    phone,
    email,
    birthday: value.birthdayToString(birthday),
    gender,
  });

  return EmployeeMapper.toEmployeeDTO(employee);
}

export async function editEmployee(
  value: EmployeeFormDTO,
  companyUuid: string,
): Promise<EmployeeDTO> {
  const {
    uuid,
    title,
    email,
    phone,
    first_name = '',
    last_name = '',
    middle_name = '',
    birthday,
    gender = GENDER.MALE,
  } = value;

  Validate.string({value: uuid});
  Validate.string({value: title});
  Validate.string({value: phone});

  const {employee} = await apiPut<
    Omit<EmployeeFormDTO, 'uuid' | 'birthdayToString'>,
    IEmployeeAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/employees/${uuid}`, {
    title,
    email,
    phone,
    first_name,
    last_name,
    middle_name,
    birthday: value.birthdayToString(birthday),
    gender,
  });

  return EmployeeMapper.toEmployeeDTO(employee);
}

export async function deleteEmployees(
  ids: string[],
  companyId: string,
): Promise<any> {
  Validate.arrayOfStrings({value: ids});

  return await apiDelete<DeletePrams, IEmployeeListAnswer>(
    `${contentApiUrl}/companies/${companyId}/employees`,
    {
      ids,
    },
  );
}

export async function deleteProfileEmployees(
  employeeIds: string[],
): Promise<void> {
  Validate.arrayOfStrings({value: employeeIds});

  await apiDelete<DeletePrams, any>(`${contentApiUrl}/employees`, {
    ids: employeeIds,
  });
}

/**
 * @name getEmployeeSchedule
 * @desc Get a Employee schedule.
 * @param uuid
 * @param limit
 * @param start
 * @param end
 * @param listOptions
 * @throws {ApiError}
 */

export interface GetEmployeeScheduleParams {
  uuid: string;
  start: string;
  end: string;
  listOptions: ListOptions;
}

export interface IListOptionsPropsWithStartEnd extends ListOptionsProps {
  start: string;
  end: string;
}

export async function getEmployeeSchedule({
  uuid,
  start,
  end,
  listOptions = new ListOptions(),
}: GetEmployeeScheduleParams) {
  Validate.string({value: uuid});

  const {
    offset = 0,
    limit = 20,
    order = 'created_at DESC',
    filters = [],
    ...extra
  } = listOptions.toObject();

  const res = await apiGet<
    IListOptionsPropsWithStartEnd,
    WorkSchedulesListProps
  >(`${contentApiUrl}/employees/${uuid}/schedules`, {
    limit,
    offset,
    start,
    end,
    order,
    filters,
    ...extra,
  });

  return new ScheduleCalendarList(res || {});
}

export interface EmployeeScheduleProps {
  employee_uuid: string;
  schedule_id: string;
}

/**
 *
 * @name editEmployeeWorkSchedule
 * @desc Edit employee schedule param
 * @param employee_uuid
 * @param schedule_uuid
 * @return {Promise}
 */
export async function editEmployeeWorkSchedule({
  employee_uuid,
  schedule_id,
}: EmployeeScheduleProps): Promise<EmployeeDTO> {
  Validate.string({value: employee_uuid});
  Validate.string({value: schedule_id});
  const {employee} = await apiPut<
    Omit<EmployeeScheduleProps, 'employee_uuid'>,
    IEmployeeAnswer
  >(`${contentApiUrl}/employees/${employee_uuid}/work-schedules`, {
    schedule_id,
  });

  return EmployeeMapper.toEmployeeDTO(employee);
}

export interface IEmployeeService {
  service_uuid: string;
  service_price: number;
}

export interface IEditEmployeeServicesProps {
  companyUuid: string;
  employeeUuid: string;
  services: IEmployeeService[];
}

export async function editEmployeeServices({
  companyUuid,
  employeeUuid,
  services,
}: IEditEmployeeServicesProps): Promise<CompanyServiceList> {
  Validate.array({value: services});
  Validate.string({value: companyUuid});
  Validate.string({value: employeeUuid});

  const res = await apiPut<
    Pick<IEditEmployeeServicesProps, 'services'>,
    CompanyServiceListProps
  >(
    `${contentApiUrl}/companies/${companyUuid}/employees/${employeeUuid}/services`,
    {
      services,
    },
  );

  return new CompanyServiceList(res);
}

export interface IEmployeeServicesPriceProps {
  serviceUuid: string;
  employeeUuid: string;
  service: ServiceParametersFormDTO;
}

export async function addEmployeeServices({
  employeeUuid,
  serviceUuid,
  service: {duration, reward_value, price, reward_type, bonuses},
}: IEmployeeServicesPriceProps): Promise<ServiceDTO> {
  Validate.string({value: employeeUuid});

  const {service} = await apiPost<
    Omit<ServiceParametersFormDTO, 'uuid' | 'bonuses'> & {bonuses: string},
    any
  >(`${contentApiUrl}/employees/${employeeUuid}/services/${serviceUuid}`, {
    duration,
    reward_value,
    price,
    reward_type,
    bonuses: JSON.stringify(bonuses),
  });

  return ServiceMapper.toServiceDTO({
    ...service,
    employee_service: {
      price: service?.price,
      updated_at: new Date().toString(),
    },
  });
}

export async function editEmployeeServicesPrice({
  employeeUuid,
  serviceUuid,
  service: {duration, reward_value, price, reward_type, bonuses},
}: IEmployeeServicesPriceProps): Promise<ServiceDTO> {
  Validate.string({value: employeeUuid});

  const {service} = await apiPut<
    Omit<ServiceParametersFormDTO, 'uuid' | 'bonuses'> & {bonuses: string},
    any
  >(`${contentApiUrl}/employees/${employeeUuid}/services/${serviceUuid}`, {
    duration,
    reward_value,
    price,
    reward_type,
    bonuses: JSON.stringify(bonuses),
  });

  return ServiceMapper.toServiceDTO(service);
}

export async function deleteEmployeeServices({
  employeeUuid,
  serviceUuid,
}: Omit<IEmployeeServicesPriceProps, 'service'>): Promise<ServiceDTO> {
  Validate.string({value: employeeUuid});
  Validate.string({value: serviceUuid});

  const {service} = await apiDelete<null, any>(
    `${contentApiUrl}/employees/${employeeUuid}/services/${serviceUuid}`,
  );

  return ServiceMapper.toServiceDTO(service);
}

export interface EmployeePicture {
  uuid: string;
  file: File;
}

export async function setEmployeeAvatar({
  uuid,
  file,
}: EmployeePicture): Promise<Employee> {
  Validate.string({value: uuid});

  const data = buildFormData('picture', file);

  const {employee} = await apiPost<FormData, IEmployeeAnswer>(
    `${contentApiUrl}/employees/${uuid}/picture`,
    data,
    {},
    {},
    true,
  );

  return new Employee(employee as any);
}

export async function getEmployeeScheduleList({
  employeeUuid,
  start = moment().startOf('month').format('YYYY-MM-DD'),
  end = moment().endOf('month').format('YYYY-MM-DD'),
  limit,
}: IGetEmployeesSchedule): Promise<IScheduleCalendarListDTO> {
  Validate.string({value: employeeUuid});

  const isStart = ifElse(
    !!start,
    `date_start=${toMomentString(start, 'YYYY-MM-DD')}`,
    '',
  );
  const isEnd = ifElse(
    !!end,
    `&date_end=${toMomentString(end, 'YYYY-MM-DD')}`,
    '',
  );
  const isLimit = ifElse(!!limit && limit <= 150, `&limit=${limit}`, '');

  const {schedules, total} = await apiGet<null, ICompanyScheduleListAnswer>(
    `${contentApiUrl}/employees/${employeeUuid}/schedules?${isStart}${isEnd}${isLimit}`,
  );

  return ScheduleCalendarMapper.toScheduleCalendarListDTO(schedules, total);
}

export async function setEmployeePassword({
  login,
  password,
  uuid,
}: EmployeeUserDataFormDTO): Promise<ApiAnswer> {
  return await apiPut<Omit<EmployeeUserDataFormDTO, 'uuid'>, ApiAnswer>(
    `${contentApiUrl}/employees/${uuid}/credentials`,
    {login, password},
  );
}

export async function setEmployeeRole(
  employeeUuid: string,
  roles: EmployeeRoleType<
    | EmployeeRoleCashierPermission
    | EmployeeRoleAdminPermission
    | EmployeeRoleManagerPermission
  >[],
): Promise<EmployeeDTO> {
  const {employee} = await apiPatch<
    {
      roles: EmployeeRoleType<
        | EmployeeRoleCashierPermission
        | EmployeeRoleAdminPermission
        | EmployeeRoleManagerPermission
      >[];
    },
    IEmployeeAnswer
  >(`${contentApiUrl}/employees/${employeeUuid}/role`, {roles});

  return EmployeeMapper.toEmployeeDTO(employee);
}

export async function blockEmployee(
  employeeUuid: string,
  status: number,
): Promise<EmployeeDTO> {
  const {employee} = await apiPatch<{status: number}, IEmployeeAnswer>(
    `${contentApiUrl}/employees/${employeeUuid}/status`,
    {status},
  );

  return EmployeeMapper.toEmployeeDTO(employee);
}

export async function acceptEmployeeInvite(
  employeeUuid: string,
): Promise<EmployeeDTO> {
  const {employee} = await apiPut<null, IEmployeeAnswer>(
    `${contentApiUrl}/employees/${employeeUuid}/confirm-registration`,
    null,
  );

  return EmployeeMapper.toEmployeeDTO(employee);
}

export async function cancelEmployeeInvite(
  employeeUuid: string,
): Promise<EmployeeDTO> {
  const {employee} = await apiPut<null, IEmployeeAnswer>(
    `${contentApiUrl}/employees/${employeeUuid}/reject-registration`,
    null,
  );

  return EmployeeMapper.toEmployeeDTO(employee);
}

export async function getEmployeeServiceList({
  employeeUuid,
  limit = 10,
  offset = 0,
  keywords,
}: IEmployeeServiceListProps): Promise<IServiceListDTO> {
  const isKeywords = ifElse(!!keywords, `&keywords=${keywords}`, '');

  const {services, total} = await apiGet<null, IServiceListAnswer>(
    `${contentApiUrl}/employees/${employeeUuid}/all-services?limit=${limit}&offset=${offset}${isKeywords}`,
  );

  return ServiceMapper.toServiceListDTO(services, total);
}

export async function getEmployeeAttachedServiceList({
  employeeUuid,
  limit = 10,
  offset = 0,
  keywords,
}: IEmployeeServiceListProps): Promise<IServiceListDTO> {
  const isKeywords = ifElse(!!keywords, `&keywords=${keywords}`, '');

  const {services, total} = await apiGet<null, IServiceListAnswer>(
    `${contentApiUrl}/employees/${employeeUuid}/services?limit=${limit}&offset=${offset}${isKeywords}`,
  );

  return ServiceMapper.toServiceListDTO(services, total);
}

export async function inviteEmployee(
  employeeUuid: string,
  type?: 'sms' | 'email',
): Promise<InvitationDTO> {
  const {invitation} = await apiPost<
    null,
    {invitation: InvitationDTOProps; template: string}
  >(
    `${contentApiUrl}/employees/${employeeUuid}/invitations${
      type ? `/${type}` : ''
    }`,
    null,
  );

  return InvitationMapper.toInvitationDTO(invitation);
}

/* ------------------- EMPLOYEE BONUSES ------------------- */

export async function changeEmployeeBonuses(
  employeeUuid: string,
  bonuses: CompanyOrderBonusDTO[],
): Promise<EmployeeDTO> {
  const {employee} = await apiPatch<{bonuses: string}, IEmployeeAnswer>(
    `${contentApiUrl}/employees/${employeeUuid}/bonuses`,
    {bonuses: JSON.stringify(bonuses)},
  );

  return EmployeeMapper.toEmployeeDTO(employee);
}

export async function changeEmployeeSalary(
  employeeUuid: string,
  salary: EmployeeSalaryDTO[],
): Promise<EmployeeDTO> {
  const {employee} = await apiPatch<
    {salary: EmployeeSalaryDTO[]},
    IEmployeeAnswer
  >(`${contentApiUrl}/employees/${employeeUuid}/bonuses`, {salary});

  return EmployeeMapper.toEmployeeDTO(employee);
}
