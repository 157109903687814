import {apiGet, apiPost, apiPut} from '../core/api';
import {ApiAnswer, IListSearchProps} from '../types';
import {
  IntegrationDTO,
  IntegrationMapper,
  IntegrationAssociationDTO,
  IntegrationListModel,
  IntegrationAssociationType,
  IntegrationFormDTO,
  ICompanyDTOProps,
  IntegrationCategoryType,
} from '../../struture';
import {contentApiUrl} from '../const';

export interface IIntegrationAnswer<T> extends ApiAnswer {
  integration: IntegrationDTO;
  association: IntegrationAssociationDTO<T> | null;
}

export interface IIntegrationListAnswer extends ApiAnswer, IListSearchProps {
  integrations: IntegrationDTO[];
}

export interface IIntegrationListProps extends IListSearchProps {}

export interface IIntegrationListByCategoryProps extends IListSearchProps {
  companyUuid: string;
  categoryType: IntegrationCategoryType;
}

export interface IntegrationListByCategoryAnswer<T> extends ApiAnswer {
  company: ICompanyDTOProps;
  associations: IntegrationAssociationDTO<T>[];
}

export interface IntegrationListByCategoryReturnType<T> {
  associations: IntegrationAssociationDTO<T>[];
  total: number;
}

export async function getIntegrationList({
  offset = 0,
  limit = 10,
  keywords,
}: IIntegrationListProps): Promise<IntegrationListModel> {
  const {integrations, total} = await apiGet<
    IListSearchProps,
    IIntegrationListAnswer
  >(`${contentApiUrl}/system/integrations`, {
    offset,
    limit,
    keywords,
  });

  return IntegrationMapper.toIntegrationListModel(integrations, total || 0);
}

export async function getIntegrationById<T>(
  integrationUuid: string,
  companyUuid: string,
): Promise<IntegrationAssociationType<T>> {
  const {integration, association} = await apiGet<null, IIntegrationAnswer<T>>(
    `${contentApiUrl}/companies/${companyUuid}/integrations/${integrationUuid}`,
  );

  return IntegrationMapper.toIntegrationAssociationDTO<T>(
    integration,
    association,
  );
}

export async function createIntegration<T>(
  {status, attributes}: IntegrationFormDTO<T>,
  companyUuid: string,
  integrationUuid: string,
): Promise<IntegrationDTO> {
  const {integration} = await apiPost<
    Omit<IntegrationFormDTO<T>, 'uuid'>,
    IIntegrationAnswer<T>
  >(
    `${contentApiUrl}/companies/${companyUuid}/integrations/${integrationUuid}`,
    {
      status,
      attributes,
    },
  );

  return IntegrationMapper.toIntegrationModel(integration);
}

export async function editIntegration<T>({
  uuid,
  status,
  attributes,
}: IntegrationFormDTO<T>): Promise<IntegrationDTO> {
  const {integration} = await apiPut<
    Omit<IntegrationFormDTO<T>, 'uuid'>,
    IIntegrationAnswer<T>
  >(`${contentApiUrl}/company-integrations//${uuid}`, {
    status,
    attributes,
  });

  return IntegrationMapper.toIntegrationModel(integration);
}

export async function getIntegrationListByCategory<T>({
  offset = 0,
  limit = 10,
  keywords,
  companyUuid,
  categoryType,
}: IIntegrationListByCategoryProps): Promise<
  IntegrationListByCategoryReturnType<T>
> {
  const {associations, count} = await apiGet<
    IListSearchProps,
    IntegrationListByCategoryAnswer<T>
  >(
    `${contentApiUrl}/companies/${companyUuid}/integrations-by-category/${categoryType}`,
    {
      offset,
      limit,
      keywords,
    },
  );

  return {
    associations,
    total: count,
  };
}
