import {CategoryModel} from '../internal';

export class CategoryFormDTO {
  public pos: string;

  public status: number;

  public uuid: string;

  public title: string;

  public description: string;

  constructor(props?: CategoryModel) {
    this.pos = props?.pos || '';
    this.uuid = props?.uuid || '';
    this.title = props?.title || '';
    this.description = props?.description || '';
    this.status = props?.status || 0;
  }
}
