export const STORAGE_COMPANIES = 'companies';
export const STORAGE_URL_USER = 'profiles';
export const STORAGE_URL_EMPLOYEES = 'employees';
export const STORAGE_URL_SCHEDULES = 'schedules';
export const STORAGE_URL_COMPANY_COVER = `${STORAGE_COMPANIES}/%s/cover`;
export const STORAGE_URL_COMPANY_LOGO = `${STORAGE_COMPANIES}/%s/logo`;
export const STORAGE_URL_COMPANY_GALLERY = `${STORAGE_COMPANIES}/%s/gallery`;
export const STORAGE_URL_COMPANY_QR = `${STORAGE_COMPANIES}/%s/qr-codes`;
export const STORAGE_URL_USER_PROFILE_PICTURE = `${STORAGE_URL_USER}/%s/picture`;
export const STORAGE_URL_EMPLOYEE_PICTURE = `${STORAGE_URL_EMPLOYEES}/%s/picture`;
export const STORAGE_URL_SCHEDULE_DOCUMENTS = `${STORAGE_URL_SCHEDULES}/%s/documents`;
export const STORAGE_URL_REPORT = `${STORAGE_COMPANIES}/%s/reports`;
export const STORAGE_URL_COMPANY_PRODUCTS = `${STORAGE_COMPANIES}/%s/products`;
export const STORAGE_URL_COMPANY_PRODUCT_CATEGORIES = `${STORAGE_URL_COMPANY_PRODUCTS}/categories`;
