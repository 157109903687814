import React, {useCallback, useEffect} from 'react';
import {Form} from 'antd';
import {FormInstance} from 'antd/lib/form/Form';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {notify} from '../Feedback';
import {FormFooter, IFormFooterProps} from '../Layout';
import {isFunction} from '../../../services/helpers';
import {KeyOfObject} from '../../../services/types';
import {useDefaultForm} from '../../../contex';
import {useDidCancel} from '../../../hooks';
import {ENTER_KEYS} from '../../../services/const';

export interface IDefaultFormReturnedProps extends FormInstance {
  loadingSubmit: boolean;
  handlerUpdateFormState: (value: any) => void;
  formData: any;
}

export type FuncChildren = (data: IDefaultFormReturnedProps) => React.ReactNode;

type OnSuccessVoid<U> = (value: U, closeble?: boolean) => void;
type OnSuccessBoolean<U> = (value: U, closeble?: boolean) => boolean;

export interface IDefaultLocalFormProps<T, U> extends IFormFooterProps {
  editMode?: boolean;
  initialValues: T;
  onCancel?: () => void;
  onSuccess?: OnSuccessVoid<U> | OnSuccessBoolean<U>;
  onValuesChange?: (value: KeyOfObject<T>) => void;
  children: FuncChildren | React.ReactNode;
  showFooter?: boolean;
  showNotify?: boolean;
  additionalValuesRequest?: any;
  className?: string;
  isResetLoading?: boolean;
  form: any;
  loadingSubmit: boolean;
  setLoadingSubmit: any;
  withContext?: boolean;
  notifyFormName?: string;
  customNotifyFormName?: string;
  notifyError?: (apiError: any) => void;
  id?: string;
  footerClassName?: string;
  stickyFooter?: boolean;
  formKeyboardCodes?: string[];
  formKeyboardWithPropagationId?: string[];
  formKeyboardEndSubmit?: boolean;
  onEnterPress?: (e: KeyboardEvent, formData?: any) => boolean;
  asModal?: boolean;
}

const StyledForm = styled(Form)<any>`
  width: 100%;
`;

const inputTypeForFocus = [
  'text',
  'date',
  'email',
  'number',
  'password',
  'search',
  'tel',
  'time',
  'url',
];

export default function DefaultForm<T, U>({
  editMode,
  initialValues = {} as T,
  onCancel,
  onSuccess,
  onValuesChange,
  children,
  showFooter = true,
  showNotify = true,
  className,
  additionalValuesRequest = {},
  isResetLoading = false,
  form,
  setLoadingSubmit,
  loadingSubmit,
  withContext,
  notifyFormName,
  submitButtonText,
  customNotifyFormName,
  id,
  notifyError,
  stickyFooter,
  formKeyboardCodes = [...ENTER_KEYS],
  formKeyboardWithPropagationId = [],
  formKeyboardEndSubmit = false,
  onEnterPress,
  ...rest
}: IDefaultLocalFormProps<T, U>): JSX.Element {
  const {t} = useTranslation();
  const {formData, handlerUpdateFormState, submit} = useDefaultForm();
  const didCancel = useDidCancel();

  /**
   * @desc Обробка даних та збереження
   * */
  const handleSubmit = useCallback(
    async (value: T) => {
      setLoadingSubmit(true);

      try {
        const data: U = {
          t,
          ...value,
          ...additionalValuesRequest,
        };

        const correctFormData = (({errorFields, ...rest}) => ({...rest}))(
          formData,
        );

        const contextData: U = {
          t,
          ...correctFormData,
          ...additionalValuesRequest,
        };

        if (isFunction(onSuccess)) {
          const isShowNotify: boolean | void = await onSuccess(
            withContext ? contextData : data,
          );

          if (isResetLoading && !didCancel.current) {
            setLoadingSubmit(false);
          }

          if (showNotify && typeof isShowNotify === 'boolean' && isShowNotify) {
            if (editMode) {
              notify.editSuccess(notifyFormName || t('Form'), t);
            }
            if (customNotifyFormName) {
              notify.notifyMessage(customNotifyFormName);
            }
            if (!customNotifyFormName && !editMode) {
              notify.createSuccess(notifyFormName || t('Form'), t);
            }
          }
        }
      } catch (err: any) {
        if (showNotify) {
          if (editMode) {
            notify.editError(notifyFormName || t('the form'), err, t);
          } else {
            notify.createError(notifyFormName || t('the from'), err, t);
          }
        } else {
          if (notifyError) {
            notifyError(err);
          }
        }
      } finally {
        setLoadingSubmit(false);
      }
    },
    [
      setLoadingSubmit,
      t,
      additionalValuesRequest,
      formData,
      onSuccess,
      withContext,
      isResetLoading,
      didCancel,
      showNotify,
      editMode,
      customNotifyFormName,
      notifyFormName,
      notifyError,
    ],
  );

  const formEventListener = useCallback(
    (e: KeyboardEvent, form: any) => {
      if (
        formKeyboardCodes?.includes(e?.code) &&
        !(e?.target instanceof HTMLTextAreaElement)
      ) {
        const elements = [...form.elements].filter((item) => {
          return (
            item instanceof HTMLInputElement &&
            inputTypeForFocus.includes(item?.type) &&
            !item?.disabled
          );
        });

        if (isFunction(onEnterPress)) {
          const isContinue = onEnterPress(e, formData);

          if (!isContinue) {
            return;
          }
        }

        const indexOfCurrentElement = elements.findIndex(
          (el) => el?.id === (e?.target as any)?.id,
        );

        const nextIndexOfCurrentElement = indexOfCurrentElement + 1;

        const currentInput = elements[indexOfCurrentElement];

        const nextInput =
          elements.length === nextIndexOfCurrentElement
            ? formKeyboardEndSubmit
              ? submit()
              : elements[0]
            : elements[nextIndexOfCurrentElement];

        e.preventDefault();
        e.stopPropagation();

        if (
          currentInput instanceof HTMLInputElement &&
          nextInput instanceof HTMLInputElement
        ) {
          currentInput?.blur();
          nextInput?.focus();
        }
      }
    },
    [formData, formKeyboardCodes, formKeyboardEndSubmit, onEnterPress, submit],
  );

  useEffect(() => {
    const form = document.body.querySelector(
      className ? `.${className}` : '.enter-focus',
    );

    const event = (e: KeyboardEvent) => formEventListener(e, form);

    if (form instanceof HTMLFormElement) {
      form.addEventListener('keydown', event);
    }

    return () => {
      if (form instanceof HTMLFormElement) {
        form.removeEventListener('keydown', event);
      }
    };
  }, [className, formEventListener]);

  return (
    <StyledForm
      id={id}
      form={form}
      layout="vertical"
      className={`${className || ''} enter-focus`}
      onFinish={handleSubmit}
      onValuesChange={onValuesChange}
      initialValues={initialValues}>
      {isFunction(children)
        ? children({...form, loadingSubmit, handlerUpdateFormState, formData})
        : children}
      {showFooter && (
        <FormFooter
          {...rest}
          stickyFooter={stickyFooter}
          submitButtonText={submitButtonText || t('Save')}
          cancelButtonText={rest?.cancelButtonText || t('Cancel')}
          submitButtonProps={{
            ...rest?.submitButtonProps,
            'data-testid': 'form-submit',
            loading: loadingSubmit,
          }}
          onCancel={() => {
            if (isFunction(onCancel)) {
              onCancel();
            }
          }}
        />
      )}
    </StyledForm>
  );
}
