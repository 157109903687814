import React, {useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {
  CommentField,
  DetailsView,
  HeaderLeft,
  IDetailsViewItemProps,
} from '../../../components/lib/DataDisplay';
import {
  useSearchInputFocus,
  useStopLoading,
  useStoredCompanies,
  useStoredInvoice,
} from '../../../hooks';
import {OverviewLayout} from '../../../components/lib/Layout';
import {
  InvoiceBottomView,
  InvoiceItemListSelect,
  InvoiceItemListTable,
  InvoicePageHeader,
  InvoiceTabPanel,
} from '../Show';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import {Routes, RoutesAcl} from '../../../services/types';
import {InvoiceMapper, InvoiceStatus, InvoiceType} from '../../../struture';
import {useAcl} from '../../../contex';
import {useLocation, useNavigate, useParams} from 'react-router';
import {Col, Row} from 'antd';
import {ClientDetailsView} from '../../Clients';
import {InvoiceUpdateButton, InvoicePageMenuButton} from '../Buttons';
import {InvoiceManager, useInvoiceManager} from '../Managers';
import {StyledDescription, StyledTitle} from '../../../components/lib/Styled';
import {textToUpperCase} from '../../../services/helpers';
import styled, {useTheme} from 'styled-components';

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  margin-top: 10px;
`;

const StyledTableContainer = styled.div`
  background-color: ${({theme}) => theme.background.layout};
  padding: 10px;
  border-radius: 7px;
`;

function InvoiceContent() {
  const {invoiceId} = useParams();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const location = useLocation();
  const theme: any = useTheme();

  const {defaultCompanyUuid, defaultCompany} = useStoredCompanies();
  const {invoices: invoiceAccess} = useAcl((acl) => acl);

  const {inputRef, focus, daleyFocus} = useSearchInputFocus({
    loading: false,
  });

  const {
    invoice,
    loading: invoiceLoading,
    error: invoiceError,
    handleUpdateInvoice,

    handlePrintInvoice,
    handlePrintInvoiceStoreRealization,
    handlePrintInputInvoice,
    handleUpdateInvoiceComment,
    handleUpdateInvoiceStatus,
    handleDuplicateInvoice: onDuplicateInvoice,

    updateInvoice,
  } = useStoredInvoice({
    invoiceUuid: invoiceId!,
    companyUuid: defaultCompanyUuid,
  });

  const isInvoice = invoice?.inv_type === InvoiceType.IN;

  const {invoiceItemList, invoiceCallbacks} = useInvoiceManager();

  const handleDuplicateInvoice = useCallback(async () => {
    const {invoice} = (await onDuplicateInvoice(invoiceId!)) || {};

    if (invoice) {
      const navigateToNewInvoice = () =>
        new Promise((resolve) => {
          setTimeout(() => {
            navigate(`/${Routes.app}/${Routes.invoices}/${invoice?.uuid}`);
            resolve('');
          }, 300);
        });

      await navigateToNewInvoice();
    }
  }, [invoiceId, navigate, onDuplicateInvoice]);

  const navigateGoBack = useCallback(() => {
    navigate(location?.state?.goBack || `/${Routes.app}/${Routes.invoices}`);
  }, [location?.state?.goBack, navigate]);

  const loading = useStopLoading({
    loading: invoiceLoading,
    error: invoiceError,
    message: 'An error occurred during invoice posting loading',
  });

  const routes = useBreadcrumb([
    {
      path: `/${Routes.invoices}`,
      breadcrumbName: 'Bills',
    },
    {
      path: `/${Routes.invoices}/${invoiceId}`,
      breadcrumbName: invoice?.inv_internal_number || t('Show bill'),
    },
  ]);

  const isShowWarningDescription = !!invoiceItemList?.size;

  useEffect(() => {
    invoiceCallbacks.current.setInvoice = updateInvoice;
  }, [invoiceCallbacks, updateInvoice]);

  const detailViewItems: IDetailsViewItemProps[] = [
    {
      title: 'Resident',
      className: 'client-details__view',
    },
  ];

  return (
    <InvoiceTabPanel invoice={invoice}>
      {({tabs}) => (
        <OverviewLayout
          className="overview-details-item-invoice"
          aclItem={RoutesAcl[Routes.invoices]}
          headerTitle={t('Bill')}
          headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
          headerRight={
            isInvoice ? (
              <InvoicePageMenuButton
                disabled={invoice?.inv_status !== InvoiceStatus.DRAFT}
                invoice={InvoiceMapper.toInvoiceFormDTO(invoice!, {
                  editMode: true,
                })}
                onSuccess={handleUpdateInvoice}
                onDuplicate={handleDuplicateInvoice}
              />
            ) : invoice?.inv_status === InvoiceStatus.DRAFT ? (
              <InvoiceUpdateButton
                invoice={InvoiceMapper.toInvoiceFormDTO(invoice!, {
                  editMode: true,
                })}
                onSuccess={handleUpdateInvoice}
              />
            ) : null
          }
          underHeaderContent={
            invoice?.uuid ? (
              <InvoicePageHeader
                invoice={invoice}
                handleUpdateInvoiceStatus={handleUpdateInvoiceStatus}
              />
            ) : null
          }
          header={
            <Row gutter={10}>
              {invoice?.client?.uuid ? (
                <Col span={24}>
                  <DetailsView items={detailViewItems}>
                    <ClientDetailsView client={invoice?.client} />
                  </DetailsView>
                </Col>
              ) : null}
              <Col span={24}>
                <CommentField
                  withoutIndent={!invoice?.client?.uuid}
                  comment={invoice?.inv_comment || t('Please enter note')}
                  handleUpdateComment={handleUpdateInvoiceComment}
                  disabled={!invoiceAccess?.manage}
                />
              </Col>

              <Col span={24}>
                <StyledTitleContainer>
                  <StyledTitle bold>
                    {textToUpperCase(t('List of positions'))}
                  </StyledTitle>
                  <StyledDescription
                    $color={
                      isShowWarningDescription
                        ? theme.colors.black
                        : theme.colors.warning
                    }>
                    {isShowWarningDescription
                      ? `${t('In the amount of')}: ${
                          defaultCompany?.currency_symbol || ''
                        } ${invoice?.inv_sum_total}`
                      : t('the list is empty')}
                  </StyledDescription>
                </StyledTitleContainer>

                <StyledTableContainer>
                  {invoice?.inv_status === InvoiceStatus.DRAFT ? (
                    <InvoiceItemListSelect
                      disabled={false}
                      selectRef={inputRef}
                      handleSearchSelectDaleyFocus={daleyFocus}
                      invoice={invoice}
                    />
                  ) : null}

                  <InvoiceItemListTable
                    handleSearchSelectFocus={focus}
                    handleSearchSelectDaleyFocus={daleyFocus}
                    invoice={invoice!}
                  />
                </StyledTableContainer>
              </Col>
            </Row>
          }
          outsideFooterContent={
            <InvoiceBottomView
              invoice={invoice}
              handlePrintInvoice={handlePrintInvoice}
              handlePrintInvoiceStoreRealization={
                handlePrintInvoiceStoreRealization
              }
              handlePrintInputInvoice={handlePrintInputInvoice}
            />
          }
          disabled={!invoiceAccess?.manage}
          editTooltip={t('Edit bill of lading')}
          loading={loading}
          data={null}
          routes={routes}
          tabs={tabs}
        />
      )}
    </InvoiceTabPanel>
  );
}

export function InvoicePage() {
  return (
    <InvoiceManager>
      <InvoiceContent />
    </InvoiceManager>
  );
}
