export interface InvitationDTOProps {
  uuid: string;
  status: number;
  created_at: string;
  updated_at: string;
}

export class InvitationDTO {
  public uuid: string;

  public status: number;

  public created_at: string;

  public updated_at: string;

  constructor(props?: InvitationDTOProps) {
    this.uuid = props?.uuid || '';
    this.status = props?.status || 0;
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
  }
}
