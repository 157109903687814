import React, {useCallback} from 'react';
import {ProductModel} from '../../../../struture';
import styled, {useTheme} from 'styled-components';
import {StyledTitle} from '../../../../components/lib/Styled';
import {head} from '../../../../services/helpers';
import {LoadingOutlined} from '@ant-design/icons';

export interface IStoreSaleAmountFieldProps {
  product: ProductModel;
  loadingProductUuid?: string;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const StyledSelectedContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`;

export function StoreSaleAmountField({
  product,
  loadingProductUuid,
}: IStoreSaleAmountFieldProps): React.JSX.Element {
  const theme: any = useTheme();

  const isLoading = loadingProductUuid === product?.uuid;

  const isAmountLessThanNormal = useCallback(
    (product: ProductModel) =>
      head(product?.product_balances)?.balance_total <
      (product?.product_min_amount || 0)
        ? theme.colors.error
        : theme.colors.black,
    [theme.colors.black, theme.colors.error],
  );

  return (
    <StyledContainer>
      <StyledTitle
        style={{marginRight: 15}}
        fontSize={18}
        $color={isAmountLessThanNormal(product)}>
        {head(product?.product_balances)?.balance_total}
      </StyledTitle>

      {isLoading ? (
        <StyledSelectedContainer>
          <LoadingOutlined />
        </StyledSelectedContainer>
      ) : null}
    </StyledContainer>
  );
}
