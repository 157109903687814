import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {StoreDocumentFormDTO} from '../../../../struture';
import {useModal} from '../../../../components/lib/libV2/hooks';
import {
  EditButton,
  IEditButtonProps,
  SuspenseEmpty,
} from '../../../../components/lib/DataDisplay';
import {IUseStateStoredDocumentReturnType} from '../../../../hooks';

const StoreMovingSideWindowLazy = React.lazy(
  () => import('../Show/StoreMovingSideWindow'),
);

export interface IStoreMovingUpdateButtonProps extends IEditButtonProps {
  children?: React.ReactNode;
  onSuccess: IUseStateStoredDocumentReturnType['handleUpdateStoreDocument'];
  onCancel?: () => void;
  document: StoreDocumentFormDTO;
  fromList: boolean;
}

export function StoreMovingUpdateButton({
  children,
  onSuccess,
  onCancel,
  loading,
  document,
  fromList,
  ...rest
}: IStoreMovingUpdateButtonProps): React.JSX.Element {
  const {t} = useTranslation();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <EditButton
        tooltipTitle={t('Edit moving')}
        onClick={handleOnInit}
        disabled={false}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <StoreMovingSideWindowLazy
          editMode
          document={document}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
          fromList={fromList}
        />
      </Suspense>
    </>
  );
}
