import * as React from 'react';
import {getStatusById} from '../services/api/status';
import {StatusModel, StatusMapper, StatusDTO} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {IEntityProps} from '../services/types';
import {useDropdownAlert} from '../contex';
import {useTranslation} from 'react-i18next';

export interface IUseStatusProps extends IEntityProps {
  statusUuid: string;
}

export interface IUseStatusReturnType {
  error: null | any;
  loading: boolean;
  entity: StatusModel | null;
  refresh: (value: Partial<IUseStatusProps>) => Promise<StatusModel | void>;
  statusUuid: string;
}

export function useStatus(
  {loadOnInit = true, statusUuid}: IUseStatusProps = {} as IUseStatusProps,
): IUseStatusReturnType {
  const {cancellablePromise} = useCancellablePromise();
  const {alert} = useDropdownAlert();
  const {t} = useTranslation();

  const {
    entity,
    loading,
    error,
    handleUpdate,
    statusUuid: entityUuid,
  } = useStateReducer<Omit<IUseStatusReturnType, 'refresh'>>({
    entity: null,
    statusUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading,
      statusUuid = entityUuid,
    }: Partial<IUseStatusProps> = {}): Promise<StatusModel | void> => {
      try {
        handleUpdate({loading: showLoading, error: null, statusUuid});

        const status = await cancellablePromise<StatusDTO>(
          getStatusById(statusUuid),
        );

        const statusModel = StatusMapper.toStatusModel(status);

        handleUpdate({
          entity: statusModel,
          loading: false,
        });
        return statusModel;
      } catch (err: any) {
        alert(
          'error',
          t('Status'),
          `${t('An error occurred during getstatus')} : ${err?.message}`,
        );

        handleUpdate({
          error: err,
          loading: false,
        });
      }
    },

    [alert, cancellablePromise, entityUuid, handleUpdate, t],
  );

  React.useEffect(() => {
    if (loadOnInit && statusUuid) {
      (async () => {
        await refresh({statusUuid});
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, statusUuid]);

  return {
    error,
    loading,
    refresh,
    entity,
    statusUuid,
  };
}
