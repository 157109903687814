import {apiGet, apiPost} from '../core/api';
import {
  IPlanDTOProps,
  PlanModel,
  PlanMapper,
  PlanFormDTO,
  PlanTariffFormDTO,
  IPlanListDTO,
  SubscriptionDTO,
} from '../../struture';
import {ApiAnswer} from '../types';
import {
  toDate,
  EPlanTypeIdentifier,
  EPlanPeriod,
  EPlanStatuses,
  IPlanRule,
} from '../../services/helpers';
import {userServiceUrl} from '../const';

export const SUBSCRIPTION_INITIAL_PARAM = new SubscriptionDTO();

export interface ISystemSubscription {
  id: EPlanTypeIdentifier;
  title: string;
  price: number;
  duration: number;
  period: EPlanPeriod;
  defaultStatus: EPlanStatuses;
  rules: IPlanRule[];
  freeDays?: number;
}

const {
  id,
  created_at,
  status,
  date_end,
  date_start,
  ...SUBSCRIPTION_INITIAL_PARAM_REST
} = SUBSCRIPTION_INITIAL_PARAM;

export const SUBSCRIPTION_REQUIRED_FIELD = {
  ...SUBSCRIPTION_INITIAL_PARAM_REST,
};

export interface ISubscriptionPlanRecalculationProps {
  start_date: Date;
  package_name: string;
  amount: number;
  uuid: string;
}

export interface IGetSubscriptionListReturnType extends ApiAnswer {
  plans: IPlanDTOProps[];
}

export interface ISubscriptionActivateAnswer extends ApiAnswer {
  checkout_url: string;
}

export const SUBSCRIPTION_FORM_PARAM = PlanMapper.toPlanFormDTO(
  new PlanModel(),
);

export interface ISubscriptionPeriods {
  uuid: string;
  title: string;
}

export const SUBSCRIPTION_PERIODS: ISubscriptionPeriods[] = [
  {
    uuid: '3',
    title: '3 month',
  },
  {
    uuid: '6',
    title: 'Half year',
  },
  {
    uuid: '12',
    title: 'Year',
  },
];

export const SUBSCRIPTION_TARIFF_FORM_PARAM = PlanMapper.toPlanTariffFormDTO(
  new PlanModel(),
);

export interface ITariffPlanViewDTO {
  title: EPlanTypeIdentifier;
}

const TARIFF_PLANS = [];

const plansTest: any = [
  {
    id: '64196689a853230002e77145',
    identifier: 'buisness',
    subscriptions: [
      {
        id: '64196689a853230002e771',
        price: 0,
        periods: 1,
        discount: 0,
        date_start: '2022-02-20T08:10:49.289Z',
        date_end: '2022-03-24T08:10:49.289Z',
        comment: 'Auto assignment',
        status: -2,
        created_at: '2023-03-20T09:39:41.765Z',
      },
      {
        id: '64196689a853230002e7714c',
        price: 0,
        periods: 1,
        discount: 0,
        date_start: '2022-08-05T:10:49.289Z',
        date_end: '2022-08-06T16:56:49.289Z',
        comment: 'Auto assignment',
        status: -2,
        created_at: '2023-03-20T09:39:41.765Z',
      },
      {
        id: '64196689a853230002e7714c',
        price: 0,
        periods: 1,
        discount: 0,
        date_start: '2022-03-21T08:10:49.289Z',
        date_end: '2022-04-25T08:10:49.289Z',
        comment: 'Auto assignment',
        status: 1,
        created_at: '2023-03-20T09:39:41.765Z',
      },
    ],
    checkout_url: '',
    status: 1,
    created_at: '2023-03-20T09:39:41.761Z',
  },
];

export async function getSubscriptionList(): Promise<IPlanListDTO> {
  const {plans, total} = await apiGet<null, IGetSubscriptionListReturnType>(
    `${userServiceUrl}/users/subscriptions`,
  );

  return PlanMapper.toPlanListDTO(plans, total);
}

export async function subscriptionActivate({
  periods,
  uuid,
  comment,
  discount,
  amount,
}: PlanFormDTO): Promise<string> {
  const {checkout_url} = await apiPost<any, ISubscriptionActivateAnswer>(
    `${userServiceUrl}/subscriptions/${uuid}/checkout-url`,
    {periods, comment, discount, amount},
  );

  return checkout_url;
}

export async function subscriptionPlanRecalculation({
  start_date,
  amount,
  package_name,
  uuid,
}: ISubscriptionPlanRecalculationProps): Promise<number> {
  const {total} = await apiPost<any, ApiAnswer & {total: number}>(
    `${userServiceUrl}/subscriptions/${uuid}/transition-recalculation`,
    {start_date: toDate(start_date), amount, package: package_name},
  );

  return total;
}

export async function subscriptionTransition({
  start_date,
  amount,
  package: packageName,
  uuid,
  comment,
}: Omit<PlanTariffFormDTO, 'identifier'>): Promise<string> {
  const {checkout_url} = await apiPost<
    Omit<PlanTariffFormDTO, 'identifier' | 'uuid'>,
    ISubscriptionActivateAnswer
  >(`${userServiceUrl}/subscriptions/${uuid}/transition`, {
    start_date: toDate(start_date as any) as any,
    amount,
    package: packageName,
    comment,
  });

  return checkout_url;
}
