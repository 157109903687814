import {
  IStoreDocumentItemModelReturnType,
  IProductPrice,
  IProductDTOProps,
  ProductDTO,
  ProductMapper,
  StoreDocumentDTO,
  StoreDocumentMapper,
} from '../internal';
import {WithoutRecursion} from '../type';
import {correctPrice} from '../../services/helpers';

export interface IStoreDocumentItemConfiguredPrice {
  product: IProductPrice[];
  calculated: IProductPrice[];
}

export interface IStoreDocumentItemDTOProps {
  uuid: string;
  item_cell_identifier: string;
  item_product_amount: number;
  item_price: string;
  item_base_price: string;
  created_at: string;
  item_price_currency: string;
  updated_at: string;
  item_configured_price: IStoreDocumentItemConfiguredPrice;
  product: IProductDTOProps;
  item_product_returned_amount: number;
  store_document: StoreDocumentDTO;
}

export class StoreDocumentItemDTO {
  public uuid: string;
  public item_cell_identifier: string;
  public item_product_amount: number;
  public item_product_returned_amount: number;
  public item_price: string;
  public item_base_price: string;
  public item_price_currency: string;
  public created_at: string;
  public updated_at: string;
  public item_configured_price: IStoreDocumentItemConfiguredPrice;
  public product: ProductDTO;
  public store_document: StoreDocumentDTO;

  constructor(
    props: IStoreDocumentItemModelReturnType | IStoreDocumentItemDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';

    this.item_cell_identifier = props?.item_cell_identifier || '';
    this.item_product_amount = props?.item_product_amount || 0;
    this.item_product_returned_amount =
      props?.item_product_returned_amount || 0;
    this.item_price = correctPrice(props?.item_price || 0);
    this.item_base_price = correctPrice(props?.item_base_price || 0);
    this.item_price_currency = props?.item_price_currency || '';
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
    this.item_configured_price = props?.item_configured_price || {
      product: [],
      calculated: [],
    };
    this.product =
      withoutRecursion.indexOf(WithoutRecursion.product) !== -1
        ? (props?.product as ProductDTO)
        : ProductMapper.toProductDTO(props?.product, [
            WithoutRecursion.storeDocument,
            ...withoutRecursion,
          ]);

    this.store_document =
      withoutRecursion.indexOf(WithoutRecursion.product) !== -1
        ? (props?.store_document as StoreDocumentDTO)
        : StoreDocumentMapper.toStoreDocumentDTO(props?.store_document as any, [
            WithoutRecursion.storeDocument,
            ...withoutRecursion,
          ]);
  }
}
