import {
  IRewardModelReturnType,
  IScheduleCalendarDTOProps,
  IProfileDTOProps,
  ScheduleCalendarDTO,
  ProfileDTO,
  ScheduleCalendarMapper,
  ProfileMapper,
  IEmployeeDTOProps,
  EmployeeModel,
  EmployeeMapper,
} from '../internal';
import {WithoutRecursion} from '../type';
import {correctPrice} from '../../services/helpers';

export interface IRewardStatsDTOProps {
  total: string;
  totalAccrued: string;
  totalPaid: string;
  employee: IEmployeeDTOProps;
}

export class RewardStatsDTO {
  public total: string;
  public totalAccrued: string;
  public totalPaid: string;
  public employee: EmployeeModel;

  constructor(props: IRewardStatsDTOProps) {
    this.total = correctPrice(props?.total || 0);
    this.totalAccrued = correctPrice(props?.totalAccrued || 0);
    this.totalPaid = correctPrice(props?.totalPaid || 0);
    this.employee = EmployeeMapper.toEmployeeModel(
      EmployeeMapper.toEmployeeDTO(props?.employee),
    );
  }
}
