import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import ServicesForm, {IServicesFormProps} from '../Forms/ServicesForm';
import {ServiceFormDTO} from '../../../struture';
import {
  CenterModal,
  ICON_NAME,
  IconTittle,
} from '../../../components/lib/DataDisplay';

export default withTranslation()(ServicesSideWindow);

export interface IServicesSideWindowProps
  extends WithTranslation,
    IServicesFormProps {
  companyUuid: string;
  onSuccess: (value: ServiceFormDTO) => Promise<void>;
  visible: boolean;
}

function ServicesSideWindow({
  t,
  companyUuid,
  onSuccess,
  visible,
  editMode,
  onCancel,
  ...rest
}: IServicesSideWindowProps) {
  return (
    <CenterModal
      title={
        <IconTittle
          title={editMode ? t('Service') : t('New service')}
          iconName={ICON_NAME.SERVICE}
        />
      }
      footer={null}
      open={visible}
      onCancel={onCancel}
      data-testid="create-service-form-side-window">
      <ServicesForm
        asModal
        editMode={editMode}
        companyUuid={companyUuid}
        onSuccess={onSuccess}
        onCancel={onCancel}
        {...rest}
      />
    </CenterModal>
  );
}
