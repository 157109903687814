import {Record, List} from 'immutable';
import {
  ClientDTO,
  ClientMapper,
  ClientModel,
  CompanyDTO,
  CompanyMapper,
  CompanyModel,
  ProfileDTO,
  ProfileMapper,
  ProfileModel,
  StoreDocumentDTO,
  StoreDocumentItemDTO,
  StoreDocumentType,
  StoreDTO,
  StoreMapper,
  StoreModel,
  StoreDocumentMapper,
  StoreDocumentItemModel,
} from '../internal';
import {WithoutRecursion} from '../type';
import {StoreDocumentStatus} from '../dto/StoreDocumentDTO';

export interface IStoreDocumentModelReturnType {
  uuid: string;
  doc_type: StoreDocumentType;
  doc_date: string;
  doc_local_number: string;
  doc_input_number: string;
  doc_sum_paid: string;
  doc_sum_total: string;
  doc_comment: string;
  doc_status_text: StoreDocumentStatus;
  created_at: string;
  updated_at: string;
  doc_items: List<StoreDocumentItemModel> | StoreDocumentItemDTO[];
  company_store: StoreModel | StoreDTO;
  company_destination_store: StoreModel | StoreDTO;
  created_by: ProfileModel | ProfileDTO;
  company: CompanyModel | CompanyDTO;
  client: ClientModel | ClientDTO;
}

export class StoreDocumentModel extends Record<IStoreDocumentModelReturnType>({
  uuid: '',
  doc_type: StoreDocumentType.IN,
  doc_date: '',
  doc_local_number: '',
  doc_input_number: '',
  doc_sum_paid: '',
  doc_sum_total: '',
  doc_comment: '',
  doc_status_text: StoreDocumentStatus.DRAFT,
  created_at: '',
  updated_at: '',
  doc_items: List(),
  company_store: {} as StoreModel,
  company_destination_store: {} as StoreModel,
  company: {} as CompanyModel,
  created_by: {} as ProfileModel,
  client: {} as ClientModel,
}) {
  constructor(
    props: StoreDocumentDTO = {} as StoreDocumentDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IStoreDocumentModelReturnType = {
      ...props,
      doc_items:
        withoutRecursion.indexOf(WithoutRecursion.storeDocument) !== -1
          ? props?.doc_items
          : StoreDocumentMapper.toStoreDocumentItemListModel(props?.doc_items)
              .doc_items,
      company_store:
        withoutRecursion.indexOf(WithoutRecursion.store) !== -1
          ? props?.company_store
          : StoreMapper.toStoreDTO(props?.company_store, [
              WithoutRecursion.storeDocument,
              ...withoutRecursion,
            ]),

      company_destination_store:
        withoutRecursion.indexOf(WithoutRecursion.store) !== -1
          ? props?.company_destination_store
          : StoreMapper.toStoreDTO(props?.company_destination_store, [
              WithoutRecursion.storeDocument,
              ...withoutRecursion,
            ]),
      created_by:
        withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
          ? props?.created_by
          : ProfileMapper.toProfileModel(props?.created_by, [
              WithoutRecursion.storeDocument,
              ...withoutRecursion,
            ]),
      company:
        withoutRecursion.indexOf(WithoutRecursion.company) !== -1
          ? props?.company
          : CompanyMapper.toCompanyModel(props?.company, [
              WithoutRecursion.storeDocument,
              ...withoutRecursion,
            ]),

      client:
        withoutRecursion.indexOf(WithoutRecursion.client) !== -1
          ? props?.client
          : ClientMapper.toClientModel(props?.client, true, [
              WithoutRecursion.storeDocument,
              ...withoutRecursion,
            ]),
    };

    super(options);
  }

  get companyModel(): CompanyModel {
    return this.company instanceof CompanyModel
      ? this.company
      : CompanyMapper.toCompanyModel(this.company);
  }

  get createdByModel(): ProfileModel {
    return this.created_by instanceof ProfileModel
      ? this.created_by
      : ProfileMapper.toProfileModel(this.created_by);
  }

  get companyStoreModel(): StoreModel {
    return this.company_store instanceof StoreModel
      ? this.company_store
      : StoreMapper.toStoreModel(this.company_store);
  }

  get companyDestinationStoreModel(): StoreModel {
    return this.company_destination_store instanceof StoreModel
      ? this.company_destination_store
      : StoreMapper.toStoreModel(this.company_destination_store);
  }
  get clientModel(): ClientModel {
    return this.client instanceof ClientModel
      ? this.client
      : ClientMapper.toClientModel(this.client);
  }
}
