import {Dispatch} from 'redux';
import {ABONEMENT} from '../constants';
import {AbonementModel} from '../../struture';
import {ApiError} from '../../services/types';

export interface ISetAbonementParam {
  type: ABONEMENT;
  abonement: AbonementModel | null;
}

export interface IUpdateAbonementParam {
  type: ABONEMENT;
  abonement: AbonementModel;
}

export interface IResetAbonementParam {
  type: ABONEMENT;
}

export interface IErrorAbonementParam {
  type: ABONEMENT;
  error: ApiError | undefined;
}

export function setAbonement(
  abonement: AbonementModel | null,
): (dispatch: Dispatch) => Promise<ISetAbonementParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: ABONEMENT.SET_ABONEMENT,
      abonement,
    });
  };
}

export function updateAbonement(
  abonement: AbonementModel,
): (dispatch: Dispatch) => Promise<IUpdateAbonementParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: ABONEMENT.UPDATE_ABONEMENT,
      abonement,
    });
  };
}

export function resetAbonement(): (
  dispatch: Dispatch,
) => Promise<IResetAbonementParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: ABONEMENT.RESET_ABONEMENT,
    });
  };
}

export function errorAbonement(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorAbonementParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: ABONEMENT.ERROR_ABONEMENT,
      error,
    });
  };
}
