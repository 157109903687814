import React from 'react';
import {useTranslation} from 'react-i18next';
import {len, eq} from '../../../services/helpers';
import {
  DeleteButton,
  IDeleteButtonProps,
} from '../../../components/lib/DataDisplay';
import {TextFormat} from '../../../components/lib/Format';
import {useDelete} from '../../../components/lib/libV2/hooks';
import {EmployeeModel} from '../../../struture';

export interface IEmployeesDeleteButtonProps extends IDeleteButtonProps {
  employees: EmployeeModel[];
  onSuccess: (ids: string[]) => Promise<void>;
  onError?: () => void;
  children?: React.ReactNode;
  title?: string;
}

export default function EmployeesDeleteButton({
  children,
  employees = [],
  onSuccess,
  onError,
  title,
  ...rest
}: IEmployeesDeleteButtonProps): JSX.Element {
  const {t} = useTranslation();

  const {handleDelete, loading} = useDelete({
    onSuccess,
    entity: employees,
    notifyTitle: 'Employees',
  });

  return (
    <DeleteButton
      title={title}
      tooltipTitle={t('Delete a company employee')}
      confirmTitle={
        <TextFormat breakWord>
          {t('Are you sure you want to delete')} <b>{len(employees)}</b>
          {` ${eq(len(employees), 1) ? t('employee') : t('employees')}`}?
        </TextFormat>
      }
      onConfirm={handleDelete}
      loading={loading}
      cancelButtonText={t('Cancel')}
      {...rest}
    />
  );
}
