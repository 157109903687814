import React, {forwardRef, useCallback, useState} from 'react';
import styled, {useTheme} from 'styled-components';
import {StyledTitle} from '../../../components/lib/Styled';
import {Button} from '../../../components/lib/DataDisplay';
import {useTranslation} from 'react-i18next';
import {
  IUseStateRewardListReturnType,
  useStoredCompanies,
} from '../../../hooks';
import {OverviewBottomView} from '../../../components/lib/Layout';
import {
  correctPrice,
  toDateByFormat,
  toMoment,
} from '../../../services/helpers';
import {LocalStorageItems} from '../../../services/const';
import {LoadingOutlined, PrinterOutlined} from '@ant-design/icons';

export interface IRewardListBottomViewProps
  extends Pick<
    IUseStateRewardListReturnType,
    'handlePrintRewardDocument' | 'year' | 'month' | 'stats'
  > {}

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  gap: 2px;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 2px;
`;

const StyledPrice = styled(StyledTitle)`
  font-size: 30px;
`;

const StyledPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 5px;
`;

const Title = styled(StyledTitle)`
  margin-bottom: 5px;
`;

const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${({theme}) => theme.colors.black};
  width: auto;

  &&& {
    padding: 18.11px;
  }

  &&&& * {
    color: ${({theme}) => theme.colors.black};
  }

  &&&:hover,
  &&&:active,
  &&&:focus {
    border: 1px solid ${({theme}) => theme.colors.black};
    opacity: 0.8;
  }
`;

const StyledPrinterOutlined = styled(PrinterOutlined)`
  font-size: 15px;

  &&& {
    color: ${({theme}) => theme.colors.black};
  }
`;

export const RewardListBottomView = forwardRef(function RewardBottomView(
  {stats, month, year, handlePrintRewardDocument}: IRewardListBottomViewProps,
  ref: any,
): JSX.Element {
  const {t} = useTranslation();
  const {defaultCompany} = useStoredCompanies();
  const theme: any = useTheme();

  const [loading, setLoading] = useState(false);

  const language =
    window.localStorage.getItem(LocalStorageItems.language) === 'ua'
      ? 'uk'
      : window.localStorage.getItem(LocalStorageItems.language);

  const date = toDateByFormat(
    toMoment(new Date())
      .locale(language || 'en')
      .set({month: month - 1, year}),
    'MMMM YYYY',
  );

  const totalPaid = Number(stats?.totalPaid || 0);
  const total = Number(stats?.total || 0);

  const printRewardDocument = useCallback(async () => {
    setLoading(true);
    await handlePrintRewardDocument();
    setLoading(false);
  }, [handlePrintRewardDocument]);

  return (
    <div ref={ref}>
      <OverviewBottomView>
        <StyledWrapper>
          <StyledButton type="default" onClick={printRewardDocument}>
            {loading ? <LoadingOutlined /> : <StyledPrinterOutlined />}
            <StyledTitle fontSize={14}>{t('Print')}</StyledTitle>
          </StyledButton>
          <StyledContainer>
            <StyledTitle fontSize={14}>{`${t(
              'The total amount for',
            )} ${date}:`}</StyledTitle>
            <StyledPriceContainer>
              {total > 0 ? <Title>{t('payed')}</Title> : null}
              <StyledPrice>
                {`${defaultCompany?.currency_symbol || ''} ${correctPrice(
                  totalPaid || 0,
                )}`}
              </StyledPrice>
              <Title>{`${t('with')} ${correctPrice(total || 0)}`}</Title>
            </StyledPriceContainer>
            {totalPaid === 0 ? null : totalPaid < total ? (
              <StyledTitle $color={theme.colors.error}>
                {`${t('Balance due').toLowerCase()} ${
                  defaultCompany?.currency_symbol || ''
                }, ${correctPrice(total - totalPaid)}`}
              </StyledTitle>
            ) : totalPaid > total ? (
              <StyledTitle $color={theme.colors.success}>
                {`${t('the employee owes ')} ${
                  defaultCompany?.currency_symbol || ''
                }, ${correctPrice(totalPaid - total)}`}
              </StyledTitle>
            ) : null}
          </StyledContainer>
        </StyledWrapper>
      </OverviewBottomView>
    </div>
  );
});
