import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {ScheduleCalendarProps, isFunction} from '../../../services/helpers';
import {ChildrenMustBeAFunction} from '../../../components/lib/Errors';
import {IDetailsHeaderProps} from '../../../components/lib/General';
import {ScheduleCalendarModel} from '../../../struture';
import {useNavigate, useParams} from 'react-router';
import {Routes} from '../../../services/types';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';

export interface IDashboardScheduleDetailProps extends ScheduleCalendarProps {
  schedule: ScheduleCalendarModel;
  children: (
    value: Pick<IDetailsHeaderProps, 'routes'> & {navigateGoBack: () => void},
  ) => React.ReactNode;
}

export function DashboardScheduleDetail({
  schedule,
  children,
}: IDashboardScheduleDetailProps): JSX.Element {
  const {scheduleId} = useParams();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const routes = useBreadcrumb([
    {
      path: `${Routes.app}/${Routes.calendar}/entry/${scheduleId || ''}`,
      breadcrumbName: `${
        schedule?.schedule_number ? `#${schedule?.schedule_number}` : ''
      }`,
    },
  ]);

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.dashboard}`);
  }, [navigate]);

  if (!isFunction(children)) {
    throw new ChildrenMustBeAFunction('DashboardScheduleDetail');
  }

  return <>{children({routes, navigateGoBack})}</>;
}
