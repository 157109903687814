import {PERSONALITY_TYPE, TIME_LIST_SERVICES} from '../type';
import {
  ServiceModel,
  EmployeeMapper,
  EmployeeFormDTO,
  GroupDTO,
  GroupMapper,
} from '../internal';
import {correctPrice} from '../../helpers';

export class ServiceFormDTO {
  public uuid: string;

  public title: string;

  public description: string;

  public manager_uid: EmployeeFormDTO | string;

  public payment_group_uuid: GroupDTO | string;

  public period_amount: number;

  public price: string;

  public net_price: string;

  public period: TIME_LIST_SERVICES;

  public personality: PERSONALITY_TYPE;

  constructor(props?: ServiceModel, editMode?: boolean) {
    this.uuid = props?.uuid || '';
    this.title = props?.title || '';
    this.description = props?.description || '';
    this.manager_uid = editMode
      ? EmployeeMapper.toEmployeeFormDTO(props?.managerModel)
      : '';
    this.payment_group_uuid = editMode
      ? props?.payment_group?.uuid
        ? GroupMapper.toGroupDTO(props?.payment_group as any)
        : ''
      : '';
    this.price = correctPrice(props?.price || 0);
    this.net_price = correctPrice(props?.net_price || 0);
    this.period_amount = props?.period_amount || 0;
    this.period = props?.period || TIME_LIST_SERVICES.HOUR;
    this.personality = props?.personality || PERSONALITY_TYPE.INDIVIDUAL;
  }
}
