import React, {useCallback} from 'react';
import {
  AlphaNameAmount,
  AlphaNameFormDTO,
  AlphaNameMapper,
  AlphaNameStatuses,
  FinancialSettingsDTO,
  ModuleInputParameterActionType,
  ModuleInputParameterDTO,
  ModuleInputParameterFiledType,
} from '../../../struture';
import {
  ListLayoutItem,
  IListLayoutItemChildProps,
} from '../../../components/lib/Layout';
import {SettingsModuleAlphaNameItem, SettingsModuleListHeader} from '../Show';
import styled, {css} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useStoredCompanies, useStoredProfile} from '../../../hooks';
import {useModal} from '../../../components/lib/libV2/hooks';
import {LocalStorageItems} from '../../../services/const';
import {transliterate} from 'transliteration';
import {
  compose,
  removeAllSpace,
  toShortLime,
  isFunction,
} from '../../../services/helpers';

const PersonalizationSmsSideWindowLazy = React.lazy(
  () => import('../Show/PersonalizationSmsSideWindow'),
);

export interface ISettingsModuleInputParameterListProps {
  moduleInputParameters: ModuleInputParameterDTO[] | null;
  isLinking: boolean;
}

export interface ISettingsModuleInputParameterListSceneItem {
  children:
    | ((value: IListLayoutItemChildProps) => React.ReactNode)
    | React.ReactNode;
  sideWindow?: React.ReactNode;
  navigate?: (item: ModuleInputParameterDTO) => void | Promise<void>;
}

const StyledContainer = styled.div<{$withPadding?: boolean}>`
  width: 100%;

  ${({$withPadding}) =>
    $withPadding &&
    css`
      padding-bottom: 10px;
    `}
`;

export function SettingsModuleInputParameterList({
  moduleInputParameters,
  isLinking,
}: ISettingsModuleInputParameterListProps) {
  const {t} = useTranslation();

  const {settings, defaultCompany} = useStoredCompanies();
  const {profile} = useStoredProfile();

  const {
    handleCancel: handleCancelPersonalizationSms,
    handleOnInit: handleOnInitPersonalizationSms,
    visible: visiblePersonalizationSms,
  } = useModal();

  const getPersonalizationFromParams = useCallback(
    (
      price: string,
    ): {
      personalization: AlphaNameFormDTO;
      status: AlphaNameStatuses;
      expiredAt: string;
    } => {
      if (settings) {
        const period = localStorage.getItem(
          `${profile?.uuid}${LocalStorageItems.alphaNameFormPeriod}`,
        );

        let company_alfa_name = settings?.settings?.company_alfa_name;

        if (!company_alfa_name) {
          company_alfa_name = compose<string>(
            toShortLime(11),
            transliterate,
            removeAllSpace,
          )(defaultCompany?.title || '');
        }

        const alphaNameFormDTO = AlphaNameMapper.toAlphaNameFormDTO(
          {...settings?.settings, company_alfa_name} as FinancialSettingsDTO,
          {
            price,
            period: (period as AlphaNameAmount) || AlphaNameAmount.ThreeMonth,
          },
        );

        return {
          personalization: alphaNameFormDTO,
          status: settings?.settings?.company_alfa_name_order_status,
          expiredAt: settings?.settings?.company_alfa_name_expire_at,
        };
      }
      return {
        personalization: AlphaNameMapper.toAlphaNameFormDTO(
          {} as FinancialSettingsDTO,
        ),
        status: AlphaNameStatuses.Unknown,
        expiredAt: '',
      };
    },
    [defaultCompany?.title, profile?.uuid, settings],
  );

  const renderButtonScene = useCallback(
    (
      inputParameter: ModuleInputParameterDTO,
    ): ISettingsModuleInputParameterListSceneItem => {
      switch (inputParameter.action) {
        case ModuleInputParameterActionType.AlphaName:
          return {
            children: ({isPhone}) => (
              <SettingsModuleAlphaNameItem
                inputParameter={inputParameter}
                isLinking={isLinking}
                isPhone={isPhone}
              />
            ),
            sideWindow: (
              <PersonalizationSmsSideWindowLazy
                {...getPersonalizationFromParams(inputParameter?.price)}
                visible={visiblePersonalizationSms}
                onCancel={handleCancelPersonalizationSms}
              />
            ),
            navigate: handleOnInitPersonalizationSms,
          };

        default:
          return {children: null};
      }
    },
    [
      handleCancelPersonalizationSms,
      handleOnInitPersonalizationSms,
      isLinking,
      getPersonalizationFromParams,
      visiblePersonalizationSms,
    ],
  );

  const renderScene = useCallback(
    (
      inputParameter: ModuleInputParameterDTO,
    ): ISettingsModuleInputParameterListSceneItem => {
      switch (inputParameter.field) {
        case ModuleInputParameterFiledType.Button:
          return renderButtonScene(inputParameter);

        default:
          return {children: null};
      }
    },
    [renderButtonScene],
  );

  return (
    <>
      {moduleInputParameters?.length ? (
        <>
          <SettingsModuleListHeader
            title={t('Settings')}
            disabled={!isLinking}
          />
          {moduleInputParameters.map((inputParameter, index) => {
            const {children, sideWindow, navigate} =
              renderScene(inputParameter);

            return (
              <React.Fragment key={String(index)}>
                <StyledContainer
                  $withPadding={moduleInputParameters?.length > 1}>
                  <ListLayoutItem<ModuleInputParameterDTO>
                    disabledItem={() => !isLinking}
                    withoutLine={moduleInputParameters?.length <= 1}
                    item={inputParameter}
                    handleNavigate={isLinking ? navigate : undefined}>
                    {(value) =>
                      isFunction(children) ? children(value) : children
                    }
                  </ListLayoutItem>
                </StyledContainer>
                {sideWindow || null}
              </React.Fragment>
            );
          })}
        </>
      ) : null}
    </>
  );
}
