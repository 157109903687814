import {Record} from 'immutable';
import Company, {CompanyProps} from './Company';
import Services, {ServicesProps} from './Services';
import Employee, {EmployeeProps} from './Employee';

export interface ClientOrderProps {
  scheduled_date: string;
  uuid: string;
  status: string;
  price: number;
  company: CompanyProps;
  service: ServicesProps;
  employee: EmployeeProps;
  created_at: string;
  updated_at: string;
}
/**
 * @class ClientOrder
 * @classdesc Country model.
 * @property {Date} scheduled_date
 * @property {String} uuid
 * @property {Number} status
 * @property {Number} price
 * @property {Object} company
 * @property {Object} service
 * @property {Object} employee
 * @property {Date} created_at
 * @property {Date} updated_at
 */
export default class ClientOrder extends Record({
  scheduled_date: '',
  uuid: '',
  status: '',
  price: 0,
  company: {},
  service: {},
  employee: {},
  created_at: null,
  updated_at: null,
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(props: ClientOrderProps = {} as ClientOrderProps) {
    super({
      ...props,
      // @ts-ignore
      company: new Company(props?.company || props.Company), // || props.Company
      service: new Services(props.service || (props as any).Service), // || props.Service
      employee: new Employee(props.employee || (props as any).Employee), // || props.Employee
    });
  }
}
