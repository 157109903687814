import {List} from 'immutable';
import {
  ScheduleCalendarDTO,
  IScheduleCalendarDTOProps,
  ScheduleCalendarModel,
  ScheduleCalendarListModel,
  ScheduleCalendarFormDTO,
  ScheduleCloseDTO,
  AgendaItemDTO,
  ScheduleChangeTimeFormDTO,
  ScheduleMessageFormDTO,
  IScheduleMessageFormAdditionalProps,
  IScheduleCloseAdditionalParams,
} from '../internal';
import {listToArray} from '../../helpers';

import {WithoutRecursion} from '../type';

export interface IScheduleCalendarListDTO {
  schedules: ScheduleCalendarDTO[];
  total: number;
}

export class ScheduleCalendarMapper {
  public static toScheduleCalendarDTO(
    schedule: ScheduleCalendarModel | IScheduleCalendarDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): ScheduleCalendarDTO {
    return new ScheduleCalendarDTO(schedule, [
      WithoutRecursion.scheduleCalendar,
      ...withoutRecursion,
    ]);
  }

  public static toScheduleCalendarListDTO(
    schedules: List<ScheduleCalendarModel> | IScheduleCalendarDTOProps[],
    total?: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): IScheduleCalendarListDTO {
    const scheduleList = List.isList(schedules)
      ? listToArray<ScheduleCalendarModel>(schedules)
      : schedules;

    return {
      schedules: scheduleList?.map((schedule) =>
        ScheduleCalendarMapper.toScheduleCalendarDTO(schedule, [
          WithoutRecursion.scheduleCalendar,
          ...withoutRecursion,
        ]),
      ),
      total: total || 0,
    };
  }

  public static toScheduleCalendarModel(
    scheduleDTO: ScheduleCalendarDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): ScheduleCalendarModel {
    return new ScheduleCalendarModel(scheduleDTO, [
      WithoutRecursion.scheduleCalendar,
      ...withoutRecursion,
    ]);
  }

  public static toScheduleCalendarListModel(
    scheduleDTOs: ScheduleCalendarDTO[],
    total?: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): ScheduleCalendarListModel {
    return new ScheduleCalendarListModel({schedules: scheduleDTOs, total}, [
      WithoutRecursion.scheduleCalendar,
      ...withoutRecursion,
    ]);
  }

  public static toScheduleCalendarFormDTO(
    scheduleModel?: ScheduleCalendarModel,
    send_sms_notification?: boolean,
    editMode?: boolean,
  ): ScheduleCalendarFormDTO {
    return new ScheduleCalendarFormDTO(
      scheduleModel,
      send_sms_notification,
      editMode,
    );
  }

  public static toScheduleCloseDTO(
    schedule: ScheduleCalendarModel,
    additionalProps: IScheduleCloseAdditionalParams,
  ): ScheduleCloseDTO {
    return new ScheduleCloseDTO(schedule, additionalProps);
  }

  public static toScheduleChangeTimeFormDTO(
    scheduleModel?: ScheduleCalendarModel,
    send_sms_notification?: boolean,
  ): ScheduleChangeTimeFormDTO {
    return new ScheduleChangeTimeFormDTO(scheduleModel, send_sms_notification);
  }

  public static toAgendaItemDTO(
    schedule: ScheduleCalendarModel,
  ): AgendaItemDTO {
    return new AgendaItemDTO(schedule);
  }

  public static toScheduleMessageFormDTO(
    schedule?: ScheduleCalendarModel,
    additionalProps: IScheduleMessageFormAdditionalProps = {},
  ): ScheduleMessageFormDTO {
    return new ScheduleMessageFormDTO(schedule, additionalProps);
  }
}
