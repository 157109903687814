import {Record, List} from 'immutable';
import {IListProps} from './List';
import {ClientDTO, ClientModel, IClientListStatsProps} from '../internal';
import {WithoutRecursion} from '../type';

interface IClientListModelProps extends IListProps {
  clients: ClientDTO[];
  stats: IClientListStatsProps;
  keywords?: string;
}

export interface IClientListModelReturnType extends IListProps {
  clients: List<ClientModel>;
  stats: IClientListStatsProps;
  keywords?: string;
}

export class ClientListModel extends Record<IClientListModelReturnType>({
  clients: List(),
  total: 0,
  stats: {} as IClientListStatsProps,
  keywords: '',
}) {
  constructor(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    {
      clients = [],
      ...props
    }: IClientListModelProps = {} as IClientListModelProps,
    withoutMembers: boolean,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IClientListModelReturnType = {
      ...props,
      clients: List(
        clients.map(
          (client) => new ClientModel(client, withoutMembers, withoutRecursion),
        ),
      ),
    };

    super(options);
  }
}
