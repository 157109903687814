import {Record} from 'immutable';
import {DayOffDTO} from '../internal';

export interface IEmployeeDayOffReturnType {
  uuid: string;
  start_date: string;
  end_date: string;
  created_at: string;
  comment: string;
}

export class DayOffModel extends Record<IEmployeeDayOffReturnType>({
  uuid: '',
  start_date: '',
  end_date: '',
  created_at: '',
  comment: '',
}) {
  constructor(props: DayOffDTO = {} as DayOffDTO) {
    super({...(props as any)});
  }
}
