import React from 'react';
import {useTranslation} from 'react-i18next';
import {IConfirmActionButtonProps} from '../../../components/lib/General';
import {TextFormat} from '../../../components/lib/Format';
import {len, eq} from '../../../services/helpers';
import {ReportModel} from '../../../struture';
import {useDelete} from '../../../components/lib/libV2/hooks';
import {DeleteButton} from '../../../components/lib/DataDisplay';

export interface IReportDeleteButtonProps
  extends Omit<IConfirmActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: string[]) => Promise<void>;
  onError?: () => void;
  reports: ReportModel[];
  title?: string;
}

export function ReportDeleteButton({
  children,
  reports = [],
  onSuccess,
  onError,
  title,
  ...rest
}: IReportDeleteButtonProps) {
  const {t} = useTranslation();

  const {handleDelete, loading} = useDelete({
    onSuccess,
    entity: reports,
    notifyTitle: 'Report',
  });

  const {reportNS, tReady, defaultNS, i18nOptions, ...restProps}: any = rest;
  return (
    <DeleteButton
      title={title}
      tooltipTitle={t('Delete report')}
      confirmTitle={
        <TextFormat breakWord>
          {t('Are you sure you want to delete')} <b>{len(reports)}</b>{' '}
          {eq(len(reports), 1) ? t('report') : t('reports')}?
        </TextFormat>
      }
      onConfirm={handleDelete}
      loading={loading}
      cancelButtonText={t('Cancel')}
      {...rest}
    />
  );
}
