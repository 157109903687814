import React from 'react';
import styled, {css} from 'styled-components';
import {Text} from '../../Format';
import {isUrl, isPath} from '../../../../services/helpers';
import {Logo} from '../../DataDisplay';

export interface IHeaderLogoProps {
  headerTitle?: string;
  className?: string;
  collapsed: boolean;

  isBigPhone: boolean;
}

const StyledHeaderLogo = styled.div<{collapsed: boolean; $isBigPhone: boolean}>`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: ${({theme}) => theme.colors.third};

  ${({$isBigPhone}) =>
    $isBigPhone &&
    css`
      width: 70%;
    `}

  ${({collapsed}) =>
    collapsed &&
    css`
      position: absolute;
      opacity: 0;
    `}
`;

const StyledText = styled(Text)`
  color: ${({theme}) => theme.colors.primary};
  font-size: 15px;
`;

export default function HeaderLogo({
  headerTitle = 'Company Name',
  className,
  collapsed,
  isBigPhone,
}: IHeaderLogoProps): JSX.Element {
  return (
    <StyledHeaderLogo
      className={className}
      collapsed={collapsed}
      $isBigPhone={isBigPhone}>
      {isUrl(headerTitle) || isPath(headerTitle) ? (
        <Logo url={headerTitle} />
      ) : (
        <>
          <StyledText bold>{headerTitle}</StyledText>
        </>
      )}
    </StyledHeaderLogo>
  );
}
