import {ClientModel} from '../internal';
import {List} from 'immutable';

export class ClientGroupFormDTO {
  public uuid: string;

  public client_group_name: string;

  public client_uuids: List<ClientModel> | ClientModel[];

  constructor(props?: ClientModel, editMode?: boolean) {
    this.uuid = props?.uuid || '';
    this.client_group_name = props?.client_group_name || '';
    this.client_uuids = editMode ? props?.membersModelList || [] : [];
  }
}
