import React, {useCallback, useState} from 'react';
import {StyledDescription, StyledTitle} from '../../../components/lib/Styled';
import {SettingsModuleDependencyModel} from '../../../struture';
import {textToUpperCase, isFunction} from '../../../services/helpers';
import styled, {css, useTheme} from 'styled-components';
import {Button, ColorLine} from '../../../components/lib/DataDisplay';
import {useTranslation} from 'react-i18next';
import {useStoredCompanies} from '../../../hooks';

export interface ISettingsModuleDependencyListItemProps {
  dependency: SettingsModuleDependencyModel;
  handleLinkinModuleDependency?: (dependencyUuid: string) => Promise<void>;
  isLinking: boolean;
  loadingDependencyUuid: string;
  isPhone?: boolean;
}

const StyledTextContainer = styled.div`
  flex-direction: row;
  align-items: center;
`;

const StyledButton = styled(Button)<{disabled: boolean}>`
  width: auto;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;

  background-color: ${({theme}) => theme.colors.link};

  ${({disabled, theme}) =>
    disabled &&
    css`
      background-color: ${theme.colors.lightDisable};
    `}
`;

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100%;
  align-items: center;
`;

const StyledTextWrapper = styled.div<{$withSmallPadding?: boolean}>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  padding: 10px 25px;

  ${({$withSmallPadding}) =>
    $withSmallPadding &&
    css`
      padding: 10px 5px;
    `}
`;

export function SettingsModuleDependencyListItem({
  dependency,
  handleLinkinModuleDependency,
  isLinking,
  loadingDependencyUuid,
  isPhone,
}: ISettingsModuleDependencyListItemProps): JSX.Element {
  const theme: any = useTheme();
  const {t} = useTranslation();
  const {defaultCompany} = useStoredCompanies();

  const [loading, setLoading] = useState(false);

  const linkinModuleDependency = useCallback(async () => {
    setLoading(true);
    if (isFunction(handleLinkinModuleDependency)) {
      await handleLinkinModuleDependency(dependency?.uuid);
    }
    setLoading(false);
  }, [dependency?.uuid, handleLinkinModuleDependency]);

  const isActive = (dependency?.dep_company_usage?.total || 0) > 0;

  const limit = dependency?.dep_company_usage?.total;

  return (
    <StyledContainer>
      {isActive && isLinking ? (
        <ColorLine color={theme.colors.success} />
      ) : null}
      <StyledTextWrapper $withSmallPadding={isPhone}>
        <StyledTextContainer>
          <StyledTitle $color={isLinking ? undefined : theme.colors.disable}>
            {textToUpperCase(dependency?.dep_title)}
          </StyledTitle>
        </StyledTextContainer>
        <StyledDescription $withoutEllipsis>
          {dependency?.dep_description}
        </StyledDescription>
        <StyledDescription
          $withoutEllipsis
          $color={isLinking ? theme.colors?.success : undefined}>
          {`${defaultCompany?.currency_symbol} ${dependency?.dep_price}${
            isActive ? ` / ${t('available')}: ${limit}` : ''
          }`}
        </StyledDescription>
      </StyledTextWrapper>
      {isFunction(handleLinkinModuleDependency) && isLinking ? (
        <StyledButton
          size={isPhone ? 'small' : 'middle'}
          type="primary"
          loading={loading || loadingDependencyUuid === dependency?.uuid}
          disabled={loadingDependencyUuid === dependency?.uuid}
          onClick={linkinModuleDependency}>
          {loadingDependencyUuid === dependency?.uuid ? (
            // <ActivityIndicator />
            <></>
          ) : (
            <StyledTitle>{`+${dependency?.dep_limit}`}</StyledTitle>
          )}
        </StyledButton>
      ) : null}
    </StyledContainer>
  );
}
