import * as React from 'react';
import {List} from 'immutable';
import {
  useStoreList,
  IUseStoreListProps,
  IUseStoreListReturnType,
} from './useStoreList';
import {StoreModel} from '../struture';
import {deleteStore} from '../services/api/store';
import {
  setStoreList as storeSetStoreList,
  deleteStoreFromList as storeDeleteStore,
  loadMoreStoreList as storeLoadMoreStoreList,
} from '../store/actions';
import {useSelector, useDispatch} from 'react-redux';
import {RootState} from '../store/reducers';
import {REDUX_STATUS} from '../services/types';
import {head} from '../services/helpers';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../contex';

export interface IUseStateStoreListProps extends IUseStoreListProps {}

export interface IStoreSearchProps {
  keywords: string;
  showLoading?: boolean;
  limit?: number;
}

export interface IUseStateStoreListReturnType
  extends Omit<IUseStoreListReturnType, 'entityList'> {
  storeList: List<StoreModel> | null;
  handleDeleteStores: (ids: string[]) => Promise<void>;
  handleSearchStores: (value: IStoreSearchProps) => Promise<void>;
  handleLoadMoreStores: () => Promise<void>;
  handleRefreshStores: (
    value: Partial<IUseStoreListProps> & {page: number},
  ) => Promise<void>;
  loadingMore: boolean;
  page: number;
}

export function useStoredStoreList(
  {
    companyUuid,
    loadOnInit,
    ...rest
  }: IUseStateStoreListProps = {} as IUseStateStoreListProps,
): IUseStateStoreListReturnType {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const [loadingMore, setLoadingMore] = useState(false);

  const {
    status: storedStoreListStatus,
    loading: storedStoreListLoading,
    storeList,
    ...storedStoreListParams
  } = useSelector(({storeList}: RootState) => storeList);

  const storeTotal = storeList?.total || 0;
  const storeKeywords = storeList?.keywords || '';
  const storedPage = storeList?.page || 1;

  const dispatch = useDispatch<any>();

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: storeListLoading,
    ...storesParams
  } = useStoreList({
    companyUuid,
    loadOnInit: loadOnInit || storedStoreListStatus !== REDUX_STATUS.SUCCEEDED,
    ...rest,
  });

  useEffect(() => {
    if (
      entityList &&
      List.isList(entityList?.stores) &&
      storedStoreListStatus !== REDUX_STATUS.SUCCEEDED
    ) {
      dispatch(storeSetStoreList(entityList));
    }
  }, [dispatch, entityList, storedStoreListStatus]);

  const handleDeleteStores = React.useCallback(
    async (ids: string[]) => {
      try {
        await deleteStore(ids);

        setTimeout(() => {
          dispatch(storeDeleteStore(head(ids)));
        }, 100);
        alert('success', t('Store'), t('Store delete success'));
      } catch (error: any) {
        alert(
          'error',
          t('Store'),
          `${t('An error occurred during delete store')}: ${error?.message}`,
        );
      }
    },
    [alert, dispatch, t],
  );

  const handleSearchStores = React.useCallback(
    async ({limit = 10, keywords, showLoading}: IStoreSearchProps) => {
      setLoadingMore(true);
      const storeListModel = await refresh({
        offset: 0,
        limit,
        keywords,
        showLoading,
      });

      if (storeListModel && List.isList(storeListModel?.stores)) {
        const updatedStoreList = storeListModel.update(
          'keywords',
          () => keywords,
        );

        dispatch(storeSetStoreList(updatedStoreList));
      }
      setLoadingMore(false);
    },
    [dispatch, refresh],
  );

  const handleLoadMoreStores = useCallback(async () => {
    if (
      List.isList(storeList) &&
      storeList?.size < storeTotal &&
      !loadingMore
    ) {
      setLoadingMore(true);
      const storeListModel = await refresh({
        offset: storeList?.size,
        limit: 10,
        showLoading: false,
      });

      if (storeListModel && List.isList(storeListModel?.stores)) {
        dispatch(storeLoadMoreStoreList(storeListModel));
      }

      setLoadingMore(false);
    }
  }, [dispatch, loadingMore, refresh, storeList, storeTotal]);

  const handleRefreshStores = useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = false,
      page,
    }: Partial<IUseStoreListProps> & {page: number}) => {
      setLoadingMore(true);
      const storeListModel = await refresh({
        offset,
        limit,
        showLoading,
      });

      if (storeListModel && List.isList(storeListModel?.stores)) {
        const updatedStoreList = storeListModel.update('page', () => page);

        dispatch(storeSetStoreList(updatedStoreList));
      }
      setLoadingMore(false);
    },
    [dispatch, refresh],
  );

  return {
    ...storesParams,
    ...storedStoreListParams,
    storeList: storeList?.stores || null,
    total: storeTotal,
    loadingMore,
    offset,
    limit,
    refresh,
    loading:
      (!List.isList(storeList?.stores) && storeListLoading) ||
      !storedStoreListLoading,
    handleDeleteStores,
    handleSearchStores,
    handleLoadMoreStores,
    handleRefreshStores,
    keywords: storeKeywords,
    page: storedPage,
  };
}
