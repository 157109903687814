import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {IActionButtonProps} from '../../../components/lib/General';
import {StatusFormDTO, StatusModel} from '../../../struture';
import {useModal} from '../../../components/lib/libV2/hooks';
import {EditButton, SuspenseEmpty} from '../../../components/lib/DataDisplay';

const StatusSideWindowLazy = React.lazy(
  () => import('../Show/StatusSideWindow'),
);

export interface IStatusEditButtonProps
  extends Omit<IActionButtonProps, 'children'> {
  onSuccess: (value: StatusFormDTO) => Promise<StatusModel | void>;
  onCancel?: () => void;
  children?: React.ReactNode;
  status: StatusFormDTO;
}

export default function StatusEditButton({
  children,
  status,
  onSuccess,
  onCancel,
  ...rest
}: IStatusEditButtonProps) {
  const {t} = useTranslation();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <EditButton
        tooltipTitle={t('Edit status')}
        onClick={handleOnInit}
        disabled={false}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <StatusSideWindowLazy
          editMode
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          status={status}
          visible={visible}
        />
      </Suspense>
    </>
  );
}
