import * as React from 'react';
import {List} from 'immutable';
import {
  useStatusCategoryList,
  IUseStatusCategoryListProps,
  IUseStatusCategoryListReturnType,
} from './useStatusCategoryList';
import {StatusCategoryModel} from '../struture';
import {
  setStatusCategoryList as storeSetStatusCategoryList,
  loadMoreStatusCategoryList as storeLoadMoreStatusCategoryList,
} from '../store/actions';
import {useSelector, useDispatch} from 'react-redux';
import {RootState} from '../store/reducers';
import {REDUX_STATUS} from '../services/types';
import {useCallback, useEffect, useState} from 'react';

export interface IUseStateStatusCategoryListProps
  extends IUseStatusCategoryListProps {}

export interface IStatusCategorySearchProps {
  keywords: string;
  showLoading?: boolean;
  limit?: number;
}

export interface IUseStateStatusCategoryListReturnType
  extends Omit<IUseStatusCategoryListReturnType, 'entityList'> {
  categoryList: List<StatusCategoryModel> | null;
  handleSearchStatusCategories: (
    value: IStatusCategorySearchProps,
  ) => Promise<void>;
  handleLoadMoreStatusCategories: () => Promise<void>;
  handleRefreshStatusCategories: (
    value: Partial<IUseStatusCategoryListProps> & {page: number},
  ) => Promise<void>;
  status: REDUX_STATUS;
  loadingMore: boolean;
  page: number;
}

export function useStoredStatusCategoryList(
  {
    loadOnInit = true,
    ...rest
  }: IUseStateStatusCategoryListProps = {} as IUseStateStatusCategoryListProps,
): IUseStateStatusCategoryListReturnType {
  const [loadingMore, setLoadingMore] = useState(false);

  const {
    status: storedStatusCategory,
    loading: storedStatusCategoryListLoading,
    categoryList,
    total: storeTotal,
    keywords: storeKeywords,
    page: storedPage,
    ...storedStatusCategoryListParams
  } = useSelector(({statusCategoryList}: RootState) => statusCategoryList);

  const dispatch = useDispatch<any>();

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: categoryListLoading,
    total,
    ...categoriesParams
  } = useStatusCategoryList({
    loadOnInit: loadOnInit || storedStatusCategory !== REDUX_STATUS.SUCCEEDED,
    ...rest,
  });

  useEffect(() => {
    if (
      List.isList(entityList) &&
      storedStatusCategory !== REDUX_STATUS.SUCCEEDED
    ) {
      dispatch(storeSetStatusCategoryList(entityList, '', total));
    }
  }, [dispatch, entityList, storedStatusCategory, total]);

  const handleSearchStatusCategories = React.useCallback(
    async ({limit = 10, keywords, showLoading}: IStatusCategorySearchProps) => {
      setLoadingMore(true);
      const categoryListModel = await refresh({
        offset: 0,
        limit,
        keywords,
        showLoading,
      });

      if (categoryListModel && List.isList(categoryListModel?.categories)) {
        dispatch(
          storeSetStatusCategoryList(
            categoryListModel.categories,
            keywords,
            categoryListModel.total,
          ),
        );
      }
      setLoadingMore(false);
    },
    [dispatch, refresh],
  );

  const handleLoadMoreStatusCategories = useCallback(async () => {
    if (
      List.isList(categoryList) &&
      categoryList?.size < storeTotal &&
      !loadingMore
    ) {
      setLoadingMore(true);
      const categoryListModel = await refresh({
        offset: categoryList?.size,
        limit: 10,
        showLoading: false,
      });

      if (categoryListModel && List.isList(categoryListModel?.categories)) {
        dispatch(
          storeLoadMoreStatusCategoryList(categoryListModel?.categories),
        );
      }

      setLoadingMore(false);
    }
  }, [dispatch, loadingMore, refresh, categoryList, storeTotal]);

  const handleRefreshStatusCategories = useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = false,
      page,
    }: Partial<IUseStatusCategoryListProps> & {page: number}) => {
      const categoryListModel = await refresh({
        offset,
        limit,
        showLoading,
      });

      if (categoryListModel && List.isList(categoryListModel?.categories)) {
        dispatch(
          storeSetStatusCategoryList(
            categoryListModel?.categories,
            '',
            categoryListModel?.total,
            page,
          ),
        );
      }
    },
    [dispatch, refresh],
  );

  return {
    ...categoriesParams,
    ...storedStatusCategoryListParams,
    categoryList,
    total: storeTotal,
    loadingMore,
    offset,
    limit,
    refresh,
    loading:
      (!List.isList(categoryList) && categoryListLoading) ||
      !storedStatusCategoryListLoading,
    handleSearchStatusCategories,
    handleLoadMoreStatusCategories,
    status: storedStatusCategory,
    handleRefreshStatusCategories,
    keywords: storeKeywords || '',
    page: storedPage || 1,
  };
}
