export interface IWorkScheduleDTOProps {
  uuid: string;
  title: string;
  description: string;
  rest_days: number;
  is_default: number;
  work_days: number;
  pos: number;
  status: number;
  created_at: string;
  updated_at: string;
}

export class WorkScheduleDTO {
  public uuid: string;

  public title: string;

  public description: string;

  public rest_days: number;

  public is_default: number;

  public work_days: number;

  public pos: number;

  public status: number;

  public created_at: string;

  public updated_at: string;

  constructor(props: IWorkScheduleDTOProps) {
    this.uuid = props?.uuid || '';
    this.title = props?.title || '';
    this.description = props?.description || '';
    this.rest_days = props?.rest_days || 0;
    this.is_default = props?.is_default || 0;
    this.work_days = props?.work_days || 0;
    this.pos = props?.pos || 0;
    this.status = props?.status || 0;
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
  }
}
