import React from 'react';
import styled from 'styled-components';
import FileUploader from '../Layout/common/FileUploader';

import {
  fileUploadStructure,
  firstLetterToUppercase,
  getFirstLatter,
  ifElse,
} from '../../../services/helpers';
export interface IDetailsViewWithImageProps {
  path?: string;
  title: string;
  children: React.ReactNode | React.ReactNode[];
  withoutImage?: boolean;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding-right: 15px;
  padding-bottom: 15px;
`;

const StyledFileUploader = styled(FileUploader)`
  width: 70px;
  min-width: 70px;
  height: 70px;

  & .manager-upload {
    .ant-upload-select.ant-upload-disabled {
      width: 70px;
      height: 70px;
    }

    .ant-upload.ant-upload-disabled {
      width: 70px;
    }
  }
`;

export function DetailsViewWithImage({
  path,
  title,
  children,
  withoutImage,
}: IDetailsViewWithImageProps): JSX.Element {
  return (
    <StyledContainer>
      {withoutImage ? null : (
        <StyledFileUploader
          uploaderClassName={'manager-upload'}
          title={getFirstLatter(firstLetterToUppercase(title))}
          disabled
          file={ifElse(!!path, fileUploadStructure(path as any), '')}
        />
      )}
      {children || null}
    </StyledContainer>
  );
}
