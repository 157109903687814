import {ScheduleCalendarProps} from '../models';

/**
 * @desc Получение минимальной даты для отображение в календаре
 */

export const minDayCalendar = (calendar: ScheduleCalendarProps[]): string =>
  Object.keys(calendar).map((item) => item)[0];

/**
 * @desc Получение максимольной даты для отображение в календаре
 */

export const maxDayCalendar = (calendar: ScheduleCalendarProps[]): string => {
  const maxDate = Object.keys(calendar).map((item) => item);
  return maxDate[maxDate.length - 1];
};

/**
 * @desc Повернення об'єкта з вибираної дати:
 * 1)(employeesByDate) массив об'єктів с key = id і value = вільні часи для всіх співробітників.
 * 2)(employeeTimeFrame) масив вільних часів для запису для виброгого співробітника, якщо переданий парметр employeeId
 * @param {String} date
 * @param {Object} data
 * @param {String} employeeId
 * @return {Object}
 * */

export const getEmployeesWithTimeZone = (date, data, employeeId) => {
  if (data) {
    const employeesAndHourForDate = Object.entries(data)
      .map(([key, value]) => key === date && value)
      .filter((item) => item !== false)[0];

    const employeesFromData = Object.entries(employeesAndHourForDate)
      .map(([, value]) => value)
      .flatMap((item) => item);
    const filterEmployeesWorkToday = [...new Set(employeesFromData)];

    const employeesByDate = filterEmployeesWorkToday.map((item) => {
      const arr = [];
      return Object.entries(employeesAndHourForDate).reduce(
        (acc, [key, value]) => {
          if (value.includes(item)) {
            arr.push(key);
            acc[item] = arr;
          }
          return acc;
        },
        {},
      );
    });

    const employeeTimeFrame = [];

    employeesByDate?.forEach((i) =>
      Object.entries(i).forEach(([key, value]) => {
        if (employeeId === key) {
          employeeTimeFrame.push(...(value as any));
        }
      }, {}),
    );

    return {employeeTimeFrame, employeesByDate};
  }
};
