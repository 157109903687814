import React from 'react';
import {Routes, Route} from 'react-router';
import {PriceMarginListPage, PriceMarginPage} from '../views/PriceMargins';
import {AppRouteLayout} from '../components/lib/Layout';

export function PriceMarginRoutesNavigator() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AppRouteLayout>
            <PriceMarginListPage />
          </AppRouteLayout>
        }
      />
      <Route
        path=":priceMarginId"
        element={
          <AppRouteLayout>
            <PriceMarginPage />
          </AppRouteLayout>
        }
      />
    </Routes>
  );
}
