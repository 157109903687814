import {CashBoxModel, CashBoxType, EmployeeModel} from '../internal';
import {Moment} from 'moment';
import {correctPrice, toMoment} from '../../services/helpers';

export class CashBoxMovingFormDTO {
  public uuid: string;
  public date: string | Moment;
  public comment: string;
  public sum: string;
  public cashbox_destination_uuid: string;
  public type?: CashBoxType;
  public cashier_uuid: string | EmployeeModel;

  constructor(props?: CashBoxModel) {
    this.uuid = props?.uuid || '';
    this.date = toMoment(new Date());
    this.comment = '';
    this.cashbox_destination_uuid = '';
    this.type = CashBoxType.Cash;
    this.sum = correctPrice(props?.box_balance);
    this.cashier_uuid = props?.cashier?.uuid ? props?.cashierModel : '';
  }
}
