import {BsBag} from 'react-icons/bs';
import {GrMoney} from 'react-icons/gr';
import {CiBoxes} from 'react-icons/ci';
import {IoIosMove} from 'react-icons/io';
import {HiTemplate} from 'react-icons/hi';
import styled, {css} from 'styled-components';
import {TbPlugConnected} from 'react-icons/tb';
import {FaRegMoneyBill1} from 'react-icons/fa6';
import {PiUserSwitchFill} from 'react-icons/pi';
import {TbTruckReturn, TbShoppingCartDollar} from 'react-icons/tb';
import {MdLanguage, MdPayment, MdPriceChange} from 'react-icons/md';
import {AiOutlineFileAdd, AiOutlineOrderedList} from 'react-icons/ai';
import {LiaMoneyBillAltSolid, LiaMoneyCheckAltSolid} from 'react-icons/lia';

import {
  HomeOutlined,
  UserOutlined,
  IdcardOutlined,
  PictureOutlined,
  SettingOutlined,
  ContactsOutlined,
  FileDoneOutlined,
  ContainerOutlined,
  DashboardOutlined,
  OrderedListOutlined,
  DollarCircleOutlined,
  FundProjectionScreenOutlined,
  GroupOutlined,
  FileOutlined,
  GoogleOutlined,
  EditOutlined,
  FacebookOutlined,
  TwitterOutlined,
  RightOutlined,
  InfoCircleOutlined,
  FileTextOutlined,
  LeftOutlined,
  ProjectOutlined,
  AppstoreOutlined,
  CarryOutOutlined,
  FileProtectOutlined,
  FileExclamationOutlined,
  LockOutlined,
  RedoOutlined,
  SolutionOutlined,
  CloudSyncOutlined,
  PartitionOutlined,
  HddOutlined,
  ShopOutlined,
  TransactionOutlined,
} from '@ant-design/icons';

import {
  BsPersonVcard,
  BsCardChecklist,
  BsBuildingFillAdd,
} from 'react-icons/bs';

import {
  RiSoundModuleFill,
  RiPencilRulerLine,
  RiDeleteBin7Line,
} from 'react-icons/ri';

import {
  FaLayerGroup,
  FaMoneyBillAlt,
  FaBoxes,
  FaChartPie,
} from 'react-icons/fa';

export const iconStyle = css`
  //padding: 5px;
  border-radius: 5px;
  color: ${({theme}) => theme.colors.app};
  line-height: 0 !important;
  margin-right: 5px;
`;

export const StyledFacebookOutlined = styled(FacebookOutlined)`
  ${iconStyle};
  font-size: 30px;
  color: ${({theme}) => theme.colors.primary};
  &:hover {
    color: ${({theme}) => theme.colors.link};
  }
`;

export const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  ${iconStyle};
  font-size: 15px;
  color: ${({theme}) => theme.colors.text};

  &:hover {
    color: ${({theme}) => theme.colors.link};
  }
`;

export const StyledInfoFileTextOutlined = styled(FileTextOutlined)`
  ${iconStyle};
  font-size: 15px;
  color: ${({theme}) => theme.colors.text};

  &:hover {
    color: ${({theme}) => theme.colors.link};
  }
`;

export const StyledRightOutlined = styled(RightOutlined)`
  ${iconStyle};
  font-size: 15px;
  color: ${({theme}) => theme.colors.text};

  &:hover {
    color: ${({theme}) => theme.colors.link};
  }
`;

export const StyledLeftOutlined = styled(LeftOutlined)`
  ${iconStyle};
  font-size: 15px;
  color: ${({theme}) => theme.colors.text};

  &:hover {
    color: ${({theme}) => theme.colors.link};
  }
`;

export const StyledTwitterOutlined = styled(TwitterOutlined)`
  ${iconStyle};
  font-size: 30px;
  color: ${({theme}) => theme.colors.primary};
  &:hover {
    color: ${({theme}) => theme.colors.link};
  }
`;

export const StyledDashboardOutlined = styled(DashboardOutlined)`
  ${iconStyle};
  //background-color: ${({theme}) => theme.colors.error};
`;

export const StyledHomeOutlined = styled(HomeOutlined)`
  ${iconStyle};
  // background-color: ${({theme}) => theme.colors.green};
`;

export const StyledIdcardOutlined = styled(IdcardOutlined)`
  ${iconStyle};
  // background-color: ${({theme}) => theme.colors.slateBlue};
`;

export const StyledOrderedListOutlined = styled(OrderedListOutlined)`
  ${iconStyle};
  //background-color: ${({theme}) => theme.colors.darkOrchid};
`;

export const StyledFileDoneOutlined = styled(FileDoneOutlined)`
  ${iconStyle};
  //background-color: ${({theme}) => theme.colors.mint};
`;

export const StyledCounterparty = styled(SolutionOutlined)`
  ${iconStyle};
  //background-color: ${({theme}) => theme.colors.mint};
`;

export const StyledContactsOutlined = styled(ContactsOutlined)`
  ${iconStyle};
  // background-color: ${({theme}) => theme.colors.error};
`;
export const StyledRiPencilRulerLine = styled(RiPencilRulerLine)`
  ${iconStyle};
  // background-color: ${({theme}) => theme.colors.error};
`;

export const StyledFundProjectionScreenOutlined = styled(
  FundProjectionScreenOutlined,
)`
  ${iconStyle};
  background-color: ${({theme}) => theme.colors.darkOrchid};
`;

export const StyledPictureOutlined = styled(PictureOutlined)`
  ${iconStyle};
  background-color: ${({theme}) => theme.colors.lightCherry};
`;

export const StyledDollarCircleOutlined = styled(DollarCircleOutlined)`
  ${iconStyle};
  //background-color: ${({theme}) => theme.colors.blueGrayCrayola};
`;

export const StyledDocumentOutlined = styled(HddOutlined)`
  ${iconStyle};
  //background-color: ${({theme}) => theme.colors.blueGrayCrayola};
`;
export const StyledGrMoney = styled(GrMoney)`
  ${iconStyle};
  //background-color: ${({theme}) => theme.colors.blueGrayCrayola};
`;
export const StyledFaRegMoneyBill1 = styled(FaRegMoneyBill1)`
  ${iconStyle};
  //background-color: ${({theme}) => theme.colors.blueGrayCrayola};
`;

export const StyledLiaMoneyCheckAltSolid = styled(LiaMoneyCheckAltSolid)`
  ${iconStyle};
  //background-color: ${({theme}) => theme.colors.blueGrayCrayola};
`;

export const StyledContainerOutlined = styled(ContainerOutlined)`
  ${iconStyle};
  // background-color: ${({theme}) => theme.colors.greyBlackberry};
`;

export const StyledSettingOutlined = styled(SettingOutlined)`
  ${iconStyle};
  // background-color: ${({theme}) => theme.colors.mint};
`;

export const StyledUserOutlined = styled(UserOutlined)`
  ${iconStyle};
  // background-color: ${({theme}) => theme.colors.yellowGold};
`;

export const StyledGroupOutlined = styled(GroupOutlined)`
  ${iconStyle};
  background-color: ${({theme}) => theme.colors.lightCherry};
`;

export const StyledFileOutlined = styled(FileOutlined)`
  ${iconStyle};
`;

export const StyledGoogleOutlined = styled(GoogleOutlined)`
  ${iconStyle};
`;

export const StyledEditOutlined = styled(EditOutlined)`
  color: ${({theme}) => theme.colors.green};
`;

export const StyledProjectOutlined = styled(ProjectOutlined)`
  ${iconStyle};
  // background-color: ${({theme}) => theme.colors.blueGrayCrayola};
`;

export const StyledAppstoreOutlined = styled(AppstoreOutlined)`
  ${iconStyle};
  // background-color: ${({theme}) => theme.colors.yellowGold};
`;

export const StyledCarryOutOutlined = styled(CarryOutOutlined)`
  ${iconStyle};
  // background-color: ${({theme}) => theme.colors.slateBlue};
`;

export const StyledFileProtectOutlined = styled(FileProtectOutlined)`
  ${iconStyle};
  //background-color: ${({theme}) => theme.colors.lightCherry};
`;
export const StyledBsPersonVcard = styled(BsPersonVcard)`
  ${iconStyle};
  //background-color: ${({theme}) => theme.colors.lightCherry};
`;

export const StyledFileExclamationOutlined = styled(FileExclamationOutlined)`
  ${iconStyle};
  // background-color: ${({theme}) => theme.colors.darkOrchid};
`;

const iconLoginStyle = css`
  color: ${({theme}) => theme.colors.disable};
`;

export const StyledLockOutlined = styled(LockOutlined)`
  ${iconLoginStyle}
`;
export const StyledUserLoginOutlined = styled(UserOutlined)`
  ${iconLoginStyle}
`;

export const StyledRiSoundModuleFill = styled(RiSoundModuleFill)`
  ${iconLoginStyle}
`;

export const StyledTbPlugConnected = styled(TbPlugConnected)`
  ${iconLoginStyle}
`;
export const StyledMdLanguage = styled(MdLanguage)`
  ${iconLoginStyle}
`;

export const StyledMdPayment = styled(MdPayment)`
  ${iconLoginStyle}
`;
export const StyledFaMoneyBillAlt = styled(FaMoneyBillAlt)`
  ${iconLoginStyle};
`;

export const StyledFaLayerGroup = styled(FaLayerGroup)`
  ${iconLoginStyle}
`;
export const StyledHiTemplate = styled(HiTemplate)`
  ${iconLoginStyle}
`;
export const StyledBsCardChecklist = styled(BsCardChecklist)`
  ${iconLoginStyle}
`;

export const StyledRedoOutlined = styled(RedoOutlined)`
  ${iconLoginStyle};
  color: ${({theme}) => theme.colors.link};
`;
export const StyledCloudSyncOutlined = styled(CloudSyncOutlined)`
  ${iconLoginStyle};
`;
export const StyledPartitionOutlined = styled(PartitionOutlined)`
  ${iconLoginStyle};
`;

export const StyledBsBuildingFillAdd = styled(BsBuildingFillAdd)`
  ${iconLoginStyle};
`;

export const StyledMdPriceChange = styled(MdPriceChange)`
  ${iconLoginStyle};
`;
export const StyledFaBoxes = styled(FaBoxes)`
  ${iconLoginStyle};
`;
export const StyledCiBoxes = styled(CiBoxes)`
  ${iconLoginStyle};
`;

export const StyledTbTruckReturn = styled(TbTruckReturn)`
  ${iconLoginStyle};
`;

export const StyledIoIosMove = styled(IoIosMove)`
  ${iconLoginStyle};
`;
export const StyledAiOutlineFileAdd = styled(AiOutlineFileAdd)`
  ${iconLoginStyle};
`;
export const StyledFaChartPie = styled(FaChartPie)`
  ${iconLoginStyle};
`;
export const StyledRiDeleteBin7Line = styled(RiDeleteBin7Line)`
  ${iconLoginStyle};
`;
export const StyledBsBag = styled(BsBag)`
  ${iconLoginStyle};
`;

export const StyledAiOutlineOrderedList = styled(AiOutlineOrderedList)`
  ${iconLoginStyle};
`;
export const StyledTbShoppingCartDollar = styled(TbShoppingCartDollar)`
  ${iconLoginStyle};
`;
export const StyledLiaMoneyBillAltSolid = styled(LiaMoneyBillAltSolid)`
  ${iconLoginStyle};
`;

export const StyledSalesShopOutlined = styled(ShopOutlined)`
  ${iconLoginStyle};
`;

export const StyledMoneyTransactions = styled(TransactionOutlined)`
  ${iconLoginStyle};
`;

export const StyledPiUserSwitchFill = styled(PiUserSwitchFill)`
  ${iconLoginStyle};
`;
