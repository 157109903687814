import {Record} from 'immutable';
import {
  CompanyDTO,
  CompanyMapper,
  CompanyModel,
  EmployeeDTO,
  EmployeeMapper,
  EmployeeModel,
  CashBoxDTO,
  ProfileModel,
  ProfileMapper,
  ProfileDTO,
} from '../internal';
import {WithoutRecursion, CashBoxType} from '../type';

export interface ICashBoxModelReturnType {
  uuid: string;
  box_type: CashBoxType;
  box_title: string;
  box_comment: string;
  box_pos: number;
  box_can_move_money_from: boolean;
  box_can_move_money_to: boolean;
  box_can_in_money_to: boolean;
  box_can_out_money_from: boolean;
  box_is_default_for_company: boolean;
  box_balance: string;
  created_at: string;
  updated_at: string;
  created_by: ProfileModel | ProfileDTO;
  cashier: EmployeeModel | EmployeeDTO;
  company: CompanyModel | CompanyDTO;
}

/**
 * @class Product
 * @desc Клієнт
 */
export class CashBoxModel extends Record<ICashBoxModelReturnType>({
  uuid: '',
  box_type: CashBoxType.Cash,
  box_title: '',
  box_comment: '',
  box_pos: 0,
  box_can_move_money_from: false,
  box_can_move_money_to: false,
  box_can_in_money_to: false,
  box_can_out_money_from: false,
  box_is_default_for_company: false,
  box_balance: '',
  created_at: '',
  updated_at: '',
  company: {} as CompanyModel,
  created_by: {} as ProfileModel,
  cashier: {} as EmployeeModel,
}) {
  constructor(
    props: CashBoxDTO = {} as CashBoxDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: ICashBoxModelReturnType = {
      ...props,
      created_by:
        withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
          ? props?.created_by
          : ProfileMapper.toProfileModel(props?.created_by, [
              WithoutRecursion.cashBox,
              ...withoutRecursion,
            ]),
      cashier:
        withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
          ? props?.cashier
          : EmployeeMapper.toEmployeeModel(props?.cashier, [
              WithoutRecursion.cashBox,
              ...withoutRecursion,
            ]),
      company:
        withoutRecursion.indexOf(WithoutRecursion.company) !== -1
          ? props?.company
          : CompanyMapper.toCompanyModel(props?.company, [
              WithoutRecursion.cashBox,
              ...withoutRecursion,
            ]),
    };

    super(options);
  }

  get companyModel(): CompanyModel {
    return this.company instanceof CompanyModel
      ? this.company
      : CompanyMapper.toCompanyModel(this.company);
  }

  get createdByModel(): ProfileModel {
    return this.created_by instanceof ProfileModel
      ? this.created_by
      : ProfileMapper.toProfileModel(this.created_by);
  }

  get cashierModel(): EmployeeModel {
    return this.cashier instanceof EmployeeModel
      ? this.cashier
      : EmployeeMapper.toEmployeeModel(this.cashier);
  }
}
