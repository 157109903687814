import React from 'react';
import FooterWithoutLayout from './FooterWithoutLayout';
import styled from 'styled-components';

export interface IAppSideFooterProps {
  collapsed?: boolean;
}

const StyledAppSideFooterWrapper = styled.div`
  &,
  span {
    color: #000 !important;
  }
`;

export function AppSideFooter({collapsed}: IAppSideFooterProps): JSX.Element {
  return (
    <StyledAppSideFooterWrapper>
      <FooterWithoutLayout />
    </StyledAppSideFooterWrapper>
  );
}
