import {Record, List} from 'immutable';
import {IListProps} from './List';
import {CompanyModuleDTO, CompanyModuleModel} from '../internal';

export interface ICompanyModuleListModelProps extends IListProps {
  company_modules: CompanyModuleDTO[];
}

export interface ICompanyModuleListModelReturnType extends IListProps {
  companyModules: List<CompanyModuleModel>;
}

export class CompanyModuleListModel extends Record<ICompanyModuleListModelReturnType>(
  {
    companyModules: List(),
  },
) {
  constructor(
    {
      company_modules,
      ...props
    }: ICompanyModuleListModelProps = {} as ICompanyModuleListModelProps,
  ) {
    super({
      ...props,
      companyModules: List(
        company_modules.map((module) => new CompanyModuleModel(module)),
      ),
    });
  }
}
