import React, {useCallback, useEffect, useState} from 'react';
import {
  EMPLOYEE_ROLE_CASHIER_FORM_PARAMS,
  EmployeeRoleCashierAllowedOperations,
  EmployeeRoleCashierPermission,
  EmployeeRoleType,
} from '../../../struture';
import {Col, Form, Row, Select} from 'antd';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {listToArray} from '../../../services/helpers';
import {Button} from '../../../components/lib/DataDisplay';
import {
  DeleteOutlined,
  PlusOutlined,
  LoadingOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import {useStoredCashBoxList, useStoredCompanies} from '../../../hooks';
import {FormListFieldData, FormListOperation} from 'antd/es/form/FormList';
import Switch from 'antd/es/switch';
import {StyledDescription} from '../../../components/lib/Styled';
import {CashBoxTitleView} from '../../Payments/Show/CashBoxTitleView';
import {useDefaultForm} from '../../../contex';

export interface EmployeeRoleCashierFieldProps extends FormListOperation {
  fields: FormListFieldData[];
  loadingSubmit: boolean;
  role: EmployeeRoleType<EmployeeRoleCashierPermission>;
  fieldName: (string | number)[];
}

export interface EmployeeRoleSelectList {
  value: string;
  label: React.ReactNode;
}

const StyledDeleteOutlined = styled(DeleteOutlined)`
  color: ${({theme}) => theme.colors.error};
`;

const StyledDeleteCol = styled(Col)`
  display: flex;
  align-self: center;
`;

const StyledSwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 5px;
`;

export const StyledSwitchListContainer = styled(Col)`
  padding: 10px;
  position: relative;
  width: 100%;
  border: 1px solid #d9d9d9;

  &:nth-child(odd) {
    border-right: none;
  }

  &:nth-child(1n + 1):not(:last-child):not(:nth-last-child(2)) {
    border-bottom: none;
  }

  &:first-child {
    border-top-left-radius: 7px;
  }
  &:nth-child(2) {
    border-top-right-radius: 7px;
  }

  &:last-child {
    border-bottom-right-radius: 7px;
  }

  &:nth-last-child(2) {
    border-bottom-left-radius: 7px;
  }
`;

const StyledDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
  margin-bottom: 10px;
`;

const StyledRow = styled(Row)`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export function EmployeeRoleCashierField({
  fields,
  add,
  remove,
  loadingSubmit,
  role,
  fieldName,
}: EmployeeRoleCashierFieldProps) {
  const {t} = useTranslation();
  const {defaultCompanyUuid} = useStoredCompanies();
  const {setFieldValue, getFieldValue} = useDefaultForm();

  const [selectCashBoxes, setSelectCashBoxes] = useState<
    EmployeeRoleSelectList[]
  >([]);

  const [fiscalCashBoxesUuids, setFiscalCashBoxesUuids] = useState<string[]>(
    [],
  );

  const permissions: EmployeeRoleCashierPermission[] = role?.permissions;

  const {
    cashBoxList,
    loading: cashBoxListLoading,
    loadingMore,
    handleSearchCashBoxes,
    handleLoadMoreCashBoxes,

    limit,
    total,
    offset,
  } = useStoredCashBoxList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const handleChangeCashBox = useCallback(
    (cashBoxUuid: string, fiscalOperationName: (string | number)[]) => {
      if (fiscalCashBoxesUuids.includes(cashBoxUuid)) {
        setFieldValue([...fieldName, ...fiscalOperationName], true);
      } else {
        setFieldValue([...fieldName, ...fiscalOperationName], false);
      }
    },
    [fieldName, fiscalCashBoxesUuids, setFieldValue],
  );

  useEffect(() => {
    if (cashBoxList?.size) {
      const cashBoxes = cashBoxList?.map((cashBox) => ({
        label: (
          <CashBoxTitleView
            cashBox={cashBox}
            disable={loadingSubmit || cashBoxListLoading}
          />
        ),
        value: cashBox?.uuid,
      }));

      const fiscalCashBoxesUuids = cashBoxList
        ?.map(({uuid, box_fiscal_cashbox_id}) =>
          box_fiscal_cashbox_id ? uuid : '',
        )
        .filter(Boolean);

      const selectCashBoxes = listToArray(cashBoxes as any);

      setSelectCashBoxes(selectCashBoxes as any);
      setFiscalCashBoxesUuids(listToArray(fiscalCashBoxesUuids));
    }
  }, [cashBoxList, cashBoxListLoading, loadingSubmit]);

  return (
    <>
      {fields.map(({key, name, ...restField}, index) => {
        return (
          <React.Fragment key={key}>
            <StyledRow gutter={12} align="top">
              <Col span={8}>
                <Form.Item noStyle shouldUpdate>
                  {() => (
                    <Form.Item
                      {...{key, name, ...restField}}
                      required
                      label={t('Cash box')}
                      tooltip={t('Cash box')}
                      name={[name, 'object_uuid']}
                      rules={[
                        () => ({
                          validator(_, store) {
                            if (store === null || store === undefined) {
                              return Promise.reject(
                                new Error(t('Cash box must be specified.')),
                              );
                            }

                            let countOfStore = 0;

                            permissions?.forEach(({object_uuid}) => {
                              if (object_uuid === store) {
                                countOfStore++;
                              }
                            });

                            if (countOfStore > 1) {
                              return Promise.reject(
                                new Error(
                                  t(
                                    "Choose another cash box, it's already busy",
                                  ),
                                ),
                              );
                            }

                            return Promise.resolve();
                          },
                        }),
                      ]}>
                      <Select
                        popupMatchSelectWidth={false}
                        disabled={cashBoxListLoading || loadingSubmit}
                        showSearch
                        placeholder={t('Select a cash box')}
                        options={selectCashBoxes}
                        onSearch={(keywords) =>
                          handleSearchCashBoxes({keywords})
                        }
                        onChange={(cashBoxUuid: string) =>
                          handleChangeCashBox(cashBoxUuid, [
                            name,
                            'object_allowed_operations',
                            EmployeeRoleCashierAllowedOperations.FISCAL,
                          ])
                        }
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
              <Col span={14}>
                <Row gutter={0} justify="space-between">
                  {Object.entries(
                    role?.permissions[index]?.object_allowed_operations,
                  ).map(([operation], index) => (
                    <StyledSwitchListContainer span={12} key={`${index}`}>
                      {operation ===
                        EmployeeRoleCashierAllowedOperations.FISCAL &&
                      !fiscalCashBoxesUuids.includes(
                        getFieldValue([...fieldName, name, 'object_uuid']),
                      ) ? null : (
                        <StyledSwitchContainer>
                          <StyledDescription>{t(operation)}</StyledDescription>
                          <Form.Item
                            noStyle
                            valuePropName="checked"
                            name={[
                              name,
                              'object_allowed_operations',
                              operation,
                            ]}>
                            <Switch
                              disabled={loadingSubmit}
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                              loading={loadingSubmit}
                            />
                          </Form.Item>
                        </StyledSwitchContainer>
                      )}
                    </StyledSwitchListContainer>
                  ))}
                </Row>
              </Col>
              {permissions?.length > 1 ? (
                <StyledDeleteCol span={2}>
                  <StyledDeleteOutlined onClick={() => remove(name)} />
                </StyledDeleteCol>
              ) : null}
            </StyledRow>
            {permissions.length - 1 === index ? null : <StyledDivider />}
          </React.Fragment>
        );
      })}

      {permissions?.length < total ? (
        <Form.Item>
          <Button
            type="dashed"
            onClick={async () => {
              if (limit + offset === permissions?.length) {
                await handleLoadMoreCashBoxes();
              }

              add(EMPLOYEE_ROLE_CASHIER_FORM_PARAMS.permissions[0]);
            }}
            block
            icon={loadingMore ? <LoadingOutlined /> : <PlusOutlined />}>
            {t('Add another cash box')}
          </Button>
        </Form.Item>
      ) : null}
    </>
  );
}
