import React from 'react';
import Color from 'color';
import styled, {css} from 'styled-components';

export interface StatusProgressItemProps {
  color: string;
  height?: number;
  active?: boolean;
}

const ArrowStep = styled.div<{
  $height: number;
  $color: string;
  $active?: boolean;
}>`
  float: left;
  background: ${({$color}) =>
    Color($color).alpha(1).lighten(0.7).gray(95).toString()};
  position: relative;
  min-width: 18px;
  height: ${({$height}) => `${$height}px`};
  margin: 0 1px;

  &:before {
    content: '';
    border-left: 16px solid #fff;
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: ${({$height}) => `${$height}px`};
  }

  &:after {
    content: '';
    border-left: 16px solid
      ${({$color}) => Color($color).alpha(1).lighten(0.7).gray(95).toString()};
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 1;
    height: ${({$height}) => `${$height}px`};
  }

  &:first-child {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;

    &:before {
      border: none;
    }
  }

  ${({$active, $color}) =>
    $active &&
    css`
      background: ${$color};
      border-top: ${$color};
      border-bottom: ${$color};

      &::after {
        border-left-color: ${$color};
      }
    `}
`;

export function StatusProgressItem({
  color,
  height = 15,
  active,
}: StatusProgressItemProps): React.JSX.Element {
  return <ArrowStep $height={height} $color={color} $active={active} />;
}
