import './Password.less';
import React, {forwardRef} from 'react';
import {Input, InputProps} from 'antd';
import styled from 'styled-components';
import {ILiteralObj} from '../../../services/types';

export interface IPasswordProps extends ILiteralObj {}

export default forwardRef(Password);

const StyledPassword = styled(Input.Password)`
  border-color: rgb(236, 236, 236);
  height: 42px;
  border-radius: ${({theme}) => theme.border.baseBorderRadius};
`;

function Password(props: IPasswordProps, ref: any): JSX.Element {
  return (
    <div className="Password">
      <StyledPassword {...props} ref={ref} />
    </div>
  );
}
