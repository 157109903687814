import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {IActionButtonProps} from '../../../components/lib/General';
import {useModal} from '../../../components/lib/libV2/hooks';
import {ServiceFormDTO} from '../../../struture';
import {AddButton, SuspenseEmpty} from '../../../components/lib/DataDisplay';

const ServicesSideWindowLazy = React.lazy(
  () => import('../Show/ServicesSideWindow'),
);

export interface IServicesCreateButtonProps
  extends Omit<IActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: ServiceFormDTO) => Promise<void>;
  onCancel?: () => void;
  companyUuid: string;
}

export default function ServicesCreateButton({
  children,
  companyUuid,
  onSuccess,
  onCancel,
  loading,
  ...rest
}: IServicesCreateButtonProps): JSX.Element {
  const {t} = useTranslation();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <AddButton
        tooltipTitle={t('Create company service')}
        onClick={handleOnInit}
        loading={loading}
        {...rest}
      />

      {visible ? (
        <Suspense fallback={<SuspenseEmpty />}>
          <ServicesSideWindowLazy
            companyUuid={companyUuid}
            onSuccess={handleSuccess}
            visible={visible}
            onCancel={handleCancel}
          />
        </Suspense>
      ) : null}
    </>
  );
}
