import moment, {Moment} from 'moment';
import {apiDelete, apiGet, apiPatch, apiPost, apiPut} from '../core/api';
import {
  ApiAnswer,
  ConvertTypeToRequiredField,
  DateValue,
  IEntityProps,
  IListSearchProps,
  RequiredField,
} from '../types';
import {
  dateToIsoString,
  destructuringTime,
  head,
  setHourAndMinute,
  toDateByFormat,
} from '../helpers';
import {contentApiUrl} from '../const';
import Validate from '../validate/Validate';
import {
  ClientFormDTO,
  EmployeeFormDTO,
  ExpenseCreateFormDTO,
  ExpenseDiscountType,
  ExpenseDTO,
  ExpenseFormDTO,
  ExpenseMapper,
  IAggregatedSumDTOProps,
  IExpenseDTOProps,
  IExpenseListDTO,
  IPaymentListDTO,
  IScheduleCalendarDTOProps,
  IScheduleCalendarListDTO,
  IScheduleCalendarParentDTOProps,
  IScheduleStatusDTOProps,
  IScheduleStatusListDTO,
  PaymentDTO,
  PaymentMapper,
  PaymentScheduleFormDTO,
  ScheduleCalendarDTO,
  ScheduleCalendarFormDTO,
  ScheduleCalendarMapper,
  ScheduleCalendarParentDTO,
  ScheduleChangeTimeFormDTO,
  ScheduleDocumentType,
  ScheduleMessageFormDTO,
  ScheduleStatusMapper,
  AggregatedSumDTO,
  IScheduleStatsDTO,
  StatusCommentFormDTO,
  ScheduleCloseDTO,
  ScheduleCalendarStatsDTO,
  IScheduleCalendarStatsDTOProps,
} from '../../struture';
import {IPaymentAnswer, IPaymentListAnswer} from './companyPayments';

export const ORDER_INITIAL_PARAM = new ScheduleCalendarFormDTO();
export const ORDER_CHANGE_TIME_INITIAL_PARAM = new ScheduleChangeTimeFormDTO();

export interface IScheduleStatusListProps extends Partial<IListSearchProps> {
  scheduleUuid: string;
}

export interface IScheduleStatusListAnswer extends ApiAnswer {
  statuses: IScheduleStatusDTOProps[];
}

export enum ORDER_SCHEDULE_STATUS {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  CANCELED_BY_EMPLOYEE = 'CANCELED_BY_EMPLOYEE',
  CANCELED_BY_COMPANY = 'CANCELED_BY_COMPANY',
  CANCELED_BY_ADMIN = 'CANCELED_BY_ADMIN',
  FINISHED = 'FINISHED',
  REQUESTED = 'REQUESTED',
  ERROR = 'ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
  EXPIRED = 'EXPIRED',
}

export enum APPOINTMENT_METHOD {
  CASHBOX = 'CASHBOX',
  ACCOUNT = 'ACCOUNT',
  BANK = 'BANK',
}

export interface ISchedulePaymentListParam
  extends Partial<IListSearchProps>,
    IEntityProps {
  date_start?: Date | Moment | string;
  date_end?: Date | Moment | string;
  scheduleUuid: string;
  type?: PaymentScheduleOperationType;
}

export const CLOSE_PAYMENT_METHOD: APPOINTMENT_METHOD[] = [
  APPOINTMENT_METHOD.CASHBOX,
  APPOINTMENT_METHOD.ACCOUNT,
  APPOINTMENT_METHOD.BANK,
];

export interface IOrderRequiredFields {
  scheduled_date: RequiredField<string, string>;
  price: RequiredField<string, string>;
  sum_total: RequiredField<string, string>;
  employee: ConvertTypeToRequiredField<Omit<EmployeeFormDTO, 'gender'>>;
}

export interface IOrderRequiredFieldsWithFullName
  extends Omit<IOrderRequiredFields, 'client' | 'employee'> {
  client: ConvertTypeToRequiredField<
    Omit<ClientFormDTO, 'first_name' | 'last_name' | 'uuid'> & {fullName: any}
  >;
  employee: ConvertTypeToRequiredField<
    Omit<EmployeeFormDTO, 'first_name' | 'last_name' | 'gender'> & {
      fullName: any;
    }
  >;
}

export const ORDER_REQUIRED_FIELDS: IOrderRequiredFields = {
  scheduled_date: (value: string) => moment(value).format('YYYY-MM-DD HH:mm'),
  price: '',
  sum_total: '',
  employee: {first_name: '', last_name: '', middle_name: '', phone: ''} as any,
};

// export const ORDER_CLIENT_REQUIRED_FIELDS: {
//   client: ConvertTypeToRequiredField<Omit<ClientFormDTO, 'uuid'>>;
// } = {
//   // client: CLIENT_REQUIRED_FIELD as any,
// };

export interface ICorrectOrderScheduleDateProps {
  time: string;
  scheduled_date: Moment;
}

export const correctOrderScheduleDate = ({
  time,
  scheduled_date,
}: ICorrectOrderScheduleDateProps): Moment => {
  const [hour, minute] = destructuringTime(time);

  return time
    ? setHourAndMinute(hour || 0, minute || 0, scheduled_date.toDate())
    : scheduled_date;
};

export const correctOrderScheduleDateToString = (
  value: ICorrectOrderScheduleDateProps,
) => correctOrderScheduleDate(value).toISOString() as string;

export const correctCloseOrderScheduleDateToString = (value: DateValue) =>
  moment(value).toISOString();

export const correctOrderParams = ({
  scheduled_date,
  time,
  client,
  ...rest
}: Omit<ScheduleCalendarFormDTO, 'scheduled_date' | 'time'> & {
  scheduled_date: Moment;
  time: string;
}): Pick<
  ScheduleCalendarFormDTO,
  Exclude<keyof ScheduleCalendarFormDTO, 'scheduled_date' | 'time'>
> & {
  scheduled_date: string;
  time: string;
} => {
  const correctClient = (({uuid, first_name, last_name, phone}: any) => {
    if (phone) {
      return {first_name, last_name, phone};
    }
    return {
      uuid,
    };
  })(client);

  return {
    ...rest,
    client: correctClient,
    time,
    scheduled_date: correctOrderScheduleDateToString({time, scheduled_date}),
  };
};

export interface IOrderAnswer extends ApiAnswer {
  schedule: IScheduleCalendarDTOProps;
  stats: IScheduleStatsDTO;
}

export interface IOrderActionAnswer extends ApiAnswer {
  schedule: IScheduleCalendarDTOProps;
  stats: IScheduleCalendarStatsDTOProps;
}

export interface IRelatedScheduleProps extends IListSearchProps {
  scheduleUuid: string;
}

export interface IRelatedScheduleAnswer extends ApiAnswer {
  schedule_items: IScheduleCalendarDTOProps[];
  parent: IScheduleCalendarParentDTOProps;
}

export interface IPaymentScheduleAnswer extends IPaymentAnswer {
  schedule: IScheduleCalendarDTOProps;
}

export interface IPaymentScheduleReturnType {
  payment: PaymentDTO;
  schedule: ScheduleCalendarDTO;
}

export interface IPaymentScheduleListStats {
  totalInSum: string;
  totalInFiscalSum?: string;
  totalOutSum: string;
}

export interface IPaymentScheduleListAnswer extends IPaymentListAnswer {
  stats: IPaymentScheduleListStats;
}

export interface IScheduleAnswer {
  schedule: ScheduleCalendarDTO;
  stats: IScheduleStatsDTO;
}

export interface IScheduleActionAnswer {
  schedule: ScheduleCalendarDTO;
  stats: ScheduleCalendarStatsDTO;
}

export enum PaymentScheduleOperationType {
  In = 'IN',
  Out = 'OUT',
  ALL = '',
}

export async function getScheduleStatusListById({
  scheduleUuid,
  limit = 10,
  offset = 0,
  keywords,
}: IScheduleStatusListProps): Promise<IScheduleStatusListDTO> {
  const isKeywords = keywords ? `&keywords=${keywords}` : '';

  const {statuses, total} = await apiGet<null, IScheduleStatusListAnswer>(
    `${contentApiUrl}/schedules/${scheduleUuid}/statuses?limit=${limit}&offset=${offset}${isKeywords}`,
  );

  return ScheduleStatusMapper.toScheduleStatusListDTO(statuses, total);
}

export async function getRelatedScheduleList({
  scheduleUuid,
  limit,
  offset = 0,
  keywords,
}: IRelatedScheduleProps): Promise<
  IScheduleCalendarListDTO & {parent: ScheduleCalendarParentDTO}
> {
  const isLimit = !!limit && limit <= 150 ? `&limit=${limit}` : '';
  const isKeywords = keywords ? `&keywords=${keywords}` : '';

  const {schedule_items, total, parent} = await apiGet<
    null,
    IRelatedScheduleAnswer
  >(
    `${contentApiUrl}/schedules/${scheduleUuid}/sub-items?&offset=${offset}${isLimit}${isKeywords}`,
  );

  return {
    ...ScheduleCalendarMapper.toScheduleCalendarListDTO(schedule_items, total),
    parent: new ScheduleCalendarParentDTO(parent || {}),
  };
}

export async function getOrderById(
  scheduleUuid: string,
): Promise<IScheduleAnswer> {
  Validate.string({value: scheduleUuid});

  const {schedule, stats} = await apiGet<null, IOrderAnswer>(
    `${contentApiUrl}/schedules/${scheduleUuid}`,
  );

  return {
    schedule: ScheduleCalendarMapper.toScheduleCalendarDTO(schedule),
    stats,
  };
}

export async function createOrder({
  scheduled_date,
  client,
  service_uuid,
  worker_uuid,
  comment,
  price,
  send_email_notification,
  send_sms_notification,
  parent_uuid,
  schedule_title,
}: ScheduleCalendarFormDTO): Promise<IScheduleActionAnswer> {
  const {schedule, stats} = await apiPost<
    Partial<ScheduleCalendarFormDTO>,
    IOrderActionAnswer
  >(`${contentApiUrl}/schedules`, {
    scheduled_date,
    client,
    service_uuid,
    worker_uuid,
    comment,
    price,
    send_email_notification,
    send_sms_notification,
    schedule_title,
    ...(parent_uuid ? {parent_uuid} : {}),
  });

  return {
    schedule: ScheduleCalendarMapper.toScheduleCalendarDTO(schedule),
    stats: ScheduleCalendarMapper.toScheduleCalendarStatsDT(stats),
  };
}

export async function updateOrder({
  scheduled_date,
  client,
  worker_uuid,
  comment,
  send_email_notification,
  send_sms_notification,
  parent_uuid,
  uuid,
  schedule_title,
}: ScheduleCalendarFormDTO): Promise<IScheduleActionAnswer> {
  const {schedule, stats} = await apiPut<
    Partial<ScheduleCalendarFormDTO>,
    IOrderActionAnswer
  >(`${contentApiUrl}/schedules/${uuid}`, {
    scheduled_date,
    client,
    worker_uuid,
    comment,
    send_email_notification,
    send_sms_notification,
    parent_uuid,
    schedule_title,
  });

  return {
    schedule: ScheduleCalendarMapper.toScheduleCalendarDTO(schedule),
    stats: ScheduleCalendarMapper.toScheduleCalendarStatsDT(stats),
  };
}

export async function deleteOrder(
  scheduleUuid: string,
): Promise<IScheduleActionAnswer> {
  const {schedule, stats} = await apiDelete<null, IOrderActionAnswer>(
    `${contentApiUrl}/schedules/${scheduleUuid}`,
  );

  return {
    schedule: ScheduleCalendarMapper.toScheduleCalendarDTO(schedule),
    stats: ScheduleCalendarMapper.toScheduleCalendarStatsDT(stats),
  };
}

export async function closeOrder({
  uuid,
  schedule_checkout_transaction,
  schedule_close_date,
  schedule_comment,
  schedule_payment_date,
  schedule_payment_method,
  schedule_payment_sum,
  schedule_payment_cashbox_uuid,
  schedule_payment_cashier_uuid,
  schedule_payment_post_action,
}: ScheduleCloseDTO): Promise<ScheduleCalendarDTO> {
  const {schedule} = await apiPut<Omit<ScheduleCloseDTO, 'uuid'>, IOrderAnswer>(
    `${contentApiUrl}/schedules/${uuid}/close`,
    {
      schedule_checkout_transaction,
      schedule_close_date: dateToIsoString(schedule_close_date as any),
      schedule_comment,
      schedule_payment_date: dateToIsoString(schedule_payment_date as any),
      schedule_payment_method,
      schedule_payment_sum,
      schedule_payment_cashbox_uuid,
      schedule_payment_cashier_uuid,
      schedule_payment_post_action,
    },
  );

  return ScheduleCalendarMapper.toScheduleCalendarDTO(schedule);
}

export async function cancelClosingOrder(
  scheduleUuid: string,
): Promise<ScheduleCalendarDTO> {
  const {schedule} = await apiPut<null, IOrderAnswer>(
    `${contentApiUrl}/schedules/${scheduleUuid}/disclose`,
    null,
  );

  return ScheduleCalendarMapper.toScheduleCalendarDTO(schedule);
}

export async function cancelOrder(
  scheduleUuid: string,
): Promise<ScheduleCalendarDTO> {
  const {schedule} = await apiPut<null, IOrderAnswer>(
    `${contentApiUrl}/schedules/${scheduleUuid}/cancel`,
    null,
  );

  return ScheduleCalendarMapper.toScheduleCalendarDTO(schedule);
}

export async function updateOrderComment(
  scheduleUuid: string,
  comment: string,
): Promise<ScheduleCalendarDTO> {
  const {schedule} = await apiPatch<{comment: string}, IOrderAnswer>(
    `${contentApiUrl}/schedules/${scheduleUuid}/details`,
    {comment},
  );

  return ScheduleCalendarMapper.toScheduleCalendarDTO(schedule);
}

export async function getSchedulePaymentList({
  scheduleUuid,
  date_start,
  date_end,
  keywords,
  offset = 0,
  limit = 10,
  type = PaymentScheduleOperationType.ALL,
}: ISchedulePaymentListParam): Promise<IPaymentListDTO> {
  const {payments, stats, count} = await apiGet<
    IListSearchProps & {
      date_start: any;
      date_end: any;
      type: PaymentScheduleOperationType;
    },
    IPaymentScheduleListAnswer
  >(`${contentApiUrl}/schedules/${scheduleUuid}/payments`, {
    date_start: date_start ? toDateByFormat(date_start, 'YYYY-MM-DD') : '',
    date_end: date_end ? toDateByFormat(date_end, 'YYYY-MM-DD') : '',
    keywords,
    offset,
    limit,
    type,
  });

  return PaymentMapper.toPaymentListDTO(payments, count, stats);
}

export async function findAnotherOrderTime({
  uuid,
  scheduled_comment,
  scheduled_send_notification,
  scheduled_worker_uuid,
  date,
}: ScheduleChangeTimeFormDTO): Promise<ScheduleCalendarDTO> {
  const {schedule} = await apiPatch<
    Omit<ScheduleChangeTimeFormDTO, 'uuid' | 'date'>,
    IOrderAnswer
  >(`${contentApiUrl}/schedules/${uuid}/re-schedule`, {
    scheduled_comment,
    scheduled_send_notification,
    scheduled_worker_uuid,
    scheduled_date: toDateByFormat(date, 'YYYY-MM-DDTHH:mm:ss'),
  });

  return ScheduleCalendarMapper.toScheduleCalendarDTO(schedule);
}

export async function changeOrderStatus(
  scheduleUuid: string,
  {status_uuid, comment}: StatusCommentFormDTO,
): Promise<ScheduleCalendarDTO> {
  const {schedule} = await apiPatch<StatusCommentFormDTO, IOrderAnswer>(
    `${contentApiUrl}/schedules/${scheduleUuid}/status`,
    {status_uuid, comment},
  );

  return ScheduleCalendarMapper.toScheduleCalendarDTO(schedule);
}

/* ------------------- PAYMENT ------------------- */

export async function createSchedulePrepayment(
  {
    payment_comment,
    payment_date,
    payment_method,
    payment_manager_uuid,
    payment_client_uuid,
    payment_price,
    payment_post_action,
    payment_cashbox_uuid,
  }: PaymentScheduleFormDTO,
  scheduleUuid: string,
): Promise<IPaymentScheduleReturnType> {
  const {payment, schedule} = await apiPost<
    Omit<PaymentScheduleFormDTO, 'uuid'>,
    IPaymentScheduleAnswer
  >(`${contentApiUrl}/schedules/${scheduleUuid}/prepayments`, {
    payment_comment,
    payment_date: dateToIsoString(payment_date as any),
    payment_method,
    payment_manager_uuid,
    payment_client_uuid,
    payment_price,
    payment_post_action,
    payment_cashbox_uuid,
  });

  return {
    payment: PaymentMapper.toPaymentDTO(payment),
    schedule: ScheduleCalendarMapper.toScheduleCalendarDTO(schedule),
  };
}

export async function createScheduleRefunds(
  {
    payment_comment,
    payment_date,
    payment_method,
    payment_manager_uuid,
    payment_client_uuid,
    payment_price,
    payment_post_action,
    payment_cashbox_uuid,
  }: PaymentScheduleFormDTO,
  scheduleUuid: string,
): Promise<IPaymentScheduleReturnType> {
  const {payment, schedule} = await apiPost<
    Omit<PaymentScheduleFormDTO, 'uuid'>,
    IPaymentScheduleAnswer
  >(`${contentApiUrl}/schedules/${scheduleUuid}/refunds`, {
    payment_comment,
    payment_date: dateToIsoString(payment_date as any),
    payment_method,
    payment_manager_uuid,
    payment_client_uuid,
    payment_price,
    payment_post_action,
    payment_cashbox_uuid,
  });

  return {
    payment: PaymentMapper.toPaymentDTO(payment),
    schedule: ScheduleCalendarMapper.toScheduleCalendarDTO(schedule),
  };
}

/* ------------------- EXPENSE ------------------- */

export const EXPENSE_INITIAL_PARAM = new ExpenseFormDTO();

const {uuid, ...EXPENSE_INITIAL_PARAM_REST} = new ExpenseDTO(
  {} as IExpenseDTOProps,
);

export const EXPENSE_REQUIRED_FIELD = {
  ...EXPENSE_INITIAL_PARAM_REST,
};

export const EXPENSE_DISCOUNT_TYPE: (currencySymbol: string) => {
  uuid: ExpenseDiscountType;
  title: ExpenseDiscountType;
  description: string;
}[] = (currencySymbol) => [
  {
    uuid: ExpenseDiscountType.Percentage,
    title: ExpenseDiscountType.Percentage,
    description: '%',
  },
  {
    uuid: ExpenseDiscountType.Fixed,
    title: ExpenseDiscountType.Fixed,
    description: currencySymbol,
  },
];

export interface IExpenseAnswer extends ApiAnswer {
  schedule_expense: IExpenseDTOProps;

  aggregated_sum: IAggregatedSumDTOProps;
}

export interface IExpenseReturnType {
  expense: ExpenseDTO;

  aggregatedSum: AggregatedSumDTO;
}

export interface IExpenseListAnswer extends ApiAnswer {
  schedule_expenses: IExpenseDTOProps[];
  aggregated_sum: IAggregatedSumDTOProps;
}

export interface ICompanyExpenseListAnswer extends ApiAnswer, IListSearchProps {
  schedule_expenses: IExpenseDTOProps[];
}

export interface ICompanyExpenseListProps extends IListSearchProps {
  scheduleUuid: string;
}

export async function getExpenseList({
  offset = 0,
  limit = 10,
  keywords,
  scheduleUuid,
}: ICompanyExpenseListProps): Promise<IExpenseListDTO> {
  const {schedule_expenses, aggregated_sum, total} = await apiGet<
    IListSearchProps,
    IExpenseListAnswer
  >(`${contentApiUrl}/schedules/${scheduleUuid}/expenses`, {
    offset,
    limit,
    keywords,
  });

  return ExpenseMapper.toExpenseListDTO(
    schedule_expenses,
    total || 0,
    [],
    ExpenseMapper.toAggregatedSumDTO(aggregated_sum),
  );
}

export async function getExpenseById(
  schedule_expenseUuid: string,
): Promise<ExpenseDTO> {
  const {schedule_expense} = await apiGet<null, IExpenseAnswer>(
    `${contentApiUrl}/expenses/${schedule_expenseUuid}/`,
  );

  return ExpenseMapper.toExpenseDTO(schedule_expense);
}

export async function createExpense(
  {
    comment,
    price,
    net_price,
    discount,
    discount_type,
    workable_type,
    workable_uuid,
    manager_uuid,
    amount,
  }: ExpenseFormDTO | ExpenseCreateFormDTO,
  scheduleUuid: string,
): Promise<IExpenseReturnType> {
  const {schedule_expense, aggregated_sum} = await apiPost<
    Omit<ExpenseFormDTO, 'uuid'>,
    IExpenseAnswer
  >(`${contentApiUrl}/schedules/${scheduleUuid}/expenses`, {
    comment,
    price,
    net_price,
    discount,
    discount_type,
    workable_type,
    workable_uuid,
    manager_uuid,
    amount,
  });

  return {
    expense: ExpenseMapper.toExpenseDTO(schedule_expense),
    aggregatedSum: ExpenseMapper.toAggregatedSumDTO(aggregated_sum),
  };
}

export async function editExpense({
  uuid: expense_uuid,
  comment,
  price,
  net_price,
  discount,
  discount_type,
  workable_type,
  workable_uuid,
  manager_uuid,
  amount,
}: Omit<ExpenseFormDTO, 'getTimingList'>): Promise<IExpenseReturnType> {
  const {schedule_expense, aggregated_sum} = await apiPut<
    Omit<ExpenseFormDTO, 'uuid'>,
    IExpenseAnswer
  >(`${contentApiUrl}/schedule-expenses/${expense_uuid}`, {
    comment,
    price,
    net_price,
    discount,
    discount_type,
    workable_type,
    workable_uuid,
    manager_uuid,
    amount,
  });

  return {
    expense: ExpenseMapper.toExpenseDTO(schedule_expense),
    aggregatedSum: ExpenseMapper.toAggregatedSumDTO(aggregated_sum),
  };
}

export async function deleteExpense(
  ids: string[] = [],
): Promise<IExpenseReturnType> {
  const {schedule_expense, aggregated_sum} = await apiDelete<
    {ids: string[]},
    IExpenseAnswer
  >(`${contentApiUrl}/schedule-expenses/${head(ids)}`);

  return {
    expense: ExpenseMapper.toExpenseDTO(schedule_expense),
    aggregatedSum: ExpenseMapper.toAggregatedSumDTO(aggregated_sum),
  };
}

export async function changeExpenseAmount(
  expenseUuid: string,
  amount: number,
): Promise<IExpenseReturnType> {
  const {schedule_expense, aggregated_sum} = await apiPatch<
    {amount: number},
    IExpenseAnswer
  >(`${contentApiUrl}/schedule-expenses/${expenseUuid}`, {
    amount,
  });

  return {
    expense: ExpenseMapper.toExpenseDTO(schedule_expense),
    aggregatedSum: ExpenseMapper.toAggregatedSumDTO(aggregated_sum),
  };
}

/* ------------------- SCHEDULE DOCUMENTS ------------------- */

export interface IScheduleDocumentAnswer extends ApiAnswer {
  html: string;
}

export async function getScheduleDocument(
  scheduleUuid: string,
  documentType: ScheduleDocumentType = ScheduleDocumentType.ActCompletedWork,
): Promise<string> {
  const {html} = await apiGet<null, IScheduleDocumentAnswer>(
    `${contentApiUrl}/schedules/${scheduleUuid}/docs/${documentType}`,
  );

  return html;
}

/* ------------------- SCHEDULE MESSAGES ------------------- */

export interface IFormingScheduleMessageAnswer extends ApiAnswer {
  message: string;
}

export interface ISendScheduleMessageAnswer extends ApiAnswer {
  notifications: {addressee: string; message: string; status: string}[];
}

export async function formingScheduleMessage(
  scheduleUuid: string,
  templateUuid: string,
): Promise<string> {
  const {message} = await apiGet<null, IFormingScheduleMessageAnswer>(
    `${contentApiUrl}/schedules/${scheduleUuid}/templates/${templateUuid}/parse`,
  );

  return message;
}

export async function sendScheduleMessage({
  uuid,
  message,
  type,
  addressees,
}: ScheduleMessageFormDTO): Promise<ISendScheduleMessageAnswer> {
  return await apiPost<
    Pick<ScheduleMessageFormDTO, 'message' | 'addressees'>,
    ISendScheduleMessageAnswer
  >(`${contentApiUrl}/schedules/${uuid}/send/${type}`, {
    message,
    addressees: Array.isArray(addressees) ? addressees : [addressees],
  });
}
