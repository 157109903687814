import {IFinancialInformationReturnType} from '../internal';

export interface IFinancialInformationType {
  total: number;
  percent: string;
  rate: number;
}

export interface IFinancialInformationDTOProps {
  date: string;
  total: number;
  currency: string;
  types: {
    cashbox: IFinancialInformationType;
    bank: IFinancialInformationType;
    account: IFinancialInformationType;
  };
}

export class FinancialInformationDTO {
  public date: string;

  public total: number;

  public currency: string;

  public types: {
    cashbox: IFinancialInformationType;
    bank: IFinancialInformationType;
    account: IFinancialInformationType;
  };

  constructor(
    props?: IFinancialInformationDTOProps | IFinancialInformationReturnType,
  ) {
    this.date = props?.date || '';
    this.total = props?.total || 0;
    this.currency = props?.currency || '';
    this.types = props?.types || ({cashbox: {}, bank: {}, account: {}} as any);
  }
}
