import {Dispatch} from 'redux';
import {DEFAULT_COMPANY} from '../constants';
import {CompanyModel, CompanySettingsDTO} from '../../struture';

export interface ISetDefaultCompany {
  type: DEFAULT_COMPANY;
  defaultCompany: CompanyModel;
  defaultCompanyUuid: string;
  settings: CompanySettingsDTO;
}

export interface ISetDefaultCompanySettings {
  type: DEFAULT_COMPANY;
  settings: CompanySettingsDTO;
}

export interface IUpdateDefaultCompany {
  type: DEFAULT_COMPANY;
  defaultCompany: CompanyModel;
  settings: CompanySettingsDTO;
}

export interface IDeleteDefaultCompany {
  type: DEFAULT_COMPANY;
  defaultCompanyUuid: string;
}

export interface IErrorDefaultCompany {
  type: DEFAULT_COMPANY;
  error: Error | undefined;
}

export interface ICompanySettings {
  type: DEFAULT_COMPANY;
  settings: CompanySettingsDTO;
}

export function setDefaultCompany(
  defaultCompany: CompanyModel,
  defaultCompanyUuid: string,
  settings: CompanySettingsDTO,
): (dispatch: Dispatch) => Promise<ISetDefaultCompany> {
  return async function (dispatch: Dispatch) {
    dispatch({
      type: DEFAULT_COMPANY.LOADING_DEFAULT_COMPANY,
    });

    return dispatch({
      type: DEFAULT_COMPANY.SET_DEFAULT_COMPANY,
      defaultCompany,
      defaultCompanyUuid,
      settings,
    });
  };
}

export function setDefaultCompanySettings(
  settings: CompanySettingsDTO,
): (dispatch: Dispatch) => Promise<ISetDefaultCompanySettings> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: DEFAULT_COMPANY.SET_DEFAULT_COMPANY_SETTINGS,
      settings,
    });
  };
}

export function updateDefaultCompany(
  defaultCompany: CompanyModel,
  settings: CompanySettingsDTO,
): (dispatch: Dispatch) => Promise<IUpdateDefaultCompany> {
  return async function (dispatch: Dispatch) {
    dispatch({
      type: DEFAULT_COMPANY.LOADING_DEFAULT_COMPANY,
    });
    return dispatch({
      type: DEFAULT_COMPANY.UPDATE_DEFAULT_COMPANY,
      defaultCompany,
      settings,
    });
  };
}

export function deleteDefaultCompany(
  defaultCompanyUuid: string,
): (dispatch: Dispatch) => Promise<IDeleteDefaultCompany> {
  return async function (dispatch: Dispatch) {
    dispatch({
      type: DEFAULT_COMPANY.LOADING_DEFAULT_COMPANY,
    });
    return dispatch({
      type: DEFAULT_COMPANY.DELETE_DEFAULT_COMPANY,
      defaultCompanyUuid,
    });
  };
}

export function errorDefaultCompany(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorDefaultCompany> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: DEFAULT_COMPANY.ERROR_DEFAULT_COMPANY,
      error,
    });
  };
}

export function setCompanySettings(
  settings: CompanySettingsDTO,
): (dispatch: Dispatch) => Promise<ICompanySettings> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: DEFAULT_COMPANY.SET_COMPANY_SETTINGS,
      settings,
    });
  };
}
