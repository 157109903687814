import {sortByName} from './sort';
import {checkArrayLength} from './array';
import {CompanyProps} from '../models/Company';

/**
 * @desc Перевірка чи є юзер власником хоча б в одній компанії
 * @param {Array} companies
 * @param {String} userId
 * @return {Boolean}
 * */

export function isOwnerInOnceOfCompany(
  companies: CompanyProps[],
  userId: string,
): boolean | void {
  try {
    const isOwner = (company) => company?.created_by?.uuid === userId;

    return companies.some(isOwner);
  } catch (e) {
    console.error(e.message);
  }
}

/**
 * @desc Перевірка чи є юзер співробітником хоча б в одній компанії
 * @param {Array} companies
 * @param {String} userId
 * @return {Boolean}
 * */

export function isWorkInOnceOfCompany(
  companies: CompanyProps[],
  userId: string,
): boolean | void {
  try {
    const isEmployee = (company) => company?.created_by?.uuid !== userId;

    return companies.some(isEmployee);
  } catch (e) {
    console.error(e.message);
  }
}

/**
 * @desc Повернення компаній в яких є власником
 * @param {Array} companies
 * @param {String} userId
 * @return {Array}
 * */

export function getMyCompanies(
  companies: CompanyProps[],
  userId: string,
): CompanyProps[] | void {
  try {
    if (companies?.length === 0) {
      return null;
    }

    const isOwner = (company) => company?.created_by?.uuid === userId;

    const myCompanies = companies.filter(isOwner);

    if (myCompanies?.length === 0) {
      return null;
    }

    // @ts-ignore
    return myCompanies.sort(sortByName('title')).toArray();
  } catch (e) {
    console.error(e.message);
  }
}

/**
 * @desc Повернення компаній в яких користувач працює
 * @param {Array} companies
 * @param {String} userId
 * @return {Array}
 * */

export function getCompaniesEmployeeWork(
  companies: any,
  userId: string,
): CompanyProps[] {
  try {
    if (checkArrayLength(companies)) {
      return null;
    }

    const isOwner = (company) => company?.created_by?.uuid !== userId;

    const workIn = companies.filter(isOwner);

    if (checkArrayLength(workIn)) {
      return null;
    }
    return workIn.sort(sortByName('title')).toArray();
  } catch (e) {
    console.error(e.message);
  }
}

/**
 * @desc Оновлення базової інформації для input форми - initialValues
 * @param {Object} init - базова інформація для форми
 * @param {Object} update - оновлена інформація для форми
 * */
export const updateInformation = (init, update) => {
  const store = {};

  for (const key in init) {
    if (key in update) {
      store[key] = update[key];
    } else if (update?.categories) {
      store['category_uuid'] = update?.categories[0]?.uuid;
    }
  }

  return store;
};

/**
 * @desc Оновлення для полів верифікації - inputInformation
 * @param {Object} inputInformation - базова інформація для форми
 * @param {Object} verify - оновлена інформація для форми
 * */
export const updateInputInformation = (inputInformation, verify) =>
  inputInformation.map((value) => {
    for (let i = 0; i < verify.length; i++) {
      if (verify[i]?.name === value.name) {
        return {...value, ...verify[i]};
      }
    }
    return value;
  });
