import {List} from 'immutable';
import {FileDTO, IFileDTOProps, FileModel, FileListModel} from '../internal';
import {listToArray} from '../../helpers';
import {WithoutRecursion} from '../type';

export interface IFileListDTO {
  files: FileDTO[];
  total: number;
}

export class FileMapper {
  public static toFileDTO(
    file: FileModel | IFileDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): FileDTO {
    return new FileDTO(file, [WithoutRecursion.file, ...withoutRecursion]);
  }

  public static toFileListDTO(
    files: List<FileModel> | IFileDTOProps[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): IFileListDTO {
    const fileList = List.isList(files) ? listToArray<FileModel>(files) : files;

    return {
      files: fileList?.map((file) =>
        FileMapper.toFileDTO(file, [
          WithoutRecursion.file,
          ...withoutRecursion,
        ]),
      ),
      total,
    };
  }

  public static toFileModel(
    fileDTO: FileDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): FileModel {
    return new FileModel(fileDTO, [WithoutRecursion.file, ...withoutRecursion]);
  }

  public static toFileListModel(
    fileDTOs: FileDTO[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): FileListModel {
    return new FileListModel({files: fileDTOs, total}, [
      WithoutRecursion.file,
      ...withoutRecursion,
    ]);
  }
}
