import {Record} from 'immutable';
import {
  CompanyDTO,
  MailingTemplateDTO,
  CompanyModel,
  CompanyMapper,
} from '../internal';
import {WithoutRecursion} from '../type';

enum MAILING_TEMPLATES_TYPE {
  SMS = 'sms',
  EMAIL = 'email',
  VIBER = 'viber',
  ALL = 'all',
}

export interface IMailingTemplateModelReturnType {
  template_type: MAILING_TEMPLATES_TYPE;
  template_uuid: string;
  template_title: string;
  template_event: string;
  template_body: string;
  template_comment: string;
  company?: CompanyDTO | CompanyModel;
  created_at: string;
  updated_at: string;
}

export class MailingTemplateModel extends Record<IMailingTemplateModelReturnType>(
  {
    template_type: MAILING_TEMPLATES_TYPE.EMAIL,
    template_uuid: '',
    template_title: '',
    template_event: '',
    template_body: '',
    template_comment: '',
    created_at: '',
    updated_at: '',
    company: {} as CompanyModel,
  },
) {
  constructor(
    props: MailingTemplateDTO = {} as MailingTemplateDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IMailingTemplateModelReturnType = {
      ...props,
      company: props?.company
        ? withoutRecursion.indexOf(WithoutRecursion.company) !== -1
          ? props?.company
          : CompanyMapper.toCompanyModel(props?.company, [
              WithoutRecursion.employee,
              ...withoutRecursion,
            ])
        : undefined,
    };

    super(options);
  }

  get companyModel(): CompanyModel | undefined {
    return this.company
      ? this.company instanceof CompanyModel
        ? this.company
        : CompanyMapper.toCompanyModel(this.company)
      : undefined;
  }
}
