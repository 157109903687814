import {Record, List} from 'immutable';
import {
  ClientDTO,
  ClientMapper,
  ClientModel,
  CompanyDTO,
  CompanyMapper,
  CompanyModel,
  ProfileDTO,
  ProfileMapper,
  ProfileModel,
  StoreDocumentDTO,
  StoreDocumentItemDTO,
  StoreDocumentType,
  StoreDTO,
  StoreMapper,
  StoreModel,
  StoreDocumentMapper,
  StoreDocumentItemModel,
  DocTriggerableType,
  ScheduleCalendarModel,
  StoreDocumentExtraServiceDTO,
  StoreDocumentMultiCurrencyDTO,
  StorePaymentDocumentDiscountSourceType,
  StorePaymentDocumentDiscountType,
  StoreDocumentProfitStatsDTO,
} from '../internal';
import {WithoutRecursion} from '../type';
import {StoreDocumentStatus} from '../dto/StoreDocumentDTO';

export interface IStoreDocumentModelReturnType {
  uuid: string;
  doc_type: StoreDocumentType;
  doc_date: string;
  doc_local_number: string;
  doc_input_number: string;
  doc_sum_paid: string;
  doc_sum_total: string;
  doc_comment: string;
  doc_status_text: StoreDocumentStatus;
  created_at: string;
  updated_at: string;
  doc_items: List<StoreDocumentItemModel> | StoreDocumentItemDTO[];
  company_store: StoreModel | StoreDTO;
  company_destination_store: StoreModel | StoreDTO;
  created_by: ProfileModel | ProfileDTO;
  company: CompanyModel | CompanyDTO;
  client: ClientModel | ClientDTO;
  document: StoreDocumentDTO | StoreDocumentModel;
  doc_is_paymentable: boolean;
  doc_is_editable: boolean;
  doc_triggerable_type: DocTriggerableType | null;
  doc_triggerable: ScheduleCalendarModel | StoreDocumentModel | null;
  doc_is_relatable: boolean;
  count_of_related_documents: number;
  is_add_doc_items?: boolean;
  doc_extra_services?: StoreDocumentExtraServiceDTO[];
  doc_multi_currencies?: StoreDocumentMultiCurrencyDTO[];
  doc_is_checkable: boolean;
  doc_discount_source?: StorePaymentDocumentDiscountSourceType;
  doc_discount_type?: StorePaymentDocumentDiscountType;
  doc_discount_value?: string;
  doc_sum_total_with_discount: string;
  doc_sum_discount: string;
  doc_sum_services: string;
  stats?: StoreDocumentProfitStatsDTO;
}

export class StoreDocumentModel extends Record<IStoreDocumentModelReturnType>({
  uuid: '',
  doc_type: '' as StoreDocumentType,
  doc_date: '',
  doc_local_number: '',
  doc_input_number: '',
  doc_sum_paid: '',
  doc_sum_total: '',
  doc_comment: '',
  doc_status_text: StoreDocumentStatus.DRAFT,
  created_at: '',
  updated_at: '',
  doc_items: List(),
  company_store: {} as StoreModel,
  company_destination_store: {} as StoreModel,
  company: {} as CompanyModel,
  created_by: {} as ProfileModel,
  client: {} as ClientModel,
  document: {} as any,
  doc_is_paymentable: false,
  doc_is_editable: false,
  doc_triggerable_type: null,
  doc_triggerable: null,
  doc_is_relatable: false,
  count_of_related_documents: 0,
  is_add_doc_items: false,
  doc_extra_services: [],
  doc_multi_currencies: [],
  doc_is_checkable: false,
  doc_discount_source: undefined,
  doc_discount_type: undefined,
  doc_discount_value: undefined,
  doc_sum_total_with_discount: '0.00',
  doc_sum_discount: '0.00',
  doc_sum_services: '0.00',
  stats: undefined,
}) {
  constructor(
    props: StoreDocumentDTO = {} as StoreDocumentDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IStoreDocumentModelReturnType = {
      ...props,
      doc_items:
        withoutRecursion.indexOf(WithoutRecursion.storeDocument) !== -1
          ? props?.doc_items
          : StoreDocumentMapper.toStoreDocumentItemListModel(props?.doc_items)
              .doc_items,
      company_store:
        withoutRecursion.indexOf(WithoutRecursion.store) !== -1
          ? props?.company_store
          : StoreMapper.toStoreDTO(props?.company_store, [
              WithoutRecursion.storeDocument,
              ...withoutRecursion,
            ]),

      company_destination_store:
        withoutRecursion.indexOf(WithoutRecursion.store) !== -1
          ? props?.company_destination_store
          : StoreMapper.toStoreDTO(props?.company_destination_store, [
              WithoutRecursion.storeDocument,
              ...withoutRecursion,
            ]),
      created_by:
        withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
          ? props?.created_by
          : ProfileMapper.toProfileModel(props?.created_by, [
              WithoutRecursion.storeDocument,
              ...withoutRecursion,
            ]),
      company:
        withoutRecursion.indexOf(WithoutRecursion.company) !== -1
          ? props?.company
          : CompanyMapper.toCompanyModel(props?.company, [
              WithoutRecursion.storeDocument,
              ...withoutRecursion,
            ]),

      client:
        withoutRecursion.indexOf(WithoutRecursion.client) !== -1
          ? props?.client
          : ClientMapper.toClientModel(props?.client, true, [
              WithoutRecursion.storeDocument,
              ...withoutRecursion,
            ]),

      document:
        withoutRecursion.indexOf(WithoutRecursion.storeDocument) !== -1
          ? props?.document
          : StoreDocumentMapper.toStoreDocumentModel(props?.document, [
              WithoutRecursion.storeDocument,
              ...withoutRecursion,
            ]),
    };

    super(options);
  }

  get companyModel(): CompanyModel {
    return this.company instanceof CompanyModel
      ? this.company
      : CompanyMapper.toCompanyModel(this.company);
  }

  get createdByModel(): ProfileModel {
    return this.created_by instanceof ProfileModel
      ? this.created_by
      : ProfileMapper.toProfileModel(this.created_by);
  }

  get companyStoreModel(): StoreModel {
    return this.company_store instanceof StoreModel
      ? this.company_store
      : StoreMapper.toStoreModel(this.company_store);
  }

  get companyDestinationStoreModel(): StoreModel {
    return this.company_destination_store instanceof StoreModel
      ? this.company_destination_store
      : StoreMapper.toStoreModel(this.company_destination_store);
  }
  get clientModel(): ClientModel {
    return this.client instanceof ClientModel
      ? this.client
      : ClientMapper.toClientModel(this.client);
  }

  get documentModel(): StoreDocumentModel {
    return this.document instanceof StoreDocumentModel
      ? this.document
      : StoreDocumentMapper.toStoreDocumentModel(this.document);
  }
}
