import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {StoreFormDTO} from '../../../struture';
import {useModal} from '../../../components/lib/libV2/hooks';
import {
  EditButton,
  IEditButtonProps,
  SuspenseEmpty,
} from '../../../components/lib/DataDisplay';
import {IUseStateStoreReturnType} from '../../../hooks';

const StoreSideWindowLazy = React.lazy(() => import('../Show/StoreSideWindow'));

export interface IStoreUpdateButtonProps extends IEditButtonProps {
  children?: React.ReactNode;
  onSuccess: IUseStateStoreReturnType['handleUpdateStore'];
  onCancel?: () => void;
  store: StoreFormDTO;
}

export function StoreUpdateButton({
  children,
  onSuccess,
  onCancel,
  loading,
  store,
  ...rest
}: IStoreUpdateButtonProps): React.JSX.Element {
  const {t} = useTranslation();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <EditButton
        tooltipTitle={t('Edit store')}
        onClick={handleOnInit}
        disabled={false}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <StoreSideWindowLazy
          editMode
          store={store}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}
