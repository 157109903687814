import {Record, List} from 'immutable';
import {IListProps} from './List';
import {ServiceDTO, ServiceModel} from '../internal';
import {WithoutRecursion} from '../type';

export interface IServiceListModelProps extends IListProps {
  services: ServiceDTO[];
}

export interface IServiceListModelReturnType extends IListProps {
  services: List<ServiceModel>;
  keywords?: string;
  page?: number;
}

export class ServiceListModel extends Record<IServiceListModelReturnType>({
  services: List(),
  total: 0,
  page: 1,
  keywords: '',
}) {
  constructor(
    {
      services = [],
      total,
    }: IServiceListModelProps = {} as IServiceListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IServiceListModelReturnType = {
      services: List(
        services.map((service) => new ServiceModel(service, withoutRecursion)),
      ),
      total,
    };

    super(options);
  }
}
