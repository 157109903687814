import React, {RefObject} from 'react';
import styled from 'styled-components';
import {SearchInput} from '../../General';
import {InputProps} from 'antd/lib/input';

export default ListSearchInput;

export interface IListSearchInputProps extends InputProps {
  handleSearch: (value: string) => Promise<void>;
  loading?: boolean;
  tooltipTitle?: string;
  label?: string;
  fullWidth?: boolean;
  searchText?: string;
  toggleValue?: any;
  inputRef?: RefObject<HTMLInputElement>;
  searchTextRef?: React.MutableRefObject<string>;
}

const StyledSearchInput = styled(SearchInput)`
  width: auto;
  flex: 1;
`;

function ListSearchInput({
  handleSearch,
  loading = false,
  label,
  fullWidth,
  searchText = '',
  inputRef,
  searchTextRef,
  toggleValue,
  tooltipTitle,
  ...rest
}: IListSearchInputProps): JSX.Element {
  return (
    <StyledSearchInput
      allowClear
      ref={inputRef}
      placeholder={label || ''}
      size="middle"
      defaultValue={searchText}
      handleSearch={handleSearch}
      loading={loading}
      fullWidth={fullWidth}
      searchTextRef={searchTextRef}
      {...rest}
    />
  );
}
