import {apiDelete, apiGet, apiPatch, apiPost, apiPut} from '../core/api';
import {head} from '../helpers';
import {ApiAnswer, IListSearchProps} from '../types';
import {
  AbonementGroupDTO,
  AbonementGroupFormDTO,
  AbonementGroupMapper,
  AbonementGroupModel,
  AbonementGroupStatuses,
  ClientAbonementListDTO,
  ClientAbonementMapper,
  IAbonementGroupDTOProps,
  IAbonementGroupListDTO,
  IClientAbonementDTOProps,
} from '../../struture';
import {contentApiUrl} from '../const';

export const ABONEMENT_GROUP_INITIAL_PARAM = new AbonementGroupFormDTO();

const {
  uuid,
  updated_at,
  created_at,
  group_status,
  group_status_text,
  company_abonement,
  manager,
  ...ABONEMENT_GROUP_INITIAL_PARAM_REST
} = new AbonementGroupDTO({} as IAbonementGroupDTOProps);

export const ABONEMENT_GROUP_REQUIRED_FIELD = {
  ...ABONEMENT_GROUP_INITIAL_PARAM_REST,
};

export interface IAbonementGroupAnswer extends ApiAnswer {
  group: IAbonementGroupDTOProps;
}

export interface IAbonementGroupListAnswer extends ApiAnswer {
  groups: IAbonementGroupDTOProps[];
}

export interface ICompanyAbonementGroupListAnswer
  extends ApiAnswer,
    IListSearchProps {
  group: IAbonementGroupDTOProps;
}

export interface ICompanyAbonementGroupListProps extends IListSearchProps {
  abonementUuid: string;
}

export interface ITariffModelGroupClientAbonementListProps
  extends IListSearchProps {
  groupUuid: string;
}

export interface ITariffModelGroupClientAbonementListAnswer extends ApiAnswer {
  abonements: IClientAbonementDTOProps[];
  group: IAbonementGroupDTOProps;
}

export async function getAbonementGroupList({
  offset = 0,
  limit = 10,
  keywords,
  abonementUuid,
}: ICompanyAbonementGroupListProps): Promise<IAbonementGroupListDTO> {
  const {groups, count} = await apiGet<
    IListSearchProps,
    IAbonementGroupListAnswer
  >(`${contentApiUrl}/abonements/${abonementUuid}/groups`, {
    offset,
    limit,
    keywords,
  });

  return AbonementGroupMapper.toAbonementGroupListDTO(groups, count || 0);
}

export async function getAbonementGroupById(
  abonementGroupUuid: string,
): Promise<AbonementGroupDTO> {
  const {group} = await apiGet<null, IAbonementGroupAnswer>(
    `${contentApiUrl}/abonement-groups/${abonementGroupUuid}`,
  );

  return AbonementGroupMapper.toAbonementGroupDTO(group);
}

export async function createAbonementGroup(
  {manager_uuid, title, schedule, comment}: AbonementGroupFormDTO,
  abonementUuid: string,
): Promise<AbonementGroupDTO> {
  const {group} = await apiPost<
    Omit<AbonementGroupFormDTO, 'uuid'>,
    IAbonementGroupAnswer
  >(`${contentApiUrl}/abonements/${abonementUuid}/groups`, {
    manager_uuid,
    title,
    schedule,
    comment,
  });

  return AbonementGroupMapper.toAbonementGroupDTO(group);
}

export async function editAbonementGroup({
  uuid: abonement_uuid,
  manager_uuid,
  title,
  schedule,
  comment,
}: Omit<AbonementGroupFormDTO, 'getTimingList'>): Promise<AbonementGroupDTO> {
  const {group} = await apiPut<
    Omit<AbonementGroupFormDTO, 'uuid'>,
    IAbonementGroupAnswer
  >(`${contentApiUrl}/abonement-groups/${abonement_uuid}`, {
    manager_uuid,
    title,
    schedule,
    comment,
  });

  return AbonementGroupMapper.toAbonementGroupDTO(group);
}

export async function deleteAbonementGroup(
  ids: string[] = [],
): Promise<AbonementGroupDTO> {
  const {group} = await apiDelete<
    {ids: string[]},
    ICompanyAbonementGroupListAnswer
  >(`${contentApiUrl}/abonement-groups/${head(ids)}`);

  return AbonementGroupMapper.toAbonementGroupDTO(group);
}

export async function changeAbonementGroupStatus(
  abonementGroupUuid: string,
  status: AbonementGroupStatuses,
): Promise<AbonementGroupDTO> {
  const {group} = await apiPatch<
    {status: AbonementGroupStatuses},
    IAbonementGroupAnswer
  >(`${contentApiUrl}/abonement-groups/${abonementGroupUuid}/status`, {
    status,
  });

  return AbonementGroupMapper.toAbonementGroupDTO(group);
}

export async function getTariffModelGroupClientsAbonementList({
  offset = 0,
  limit = 10,
  keywords,
  groupUuid,
}: ITariffModelGroupClientAbonementListProps): Promise<
  ClientAbonementListDTO<AbonementGroupModel>
> {
  const {abonements, group, count} = await apiGet<
    IListSearchProps,
    ITariffModelGroupClientAbonementListAnswer
  >(`${contentApiUrl}/abonement-groups/${groupUuid}/abonements`, {
    offset,
    limit,
    keywords,
  });

  return ClientAbonementMapper.toClientAbonementListDTO(
    abonements,
    count || 0,
    AbonementGroupMapper.toAbonementGroupModel(group as AbonementGroupDTO),
  );
}
