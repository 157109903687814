import {useRef, useEffect, useCallback} from 'react';
import {CanceledPromiseError} from '../components/lib/Errors';
import {isThereContent, eq} from '../services/helpers';

export interface IMakeCancelableReturnType {
  promise: Promise<any>;
  cancel: () => void;
}

export function makeCancelable(
  promise: Promise<any>,
): IMakeCancelableReturnType {
  let isCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise
      .then((val) =>
        isCanceled ? reject(new CanceledPromiseError()) : resolve(val),
      )
      .catch((error) =>
        isCanceled ? reject(new CanceledPromiseError()) : reject(error),
      );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      isCanceled = true;
    },
  };
}

/**
 * @info took and modified from https://github.com/rajeshnaroth/react-cancelable-promise-hook
 * @param cancelable
 * @returns {{cancellablePromise: (function(*=): Promise<any>)}}
 */
export default function useCancellablePromise<T>(
  cancelable: (
    promise: Promise<any>,
  ) => IMakeCancelableReturnType = makeCancelable,
) {
  const emptyPromise = Promise.resolve(true);

  if (eq(cancelable(emptyPromise).cancel, undefined)) {
    throw new Error(
      'promise wrapper argument must provide a cancel() function',
    );
  }

  const promises = useRef<IMakeCancelableReturnType[]>();

  useEffect(() => {
    promises.current = promises.current || [];

    return () => {
      if (isThereContent(promises.current)) {
        promises.current.forEach((p) => p.cancel());
        promises.current = [];
      }
    };
  }, []);

  const cancellablePromise: <T>(p: Promise<any>) => Promise<T> = useCallback(
    <T>(p: Promise<any>): Promise<T> => {
      const cPromise = cancelable(p);

      promises.current = promises.current
        ? [...promises.current, cPromise]
        : [cPromise];

      return cPromise.promise;
    },
    [cancelable],
  );

  return {cancellablePromise};
}
