import React, {useCallback} from 'react';
import {ICalendarManagerProps} from '../../../contex';
import {ScheduleCalendarModel} from '../../../struture';
import {Routes, RoutesAcl} from '../../../services/types';
import ClientCalendarPopover from '../Show/ClientCalendarPopover';
import {ClientAbonementScheduleDetail} from '../Show';
import {
  useDefaultCalendarScheduleProps,
  useStoredCompanies,
} from '../../../hooks';
import {useLocation, useNavigate, useParams} from 'react-router';
import {HeaderLeft} from '../../../components/lib/DataDisplay';
import {SingleLayout} from '../../../components/lib/Layout';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';

export interface IClientAbonementCalendarPageProps {
  children: (
    props: ICalendarManagerProps<ScheduleCalendarModel>,
  ) => React.ReactNode;
}

export function ClientAbonementCalendarPage({
  children,
}: IClientAbonementCalendarPageProps): JSX.Element {
  const {clientId, abonementId} = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const defaultCalendarProps = useDefaultCalendarScheduleProps();
  const {defaultCompany} = useStoredCompanies();

  const navigateGoBack = useCallback(() => {
    navigate(location?.state?.goBack || -1);
  }, [location?.state?.goBack, navigate]);

  const routes = useBreadcrumb([
    {
      path: `/${Routes.clients}`,
      breadcrumbName: 'Clients',
    },
    {
      path: `/${Routes.clients}/${clientId}`,
      breadcrumbName: location?.state?.clientFullName || 'Show Client',
    },
    {
      path: `/${Routes.clients}/${clientId}/${Routes.clientAbonements}`,
      breadcrumbName: 'Client abonements',
    },
    {
      path: `/${Routes.clients}/${clientId}/${Routes.clientAbonements}/${abonementId}`,
      breadcrumbName: 'Client abonement',
    },
  ]);

  return (
    <SingleLayout
      isCalendar
      aclItem={RoutesAcl[Routes.clientAbonements]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      routes={routes}>
      {children({
        ...defaultCalendarProps,
        localStorageName: '',
        withoutStorageDate: '',
        schedules: undefined,
        SchedulePopover: ClientCalendarPopover,
        scheduleDetails: (children, schedule) => (
          //@ts-ignore
          <ClientAbonementScheduleDetail routes={routes} schedule={schedule}>
            {({routes, navigateGoBack}) => children({routes, navigateGoBack})}
          </ClientAbonementScheduleDetail>
        ),
        customDayTimeRange: defaultCompany?.work_schedules,
      })}
    </SingleLayout>
  );
}
