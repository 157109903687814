import {message} from 'antd';

export default {
  actionSuccess,
  actionError,
  addError,
  addSuccess,
  commandError,
  commandSuccess,
  createError,
  createSuccess,
  deleteError,
  deleteSuccess,
  editError,
  editSuccess,
  updateError,
  updateSuccess,
  removeError,
  removeSuccess,
  authError,
  notifyMessage,
  notifyErrorMessage,
};

export type T = any;

function createSuccess(subject: string, t: T) {
  return actionSuccess(subject, t('created'), t);
}

function createError(subject: string, err: any, t: T) {
  return actionError(subject, t('creating'), err, t);
}

function authError(subject: string, err: any, t: T) {
  return actionError(subject, t('dispatching'), err, t);
}

function notifyMessage(subject: string) {
  return message.success(subject);
}

function notifyErrorMessage(subject: string) {
  return message.error(subject);
}

function addSuccess(subject: string, t: T) {
  return actionSuccess(subject, t('added'), t);
}

function addError(subject: string, err: any, t: T) {
  return actionError(subject, t('adding'), err, t);
}

function editSuccess(subject: string, t: T) {
  return actionSuccess(subject, t('edited'), t);
}

function editError(subject: string, err: any, t: T) {
  return actionError(subject, t('editing'), err, t);
}

function updateSuccess(subject: string, t: T) {
  return actionSuccess(subject, t('updated'), t);
}

function updateError(subject: string, err: any, t: T) {
  return actionError(subject, t('updating'), err, t);
}

function deleteSuccess(subject: string, t: T) {
  return actionSuccess(subject, t('deleted'), t);
}

function deleteError(
  subject: string,
  err: any | ((string & any) | unknown),
  t: T,
) {
  return actionError(subject, t('deleting'), err, t);
}

function removeSuccess(subject: string, t: T) {
  return actionSuccess(subject, t('removed'), t);
}

function removeError(subject: string, err: any, t: T) {
  return actionError(subject, t('removing'), err, t);
}

function actionSuccess(subject = 'Record', action: string, t: T) {
  return message.success(`${subject} ${action} ${t('successfully')}.`);
}

function actionError(subject = 'record', action = 'updating', err: any, t: T) {
  const errStr = err ? `: ${typeof err === 'object' ? err.message : err}` : '.';
  return message.error(
    `${t('An error has occurred while')} ${action} ${subject}${errStr}`,
  );
}

function commandSuccess({subject}: {subject: string}) {
  return message.success(
    `A command ${subject ? `${subject} ` : ''}has been sent!`,
  );
}

function commandError(err: any) {
  const errStr = err ? `: ${typeof err === 'object' ? err.message : err}` : '.';
  return message.error(
    `An error has occurred while sending the command to the agent${errStr}`,
  );
}
