import {List} from 'immutable';
import {
  PriceMarginDTO,
  IPriceMarginDTOProps,
  PriceMarginModel,
  PriceMarginListModel,
  PriceMarginFormDTO,
} from '../internal';
import {listToArray} from '../../helpers';
import {WithoutRecursion} from '../type';

export interface IPriceMarginListDTO {
  prices: PriceMarginDTO[];
  total: number;
  keywords?: string;
  page?: number;
}

export class PriceMarginMapper {
  public static toPriceMarginDTO(
    price: PriceMarginModel | IPriceMarginDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): PriceMarginDTO {
    return new PriceMarginDTO(price, [
      WithoutRecursion.priceMargin,
      ...withoutRecursion,
    ]);
  }

  public static toPriceMarginListDTO(
    prices: List<PriceMarginModel> | IPriceMarginDTOProps[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): IPriceMarginListDTO {
    const priceList = List.isList(prices)
      ? listToArray<PriceMarginModel>(prices)
      : prices;

    return {
      prices: priceList?.map((price) =>
        PriceMarginMapper.toPriceMarginDTO(price, [
          WithoutRecursion.priceMargin,
          ...withoutRecursion,
        ]),
      ),
      total,
    };
  }

  public static toPriceMarginModel(
    priceDTO: PriceMarginDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): PriceMarginModel {
    return new PriceMarginModel(priceDTO, [
      WithoutRecursion.priceMargin,
      ...withoutRecursion,
    ]);
  }

  public static toPriceMarginListModel(
    priceDTOs: PriceMarginDTO[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): PriceMarginListModel {
    return new PriceMarginListModel({prices: priceDTOs, total}, [
      WithoutRecursion.priceMargin,
      ...withoutRecursion,
    ]);
  }

  public static toPriceMarginFormDTO(
    priceModel?: PriceMarginModel,
  ): PriceMarginFormDTO {
    return new PriceMarginFormDTO(priceModel);
  }
}
