import * as React from 'react';
import {useKeyboard} from '../contex';

export interface UseKeyboardActionsProps {
  eventListenerCallBack: (keywords: string) => void | Promise<void>;
  keyboardShow: boolean;
}

export function useKeyboardActions({
  eventListenerCallBack,
  keyboardShow,
}: UseKeyboardActionsProps) {
  const {inputRef} = useKeyboard();

  React.useEffect(() => {
    const input = inputRef.current;
    const onInput = async (e: any) => {
      eventListenerCallBack(e?.key);
    };

    if (keyboardShow) {
      if (input instanceof HTMLInputElement) {
        input.addEventListener('input', onInput);
      }
    } else {
      if (input instanceof HTMLInputElement) {
        input.removeEventListener('input', onInput);
      }
    }

    return () => {
      if (input instanceof HTMLInputElement) {
        input.removeEventListener('input', onInput);
      }
    };
  }, [eventListenerCallBack, inputRef, keyboardShow]);
}
