import React, {useMemo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {Col, Row, Input, Form} from 'antd';
import {IDefaultFormProps, DefaultForm} from '../../../components/lib/General';
import {CLIENT_GROUP_INITIAL_PARAM} from '../../../services/api/client';
import {ClientGroupFormDTO} from '../../../struture';
import {useDropdownAlert} from '../../../contex';
import {useStateCompanyClientList, useStoredCompanies} from '../../../hooks';
import {SearchSelect} from '../../../components/lib/DataDisplay';
import {isListToArray} from '../../../services/helpers';
import {ClientPersonalityType} from '../../../services/types';

export interface IClientGroupFormProps
  extends Omit<
    IDefaultFormProps<ClientGroupFormDTO, ClientGroupFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  client?: ClientGroupFormDTO;
}

export function ClientGroupForm({
  loading,
  editMode,
  client = CLIENT_GROUP_INITIAL_PARAM,
  onCancel,
  ...rest
}: IClientGroupFormProps) {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const {defaultCompanyUuid} = useStoredCompanies();

  const additionalValues = useMemo(
    () => ({
      uuid: client?.uuid,
    }),
    [client?.uuid],
  );

  const {
    clients,
    loading: clientLoading,
    handleSearchClients,
  } = useStateCompanyClientList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Client'),
        `${
          editMode
            ? t('An error occurred during edit client group')
            : t('An error occurred during create client group')
        } : ${apiError?.message}`,
      );
    },
    [alert, t, editMode],
  );

  return (
    <DefaultForm<any, any>
      withContext
      editMode={editMode}
      initialValues={{
        ...client,
      }}
      additionalValuesRequest={additionalValues}
      showNotify={false}
      onCancel={onCancel}
      notifyError={notifyError}
      {...rest}>
      {({loadingSubmit, handlerUpdateFormState, formData}) => (
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              tooltip={t('Title')}
              label={t('Title')}
              name="client_group_name"
              rules={[
                {
                  required: true,
                  message: t('Title must be specified.'),
                },
              ]}>
              <Input
                disabled={loading || loadingSubmit}
                placeholder={t('Enter the title')}
                onChange={(e) =>
                  handlerUpdateFormState({client_group_name: e.target.value})
                }
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              tooltip={t('Group members')}
              label={t('Group members')}
              name="client_uuids">
              <SearchSelect
                isMultiple
                name="client_uuids"
                getOptionValueTitle="fullNameClient"
                placeholder={t('Select a clients')}
                disable={loading || loadingSubmit || clientLoading}
                data={isListToArray(clients as any)}
                onSearch={(keywords) =>
                  handleSearchClients({
                    keywords,
                    showLoading: false,
                    limit: 100,
                  }) as any
                }
                onChange={(client_uuids) =>
                  handlerUpdateFormState({client_uuids})
                }
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </DefaultForm>
  );
}
