import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {Empty} from 'antd';
import {ListLayout} from '../../../components/lib/Layout';
import {
  useDefaultPriceMargin,
  useKeyboardOpenForm,
  useSearchInputFocus,
  useStopLoading,
  useStoredCompanies,
  useStoredPriceTagList,
  useStoredProductCategoryProductList,
  useStoredStoreList,
} from '../../../hooks';
import {
  textToUpperCase,
  correctPrice,
  listToArray,
} from '../../../services/helpers';
import {
  PriceTagMapper,
  ProductFormDTO,
  ProductMapper,
  ProductModel,
  ProductStatus,
} from '../../../struture';
import {useBreadcrumb, useModal} from '../../../components/lib/libV2/hooks';
import {
  ProductType,
  Routes,
  Routes as RoutesType,
  RoutesAcl,
  RoutesTitle,
} from '../../../services/types';
import {
  Table,
  TableActionCell,
} from '../../../components/lib/libV2/DataDisplay';
import {successButtonStyle} from '../../../components/lib/Styled';
import {
  HeaderLeft,
  ListActions,
  SuspenseEmpty,
} from '../../../components/lib/DataDisplay';
import {useLocation, useNavigate, useParams} from 'react-router';
import {ProductDetailsView, ProductStoreSearchView} from '../Show';
import {
  ProductCreateButton,
  ProductEditButton,
  ProductsDeleteButton,
} from '../Buttons';
import {useAcl} from '../../../contex';
import styled from 'styled-components';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import {SessionStorageItems} from '../../../services/const';

import './ProductList.less';

const ProductSideWindowLazy = React.lazy(
  () => import('../Show/ProductSideWindow'),
);

const PriceTagPrintModalLazy = React.lazy(
  () => import('../PriceTags/Show/PriceTagPrintModal'),
);

const StyledProductCreateButton = styled(ProductCreateButton)`
  ${successButtonStyle}
`;

const HeaderSearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
`;

export function ProductCategoryProductListPage() {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {defaultCompany} = useStoredCompanies();
  const {manage} = useAcl(({product}) => product);
  const {categoryId} = useParams();
  const location = useLocation();
  const {defaultCompanyUuid} = useStoredCompanies();
  const {ref: listRef} = useKeyboardOpenForm({
    className: 'product-category-product-create-event',
    disabled: !manage,
  });

  const [selectedProducts, setSelectedProducts] = useState<ProductModel[]>([]);
  const [storeLoading, setStoreLoading] = useState(false);
  const [priceMarginFields, setPriceMarginFields] = useState<any[]>([]);
  const [productUuid, setProductUuid] = useState<string>('');

  const {priceMarginList, defaultPriceMargin} = useDefaultPriceMargin({});

  const storeUuid = sessionStorage.getItem(
    SessionStorageItems.productStoreUuid,
  );

  useEffect(() => {
    if (priceMarginFields?.length === 0 && priceMarginList?.size) {
      const fields = priceMarginList?.map(({price_name, uuid}) => ({
        title: price_name,
        key: price_name,
        align: 'right',
        render: (product: ProductModel) => {
          const price = product?.product_prices?.find(
            ({price_uuid}) => price_uuid === uuid,
          );

          if (price) {
            return correctPrice(price?.price_value || 0);
          }
        },
      }));

      setPriceMarginFields(listToArray(fields));
    }
  }, [priceMarginFields?.length, priceMarginList, t]);

  const store_uuid = sessionStorage.getItem(
    SessionStorageItems.productStoreUuid,
  );

  const {
    productList,
    limit: pageSize,
    error: productListError,
    loading: productListLoading,
    total,
    keywords,
    page,
    category,
    loadingMore,

    handleRefreshProducts,
    handleCreateProduct: onCreateProduct,
    handleSearchProducts,
    handleEditProduct,
    handleDeleteProducts: onDeleteProducts,
  } = useStoredProductCategoryProductList({
    categoryUuid: categoryId!,
    store_uuid: store_uuid || '',
    company_uuid: defaultCompanyUuid,
  });

  const {
    storeList,
    loading: storeListLoading,
    handleSearchStores,
  } = useStoredStoreList({
    companyUuid: defaultCompanyUuid,
    loadOnInit: false,
    limit: 100,
  });

  const {handlePrintPriceTag} = useStoredPriceTagList({
    companyUuid: defaultCompanyUuid,
    loadOnInit: false,
    productUuid,
  });

  const loading = useStopLoading({
    error: productListError,
    loading: productListLoading || loadingMore,
    message: 'An error occurred during materials loading',
  });

  const {inputRef, focus, daleyFocus} = useSearchInputFocus({loading});

  const handleDeleteProducts = useCallback(
    async (value: string[]) => {
      await onDeleteProducts(value);
      focus();
    },
    [focus, onDeleteProducts],
  );

  const onStoreChange = useCallback(
    async (store_uuid: string) => {
      setStoreLoading(true);
      await handleSearchProducts({
        keywords: '',
        store_uuid,
        showLoading: true,
      });
      setStoreLoading(false);
    },
    [handleSearchProducts],
  );

  const productRowSelection = useMemo(
    () => ({
      hideSelectAll: true,
      selectedRowKeys: selectedProducts.map(({uuid}) => uuid),
      onChange: (
        selectedRowKeys: React.Key[],
        selectedRecords: ProductModel[],
      ) => {
        setSelectedProducts(selectedRecords);
      },
    }),
    [selectedProducts],
  );

  const productColumns = [
    {
      title: t('Title'),
      key: 'title',
      render: (product: ProductModel) => (
        <ProductDetailsView
          product={product}
          to={`/${Routes.app}/${Routes.goods}/${Routes.categories}/${categoryId}/${Routes.categoryProducts}/${product?.uuid}`}
        />
      ),
    },
    {
      title: `${t('Price')}, ${defaultCompany?.currency_symbol || ''}`,
      key: 'price',
      children: priceMarginFields,
    },
    {
      title: t('Actions'),
      key: 'actions',
      align: 'center' as any,
      fixed: 'right' as any,
      render: (product: ProductModel) => (
        <TableActionCell>
          <>
            <ProductEditButton
              // disabled={!manage}
              type="text"
              product={ProductMapper.toProductFormDTO(product, {
                editMode: true,
                disabledCategory: true,
              })}
              onSuccess={handleEditProduct as any}
              onCancel={daleyFocus}
            />
            <ProductsDeleteButton
              // disabled={!manage}
              products={[product]}
              onSuccess={handleDeleteProducts}>
              <DeleteOutlined />
            </ProductsDeleteButton>
          </>
        </TableActionCell>
      ),
    },
  ];

  const {
    handleCancel: productHandleCancel,
    handleOnInit: productHandleOnInit,
    visible: productVisible,
  } = useModal({
    onCancel: () => {},
    onSuccess: () => {},
  });

  const {
    handleCancel: priceTagHandleCancel,
    handleOnInit: priceTagHandleOnInit,
    visible: priceTagVisible,
    handleSuccess: priceTagHandleSuccess,
  } = useModal({
    onCancel: daleyFocus,
    onSuccess: handlePrintPriceTag,
  });

  const handleCreateProduct = useCallback(
    async (value: ProductFormDTO) => {
      const product = await onCreateProduct(value);

      productHandleCancel();

      if (value?.print_price_tag && product) {
        setProductUuid(product?.uuid);
        setTimeout(priceTagHandleOnInit, 250);
      }

      if (value?.add_another_product) {
        setTimeout(productHandleOnInit, 250);
      }
    },
    [
      onCreateProduct,
      priceTagHandleOnInit,
      productHandleCancel,
      productHandleOnInit,
    ],
  );

  const routes = useBreadcrumb([
    {
      path: `/${Routes.goods}/${Routes.categories}`,
      breadcrumbName: 'Categories',
      state: {type: ProductType.Category},
    },
    {
      path: `/${Routes.goods}/${Routes.categories}/${categoryId}`,
      breadcrumbName: category?.title || 'Show category',
      state: {type: ProductType.Category},
    },
    {
      path: `/${Routes.goods}/${Routes.categories}/${categoryId}/${Routes.categoryProducts}`,
      breadcrumbName: 'Goods and materials values',
    },
  ]);

  const navigateGoBack = useCallback(() => {
    navigate(
      location?.state?.goBack ||
        `/${Routes.app}/${Routes.goods}/${Routes.categories}/${categoryId}`,
    );
  }, [categoryId, location?.state?.goBack, navigate]);

  return (
    <>
      <ListLayout
        ref={listRef}
        aclItem={RoutesAcl[Routes.products]}
        headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
        headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.products]))}
        headerRight={
          <ProductCreateButton
            disabled={!manage}
            loading={loading}
            onSuccess={handleCreateProduct}
            product={ProductMapper.toProductFormDTO(
              {
                categoryModel: category,
                category,
              } as ProductModel,
              {editMode: true, disabledCategory: true},
            )}
            className="product-category-product-create-event"
            onCancel={daleyFocus}
          />
        }
        routes={routes}
        empty={
          productList?.size ? null : (
            <Empty
              description={t(
                `It looks like you don't have any materials at the moment.`,
              )}>
              <ProductCreateButton
                type="primary"
                disabled={!manage}
                loading={loading}
                onSuccess={handleCreateProduct}
                product={ProductMapper.toProductFormDTO(
                  {
                    categoryModel: category,
                    category,
                  } as ProductModel,
                  {editMode: true, disabledCategory: true},
                )}
                onCancel={daleyFocus}>
                {t('Create material')}
              </ProductCreateButton>
            </Empty>
          )
        }
        headerExtra={
          <ListActions
            inputRef={inputRef}
            searchText={keywords}
            withoutPicker
            loading={loading}
            handleSearch={(keywords) =>
              handleSearchProducts({keywords, store_uuid: storeUuid!})
            }
            inputTooltip={t('Search')}
            inputLabel={t('Search')}
            withSearchContent={
              <StyledProductCreateButton
                type="primary"
                disabled={!manage}
                loading={loading}
                onSuccess={handleCreateProduct}
                title={t('Create')}
                icon={<PlusOutlined />}
                product={ProductMapper.toProductFormDTO(
                  {
                    categoryModel: category,
                    category,
                  } as ProductModel,
                  {editMode: true, disabledCategory: true},
                )}
                onCancel={daleyFocus}
              />
            }
            withSearchEndContent={
              <HeaderSearchContainer>
                <ProductStoreSearchView
                  loading={loading}
                  onStoreChange={onStoreChange}
                  storeUuid={storeUuid || ''}
                  storeList={storeList}
                  storeListLoading={storeListLoading}
                  handleSearchStores={handleSearchStores}
                />
              </HeaderSearchContainer>
            }
          />
        }
        loading={loading && !productList?.size}>
        <Table<ProductModel>
          rowSelection={productRowSelection}
          total={total}
          pageSize={pageSize}
          dataSource={productList}
          onChange={handleRefreshProducts}
          columns={productColumns}
          loading={loading || storeLoading}
          page={page}
          onRow={(record: ProductModel) => ({
            className:
              record?.product_status !== ProductStatus.ENABLE
                ? 'product-list--disabled-in-list'
                : '',
          })}
        />
      </ListLayout>
      <Suspense fallback={<SuspenseEmpty />}>
        <ProductSideWindowLazy
          // disabledCategory
          loading={loading as boolean}
          onSuccess={handleCreateProduct}
          onCancel={productHandleCancel}
          visible={productVisible}
          product={ProductMapper.toProductFormDTO(
            {
              categoryModel: category,
              category,
            } as ProductModel,
            {editMode: true, disabledCategory: true, addAnotherProduct: true},
          )}
        />
      </Suspense>

      <Suspense fallback={<SuspenseEmpty />}>
        <PriceTagPrintModalLazy
          print={PriceTagMapper.toPriceTagPrintFormDTO({
            price_margin_uuid: defaultPriceMargin?.uuid || '',
            tag_size: '60x40',
          })}
          onSuccess={priceTagHandleSuccess}
          visible={priceTagVisible}
          onCancel={priceTagHandleCancel}
        />
      </Suspense>
    </>
  );
}
