import * as React from 'react';
import {Dispatch, SetStateAction} from 'react';

export interface IUseStateEntityReturnedType<T> {
  entity: T | null;
  handleUpdate: (item: T) => void;
  setEntity: Dispatch<SetStateAction<T | null>>;
  loading: boolean;
}

export default function useStateEntity<T>(
  data: T | null,
): IUseStateEntityReturnedType<T> {
  const [entity, setEntity] = React.useState<T | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    let didCancel = false;
    if (data && !didCancel) {
      setEntity(data);
      setLoading(false);
    }

    return () => {
      didCancel = true;
    };
  }, [data]);

  const handleUpdate = React.useCallback((item: T, isModels = true) => {
    if (isModels) {
      return setEntity(item);
    }
    setEntity((prevState) => ({
      ...prevState,
      ...item,
    }));
  }, []);

  return {
    entity,
    setEntity,
    handleUpdate,
    loading,
  };
}
