import React, {Suspense, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Dropdown, MenuProps, Popconfirm} from 'antd';
import {useModal} from '../../../../components/lib/libV2/hooks';
import {StoreDocumentFormDTO} from '../../../../struture';
import {
  Button,
  IEditButtonProps,
  SuspenseEmpty,
} from '../../../../components/lib/DataDisplay';
import {StyledTitle} from '../../../../components/lib/Styled';
import {DeleteOutlined, LoadingOutlined} from '@ant-design/icons';
import styled, {css, useTheme} from 'styled-components';
import {IoMenu} from 'react-icons/io5';
import {IUseStateStoredDocumentReturnType} from '../../../../hooks';
import {MdModeEditOutline} from 'react-icons/md';

const StoreRealizationSideWindowLazy = React.lazy(
  () => import('../Show/StoreRealizationSideWindow'),
);

export interface IStoreRealizationMenuButtonProps
  extends IEditButtonProps,
    Pick<IUseStateStoredDocumentReturnType, 'handlePaymentStoreDocument'> {
  children?: React.ReactNode;
  onSuccess: IUseStateStoredDocumentReturnType['handleUpdateStoreDocument'];
  onCancel?: () => void;
  document: StoreDocumentFormDTO;
  fromList: boolean;
  handleDeleteStoreDocuments: () => Promise<void>;
}

const StyledDropdown = styled(Dropdown)<{
  disabled?: boolean;
}>`
  cursor: pointer;

  margin-right: 15px;
  .ant-dropdown-menu-item {
    padding: 0 !important;
  }

  ${({disabled}) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  color: ${({theme}) => theme.colors.error};
`;

const StyledDeleteOutlined = styled(DeleteOutlined)`
  color: ${({theme}) => theme.colors.error};
`;

const StyledPopconfirmButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;

  padding: 0;
  margin: 0;

  &&&:hover,
  &&&:active,
  &&&:focus {
    background-color: transparent;
  }
`;

export default function StoreRealizationMenuButton({
  children,
  onSuccess,
  onCancel,
  loading,
  disabled,
  document,
  fromList,
  handlePaymentStoreDocument,
  handleDeleteStoreDocuments: onDeleteStoreDocuments,
  ...rest
}: IStoreRealizationMenuButtonProps) {
  const {t} = useTranslation();
  const theme: any = useTheme();

  const [deletedLoading, setDeletedLoading] = useState(false);

  const handleDeleteStoreDocuments = useCallback(async () => {
    setDeletedLoading(true);
    await onDeleteStoreDocuments();
    setDeletedLoading(false);
  }, [onDeleteStoreDocuments]);

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess: onSuccess,
  });

  const items: MenuProps['items'] = [
    {
      key: 'product',
      label: <StyledTitle>{t('Edit invoice')}</StyledTitle>,
      icon: <MdModeEditOutline size={15} />,
      onClick: handleOnInit,
    },
    {
      key: 'delete-product',
      label: (
        <Popconfirm
          title={t('Are you sure you want to delete the document?')}
          okText={t('Yes')}
          cancelText={t('No')}
          onCancel={async (e) => {
            e?.preventDefault();
            e?.stopPropagation();
          }}
          onConfirm={async (e) => {
            e?.preventDefault();
            e?.stopPropagation();

            await handleDeleteStoreDocuments();
          }}>
          <StyledPopconfirmButton
            type="text"
            onClick={async (e: any) => {
              if (e) {
                e?.preventDefault();
                e?.stopPropagation();
              }
            }}>
            {deletedLoading ? (
              <StyledLoadingOutlined />
            ) : (
              <StyledDeleteOutlined />
            )}
            <StyledTitle $color={theme.colors.error}>
              {t('Delete draft')}
            </StyledTitle>
          </StyledPopconfirmButton>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <StyledDropdown
        trigger={['hover']}
        disabled={!!loading || disabled}
        menu={{items}}
        placement="bottomLeft">
        {loading || deletedLoading ? (
          <LoadingOutlined color={theme.colors.primary} />
        ) : (
          <IoMenu size={20} color={theme.colors.primary} />
        )}
      </StyledDropdown>

      <Suspense fallback={<SuspenseEmpty />}>
        <StoreRealizationSideWindowLazy
          editMode
          document={document}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
          handlePaymentStoreDocument={handlePaymentStoreDocument}
          fromList={fromList}
        />
      </Suspense>
    </>
  );
}
