import React from 'react';
import {Menu} from 'antd';
import {Link} from 'react-router-dom';
import styled, {css} from 'styled-components';
import {textToUpperCase} from '../../../services/helpers';
import {useAcl} from '../../../contex';
import {AclDTO, AclMapper} from '../../../struture';
import {useLocation} from 'react-router';
import {MenuItem} from '../../../components/lib/Layout';
import {useStoredCompanies} from '../../../hooks';
import {Button} from '../../../components/lib/DataDisplay';
import {SETTINGS_MENU_ITEMS, MENU_KEY_SETTINGS} from '../const';

import './SettingsMenu.less';
import '../../../components/lib/Layout/AppMenu.less';

import {
  StyledSettingOutlined,
  StyledLockOutlined,
  StyledTitle,
} from '../../../components/lib/Styled';

export interface ISettingsMenuProps {
  collapsed: boolean;
  loading?: boolean;
  onClick: (item: MenuItem) => void;
}

export const buildSettingsMenuGroups = (): MenuItem[] => [
  {
    key: MENU_KEY_SETTINGS,
    title: 'Settings',
    icon: <StyledSettingOutlined />,
    children: SETTINGS_MENU_ITEMS,
  },
];

const StyledMenu = styled(Menu)<{$collapse: boolean}>`
  background-color: transparent;
  overflow-y: auto;
  margin-bottom: 10px;

  &&& {
    border-inline-end: none;
  }

  &&& .ant-menu-item:hover {
    transition: none;

    & .ant-menu-title-content {
      transition: none;
    }
  }

  &&& .ant-menu-submenu-selected > .ant-menu-submenu-title {
    width: 100%;
    background: transparent;
    border: 1px solid ${({theme}) => theme.colors.link};
    margin-inline: 0;
    margin-block: 0;
    padding-left: 12px;
    transition: none;

    & > * {
      color: ${({theme}) => theme.colors.link};
    }

    & > .ant-menu-submenu-arrow {
      inset-inline-end: 7px;
    }

    ${({$collapse}) =>
      $collapse &&
      css`
        margin-inline: 4px;
        width: 80%;
      `}
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledButton = styled(Button)<{$withLine: boolean}>`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  text-align: left;

  &&&:hover,
  &&&:active,
  &&&:focus {
    background-color: transparent;
  }

  ${({$withLine}) =>
    $withLine &&
    css`
      background-color: red;
    `}
`;

const StyledLink = styled(Link)<{$withLine: boolean}>`
  position: relative;

  ${({$withLine}) =>
    $withLine &&
    css`
      &:after {
        content: ' ';
        width: 220px;
        height: 1px;
        position: absolute;
        background-color: ${({theme}) => theme.colors.textSecondary};
        top: -16px;
        left: -38px;
      }
    `}
`;

export function SettingsMenu({
  onClick,
  collapsed,
  loading = false,
}: ISettingsMenuProps) {
  const location = useLocation();

  const acl = useAcl((acl) => acl);
  const {defaultCompanyLoading} = useStoredCompanies();

  const getMenuNestedItem = (children: MenuItem[], isChildren = false): any =>
    children.map(
      ({
        key,
        icon,
        className,
        link,
        title,
        openWith,
        children: nestedItem,
        aclItem,
      }) => {
        const {read} = aclItem
          ? acl[aclItem]
          : AclMapper.toAclModel({} as AclDTO);

        const label: any = (
          <StyledTitle
            fontSize={12}
            muted={!read}
            onClick={() => onClick({openWith, key, link, title, icon})}>
            {isChildren ? title : textToUpperCase(title)}
          </StyledTitle>
        );

        return {
          key,
          label,
          popupClassName: 'settings-submenu',
          title: title ? textToUpperCase(title) : null,
          disabled: !read || loading || defaultCompanyLoading,
          icon: icon ? read ? icon : <StyledLockOutlined /> : null,
          // onClick: () =>
          //   read && key !== 'Settings' ? navigate(link || key) : undefined,
          // label : title ? (
          //       nestedItem ? (
          //         <StyledTitle fontSize={12} muted={!read}>
          //           {isChildren ? title : textToUpperCase(title)}
          //         </StyledTitle>
          //       ) : itemSideWindowAccess.includes(key as ItemSideWindow) ? (
          //         <StyledButton
          //           $withLine={!!withLine}
          //           type="text"
          //           onClick={siderActions[key as ItemSideWindow]}>
          //           <StyledTitle fontSize={14} muted={!read}>
          //             {isChildren ? title : textToUpperCase(title)}
          //           </StyledTitle>
          //         </StyledButton>
          //       ) : (
          //         <StyledLink
          //           $withLine={!!withLine}
          //           to={read ? link || key : location.pathname}
          //           className={className}
          //           state={{
          //             aclItem: aclItem ? acl[aclItem] : undefined,
          //             from: location.pathname,
          //           }}>
          //           <StyledTitle muted={!read}>
          //             {isChildren ? title : textToUpperCase(title)}
          //           </StyledTitle>
          //         </StyledLink>
          //       )
          //     ) : null,
          children: nestedItem ? getMenuNestedItem(nestedItem, true) : null,
        };
      },
    );

  return (
    <>
      <StyledMenu
        rootClassName="app-menu--popup"
        $collapse={collapsed}
        mode="vertical"
        defaultOpenKeys={[]}
        defaultSelectedKeys={['Settings']}
        selectedKeys={['Settings']}
        items={getMenuNestedItem(buildSettingsMenuGroups())}
      />
    </>
  );
}
