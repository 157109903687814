import {correctPrice} from '../../helpers';
import {RewardType, ServiceModel, EmployeeModel} from '../internal';

export class ServiceParametersFormDTO {
  public uuid: string;

  public price: string;

  public duration: number;

  public reward_value: number;

  public reward_type: RewardType;

  constructor(props?: EmployeeModel | ServiceModel) {
    this.uuid = props?.uuid || '';
    this.reward_type =
      props?.employee_service?.reward_type || RewardType.Percentage;
    this.price = correctPrice(
      props instanceof EmployeeModel
        ? props?.employee_service?.price
        : props?.employee_service?.price || props?.price,
    );
    this.duration =
      (props instanceof EmployeeModel
        ? props?.employee_service?.duration
        : props?.employee_service?.duration || props?.period_amount) || 0;
    this.reward_value = props?.employee_service?.reward_value || 0;
  }
}
