import React, {forwardRef} from 'react';
import styled, {useTheme} from 'styled-components';
import {StyledTitle} from '../../../components/lib/Styled';
import {useTranslation} from 'react-i18next';
import {
  IUseStateStoredDocumentReturnType,
  PaymentProps,
  useStoredCompanies,
} from '../../../hooks';
import {
  PaymentFormDTO,
  PaymentScheduleFormDTO,
  StoreDocumentStatus,
  StoreDocumentType,
} from '../../../struture';
import {OverviewBottomView} from '../../../components/lib/Layout';
import {correctPrice} from '../../../services/helpers';
import {Routes} from '../../../services/types';
import {PaymentScheduleOperationType} from '../../../services/api/orders';
import {IPaymentStoreDocumentReturnType} from '../../../services/api/storeDocument';
import {CreateStoreDocumentPaymentButton} from '../Buttons';
import {StoreRealizationDocumentBottomPriceView} from '../../Stores';

export interface IStoreDocumentPaymentBottomViewProps
  extends Pick<IUseStateStoredDocumentReturnType, 'document'> {
  payment: (props?: PaymentProps) => PaymentFormDTO | PaymentScheduleFormDTO;
  loading: boolean;
  createScheduleRefunds: (
    value: PaymentScheduleFormDTO,
  ) => Promise<void | IPaymentStoreDocumentReturnType>;
}

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  gap: 2px;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 2px;
`;

const StyledPrice = styled(StyledTitle)`
  font-size: 30px;
`;

const StyledPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 5px;
`;

const Title = styled(StyledTitle)`
  margin-bottom: 5px;
`;

export const StoreDocumentPaymentBottomView = forwardRef(
  function StoreDocumentPaymentBottomView(
    {
      document,
      payment,
      loading,
      createScheduleRefunds,
    }: IStoreDocumentPaymentBottomViewProps,
    ref: any,
  ): JSX.Element {
    const {t} = useTranslation();
    const {defaultCompany, defaultCompanyUuid} = useStoredCompanies();
    const theme: any = useTheme();

    const sumPaid = Number(document?.doc_sum_paid || 0);
    const sumTotal = Number(document?.doc_sum_total || 0);

    return (
      <>
        <div ref={ref}>
          <OverviewBottomView>
            {sumPaid < sumTotal &&
            document?.doc_status_text === StoreDocumentStatus.CLOSED ? (
              <CreateStoreDocumentPaymentButton
                from={Routes.stores}
                type="primary"
                payment={payment}
                companyUuid={defaultCompanyUuid}
                loading={loading}
                onSuccess={async () => {}}
                onSuccessRefund={createScheduleRefunds}
                title={t('Return funds')}
                scheduleOperationType={PaymentScheduleOperationType.Out}
                paymentPrice={correctPrice(sumTotal - sumPaid || 0)}
                document={document}
              />
            ) : null}

            <StyledWrapper>
              {document?.doc_type === StoreDocumentType.OUT ? (
                <StoreRealizationDocumentBottomPriceView document={document} />
              ) : (
                <StyledContainer>
                  <StyledTitle fontSize={14}>{`${t('Paid')}: `}</StyledTitle>
                  <StyledPriceContainer>
                    <StyledPrice $color={theme.colors.success}>
                      {`${defaultCompany?.currency_symbol || ''} ${correctPrice(
                        sumPaid || 0,
                      )}`}
                    </StyledPrice>
                    <Title>{`${t('with')} ${correctPrice(
                      sumTotal || 0,
                    )}`}</Title>
                  </StyledPriceContainer>
                  {sumPaid === 0 ? null : sumPaid < sumTotal ? (
                    <StyledTitle $color={theme.colors.success}>
                      {`${t('Balance due').toLowerCase()} ${
                        defaultCompany?.currency_symbol || ''
                      }, ${correctPrice(sumTotal - sumPaid)}`}
                    </StyledTitle>
                  ) : sumPaid > sumTotal ? (
                    <StyledTitle $color={theme.colors.error}>
                      {`${t('amount to be refunded')} ${
                        defaultCompany?.currency_symbol || ''
                      }, ${correctPrice(sumTotal - sumPaid)}`}
                    </StyledTitle>
                  ) : null}
                </StyledContainer>
              )}
            </StyledWrapper>
          </OverviewBottomView>
        </div>
      </>
    );
  },
);
