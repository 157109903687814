import React from 'react';
import {useTranslation} from 'react-i18next';
import {DeleteOutlined} from '@ant-design/icons';
import {
  Table,
  ITableProps,
  TableActionCell,
} from '../../../components/lib/libV2/DataDisplay';
import {ColorView, DetailsItemView} from '../../../components/lib/DataDisplay';
import {StyledDescription} from '../../../components/lib/Styled';
import {StyledEditOutlined} from '../../../components/lib/Styled';
import {IUseCompanyScheduleListProps, useStoredCompanies} from '../../../hooks';
import {
  toUtcDateInFormat,
  isRecordToObject,
  toDateByFormat,
} from '../../../services/helpers';
import {RowSelection, Routes} from '../../../services/types';
import {UpdateOrderButton, DeleteOrderButton} from '../Buttons';
import {useAcl} from '../../../contex';
import {
  ScheduleCalendarModel,
  ScheduleCalendarFormDTO,
  ClientModel,
  EmployeeModel,
  ProfileModel,
  StatusCategoryModel,
  StatusModel,
} from '../../../struture';
import {List} from 'immutable';
import {OrderDetailsView, OrderPriceView, OrderStatusesProgress} from '../Show';

import './OrderList.less';

export interface IScheduleListWithOutActionsProps<T>
  extends Omit<ITableProps<any>, 'columns' | 'pageSize' | 'dataSource'> {
  schedules: List<ScheduleCalendarModel> | null;
  loading: boolean;
  limit?: number;
  onRefresh: (
    value: Partial<IUseCompanyScheduleListProps>,
  ) => Promise<List<ScheduleCalendarModel> | void>;
  rowSelection: RowSelection<ScheduleCalendarModel>;
  total: number;
  withoutActions?: T;
  withoutClientField?: boolean;
  pathname?: string;
  state?: any;
  daleyFocus?: (ms?: number) => void;
  categoryList: List<StatusCategoryModel> | null;
  statusList: List<StatusModel> | null;
  handleUpdateScheduleState: (schedule: ScheduleCalendarModel) => void;
}

export interface IScheduleListWithActionsProps<T>
  extends IScheduleListWithOutActionsProps<T> {
  companyUuid: string;
  handleUpdate: (value: ScheduleCalendarFormDTO) => Promise<void>;
  handleDelete: (ids: string[]) => Promise<void>;
  state?: any;
  categoryList: List<StatusCategoryModel> | null;
  statusList: List<StatusModel> | null;
  handleUpdateScheduleState: (schedule: ScheduleCalendarModel) => void;
}

export type ScheduleProps<WithoutActions> = WithoutActions extends boolean
  ? IScheduleListWithOutActionsProps<WithoutActions>
  : IScheduleListWithActionsProps<WithoutActions>;

export default function ScheduleList<
  WithoutActions extends boolean | undefined,
>({
  schedules,
  limit = 10,
  loading,
  onRefresh = async () => {},
  total,
  withoutActions,
  withoutClientField,
  pathname,
  state = {},
  daleyFocus,
  categoryList,
  statusList,
  handleUpdateScheduleState,
  ...rest
}: ScheduleProps<WithoutActions>): JSX.Element {
  const {t} = useTranslation();
  const {manage} = useAcl(({order}) => order);
  const {defaultCompany} = useStoredCompanies();

  const {companyUuid, handleUpdate, handleDelete, ...restProps} =
    rest as unknown as IScheduleListWithActionsProps<WithoutActions>;

  const columns: any = [
    {
      title: t('Phase'),
      key: 'Phase',
      className: 'order-list-indent',
      width: 180,
      render: (schedule: ScheduleCalendarModel) => (
        <>
          {List.isList(categoryList) ? (
            <OrderStatusesProgress
              schedule={schedule}
              categoryList={categoryList}
              statusList={statusList}
              activeStatus={schedule?.latest_status as any}
              handleUpdateScheduleState={handleUpdateScheduleState}
            />
          ) : null}
        </>
      ),
    },
    {
      title: t('Order'),
      key: 'order',
      className: 'order-list-indent',
      render: (c: ScheduleCalendarModel) => (
        <OrderDetailsView
          withCategoryLabel
          pathname={pathname}
          state={state}
          schedule={c}
        />
      ),
    },
    withoutClientField
      ? undefined
      : {
          title: t('Client'),
          key: 'client',
          className: 'order-list-indent',
          render: (c: ScheduleCalendarModel) => (
            <DetailsItemView<ClientModel>
              item={c?.clientModel}
              fields={{
                fullNameClient: {
                  link: 'Go to client details page',
                  path: `/${Routes.app}/${Routes.clients}/${c?.clientModel?.uuid}`,
                },
                phone: {description: ''},
              }}
            />
          ),
        },
    {
      title: t('Manager'),
      key: 'manager',
      className: 'order-list-indent',
      render: (c: ScheduleCalendarModel) => (
        <DetailsItemView<EmployeeModel>
          item={c?.employeeModel}
          fields={{
            fullName: {
              link: 'Go to manager details page',
              path: `/${Routes.app}/${Routes.employees}/${c?.employeeModel?.uuid}`,
            },
            phone: {description: ''},
          }}
        />
      ),
    },
    {
      title: t('Created'),
      key: 'created',
      className: 'order-list-indent',
      render: (c: ScheduleCalendarModel) => {
        return (
          <DetailsItemView<ProfileModel>
            item={c?.createdByModel}
            fields={{
              fullName: {
                title: '',
              },
              uuid: {
                description: (
                  <StyledDescription>
                    {toDateByFormat(c?.created_at, 'DD.MM.YYYY HH:mm')}
                  </StyledDescription>
                ),
              },
            }}
          />
        );
      },
    },
    {
      title: t('Assigned to'),
      key: 'date',
      className: 'order-list-indent',
      render: (c: ScheduleCalendarModel) => (
        <ColorView color={c?.status_color}>
          {toUtcDateInFormat(c?.scheduled_date, 'DD.MM.YYYY HH:mm')}
        </ColorView>
      ),
    },
    {
      title: `${t('Sum')}, ${defaultCompany?.currency_symbol || ''}`,
      key: 'sum',
      fixed: withoutActions ? 'right' : null,
      align: 'right' as any,
      className: 'order-list-td order-list-indent',
      render: (schedule: ScheduleCalendarModel) => (
        <OrderPriceView schedule={schedule} />
      ),
    },
  ].filter(Boolean);

  const isColumnWithActions = withoutActions
    ? columns
    : [
        ...columns,
        {
          title: '',
          key: 'schedule_actions',
          align: 'center',
          fixed: 'right',
          className: 'order-list-td order-list-indent order-list',
          render: (record: ScheduleCalendarModel) => (
            <TableActionCell className="order-list--hidden">
              <UpdateOrderButton
                size="small"
                isHasStrictMode={record?.schedule_has_strict_mode}
                disabled={!manage}
                companyUuid={companyUuid}
                type="text"
                schedule={record}
                data-testid="payment-edit-button"
                onSuccess={handleUpdate}
                onCancel={daleyFocus}>
                <StyledEditOutlined />
              </UpdateOrderButton>
              <DeleteOrderButton
                size="small"
                className="order-list--delete"
                disabled={!manage}
                data-testid="payment-delete-button"
                schedules={[isRecordToObject(record)]}
                onSuccess={handleDelete}>
                <DeleteOutlined />
              </DeleteOrderButton>
            </TableActionCell>
          ),
        },
      ];

  return (
    <Table
      {...restProps}
      tableLayout="fixed"
      loading={loading}
      rowKey="uuid"
      dataSource={schedules}
      columns={isColumnWithActions}
      pageSize={limit}
      total={total}
      onRow={(record: ScheduleCalendarModel, index: number | undefined) => ({
        className: 'order-list--row',
      })}
      onChange={onRefresh}
    />
  );
}
