import React from 'react';
import {IConfirmActionButtonProps} from '../../../../components/lib/General';
import {StoreDocumentModel, StoreDocumentType} from '../../../../struture';
import {StorePostingDeleteButton} from '../../Posting';
import {StoreRealizationDeleteButton} from '../../Realization';
import {StoreWriteOffDeleteButton} from '../../WriteOff';
import {StoreReturnDeleteButton} from '../../Return';
import {StoreReturnClientDeleteButton} from '../../ReturnClients';
import {StoreMovingDeleteButton} from '../../Moving';

export interface IStoreRelatedDeleteButtonProps
  extends Omit<IConfirmActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: string[]) => Promise<void>;
  onError?: () => void;
  documents: StoreDocumentModel[];
  title?: string;
  documentType: StoreDocumentType;
}

export function StoreRelatedDeleteButton({
  documentType,
  ...rest
}: IStoreRelatedDeleteButtonProps) {
  const crateDocumentButtons = {
    [StoreDocumentType.IN]: <StorePostingDeleteButton {...rest} />,
    [StoreDocumentType.OUT]: <StoreRealizationDeleteButton {...rest} />,
    [StoreDocumentType.ERASE]: <StoreWriteOffDeleteButton {...(rest as any)} />,
    [StoreDocumentType.RETURN]: <StoreReturnDeleteButton {...rest} />,
    [StoreDocumentType.CRETURN]: <StoreReturnClientDeleteButton {...rest} />,
    [StoreDocumentType.MOVE]: <StoreMovingDeleteButton {...(rest as any)} />,
    [StoreDocumentType.SALE]: <></>,
    [StoreDocumentType.PREORDER]: <></>,
  };

  return <>{crateDocumentButtons[documentType]}</>;
}
