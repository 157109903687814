import React, {useMemo, useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {PRICE_MARGIN_INITIAL_PARAM} from '../../../services/api/priceMargin';
import {DefaultForm, IDefaultFormProps} from '../../../components/lib/General';
import {PriceMarginFormDTO} from '../../../struture';
import {useDropdownAlert} from '../../../contex';
import {Col, Form, Input, InputNumber, Row} from 'antd';
import styled from 'styled-components';
import {FormSwitch} from '../../../components/lib/DataDisplay';

export interface IPriceMarginFormProps
  extends Omit<
    IDefaultFormProps<PriceMarginFormDTO, PriceMarginFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  priceMargin?: PriceMarginFormDTO;
  isActiveByDefault: boolean;
}

const FullWidthInputNumber = styled(InputNumber)`
  width: 100%;
`;

export function PriceMarginForm({
  loading,
  priceMargin = PRICE_MARGIN_INITIAL_PARAM,
  editMode,
  isActiveByDefault,
  ...rest
}: IPriceMarginFormProps): React.JSX.Element {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const [instance] = Form.useForm();

  const additionalValues = useMemo(
    () => ({
      uuid: priceMargin?.uuid,
    }),
    [priceMargin?.uuid],
  );

  const notifyError = useCallback(
    (ApiError: any) => {
      alert(
        'error',
        t('Price margin'),
        `${
          editMode
            ? t('An error occurred during edit price margin')
            : t('An error occurred during create price margin')
        } : ${ApiError?.message}`,
      );
    },
    [alert, editMode, t],
  );

  useEffect(() => {
    if (isActiveByDefault) {
      instance.setFieldsValue({price_default: true});
    }
  }, [instance, isActiveByDefault]);

  return (
    <DefaultForm<PriceMarginFormDTO, PriceMarginFormDTO>
      instance={instance}
      initialValues={{
        ...priceMargin,
      }}
      additionalValuesRequest={additionalValues}
      editMode={editMode}
      notifyError={notifyError}
      showNotify={false}
      {...rest}>
      {({loadingSubmit, getFieldValue, setFieldsValue}) => (
        <Row gutter={20}>
          <Col span={24}>
            <Form.Item
              label={t('Title')}
              name="price_name"
              rules={[
                {
                  required: true,
                  message: t('Title must be specified.'),
                },
              ]}>
              <Input
                disabled={loading || loadingSubmit}
                placeholder={t('Enter the title')}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              tooltip={t('Percentage markup')}
              label={t('Percentage markup')}
              name="price_margin"
              rules={[
                {
                  required: true,
                  message: t('Percentage markup must be specified.'),
                },
                {
                  transform: (value) => Number(value),
                  min: 0,
                  type: 'number',
                  message: t(
                    'Percentage markup must be bigger or equal than 0',
                  ),
                },
                {
                  transform: (value) => Number(value),
                  max: 100,
                  type: 'number',
                  message: t('Percentage markup must be less or equal 100'),
                },
              ]}>
              <FullWidthInputNumber
                addonBefore="%"
                type="number"
                disabled={loading || loadingSubmit}
                placeholder={t('Enter the percentage markup')}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label={t('Note')} name="price_description">
              <Input.TextArea
                allowClear
                autoSize={{minRows: 2, maxRows: 5}}
                disabled={loading || loadingSubmit}
                placeholder={t('Enter your note')}
              />
            </Form.Item>
          </Col>

          <FormSwitch
            span={24}
            loading={loadingSubmit || loading}
            disabled={loadingSubmit || isActiveByDefault}
            name="price_default"
            getFieldValue={getFieldValue}
            setFieldsValue={setFieldsValue}
            label={null}
            title={t('Set by default')}
            value={editMode ? undefined : isActiveByDefault}
          />
        </Row>
      )}
    </DefaultForm>
  );
}
