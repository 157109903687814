import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {
  ISpecification,
  NotWorkingHours,
  PastDates,
} from '../components/Calendar';

export interface IUseCalendarSpecificationsReturnProps {
  notWorkingHours: ISpecification;
  pastDates: ISpecification;
  allSpecification: ISpecification[];
}

export default function useCalendarSpecifications(): IUseCalendarSpecificationsReturnProps {
  const {t} = useTranslation();

  const notWorkingHours: ISpecification = {
    title: t('Not working hours'),
    blocks: [<NotWorkingHours />, <NotWorkingHours withPastDate />],
  };

  const pastDates: ISpecification = {
    title: t('Past dates'),
    blocks: [<PastDates />],
  };

  return {
    notWorkingHours,
    pastDates,
    allSpecification: [notWorkingHours, pastDates],
  };
}
