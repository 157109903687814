import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {REPORT_INITIAL_PARAM} from '../../../services/api/report';
import {DefaultForm, IDefaultFormProps} from '../../../components/lib/General';
import {
  useInterval,
  useStateCompanyClientList,
  useStateEmployeeList,
  useStateServiceList,
  useStoredAbonementList,
  useStoredCompanies,
  useStoredEmployeeAttachedServiceList,
  useStoredReport,
} from '../../../hooks';
import {ReportFormDTO, ReportStatuses, ReportTypes} from '../../../struture';
import {isFunction} from '../../../services/helpers';
import {StatusError} from '../../../components/lib/Errors';
import {useDropdownAlert} from '../../../contex';
import {ReportingFields} from './ReportingFields';
import {useNavigate} from 'react-router';
import {ReportingAbonementFields} from './ReportingAbonementFields';

export interface IReportingFormProps
  extends Omit<
    IDefaultFormProps<ReportFormDTO, ReportFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest' | 'onSuccess'
  > {
  loading?: boolean;
  report?: ReportFormDTO;
  type: ReportTypes;
  timeList?: string[];
  setLoading: React.Dispatch<boolean>;
}

const REPORT_INTERVAL = 10000;
const REPORT_CLEAR_INTERVAL = 60001;

const reportType = [ReportTypes.Payments, ReportTypes.Orders];

export function ReportingForm({
  report = REPORT_INITIAL_PARAM,
  onCancel,
  type,
  loading,
  setLoading,
  ...rest
}: IReportingFormProps): JSX.Element {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const {defaultCompanyUuid} = useStoredCompanies();
  const navigate = useNavigate();

  const [employeeUuid, setEmployeeUuid] = useState('');

  const {
    employees,
    loading: employeesLoading,
    handleSearchEmployees,
  } = useStateEmployeeList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const {
    clients,
    loading: clientsLoading,
    handleSearchClients,
  } = useStateCompanyClientList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
    loadOnInit: reportType.includes(type),
  });

  const {
    services,
    loading: servicesLoading,
    handleSearchServices,
  } = useStateServiceList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
    loadOnInit: reportType.includes(type),
  });

  const {
    employeeAttachedServiceList,
    loading: employeeAttachedServiceListLoading,
    handleSearchEmployeeAttachedServices,
    handleRefreshStoreEmployeeAttachedServices,
  } = useStoredEmployeeAttachedServiceList({
    isOrderScreen: true,
    employeeUuid,
    loadOnInit: false,
    limit: 100,
  });

  const {
    abonementList,
    loading: abonementsLoading,
    handleSearchAbonements,
  } = useStoredAbonementList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
    loadOnInit: type === ReportTypes.Abonements,
  });

  const {handleCreateReport, refresh} = useStoredReport({
    companyUuid: defaultCompanyUuid,
    reportUuid: '',
    loadOnInit: false,
  });

  const {intervalCallback, handleClearInterval} = useInterval(
    async (reportUuid: string) => {
      const report = await refresh({reportUuid});
      if (report && report.report_status === ReportStatuses.Completed) {
        if (isFunction(onCancel)) {
          onCancel();
        }

        window.open(report?.report_path_url, '_blank');

        setTimeout(handleClearInterval, 0);
      }
    },
    REPORT_INTERVAL,
    REPORT_CLEAR_INTERVAL,
    () => {
      setLoading(false);
      alert(
        'error',
        t('Report'),
        t('The report was not generated for unknown reasons'),
      );
    },
  );

  const handleSuccess = useCallback(
    async (value: ReportFormDTO) => {
      try {
        setLoading(true);
        const report = await handleCreateReport(value, type);

        if (report && report?.report_status === ReportStatuses.Error) {
          alert(
            'error',
            t('Report'),
            t('An error occurred during create report'),
          );

          if (isFunction(onCancel)) {
            onCancel();
          }
          return;
        }

        if (report) {
          intervalCallback(report?.uuid);
        }
      } catch (error: any) {
        setLoading(false);
        throw new StatusError(error?.message, error?.status);
      }
    },
    [
      setLoading,
      handleCreateReport,
      type,
      alert,
      t,
      onCancel,
      intervalCallback,
    ],
  );

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Report'),
        `${t('An error occurred during create report')} : ${apiError?.message}`,
      );
    },
    [t, alert],
  );

  return (
    <DefaultForm<ReportFormDTO, ReportFormDTO>
      initialValues={{
        ...report,
      }}
      submitButtonText={t('Form the')}
      onCancel={onCancel}
      notifyError={notifyError}
      showNotify={false}
      onSuccess={handleSuccess}
      {...rest}>
      {() =>
        type === ReportTypes.Abonements ? (
          <ReportingAbonementFields
            loading={!!loading}
            employees={employees}
            employeesLoading={employeesLoading}
            handleSearchEmployees={handleSearchEmployees}
            abonementList={abonementList}
            abonementsLoading={abonementsLoading}
            handleSearchAbonements={handleSearchAbonements}
          />
        ) : (
          <ReportingFields
            loading={!!loading}
            setEmployeeUuid={setEmployeeUuid}
            employees={employees}
            employeesLoading={employeesLoading}
            handleSearchEmployees={handleSearchEmployees}
            clients={clients}
            clientsLoading={clientsLoading}
            handleSearchClients={handleSearchClients}
            services={services}
            servicesLoading={servicesLoading}
            handleSearchServices={handleSearchServices}
            employeeAttachedServiceList={employeeAttachedServiceList}
            employeeAttachedServiceListLoading={
              employeeAttachedServiceListLoading
            }
            handleSearchEmployeeAttachedServices={
              handleSearchEmployeeAttachedServices
            }
            handleRefreshStoreEmployeeAttachedServices={
              handleRefreshStoreEmployeeAttachedServices
            }
          />
        )
      }
    </DefaultForm>
  );
}
