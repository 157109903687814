import styled from 'styled-components';
import {Col, Row, Input, Form} from 'antd';
import {useTranslation} from 'react-i18next';
import {CiDeliveryTruck} from 'react-icons/ci';
import {FaHandshakeSlash} from 'react-icons/fa';
import {useDropdownAlert} from '../../../contex';
import {useStoredCompanies} from '../../../hooks';
import {GENDER_SEGMENTED} from '../../../services/const';
import {StyledTitle} from '../../../components/lib/Styled';
import {ClientDiscountType, ClientFormDTO} from '../../../struture';
import React, {useEffect, useMemo, useRef, useState, useCallback} from 'react';

import {
  IDefaultFormProps,
  DefaultForm,
  PhoneInputComponent as PhoneInput,
  DatePicker,
} from '../../../components/lib/General';

import {
  CLIENT_INITIAL_PARAM,
  CLIENT_DISCOUNT_TYPE,
} from '../../../services/api/client';

import {
  FormSwitch,
  SearchSelect,
  Collapse,
  Segmented,
} from '../../../components/lib/DataDisplay';

import {
  CLIENT_ORG_TYPE,
  StyledFieldsContainer,
  StyledIconContainer,
  Title,
} from './ClientLegalForm';

export interface IClientFormProps
  extends Omit<
    IDefaultFormProps<ClientFormDTO, ClientFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  client?: ClientFormDTO;
  isFullForm?: boolean;
  autoFocus?: boolean;
  provider?: boolean;
}

const StyledSearchSelect = styled(SearchSelect)`
  width: 60px;
`;

export default function ClientForm({
  loading,
  editMode,
  client = CLIENT_INITIAL_PARAM,
  isFullForm = true,
  autoFocus,
  onCancel,
  instance,
  provider,
  ...rest
}: IClientFormProps) {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const inputRef = useRef<any>(null);
  const {defaultCompany} = useStoredCompanies();

  const [mount, setMount] = useState(false);

  const contentRef = useRef(null);
  // const {width: contentWidth} = useSize(contentRef);

  const discountType = Form.useWatch('client_discount_type', instance);
  const discountGoodsType = Form.useWatch(
    'client_discount_goods_type',
    instance,
  );

  const client_is_provisioner = Form.useWatch<boolean>(
    'client_is_provisioner',
    instance,
  );

  const client_is_conflict = Form.useWatch<boolean>(
    'client_is_conflict',
    instance,
  );

  const additionalValues = useMemo(
    () => ({
      uuid: client?.uuid,
    }),
    [client?.uuid],
  );

  const clientDiscountTypes = useMemo(
    () => CLIENT_DISCOUNT_TYPE(defaultCompany?.currency_symbol!),
    [defaultCompany?.currency_symbol],
  );

  useEffect(() => {
    if (autoFocus) {
      setMount(true);
    }
  }, [autoFocus]);

  useEffect(() => {
    if (autoFocus && mount && inputRef.current) {
      inputRef.current.focus({
        cursor: 'start',
      });
    }
  }, [autoFocus, mount]);

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Client'),
        `${
          editMode
            ? t('An error occurred during edit client')
            : t('An error occurred during create client')
        } : ${apiError?.message}`,
      );
    },
    [alert, t, editMode],
  );

  useEffect(() => {
    if (provider) {
      instance.setFieldValue('client_is_provisioner', true);
    }
  }, [instance, provider]);

  useEffect(() => {
    if (instance?.client_is_conflict) {
      instance.setFieldValue('client_is_conflict', true);
    }
  }, [instance]);

  return (
    <DefaultForm<any, any>
      instance={instance}
      editMode={editMode}
      initialValues={{
        ...client,
      }}
      additionalValuesRequest={additionalValues}
      showNotify={false}
      onCancel={onCancel}
      notifyError={notifyError}
      {...rest}>
      {({
        loadingSubmit,
        handlerUpdateFormState,
        getFieldValue,
        setFieldsValue,
        formData,
        validateFields,
      }) => (
        <Row gutter={24} ref={contentRef}>
          <Col span={24}>
            <Form.Item
              tooltip={t(
                'The different types for counterparties have different properties and functions.',
              )}
              label={t('Counterparty type')}
              name="client_org_type"
              rules={[
                {
                  required: true,
                  message: t('Counterparty type must be specified.'),
                },
              ]}>
              <Segmented
                options={CLIENT_ORG_TYPE}
                disabled={loading || loadingSubmit || editMode}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="first_name"
              label={t('First Name')}
              rules={[
                {
                  required: true,
                  message: t('First Name must be specified.'),
                },
              ]}>
              <Input
                autoFocus
                size="large"
                ref={inputRef}
                disabled={loading || loadingSubmit}
                data-testid="client-create-input-first-name"
                placeholder={t('Enter the client first name')}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label={t('Last Name')} name="last_name">
              <Input
                size="large"
                data-testid="client-create-input-last-name"
                disabled={loading || loadingSubmit}
                placeholder={t('Enter the client last name')}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('Phone')}
              name="phone"
              rules={[
                {
                  required: true,
                  message: t('Client Phone must be specified.'),
                },
              ]}>
              <PhoneInput
                size="large"
                placeholder={t('Enter the client phone')}
                id="field-phone"
                containerClass="field-phone-container"
                searchClass="field-phone-search"
                inputClass="ant-input field-phone"
                buttonClass="field-phone-flag"
                loading={loading || loadingSubmit}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Collapse
              title={(isExpanded) =>
                isExpanded ? t('Hide') : t('Additional parameters')
              }
              height={850}>
              <Row>
                <Col span={24}>
                  <Form.Item label={t('Middle Name')} name="middle_name">
                    <Input
                      size="large"
                      data-testid="client-create-input-middle-name"
                      disabled={loading || loadingSubmit}
                      placeholder={t('Enter the client middle name')}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label={t('Email')} name="email">
                    <Input
                      size="large"
                      data-testid="client-create-input-email"
                      disabled={loading || loadingSubmit}
                      placeholder={t('Enter the client email')}
                    />
                  </Form.Item>
                </Col>

                <Col span={11}>
                  <Form.Item
                    tooltip={t(
                      'The type of discount that applies to provided services.',
                    )}
                    label={t('Discount on service')}
                    name="client_discount"
                    rules={
                      discountType === ClientDiscountType.Fixed
                        ? [
                            {
                              transform: (value) => Number(value ?? 0),
                              type: 'number',
                              required: false,
                              message: t('Discount must be specified.'),
                            },
                          ]
                        : [
                            {
                              transform: (value) => Number(value ?? 0),
                              type: 'number',
                              min: 0,
                              max: 100,
                              required: false,
                              message: t(
                                'Discount must be a number and bigger than -1 and less 100',
                              ),
                            },
                          ]
                    }>
                    <Input
                      addonAfter={
                        <Form.Item noStyle name="client_discount_type">
                          <StyledSearchSelect
                            popupMatchSelectWidth={false}
                            name="client_discount_type"
                            placeholder={''}
                            getOptionValueTitle="description"
                            disable={loadingSubmit}
                            data={clientDiscountTypes}
                            onChange={async () => {
                              try {
                                await validateFields(['client_discount']);
                              } catch (error) {}
                            }}
                          />
                        </Form.Item>
                      }
                      size="large"
                      type="number"
                      disabled={loadingSubmit}
                      placeholder={'0'}
                    />
                  </Form.Item>
                </Col>

                <Col span={11} offset={2}>
                  <Form.Item
                    tooltip={t(
                      'The types of discount that applies to the goods.',
                    )}
                    label={t('Goods discount')}
                    name="client_discount_goods"
                    rules={
                      discountGoodsType === ClientDiscountType.Fixed
                        ? [
                            {
                              transform: (value) => Number(value ?? 0),
                              type: 'number',
                              required: false,
                              message: t(
                                'Discount on goods must be specified.',
                              ),
                            },
                          ]
                        : [
                            {
                              transform: (value) => Number(value),
                              type: 'number',
                              min: 0,
                              max: 100,
                              required: false,
                              message: t(
                                'Discount must be a number and bigger than -1 and less 100',
                              ),
                            },
                          ]
                    }>
                    <Input
                      addonAfter={
                        <Form.Item noStyle name="client_discount_goods_type">
                          <StyledSearchSelect
                            popupMatchSelectWidth={false}
                            name="client_discount_goods_type"
                            placeholder={''}
                            getOptionValueTitle="description"
                            disable={loadingSubmit}
                            data={clientDiscountTypes}
                            onChange={async () => {
                              try {
                                await validateFields(['client_discount_goods']);
                              } catch (error) {}
                            }}
                          />
                        </Form.Item>
                      }
                      size="large"
                      type="number"
                      disabled={loadingSubmit}
                      placeholder={'0'}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label={t('Gender')} name="gender">
                    <Segmented
                      onChange={(gender) => handlerUpdateFormState({gender})}
                      options={GENDER_SEGMENTED}
                      disabled={loadingSubmit}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label={t('Address')}
                    name="address"
                    rules={[
                      {
                        required: false,
                      },
                      {
                        max: 250,
                        message: t(
                          'Client Address must contain a maximum of 250 characters.',
                        ),
                      },
                    ]}>
                    <Input
                      size="large"
                      data-testid="client-create-input-address"
                      disabled={loading || loadingSubmit}
                      placeholder={t('Enter the client address')}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label={t('Birthday')} name="birthday">
                    <DatePicker
                      size="large"
                      data-testid="client-create-input-birthday"
                      disabled={loading || loadingSubmit}
                      placeholder={t('Birthday')}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label={t('Note')} name="client_comment">
                    <Input.TextArea
                      allowClear
                      size="large"
                      autoSize={{minRows: 2, maxRows: 5}}
                      disabled={loading || loadingSubmit}
                      placeholder={t('Enter your note')}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <FormSwitch
                    loading={loadingSubmit || loading}
                    disabled={loadingSubmit || provider}
                    name="client_is_provisioner"
                    label={
                      <StyledIconContainer>
                        <CiDeliveryTruck size={18} />
                        <StyledTitle>{t('Provider')}</StyledTitle>
                      </StyledIconContainer>
                    }
                    tooltipTitle={t('Provider')}
                    getFieldValue={getFieldValue}
                    setFieldsValue={setFieldsValue}
                    value={provider ? client_is_provisioner : undefined}
                  />
                </Col>

                <Col span={12}>
                  <FormSwitch
                    loading={loadingSubmit || loading}
                    disabled={loadingSubmit}
                    name="client_is_conflict"
                    label={
                      <StyledIconContainer>
                        <FaHandshakeSlash size={18} />
                        <StyledTitle>{t('A conflicted client')}</StyledTitle>
                      </StyledIconContainer>
                    }
                    tooltipTitle={t('A conflicted client')}
                    getFieldValue={getFieldValue}
                    setFieldsValue={setFieldsValue}
                    value={client_is_conflict}
                  />
                </Col>
              </Row>
            </Collapse>
          </Col>
        </Row>
      )}
    </DefaultForm>
  );
}
