/**
 * @desc Сортування по вказаному параметру
 * @param {String} property
 * @return {Function}
 * */

export function sortByName(property) {
  return (a, b) => {
    if (a[property] > b[property]) {
      return 1;
    }
    if (a[property] < b[property]) {
      return -1;
    }

    return 0;
  };
}
