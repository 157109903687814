export interface IPersonalizationFormDTOProps {
  close_by_press: boolean;
  active_list: boolean;
}

export class PersonalizationFormDTO {
  public close_by_press: boolean;

  public active_list: boolean;

  constructor(props?: IPersonalizationFormDTOProps) {
    this.close_by_press = props?.close_by_press || false;
    this.active_list = props?.active_list || true;
  }
}
