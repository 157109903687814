import React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {IDefaultTabPanelProps, TabItem} from '../../../components/lib/General';
import {findIndex, eq, FunctionArgs} from '../../../services/helpers';
import {useNavigate} from 'react-router';
import {Routes, RoutesAcl} from '../../../services/types';
import {useSystemModules} from '../../../hooks';

export enum COMPANY_TABS {
  CALENDAR = 'calendar',
  CLIENTS = 'Counterparties',
  SERVICES = 'Services',
  ABONEMENT = 'Tariff models',
  EMPLOYEES = 'Employees',
  DEPARTMENTS = 'Departments',
  STORES = 'Stores',
}

export default withTranslation()(CompanyTabPanel);

export interface ICompanyTabPanelProps extends WithTranslation {
  children: (
    value: Pick<IDefaultTabPanelProps, 'tabs'> & {calendarIndex: number},
  ) => React.ReactNode;
  timeRange: any;
}

/**
 * @decr Відображення вкладок Клієнта
 * */
function CompanyTabPanel({children, t, timeRange}: ICompanyTabPanelProps) {
  const navigate = useNavigate();
  const {modulesMap} = useSystemModules();

  /**
   * @desc Список вкладок для Клієнта
   * */
  const clientTabs = [
    {
      key: COMPANY_TABS.CALENDAR,
      title: t('Service delivery schedule'),
      disabled: false,
      navigation: () => {
        navigate(Routes.schedules, {
          state: {timeRange, aclItem: RoutesAcl[Routes.companies]},
        });
      },
      renderer: () => <></>,
    },
    {
      key: COMPANY_TABS.DEPARTMENTS,
      title: t(COMPANY_TABS.DEPARTMENTS),
      aclItem: RoutesAcl[Routes.departments],
      disabled: false,
      navigation: () => {
        navigate(`/${Routes.app}/${Routes.departments}`);
      },
      renderer: () => <></>,
    },
    {
      key: COMPANY_TABS.STORES,
      title: t(COMPANY_TABS.STORES),
      aclItem: RoutesAcl[Routes.stores],
      disabled: false,
      navigation: () => {
        navigate(`/${Routes.app}/${Routes.stores}`);
      },
      renderer: () => <></>,
    },
    {
      key: COMPANY_TABS.CLIENTS,
      title: t(COMPANY_TABS.CLIENTS),
      aclItem: RoutesAcl[Routes.clients],
      disabled: false,
      navigation: () => {
        navigate(`/${Routes.app}/${Routes.clients}`);
      },
      renderer: () => <></>,
    },
    {
      key: COMPANY_TABS.SERVICES,
      title: t(COMPANY_TABS.SERVICES),
      aclItem: RoutesAcl[Routes.services],
      disabled: false,
      navigation: () => {
        navigate(`/${Routes.app}/${Routes.services}`);
      },
      renderer: () => <></>,
    },
    ...(modulesMap?.abonement?.enabled
      ? [
          {
            key: COMPANY_TABS.ABONEMENT,
            title: t(COMPANY_TABS.ABONEMENT),
            aclItem: RoutesAcl[Routes.tariffModels],
            disabled: false,
            navigation: () => {
              navigate(`/${Routes.app}/${Routes.tariffModels}`);
            },
            renderer: () => <></>,
          },
        ]
      : []),
    {
      key: COMPANY_TABS.EMPLOYEES,
      title: t(COMPANY_TABS.EMPLOYEES),
      aclItem: RoutesAcl[Routes.employees],
      disabled: false,
      navigation: () => {
        navigate(`/${Routes.app}/${Routes.employees}`);
      },
      renderer: () => <></>,
    },
  ];

  const calendarIndex = findIndex<
    [FunctionArgs<TabItem, boolean>, TabItem[]],
    number
  >(({key}: TabItem) => eq(key, COMPANY_TABS.CALENDAR), clientTabs);

  return <>{children({tabs: clientTabs, calendarIndex})}</>;
}
