import {Record} from 'immutable';

export interface ClientServiceProps {
  uuid: string;
}

/**
 * @class Client
 * @desc Клієнт
 */
export default class ClientService extends Record({
  uuid: '',
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(props: ClientServiceProps = {} as ClientServiceProps) {
    const options = {};

    super({...props, ...options} as any);
  }
}
