'use strict';

export class ConcurrentQueue {
  protected readonly concurrency: number;
  public count: number;
  public waiting: any[];
  public inProcess: boolean;
  public paused: boolean;
  protected onProcess: (task: any) => Promise<void>;
  protected onDone: ((err: Error | null, task: any) => void) | null;
  protected onSuccess: ((task: any) => void) | null;
  protected onFailure: ((err: Error | null, task: any) => void) | null;
  protected onDrain: (() => void) | null;

  constructor(concurrency: number) {
    this.concurrency = concurrency;
    this.count = 0;
    this.waiting = [];
    this.inProcess = false;
    this.paused = false;
    this.onProcess = async () => {};
    this.onDone = null;
    this.onSuccess = null;
    this.onFailure = null;
    this.onDrain = null;
  }

  static channels(concurrency: number): ConcurrentQueue {
    return new ConcurrentQueue(concurrency);
  }

  add(task: any): void {
    this.waiting.push(task);
    const hasChannel = this.count < this.concurrency;
    if (hasChannel) this.next();
  }

  next(): void {
    const emptyChannels = this.concurrency - this.count;
    let launchCount = Math.min(emptyChannels, this.waiting.length);

    while (launchCount-- > 0) {
      this.count++;
      this.inProcess = true;
      const task = this.waiting.shift();
      this.onProcess(task)
        .then(
          (res) => this.finish(null, res),
          (err) => this.finish(err),
        )
        .finally(() => {
          this.inProcess = false;
          if (!this.paused && this.waiting.length > 0) this.next();
        });
    }
  }

  finish(err: Error | null, res?: any) {
    this.count--;

    const {onFailure, onSuccess, onDone, onDrain} = this;
    if (err && onFailure) onFailure(err, res);
    else if (onSuccess) onSuccess(res);
    if (onDone) onDone(err, res);
    if (this.count === 0 && this.waiting.length === 0 && onDrain) onDrain();
  }

  process(listener: any) {
    this.onProcess = listener;
    return this;
  }

  done(listener: (err: Error | null, task: any) => void) {
    this.onDone = listener;
    return this;
  }

  success(listener: (task: any) => void) {
    this.onSuccess = listener;
    return this;
  }

  failure(listener: (err: Error | null, task: any) => void) {
    this.onFailure = listener;
    return this;
  }

  drain(listener: () => void) {
    this.onDrain = listener;
    return this;
  }

  pause() {
    this.paused = true;
    return this;
  }

  clear() {
    this.pause();

    this.waiting = [];
    this.count = 0;

    setTimeout(() => {
      this.paused = false;
    }, 0);
  }
}
