import React from 'react';
import {StyledTitle} from '../../Styled/Text';
import styled, {css} from 'styled-components';

import {
  StatusProgressItemProps,
  StatusProgressItem,
} from './StatusProgressItem';
export interface StatusProgressProps {
  statuses: StatusProgressItemProps[] | null;
  title: string;
  icon: React.ReactNode;
  color: string;
  activeIndex: number;
  onClick?: () => void;
  disabled: boolean;
}

const StyledStatusContainer = styled.div<{disabled: boolean}>`
  padding: 0;
  list-style-type: none;
  font-size: 12px;
  clear: both;
  line-height: 1em;
  margin: 0 -1px;
  text-align: center;
  overflow: hidden;

  ${({disabled}) =>
    !disabled &&
    css`
      cursor: pointer;

      &:hover {
        opacity: 0.9;
      }
    `}
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100px;
`;

const StyledTitleContainer = styled.div<{$color: string}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  & * {
    color: ${({$color}) => $color};
  }
`;

export function StatusProgress({
  statuses,
  title,
  icon,
  color,
  activeIndex,
  onClick,
  disabled,
}: StatusProgressProps): React.JSX.Element {
  return (
    <>
      {statuses?.length ? (
        <StyledContainer>
          <StyledTitleContainer $color={color}>
            {icon || null}
            <StyledTitle fontWeight={400}>{title}</StyledTitle>
          </StyledTitleContainer>
          <StyledStatusContainer onClick={onClick} disabled={disabled}>
            {statuses.map(({color}, index) => (
              <StatusProgressItem
                key={`${color}${index}`}
                color={color}
                active={activeIndex >= index}
              />
            ))}
          </StyledStatusContainer>
        </StyledContainer>
      ) : null}
    </>
  );
}
