import React, {forwardRef, memo, useCallback, useState} from 'react';
import {Col, ColProps} from 'antd';
import styled, {css} from 'styled-components';
import {
  calculateTopIndentEvent,
  MutationScheduleEvent,
  eq,
} from '../../../services/helpers';
import {DateValue} from '../../../services/types';
import {CALENDAR_ROW_HEIGHT} from '../../lib/const';

export interface ICalendarEventViewProps extends ColProps {
  eventStartDate?: DateValue;
  event: MutationScheduleEvent;
  withoutIndent?: boolean;
  isPastDate?: boolean;
  disabled?: boolean;
  collapse?: boolean;
}

const StyledCol = styled(Col)<{
  height: string;
  zindex: number;
  ispast: number;
  top?: number | string;
  color_schema?: string;
  disabled: number;
}>`
  position: unset;
  max-height: 95% !important;

  ${({height}) =>
    height &&
    css`
      height: ${height};
    `}

  ${({zindex}) =>
    zindex &&
    css`
      z-index: ${zindex};
    `}

  ${({top}) =>
    top &&
    css`
      margin-top: ${top};
    `}

  ${({disabled}) =>
    disabled &&
    css`
      background-color: ${({theme}) => theme.colors.lightDisable};
    `}
`;

export default memo(
  forwardRef(
    (
      {
        span,
        event,
        children,
        eventStartDate,
        withoutIndent = false,
        isPastDate,
        disabled,
        ...rest
      }: ICalendarEventViewProps,
      ref: any,
    ): JSX.Element => {
      const [isOpenCollapse, setIsOpenCollapse] = useState<boolean>(false);

      const handleSetOpenCollapse = useCallback((isOpen: string[]) => {
        setIsOpenCollapse(!!isOpen?.length);
      }, []);

      return (
        <StyledCol
          disabled={disabled ? 1 : 0}
          ispast={isPastDate ? 1 : 0}
          ref={ref}
          span={span}
          height={`${CALENDAR_ROW_HEIGHT}px`}
          zindex={
            isOpenCollapse
              ? 999999 - event?.schedule_priority
              : event?.schedule_priority
          }
          top={
            withoutIndent ? undefined : calculateTopIndentEvent(eventStartDate)
          }
          {...rest}>
          {React.isValidElement(children)
            ? React.cloneElement(children, {
                ...(children?.props || {}),
                isOpenCollapse,
                handleSetOpenCollapse,
              })
            : children}
        </StyledCol>
      );
    },
  ),
);
