import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  DefaultForm,
  IDefaultFormProps,
} from '../../../../components/lib/General';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../../../../contex';
import {PriceTagPrintFormDTO} from '../../../../struture';
import {Col, Row, Form, Select} from 'antd';
import {useStoredCompanies} from '../../../../hooks';
import {ENTER_KEYS} from '../../../../services/const';

export interface IPriceTagPrintFormProps
  extends Pick<
    IDefaultFormProps<PriceTagPrintFormDTO, PriceTagPrintFormDTO>,
    | 'showFooter'
    | 'showNotify'
    | 'editMode'
    | 'className'
    | 'additionalValuesRequest'
  > {
  loading?: boolean;
  onCancel?: () => void;
  onSuccess: (value: PriceTagPrintFormDTO) => Promise<void>;
  onError?: () => Promise<void>;
  print: PriceTagPrintFormDTO;
}

export function PriceTagPrintForm({
  loading,
  editMode,
  print,
  onCancel,
  ...rest
}: IPriceTagPrintFormProps) {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const [instance] = Form.useForm();
  const {defaultCompany} = useStoredCompanies();

  const once = useRef(false);
  const selectRef = useRef<any>(null);

  const [priceTagSize, setPriceTagSize] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  const additionalValues = useMemo(
    () => ({
      price_margin_uuid: print?.price_margin_uuid,
    }),
    [print?.price_margin_uuid],
  );

  const notifyError = useCallback(
    (ApiError: any) => {
      once.current = true;
      alert(
        'error',
        t('Price tag'),
        `${'An error occurred during print price tags'} : ${ApiError?.message}`,
      );
    },
    [alert, t],
  );

  const onEnterPress = useCallback(
    (e: KeyboardEvent) => {
      setTimeout(() => {
        if (e?.target instanceof HTMLInputElement) {
          const field = e?.target?.id;
          const value = instance.getFieldValue(e?.target?.id);

          if (!value) {
            return false;
          }

          const popup = e?.target?.closest('.ant-select-open');

          if (!popup && e?.target?.type === 'search') {
            if (field === 'tag_size' && value) {
              e?.stopPropagation();
              e?.preventDefault();
            }
          }

          if (field === 'tag_size' && !once?.current) {
            once.current = true;
            setTimeout(instance.submit, 200);
          }
        }
      }, 100);

      return false;
    },

    [instance],
  );

  useEffect(() => {
    if (selectRef?.current) {
      setTimeout(() => {
        selectRef?.current?.focus();
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (defaultCompany?.price_tag_formats?.length) {
      const selectValues = defaultCompany?.price_tag_formats?.map((value) => ({
        value,
        label: value,
      }));

      setPriceTagSize(selectValues);
    }
  }, [defaultCompany?.price_tag_formats]);

  return (
    <DefaultForm
      instance={instance}
      className="price-tag-print-form"
      formKeyboardCodes={[...ENTER_KEYS]}
      onEnterPress={onEnterPress}
      additionalValuesRequest={additionalValues}
      initialValues={{
        ...print,
      }}
      onCancel={onCancel}
      submitButtonText={t('Print')}
      editMode={editMode}
      showNotify={false}
      notifyError={notifyError}
      {...rest}>
      {({loadingSubmit}) => (
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              tooltip={t('Format of price tags')}
              label={t('Format of price tags')}
              name="tag_size"
              rules={[
                {
                  required: true,
                  message: t('Tag size must be specified.'),
                },
              ]}>
              <Select
                ref={selectRef}
                disabled={loadingSubmit}
                allowClear
                placeholder={t('Select price tag size')}
                showSearch
                options={priceTagSize}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </DefaultForm>
  );
}
