import {
  PersonalizationFormDTO,
  IPersonalizationFormDTOProps,
} from '../internal';

export class PersonalizationMapper {
  public static toPersonalizationFormDTO(
    props: IPersonalizationFormDTOProps,
  ): PersonalizationFormDTO {
    return new PersonalizationFormDTO(props);
  }
}
