import {Record} from 'immutable';
import Profile, {ProfileProps} from './Profile';
import GroupPayment, {IGroupPaymentProps} from './GroupPayment';

export interface CompanyServiceProps {
  uuid: string;
  title: string;
  period_amount: string;
  description: string;
  period: string;
  manager: any; // TODO: додати тип
  personality: string;
  status: number;
  price: number;
  profile?: ProfileProps;
  group: any;
  payment_group: IGroupPaymentProps;
}

/**
 * @class CompanyService
 * @classdesc
 * @extends {Record}
 * @property {string}
 */
export default class CompanyService extends Record({
  uuid: '',
  title: '',
  period_amount: '',
  description: '',
  period: '',
  manager: {} as any,
  personality: '',
  status: 1,
  price: 0.0,
  group: {},
  payment_group: {},
}) {
  constructor(
    {payment_group, ...props}: CompanyServiceProps = {} as CompanyServiceProps,
  ) {
    const profile = new Profile(props?.profile || ({} as any));

    const options: any = {};

    if (payment_group) {
      options.payment_group = new GroupPayment(payment_group);
    }

    super({...profile.toObject(), ...props, ...options});
  }

  get fullName(): string {
    return new Profile(this.manager?.profile || {}).fullName;
  }
}
