import {Record} from 'immutable';
import {
  ClientDTO,
  ClientModel,
  EmployeeDTO,
  EmployeeMapper,
  EmployeeModel,
  GroupPaymentMapper,
  GroupPaymentModel,
  PAYMENT_AGENT_TYPE,
  PAYMENT_METHOD,
  PAYMENT_STATUS,
  PAYMENT_DESTINATION,
  PaymentDTO,
  ScheduleCalendarDTO,
  ScheduleCalendarMapper,
  ScheduleCalendarModel,
  ClientMapper,
  CashBoxModel,
  CashBoxMapper,
  PaymentableModel,
  PaymentableMapper,
  StoreDocumentMapper,
  StoreDocumentDTO,
  StoreDocumentModel,
  InvoiceModel,
} from '../internal';
import {OPERATION_TYPE} from '../../services/types';

export interface IPaymentModelReturnType {
  uuid: string;
  payment_comment: string;
  payment_sum: string;
  payment_date: string;
  payment_status: PAYMENT_STATUS;
  created_by: number;
  created_at: string;
  updated_at: string;
  payment_number: string;
  payment_group: GroupPaymentModel;
  payment_type: OPERATION_TYPE;
  payment_method: PAYMENT_METHOD;
  payment_agent_type: PAYMENT_AGENT_TYPE;
  payment_trigger: PAYMENT_DESTINATION;
  payment_agent_object: ClientModel | EmployeeModel;
  cashier: EmployeeModel;
  company_cashbox: CashBoxModel;
  payment_trigger_object:
    | ScheduleCalendarModel
    | StoreDocumentModel
    | InvoiceModel
    | string;
  paymentable?: PaymentableModel;
  payment_is_credit_operation: boolean;
  payment_has_fiscal_receipt?: boolean;
  payment_fiscal_receipt?: any;
}

export class PaymentModel extends Record<IPaymentModelReturnType>({
  uuid: '',
  payment_comment: '',
  payment_type: OPERATION_TYPE.IN,
  payment_sum: '0.00',
  payment_date: '',
  payment_status: PAYMENT_STATUS.Draft,
  created_by: 0,
  created_at: '',
  updated_at: '',
  payment_group: {} as GroupPaymentModel,
  payment_number: '',
  payment_method: PAYMENT_METHOD.Account,
  payment_agent_type: PAYMENT_AGENT_TYPE.Client,
  payment_trigger: PAYMENT_DESTINATION.Operation,
  payment_agent_object: {} as ClientModel | EmployeeModel,
  cashier: {} as EmployeeModel,
  company_cashbox: {} as CashBoxModel,
  payment_trigger_object: '',
  paymentable: {} as PaymentableModel,
  payment_is_credit_operation: false,
  payment_has_fiscal_receipt: false,
  payment_fiscal_receipt: {},
}) {
  constructor(props: PaymentDTO = {} as PaymentDTO) {
    const options: IPaymentModelReturnType = {
      ...props,
      payment_group: GroupPaymentMapper.toGroupPaymentModel(
        props?.payment_group,
      ),

      cashier: EmployeeMapper.toEmployeeModel(props?.cashier),

      company_cashbox: CashBoxMapper.toCashBoxModel(props?.company_cashbox),

      payment_trigger_object:
        props.payment_trigger === PAYMENT_DESTINATION.ScheduledOrder
          ? ScheduleCalendarMapper.toScheduleCalendarModel(
              props?.payment_trigger_object as ScheduleCalendarDTO,
            )
          : props.payment_trigger === PAYMENT_DESTINATION.StoreDocument
          ? StoreDocumentMapper.toStoreDocumentModel(
              props?.payment_trigger_object as StoreDocumentDTO,
            )
          : props.payment_trigger === PAYMENT_DESTINATION.Invoice
          ? (props?.payment_trigger_object as InvoiceModel)
          : '',
      payment_agent_object:
        props?.payment_agent_type === PAYMENT_AGENT_TYPE.Client
          ? ClientMapper.toClientModel(props?.payment_agent_object as ClientDTO)
          : EmployeeMapper.toEmployeeModel(
              props?.payment_agent_object as EmployeeDTO,
            ),
      paymentable: PaymentableMapper.toPaymentableModel(
        props?.paymentable || ({} as any),
      ),
    };
    super(options);
  }
}
