import {EmployeeModel, CashBoxModel} from '../internal';
import {CashBoxType} from '../type';

export interface ICashBoxAdditionalProps {
  editMode?: boolean;
  cashier?: EmployeeModel;
}

export class CashBoxFormDTO {
  public uuid: string;

  public title: string;

  public comment: string;

  public can_move_money_to: boolean;

  public can_move_money_from: boolean;

  public can_in_money_to: boolean;

  public can_out_money_from: boolean;

  public is_default_for_company: boolean;

  public type: CashBoxType;

  public cashier_uuid: string | EmployeeModel;

  constructor(
    props?: CashBoxModel,
    {cashier, editMode}: ICashBoxAdditionalProps = {},
  ) {
    this.uuid = props?.uuid || '';
    this.title = props?.box_title || '';
    this.comment = props?.box_comment || '';
    this.type = props?.box_type || CashBoxType.Cash;
    this.can_move_money_to =
      typeof props?.box_can_move_money_to === 'boolean'
        ? props?.box_can_move_money_to
        : true;
    this.can_move_money_from =
      typeof props?.box_can_move_money_from === 'boolean'
        ? props?.box_can_move_money_from
        : true;
    this.can_in_money_to =
      typeof props?.box_can_in_money_to === 'boolean'
        ? props?.box_can_in_money_to
        : true;
    this.can_out_money_from =
      typeof props?.box_can_out_money_from === 'boolean'
        ? props?.box_can_out_money_from
        : true;
    this.is_default_for_company = props?.box_is_default_for_company || false;
    this.cashier_uuid = editMode
      ? props?.cashier?.uuid
        ? props?.cashierModel
        : ''
      : cashier || '';
  }
}
