import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {List} from 'immutable';
import {IActionButtonProps} from '../../../components/lib/General';
import {useModal} from '../../../components/lib/libV2/hooks';
import {CompanyModel, GroupPaymentFormDTO} from '../../../struture';
import {EditButton, SuspenseEmpty} from '../../../components/lib/DataDisplay';

const GroupPaymentSideWindowLazy = React.lazy(
  () => import('../Show/GroupPaymentSideWindow'),
);

export interface IUpdateGroupPaymentButtonProps
  extends Omit<IActionButtonProps, 'children'> {
  onSuccess: (value: GroupPaymentFormDTO) => Promise<void>;
  onCancel?: () => void;
  children?: React.ReactNode;
  groupPayment: GroupPaymentFormDTO;
  companyList: List<CompanyModel>;
  defaultCompanyUuid: string;
}

export default function UpdateGroupPaymentButton({
  children,
  groupPayment,
  onSuccess,
  onCancel,
  companyList,
  defaultCompanyUuid,
  ...rest
}: IUpdateGroupPaymentButtonProps) {
  const {t} = useTranslation();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <EditButton
        tooltipTitle={t('Edit group payment')}
        onClick={handleOnInit}
        disabled={false}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <GroupPaymentSideWindowLazy
          editMode
          companyList={companyList}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          groupPayment={groupPayment}
          visible={visible}
        />
      </Suspense>
    </>
  );
}
