import {ScheduleCalendarModel} from '../internal';
import {ClientPersonalityType} from '../../services/types';

export enum ScheduleMessageType {
  Sms = 'sms',
  Email = 'email',
}

export interface IScheduleMessageFormAdditionalProps {
  type?: ScheduleMessageType;
}

export class ScheduleMessageFormDTO {
  public uuid: string;
  public message: string;
  public addressees: string | string[];
  public type: ScheduleMessageType;

  constructor(
    props?: ScheduleCalendarModel,
    {type}: IScheduleMessageFormAdditionalProps = {},
  ) {
    this.uuid = props?.uuid || '';
    this.message = '';
    this.type = type || ScheduleMessageType.Sms;
    this.addressees =
      props?.client?.client_type === ClientPersonalityType.Individual
        ? props?.clientModel?.phone
        : '';
  }
}
