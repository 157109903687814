import React, {Suspense, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useAcl, useCalendar} from '../../../contex';
import {OverviewLayout} from '../../../components/lib/Layout';
import {
  useStateExpenseList,
  useStateSchedule,
  useStateSchedulePaymentList,
  useStoredCompanies,
  useStoredFileList,
  useStoredStatusList,
} from '../../../hooks';
import {HeaderLeft, SuspenseEmpty} from '../../../components/lib/DataDisplay';
import {OrderBottomView, OrderDetailsHeader, OrderHeader} from '../Show';
import OrderPageTabPanel from '../Show/OrderPageTabPanel';
import {useParams} from 'react-router';
import {UpdateOrderButton} from '../../../views/Orders/Buttons';
import {
  PaymentScheduleFormDTO,
  ScheduleCalendarMapper,
  ScheduleStatusesText,
} from '../../../struture';
import {useModal} from '../../../components/lib/libV2/hooks';
import {ExpenseList} from '../List';
import styled, {useTheme} from 'styled-components';
import {correctPrice, textToUpperCase} from '../../../services/helpers';
import {StyledDescription, StyledTitle} from '../../../components/lib/Styled';

const FileListSideWindowLazy = React.lazy(
  () => import('../Show/FileListSideWindow'),
);

export interface ICalendarEntryProps {}

export const DISABLED_SCHEDULE_ACTIONS = [
  ScheduleStatusesText.COMPLETED,
  ScheduleStatusesText.CANCELLED,
  ScheduleStatusesText.CLOSED,
];

const StyledExpanseContainer = styled.div`
  background-color: ${({theme}) => theme.background.layout};
  padding: 10px;
  border-radius: 7px;
`;

const StyledExpanseTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
`;

export default function OrderPage({}: ICalendarEntryProps): JSX.Element {
  const {scheduleId} = useParams();
  const {t} = useTranslation();
  const theme: any = useTheme();
  const {defaultCompany} = useStoredCompanies();

  const {defaultCompanyUuid} = useStoredCompanies();
  const {scheduleDetails} = useCalendar();

  const {order: orderAccess} = useAcl((acl) => acl);

  const {visible, handleOnInit, handleCancel} = useModal();

  const {
    schedule,
    loading,
    stats,

    handleUpdateSchedule,
    handleUpdateScheduleComment,
    handleChangeScheduleStatus,
    handleUpdate,
    handlePrintScheduleDocument,
    handleFormingScheduleMessage,
    handleSendScheduleMessage,
    handleUpdateStats,
    handleCloseSchedule,
    refresh: handleRefreshSchedule,
  } = useStateSchedule({
    scheduleUuid: scheduleId!,
  });

  const {handleCreateSchedulePrepayment} = useStateSchedulePaymentList({
    loadOnInit: false,
    scheduleUuid: scheduleId!,
  });

  const expanseListParams = useStateExpenseList({
    scheduleUuid: schedule?.uuid!,
    handleUpdate,
    schedule,
  });

  const {
    handleUploadFiles,
    fileList,
    loading: fileListLoading,
    ...fileListParams
  } = useStoredFileList({
    scheduleUuid: scheduleId!,
    limit: 150,
    handleUpdateStats,
  });

  const isDisabledScheduleAction = DISABLED_SCHEDULE_ACTIONS.includes(
    schedule?.status_text,
  );

  const createSchedulePrepayment = useCallback(
    async (value: PaymentScheduleFormDTO) => {
      const prepayment = await handleCreateSchedulePrepayment(value);

      if (prepayment?.schedule) {
        const scheduleModel = ScheduleCalendarMapper.toScheduleCalendarModel(
          prepayment?.schedule,
        );

        handleUpdate(scheduleModel);
      }

      return prepayment;
    },
    [handleCreateSchedulePrepayment, handleUpdate],
  );

  const {allowedStatusesTo, loading: statusListLoading} = useStoredStatusList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
    allowedStatusesTo:
      schedule?.latestStatusModel?.customStatusModel?.allowed_statuses_to,
  });

  const isWarningExpanseDescription =
    Number(expanseListParams?.aggregatedSum?.total_sum) > 0 ||
    expanseListParams?.expenseList?.size;

  return (
    <>
      <OrderPageTabPanel
        isDisabledScheduleAction={isDisabledScheduleAction}
        schedule={schedule}
        expanseListParams={expanseListParams}>
        {({tabs}) => (
          <>
            {scheduleDetails(({routes, navigateGoBack}) => {
              return (
                <OverviewLayout
                  isHeaderFlickering
                  underHeaderContent={
                    schedule?.uuid ? (
                      <OrderHeader
                        schedule={schedule}
                        loading={statusListLoading || loading}
                        handleChangeScheduleStatus={handleChangeScheduleStatus}
                        allowedStatusesTo={allowedStatusesTo}
                        handleCreateSchedulePrepayment={
                          createSchedulePrepayment
                        }
                        handleCloseSchedule={handleCloseSchedule}
                      />
                    ) : null
                  }
                  header={
                    <>
                      <OrderDetailsHeader
                        schedule={schedule}
                        handleUpdateScheduleComment={
                          handleUpdateScheduleComment
                        }
                        handleUploadFiles={handleUploadFiles}
                        handleOpenFileList={handleOnInit}
                        stats={stats}
                        isDisabledScheduleAction={isDisabledScheduleAction}
                      />

                      <StyledExpanseTitleContainer>
                        <StyledTitle bold>
                          {textToUpperCase(t('Services and materials'))}
                        </StyledTitle>
                        <StyledDescription
                          $color={
                            isWarningExpanseDescription
                              ? theme.colors.black
                              : theme.colors.warning
                          }>
                          {isWarningExpanseDescription
                            ? `${t('In the amount of')}: ${
                                defaultCompany?.currency_symbol || ''
                              } ${correctPrice(
                                expanseListParams?.aggregatedSum?.total_sum ||
                                  0,
                              )}`
                            : t('There are no services and materials')}
                        </StyledDescription>
                      </StyledExpanseTitleContainer>

                      <StyledExpanseContainer>
                        <ExpenseList
                          schedule={schedule}
                          isDisabledScheduleAction={isDisabledScheduleAction}
                          handleRefreshSchedule={handleRefreshSchedule}
                          {...expanseListParams}
                        />
                      </StyledExpanseContainer>
                    </>
                  }
                  headerTitleDescription={schedule?.schedule_title}
                  disabled={!orderAccess?.manage}
                  loading={statusListLoading || loading || fileListLoading}
                  data={null}
                  routes={routes}
                  headerTitle={t('Order')}
                  headerLeft={
                    <HeaderLeft absolute={false} onClick={navigateGoBack} />
                  }
                  headerRight={
                    <UpdateOrderButton
                      isHasStrictMode={schedule?.schedule_has_strict_mode}
                      schedule={schedule as any}
                      onSuccess={handleUpdateSchedule}
                      companyUuid={defaultCompanyUuid}
                      disabled={isDisabledScheduleAction}
                    />
                  }
                  outsideFooterContent={
                    <OrderBottomView
                      schedule={schedule}
                      handlePrintScheduleDocument={handlePrintScheduleDocument}
                      handleFormingScheduleMessage={
                        handleFormingScheduleMessage
                      }
                      handleSendScheduleMessage={handleSendScheduleMessage}
                    />
                  }
                  tabs={tabs}
                />
              );
            }, schedule)}
          </>
        )}
      </OrderPageTabPanel>
      <Suspense fallback={<SuspenseEmpty />}>
        <FileListSideWindowLazy
          {...fileListParams}
          fileList={fileList}
          loading={fileListLoading}
          handleUploadFiles={handleUploadFiles}
          schedule={schedule}
          onCloseDrawer={handleCancel}
          visible={visible}
          disabled={isDisabledScheduleAction}
        />
      </Suspense>
    </>
  );
}
