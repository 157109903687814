import {Record, List} from 'immutable';
import {ListModel, ListProps} from './List';
import Company, {CompanyProps} from './Company';

export interface CompanyListProps extends ListProps {
  companies: CompanyProps[];
}

/**
 * @class CompanyList
 * @classdesc Company list model.
 * @extends {Record}
 * @property {List} companies - list of companies
 * @property {number} total - total amount of records on backend
 */
export default class CompanyList extends Record({
  companies: List(),
  count: 0,
  ...ListModel.toJS(),
}) {
  /**
   * @constructor
   * @param {{companies:[]}} param0
   */
  constructor({companies = [], ...props}: CompanyListProps) {
    super({
      companies: List(companies.map((company) => new Company(company))),
      ...props,
    });
  }
}
