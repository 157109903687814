import {Record} from 'immutable';

export interface IFeedBackProps {
  uuid: string;
  message: string;
  rate: string;
  updated_at: string;
  created_at: string;
}

/**
 * @class FeedBack
 * @classdesc
 * @extends {Record}
 */
export default class FeedBack extends Record({
  uuid: '',
  message: '',
  rate: '',
  updated_at: null,
  created_at: null,
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(props: IFeedBackProps = {} as IFeedBackProps) {
    super({...props});
  }
}
