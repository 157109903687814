import * as React from 'react';
import {List} from 'immutable';
import {ApiError, IEntityProps, IListSearchProps} from '../services/types';
import {
  IServiceGroupSessionListDTO,
  ServiceGroupSessionMapper,
  ServiceGroupSessionModel,
} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {getGroupSessionList} from '../services/api/groupSessions';

export interface IUseServiceGroupSessionListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  serviceUuid: string;
}

export interface IUseServiceGroupSessionListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<ServiceGroupSessionModel> | null;
  refresh: (
    value: Partial<IUseServiceGroupSessionListProps>,
  ) => Promise<List<ServiceGroupSessionModel> | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  serviceUuid: string;
}

export function useServiceGroupSessionList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    serviceUuid,
  }: IUseServiceGroupSessionListProps = {} as IUseServiceGroupSessionListProps,
): IUseServiceGroupSessionListReturnType {
  const {cancellablePromise, didCancel} = useCancellablePromise();
  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    handleUpdate,
    serviceUuid: localServiceUuid,
  } = useStateReducer<Omit<IUseServiceGroupSessionListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    serviceUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      serviceUuid = localServiceUuid,
    }: Partial<IUseServiceGroupSessionListProps> = {}): Promise<List<ServiceGroupSessionModel> | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          serviceUuid,
        });

        const {group_sessions, total} =
          await cancellablePromise<IServiceGroupSessionListDTO>(
            getGroupSessionList({serviceUuid, limit, offset, keywords}),
          );

        const groupSessionsListModel =
          ServiceGroupSessionMapper.toServiceGroupSessionListModel(
            group_sessions,
            total,
          );

        if (!didCancel.current) {
          handleUpdate({
            entityList: groupSessionsListModel?.group_sessions,
            total: groupSessionsListModel?.total,
            loading: false,
          });

          return groupSessionsListModel?.group_sessions;
        }
      } catch (e) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }
      }
    },
    [
      localServiceUuid,
      cancellablePromise,
      didCancel,
      error,
      handleUpdate,
      listKeywords,
      listLimit,
      listOffset,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && serviceUuid) {
      (async () => {
        await refresh({serviceUuid});
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, serviceUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    serviceUuid: localServiceUuid,
  };
}
