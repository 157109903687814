import {Record, List} from 'immutable';
import {IListProps} from './List';
import {StatusDTO, StatusModel} from '../internal';
import {WithoutRecursion} from '../type';

export interface IStatusListModelProps extends IListProps {
  statuses: StatusDTO[];
}

export interface IStatusListModelReturnType extends IListProps {
  statuses: List<StatusModel>;
}

export class StatusListModel extends Record<IStatusListModelReturnType>({
  statuses: List(),
  total: 0,
}) {
  constructor(
    {statuses = [], total}: IStatusListModelProps = {} as IStatusListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IStatusListModelReturnType = {
      statuses: List(
        statuses.map((status) => new StatusModel(status, withoutRecursion)),
      ),
      total,
    };

    super(options);
  }
}
