import {List} from 'immutable';
import {
  MailingTemplateDTO,
  MailingTemplateFormDTO,
  IMailingTemplateDTOProps,
  MailingTemplateModel,
  MailingTemplateListModel,
} from '../internal';
import {listToArray} from '../../helpers';
import {WithoutRecursion} from '../type';

export interface IMailingTemplateListDTO {
  templates: MailingTemplateDTO[];
  total: number;
}

export class MailingTemplateMapper {
  public static toMailingTemplateDTO(
    template: MailingTemplateModel | IMailingTemplateDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): MailingTemplateDTO {
    return new MailingTemplateDTO(
      {
        template_type: template?.template_type,
        template_uuid: template?.template_uuid,
        template_title: template?.template_title,
        template_event: template?.template_event,
        template_body: template?.template_body,
        template_comment: template?.template_comment,
        company: template?.company,
        created_at: template?.created_at,
        updated_at: template?.updated_at,
      },
      [WithoutRecursion.mailingTemplate, ...withoutRecursion],
    );
  }

  public static toMailingTemplateListDTO(
    templates: List<MailingTemplateModel> | IMailingTemplateDTOProps[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): IMailingTemplateListDTO {
    const templateList = List.isList(templates)
      ? listToArray<MailingTemplateModel>(templates)
      : templates;

    return {
      templates: templateList?.map((template) =>
        MailingTemplateMapper.toMailingTemplateDTO(template, [
          WithoutRecursion.mailingTemplate,
          ...withoutRecursion,
        ]),
      ),
      total,
    };
  }

  public static toMailingTemplateModel(
    templateDTO: MailingTemplateDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): MailingTemplateModel {
    return new MailingTemplateModel(templateDTO, [
      WithoutRecursion.mailingTemplate,
      ...withoutRecursion,
    ]);
  }

  public static toMailingTemplateListModel(
    templateDTOs: MailingTemplateDTO[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): MailingTemplateListModel {
    return new MailingTemplateListModel({templates: templateDTOs, total}, [
      WithoutRecursion.mailingTemplate,
      ...withoutRecursion,
    ]);
  }

  public static toMailingTemplateFormDTO(
    templateModel?: MailingTemplateModel,
  ): MailingTemplateFormDTO {
    return new MailingTemplateFormDTO(templateModel);
  }
}
