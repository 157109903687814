import {Record, List} from 'immutable';
import {IListProps} from './List';
import {
  IProductsListStatsProps,
  ProductCategoryDTO,
  ProductCategoryModel,
} from '../internal';
import {WithoutRecursion} from '../type';

export interface IProductCategoryListModelProps extends IListProps {
  categories: ProductCategoryDTO[];
  stats?: IProductsListStatsProps;
  parentCategory?: ProductCategoryModel;
}

export interface IProductCategoryListModelReturnType extends IListProps {
  categories: List<ProductCategoryModel>;
  stats?: IProductsListStatsProps;
  keywords: string;
  page: number;
  parentCategory?: ProductCategoryModel;
}

export class ProductCategoryListModel extends Record<IProductCategoryListModelReturnType>(
  {
    categories: List(),
    total: 0,
    stats: {} as IProductsListStatsProps,
    keywords: '',
    page: 1,
    parentCategory: {} as ProductCategoryModel,
  },
) {
  constructor(
    {
      categories = [],
      ...props
    }: IProductCategoryListModelProps = {} as IProductCategoryListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IProductCategoryListModelReturnType = {
      ...props,
      keywords: '',
      page: 1,
      categories: List(
        categories.map((category) => new ProductCategoryModel(category)),
      ),
    };

    super(options);
  }
}
