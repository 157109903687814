import moment from 'moment';
import {apiDelete, apiGet, apiPost, apiPut} from '../core/api';
import {contentApiUrl} from '../const';
import {
  IUserListProps,
  IUserProps,
  ListOptions,
  User,
  USER_STATUS_TEST,
  UserList,
} from '../models';
import Validate from '../validate/Validate';
import {GENDER, head, ifElse, toLowerCase} from '../helpers';
import {ApiAnswer, DeletePrams} from '../types';
import {ErrorsStatus} from '../core/request';

export interface ICompanyUserParams
  extends Pick<
    IUserProps,
    | 'first_name'
    | 'middle_name'
    | 'last_name'
    | 'phone'
    | 'email'
    | 'address'
    | 'gender'
    | 'birthday'
    | 'goods_discount'
    | 'subscription_discount'
    | 'card_number'
  > {
  uuid?: string;
  default_company?: string;
  identifier?: string;
  login?: string;
  password?: string;
  confirm_password?: string;
}

export const USER_INITIAL_PARAM: ICompanyUserParams = {
  first_name: '',
  middle_name: '',
  last_name: '',
  phone: '',
  email: '',
  address: '',
  birthday: '',
  card_number: '',
  login: '',
  password: '',
  confirm_password: '',
  goods_discount: 0,
  subscription_discount: 0,
  gender: GENDER.MALE,
};

export const USER_REQUIRED_FIELD = {
  ...USER_INITIAL_PARAM,
  birthday: (value: string) =>
    value ? moment(value).format('YYYY-MM-DD') : '',
};

export interface GetUserListAnswer extends IUserListProps, ApiAnswer {}

export const correctUserFormParam = (
  {default_company, ...rest}: IUserProps,
  defaultCompanyUuid?: string,
): ICompanyUserParams => ({
  ...rest,
  default_company: (default_company?.uuid as string) || defaultCompanyUuid,
});

export interface IUserSearchProps {
  keywords?: string;
}

export enum TOGGLE_USER_STATUS {
  ENABLE = 'enable',
  DISABLE = 'disable',
}

export const toggleUserAccountStatus = (
  status: USER_STATUS_TEST,
): TOGGLE_USER_STATUS => {
  if (status === USER_STATUS_TEST.ENABLE) {
    return TOGGLE_USER_STATUS.DISABLE;
  }
  return TOGGLE_USER_STATUS.ENABLE;
};

export async function getUserList(
  listOptions = new ListOptions(),
  keywords?: string,
): Promise<UserList> {
  const {offset = 0, limit = 50} = listOptions.toObject();

  const isKeywords = ifElse(!!keywords, `&keywords=${keywords}`, '');

  const res = await apiGet<null, GetUserListAnswer>(
    `${contentApiUrl}/users?offset=${offset}&limit=${limit}${isKeywords}`,
    null,
    {ignoreRedirect: [ErrorsStatus.Forbidden]},
  );

  return new UserList(res);
}

export interface UserAnswer extends ApiAnswer {
  user: IUserProps;
}

export async function getUserById({
  userUuid,
}: {
  userUuid: string;
}): Promise<User> {
  const {user} = await apiGet<null, UserAnswer>(
    `${contentApiUrl}/users/${userUuid}`,
    null,
    {ignoreRedirect: [ErrorsStatus.Forbidden]},
  );

  return new User(user);
}

export async function createUser({
  first_name,
  last_name,
  middle_name,
  email,
  phone,
  address,
  gender,
  birthday,
  subscription_discount,
  goods_discount,
  card_number,
  login,
  password,
  confirm_password,
}: ICompanyUserParams): Promise<User> {
  Validate.string({value: first_name});
  Validate.string({value: last_name});
  Validate.string({value: phone});
  Validate.string({value: login});
  Validate.string({value: password});
  Validate.string({value: confirm_password});

  const {user} = await apiPost<ICompanyUserParams, UserAnswer>(
    `${contentApiUrl}/users`,
    {
      first_name,
      last_name,
      middle_name,
      email,
      phone,
      address,
      gender,
      birthday,
      subscription_discount,
      goods_discount,
      card_number,
      login,
      password,
      confirm_password,
    },
  );

  return new User(user);
}

/**
 * @name editClientById
 * @desc Редагування існуючого клієнта
 */

export async function editUser({
  uuid: user_uuid,
  first_name,
  middle_name,
  last_name,
  email,
  phone,
  address,
  gender,
  birthday,
  subscription_discount,
  goods_discount,
  card_number,
  default_company,
}: ICompanyUserParams): Promise<User> {
  Validate.string({value: first_name});
  Validate.string({value: last_name});
  Validate.string({value: phone});

  const {user} = await apiPut<ICompanyUserParams, UserAnswer>(
    `${contentApiUrl}/users/${user_uuid}`,
    {
      first_name,
      last_name,
      middle_name,
      phone,
      email,
      address,
      gender,
      birthday,
      subscription_discount,
      goods_discount,
      card_number,
      default_company,
    },
  );
  return new User(user);
}

/**
 * @name deleteClientById
 * @desc Видалити одного клієнта по Uuid
 * @param {String} client_uuid
 * @param {Array} ids
 * @throws {ApiError}
 */
export async function deleteUser(
  ids: string[] = [],
  client_uuid?: string,
): Promise<UserAnswer> {
  const userUuid = head(ids);

  return await apiDelete<DeletePrams, UserAnswer>(
    `${contentApiUrl}/users/${userUuid}`,
  );
}

export interface IDisableOrEnableUserProps {
  user_uuid: string;
  action: TOGGLE_USER_STATUS;
}

export async function disableOrEnableUser({
  user_uuid,
  action = TOGGLE_USER_STATUS.ENABLE,
}: IDisableOrEnableUserProps): Promise<User> {
  Validate.string({value: user_uuid});
  Validate.string({value: action});

  const {user} = await apiPut<null, UserAnswer>(
    `${contentApiUrl}/users/${user_uuid}/${toLowerCase(action)}`,
    null,
  );
  return new User(user);
}
