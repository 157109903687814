import React from 'react';
import {
  DefaultForm,
  IDefaultFormProps,
} from '../../../../components/lib/General';
import {Input} from '../../../../components/lib/DataEntry';
import {useTranslation} from 'react-i18next';
import {Col, Row, Form} from 'antd';
import {AuthButton} from '../../Buttons';
import {FormSwitch} from '../../../../components/lib/DataDisplay';
import {
  EmailVerificationFormDTO,
  IEmailVerificationFormDTOProps,
} from '../../../../struture';

export interface IEmailVerificationFormModalProps
  extends Omit<
    IDefaultFormProps<EmailVerificationFormDTO, EmailVerificationFormDTO>,
    'initialValues' | 'children'
  > {}

export function EmailVerificationForm({
  ...rest
}: IEmailVerificationFormModalProps): JSX.Element {
  const {t} = useTranslation();

  return (
    <DefaultForm
      {...rest}
      initialValues={
        new EmailVerificationFormDTO({} as IEmailVerificationFormDTOProps)
      }
      showFooter={false}>
      {({loadingSubmit, submit, handlerUpdateFormState, ...rest}) => (
        <Row gutter={10}>
          <Col span={24}>
            <Form.Item
              name="email"
              label={t('Email')}
              rules={[
                {
                  required: true,
                  message: t('Please input your Email'),
                },
              ]}>
              <Input disabled={loadingSubmit} placeholder={t('Enter email')} />
            </Form.Item>
          </Col>
          <FormSwitch
            disabled={loadingSubmit}
            loading={loadingSubmit}
            name="it_is_mine"
            label={t('This is my email')}
            tooltipTitle={t('This is my email')}
            onChange={(payment_document) =>
              handlerUpdateFormState({payment_document})
            }
            {...rest}
          />
          <Col span={24}>
            <AuthButton
              disabled={loadingSubmit}
              data-testid="login-form__button--submit"
              loading={loadingSubmit}
              type="primary"
              onClick={submit}
              block>
              {t('Send')}
            </AuthButton>
          </Col>
        </Row>
      )}
    </DefaultForm>
  );
}
