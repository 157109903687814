import {EmployeeModel} from '../internal';

export enum EmployeeRole {
  Administrator = 'admin',
  Manager = 'manager',
}

export class EmployeeRoleFormDTO {
  public uuid: string;

  public role: EmployeeRole;

  constructor(props?: EmployeeModel) {
    this.uuid = props?.uuid || '';
    this.role = props?.role || EmployeeRole.Manager;
  }
}
