import {correctPrice, toMoment} from '../../services/helpers';
import {
  IPaymentSchedulePostAction,
  CashBoxModel,
  ScheduleCalendarModel,
  EmployeeModel,
  ClientModel,
} from '../internal';
import {Moment} from 'moment';

enum APPOINTMENT_METHOD {
  CASHBOX = 'CASHBOX',
  ACCOUNT = 'ACCOUNT',
  BANK = 'BANK',
}

export interface IScheduleCloseAdditionalParams {
  editMode?: boolean;
  cashier: EmployeeModel;
  client: ClientModel;
  payment_post_action?: IPaymentSchedulePostAction | null;
  comment?: string;
}

export class ScheduleCloseDTO {
  public uuid: string;
  public schedule_checkout_transaction: boolean;
  public schedule_close_date?: string | Moment;
  public schedule_payment_date: string | Moment;
  public schedule_payment_method: APPOINTMENT_METHOD;
  public schedule_payment_sum: number | string;
  public schedule_comment: string;
  public schedule_payment_cashier_uuid: string | EmployeeModel;
  public schedule_payment_cashbox_uuid: string | CashBoxModel;
  public schedule_payment_post_action?: IPaymentSchedulePostAction | null;
  public client?: ClientModel;

  constructor(
    props: ScheduleCalendarModel,
    {
      cashier,
      payment_post_action,
      client,
      comment,
    }: IScheduleCloseAdditionalParams,
  ) {
    this.uuid = props?.uuid || '';
    this.schedule_checkout_transaction = true;
    this.schedule_close_date = toMoment(new Date());
    this.schedule_payment_date = toMoment(new Date());
    this.schedule_payment_method = APPOINTMENT_METHOD.CASHBOX;
    this.schedule_payment_sum = this.calculatePaymentSum(props);
    this.schedule_comment = comment || '';
    this.schedule_payment_cashier_uuid = cashier?.uuid ? cashier : '';
    this.schedule_payment_cashbox_uuid = '';
    this.schedule_payment_post_action = payment_post_action || null;
    this.client = client;
  }

  private calculatePaymentSum({
    sum_total,
    sum_paid,
  }: ScheduleCalendarModel): string {
    const total = Number(sum_total);
    const paid = Number(sum_paid);

    const result = total - paid;

    if (paid >= total) {
      return correctPrice(0);
    }

    return correctPrice(result);
  }
}
