import React, {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {Col, Form, Input, Row} from 'antd';
import {useDefaultForm} from '../../../contex';
import {useTranslation} from 'react-i18next';
import {
  IUseStateAbonementListReturnType,
  IUseStateEmployeeListReturnType,
  IUseStateCompanyClientListReturnType,
  useStoredCompanies,
  IUseStateAbonementGroupListReturnType,
} from '../../../hooks';
import {FormSwitch, SearchSelect} from '../../../components/lib/DataDisplay';
import {StyledDescription} from '../../../components/lib/Styled';
import {correctPrice, isListToArray} from '../../../services/helpers';
import {DatePicker} from '../../../components/lib/General';
import {List} from 'immutable';
import {
  AbonementGroupModel,
  AbonementModel,
  AbonementStatuses,
  ClientModel,
} from '../../../struture';
import styled from 'styled-components';
import {PERSONALITY_TYPE} from '../../../services/types';

export interface IClientAbonementFieldsProps
  extends Pick<
      IUseStateEmployeeListReturnType,
      'employees' | 'handleSearchEmployees'
    >,
    Pick<
      IUseStateAbonementListReturnType,
      'abonementList' | 'handleSearchAbonements'
    >,
    Pick<
      IUseStateCompanyClientListReturnType,
      'clients' | 'handleSearchClients'
    >,
    Pick<
      IUseStateAbonementGroupListReturnType,
      | 'groupList'
      | 'handleSearchAbonementGroups'
      | 'handleRefreshAbonementGroups'
    > {
  employeesLoading: boolean;
  abonementsLoading: boolean;
  clientsLoading: boolean;
  groupsLoading: boolean;
  disabledField?: 'clients' | 'abonements' | 'group';
}

const StyledDescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > :first-child {
    margin-right: 5px;
  }
`;

export function ClientAbonementFields({
  abonementList,
  abonementsLoading,
  handleSearchAbonements,

  clients,
  clientsLoading,
  handleSearchClients,

  groupList,
  groupsLoading,
  handleSearchAbonementGroups,
  handleRefreshAbonementGroups,

  disabledField,
}: IClientAbonementFieldsProps): JSX.Element {
  const {t} = useTranslation();
  const {
    loadingSubmit,
    handlerUpdateFormState,
    setFieldsValue,
    getFieldValue,
    formData,
  } = useDefaultForm();
  const {defaultCompany} = useStoredCompanies();

  const [disabledClientsField, setDisabledClientsField] = useState(false);
  const [disabledAbonementField, setDisabledAbonementField] = useState(false);
  const [disabledAbonementGroupField, setDisabledAbonementGroupField] =
    useState(false);

  const [activeAbonementList, setActiveAbonementList] =
    useState<List<AbonementModel> | null>(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [showGroups, setShowGroups] = useState(false);
  const [groupRefresh, setGroupRefresh] = useState(false);

  const handleSearchAbonement = useCallback(
    async (keywords: string) => {
      await handleSearchAbonements({
        keywords,
        showLoading: false,
        limit: 100,
      });

      setSearchLoading(true);
    },
    [handleSearchAbonements],
  );

  const updateFieldsExtendsAbonement = useCallback(
    (abonement: AbonementModel) => {
      if (abonement) {
        const discount = Number(formData?.discount);
        const price = Number(abonement?.abon_price);
        const amount = correctPrice(
          discount ? price - price * (discount / 100) : abonement?.abon_price,
        );

        handlerUpdateFormState({
          price: abonement?.abon_price,
          final_price: amount,
          manager_uuid: abonement?.managerModel?.uuid || '',
          manager_name: abonement?.managerModel?.fullName || '',
          base_cost: abonement?.abon_price,
        });
      }
    },
    [formData?.discount, handlerUpdateFormState],
  );

  const handleChangeAbonement = useCallback(
    async (company_abonement_uuid: string) => {
      const abonement = abonementList?.find(
        ({uuid}) => uuid === company_abonement_uuid,
      );

      handlerUpdateFormState({
        company_abonement_uuid,
      });

      if (abonement) {
        if (abonement?.serviceModel?.personality === PERSONALITY_TYPE.GROUP) {
          setGroupRefresh(true);
          setShowGroups(true);

          handlerUpdateFormState({
            abonement_group_uuid: '',
          });

          await handleRefreshAbonementGroups({
            limit: 100,
            abonementUuid: abonement?.uuid,
            showLoading: false,
            page: 1,
          });
          setTimeout(setGroupRefresh, 300, false);
        } else {
          handlerUpdateFormState({
            abonement_group_uuid: '',
          });
          setShowGroups(false);
        }

        updateFieldsExtendsAbonement(abonement);
      }
    },
    [
      abonementList,
      handleRefreshAbonementGroups,
      handlerUpdateFormState,
      updateFieldsExtendsAbonement,
    ],
  );

  const handleChangeGroup = useCallback(
    (abonement_group_uuid: string) => {
      if (!groupRefresh) {
        handlerUpdateFormState({abonement_group_uuid});
      }
    },
    [groupRefresh, handlerUpdateFormState],
  );

  const handleChangeAmount = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.replace(/[a-zA-Z]+/g, '');

      if (value !== '' && Number(value) >= 0 && Number(formData?.price || 0)) {
        const discount =
          (100 * (Number(formData?.price || 0) - Number(value))) /
          Number(formData?.price || 0);

        handlerUpdateFormState({
          final_price: value,
          discount: discount.toFixed(2),
        });
      } else {
        handlerUpdateFormState({
          final_price: value,
          discount: 0,
        });
      }
    },
    [formData?.price, handlerUpdateFormState],
  );

  const isActiveAbonement = useCallback(
    ({abon_status}: AbonementModel) => abon_status === AbonementStatuses.Enable,
    [],
  );

  useEffect(() => {
    if (abonementList && !activeAbonementList) {
      setActiveAbonementList(abonementList.filter(isActiveAbonement));
    }

    if (searchLoading && abonementList) {
      setActiveAbonementList(abonementList.filter(isActiveAbonement));
      setSearchLoading(false);
    }
  }, [abonementList, activeAbonementList, isActiveAbonement, searchLoading]);

  useEffect(() => {
    if (
      !disabledClientsField &&
      formData?.client_uuid instanceof ClientModel &&
      disabledField === 'clients'
    ) {
      setDisabledClientsField(true);
    }
  }, [disabledField, disabledClientsField, formData?.client_uuid]);

  useEffect(() => {
    if (
      !disabledAbonementField &&
      formData?.company_abonement_uuid instanceof AbonementModel &&
      disabledField === 'abonements'
    ) {
      updateFieldsExtendsAbonement(formData?.company_abonement_uuid);

      setDisabledAbonementField(true);
    }
  }, [
    disabledAbonementField,
    disabledField,
    formData?.company_abonement_uuid,
    updateFieldsExtendsAbonement,
  ]);

  useEffect(() => {
    if (
      !disabledAbonementGroupField &&
      formData?.abonement_group_uuid instanceof AbonementGroupModel &&
      disabledField === 'group'
    ) {
      updateFieldsExtendsAbonement(formData?.company_abonement_uuid);

      setDisabledAbonementField(true);
      setDisabledAbonementGroupField(true);
      setShowGroups(true);
    }
  }, [
    disabledAbonementField,
    disabledAbonementGroupField,
    disabledField,
    formData?.abonement_group_uuid,
    formData?.company_abonement_uuid,
    updateFieldsExtendsAbonement,
  ]);

  return (
    <Row gutter={12}>
      <Col span={24}>
        <Form.Item
          tooltip={t('Clients')}
          label={t('Clients')}
          name="client_uuid"
          rules={[
            {
              required: true,
              message: t('Clients must be specified.'),
            },
          ]}>
          <SearchSelect
            selectIfOnFirst={false}
            selectFirst={false}
            name="client_uuid"
            getOptionValueTitle="fullNameClient"
            placeholder={t('Select an client')}
            disable={loadingSubmit || clientsLoading || disabledClientsField}
            data={isListToArray(clients as any)}
            onSearch={(keywords) =>
              handleSearchClients({
                keywords,
                showLoading: false,
                limit: 100,
              }) as any
            }
            onChange={(client_uuid) => handlerUpdateFormState({client_uuid})}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          tooltip={t('Tariff model')}
          label={t('Tariff model')}
          name="company_abonement_uuid"
          rules={[
            {
              required: true,
              message: t('Tariff model must be specified.'),
            },
          ]}
          extra={
            formData?.manager_uuid || formData?.base_cost ? (
              <StyledDescriptionContainer>
                {formData?.manager_uuid ? (
                  <StyledDescription style={{whiteSpace: 'unset'}}>{`${t(
                    'Manager',
                  )}: ${formData?.manager_name};`}</StyledDescription>
                ) : null}

                {formData?.base_cost ? (
                  <StyledDescription style={{whiteSpace: 'unset'}}>{`${t(
                    'Base cost',
                  )}: ${defaultCompany?.currency_symbol} ${
                    formData?.base_cost
                  };`}</StyledDescription>
                ) : null}
              </StyledDescriptionContainer>
            ) : null
          }>
          <SearchSelect
            selectIfOnFirst={false}
            selectFirst={false}
            name="company_abonement_uuid"
            getOptionValueTitle="abon_title"
            placeholder={t('Select an abonement')}
            disable={
              loadingSubmit || abonementsLoading || disabledAbonementField
            }
            data={isListToArray(activeAbonementList as any)}
            onChange={handleChangeAbonement}
            onSearch={handleSearchAbonement}
          />
        </Form.Item>
      </Col>

      {showGroups ? (
        <Col span={24}>
          <Form.Item
            tooltip={t('Tariff model groups')}
            label={t('Tariff model groups')}
            name="abonement_group_uuid"
            rules={[
              {
                required: true,
                message: t('Tariff model groups must be specified.'),
              },
            ]}>
            <SearchSelect
              selectIfOnFirst
              selectFirst={false}
              name="abonement_group_uuid"
              getOptionValueTitle="group_title"
              placeholder={t('Select an tariff model group')}
              disable={
                loadingSubmit ||
                groupRefresh ||
                groupsLoading ||
                disabledAbonementGroupField
              }
              data={groupRefresh ? [] : isListToArray(groupList as any)}
              onChange={handleChangeGroup}
              onSearch={(keywords) =>
                handleSearchAbonementGroups({
                  keywords,
                  limit: 100,
                  showLoading: false,
                  abonementUUid: formData?.company_abonement_uuid,
                })
              }
            />
          </Form.Item>
        </Col>
      ) : null}

      <Col span={24}>
        <Form.Item
          label={t('Amount')}
          name="final_price"
          rules={[
            ({getFieldValue}) => ({
              validator(_, amount) {
                const value = amount.replace(/[a-zA-Z]+/g, '');
                const price = getFieldValue('price');

                if (!Number(value)) {
                  return Promise.reject(
                    new Error(t('The amount must be a number')),
                  );
                }

                if (Number(value) < 0) {
                  return Promise.reject(
                    new Error(t('The amount must be greater than 0')),
                  );
                }

                // if (
                //   Number(value) > 0 &&
                //   typeof Number(price || 0) === 'number'
                // ) {
                //   const discount =
                //     (100 * (Number(price || 0) - Number(value))) /
                //     Number(price || 0);
                //
                //   if (discount < 0) {
                //     return Promise.reject(
                //       new Error(
                //         t('The amount cannot be less than 0% discount'),
                //       ),
                //     );
                //   }
                //
                //   if (discount > 100) {
                //     return Promise.reject(
                //       new Error(
                //         t(
                //           'The amount of the discount cannot be more than 100%.',
                //         ),
                //       ),
                //     );
                //   }
                //
                //
                // }
                return Promise.resolve();
              },
            }),
          ]}
          extra={
            formData?.discount > 0 ? (
              <StyledDescription style={{whiteSpace: 'unset'}}>
                {`${t('Includes discount')} ${formData?.discount}%`}
              </StyledDescription>
            ) : null
          }>
          <Input
            addonBefore={defaultCompany?.currency_symbol}
            disabled={loadingSubmit}
            onChange={handleChangeAmount}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          tooltip={t('Start date')}
          label={t('Start date')}
          name="start_date"
          rules={[
            {
              required: true,
              message: t('Start date must be specified.'),
              type: 'date',
            },
          ]}
          extra={
            <StyledDescription style={{whiteSpace: 'unset'}}>
              {t('Abonement start date')}
            </StyledDescription>
          }>
          <DatePicker
            disabled={loadingSubmit}
            placeholder={t('Start date')}
            onChange={(start_date) => handlerUpdateFormState({start_date})}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          tooltip={t('Card number')}
          label={t('Card number')}
          name="card_number"
          extra={
            <StyledDescription style={{whiteSpace: 'unset'}}>
              {t('Abonement card that helps to identify the client')}
            </StyledDescription>
          }>
          <Input
            disabled={loadingSubmit}
            placeholder={t('Enter card number')}
            onChange={(e) =>
              handlerUpdateFormState({card_number: e.target.value})
            }
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item label={t('Note')} name="comment">
          <Input.TextArea
            allowClear
            autoSize={{minRows: 2, maxRows: 5}}
            disabled={loadingSubmit}
            placeholder={t('Enter your note')}
            onChange={(e) => handlerUpdateFormState({comment: e.target.value})}
          />
        </Form.Item>
      </Col>

      <FormSwitch
        disabled={loadingSubmit}
        loading={loadingSubmit}
        name="send_notification"
        label={t('Notify the Client about changes in the Subscription')}
        tooltipTitle={t('Notify the Client about changes in the Subscription3')}
        setFieldsValue={setFieldsValue}
        getFieldValue={getFieldValue}
        onChange={(send_notification) =>
          handlerUpdateFormState({send_notification})
        }
      />
    </Row>
  );
}
