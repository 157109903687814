import React from 'react';
import {Space} from 'antd';
import styled, {css} from 'styled-components';
import {Text} from '../../../Format';
import {FileUploader} from '../../common';
import {
  fileUploadStructure,
  ifElse,
  firstLetterToUppercase,
  getFirstLatter,
} from '../../../../../services/helpers';

export interface IOverviewHeaderProps {
  title: string;
  description?: string | React.ReactNode;
  image?: string;
  handleUploadImage?: (file: File) => Promise<string>;
  disabled?: boolean;
  extra?: React.ReactNode | React.ReactNode[];
  withoutUpload?: boolean;
  className?: string;
  withoutIndent?: boolean;
}

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
`;

const StyledSpace = styled(Space)<{
  $withoutUpload: boolean;
  $withoutIndent: boolean;
}>`
  ${({$withoutUpload}) =>
    !$withoutUpload &&
    css`
      margin-left: 20px;
    `}

  ${({$withoutIndent}) =>
    $withoutIndent &&
    css`
      gap: 0 !important;
    `}
`;

const StyledTitle = styled(Text)`
  font-size: 20px;
`;
const StyledDescription = styled(Text)`
  color: ${({theme}) => theme.colors.lightDisable};
`;

export function OverviewHeader({
  title,
  description,
  image,
  disabled,
  handleUploadImage,
  extra,
  withoutUpload = false,
  className,
  withoutIndent,
}: IOverviewHeaderProps): JSX.Element {
  return (
    <StyledWrapper className={className}>
      <StyledContainer className={className}>
        {withoutUpload ? null : (
          <FileUploader
            title={getFirstLatter(firstLetterToUppercase(title))}
            disabled
            onUpload={handleUploadImage}
            file={ifElse(!!image, fileUploadStructure(image as any), '')}
          />
        )}

        <StyledSpace
          $withoutIndent={!!withoutIndent}
          direction="vertical"
          size="small"
          $withoutUpload={withoutUpload}>
          <StyledTitle bold>{title}</StyledTitle>
          {React.isValidElement(description) ? (
            description
          ) : description ? (
            <StyledDescription>{description}</StyledDescription>
          ) : null}
        </StyledSpace>
      </StyledContainer>
      {extra || null}
    </StyledWrapper>
  );
}
