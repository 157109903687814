import React, {useRef} from 'react';
import {
  ProductModel,
  ProductStatus,
  StoreDocumentItemSaleType,
} from '../../../struture';
import styled, {css, useTheme} from 'styled-components';
import {Routes} from '../../../services/types';
import {useLocation, useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import {useAcl} from '../../../contex';
import {Link} from '../../../components/lib/DataDisplay';
import {StyledDescription, StyledTitle} from '../../../components/lib/Styled';
import {TbLockCancel} from 'react-icons/tb';
import {Tag} from 'antd';
import {
  LoadingOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import {useModal} from '../../../components/lib/libV2/hooks';
import {ProductTourView} from './ProductTourView';

export interface IProductDetailsViewProps {
  product: ProductModel | null;
  children?: React.ReactNode | React.ReactNode[];
  to?: string;
  onlyTitle?: boolean;
  withoutNavigate?: boolean;
  message?: string;
  item_product_returned_amount?: number;
  product_amount?: number;
  tagType?: StoreDocumentItemSaleType;
  price?: string;
  leftover?: number;
  withTour?: boolean;
  fontSize?: number;
}

const StyledSpace = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 5px;
`;

const StyledLink = styled(Link)<{$color?: string; $fontSize?: number}>`
  align-items: center;

  ${({$color}) =>
    $color &&
    css`
      color: ${$color};
    `}

  ${({$fontSize}) =>
    $fontSize &&
    css`
      font-size: ${`${$fontSize}px`};
    `}
`;

const StyledTitleContainer = styled.div<{
  $isLineThrough: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 5px;

  ${({$isLineThrough}) =>
    $isLineThrough &&
    css`
      text-decoration: line-through;
    `}
`;

const StyledCategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  width: fit-content;
`;

const StyledLineThroughDescription = styled(StyledDescription)<{
  $isLineThrough: boolean;
}>`
  ${({$isLineThrough}) =>
    $isLineThrough &&
    css`
      text-decoration: line-through;
    `}
`;

const StyledTag = styled(Tag)`
  display: flex;
  gap: 10px;

  width: fit-content;
  cursor: pointer;
`;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  color: ${({theme}) => theme.colors.primary};
`;

const StyledWarningOutlined = styled(WarningOutlined)`
  color: ${({theme}) => theme.colors.warning};
`;

const StyledCloseCircleOutlined = styled(CloseCircleOutlined)`
  color: ${({theme}) => theme.colors.error};
`;

const StyledCheckCircleOutlined = styled(CheckCircleOutlined)`
  color: ${({theme}) => theme.colors.success};
`;

export function ProductDetailsView({
  product,
  to,
  onlyTitle,
  withoutNavigate = false,
  message,
  item_product_returned_amount,
  product_amount,
  tagType,
  price,
  leftover,
  withTour,
  fontSize,
}: IProductDetailsViewProps): JSX.Element {
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const {manage} = useAcl(({product}) => product);
  const theme: any = useTheme();

  const contentRef = useRef(null);

  const {visible, handleOnInit, handleCancel} = useModal();

  const returnedAmount = item_product_returned_amount || 0;
  const productAmount = product_amount || 0;
  const isReturnedAmount = returnedAmount > 0 && productAmount > 0;

  const isLessThen = returnedAmount < productAmount;
  const isGrateOrEqualThen = returnedAmount >= productAmount;

  const saleTagColors = {
    [StoreDocumentItemSaleType.NOT_FOUND_ERROR]: theme.colors.warning,
    [StoreDocumentItemSaleType.API_ERROR]: theme.colors.error,
    [StoreDocumentItemSaleType.LOADING]: '',
    [StoreDocumentItemSaleType.SUCCESS]: '',
  };

  const saleTagIcons = {
    [StoreDocumentItemSaleType.NOT_FOUND_ERROR]: <StyledWarningOutlined />,
    [StoreDocumentItemSaleType.API_ERROR]: <StyledCloseCircleOutlined />,
    [StoreDocumentItemSaleType.LOADING]: <StyledLoadingOutlined />,
    [StoreDocumentItemSaleType.SUCCESS]: <StyledCheckCircleOutlined />,
  };

  return (
    <>
      {product ? (
        <StyledSpace>
          <StyledTitleWrapper ref={contentRef}>
            {product?.product_status === ProductStatus.ENABLE ||
            tagType ? null : (
              <TbLockCancel
                className="product-list--icon"
                fontSize={14}
                color={theme.colors.error}
              />
            )}

            {onlyTitle || !tagType ? null : saleTagIcons[tagType]}

            {manage && !withoutNavigate ? (
              <StyledLink
                $fontSize={fontSize}
                $color={saleTagColors[tagType!]}
                onClick={(e) => {
                  e?.preventDefault();
                  e?.stopPropagation();

                  if (withTour) {
                    handleOnInit();
                  } else {
                    navigate(
                      to ||
                        `/${Routes.app}/${Routes.goods}/${Routes.products}/${product?.uuid}`,
                      {state: {goBack: location.pathname}},
                    );
                  }
                }}
                className="product-list--link"
                to={
                  to ||
                  `/${Routes.app}/${Routes.goods}/${Routes.products}/${product?.uuid}`
                }
                tooltip={
                  withTour
                    ? t('Open details of product')
                    : t('Go to material details page')
                }
                state={{goBack: location.pathname}}>
                <StyledTitleContainer
                  className="product-list--link"
                  $isLineThrough={isReturnedAmount && isGrateOrEqualThen}>
                  {product?.product_title}
                </StyledTitleContainer>
              </StyledLink>
            ) : (
              <StyledTitle fontSize={fontSize} $color={saleTagColors[tagType!]}>
                {product?.product_title}
              </StyledTitle>
            )}
          </StyledTitleWrapper>
          {onlyTitle || !product?.category?.title ? null : (
            <StyledCategoryContainer>
              {/*<MdShelves color={theme.colors.textSecondary} size={13} />*/}
              <StyledLineThroughDescription
                $isLineThrough={isReturnedAmount && isGrateOrEqualThen}>
                {product?.category?.parent?.uuid ? (
                  <Tag color={'green'} key={product?.category?.parent?.uuid}>
                    {product?.category?.parent?.title}
                  </Tag>
                ) : null}
                <Tag color={'blue'} key={product?.category?.uuid}>
                  {product?.category?.title}
                </Tag>
              </StyledLineThroughDescription>
            </StyledCategoryContainer>
          )}

          {onlyTitle || !message ? null : (
            <StyledDescription>{t(message)}</StyledDescription>
          )}

          {onlyTitle || !(isReturnedAmount && isReturnedAmount) ? null : (
            <StyledTag
              color={
                isLessThen
                  ? theme.colors.warning
                  : isGrateOrEqualThen
                  ? theme.colors.error
                  : undefined
              }>
              {t('Returned').toLowerCase()}
            </StyledTag>
          )}
        </StyledSpace>
      ) : null}
      {visible ? (
        <ProductTourView
          visible={visible}
          handleCancel={handleCancel}
          product={product}
          ref={contentRef}
          price={price}
          leftover={leftover}
        />
      ) : null}
    </>
  );
}
