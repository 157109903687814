import React, {useCallback, useEffect} from 'react';
import {Col, Empty, Form, Input, InputNumber, Row} from 'antd';
import {withTranslation, WithTranslation} from 'react-i18next';
import styled from 'styled-components';
import {List} from 'immutable';
import {SearchSelect} from '../../../components/lib/DataDisplay';
import {
  isListToArray,
  eq,
  multiply,
  correctPrice,
} from '../../../services/helpers';
import {ServiceModel} from '../../../struture';
import {useDefaultForm} from '../../../contex';
import {useStoredCompanies} from '../../../hooks';

export default withTranslation()(RelatedServicesFields);

export interface IRelatedServicesFieldsProps extends WithTranslation {
  servicesLoading: boolean;
  services: List<ServiceModel> | null;
  loading: boolean;
  serviceUuid?: string;
}

const FullWidthInputNumber = styled(InputNumber)`
  width: 100%;
`;

const FullWidthInputNumberDisabled = styled(FullWidthInputNumber)`
  color: ${({theme}) => theme.colors.text};
  .ant-input-number-input {
    font-weight: 700;
  }
`;

function RelatedServicesFields({
  t,
  servicesLoading,
  services,
  loading,
  serviceUuid,
}: IRelatedServicesFieldsProps): JSX.Element {
  const {loadingSubmit, setFieldsValue, getFieldValue} = useDefaultForm();

  const {defaultCompany} = useStoredCompanies();

  const handleUpdateService = useCallback(
    (value: string): void => {
      if (List.isList(services)) {
        const service = services.find(({uuid}) => eq(uuid, value));

        if (service) {
          const quantity = getFieldValue('service_quantity');

          setFieldsValue({
            service_price: correctPrice(service?.price),
            total: correctPrice(
              multiply(Number(service?.price), Number(quantity)),
            ),
          });
        }
      }
    },
    [getFieldValue, services, setFieldsValue],
  );

  const handleUpdateQuantity = useCallback(
    (quantity: number): void => {
      const servicePrice = getFieldValue('service_price');

      setFieldsValue({
        total: correctPrice(multiply(Number(servicePrice), Number(quantity))),
      });
    },
    [getFieldValue, setFieldsValue],
  );

  const handleUpdatePrice = useCallback(
    (price: string): void => {
      const quantity = getFieldValue('service_quantity');

      setFieldsValue({
        total: correctPrice(multiply(Number(price), Number(quantity))),
      });
    },
    [getFieldValue, setFieldsValue],
  );

  useEffect(() => {
    if (serviceUuid) {
      handleUpdateService(serviceUuid);
    }
  }, [handleUpdateService, serviceUuid]);

  useEffect(() => {
    const price = getFieldValue('service_price');

    if (price) {
      handleUpdatePrice(price);
    }
  }, [getFieldValue, handleUpdatePrice]);

  return (
    <Row gutter={20}>
      <Col span={24}>
        <Form.Item
          tooltip={t('Service of which there is a supplementary service')}
          label={t('Service')}
          name="service_uuid"
          rules={[
            {
              required: true,
              message: t('Service must be specified'),
            },
          ]}>
          <SearchSelect
            disable={servicesLoading}
            name="service_uuid"
            placeholder={t('Select the service')}
            data={isListToArray(services as any)}
            onChange={handleUpdateService}
            notFoundContent={
              <Empty
                description={t(
                  'All available services have already been added to the Order',
                )}
              />
            }
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label={t('Price')}
          name="service_price"
          rules={[
            {
              required: true,
              message: t('Price must be specified'),
            },
            {
              transform: (value) => Number(value),
              type: 'number',
              message: t('Price must be a number.'),
            },
          ]}>
          <Input
            addonBefore={defaultCompany?.currency_symbol}
            disabled={loading || loadingSubmit}
            placeholder={t('Enter related service price')}
            onChange={(e) => handleUpdatePrice(e.target.value)}
          />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          tooltip={t('Quantity')}
          label={t('Quantity')}
          name="service_quantity"
          rules={[
            {
              required: true,
              message: t('Quantity must be specified'),
            },
          ]}>
          <FullWidthInputNumber
            type="number"
            data-testid="relates-service__input--quantity"
            disabled={loading || loadingSubmit}
            placeholder={t('Enter related service quantity')}
            onChange={handleUpdateQuantity as any}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item label={t('Total')} name="total">
          <Input
            addonBefore={defaultCompany?.currency_symbol}
            disabled
            data-testid="relates-service__input--total"
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item label={t('Comment')} name="comment">
          <Input.TextArea
            allowClear
            autoSize={{minRows: 2, maxRows: 5}}
            data-testid="relates-service__input--comment"
            disabled={loading || loadingSubmit}
            placeholder=""
          />
        </Form.Item>
      </Col>
    </Row>
  );
}
