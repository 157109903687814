import {Record} from 'immutable';
import {WorkScheduleDTO} from '../internal';

export interface IWorkScheduleReturnType {
  uuid: string;
  title: string;
  description: string;
  rest_days: number;
  is_default: number;
  work_days: number;
  pos: number;
  status: number;
  created_at: string;
  updated_at: string;
}

/**
 * @class WorkScheduleModel
 * @classdesc Work schedule model
 */
export class WorkScheduleModel extends Record<IWorkScheduleReturnType>({
  uuid: '',
  title: '',
  description: '',
  rest_days: 0,
  is_default: 0,
  work_days: 0,
  pos: 0,
  status: 0,
  created_at: '',
  updated_at: '',
}) {
  /**
   *
   * @constructor
   * @param {{}} props
   */
  constructor(props: WorkScheduleDTO = {} as WorkScheduleDTO) {
    super({...props});
  }
}
