import React from 'react';
import {useTranslation} from 'react-i18next';
import {IConfirmActionButtonProps} from '../../../components/lib/General';
import {TextFormat} from '../../../components/lib/Format';
import {len} from '../../../services/helpers';
import {useDelete} from '../../../components/lib/libV2/hooks';
import {RewardModel} from '../../../struture';
import {DeleteButton} from '../../../components/lib/DataDisplay';

export interface IRewardDeleteButtonProps
  extends Omit<IConfirmActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: string[]) => Promise<void>;
  onError?: () => void;
  rewards: RewardModel[];
}

export function RewardDeleteButton({
  children,
  rewards,
  onSuccess,
  onError,
  title,
  ...rest
}: IRewardDeleteButtonProps): JSX.Element {
  const {t} = useTranslation();

  const {handleDelete, loading} = useDelete({
    onSuccess,
    entity: rewards,
    notifyTitle: 'Reward',
  });

  return (
    <DeleteButton
      title={title}
      tooltipTitle={t('' + '')}
      confirmTitle={
        <TextFormat breakWord>
          {t(`Are you sure you want to delete `)}
          <b>{`${len(rewards)} ${
            len(rewards)! > 1 ? t('rewards') : t('reward')
          }`}</b>
        </TextFormat>
      }
      onConfirm={handleDelete}
      loading={loading}
      cancelButtonText={t('Cancel')}
      {...rest}
    />
  );
}
