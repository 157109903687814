import {Record, List} from 'immutable';
import {IListProps} from './List';
import {MailingTemplateDTO, MailingTemplateModel} from '../internal';
import {WithoutRecursion} from '../type';

export interface IMailingTemplateListModelProps extends IListProps {
  templates: MailingTemplateDTO[];
}

export interface IMailingTemplateListModelReturnType extends IListProps {
  templates: List<MailingTemplateModel>;
}

export class MailingTemplateListModel extends Record<IMailingTemplateListModelReturnType>(
  {
    templates: List(),
    total: 0,
  },
) {
  constructor(
    {
      templates = [],
      ...props
    }: IMailingTemplateListModelProps = {} as IMailingTemplateListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    super({
      ...props,
      templates: List(
        templates.map(
          (template) => new MailingTemplateModel(template, withoutRecursion),
        ),
      ),
    });
  }
}
