import {
  ClientModel,
  StoreDocumentMapper,
  StoreDocumentModel,
  StoreDocumentStatus,
  StoreDocumentType,
  StoreModel,
} from '../internal';
import {Moment} from 'moment';
import {toMoment, listToArray} from '../../helpers';
import {StoreDocumentItemFormDTO} from './StoreDocumentItemFormDTO';

export interface IStoreDocumentAdditionalParams {
  editMode?: boolean;
  is_payment?: boolean;
}

export class StoreDocumentFormDTO {
  public uuid: string;

  public doc_store_uuid: StoreModel | string;

  public doc_destination_store_uuid: StoreModel | string;

  public doc_client_uuid: ClientModel | string;

  public doc_input_number: string;

  public doc_related_document_uuid: string;

  public doc_date: string | Moment;

  public doc_comment: string;

  public doc_type: StoreDocumentType;

  public doc_status: StoreDocumentStatus;

  public doc_items: StoreDocumentItemFormDTO[];

  public is_payment?: boolean;

  constructor(
    props: StoreDocumentModel,
    {editMode, is_payment}: IStoreDocumentAdditionalParams,
  ) {
    this.uuid = props?.uuid || '';
    this.doc_input_number = props?.doc_input_number || '';
    this.doc_date = props?.doc_date
      ? toMoment(props?.doc_date)
      : toMoment(new Date());
    this.doc_comment = props?.doc_comment || '';
    this.doc_items = listToArray(
      props?.doc_items?.map((item) =>
        StoreDocumentMapper.toStoreDocumentItemFormDTO(item, {
          editMode: !!editMode,
        }),
      ),
    );
    this.doc_related_document_uuid = ''; //props?.doc_related_document_uuid || '';
    this.doc_type = props?.doc_type || StoreDocumentType.IN;
    this.doc_status = props?.doc_status_text || StoreDocumentStatus.DRAFT;
    this.doc_store_uuid = props?.companyStoreModel?.uuid
      ? props?.companyStoreModel
      : '';
    this.doc_destination_store_uuid = props?.companyDestinationStoreModel?.uuid
      ? props?.companyDestinationStoreModel
      : '';
    this.doc_client_uuid = props?.clientModel?.uuid ? props?.clientModel : '';

    this.is_payment = typeof is_payment === 'boolean' ? is_payment : true;
  }
}
