import {List} from 'immutable';
import {
  GalleryDTO,
  IGalleryDTOProps,
  GalleryModel,
  GalleryListModel,
} from '../internal';
import {listToArray} from '../../helpers';

export interface IGalleryListDTO {
  gallery: GalleryDTO[];
  total: number;
}

export class GalleryMapper {
  public static toGalleryDTO(
    props: GalleryModel | IGalleryDTOProps,
  ): GalleryDTO {
    return new GalleryDTO({
      uuid: props?.uuid,
      title: props?.title,
      description: props?.description,
      path_url: props?.path_url,
      status: props?.status,
      created_at: props?.created_at,
      updated_at: props?.updated_at,
    });
  }

  public static toGalleryListDTO(
    gallery: List<GalleryModel> | IGalleryDTOProps[],
    total?: number,
  ): IGalleryListDTO {
    const galleryList = List.isList(gallery)
      ? listToArray<GalleryModel>(gallery)
      : gallery;

    return {
      gallery: galleryList?.map((gallery) =>
        GalleryMapper.toGalleryDTO(gallery),
      ),
      total: total || 0,
    };
  }

  public static toGalleryModel(galleryDTO: GalleryDTO): GalleryModel {
    return new GalleryModel(galleryDTO);
  }

  public static toGalleryListModel(
    galleryDTOs: GalleryDTO[],
    total: number,
  ): GalleryListModel {
    return new GalleryListModel({gallery: galleryDTOs, total});
  }
}
