import * as React from 'react';
import {List} from 'immutable';
import {
  useProductStoreDocumentList,
  IUseProductStoreDocumentListProps,
  IUseProductStoreDocumentListReturnType,
} from './useProductStoreDocumentList';
import {StoreDocumentModel} from '../struture';
import {useStateEntityList} from '../components/lib/libV2/hooks';

export interface IUseStateProductStoreDocumentListProps
  extends IUseProductStoreDocumentListProps {}

export interface IUseStateProductStoreDocumentListReturnType
  extends Omit<IUseProductStoreDocumentListReturnType, 'entityList'> {
  documentList: List<StoreDocumentModel> | null;
  handleSearchProductStoreDocuments: (
    value: Partial<IUseProductStoreDocumentListProps> & {page?: number},
  ) => Promise<void>;
  page: number;
}

export function useStateProductStoreDocumentList(
  {
    productUuid,
    ...rest
  }: IUseStateProductStoreDocumentListProps = {} as IUseStateProductStoreDocumentListProps,
): IUseStateProductStoreDocumentListReturnType {
  const [page, setPage] = React.useState(1);

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: documentListLoading,
    total: documentListListTotal,
    ...documentListsParams
  } = useProductStoreDocumentList({
    productUuid,
    ...rest,
  });

  const {
    entityList: documentList,
    loading,
    total,
  } = useStateEntityList<StoreDocumentModel>({
    entityList,
    refresh,
    limit,
    offset,
    total: documentListListTotal,
  });

  const handleSearchProductStoreDocuments = React.useCallback(
    async ({
      keywords,
      showLoading = true,
      limit = 10,
      page,
      ...rest
    }: Partial<IUseProductStoreDocumentListProps> & {page?: number}) => {
      await refresh({
        offset: 0,
        keywords,
        showLoading,
        limit,
        ...rest,
      });

      if (page) {
        setPage(page);
      }
    },
    [refresh],
  );

  return {
    documentList,
    offset,
    limit,
    refresh,
    total,
    loading: documentListLoading || loading,
    ...documentListsParams,
    handleSearchProductStoreDocuments,
    page,
  };
}
