import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IDefaultFormProps} from '../../../components/lib/General';
import {useDropdownAlert} from '../../../contex';
import {WizardFormV2} from '../../../components/lib/Navigation';
import {
  ISendFieldsMappingProps,
  uploadClientListFile,
} from '../../../services/api/client';
import {ClientFileImportSelectFields} from './ClientFileImportSelectFields';
import {ClientFileImportSettingFields} from './ClientFileImportSettingFields';
import {Form} from 'antd';
import {isFunction} from '../../../services/helpers';

export interface IClientFileImportFormProps
  extends Omit<
    IDefaultFormProps<ISendFieldsMappingProps, ISendFieldsMappingProps>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
}

export enum ClientFileImportStep {
  SELECT = 'File selection',
  SETTING = 'Setting fields',
}

export function ClientFileImportForm({
  loading: initLoading,
  editMode,
  onCancel,
  onSuccess,
  ...rest
}: IClientFileImportFormProps) {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const [instance] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const fields = Form.useWatch('fields', instance);

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Import'),
        `${t('An error occurred during import')} : ${apiError?.message}`,
      );
    },
    [alert, t],
  );

  const handleUploadFileList = useCallback(
    async (xlsFile: File) => {
      setLoading(true);
      try {
        const fields = await uploadClientListFile(xlsFile);
        setLoading(false);
        return fields;
      } catch (error: any) {
        notifyError(error);
        setLoading(false);
      }
    },
    [notifyError],
  );

  const validatedFields = fields?.map((_: any, index: number) => [
    ['fields', index, 'from'],
    ['fields', index, 'to'],
  ]);

  const handleOnSuccess = useCallback(
    async ({clientsValueFields, uuid}: any) => {
      const mappingFields = (fields || [])?.map(({from, to}: any) => {
        const clientField = (clientsValueFields || [])?.find(
          ({title}: any) => title === from,
        );

        if (clientField) {
          return {
            from: clientField?.name,
            to,
          };
        }
        return {
          from,
          to,
        };
      });

      if (isFunction(onSuccess)) {
        await onSuccess({fields: mappingFields, uuid});
      }
    },
    [fields, onSuccess],
  );

  const handleChangeActiveIndex = useCallback((index: number) => {
    setActiveIndex(index);
  }, []);

  return (
    <WizardFormV2<any, any>
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
      instance={instance}
      activeKey={ClientFileImportStep.SELECT}
      initialValues={{}}
      loading={initLoading}
      isClickableSteps
      doneButtonText={t('Create')}
      showNotify={false}
      onSuccess={handleOnSuccess}
      nextButtonDisabled={loading}
      notifyError={notifyError}
      {...rest}>
      <WizardFormV2.Step
        key={ClientFileImportStep.SELECT}
        title={t(ClientFileImportStep.SELECT)}
        loading={false}
        checkFieldsOnStepChange={['file']}>
        <ClientFileImportSelectFields
          handleUploadFileList={handleUploadFileList}
          handleChangeActiveIndex={handleChangeActiveIndex}
        />
      </WizardFormV2.Step>
      <WizardFormV2.Step
        checkFieldsOnStepChange={validatedFields}
        key={ClientFileImportStep.SETTING}
        title={t(ClientFileImportStep.SETTING)}
        loading={false}>
        <ClientFileImportSettingFields fields={fields} />
      </WizardFormV2.Step>
    </WizardFormV2>
  );
}
