import {
  ClientDTO,
  IClientDTOProps,
  CompanyDTO,
  ICompanyDTOProps,
  IProfileSubscriptionDTOProps,
  ProfileSubscriptionDTO,
  ClientMapper,
  CompanyMapper,
  IProfileModelReturnType,
  AclMapper,
  IAclDTOMap,
  IAclProp,
  IEmployeeDTOProps,
  EmployeeDTO,
  EmployeeMapper,
  ActiveSubscriptionDTO,
  IActiveSubscriptionDTOProps,
  ActiveSubscriptionMapper,
} from '../internal';
import {WithoutRecursion, GENDER} from '../type';
import {toDateByFormat} from '../date';
import {DATE_SHORT_FORMAT, aclFallback} from '../type';

export interface IProfileDTOProps {
  id: string;
  uuid: string;
  status: number;
  balance: number;
  first_name: string;
  last_name: string;
  middle_name: string;
  is_admin: boolean;
  phone: string;
  phone_approved: number;
  email: string;
  email_approved: number;
  picture_url: string;
  checkout_url: string;
  address: string;
  birthday: string;
  acl: IAclProp;
  gender: GENDER;
  created_clients: IClientDTOProps[];
  created_at: string;
  updated_at: string;
  companies: ICompanyDTOProps[];
  default_company: ICompanyDTOProps;
  default_employee: IEmployeeDTOProps;
  profile_subscriptions: IProfileSubscriptionDTOProps[];
  active_subscription: IActiveSubscriptionDTOProps;
}

export class ProfileDTO {
  public id: string;

  public uuid: string;

  public status: number;

  public balance: number;

  public first_name: string;

  public last_name: string;

  public middle_name: string;

  public is_admin: boolean;

  public phone: string;

  public phone_approved: number;

  public email: string;

  public email_approved: number;

  public picture_url: string;

  public address: string;

  public checkout_url: string;

  public birthday: string;

  public gender: GENDER;

  public created_clients: ClientDTO[];

  public acl: IAclDTOMap;

  public created_at: string;

  public updated_at: string;

  public companies: CompanyDTO[];

  public default_company: CompanyDTO;

  public default_employee: EmployeeDTO;

  public profile_subscriptions: ProfileSubscriptionDTO[];

  public active_subscription: ActiveSubscriptionDTO;

  constructor(
    props: IProfileModelReturnType | IProfileDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.id = props?.id || '';
    this.uuid = props?.uuid || '';
    this.first_name = props?.first_name || '';
    this.last_name = props?.last_name || '';
    this.middle_name = props?.middle_name || '';
    this.phone = props?.phone || '';
    this.email = props?.email || '';
    this.picture_url = props?.picture_url || '';
    this.address = props?.address || '';
    this.birthday = props?.birthday
      ? toDateByFormat(props?.birthday, DATE_SHORT_FORMAT)
      : '';
    this.created_at = props?.created_at || '';
    this.checkout_url = props?.checkout_url || '';
    this.updated_at = props?.updated_at || '';
    this.status = props?.status || 0;
    this.balance = props?.balance || 0;
    this.phone_approved = props?.phone_approved || 0;
    this.email_approved = props?.email_approved || 0;
    this.is_admin = props?.is_admin || false;
    this.gender = props?.gender || GENDER.MALE;

    this.active_subscription = ActiveSubscriptionMapper.toActiveSubscriptionDTO(
      props?.active_subscription || ({} as IActiveSubscriptionDTOProps),
    );

    this.acl = AclMapper.toAclMapDTO(props?.acl || aclFallback);

    this.profile_subscriptions = (props?.profile_subscriptions || []).map(
      (subscription) => new ProfileSubscriptionDTO(subscription as any),
    ) as any;

    this.created_clients =
      withoutRecursion.indexOf(WithoutRecursion.client) !== -1
        ? ((props?.created_clients || []) as ClientDTO[])
        : ClientMapper.toClientListDTO({
            clients: props?.created_clients || [],
            total: 0,
            withoutRecursion: [WithoutRecursion.profile, ...withoutRecursion],
          }).clients;
    this.companies =
      withoutRecursion.indexOf(WithoutRecursion.company) !== -1
        ? ((props?.companies || []) as CompanyDTO[])
        : CompanyMapper.toCompanyListDTO(props?.companies || [], 0, [
            WithoutRecursion.profile,
            ...withoutRecursion,
          ]).companies;
    this.default_company =
      withoutRecursion.indexOf(WithoutRecursion.company) !== -1
        ? (props?.default_company as CompanyDTO)
        : CompanyMapper.toCompanyDTO(props?.default_company, [
            WithoutRecursion.profile,
            ...withoutRecursion,
          ]);

    this.default_employee =
      withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
        ? (props?.default_employee as EmployeeDTO)
        : EmployeeMapper.toEmployeeDTO(props?.default_employee, [
            WithoutRecursion.profile,
            ...withoutRecursion,
          ]);
  }
}
