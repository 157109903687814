import {SystemSubscriptionList, SystemSubscription} from '../models';
import {apiGet, apiPost} from '../core/api';
import {contentApiUrl} from '../const';
import {ApiAnswer} from '../types';
import {ISystemSubscriptionProps} from '../helpers';

export interface ISystemSubscriptionParam {
  activation_date: string;
  price?: string | number;
  subscription_key: string;
  subscription_price?: string | number;
}

export interface ISystemSubscriptionPaymentParam
  extends Omit<ISystemSubscriptionParam, 'activation_date'> {}

export const SUBSCRIPTION_INITIAL_DATA: ISystemSubscriptionParam = {
  activation_date: '',
  subscription_price: '',
  subscription_key: '',
};

export const SUBSCRIPTION_PAYMENT_INITIAL_DATA: ISystemSubscriptionPaymentParam =
  (({
    activation_date,
    price,
    ...rest
  }: ISystemSubscriptionParam): ISystemSubscriptionPaymentParam => ({
    ...rest,
  }))(SUBSCRIPTION_INITIAL_DATA);

export interface IGetSystemSubscriptionListProps {
  limit?: number;
  offset?: number;
}

export interface ISystemSubscriptionsAnswer extends ApiAnswer {
  subscriptions: ISystemSubscriptionProps[];
}

export async function getSystemSubscriptionList({
  limit = 10,
  offset = 0,
}: IGetSystemSubscriptionListProps): Promise<SystemSubscriptionList> {
  const props = await apiGet<null, ISystemSubscriptionsAnswer>(
    `${contentApiUrl}/system/subscriptions?limit=${limit}&offset=${offset}`,
  );

  return new SystemSubscriptionList((props as any) || {});
}

const systemSubscription = {
  status_txt: 'REQUESTED',
  subscription_key: 'starter',
  subscription_price: '0.00',
  subscription_title: 'Starter',
  subscription_description: 'The basic subscription for all users',
  rule_limitations: [
    {
      system_rule_limit: 1,
      system_rule: {
        rule_title: 'Create company',
        rule_description: 'How many companies can be created?',
        created_at: '2021-08-01T01:32:38+03:00',
        updated_at: '2021-08-01T01:32:38+03:00',
      },
    },
    {
      system_rule_limit: 5,
      system_rule: {
        rule_title: 'Create client',
        rule_description: 'How many clients can be created?',
        created_at: '2021-08-01T01:32:38+03:00',
        updated_at: '2021-08-01T01:32:38+03:00',
      },
    },
    {
      system_rule_limit: 2,
      system_rule: {
        rule_title: 'Create employee',
        rule_description: 'How many employee can be created?',
        created_at: '2021-08-01T01:32:38+03:00',
        updated_at: '2021-08-01T01:32:38+03:00',
      },
    },
    {
      system_rule_limit: 20,
      system_rule: {
        rule_title: 'Create order',
        rule_description: 'How many orders can be created?',
        created_at: '2021-08-01T01:32:38+03:00',
        updated_at: '2021-08-01T01:32:38+03:00',
      },
    },
    {
      system_rule_limit: 5,
      system_rule: {
        rule_title: 'Create service',
        rule_description: 'How many services can be created?',
        created_at: '2021-08-01T01:32:38+03:00',
        updated_at: '2021-08-01T01:32:38+03:00',
      },
    },
    {
      system_rule_limit: 0,
      system_rule: {
        rule_title: 'Create report',
        rule_description: 'How many reports can be created?',
        created_at: '2021-08-01T01:32:38+03:00',
        updated_at: '2021-08-01T01:32:38+03:00',
      },
    },
  ],
};

export async function activateSubscription({
  price,
  activation_date,
  subscription_key,
}: ISystemSubscriptionParam): Promise<SystemSubscription> {
  // const props = await apiPost<null, ISystemSubscriptionsAnswer>(
  //   `${contentApiUrl}/system/subscriptions?limit=${limit}&offset=${offset}`,
  // );

  return new SystemSubscription(systemSubscription);
}

const paymentSubscriptionData = {
  status_txt: 'ACTIVE',
  subscription_key: 'business',
  subscription_price: '300.00',
  subscription_title: 'Business',
  subscription_description: 'The basic subscription for all users',
  rule_limitations: [
    {
      system_rule_limit: 1,
      system_rule: {
        rule_title: 'Create company',
        rule_description: 'How many companies can be created?',
        created_at: '2021-08-01T01:32:38+03:00',
        updated_at: '2021-08-01T01:32:38+03:00',
      },
    },
    {
      system_rule_limit: 5,
      system_rule: {
        rule_title: 'Create client',
        rule_description: 'How many clients can be created?',
        created_at: '2021-08-01T01:32:38+03:00',
        updated_at: '2021-08-01T01:32:38+03:00',
      },
    },
    {
      system_rule_limit: 2,
      system_rule: {
        rule_title: 'Create employee',
        rule_description: 'How many employee can be created?',
        created_at: '2021-08-01T01:32:38+03:00',
        updated_at: '2021-08-01T01:32:38+03:00',
      },
    },
    {
      system_rule_limit: 20,
      system_rule: {
        rule_title: 'Create order',
        rule_description: 'How many orders can be created?',
        created_at: '2021-08-01T01:32:38+03:00',
        updated_at: '2021-08-01T01:32:38+03:00',
      },
    },
    {
      system_rule_limit: 5,
      system_rule: {
        rule_title: 'Create service',
        rule_description: 'How many services can be created?',
        created_at: '2021-08-01T01:32:38+03:00',
        updated_at: '2021-08-01T01:32:38+03:00',
      },
    },
    {
      system_rule_limit: 0,
      system_rule: {
        rule_title: 'Create report',
        rule_description: 'How many reports can be created?',
        created_at: '2021-08-01T01:32:38+03:00',
        updated_at: '2021-08-01T01:32:38+03:00',
      },
    },
  ],
};

export async function paySubscription({
  subscription_price,
  subscription_key,
}: ISystemSubscriptionPaymentParam): Promise<SystemSubscription> {
  // const props = await apiPost<null, ISystemSubscriptionsAnswer>(
  //   `${contentApiUrl}/system/subscriptions?limit=${limit}&offset=${offset}`,
  // );

  return new SystemSubscription(paymentSubscriptionData);
}
