import {Record} from 'immutable';
import {
  ClientAbonementModel,
  EmployeeDTO,
  ClientVisitDTO,
  EmployeeMapper,
  EmployeeModel,
  ClientAbonementDTO,
  ClientAbonementMapper,
  IClientAbonementLatestStatusDTOProps,
} from '../internal';
import {WithoutRecursion} from '../type';

export interface IClientVisitModelReturnType {
  uuid: string;
  visit_date: string;
  visit_comment: string;
  created_at: string;
  updated_at: string;
  client_abonement: ClientAbonementModel | ClientAbonementDTO;
  manager: EmployeeModel | EmployeeDTO;
}

export class ClientVisitModel extends Record<IClientVisitModelReturnType>({
  uuid: '',
  visit_date: '',
  visit_comment: '',
  created_at: '',
  updated_at: '',
  client_abonement: {} as ClientAbonementModel,
  manager: {} as EmployeeModel,
}) {
  constructor(
    props: ClientVisitDTO = {} as ClientVisitDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IClientVisitModelReturnType = {
      ...props,

      client_abonement:
        withoutRecursion.indexOf(WithoutRecursion.abonement) !== -1
          ? props?.client_abonement
          : ClientAbonementMapper.toClientAbonementModel(
              props?.client_abonement,
              {} as IClientAbonementLatestStatusDTOProps,
              [WithoutRecursion.clientAbonement, ...withoutRecursion],
            ).abonement,

      manager:
        withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
          ? props?.manager
          : EmployeeMapper.toEmployeeModel(props?.manager, [
              WithoutRecursion.abonement,
              ...withoutRecursion,
            ]),
    };

    super(options);
  }

  get clientAbonementModel(): ClientAbonementModel {
    return this.client_abonement instanceof ClientAbonementModel
      ? this.client_abonement
      : ClientAbonementMapper.toClientAbonementModel(this.client_abonement)
          .abonement;
  }

  get managerModel(): EmployeeModel {
    return this.manager instanceof EmployeeModel
      ? this.manager
      : EmployeeMapper.toEmployeeModel(this.manager);
  }
}
