import {List, Record} from 'immutable';
import {
  GroupPaymentDTO,
  CompanyDTO,
  CompanyModel,
  CompanyMapper,
} from '../internal';
import {WithoutRecursion} from '../type';

export interface IGroupPaymentModelReturnType {
  uuid: string;
  title: string;
  description: string;
  updated_at: string;
  created_at: string;
  companies: List<CompanyModel> | CompanyDTO[];
}

export class GroupPaymentModel extends Record<IGroupPaymentModelReturnType>({
  uuid: '',
  title: '',
  description: '',
  updated_at: '',
  created_at: '',
  companies: List(),
}) {
  private _keys: any;

  constructor(
    props: GroupPaymentDTO = {} as GroupPaymentDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IGroupPaymentModelReturnType = {
      ...props,
      companies: withoutRecursion.indexOf(WithoutRecursion.company)
        ? props?.companies
        : CompanyMapper.toCompanyListModel(props?.companies || [], 0, [
            WithoutRecursion.groupPayment,
            ...withoutRecursion,
          ]).companies,
    };

    super(options);
  }

  get companyListModel(): List<CompanyModel> {
    return List.isList(this.companies)
      ? this.companies
      : CompanyMapper.toCompanyListModel(this.companies).companies;
  }
}
