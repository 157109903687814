import {List} from 'immutable';
import {
  ProductCategoryDTO,
  ProductCategoryFormDTO,
  IProductCategoryDTOProps,
  ProductCategoryModel,
  ProductCategoryListModel,
  IProductsListStatsProps,
  IProductCategoryAdditional,
} from '../internal';
import {listToArray} from '../../helpers';
import {WithoutRecursion} from '../type';

export interface IProductCategoryListStatsProps {}

export interface IProductCategoryListDTO {
  categories: ProductCategoryDTO[];
  total: number;
  stats?: IProductsListStatsProps;
  parentCategory?: ProductCategoryModel;
}

export class ProductCategoryMapper {
  public static toProductCategoryDTO(
    category: ProductCategoryModel | IProductCategoryDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): ProductCategoryDTO {
    return new ProductCategoryDTO(category, [
      WithoutRecursion.category,
      ...withoutRecursion,
    ]);
  }

  public static toProductCategoryListDTO(
    categories: List<ProductCategoryModel> | IProductCategoryDTOProps[],
    total: number,
    stats?: IProductsListStatsProps,
    withoutRecursion: WithoutRecursion[] = [],
    parentCategory?: ProductCategoryModel,
  ): IProductCategoryListDTO {
    const categoryList = List.isList(categories)
      ? listToArray<ProductCategoryModel>(categories)
      : categories;

    return {
      categories: categoryList?.map((category) =>
        ProductCategoryMapper.toProductCategoryDTO(category, [
          WithoutRecursion.category,
          ...withoutRecursion,
        ]),
      ),
      total,
      stats,
      parentCategory,
    };
  }

  public static toProductCategoryModel(
    categoryDTO: ProductCategoryDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): ProductCategoryModel {
    return new ProductCategoryModel(categoryDTO, [
      WithoutRecursion.category,
      ...withoutRecursion,
    ]);
  }

  public static toProductCategoryListModel(
    categoryDTOs: ProductCategoryDTO[],
    total: number,
    withoutMembers: boolean = false,
    withoutRecursion: WithoutRecursion[] = [],
    stats?: IProductsListStatsProps,
    parentCategory?: ProductCategoryModel,
  ): ProductCategoryListModel {
    return new ProductCategoryListModel(
      {categories: categoryDTOs, total, stats, parentCategory},
      [WithoutRecursion.category, ...withoutRecursion],
    );
  }

  public static toProductCategoryFormDTO(
    categoryModel: ProductCategoryModel,
    additionalInformation?: IProductCategoryAdditional,
  ): ProductCategoryFormDTO {
    return new ProductCategoryFormDTO(categoryModel, additionalInformation);
  }
}
