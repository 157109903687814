export enum EMPLOYEE_ATTACHED_SERVICE_LIST {
  SET_EMPLOYEE_ATTACHED_SERVICE_LIST = 'set_employee_attached_service_list',
  LOADING_EMPLOYEE_ATTACHED_SERVICE_LIST = 'loading_employee_attached_service_list',
  ERROR_EMPLOYEE_ATTACHED_SERVICE_LIST = 'error_employee_attached_service_list',
  LOAD_MORE_EMPLOYEE_ATTACHED_SERVICE_LIST = 'load_more_employee_attached_service_list',
  ADD_ATTACHED_SERVICE = 'add_attached_service',
  ADD_ATTACHED_SERVICE_TO_EMPLOYEE = 'add_attached_service_to_employee',
  UPDATE_ATTACHED_SERVICE = 'update_attached_service',
  UPDATE_ATTACHED_SERVICE_FOR_EMPLOYEE = 'update_attached_service_for_employee',
  DELETE_ATTACHED_SERVICE = 'delete_attached_service',
  DELETE_ATTACHED_SERVICE_FOR_EMPLOYEE = 'delete_attached_service_for_employee',
}
