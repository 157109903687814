import {
  IntegrationDTO,
  IntegrationModel,
  IntegrationListModel,
  IntegrationCategoryDTO,
  IntegrationCategoryModel,
  IntegrationAssociationDTO,
  IntegrationAdditionalProps,
  IntegrationFormDTO,
} from '../internal';
import {WithoutRecursion} from '../type';

export type IntegrationAssociationType<T> = {
  integration: IntegrationModel;
  association: IntegrationAssociationDTO<T> | null;
};

export class IntegrationMapper {
  public static toIntegrationModel(
    integrationDTO: IntegrationDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): IntegrationModel {
    return new IntegrationModel(integrationDTO, [
      WithoutRecursion.integration,
      ...withoutRecursion,
    ]);
  }

  public static toIntegrationCategoryModel(
    integrationCategoryDTO: IntegrationCategoryDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): IntegrationCategoryModel {
    return new IntegrationCategoryModel(integrationCategoryDTO, [
      WithoutRecursion.integration,
      ...withoutRecursion,
    ]);
  }

  public static toIntegrationAssociationDTO<T>(
    integrationDTO: IntegrationDTO,
    association: IntegrationAssociationDTO<T> | null,
    withoutRecursion: WithoutRecursion[] = [],
  ): IntegrationAssociationType<T> {
    return {
      integration: IntegrationMapper.toIntegrationModel(
        integrationDTO,
        withoutRecursion,
      ),
      association,
    };
  }

  public static toIntegrationListModel(
    integrationDTOs: IntegrationDTO[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): IntegrationListModel {
    return new IntegrationListModel({integrations: integrationDTOs, total}, [
      WithoutRecursion.integration,
      ...withoutRecursion,
    ]);
  }

  public static toIntegrationFormDTO<T>(
    association: IntegrationAssociationDTO<T> | null,
    additionalProps: IntegrationAdditionalProps<T>,
  ): IntegrationFormDTO<T> {
    return new IntegrationFormDTO<T>(association, additionalProps);
  }
}
