import React, {useRef} from 'react';
import {ServiceModel} from '../../../struture';
import styled, {useTheme} from 'styled-components';
import {StyledDescription} from '../../../components/lib/Styled';
import {Routes} from '../../../services/types';
import {Link} from '../../../components/lib/DataDisplay';
import {useLocation, useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import {useStoredCompanies} from '../../../hooks';
import {ServicePersonalityView} from './ServicePersonalityView';
import {ServiceTourView} from './ServiceTourView';
import {useModal} from '../../../components/lib/libV2/hooks';

export interface IServiceDetailsViewProps {
  service: ServiceModel | null;
  price?: string;
  withTour?: boolean;
  onlyTitle?: boolean;
}
const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding-right: 15px;
  padding-bottom: 15px;
`;

const StyledSpace = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 5px;
`;
export function ServiceDetailsView({
  service,
  price,
  withTour,
  onlyTitle,
}: IServiceDetailsViewProps): JSX.Element {
  const {t} = useTranslation();
  const location = useLocation();
  const theme: any = useTheme();
  const {defaultCompany} = useStoredCompanies();
  const navigate = useNavigate();

  const contentRef = useRef(null);

  const {visible, handleOnInit, handleCancel} = useModal();

  return (
    <>
      {service ? (
        <StyledContainer ref={contentRef}>
          <StyledSpace>
            <Link
              onClick={(e) => {
                e?.preventDefault();
                e?.stopPropagation();

                if (withTour) {
                  handleOnInit();
                } else {
                  navigate(
                    `/${Routes.app}/${Routes.services}/${service?.uuid}`,
                    {state: {goBack: location.pathname}},
                  );
                }
              }}
              to={`/${Routes.app}/${Routes.services}/${service?.uuid}`}
              tooltip={
                withTour
                  ? t('Open details of service')
                  : t('Go to service details page')
              }
              state={{goBack: location.pathname}}>
              {service?.title}
            </Link>
            {onlyTitle ? null : (
              <>
                <StyledDescription $color={theme.colors.success}>{`${t(
                  'Price',
                )}: ${defaultCompany?.currency_symbol} ${
                  service?.price
                }`}</StyledDescription>
                <ServicePersonalityView service={service} />
                {service?.description ? (
                  <StyledDescription>{`${service?.description}`}</StyledDescription>
                ) : null}
              </>
            )}
          </StyledSpace>
        </StyledContainer>
      ) : null}
      {visible ? (
        <ServiceTourView
          service={service}
          visible={visible}
          handleCancel={handleCancel}
          price={price}
          ref={contentRef}
        />
      ) : null}
    </>
  );
}
