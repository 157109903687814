import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {HeaderLeft} from '../../../components/lib/DataDisplay';
import {
  useRequiredFields,
  useStoredDepartment,
  useStopLoading,
  useStoredCompanies,
} from '../../../hooks';
import {DEPARTMENT_REQUIRED_FIELD} from '../../../services/api/department';
import {OverviewLayout} from '../../../components/lib/Layout';
import {StyledTitle} from '../../../components/lib/Styled';
import {DepartmentTabPanel} from '../Show';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import {Routes, RoutesAcl} from '../../../services/types';
import {
  AbonementFormDTO,
  DepartmentMapper,
  DepartmentModel,
} from '../../../struture';
import {useAcl} from '../../../contex';
import {useLocation, useNavigate, useParams} from 'react-router';
import {DepartmentUpdateButton} from '../Buttons';
import {Col, Row, Space} from 'antd';
import styled from 'styled-components';
import {Table} from '../../../components/lib/libV2/DataDisplay';

const StyledTable = styled(Table)`
  &&& {
    max-width: 200px;

    .ant-table-cell,
    .ant-table-tbody {
      background-color: ${({theme}) => theme.background.layout};
    }
  }
`;

export function DepartmentPage() {
  const {departmentId, tabsId} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {t} = useTranslation();

  const {defaultCompanyUuid} = useStoredCompanies();

  const {department: departmentAccess} = useAcl((acl) => acl);

  const {
    department,
    loading: departmentLoading,
    error: departmentError,
    handleUpdateDepartment,
  } = useStoredDepartment({
    departmentUuid: departmentId!,
    companyUuid: defaultCompanyUuid,
  });

  const loading = useStopLoading({
    loading: departmentLoading,
    error: departmentError,
    message: 'An error occurred during department loading',
  });

  const routes = useBreadcrumb([
    {
      path: `/${Routes.departments}`,
      breadcrumbName: 'Departments',
    },
    {
      path: `/${Routes.departments}/${departmentId}`,
      breadcrumbName: department?.dep_name || 'Show department',
    },
  ]);

  const changeFields = useCallback(
    (
      {...rest}: typeof DEPARTMENT_REQUIRED_FIELD,
      department: DepartmentModel,
    ) => {
      const abon_timing_list = {};

      const timingListSize = Object.keys(abon_timing_list || {}).length;

      const timingList = AbonementFormDTO.getTimingWeekList(abon_timing_list);

      const timingListArr = Object.entries(abon_timing_list || {}).reduce(
        (acc: any, [key, value]: any) => {
          acc = [...acc, {day: t(key), time: `${value?.min} - ${value?.max}`}];
          return acc;
        },
        [],
      );

      const timingListField = timingListSize ? (
        timingList ? (
          <StyledTitle>{`${t('Mon-Sun')}: ${timingList}`}</StyledTitle>
        ) : (
          <StyledTable
            total={timingListArr.length}
            pageSize={timingListArr.length}
            loading={false}
            rowKey="day"
            dataSource={timingListArr}
            columns={[
              {
                width: 'fit-content',
                title: t('Weekday'),
                key: 'Weekday',
                render: (c: any) => c?.day,
              },
              {
                width: 'fit-content',
                title: t('Time'),
                key: 'time',
                render: (c: any) => c?.time,
              },
            ]}
            size="small"
          />
        )
      ) : null;

      return {
        ...rest,
        ...(timingListSize ? {'Time limit': timingListField} : []),
      };
    },
    [t],
  );

  const overviewData = useRequiredFields(
    department as any,
    DEPARTMENT_REQUIRED_FIELD,
    changeFields,
  );

  const navigateGoBack = useCallback(() => {
    navigate(location?.state?.goBack || `/${Routes.app}/${Routes.departments}`);
  }, [location?.state?.goBack, navigate]);

  return (
    <DepartmentTabPanel department={department}>
      {({tabs}) => (
        <OverviewLayout
          aclItem={RoutesAcl[Routes.departments]}
          headerTitle={t('Department')}
          headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
          headerRight={
            <DepartmentUpdateButton
              department={DepartmentMapper.toDepartmentFormDTO(department!)}
              onSuccess={handleUpdateDepartment}
            />
          }
          header={
            <Row gutter={10}>
              <Col span={24}>
                <Space direction="vertical" size="small">
                  <StyledTitle bold fontSize={20}>
                    {department?.dep_name}
                  </StyledTitle>
                  {/*<DepartmentPersonalityView department={department} />*/}
                </Space>
              </Col>
              {/*<Col span={24}>*/}
              {/*  <DetailsView titles={['Employee', 'Group payment']}>*/}
              {/*    {department?.manager?.uuid ? (*/}
              {/*      <EmployeeDetailsView employee={department?.managerModel} />*/}
              {/*    ) : null}*/}

              {/*    {department?.payment_group?.uuid ? (*/}
              {/*      <GroupPaymentDetailsView*/}
              {/*        groupPayment={department?.payment_group as any}*/}
              {/*      />*/}
              {/*    ) : null}*/}
              {/*  </DetailsView>*/}
              {/*</Col>*/}
            </Row>
          }
          disabled={!departmentAccess?.manage}
          editTooltip={t('Edit Department')}
          loading={loading}
          data={overviewData}
          routes={routes}
          tabsDefaultActiveKey={tabsId}
          tabs={tabs}
        />
      )}
    </DepartmentTabPanel>
  );
}
