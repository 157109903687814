import React from 'react';
import {StoreDocumentItemModel} from '../../../struture';
import styled, {css} from 'styled-components';
import {StyledDescription, StyledTitle} from '../../../components/lib/Styled';
import {correctPrice} from '../../../services/helpers';

export interface StoreDocItemsPriceDisabledViewProps {
  docItem: StoreDocumentItemModel;
  isLineThrough?: boolean;
}

const StyledContainer = styled.div<{
  $isLineThrough: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  ${({$isLineThrough}) =>
    $isLineThrough &&
    css`
      text-decoration: line-through;
    `}
`;

export function StoreDocItemsPriceDisabledView({
  docItem,
  isLineThrough,
}: StoreDocItemsPriceDisabledViewProps): React.JSX.Element {
  return (
    <StyledContainer $isLineThrough={!!isLineThrough}>
      <StyledTitle>{correctPrice(docItem?.item_base_price || 0)}</StyledTitle>
      {!(
        docItem?.item_price_currency === '₴' ||
        docItem?.item_price_currency === ''
      ) ? (
        <StyledDescription>{`${docItem?.item_price_currency}: ${correctPrice(
          docItem?.item_price || 0,
        )}`}</StyledDescription>
      ) : null}
    </StyledContainer>
  );
}
