import {LoginDTO} from '../internal';

export class LoginFormDTO {
  public identifier: string;

  public password: string;

  constructor(props?: LoginDTO) {
    this.identifier = props?.identifier || '';
    this.password = props?.password || '';
  }
}
