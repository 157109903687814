import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {StyledDescription} from '../Styled/Text';

export interface IDetailsViewItemProps {
  title: string;
  className?: string;
  selected?: boolean;
}

export interface IDetailsViewProps {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  items?: IDetailsViewItemProps[];

  // @deprecated in favor of "items".
  titles?: string[];
  colors?: (string | undefined)[];
  selected?: boolean[];
}

const StyledDetailsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  padding-top: 10px;
`;

const StyledViewContainer = styled.div<{$color?: string}>`
  position: relative;

  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 5px;

  background-color: ${({theme, $color}) => $color || theme.background.primary};
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.25) 5px 5px 4px -1px;
  min-width: 200px;

  padding: 10px;

  &.client-details__view {
    background-color: ${({theme, $color}) =>
      $color || theme.background.primary1};
  }

  &.store-details__view {
    background-color: ${({theme, $color}) =>
      $color || theme.background.primary2};
  }

  &.store-details__view,
  &.client-details__view {
    a,
    span,
    svg {
      color: ${({theme}) => theme.colors.white};
    }
  }
`;

export function DetailsView({
  titles,
  children,
  colors = [],
  selected = [],
  className = 'details_view',
  items,
}: IDetailsViewProps): JSX.Element {
  const {t} = useTranslation();
  const node = React.Children.map(children, (element, index) => {
    if (React.isValidElement(element)) {
      const itemClassName =
        Array.isArray(items) && items.length > 0
          ? items[index].className || element?.props?.className || ''
          : element?.props?.className || '';

      const itemTitle =
        Array.isArray(items) && items.length > 0
          ? items[index].title
          : titles && titles[index];

      return (
        <StyledViewContainer
          $color={colors[index]}
          key={String(index)}
          className={`${className} ${itemClassName}`}>
          {itemTitle ? (
            <StyledDescription
              style={{
                color: '#464646',
                fontWeight: 500,
                fontSize: '13px',
              }}>{`${t(itemTitle)}:`}</StyledDescription>
          ) : null}
          {React.cloneElement(element, {selected: selected[index]} as any)}
        </StyledViewContainer>
      );
    }
  })?.filter(Boolean);

  return <StyledDetailsContainer>{node}</StyledDetailsContainer>;
}
