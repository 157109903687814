import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {List} from 'immutable';
import {
  IUseProductCategoryProductListProps,
  IUseProductCategoryProductListReturnType,
  useProductCategoryProductList,
} from './useProductCategoryProductList';
import {
  ProductCategoryModel,
  ProductFormDTO,
  ProductMapper,
  ProductModel,
  ProductType,
} from '../struture';
import {
  createProduct,
  deleteProduct,
  editProduct,
} from '../services/api/product';
import {
  addProductCategoryListStats as storeAddProductCategoryListStats,
  addProductCategoryProductToList as storeAddProductCategoryProductToList,
  addProductToList as storeAddProduct,
  deleteProductCategoryProductFromList as storeDeleteProductCategoryProduct,
  deleteProductFromList as storeDeleteProduct,
  loadMoreProductCategoryProductList as storeLoadMoreProductCategoryProductList,
  setProductCategoryProductList as storeSetProductCategoryProductList,
  subProductCategoryListStats as storeSubProductCategoryListStats,
  updateProduct as storeUpdateProduct,
  updateProductCategoryProductFromList as storeUpdateProductCategoryProductToList,
  updateProductFromList as storeUpdateProductFromList,
} from '../store/actions';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../store/reducers';
import {REDUX_STATUS} from '../services/types';
import {head} from '../services/helpers';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../contex';
import {useStoredProductList} from './useStoredProductList';

export interface IUseStateProductCategoryProductListProps
  extends IUseProductCategoryProductListProps {
  company_uuid: string;
}

export interface IProductCategoryProductSearchProps {
  keywords: string;
  showLoading?: boolean;
  limit?: number;
  categoryUuid?: string;
  store_uuid?: string;
}

export interface IUseStateProductCategoryProductListReturnType
  extends Omit<IUseProductCategoryProductListReturnType, 'entityList'> {
  productList: List<ProductModel> | null;
  handleDeleteProducts: (ids: string[]) => Promise<void>;
  handleSearchProducts: (
    value: IProductCategoryProductSearchProps,
  ) => Promise<void>;
  handleLoadMoreProducts: () => Promise<void>;
  handleCreateProduct: (value: ProductFormDTO) => Promise<ProductModel | void>;
  handleEditProduct: (value: ProductFormDTO) => Promise<ProductModel | void>;
  handleRefreshProducts: (
    value: Partial<IUseProductCategoryProductListProps> & {page: number},
  ) => Promise<void>;
  status: REDUX_STATUS;
  loadingMore: boolean;
  page: number;
  category: ProductCategoryModel | null;
}

export function useStoredProductCategoryProductList(
  {
    categoryUuid: localCategoryUuid,
    loadOnInit = true,
    company_uuid,
    ...rest
  }: IUseStateProductCategoryProductListProps = {} as IUseStateProductCategoryProductListProps,
): IUseStateProductCategoryProductListReturnType {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const [loadingMore, setLoadingMore] = useState(false);
  const [productList, setVisitList] = useState<List<ProductModel> | null>(null);
  const [isStarLoading, setIsStartLoading] = React.useState<boolean>(false);
  const [storeKeywords, setStoreKeywords] = useState('');
  const [storePage, setStorePage] = useState(1);
  const [storeTotal, setStoreTotal] = useState(0);
  const [categoryUuid, setAbonementUuid] = useState(localCategoryUuid);
  const [category, setCategory] = useState<ProductCategoryModel | null>(null);

  const {
    status: storedProductCategoryProductListStatus,
    loading: storedProductCategoryProductListLoading,
    cachedProductList,
    ...storedProductCategoryProductListParams
  } = useSelector(
    ({productCategoryProductList}: RootState) => productCategoryProductList,
  );

  const {productList: storedProductList} = useStoredProductList({
    companyUuid: company_uuid,
    loadOnInit: false,
    productType: ProductType.DEFAULT,
  });

  const dispatch = useDispatch<any>();

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: productListLoading,
    total,
    ...productsParams
  } = useProductCategoryProductList({
    categoryUuid,
    loadOnInit: false,
    ...rest,
  });

  React.useEffect(() => {
    if (cachedProductList?.size > 0) {
      const productList = cachedProductList.get(categoryUuid);

      if (productList) {
        setIsStartLoading(false);
        setVisitList(productList?.products);
        setStorePage(productList?.page);
        setStoreKeywords(productList?.keywords);
        setStoreTotal(productList?.total || 0);
        setCategory(productList?.category || null);
      } else {
        setIsStartLoading(true);
      }
    }
    if (cachedProductList?.size === 0 && !productList) {
      setIsStartLoading(true);
    }
  }, [dispatch, cachedProductList, categoryUuid, storeTotal, productList]);

  useEffect(() => {
    if (!List.isList(entityList) && isStarLoading && loadOnInit) {
      setIsStartLoading(false);
      (async () => {
        const entityList = await refresh({categoryUuid});

        if (entityList) {
          dispatch(
            storeSetProductCategoryProductList(
              entityList,
              categoryUuid,
              '',
              entityList?.total,
              entityList?.page,
              entityList?.category,
            ),
          );
        }
      })();
    }
  }, [categoryUuid, dispatch, entityList, isStarLoading, loadOnInit, refresh]);

  const handleDeleteProducts = React.useCallback(
    async (ids: string[]) => {
      try {
        const {stats} = await deleteProduct(ids);

        setTimeout(() => {
          dispatch(storeDeleteProductCategoryProduct(head(ids), categoryUuid));
          dispatch(storeDeleteProduct(head(ids), stats));
        }, 100);

        dispatch(storeSubProductCategoryListStats());

        alert(
          'success',
          t('Tariff model product'),
          t('Tariff model product delete success'),
        );
      } catch (error: any) {
        alert(
          'error',
          t('Tariff model product'),
          `${t('An error occurred during delete tariff model products')}: ${
            error?.message
          }`,
        );
      }
    },
    [alert, dispatch, t, categoryUuid],
  );

  const handleSearchProducts = React.useCallback(
    async ({
      limit = 10,
      keywords,
      categoryUuid: localCategoryUuid = categoryUuid,
      showLoading,
      store_uuid,
    }: IProductCategoryProductSearchProps) => {
      setLoadingMore(true);
      const productListModel = await refresh({
        offset: 0,
        limit,
        keywords,
        categoryUuid: localCategoryUuid,
        showLoading,
        store_uuid,
      });

      if (productListModel && List.isList(productListModel?.products)) {
        setAbonementUuid(localCategoryUuid);
        dispatch(
          storeSetProductCategoryProductList(
            productListModel,
            localCategoryUuid,
            keywords,
            productListModel.total,
            1,
            productListModel?.category,
          ),
        );
      }
      setLoadingMore(false);
    },
    [categoryUuid, dispatch, refresh],
  );

  const handleLoadMoreProducts = useCallback(async () => {
    if (
      List.isList(productList) &&
      productList?.size < storeTotal &&
      !loadingMore
    ) {
      setLoadingMore(true);
      const productListModel = await refresh({
        offset: productList?.size,
        limit: 10,
        showLoading: false,
      });

      if (productListModel && List.isList(productListModel?.products)) {
        dispatch(
          storeLoadMoreProductCategoryProductList(
            productListModel,
            categoryUuid,
          ),
        );
      }

      setLoadingMore(false);
    }
  }, [productList, storeTotal, loadingMore, refresh, dispatch, categoryUuid]);

  const handleRefreshProducts = useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = false,
      page,
      categoryUuid: localCategoryUuid = categoryUuid,
    }: Partial<IUseProductCategoryProductListProps> & {page: number}) => {
      setLoadingMore(true);
      const productListModel = await refresh({
        offset,
        limit,
        showLoading,
        categoryUuid: localCategoryUuid,
      });

      if (productListModel && List.isList(productListModel?.products)) {
        setAbonementUuid(localCategoryUuid);

        dispatch(
          storeSetProductCategoryProductList(
            productListModel,
            localCategoryUuid,
            storeKeywords,
            productListModel?.total,
            page,
          ),
        );

        setLoadingMore(false);
      }
    },
    [categoryUuid, dispatch, refresh, storeKeywords],
  );

  const handleCreateProduct = React.useCallback(
    async (value: ProductFormDTO) => {
      const {product: productDTO, stats} = await createProduct(
        value,
        company_uuid,
      );

      const productModel = ProductMapper.toProductModel(productDTO);

      dispatch(
        storeAddProductCategoryProductToList(productModel, categoryUuid),
      );

      if (List.isList(storedProductList)) {
        dispatch(storeAddProduct(productModel, stats));
      }

      dispatch(storeAddProductCategoryListStats());

      return productModel;
    },
    [categoryUuid, company_uuid, dispatch, storedProductList],
  );

  const handleEditProduct = React.useCallback(
    async (value: ProductFormDTO) => {
      const productDTO = await editProduct(value);

      const productModel = ProductMapper.toProductModel(productDTO);

      dispatch(
        storeUpdateProductCategoryProductToList(productModel, categoryUuid),
      );

      dispatch(storeUpdateProduct(productModel));
      dispatch(storeUpdateProductFromList(productModel));

      return productModel;
    },
    [categoryUuid, dispatch],
  );

  return {
    ...productsParams,
    ...storedProductCategoryProductListParams,
    productList,
    total: storeTotal,
    loadingMore,
    offset,
    limit,
    refresh,
    loading:
      (!List.isList(productList) && productListLoading) ||
      !storedProductCategoryProductListLoading,
    handleDeleteProducts,
    handleSearchProducts,
    handleLoadMoreProducts,
    status: storedProductCategoryProductListStatus,
    handleRefreshProducts,
    keywords: storeKeywords || '',
    page: storePage || 1,
    handleCreateProduct,
    handleEditProduct,
    category,
  };
}
