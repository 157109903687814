import {Record, List} from 'immutable';
import {IListProps} from './List';
import {
  IStoreDocumentStatsDTOProps,
  StoreDocumentDTO,
  StoreDocumentMapper,
  StoreDocumentModel,
  StoreDocumentShowOnly,
  StoreDocumentStatsDTO,
} from '../internal';
import {WithoutRecursion} from '../type';

export interface IStoreDocumentListModelProps extends IListProps {
  documents: StoreDocumentDTO[];
  keywords?: string;
  page?: number;
  document?: StoreDocumentModel;
  stats?: StoreDocumentStatsDTO;
  show_only?: StoreDocumentShowOnly;
  resident_uuid?: string;
}

export interface IStoreDocumentListModelReturnType extends IListProps {
  documents: List<StoreDocumentModel>;
  keywords?: string;
  page?: number;
  document?: StoreDocumentModel;
  stats?: StoreDocumentStatsDTO;
  show_only?: StoreDocumentShowOnly;
  resident_uuid?: string;
}

export class StoreDocumentListModel extends Record<IStoreDocumentListModelReturnType>(
  {
    documents: List(),
    total: 0,
    keywords: '',
    page: 1,
    document: {} as StoreDocumentModel,
    stats: StoreDocumentMapper.toStoreDocumentStatsDTO(
      {} as IStoreDocumentStatsDTOProps,
    ),
    show_only: undefined,
    resident_uuid: '',
  },
) {
  constructor(
    {
      documents = [],
      ...props
    }: IStoreDocumentListModelProps = {} as IStoreDocumentListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IStoreDocumentListModelReturnType = {
      ...props,
      documents: List(
        documents.map(
          (document) => new StoreDocumentModel(document, withoutRecursion),
        ),
      ),
    };

    super(options);
  }
}
