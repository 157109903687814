import {
  IProductDTOProps,
  PhotoDTO,
  PhotoListModel,
  PhotoModel,
} from '../internal';
import {WithoutRecursion} from '../type';

export class PhotoMapper {
  public static toPhotoModel(
    photoDTO: PhotoDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): PhotoModel {
    return new PhotoModel(photoDTO, [
      WithoutRecursion.photo,
      ...withoutRecursion,
    ]);
  }

  public static toPhotoListModel(
    photoDTOs: (PhotoDTO | PhotoModel)[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
    trigger_object: IProductDTOProps,
  ): PhotoListModel {
    return new PhotoListModel({photos: photoDTOs, total, trigger_object}, [
      WithoutRecursion.photo,
      ...withoutRecursion,
    ]);
  }
}
