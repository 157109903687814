import React from 'react';
import {
  DetailsActionItemView,
  IDetailsActionItemViewProps,
} from '../../../components/lib/DataDisplay';
import {StyledTitle} from '../../../components/lib/Styled';
import Color from 'color';
import {ProductType} from '../../../services/types';
import {AiOutlineTag} from 'react-icons/ai';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {isThereContent} from '../../../services/helpers';
import {LoadingOutlined} from '@ant-design/icons';
import {IProductsListStatsProps} from '../../../struture';

export interface IProductPriceTagTypeViewProps
  extends Omit<IDetailsActionItemViewProps, 'onClick' | 'type' | 'children'> {
  color: string;
  selected?: boolean;
  title: string;
  stats: IProductsListStatsProps;
  onClick: (type: ProductType) => void;
}

const getPriceTagText = (count: string) => {
  switch (count) {
    case '1':
      return 'productT';
    case '2':
    case '3':
    case '4':
      return 'productTT';
    default:
      return 'productsT';
  }
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100%;
`;

const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  height: 100%;
`;

const StyledLoadingOutlined = styled(LoadingOutlined)<{$color: string}>`
  color: ${({$color}) => $color};
  font-size: 20px;
`;

export function ProductPriceTagTypeView({
  color,
  selected,
  title,
  stats,
  onClick,
  className,
  ...rest
}: IProductPriceTagTypeViewProps): React.JSX.Element {
  const {t} = useTranslation();

  const textColor = Color(color).alpha(1).darken(0.65).toString();

  const textType = t(getPriceTagText(`${stats?.product_price_tags_count}`));

  return (
    <DetailsActionItemView
      color={color}
      selected={selected}
      onClick={onClick as any}
      {...rest}>
      <StyledContainer>
        <AiOutlineTag color={textColor} size={23} />

        <StyledInfoContainer>
          <StyledTitle $color={textColor}>{t(title)}</StyledTitle>

          {isThereContent(stats) ? (
            <StyledTitle fontWeight={500} fontSize={14} $color={textColor}>
              {isThereContent(stats)
                ? Number(stats.product_price_tags_count) > 0
                  ? `${t('selected')} ${
                      stats.product_price_tags_count
                    } ${textType}`
                  : undefined
                : undefined}
            </StyledTitle>
          ) : (
            <StyledLoadingOutlined $color={textColor} />
          )}
        </StyledInfoContainer>
      </StyledContainer>
    </DetailsActionItemView>
  );
}
