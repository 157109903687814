import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {IDefaultFormProps, DefaultForm} from '../../../components/lib/General';
import {PaymentScheduleFormDTO} from '../../../struture';
import {useDropdownAlert} from '../../../contex';
import {PaymentStoreDocumentPostingFields} from './PaymentStoreDocumentPostingFields';
import {
  useStateCompanyClientList,
  useStateEmployeeList,
  useStoredCashBoxList,
  useStoredCompanies,
} from '../../../hooks';

export interface IPaymentStoreDocumentPostingFormProps
  extends Omit<
    IDefaultFormProps<PaymentScheduleFormDTO, PaymentScheduleFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  payment: PaymentScheduleFormDTO;
}

export function PaymentStoreDocumentPostingForm({
  loading,
  editMode,
  payment,
  ...rest
}: IPaymentStoreDocumentPostingFormProps) {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const {defaultCompanyUuid} = useStoredCompanies();

  const additionalValues = useMemo(
    () => ({
      uuid: payment?.uuid,
    }),
    [payment?.uuid],
  );

  const {
    clients,
    loading: clientsLoading,
    handleSearchClients,
  } = useStateCompanyClientList({
    companyUuid: defaultCompanyUuid,
    loadOnInit: true,
    provisioner_only: true,
  });

  const {
    employees,
    loading: employeesLoading,
    handleSearchEmployees,
  } = useStateEmployeeList({
    loadOnInit: true,
    companyUuid: defaultCompanyUuid,
  });

  const {
    cashBoxList,
    loading: cashBoxListLoading,
    handleSearchCashBoxes,
  } = useStoredCashBoxList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Payment'),
        `${
          editMode
            ? t('An error occurred during edit payment')
            : t('An error occurred during create payment')
        } : ${apiError?.message}`,
      );
    },
    [editMode, t, alert],
  );

  return (
    <DefaultForm<any, any>
      withContext
      initialValues={{
        ...payment,
      }}
      isResetLoading={false}
      editMode={editMode}
      showNotify={false}
      notifyError={notifyError}
      additionalValuesRequest={additionalValues}
      submitButtonText={t('Pay out')}
      {...rest}>
      <PaymentStoreDocumentPostingFields
        clients={clients}
        clientsLoading={clientsLoading}
        handleSearchClients={handleSearchClients}
        employees={employees}
        employeesLoading={employeesLoading}
        handleSearchEmployees={handleSearchEmployees}
        cashBoxList={cashBoxList}
        cashBoxListLoading={cashBoxListLoading}
        handleSearchCashBoxes={handleSearchCashBoxes}
      />
    </DefaultForm>
  );
}
