import * as React from 'react';
import {getRewardById, IRewardReturnType} from '../services/api/reward';
import {RewardModel, RewardMapper, RewardStatsDTO} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {IEntityProps} from '../services/types';
import {useDropdownAlert} from '../contex';
import {useTranslation} from 'react-i18next';

export interface IUseRewardProps extends IEntityProps {
  rewardUuid: string;
}

export interface IRewardModalReturnType {
  reward: RewardModel | null;
  stats: RewardStatsDTO;
}

export interface IUseRewardReturnType {
  error: null | any;
  loading: boolean;
  entity: RewardModel | null;
  refresh: (
    value: Partial<IUseRewardProps>,
  ) => Promise<IRewardModalReturnType | void>;
  rewardUuid: string;
}

export function useReward(
  {loadOnInit = true, rewardUuid}: IUseRewardProps = {} as IUseRewardProps,
): IUseRewardReturnType {
  const {cancellablePromise} = useCancellablePromise();
  const {alert} = useDropdownAlert();
  const {t} = useTranslation();

  const {
    entity,
    loading,
    error,
    handleUpdate,
    rewardUuid: entityUuid,
  } = useStateReducer<Omit<IUseRewardReturnType, 'refresh'>>({
    entity: null,
    rewardUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading,
      rewardUuid = entityUuid,
    }: Partial<IUseRewardProps> = {}): Promise<IRewardModalReturnType | void> => {
      try {
        handleUpdate({loading: showLoading, error: null, rewardUuid});

        const {reward, stats} = await cancellablePromise<IRewardReturnType>(
          getRewardById(rewardUuid),
        );

        const rewardModel = RewardMapper.toRewardModel(reward);

        handleUpdate({
          entity: rewardModel,
          loading: false,
        });
        return {reward: rewardModel, stats};
      } catch (err: any) {
        alert(
          'error',
          t('Reward'),
          `${t('An error occurred during get reward')} : ${err?.message}`,
        );

        handleUpdate({
          error: err,
          loading: false,
        });
      }
    },

    [alert, cancellablePromise, entityUuid, handleUpdate, t],
  );

  React.useEffect(() => {
    if (loadOnInit && rewardUuid) {
      (async () => {
        await refresh({rewardUuid});
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, rewardUuid]);

  return {
    error,
    loading,
    refresh,
    entity,
    rewardUuid,
  };
}
