import * as React from 'react';
import {List} from 'immutable';
import {Moment} from 'moment';
import {ApiError, IEntityProps, IListSearchProps} from '../services/types';
import {
  IPaymentListDTO,
  PaymentMapper,
  PaymentModel,
  StoreDocumentModel,
} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {
  IPaymentScheduleListStats,
  PaymentScheduleOperationType,
} from '../services/api/orders';
import {getStoreDocumentPaymentList} from '../services/api/storeDocument';
import {SessionStorageItems} from '../services/const';
import {toMoment} from '../services/helpers';

export interface IUseStoreDocumentPaymentListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  documentUuid: string;
  date_start?: Date | Moment | string;
  date_end?: Date | Moment | string;
  isSearch?: boolean;
  type?: PaymentScheduleOperationType;
  isClearDate?: boolean;
}

export interface IUseStoreDocumentPaymentListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<PaymentModel> | null;
  refresh: (
    value: Partial<IUseStoreDocumentPaymentListProps>,
  ) => Promise<List<PaymentModel> | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  documentUuid: string;
  date_start: Date | Moment | string;
  date_end?: Date | Moment | string;
  stats: IPaymentScheduleListStats;
  type?: PaymentScheduleOperationType;
  document: StoreDocumentModel;
}

export function useStoreDocumentPaymentList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    documentUuid,
    date_start,
    date_end,
    type = PaymentScheduleOperationType.ALL,
  }: IUseStoreDocumentPaymentListProps = {} as IUseStoreDocumentPaymentListProps,
): IUseStoreDocumentPaymentListReturnType {
  const {cancellablePromise, didCancel} = useCancellablePromise();

  const paymentRangeDates = JSON.parse(
    sessionStorage.getItem(SessionStorageItems.schedulePaymentRangeDates) ||
      JSON.stringify({}),
  );

  date_start = paymentRangeDates?.start
    ? toMoment(paymentRangeDates?.start)
    : date_start || '';
  date_end = paymentRangeDates?.end
    ? toMoment(paymentRangeDates?.end)
    : date_end || '';

  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    documentUuid: listClientUuid,
    date_start: listStartDate,
    date_end: listEndDate,
    stats: listStats,
    type: listType,
    document: listDocument,
    handleUpdate,
  } = useStateReducer<Omit<IUseStoreDocumentPaymentListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    documentUuid,
    date_start,
    date_end,
    stats: {} as IPaymentScheduleListStats,
    type,
    document: {} as StoreDocumentModel,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      documentUuid = listClientUuid,
      date_start = listStartDate,
      date_end = listEndDate,
      isSearch = false,
      type = listType,
      isClearDate,
    }: Partial<IUseStoreDocumentPaymentListProps> = {}): Promise<List<PaymentModel> | void> => {
      try {
        if (date_start && date_end && !isSearch) {
          sessionStorage.setItem(
            SessionStorageItems.schedulePaymentRangeDates,
            JSON.stringify({
              start: date_start,
              end: date_end,
              isCleared: false,
            }),
          );
        }

        if (isClearDate) {
          sessionStorage.setItem(
            SessionStorageItems.schedulePaymentRangeDates,
            JSON.stringify({start: '', end: '', isCleared: true}),
          );
          date_start = '';
          date_end = '';
        }

        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          documentUuid,
          type,
        });

        const {payments, stats, total, document} =
          await cancellablePromise<IPaymentListDTO>(
            getStoreDocumentPaymentList({
              documentUuid,
              limit,
              offset,
              keywords,
              date_start,
              date_end,
              type,
            }),
          );

        const paymentListModel = PaymentMapper.toPaymentListModel(
          payments,
          total,
          document,
        );

        if (!didCancel.current) {
          handleUpdate({
            entityList: paymentListModel?.payments,
            total: paymentListModel?.total,
            loading: false,
            stats,
            date_start,
            date_end,
            document,
          });

          return paymentListModel?.payments;
        }
      } catch (e) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }
      }
    },
    [
      cancellablePromise,
      didCancel,
      error,
      handleUpdate,
      listClientUuid,
      listEndDate,
      listKeywords,
      listLimit,
      listOffset,
      listStartDate,
      listType,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && documentUuid) {
      (async () => {
        await refresh({documentUuid});
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, documentUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    documentUuid: listClientUuid,
    date_start: listStartDate,
    date_end: listEndDate,
    stats: listStats,
    type,
    document: listDocument,
  };
}
