import React from 'react';
import {List} from 'immutable';
import {useAcl} from '../../../contex';
import {ClientDetailsView} from '../Show';
import {useTranslation} from 'react-i18next';
import {IUseCompanyClientListProps} from '../../../hooks';
import {DeleteOutlined, LockOutlined} from '@ant-design/icons';
import {ClientEditButton, ClientsDeleteButton} from '../Buttons';
import {ClientPersonalityType, RowSelection} from '../../../services/types';

import {
  ClientFormDTO,
  ClientGroupFormDTO,
  ClientLegalFormDTO,
  ClientMapper,
  ClientModel,
  ClientOrgType,
} from '../../../struture';
import {
  Table,
  TableActionCell,
} from '../../../components/lib/libV2/DataDisplay';

import './ClientList.less';

export interface IClientListProps {
  clients: List<ClientModel> | null;
  loading: boolean;
  limit: number;
  onRefresh: (
    value: Partial<IUseCompanyClientListProps>,
  ) => Promise<List<ClientModel> | void>;
  rowSelection: RowSelection<ClientModel>;
  total: number;
  withoutActions?: boolean;
  daleyFocus?: (ms?: number) => void;
  handleUpdateClient: (value: ClientFormDTO) => Promise<void>;
  handleUpdateClientLegal: (value: ClientLegalFormDTO) => Promise<void>;
  handleUpdateClientGroup: (value: ClientGroupFormDTO) => Promise<void>;
  handleDeleteClients: (value: string[]) => Promise<void>;
}

export default function ClientList({
  clients,
  limit = 10,
  loading,
  total,
  withoutActions,
  daleyFocus,
  handleUpdateClient,
  handleDeleteClients,
  handleUpdateClientGroup,
  handleUpdateClientLegal,
  onRefresh = async () => {},
  ...restProps
}: IClientListProps): JSX.Element {
  const {t} = useTranslation();
  const {manage} = useAcl(({client}) => client);

  const columnsWithoutActions = [
    {
      key: 'client',
      title: t('Title'),
      width: '90%',
      className: 'client-list-td client-list--title',
      render: (client: ClientModel) => <ClientDetailsView client={client} />,
    },
  ];

  const columns: any[] = withoutActions
    ? columnsWithoutActions
    : [
        ...columnsWithoutActions,
        {
          title: '',
          width: 40,
          key: 'client_actions',
          align: 'center' as any,
          fixed: 'right' as any,
          className: 'client-list-td',
          render: (client: ClientModel) => (
            <TableActionCell className="client-list--hidden">
              {client?.client_strict_mode ? (
                <LockOutlined />
              ) : (
                <>
                  <ClientEditButton
                    clientType={client?.client_type}
                    disabled={!manage}
                    type="text"
                    client={
                      client?.client_type === ClientPersonalityType.Individual
                        ? client?.client_org_type === ClientOrgType.PERSON
                          ? ClientMapper.toClientFormDTO(client)
                          : ClientMapper.toClientLegalFormDTO(client)
                        : ClientMapper.toClientGroupFormDTO(client)
                    }
                    data-testid="client-edit-button"
                    onSuccess={
                      client?.client_org_type === ClientOrgType.PERSON
                        ? handleUpdateClient
                        : handleUpdateClientLegal
                    }
                    onSuccessGroup={handleUpdateClientGroup}
                  />
                  <ClientsDeleteButton
                    disabled={!manage}
                    data-testid="client-delete-button"
                    clients={[client]}
                    className="client-list--delete"
                    onSuccess={handleDeleteClients}>
                    <DeleteOutlined />
                  </ClientsDeleteButton>
                </>
              )}
            </TableActionCell>
          ),
        },
      ];

  return (
    <Table
      {...restProps}
      rowKey="uuid"
      total={total}
      pageSize={limit}
      columns={columns}
      loading={loading}
      tableLayout="fixed"
      dataSource={clients}
      onChange={onRefresh}
      sticky={{offsetHeader: 80}}
      onRow={(record: ClientModel, index: number | undefined) => ({
        className: 'client-list--row',
      })}
    />
  );
}
