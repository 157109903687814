import React, {useMemo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {IDefaultFormProps, DefaultForm} from '../../../components/lib/General';
import {
  CLIENT_REFUND_INITIAL_PARAM,
  ICalculationRefundsClientAbonementProps,
} from '../../../services/api/client';
import {ClientRefundsDTO, ClientRefundsFormDTO} from '../../../struture';
import {useDropdownAlert} from '../../../contex';
import {ClientRefundsFields} from './ClientRefundsFields';
import {useStoredCashBoxList, useStoredCompanies} from '../../../hooks';

export interface IClientRefundsFormProps
  extends Omit<
    IDefaultFormProps<ClientRefundsFormDTO, ClientRefundsFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  refund?: ClientRefundsFormDTO;
  handleCalculateRefundsClientAbonement: (
    value: ICalculationRefundsClientAbonementProps,
  ) => Promise<ClientRefundsDTO>;
}

export function ClientRefundsForm({
  loading,
  editMode,
  refund = CLIENT_REFUND_INITIAL_PARAM,
  onCancel,
  handleCalculateRefundsClientAbonement,
  ...rest
}: IClientRefundsFormProps) {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const {defaultCompanyUuid} = useStoredCompanies();

  const additionalValues = useMemo(
    () => ({
      uuid: refund?.uuid,
    }),
    [refund?.uuid],
  );

  const {
    cashBoxList,
    loading: cashBoxListLoading,
    handleSearchCashBoxes,
  } = useStoredCashBoxList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Client refunds'),
        `${
          editMode
            ? t('An error occurred during edit refunds')
            : t('An error occurred during create refunds')
        } : ${apiError?.message}`,
      );
    },
    [alert, t, editMode],
  );

  return (
    <DefaultForm<any, any>
      withContext
      editMode={editMode}
      initialValues={{
        ...refund,
      }}
      additionalValuesRequest={additionalValues}
      showNotify={false}
      onCancel={onCancel}
      notifyError={notifyError}
      submitButtonText={t('Conduct')}
      {...rest}>
      {() => (
        <ClientRefundsFields
          editMode={!!editMode}
          handleCalculateRefundsClientAbonement={
            handleCalculateRefundsClientAbonement
          }
          cashBoxList={cashBoxList}
          cashBoxListLoading={cashBoxListLoading}
          handleSearchCashBoxes={handleSearchCashBoxes}
        />
      )}
    </DefaultForm>
  );
}
