export enum REWARD_LIST {
  SET_REWARD_LIST = 'set_reward_list',
  LOADING_REWARD_LIST = 'loading_reward_list',
  ERROR_REWARD_LIST = 'error_reward_list',
  LOAD_MORE_REWARD_LIST = 'load_more_reward_list',
  ADD_REWARD = 'add_reward',
  UPDATE_REWARD = 'update_reward',
  UPDATE_STATS = 'update_stats',
  DELETE_REWARD = 'delete_reward',
}
