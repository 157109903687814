import React from 'react';
import {IoIosCash} from 'react-icons/io';
import {ClientTitle} from './ClientTitle';
import {useTranslation} from 'react-i18next';
import {ClientModel} from '../../../struture';
import {Routes} from '../../../services/types';
import {ClientBalanceView} from './ClientBalanceView';
import styled, {css} from 'styled-components';
import {Link} from '../../../components/lib/DataDisplay';
import {StyledTitle} from '../../../components/lib/Styled';
import {FileUploader} from '../../../components/lib/Layout/common';

import {
  ifElse,
  getFirstLatter,
  fileUploadStructure,
  firstLetterToUppercase,
} from '../../../services/helpers';

export interface IOverviewHeaderProps {
  client: ClientModel | null;
  handleUploadImage?: (file: File) => Promise<string>;
  disabled?: boolean;
  extra?: React.ReactNode | React.ReactNode[];
  withoutUpload?: boolean;
  className?: string;
}

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledContainer = styled.div<{$withoutUpload: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  ${({$withoutUpload}) =>
    !$withoutUpload &&
    css`
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 15px;
    `}
`;

const StyledClientContainer = styled.div<{$withoutUpload: boolean}>`
  display: flex;
  flex-direction: column;
  gap: 5px;

  ${({$withoutUpload}) =>
    !$withoutUpload &&
    css`
      margin-left: 20px;
    `}
`;

const StyledButtonContainer = styled.div<{$isVisible: boolean}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;

  ${({$isVisible}) =>
    !$isVisible &&
    css`
      visibility: hidden;
    `}
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;

  width: fit-content;
  height: fit-content;
  margin-left: auto;
  padding: 10px;

  background-color: ${({theme}) => theme.background.primary};
  border: 1px solid ${({theme}) => theme.colors.disabled};
  border-radius: 7px;

  &:hover {
    border-color: ${({theme}) => theme.colors.link};
  }
`;

export function ClientHeader({
  handleUploadImage,
  extra,
  withoutUpload = false,
  className,
  client,
}: IOverviewHeaderProps): JSX.Element {
  const {t} = useTranslation();

  return (
    <StyledButtonContainer $isVisible={!!client}>
      <StyledWrapper className={className}>
        <StyledContainer className={className} $withoutUpload={withoutUpload}>
          {withoutUpload ? null : (
            <FileUploader
              title={getFirstLatter(
                firstLetterToUppercase(client?.fullNameClient!),
              )}
              disabled
              onUpload={handleUploadImage}
              file={ifElse(
                !!client?.picture_url,
                fileUploadStructure(client?.picture_url as any),
                '',
              )}
            />
          )}

          <StyledClientContainer $withoutUpload={withoutUpload}>
            <ClientTitle client={client} />
            <ClientBalanceView client={client} />
            <StyledTitle>{t(client?.phone || '')}</StyledTitle>
          </StyledClientContainer>
        </StyledContainer>
        {extra || null}
      </StyledWrapper>
      {withoutUpload ? null : (
        <StyledLink
          withoutRight
          to={`${Routes.clientBalanceOperations}`}
          tooltip={t('Go to balance operations details page')}
          state={{goBack: location.pathname, name: client?.fullNameClient}}>
          <IoIosCash size={18} />
          <StyledTitle fontSize={16}>{t('Balance')}</StyledTitle>
        </StyledLink>
      )}
    </StyledButtonContainer>
  );
}
