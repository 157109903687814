import React from 'react';
import {CommentField, DetailsView} from '../../../components/lib/DataDisplay';
import {ClientDetailsView} from '../../Clients';
import {EmployeeDetailsView} from '../../Employees';
import {
  IUseStateFileListReturnType,
  IUseStateScheduleReturnType,
} from '../../../hooks';
import {useTranslation} from 'react-i18next';
import {FileDraggerView} from './FileDraggerView';
import {useAcl} from '../../../contex';
import {useTheme} from 'styled-components';
import {EmployeeStatuses} from '../../../struture';

export interface IOrderDetailsHeaderProps
  extends Pick<
      IUseStateScheduleReturnType,
      'schedule' | 'handleUpdateScheduleComment' | 'stats'
    >,
    Pick<IUseStateFileListReturnType, 'handleUploadFiles'> {
  handleOpenFileList: () => void;
  isDisabledScheduleAction: boolean;
}

export function OrderDetailsHeader({
  schedule,
  handleUpdateScheduleComment,

  handleUploadFiles,
  handleOpenFileList,
  stats,

  isDisabledScheduleAction,
}: IOrderDetailsHeaderProps): React.JSX.Element {
  const {t} = useTranslation();
  const {order: orderAccess} = useAcl((acl) => acl);
  const theme: any = useTheme();

  return (
    <>
      <DetailsView
        colors={[
          undefined,
          schedule?.employee?.status === EmployeeStatuses.DELETED
            ? theme.colors.lightError
            : undefined,
        ]}
        titles={['Client', 'Manager']}>
        {schedule?.client?.uuid ? (
          <ClientDetailsView client={schedule?.clientModel} />
        ) : null}
        {schedule?.employee?.uuid ? (
          <EmployeeDetailsView employee={schedule?.employeeModel} />
        ) : null}
      </DetailsView>

      <FileDraggerView
        handleUploadFiles={handleUploadFiles}
        handleOpenFileList={handleOpenFileList}
        stats={stats}
        hiddeUploadArea={isDisabledScheduleAction}
      />

      <CommentField
        comment={schedule?.comment || t('Please enter note')}
        handleUpdateComment={handleUpdateScheduleComment}
        disabled={!orderAccess?.manage}
      />
    </>
  );
}
