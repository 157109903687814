import {
  ExpenseModel,
  ExpenseWorkableType,
  InvoiceDTO,
  InvoiceFormAdditionalParams,
  InvoiceFormDTO,
  InvoiceItemDTO,
  InvoiceItemFormAdditionalParams,
  InvoiceItemFormDTO,
  InvoiceItemListModel,
  InvoiceItemModel,
  InvoiceListModel,
  InvoiceModel,
  InvoiceStats,
  InvoiceStatsModel,
  ProductModel,
  ServiceModel,
  StoreDocumentItemModel,
} from '../internal';
import {WithoutRecursion} from '../type';

export class InvoiceMapper {
  public static toInvoiceModel(
    invoiceDTO: InvoiceDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): InvoiceModel {
    return new InvoiceModel(invoiceDTO, [
      WithoutRecursion.invoice,
      ...withoutRecursion,
    ]);
  }

  public static toInvoiceListModel(
    invoiceDTOs: (InvoiceDTO | InvoiceModel)[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
    stats?: InvoiceStatsModel,
  ): InvoiceListModel {
    return new InvoiceListModel({invoices: invoiceDTOs, total, stats}, [
      WithoutRecursion.invoice,
      ...withoutRecursion,
    ]);
  }

  public static toInvoiceFormDTO(
    invoiceModel: InvoiceModel,
    additionalParams?: InvoiceFormAdditionalParams,
  ): InvoiceFormDTO {
    return new InvoiceFormDTO(invoiceModel, additionalParams);
  }

  public static toInvoiceItemModel(
    itemDTO: InvoiceItemDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): InvoiceItemModel {
    return new InvoiceItemModel(itemDTO, [
      WithoutRecursion.invoice,
      ...withoutRecursion,
    ]);
  }

  public static toInvoiceItemListModel(
    itemDTOs: (InvoiceItemDTO | InvoiceItemModel)[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
    invoice?: InvoiceModel,
  ): InvoiceItemListModel {
    return new InvoiceItemListModel({items: itemDTOs, total, invoice}, [
      WithoutRecursion.invoice,
      ...withoutRecursion,
    ]);
  }

  public static toInvoiceItemFormDTO(
    itemModel: InvoiceItemModel,
    additionalParams?: InvoiceItemFormAdditionalParams,
  ): InvoiceItemFormDTO {
    return new InvoiceItemFormDTO(itemModel, additionalParams);
  }

  public static toInvoiceItemFormDTOFromService(
    service: ServiceModel,
    additionalParams?: InvoiceItemFormAdditionalParams,
  ): InvoiceItemFormDTO {
    return new InvoiceItemFormDTO(
      {
        item_title: service?.title || '',
        item_positionable_object: service,
        item_amount: 1,
        item_price: service?.price,
      } as InvoiceItemModel,
      additionalParams,
    );
  }

  public static toInvoiceItemFormDTOFromProduct(
    product: ProductModel,
    additionalParams?: InvoiceItemFormAdditionalParams,
  ): InvoiceItemFormDTO {
    return new InvoiceItemFormDTO(
      {
        item_title: product?.product_title || '',
        item_positionable_object: product,
        item_amount: 1,
      } as InvoiceItemModel,
      additionalParams,
    );
  }

  public static toInvoiceItemFormDTOFromExpense(
    expense: ExpenseModel,
    additionalParams?: InvoiceItemFormAdditionalParams,
  ): InvoiceItemFormDTO {
    return new InvoiceItemFormDTO(
      {
        item_title:
          (expense?.workable_type === ExpenseWorkableType.Product
            ? (expense?.workable as ProductModel)?.product_title
            : (expense?.workable as ServiceModel)?.title) || '',
        item_positionable_object: expense,
        item_amount: expense?.amount,
        item_price: expense?.price,
      } as InvoiceItemModel,
      additionalParams,
    );
  }

  public static toInvoiceItemFormDTOFromStoreDocumentItem(
    storeDocumentItem: StoreDocumentItemModel,
    additionalParams?: InvoiceItemFormAdditionalParams,
  ): InvoiceItemFormDTO {
    return new InvoiceItemFormDTO(
      {
        item_title: storeDocumentItem?.product?.product_title || '',
        item_positionable_object: storeDocumentItem,
        item_amount: storeDocumentItem?.item_product_amount,
        item_price: storeDocumentItem?.item_price,
      } as InvoiceItemModel,
      additionalParams,
    );
  }

  public static toInvoiceItemFormDTOFactory(
    item:
      | ServiceModel
      | ProductModel
      | ExpenseModel
      | StoreDocumentItemModel
      | InvoiceItemModel,
    additionalParams?: InvoiceItemFormAdditionalParams,
  ): InvoiceItemFormDTO {
    if (item instanceof InvoiceItemModel) {
      return InvoiceMapper.toInvoiceItemFormDTO(item, additionalParams);
    }

    if (item instanceof ServiceModel) {
      return InvoiceMapper.toInvoiceItemFormDTOFromService(
        item,
        additionalParams,
      );
    }

    if (item instanceof ExpenseModel) {
      return InvoiceMapper.toInvoiceItemFormDTOFromExpense(
        item,
        additionalParams,
      );
    }

    if (item instanceof StoreDocumentItemModel) {
      return InvoiceMapper.toInvoiceItemFormDTOFromStoreDocumentItem(
        item,
        additionalParams,
      );
    }

    return InvoiceMapper.toInvoiceItemFormDTOFromProduct(
      item,
      additionalParams,
    );
  }

  public static toInvoiceStatsModel(stats: InvoiceStats): InvoiceStatsModel {
    return new InvoiceStatsModel(stats);
  }
}
