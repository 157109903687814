import React, {useCallback, useEffect} from 'react';
import {Col, Form, Input, Row} from 'antd';
import {useDefaultForm} from '../../../contex';
import {withTranslation, WithTranslation} from 'react-i18next';
import styled, {css} from 'styled-components';
import {SUBSCRIPTION_PERIODS} from '../../../services/api/subscriptions';
import {SearchSelect, Segmented} from '../../../components/lib/DataDisplay';
import {correctPrice} from '../../../services/helpers';

export default withTranslation()(SubscriptionFormFields);

export interface ISubscriptionFormFieldsProps extends WithTranslation {
  loading: boolean;
}

const StyledInput = styled(Input)`
  color: ${({theme}) => theme.colors.primary} !important;

  & * {
    color: ${({theme}) => theme.colors.primary} !important;
  }
`;

const StyledSearchSelect = styled(SearchSelect)<{$isSelected: boolean}>`
  &&& {
    width: 33px;

    .ant-select-selector {
      position: relative;
      border: none;
      background-color: #f5f5f5;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      transition: all 1s;

      ${({$isSelected, theme}) =>
        $isSelected &&
        css`
          & + .ant-select-arrow {
            color: ${theme.colors.white};
          }

          &:before {
            content: ' ';
            position: absolute;
            left: 0;

            width: 33px;
            height: 100%;
            background-color: ${theme.colors.link};
            border-radius: 7px;
          }
        `}
    }
  }
`;

function SubscriptionFormFields({
  t,
  loading,
}: ISubscriptionFormFieldsProps): JSX.Element {
  const {loadingSubmit, handlerUpdateFormState, formData, setFields} =
    useDefaultForm();

  const handleValidatePriceIsNumber = useCallback(
    (_: any, value: any) => {
      const numberValue = Number(value);
      if (numberValue <= 0 || Number.isNaN(numberValue)) {
        return Promise.reject(new Error(t('Periods must be a number')));
      }
      return Promise.resolve();
    },
    [t],
  );

  const handleChangePeriods = useCallback(
    (periods: any) => {
      const price = Number(formData?.price) || 0;
      const amount = correctPrice(Number(periods) * price || 0);

      handlerUpdateFormState({periods, amount});
    },
    [formData?.price, handlerUpdateFormState],
  );

  useEffect(() => {
    if (formData && !formData?.id) {
      handlerUpdateFormState({id: t(formData?.identifier)});
    }
  }, [formData, formData?.identifier, handlerUpdateFormState, t]);

  return (
    <Row gutter={12}>
      <Col span={24}>
        <Form.Item tooltip={t('Plan name')} label={t('Plan name')} name="id">
          <StyledInput data-testid="subscription-input-identifier" disabled />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item tooltip={t('Price')} label={t('Price')} name="price">
          <StyledInput
            autoComplete="off"
            data-testid="subscription-input-price"
            disabled
            addonAfter={t('UAH')}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          tooltip={t(
            'The period for which the subscription is extended or connected',
          )}
          label={t('Extension period')}
          name="periods"
          rules={[
            {
              required: true,
              message: t('Periods must be specified.'),
            },
            {validator: handleValidatePriceIsNumber},
          ]}>
          <Segmented
            options={SUBSCRIPTION_PERIODS}
            disabled={loadingSubmit}
            onChange={handleChangePeriods}
            addonAfter={
              <Form.Item noStyle name="periods">
                <StyledSearchSelect
                  $isSelected={formData?.periods === '1'}
                  withOutSearch
                  showValue={false}
                  popupMatchSelectWidth={false}
                  name="periods"
                  placeholder=""
                  disable={loadingSubmit}
                  data={[
                    {
                      uuid: '1',
                      title: 'month',
                    },
                  ]}
                  containerStyle={{width: 33}}
                  onChange={handleChangePeriods}
                />
              </Form.Item>
            }
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item tooltip={t('Comment')} label={t('Comment')} name="comment">
          <Input.TextArea
            data-testid="subscription-input-comment"
            disabled={loading || loadingSubmit}
            autoSize={{minRows: 3, maxRows: 5}}
            placeholder={t('Enter comment')}
            onChange={(e) => handlerUpdateFormState({comment: e.target.value})}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item tooltip={t('Amount')} label={t('Amount')} name="amount">
          <StyledInput
            style={{width: '100%'}}
            data-testid="subscription-input-periods"
            addonAfter={t('UAH')}
            disabled
          />
        </Form.Item>
      </Col>
    </Row>
  );
}
