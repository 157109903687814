import React from 'react';
import {GroupModel} from '../../../struture';
import styled from 'styled-components';
import {StyledDescription} from '../../../components/lib/Styled';
import {Routes} from '../../../services/types';
import {Link} from '../../../components/lib/DataDisplay';
import {useLocation} from 'react-router';
import {useTranslation} from 'react-i18next';

export interface IGroupPaymentDetailsViewProps {
  groupPayment: GroupModel | null;
}
const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding-right: 15px;
  padding-bottom: 15px;
`;

const StyledSpace = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
`;
export function GroupPaymentDetailsView({
  groupPayment,
}: IGroupPaymentDetailsViewProps): JSX.Element {
  const {t} = useTranslation();
  const location = useLocation();

  return (
    <>
      {groupPayment ? (
        <StyledContainer>
          <StyledSpace>
            <Link
              style={{color: '#9f9a7f', fontWeight: '500', fontSize: '22px'}}
              to={`/${Routes.app}/${Routes.groupPayment}`}
              tooltip={t('Go to group payments details page')}
              state={{goBack: location.pathname}}>
              {groupPayment?.title}
            </Link>
            {groupPayment?.description ? (
              <StyledDescription
                style={{
                  color: '#464646',
                }}>{`${groupPayment?.description}`}</StyledDescription>
            ) : null}
          </StyledSpace>
        </StyledContainer>
      ) : null}
    </>
  );
}
