import React, {useEffect, useState} from 'react';
import {Col, Form, Input, Row} from 'antd';
import {SearchSelect} from '../../../components/lib/DataDisplay';
import {isListToArray} from '../../../services/helpers';
import {DatePicker} from '../../../components/lib/General';
import {useTranslation} from 'react-i18next';
import {useDefaultForm} from '../../../contex';
import {IUseStateEmployeeListReturnType} from '../../../hooks';

export interface IClientVisitFieldsProps
  extends Pick<
    IUseStateEmployeeListReturnType,
    'employees' | 'handleSearchEmployees'
  > {
  employeesLoading: boolean;
}

export function ClientVisitFields({
  employees,
  employeesLoading,
  handleSearchEmployees,
}: IClientVisitFieldsProps): JSX.Element {
  const {t} = useTranslation();
  const {loadingSubmit, formData, handlerUpdateFormState} = useDefaultForm();

  const [showEmployeeField, setShowEmployeeField] = useState(false);

  useEffect(() => {
    if (!showEmployeeField && formData?.manager_uuid) {
      setShowEmployeeField(true);
    }
  }, [formData?.manager_uuid, showEmployeeField]);

  return (
    <Row gutter={12}>
      {showEmployeeField ? (
        <Col span={24}>
          <Form.Item
            tooltip={t('Manager')}
            label={t('Manager')}
            name="manager_uuid"
            rules={[
              {
                required: true,
                message: t('Manager be specified.'),
              },
            ]}>
            <SearchSelect
              selectIfOnFirst={false}
              selectFirst={false}
              name="manager_uuid"
              getOptionValueTitle="fullName"
              placeholder={t('Select an employee')}
              disable={loadingSubmit || employeesLoading}
              data={isListToArray(employees as any)}
              onChange={(manager_uuid) =>
                handlerUpdateFormState({manager_uuid})
              }
              onSearch={(keywords) =>
                handleSearchEmployees({
                  keywords,
                  showLoading: false,
                  limit: 100,
                })
              }
            />
          </Form.Item>
        </Col>
      ) : null}
      <Col span={24}>
        <Form.Item
          tooltip={t('Date and time')}
          label={t('Date and time')}
          name="date"
          rules={[
            {
              required: true,
              message: t('Date and time must be specified.'),
              type: 'date',
            },
          ]}>
          <DatePicker
            showTime
            format="YYYY-MM-DD HH:mm"
            disabled={loadingSubmit}
            placeholder={t('Start date')}
            onChange={(date) => {
              handlerUpdateFormState({
                date,
              });
            }}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item label={t('Note')} name="comment">
          <Input.TextArea
            allowClear
            autoSize={{minRows: 2, maxRows: 5}}
            disabled={loadingSubmit}
            placeholder={t('Enter your note')}
            onChange={(e) => handlerUpdateFormState({comment: e.target.value})}
          />
        </Form.Item>
      </Col>
    </Row>
  );
}
