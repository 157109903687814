import React from 'react';
import {useTranslation} from 'react-i18next';
import {IConfirmActionButtonProps} from '../../../components/lib/General';
import {TextFormat} from '../../../components/lib/Format';
import {len} from '../../../services/helpers';
import {useDelete} from '../../../components/lib/libV2/hooks';
import {ScheduleCalendarModel} from '../../../struture';
import {DeleteButton} from '../../../components/lib/DataDisplay';

export interface IDeleteOrderButtonProps
  extends Omit<IConfirmActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: string[]) => Promise<void>;
  onError?: () => void;
  schedules: ScheduleCalendarModel[];
}

export default function DeleteOrderButton({
  children,
  schedules,
  onSuccess,
  onError,
  title,
  ...rest
}: IDeleteOrderButtonProps): JSX.Element {
  const {t} = useTranslation();

  const {handleDelete, loading} = useDelete({
    onSuccess,
    entity: schedules,
    notifyTitle: 'Orders',
  });

  return (
    <DeleteButton
      title={title}
      tooltipTitle={t('Delete company order')}
      confirmTitle={
        <TextFormat breakWord>
          {t(`Are you sure you want to delete `)}
          <b>{`${len(schedules)} ${
            len(schedules) === 1
              ? t('order').toLowerCase()
              : t('orders').toLowerCase()
          }`}</b>
        </TextFormat>
      }
      onConfirm={handleDelete}
      loading={loading}
      cancelButtonText={t('Cancel')}
      {...rest}
    />
  );
}
