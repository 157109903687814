export enum EMPLOYEE_SERVICE_LIST {
  SET_EMPLOYEE_SERVICE_LIST = 'set_employee_service_list',
  LOADING_EMPLOYEE_SERVICE_LIST = 'loading_employee_service_list',
  ERROR_EMPLOYEE_SERVICE_LIST = 'error_employee_service_list',
  LOAD_MORE_EMPLOYEE_SERVICE_LIST = 'load_more_employee_service_list',
  ADD_EMPLOYEE_SERVICE = 'add_employee_service',
  ADD_SERVICE_TO_EMPLOYEE = 'add_service_to_employee',
  UPDATE_EMPLOYEE_SERVICE = 'update_employee_service',
  UPDATE_SERVICE_FOR_EMPLOYEE = 'update_service_for_employee',
  DELETE_EMPLOYEE_SERVICE = 'delete_employee_service',
  DELETE_SERVICE_FROM_EMPLOYEE = 'delete_service_from_employee',
}
