import {
  ClientDTO,
  ClientMapper,
  EmployeeFormDTO,
  EmployeeMapper,
  IScheduleCalendarModelReturnType,
  ServiceFormDTO,
  ServiceMapper,
  ClientPersonalityType,
} from '../internal';
import {correctPrice} from '../../helpers';

export class ScheduleCalendarFormDTO {
  public scheduled_date: string;

  public schedule_title: string;

  public uuid: string;

  public client_uuid: ClientDTO | string;

  public service_uuid: ServiceFormDTO | string;

  public worker_uuid: EmployeeFormDTO | string;

  public send_sms_notification: boolean;

  public send_email_notification: boolean;

  public comment: string;

  public price: string;

  public client_type?: ClientPersonalityType;

  constructor(
    props?: IScheduleCalendarModelReturnType,
    send_sms_notification?: boolean,
    editMode?: boolean,
  ) {
    this.scheduled_date = props?.scheduled_date || new Date().toString();
    this.schedule_title = props?.schedule_title || '';
    this.uuid = props?.uuid || '';
    this.client_uuid = editMode
      ? props?.client?.uuid
        ? ClientMapper.toClientDTO(props?.client as any)
        : ''
      : props?.client?.uuid || '';
    this.service_uuid = editMode
      ? props?.service?.uuid
        ? ServiceMapper.toServiceFormDTO(props?.service as any)
        : ''
      : props?.service?.uuid || '';
    this.worker_uuid = editMode
      ? props?.employee?.uuid
        ? EmployeeMapper.toEmployeeFormDTO(props?.employee as any)
        : ''
      : props?.employee?.uuid || '';
    this.send_sms_notification = !!send_sms_notification || false;
    this.send_email_notification = false;
    this.comment = props?.comment || '';
    this.price = correctPrice(props?.price);
    this.client_type =
      props?.client?.client_type || ClientPersonalityType.Individual;
  }
}
