import {Record, List} from 'immutable';
import {IListProps} from './List';
import {
  SettingsModuleDependencyDTO,
  SettingsModuleDependencyModel,
} from '../internal';

export interface ISettingsModuleDependencyListModelProps extends IListProps {
  dependencies: SettingsModuleDependencyDTO[];
}

export interface ISettingsModuleDependencyListModelReturnType
  extends IListProps {
  dependencies: List<SettingsModuleDependencyModel>;
}

export class SettingsModuleDependencyListModel extends Record<ISettingsModuleDependencyListModelReturnType>(
  {
    dependencies: List(),
  },
) {
  constructor(
    {
      dependencies,
      ...props
    }: ISettingsModuleDependencyListModelProps = {} as ISettingsModuleDependencyListModelProps,
  ) {
    super({
      ...props,
      dependencies: List(
        dependencies.map(
          (dependency) => new SettingsModuleDependencyModel(dependency),
        ),
      ),
    });
  }
}
