import React from 'react';
import {StoreDocumentModel, StoreDocumentStatus} from '../../../../struture';
import styled, {useTheme, css} from 'styled-components';
import {StyledDescription} from '../../../../components/lib/Styled';
import {useLocation} from 'react-router';
import {useTranslation} from 'react-i18next';
import {Link} from '../../../../components/lib/DataDisplay';
import {Routes} from '../../../../services/types';
import {toDateByFormat} from '../../../../services/helpers';
import {MdEditDocument} from 'react-icons/md';
import {IoMdDocument} from 'react-icons/io';
import Color from 'color';
import {Tag} from 'antd';
import {DollarOutlined} from '@ant-design/icons';
import {correctPrice, textToUpperCase} from '@sportix/sportix-common-modules';
import {useStoredCompanies} from '../../../../hooks';

export interface IStorePostingDetailsViewProps {
  document: StoreDocumentModel | null;
  to?: string;
  showTag?: boolean;
  showTotal?: boolean;
}
const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`;

const StyledSpace = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 5px;
`;

const StyledDescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledLink = styled(Link)<{$draft: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
  gap: 5px;
  justify-content: flex-start;

  ${({$draft}) =>
    $draft &&
    css`
      color: ${({theme}) => theme.colors.warning};

      &:hover {
        color: ${({theme}) =>
          Color(theme.colors.warning).alpha(1).lighten(0.2).toString()};
      }
    `}
`;

const StyledTag = styled(Tag)`
  height: 17px;
  line-height: 1.3;
`;

export function StorePostingDetailsView({
  document,
  to,
  showTag,
  showTotal,
}: IStorePostingDetailsViewProps): JSX.Element {
  const {t} = useTranslation();
  const location = useLocation();
  const theme: any = useTheme();
  const {defaultCompany} = useStoredCompanies();

  const isDiscount = Number(document?.doc_discount_value || 0) > 0;

  const total =
    (isDiscount
      ? Number(document?.doc_sum_total_with_discount)
      : Number(document?.doc_sum_total)) || 0;

  return (
    <>
      {document ? (
        <StyledContainer>
          <StyledSpace>
            <StyledTitleContainer>
              {showTag ? (
                <StyledTag
                  color={Color(theme.tabs.success)
                    .alpha(1)
                    .darken(0.1)
                    .toString()}>
                  <StyledDescription
                    fontSize={10}
                    $color={Color(theme.colors.success)
                      .alpha(1)
                      .darken(0.65)
                      .toString()}>
                    {textToUpperCase(t('Bill of Lading'))}
                  </StyledDescription>
                </StyledTag>
              ) : null}
              <StyledLink
                $draft={document?.doc_status_text === StoreDocumentStatus.DRAFT}
                to={
                  to ||
                  `/${Routes.app}/${Routes.stores}/${Routes.storePosting}/${document?.uuid}`
                }
                tooltip={t('Open a detailed description of the posting')}
                state={{goBack: location.pathname}}>
                {document?.doc_status_text === StoreDocumentStatus.DRAFT ? (
                  <MdEditDocument color={theme.colors.warning} />
                ) : (
                  <IoMdDocument color={theme.colors.link} />
                )}
                {document?.doc_multi_currencies?.length ? (
                  <DollarOutlined />
                ) : null}

                {document?.doc_local_number}
                {document?.doc_status_text === StoreDocumentStatus.DRAFT ? (
                  <StyledDescription $color={theme.colors.warning}>
                    {` ( ${t('Draft')} )`}
                  </StyledDescription>
                ) : null}
              </StyledLink>
            </StyledTitleContainer>
            <StyledDescriptionContainer>
              {document?.doc_date ? (
                <StyledDescription>
                  {toDateByFormat(document?.doc_date, 'DD.MM.YYYY HH:mm')}
                </StyledDescription>
              ) : null}
              {document?.doc_input_number ? (
                <StyledDescription>
                  {`/ № ${document?.doc_input_number}`}
                </StyledDescription>
              ) : null}

              {showTotal ? (
                <StyledDescription>
                  {`/ ${t('the amount of the document')}: ${
                    defaultCompany?.currency_symbol || ''
                  }${correctPrice(total)}`}
                </StyledDescription>
              ) : null}
            </StyledDescriptionContainer>
          </StyledSpace>
        </StyledContainer>
      ) : null}
    </>
  );
}
