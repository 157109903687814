import {css} from 'styled-components';

export const invertColor = css`
  color: ${({theme}) => theme.colors.app};
`;

export const invertBorderColor = css`
  border-color: ${({theme}) => theme.colors.app};
  &:hover {
    border-color: ${({theme}) => theme.colors.componentBackground};
  }
  &:active {
    border-color: ${({theme}) => theme.colors.componentBackground};
  }
`;

export const invertSecondaryColor = css`
  color: ${({theme}) => theme.colors.componentBackground};
`;

export const invertInputAutofill = css`
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: ${({theme}) => theme.colors.app};
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
  }
`;
