import React from 'react';
import {isFunction, toDateByFormat} from '../../../services/helpers';
import {ChildrenMustBeAFunction} from '../../../components/lib/Errors';
import {IDefaultTabPanelProps} from '../../../components/lib/General';
import {ScheduleStatusModel} from '../../../struture';
import {OrderStatusListView} from './OrderStatusListView';

export interface IOrderStatusListPanelProps {
  children: (value: Pick<IDefaultTabPanelProps, 'tabs'>) => React.ReactNode;
  groupedStatuses: {[key: string]: ScheduleStatusModel[]};
}

export default function OrderStatusListPanel({
  children,
  groupedStatuses,
}: IOrderStatusListPanelProps): JSX.Element {
  const OrderStatusLists = groupedStatuses
    ? Object.entries(groupedStatuses).map(([key, value], index) => {
        return {
          key: `${key}`,
          title: toDateByFormat(key, 'DD MMMM YYYY'),
          defaultActiveKey:
            index === 0 ? toDateByFormat(key, 'DD MMMM YYYY') : undefined,
          renderer: () => <OrderStatusListView statuses={value} />,
        };
      })
    : [];

  if (!isFunction(children)) {
    throw new ChildrenMustBeAFunction('LoadingWithEmptyData');
  }

  return <>{children({tabs: OrderStatusLists})}</>;
}
