import React, {useCallback, useMemo} from 'react';
import {DefaultForm, IDefaultFormProps} from '../../../components/lib/General';
import {
  InvoiceItemFormDTO,
  InvoiceItemPositionableType,
  InvoiceModel,
  ProductModel,
} from '../../../struture';
import {InvoiceItemFields} from './InvoiceItemFields';
import {ENTER_KEYS} from '../../../services/const';
import {produce} from 'immer';
import {isFunction} from '../../../services/helpers';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../../../contex';
import {useInvoiceManager} from '../Managers';
import {correctPrice} from '@sportix/sportix-common-modules';

export interface InvoiceItemFormProps
  extends Omit<
    IDefaultFormProps<InvoiceItemFormDTO, InvoiceItemFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest' | 'onSuccess'
  > {
  invoice: InvoiceModel;
  loading?: boolean;
  item: InvoiceItemFormDTO;
  onSuccess: (
    value: InvoiceItemFormDTO | InvoiceItemFormDTO[],
  ) => Promise<void>;
}

export function InvoiceItemForm({
  loading,
  editMode,
  item,
  invoice,
  onCancel,
  onSuccess,
  ...rest
}: InvoiceItemFormProps) {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const {defaultPriceMargin, getProductPriceMargin} = useInvoiceManager();

  const multiCurrencies = useMemo(
    () => invoice?.inv_multi_currencies || [],
    [invoice?.inv_multi_currencies],
  );

  const isShowCurrencySelect = !!multiCurrencies?.length;

  const itemPrice =
    item?.item_positionable_type === InvoiceItemPositionableType.PRODUCT &&
    !item?.editMode
      ? getProductPriceMargin(
          item?.item_positionable_object as ProductModel,
          defaultPriceMargin,
        )
      : item?.item_price;

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Items'),
        `${
          editMode
            ? t('An error occurred during edit item')
            : t('An error occurred during add items')
        } : ${apiError?.message}`,
      );
    },
    [alert, editMode, t],
  );

  const onAmountEnter = useCallback(
    (e: KeyboardEvent) => {
      if (e?.target instanceof HTMLElement) {
        e?.preventDefault();
        e?.stopPropagation();
      }

      return true;
    },

    [],
  );

  const handleSuccess = useCallback(
    async (value: InvoiceItemFormDTO) => {
      const updatedValue = produce(value, (draft) => {
        if (!item?.editMode) {
          delete (draft as any).uuid;
        }

        if (!isShowCurrencySelect || draft.item_currency === '₴') {
          draft.item_currency = '';
        }

        delete draft.editMode;
        delete draft.item_positionable_object;
        delete (draft as any).price_margin;
        delete (draft as any).t;
        delete (draft as any).total;
      });

      if (isFunction(onSuccess)) {
        await onSuccess(item?.editMode ? updatedValue : [updatedValue]);
      }
    },
    [isShowCurrencySelect, item?.editMode, onSuccess],
  );

  const currency = multiCurrencies?.find(
    ({title}) => title === (item?.item_currency || '₴'),
  );

  const rate = Number(currency?.rate || 1);

  return (
    <DefaultForm<any, any>
      formKeyboardCodes={['Tab', ...ENTER_KEYS]}
      onEnterPress={onAmountEnter}
      className="invoice-item-form"
      formKeyboardEndSubmit
      withContext
      onSuccess={handleSuccess}
      editMode={editMode}
      cancelButtonText={t('Close')}
      initialValues={{
        ...item,
        item_currency: item?.item_currency || '₴',
        ...(!item?.editMode
          ? {
              price_margin: defaultPriceMargin?.uuid,
              total: correctPrice(Number(itemPrice) * rate * item?.item_amount),
              item_price: itemPrice,
            }
          : {
              price_margin: defaultPriceMargin?.uuid,
              total: correctPrice(Number(itemPrice) * rate * item?.item_amount),
            }),
      }}
      showNotify={false}
      onCancel={onCancel}
      notifyError={notifyError}
      {...rest}>
      <InvoiceItemFields
        loading={!!loading}
        multiCurrencies={multiCurrencies}
        isShowCurrencySelect={isShowCurrencySelect}
      />
    </DefaultForm>
  );
}
