import {List} from 'immutable';
import {
  ClientDTO,
  ClientFormDTO,
  IClientDTOProps,
  ClientModel,
  ClientListModel,
  ClientGroupFormDTO,
  ClientLegalFormDTO,
  ClientOperationDTO,
  ClientOperationFormDTO,
  ClientOperationModel,
  IClientOperationDTOProps,
  ClientOperationListModel,
} from '../internal';
import {listToArray} from '../../services/helpers';
import {WithoutRecursion} from '../type';
import {IClientListStatsProps} from '../../services/api/client';

export interface IClientListDTO {
  clients: ClientDTO[];
  total: number;
  stats?: IClientListStatsProps;
}

export interface IClientOperationListStats {
  total: string;
}
export interface IClientOperationListDTO {
  operations: ClientOperationDTO[];
  total: number;
  client: ClientDTO;
  stats: IClientOperationListStats;
}

export class ClientMapper {
  public static toClientDTO(
    client: ClientModel | IClientDTOProps,
    withoutMembers: boolean = false,
    withoutRecursion: WithoutRecursion[] = [],
  ): ClientDTO {
    return new ClientDTO(client, withoutMembers, [
      WithoutRecursion.client,
      ...withoutRecursion,
    ]);
  }

  public static toClientListDTO(
    clients: List<ClientModel> | IClientDTOProps[],
    total: number,
    withoutMembers: boolean = false,
    withoutRecursion: WithoutRecursion[] = [],
    stats?: IClientListStatsProps,
  ): IClientListDTO {
    const clientList = List.isList(clients)
      ? listToArray<ClientModel>(clients)
      : clients;

    return {
      clients: clientList?.map((client) =>
        ClientMapper.toClientDTO(client, withoutMembers, [
          WithoutRecursion.client,
          ...withoutRecursion,
        ]),
      ),
      total,
      stats,
    };
  }

  public static toClientModel(
    clientDTO: ClientDTO,
    withoutMembers: boolean = false,
    withoutRecursion: WithoutRecursion[] = [],
  ): ClientModel {
    return new ClientModel(clientDTO, withoutMembers, [
      WithoutRecursion.client,
      ...withoutRecursion,
    ]);
  }

  public static toClientListModel(
    clientDTOs: ClientDTO[],
    total: number,
    withoutMembers: boolean = false,
    withoutRecursion: WithoutRecursion[] = [],
  ): ClientListModel {
    return new ClientListModel({clients: clientDTOs, total}, withoutMembers, [
      WithoutRecursion.client,
      ...withoutRecursion,
    ]);
  }

  public static toClientFormDTO(clientModel: ClientModel): ClientFormDTO {
    return new ClientFormDTO(clientModel);
  }

  public static toClientLegalFormDTO(
    clientModel: ClientModel,
  ): ClientLegalFormDTO {
    return new ClientLegalFormDTO(clientModel);
  }

  public static toClientGroupFormDTO(
    clientModel: ClientModel,
    editMode?: boolean,
  ): ClientGroupFormDTO {
    return new ClientGroupFormDTO(clientModel, editMode);
  }

  public static toClientOperationDTO(
    operation: ClientOperationModel | IClientOperationDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): ClientOperationDTO {
    return new ClientOperationDTO(operation);
  }

  public static toClientOperationListDTO(
    operations: List<ClientOperationModel> | IClientOperationDTOProps[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
    client: ClientDTO,
    stats: IClientOperationListStats,
  ): IClientOperationListDTO {
    const clientOperationsList = List.isList(operations)
      ? listToArray<ClientOperationModel>(operations)
      : operations;

    return {
      operations: clientOperationsList?.map((client) =>
        ClientMapper.toClientOperationDTO(client, [
          WithoutRecursion.client,
          ...withoutRecursion,
        ]),
      ),
      total,
      client,
      stats,
    };
  }

  public static toClientOperationModel(
    clientOperationDTO: ClientOperationDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): ClientOperationModel {
    return new ClientOperationModel(clientOperationDTO, [
      WithoutRecursion.client,
      ...withoutRecursion,
    ]);
  }

  public static toClientOperationListModel(
    clientOperationDTOs: ClientOperationDTO[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
    client: ClientDTO,
    stats: IClientOperationListStats,
  ): ClientOperationListModel {
    return new ClientOperationListModel(
      {operations: clientOperationDTOs, total, client, stats},
      [WithoutRecursion.client, ...withoutRecursion],
    );
  }

  public static toClientOperationFormDTO(
    clientModel: ClientModel,
  ): ClientOperationFormDTO {
    return new ClientOperationFormDTO(clientModel);
  }
}
