import dayjs, {Dayjs, UnitType, ManipulateType} from 'dayjs';
import {curry, reduce, map, compose, split} from '../helpers';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);

export type DateType = string | Date | Dayjs;

export const toDateJS = (
  value: DateType,
  format: string | undefined = undefined,
): Dayjs => dayjs(value, format);

export const toNativeDate = (value: DateType = new Date()): Date =>
  dayjs(value).toDate();

export const toNativeDateParseFormat = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  value: DateType = new Date(),
  parseFormat: string,
): Date => dayjs(value, parseFormat).toDate();

export const toStringDate = (value: DateType = new Date()): string =>
  dayjs(value).toString();

export const toISOStringDate = (value: DateType = new Date()): string =>
  dayjs(value).toISOString();

export const toUTCStringDate = (value: DateType = new Date()): string =>
  dayjs(value).utc().toString();

export const toDateByFormat = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  value: DateType = new Date(),
  format: string,
): string => dayjs(value).format(format);

export const toDateByParseFormat = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  value: DateType = new Date(),
  parseFormat: string,
  format: string,
): string => dayjs(value, parseFormat).format(format);

export const getWeekDayNameByParseFormat = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  value: DateType = new Date(),
  parseFormat: string,
): string => dayjs(value, parseFormat).locale('en').format('dddd');

export const toUtcDateByFormat = (value: DateType, format: string) =>
  dayjs(value).utc().format(format);

export const addToDate = curry(
  (number: number, name: ManipulateType, value: DateType): Dayjs =>
    dayjs(value).add(number, name),
);

export const setDateValue = curry(
  (setProps: {[key: string]: number}, value: DateType): Dayjs => {
    const date = dayjs(value);

    return reduce(
      (acc, [key, value]) => {
        acc = acc.set(key as UnitType, value);
        return acc;
      },
      date,
      Object.entries(setProps),
    );
  },
);

export const splittingTimeToDate = compose<any>(
  map((value: DateType) => toDateJS(value, 'HH:mm')),
  split('-'),
);

export const isDateGreaterThanNow = (value: DateType): boolean => {
  return dayjs(value).unix() > dayjs().unix();
};

export const isDateGreater = (
  dateOne: DateType,
  dateTwo: DateType,
): boolean => {
  return dayjs(dateOne).unix() > dayjs(dateTwo).unix();
};

export const isDateGreaterOrEqual = (
  dateOne: DateType,
  dateTwo: DateType,
): boolean => {
  return dayjs(dateOne).unix() >= dayjs(dateTwo).unix();
};

export const isDateLessOrOrEqual = (
  dateOne: DateType,
  dateTwo: DateType,
): boolean => !isDateGreaterOrEqual(dateOne, dateTwo);

export const isDateLessThanNow = (value: DateType): boolean =>
  !isDateGreaterThanNow(value);

export const getDiffDaysFromNow = (value: DateType): number =>
  dayjs(value).diff(new Date(), 'days');

export const getDiffDays = (starDate: DateType, endDate: DateType): number =>
  dayjs(endDate).diff(starDate, 'days');

export const getDiffHoursFromNow = (value: DateType): number =>
  dayjs(value).diff(new Date(), 'hours');

export const getDiffMinutesFromNow = (value: DateType): number =>
  dayjs(value).diff(new Date(), 'minutes');

export const getStartOfMonthDate = (format = 'YYYY-MM-DD'): string =>
  toDateByFormat(dayjs(new Date()).startOf('months'), format);

export const getEndOfMonthDate = (format = 'YYYY-MM-DD'): string =>
  toDateByFormat(dayjs(new Date()).endOf('months'), format);

export const correctDate = (date: string): string => {
  const updatedDate = dayjs(date).toDate();

  return dayjs(
    dayjs(
      new Date(
        Date.UTC(
          updatedDate.getUTCFullYear(),
          updatedDate.getUTCMonth(),
          updatedDate.getUTCDate(),
          updatedDate.getUTCHours(),
          updatedDate.getUTCMinutes(),
          updatedDate.getUTCSeconds(),
        ),
      ).toISOString(),
    )
      .utc()
      .format('YYYY-MM-DD HH:mm:ss'),
    'YYYY-MM-DD HH:mm:ss',
  )
    .toDate()
    .toString();
};

export const toStringDateByParseFormat = (
  value: DateType = new Date(),
  parseFormat: string,
): string => dayjs(value, parseFormat).toString();

export function sortedByDate(property) {
  return (a, b) => {
    const dateA: any = new Date(
      toStringDateByParseFormat(a[property], 'DD.MM.YYYY HH:mm'),
    );
    const dateB: any = new Date(
      toStringDateByParseFormat(b[property], 'DD.MM.YYYY HH:mm'),
    );
    return dateA - dateB;
  };
}

export default dayjs;
