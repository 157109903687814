import {
  CashBoxModel,
  ClientModel,
  EmployeeModel,
  PAYMENT_METHOD,
  PaymentModel,
  ScheduleCalendarModel,
  StoreDocumentType,
} from '../internal';
import {correctPrice, DateType, toMoment} from '../../services/helpers';

export enum PaymentScheduleType {
  Prepayment = 'Prepayment',
  Refunds = 'Refunds',
}

export enum PaymentSchedulePostActionType {
  SetScheduleStatus = 'set_schedule_status',
}

export interface IPaymentSchedulePostAction {
  action: PaymentSchedulePostActionType;
  value: string;
}

export interface IPaymentScheduleAdditionalParams {
  //extends Partial<IPaymentSchedulePostAction>
  editMode?: boolean;
  type?: PaymentScheduleType;
  cashier: EmployeeModel;
  payment_post_action?: IPaymentSchedulePostAction | null;
  schedule: ScheduleCalendarModel;
  payment_default_sum?: string;
  client_is_required?: boolean;
  doc_sum_paid?: string;
  documentType?: StoreDocumentType;
}

export class PaymentScheduleFormDTO {
  public uuid: string;
  public payment_date: DateType;
  public payment_client_uuid: string | ClientModel;
  public payment_manager_uuid: string | EmployeeModel;
  public payment_price: string;
  public payment_cashbox_uuid: string | CashBoxModel;
  public payment_method: PAYMENT_METHOD;
  public payment_comment: string;
  public payment_post_action?: IPaymentSchedulePostAction | null;
  public payment_max_sum?: number;
  public payment_default_sum?: string;
  public client_is_required?: boolean;
  public doc_type?: StoreDocumentType;

  constructor(
    props: PaymentModel,
    {
      editMode,
      cashier,
      payment_post_action,
      schedule,
      type,
      payment_default_sum,
      doc_sum_paid,
      client_is_required,
      documentType,
    }: IPaymentScheduleAdditionalParams,
  ) {
    this.uuid = props?.uuid || '';
    this.payment_date = props?.payment_date
      ? PaymentScheduleFormDTO.toDate(props?.payment_date || '')
      : PaymentScheduleFormDTO.toDate(new Date() as any);
    this.payment_price = doc_sum_paid
      ? correctPrice(doc_sum_paid || 0)
      : correctPrice(props?.payment_sum || 0);
    this.payment_comment = props?.payment_comment || '';
    this.payment_cashbox_uuid = editMode ? props?.company_cashbox || '' : '';
    this.payment_method = props?.payment_method || PAYMENT_METHOD.Cash;
    this.payment_client_uuid =
      this.paymentAgentUuidFactory(props?.payment_agent_object, editMode) || '';
    this.payment_manager_uuid = cashier;
    this.payment_post_action = payment_post_action || null;
    this.payment_max_sum =
      type === PaymentScheduleType.Refunds
        ? Number(schedule?.sum_paid || 0)
        : -1;
    this.payment_default_sum = payment_default_sum || '0.00';
    this.client_is_required = client_is_required ?? true;
    this.doc_type = documentType ?? undefined;
  }

  private static toDate(birthday: string): any {
    return birthday ? toMoment(birthday) : '';
  }

  private paymentAgentUuidFactory(
    paymentAgentObject: ClientModel | EmployeeModel | undefined,
    editMode?: boolean,
  ) {
    if (paymentAgentObject instanceof ClientModel && paymentAgentObject?.uuid) {
      return paymentAgentObject;
    }

    return '';
  }
}
