import React, {useEffect, useRef, useState, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import Label from '../Label';
import type {Moment} from 'moment';
import DatePicker from '../../General/DatePicker';
import styled, {css} from 'styled-components';
import {isFunction, toDateByFormat} from '../../../../services/helpers';
import type {PickerDateProps} from 'antd/es/date-picker/generatePicker';

export interface ListTimePickerProps
  extends Omit<PickerDateProps<Moment>, 'onChange' | 'size'> {}

export interface IListDatePickerProps extends ListTimePickerProps {
  label?: string;
  tooltipTitle?: string;
  loading?: boolean;
  fullWidth?: boolean;
  defaultDatePickerValue?: Moment | undefined;
  onChange: (value: any) => Promise<void> | void;
}

const StyledDatePicker = styled(DatePicker)<{$fullWidth: boolean}>`
  min-width: 288px;

  ${({$fullWidth}) =>
    $fullWidth &&
    css`
      width: 100%;
    `}
`;

export function ListDatePicker({
  label,
  tooltipTitle,
  loading,
  fullWidth,
  defaultDatePickerValue,
  onChange,
  ...rest
}: IListDatePickerProps): JSX.Element {
  const {t} = useTranslation();

  const [pikerValue, setPickerValue] = useState<any>(null);

  const once = useRef(false);

  useEffect(() => {
    if (defaultDatePickerValue && !pikerValue && !once.current) {
      once.current = true;
      setPickerValue(defaultDatePickerValue);
    }
  }, [defaultDatePickerValue, pikerValue]);

  const handleChange = useCallback(
    async (value: any) => {
      setPickerValue(value);

      if (isFunction(onChange)) {
        await onChange(value as any);
      }
    },
    [onChange],
  );

  return (
    <Label label={label} tooltipTitle={tooltipTitle} fullWidth={fullWidth}>
      <StyledDatePicker
        disabled={loading}
        size="middle"
        $fullWidth={!!fullWidth}
        placeholder={t('Select a date')}
        value={pikerValue}
        onChange={handleChange}
        {...rest}
      />
    </Label>
  );
}
