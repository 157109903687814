import React, {useCallback} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {AuthRouteLayout} from '../../../components/lib/Layout';
import {DefaultCompaniesForm} from '../Forms';
import {StyledContainer, StyledTextFormat, Header} from '../Show';
import {useStoredCompanies} from '../../../hooks';
import {useNavigate} from 'react-router';
import {Routes} from '../../../services/types';

export default withTranslation()(SetDefaultCompanyPage);

export interface ISetDefaultCompanyPageProps extends WithTranslation {}

function SetDefaultCompanyPage({t}: ISetDefaultCompanyPageProps): JSX.Element {
  const navigate = useNavigate();

  const {companies, setDefaultCompany, defaultCompanyUuid} =
    useStoredCompanies();

  const handleSetDefaultCompany = useCallback(
    async (value: {default_company: string}): Promise<void> => {
      await setDefaultCompany(value);
      navigate(`/${Routes.app}/dashboard`);
    },
    [navigate, setDefaultCompany],
  );

  return (
    <AuthRouteLayout>
      <StyledContainer>
        <Header>
          <StyledTextFormat>{t('Check default Company')}</StyledTextFormat>
        </Header>
        <DefaultCompaniesForm<any, any>
          companies={companies}
          initialValues={{default_company: defaultCompanyUuid || ''}}
          className="CreateCompanyForm-form"
          onSuccess={handleSetDefaultCompany}
        />
      </StyledContainer>
    </AuthRouteLayout>
  );
}
