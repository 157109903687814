import {Moment} from 'moment';
import {apiDelete, apiGet, apiPatch, apiPost, apiPut} from '../core/api';
import {contentApiUrl} from '../const';
import {
  ApiAnswer,
  IEntityProps,
  IListSearchProps,
  OPERATION_TYPE,
  Routes,
} from '../types';
import Validate from '../validate/Validate';
import {
  CashBoxMapper,
  CashBoxType,
  ClientRefundsType,
  ICashBoxDTOProps,
  IPaymentDTOProps,
  IPaymentListDTO,
  PAYMENT_AGENT_TYPE,
  PAYMENT_DESTINATION,
  PAYMENT_METHOD,
  PaymentDTO,
  PaymentFormDTO,
  PaymentMapper,
  ScheduleCalendarDTO,
} from '../../struture';
import {toDateByFormat, dateToIsoString} from '../helpers';
import {
  IPaymentScheduleListStats,
  IScheduleStatusListProps,
  PaymentScheduleOperationType,
} from './orders';

export const PAYMENT_INITIAL_PARAM = new PaymentFormDTO();

const {
  uuid,
  payment_agent_uuid,
  payment_agent_type,
  ...PAYMENT_REQUIRED_PARAM
} = PAYMENT_INITIAL_PARAM;

export const PAYMENT_REQUIRED_FIELD = {
  ...PAYMENT_REQUIRED_PARAM,
};

export const PAYMENT_TYPE = [
  {
    title: 'Receipts',
    uuid: OPERATION_TYPE.IN,
    description: 'Operations with financial receipts to the Company',
  },
  {
    title: 'Expenses',
    uuid: OPERATION_TYPE.OUT,
    description: 'Operations with financial expenses',
  },
];

export const FROM_PAYMENT_METHOD = (value: OPERATION_TYPE) => [
  {
    title: PAYMENT_METHOD.Cash,
    uuid: PAYMENT_METHOD.Cash,
  },
  {
    title: PAYMENT_METHOD.Bank,
    uuid: PAYMENT_METHOD.Bank,
  },
  ...(value === OPERATION_TYPE.IN
    ? [
        {
          title: PAYMENT_METHOD.Account,
          uuid: PAYMENT_METHOD.Account,
        },
      ]
    : []),
];

export const PAYMENT_METHOD_FORM = [
  {
    title: 'tCash',
    uuid: PAYMENT_METHOD.Cash,
  },
  {
    title: 'Cashless',
    uuid: PAYMENT_METHOD.Bank,
  },
];

export const convertedPaymentMethodToCashBox = {
  [PAYMENT_METHOD.Cash]: CashBoxType.Cash,
  [PAYMENT_METHOD.Bank]: CashBoxType.Bank,
  [PAYMENT_METHOD.Account]: '',
};
export const convertedCashBoxMethodToPayment = {
  [CashBoxType.Cash]: PAYMENT_METHOD.Cash,
  [CashBoxType.Bank]: PAYMENT_METHOD.Bank,
};

export const FROM_PAYMENT_AGENT_TYPE = (value: OPERATION_TYPE) => [
  {
    title: 'Counterparties',
    uuid: PAYMENT_AGENT_TYPE.Client,
    description:
      value === OPERATION_TYPE.IN
        ? 'Advance payment'
        : 'Customer refunds or other payments',
  },
  {
    title: 'Employees',
    uuid: PAYMENT_AGENT_TYPE.Employee,
    description:
      value === OPERATION_TYPE.IN
        ? "Replenishment of the Employee's personal balance in the system"
        : 'Payments to the Employee',
    disabled: true,
  },
];

export const FORM_PAYMENT_DESTINATION = [
  {
    title: 'Operational payment',
    uuid: PAYMENT_DESTINATION.Operation,
  },
  {
    title: 'By order',
    uuid: PAYMENT_DESTINATION.ScheduledOrder,
  },
  {
    title: "To the Client's personal account",
    uuid: PAYMENT_DESTINATION.Account,
  },
  {
    title: 'Client subscription',
    uuid: PAYMENT_DESTINATION.AbonementSubscription,
  },
  {
    title: 'Client abonement',
    uuid: PAYMENT_DESTINATION.ClientAbonement,
  },
];

export interface IPaymentListAnswer extends Omit<ApiAnswer, 'total'> {
  payments: IPaymentDTOProps[];
  stats: IPaymentScheduleListStats;
  cashbox: ICashBoxDTOProps;
}

export interface IPaymentListParam
  extends Partial<IListSearchProps>,
    IEntityProps {
  date_start?: Date | Moment | string;
  date_end?: Date | Moment | string;
  companyUuid: string;
  payment_type?: PaymentScheduleOperationType;
  cashbox_uuid: string;
}

export interface IPaymentAnswer extends ApiAnswer {
  payment: IPaymentDTOProps;
}

export async function getCompanyPaymentList({
  companyUuid,
  date_start,
  date_end,
  keywords,
  offset = 0,
  limit = 10,
  payment_type = PaymentScheduleOperationType.ALL,
  cashbox_uuid,
}: IPaymentListParam): Promise<IPaymentListDTO> {
  Validate.string({value: companyUuid});

  const {payments, count, stats, cashbox} = await apiGet<
    IListSearchProps & {
      date_start: any;
      date_end: any;
      payment_type: PaymentScheduleOperationType;
      cashbox_uuid: string;
    },
    IPaymentListAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/payments`, {
    offset,
    limit,
    keywords,
    date_end: date_end ? toDateByFormat(date_end, 'YYYY-MM-DD') : '',
    date_start: date_start ? toDateByFormat(date_start, 'YYYY-MM-DD') : '',
    payment_type,
    cashbox_uuid,
  });

  return PaymentMapper.toPaymentListDTO(payments, count, stats, cashbox);
}

export async function getPaymentById(paymentUuid: string): Promise<PaymentDTO> {
  const {payment} = await apiGet<null, IPaymentAnswer>(
    `${contentApiUrl}/payments/${paymentUuid}`,
  );

  return PaymentMapper.toPaymentDTO(payment);
}

export async function createCompanyPayment(
  value: PaymentFormDTO,
  companyUuid: string,
): Promise<PaymentDTO> {
  const {
    payment_comment,
    payment_date,
    payment_sum,
    payment_group_uuid,
    payment_type,
    payment_destination,
    payment_agent_type,
    payment_method,
    payment_agent_uuid,
    payment_destination_object_uuid,
    payment_cashbox_uuid,
    payment_cashier_uuid,
  } = value;

  Validate.string({value: companyUuid});
  Validate.string({value: payment_group_uuid});
  Validate.string({value: payment_type});
  Validate.number({value: payment_sum});

  const {payment} = await apiPost<
    Omit<PaymentFormDTO, 'uuid' | 'dateToString'>,
    IPaymentAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/payments`, {
    payment_comment,
    payment_date: dateToIsoString(payment_date as any),
    payment_sum,
    payment_group_uuid,
    payment_type,
    payment_destination,
    payment_agent_type,
    payment_method,
    payment_agent_uuid,
    payment_cashbox_uuid,
    payment_cashier_uuid,
    ...(payment_destination_object_uuid
      ? {payment_destination_object_uuid}
      : {}),
  });

  return PaymentMapper.toPaymentDTO(payment);
}

export async function updateCompanyPayment(
  value: PaymentFormDTO,
): Promise<PaymentDTO> {
  const {
    payment_comment,
    payment_date,
    payment_sum,
    payment_group_uuid,
    payment_type,
    payment_destination,
    payment_agent_type,
    payment_method,
    payment_agent_uuid,
    uuid,
    payment_destination_object_uuid,
    payment_cashbox_uuid,
    payment_cashier_uuid,
  } = value;

  const {payment} = await apiPut<
    Omit<PaymentFormDTO, 'uuid' | 'dateToString'>,
    IPaymentAnswer
  >(`${contentApiUrl}/payments/${uuid}`, {
    payment_comment,
    payment_date: dateToIsoString(payment_date as any),
    payment_sum,
    payment_group_uuid,
    payment_type,
    payment_destination,
    payment_agent_type,
    payment_method,
    payment_agent_uuid,
    payment_cashbox_uuid,
    payment_cashier_uuid,
    ...(payment_destination_object_uuid
      ? {payment_destination_object_uuid}
      : {}),
  });

  return PaymentMapper.toPaymentDTO(payment);
}

export async function deleteCompanyPayment(
  paymentUuid: string,
): Promise<PaymentDTO> {
  const {payment} = await apiDelete<any, IPaymentAnswer>(
    `${contentApiUrl}/payments/${paymentUuid}`,
  );

  return PaymentMapper.toPaymentDTO(payment);
}

export async function updatePaymentCashBox({
  payment_agent_type,
  payment_method,
  payment_agent_uuid,
  uuid,
  payment_cashbox_uuid,
}: PaymentFormDTO): Promise<PaymentDTO> {
  const {payment} = await apiPatch<Partial<PaymentFormDTO>, IPaymentAnswer>(
    `${contentApiUrl}/payments/${uuid}/method`,
    {
      payment_agent_type,
      payment_method,
      payment_agent_uuid,
      payment_cashbox_uuid,
    },
  );

  return PaymentMapper.toPaymentDTO(payment);
}
