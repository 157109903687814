import React from 'react';
import {Routes, Route} from 'react-router-dom';
import {AppRouteLayout} from '../components/lib/Layout';
import {
  StoreSaleListPage,
  StoreSaleManager,
  StoreSaleCategoryListGUIPage,
  StoreSaleCategoryGUIPage,
} from '../views/Stores';
import {Routes as RoutesType} from '../services/types';
import {KeyboardManager} from '../contex';

export function StoreSaleRoutesNavigator() {
  return (
    <KeyboardManager>
      <StoreSaleManager>
        <Routes>
          <Route
            path={`/`}
            element={
              <AppRouteLayout>
                <StoreSaleListPage />
              </AppRouteLayout>
            }
          />
          <Route
            path={`/${RoutesType.categories}`}
            element={
              <AppRouteLayout>
                <StoreSaleCategoryListGUIPage />
              </AppRouteLayout>
            }
          />

          <Route
            path={`/${RoutesType.categories}/:categoryId/*`}
            element={
              <AppRouteLayout>
                <StoreSaleCategoryGUIPage />
              </AppRouteLayout>
            }
          />
        </Routes>
      </StoreSaleManager>
    </KeyboardManager>
  );
}
