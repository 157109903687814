import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {List} from 'immutable';
import {
  Table,
  TableActionCell,
} from '../../../components/lib/libV2/DataDisplay';
import {isListToArray} from '../../../services/helpers';
import {EmployeeServicesEditButtons} from '../../EmployeeService';
import {
  EmployeeListModel,
  EmployeeMapper,
  EmployeeModel,
  IEmployeeServiceFormDTOProps,
  ServiceModel,
  CompanyOrderBonusGroupType,
} from '../../../struture';
import {EMPLOYEE_TABS} from '../../Employees';
import {TableLinkCell} from '../../../components/lib/libV2/DataDisplay';
import {useAcl} from '../../../contex';
import {IUseServiceEmployeeListProps} from '../../../hooks/useServiceEmployeeList';
import {useStoredCompanies} from '../../../hooks';
import {Routes, TIME_LIST_SERVICES} from '../../../services/types';
import {Tooltip} from 'antd';
import {StyledDescription, StyledTitle} from '../../../components/lib/Styled';
import styled from 'styled-components';

export interface IEmployeeServiceListProps {
  employees: List<EmployeeModel> | null;
  loading: boolean;
  handleUpdate: (service: IEmployeeServiceFormDTOProps) => Promise<void>;
  handleAdd: (employee: EmployeeModel) => Promise<void>;
  rowSelection: any;
  handleDelete: (employee: EmployeeModel) => Promise<void>;
  pageSize: number;
  total: number;
  refresh: (
    value: Partial<IUseServiceEmployeeListProps> & {page: number},
  ) => Promise<EmployeeListModel | void>;
  price: string | undefined;
  period: TIME_LIST_SERVICES;
  service: ServiceModel | null;
  page: number;
  daleyFocus: (ms?: number) => void;
}

const StyledPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  & > span:first-of-type {
    margin-right: 5px;
  }
`;

const Description = styled(StyledDescription)`
  text-decoration: line-through;
`;

export default function ServiceEmployeeList({
  employees,
  loading,
  handleUpdate,
  handleDelete,
  rowSelection,
  handleAdd,
  total,
  pageSize,
  price,
  refresh,
  period,
  service,
  page,
  daleyFocus,
}: IEmployeeServiceListProps) {
  const {t} = useTranslation();

  const {employee, service: serviceAccess} = useAcl((acl) => acl);
  const {defaultCompany} = useStoredCompanies();

  const [editingUuid, setEditingUuid] = useState<string>('');

  const isEditingCell = useCallback(
    (serviceEmployee: EmployeeModel): boolean =>
      serviceEmployee.uuid === editingUuid,
    [editingUuid],
  );

  const handleEditService = useCallback(
    (setFieldsValue: any, serviceEmployee: EmployeeModel): void => {
      setFieldsValue({
        price: serviceEmployee?.employee_service?.price,
      });

      setEditingUuid(serviceEmployee?.uuid as string);
    },
    [],
  );

  const handleCancelEdit = useCallback((): void => {
    setEditingUuid('');
  }, []);

  const onPriceEditSuccess = useCallback(
    async (value: any): Promise<void> => {
      await handleUpdate(value);
      handleCancelEdit();
    },
    [handleUpdate, handleCancelEdit],
  );

  const columns = [
    {
      width: '33%',
      title: t('Employee'),
      key: 'employee',
      render: (serviceEmployee: EmployeeModel) =>
        employee?.read ? (
          <TableLinkCell
            tooltip="Open a detailed description of the employee"
            fullPath={`/${Routes.app}/${Routes.employees}/${serviceEmployee?.uuid}`}>
            {serviceEmployee?.fullName}
          </TableLinkCell>
        ) : (
          serviceEmployee?.fullName
        ),
    },
    {
      width: '33%',
      title: t('Price'),
      key: 'price',
      align: 'right' as any,
      render: (serviceEmployee: EmployeeModel) => {
        const isDifferentPrice =
          serviceEmployee?.employee_service?.price &&
          price &&
          Number(serviceEmployee?.employee_service?.price) !== Number(price);

        return (
          <>
            {defaultCompany ? (
              <>
                {serviceEmployee?.employee_service?.updated_at &&
                isDifferentPrice ? (
                  <StyledPriceContainer>
                    <Tooltip title={t("The price of an employee's service")}>
                      <StyledTitle>
                        {`${defaultCompany?.currency_symbol} ${
                          serviceEmployee?.employee_service?.price || '0.00'
                        } / `}
                      </StyledTitle>
                    </Tooltip>
                    <Tooltip title={t('The price of the service')}>
                      <Description fontSize={14}>{price || '0.00'}</Description>
                    </Tooltip>
                  </StyledPriceContainer>
                ) : (
                  <StyledTitle>
                    {`${defaultCompany?.currency_symbol} ${price || '0.00'}`}
                  </StyledTitle>
                )}
              </>
            ) : null}
          </>
        );
      },
      onCell: (serviceEmployee: EmployeeModel) => ({
        inputType: 'number',
        formItemName: 'price',
        title: t('Price'),
        editing: isEditingCell(serviceEmployee),
        autoFocus: true,
      }),
    },
    {
      width: '33%',
      align: 'center' as any,
      title: t('Actions'),
      key: 'actions',
      fixed: 'right' as any,
      render: (serviceEmployee: EmployeeModel) => {
        return (
          <TableActionCell>
            <EmployeeServicesEditButtons
              servicePeriod={period}
              disabled={!serviceAccess?.manage}
              loadingSubmit={false}
              item={EmployeeMapper.toEmployeeServiceFormDTO(serviceEmployee)}
              handleEdit={handleUpdate}
              onDelete={() => handleDelete(serviceEmployee)}
              handleAdd={() => handleAdd(serviceEmployee)}
              isOwnService={!!serviceEmployee?.employee_service?.updated_at}
              service={service}
              employeeServiceBonuses={(
                serviceEmployee?.bonus_list || []
              ).filter(({groups}) =>
                groups?.includes(CompanyOrderBonusGroupType.Service),
              )}
              daleyFocus={daleyFocus}
            />
          </TableActionCell>
        );
      },
    },
  ];

  return (
    <Table
      loading={loading}
      dataSource={isListToArray(employees as any)}
      columns={columns}
      rowSelection={rowSelection}
      total={total}
      pageSize={pageSize}
      onChange={refresh}
      page={page}
    />
  );
}
