import {List} from 'immutable';
import {
  PaymentDTO,
  IClientSubscriptionableDTOProps,
  ClientSubscriptionableDTO,
  IClientSubscriptionModelReturnType,
  PaymentModel,
  ClientSubscriptionableMapper,
  PaymentMapper,
} from '../internal';
import {correctPrice} from '../../helpers';
import {CLIENT_TYPE, SUBSCRIPTION_STATUS} from '../type';

export interface IClientSubscriptionDTOProps {
  uuid: string;
  start_date: string;
  end_date: string;
  comment: string;
  period_amount: number;
  price: string;
  created_at: string;
  updated_at: string;
  payments: List<PaymentModel>;
  type: CLIENT_TYPE;
  status: SUBSCRIPTION_STATUS;
  subscriptionable: IClientSubscriptionableDTOProps;
}

export class ClientSubscriptionDTO {
  public uuid: string;

  public start_date: string;

  public end_date: string;

  public comment: string;

  public period_amount: number;

  public price: string;

  public created_at: string;

  public updated_at: string;

  public payments: PaymentDTO[];

  public type: CLIENT_TYPE;

  public status: SUBSCRIPTION_STATUS;

  public subscriptionable: ClientSubscriptionableDTO;

  constructor(
    props: IClientSubscriptionModelReturnType | IClientSubscriptionDTOProps,
  ) {
    this.uuid = props?.uuid || '';
    this.start_date = props?.start_date || '';
    this.end_date = props?.end_date || '';
    this.comment = props?.comment || '';
    this.period_amount = props?.period_amount || 0;
    this.price = correctPrice(props?.price);
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
    this.type = props?.type || CLIENT_TYPE.PERSONAL;
    this.status = props?.status || SUBSCRIPTION_STATUS.DRAFT;

    this.payments = PaymentMapper.toPaymentListDTO(
      props?.payments || [],
      0,
    ).payments;

    this.subscriptionable =
      ClientSubscriptionableMapper.toClientSubscriptionableDTO(
        props?.subscriptionable,
      );
  }
}
