import {Record, List} from 'immutable';
import {ListModel, ListProps} from './List';
import Payment, {PaymentProps} from './Payment';

export interface IPaymentListProps extends Partial<ListProps> {
  payments: PaymentProps[];
}

export interface IPaymentListReturnType
  extends Omit<IPaymentListProps, 'payments'> {
  payments: List<Payment[]>;
}

export default class PaymentList extends Record<IPaymentListReturnType>({
  payments: List(),
  ...ListModel.toJS(),
} as any) {
  /**
   * @constructor
   * @param {{clients:[]}} param0
   */
  constructor({payments = [], ...props}: IPaymentListProps) {
    const options: any = {};
    if (Array.isArray(payments)) {
      options.payments = List(
        payments.map((subscription) => new Payment(subscription)),
      );
    }

    super({
      ...props,
      ...options,
    });
  }
}
