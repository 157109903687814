import React, {forwardRef} from 'react';
import styled from 'styled-components';

export interface IOverviewBottomViewProps {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
}

const StyledContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  min-height: 85px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px -6px 4px -1px;

  background-color: ${({theme}) => theme.background.primary};
  padding: 10px 30px;
  overflow: hidden;
`;

export const OverviewBottomView = forwardRef(function OverviewBottomView(
  {children, className}: IOverviewBottomViewProps,
  ref: any,
): JSX.Element {
  return (
    <StyledContainer ref={ref} className={className}>
      {children}
    </StyledContainer>
  );
});
