import React from 'react';
import {useTranslation} from 'react-i18next';
import styled, {useTheme} from 'styled-components';
import {StoreDocumentStatsDTO} from '../../../../struture';
import {StyledTitle} from '../../../../components/lib/Styled';
import {correctPrice, toDateByFormat} from '../../../../services/helpers';

export interface IStoreSaleSummaryViewProps {
  date?: Date;
  currency?: string;
  stats?: StoreDocumentStatsDTO | null;
}

const StyledStatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`;

const StyledStatsItem = styled.div`
  width: 100%;
`;

export function StoreSaleSummaryView({
  date,
  stats,
  currency,
}: IStoreSaleSummaryViewProps): React.JSX.Element {
  const {t} = useTranslation();
  const theme: any = useTheme();

  const sumTotal = Number(stats?.sum_total || 0);
  const sumPaid = Number(stats?.sum_paid || 0);
  const sumUnPaid = Number(stats?.sum_unpaid || 0);

  // ${t('for')} "${toDateByFormat(
  //           date,
  //           'DD.MM.YYYY',
  //         )}"
  return (
    <StyledStatsContainer>
      <StyledStatsItem>
        <StyledTitle
          style={{marginTop: 4}}
          $color={theme.colors.secondary}
          fontSize={15}>{`${t('Total operation sum')}, ${
          currency || ''
        }: `}</StyledTitle>
        <StyledTitle fontSize={16} bold>
          {correctPrice(sumTotal)}
        </StyledTitle>
      </StyledStatsItem>
      {sumPaid < sumTotal ? (
        <StyledStatsItem>
          <StyledTitle $color={theme.colors.secondary} fontSize={15}>
            {`${t('Real cashbox income')}, ${currency || ''}: `}
          </StyledTitle>
          <StyledTitle fontSize={16} color={'green'}>
            {correctPrice(sumPaid)}
          </StyledTitle>
        </StyledStatsItem>
      ) : null}
      {sumUnPaid > 0 ? (
        <StyledStatsItem>
          <StyledTitle $color={theme.colors.secondary} fontSize={15}>
            {`${t('Debit payment')}, ${currency || ''}: `}
          </StyledTitle>
          <StyledTitle fontSize={16} color={'red'}>
            {correctPrice(sumUnPaid)}
          </StyledTitle>
        </StyledStatsItem>
      ) : null}
    </StyledStatsContainer>
  );
}
