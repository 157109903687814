import {MenuItem} from '../../../components/lib/Layout';

export type GroupedMenuItems = Record<string, MenuItem[]>;

export function groupByMenuGroupKey(items: MenuItem[]): GroupedMenuItems {
  if (!Array.isArray(items)) return {} as GroupedMenuItems;

  return items.reduce<GroupedMenuItems>(
    (acc: GroupedMenuItems, item: MenuItem) => {
      const group: string = item.group?.title ?? 'default';

      if (!acc[group]) {
        acc[group] = [];
      }

      acc[group].push(item);

      return {
        ...acc,
      };
    },
    {} as GroupedMenuItems,
  );
}
