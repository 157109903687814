import {Record} from 'immutable';
import {
  EmployeeDTO,
  ServiceDTO,
  ServiceGroupSessionDTO,
  EmployeeModel,
  EmployeeMapper,
  ServiceMapper,
  ServiceModel,
} from '../internal';
import {WithoutRecursion} from '../type';

export interface IServiceGroupSessionModelReturnType {
  uuid: string;
  title: string;
  description: string;
  start_date: string;
  end_date: string;
  weekdays: string;
  max_attenders: number;
  status: number;
  created_at: string;
  updated_at: string;
  service: ServiceModel | ServiceDTO;
  duration: number;
  manager: EmployeeModel | EmployeeDTO;
  price: string;
}

export class ServiceGroupSessionModel extends Record<IServiceGroupSessionModelReturnType>(
  {
    uuid: '',
    title: '',
    description: '',
    start_date: '',
    end_date: '',
    weekdays: '',
    max_attenders: 0,
    status: 0,
    created_at: '',
    updated_at: '',
    service: {} as ServiceModel,
    duration: 0,
    manager: {} as EmployeeModel,
    price: '',
  },
) {
  constructor(
    props: ServiceGroupSessionDTO = {} as ServiceGroupSessionDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IServiceGroupSessionModelReturnType = {
      ...props,

      manager:
        withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
          ? props?.manager
          : EmployeeMapper.toEmployeeModel(props?.manager, [
              WithoutRecursion.groupSession,
              ...withoutRecursion,
            ]),

      service:
        withoutRecursion.indexOf(WithoutRecursion.service) !== -1
          ? props?.service
          : ServiceMapper.toServiceModel(props?.service, [
              WithoutRecursion.groupSession,
              ...withoutRecursion,
            ]),
    };

    super(options);
  }

  get managerModel(): EmployeeModel {
    return this.manager instanceof EmployeeModel
      ? this.manager
      : EmployeeMapper.toEmployeeModel(this.manager);
  }

  get serviceModel(): ServiceModel {
    return this.service instanceof ServiceModel
      ? this.service
      : ServiceMapper.toServiceModel(this.service);
  }
}
