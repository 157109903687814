import {Map} from 'immutable';
import {APP_STATE, PRODUCT_CATEGORY} from '../constants';
import {REDUX_STATUS, ApiError} from '../../services/types';
import {ProductCategoryModel} from '../../struture';

interface IProductCategoryListState {
  category: ProductCategoryModel | null;
  cachedProductCategoryList: Map<string, ProductCategoryModel>;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
}

interface SetAction extends Pick<IProductCategoryListState, 'category'> {
  type: PRODUCT_CATEGORY.SET_PRODUCT_CATEGORY;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface UpdateProductCategory {
  type: PRODUCT_CATEGORY.UPDATE_PRODUCT_CATEGORY;
  category: ProductCategoryModel;
}

interface LoadingAction {
  type: PRODUCT_CATEGORY.LOADING_PRODUCT_CATEGORY;
}

interface ResetAction {
  type: PRODUCT_CATEGORY.RESET_PRODUCT_CATEGORY;
}

interface ErrorAction extends Pick<IProductCategoryListState, 'error'> {
  type: PRODUCT_CATEGORY.ERROR_PRODUCT_CATEGORY;
}

interface Handlers {
  [PRODUCT_CATEGORY.SET_PRODUCT_CATEGORY]: (
    state: IProductCategoryListState,
    action: SetAction,
  ) => IProductCategoryListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IProductCategoryListState,
    action: SetInitialStateAction,
  ) => IProductCategoryListState;

  [PRODUCT_CATEGORY.RESET_PRODUCT_CATEGORY]: (
    state: IProductCategoryListState,
    action: ResetAction,
  ) => IProductCategoryListState;

  [PRODUCT_CATEGORY.UPDATE_PRODUCT_CATEGORY]: (
    state: IProductCategoryListState,
    action: UpdateProductCategory,
  ) => IProductCategoryListState;

  [PRODUCT_CATEGORY.ERROR_PRODUCT_CATEGORY]: (
    state: IProductCategoryListState,
    value: ErrorAction,
  ) => IProductCategoryListState;

  [PRODUCT_CATEGORY.LOADING_PRODUCT_CATEGORY]: (
    state: IProductCategoryListState,
    value?: LoadingAction,
  ) => IProductCategoryListState;
  DEFAULT: (state: IProductCategoryListState) => IProductCategoryListState;
}

const initState: IProductCategoryListState = {
  category: null,
  cachedProductCategoryList: Map(),
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
};

const handlers: Handlers = {
  [PRODUCT_CATEGORY.SET_PRODUCT_CATEGORY]: (state, {category}) => ({
    ...state,
    ...{
      category,
      cachedProductCategoryList: category
        ? state.cachedProductCategoryList.set(category?.uuid, category)
        : state.cachedProductCategoryList,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [PRODUCT_CATEGORY.RESET_PRODUCT_CATEGORY]: (state) => ({
    ...state,
    ...{
      status: REDUX_STATUS.IDLE,
    },
  }),

  [PRODUCT_CATEGORY.UPDATE_PRODUCT_CATEGORY]: (state, {category}) => ({
    ...state,
    ...{
      category,
      cachedProductCategoryList: state.cachedProductCategoryList.update(
        category?.uuid,
        () => category,
      ),
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [PRODUCT_CATEGORY.ERROR_PRODUCT_CATEGORY]: (
    state: IProductCategoryListState,
    {error}: ErrorAction,
  ) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [PRODUCT_CATEGORY.LOADING_PRODUCT_CATEGORY]: (
    state: IProductCategoryListState,
  ) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IProductCategoryListState) => state,
};

export default function ProductCategory(
  state: any = initState,
  action: any,
): IProductCategoryListState {
  const handler = handlers[action.type as PRODUCT_CATEGORY] || handlers.DEFAULT;
  return handler(state, action);
}
