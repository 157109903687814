import {correctPrice} from '../../services/helpers';

export interface StoreDocumentExtraServiceDTOProps {
  title: string;
  price: string;
}

export class StoreDocumentExtraServiceDTO {
  public title: string;

  public price: string;

  constructor(props: StoreDocumentExtraServiceDTOProps) {
    this.title = props?.title || '';
    this.price = correctPrice(props?.price || 0);
  }
}
