import React, {useMemo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {DEPARTMENT_INITIAL_PARAM} from '../../../services/api/department';
import {DefaultForm, IDefaultFormProps} from '../../../components/lib/General';
import {DepartmentFormDTO} from '../../../struture';
import {useDropdownAlert} from '../../../contex';
import {split} from '../../../services/helpers';
import {DepartmentFormFields} from './DepartmentFormFields';

export interface IDepartmentFormProps
  extends Omit<
    IDefaultFormProps<DepartmentFormDTO, DepartmentFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  department?: DepartmentFormDTO;
  handleUpdateIcon?: (icon: any) => void;
  handleUpdateColorIcon?: (color: string) => void;
}

export function DepartmentForm({
  loading,
  department = DEPARTMENT_INITIAL_PARAM,
  editMode,
  handleUpdateIcon,
  handleUpdateColorIcon,
  ...rest
}: IDepartmentFormProps): React.JSX.Element {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const weekdays = useMemo(
    () => ({
      weekdays: split(',', department?.dep_schedule),
    }),
    [department?.dep_schedule],
  );

  const additionalValues = useMemo(
    () => ({
      uuid: department?.uuid,
    }),
    [department?.uuid],
  );

  const notifyError = useCallback(
    (ApiError: any) => {
      alert(
        'error',
        t('Department'),
        `${
          editMode
            ? t('An error occurred during edit department')
            : t('An error occurred during create department')
        } : ${ApiError?.message}`,
      );
    },
    [alert, editMode, t],
  );

  return (
    <DefaultForm<DepartmentFormDTO, DepartmentFormDTO>
      withContext
      initialValues={{
        ...department,
        ...weekdays,
      }}
      additionalValuesRequest={additionalValues}
      editMode={editMode}
      notifyError={notifyError}
      showNotify={false}
      {...rest}>
      <DepartmentFormFields
        loading={!!loading}
        handleUpdateIcon={handleUpdateIcon}
        handleUpdateColorIcon={handleUpdateColorIcon}
      />
    </DefaultForm>
  );
}
