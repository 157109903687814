import * as React from 'react';
import {useEffect, useMemo, useState} from 'react';
import {CashBoxModel} from '../struture';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import useStoredCompanies from './useStoredCompanies';
import {FormInstance, Switch} from 'antd';
import {useStoredCashBoxList} from './useStoredCashBoxList';

export interface IntegrationCheckboxItemParamsProps {
  selectedCashBoxes: string[];
  form: FormInstance;
}

const StyledHiddenContainer = styled.div`
  overflow: hidden;
  height: 0;
  width: 22px;
`;

const StyledSwitch = styled(Switch)`
  position: absolute;
  left: 13px;
  top: calc(50% - 10px);
`;

const StyledCheckboxContainer = styled.div`
  width: fit-content;
  min-width: 50px;
`;

export function useIntegrationCheckboxItemFieldParams({
  selectedCashBoxes: defaultSelectedCashBoxes,
  form,
}: IntegrationCheckboxItemParamsProps) {
  const {t} = useTranslation();
  const {defaultCompanyUuid} = useStoredCompanies();

  const {cashBoxList, loading} = useStoredCashBoxList({
    limit: 150,
    companyUuid: defaultCompanyUuid,
  });

  const [selectedCashBoxes, setSelectedCashBoxes] = useState<string[] | null>(
    null,
  );

  const columns = [
    {
      title: t('Title'),
      key: 'title',
      render: (cashBox: CashBoxModel) => cashBox?.box_title,
    },
  ];

  const rowSelection = useMemo(
    () => ({
      hideSelectAll: true,
      selectedRowKeys: selectedCashBoxes || [],
      onChange: async (
        selectedRowKeys: React.Key[],
        selectedRecords: CashBoxModel[],
      ) => {
        const cashbox_relations = selectedRecords.map(({uuid}) => uuid);

        setSelectedCashBoxes(cashbox_relations);

        form.setFieldsValue({cashbox_relations});
      },
      renderCell: (
        checked: boolean,
        bonus: CashBoxModel,
        index: number,
        originNode: React.ReactNode,
      ) => {
        return (
          <StyledCheckboxContainer className="row-selection-container">
            <StyledSwitch
              // disabled={bonusLoading && loadingItemId === bonus?.id}
              checked={checked}
              onClick={(_, event) => {
                event.preventDefault();
                const target = event?.target;

                if (target instanceof HTMLElement) {
                  const container = target.closest('.row-selection-container');

                  if (container instanceof HTMLElement) {
                    const rowCheckBox = container.querySelector(
                      '.ant-checkbox-input',
                    );

                    const clickEvent = new MouseEvent('click', {
                      bubbles: true,
                      cancelable: true,
                    });
                    if (rowCheckBox instanceof HTMLElement) {
                      rowCheckBox.dispatchEvent(clickEvent);
                    }
                  }
                }
              }}
            />
            <StyledHiddenContainer>
              {React.isValidElement(originNode)
                ? React.cloneElement(originNode, {
                    className: 'row-selection-checkbox',
                  } as any)
                : null}
            </StyledHiddenContainer>
          </StyledCheckboxContainer>
        );
      },
    }),
    [form, selectedCashBoxes],
  );

  useEffect(() => {
    if (Array.isArray(defaultSelectedCashBoxes)) {
      setSelectedCashBoxes(defaultSelectedCashBoxes);
    }
  }, [defaultSelectedCashBoxes]);

  return {
    columns,
    rowSelection,
    cashBoxList,
    loading,
  };
}
