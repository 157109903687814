import React, {Suspense, useState} from 'react';
import {isFunction} from '../../../services/helpers';
import {ChildrenMustBeAFunction} from '../../../components/lib/Errors';
import {Routes, RoutesAcl} from '../../../services/types';
import {useTranslation} from 'react-i18next';
import {ReportMapper, ReportTypes} from '../../../struture';
import {IDefaultTabPanelProps} from '../../../components/lib/General';
import {useTheme} from 'styled-components';
import {BsFiletypePdf} from 'react-icons/bs';
import {useModal} from '../../../components/lib/libV2/hooks';
import {useNavigate} from 'react-router';

const ReportSideWindowLazy = React.lazy(
  () => import('../Show/ReportSideWindow'),
);

export interface IReportingTabPanelProps {
  children: (value: Pick<IDefaultTabPanelProps, 'tabs'>) => React.ReactNode;
}

export enum REPORTING_TABS {
  ORDERS = 'Orders report',
  PAYMENT = 'Payments report',
  ABONEMENT = 'Payments abonement',
  ARCHIVE = 'Archive of reports',
}

export function ReportingTabPanel({children}: IReportingTabPanelProps) {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const theme: any = useTheme();
  const {handleOnInit, visible, handleCancel} = useModal();

  const [reportType, setReportType] = useState(ReportTypes.Orders);

  const tabs = [
    {
      key: REPORTING_TABS.ORDERS,
      aclItem: RoutesAcl[Routes.reports],
      withAclManage: true,
      title: REPORTING_TABS.ORDERS,
      accessibilityHint: t('Navigates to reporting form modal'),
      navigation: () => {
        setReportType(ReportTypes.Orders);
        handleOnInit();
      },
      buttonIcon: <BsFiletypePdf size={20} color={theme.colors.primary} />,
      renderer: () => <></>,
      bold: false,
    },
    {
      key: REPORTING_TABS.PAYMENT,
      aclItem: RoutesAcl[Routes.reports],
      withAclManage: true,
      title: REPORTING_TABS.PAYMENT,
      accessibilityHint: t('Navigates to reporting form modal'),
      navigation: () => {
        setReportType(ReportTypes.Payments);
        handleOnInit();
      },
      buttonIcon: <BsFiletypePdf size={20} color={theme.colors.primary} />,
      renderer: () => <></>,
      bold: false,
    },
    {
      key: REPORTING_TABS.ABONEMENT,
      aclItem: RoutesAcl[Routes.clientAbonements],
      withAclManage: true,
      title: REPORTING_TABS.ABONEMENT,
      accessibilityHint: t('Navigates to reporting form modal'),
      navigation: () => {
        setReportType(ReportTypes.Abonements);
        handleOnInit();
      },
      buttonIcon: <BsFiletypePdf size={20} color={theme.colors.primary} />,
      renderer: () => <></>,
      bold: false,
    },
    {
      key: REPORTING_TABS.ARCHIVE,
      aclItem: RoutesAcl[Routes.reports],
      title: REPORTING_TABS.ARCHIVE,
      accessibilityHint: t('Navigates to reporting archive screen'),
      navigation: () => {
        navigate(`${Routes.reportsArchive}`);
      },
      renderer: () => <></>,
      bold: false,
    },
  ];

  if (!isFunction(children)) {
    throw new ChildrenMustBeAFunction('LoadingWithEmptyData');
  }

  return (
    <>
      {children({tabs})}
      <Suspense fallback={<React.Fragment />}>
        <ReportSideWindowLazy
          type={reportType}
          report={ReportMapper.toReportFormDTO()}
          visible={visible}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}
