import React, {useCallback} from 'react';
import {useTranslation, WithTranslation, withTranslation} from 'react-i18next';
import {ICalendarManagerProps} from '../../../contex';
import {
  IClientSearchProps,
  useDefaultCalendarScheduleProps,
  useRedirectToCalendar,
  useStateCompanyClientList,
  useStateScheduleList,
  useStoredCompanies,
} from '../../../hooks';
import {CompanyCalendarPopover, DashboardScheduleDetail} from '../Show';
import {SingleLayout} from '../../../components/lib/Layout';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '../../../services/types';
import {ClientModel, ScheduleCalendarModel} from '../../../struture';
import {useLocation, useNavigate} from 'react-router';
import {textToUpperCase} from '../../../services/helpers';
import {CALENDAR_ENTRY} from '../../../components/lib/const';
import {
  SearchInput,
  SelectSearch,
  SearchSuggestionList,
} from '../../../components/lib/General';
import {Row, Col} from 'antd';
import styled from 'styled-components';

export default withTranslation()(DashboardPage);

export interface IDashboardPageProps extends WithTranslation {
  children: (
    props: ICalendarManagerProps<ScheduleCalendarModel>,
  ) => React.ReactNode;
}

const StyledRow = styled(Row)`
  padding-left: 20px;
  padding-right: 20px;
`;

/**
 * @name DashboardPage
 * @desc component.
 */
function DashboardPage({children}: IDashboardPageProps): JSX.Element {
  const {t} = useTranslation();
  const defaultCalendarProps = useDefaultCalendarScheduleProps();
  const {defaultCompany, defaultCompanyUuid} = useStoredCompanies();
  const navigate = useNavigate();

  const {
    schedules,
    refresh: refreshCompanySchedules,
    loading,

    handleCreateSchedule,
  } = useStateScheduleList({
    companyUuid: defaultCompanyUuid,
    loadOnInit: false,
    limit: 150,
  });

  const {handleSearchClients} = useStateCompanyClientList({
    loadOnInit: false,
    companyUuid: defaultCompanyUuid,
  });

  const location = useLocation();

  useRedirectToCalendar({
    path: Routes.dashboard,
    isChecked: !location.pathname.includes(CALENDAR_ENTRY),
  });

  const handleNavigate = useCallback(
    ({uuid}: ClientModel) => {
      navigate(`/${Routes.app}/${Routes.clients}/${uuid}`);
    },
    [navigate],
  );

  const handleSearchClient = useCallback(
    async (value: IClientSearchProps): Promise<ClientModel[] | void> => {
      const clients = await handleSearchClients(value);

      if (clients) {
        return clients.toArray();
      }
    },
    [handleSearchClients],
  );

  return (
    <SingleLayout
      isCalendar
      aclItem={RoutesAcl[Routes.calendar]}
      headerLeft={<></>}
      headerTitle={textToUpperCase(
        defaultCompany?.title || t(RoutesTitle[RoutesType.dashboard]),
      )}
      underHeaderContent={
        <StyledRow>
          <Col span={24}>
            <SelectSearch
              handleSearch={handleSearchClient}
              selectCallback={handleNavigate}
              searchInput={<SearchInput placeholder={t('Search')} />}
              searchResultDisplay={<SearchSuggestionList />}
            />
          </Col>
        </StyledRow>
      }>
      {children({
        ...defaultCalendarProps,
        localStorageName: 'dashboardCalendarDate',
        schedules: schedules!,
        onRefresh: (rest) =>
          refreshCompanySchedules({
            ...rest,
            companyUuid: defaultCompanyUuid,
          }),
        SchedulePopover: CompanyCalendarPopover,
        scheduleDetails: (children, schedule) => (
          // @ts-ignore
          <DashboardScheduleDetail schedule={schedule}>
            {({routes, navigateGoBack}) => children({routes, navigateGoBack})}
          </DashboardScheduleDetail>
        ),
        customDayTimeRange: (defaultCompany &&
          defaultCompany?.work_schedules) as any,
        loading,
        withDnDEventsProps: {onSuccess: handleCreateSchedule},
        isClearDnDCache: true,
      })}
    </SingleLayout>
  );
}
