import Button from '../DataDisplay/Button';
import styled, {css} from 'styled-components';

export const StyledIconButton = styled(Button)<{
  $useCustomStyle: boolean;
  disabled?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  box-shadow: none;
  border-radius: 7px;

  ${({$useCustomStyle, disabled}) =>
    !$useCustomStyle &&
    !disabled &&
    css`
      z-index: 2;

      &&:hover,
      &&:active,
      &&:focus {
        background-color: transparent;
        border-color: transparent;
        opacity: 0.7;
      }
    `}
`;
