import {
  ActiveSubscriptionDTO,
  ActiveSubscriptionModel,
  IActiveSubscriptionDTOProps,
} from '../internal';

export class ActiveSubscriptionMapper {
  public static toActiveSubscriptionDTO(
    props: IActiveSubscriptionDTOProps | ActiveSubscriptionModel,
  ): ActiveSubscriptionDTO {
    return new ActiveSubscriptionDTO(props);
  }

  public static toActiveSubscriptionModel(
    props: ActiveSubscriptionDTO,
  ): ActiveSubscriptionModel {
    return new ActiveSubscriptionModel(props);
  }
}
