export enum Package {
  starter = 'starter',
  prof = 'prof',
  trial = 'trial',
}

export interface ISignupFormDTOProps {
  name: string;
  phone: string;
  package: Package;
  password: string;
  password_confirmation: string;
  invitation_code?: string;
}

export class SignupFormDTO {
  public name: string;

  public phone: string;
  public package: Package;
  public password: string;
  public password_confirmation: string;
  public invitation_code?: string;

  constructor(props?: ISignupFormDTOProps) {
    this.name = props?.name || '';
    this.phone = props?.phone
      ? props.phone.startsWith('+')
        ? props.phone
        : `+${props.phone}`
      : '';
    this.package = Package.trial;
    this.password = props?.password || '';
    this.password_confirmation = props?.password_confirmation || '';
    this.invitation_code = props?.invitation_code || '';

    if (!this.invitation_code) {
      delete this.invitation_code;
    }
  }
}
