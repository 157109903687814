import {useCallback, useEffect, useRef} from 'react';
import {
  CompanyScheduleFilter,
  getCompanySchedule,
  GetCompanyScheduleState,
} from '../services/api/company';
import {ListOptionsProps, neq} from '../services/helpers';
import useCancellablePromise from './useCancellablePromise';
import useStateReducer from './useStateReducer';
import {ApiError} from '../services/types';
import {
  IScheduleCalendarListDTO,
  ScheduleCalendarMapper,
  ScheduleCalendarModel,
  ScheduleCalendarStatsDTO,
} from '../struture';
import {List} from 'immutable';
import {StatusError} from '../components/lib/Errors';

export interface IUseCompanyScheduleListProps
  extends Partial<ListOptionsProps>,
    Partial<CompanyScheduleFilter> {
  loadOnInit?: boolean;
  showLoading?: boolean;
  companyUuid: string;
  page?: number;
  keywords?: string;
  activeness?: number;
  sort?: string;
  isClearDate?: boolean;
  state?: GetCompanyScheduleState;
  minKeywordLength?: number;
}

export interface IUseCompanyScheduleListReturnType
  extends Partial<CompanyScheduleFilter> {
  error: ApiError | null;
  loading: boolean;
  list: List<ScheduleCalendarModel>;
  refresh: (
    value: Partial<IUseCompanyScheduleListProps>,
    withError?: boolean,
  ) => Promise<List<ScheduleCalendarModel> | void>;
  total: number;
  offset: number;
  limit: number;
  sort: string;
  page: number;
  filters: string[];
  activeness: number;
  stats: ScheduleCalendarStatsDTO;
  state: GetCompanyScheduleState;
  keywords: string;
  minKeywordLength?: number;
}

export default function useCompanyScheduleList({
  companyUuid,

  offset = 0,
  sort = '',
  limit = 10,
  filters = [],
  loadOnInit = true,
  page = 1,
  keywords = '',
  activeness = 0,
  state = GetCompanyScheduleState.ALL,
  minKeywordLength = 0,

  date_end,
  date_start,
  client_uuid,
  manager_uuid,
  location_uuid,
  status_uuid,
}: IUseCompanyScheduleListProps): IUseCompanyScheduleListReturnType {
  const {cancellablePromise} = useCancellablePromise();
  const didCancel = useRef<boolean>();

  const {
    offset: listOffset,
    sort: listSort,
    limit: listPageSize,
    filters: listFilters,
    handleUpdate,
    page: listPage,
    companyUuid: listCompanyUuid,
    list,
    keywords: listKeywords,
    activeness: listActiveness,
    stats: listStats,
    state: listState,
    minKeywordLength: listMinKeywordLength,

    date_start: listStart,
    date_end: listEnd,
    client_uuid: listClientUuid,
    manager_uuid: listManagerUuid,
    location_uuid: listLocationUuid,
    status_uuid: listStatusUuid,
    ...rest
  } = useStateReducer<
    Omit<IUseCompanyScheduleListReturnType, 'refresh'> & {
      companyUuid: string;
      keywords?: string;
    }
  >({
    error: null,
    loading: loadOnInit,
    list: null as any,
    offset,
    limit,
    sort,
    filters,
    total: 0,
    page,
    companyUuid,
    keywords,
    activeness,
    stats: {} as ScheduleCalendarStatsDTO,
    state,
    minKeywordLength,

    date_end,
    date_start,
    client_uuid,
    manager_uuid,
    location_uuid,
    status_uuid,
  });

  const refresh = useCallback(
    async (
      {
        offset = listOffset,
        sort = listSort,
        limit = listPageSize,
        filters = listFilters,
        showLoading = true,
        companyUuid = listCompanyUuid,
        page = listPage,
        keywords = listKeywords,
        activeness = listActiveness,
        state = listState,
        minKeywordLength = listMinKeywordLength || 0,

        date_start = listStart,
        date_end = listEnd,
        client_uuid = listClientUuid,
        manager_uuid = listManagerUuid,
        location_uuid = listLocationUuid,
        status_uuid = listLocationUuid,
      }: Partial<IUseCompanyScheduleListProps> = {},
      witheError?: boolean,
    ): Promise<List<ScheduleCalendarModel> | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          offset,
          limit,
          sort,
          filters,
          page,
          companyUuid,
          keywords,
          state,
          minKeywordLength,

          date_start,
          date_end,
          client_uuid,
          manager_uuid,
          location_uuid,
          status_uuid,
        });

        if (keywords?.length >= minKeywordLength) {
          const {schedules, total, stats} =
            await cancellablePromise<IScheduleCalendarListDTO>(
              getCompanySchedule({
                keywords,
                companyUuid,
                limit,
                page,
                offset,
                activeness,
                sort,
                state,

                date_start,
                date_end,
                client_uuid,
                manager_uuid,
                location_uuid,
                status_uuid,
              }),
            );

          const scheduleListModel =
            ScheduleCalendarMapper.toScheduleCalendarListModel(
              schedules,
              total,
            );

          handleUpdate({
            list: scheduleListModel.schedules,
            loading: false,
            total,
            stats,
          });

          return scheduleListModel.schedules;
        }
      } catch (error: any) {
        handleUpdate({
          error,
          loading: false,
        });

        if (witheError) {
          throw new StatusError(error?.message, error?.status);
        }

        // alert(
        //   'error',
        //   t('Orders'),
        //   t('An error occurred during get order list'),
        // );
      }
    },
    [
      listOffset,
      listSort,
      listPageSize,
      listFilters,
      listCompanyUuid,
      listPage,
      listKeywords,
      listActiveness,
      listState,
      listMinKeywordLength,
      listStart,
      listEnd,
      listClientUuid,
      listManagerUuid,
      listLocationUuid,
      handleUpdate,
      cancellablePromise,
    ],
  );

  useEffect(() => {
    didCancel.current = false;

    if (loadOnInit && companyUuid) {
      (async () => {
        await refresh({
          companyUuid,
          date_end,
          date_start,
          client_uuid,
          manager_uuid,
          location_uuid,
          status_uuid,
        });
      })();
    }
    return () => {
      didCancel.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, companyUuid]);

  useEffect(() => {
    if (neq(listCompanyUuid, companyUuid)) {
      handleUpdate({companyUuid});
    }
  }, [companyUuid, handleUpdate, listCompanyUuid, loadOnInit]);

  return {
    refresh,
    offset: listPage,
    limit: listPageSize,
    sort: listSort,
    page: listPage,
    filters: listFilters,
    list,
    activeness: listActiveness,
    stats: listStats,
    state: listState,
    keywords: listKeywords,

    date_start: listEnd,
    date_end: listStart,
    client_uuid: listClientUuid,
    manager_uuid: listManagerUuid,
    location_uuid: listLocationUuid,
    status_uuid: listStatusUuid,
    ...rest,
  };
}
