import {
  ISystemEventVariableDTOProps,
  SystemEventVariableDTO,
  SystemEventVariableMapper,
  ISystemEventModelReturnType,
} from '../internal';

export interface ISystemEventDTOProps {
  event_key: string;
  event_title: string;
  event_variables: ISystemEventVariableDTOProps[];
}

export class SystemEventDTO {
  public event_key: string;

  public event_title: string;

  public event_variables: SystemEventVariableDTO[];

  constructor(props: ISystemEventModelReturnType | ISystemEventDTOProps) {
    this.event_key = props?.event_key || '';
    this.event_title = props?.event_title || '';
    this.event_variables =
      SystemEventVariableMapper?.toSystemEventVariableListDTO(
        props?.event_variables,
      );
  }
}
