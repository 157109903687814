import React, {forwardRef, Suspense} from 'react';
import styled, {css, useTheme} from 'styled-components';
import {StyledTitle} from '../../../../components/lib/Styled';
import {useTranslation} from 'react-i18next';
import {OverviewBottomView} from '../../../../components/lib/Layout';
import {Button, SuspenseEmpty} from '../../../../components/lib/DataDisplay';
import {textToUpperCase} from '../../../../services/helpers';
import {LoadingOutlined} from '@ant-design/icons';
import {useStoreSale} from '../Managers';
import {GrDocumentTime} from 'react-icons/gr';
import {useModal} from '../../../../components/lib/libV2/hooks';

const StoreSaleStoreRealizationSidWindowLazy = React.lazy(
  () => import('./StoreSaleStoreRealizationSideWindow'),
);

export interface IStoreSaleBottomViewProps {
  onConductButtonClick: () => void;
  realizationHandleOnInit: () => void;
  contentWidth: number;
}

const StyledButtonContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const StyledDraftButton = styled(Button)<{$bgColor?: string}>`
  min-width: 150px;
  max-width: fit-content;
  height: 65px;
  background-color: ${({theme, $bgColor}) => $bgColor || theme.colors.primary};
  white-space: unset;

  ${({disabled, $bgColor}) =>
    !disabled &&
    css`
      &&&:hover,
      &&&:active,
      &&&:focus {
        background-color: ${({theme}) => $bgColor || theme.colors.primary};
        opacity: 0.7;
      }
    `}

  ${({disabled, theme}) =>
    disabled &&
    css`
      & > button {
        height: 65px;
        width: 100%;
      }

      border-radius: 7px;

      & * {
        color: ${theme.colors.white};
      }
    `}
`;

export const StoreSaleBottomView = forwardRef(function StoreSaleBottomView(
  {
    onConductButtonClick,
    realizationHandleOnInit,
    contentWidth,
  }: IStoreSaleBottomViewProps,
  ref: any,
): React.JSX.Element {
  const {t} = useTranslation();
  const theme: any = useTheme();

  const {
    loadingSubmit,
    storeDocumentItemList,
    disabledProductUuid,
    loadingProductUuid,
    concurrentQueue,

    loadingSaveDraftDocument,
    handleSaveAsDraftDocument,
    handleSelectDaftDocument,
  } = useStoreSale();

  const {
    handleOnInit: realizationDraftHandleOnInit,
    visible: realizationDraftVisible,
    handleSuccess: realizationDraftHandleSuccess,
    handleCancel: realizationDraftHandleCancel,
  } = useModal({
    onSuccess: handleSelectDaftDocument,
  });

  return (
    <>
      <OverviewBottomView>
        <StyledButtonContainer>
          {storeDocumentItemList?.length ? (
            <StyledDraftButton
              disabled={
                !!disabledProductUuid ||
                !!loadingProductUuid ||
                loadingSubmit ||
                loadingSaveDraftDocument
              }
              loading={loadingSaveDraftDocument}
              type="primary"
              onClick={handleSaveAsDraftDocument}
              icon={loadingSubmit ? <LoadingOutlined /> : null}>
              <StyledTitle>{textToUpperCase(t('Save as draft'))}</StyledTitle>
            </StyledDraftButton>
          ) : null}

          {storeDocumentItemList?.length ? (
            <StyledDraftButton
              fitContent
              $bgColor={theme.colors.link}
              disabled={
                loadingSubmit ||
                !!disabledProductUuid ||
                !!loadingProductUuid ||
                concurrentQueue.current.inProcess ||
                !!concurrentQueue.current.count
              }
              type="primary"
              onClick={onConductButtonClick}
              icon={loadingSubmit ? <LoadingOutlined /> : null}>
              <StyledTitle>{textToUpperCase(t('Conduct'))}</StyledTitle>
            </StyledDraftButton>
          ) : null}
        </StyledButtonContainer>

        <StyledDraftButton
          type="primary"
          onClick={realizationDraftHandleOnInit}>
          <StyledTitle>{textToUpperCase(t('Drafts'))}</StyledTitle>
        </StyledDraftButton>

        <StyledDraftButton
          $bgColor={theme.colors.warning}
          type="primary"
          onClick={realizationHandleOnInit}
          icon={<GrDocumentTime size={18} color={theme.colors.white} />}>
          <StyledTitle>{textToUpperCase(t('Sales history'))}</StyledTitle>
        </StyledDraftButton>
      </OverviewBottomView>

      {realizationDraftVisible ? (
        <Suspense fallback={<SuspenseEmpty />}>
          <StoreSaleStoreRealizationSidWindowLazy
            visible={realizationDraftVisible}
            onCancel={realizationDraftHandleCancel}
            onSuccess={realizationDraftHandleSuccess as any}
            title={t('Drafts')}
          />
        </Suspense>
      ) : null}
    </>
  );
});
