import * as React from 'react';
import {useReport, IUseReportProps, IUseReportReturnType} from './useReport';
import {
  ReportFormDTO,
  ReportModel,
  ReportMapper,
  ReportTypes,
} from '../struture';
import {createReport} from '../services/api/report';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../store/reducers';
import {REDUX_STATUS} from '../services/types';
import {useEffect} from 'react';
import {
  addReportToList as storeAddReport,
  setReport as storeSetReport,
  resetReport as storeResetReport,
} from '../store/actions';
import {head, last, toDateByFormat} from '../services/helpers';
export interface IUseStateReportProps extends IUseReportProps {
  companyUuid: string;
}
export interface IUseStateReportReturnType
  extends Omit<IUseReportReturnType, 'entity'> {
  report: ReportModel | null;
  handleCreateReport: (
    value: ReportFormDTO,
    reportType: ReportTypes,
  ) => Promise<ReportModel | void>;
  handleRefreshReport: () => Promise<void>;
  handleResetReport: () => void;
  status: REDUX_STATUS;
}

export function useStoredReport({
  companyUuid,
  loadOnInit,
  reportUuid,
  ...rest
}: IUseStateReportProps): IUseStateReportReturnType {
  const [report, setReport] = React.useState<ReportModel | null>(null);
  const [isStarLoading, setIsStartLoading] = React.useState<boolean>(false);

  const {
    status: storedReportStatus,
    cachedReportList,
    ...storedReportParams
  } = useSelector(({report}: RootState) => report);

  const dispatch = useDispatch<any>();
  const once = React.useRef(false);

  const {entity, refresh, ...reportParams} = useReport({
    loadOnInit: loadOnInit || isStarLoading,
    reportUuid,
    ...rest,
  });

  React.useEffect(() => {
    if (cachedReportList?.size > 0) {
      const service = cachedReportList.get(reportUuid);

      if (service) {
        setIsStartLoading(false);
        setReport(service);
      } else {
        setIsStartLoading(true);
      }
    }
    if (cachedReportList?.size === 0 && !report) {
      setIsStartLoading(true);
    }
  }, [cachedReportList, dispatch, reportUuid, report]);

  useEffect(() => {
    if (entity && isStarLoading && !once.current) {
      dispatch(storeSetReport(entity));
      setReport(entity);
    }
  }, [dispatch, entity, isStarLoading, storedReportStatus]);

  const handleCreateReport = React.useCallback(
    async (value: ReportFormDTO, reportType: ReportTypes) => {
      const reportDTO = await createReport(
        {
          ...value,
          report_start_date: toDateByFormat(head(value?._date), 'YYYY-MM-DD'),
          report_end_date: toDateByFormat(last(value?._date), 'YYYY-MM-DD'),
        },
        companyUuid,
        reportType,
      );

      const reportModel = ReportMapper.toReportModel(reportDTO);

      dispatch(storeAddReport(reportModel));

      return reportModel;
    },
    [companyUuid, dispatch],
  );

  const handleResetReport = React.useCallback(() => {
    once.current = true;
    dispatch(storeResetReport());
  }, [dispatch]);

  const handleRefreshReport = React.useCallback(async () => {
    const reportModel = await refresh({showLoading: false, reportUuid});

    if (reportModel) {
      dispatch(storeSetReport(reportModel));
    }
  }, [reportUuid, dispatch, refresh]);

  return {
    ...reportParams,
    ...storedReportParams,
    report,
    loading: !report,
    handleCreateReport,
    status: storedReportStatus,
    handleResetReport,
    refresh,
    handleRefreshReport,
  };
}
