import React from 'react';
import {IntegrationCategoryModel} from '../../../struture';
import styled from 'styled-components';
import {StyledTitle, StyledDescription} from '../../../components/lib/Styled';

export interface IntegrationCategoryViewProps {
  category: IntegrationCategoryModel;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 15px;
`;

export function IntegrationCategoryView({
  category,
}: IntegrationCategoryViewProps): React.JSX.Element {
  return (
    <StyledContainer>
      <StyledTitle fontSize={18}>{category?.title}</StyledTitle>
      <StyledDescription fontSize={13}>
        {category?.description}
      </StyledDescription>
    </StyledContainer>
  );
}
