import {useTranslation} from 'react-i18next';
import {GlobalOutlined} from '@ant-design/icons';
import styled, {useTheme} from 'styled-components';
import React, {useCallback, useEffect} from 'react';
import {StyledTitle} from '../../../components/lib/Styled';
import {AuthPageWrapper} from '../../Authorization/common';
import {AppIntroTabPanel, AppIntroBottomView} from '../Show';
import {AuthLayout, AuthRouteLayout} from '../../Authorization/Show';
import {contactSupport, LocalStorageItems} from '../../../services/const';

const StyledText = styled(StyledTitle)`
  display: block;
`;

const StyledList = styled.ul`
  text-align: left;
`;

const StyledListItem = styled.li`
  padding: 10px 0;
`;

const StyledHoveredLink = styled(StyledTitle)`
  cursor: pointer;
`;

export default function AppIntroPage(): JSX.Element {
  const {t} = useTranslation();
  const theme: any = useTheme();

  const handleOpenSiteUrl = useCallback(async () => {
    window.open(contactSupport, '_blank');
  }, []);

  useEffect(() => {
    const isShowAppIntro = localStorage.getItem(
      LocalStorageItems.isShowAppIntro,
    );
    if (!isShowAppIntro) {
      localStorage.setItem(LocalStorageItems.isShowAppIntro, 'true');
    }
  }, []);

  return (
    <AuthLayout>
      <AppIntroTabPanel>
        {({routes}) => (
          <AuthRouteLayout
            header={
              <>
                <AuthPageWrapper
                  showLogo={true}
                  title={t('App intro')}
                  description={t('Welcome to the CRM .ServicitY')}>
                  <StyledList>
                    <StyledListItem>
                      {t(
                        'Create service and configure the price for this service.',
                      )}
                    </StyledListItem>
                    <StyledListItem>
                      {t(
                        'Create employee and assign the list of services (s)he is allowed to provide.',
                      )}
                    </StyledListItem>
                    <StyledListItem>
                      {t('Create products and configure the type of prices.')}
                    </StyledListItem>
                    <StyledListItem>
                      {t(
                        'That`s it! You are ready to register the very first order.',
                      )}
                    </StyledListItem>
                  </StyledList>

                  <StyledTitle>
                    {t(
                      'We are always ready to support you, so feel free to contact us if you have any question. In order to do that visit our website and use "contact us" form.',
                    )}
                  </StyledTitle>
                  <StyledText>
                    <StyledHoveredLink
                      title={t(`Our official Web-site.`)}
                      color={theme.colors.link}
                      onClick={handleOpenSiteUrl}>
                      <GlobalOutlined /> www.servicity.in.ua
                    </StyledHoveredLink>
                  </StyledText>
                </AuthPageWrapper>
              </>
            }
            routes={routes}
            outsideFooterContent={<AppIntroBottomView />}
          />
        )}
      </AppIntroTabPanel>
    </AuthLayout>
  );
}
