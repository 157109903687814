import {Record} from 'immutable';

export interface ISystemRule {
  rule_title: string;
  rule_description: string;
  created_at: string;
  updated_at: string;
}

export interface IRuleLimitationsProps {
  system_rule_limit: number;
  system_rule: ISystemRule;
}

export interface ISystemSubscriptionProps {
  subscription_key: string;
  subscription_price: string;
  subscription_title: string;
  subscription_description: string;
  status_txt: string;
  rule_limitations: IRuleLimitationsProps[];
}

/**
 * @class Client
 * @desc Клієнт
 */
export default class SystemSubscription extends Record({
  subscription_key: '',
  subscription_price: '',
  subscription_title: '',
  subscription_description: '',
  status_txt: 'UNDEFINED',
  rule_limitations: [],
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(
    props: ISystemSubscriptionProps = {} as ISystemSubscriptionProps,
  ) {
    super({...props});
  }
}
