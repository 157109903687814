export enum PRODUCT_LIST {
  SET_PRODUCT_LIST = 'set_product_list',
  SET_PRODUCT_LIST_STATS = 'set_product_list_stats',
  ADD_PRODUCT_LIST_STATS = 'add_product_list_stats',
  SUB_PRODUCT_LIST_STATS = 'sub_product_list_stats',
  LOADING_PRODUCT_LIST = 'loading_product_list',
  ERROR_PRODUCT_LIST = 'error_product_list',
  LOAD_MORE_PRODUCT_LIST = 'load_more_product_list',
  ADD_PRODUCT = 'add_product',
  UPDATE_PRODUCT = 'update_product_in_list',
  DELETE_PRODUCT = 'delete_product',
}
