import {List} from 'immutable';
import {
  ProfileSubscriptionDTO,
  IProfileSubscriptionDTOProps,
  ProfileSubscriptionModel,
  ProfileSubscriptionListModel,
  SystemSubscriptionMapper,
} from '../internal';
import {listToArray} from '../../services/helpers';

export class ProfileSubscriptionMapper {
  public static toProfileSubscriptionDTO(
    profileSubscription:
      | ProfileSubscriptionModel
      | IProfileSubscriptionDTOProps,
  ): ProfileSubscriptionDTO {
    return new ProfileSubscriptionDTO({
      status_txt: profileSubscription?.status_txt || '',
      system_subscription: SystemSubscriptionMapper.toSystemSubscriptionDTO(
        profileSubscription?.system_subscription,
      ),
      created_at: profileSubscription?.created_at || '',
      updated_at: profileSubscription?.updated_at || '',
    });
  }

  public static toProfileSubscriptionListDTO(
    profileSubscription:
      | List<ProfileSubscriptionModel>
      | IProfileSubscriptionDTOProps[],
  ): ProfileSubscriptionDTO[] {
    const profileSubscriptionList = List.isList(profileSubscription)
      ? listToArray<ProfileSubscriptionModel>(profileSubscription)
      : profileSubscription;

    return profileSubscriptionList?.map((employee) =>
      ProfileSubscriptionMapper.toProfileSubscriptionDTO(employee),
    );
  }

  public static toProfileSubscriptionModel(
    profileSubscriptionDTO: ProfileSubscriptionDTO,
  ): ProfileSubscriptionModel {
    return new ProfileSubscriptionModel(profileSubscriptionDTO);
  }

  public static toProfileSubscriptionListModel(
    profileSubscriptionDTOs: ProfileSubscriptionDTO[],
  ): ProfileSubscriptionListModel {
    return new ProfileSubscriptionListModel({
      profile_subscriptions: profileSubscriptionDTOs,
    });
  }
}
