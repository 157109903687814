import {ILiteralObj} from '../services/types';
import {
  isThereContent,
  filter,
  filterFormErrorFields,
} from '../services/helpers';
import {isFunction} from '@sportix/sportix-common-modules';

export enum FORM_ACTIONS {
  UPDATE = 'update',
  FILTER_ERRORS = 'filter_errors',
  DEFAULT = 'default',
}

export interface ErrorField {
  name: [string];
  errors: [string];
}

export interface ErrorFields {
  errorFields: ErrorField[];
}

export interface UpdateAction extends ILiteralObj {
  type: FORM_ACTIONS.UPDATE;

  update: ((value: any) => any) | ILiteralObj;
}

export interface FilterErrorsAction extends ILiteralObj {
  type: FORM_ACTIONS.FILTER_ERRORS;
}

export type FormActions = UpdateAction | FilterErrorsAction;

const filterErrorFields = <T extends ILiteralObj>(
  errorFields: ErrorField[],
  value: T,
): ErrorField[] =>
  filter<any, any>(
    filterFormErrorFields(Object.entries(value || {})),
    errorFields,
  );

export const handlers = {
  [FORM_ACTIONS.UPDATE]: <T extends ErrorFields>(
    state: T,
    {type, update}: UpdateAction,
  ): T => {
    if (isFunction(update)) {
      return {
        ...state,
        ...update(state),
      };
    }

    if (isThereContent(update)) {
      return {
        ...state,
        ...update,
      };
    }

    return state;
  },
  [FORM_ACTIONS.FILTER_ERRORS]: <T extends ErrorFields>(
    state: T,
    {type, errorFields, ...rest}: FilterErrorsAction,
  ): T => ({
    ...state,
    errorFields: isThereContent(state?.errorFields)
      ? filterErrorFields(state?.errorFields!, {...rest})
      : [],
  }),
  [FORM_ACTIONS.DEFAULT]: <T extends ErrorFields>(state: T): T => state,
};
