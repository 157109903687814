import React, {MutableRefObject, useEffect, useRef} from 'react';
import {Col, Form, Input, Row} from 'antd';
import {SearchSelect} from '../../../../components/lib/DataDisplay';
import {listToArray} from '../../../../services/helpers';
import {StoreModel} from '../../../../struture';
import {IUseStateStoreListReturnType, useSize} from '../../../../hooks';
import {phoneScreen} from '../../../../services/const';
import {useTranslation} from 'react-i18next';
import {useDefaultForm} from '../../../../contex';
import {StoreDocumentRefObject} from '../../helpers';

export interface IStoreMovingFieldsProps
  extends Pick<
    IUseStateStoreListReturnType,
    'storeList' | 'handleSearchStores'
  > {
  loading: boolean;
  editMode: boolean;
  storeListLoading: boolean;
  documentEditRef?: MutableRefObject<StoreDocumentRefObject>;
}

export function StoreMovingFields({
  loading,
  editMode,

  storeList,
  storeListLoading,
  handleSearchStores,

  documentEditRef,
}: IStoreMovingFieldsProps): React.JSX.Element {
  const {t} = useTranslation();
  const {loadingSubmit, formData, handlerUpdateFormState}: any =
    useDefaultForm();

  const contentRef = useRef(null);
  const {width: contentWidth} = useSize(contentRef);
  const isFullWidth = contentWidth <= phoneScreen ? 24 : 12;

  useEffect(() => {
    if (documentEditRef?.current) {
      const {errorFields, ...rest} = formData;

      documentEditRef.current.document = rest;
    }
  }, [documentEditRef, formData]);

  return (
    <Row gutter={20} ref={contentRef}>
      <Col span={isFullWidth}>
        <Form.Item
          label={t('Store')}
          name="doc_store_uuid"
          tooltip={t('Store')}
          rules={[
            {
              required: true,
              message: t('Store must be specified.'),
            },
          ]}>
          <SearchSelect
            selectFirst={storeList?.size === 1}
            name="doc_store_uuid"
            disable={loading || storeListLoading || loadingSubmit || editMode}
            placeholder={t('Move from warehouse')}
            data={listToArray(
              storeList?.filter(
                ({uuid}: StoreModel) =>
                  uuid !== formData?.doc_destination_store_uuid,
              ) as any,
            )}
            getOptionValueTitle="store_name"
            onSearch={(keywords) => handleSearchStores({keywords})}
            resetState={storeListLoading}
            onChange={(doc_store_uuid) =>
              handlerUpdateFormState({doc_store_uuid})
            }
          />
        </Form.Item>
      </Col>

      <Col span={isFullWidth}>
        <Form.Item
          label={t('In store')}
          name="doc_destination_store_uuid"
          tooltip={t('In store')}
          rules={[
            {
              required: true,
              message: t('Store must be specified.'),
            },
          ]}>
          <SearchSelect
            selectFirst={storeList?.size === 1}
            name="doc_destination_store_uuid"
            disable={loading || storeListLoading || loadingSubmit}
            placeholder={t('Move to warehouse')}
            data={listToArray(
              storeList?.filter(
                ({uuid}: StoreModel) => uuid !== formData?.doc_store_uuid,
              ) as any,
            )}
            getOptionValueTitle="store_name"
            onSearch={(keywords) => handleSearchStores({keywords})}
            resetState={storeListLoading}
            onChange={(doc_destination_store_uuid) =>
              handlerUpdateFormState({doc_destination_store_uuid})
            }
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item label={t('Note')} name="doc_comment">
          <Input.TextArea
            allowClear
            autoSize={{minRows: 2, maxRows: 5}}
            disabled={loading || loadingSubmit}
            placeholder={t('Enter your note')}
            onChange={(e) =>
              handlerUpdateFormState({doc_comment: e?.target?.value})
            }
          />
        </Form.Item>
      </Col>
    </Row>
  );
}
