import React from 'react';
import styled, {css} from 'styled-components';
export interface IColorViewProps {
  color: string;
}

const StyledColorView = styled.div<{$color: string}>`
  position: relative;
  background-color: ${({theme}) => theme.colors.primary};
  height: 100%;
  width: 10px;

  ${({$color}) =>
    $color &&
    css`
      background-color: ${$color};
    `}
`;

export function ColorLine({color, ...rest}: IColorViewProps): JSX.Element {
  return <StyledColorView $color={color} {...rest} />;
}
