import {
  ClientMapper,
  ClientModel,
  EmployeeMapper,
  EmployeeModel,
  IScheduleCalendarModelReturnType,
  ServiceFormDTO,
  ServiceMapper,
} from '../internal';
import {correctPrice, toDateByFormat} from '../../services/helpers';
import {ClientPersonalityType} from '../../services/types';

export enum CalendarMode {
  Calendar = 'Calendar',
  DateAndTime = 'Date and time',
}

export class ScheduleCalendarFormDTO {
  public scheduled_date: string;
  public uuid: string;
  public schedule_title: string;

  public client:
    | ClientModel
    | {uuid: string}
    | {first_name: string; last_name: string; phone: string};
  public service_uuid: ServiceFormDTO | string;
  public worker_uuid: EmployeeModel | string;
  public send_sms_notification: boolean;
  public send_email_notification: boolean;
  public comment: string;
  public price: string;
  public time: string;
  public parent_uuid: string;
  public parent_number: string;
  public parent_date: string;
  public mode_date_and_time?: CalendarMode;
  public client_type?: ClientPersonalityType;

  constructor(props?: IScheduleCalendarModelReturnType, editMode?: boolean) {
    this.scheduled_date =
      props?.scheduled_date || props?.parent?.scheduled_date || '';
    this.uuid = props?.uuid || '';
    this.schedule_title = props?.schedule_title || '';
    this.parent_uuid = props?.parent?.uuid || '';
    this.parent_date = props?.parent?.scheduled_date || '';
    this.parent_number = props?.parent?.schedule_number || '';
    this.client = editMode
      ? props?.client?.uuid
        ? props?.client instanceof ClientModel
          ? props?.client
          : ClientMapper.toClientModel(props?.client as any)
        : {uuid: ''}
      : {uuid: ''};
    this.service_uuid = '';
    this.worker_uuid = editMode
      ? props?.employee?.uuid
        ? props?.employee instanceof EmployeeModel
          ? props?.employee
          : EmployeeMapper.toEmployeeModel(props?.employee as any)
        : ''
      : props?.employee?.uuid || '';
    this.send_sms_notification = false;
    this.send_email_notification = false;
    this.comment = props?.comment || '';
    this.time = '';
    this.price = correctPrice(props?.price);
    this.mode_date_and_time = CalendarMode.DateAndTime;
    this.client_type =
      props?.client?.client_type || ClientPersonalityType.Individual;
  }
}
