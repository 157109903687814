import React from 'react';
import styled from 'styled-components';
import {TextFormat} from '../Format';
import {WithTranslation} from 'react-i18next';
import Status, {IStatusProps} from './Status';
import {FlexContainer} from '../Styled/FlexContainer';

export interface IStatusTitleProps
  extends Omit<IStatusProps, keyof WithTranslation> {
  children: React.ReactNode;
}

const StyledFlexContainer = styled(FlexContainer)`
  height: fit-content;
`;

export default function StatusTitle({
  children,
  ...rest
}: IStatusTitleProps): JSX.Element {
  return (
    <StyledFlexContainer flexDirection="column">
      <TextFormat>{children}</TextFormat>
      <Status {...rest} />
    </StyledFlexContainer>
  );
}
