import React from 'react';
import Switch from 'antd/es/switch';
import {StyledTitle} from '../../../components/lib/Styled';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

export interface StoreDocumentWithCreditOnlySwitchProps {
  loading: boolean;
  showWithCreditsOnly: boolean;
  handleChangeWithCreditOnly: (value: any) => Promise<any>;
}

const StyledSwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  gap: 5px;

  height: 32px;
  margin-left: auto;
`;

export function StoreDocumentWithCreditOnlySwitch({
  showWithCreditsOnly,
  handleChangeWithCreditOnly,
  loading,
}: StoreDocumentWithCreditOnlySwitchProps): React.JSX.Element {
  const {t} = useTranslation();

  return (
    <StyledSwitchContainer>
      <Switch
        size="default"
        onChange={handleChangeWithCreditOnly}
        checked={showWithCreditsOnly}
        loading={loading}
        disabled={loading}
      />
      <StyledTitle>{t('Show the debtor')}</StyledTitle>
    </StyledSwitchContainer>
  );
}
