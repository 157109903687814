import {Dispatch} from 'redux';
import {CLIENT_ABONEMENT} from '../constants';
import {IClientAbonementModel} from '../../struture';
import {ApiError} from '../../services/types';

export interface ISetClientAbonementParam {
  type: CLIENT_ABONEMENT;
  abonement: IClientAbonementModel | null;
}

export interface IUpdateClientAbonementParam {
  type: CLIENT_ABONEMENT;
  abonement: IClientAbonementModel;
}

export interface IResetClientAbonementParam {
  type: CLIENT_ABONEMENT;
}

export interface IErrorClientAbonementParam {
  type: CLIENT_ABONEMENT;
  error: ApiError | undefined;
}

export function setClientAbonement(
  abonement: IClientAbonementModel | null,
): (dispatch: Dispatch) => Promise<ISetClientAbonementParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CLIENT_ABONEMENT.SET_CLIENT_ABONEMENT,
      abonement,
    });
  };
}

export function updateClientAbonement(
  abonement: IClientAbonementModel,
): (dispatch: Dispatch) => Promise<IUpdateClientAbonementParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CLIENT_ABONEMENT.UPDATE_CLIENT_ABONEMENT,
      abonement,
    });
  };
}

export function resetClientAbonement(): (
  dispatch: Dispatch,
) => Promise<IResetClientAbonementParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CLIENT_ABONEMENT.RESET_CLIENT_ABONEMENT,
    });
  };
}

export function errorClientAbonement(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorClientAbonementParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CLIENT_ABONEMENT.ERROR_CLIENT_ABONEMENT,
      error,
    });
  };
}
