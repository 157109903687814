import React, {useCallback, useState} from 'react';
import {Empty} from 'antd';
import {useTranslation} from 'react-i18next';
import {
  IUseStoreDocumentListProps,
  useKeyboardOpenForm,
  useSearchInputFocus,
  useStateCompanyClientList,
  useStopLoading,
  useStoredCompanies,
  useStoredStoreDocument,
  useStoredStoreDocumentList,
} from '../../../../hooks';
import {
  StorePreorderCreateButton,
  StorePreorderDeleteButton,
  StorePreorderUpdateButton,
} from '../Buttons';
import {
  isThereContent,
  textToUpperCase,
  toMoment,
} from '../../../../services/helpers';
import {
  Table,
  TableActionCell,
} from '../../../../components/lib/libV2/DataDisplay';
import {useBreadcrumb} from '../../../../components/lib/libV2/hooks';
import {
  ClientModel,
  StoreDocumentFormDTO,
  StoreDocumentMapper,
  StoreDocumentModel,
  StoreDocumentShowOnly,
  StoreDocumentStatus,
  StoreDocumentType,
} from '../../../../struture';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '../../../../services/types';
import {useAcl} from '../../../../contex';
import {
  DetailsItemView,
  HeaderLeft,
  ListActions,
  TableIndexField,
} from '../../../../components/lib/DataDisplay';
import {useNavigate, useParams} from 'react-router';
import {ListLayout} from '../../../../components/lib/Layout';
import styled, {useTheme} from 'styled-components';
import {PlusOutlined} from '@ant-design/icons';
import {StorePreorederDetailsView} from '../Show';
import {
  StyledDescription,
  successButtonStyle,
} from '../../../../components/lib/Styled';
import {
  StoreDocumentListBottomView,
  StoreDocumentTypeView,
  StoreDocumentClientSearchView,
  StyledListActionsContainer,
  SegmentedContainer,
  RangeContainer,
  StoreProductPriceView,
} from '../../Show';
import {StyledDetailsView} from '../../../../components/lib/Styled/DetailsView';
import ListDateRange from '../../../../components/lib/DataDisplay/ListActions/ListDateRange';

import '../../../Orders/List/OrderList.less';
import {SessionStorageItems} from '../../../../services/const';

const StyledStorePostingCreateButton = styled(StorePreorderCreateButton)`
  ${successButtonStyle}
`;

const DELETED_STATUSES = [StoreDocumentStatus.ERROR, StoreDocumentStatus.DRAFT];

export function StorePreorderListPage(): JSX.Element {
  const navigate = useNavigate();
  const {defaultCompanyUuid, defaultCompany} = useStoredCompanies();
  const {manage} = useAcl(({store}) => store);
  const {t} = useTranslation();
  const {show_only} = useParams();
  const theme: any = useTheme();

  const {ref: listRef} = useKeyboardOpenForm({
    className: 'preorder-create-event',
    disabled: !manage,
  });

  const [searchLoading, setSearchLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState<ClientModel | null>(
    null,
  );

  const residentUuid =
    sessionStorage.getItem(
      `${SessionStorageItems.storeDocumentResidentUuid}${StoreDocumentType.PREORDER}`,
    ) || '';

  const {
    documentList,
    limit,
    total,
    loading: loadingStoreDocumentList,
    loadingMore,
    error: errorStoreDocumentList,
    keywords,
    start_date,
    end_date,
    stats,
    page,

    handleDeleteStoreDocuments: onDeleteStoreDocuments,
    handleSearchStoreDocuments,
    handlePickStoreDocumentRange,
    handleRefreshStoreDocuments,
    handleRefreshStoreDocumentsType: onRefreshStoreDocumentsType,
  } = useStoredStoreDocumentList({
    companyUuid: defaultCompanyUuid,
    documentType: StoreDocumentType.PREORDER,
    show_only: show_only as StoreDocumentShowOnly,
    alwaysSendRequest: true,
    resident_uuid: residentUuid,
  });

  const {
    handleCreateOrUpdateStoreDocument: onCreateOrUpdateStoreDocument,
    handlePaymentStoreDocument,
    handleUpdateStoreDocument,
  } = useStoredStoreDocument({
    loadOnInit: false,
    companyUuid: defaultCompanyUuid,
    documentUuid: '',
    documentType: StoreDocumentType.PREORDER,
  });

  const {
    clients,
    loading: clientListLoading,
    handleSearchClients,
  } = useStateCompanyClientList({
    companyUuid: defaultCompanyUuid,
    provisioner_only: true,
  });

  const loading = useStopLoading({
    loading: loadingStoreDocumentList || loadingMore || searchLoading,
    error: errorStoreDocumentList,
    message:
      'An error occurred during invoices for order to the provider loading',
  });

  const {inputRef, focus, daleyFocus} = useSearchInputFocus({loading});

  const handleDeleteStoreDocuments = useCallback(
    async (value: string[]) => {
      await onDeleteStoreDocuments(value);
      focus();
    },
    [focus, onDeleteStoreDocuments],
  );

  const handleCreateOrUpdateStoreDocument = useCallback(
    async (value: StoreDocumentFormDTO) => {
      const document = await onCreateOrUpdateStoreDocument(value);

      if (document && !value?.uuid) {
        navigate(
          `/${Routes.app}/${Routes.stores}/${Routes.storeOrderProvider}/${document?.uuid}`,
        );
      }
    },
    [navigate, onCreateOrUpdateStoreDocument],
  );

  const handleRefreshStoreDocumentsType = useCallback(
    async (value: Partial<IUseStoreDocumentListProps>) => {
      if (value?.show_only) {
        navigate(
          `/${Routes.app}/${Routes.stores}/${Routes.storeOrderProvider}/${Routes.storeShowOnly}/${value?.show_only}`,
        );
      } else {
        navigate(
          `/${Routes.app}/${Routes.stores}/${Routes.storeOrderProvider}`,
        );
      }

      await onRefreshStoreDocumentsType({
        ...value,
        resident_uuid: value?.resident_uuid || residentUuid,
      });
    },
    [navigate, onRefreshStoreDocumentsType, residentUuid],
  );

  const onClientSelect = useCallback(
    async (resident_uuid: string) => {
      setSearchLoading(true);

      await onRefreshStoreDocumentsType({
        keywords,
        show_only: show_only as StoreDocumentShowOnly,
        resident_uuid,
      });

      const client = clients?.find(({uuid}) => uuid === resident_uuid);

      setSelectedClient(client || null);

      sessionStorage.setItem(
        `${SessionStorageItems.storeDocumentResidentUuid}${StoreDocumentType.PREORDER}`,
        resident_uuid,
      );

      setSearchLoading(false);
    },
    [clients, keywords, onRefreshStoreDocumentsType, show_only],
  );

  const routes = useBreadcrumb([
    {
      path: `/${Routes.stores}/${Routes.storeOrderProvider}`,
      breadcrumbName: 'Invoices for order to the provider',
    },
  ]);

  const columns = [
    {
      ellipsis: true,
      title: `№`,
      key: 'index',
      align: 'center' as any,
      className: 'order-list-td order-list--middle-text',
      render: (document: StoreDocumentModel, items: any, index: number) => (
        <TableIndexField number={(page - 1) * limit + index + 1} />
      ),
    },
    {
      ellipsis: true,
      title: t('Invoices for order to the provider'),
      key: 'order',
      render: (document: StoreDocumentModel) => (
        <StorePreorederDetailsView document={document} />
      ),
    },
    {
      ellipsis: true,
      title: t('Provider'),
      key: 'provider',
      render: (document: StoreDocumentModel) => (
        <DetailsItemView<ClientModel>
          item={document?.clientModel}
          fields={{
            fullNameClient: {
              link: 'Go to provider details page',
              path: `/${Routes.app}/${Routes.clients}/${document?.clientModel?.uuid}`,
            },
            phone: {description: ''},
          }}
        />
      ),
    },
    {
      ellipsis: true,
      title: `${t('Sum')}, ${defaultCompany?.currency_symbol || ''}`,
      key: 'sum',
      render: (document: StoreDocumentModel) => (
        <StoreProductPriceView document={document} />
      ),
    },
    {
      ellipsis: true,
      title: t('Actions'),
      key: 'store_actions',
      align: 'center' as any,
      fixed: 'right' as any,
      className: 'order-list-td',
      render: (document: StoreDocumentModel) => {
        return (
          <TableActionCell>
            {document?.doc_status_text === StoreDocumentStatus.DRAFT ? (
              <StorePreorderUpdateButton
                fromList
                document={StoreDocumentMapper.toStoreDocumentFormDTO(
                  document!,
                  {
                    editMode: true,
                  },
                )}
                onSuccess={handleUpdateStoreDocument}
                handlePaymentStoreDocument={handlePaymentStoreDocument}
                onCancel={daleyFocus}
              />
            ) : null}

            <StorePreorderDeleteButton
              disabled={
                !manage || !DELETED_STATUSES.includes(document?.doc_status_text)
              }
              documents={[document]}
              onSuccess={handleDeleteStoreDocuments}
            />
          </TableActionCell>
        );
      },
    },
  ];

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.companies}/${defaultCompanyUuid}`);
  }, [defaultCompanyUuid, navigate]);

  return (
    <ListLayout
      ref={listRef}
      aclItem={RoutesAcl[Routes.stores]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(
        t(RoutesTitle[RoutesType.storeOrderProvider]),
      )}
      headerRight={
        <StorePreorderCreateButton
          disabled={!manage}
          loading={loading}
          onSuccess={handleCreateOrUpdateStoreDocument}
          handlePaymentStoreDocument={handlePaymentStoreDocument}
          document={StoreDocumentMapper.toStoreDocumentFormDTO(
            {} as StoreDocumentModel,
            {
              editMode: false,
              client: selectedClient,
            },
          )}
          className="preorder-create-event"
          onCancel={daleyFocus}
        />
      }
      routes={routes}
      empty={
        documentList?.size ? null : (
          <Empty
            description={t(
              'There are no invoices for order to the provider created',
            )}>
            <StorePreorderCreateButton
              disabled={!manage}
              loading={loading}
              type="primary"
              onSuccess={handleCreateOrUpdateStoreDocument}
              handlePaymentStoreDocument={handlePaymentStoreDocument}
              title={t('Create invoice for order to the provider')}
              document={StoreDocumentMapper.toStoreDocumentFormDTO(
                {} as StoreDocumentModel,
                {
                  editMode: false,
                  client: selectedClient,
                },
              )}
              onCancel={daleyFocus}
            />
          </Empty>
        )
      }
      headerExtra={
        <StyledListActionsContainer>
          <RangeContainer>
            <ListDateRange
              defaultDateRangeValue={
                start_date && end_date
                  ? [toMoment(start_date), toMoment(end_date)]
                  : undefined
              }
              onChange={handlePickStoreDocumentRange}
              loading={loading}
            />
          </RangeContainer>

          <SegmentedContainer>
            <StyledDetailsView
              titles={[]}
              selected={[
                show_only === undefined,
                show_only === StoreDocumentShowOnly.PAID,
                show_only === StoreDocumentShowOnly.UNPAID,
              ]}>
              <StoreDocumentTypeView
                color={theme.tabs.link}
                title="All documents"
                stats={stats}
                onClick={handleRefreshStoreDocumentsType}
                disabled={loading}
              />
              <StoreDocumentTypeView
                color={theme.tabs.success}
                type={StoreDocumentShowOnly.PAID}
                title={t('tPaid')}
                stats={stats}
                onClick={handleRefreshStoreDocumentsType}
                disabled={loading}
              />
              <StoreDocumentTypeView
                color={theme.tabs.error}
                type={StoreDocumentShowOnly.UNPAID}
                title={t('Waiting for payment')}
                stats={stats}
                onClick={handleRefreshStoreDocumentsType}
                disabled={loading}
              />
            </StyledDetailsView>
          </SegmentedContainer>
          <ListActions
            withoutPicker
            inputRef={inputRef}
            searchText={keywords}
            loading={loading}
            handleSearch={(keywords) => handleSearchStoreDocuments({keywords})}
            inputTooltip={t('Search invoice for order to the provider')}
            inputLabel={t('Search invoice for order to the provider')}
            withSearchContent={
              <StyledStorePostingCreateButton
                disabled={!manage}
                loading={loading}
                type="primary"
                onSuccess={handleCreateOrUpdateStoreDocument}
                handlePaymentStoreDocument={handlePaymentStoreDocument}
                document={StoreDocumentMapper.toStoreDocumentFormDTO(
                  {} as StoreDocumentModel,
                  {
                    editMode: false,
                    client: selectedClient,
                  },
                )}
                title={t('Invoice for order to the provider')}
                icon={<PlusOutlined />}
                onCancel={daleyFocus}
              />
            }
            withSearchEndContent={
              <StoreDocumentClientSearchView
                isProvider
                loading={loading}
                onClientChange={onClientSelect}
                clientUuid={residentUuid}
                clients={clients}
                clientListLoading={clientListLoading}
                handleSearchClients={handleSearchClients}
              />
            }
          />
        </StyledListActionsContainer>
      }
      outsideFooterContent={
        isThereContent(stats) ? (
          <StoreDocumentListBottomView
            stats={stats}
            documentType={StoreDocumentType.PREORDER}
          />
        ) : null
      }
      loading={loading && !documentList?.size}>
      <Table<StoreDocumentModel>
        pageSize={limit}
        total={total}
        onChange={handleRefreshStoreDocuments}
        dataSource={documentList}
        columns={columns}
        loading={loading}
        page={page}
      />
    </ListLayout>
  );
}
