import React, {useCallback, useEffect, useState} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {Moment} from 'moment';
import {
  getListOfWeekday,
  getPickerHourOrMinute,
  getRangePickerHourOrMinute,
  getWeekdayPickerTimeFormat,
  head,
  monthWeekDays,
  reduce,
  splittingTimeToMoment,
  toMomentString,
  filter,
  toMoment,
} from '../../../services/helpers';
import TimePickerWithCheckbox, {
  IOnChangePicker,
} from './TimePickerWithCheckbox';
import {useDefaultForm} from '../../../contex';

export default withTranslation()(WeekdayTimePickerForm);

export interface IWeekdayTimePickerFormProps extends WithTranslation {
  editMode?: boolean;
  onChange?: (value: IOnChangePicker & {fieldValue: any}) => void;
  isFixedRange: boolean;
  isRangePicker?: boolean;
  fixedTitle?: string;
  invert?: boolean;
  disabled?: boolean;
  checkboxDisable?: boolean;
  name?: string;
}

const THE_LIST_OF_WEEK_DAYS = [...monthWeekDays];

/**
 * @desc Компонент для вибору часу в розрізі тижня
 * */
function WeekdayTimePickerForm({
  t,
  editMode = false,
  onChange = () => {},
  isFixedRange = false,
  isRangePicker = false,
  fixedTitle,
  invert = true,
  disabled,
  checkboxDisable = true,
  name = 'weekdays',
}: IWeekdayTimePickerFormProps): JSX.Element | null {
  const {valid, formData} = useDefaultForm();

  const weekdays = formData[name];
  const [listOfWeekdays, setListOfWeekdays] = useState<any>(
    THE_LIST_OF_WEEK_DAYS,
  );
  const [fixedWeekday, setFixedWeekday] = useState<string[] | null>(null);
  const [loading, setLoading] = useState(false);

  const buildWeekdayTime = useCallback(() => {
    if (isFixedRange) {
      if (head(weekdays)) {
        setFixedWeekday([
          fixedTitle || t('Mon-Fri'),
          splittingTimeToMoment(weekdays[0] || ''),
        ]);
      } else {
        setFixedWeekday([fixedTitle || t('Mon-Fri'), []]);
      }
    } else {
      if (weekdays) {
        const update = reduce(
          getListOfWeekday(isRangePicker),
          [...THE_LIST_OF_WEEK_DAYS],
          weekdays,
        );

        setListOfWeekdays(update);
      } else {
        setListOfWeekdays([...THE_LIST_OF_WEEK_DAYS]);
      }
    }
    setLoading(true);
  }, [fixedTitle, isFixedRange, isRangePicker, t, weekdays]);

  useEffect(() => {
    if (editMode) {
      buildWeekdayTime();
    }
  }, [buildWeekdayTime, editMode, weekdays]);

  const onPickerChange = useCallback(
    (value: IOnChangePicker) => {
      const weekdays = formData[name];

      const correctEmptyValue = filter(Boolean, weekdays);

      onChange({...value, fieldValue: correctEmptyValue});
    },
    [formData, name, onChange],
  );

  const handleGetDefaultTime = useCallback(
    (
      value: Omit<IOnChangePicker, 'time'>,
    ): Moment | [Moment, Moment] | undefined => {
      const defaultTime = getWeekdayPickerTimeFormat(weekdays || []);

      onPickerChange({
        ...value,
        time:
          defaultTime ||
          (isRangePicker
            ? [
                toMomentString(new Date(), 'HH:mm'),
                toMomentString(toMoment(new Date()).add(1, 'hours'), 'HH:mm'),
              ]
            : toMomentString(new Date(), 'HH:mm')),
      });

      if (defaultTime) {
        return (
          Array.isArray(defaultTime)
            ? getRangePickerHourOrMinute(defaultTime)
            : getPickerHourOrMinute(defaultTime)
        ) as any;
      }
    },
    [isRangePicker, onPickerChange, weekdays],
  );

  if (!valid) {
    return null;
  }

  return (
    <div className="week-days-checker">
      {loading || !editMode ? (
        <>
          {isFixedRange && fixedWeekday ? (
            <TimePickerWithCheckbox
              disabled={disabled}
              isRangePicker={isRangePicker}
              key="fixed_range"
              value={fixedWeekday as [string, string]}
              onChange={onPickerChange}
              invert={editMode && invert}
              handleGetDefaultTime={handleGetDefaultTime}
              checkboxDisable={checkboxDisable}
            />
          ) : (
            listOfWeekdays.map((item: any, index: number) => (
              <TimePickerWithCheckbox
                disabled={disabled}
                key={String(`${index}`)}
                value={item}
                onChange={onPickerChange}
                isRangePicker={isRangePicker}
                handleGetDefaultTime={handleGetDefaultTime}
                invert={editMode && invert}
              />
            ))
          )}
        </>
      ) : null}
    </div>
  );
}
