import * as React from 'react';
import {List} from 'immutable';
import {ApiError, IEntityProps, IListSearchProps} from '../services/types';
import {
  IScheduleStatusListDTO,
  ScheduleStatusMapper,
  ScheduleStatusModel,
} from '../struture';
import useCancellablePromise from './useCancellablePromise';
import useStateReducer from './useStateReducer';
import {getScheduleStatusListById} from '../services/api/orders';

export interface IUseScheduleStatusListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  scheduleUuid: string;
}

export interface IUseScheduleStatusListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<ScheduleStatusModel> | null;
  refresh: (
    value: Partial<IUseScheduleStatusListProps>,
  ) => Promise<List<ScheduleStatusModel> | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  scheduleUuid: string;
}

export function useScheduleStatusList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    scheduleUuid,
  }: IUseScheduleStatusListProps = {} as IUseScheduleStatusListProps,
): IUseScheduleStatusListReturnType {
  const {cancellablePromise} = useCancellablePromise();
  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    scheduleUuid: listUuid,
    handleUpdate,
  } = useStateReducer<Omit<IUseScheduleStatusListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    scheduleUuid,
    error: null,
    loading: loadOnInit,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      scheduleUuid = listUuid,
    }: Partial<IUseScheduleStatusListProps> = {}): Promise<List<ScheduleStatusModel> | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          scheduleUuid,
        });

        const {statuses, total} =
          await cancellablePromise<IScheduleStatusListDTO>(
            getScheduleStatusListById({
              limit,
              offset,
              keywords,
              scheduleUuid,
            }),
          );

        const scheduleStatusListModel =
          ScheduleStatusMapper.toScheduleStatusListModel(statuses, total);

        handleUpdate({
          entityList: scheduleStatusListModel?.statuses,
          total: scheduleStatusListModel?.total,
          loading: false,
        });

        return scheduleStatusListModel?.statuses;
      } catch (e) {
        handleUpdate({
          error,
          loading: false,
        });
      }
    },
    [
      cancellablePromise,
      error,
      handleUpdate,
      listUuid,
      listKeywords,
      listLimit,
      listOffset,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && scheduleUuid) {
      (async () => {
        await refresh({scheduleUuid});
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, scheduleUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    scheduleUuid: listUuid,
  };
}
