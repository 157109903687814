import {UserModel} from '../internal';

export class UserFormDTO {
  public uuid: string;

  public email: string;

  public first_name: string;

  public last_name: string;

  public phone: string;

  public birthday: string;

  public address: string;

  public gender: string;

  public subscription_discount: number;

  public goods_discount: number;

  public discount_card_number: number;

  public default_company: string;

  public login: string;

  public password: string;

  public confirm_password: string;

  constructor(props?: UserModel) {
    this.uuid = props?.uuid || '';
    this.email = props?.email || '';
    this.first_name = props?.first_name || '';
    this.last_name = props?.last_name || '';
    this.phone = props?.phone || '';
    this.birthday = props?.birthday || '';
    this.address = props?.address || '';
    this.gender = props?.gender || '';
    this.subscription_discount = props?.subscription_discount || 0;
    this.goods_discount = props?.subscription_discount || 0;
    this.discount_card_number = props?.subscription_discount || 0;
    this.default_company = props?.default_company?.uuid || '';
    this.login = props?.login || '';
    this.password = props?.password || '';
    this.confirm_password = props?.confirm_password || '';
  }
}
