import React, {useCallback, useEffect, useRef, useState} from 'react';
import {ReactImageGalleryItem} from 'react-image-gallery';
import {
  IUseStateProductPhotoListReturnType,
  IUseStateProductReturnType,
  useSize,
} from '../../../hooks';
import {List} from 'immutable';
import styled, {css} from 'styled-components';
import {FileDraggerView} from '../../Orders/Show/FileDraggerView';
import {CheckCircleOutlined, DeleteOutlined} from '@ant-design/icons';
import {
  AddButton,
  DeleteButton,
  ImageGallery,
} from '../../../components/lib/DataDisplay';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../../../contex';
import {TextFormat} from '../../../components/lib/Format';
import {listToArray} from '../../../services/helpers';

import './ProductGallery.less';

export interface ProductGalleryProps
  extends Pick<
      IUseStateProductPhotoListReturnType,
      'photoList' | 'handleDeletePhotos' | 'handleUploadPhotos'
    >,
    Pick<IUseStateProductReturnType, 'handleUpdateProductDefaultPhoto'> {
  selectedPhoto: string;
}

const StyledContainer = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 100%;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  height: 32px;
`;

const StyledAddButton = styled(AddButton)`
  &&& > * {
    color: ${({theme}) => theme.colors.success};
  }

  &&&:hover,
  &&&:active,
  &&&:focus {
    opacity: 0.7;
  }

  ${({disabled, loading}) =>
    (disabled || loading) &&
    css`
      &&&:hover,
      &&&:active,
      &&&:focus {
        opacity: 1;

        &&& > * {
          color: ${({theme}) => theme.colors.disabled};
        }
      }
    `}
`;

enum ProductGalleryLoading {
  DEFAULT = 'default',
  DELETE = 'delete',
  UNKNOWN = 'unknown',
}

export function ProductGallery({
  photoList,
  handleUploadPhotos,
  selectedPhoto: initSelectedPhoto,
  handleDeletePhotos: onDeletePhotos,
  handleUpdateProductDefaultPhoto: onUpdateProductDefaultPhoto,
}: ProductGalleryProps): React.JSX.Element {
  const {t} = useTranslation();

  const contentRef = useRef(null);
  const {width: contentWidth} = useSize(contentRef);
  const isShowText = contentWidth > 400;

  const [items, setItems] = useState<ReactImageGalleryItem[]>([
    {
      original: '/images-empty.png',
      thumbnail: '/images-empty.png',
      thumbnailHeight: 50,
    },
  ]);

  const [selectedPhoto, setSelectedPhoto] = useState('');
  const [selectedPhotoUrl, setSelectedPhotoUrl] = useState('');
  const [loading, setLoading] = useState<ProductGalleryLoading>(
    ProductGalleryLoading.UNKNOWN,
  );
  const [isDefaultPhoto, setIsDefaultPhoto] = useState(true);

  const once = useRef(false);

  useEffect(() => {
    if (List.isList(photoList) && photoList?.size) {
      const items = photoList?.map(({file_url, file_original_name}) => ({
        original: file_url,
        thumbnail: file_url,
        originalTitle: file_original_name,
        thumbnailTitle: file_original_name,
        thumbnailHeight: 50,
      }));

      const photo = photoList?.first();

      setItems(listToArray(items));
      setIsDefaultPhoto(false);

      if (photo && !once.current) {
        once.current = true;
        setSelectedPhoto(photo?.uuid);
        setSelectedPhotoUrl(photo?.file_url);
      }
    }
  }, [photoList]);

  const handleSelectPhoto = useCallback(
    (index: number) => {
      const photo = photoList?.get(index);

      if (photo) {
        once.current = true;
        setSelectedPhoto(photo?.uuid);
        setSelectedPhotoUrl(photo?.file_url);
      }
    },
    [photoList],
  );

  const handleDeletePhotos = useCallback(async () => {
    setLoading(ProductGalleryLoading.DELETE);

    await onDeletePhotos([selectedPhoto]);

    setLoading(ProductGalleryLoading.UNKNOWN);
  }, [onDeletePhotos, selectedPhoto]);

  const handleUpdateProductDefaultPhoto = useCallback(async () => {
    setLoading(ProductGalleryLoading.DEFAULT);

    await onUpdateProductDefaultPhoto(selectedPhoto);

    setLoading(ProductGalleryLoading.UNKNOWN);
  }, [onUpdateProductDefaultPhoto, selectedPhoto]);

  return (
    <StyledContainer ref={contentRef}>
      <StyledButtonContainer>
        {!photoList?.size ||
        (initSelectedPhoto &&
          selectedPhotoUrl &&
          initSelectedPhoto === selectedPhotoUrl) ? null : (
          <>
            <StyledAddButton
              type="text"
              tooltipTitle={t('Set by default')}
              onClick={handleUpdateProductDefaultPhoto}
              disabled={loading !== ProductGalleryLoading.UNKNOWN}
              icon={<CheckCircleOutlined />}
              loading={loading === ProductGalleryLoading.DEFAULT}>
              {isShowText ? t('Set by default') : ' '}
            </StyledAddButton>

            <DeleteButton
              type="text"
              tooltipTitle={t('Delete photo')}
              confirmTitle={
                <TextFormat breakWord>
                  {t(`Are you sure you want to delete photo`)}
                </TextFormat>
              }
              onConfirm={handleDeletePhotos}
              loading={false}
              disabled={loading !== ProductGalleryLoading.UNKNOWN}
              icon={<DeleteOutlined />}
              cancelButtonText={t('Cancel')}>
              {isShowText ? t('Delete') : ' '}
            </DeleteButton>
          </>
        )}
      </StyledButtonContainer>

      <ImageGallery
        additionalClass="product-gallery"
        showBullets={!isDefaultPhoto}
        showThumbnails={!isDefaultPhoto}
        showNav={!isDefaultPhoto}
        showFullscreenButton={!isDefaultPhoto}
        items={items}
        showPlayButton={false}
        onSlide={handleSelectPhoto}
        // onImageError={(e) => {
        //   alert(
        //     'error',
        //     t('Photo'),
        //     t('An error occurred during download photo'),
        //   );
        // }}
      />

      <FileDraggerView
        accept="image/png, image/jpeg, image/jpg"
        handleUploadFiles={handleUploadPhotos}
        description="Files that will be attached to the product. Single or mass download support."
        stats={{} as any}
      />
    </StyledContainer>
  );
}
