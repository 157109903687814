import * as React from 'react';

export default function useOutsideElementClickHandler(
  ref: any,
  callback: any,
  targetElementId: string,
  ignoreElementIds?: string[],
): void {
  React.useEffect(() => {
    const target = document.getElementById(targetElementId);

    const handleClickOutside = (evt: any) => {
      if (ref.current && !ref.current.contains(evt.target)) {
        if (Array.isArray(ignoreElementIds)) {
          if (
            !ignoreElementIds.some((item) => evt.target.closest(`#${item}`))
          ) {
            callback();
          }
        } else {
          callback();
        }
      }
    };

    if (target) {
      target.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      if (target) {
        target.removeEventListener('mousedown', handleClickOutside);
      }
    };
  });
}
