import React from 'react';
import {SystemModulesLayout} from '../SystemModulesLayout';
import styled from 'styled-components';
import {HEADER_HEIGHT} from '../const';
import {useListLayout} from '../../../../contex';
import {IModulesMap} from '../../../../hooks';

export interface IListContentProps {
  children: React.ReactNode | React.ReactNode[];
  moduleItem?: keyof IModulesMap;
  className?: string;
}

export const PADDING_INDENT = 15;

const StyledListContent = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  flex-grow: 3;
  flex-shrink: 1;
  flex-basis: content;

  width: 300px;

  padding-left: 24px;
  padding-right: 12px;

  min-height: calc(100vh - ${HEADER_HEIGHT}px - ${PADDING_INDENT}px);
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;

  width: 100%;
`;

export function ListContent({
  children,
  moduleItem,
  className,
}: IListContentProps): React.JSX.Element {
  const {contentRef, headerRef} = useListLayout();

  return (
    <StyledContent ref={contentRef}>
      <StyledListContent className={className}>
        <SystemModulesLayout
          moduleItem={moduleItem}
          height={headerRef?.current?.getBoundingClientRect()?.height || 0}>
          {children}
        </SystemModulesLayout>
      </StyledListContent>
    </StyledContent>
  );
}
