import React, {useCallback, useMemo, useState} from 'react';
import {Empty} from 'antd';
import {useTranslation} from 'react-i18next';
import {
  useKeyboardOpenForm,
  useSearchInputFocus,
  useStopLoading,
  useStoredCompanies,
} from '../../../../hooks';
import {StoreRelatedDeleteButton, StoreRelatedUpdateButton} from '../Buttons';
import {textToUpperCase, toDateByFormat} from '../../../../services/helpers';
import {
  Table,
  TableActionCell,
} from '../../../../components/lib/libV2/DataDisplay';
import {useBreadcrumb} from '../../../../components/lib/libV2/hooks';
import {
  ClientModel,
  StoreDocumentMapper,
  StoreDocumentModel,
  StoreDocumentStatus,
  StoreDocumentType,
} from '../../../../struture';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '../../../../services/types';
import {useAcl} from '../../../../contex';
import {
  DetailsItemView,
  HeaderLeft,
  ListActions,
} from '../../../../components/lib/DataDisplay';
import {useNavigate, useParams} from 'react-router';
import {ListLayout} from '../../../../components/lib/Layout';
import styled from 'styled-components';
import {PlusOutlined} from '@ant-design/icons';
import {StoreRelatedDetailsView, StoreRelatedPriceView} from '../Show';
import {successButtonStyle} from '../../../../components/lib/Styled';
import useStateStoreDocumentRelatedDocumentList, {
  RelatedReturnDocumentType,
  RelatedReturnDocumentTypeComment,
} from '../../../../hooks/useStateStoreDocumentRelatedDocumentList';
import {StyledListActionsContainer} from '../../../Payments/Show/ListStyledComponent';
import {StoreDocumentPageHeader} from '../../Show';

import '../../../Orders/List/OrderList.less';

export interface IStoreRelatedDocumentListPageProps {}

const StyledStoreRelatedUpdateButton = styled(StoreRelatedUpdateButton)`
  ${successButtonStyle}
`;

const ListActionsContainer = styled(StyledListActionsContainer)`
  gap: 10px;
`;

const CREATE_DOCUMENT_TYPE = [StoreDocumentType.IN, StoreDocumentType.OUT];

export const RELATED_ROUTES_NAME = {
  [StoreDocumentType.IN]: Routes.storePosting,
  [StoreDocumentType.OUT]: Routes.storeRealization,
  [StoreDocumentType.ERASE]: Routes.storeWriteOff,
  [StoreDocumentType.RETURN]: Routes.storeReturn,
  [StoreDocumentType.CRETURN]: Routes.storeReturnClient,
  [StoreDocumentType.MOVE]: Routes.storeMoving,
  [StoreDocumentType.SALE]: Routes.storeSale,
  [StoreDocumentType.PREORDER]: Routes.storeOrderProvider,
};

export const RELATED_ROUTES_TITLE = {
  [StoreDocumentType.IN]: 'Bill of Lading',
  [StoreDocumentType.OUT]: 'Sales invoice',
  [StoreDocumentType.ERASE]: 'Bill of lading for write off',
  [StoreDocumentType.RETURN]: 'Invoice for return to the supplier',
  [StoreDocumentType.CRETURN]: 'Invoice for return to the client',
  [StoreDocumentType.MOVE]: 'Bill of lading for movement',
  [StoreDocumentType.SALE]: 'Sale',
  [StoreDocumentType.PREORDER]: 'Invoice for order to the provider',
};

export function StoreRelatedListPage({}: IStoreRelatedDocumentListPageProps): JSX.Element {
  const navigate = useNavigate();
  const {documentId} = useParams();
  const {defaultCompany} = useStoredCompanies();
  const {manage} = useAcl(({store}) => store);
  const {t} = useTranslation();

  const {ref: listRef} = useKeyboardOpenForm({
    className: 'related-create-event',
    disabled: !manage,
  });

  const [selectedStoreDocument, setSelectedStoreDocument] = useState<
    StoreDocumentModel[]
  >([]);

  const {
    documentList,
    limit,
    total,
    loading: loadingStoreDocumentList,
    error: errorStoreDocumentList,
    keywords,
    document,

    handleDeleteStoreDocuments: onDeleteStoreDocuments,
    handleSearchStoreDocuments,
    handleRefreshStoreDocuments,

    handleCreateOrUpdateStoreDocument,
    handleUpdateStoreDocument,
    handlePaymentStoreDocument,
  } = useStateStoreDocumentRelatedDocumentList({
    documentUuid: documentId!,
  });

  const loading = useStopLoading({
    loading: loadingStoreDocumentList,
    error: errorStoreDocumentList,
    message: 'An error occurred during store related loading',
  });

  const {inputRef, focus, daleyFocus} = useSearchInputFocus({loading});

  const handleDeleteStoreDocuments = useCallback(
    async (document: StoreDocumentModel) => {
      await onDeleteStoreDocuments(document);
      focus();
    },
    [focus, onDeleteStoreDocuments],
  );

  const routes = useBreadcrumb([
    {
      path: `/${Routes.stores}/${RELATED_ROUTES_NAME[document?.doc_type!]}`,
      breadcrumbName:
        RELATED_ROUTES_TITLE[document?.doc_type!] || 'Show documents',
    },
    {
      path: `/${Routes.stores}/${
        RELATED_ROUTES_NAME[document?.doc_type!]
      }/${documentId}`,
      breadcrumbName: document?.doc_local_number || 'Show document',
    },
    {
      path: `/${Routes.stores}/${
        RELATED_ROUTES_NAME[document?.doc_type!]
      }/${documentId}/${Routes.storeRelated}`,
      breadcrumbName: RoutesTitle[RoutesType.storeRelated],
    },
  ]);

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: selectedStoreDocument.map(({uuid}) => uuid),
      onChange: (
        selectedRowKeys: React.Key[],
        selectedRecords: StoreDocumentModel[],
      ) => {
        setSelectedStoreDocument(selectedRecords);
      },
    }),
    [selectedStoreDocument],
  );

  const columns = [
    {
      ellipsis: true,
      title: t('Related documents'),
      key: 'posting',
      render: (document: StoreDocumentModel) => (
        <StoreRelatedDetailsView document={document} />
      ),
    },
    {
      ellipsis: true,
      title: t('Provider'),
      key: 'provider',
      render: (document: StoreDocumentModel) => (
        <DetailsItemView<ClientModel>
          item={document?.clientModel}
          fields={{
            fullNameClient: {
              link: 'Go to provider details page',
              path: `/${Routes.app}/${Routes.clients}/${document?.clientModel?.uuid}`,
            },
            phone: {description: ''},
          }}
        />
      ),
    },
    {
      ellipsis: true,
      title: `${t('Sum')}, ${defaultCompany?.currency_symbol || ''}`,
      key: 'sum',
      align: 'right' as any,
      render: (document: StoreDocumentModel) => (
        <StoreRelatedPriceView document={document} />
      ),
    },
    {
      ellipsis: true,
      title: t('Actions'),
      key: 'store_actions',
      align: 'center' as any,
      fixed: 'right' as any,
      className: 'order-list-td',
      render: (document: StoreDocumentModel) => {
        return (
          <TableActionCell>
            {document?.doc_status_text === StoreDocumentStatus.DRAFT ? (
              <StoreRelatedUpdateButton
                fromList={false}
                from={document?.doc_type}
                document={StoreDocumentMapper.toStoreDocumentFormDTO(
                  {
                    documentModel: document!,
                    companyStoreModel: document?.companyStoreModel,
                    client: document?.client,
                    doc_status_text: StoreDocumentStatus.ACTIVE,
                  } as StoreDocumentModel,
                  {
                    editMode: true,
                    default_doc_items: document?.doc_items || [],
                  },
                )}
                onSuccess={handleUpdateStoreDocument}
                handlePaymentStoreDocument={handlePaymentStoreDocument}
                onCancel={daleyFocus}
                documentType={document?.doc_type}
                disabled={!manage || !document?.doc_is_editable}
              />
            ) : null}

            <StoreRelatedDeleteButton
              disabled={!manage || !document?.doc_is_editable}
              documents={[document]}
              onSuccess={() => handleDeleteStoreDocuments(document)}
              documentType={document?.doc_type}
            />
          </TableActionCell>
        );
      },
    },
  ];

  const navigateGoBack = useCallback(() => {
    navigate(
      `/${Routes.app}/${Routes.stores}/${
        RELATED_ROUTES_NAME[document?.doc_type!]
      }/${documentId}`,
    );
  }, [document?.doc_type, documentId, navigate]);

  const documentFormDTO = CREATE_DOCUMENT_TYPE.includes(document?.doc_type!)
    ? StoreDocumentMapper.toStoreDocumentFormDTO(
        {
          documentModel: document!,
          companyStoreModel: document?.companyStoreModel,
          client: document?.client,
          doc_status_text: StoreDocumentStatus.DRAFT,
          doc_type: RelatedReturnDocumentType[document?.doc_type!],
          doc_comment: RelatedReturnDocumentTypeComment[document?.doc_type!]
            ? `${RelatedReturnDocumentTypeComment[document?.doc_type!]} №${
                document?.doc_local_number
              } ${t('From').toLowerCase()} ${toDateByFormat(
                document?.doc_date,
                'DD.MM.YYYY HH:mm',
              )}`
            : '',
        } as StoreDocumentModel,
        {
          editMode: true,
        },
      )
    : undefined;

  return (
    <ListLayout
      ref={listRef}
      aclItem={RoutesAcl[Routes.stores]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.storeRelated]))}
      routes={routes}
      empty={
        documentList?.size ? null : (
          <Empty description={t('There are no store related document added')}>
            {CREATE_DOCUMENT_TYPE.includes(document?.doc_type!) ? (
              <StoreRelatedUpdateButton
                fromList={false}
                from={document?.doc_type}
                disabled={!manage}
                loading={loading}
                type="primary"
                onSuccess={handleCreateOrUpdateStoreDocument}
                handlePaymentStoreDocument={handlePaymentStoreDocument}
                title={t('Create return invoice')}
                document={documentFormDTO!}
                onCancel={daleyFocus}
                documentType={RelatedReturnDocumentType[document?.doc_type!]}
              />
            ) : null}
          </Empty>
        )
      }
      headerExtra={
        <ListActionsContainer>
          {document?.uuid ? (
            <StoreDocumentPageHeader
              withoutIndent
              withoutPayment
              document={document}
            />
          ) : null}
          <ListActions
            withoutPicker
            inputRef={inputRef}
            searchText={keywords}
            loading={loading}
            handleSearch={(keywords) => handleSearchStoreDocuments({keywords})}
            inputTooltip={t('Search related document')}
            inputLabel={t('Search related document')}
            withSearchContent={
              CREATE_DOCUMENT_TYPE.includes(document?.doc_type!) ? (
                <StyledStoreRelatedUpdateButton
                  fromList={false}
                  from={document?.doc_type}
                  disabled={!manage}
                  loading={loading}
                  type="primary"
                  onSuccess={handleCreateOrUpdateStoreDocument}
                  handlePaymentStoreDocument={handlePaymentStoreDocument}
                  document={documentFormDTO!}
                  title={t('Return invoice')}
                  icon={<PlusOutlined />}
                  onCancel={daleyFocus}
                  documentType={RelatedReturnDocumentType[document?.doc_type!]}
                />
              ) : null
            }
          />
        </ListActionsContainer>
      }
      loading={loading && !documentList?.size}>
      <Table<StoreDocumentModel>
        pageSize={limit}
        total={total}
        onChange={handleRefreshStoreDocuments}
        rowSelection={rowSelection}
        dataSource={documentList}
        columns={columns}
        loading={loading}
      />
    </ListLayout>
  );
}
