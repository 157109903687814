import React, {useCallback} from 'react';
import {Empty} from 'antd';
import {useTranslation} from 'react-i18next';
import {
  IUseStoreDocumentListProps,
  useKeyboardOpenForm,
  useSearchInputFocus,
  useStopLoading,
  useStoredCompanies,
  useStoredStoreDocument,
  useStoredStoreDocumentList,
} from '../../../../hooks';
import {
  StoreWriteOffCreateButton,
  StoreWriteOffDeleteButton,
  StoreWriteOffUpdateButton,
} from '../Buttons';
import {isThereContent, textToUpperCase} from '../../../../services/helpers';
import {
  Table,
  TableActionCell,
} from '../../../../components/lib/libV2/DataDisplay';
import {useBreadcrumb} from '../../../../components/lib/libV2/hooks';
import {
  StoreDocumentFormDTO,
  StoreDocumentMapper,
  StoreDocumentModel,
  StoreDocumentShowOnly,
  StoreDocumentStatus,
  StoreDocumentType,
} from '../../../../struture';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '../../../../services/types';
import {useAcl} from '../../../../contex';
import {
  HeaderLeft,
  ListActions,
  TableIndexField,
} from '../../../../components/lib/DataDisplay';
import {useNavigate, useParams} from 'react-router';
import {ListLayout} from '../../../../components/lib/Layout';
import styled, {useTheme} from 'styled-components';
import {PlusOutlined} from '@ant-design/icons';
import {StoreWriteOffDetailsView} from '../Show';
import {successButtonStyle} from '../../../../components/lib/Styled';
import {
  RangeContainer,
  SegmentedContainer,
  StoreDetailsView,
  StoreDocumentListBottomView,
  StoreDocumentTypeView,
  StyledListActionsContainer,
} from '../../Show';
import {toMoment} from '../../../../services/helpers';
import {StyledDetailsView} from '../../../../components/lib/Styled/DetailsView';
import ListDateRange from '../../../../components/lib/DataDisplay/ListActions/ListDateRange';

import '../../../Orders/List/OrderList.less';

export interface IStoreWriteOffListPageProps {}

const StyledStorePostingCreateButton = styled(StoreWriteOffCreateButton)`
  ${successButtonStyle}
`;

const DELETED_STATUSES = [StoreDocumentStatus.ERROR, StoreDocumentStatus.DRAFT];

export function StoreWriteOffListPage({}: IStoreWriteOffListPageProps): JSX.Element {
  const navigate = useNavigate();
  const {defaultCompanyUuid} = useStoredCompanies();
  const {manage} = useAcl(({store}) => store);
  const {t} = useTranslation();
  const {show_only} = useParams();
  const theme: any = useTheme();

  const {ref: listRef} = useKeyboardOpenForm({
    className: 'write-off-create-event',
    disabled: !manage,
  });

  const {
    documentList,
    limit,
    total,
    loading: loadingStoreDocumentList,
    error: errorStoreDocumentList,
    keywords,
    start_date,
    end_date,
    loadingMore,
    stats,
    page,

    handleDeleteStoreDocuments: onDeleteStoreDocuments,
    handleSearchStoreDocuments,
    handlePickStoreDocumentRange,
    handleRefreshStoreDocuments,
    handleRefreshStoreDocumentsType: onRefreshStoreDocumentsType,
  } = useStoredStoreDocumentList({
    companyUuid: defaultCompanyUuid,
    documentType: StoreDocumentType.ERASE,
    show_only: show_only as StoreDocumentShowOnly,
    alwaysSendRequest: true,
  });

  const {
    handleCreateStoreDocument: onCreateStoreDocument,
    handleUpdateStoreDocument,
  } = useStoredStoreDocument({
    loadOnInit: false,
    companyUuid: defaultCompanyUuid,
    documentUuid: '',
    documentType: StoreDocumentType.ERASE,
  });

  const loading = useStopLoading({
    loading: loadingStoreDocumentList || loadingMore,
    error: errorStoreDocumentList,
    message: 'An error occurred during store write off loading',
  });

  const {inputRef, focus, daleyFocus} = useSearchInputFocus({loading});

  const handleDeleteStoreDocuments = useCallback(
    async (value: string[]) => {
      await onDeleteStoreDocuments(value);
      focus();
    },
    [focus, onDeleteStoreDocuments],
  );

  const handleCreateStoreDocument = useCallback(
    async (value: StoreDocumentFormDTO) => {
      const documentModel = await onCreateStoreDocument(value);

      if (documentModel?.document) {
        navigate(
          `/${Routes.app}/${Routes.stores}/${Routes.storeWriteOff}/${documentModel?.document?.uuid}`,
        );
      }
    },
    [navigate, onCreateStoreDocument],
  );

  const handleRefreshStoreDocumentsType = useCallback(
    async (value: Partial<IUseStoreDocumentListProps>) => {
      if (value?.show_only) {
        navigate(
          `/${Routes.app}/${Routes.stores}/${Routes.storeWriteOff}/${Routes.storeShowOnly}/${value?.show_only}`,
        );
      } else {
        navigate(`/${Routes.app}/${Routes.stores}/${Routes.storeWriteOff}`);
      }

      await onRefreshStoreDocumentsType(value);
    },
    [navigate, onRefreshStoreDocumentsType],
  );

  const routes = useBreadcrumb([
    {
      path: `/${Routes.stores}/${Routes.storeWriteOff}`,
      breadcrumbName: 'Bill of lading for write offs',
    },
  ]);

  const columns = [
    {
      ellipsis: true,
      title: `№`,
      key: 'index',
      align: 'center' as any,
      className: 'order-list-td order-list--middle-text',
      render: (document: StoreDocumentModel, items: any, index: number) => (
        <TableIndexField number={(page - 1) * limit + index + 1} />
      ),
    },
    {
      ellipsis: true,
      title: t('Bill of lading for write offs'),
      key: 'write-off',
      render: (document: StoreDocumentModel) => (
        <StoreWriteOffDetailsView document={document} />
      ),
    },
    {
      ellipsis: true,
      title: t('Store'),
      key: 'store',
      render: (document: StoreDocumentModel) => (
        <StoreDetailsView store={document?.companyStoreModel} />
      ),
    },
    {
      ellipsis: true,
      title: t('Actions'),
      key: 'store_actions',
      align: 'center' as any,
      fixed: 'right' as any,
      className: 'order-list-td',
      render: (document: StoreDocumentModel) => {
        return (
          <TableActionCell>
            {document?.doc_status_text === StoreDocumentStatus.DRAFT ? (
              <StoreWriteOffUpdateButton
                fromList
                document={StoreDocumentMapper.toStoreDocumentFormDTO(
                  document!,
                  {
                    editMode: true,
                  },
                )}
                onSuccess={handleUpdateStoreDocument}
                onCancel={daleyFocus}
              />
            ) : null}
            <StoreWriteOffDeleteButton
              disabled={
                !manage || !DELETED_STATUSES.includes(document?.doc_status_text)
              }
              documents={[document]}
              onSuccess={handleDeleteStoreDocuments}
            />
          </TableActionCell>
        );
      },
    },
  ];

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.companies}/${defaultCompanyUuid}`);
  }, [defaultCompanyUuid, navigate]);

  return (
    <ListLayout
      ref={listRef}
      aclItem={RoutesAcl[Routes.stores]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.storeWriteOff]))}
      headerRight={
        <StoreWriteOffCreateButton
          disabled={!manage}
          loading={loading}
          onSuccess={handleCreateStoreDocument}
          document={StoreDocumentMapper.toStoreDocumentFormDTO(
            {} as StoreDocumentModel,
            {
              editMode: false,
            },
          )}
          className="write-off-create-event"
          onCancel={daleyFocus}
        />
      }
      routes={routes}
      empty={
        documentList?.size ? null : (
          <Empty description={t('There are no store write off created')}>
            <StoreWriteOffCreateButton
              disabled={!manage}
              loading={loading}
              type="primary"
              onSuccess={handleCreateStoreDocument}
              title={t('Create write off')}
              document={StoreDocumentMapper.toStoreDocumentFormDTO(
                {} as StoreDocumentModel,
                {
                  editMode: false,
                },
              )}
              onCancel={daleyFocus}
            />
          </Empty>
        )
      }
      headerExtra={
        <StyledListActionsContainer>
          <RangeContainer>
            <ListDateRange
              defaultDateRangeValue={
                start_date && end_date
                  ? [toMoment(start_date), toMoment(end_date)]
                  : undefined
              }
              onChange={handlePickStoreDocumentRange}
              loading={loading}
            />
          </RangeContainer>
          <SegmentedContainer>
            <StyledDetailsView
              titles={[]}
              selected={[
                show_only === undefined,
                show_only === StoreDocumentShowOnly.PAID,
                show_only === StoreDocumentShowOnly.UNPAID,
              ]}>
              <StoreDocumentTypeView
                color={theme.tabs.link}
                title="All documents"
                stats={stats}
                onClick={handleRefreshStoreDocumentsType}
                disabled={loading}
              />
              <StoreDocumentTypeView
                color={theme.tabs.success}
                type={StoreDocumentShowOnly.PAID}
                title={t('tPaid')}
                stats={stats}
                onClick={handleRefreshStoreDocumentsType}
                disabled={loading}
              />
              <StoreDocumentTypeView
                color={theme.tabs.error}
                type={StoreDocumentShowOnly.UNPAID}
                title={t('Waiting for payment')}
                stats={stats}
                onClick={handleRefreshStoreDocumentsType}
                disabled={loading}
              />
            </StyledDetailsView>
          </SegmentedContainer>
          <ListActions
            withoutPicker
            inputRef={inputRef}
            searchText={keywords}
            loading={loading}
            handleSearch={(keywords) => handleSearchStoreDocuments({keywords})}
            inputTooltip={t('Search write off')}
            inputLabel={t('Search write off')}
            withSearchContent={
              <StyledStorePostingCreateButton
                disabled={!manage}
                loading={loading}
                type="primary"
                onSuccess={handleCreateStoreDocument}
                document={StoreDocumentMapper.toStoreDocumentFormDTO(
                  {} as StoreDocumentModel,
                  {
                    editMode: false,
                  },
                )}
                title={t('Write off')}
                icon={<PlusOutlined />}
                onCancel={daleyFocus}
              />
            }
          />
        </StyledListActionsContainer>
      }
      outsideFooterContent={
        isThereContent(stats) ? (
          <StoreDocumentListBottomView
            stats={stats}
            documentType={StoreDocumentType.ERASE}
          />
        ) : null
      }
      loading={loading && !documentList?.size}>
      <Table<StoreDocumentModel>
        pageSize={limit}
        total={total}
        onChange={handleRefreshStoreDocuments}
        dataSource={documentList}
        columns={columns}
        loading={loading}
        page={page}
      />
    </ListLayout>
  );
}
