export type ApiError = {
  message: string;
  status: number;
};

export enum APP_STATE {
  SET_INITIAL_STATE = 'set initial state',
}

export enum REDUX_STATUS {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  CHECK_IN_CACHE = 'check_in_cache',
  FAILED = 'failed',
}