import React, {useRef} from 'react';
import {AclLayout, IAclLayoutProps} from './AclLayout';
import styled from 'styled-components';
import {AppHeader, IAppHeaderProps} from './AppHeader';
import {HEADER_HEIGHT} from './const';
import EmptyLayout from './EmptyLayout';
import {LoadingOutlined} from '@ant-design/icons';
import {useLocation} from 'react-router';
import {useSize} from '../../../hooks';

export interface ISingleLayoutProps
  extends Pick<IAclLayoutProps, 'aclItem'>,
    IAppHeaderProps {
  children: React.ReactNode | React.ReactNode[];
  loading?: boolean;
  isCalendar?: boolean;
  underHeaderContent?: React.ReactNode;
}

const PADDING_INDENT = 15;

const StyledOverViewLayout = styled.div<{
  $headerHeight: number;
}>`
  position: relative;
  min-width: 100%;
  min-height: 100vh;
  height: auto;
  padding-top: ${({$headerHeight}) =>
    `${($headerHeight || HEADER_HEIGHT) + 20}px`};
  padding-bottom: 50px;
`;

const StyledContent = styled.div<{
  $headerHeight: number;
}>`
  min-height: ${({$headerHeight}) =>
    `calc(100vh - ${
      ($headerHeight || HEADER_HEIGHT) * 2
    }px - ${PADDING_INDENT}px)`};
  overflow-x: auto;
  padding-left: 24px;
  padding-right: 24px;
`;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  font-size: 50px;
`;

export function SingleLayout({
  headerTitle,
  loading,
  aclItem,
  headerRight,
  headerLeft,
  routes,
  children,
  isCalendar,
  underHeaderContent,
}: ISingleLayoutProps): JSX.Element {
  const location = useLocation();
  const headerRef = useRef(null);
  const {height: headerHeight} = useSize(headerRef);

  return (
    <>
      {location?.pathname?.includes('entry') ? (
        children
      ) : (
        <AclLayout aclItem={aclItem}>
          <StyledOverViewLayout $headerHeight={headerHeight}>
            <AppHeader
              ref={headerRef}
              isCalendar={isCalendar}
              headerRight={headerRight}
              headerTitle={headerTitle}
              headerLeft={headerLeft}
              routes={routes}
              underHeaderContent={underHeaderContent}
            />
            <StyledContent $headerHeight={headerHeight}>
              {loading ? (
                <EmptyLayout>
                  <StyledLoadingOutlined />
                </EmptyLayout>
              ) : (
                children
              )}
            </StyledContent>
          </StyledOverViewLayout>
        </AclLayout>
      )}
    </>
  );
}
