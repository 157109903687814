import {Record, List} from 'immutable';
import {IListProps} from './List';
import {PlanDTO, PlanModel} from '../internal';

export interface IPlanListModelProps extends IListProps {
  plans: PlanDTO[];
}

export interface IPlanListModelReturnType extends IListProps {
  plans: List<PlanModel>;
}

export class PlanListModel extends Record<IPlanListModelReturnType>({
  plans: List(),
  total: 0,
}) {
  constructor(
    {plans = [], total}: IPlanListModelProps = {} as IPlanListModelProps,
  ) {
    const options: IPlanListModelReturnType = {
      total: total,
      plans: List(),
    };

    if (Array.isArray(plans)) {
      options.plans = List(plans.map((plan) => new PlanModel(plan)));
    }

    super({
      ...options,
    });
  }
}
