import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {
  EditButton,
  IEditButtonProps,
  SuspenseEmpty,
} from '../../../components/lib/DataDisplay';
import {useModal} from '../../../components/lib/libV2/hooks';
import {ProductFormDTO} from '../../../struture';
import {IUseStateProductReturnType} from '../../../hooks';

const ProductSideWindowLazy = React.lazy(
  () => import('../Show/ProductSideWindow'),
);

export interface IProductEditButtonProps extends IEditButtonProps {
  product: ProductFormDTO;
  onSuccess: IUseStateProductReturnType['handleUpdateProduct'];
  onCancel?: () => void;
  children?: React.ReactNode;
}

export default function ProductEditButton({
  children,
  product,
  onSuccess,
  onCancel,
  ...rest
}: IProductEditButtonProps) {
  const {t} = useTranslation();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <EditButton
        tooltipTitle={t('Edit material')}
        onClick={handleOnInit}
        disabled={false}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <ProductSideWindowLazy
          product={product}
          editMode
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}
