import React from 'react';
import {ClientAbonementStatusesText} from '../../../struture';
import {Tag} from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  HistoryOutlined,
  ReloadOutlined,
  ContainerOutlined,
  FileUnknownOutlined,
  RetweetOutlined,
} from '@ant-design/icons';
import styled, {css, useTheme} from 'styled-components';
import Color from 'color';
import {useTranslation} from 'react-i18next';

export interface IClientAbonementStatusTagProps {
  status: ClientAbonementStatusesText;
  title: string;
  icon?: React.ReactNode;
}

const StyledTag = styled(Tag)<{$color: string}>`
  width: fit-content;

  ${({$color}) =>
    $color &&
    css`
      border-color: ${Color($color).alpha(0.5).darken(0.3) as any};
      background-color: ${Color($color).alpha(0.9) as any};
      color: ${({theme}) => theme.colors.white};
    `}
`;

export function ClientAbonementStatusTag({
  status,
  title,
  icon,
  ...rest
}: IClientAbonementStatusTagProps): JSX.Element {
  const theme: any = useTheme();
  const {t} = useTranslation();

  const clientAbonementIcon = {
    [ClientAbonementStatusesText.Initiated]: <ContainerOutlined />,
    [ClientAbonementStatusesText.Active]: <CheckCircleOutlined />,
    [ClientAbonementStatusesText.Paused]: <HistoryOutlined />,
    [ClientAbonementStatusesText.Terminated]: <HistoryOutlined />,
    [ClientAbonementStatusesText.Blocked]: <CloseCircleOutlined />,
    [ClientAbonementStatusesText.Transffered]: <ReloadOutlined />,
    [ClientAbonementStatusesText.Error]: <CloseCircleOutlined />,
    [ClientAbonementStatusesText.ReturnedMoney]: <RetweetOutlined />,
  };

  const clientAbonementColors = {
    [ClientAbonementStatusesText.Initiated]: theme.colors.link,
    [ClientAbonementStatusesText.Active]: theme.colors.success,
    [ClientAbonementStatusesText.Paused]: theme.colors.yellow,
    [ClientAbonementStatusesText.Terminated]: theme.colors.warning,
    [ClientAbonementStatusesText.Blocked]: theme.colors.error,
    [ClientAbonementStatusesText.Transffered]: theme.colors.brown,
    [ClientAbonementStatusesText.Error]: theme.colors.error,
    [ClientAbonementStatusesText.ReturnedMoney]: '#c0aea1',
  };

  return (
    <StyledTag
      {...rest}
      $color={clientAbonementColors[status]}
      icon={clientAbonementIcon[status] || <FileUnknownOutlined />}>
      {t(title)}
      {icon || null}
    </StyledTag>
  );
}
