import React, {useCallback} from 'react';
import styled from 'styled-components';
import {authLogoUrl} from '../../../services/const';
import {Image} from 'antd';
import {TextFormat} from '../../../components/lib/Format';
import {
  FlexContainer,
  StyledLeftOutlined,
} from '../../../components/lib/Styled';
import {withTranslation, WithTranslation} from 'react-i18next';
import {AuthRouteLayout} from '../../../components/lib/Layout';
import {useNavigate} from 'react-router';

export default withTranslation()(NotFoundPage);

export interface INotFoundPageProps extends WithTranslation {}

const StyledContainer = styled(FlexContainer)`
  margin-bottom: 20px;
  max-width: 50vw;

  @media (max-width: 1050px) {
    max-width: 80vw;
  }
`;

const StyledImage = styled(Image)`
  margin-bottom: 25px;
`;

const StyledTextFormat = styled(TextFormat)`
  font-size: 1.2em;
  text-align: center;
`;

const StyledTextNotFound = styled(TextFormat)`
  font-size: 1.5em;
  text-align: center;
`;

const StyledButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 15px;
  margin-top: 15px;

  cursor: pointer;

  &:first-child {
    margin-right: 15px;
  }

  &:hover > * {
    color: ${({theme}) => theme.colors.link};
  }
`;

function NotFoundPage({t}: INotFoundPageProps): JSX.Element {
  const navigate = useNavigate();

  const handleNavigationBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <AuthRouteLayout withBackButton={false}>
      <StyledContainer
        justifyContent="center"
        alignItems="center"
        flexDirection="column">
        {authLogoUrl && (
          <StyledImage
            src={authLogoUrl}
            width="20vw"
            alt="logo"
            preview={false}
          />
        )}
        <StyledTextNotFound bold>{t('Page not found')}</StyledTextNotFound>
        <StyledButton onClick={handleNavigationBack}>
          <StyledLeftOutlined />
          <StyledTextFormat>{t('Back')}</StyledTextFormat>
        </StyledButton>
      </StyledContainer>
    </AuthRouteLayout>
  );
}
