import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Col, Input, Row, InputNumber, Select, Form, Tooltip} from 'antd';
import styled from 'styled-components';
import {
  IUseStateEmployeeListReturnType,
  IUseStateGroupPaymentListReturnType,
  useSize,
  useStoredCompanies,
} from '../../../hooks';
import {
  DEFAULT_SERVICE_MULTI_SESSIONABLE,
  EmployeeModel,
  EmployeeStatuses,
  ServiceMultiSessionable,
  ServiceMultiSessionablePeriods,
} from '../../../struture';
import {isListToArray} from '../../../services/helpers';
import {
  Button,
  FormSwitch,
  SearchSelect,
  Segmented,
  Collapse,
} from '../../../components/lib/DataDisplay';
import {StyledDescription, StyledTitle} from '../../../components/lib/Styled';
import {useDefaultForm} from '../../../contex';
import {phoneScreen} from '../../../services/const';
import {SERVICES_PERSONALITY} from './ServicesForm';
import {
  DeleteOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  StyledFieldsContainer,
  Title,
} from '../../Invoices/Forms/StoreInvoiceFields';

export interface IServicesFormFieldsProps
  extends Pick<
      IUseStateEmployeeListReturnType,
      'employees' | 'handleSearchEmployees'
    >,
    Pick<
      IUseStateGroupPaymentListReturnType,
      'groupPayments' | 'handleSearchGroupPayments'
    > {
  loading: boolean;
  timeList: string[];
  employeeLoading: boolean;
  groupPaymentsLoading: boolean;
  editMode: boolean;
  multi_sessions: ServiceMultiSessionable[];
}

const StyledMultiSessionableTittleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const FullWidthInputNumber = styled(InputNumber)`
  width: 95%;
`;

const StyledDeleteOutlined = styled(DeleteOutlined)`
  color: ${({theme}) => theme.colors.error};
`;

const StyledDeleteCol = styled(Col)`
  display: flex;
  align-self: center;
`;

const StyledSelect = styled(Select)`
  width: 100px;
`;

const StyledQuestionCircleOutlined = styled(QuestionCircleOutlined)`
  color: ${({theme}) => theme.colors.disabled};
`;

/**
 * @desc Створенння форми послуги
 * */
export function ServiceFormFields({
  loading,
  timeList,
  editMode,

  employees,
  employeeLoading,
  handleSearchEmployees,

  groupPayments,
  groupPaymentsLoading,
  handleSearchGroupPayments,

  multi_sessions,
}: IServicesFormFieldsProps): JSX.Element {
  const {t} = useTranslation();
  const {
    loadingSubmit,
    getFieldValue,
    formData,
    setFieldsValue,
    setFieldValue,
    handlerUpdateFormState,
  } = useDefaultForm();
  const {defaultCompany} = useStoredCompanies();

  const contentRef = useRef(null);
  const {width: contentWidth} = useSize(contentRef);
  const isFullWidth = contentWidth <= phoneScreen ? 24 : 12;

  const once = useRef(false);

  const [profitability, setProfitability] = useState<any>('0');

  const getDiffPercentage = useCallback(
    ({price, net_price}: any) => {
      const numPrice = Number(price || getFieldValue('price'));
      const numNetPrice = Number(net_price || getFieldValue('net_price'));

      const diff = numPrice - numNetPrice;

      const percentage = numNetPrice ? (diff / numNetPrice) * 100 : diff * 100;

      if (numPrice > numNetPrice) {
        setProfitability(percentage.toFixed(2));
      } else {
        setProfitability(0);
      }

      return {
        price: numPrice,
        net_price: numNetPrice,
        percentage: percentage.toFixed(2),
      };
    },
    [getFieldValue],
  );

  const handleChangeNetPriceFields = useCallback(
    (net_price: string) => {
      getDiffPercentage({net_price});
    },
    [getDiffPercentage],
  );

  const handleChangePriceFields = useCallback(
    (price: string) => {
      getDiffPercentage({price});
    },
    [getDiffPercentage],
  );

  useEffect(() => {
    if (editMode && !once.current) {
      once.current = true;
      getDiffPercentage({});
    }
  }, [editMode, getDiffPercentage]);

  const MULTI_SESSIONABLE_PERIODS = [
    {
      value: ServiceMultiSessionablePeriods.HOUR,
      label: t(ServiceMultiSessionablePeriods.HOUR),
    },
    {
      value: ServiceMultiSessionablePeriods.DAY,
      label: t(ServiceMultiSessionablePeriods.DAY),
    },
    {
      value: ServiceMultiSessionablePeriods.WEEK,
      label: t(ServiceMultiSessionablePeriods.WEEK),
    },
    {
      value: ServiceMultiSessionablePeriods.MONTH,
      label: t(ServiceMultiSessionablePeriods.MONTH),
    },
    {
      value: ServiceMultiSessionablePeriods.YEAR,
      label: t(ServiceMultiSessionablePeriods.YEAR),
    },
  ];

  return (
    <Row gutter={20} ref={contentRef}>
      <Col span={24}>
        <Form.Item
          label={t('Title')}
          name="title"
          rules={[
            {
              required: true,
              message: t('Title must be specified.'),
            },
          ]}>
          <Input
            size="large"
            data-testid="service-input-title"
            disabled={loading || loadingSubmit}
            placeholder={t('Enter the Service title')}
          />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          label={t('Price')}
          name="price"
          required
          rules={[
            ({getFieldValue}) => ({
              validator(_, price) {
                const value = price;

                const netPrice = getFieldValue('net_price');

                if (Number.isNaN(Number(value))) {
                  return Promise.reject(new Error(t('Price must be a number')));
                }

                if (!value) {
                  return Promise.reject(
                    new Error(t('Price must be specified.')),
                  );
                }

                if (Number(value) < Number(netPrice)) {
                  return Promise.reject(
                    new Error(t('The price cannot be less than net price')),
                  );
                }

                return Promise.resolve();
              },
            }),
          ]}
          extra={
            Number(getFieldValue('net_price')) > 0 ? (
              <StyledDescription>
                {`${t('Estimated profitability')} ${profitability || 0} %`}
              </StyledDescription>
            ) : null
          }>
          <Input
            size="large"
            autoComplete="off"
            addonBefore={defaultCompany?.currency_symbol}
            data-testid="service-input-price"
            disabled={loading || loadingSubmit}
            onChange={(e) => handleChangePriceFields(e?.target?.value)}
          />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          label={t('Net price')}
          name="net_price"
          required
          rules={[
            ({getFieldValue, setFields}) => ({
              validator(_, net_price) {
                const value = net_price;

                const price = getFieldValue('price');

                if (Number.isNaN(Number(value))) {
                  return Promise.reject(
                    new Error(t('Net price must be number.')),
                  );
                }

                if (!value) {
                  return Promise.reject(
                    new Error(t('Net price must be specified.')),
                  );
                }

                if (Number(value) > Number(price)) {
                  setFields([
                    {
                      name: 'price',
                      errors: [t('The price cannot be less than net price')],
                    },
                  ]);
                }

                return Promise.resolve();
              },
            }),
          ]}>
          <Input
            size="large"
            addonBefore={defaultCompany?.currency_symbol}
            data-testid="service-input-price"
            disabled={loading || loadingSubmit}
            placeholder={t('Enter the service net price')}
            onChange={(e) => handleChangeNetPriceFields(e?.target?.value)}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Collapse
          title={(isExpanded) =>
            isExpanded ? t('Hide') : t('Additional parameters')
          }
          height={850}>
          <Row>
            <Col span={isFullWidth}>
              <Form.Item
                tooltip={t('Duration')}
                label={t('Duration')}
                name="period_amount"
                rules={[
                  () => ({
                    validator(_, value) {
                      const duration = Number(value);

                      if (Number.isNaN(duration)) {
                        return Promise.reject(
                          new Error(t('Duration must be specified.')),
                        );
                      }

                      if (duration < 1) {
                        return Promise.reject(
                          new Error(t('Duration must be specified.')),
                        );
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}>
                <FullWidthInputNumber
                  size={'large'}
                  type="number"
                  data-testid="service-input-duration"
                  disabled={loading || loadingSubmit}
                />
              </Form.Item>
            </Col>
            <Col span={isFullWidth}>
              <Form.Item
                tooltip={t('Period of time')}
                label={t('Period')}
                name="period"
                rules={[
                  {
                    required: true,
                    message: t('Service period must be specified'),
                  },
                ]}>
                <Select
                  size="large"
                  disabled={loading || loadingSubmit}
                  allowClear
                  data-testid="service-input-period"
                  placeholder={t('Select the service period.')}
                  showSearch
                  optionFilterProp="children">
                  {timeList.map((type) => (
                    <Select.Option
                      key={type}
                      value={type}
                      disabled={loading || loadingSubmit}>
                      {t(`${type}`)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                tooltip={t('The type of service it belongs to')}
                label={t('Type of service')}
                name="personality"
                rules={[
                  {
                    required: true,
                    message: t('Type of service must be specified.'),
                  },
                ]}>
                <Segmented
                  options={SERVICES_PERSONALITY}
                  disabled={loading || loadingSubmit}
                />
              </Form.Item>
            </Col>

            <FormSwitch
              span={24}
              loading={loadingSubmit || loading}
              disabled={loadingSubmit}
              name="is_multi_sessionable"
              getFieldValue={getFieldValue}
              setFieldsValue={setFieldsValue}
              label={null}
              title={
                <StyledMultiSessionableTittleContainer>
                  <StyledTitle>{t('Multi-session')}</StyledTitle>
                  <Tooltip
                    title={t(
                      'Service that consists of stages granting. The first visit may be separated from the second by several days, or even weeks',
                    )}>
                    <StyledQuestionCircleOutlined />
                  </Tooltip>
                </StyledMultiSessionableTittleContainer>
              }
              onChange={(is_multi_sessionable) => {
                handlerUpdateFormState({is_multi_sessionable});
                if (is_multi_sessionable) {
                  setFieldValue('multi_sessions', [
                    DEFAULT_SERVICE_MULTI_SESSIONABLE,
                  ]);
                } else {
                  setFieldValue('multi_sessions', []);
                }
              }}
            />

            {formData?.is_multi_sessionable ? (
              <StyledFieldsContainer>
                <Title>{`${t('Additional sessions')}:`}</Title>
                <Col span={24}>
                  <Form.List name="multi_sessions">
                    {(fields, {add, remove}) => (
                      <>
                        {fields.map((field) => {
                          return (
                            <Row gutter={12} align="top" key={field?.key}>
                              <Col span={11}>
                                <Form.Item
                                  {...field}
                                  required
                                  label={t('By')}
                                  name={[field.name, 'amount']}
                                  rules={[
                                    {
                                      required: true,
                                      message: t('By must be specified.'),
                                    },
                                  ]}>
                                  <FullWidthInputNumber
                                    onFocus={(e) => e?.target?.select()}
                                    addonAfter={
                                      <Form.Item
                                        noStyle
                                        name={[field.name, 'period']}>
                                        <StyledSelect
                                          popupMatchSelectWidth={false}
                                          disabled={loadingSubmit}
                                          options={MULTI_SESSIONABLE_PERIODS}
                                        />
                                      </Form.Item>
                                    }
                                    disabled={loading || loadingSubmit}
                                    placeholder={t('Select an amount')}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={11}>
                                <Form.Item
                                  {...field}
                                  required
                                  label={t('Note')}
                                  rules={[
                                    {
                                      required: true,
                                      message: t('Note must be specified.'),
                                    },
                                  ]}
                                  name={[field.name, 'comment']}>
                                  <Input
                                    disabled={loading || loadingSubmit}
                                    placeholder={t('Enter your note')}
                                  />
                                </Form.Item>
                              </Col>

                              {multi_sessions?.length > 1 ? (
                                <StyledDeleteCol span={2}>
                                  <StyledDeleteOutlined
                                    onClick={() => remove(field.name)}
                                  />
                                </StyledDeleteCol>
                              ) : null}
                            </Row>
                          );
                        })}

                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() =>
                              add({
                                ...DEFAULT_SERVICE_MULTI_SESSIONABLE,
                              })
                            }
                            block
                            icon={<PlusOutlined />}>
                            {t('Add')}
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Col>
              </StyledFieldsContainer>
            ) : null}

            <Col span={24}>
              <Form.Item
                tooltip={t('The employee who will provide the service')}
                label={t('Manager')}
                name="manager_uid"
                rules={[
                  {
                    required: true,
                    message: t('Manager must be specified.'),
                  },
                ]}>
                <SearchSelect
                  selectFirst
                  name="manager_uid"
                  getOptionValueTitle="fullName"
                  placeholder={t(
                    'Select the manager for which the service is manage',
                  )}
                  disable={loading || loadingSubmit || employeeLoading}
                  data={isListToArray(employees as any)}
                  onSearch={(keywords) =>
                    handleSearchEmployees({
                      keywords,
                      showLoading: false,
                      limit: 100,
                    })
                  }
                  itemDisabled={(employee: EmployeeModel) =>
                    ![
                      EmployeeStatuses.Activated,
                      EmployeeStatuses.Confirmed,
                      EmployeeStatuses.Created,
                    ].includes(employee?.status)
                  }
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                tooltip={t('Group payments')}
                label={t('Group payments')}
                name="payment_group_uuid"
                rules={[
                  {
                    required: true,
                    message: t('Group payments must be specified.'),
                  },
                ]}>
                <SearchSelect
                  selectFirst
                  name="payment_group_uuid"
                  placeholder={t('Select the group payments')}
                  data={isListToArray(groupPayments as any)}
                  disable={loading || loadingSubmit || groupPaymentsLoading}
                  onSearch={(keywords) =>
                    handleSearchGroupPayments({
                      keywords,
                      showLoading: false,
                      limit: 100,
                    })
                  }
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label={t('Description')} name="description">
                <Input.TextArea
                  size="large"
                  allowClear
                  autoSize={{minRows: 2, maxRows: 5}}
                  data-testid="service-input-description"
                  disabled={loading || loadingSubmit}
                  placeholder=""
                />
              </Form.Item>
            </Col>
          </Row>
        </Collapse>
      </Col>
    </Row>
  );
}
