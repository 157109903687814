import {Record, List} from 'immutable';
import {IListProps} from './List';
import {StoreDocumentItemDTO, StoreDocumentItemModel} from '../internal';
import {WithoutRecursion} from '../type';

export interface IStoreDocumentItemListModelProps extends IListProps {
  doc_items: StoreDocumentItemDTO[];
}

export interface IStoreDocumentItemListModelReturnType extends IListProps {
  doc_items: List<StoreDocumentItemModel>;
}

export class StoreDocumentItemListModel extends Record<IStoreDocumentItemListModelReturnType>(
  {
    doc_items: List(),
  },
) {
  constructor(
    {
      doc_items = [],
      ...props
    }: IStoreDocumentItemListModelProps = {} as IStoreDocumentItemListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IStoreDocumentItemListModelReturnType = {
      ...props,
      doc_items: List(
        doc_items.map(
          (doc_item) => new StoreDocumentItemModel(doc_item, withoutRecursion),
        ),
      ),
    };

    super(options);
  }
}
