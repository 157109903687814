import {DEFAULT_COMPANY} from '../constants';
import {REDUX_STATUS, ApiError} from '../../services/types';
import {CompanyModel, CompanySettingsDTO} from '../../struture';

export interface IInitialState {
  defaultCompany: CompanyModel | null;
  defaultCompanyUuid: string;
  error: ApiError | null;
  loading: boolean;
  settings: CompanySettingsDTO | null;
  status: REDUX_STATUS;
}

export interface SetDefaultAction
  extends Pick<
    IInitialState,
    'defaultCompany' | 'defaultCompanyUuid' | 'settings'
  > {
  type: DEFAULT_COMPANY.SET_DEFAULT_COMPANY;
}

export interface SetDefaultSettingsAction
  extends Pick<IInitialState, 'settings'> {
  type: DEFAULT_COMPANY.SET_DEFAULT_COMPANY;
}

export interface UpdateDefaultAction {
  type: DEFAULT_COMPANY.UPDATE_DEFAULT_COMPANY;
  defaultCompany: CompanyModel;
  settings: CompanySettingsDTO;
}

export interface SetSettingsAction extends Pick<IInitialState, 'settings'> {
  type: DEFAULT_COMPANY.SET_COMPANY_SETTINGS;
}

export interface DeleteDefaultAction {
  type: DEFAULT_COMPANY.DELETE_DEFAULT_COMPANY;
  defaultCompanyUuid: string;
}

export interface LoadingDefaultAction {
  type: DEFAULT_COMPANY.LOADING_DEFAULT_COMPANY;
}

export interface ErrorDefaultAction extends Pick<IInitialState, 'error'> {
  type: DEFAULT_COMPANY.ERROR_DEFAULT_COMPANY;
}

export interface Handlers {
  [DEFAULT_COMPANY.SET_DEFAULT_COMPANY]: (
    state: IInitialState,
    action: SetDefaultAction,
  ) => IInitialState;

  [DEFAULT_COMPANY.SET_DEFAULT_COMPANY_SETTINGS]: (
    state: IInitialState,
    action: SetDefaultSettingsAction,
  ) => IInitialState;

  [DEFAULT_COMPANY.UPDATE_DEFAULT_COMPANY]: (
    state: IInitialState,
    action: UpdateDefaultAction,
  ) => IInitialState;

  [DEFAULT_COMPANY.SET_COMPANY_SETTINGS]: (
    state: IInitialState,
    action: SetSettingsAction,
  ) => IInitialState;

  [DEFAULT_COMPANY.DELETE_DEFAULT_COMPANY]: (
    state: IInitialState,
    action: DeleteDefaultAction,
  ) => IInitialState;

  [DEFAULT_COMPANY.ERROR_DEFAULT_COMPANY]: (
    state: IInitialState,
    value: ErrorDefaultAction,
  ) => IInitialState;

  [DEFAULT_COMPANY.LOADING_DEFAULT_COMPANY]: (
    state: IInitialState,
    value?: LoadingDefaultAction,
  ) => IInitialState;
  DEFAULT: (state: IInitialState) => IInitialState;
}

const initState: IInitialState = {
  defaultCompany: null,
  defaultCompanyUuid: '',
  settings: null,
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
};

const handlers: Handlers = {
  [DEFAULT_COMPANY.SET_DEFAULT_COMPANY]: (
    state,
    {defaultCompanyUuid, defaultCompany, settings},
  ) => ({
    ...state,
    ...{
      loading: false,
      defaultCompany,
      defaultCompanyUuid,
      settings,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [DEFAULT_COMPANY.SET_DEFAULT_COMPANY_SETTINGS]: (state, {settings}) => ({
    ...state,
    ...{
      loading: false,
      settings,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [DEFAULT_COMPANY.UPDATE_DEFAULT_COMPANY]: (
    state,
    {defaultCompany, settings},
  ) => {
    return {
      ...state,
      ...{
        defaultCompany,
        settings,
        loading: false,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [DEFAULT_COMPANY.SET_COMPANY_SETTINGS]: (state, {settings}) => ({
    ...state,
    ...{
      settings,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [DEFAULT_COMPANY.DELETE_DEFAULT_COMPANY]: (state, {defaultCompanyUuid}) => ({
    ...state,
    ...{
      defaultCompany: null,
      defaultCompanyUuid: '',
      loading: false,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [DEFAULT_COMPANY.ERROR_DEFAULT_COMPANY]: (
    state: IInitialState,
    {error}: ErrorDefaultAction,
  ) => ({
    ...state,
    ...{
      error,
      loading: false,
      status: REDUX_STATUS.FAILED,
    },
  }),
  [DEFAULT_COMPANY.LOADING_DEFAULT_COMPANY]: (state: IInitialState) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IInitialState) => state,
};

export default function DefaultCompany(
  state: any = initState,
  action: any,
): IInitialState {
  const handler = handlers[action.type as DEFAULT_COMPANY] || handlers.DEFAULT;
  return handler(state, action);
}
