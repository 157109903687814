import {Record, List} from 'immutable';
import {IListProps} from './List';
import {CashBoxDTO, CashBoxModel} from '../internal';
import {WithoutRecursion} from '../type';

export interface ICashBoxListModelProps extends IListProps {
  cashboxes: CashBoxDTO[];
}

export interface ICashBoxListModelReturnType extends IListProps {
  cashboxes: List<CashBoxModel>;
}

export class CashBoxListModel extends Record<ICashBoxListModelReturnType>({
  cashboxes: List(),
  total: 0,
}) {
  constructor(
    {
      cashboxes = [],
      ...props
    }: ICashBoxListModelProps = {} as ICashBoxListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: ICashBoxListModelReturnType = {
      ...props,
      cashboxes: List(cashboxes.map((cashBox) => new CashBoxModel(cashBox))),
    };

    super(options);
  }
}
