import {ISubscriptionDTOProps, SubscriptionDTO} from '../internal';

export interface ISubscriptionListDTO {
  subscriptions: SubscriptionDTO[];
  total: number;
}

export class SubscriptionMapper {
  public static toSubscriptionDTO(
    subscription: ISubscriptionDTOProps,
  ): SubscriptionDTO {
    return new SubscriptionDTO({
      id: subscription?.id,
      date_start: subscription?.date_start,
      date_end: subscription?.date_end,
      comment: subscription?.comment,
      created_at: subscription?.created_at,
      price: subscription?.price,
      periods: subscription?.periods,
      discount: subscription?.discount,
      status: subscription?.status,
    });
  }

  public static toSubscriptionListDTO(
    subscriptions: ISubscriptionDTOProps[],
    total?: number,
  ): ISubscriptionListDTO {
    return {
      subscriptions: subscriptions.map((subscription) =>
        SubscriptionMapper.toSubscriptionDTO(subscription),
      ),
      total: total || 0,
    };
  }
}
