import React, {Suspense, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Dropdown, MenuProps} from 'antd';
import {useModal} from '../../../components/lib/libV2/hooks';
import {PaymentFormDTO, PaymentScheduleFormDTO} from '../../../struture';
import {
  Button,
  IAddButtonProps,
  SuspenseEmpty,
} from '../../../components/lib/DataDisplay';
import {Routes} from '../../../services/types';
import {StyledTitle} from '../../../components/lib/Styled';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import styled, {css, useTheme} from 'styled-components';
import {PaymentProps} from '../../../hooks';
import {
  IPaymentScheduleReturnType,
  PaymentScheduleOperationType,
} from '../../../services/api/orders';
import {
  StyledAddButton,
  StyledAddDangerButton,
} from './CreateSchedulePaymentButton';
import {IoMenu} from 'react-icons/io5';

const PaymentSideWindowLazy = React.lazy(
  () => import('../Show/PaymentSideWindow'),
);

export type PaymentOnSuccess = (value: PaymentFormDTO) => Promise<void>;
export type PaymentScheduleOnSuccess = (
  value: PaymentScheduleFormDTO,
) => Promise<void | IPaymentScheduleReturnType>;

export interface ICreatePaymentButtonProps extends IAddButtonProps {
  onSuccess: PaymentOnSuccess | PaymentScheduleOnSuccess;
  onSuccessRefund?: PaymentScheduleOnSuccess;
  onCancel?: () => void;
  companyUuid: string;
  payment: (props?: PaymentProps) => PaymentFormDTO | PaymentScheduleFormDTO;
  from?: Routes.clients | Routes.orders;
  paymentOperationType?: PaymentScheduleOperationType;
}

const StyledDropdown = styled(Dropdown)<{
  $withoutPadding: boolean;
  disabled?: boolean;
}>`
  padding: 15px;
  cursor: pointer;

  ${({$withoutPadding}) =>
    $withoutPadding &&
    css`
      padding: 0;
    `}

  ${({disabled}) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
}

.ant-dropdown-menu-item {
  padding: 0 !important;
}
`;

const StyledIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

export default function CreatePaymentButton({
  loading = false,
  onSuccess,
  onSuccessRefund,
  companyUuid,
  onCancel,
  payment,
  from,
  title,
  paymentOperationType,
  disabled,
  ...rest
}: ICreatePaymentButtonProps) {
  const {t} = useTranslation();
  const theme: any = useTheme();

  const [type, setType] = useState<PaymentScheduleOperationType>(
    PaymentScheduleOperationType.In,
  );

  const {
    handleCancel: paymentHandleCancel,
    handleOnInit: paymentHandleOnInit,
    handleSuccess: paymentHandleSuccess,
    visible: paymentVisible,
  } = useModal({
    onCancel,
    onSuccess: onSuccess as PaymentOnSuccess,
  });

  const handlePrepayment = useCallback(() => {
    setType(PaymentScheduleOperationType.In);
    paymentHandleOnInit();
  }, [paymentHandleOnInit]);

  const handleRefunds = useCallback(() => {
    setType(PaymentScheduleOperationType.Out);
    paymentHandleOnInit();
  }, [paymentHandleOnInit]);

  const items: MenuProps['items'] = [
    {
      key: 'Receipts',
      label: <StyledTitle>{t('Receipts')}</StyledTitle>,
      icon: <ArrowDownOutlined />,
      onClick: handlePrepayment,
    },
    {
      key: 'Refunds',
      label: <StyledTitle>{t('schedulePayment')}</StyledTitle>,
      icon: <ArrowUpOutlined />,
      onClick: handleRefunds,
    },
  ];

  return (
    <>
      {type && paymentOperationType === PaymentScheduleOperationType.ALL ? (
        <StyledDropdown
          $withoutPadding={!!title}
          trigger={['hover']}
          disabled={!!loading || disabled}
          menu={{items}}
          placement="bottomLeft">
          {loading ? (
            <LoadingOutlined color={theme.colors.primary} />
          ) : title ? (
            <Button type="primary">
              <StyledTitle>{title}</StyledTitle>
            </Button>
          ) : (
            <StyledIconContainer>
              <IoMenu size={20} color={theme.colors.primary} />
            </StyledIconContainer>
          )}
        </StyledDropdown>
      ) : paymentOperationType === PaymentScheduleOperationType.In ? (
        <StyledAddButton
          $showBg={!!title}
          tooltipTitle={t('Create receipts')}
          onClick={handlePrepayment}
          loading={loading}
          title={title}
          disabled={disabled}
          {...rest}
        />
      ) : paymentOperationType === PaymentScheduleOperationType.Out ? (
        <StyledAddDangerButton
          $showBg={!!title}
          tooltipTitle={t('Create schedulePayment')}
          onClick={handleRefunds}
          loading={loading}
          title={title}
          disabled={disabled}
          {...rest}
        />
      ) : null}

      <Suspense fallback={<SuspenseEmpty />}>
        <PaymentSideWindowLazy
          from={from}
          payment={payment({payment_type: type as any}) as PaymentFormDTO}
          companyUuid={companyUuid}
          loading={loading as boolean}
          onSuccess={paymentHandleSuccess}
          onCancel={paymentHandleCancel}
          visible={paymentVisible}
        />
      </Suspense>
    </>
  );
}
