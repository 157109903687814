import React from 'react';
import {
  EmployeeRoleCashierPermission,
  EmployeeRoleType,
} from '../../../struture';
import {useTranslation} from 'react-i18next';
import {FormListFieldData, FormListOperation} from 'antd/es/form/FormList';

export interface EmployeeRoleManagerFieldProps extends FormListOperation {
  fields: FormListFieldData[];
  loadingSubmit: boolean;
  role: EmployeeRoleType<EmployeeRoleCashierPermission>;
}

export function EmployeeRoleManagerField({
  fields,
  add,
  remove,
  loadingSubmit,
  role,
}: EmployeeRoleManagerFieldProps) {
  const {t} = useTranslation();

  const permissions: EmployeeRoleCashierPermission[] = role?.permissions;

  return <></>;
}
