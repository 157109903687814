import {Record} from 'immutable';
import Paymentable, {IPaymentableProps} from './Paymentable';

export type PaymentTrigger = 'account' | 'subscription';

export interface PaymentProps {
  uuid: string;
  payment_comment: string;
  payment_type: string;
  payment_sum: number;
  payment_date: string;
  payment_status: string;
  created_by: number;
  created_at: string;
  updated_at: string;
  payment_method: string;
  payment_trigger: PaymentTrigger;
  paymentable: IPaymentableProps;
}

/**
 * @class Client
 * @desc Клієнт
 */
export default class Payment extends Record({
  uuid: '',
  payment_comment: '',
  payment_type: '',
  payment_sum: 0,
  payment_date: '',
  created_by: 0,
  created_at: '',
  updated_at: '',
  payment_status: '',
  paymentable: new Paymentable({} as any),
  payment_method: '',
  payment_trigger: '',
}) {
  constructor({paymentable, ...props}: PaymentProps = {} as PaymentProps) {
    const options: any = {};

    if (paymentable) {
      options.paymentable = new Paymentable(paymentable);
    }

    super({...props, ...options});
  }
}
