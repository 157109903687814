import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Col, Form, Input, Row} from 'antd';
import {StyledDescription} from '../../../components/lib/Styled';
import {DatePicker} from '../../../components/lib/General';
import {SearchSelect, Segmented} from '../../../components/lib/DataDisplay';
import {FROM_PAYMENT_METHOD} from './PaymentScheduleFields';
import {useDefaultForm} from '../../../contex';
import {useTranslation} from 'react-i18next';
import {
  correctPrice,
  eq,
  isListToArray,
  isObject,
} from '../../../services/helpers';
import {
  useSize,
  useStateCompanyClientList,
  useStateEmployeeList,
  useStateGroupPaymentList,
  useStoredCashBoxList,
  useStoredCompanies,
} from '../../../hooks';
import {Routes} from '../../../services/types';
import {phoneScreen} from '../../../services/const';
import {convertedPaymentMethodToCashBox} from '../../../services/api/companyPayments';
import {
  CashBoxModel,
  OPERATION_TYPE,
  PAYMENT_DESTINATION,
  PAYMENT_METHOD,
} from '../../../struture';
import {CashBoxTitleView} from '../Show/CashBoxTitleView';
import {PaymentInvoiceTable} from './PaymentInvoiceTable';
import {PaymentStoreRealizationTable} from './PaymentStoreRealizationTable';
import {PaymentOrderTable} from './PaymentOrderTable';

export interface IPaymentFieldsProps {
  from?: Routes.clients | Routes.orders;
  editMode: boolean;
}

export const FROM_PAYMENT_BASIC_OF = [
  {
    title: 'Bill',
    uuid: PAYMENT_DESTINATION.Invoice,
  },
  {
    title: 'Sales invoice',
    uuid: PAYMENT_DESTINATION.StoreDocument,
  },
  {
    title: 'Order',
    uuid: PAYMENT_DESTINATION.ScheduledOrder,
  },
  {
    title: 'Another',
    uuid: '',
  },
];

const PAYMENT_DESTINATION_TYPE = [
  PAYMENT_DESTINATION.Invoice,
  PAYMENT_DESTINATION.StoreDocument,
  PAYMENT_DESTINATION.ScheduledOrder,
];

const PaymentListTableStrategy = {
  [PAYMENT_DESTINATION.Invoice]: PaymentInvoiceTable,
  [PAYMENT_DESTINATION.StoreDocument]: PaymentStoreRealizationTable,
  [PAYMENT_DESTINATION.ScheduledOrder]: PaymentOrderTable,
  [PAYMENT_DESTINATION.Operation]: null,
  [PAYMENT_DESTINATION.Account]: null,
  [PAYMENT_DESTINATION.AbonementSubscription]: null,
  [PAYMENT_DESTINATION.ClientAbonement]: null,
  [PAYMENT_DESTINATION.EmployeeSalary]: null,
};

export function PaymentFields({
  from,
  editMode,
}: IPaymentFieldsProps): JSX.Element {
  const {loadingSubmit, formData, handlerUpdateFormState} = useDefaultForm();
  const {t} = useTranslation();
  const {defaultCompany, defaultCompanyUuid} = useStoredCompanies();

  const clientRef = useRef<any>(null);
  const contentRef = useRef(null);
  const {width: contentWidth} = useSize(contentRef);
  const isFullWidth = contentWidth <= phoneScreen ? 24 : 12;

  const [resetCashBoxState, setResetCashBoxState] = useState(false);

  const validatePaymentSum = useCallback((_: any, value: string) => {
    const isNumber = Number(value);

    if (!Number.isNaN(isNumber) && eq(typeof isNumber, 'number')) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Value must be a number'));
  }, []);

  const {
    clients,
    loading: clientsLoading,
    handleSearchClients,
  } = useStateCompanyClientList({
    companyUuid: defaultCompanyUuid,
  });

  const {
    employees,
    loading: employeesLoading,
    handleSearchEmployees,
  } = useStateEmployeeList({
    companyUuid: defaultCompanyUuid,
  });

  const {
    groupPayments,
    loading: groupPaymentsLoading,
    handleSearchGroupPayments,
  } = useStateGroupPaymentList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const {
    cashBoxList,
    loading: cashBoxListLoading,
    handleSearchCashBoxes,
  } = useStoredCashBoxList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  useEffect(() => {
    if (
      isObject(formData?.payment_destination_object_uuid) &&
      !formData?.payment_agent_uuid &&
      Number(formData?.payment_sum) === 0
    ) {
      handlerUpdateFormState({
        payment_agent_uuid:
          formData?.payment_destination_object_uuid?.clientModel,
        payment_sum: correctPrice(
          formData?.payment_destination_object_uuid?.sum_total,
        ),
      });
    }
  }, [
    formData?.payment_agent_uuid,
    formData?.payment_destination_object_uuid,
    formData?.payment_sum,
    handlerUpdateFormState,
  ]);

  const PaymentListTable =
    PaymentListTableStrategy[
      formData?.payment_destination as PAYMENT_DESTINATION
    ];

  const getDestinationObject = useCallback(() => {
    if (
      typeof formData?.payment_destination_object_uuid !== 'string' &&
      editMode
    ) {
      handlerUpdateFormState({
        payment_destination_object_uuid:
          formData?.payment_destination_object_uuid?.uuid,
      });

      return formData?.payment_destination_object_uuid;
    }
  }, [
    editMode,
    formData?.payment_destination_object_uuid,
    handlerUpdateFormState,
  ]);

  return (
    <Row gutter={20} ref={contentRef}>
      <Col span={24}>
        <Form.Item
          label={t('Date')}
          name="payment_date"
          rules={[
            {
              required: true,
              message: t('Date must be specified'),
            },
          ]}
          tooltip={{
            title: t('The date of the financial transaction'),
          }}
          extra={
            <StyledDescription>
              {t('The date of the financial transaction')}
            </StyledDescription>
          }>
          <DatePicker
            showTime
            format="YYYY-MM-DD HH:mm"
            disabled={loadingSubmit}
            placeholder={t('Date')}
            onChange={(payment_date) => handlerUpdateFormState({payment_date})}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          label={t('Clients')}
          name="payment_agent_uuid"
          rules={[
            {
              required: true,
              message: t('Clients must be specified'),
            },
          ]}
          tooltip={{
            title: t('Clients'),
          }}>
          <SearchSelect
            ref={clientRef}
            onSearch={async (keywords) => {
              await handleSearchClients({
                keywords,
                limit: 100,
                showLoading: false,
              });
            }}
            name="payment_agent_uuid"
            getOptionValueTitle="fullNameClient"
            placeholder={t('Select the Client by name')}
            disable={loadingSubmit || clientsLoading || from === Routes.clients}
            data={isListToArray(clients as any)}
            onChange={(payment_agent_uuid) =>
              handlerUpdateFormState({
                payment_agent_uuid,
                payment_destination: PAYMENT_DESTINATION_TYPE.includes(
                  formData?.payment_destination,
                )
                  ? formData?.payment_destination
                  : '',
              })
            }
          />
        </Form.Item>
      </Col>

      {formData?.payment_agent_uuid &&
      formData?.payment_type === OPERATION_TYPE.OUT ? (
        <>
          <Col span={24}>
            <Form.Item
              label={t('On the basis of')}
              name="payment_destination"
              tooltip={t('On the basis of')}>
              <Segmented
                options={FROM_PAYMENT_BASIC_OF}
                disabled={loadingSubmit}
                onChange={(payment_destination) => {
                  handlerUpdateFormState({
                    payment_destination,
                  });

                  if (payment_destination === '') {
                    handlerUpdateFormState({
                      payment_destination_object_uuid: '',
                      payment_sum: correctPrice(0),
                    });
                  }
                }}
              />
            </Form.Item>
          </Col>
          {formData?.payment_destination !== '' && PaymentListTable ? (
            <Col span={24}>
              <PaymentListTable
                selectedItem={getDestinationObject()}
                residentUuid={
                  typeof formData?.payment_agent_uuid === 'string'
                    ? formData?.payment_agent_uuid
                    : formData?.payment_agent_uuid?.uuid
                }
                handleSelectDestination={(
                  payment_destination_object_uuid: string,
                  payment_sum: string,
                  payment_comment: string,
                ) =>
                  handlerUpdateFormState({
                    payment_destination_object_uuid,
                    payment_sum,
                    payment_comment,
                  })
                }
              />
            </Col>
          ) : null}
        </>
      ) : null}

      <Col span={isFullWidth}>
        <Form.Item
          label={t('Forms of payment')}
          name="payment_method"
          rules={[
            {
              required: true,
              message: t('Forms of payment must be specified'),
            },
          ]}
          tooltip={t('Forms of payment')}>
          <Segmented
            options={FROM_PAYMENT_METHOD}
            disabled={loadingSubmit || formData?.disabledCashBox}
            onChange={(payment_method) => {
              handlerUpdateFormState({
                payment_method,
              });

              if (typeof formData?.payment_cashbox_uuid === 'string') {
                handlerUpdateFormState({
                  payment_cashbox_uuid: '',
                });
                setResetCashBoxState(true);
                setTimeout(() => {
                  setResetCashBoxState(false);
                }, 100);
              }
            }}
          />
        </Form.Item>
      </Col>

      <Col span={isFullWidth}>
        <Form.Item
          label={t('Cash boxes')}
          name="payment_cashbox_uuid"
          rules={[
            {
              required: true,
              message: t('Cash boxes must be specified'),
            },
          ]}
          tooltip={t('Cash boxes')}>
          <SearchSelect
            resetState={resetCashBoxState}
            selectFirst={false}
            onSearch={(keywords) =>
              handleSearchCashBoxes({
                keywords,
                limit: 100,
                showLoading: false,
              })
            }
            getOptionValueTitle={(cashBox: CashBoxModel) => (
              <CashBoxTitleView
                cashBox={cashBox}
                disable={
                  loadingSubmit ||
                  cashBoxListLoading ||
                  formData?.disabledCashBox
                }
              />
            )}
            name="payment_cashbox_uuid"
            placeholder={t('Select a cash box')}
            disable={
              loadingSubmit || cashBoxListLoading || formData?.disabledCashBox
            }
            data={isListToArray(
              cashBoxList?.filter(
                ({box_type, box_can_in_money_to, box_can_out_money_from}) => {
                  return (
                    box_type ===
                      convertedPaymentMethodToCashBox[
                        formData?.payment_method as PAYMENT_METHOD
                      ] &&
                    (formData?.payment_type === OPERATION_TYPE?.IN
                      ? box_can_in_money_to
                      : box_can_out_money_from)
                  );
                },
              ) as any,
            )}
            onChange={(payment_cashbox_uuid) => {
              handlerUpdateFormState({payment_cashbox_uuid});
            }}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          label={t('Sum')}
          name="payment_sum"
          rules={[
            () => ({
              validator(_, sum) {
                const value = sum;

                if (Number.isNaN(Number(value))) {
                  return Promise.reject(new Error(t('Sum must be a number')));
                }

                if (!value) {
                  return Promise.reject(new Error(t('Sum must be specified.')));
                }

                if (value <= 0) {
                  return Promise.reject(
                    new Error(t('Sum must be grate than 0')),
                  );
                }

                return Promise.resolve();
              },
            }),
          ]}
          tooltip={t('Sum')}
          extra={
            <StyledDescription>
              {t('The amount of the financial transaction')}
            </StyledDescription>
          }>
          <Input
            addonBefore={defaultCompany?.currency_symbol}
            data-testid="payment-sum"
            disabled={
              loadingSubmit ||
              (formData?.payment_destination !== '' &&
                formData?.payment_agent_uuid)
            }
            onChange={(e) =>
              handlerUpdateFormState({payment_sum: e.target.value})
            }
            placeholder={t('Enter sum')}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          tooltip={t('Financial Group')}
          label={t('Financial Group')}
          name="payment_group_uuid"
          extra={
            <StyledDescription>
              {t(
                'A label for grouping payments, with the subsequent possibility of aggregating all transactions',
              )}
            </StyledDescription>
          }>
          <SearchSelect
            selectFirst={true}
            name="payment_group_uuid"
            placeholder={t('Choose a payment group')}
            data={isListToArray(groupPayments as any)}
            disable={loadingSubmit || groupPaymentsLoading}
            onChange={(payment_group_uuid) =>
              handlerUpdateFormState({payment_group_uuid})
            }
            onSearch={(keywords) =>
              handleSearchGroupPayments({
                keywords,
                limit: 100,
                showLoading: false,
              })
            }
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          label={t('Cashier')}
          name="payment_cashier_uuid"
          rules={[
            {
              required: true,
              message: t('Cashier must be specified'),
            },
          ]}
          tooltip={t('Cashier')}>
          <SearchSelect
            onSearch={(keywords) =>
              handleSearchEmployees({
                keywords,
                limit: 100,
                showLoading: false,
              })
            }
            getOptionValueTitle="fullName"
            name="payment_cashier_uuid"
            placeholder={t('Select a cashier')}
            disable={loadingSubmit || employeesLoading}
            data={isListToArray(employees as any)}
            onChange={(payment_cashier_uuid) =>
              handlerUpdateFormState({payment_cashier_uuid})
            }
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          label={t('note')}
          name="payment_comment"
          tooltip={{
            title: t('Admin comment for payment'),
          }}
          rules={[
            {
              required: true,
              message: t('Note must be specified'),
            },
          ]}>
          <Input.TextArea
            allowClear
            autoSize={{minRows: 2, maxRows: 5}}
            disabled={loadingSubmit}
            placeholder={t('Enter your note')}
            onChange={(e) =>
              handlerUpdateFormState({payment_comment: e.target.value})
            }
          />
        </Form.Item>
      </Col>
    </Row>
  );
}
