import React from 'react';
import {useTranslation} from 'react-i18next';
import {IConfirmActionButtonProps} from '../../../components/lib/General';
import {TextFormat} from '../../../components/lib/Format';
import {len, eq} from '../../../services/helpers';
import {useDelete} from '../../../components/lib/libV2/hooks';
import {DayOffModel} from '../../../struture';
import {DeleteButton} from '../../../components/lib/DataDisplay';

export interface IServicesDeleteButtonProps
  extends Omit<IConfirmActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (id: string[]) => Promise<void>;
  onError?: () => void;
  daysOff: DayOffModel[];
  title?: string;
}

export default function EmployeeDayOffDeleteButton({
  children,
  daysOff = [],
  onSuccess,
  onError,
  title,
  ...rest
}: IServicesDeleteButtonProps) {
  const {t} = useTranslation();

  const {handleDelete, loading} = useDelete({
    onSuccess,
    entity: daysOff,
    notifyTitle: 'Day off',
  });

  return (
    <DeleteButton
      title={title}
      tooltipTitle={t('Delete employee day off')}
      confirmTitle={
        <TextFormat breakWord>
          {t('Are you sure you want to delete')} <b>{len(daysOff)}</b>
          {` ${eq(len(daysOff), 1) ? t(' the day off') : t(' days off')}`}?
        </TextFormat>
      }
      onConfirm={handleDelete}
      loading={loading}
      cancelButtonText={t('Cancel')}
      {...rest}
    />
  );
}
