import React, {useCallback} from 'react';
import {ISystemSubscription} from '../../../services/api/subscriptions';
import styled, {css, useTheme} from 'styled-components';
import {IUseStatePlanListReturnType, useStoredCompanies} from '../../../hooks';
import {StyledDescription, StyledTitle} from '../../../components/lib/Styled';
import {useTranslation} from 'react-i18next';
import {Space} from 'antd';
import {
  correctPrice,
  EPlanLimitation,
  EPlanTypeIdentifier,
  textToUpperCase,
} from '../../../services/helpers';
import {ChangeSubscriptionTariffButton, SubscriptionButton} from '../Buttons';
import {PlanMapper, PlanModel} from '../../../struture';
import {systemTitles} from '../../../services/const';

export interface SubscriptionTariffModelListItem
  extends Pick<
    IUseStatePlanListReturnType,
    'handleSubscriptionActivate' | 'handleSubscriptionTransition'
  > {
  systemSubscription: ISystemSubscription;
  plan: PlanModel | null;
  activePlan: EPlanTypeIdentifier;
  active: boolean;
  handleActivate: (id: EPlanTypeIdentifier) => void;
  disabled: boolean;
}

const StyledContainer = styled.div<{$isActive: boolean}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  text-align: center;

  min-width: 250px;
  height: auto;
  box-sizing: border-box;

  background-color: ${({theme}) => theme.background.primary};
  border-radius: 7px;
  border: 1px solid transparent;
  padding: 10px;

  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);

  ${({$isActive, theme}) =>
    $isActive &&
    css`
      border: 1px solid ${theme.colors.success};
      box-shadow: 0 0 3px 0 ${theme.colors.success};
    `}
`;

const StyledCapabilitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
  padding-bottom: 30px;
`;

const StyledSpace = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
`;

const Title = styled(StyledTitle)`
  align-self: center;
`;

const DepartmentTitle = styled(StyledTitle)`
  padding-top: 10px;
  padding-bottom: 10px;
`;

const StyledRuleTitle = styled(StyledTitle)`
  display: inline-block;
  padding-top: 10px;
`;

const CAPABILITIES = {
  [EPlanLimitation.COMPANY]: 'Organizations',
  [EPlanLimitation.EMPLOYEE]: 'Capability employees',
  [EPlanLimitation.SERVICE]: 'Capability services',
  [EPlanLimitation.CLIENT]: 'Capability clients',
  [EPlanLimitation.DEPARTMENT]: 'Capability departments',
};

const LIMITATION = [
  EPlanLimitation.EMPLOYEE,
  EPlanLimitation.SERVICE,
  EPlanLimitation.CLIENT,
];

const toExtraPrice = (systemSubscription: ISystemSubscription): any =>
  systemSubscription.rules.reduce(
    (acc, item) => ({
      ...acc,
      [item.id]: {limit: item.limit, extraPrice: correctPrice(item.extraPrice)},
    }),
    {},
  );

export function SubscriptionTariffModelListItem({
  systemSubscription,
  activePlan,
  active,
  plan,
  handleActivate,
  handleSubscriptionTransition,
  handleSubscriptionActivate,
  disabled,
}: SubscriptionTariffModelListItem): JSX.Element {
  const {t} = useTranslation();
  const theme: any = useTheme();
  const {defaultCompany} = useStoredCompanies();
  const currencySymbol = defaultCompany?.currency_symbol;

  const isActive = activePlan === systemSubscription?.id;

  const onActivate = useCallback(() => {
    if (!disabled) {
      handleActivate(systemSubscription?.id);
    }
  }, [disabled, handleActivate, systemSubscription?.id]);

  return (
    <StyledContainer $isActive={active} onClick={onActivate}>
      <StyledSpace>
        <Title fontSize={23} $color={theme.colors.success}>
          {textToUpperCase(t(systemTitles[systemSubscription?.id]))}
        </Title>

        <StyledTitle $color={theme.colors.success} fontSize={30}>
          {`${
            systemSubscription?.price === 0
              ? t('Free')
              : `${currencySymbol} ${correctPrice(systemSubscription?.price)}`
          }`}
          <StyledDescription fontSize={25}>
            {`/${t(systemSubscription?.period).toLowerCase()}`}
          </StyledDescription>
        </StyledTitle>

        {systemSubscription?.id === EPlanTypeIdentifier.PROFESSIONAL ? (
          <DepartmentTitle fontSize={16}>
            {`${t('Company with ability to create locations')} (${t(
              'additional location',
            )} +${defaultCompany?.currency_symbol || ''}${
              toExtraPrice(systemSubscription)?.department_limitation
                ?.extraPrice
            }`}
            <StyledDescription fontSize={13}>{`/${t(
              'month',
            ).toLowerCase()}`}</StyledDescription>
            )
          </DepartmentTitle>
        ) : (
          <DepartmentTitle fontSize={16}>
            {t('Company with fixed location')}
          </DepartmentTitle>
        )}

        <StyledCapabilitiesContainer>
          <Space direction="vertical" size="small">
            {systemSubscription?.rules.map((rule) =>
              LIMITATION.includes(rule?.id) ? (
                <>
                  {systemSubscription?.id ===
                    EPlanTypeIdentifier.PROFESSIONAL &&
                  rule?.id === EPlanLimitation.EMPLOYEE ? (
                    <StyledTitle fontSize={16} key={rule?.id}>
                      {`${t('up to')}`}&nbsp;
                      <StyledTitle bold fontSize={23}>
                        {rule?.limit}
                      </StyledTitle>
                      &nbsp;
                      {`${t(CAPABILITIES[rule?.id])} (${t(
                        'additional employee',
                      )} +${defaultCompany?.currency_symbol || ''}${
                        toExtraPrice(systemSubscription)?.employee_limitation
                          ?.extraPrice
                      }`}
                      <StyledDescription fontSize={13}>{`/${t(
                        'month',
                      ).toLowerCase()}`}</StyledDescription>
                      )
                    </StyledTitle>
                  ) : systemSubscription?.id ===
                    EPlanTypeIdentifier.PROFESSIONAL ? (
                    <StyledRuleTitle fontSize={16} key={rule?.id}>
                      <StyledTitle fontSize={16} bold>
                        {t('no limit')}
                      </StyledTitle>
                      {` ${t('on the number of')} ${t(rule?.id)}`}
                    </StyledRuleTitle>
                  ) : (
                    <StyledTitle fontSize={16} key={rule?.id}>
                      {`${t('up to')}`}&nbsp;
                      <StyledTitle bold fontSize={23}>
                        {rule?.limit}
                      </StyledTitle>
                      &nbsp;
                      {`${t(CAPABILITIES[rule?.id])}`}
                    </StyledTitle>
                  )}
                </>
              ) : null,
            )}
          </Space>
          <StyledTitle fontSize={16}>{t('SMS Notifications')}</StyledTitle>
          <StyledTitle fontSize={16}>
            <StyledTitle fontSize={16} bold>
              {systemSubscription?.freeDays}
            </StyledTitle>
            {` ${t('days')} - ${t('Free trial period')}`}
          </StyledTitle>
        </StyledCapabilitiesContainer>
      </StyledSpace>

      {isActive && systemSubscription?.id !== EPlanTypeIdentifier.TRIAL ? (
        <SubscriptionButton
          title={t('To pay')}
          subscription={PlanMapper.toPlanFormDTO(plan!)}
          onClick={onActivate}
          onSuccess={handleSubscriptionActivate as any}
          disabled={disabled}
        />
      ) : (
        <ChangeSubscriptionTariffButton
          disabled={
            systemSubscription?.id === EPlanTypeIdentifier.TRIAL || disabled
          }
          title={
            systemSubscription?.id === EPlanTypeIdentifier.TRIAL
              ? t('Activated')
              : t('Go to')
          }
          subscription={PlanMapper.toPlanTariffFormDTO(
            plan!,
            systemSubscription?.id,
          )}
          onClick={onActivate}
          onSuccess={handleSubscriptionTransition as any}
        />
      )}
    </StyledContainer>
  );
}
