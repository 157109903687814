import React, {useEffect} from 'react';
import {isFunction} from '../../../../services/helpers';
import {ChildrenMustBeAFunction} from '../../../../components/lib/Errors';
import {IAuthRouteItemProps, StyledWarningOutlined} from '../../Show';
import {WithTranslation} from 'react-i18next';
import {UserAddOutlined} from '@ant-design/icons';
import {IoKeyOutline} from 'react-icons/io5';
import {Routes} from '../../../../services/types';
import {useNavigate} from 'react-router';

interface ITabChildrenProps {
  routes: Omit<IAuthRouteItemProps, keyof WithTranslation>[];
}

export interface ILoginTabPanelProps {
  children: (value: ITabChildrenProps) => React.ReactNode;
  showForgotPasswordWarning?: boolean;
  showSignupWarning?: boolean;
}

export enum LOGIN_TABS {
  FORGOT_PASSWORD = 'Forgot password',
  SING_UP = 'Sign up',
}

export function LoginTabPanel({
  children,
  showSignupWarning,
  showForgotPasswordWarning,
}: ILoginTabPanelProps) {
  const navigate = useNavigate();

  const [routes, setRoutes] = React.useState<
    Omit<IAuthRouteItemProps, keyof WithTranslation>[]
  >([]);

  useEffect(() => {
    setRoutes([
      {
        key: LOGIN_TABS.FORGOT_PASSWORD,
        title: LOGIN_TABS.FORGOT_PASSWORD,
        icon: <IoKeyOutline size={18} />,
        navigation: () => {
          navigate(`/${Routes.auth}/${Routes.forgotPassword}`, {
            state: {
              backPageName: 'Entry',
              from: location.pathname,
            },
          });
        },
        buttonIcon: showForgotPasswordWarning ? (
          <StyledWarningOutlined />
        ) : undefined,
      },
      {
        key: LOGIN_TABS.SING_UP,
        title: LOGIN_TABS.SING_UP,
        icon: <UserAddOutlined size={18} />,
        navigation: () => {
          navigate(`/${Routes.auth}/${Routes.signup}`, {
            state: {from: location.pathname},
          });
        },
        buttonIcon: showSignupWarning ? <StyledWarningOutlined /> : undefined,
      },
    ]);
  }, [navigate, showForgotPasswordWarning, showSignupWarning]);

  if (!isFunction(children)) {
    throw new ChildrenMustBeAFunction('LoginTabPanel');
  }

  return <>{children({routes})}</>;
}
