import {
  ClientDTO,
  IClientDTOProps,
  ProfileDTO,
  IProfileDTOProps,
  CompanyDTO,
  ICompanyDTOProps,
  IMailingModelReturnType,
  ClientMapper,
  ProfileMapper,
  CompanyMapper,
} from '../internal';

export type MailingType = 'viber' | 'sms' | 'email';

export enum MAILING_STATUSES {
  SCHEDULED = 'SCHEDULED',
  RUN_IMMEDIATELY = 'RUN_IMMEDIATELY',
  FAILED = 'FAILED',
  EXECUTED = 'EXECUTED',
  UNSCHEDULED = 'UNSCHEDULED',
}

export interface IMailingDTOProps {
  uuid: string;
  title: string;
  body: string;
  start_date: string;
  comment: string;
  status: number;
  status_t: MAILING_STATUSES;
  created_at: string;
  updated_at: string;
  clients: IClientDTOProps[];
  created_by: IProfileDTOProps;
  company: ICompanyDTOProps;
}

export class MailingDTO {
  public uuid: string;

  public title: string;

  public body: string;

  public start_date: string;

  public comment: string;

  public status: number;

  public status_text: MAILING_STATUSES;

  public created_at: string;

  public updated_at: string;

  public clients: ClientDTO[];

  public created_by: ProfileDTO;

  public company: CompanyDTO;

  constructor(props?: IMailingModelReturnType | IMailingDTOProps) {
    this.uuid = props?.uuid || '';
    this.title = props?.title || '';
    this.body = props?.body || '';
    this.start_date = props?.start_date || '';
    this.comment = props?.comment || '';
    this.status = props?.status || 0;
    this.status_text =
      (props as IMailingDTOProps)?.status_t ||
      (props as IMailingModelReturnType)?.status_text ||
      MAILING_STATUSES.FAILED;
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
    this.clients = ClientMapper.toClientListDTO(
      props?.clients || [],
      0,
    ).clients;
    this.created_by = ProfileMapper.toProfileDTO(
      props?.created_by || ({} as IProfileDTOProps),
    );
    this.company = CompanyMapper.toCompanyDTO(
      props?.company || ({} as ICompanyDTOProps),
    );
  }
}
