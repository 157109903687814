import {useState, useCallback, useEffect} from 'react';
import {listSchedules} from '../services/schedules';
import {useAction} from '../components/lib/Errors/ActionErrorHandler';
import {ListOptions} from '../services/models';

export default function useSсhedulesList({
  offset = 0,
  order = 'name ASC',
  limit = 20,
  filters = [],
  loadOnInit = true,
  ...rest
} = {}) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState(null);
  const [listPage, setListPage] = useState(offset);
  const [listPageSize, setListPageSize] = useState(limit);
  const [listOrder, setListOrder] = useState(order);
  const [listFilters, setListFilters] = useState(filters);
  const [total, setTotal] = useState(null);
  const [relatedAgents, setRelatedAgents] = useState({});

  const {action} = useAction();

  const refresh = useCallback(
    async ({
      offset = listPage,
      order = listOrder,
      limit = listPageSize,
      filters = listFilters,
    } = {}) => {
      try {
        if (
          offset !== listPage ||
          order !== listOrder ||
          limit !== listPageSize
        ) {
          setLoading(true);
        }
        setError(null);
        setListPage(offset);
        setListPageSize(limit);
        setListOrder(order);
        setListFilters(filters);

        const {schedules, total} = await action(
          listSchedules(
            new ListOptions({
              offset,
              order,
              limit,
              filters,
              ...rest,
            }),
          ),
        );

        setList(schedules.toArray());
        setTotal(total);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    },
    [],
  );

  useEffect(() => {
    if (loadOnInit) {
      (async () => {
        try {
          setLoading(true);
          await refresh({
            offset: listPage,
            order: listOrder,
            limit: listPageSize,
            ...rest,
          });
          setLoading(false);
        } catch (err) {
          if (!err.isCanceled) {
            setLoading(false);
          }
        }
      })();
    }
  }, []);

  return {
    error,
    loading,
    list,
    relatedAgents,
    refresh,
    total,
    offset: listPage,
    limit: listPageSize,
    order: listOrder,
  };
}
