import React from 'react';
import {useTranslation} from 'react-i18next';
import {IConfirmActionButtonProps} from '../../../components/lib/General';
import {TextFormat} from '../../../components/lib/Format';
import {len, eq} from '../../../services/helpers';
import {MaterialAndExpenseModel} from '../../../struture';
import {useDelete} from '../../../components/lib/libV2/hooks';
import {DeleteButton} from '../../../components/lib/DataDisplay';

export interface IMaterialsAndExpenseDeleteButtonProps
  extends Omit<IConfirmActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: string[]) => Promise<void>;
  onError?: () => void;
  materialsAndExpenses: MaterialAndExpenseModel[];
}

export default function MaterialsAndExpensesDeleteButton({
  children,
  materialsAndExpenses = [],
  onSuccess,
  onError,
  title,
  ...rest
}: IMaterialsAndExpenseDeleteButtonProps) {
  const {t} = useTranslation();

  const {handleDelete, loading} = useDelete({
    onSuccess,
    entity: materialsAndExpenses,
    notifyTitle: '',
  });

  return (
    <DeleteButton
      title={title}
      tooltipTitle={t('Delete material and expense')}
      confirmTitle={
        <TextFormat breakWord>
          {t('Are you sure you want to delete')}{' '}
          <b>{len(materialsAndExpenses)}</b>{' '}
          {eq(len(materialsAndExpenses), 1)
            ? t('Material and expense').toLowerCase()
            : t('Materials and expenses').toLowerCase()}
          ?
        </TextFormat>
      }
      onConfirm={handleDelete}
      loading={loading}
      cancelButtonText={t('Cancel')}
      {...rest}
    />
  );
}
