import React from 'react';
import {
  DetailsActionItemView,
  IDetailsActionItemViewProps,
} from '../../../components/lib/DataDisplay';
import {StyledTitle} from '../../../components/lib/Styled';
import Color from 'color';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {isThereContent} from '../../../services/helpers';
import {LoadingOutlined} from '@ant-design/icons';
import {GetCompanyScheduleState} from '../../../services/api/company';
import {ScheduleCalendarStatsDTO} from '../../../struture';
import {useStoredCompanies} from '../../../hooks';
import {
  AiOutlineSync,
  AiOutlineStop,
  AiOutlineUnorderedList,
  AiOutlineClockCircle,
} from 'react-icons/ai';
import {useAcl} from '../../../contex';

export interface IOrderStateViewProps
  extends Omit<IDetailsActionItemViewProps, 'onClick' | 'type' | 'children'> {
  type: GetCompanyScheduleState;
  color: string;
  selected?: boolean;
  title: string;
  stats: ScheduleCalendarStatsDTO;
  onClick: (type: GetCompanyScheduleState) => void;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100%;
`;

const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  height: 100%;
`;

const StyledLoadingOutlined = styled(LoadingOutlined)<{$color: string}>`
  color: ${({$color}) => $color};
  font-size: 20px;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
`;

export function OrderStateView({
  color,
  selected,
  type,
  title,
  stats,
  onClick,
  ...rest
}: IOrderStateViewProps): React.JSX.Element {
  const {t} = useTranslation();
  const {defaultCompany} = useStoredCompanies();
  const {read} = useAcl(({order_stats}) => order_stats);

  const textColor = Color(color).alpha(1).darken(0.65).toString();

  let sum;
  let icon;
  let count;
  let countText;

  if (type === GetCompanyScheduleState.ALL) {
    sum = stats?.totalSum;
    icon = <AiOutlineUnorderedList color={textColor} size={25} />;
    count = stats?.totalCount;
  } else if (type === GetCompanyScheduleState.UNPAID) {
    sum = stats?.totalUnPaidSum;
    icon = <AiOutlineSync color={textColor} size={25} />;
    count = stats?.totalUnPaidCount;
  } else if (type === GetCompanyScheduleState.OVERDUE) {
    sum = stats?.totalOverdueSum;
    icon = <AiOutlineClockCircle color={textColor} size={25} />;
    count = stats?.totalOverdueCount;
  } else {
    sum = stats?.totalCancelledSum;
    icon = <AiOutlineStop color={textColor} size={25} />;
    count = stats?.totalCancelledCount;
  }

  switch (count) {
    case 0:
      countText = t('There are no orders');
      count = '';
      break;
    case 1:
    case 2:
    case 3:
    case 4:
      countText = t('tOrder');
      break;
    default:
      countText = t('tOrders');
      break;
  }

  return (
    <DetailsActionItemView
      $minHeight={100}
      color={color}
      selected={selected}
      onClick={() => onClick(type)}
      {...rest}>
      <StyledContainer>
        {icon || null}
        <StyledInfoContainer>
          <StyledTitle $color={textColor}>{t(title)}</StyledTitle>

          {isThereContent(stats) ? (
            <StyledTitleContainer>
              {sum && read ? (
                <StyledTitle fontWeight={500} fontSize={25} $color={textColor}>
                  {`${defaultCompany?.currency_symbol || ''} ${sum}`}
                </StyledTitle>
              ) : null}
              {typeof count === 'number' || count === '' ? (
                <StyledTitle $color={textColor}>
                  {`${count} ${countText}`}
                </StyledTitle>
              ) : null}
            </StyledTitleContainer>
          ) : (
            <StyledLoadingOutlined $color={textColor} />
          )}
        </StyledInfoContainer>
      </StyledContainer>
    </DetailsActionItemView>
  );
}
