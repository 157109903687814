import i18n from 'i18next';
import LanguageDetector, {
  DetectorOptions,
} from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import {translationEN, translationUA} from '@sportix/sportix-common-modules';
import {domainUrl, LocalStorageItems} from '../services/const';
import {setDateLanguage} from '../services/helpers';
import moment from 'moment/moment';

export const languageCode: any = {
  uk: 'ua',
  en: 'en',
};

const resources = {
  en: {
    translation: translationEN,
  },
  ua: {
    translation: translationUA,
  },
};

// (i18n as any).init(() => {
//   moment.locale(
//     window.localStorage.getItem(LocalStorageItems.language) || 'en',
//   );
// });

(i18n as any)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection: {
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
        'htmlTag',
      ],

      lookupQuerystring: 'lng',
      lookupCookie: 'language',
      lookupLocalStorage: 'language',
      lookupSessionStorage: 'language',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      caches: ['localStorage', 'cookie'],

      cookieDomain: domainUrl,

      htmlTag: document.documentElement,

      cookieOptions: {path: '/', sameSite: 'strict'},

      convertDetectedLanguage: (lng: any) => {
        return 'ua';
        // return lng?.toLowerCase()?.includes('en')
        //   ? 'en'
        //   : lng?.toLowerCase()?.includes('uk')
        //   ? 'ua'
        //   : lng
      },
    },

    fallbackLng: 'en',

    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
    debug: false,
  });

(i18n as any).on('languageChanged', setDateLanguage);

export default i18n;
