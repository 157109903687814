import {
  CompanyDTO,
  CompanyMapper,
  ICompanyDTOProps,
  IStoreDocumentModelReturnType,
  IProfileDTOProps,
  ProfileDTO,
  ProfileMapper,
  IStoreDocumentItemDTOProps,
  IStoreDTOProps,
  IClientDTOProps,
  StoreDTO,
  ClientDTO,
  StoreMapper,
  ClientMapper,
  StoreDocumentItemDTO,
  StoreDocumentMapper,
} from '../internal';
import {WithoutRecursion} from '../type';

export enum StoreDocumentType {
  IN = 'IN',
  OUT = 'OUT',
  ERASE = 'ERASE',
  RETURN = 'RETURN',
  MOVE = 'MOVE',
}

export enum StoreDocumentStatus {
  ACTIVE = 'active',
  DRAFT = 'draft',
  COMPLETED = 'completed',
  ERROR = 'error',
}

export interface IStoreDocumentDTOProps {
  uuid: string;
  doc_type: StoreDocumentType;
  doc_date: string;
  doc_local_number: string;
  doc_input_number: string;
  doc_sum_paid: string;
  doc_sum_total: string;
  doc_comment: string;
  doc_status_text: StoreDocumentStatus;
  created_at: string;
  updated_at: string;
  doc_items: IStoreDocumentItemDTOProps[];
  company_store: IStoreDTOProps;
  company_destination_store: IStoreDTOProps;
  created_by: IProfileDTOProps;
  company: ICompanyDTOProps;
  client: IClientDTOProps;
}

export class StoreDocumentDTO {
  public uuid: string;

  public doc_type: StoreDocumentType;

  public doc_date: string;

  public doc_local_number: string;

  public doc_input_number: string;

  public doc_sum_paid: string;

  public doc_sum_total: string;

  public doc_comment: string;

  public doc_status_text: StoreDocumentStatus;

  public created_at: string;

  public updated_at: string;

  public doc_items: StoreDocumentItemDTO[];

  public company_store: StoreDTO;

  public company_destination_store: StoreDTO;

  public created_by: ProfileDTO;

  public company: CompanyDTO;

  public client: ClientDTO;

  constructor(
    props: IStoreDocumentModelReturnType | IStoreDocumentDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';
    this.doc_type = props?.doc_type || StoreDocumentType.IN;
    this.doc_date = props?.doc_date || '';
    this.doc_local_number = props?.doc_local_number || '';
    this.doc_input_number = props?.doc_input_number || '';
    this.doc_sum_paid = props?.doc_sum_paid || '';
    this.doc_sum_total = props?.doc_sum_total || '';
    this.doc_comment = props?.doc_comment || '';
    this.doc_status_text = props?.doc_status_text || StoreDocumentStatus.DRAFT;
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';

    this.doc_items =
      withoutRecursion.indexOf(WithoutRecursion.storeDocument) !== -1
        ? (props?.doc_items as StoreDocumentItemDTO[])
        : StoreDocumentMapper.toStoreDocumentItemListDTO(
            props?.doc_items as StoreDocumentItemDTO[],
          ).storeDocumentItems;

    this.company_store =
      withoutRecursion.indexOf(WithoutRecursion.store) !== -1
        ? (props?.company_store as StoreDTO)
        : StoreMapper.toStoreDTO(props?.company_store, [
            WithoutRecursion.storeDocument,
            ...withoutRecursion,
          ]);
    this.company_destination_store =
      withoutRecursion.indexOf(WithoutRecursion.store) !== -1
        ? (props?.company_destination_store as StoreDTO)
        : StoreMapper.toStoreDTO(props?.company_destination_store, [
            WithoutRecursion.storeDocument,
            ...withoutRecursion,
          ]);

    this.created_by =
      withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
        ? (props?.created_by as ProfileDTO)
        : ProfileMapper.toProfileDTO(props?.created_by, [
            WithoutRecursion.storeDocument,
            ...withoutRecursion,
          ]);

    this.company =
      withoutRecursion.indexOf(WithoutRecursion.company) !== -1
        ? (props?.company as CompanyDTO)
        : CompanyMapper.toCompanyDTO(props?.company, [
            WithoutRecursion.storeDocument,
            ...withoutRecursion,
          ]);

    this.client =
      withoutRecursion.indexOf(WithoutRecursion.client) !== -1
        ? (props?.client as ClientDTO)
        : ClientMapper.toClientDTO(props?.client, true, [
            WithoutRecursion.storeDocument,
            ...withoutRecursion,
          ]);
  }
}
