import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {OverviewHeader, OverviewLayout} from '../../../components/lib/Layout';
import {
  useRequiredFields,
  useStopLoading,
  useStoredCompanies,
  useStoreEmployee,
} from '../../../hooks';
import {EMPLOYEE_REQUIRED_FIELD} from '../../../services/api/employee';
import EmployeeTabPanel from '../Show/EmployeeTabPanel';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import {Routes, RoutesAcl} from '../../../services/types';
import {EmployeeMapper, EmployeeStatuses} from '../../../struture';
import {
  EmployeeActionButtons,
  EmployeeEditButton,
  EmployeeRoleButton,
} from '../Buttons';
import {useAcl} from '../../../contex';
import {useLocation, useNavigate, useParams} from 'react-router';
import {HeaderLeft} from '../../../components/lib/DataDisplay';
import {StyledDescription} from '../../../components/lib/Styled';
import styled from 'styled-components';
import {EmployeeHeader, EmployeeRoleTag} from '../Show';

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
`;

export default function EmployeePage() {
  const {employeeId} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {t} = useTranslation();

  const {defaultCompanyUuid} = useStoredCompanies();
  const {manage} = useAcl(({employee}) => employee);

  const {
    employee,
    loading: employeeLoading,
    error: employeeError,
    handleUpdateEmployee,
    handleSetEmployeePassword,
    handleSetEmployeeRole,
    handleBlockEmployee,
    handleAcceptEmployeeInvite,
    handleCancelEmployeeInvite,
  } = useStoreEmployee({
    employeeUuid: employeeId!,
    companyUuid: defaultCompanyUuid,
  });

  const disabled = employee?.status === EmployeeStatuses.DELETED;

  const loading = useStopLoading({
    loading: employeeLoading,
    error: employeeError,
    message: 'An error occurred during employee loading',
  });

  const routes = useBreadcrumb([
    {
      path: `/${Routes.employees}`,
      breadcrumbName: 'Employees',
    },
    {
      path: `/${employeeId}`,
      breadcrumbName: t('Show Employee'),
    },
  ]);

  const overviewData = useRequiredFields(employee, EMPLOYEE_REQUIRED_FIELD);

  const navigateGoBack = useCallback(() => {
    navigate(location?.state?.goBack || `/${Routes.app}/${Routes.employees}`);
  }, [location?.state?.goBack, navigate]);

  return (
    <EmployeeTabPanel disabled={disabled} employee={employee}>
      {({tabs}) => (
        <OverviewLayout
          aclItem={RoutesAcl[Routes.employees]}
          headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
          headerRight={
            loading ? null : (
              <EmployeeEditButton
                disabled={loading || disabled}
                employee={EmployeeMapper.toEmployeeFormDTO(employee!)}
                onSuccess={handleUpdateEmployee}
              />
            )
          }
          headerTitle={t('Employee')}
          disabled={!manage}
          editTooltip={t('Edit Employee')}
          loading={loading}
          data={overviewData}
          routes={routes}
          tabs={tabs}
          header={
            <OverviewHeader
              withoutIndent
              title={''}
              description={
                <>
                  <EmployeeHeader employee={employee} />
                  <StyledTitleContainer>
                    <StyledDescription>{employee?.title}</StyledDescription>
                    {employee?.role_permissions?.length ? (
                      employee?.role_permissions?.map(({role}) => (
                        <EmployeeRoleTag
                          disabled={disabled}
                          key={role}
                          value={role}
                          label={t(role)}
                        />
                      ))
                    ) : (
                      <EmployeeRoleTag
                        disabled={disabled}
                        value={employee?.role!}
                        label={t(employee?.role)}
                      />
                    )}
                    <EmployeeRoleButton
                      disabled={!manage || disabled}
                      employee={EmployeeMapper.toEmployeeRoleFormDTO(employee!)}
                      onSuccess={handleSetEmployeeRole}
                      loading={loading}
                      isEmployeeOwner={!!employee?.is_owner}
                    />
                  </StyledTitleContainer>
                </>
              }
              image={employee?.picture_url}
              handleUploadImage={async () => {
                return '';
              }}
              extra={
                <EmployeeActionButtons
                  disabled={disabled}
                  manage={manage}
                  employee={employee}
                  handleBlockEmployee={handleBlockEmployee}
                  handleAcceptEmployeeInvite={handleAcceptEmployeeInvite}
                  handleCancelEmployeeInvite={handleCancelEmployeeInvite}
                  employeeId={employeeId}
                  handleSetEmployeePassword={handleSetEmployeePassword}
                  loading={loading}
                />
              }
            />
          }
        />
      )}
    </EmployeeTabPanel>
  );
}
