import {IEmployeeDTOProps, EmployeeModel, EmployeeMapper} from '../internal';
import {correctPrice} from '../../helpers';

export interface IRewardStatsDTOProps {
  total: string;
  sum_total: string;
  employee: IEmployeeDTOProps;
}

export class RewardStatsDTO {
  public total: string;

  public sum_total: string;

  public employee: EmployeeModel;

  constructor(props: IRewardStatsDTOProps) {
    this.total = correctPrice(props?.total || 0);
    this.sum_total = correctPrice(props?.sum_total || 0);
    this.employee = EmployeeMapper.toEmployeeModel(
      EmployeeMapper.toEmployeeDTO(props?.employee),
    );
  }
}
