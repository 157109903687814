import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {ButtonProps} from 'antd/lib/button';
import {
  ScheduleCalendarFormDTO,
  ScheduleCalendarMapper,
  ScheduleCalendarModel,
} from '../../../struture';
import {useModal} from '../../../components/lib/libV2/hooks';
import {EditButton, SuspenseEmpty} from '../../../components/lib/DataDisplay';

const OrderSideWindowLazy = React.lazy(() => import('../Show/OrderSideWindow'));

export interface ICreateOrderButtonProps extends ButtonProps {
  onSuccess: (value: ScheduleCalendarFormDTO) => Promise<void>;
  onCancel?: () => void;
  companyUuid: string;
  schedule: ScheduleCalendarModel;
  children?: React.ReactNode;
  isHasStrictMode: boolean;
}

export default function CreateOrderButton({
  loading = false,
  onSuccess,
  companyUuid,
  schedule,
  children,
  onCancel,
  isHasStrictMode,
  ...rest
}: ICreateOrderButtonProps): JSX.Element {
  const {t} = useTranslation();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <EditButton
        tooltipTitle={t('Update company order')}
        onClick={handleOnInit}
        disabled={false}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <OrderSideWindowLazy
          editMode
          order={
            schedule &&
            ScheduleCalendarMapper.toScheduleCalendarFormDTO(schedule, true)
          }
          companyUuid={companyUuid}
          loading={!!loading}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          visible={visible}
          isHasStrictMode={isHasStrictMode}
        />
      </Suspense>
    </>
  );
}
