import {Record, List} from 'immutable';
import {ListModel, ListProps} from './List';
import CompanyService, {CompanyServiceProps} from './CompanyService';

export interface CompanyServiceListProps extends ListProps {
  services: CompanyServiceProps[];
}

/**
 * @class CompanyServiceList
 * @classdesc Company service list model.
 * @extends {Record}
 * @property {List} companies - list of companies
 * @property {number} total - total amount of records on backend
 */
export default class CompanyServiceList extends Record({
  services: List(),
  ...ListModel.toJS(),
}) {
  /**
   * @constructor
   * @param {{services:[]}} param
   * @param {{count:number}} param
   */
  constructor({services = [], ...props}: CompanyServiceListProps) {
    super({
      services: List(services.map((service) => new CompanyService(service))),
      ...props,
    });
  }
}
