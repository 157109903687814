import {Record, List} from 'immutable';
import {IListProps} from './List';
import {
  ClientMapper,
  ClientModel,
  FileDTO,
  FileModel,
  IClientDTOProps,
} from '../internal';
import {WithoutRecursion} from '../type';

export interface IFileListModelProps extends IListProps {
  files: FileDTO[];
  trigger_object?: IClientDTOProps;
}

export interface IFileListModelReturnType extends IListProps {
  files: List<FileModel>;
  trigger_object?: ClientModel;
}

export class FileListModel extends Record<IFileListModelReturnType>({
  files: List(),
  total: 0,
  trigger_object: {} as ClientModel,
}) {
  constructor(
    {files = [], ...props}: IFileListModelProps = {} as IFileListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IFileListModelReturnType = {
      ...props,
      trigger_object: ClientMapper.toClientModel(
        ClientMapper.toClientDTO(props?.trigger_object as IClientDTOProps),
      ),

      files: List(files.map((file) => new FileModel(file))),
    };

    super(options);
  }
}
