import * as React from 'react';
import {useDrop} from 'react-dnd';
import {DRAG_ITEM_TYPE} from '../services/types';
import {
  buildDisplayLengthOfTime,
  isFunction,
  destructuringTime,
  setHourAndMinute,
  buildEventByTime,
  MutationScheduleEvent,
  last,
  EventType,
  isRecordToObject,
  toUtcDateInFormat,
  isDateLess,
  toDateByFormat,
} from '../services/helpers';
import {useDragCalendarEvent} from '../hooks';
import CalendarEventPreview from '../components/Calendar/Show/CalendarEventPreview';
import CalendarEventView from '../components/Calendar/Show/CalendarEventView';
import {
  CalendarDndActions,
  handlers,
  IInitialState,
  CALENDAR_DND_ACTIONS,
  dndCache,
} from './CalendarDnDActions';
import CalendarEventDisplay from '../components/Calendar/Show/CalendarEventDisplay';
import {useCalendar} from './CalendarManager';
import {useEffect} from 'react';
import {Record} from 'immutable';
import {ScheduleCalendarFormDTO} from '../struture';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from './DropdownAlertManager';

export interface IAddEventReturnProps {
  hour: number;
  addedEvents: MutationScheduleEvent;
  scheduleUpdatedEvents: EventType;
}

export interface ICalendarDndContext extends IInitialState {
  drop: any;
  drag: any;
  isDragging: boolean;
  isDidDrop: (didDrop: boolean) => void;
  handleUpdateDnDData: (value: Partial<IInitialState>) => void;
  handleChangeTime?: (time: string) => void;
  setDndEvents: (dndEvents: EventType) => void;
  handleAddDnDEvent: (
    scheduleTime: string,
    scheduleEvents: MutationScheduleEvent,
    isToday?: boolean,
    weekdayEnd?: string,
  ) => IAddEventReturnProps;
  dndCache: Map<any, any>;
}

export const CalendarDndContext = React.createContext<ICalendarDndContext>({
  drop: React.createRef(),
  drag: React.createRef(),
  isDragging: false,
  didDrop: false,
  eventTime: '',
  updatedTime: '',
  handleUpdateDnDData: () => {},
  setDndEvents: () => {},
  handleChangeTime: () => {},
  isDidDrop: () => {},
  handleAddDnDEvent: () => ({} as any),
  event: {} as any,
  dndEvents: {} as any,
  addedEvents: null,
  dndCache: new Map(),
});

export const useCalendarDnd = () =>
  React.useContext<ICalendarDndContext>(CalendarDndContext);

export interface ICalendarDndManagerProps {
  children: any;
  handleChangeTime?: (time: string) => void;
  isClearDnDCache: boolean;
  onSuccess?: (value: ScheduleCalendarFormDTO) => Promise<void>;

  order?: Partial<ScheduleCalendarFormDTO>;
}

export default function CalendarDndManager({
  children,
  handleChangeTime,
  isClearDnDCache,
  onSuccess,
  order,
}: ICalendarDndManagerProps): JSX.Element {
  const [dndData, dispatch] = React.useReducer(
    (state: IInitialState, action: CalendarDndActions) => {
      const handler =
        handlers[action.type] || handlers[CALENDAR_DND_ACTIONS.DEFAULT];
      return handler(state, action as any);
    },
    {
      eventTime: '',
      updatedTime: '',
      event: null,
      didDrop: false,
      dndEvents: null,
      addedEvents: null,
    },
  );

  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const {
    defaultScheduleItem,
    scheduleStartProp,
    selectedDate,
    limitedByDate,
    scheduleEndProp,
  } = useCalendar();

  const {currentOffset, initialOffset, isDragging, drag, eventTime} =
    useDragCalendarEvent({
      time: dndData.eventTime,
    });

  const isDidDrop = React.useCallback((didDrop: boolean): void => {
    dispatch({
      type: CALENDAR_DND_ACTIONS.SET_DID_DROP,
      didDrop,
    });
  }, []);

  const [, drop] = useDrop(
    () => ({
      accept: DRAG_ITEM_TYPE.BOX,
      drop(item, monitor) {
        const didDrop = monitor?.didDrop();

        if (!didDrop) {
          isDidDrop(true);
          updateDragTime(eventTime);
        }
        return undefined;
      },
    }),
    [eventTime],
  );

  useEffect(() => {
    if (isClearDnDCache) {
      dndCache.clear();
    }
  }, [isClearDnDCache]);

  const handleUpdateDnDData = React.useCallback(
    (value: Partial<IInitialState>): void => {
      dispatch({
        type: CALENDAR_DND_ACTIONS.UPDATE_ALL,
        ...value,
      });

      if (value.eventTime && isFunction(handleChangeTime)) {
        handleChangeTime(value?.eventTime);
      }
    },
    [handleChangeTime],
  );

  useEffect(() => {
    const event = dndCache.get('addedEvents');
    if (event && !dndData?.eventTime) {
      const [[_, dndEvents]]: any = Object.entries(event || {});

      const currentEvent: any = last(dndEvents);

      if (currentEvent) {
        handleUpdateDnDData({
          eventTime: toUtcDateInFormat(currentEvent?.scheduled_date, 'HH:mm'),
        });
      }
    }
  }, [dndData?.eventTime, handleUpdateDnDData]);

  const updateDragTime = React.useCallback((updatedTime: string): void => {
    dispatch({
      type: CALENDAR_DND_ACTIONS.SET_UPDATE_TIME,
      updatedTime,
    });
  }, []);

  const setDndEvents = React.useCallback((dndEvents: EventType): void => {
    dispatch({
      type: CALENDAR_DND_ACTIONS.SET_DND_EVENTS,
      dndEvents,
    });
  }, []);

  const handleAddDnDEvent = React.useCallback(
    (
      scheduleTime: string,
      scheduleEvents: MutationScheduleEvent,
      isToday?: boolean,
      weekdayEnd?: string,
    ): IAddEventReturnProps & any => {
      const [hour, minute] = destructuringTime(scheduleTime!);
      const wholeHour = `${hour > 9 ? hour : `0${hour}`}:00`;

      const scheduleDate = setHourAndMinute(
        hour,
        minute,
        selectedDate,
      ).toDate();

      const date = toDateByFormat(selectedDate, 'YYYY-MM-DD');

      if (
        limitedByDate &&
        isDateLess(
          `${date} ${wholeHour}`,
          toUtcDateInFormat(limitedByDate, 'YYYY-MM-DD HH:mm'),
        )
      ) {
        alert(
          'error',
          t('Previous date'),
          t(
            'You cannot select an earlier day, because the date of the accompanying order cannot be less than the date of the parent order.',
          ),
        );

        return;
      }

      const scheduleUpdatedEvents = buildEventByTime({
        startProp: scheduleStartProp as any,
        endProp: scheduleEndProp,
        addedEvent: Record({
          service: {period_amount: 1, period: 'hour', title: 'testing'},
          schedule_priority: 0,
          schedule_amount: 1,
          ...isRecordToObject(defaultScheduleItem as any),
          [scheduleStartProp as string]: scheduleDate,
          isDragging: true,
          onSuccess,
          order,
        })(),
        eventByTime: scheduleEvents,
      });

      const addedEvents: MutationScheduleEvent = last(
        scheduleUpdatedEvents[wholeHour] || [],
      );

      dispatch({
        type: CALENDAR_DND_ACTIONS.SET_DND_ADDED_EVENTS,
        addedEvents: {[wholeHour]: scheduleUpdatedEvents[wholeHour]},
      });

      handleUpdateDnDData({
        event: last(scheduleUpdatedEvents[wholeHour] || []),
        eventTime: scheduleTime,
        dndEvents: scheduleUpdatedEvents,
      });

      return {
        hour,
        addedEvents,
        scheduleUpdatedEvents,
      };
    },

    [
      alert,
      defaultScheduleItem,
      handleUpdateDnDData,
      limitedByDate,
      onSuccess,
      order,
      scheduleEndProp,
      scheduleStartProp,
      selectedDate,
      t,
    ],
  );

  return (
    <CalendarDndContext.Provider
      value={{
        ...dndData,
        drag,
        drop,
        isDragging,
        handleUpdateDnDData,
        isDidDrop,
        handleChangeTime,
        handleAddDnDEvent,
        setDndEvents,
        dndCache,
      }}>
      {children}
      {isDragging ? (
        <CalendarEventPreview
          initialOffset={initialOffset}
          currentOffset={currentOffset}>
          <CalendarEventView
            withoutIndent
            event={dndData?.event as any}
            span={23}>
            <CalendarEventDisplay
              isOpenPopover
              direction="horizontal"
              isDragging={dndData?.event?.isDragging}
              event={defaultScheduleItem}
              lengthOfTime={buildDisplayLengthOfTime(
                eventTime,
                dndData.event?.schedule_amount as any,
              )}
            />
          </CalendarEventView>
        </CalendarEventPreview>
      ) : null}
    </CalendarDndContext.Provider>
  );
}
