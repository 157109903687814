import * as React from 'react';

export default function useOutsideClickHandler(
  ref: any,
  callback: any,
  query?: string,
  ignoreElementClassNames: string[] = [],
): void {
  React.useEffect(() => {
    const handleClickOutside = (evt: Event) => {
      const target = evt?.target;

      if (ref.current && !ref.current.contains(evt.target)) {
        if (
          target instanceof HTMLElement &&
          ignoreElementClassNames.some((item) => {
            return target.closest(`.${item}`);
          })
        ) {
          return;
        }

        evt.stopPropagation();

        if (target instanceof HTMLElement && query) {
          const element = target.closest(query);

          if (element instanceof HTMLElement) {
            return;
          }
        }

        callback();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}
