import {FinancialSettingsDTO} from '../internal';
import {correctPrice} from '../../helpers';

export enum AlphaNameAmount {
  'ThreeMonth' = '3',
  'HalfYear' = '6',
  'Year' = '12',
}

export interface IAlphaNameFormDTOProps {
  alfa_name: string;
  amount: AlphaNameAmount;
  price?: string;
  total?: string;
}

export class AlphaNameFormDTO {
  public alfa_name: string;

  public amount: AlphaNameAmount;

  public price?: string;

  public total?: string;

  constructor(
    props?: FinancialSettingsDTO,
    additionalProps?: {price: string; period: AlphaNameAmount},
  ) {
    this.alfa_name = props?.company_alfa_name || '';
    this.amount = additionalProps?.period || AlphaNameAmount.ThreeMonth;
    this.price = correctPrice(additionalProps?.price || 0);
    this.total = correctPrice(
      Number(additionalProps?.price || 0) *
        Number(additionalProps?.period || AlphaNameAmount.ThreeMonth),
    );
  }
}
