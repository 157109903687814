import {SystemRuleDTO, ISystemRuleDTOProps, SystemRuleModel} from '../internal';

export class SystemRuleMapper {
  public static toSystemRuleDTO(
    systemRule: SystemRuleModel | ISystemRuleDTOProps,
  ): SystemRuleDTO {
    return new SystemRuleDTO({
      rule_title: systemRule?.rule_title || '',
      rule_description: systemRule?.rule_description || '',
      created_at: systemRule?.created_at || '',
      updated_at: systemRule?.updated_at || '',
    });
  }

  public static toSystemRuleModel(
    systemRuleDTO: SystemRuleDTO,
  ): SystemRuleModel {
    return new SystemRuleModel(systemRuleDTO);
  }
}
