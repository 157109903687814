import {Record} from 'immutable';
import {UserOperationDTO} from '../internal';
import {OPERATION_TYPE} from '../type';

export interface IUserOperationModelReturnType {
  uuid: string;
  operation_type: OPERATION_TYPE;
  operation_price: string;
  operation_comment: string;
  created_at: string;
  updated_at: string;
}

/**
 * @class Client
 * @desc Клієнт
 */
export class UserOperationModel extends Record<IUserOperationModelReturnType>({
  uuid: '',
  operation_type: OPERATION_TYPE.IN,
  operation_price: '',
  operation_comment: '',
  created_at: '',
  updated_at: '',
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(props: UserOperationDTO = {} as UserOperationDTO) {
    super({...props});
  }
}
