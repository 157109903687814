import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {AuthLayout, FullWidthSpace, HeaderText} from '../Show';
import {useDropdownAlert} from '../../../contex';
import {Routes} from '../../../services/types';
import {useStoredAuthData, useStoreVerificationFactory} from '../../../hooks';
import {EmailVerificationForm} from './Forms';
import {EmailVerificationFormDTO} from '../../../struture';
import {verifyByEmail} from '../../../services/api/user';
import {useLocation, useNavigate} from 'react-router';
import {LocalStorageItems} from '../../../services/const';
import {decryptObject} from '../../../services/helpers';
import {StatusError} from '../../../components/lib/Errors';

export function EmailVerificationPage(): JSX.Element {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    setAuthTokens,
    access_token: storedAccessToken,
    refresh_token: soredRefreshToken,
  } = useStoredAuthData();

  const {updateStateFactory, identifier} = useStoreVerificationFactory<{
    timeWhenCodeWasSend: null | Date;
    identifier: string;
    prevIdentifier: string;
  }>({
    key: location?.state?.verificationStateName,
  });

  const handleCancel = useCallback(() => {
    navigate(location?.state?.goBack || -1, {
      state: location?.state?.prevState,
    });
  }, [location?.state?.goBack, location?.state?.prevState, navigate]);

  const handleGenerateVerifyEmail = useCallback(
    async (value: EmailVerificationFormDTO) => {
      try {
        let access = storedAccessToken;
        let refresh = soredRefreshToken;

        if (location?.state?.verificationStateName === Routes.signup) {
          const serializeTokens = await localStorage.getItem(
            LocalStorageItems.signupTokens,
          );

          if (serializeTokens) {
            const {access_token, refresh_token} = decryptObject<{
              access_token: string;
              refresh_token: string;
            }>(serializeTokens);

            access = access_token;
            refresh = refresh_token;
          }
        }

        if (location?.state?.verificationStateName === Routes.employeeSignup) {
          const serializeEmployeeAuthData = await localStorage.getItem(
            LocalStorageItems.employeeAuthData,
          );

          if (serializeEmployeeAuthData) {
            const {access_token, refresh_token} = decryptObject<{
              access_token: string;
              refresh_token: string;
            }>(serializeEmployeeAuthData);

            access = access_token;
            refresh = refresh_token;
          }
        }

        if (access && refresh) {
          const {access_token, refresh_token} = await verifyByEmail(
            value,
            access,
            refresh,
          );

          if (access_token && refresh_token) {
            setAuthTokens(access_token, refresh_token);
            updateStateFactory({
              identifier: value.email,
              ...(identifier?.includes('@')
                ? {}
                : {prevIdentifier: identifier}),
            });
          }

          alert(
            'success',
            t('Generation new email code'),
            t('Generation of new email code is successful'),
          );

          handleCancel();
        } else {
          alert(
            'error',
            t('Token'),
            t('Access token not found, try signing in with a new one'),
          );
        }
      } catch (error: any) {
        alert(
          'error',
          t('Generation new email code'),
          `${t('An error occurred during generate new email code')}: ${
            error?.message
          }`,
        );

        throw new StatusError(error?.message, error?.status);
      }
    },
    [
      alert,
      handleCancel,
      identifier,
      location?.state?.verificationStateName,
      setAuthTokens,
      soredRefreshToken,
      storedAccessToken,
      t,
      updateStateFactory,
    ],
  );

  return (
    <AuthLayout>
      <FullWidthSpace direction="vertical" size="middle">
        <HeaderText title={t('Verification by email')} />
        <EmailVerificationForm
          onSuccess={handleGenerateVerifyEmail}
          onCancel={handleCancel}
        />
      </FullWidthSpace>
    </AuthLayout>
  );
}
