import {ReportModel} from '../internal';
import {getEndOfMonthDate, getStartOfMonthDate} from '../date';

export enum REPORT_PAYMENT_METHOD {
  Account = 'ACCOUNT',
  Bank = 'BANK',
  Cash = 'CASHBOX',
  All = 'ALL',
}

export class ReportFormDTO {
  public uuid: string;

  public report_start_date: string;

  public report_end_date: string;

  public _date: string[];

  public report_employee_uuid: string;

  public report_client_uuid: string;

  public report_service_uuid: string;

  public report_operation_type: REPORT_PAYMENT_METHOD;

  constructor(props?: ReportModel) {
    this.uuid = props?.uuid || '';
    this.report_start_date = getStartOfMonthDate();
    this.report_end_date = getEndOfMonthDate();
    this.report_employee_uuid = '';
    this.report_client_uuid = '';
    this.report_service_uuid = '';
    this.report_operation_type = REPORT_PAYMENT_METHOD.All;
    this._date = [this.report_start_date, this.report_end_date];
  }
}
