import {correctPrice} from '../../helpers';

export interface IScheduleCalendarParentDTOProps {
  comment: string;
  created_at: string;
  is_child: boolean;
  price: string;
  schedule_number: string;
  scheduled_date: string;
  status_color: string;
  status_text: string;
  sum_total: string;
  updated_at: string;
  uuid: string;
}

export class ScheduleCalendarParentDTO {
  public comment: string;

  public created_at: string;

  public is_child: boolean;

  public price: string;

  public schedule_number: string;

  public scheduled_date: string;

  public status_color: string;

  public status_text: string;

  public sum_total: string;

  public updated_at: string;

  public uuid: string;

  constructor(props: IScheduleCalendarParentDTOProps) {
    this.comment = props?.comment || '';
    this.created_at = props?.created_at || '';
    this.is_child = props?.is_child || false;
    this.price = correctPrice(props?.price);
    this.schedule_number = props?.schedule_number || '';
    this.scheduled_date = props?.scheduled_date || '';
    this.status_color = props?.status_color || '';
    this.status_text = props?.status_text || '';
    this.sum_total = correctPrice(props?.sum_total);
    this.updated_at = props?.updated_at || '';
    this.uuid = props?.uuid || '';
  }
}
