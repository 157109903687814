import React from 'react';

export interface IContentHiddenProps {
  hidden: boolean;
  children: React.ReactNode;
}

export function ContentHidden({
  hidden,
  children,
}: IContentHiddenProps): JSX.Element {
  return <>{hidden ? children : null}</>;
}
