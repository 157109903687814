import React, {useCallback} from 'react';
import {ICalendarManagerProps} from '../../../contex';
import {ScheduleCalendarModel} from '../../../struture';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '../../../services/types';
import ClientCalendarPopover from '../Show/ClientCalendarPopover';
import ClientScheduleDetail from '../Show/ClientScheduleDetail';
import {
  useDefaultCalendarScheduleProps,
  useStateClientOrderList,
  useStoredCompanies,
} from '../../../hooks';
import {textToUpperCase} from '../../../services/helpers';
import {useLocation, useNavigate, useParams} from 'react-router';
import {HeaderLeft} from '../../../components/lib/DataDisplay';
import {SingleLayout} from '../../../components/lib/Layout';
import {useTranslation} from 'react-i18next';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';

export interface IClientCalendarPageProps {
  children: (
    props: ICalendarManagerProps<ScheduleCalendarModel>,
  ) => React.ReactNode;
}

export function ClientCalendarPage({
  children,
}: IClientCalendarPageProps): JSX.Element {
  const {clientId} = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const defaultCalendarProps = useDefaultCalendarScheduleProps();
  const {defaultCompany} = useStoredCompanies();

  const {
    schedules,
    refresh: clientRefresh,

    handleCreateSchedule,
  } = useStateClientOrderList({
    clientUuid: clientId!,
    loadOnInit: false,
    limit: 100,
  });

  const navigateGoBack = useCallback(() => {
    navigate(location?.state?.goBack || -1);
  }, [location?.state?.goBack, navigate]);

  const routes = useBreadcrumb([
    {
      path: `/${Routes.clients}`,
      breadcrumbName: 'Clients',
    },
    {
      path: `/${Routes.clients}/${clientId}`,
      breadcrumbName: location?.state?.clientFullName || 'Show Client',
    },
    ...(location?.pathname?.includes(Routes.clientSchedules)
      ? [
          {
            path: `/${Routes.clients}/${clientId}/${Routes.clientSchedules}`,
            breadcrumbName: 'Orders',
          },
        ]
      : []),
    {
      path: ``,
      breadcrumbName: 'Calendar',
    },
  ]);

  return (
    <SingleLayout
      isCalendar
      aclItem={RoutesAcl[Routes.clients]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(
        defaultCompany?.title || t(RoutesTitle[RoutesType.settings]),
      )}
      routes={routes}>
      {children({
        ...defaultCalendarProps,
        localStorageName: 'clientCalendarDate',
        schedules: schedules!,
        onRefresh: clientRefresh,
        SchedulePopover: ClientCalendarPopover,
        scheduleDetails: (children, schedule) => (
          //@ts-ignore
          <ClientScheduleDetail
            schedule={schedule}
            clientFullName={location?.state?.clientFullName}>
            {({routes, navigateGoBack}) => children({routes, navigateGoBack})}
          </ClientScheduleDetail>
        ),
        customDayTimeRange:
          location?.state?.timeRange || defaultCompany?.work_schedules,
        withDnDEventsProps: {
          onSuccess: handleCreateSchedule,
          order: {client: {uuid: clientId}} as any,
        },
        isClearDnDCache: true,
      })}
    </SingleLayout>
  );
}
