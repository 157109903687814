import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {ButtonProps} from 'antd/lib/button';
import {ActionButton} from '../../../components/lib/General';
import {SuspenseEmpty} from '../../../components/lib/DataDisplay';
import {
  ScheduleCalendarMapper,
  ScheduleCalendarModel,
  ScheduleChangeTimeFormDTO,
} from '../../../struture';
import {useModal} from '../../../components/lib/libV2/hooks';
import styled from 'styled-components';

const OrderChangeTimeSideWindowLazy = React.lazy(
  () => import('../Show/OrderChangeTimeSideWindow'),
);

export interface IOrderChangeTimeButtonProps extends ButtonProps {
  onSuccess: (value: ScheduleChangeTimeFormDTO) => Promise<void>;
  onError?: () => void;
  children?: React.ReactNode;
  schedule: ScheduleCalendarModel;
}

const StyledActionButton = styled(ActionButton)`
  background-color: ${({theme}) => theme.colors.warning};
  color: ${({theme}) => theme.colors.white};
  flex: 1;
`;

export function OrderChangeTimeButton({
  loading = false,
  onSuccess,
  children,
  schedule,
  ...rest
}: IOrderChangeTimeButtonProps): JSX.Element {
  const {t} = useTranslation();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onSuccess,
  });

  return (
    <>
      <StyledActionButton
        size="middle"
        tooltip={t('Find another time')}
        type="primary"
        loading={loading}
        onClick={handleOnInit}
        {...rest}>
        {children || t('Find another time')}
      </StyledActionButton>

      <Suspense fallback={<SuspenseEmpty />}>
        <OrderChangeTimeSideWindowLazy
          schedule={ScheduleCalendarMapper.toScheduleChangeTimeFormDTO(
            schedule,
          )}
          relatedSchedule={
            schedule?.parent?.uuid
              ? {
                  scheduleNumber: schedule?.parent?.schedule_number,
                  scheduleDate: schedule?.parent?.scheduled_date,
                }
              : undefined
          }
          loading={!!loading}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          visible={visible}
        />
      </Suspense>
    </>
  );
}
