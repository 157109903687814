import React from 'react';
import {PageHeader} from '@ant-design/pro-layout';
import {Select} from 'antd';
import classNames from 'classnames';
import {SelectValue} from 'antd/lib/select';
import {ILiteralObj} from '../../../services/types';
import CustomPageHeader from './PageHeader';
import {IDetailsHeaderProps} from '../General';

import './PageWrapper.less';

export default PageWrapper;

export type SelectedData<T> = {
  value: T[];
  defaultValue: SelectValue;
};

export interface IPageWrapperProps<T>
  extends Partial<Pick<IDetailsHeaderProps, 'routes' | 'extra' | 'footer'>> {
  children: React.ReactNode;
  title?: string;
  className?: string;
  headerMenu?: any;
  backIcon?: boolean | React.ReactNode;
  horizontalScroll?: any;
  selectedData?: SelectedData<T>;
  noPadding?: any;
  handleSelect?: any;
  margin?: any;
  shadow?: any;
  showSelected?: boolean;
  hiddenSelected?: boolean;
  showBreadcrumbs?: boolean;
}

/**
 * Page wrapper that includes include page header and actions
 * @param children
 * @param title
 * @param className
 * @param headerMenu
 * @param backIcon
 * @param horizontalScroll
 * @param noPadding
 * @param margin
 * @param selectedData
 * @param handleSelectCompany
 * @param shadow
 * @param showSelected
 * @param rest
 * @returns {*}
 * @constructor
 */
function PageWrapper<T extends ILiteralObj>({
  children,
  title,
  className,
  headerMenu,
  backIcon = false,
  horizontalScroll,
  selectedData,
  noPadding,
  handleSelect,
  margin,
  shadow,
  showSelected = false,
  hiddenSelected = false,
  showBreadcrumbs = false,
  routes,
  extra = [],
  footer,
  ...rest
}: IPageWrapperProps<T>): JSX.Element {
  return (
    <div
      className={classNames(
        'PageWrapper',
        {
          'PageWrapper--no-padding': noPadding,
          'PageWrapper--margin': margin,
          'PageWrapper--shadow': shadow,
        },
        className,
      )}
      {...rest}>
      {(backIcon || title || headerMenu) && !showBreadcrumbs ? (
        <>
          <PageHeader
            backIcon={backIcon}
            title={title}
            extra={headerMenu}
            className="PageWrapper_header"
            footer={footer}
          />
          {(selectedData != null || showSelected) && !hiddenSelected ? (
            <Select
              mode="multiple"
              showArrow
              onChange={handleSelect}
              optionLabelProp="label"
              style={{marginBottom: '10px', minWidth: '150px'}}
              value={selectedData?.defaultValue}>
              {selectedData?.value.map((item) => (
                <Select.Option
                  key={item.uuid}
                  value={item.uuid}
                  label={item.title}>
                  {item.title}
                </Select.Option>
              ))}
            </Select>
          ) : null}
        </>
      ) : null}

      <div className={classNames('PageWrapper_content')}>
        {showBreadcrumbs && routes ? (
          <CustomPageHeader title={title} routes={routes} extra={extra} />
        ) : null}
        {children}
      </div>
    </div>
  );
}
