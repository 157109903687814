import * as React from 'react';
import {List} from 'immutable';
import {
  IUseEmployeeProps,
  IUseEmployeeReturnType,
  useEmployee,
} from './useEmployee';
import {
  CompanyOrderBonusDTO,
  CompanyOrderBonusGroupType,
  EmployeeFormDTO,
  EmployeeMapper,
  EmployeeModel,
  EmployeeRoleFormDTO,
  EmployeeSalaryFormDTO,
  EmployeeStatuses,
  EmployeeUserDataFormDTO,
  InvitationMapper,
  EmployeeSalaryDTO,
} from '../struture';
import {
  acceptEmployeeInvite,
  blockEmployee,
  cancelEmployeeInvite,
  changeEmployeeBonuses,
  changeEmployeeSalary,
  createEmployee,
  editEmployee,
  inviteEmployee,
  setEmployeePassword,
  setEmployeeRole,
} from '../services/api/employee';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../store/reducers';
import {REDUX_STATUS} from '../services/types';
import {
  addEmployeeToServiceList as storeAddEmployeeToServiceList,
  resetEmployee as storeResetEmployee,
  setEmployee as storeSetEmployee,
  updateEmployee as storeUpdateEmployee,
  updateEmployeeFromServiceList as storeUpdateEmployeeFromServiceList,
} from '../store/actions';
import {toStringDate} from '../services/helpers';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../contex';
import {useCallback, useState} from 'react';

export interface IUseStateEmployeeProps extends IUseEmployeeProps {
  companyUuid: string;
}

export interface IUseStateEmployeeReturnType
  extends Omit<IUseEmployeeReturnType, 'entity'> {
  employee: EmployeeModel | null;
  handleUpdateEmployee: (value: EmployeeFormDTO) => Promise<void>;
  handleCreateEmployee: (value: EmployeeFormDTO) => Promise<void>;
  handleInvitationEmployee: (employeeUuid: string) => Promise<void>;
  handleBlockEmployee: (
    employeeUuid: string,
    status: number,
  ) => Promise<EmployeeModel | void>;
  handleSetEmployeePassword: (value: EmployeeUserDataFormDTO) => Promise<void>;
  handleSetEmployeeRole: (value: EmployeeRoleFormDTO) => Promise<void>;
  handleAcceptEmployeeInvite: (value: EmployeeModel) => Promise<void>;
  handleCancelEmployeeInvite: (value: EmployeeModel) => Promise<void>;
  handleChangeEmployeeBonuses: (
    value: CompanyOrderBonusDTO[],
  ) => Promise<EmployeeModel>;
  handleChangeEmployeeSalary: (
    value: EmployeeSalaryDTO[],
  ) => Promise<EmployeeModel>;
  handleRefreshEmployee: () => Promise<EmployeeModel | void>;
  handleResetEmployee: () => void;
  handleSetEmployee: (employee: EmployeeModel) => void;
  handleUpdateStoredEmployee: (employee: EmployeeModel) => void;
  status: REDUX_STATUS;
  employeeServiceBonuses: CompanyOrderBonusDTO[] | null;
}

export function useStoreEmployee({
  companyUuid,
  loadOnInit = true,
  employeeUuid,
  ...rest
}: IUseStateEmployeeProps): IUseStateEmployeeReturnType {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const [employee, setEmployee] = React.useState<EmployeeModel | null>(null);
  const [isStarLoading, setIsStartLoading] = React.useState<boolean>(false);
  const [employeeServiceBonuses, setEmployeeServiceBonuses] = useState<
    CompanyOrderBonusDTO[] | null
  >(null);

  const {
    status: storedEmployeeStatus,
    cachedEmployeeList,
    ...storedEmployeeParams
  } = useSelector(({employee}: RootState) => employee);

  const dispatch = useDispatch<any>();
  const once = React.useRef(false);

  React.useEffect(() => {
    if (cachedEmployeeList?.size > 0) {
      const employee = cachedEmployeeList.get(employeeUuid);

      if (employee) {
        setIsStartLoading(false);
        setEmployee(employee);

        if (employee?.bonus_list?.length) {
          const serviceBonuses = (employee?.bonus_list || []).filter(
            ({groups}) => groups?.includes(CompanyOrderBonusGroupType.Service),
          );

          setEmployeeServiceBonuses(serviceBonuses);
        }
      } else {
        setIsStartLoading(true);
      }
    }
    if (cachedEmployeeList?.size === 0 && !employee) {
      setIsStartLoading(true);
    }
  }, [cachedEmployeeList, dispatch, employeeUuid, employee]);

  const {entity, refresh, ...employeeParams} = useEmployee({
    loadOnInit: loadOnInit && isStarLoading,
    employeeUuid,
    ...rest,
  });

  React.useEffect(() => {
    if (entity && isStarLoading && !once.current) {
      dispatch(storeSetEmployee(entity));
      setEmployee(entity);
    }
  }, [dispatch, entity, isStarLoading, storedEmployeeStatus]);

  const handleUpdateStoredEmployee = useCallback(
    (employeeModel: EmployeeModel) => {
      dispatch(storeUpdateEmployee(employeeModel));
    },
    [dispatch],
  );

  const handleUpdateEmployee = React.useCallback(
    async (value: EmployeeFormDTO): Promise<void> => {
      const employeeFormDTO = EmployeeMapper.toEmployeeFormDTO(value as any);

      const employeeDTO = await editEmployee(employeeFormDTO, companyUuid);

      const employeeModel = EmployeeMapper.toEmployeeModel(employeeDTO);
      handleUpdateStoredEmployee(employeeModel);

      dispatch(storeUpdateEmployeeFromServiceList(employeeModel));
    },
    [companyUuid, dispatch, handleUpdateStoredEmployee],
  );

  const handleAcceptEmployeeInvite = React.useCallback(
    async (value: EmployeeModel): Promise<void> => {
      try {
        const employeeDTO = await acceptEmployeeInvite(value?.uuid);

        const employeeModel = EmployeeMapper.toEmployeeModel(employeeDTO);

        dispatch(storeUpdateEmployee(employeeModel));

        alert('success', t('Employee'), t('Accept employee invite success'));
      } catch (error: any) {
        alert(
          'error',
          t('Employee'),
          `${t('An error occurred during accept employee invite')}: ${
            error?.message
          }`,
        );
      }
    },
    [alert, dispatch, t],
  );

  const handleCancelEmployeeInvite = React.useCallback(
    async (value: EmployeeModel): Promise<void> => {
      try {
        const employeeDTO = await cancelEmployeeInvite(value?.uuid);

        const employeeModel = EmployeeMapper.toEmployeeModel(employeeDTO);

        dispatch(storeUpdateEmployee(employeeModel));
        alert('success', t('Employee'), t('Cancel employee invite success'));
      } catch (error: any) {
        alert(
          'error',
          t('Employee'),
          `${t('An error occurred during cancel employee invite')}: ${
            error?.message
          }`,
        );
      }
    },
    [alert, dispatch, t],
  );

  const handleCreateEmployee = React.useCallback(
    async (value: EmployeeFormDTO) => {
      const employeeFormDTO = EmployeeMapper.toEmployeeFormDTO(value as any);

      const employeeDTO = await createEmployee(employeeFormDTO, companyUuid);

      const employeeModel = EmployeeMapper.toEmployeeModel(employeeDTO);

      const updatedModel = employeeModel.set(
        'created_at',
        toStringDate(new Date()),
      );

      dispatch(storeAddEmployeeToServiceList(employeeModel));
    },
    [companyUuid, dispatch],
  );

  const handleInvitationEmployee = React.useCallback(
    async (employeeUuid: string) => {
      const invitationDTO = await inviteEmployee(employeeUuid, 'sms');
      const invitationModel = InvitationMapper.toInvitationModel(invitationDTO);

      const employeeInvitationList = employee?.invitationList;

      const updatedInvitationList = employeeInvitationList
        ? employeeInvitationList.unshift(invitationModel)
        : List([invitationModel]);

      if (employee) {
        const employeeModel = employee?.set(
          'invitation',
          updatedInvitationList,
        );

        dispatch(storeUpdateEmployee(employeeModel));
      }
    },
    [dispatch, employee],
  );

  const handleSetEmployeePassword = React.useCallback(
    async (value: EmployeeUserDataFormDTO) => {
      await setEmployeePassword(value);
    },
    [],
  );

  const handleSetEmployeeRole = React.useCallback(
    async ({uuid, roles}: EmployeeRoleFormDTO) => {
      const employeeDTO = await setEmployeeRole(uuid, roles);
      const employeeModel = EmployeeMapper.toEmployeeModel(employeeDTO);

      dispatch(storeUpdateEmployee(employeeModel));
    },
    [dispatch],
  );

  const handleBlockEmployee = React.useCallback(
    async (
      employeeUuid: string,
      status: number,
    ): Promise<EmployeeModel | void> => {
      try {
        const employeeDTO = await blockEmployee(employeeUuid, status);
        const employeeModel = EmployeeMapper.toEmployeeModel(employeeDTO);

        dispatch(storeUpdateEmployee(employeeModel));

        alert(
          'success',
          employeeModel?.status === EmployeeStatuses.Disabled
            ? t('Employee lock')
            : t('Employee unlock'),
          employeeModel?.status === EmployeeStatuses.Disabled
            ? t('Employee lock success')
            : t('Employee unlock success'),
        );

        return employeeModel;
      } catch (error: any) {
        alert(
          'error',
          employee?.status === EmployeeStatuses.Disabled
            ? t('Employee lock')
            : t('Employee unlock'),
          `${t(
            `An error occurred during ${
              employee?.status === EmployeeStatuses.Disabled ? 'lock' : 'unlock'
            } employee`,
          )} : ${error?.message}`,
        );
      }
    },
    [alert, dispatch, employee?.status, t],
  );

  const handleResetEmployee = React.useCallback(() => {
    once.current = true;
    dispatch(storeResetEmployee());
  }, [dispatch]);

  const handleSetEmployee = React.useCallback(
    (employee: EmployeeModel) => {
      dispatch(storeSetEmployee(employee));
      setEmployee(employee);
    },
    [dispatch],
  );

  const handleRefreshEmployee = React.useCallback(async () => {
    const employeeModel = await refresh({showLoading: false, employeeUuid});

    if (employeeModel) {
      setEmployee(employeeModel);
      dispatch(storeUpdateEmployee(employeeModel));
    }

    return employeeModel;
  }, [dispatch, employeeUuid, refresh]);

  const handleChangeEmployeeBonuses = React.useCallback(
    async (value: CompanyOrderBonusDTO[]): Promise<EmployeeModel> => {
      const employeeDTO = await changeEmployeeBonuses(employeeUuid, value);

      const employeeModel = EmployeeMapper.toEmployeeModel(employeeDTO);
      handleUpdateStoredEmployee(employeeModel);

      dispatch(storeUpdateEmployeeFromServiceList(employeeModel));

      return employeeModel;
    },
    [dispatch, employeeUuid, handleUpdateStoredEmployee],
  );

  const handleChangeEmployeeSalary = React.useCallback(
    async (value: EmployeeSalaryDTO[]): Promise<EmployeeModel> => {
      const employeeDTO = await changeEmployeeSalary(employeeUuid, value);

      const employeeModel = EmployeeMapper.toEmployeeModel(employeeDTO);

      handleUpdateStoredEmployee(employeeModel);

      dispatch(storeUpdateEmployeeFromServiceList(employeeModel));

      return employeeModel;
    },
    [dispatch, employeeUuid, handleUpdateStoredEmployee],
  );

  return {
    ...employeeParams,
    ...storedEmployeeParams,
    employee: employee,
    loading: !employee,
    handleUpdateEmployee,
    handleCreateEmployee,
    status: storedEmployeeStatus,
    handleResetEmployee,
    handleInvitationEmployee,
    handleSetEmployeePassword,
    handleSetEmployeeRole,
    handleBlockEmployee,
    handleSetEmployee,
    handleAcceptEmployeeInvite,
    handleCancelEmployeeInvite,
    refresh,
    handleRefreshEmployee,
    handleUpdateStoredEmployee,
    handleChangeEmployeeBonuses,
    employeeServiceBonuses,
    handleChangeEmployeeSalary,
  };
}
