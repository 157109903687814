import React, {Suspense, useCallback, useEffect, useRef, useState} from 'react';
import {Col, Form, Input} from 'antd';
import {useTranslation} from 'react-i18next';
import {useDefaultForm} from '../../../contex';
import styled, {css, useTheme} from 'styled-components';
import {Button, SuspenseEmpty} from '../../../components/lib/DataDisplay';
import {StyledTitle} from '../../../components/lib/Styled';
import {useModal} from '../../../hooks';
import {ProductBarCodeFormDTO} from '../../../struture';
import JsBarcode from 'jsbarcode';
import {generateEAN13, isValidEAN13} from '../../../services/helpers';

const ProductBarCodeModalLazy = React.lazy(
  () => import('../Show/ProductBarCodeModal'),
);

export interface IProductBarCodeFieldProps {
  loading?: boolean;
  editMode: boolean;
}

const StyledFormItem = styled(Form.Item)`
  flex: auto;
  margin-bottom: 0;

  & .ant-input-group-addon {
    padding: 0;
  }
`;

const StyledGenerateButton = styled(Button)<{disabled: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;

  &&& {
    height: 30px;

    ${({disabled}) =>
      disabled &&
      css`
        & > button {
          height: 30px;
        }
      `}
  }
`;

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 5px;
`;

const StyledBarCodeContainer = styled.div<{$visible: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 0;
  transition: all 0.5s;
  background-color: ${({theme}) => theme.background.layout};
  border-radius: 7px;

  ${({$visible}) =>
    $visible &&
    css`
      height: 150px;
      margin-top: 10px;
      transition: all 0.5s;
    `}
`;

export function ProductBarCodeField({
  loading,
  editMode,
}: IProductBarCodeFieldProps): React.JSX.Element {
  const {t} = useTranslation();
  const theme: any = useTheme();
  const {loadingSubmit, handlerUpdateFormState, formData, setFields} =
    useDefaultForm();

  const [showBarCode, setShowBarCode] = useState(false);

  const once = useRef(false);

  const handleChangeBarCode = useCallback(
    (bar_code: string) => {
      const valid = isValidEAN13(bar_code);

      if (valid) {
        setShowBarCode(true);
        once.current = true;
        setFields([
          {
            name: 'bar_code',
            errors: [],
          },
        ]);

        JsBarcode('#bar-code-container', bar_code, {
          format: formData?.bar_code_format,
          lineColor: 'black',
          background: theme.background.layout,
        });

        setShowBarCode(true);
      } else {
        setShowBarCode(false);
        setFields([
          {
            name: 'bar_code',
            errors: [t('Not a valid input format')],
          },
        ]);
      }

      handlerUpdateFormState({bar_code});
    },
    [
      formData?.bar_code_format,
      handlerUpdateFormState,
      setFields,
      t,
      theme.background.layout,
    ],
  );

  const handleGenerateBarCode = useCallback(
    ({bar_code_format}: ProductBarCodeFormDTO) => {
      const EAN13 = generateEAN13();

      JsBarcode('#bar-code-container', EAN13, {
        format: bar_code_format,
        lineColor: 'black',
        background: theme.background.layout,
      });

      setShowBarCode(true);
      once.current = true;

      handlerUpdateFormState({bar_code: EAN13});
    },
    [handlerUpdateFormState, theme.background.layout],
  );

  const {visible, handleSuccess, handleCancel, handleOnInit} = useModal({
    onSuccess: handleGenerateBarCode,
  });

  useEffect(() => {
    if (editMode) {
      if (!once?.current && formData?.bar_code) {
        once.current = true;

        try {
          JsBarcode('#bar-code-container', formData?.bar_code, {
            format: formData?.bar_code_format,
            lineColor: 'black',
            background: theme.background.layout,
          });
          setShowBarCode(true);
        } catch (e) {
          console.log(e);
        }
      }
    }
  }, [
    editMode,
    formData?.bar_code,
    formData?.bar_code_format,
    theme.background.layout,
  ]);

  return (
    <>
      <StyledCol span={24}>
        <StyledFormItem
          label={t('Bar code')}
          name="bar_code"
          extra={
            <StyledBarCodeContainer $visible={showBarCode}>
              <svg id="bar-code-container" />
            </StyledBarCodeContainer>
          }>
          <Input
            addonAfter={
              <StyledGenerateButton
                onClick={handleOnInit}
                disabled={loading || loadingSubmit}
                size="small"
                type="text">
                <StyledTitle>{t('Generate')}</StyledTitle>
              </StyledGenerateButton>
            }
            disabled={loading || loadingSubmit}
            placeholder={t('Enter bar code')}
            onChange={(e) => handleChangeBarCode(e?.target?.value)}
          />
        </StyledFormItem>
      </StyledCol>

      <Suspense fallback={<SuspenseEmpty />}>
        <ProductBarCodeModalLazy
          barCode={{bar_code_format: formData?.bar_code_format}}
          onSuccess={handleSuccess as any}
          visible={visible}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}
