import {Record, List} from 'immutable';
import {IListProps} from './List';
import {PaymentDTO, PaymentModel} from '../internal';

export interface IPaymentListModelProps extends IListProps {
  payments: PaymentDTO[];
}

export interface IPaymentListModelReturnType extends IListProps {
  payments: List<PaymentModel>;
}

export class PaymentListModel extends Record<IPaymentListModelReturnType>({
  payments: List(),
  total: 0,
}) {
  constructor(
    {payments, ...props}: IPaymentListModelProps = {} as IPaymentListModelProps,
  ) {
    super({
      ...props,
      payments: List(payments.map((payment) => new PaymentModel(payment))),
    });
  }
}
