import React, {useEffect, useState} from 'react';
import {Form} from 'antd';
import {DefaultFormManager} from '../../../contex';
import DefaultForm, {IDefaultLocalFormProps} from './DefaultForm';

export interface IDefaultFormProps<T, U>
  extends Omit<
    IDefaultLocalFormProps<T, U>,
    'form' | 'loadingSubmit' | 'setLoadingSubmit'
  > {
  instance?: any;
  loading?: boolean;
}

export default function DefaultStateForm<T, U>({
  children,
  initialValues,
  instance,
  loading = false,
  ...rest
}: IDefaultFormProps<T, U>): JSX.Element {
  const [form] = Form.useForm();
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  useEffect(() => {
    setLoadingSubmit(!!loading);
  }, [loading]);

  return (
    <DefaultFormManager<T>
      form={instance ? {...instance} : {...form}}
      initialValues={initialValues}
      loadingSubmit={loadingSubmit}
      setLoadingSubmit={setLoadingSubmit}>
      <DefaultForm<T, U>
        form={instance || form}
        loadingSubmit={loadingSubmit}
        setLoadingSubmit={setLoadingSubmit}
        initialValues={initialValues}
        {...rest}>
        {children}
      </DefaultForm>
    </DefaultFormManager>
  );
}
