import * as React from 'react';
import {phoneScreenMediaQuery} from '../services/const';

export function usePhoneScreenMediaQuery(): boolean {
  const [isPhoneScreen, setIsPhoneScreen] = React.useState<boolean>(
    phoneScreenMediaQuery?.matches,
  );

  const resizePhoneScreen = React.useCallback(
    ({matches}: MediaQueryListEvent): void => {
      setIsPhoneScreen(matches);
    },
    [],
  );

  React.useEffect(() => {
    phoneScreenMediaQuery.addEventListener('change', resizePhoneScreen);

    return () => {
      phoneScreenMediaQuery.removeEventListener('change', resizePhoneScreen);
    };
  }, [resizePhoneScreen]);

  return isPhoneScreen;
}
