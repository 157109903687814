import {Record, List} from 'immutable';
import {IListProps} from './List';
import {ClientDTO, ClientModel} from '../internal';
import {WithoutRecursion} from '../type';

export interface IClientListModelProps extends IListProps {
  clients: ClientDTO[];
}

export interface IClientListModelReturnType extends IListProps {
  clients: List<ClientModel>;
}

export class ClientListModel extends Record<IClientListModelReturnType>({
  clients: List(),
  total: 0,
}) {
  constructor(
    {
      clients = [],
      ...props
    }: IClientListModelProps = {} as IClientListModelProps,
    withoutMembers: boolean,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IClientListModelReturnType = {
      ...props,
      clients: List(
        clients.map(
          (client) => new ClientModel(client, withoutMembers, withoutRecursion),
        ),
      ),
    };

    super(options);
  }
}
