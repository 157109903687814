import * as React from 'react';
import {
  CompanyScheduleFilter,
  DEFAULT_SCHEDULE_FILTER,
} from '../../../services/api/company';
import {useStateReducer} from '../../../components/lib/libV2/hooks';
import {LocalStorageItems} from '../../../services/const';

export interface IOrderListFilterManagerProps {
  children: React.ReactNode;
}

export type UpdateOrderList = Partial<
  CompanyScheduleFilter & Pick<IOrderListFilterManagerContext, 'loading'>
>;

export interface IOrderListFilterManagerContext extends CompanyScheduleFilter {
  handleUpdateOrderListFilter: (value: UpdateOrderList) => void;
  handleApplyOrderListFilters: () => void;
  handleResetOrderListFilters: () => void;
  handleCancelChangeOrderListFilter: () => void;
  loading: boolean;
  count: number;
}

export const OrderListFilterManagerContext =
  React.createContext<IOrderListFilterManagerContext>({
    date_start: null,
    date_end: null,
    status_uuid: null,
    client_uuid: null,
    manager_uuid: null,
    location_uuid: null,
    handleUpdateOrderListFilter: () => {},
    handleApplyOrderListFilters: () => {},
    handleResetOrderListFilters: () => {},
    handleCancelChangeOrderListFilter: () => {},
    loading: false,
    count: 0,
  });

export const useOrderListFilter = () =>
  React.useContext<IOrderListFilterManagerContext>(
    OrderListFilterManagerContext,
  );

export function OrderListFilterManager({
  children,
}: IOrderListFilterManagerProps): React.JSX.Element {
  const {
    date_start,
    date_end,
    client_uuid,
    manager_uuid,
    status_uuid,
    location_uuid,
    loading,
    count,

    handleUpdate,
  } = useStateReducer<
    CompanyScheduleFilter &
      Pick<IOrderListFilterManagerContext, 'loading' | 'count'>
  >({
    date_start: null,
    date_end: null,
    status_uuid: null,
    client_uuid: null,
    manager_uuid: null,
    location_uuid: null,
    loading: true,
    count: 0,
  });

  const getCount = React.useCallback(
    (
      value: Partial<
        CompanyScheduleFilter &
          Pick<IOrderListFilterManagerContext, 'loading' | 'count'>
      >,
    ) => {
      const count = Object.entries(value).reduce((acc, [_, value]) => {
        acc += Number(!!value);

        return acc;
      }, 0);

      handleUpdate({count});
    },
    [handleUpdate],
  );

  const handleUpdateOrderListFilter = React.useCallback(
    (value: UpdateOrderList) => {
      handleUpdate((prevState) => {
        return {...prevState, ...value};
      });
    },
    [handleUpdate],
  );

  const handleApplyOrderListFilters = React.useCallback(() => {
    const scheduleFilters = {
      date_start,
      date_end,
      client_uuid,
      manager_uuid,
      status_uuid,
      location_uuid,
    };

    const serializationScheduleFilter = JSON.stringify(scheduleFilters);

    localStorage.setItem(
      LocalStorageItems.orderListFilter,
      serializationScheduleFilter,
    );

    getCount({date_end, client_uuid, manager_uuid, status_uuid, location_uuid});
  }, [
    client_uuid,
    date_end,
    date_start,
    getCount,
    location_uuid,
    manager_uuid,
    status_uuid,
  ]);

  const handleResetOrderListFilters = React.useCallback(() => {
    handleUpdate(DEFAULT_SCHEDULE_FILTER);

    const serializationScheduleFilter = JSON.stringify(DEFAULT_SCHEDULE_FILTER);

    localStorage.setItem(
      LocalStorageItems.orderListFilter,
      serializationScheduleFilter,
    );
    handleUpdate({count: 0});
  }, [handleUpdate]);

  const handleCancelChangeOrderListFilter = React.useCallback(() => {
    const serializationScheduleFilter = JSON.parse(
      localStorage.getItem(LocalStorageItems.orderListFilter) || '{}',
    );

    const filters = {
      ...DEFAULT_SCHEDULE_FILTER,
      ...serializationScheduleFilter,
    };

    handleUpdate((prevState) => ({...prevState, ...filters}));
  }, [handleUpdate]);

  React.useEffect(() => {
    const serializationScheduleFilter = JSON.parse(
      localStorage.getItem(LocalStorageItems.orderListFilter) || '{}',
    );

    const {
      date_start = null,
      count = 0,
      ...filters
    } = {
      ...DEFAULT_SCHEDULE_FILTER,
      ...serializationScheduleFilter,
    };

    const state = {...filters, date_start, count, loading: false};

    handleUpdateOrderListFilter(state);

    getCount(filters);
  }, [getCount, handleUpdateOrderListFilter]);

  const value = React.useMemo(
    () => ({
      date_start,
      date_end,
      client_uuid,
      manager_uuid,
      status_uuid,
      location_uuid,
      loading,
      count,

      handleUpdateOrderListFilter,
      handleApplyOrderListFilters,
      handleResetOrderListFilters,
      handleCancelChangeOrderListFilter,
    }),
    [
      client_uuid,
      date_end,
      date_start,
      location_uuid,
      manager_uuid,
      status_uuid,
      loading,
      count,

      handleUpdateOrderListFilter,
      handleApplyOrderListFilters,
      handleResetOrderListFilters,
      handleCancelChangeOrderListFilter,
    ],
  );

  return (
    <OrderListFilterManagerContext.Provider value={value}>
      {children}
    </OrderListFilterManagerContext.Provider>
  );
}
