import {Record, Map} from 'immutable';
import CompanyCalendarTimeSlotsMap, {
  ICompanyCalendarTimeSlotsMapProps,
} from './CompanyCalendarTimeSlotsMap';

export interface Calendar extends ICompanyCalendarTimeSlotsMapProps {
  date: string;
}

export interface ICompanyCalendarMap {
  calendar: Calendar | object;
}

/**
 * @class CompanyCalendarMap
 * @classdesc CompanyCalendarMap map model
 * @property {Map} calendar - map of company calendar and timeslots
 */
export default class CompanyCalendarMap extends Record({
  calendar: Map(),
}) {
  /**
   * @constructor
   * @param {{calendar:{}}} param0
   */
  constructor({calendar = {}, ...props}: ICompanyCalendarMap) {
    super({
      calendar: Map({
        ...Object.fromEntries(
          Object.entries(calendar).map(([date, timeslots]) => [
            date,
            new CompanyCalendarTimeSlotsMap({timeslots}),
          ]),
        ),
      }),
      ...props,
    });
  }
}
