import {List} from 'immutable';
import {
  split,
  head,
  toLowerCase,
  compose,
  curry,
  neq,
  firstLetterToUppercase,
  eq,
  isObject,
  getFirstLatter,
} from './index';
import {app_languages, ILiteralObj} from '../types';
import moment, {Moment} from 'moment';
import {EAN13_UA, LocalStorageItems} from '../const';

export const transformBrowserToHtmlLang = (lang: string): string => {
  if (lang === app_languages.Ukrainian) {
    return 'ua';
  }
  if (lang === app_languages.English) {
    return 'en';
  }

  if (lang === app_languages.Russian) {
    return 'ru';
  }
  return lang;
};

export const getContentLanguage = compose<string>(
  transformBrowserToHtmlLang,
  toLowerCase,
  head,
  split(','),
);

export function getCookie(name: string): string | undefined {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(/([.$?*|{}()[]\\\/+^])/g, '\\$1')}=([^;]*)`,
    ),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function setCookie(
  name: string,
  value: string,
  options: any = {},
): void {
  options = {
    path: '/',
    // при необходимости добавьте другие значения по умолчанию
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(
    value,
  )}`;

  for (const optionKey in options) {
    updatedCookie += `; ${optionKey}`;
    const optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += `=${optionValue}`;
    }
  }

  document.cookie = updatedCookie;
}

export function deleteCookie(name: string) {
  setCookie(name, '', {
    'max-age': -1,
  });
}

export const notEmptyValue = <T extends ILiteralObj>(value: T): any =>
  Object.entries(value).reduce((acc: any, [key, value]) => {
    if (value) {
      acc[key] = value;
    }
    return acc;
  }, {});

export const isNotEqualById = curry<[string, string], boolean>(
  (id: string, targetId: string): boolean => neq(id, targetId),
);

export const serializeInfo = (
  acc: string,
  [key, value]: [string, string | null],
): string => {
  const joinInfo = `${key}/${value}`;

  acc = acc ? `${acc};${joinInfo}` : joinInfo;

  return acc;
};

export const setDateLanguage = (lng: string): void => {
  if (lng.toLowerCase().includes('ua')) {
    moment.locale('uk');
    localStorage.setItem(LocalStorageItems.language, 'ua');
    return;
  }

  if (lng.toLowerCase().includes('uk')) {
    localStorage.setItem(LocalStorageItems.language, 'ua');
  } else {
    localStorage.setItem(LocalStorageItems.language, lng);
  }

  moment.locale(lng);
};

export const getFirstLatterToUpperCase = compose<string>(
  getFirstLatter,
  firstLetterToUppercase,
);

export const isUrl = (value: string): boolean => {
  const pattern = /^https?|data:image/;

  return !!value.match(pattern);
};

const isDote = (value: string) => eq(value, '.');
const isSlash = (value: string) => eq(value, '/');

export const isPath = (value: string): boolean => {
  return compose<boolean>(
    (value) => isDote(value) || isSlash(value),
    head,
    split(''),
  )(value);
};

export const makePassword = (length: number): string => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const randomNumber = (length: number): string => {
  let result = '';
  const characters = '0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const identity = <T>(value: T): T => value;

export function debounceV2(f: any, ms: number) {
  let isCooldown = false;

  return () => {
    if (isCooldown) return;

    // @ts-ignore
    f.apply(this, arguments);

    isCooldown = true;

    setTimeout(() => (isCooldown = false), ms);
  };
}

export const getPassedSecondsFrom = (date: string | Date): number => {
  const milliseconds = new Date().getTime() - new Date(date).getTime();

  return Math.floor(milliseconds / 1000);
};

export const getPassedHourFrom = (date: string | Date): number => {
  const milliseconds = new Date(date).getTime() - new Date().getTime();

  return Math.floor(milliseconds / (1000 * 60 * 60));
};

export const toDateByFormat = (
  value: any = new Date(),
  format: string,
): string => moment(value).format(format);

export const isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function () {
    return (
      navigator.userAgent.match(/IEMobile/i) ||
      navigator.userAgent.match(/WPDesktop/i)
    );
  },
  any: function () {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    );
  },
};

type DateType = string | Date | Moment;

export const isDateGreaterThanNow = (value: DateType): boolean => {
  return moment(value).unix() > moment().unix();
};

export const isDateGreaterOrEqual = (
  dateOne: DateType,
  dateTwo: DateType,
): boolean => {
  return moment(dateOne).unix() >= moment(dateTwo).unix();
};

export const isDateGreater = (
  dateOne: DateType,
  dateTwo: DateType,
): boolean => {
  return moment(dateOne).unix() > moment(dateTwo).unix();
};
export const isDateLessThanNow = (value: DateType): boolean =>
  !isDateGreaterThanNow(value);

export const isDateLessOrOrEqual = (
  dateOne: DateType,
  dateTwo: DateType,
): boolean => !isDateGreaterOrEqual(dateOne, dateTwo);

export const isDateLess = (dateOne: DateType, dateTwo: DateType): boolean => {
  return !isDateGreaterOrEqual(dateOne, dateTwo);
};

export const getDiffDaysFromNow = (value: DateType): number =>
  moment(value).diff(new Date(), 'days');

export const getDiffHoursFromNow = (value: DateType): number =>
  moment(value).diff(new Date(), 'hours');

export const getDiffMinutesFromNow = (value: DateType): number =>
  moment(value).diff(new Date(), 'minutes');

export const groupByPropV2 = curry((prop: string, acc: any, curr: any): any => {
  const propName = curr[prop];

  acc = propName
    ? acc[propName]
      ? {...acc, [propName]: [...acc[propName], curr]}
      : {...acc, [propName]: [curr]}
    : acc;

  return acc;
});

export const toStringDate = (value: DateType = new Date()): string =>
  moment(value).toString();

export const toShortLime = curry((length: number, words: string): string =>
  words?.substring(0, length),
);

export const removeAllSpace = (words: string): string =>
  words?.replaceAll(' ', '');

export const getStartOfMonthDate = (format = 'YYYY-MM-DD'): string =>
  toDateByFormat(moment(new Date()).startOf('months'), 'YYYY-MM-DD');

export const getEndOfMonthDate = (format = 'YYYY-MM-DD'): string =>
  toDateByFormat(moment(new Date()).endOf('months'), 'YYYY-MM-DD');

export const notEmptyFields = <T>(value: T) =>
  isObject(value)
    ? Object.entries(value as any).reduce((acc: any, [key, value]) => {
        if (
          value ||
          (typeof value === 'number' && !Number.isNaN(value)) ||
          typeof value === 'boolean' ||
          value === 'allowEmptyString' ||
          value === 'null'
        ) {
          acc[key] =
            value === 'allowEmptyString' ? '' : value === 'null' ? null : value;
        }
        return acc;
      }, {})
    : value;

export const isEmptyV2 = <T>(value: T): boolean =>
  typeof value === 'boolean'
    ? false
    : typeof value === 'number' && value > 0
    ? true
    : !!value;

export const todayWeekday = (): string =>
  moment(new Date()).locale('en').format('dddd');

export const todayHour = (): string => toDateByFormat(new Date(), 'HH');

export const areDeeplyEqual = (obj1: any, obj2: any): boolean => {
  if (obj1 === obj2) return true;

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) return false;

    return obj1.every((elem, index) => {
      return areDeeplyEqual(elem, obj2[index]);
    });
  }

  if (
    typeof obj1 === 'object' &&
    typeof obj2 === 'object' &&
    obj1 !== null &&
    obj2 !== null
  ) {
    if (Array.isArray(obj1) || Array.isArray(obj2)) return false;

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (
      keys1.length !== keys2.length ||
      !keys1.every((key) => keys2.includes(key))
    )
      return false;

    for (let key in obj1) {
      let isEqual = areDeeplyEqual(obj1[key], obj2[key]);
      if (!isEqual) {
        return false;
      }
    }

    return true;
  }

  return false;
};

export const generateEAN13 = () => {
  const prefix = EAN13_UA + randomNumber(4);
  const productCode = randomNumber(5);
  const fullCode = prefix + productCode;

  const digits = fullCode.split('').map(Number);
  let evenSum = 0;
  let oddSum = 0;

  for (let i = 0; i < digits.length; i++) {
    if (i % 2 === 0) {
      oddSum += digits[i];
    } else {
      evenSum += digits[i];
    }
  }

  const totalSum = oddSum + evenSum * 3;
  const checkDigit = (10 - (totalSum % 10)) % 10;

  return fullCode + checkDigit;
};

export const isValidEAN13 = (code: string) => {
  if (!/^\d{13}$/.test(code)) {
    return false;
  }

  const digits = code.split('').map(Number);

  let oddSum = 0;
  let evenSum = 0;

  for (let i = 0; i < 12; i++) {
    if (i % 2 === 0) {
      oddSum += digits[i];
    } else {
      evenSum += digits[i];
    }
  }

  const totalSum = oddSum + evenSum * 3;
  const checkDigit = (10 - (totalSum % 10)) % 10;

  return checkDigit === digits[12];
};

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const areDeeplyEqualIgnoreFields = (
  obj1: any,
  obj2: any,
  fields: string[],
): boolean => {
  if (obj1 === obj2) return true;

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) return false;

    return obj1.every((elem, index) => {
      return areDeeplyEqual(elem, obj2[index]);
    });
  }

  if (
    typeof obj1 === 'object' &&
    typeof obj2 === 'object' &&
    obj1 !== null &&
    obj2 !== null
  ) {
    if (Array.isArray(obj1) || Array.isArray(obj2)) return false;

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (
      keys1.length !== keys2.length ||
      !keys1.every((key) => keys2.includes(key))
    )
      return false;

    for (let key in obj1) {
      if (fields.includes(key)) {
        continue;
      }

      let isEqual = areDeeplyEqual(obj1[key], obj2[key]);
      if (!isEqual) {
        return false;
      }
    }

    return true;
  }

  return false;
};
