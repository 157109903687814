export interface ISettingDTOProps {
  uuid: string;
  created_at: string;
  updated_at: string;
  setting_key: string;
  setting_value: string;
}

export class SettingDTO {
  public uuid: string;

  public created_at: string;

  public updated_at: string;

  public setting_key: string;

  public setting_value: string;

  constructor(props: ISettingDTOProps) {
    this.uuid = props?.uuid || '';
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
    this.setting_key = props?.setting_key || '';
    this.setting_value = props?.setting_value || '';
  }
}
