import * as React from 'react';
import {Routes} from '../services/types';
import {
  verificationRestore,
  verificationSignup,
  regenerationCode,
} from '../services/api/user';
import {AuthDataDTO} from '../struture';
import {RootState} from '../store/reducers';
import {useDispatch, useSelector} from 'react-redux';
import {
  setSignupPageState,
  updateSignupPageState,
  resetSignupPageState,
  setEmployeeSignupPageState,
  updateEmployeeSignupPageState,
  resetEmployeeSignupPageState,
  setForgotPasswordPageState,
  updateForgotPasswordPageState,
  resetForgotPasswordPageState,
} from '../store/actions';
import {isFunction} from '../services/helpers';
import {useReSendTimeout} from './useReSendTimeout';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../contex';
import {verificationReSendCodeTimeout} from '../services/const';

type StoreVerificationFactoryKey =
  | Routes.signup
  | Routes.employeeSignup
  | Routes.forgotPassword
  | Routes.approveEnter;

export interface IUseStoreVerificationFactoryProps<T> {
  key: StoreVerificationFactoryKey;
  getState?: (state: RootState) => Partial<T>;
}

export interface IStoreVerificationFactoryReturnType<T> {
  updateStateFactory: (value: Partial<T>) => void;
  apiFunctionFactory: (code: string) => Promise<AuthDataDTO | void>;
  initStateFactory: (showLoading?: boolean) => void;
  resetStateFactory: () => void;
  loading: boolean;
  isShowLoading: boolean;
  disabled: boolean;
  seconds: number;
  handleRegenerationCode: (value?: string) => Promise<void>;
}

export function useStoreVerificationFactory<T>({
  key,
  getState,
}: IUseStoreVerificationFactoryProps<T>): IStoreVerificationFactoryReturnType<T> &
  T {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const {...state}: any = useSelector((state: RootState) => {
    return isFunction(getState) ? getState(state) : state[key];
  });

  const {seconds, disabled, startInterval} = useReSendTimeout({
    timeWhenCodeWasSend: state?.timeWhenCodeWasSend,
    loading: state?.loading,
  });

  const dispatch = useDispatch<any>();

  const updateStateFactory = React.useCallback(
    async (value: Partial<T>) => {
      if (key === Routes.signup) {
        dispatch(updateSignupPageState(value));
      }

      if (key === Routes.employeeSignup) {
        dispatch(updateEmployeeSignupPageState(value));
      }

      if (key === Routes.forgotPassword) {
        dispatch(updateForgotPasswordPageState(value));
      }
    },
    [dispatch, key],
  );

  const resetStateFactory = React.useCallback(async () => {
    if (key === Routes.signup) {
      dispatch(resetSignupPageState());
    }

    if (key === Routes.employeeSignup) {
      dispatch(resetEmployeeSignupPageState());
    }

    if (key === Routes.forgotPassword) {
      dispatch(resetForgotPasswordPageState());
    }
  }, [dispatch, key]);

  const initStateFactory = React.useCallback(
    (showLoading = true): void => {
      dispatch(setSignupPageState());

      dispatch(setForgotPasswordPageState());

      dispatch(setEmployeeSignupPageState());
    },
    [dispatch],
  );

  const apiFunctionFactory = React.useCallback(
    async (code: string): Promise<AuthDataDTO | void> => {
      if (key === Routes.signup) {
        const authDataDTO = await verificationSignup(code);

        dispatch(resetSignupPageState());

        return authDataDTO;
      }

      if (key === Routes.employeeSignup) {
        const authDataDTO = await verificationSignup(code);

        dispatch(
          updateEmployeeSignupPageState({
            approveEnter: false,
          }),
        );

        return authDataDTO;
      }

      if (key === Routes.forgotPassword) {
        const verification = (state as T as any)?.verification;

        const authData = (await verificationRestore(
          code,
          verification || '',
        )) as any;

        dispatch(
          updateForgotPasswordPageState({
            approveEnter: false,
            verification_code: authData?.verification_code,
          }),
        );

        return authData;
      }
    },
    [dispatch, key, state],
  );

  const handleRegenerationCode = React.useCallback(
    async (value?: string) => {
      try {
        if (state?.identifier) {
          const identifier =
            (typeof value === 'string' ? value : '') || state?.identifier || '';

          const {success} = await regenerationCode({
            identifier,
          });

          if (success) {
            startInterval();

            if (seconds === Number(verificationReSendCodeTimeout)) {
              await updateStateFactory({
                timeWhenCodeWasSend: new Date(),
                identifier,
              } as any);
            }
            alert(
              'success',
              t('Generation new code'),
              t('Generation of new code is successful'),
            );
          }
        }
      } catch (error: any) {
        alert(
          'error',
          t('Generation new code'),
          `${t('An error occurred during generate new code')}: ${
            error?.message
          }`,
        );
      }
    },
    [state?.identifier, startInterval, seconds, updateStateFactory, alert, t],
  );

  return {
    updateStateFactory,
    initStateFactory,
    apiFunctionFactory,
    resetStateFactory,
    seconds,
    disabled,
    handleRegenerationCode,
    ...state,
  };
}
