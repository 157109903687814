import {Record, List} from 'immutable';
import {IListProps} from './List';
import {CompanyDTO, CompanyModel} from '../internal';
import {WithoutRecursion} from '../type';

export interface ICompanyListModelProps extends IListProps {
  companies: CompanyDTO[];
}

export interface ICompanyListModelReturnType extends IListProps {
  companies: List<CompanyModel>;
}

export class CompanyListModel extends Record<ICompanyListModelReturnType>({
  companies: List(),
  total: 0,
}) {
  constructor(
    {
      companies = [],
      total,
      ...props
    }: ICompanyListModelProps = {} as ICompanyListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    super({
      ...props,
      total: total || companies?.length,
      companies: List(
        companies.map((company) => new CompanyModel(company, withoutRecursion)),
      ),
    });
  }
}
