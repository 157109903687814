import {Record} from 'immutable';
import {
  ProfileModel,
  ProfileDTO,
  ProfileMapper,
  MaterialAndExpenseDTO,
} from '../internal';
import {WithoutRecursion} from '../type';

export interface IMaterialAndExpenseModelReturnType {
  uuid: string;
  name: string;
  amount: string;
  price: string;
  total: string;
  comment: string;
  created_at: string;
  updated_at: string;

  created_by: ProfileModel | ProfileDTO;
}

/**
 * @class Client
 * @desc Клієнт
 */
export class MaterialAndExpenseModel extends Record<IMaterialAndExpenseModelReturnType>(
  {
    uuid: '',
    name: '',
    amount: '1.00',
    price: '1',
    total: '1.00',
    comment: '',
    created_at: '',
    updated_at: '',
    created_by: {} as ProfileModel,
  },
) {
  constructor(
    props: MaterialAndExpenseDTO = {} as MaterialAndExpenseDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IMaterialAndExpenseModelReturnType = {
      ...props,

      created_by:
        withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
          ? props?.created_by
          : ProfileMapper.toProfileModel(props?.created_by, [
              WithoutRecursion.profile,
              ...withoutRecursion,
            ]),
    };

    super(options);
  }

  /**
   * @desc Повне Ім'я
   * @return {String}
   */

  get createdByModel(): ProfileModel {
    return this.created_by instanceof ProfileModel
      ? this.created_by
      : ProfileMapper.toProfileModel(this.created_by);
  }
}
