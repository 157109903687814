import {Moment} from 'moment';
import {apiGet} from '../core/api';
import {
  UserOperationMapper,
  IUserOperationListDTO,
  IUserOperationDTOProps,
} from '../../struture';
import {ApiAnswer, IListSearchProps} from '../types';
import {contentApiUrl} from '../const';

export interface IUserOperationListProps extends IListSearchProps {
  date_start?: Date | Moment | string;
  date_end?: Date | Moment | string;
}

export interface IListOperationAnswer extends ApiAnswer {
  operations: IUserOperationDTOProps[];
}

export async function getUserOperationList({
  date_start,
  date_end,
  offset = 0,
  limit = 20,
}: IUserOperationListProps): Promise<IUserOperationListDTO> {
  const isDateStart = date_start ? `&date_start=${date_start}` : '';
  const isDateEnd = date_end ? `&date_end=${date_end}` : '';

  const {operations, total} = await apiGet<null, IListOperationAnswer>(
    `${contentApiUrl}/users/me/operations?limit=${limit}&offset=${offset}`,
  );

  return UserOperationMapper.toUserOperationListDTO(operations, total);
}
