import React, {useState} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import styled, {css} from 'styled-components';
import {CloseOutlined} from '@ant-design/icons';
import {
  ConfirmActionButton,
  IConfirmActionButtonProps,
} from '../../../components/lib/General';
import {TextFormat} from '../../../components/lib/Format';
import {notify} from '../../../components/lib/Feedback';
import {isFunction, IClientSubscriptionProps} from '../../../services/helpers';
import {ISubscriptionCancelParam, useMqlShowIcon} from '../../../hooks';
import {
  isSubscriptionTypeGroup,
  getSubscriptionGroupTitle,
  getSubscriptionServiceTitle,
} from '../../../services/api/clientSubscription';

export default withTranslation()(CancelClientSubscriptionButton);

export interface ICancelClientSubscriptionButtonProps
  extends WithTranslation,
    Omit<IConfirmActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: ISubscriptionCancelParam[]) => Promise<void>;
  onError?: () => void;
  subscriptions: IClientSubscriptionProps[];
  editable?: boolean;
}

const disabledStyle = css`
  background-color: transparent;

  & * {
    border-color: #d1d4cc !important;
  }
`;

const CancelConfirmActionButton = styled(ConfirmActionButton)<any>`
  background-color: ${({theme}) => theme.colors.app};
  border-color: ${({theme}) => theme.colors.app};
  text-shadow: none;

  &:hover,
  :focus {
    background-color: ${({theme}) => theme.colors.componentBackground};
    border-color: ${({theme}) => theme.colors.componentBackground};
    color: ${({theme}) => theme.colors.error};

    ${({disabled}) =>
      disabled &&
      css`
        ${disabledStyle}
      `}
  }
  ${({disabled}) =>
    disabled &&
    css`
      ${disabledStyle}
    `}
`;

function CancelClientSubscriptionButton({
  children,
  subscriptions,
  onSuccess,
  onError,
  t,
  editable,
  ...rest
}: ICancelClientSubscriptionButtonProps): JSX.Element {
  const [loading, setLoading] = useState(false);

  const showIcon = useMqlShowIcon();

  const handleClientCancelClick = async (): Promise<void> => {
    setLoading(true);
    try {
      const list = subscriptions.map((value) => ({
        uuid: value?.uuid,
        title: isSubscriptionTypeGroup(value?.type)
          ? getSubscriptionGroupTitle(value)
          : getSubscriptionServiceTitle(value),
      }));

      if (isFunction(onSuccess)) {
        await onSuccess(list);
      }
    } catch (err: any) {
      setLoading(false);
      notify.deleteError(t('Client'), err, t);
      if (isFunction(onError)) {
        await onError();
      }
    }
  };

  return (
    <CancelConfirmActionButton
      danger
      disabled={editable}
      tooltip={
        editable ? t('You cannot unsubscribe') : t('Cancel client subscription')
      }
      confirmTitle={
        <TextFormat breakWord>
          {t(`Are you sure you want to unsubscribe?`)}
        </TextFormat>
      }
      type="primary"
      onConfirm={handleClientCancelClick}
      loading={loading}
      cancelButtonText={t('Cancel')}
      confirmButtonText={t('Agree')}
      data-testid="clients-subscription__button--cancel"
      {...rest}>
      {showIcon ? children || t('Cancel') : <CloseOutlined />}
    </CancelConfirmActionButton>
  );
}
