import {LoginDTO, LoginFormDTO} from '../internal';

export class LoginFormMapper {
  public static toLoginDTO(login: LoginFormDTO): LoginDTO {
    return new LoginDTO({
      identifier: login?.identifier,
      password: login?.password,
    });
  }

  public static toLoginFormDTO(loginDTO: LoginDTO): LoginFormDTO {
    return new LoginFormDTO({
      identifier: loginDTO?.identifier,
      password: loginDTO?.password,
    });
  }
}
