import styled from 'styled-components';
import {Button} from 'antd';

export const AuthButton = styled(Button)`
  width: 100%;
  background-color: ${({theme}) => theme.colorsV2.buttonPrimaryColor};
  border-color: ${({theme}) => theme.colorsV2.buttonPrimaryBorderColor};
  border-radius: 5px;
  box-shadow: none;

  &&:hover,
  &&:active,
  &&:focus {
    opacity: 0.8;
    border-color: ${({theme}) => theme.colorsV2.buttonPrimaryBorderColor};
    background-color: ${({theme}) =>
      theme.colorsV2.buttonPrimaryActiveColor} !important;
  }
`;
