import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {
  useModal,
  useSize,
  useStateIntegrationListByCategory,
  useStoredCompanies,
  useStoredProfile,
} from '../../../../hooks';
import {correctPrice, textToUpperCase} from '../../../../services/helpers';
import {
  useBreadcrumb,
  useStateReducer,
} from '../../../../components/lib/libV2/hooks';
import {
  IntegrationCategoryType,
  IntegrationCheckboxAttributesDTO,
} from '../../../../struture';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '../../../../services/types';
import {Button, HeaderLeft} from '../../../../components/lib/DataDisplay';
import {useNavigate} from 'react-router';
import {ListLayout} from '../../../../components/lib/Layout';
import {StoreSaleBottomView, StoreSaleCashBoxSelect} from '../Show';
import {useStoreSale} from '../Managers';
import {StoreSaleContent} from '../Forms';
import {
  StyledDescription,
  StyledTitle,
} from '../../../../components/lib/Styled';
import {StoreSaleProductListSelect} from '../Forms/StoreSaleProductListSelect';
import styled, {css, useTheme} from 'styled-components';
import {IoMdSettings} from 'react-icons/io';
import {useKeyboard} from '../../../../contex';
import {TbKeyboardOff, TbKeyboard, TbCategory2} from 'react-icons/tb';
import {LocalStorageItems} from '../../../../services/const';

export interface IStoreDocumentSaleListPageProps {}

export interface DocumentSelectList {
  value: string;
  label: React.ReactNode;
}

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: -10px;
  gap: 5px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding-right: 0;
  padding-left: 0;
`;

const Description = styled(StyledDescription)<{$visible: boolean}>`
  opacity: 0;
  transition: all 0.5s;
  white-space: unset;

  ${({$visible}) =>
    !$visible &&
    css`
      opacity: 1;
      transition: all 0.5s;
    `};
`;

const StyledPriceContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;

  & > span:first-child {
    margin-bottom: 3px;
  }
`;

const StyledPrice = styled(StyledTitle)`
  font-size: 30px;
`;

const StyledPageHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
`;

const StyledHeaderActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
  justify-content: flex-start;
  flex: 1;

  width: 100%;
`;

export interface StoreSaleListState {
  isShowFilters: boolean;
  isShowStoreWarning: boolean;
  documentStoreName: string;
}

export function StoreSaleListPage({}: IStoreDocumentSaleListPageProps): JSX.Element {
  const navigate = useNavigate();
  const {defaultCompanyUuid, defaultCompany} = useStoredCompanies();
  const {t} = useTranslation();
  const theme: any = useTheme();
  const {profile} = useStoredProfile();

  const contentRef = useRef(null);
  const {width: contentWidth} = useSize(contentRef);

  const once = useRef(false);

  const serializeStoreData = localStorage.getItem(
    LocalStorageItems.saleFormStoreData,
  );

  const {
    defaultPriceMargin,
    storeDocument,

    handlePaymentStoreDocument,
  } = useStoreSale();

  const {
    documentStoreName,
    isShowFilters,
    isShowStoreWarning,

    handleUpdate: updateSaleContentState,
  } = useStateReducer<StoreSaleListState>({
    isShowFilters: false,
    isShowStoreWarning: false,
    documentStoreName: serializeStoreData?.includes('store_name')
      ? JSON.parse(serializeStoreData)?.store_name
      : '',
  });

  const {
    isFocus,
    focus,
    daleyFocus,
    initInput,
    keyboardShow,
    handleToggleKeyboard,
  } = useKeyboard();

  const {
    visible: storeConductFormVisible,
    handleSuccess: storeConductFormHandleSuccess,
    handleCancel: storeConductFormHandleCancel,
    handleOnInit: storeConductFormHandleOnInit,
  } = useModal({
    onSuccess: async (value: any) => {
      await handlePaymentStoreDocument(value);
      daleyFocus(800);
    },
    onCancel: () => daleyFocus(800),
  });

  const {
    handleOnInit: realizationHandleOnInit,
    visible: realizationVisible,
    ...realizationModalProps
  } = useModal();

  const {associations} =
    useStateIntegrationListByCategory<IntegrationCheckboxAttributesDTO>({
      companyUuid: defaultCompanyUuid,
      categoryType: IntegrationCategoryType.PRRO,
      limit: 100,
      loadOnInit: true,
    });

  const isShowFiscalCashboxesWarning = useMemo(
    () => !associations?.length,
    [associations?.length],
  );

  const toggleFilters = useCallback(() => {
    updateSaleContentState((prevState) => ({
      isShowFilters: !prevState.isShowFilters,
    }));
    focus();
  }, [focus, updateSaleContentState]);

  const navigateToCategoryGUIPage = useCallback(() => {
    navigate(`${Routes.categories}`);
  }, [navigate]);

  const routes = useBreadcrumb([
    {
      path: `/${Routes.stores}/${Routes.storeSale}`,
      breadcrumbName: 'Sales',
    },
  ]);

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.companies}/${defaultCompanyUuid}`);
  }, [defaultCompanyUuid, navigate]);

  useEffect(() => {
    if (!once.current) {
      once.current = true;
      initInput('store-sell-product-select');
    }
  }, [initInput]);

  return (
    <ListLayout
      isFocusKeyboard={isFocus}
      withKeyboard={keyboardShow}
      showWarning={false}
      withPaddingBottom
      ref={contentRef}
      aclItem={RoutesAcl[Routes.stores]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={
        <StyledPageHeader>
          <StyledTitle fontSize={20} fontWeight={400}>
            {textToUpperCase(t(RoutesTitle[RoutesType.storeSale]))}
          </StyledTitle>
          <StoreSaleCashBoxSelect daleyFocus={daleyFocus} />
        </StyledPageHeader>
      }
      routes={routes}
      headerExtra={
        <StyledHeader>
          <StoreSaleProductListSelect
            disabled={isShowStoreWarning}
            handleSearchSelectDaleyFocus={daleyFocus}
            storeConductFormHandleOnInit={storeConductFormHandleOnInit}>
            <Button
              fitContent
              type="text"
              onClick={() => {
                handleToggleKeyboard();
                focus();
              }}>
              {keyboardShow ? (
                <TbKeyboard size={20} color={theme.colors.black} />
              ) : (
                <TbKeyboardOff size={20} color={theme.colors.black} />
              )}
            </Button>
            <Button fitContent type="text" onClick={navigateToCategoryGUIPage}>
              <TbCategory2 size={20} color={theme.colors.black} />
            </Button>
          </StoreSaleProductListSelect>
          <Description $visible={isShowFilters}>
            {`${
              documentStoreName ? `${t('Store')}: "${documentStoreName}"; ` : ''
            }${
              defaultPriceMargin
                ? `${t('Price margin')}: "${defaultPriceMargin?.price_name}"; `
                : ''
            }${
              profile?.defaultEmployeeModel?.fullName
                ? `${t('Cashier')}: "${
                    profile?.defaultEmployeeModel?.fullName
                  }"`
                : ''
            }`}
          </Description>

          <StyledHeaderActionContainer>
            <StyledButton type="text" onClick={toggleFilters}>
              <IoMdSettings size={20} color={theme.colors.primary} />
            </StyledButton>

            {isShowStoreWarning ? (
              <StyledDescription
                $color={theme.colors.error}
                style={{whiteSpace: 'unset'}}>
                {t('To search for goods, you need to select a warehouse')}
              </StyledDescription>
            ) : null}

            <StyledPriceContainer>
              <StyledTitle fontSize={16} bold>{`${t('Total')}: `}</StyledTitle>
              <StyledPrice>
                {`${defaultCompany?.currency_symbol || ''} ${correctPrice(
                  storeDocument?.doc_sum_total || 0,
                )}`}
              </StyledPrice>
            </StyledPriceContainer>
          </StyledHeaderActionContainer>
        </StyledHeader>
      }
      empty={null}
      outsideFooterContent={
        <StoreSaleBottomView
          contentWidth={contentWidth}
          onConductButtonClick={storeConductFormHandleOnInit}
          realizationHandleOnInit={realizationHandleOnInit}
        />
      }
      loading={false}>
      <StoreSaleContent
        contentWidth={contentWidth}
        isShowFiscalCashboxesWarning={isShowFiscalCashboxesWarning}
        focus={focus}
        daleyFocus={daleyFocus}
        isShowFilters={isShowFilters}
        updateSaleContentState={updateSaleContentState}
        storeConductFormVisible={storeConductFormVisible}
        storeConductFormHandleSuccess={storeConductFormHandleSuccess}
        storeConductFormHandleCancel={storeConductFormHandleCancel}
        realizationVisible={realizationVisible}
        {...realizationModalProps}
      />
    </ListLayout>
  );
}
