import {WithTranslation} from 'react-i18next';
import {apiGet, apiPost} from '../core/api';
import {contentApiUrl} from '../const';
import {FINANCIAL_REPORTING, PERIOD_MOVEMENT_FUNDS} from '../helpers';
import {
  CompanySettingsDTO,
  CompanyMapper,
  IAlphaNameDTOProps,
  IFinancialSettingsDTOProps,
  ICompanySettingsDTOProps,
  FinancialSettingsDTO,
  CURRENCY_SYMBOL,
  NOTIFICATION_LANGUAGE,
  FinancialSettingsMapper,
  CompanyOrderBonusType,
} from '../../struture';

import {ApiAnswer} from '../types';

export interface IFinancialReliabilityParam
  extends Omit<Partial<FinancialSettingsDTO>, 'uuid'> {
  companyUuid?: string;
}

export interface CompanySettingsAnswer extends ApiAnswer {
  alfa_name_order: IAlphaNameDTOProps;
  settings: IFinancialSettingsDTOProps;
}

export const FINANCIAL_RELIABILITY_INITIAL_PARAM: Partial<FinancialSettingsDTO> =
  {
    period_of_financial_statement: PERIOD_MOVEMENT_FUNDS.Day,
    period_of_payments_movement: FINANCIAL_REPORTING.Day,
    financial_client_uuid: '',
    company_currency_symbol: CURRENCY_SYMBOL.Ukraine,
    company_notification_language: NOTIFICATION_LANGUAGE.Ukrainian,
  };

export const getPeriodMovementData = ({t}: Pick<WithTranslation, 't'>) => [
  {
    uuid: PERIOD_MOVEMENT_FUNDS.Day,
    title: t('Current day'),
  },
  {
    uuid: PERIOD_MOVEMENT_FUNDS.Week,
    title: t('Week'),
  },
  {
    uuid: PERIOD_MOVEMENT_FUNDS.Two_week,
    title: t('Two_week'),
  },
  {
    uuid: PERIOD_MOVEMENT_FUNDS.Month,
    title: t('Month'),
  },
];

export const getFinancialReportingData = ({t}: Pick<WithTranslation, 't'>) => [
  {
    uuid: FINANCIAL_REPORTING.Day,
    title: t('End of working day'),
  },
  {
    uuid: FINANCIAL_REPORTING.Week,
    title: t('End of the working week'),
  },
  {
    uuid: FINANCIAL_REPORTING.Month,
    title: t('The end of the current month'),
  },
];

export const SETTINGS_NOTIFICATION_LANGUAGE_LIST = [
  {
    uuid: NOTIFICATION_LANGUAGE.Ukrainian,
    title: 'Ukrainian',
  },
  {
    uuid: NOTIFICATION_LANGUAGE.English,
    title: 'English',
  },
];

export const COMPANY_ORDER_BONUS_TYPE: (currencySymbol: string) => {
  uuid: CompanyOrderBonusType;
  title: CompanyOrderBonusType;
  description: string;
}[] = (currencySymbol) => [
  {
    uuid: CompanyOrderBonusType.Percent,
    title: CompanyOrderBonusType.Percent,
    description: '%',
  },
  {
    uuid: CompanyOrderBonusType.Fixed,
    title: CompanyOrderBonusType.Fixed,
    description: currencySymbol,
  },
];

export const SETTINGS_CURRENCY_SYMBOL = (t: any) => [
  {
    uuid: CURRENCY_SYMBOL.Ukraine,
    title: `${t('Ukrainian hryvnia')} (${CURRENCY_SYMBOL.Ukraine})`,
  },
  // {
  //   uuid: CURRENCY_SYMBOL.English,
  //   title: `${t('American dollar')} (${CURRENCY_SYMBOL.English})`,
  // },
];

export interface FinancialReliabilityAnswer extends ApiAnswer {
  settings: ICompanySettingsDTOProps;
}

export interface UpdateFinancialReliabilityAnswer extends ApiAnswer {
  settings: IFinancialSettingsDTOProps;
}

export async function getFinancialReliability(
  companyUuid: string,
): Promise<CompanySettingsDTO> {
  const {settings} = await apiGet<null, FinancialReliabilityAnswer>(
    `${contentApiUrl}/companies/${companyUuid}/settings`,
  );

  return CompanyMapper.toCompanySettingsDTO(settings);
}

export async function updateFinancialReliability({
  price_round,
  quantity_round,
  period_of_financial_statement,
  period_of_payments_movement,
  financial_client_uuid,
  company_currency_symbol,
  company_notification_language,
  company_order_bonuses,
  companyUuid,
}: IFinancialReliabilityParam): Promise<FinancialSettingsDTO> {
  const {settings} = await apiPost<
    Omit<Partial<IFinancialReliabilityParam>, 'company_order_bonuses'> & {
      company_order_bonuses?: string;
    },
    UpdateFinancialReliabilityAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/settings`, {
    price_round,
    quantity_round,
    period_of_financial_statement,
    period_of_payments_movement,
    financial_client_uuid,
    company_currency_symbol,
    company_notification_language,
    ...(company_order_bonuses
      ? {company_order_bonuses: JSON.stringify(company_order_bonuses)}
      : {}),
  });

  return FinancialSettingsMapper.toFinancialSettings(settings);
}

export async function getCompanySettings(
  companyUuid: string,
  token?: string,
): Promise<CompanySettingsDTO> {
  const {settings, alfa_name_order} = await apiGet<null, CompanySettingsAnswer>(
    `${contentApiUrl}/companies/${companyUuid}/settings`,
    null,
    // {ignoreRedirect: true},
    {},
    token ? {Authorization: `Token ${token}`} : {},
  );

  return CompanyMapper.toCompanySettingsDTO({settings, alfa_name_order});
}
