import * as React from 'react';
import {getPaymentById} from '../services/api/companyPayments';
import {PaymentModel, PaymentMapper, PaymentDTO} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {IEntityProps} from '../services/types';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../contex';

export interface IUsePaymentProps extends IEntityProps {
  paymentUuid: string;
}

export interface IUsePaymentReturnType {
  error: null | any;
  loading: boolean;
  entity: PaymentModel | null;
  refresh: () => Promise<PaymentModel | void>;
  paymentUuid: string;
}

export function usePayment(
  {loadOnInit = true, paymentUuid}: IUsePaymentProps = {} as IUsePaymentProps,
): IUsePaymentReturnType {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const {cancellablePromise, didCancel} = useCancellablePromise();
  const {
    entity,
    loading,
    error,
    handleUpdate,
    paymentUuid: entityUuid,
  } = useStateReducer<Omit<IUsePaymentReturnType, 'refresh'>>({
    entity: null,
    paymentUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading,
      paymentUuid = entityUuid,
    }: Partial<IUsePaymentProps> = {}): Promise<PaymentModel | void> => {
      try {
        handleUpdate({loading: showLoading, error: null, paymentUuid});

        const payment = await cancellablePromise<PaymentDTO>(
          getPaymentById(paymentUuid),
        );

        const paymentModel = PaymentMapper.toPaymentModel(payment);

        if (!didCancel.current) {
          handleUpdate({
            entity: paymentModel,
            loading: false,
          });
          return paymentModel;
        }
      } catch (err: any) {
        if (!didCancel.current) {
          handleUpdate({
            error: err,
            loading: false,
          });
        }

        alert(
          'error',
          t('Payment'),
          `${t('An error occurred during get payment')} : ${error?.message}`,
        );
      }
    },

    [
      alert,
      cancellablePromise,
      didCancel,
      entityUuid,
      error?.message,
      handleUpdate,
      t,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && paymentUuid) {
      (async () => {
        await refresh({paymentUuid});
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, paymentUuid]);

  return {
    error,
    loading,
    refresh,
    entity,
    paymentUuid,
  };
}
