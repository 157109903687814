export class CanceledPromiseError extends Error {
  public isCanceled: boolean;

  public message: string;

  constructor(message: string = 'Cancel call promise') {
    super();
    Object.setPrototypeOf(this, CanceledPromiseError.prototype);
    this.message = message;
    this.isCanceled = true;
  }
}
