import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';

export default withTranslation()(GoodsListPage);

export interface IGoodsListPageProps extends WithTranslation {}

function GoodsListPage({}: IGoodsListPageProps): JSX.Element {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <p style={{fontSize: 30}}>Goods List Page</p>
    </div>
  );
}
