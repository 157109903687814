import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {IDefaultFormProps, DefaultForm} from '../../../components/lib/General';
import {PAYMENT_METHOD, PaymentFormDTO} from '../../../struture';
import {useDropdownAlert} from '../../../contex';
import {
  useStateCompanyClientList,
  useStoredCashBoxList,
  useStoredCompanies,
} from '../../../hooks';
import {PaymentCashBoxFields} from './PaymentCashBoxFields';
import {produce} from 'immer';
import {isFunction} from '@sportix/sportix-common-modules';

export interface IPaymentCashBoxFormProps
  extends Omit<
    IDefaultFormProps<PaymentFormDTO, PaymentFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  payment: PaymentFormDTO;
}

export function PaymentCashBoxForm({
  loading,
  editMode,
  payment,
  onSuccess,
  ...rest
}: IPaymentCashBoxFormProps) {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const {defaultCompanyUuid} = useStoredCompanies();

  const additionalValues = useMemo(
    () => ({
      uuid: payment?.uuid,
    }),
    [payment?.uuid],
  );

  const {
    clients,
    loading: clientsLoading,
    handleSearchClients,
    handleCreateLocalClient,
  } = useStateCompanyClientList({
    companyUuid: defaultCompanyUuid,
    loadOnInit: true,
  });

  const {
    cashBoxList,
    loading: cashBoxListLoading,
    handleSearchCashBoxes,
  } = useStoredCashBoxList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Payment'),
        `${
          editMode
            ? t('An error occurred during edit payment cash box')
            : t('An error occurred during create payment cash box')
        } : ${apiError?.message}`,
      );
    },
    [editMode, t, alert],
  );

  const handleSuccess = useCallback(
    async (value: PaymentFormDTO) => {
      const updateValue = produce(value, (draft) => {
        if (draft?.payment_method !== PAYMENT_METHOD.Account) {
          draft.payment_agent_uuid = '';
        } else {
          draft.payment_agent_uuid = (draft?.payment_agent_uuid as any)?.uuid;
          draft.payment_cashbox_uuid = '';
        }

        draft.payment_cashier_uuid = '';
        draft.payment_group_uuid = '';
      });

      if (isFunction(onSuccess)) {
        await onSuccess(updateValue);
      }
    },
    [onSuccess],
  );

  return (
    <DefaultForm<any, any>
      withContext
      initialValues={{
        ...payment,
      }}
      isResetLoading={false}
      editMode={editMode}
      showNotify={false}
      notifyError={notifyError}
      additionalValuesRequest={additionalValues}
      submitButtonText={t('Update')}
      onSuccess={handleSuccess}
      {...rest}>
      <PaymentCashBoxFields
        clients={clients}
        clientsLoading={clientsLoading}
        handleSearchClients={handleSearchClients}
        handleCreateLocalClient={handleCreateLocalClient}
        cashBoxList={cashBoxList}
        cashBoxListLoading={cashBoxListLoading}
        handleSearchCashBoxes={handleSearchCashBoxes}
      />
    </DefaultForm>
  );
}
