import {WithTranslation, withTranslation} from 'react-i18next';
import React, {SetStateAction, useCallback, useEffect, useState} from 'react';
import {Button, Spin, Steps, Tabs} from 'antd';
import classNames from 'classnames';
import {ButtonProps} from 'antd/lib/button';
import {
  isFunction,
  getIndexByKey,
  inc,
  decr,
  neq,
} from '../../../services/helpers';
import {useDefaultForm} from '../../../contex';
import {Message, MessageType} from '../DataDisplay';

import './Wizard.less';

export default withTranslation()(WizardV2);

export interface ChangeValue {
  newStepIndex: number;
  currentIndex: number;
  isValidate?: boolean;
}

export interface ChangeStepValue extends Omit<ChangeValue, 'currentIndex'> {
  currentIndex?: number;
}

export type Func = () => void;
export type AsyncFunc = () => Promise<void>;
export type FuncFinish = (currentStepIndex: number) => void;
export type AsyncFuncFinish = (currentStepIndex: number) => Promise<void>;
export type FuncStepChange = (value: ChangeValue) => void;
export type AsyncFuncStepChange = (value: ChangeValue) => Promise<void>;

export interface IWizardV2Props extends WithTranslation {
  activeKey: string;
  activeIndex?: number;
  setActiveIndex?: React.Dispatch<SetStateAction<number>>;
  nextButtonText?: string;
  cancelButtonText?: string;
  doneButtonText?: string;
  previousButtonText?: string;
  isTabs?: boolean;
  isClickableSteps?: boolean;
  loading?: boolean;
  nextButtonDisabled?: boolean;
  showActiveStepTitleOnly?: boolean;
  enableCancel?: boolean;
  saveMode?: boolean;
  onCancel?: Func | AsyncFunc;
  onFinish?: FuncFinish | AsyncFuncFinish;
  onStepChange?: FuncStepChange | AsyncFuncStepChange;
  onBeforeStepChanging?: FuncStepChange | AsyncFuncStepChange;
  cancelButtonProps?: ButtonProps;
  doneButtonProps?: ButtonProps;
  nextButtonProps?: ButtonProps;
  previousButtonProps?: ButtonProps;
  footerExtra?: React.ReactNode;
  errorFields: string[];
  children: any;
  headerMessage?: string;
  headerMessageType?: MessageType;
  submitDisabled?: boolean;
}

/**
 * @desc Wizard control
 * @param activeKey
 * @param isTabs
 * @param cancelButtonText
 * @param isClickableSteps
 * @param doneButtonText
 * @param finishButtonProps
 * @param footerExtra
 * @param loading
 * @param nextButtonDisabled
 * @param nextButtonText
 * @param nextButtonProps
 * @param onCancel
 * @param onFinish
 * @param onStepChange
 * @param onBeforeStepChanging
 * @param previousButtonText
 * @param rest
 * @param saveMode
 * @param showActiveStepTitleOnly
 // * @param steps
 * @returns {*}
 * @constructor
 */
function WizardV2({
  t,
  activeKey,
  isTabs = false,
  cancelButtonText,
  cancelButtonProps,
  isClickableSteps,
  doneButtonText,
  doneButtonProps,
  footerExtra,
  loading = false,
  nextButtonDisabled,
  nextButtonText,
  nextButtonProps,
  onCancel,
  onFinish,
  onStepChange,
  onBeforeStepChanging,
  previousButtonText,
  previousButtonProps,
  saveMode = false,
  showActiveStepTitleOnly,
  enableCancel,
  errorFields,
  children,
  headerMessage,
  headerMessageType,
  submitDisabled,
  activeIndex,
  setActiveIndex,
  ...rest
}: IWizardV2Props) {
  const {valid, loadingSubmit, formData} = useDefaultForm();

  const steps: any = React.Children.toArray(children).filter(
    ({props}: any) => !props?.hidden,
  );

  const [currentKey, setCurrentKey] = useState<string>(`.$${activeKey}`);
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

  const getIndexByKeySteps = useCallback(
    (key: string, steps: any[]) => getIndexByKey(steps, key),
    [],
  );

  const onCurrentStepIndexChange = useCallback(
    (key: string, steps: any[]) => {
      setCurrentStepIndex((prevState) => {
        const activeIndex = getIndexByKeySteps(key, steps);

        return neq(prevState, activeIndex) ? activeIndex : prevState;
      });
    },
    [getIndexByKeySteps],
  );

  const onCurrentKeyChange = useCallback(
    (index: number): void => {
      if (isFunction(setActiveIndex)) {
        setActiveIndex(index);
      }
      setCurrentKey((prevState) => {
        const activeKey = steps[index]?.key;

        if (neq(prevState, activeKey)) {
          onCurrentStepIndexChange(activeKey, steps);
          return activeKey;
        }

        return prevState;
      });
    },
    [setActiveIndex, steps, onCurrentStepIndexChange],
  );

  useEffect(() => {
    if (typeof activeIndex === 'number' && activeIndex !== currentStepIndex) {
      onCurrentKeyChange(activeIndex);
    }
  }, [activeIndex, currentStepIndex, onCurrentKeyChange]);

  const handleSelectStep = useCallback(
    async ({
      newStepIndex,
      currentIndex: forcedCurrentIndex,
      isValidate,
    }: ChangeStepValue): Promise<void> => {
      try {
        const currentIndex =
          forcedCurrentIndex !== undefined
            ? forcedCurrentIndex
            : currentStepIndex;

        const continues =
          !onBeforeStepChanging ||
          (onBeforeStepChanging &&
            (await onBeforeStepChanging({
              newStepIndex,
              currentIndex,
              isValidate,
            })));

        if (continues === undefined || continues) {
          onCurrentKeyChange(newStepIndex);

          if (isFunction(onStepChange)) {
            onStepChange({newStepIndex, currentIndex});
          }
        }
      } catch (e: any) {}
    },
    [currentStepIndex, onStepChange, onBeforeStepChanging, onCurrentKeyChange],
  );

  const getStepProps = useCallback(
    (index = currentStepIndex) => steps[index]?.props || {},
    [currentStepIndex, steps],
  );

  const handleNextClick = useCallback(
    async (stepIndex: number): Promise<void> =>
      await handleSelectStep({newStepIndex: inc(stepIndex)}),
    [handleSelectStep],
  );

  const handlePreviousClick = useCallback(
    async (stepIndex: number): Promise<void> =>
      await handleSelectStep({
        newStepIndex: decr(stepIndex),
        isValidate: false,
      }),
    [handleSelectStep],
  );

  const handleFinishClick = useCallback(async () => {
    if (isFunction(onFinish)) {
      onFinish(currentStepIndex);
    }
  }, [currentStepIndex, onFinish]);

  const handleStepClick = useCallback(
    async (newStepIndex: number) => {
      if (isClickableSteps) {
        await handleSelectStep({
          newStepIndex,
          isValidate: newStepIndex > currentStepIndex,
        });
      }
    },
    [isClickableSteps, handleSelectStep, currentStepIndex],
  );

  const handleTabClick = useCallback(
    async (key: string) => {
      const newStepIndex = getIndexByKeySteps(key, steps);
      await handleSelectStep({
        newStepIndex,
        isValidate: newStepIndex > currentStepIndex,
      });
    },
    [handleSelectStep, steps, currentStepIndex, getIndexByKeySteps],
  );

  const handleCancelClick = useCallback(() => {
    if (isFunction(onCancel)) {
      onCancel();
    }
  }, [onCancel]);

  const actions = saveMode ? (
    <>
      <Button
        type="text"
        className="Wizard-step-actions--cancel"
        disabled={loading || loadingSubmit}
        onClick={handleCancelClick}
        {...cancelButtonProps}>
        {cancelButtonText || t('Cancel')}
      </Button>
      <Button
        className="Wizard-step-actions--submit"
        type="primary"
        htmlType="submit"
        loading={loading || getStepProps().loading || loadingSubmit}
        disabled={
          nextButtonDisabled ||
          getStepProps().nextButtonDisabled ||
          loadingSubmit
        }
        onClick={handleFinishClick}
        {...doneButtonProps}>
        {doneButtonText || t('Save')}
      </Button>
    </>
  ) : (
    <>
      {currentStepIndex === 0 && enableCancel && (
        <Button
          type="text"
          className="Wizard-step-actions--cancel"
          disabled={loading || loadingSubmit}
          onClick={
            isFunction(cancelButtonProps?.onClick)
              ? () => {
                  (cancelButtonProps?.onClick as any)(formData);
                  handleCancelClick();
                }
              : handleCancelClick
          }
          {...cancelButtonProps}>
          {cancelButtonText || t('Cancel')}
        </Button>
      )}
      {currentStepIndex > 0 && (
        <Button
          type="text"
          className="Wizard-step-actions--previous"
          disabled={loading || loadingSubmit}
          onClick={() => handlePreviousClick(currentStepIndex)}
          {...previousButtonProps}>
          {previousButtonText || t('Back')}
        </Button>
      )}
      {currentStepIndex < steps.length - 1 && (
        <Button
          className="Wizard-step-actions--next"
          type="primary"
          loading={loading || getStepProps().loading || loadingSubmit}
          disabled={
            nextButtonDisabled ||
            getStepProps().nextButtonDisabled ||
            loadingSubmit
          }
          onClick={() => handleNextClick(currentStepIndex)}
          {...nextButtonProps}>
          {nextButtonText || t('Continue')}
        </Button>
      )}
      {currentStepIndex === steps.length - 1 && (
        <Button
          className="Wizard-step-actions--submit"
          type="primary"
          htmlType="submit"
          loading={loading || getStepProps().loading || loadingSubmit}
          disabled={
            nextButtonDisabled ||
            submitDisabled ||
            getStepProps().nextButtonDisabled ||
            loadingSubmit
          }
          onClick={handleFinishClick}
          {...doneButtonProps}>
          {doneButtonText || t('Finish')}
        </Button>
      )}
    </>
  );

  return (
    <Spin spinning={loading}>
      {valid ? (
        <div className="Wizard">
          {headerMessage && (
            <div className="Wizard-message">
              <Message
                message={headerMessage}
                messageType={headerMessageType}
              />
            </div>
          )}
          <div className="Wizard-step-header">
            {isTabs ? (
              <Tabs activeKey={currentKey} onTabClick={handleTabClick}>
                {steps.map(
                  ({key, props}: any): JSX.Element => (
                    <React.Fragment key={key}>
                      {props?.hidden ? null : (
                        <Tabs.TabPane
                          tab={
                            <p
                              className={classNames('Wizard-step-tabs', {
                                'Wizard-step-tabs--error':
                                  errorFields.includes(key),
                              })}>
                              {props.title}
                            </p>
                          }
                        />
                      )}
                    </React.Fragment>
                  ),
                )}
              </Tabs>
            ) : (
              <Steps current={currentStepIndex}>
                {steps.map((step: any, stepIndex: number) => (
                  <React.Fragment key={step.key}>
                    {step?.props?.hidden ? null : (
                      <Steps.Step
                        className={classNames('Wizard-step-header_item', {
                          'Wizard-step-header_item--active':
                            showActiveStepTitleOnly &&
                            currentStepIndex === stepIndex,
                          'Wizard-step-header_item--clickable':
                            isClickableSteps,
                        })}
                        status={
                          errorFields.includes(step?.key) ? 'error' : undefined
                        }
                        title={
                          (showActiveStepTitleOnly &&
                            currentStepIndex === stepIndex) ||
                          !showActiveStepTitleOnly
                            ? step?.props?.title || ''
                            : ''
                        }
                        onClick={() => handleStepClick(stepIndex)}
                      />
                    )}
                  </React.Fragment>
                ))}
              </Steps>
            )}
          </div>

          <div className="Wizard-step-body Wizard-full-height">
            {steps.map((child: any) => (
              <React.Fragment key={child?.key}>
                {child?.props?.hidden ? null : (
                  <div
                    className={classNames('Wizard-step-content', {
                      'Wizard-step-content--hidden': currentKey === child?.key,
                    })}>
                    {React.isValidElement(child)
                      ? React.cloneElement(child, {
                          isVisible: currentKey === child?.key,
                          handleNextClick: () =>
                            handleNextClick(currentStepIndex),
                          handlePreviousClick: () =>
                            handlePreviousClick(currentStepIndex),
                        } as any)
                      : child}
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>

          <div className="Wizard-step-footer">
            <div className="Wizard-step-footer-extra">
              {footerExtra}
              {getStepProps().footerExtra}
            </div>
            <div className="Wizard-step-actions">{actions}</div>
          </div>
        </div>
      ) : null}
    </Spin>
  );
}

export const WizardStepV2 = ({
  children,
  loading,
  isVisible,
  id,
  handleNextClick,
  handlePreviousClick,
}: any) => {
  const {valid} = useDefaultForm();
  return (
    <Spin spinning={loading}>
      <div id={id}>
        {valid
          ? React.isValidElement(children)
            ? isVisible &&
              React.cloneElement(children, {
                isVisible,
                handleNextClick,
                handlePreviousClick,
              } as any)
            : children
          : null}
      </div>
    </Spin>
  );
};
