import {List} from 'immutable';
import {
  ScheduleCalendarDTO,
  IScheduleCalendarDTOProps,
  ScheduleCalendarModel,
  ScheduleCalendarListModel,
  ScheduleCalendarFormDTO,
  ScheduleCloseDTO,
  AgendaItemDTO,
  ScheduleChangeTimeFormDTO,
  ClientAbonementModel,
  ClientAbonementScheduleFormDTO,
  ScheduleMessageFormDTO,
  IScheduleMessageFormAdditionalProps,
  IScheduleCloseAdditionalParams,
  IScheduleCalendarStatsDTOProps,
  ScheduleCalendarStatsDTO,
} from '../internal';
import {listToArray} from '../../services/helpers';

import {WithoutRecursion} from '../type';

export interface IScheduleStatsDTO {
  files: string;
}
export interface IScheduleCalendarListDTO {
  schedules: ScheduleCalendarDTO[];
  total: number;
  stats: ScheduleCalendarStatsDTO;
}

export class ScheduleCalendarMapper {
  public static toScheduleCalendarDTO(
    schedule: ScheduleCalendarModel | IScheduleCalendarDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): ScheduleCalendarDTO {
    return new ScheduleCalendarDTO(schedule, [
      WithoutRecursion.scheduleCalendar,
      ...withoutRecursion,
    ]);
  }

  public static toScheduleCalendarListDTO(
    schedules: List<ScheduleCalendarModel> | IScheduleCalendarDTOProps[],
    total?: number,
    withoutRecursion: WithoutRecursion[] = [],
    stats?: ScheduleCalendarStatsDTO,
  ): IScheduleCalendarListDTO {
    const scheduleList = List.isList(schedules)
      ? listToArray<ScheduleCalendarModel>(schedules)
      : schedules;

    return {
      schedules: scheduleList?.map((schedule) =>
        ScheduleCalendarMapper.toScheduleCalendarDTO(schedule, [
          WithoutRecursion.scheduleCalendar,
          ...withoutRecursion,
        ]),
      ),
      total: total || 0,
      stats: stats || ({} as ScheduleCalendarStatsDTO),
    };
  }

  public static toScheduleCalendarModel(
    scheduleDTO: ScheduleCalendarDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): ScheduleCalendarModel {
    return new ScheduleCalendarModel(scheduleDTO, [
      WithoutRecursion.scheduleCalendar,
      ...withoutRecursion,
    ]);
  }

  public static toScheduleCalendarListModel(
    scheduleDTOs: ScheduleCalendarDTO[],
    total?: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): ScheduleCalendarListModel {
    return new ScheduleCalendarListModel({schedules: scheduleDTOs, total}, [
      WithoutRecursion.scheduleCalendar,
      ...withoutRecursion,
    ]);
  }

  public static toScheduleCalendarFormDTO(
    scheduleModel?: ScheduleCalendarModel,
    editMode?: boolean,
  ): ScheduleCalendarFormDTO {
    return new ScheduleCalendarFormDTO(scheduleModel, editMode);
  }

  public static toScheduleCloseDTO(
    schedule: ScheduleCalendarModel,
    additionalProps: IScheduleCloseAdditionalParams,
  ): ScheduleCloseDTO {
    return new ScheduleCloseDTO(schedule, additionalProps);
  }

  public static toScheduleChangeTimeFormDTO(
    scheduleModel?: ScheduleCalendarModel,
    send_sms_notification?: boolean,
  ): ScheduleChangeTimeFormDTO {
    return new ScheduleChangeTimeFormDTO(scheduleModel, send_sms_notification);
  }

  public static toAgendaItemDTO(
    schedule: ScheduleCalendarModel,
  ): AgendaItemDTO {
    return new AgendaItemDTO(schedule);
  }

  public static toClientAbonementScheduleFormDTO(
    clientAbonement?: ClientAbonementModel,
    editMode?: boolean,
  ): ClientAbonementScheduleFormDTO {
    return new ClientAbonementScheduleFormDTO(clientAbonement, editMode);
  }

  public static toScheduleMessageFormDTO(
    schedule?: ScheduleCalendarModel,
    additionalProps: IScheduleMessageFormAdditionalProps = {},
  ): ScheduleMessageFormDTO {
    return new ScheduleMessageFormDTO(schedule, additionalProps);
  }

  public static toScheduleCalendarStatsDT(
    stats?: IScheduleCalendarStatsDTOProps,
  ): ScheduleCalendarStatsDTO {
    return new ScheduleCalendarStatsDTO(stats);
  }
}
