import {List} from 'immutable';
import {
  RelatedServiceDTO,
  RelatedServiceFormDTO,
  IRelatedServiceDTOProps,
  RelatedServiceModel,
  RelatedServiceListModel,
} from '../internal';
import {listToArray} from '../../services/helpers';
import {WithoutRecursion} from '../type';

export interface IRelatedServiceListDTO {
  services: RelatedServiceDTO[];
  total: number;
}

export class RelatedServiceMapper {
  public static toRelatedServiceDTO(
    props: RelatedServiceModel | IRelatedServiceDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): RelatedServiceDTO {
    return new RelatedServiceDTO(
      {
        uuid: props?.uuid,
        schedule_service_quantity: props?.schedule_service_quantity,
        schedule_service_price: props?.schedule_service_price,
        schedule_service_price_total: props?.schedule_service_price_total,
        schedule_comment: props?.schedule_comment,
        created_at: props?.created_at,
        updated_at: props?.updated_at,
        service: props?.service,
      },
      [WithoutRecursion.relatedService, ...withoutRecursion],
    );
  }

  public static toRelatedServiceListDTO(
    services: List<RelatedServiceModel> | IRelatedServiceDTOProps[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): IRelatedServiceListDTO {
    const serviceList = List.isList(services)
      ? listToArray<RelatedServiceModel>(services)
      : services;

    return {
      services: serviceList?.map((service) =>
        RelatedServiceMapper.toRelatedServiceDTO(service, [
          WithoutRecursion.relatedService,
          ...withoutRecursion,
        ]),
      ),
      total,
    };
  }

  public static toRelatedServiceModel(
    serviceDTO: RelatedServiceDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): RelatedServiceModel {
    return new RelatedServiceModel(serviceDTO, [
      WithoutRecursion.relatedService,
      ...withoutRecursion,
    ]);
  }

  public static toRelatedServiceListModel(
    serviceDTOs: RelatedServiceDTO[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): RelatedServiceListModel {
    return new RelatedServiceListModel({services: serviceDTOs, total}, [
      WithoutRecursion.relatedService,
      ...withoutRecursion,
    ]);
  }

  public static toRelatedServiceFormDTO(
    serviceModel: RelatedServiceModel,
    editMode?: boolean,
  ): RelatedServiceFormDTO {
    return new RelatedServiceFormDTO(serviceModel, editMode);
  }
}
