import React, {ForwardedRef} from 'react';
import {Button} from 'antd';
import {CloseOutlined} from '@ant-design/icons';
import {calcMonthEventModalOffset, multiply} from '../../../services/helpers';

import './CalendarMonthColModal.less';

export default React.forwardRef(CalendarMonthColModal);

export interface ICalendarMonthColModalProps {
  children: React.ReactNode;
  parentRect: DOMRect;
  onClick: () => void;
}

function CalendarMonthColModal(
  {children, parentRect, onClick}: ICalendarMonthColModalProps,
  ref: ForwardedRef<any>,
) {
  return (
    <div
      ref={ref}
      className="month-col-modal"
      style={{
        width: multiply(parentRect.width, 1.2),
        left: -calcMonthEventModalOffset(parentRect.width, '10%'),
        top: -calcMonthEventModalOffset(parentRect.width, '10%'),
      }}>
      <Button
        type="text"
        onClick={onClick}
        className="month-col-modal-button-close">
        <CloseOutlined className="month-col-modal-button-close-icon" />
      </Button>
      {children}
    </div>
  );
}
