import * as React from 'react';
import {ApiError, IEntityProps, IListSearchProps} from '../services/types';
import {
  AbonementGroupMapper,
  AbonementGroupListModel,
  IAbonementGroupListDTO,
} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {getAbonementGroupList} from '../services/api/abonementGroup';
import {useDropdownAlert} from '../contex';
import {useTranslation} from 'react-i18next';

export interface IUseAbonementGroupListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  abonementUuid: string;
}

export interface IUseAbonementGroupListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: AbonementGroupListModel | null;
  refresh: (
    value: Partial<IUseAbonementGroupListProps>,
  ) => Promise<AbonementGroupListModel | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  abonementUuid: string;
}

export function useAbonementGroupList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    abonementUuid,
  }: IUseAbonementGroupListProps = {} as IUseAbonementGroupListProps,
): IUseAbonementGroupListReturnType {
  const {cancellablePromise} = useCancellablePromise();
  const {alert} = useDropdownAlert();
  const {t} = useTranslation();

  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    abonementUuid: listUuid,
    handleUpdate,
  } = useStateReducer<Omit<IUseAbonementGroupListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    abonementUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      abonementUuid = listUuid,
    }: Partial<IUseAbonementGroupListProps> = {}): Promise<AbonementGroupListModel | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          abonementUuid,
        });

        const {groups, total} =
          await cancellablePromise<IAbonementGroupListDTO>(
            getAbonementGroupList({limit, offset, keywords, abonementUuid}),
          );

        const abonementListModel =
          AbonementGroupMapper.toAbonementGroupListModel(groups, total);

        handleUpdate({
          entityList: abonementListModel,
          total: abonementListModel?.total,
          loading: false,
        });

        return abonementListModel;
      } catch (e: any) {
        alert(
          'error',
          t('Abonement groups'),
          `${t('An error occurred during get client abonement group list')} : ${
            e?.message
          }`,
        );

        handleUpdate({
          error,
          loading: false,
        });
      }
    },
    [
      listLimit,
      listOffset,
      listKeywords,
      listUuid,
      handleUpdate,
      cancellablePromise,
      alert,
      t,
      error,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && abonementUuid) {
      (async () => {
        await refresh({abonementUuid});
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, abonementUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    abonementUuid: listUuid,
  };
}
