import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Empty} from 'antd';
import {ListLayout} from '../../../components/lib/Layout';
import {
  StyledDescription,
  successButtonStyle,
} from '../../../components/lib/Styled';
import {
  ClientAbonementCreateButton,
  ClientAbonementsDeleteButton,
} from '../Buttons';
import {
  useClientsAbonementListFactory,
  useKeyboardOpenForm,
  useSearchInputFocus,
  useStoredClientAbonement,
  useStoredCompanies,
} from '../../../hooks';
import {len, lessOrEqualThan, toDateByFormat} from '../../../services/helpers';
import {
  ClientAbonementMapper,
  ClientAbonementModel,
  ClientAbonementStatusesText,
  ClientAbonementDTO,
  ClientMapper,
  ClientModel,
  AbonementMapper,
  AbonementModel,
  AbonementGroupMapper,
  AbonementGroupModel,
  ScheduleCalendarModel,
  ProfileModel,
} from '../../../struture';
import {Routes, RoutesAcl} from '../../../services/types';
import {Table, TableLinkCell} from '../../../components/lib/libV2/DataDisplay';
import {useAcl, useDropdownAlert} from '../../../contex';
import {
  DetailsItemView,
  HeaderLeft,
  ListActions,
} from '../../../components/lib/DataDisplay';
import {useLocation, useParams} from 'react-router';
import styled, {useTheme} from 'styled-components';
import {ClientAbonementStatusTag} from '../Show';
import {PlusOutlined} from '@ant-design/icons';

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledClientAbonementCreateButton = styled(ClientAbonementCreateButton)`
  ${successButtonStyle}
`;

export const ClientAbonementColors = (theme: any) => ({
  [ClientAbonementStatusesText.Initiated]: theme.colors.link,
  [ClientAbonementStatusesText.Active]: theme.colors.success,
  [ClientAbonementStatusesText.Paused]: theme.colors.yellow,
  [ClientAbonementStatusesText.Terminated]: theme.colors.warning,
  [ClientAbonementStatusesText.Blocked]: theme.colors.error,
  [ClientAbonementStatusesText.Transffered]: theme.colors.brown,
  [ClientAbonementStatusesText.Error]: theme.colors.error,
  [ClientAbonementStatusesText.ReturnedMoney]: '#c0aea1',
});

export const clientAbonementStatusesDescription = (
  abonement: ClientAbonementModel,
  t: (v: string) => string,
) => ({
  [ClientAbonementStatusesText.Initiated]: t('waiting for activation'),
  [ClientAbonementStatusesText.Active]: `${t('valid from')} ${toDateByFormat(
    abonement?.abon_start_date,
    'YYYY.MM.DD',
  )} ${t('to')} ${
    abonement?.abon_end_date
      ? toDateByFormat(abonement?.abon_end_date, 'YYYY.MM.DD')
      : t('unknown date')
  }`,
  [ClientAbonementStatusesText.Paused]: `${t('suspended from')} ${
    abonement?.abon_end_date
      ? toDateByFormat(abonement?.abon_end_date, 'YYYY.MM.DD')
      : t('unknown date')
  }`,
  [ClientAbonementStatusesText.Terminated]: `${t('has expired')} ${
    abonement?.abon_end_date
      ? toDateByFormat(abonement?.abon_end_date, 'YYYY.MM.DD')
      : t('unknown date')
  }`,
  [ClientAbonementStatusesText.Blocked]: `${t('blocked')} ${
    abonement?.abon_end_date
      ? toDateByFormat(abonement?.abon_end_date, 'YYYY.MM.DD')
      : t('unknown date')
  }`,
  [ClientAbonementStatusesText.Transffered]: t(
    'transferred to a new abonement',
  ),
  [ClientAbonementStatusesText.Error]: t('status formation error'),
  [ClientAbonementStatusesText.ReturnedMoney]: t('refund money'),
});

export function ClientAbonementListPage() {
  const location = useLocation();
  const {clientId, companyId, tariffModelId, tariffModelGroupId} = useParams();
  const {t} = useTranslation();
  const {manage} = useAcl(({abonement}) => abonement);
  const {alert} = useDropdownAlert();
  const {defaultCompany, defaultCompanyUuid} = useStoredCompanies();
  const theme = useTheme();
  const {ref: listRef} = useKeyboardOpenForm({
    className: 'client-abonement-create-event',
    disabled: !manage,
  });

  const [selectedClientAbonements, setSelectedClientAbonements] = useState<
    ClientAbonementModel[]
  >([]);

  const {
    abonementList,
    loading,
    limit: pageSize,
    total,
    keywords,
    page,
    title,
    routes,
    client,
    tariffModel,
    tariffModelGroup,

    disabledField,

    path,
    navigateGoBack,
    handleRefreshClientAbonements,
    handleDeleteClientAbonements: onDeleteClientAbonements,
    handleSearchClientAbonements,
  } = useClientsAbonementListFactory();

  const {inputRef, focus, daleyFocus} = useSearchInputFocus({loading});

  const handleDeleteClientAbonements = useCallback(
    async (value: string[]) => {
      await onDeleteClientAbonements(value);
      focus();
    },
    [focus, onDeleteClientAbonements],
  );

  const {handleCreateClientAbonement} = useStoredClientAbonement({
    clientUuid: clientId!,
    loadOnInit: false,
    abonementUuid: '',
    path: tariffModelGroupId
      ? (abonement: ClientAbonementModel) =>
          `/${Routes?.app}/${Routes.tariffModels}/${tariffModelId}/${Routes.tariffModelGroup}/${tariffModelGroupId}/${Routes.clientAbonements}/${abonement?.uuid}`
      : tariffModelId
      ? (abonement: ClientAbonementModel) =>
          `/${Routes?.app}/${Routes.tariffModels}/${tariffModelId}/${Routes.clientAbonements}/${abonement?.uuid}`
      : companyId
      ? (abonement: ClientAbonementModel) =>
          `/${Routes?.app}/${Routes.clientAbonements}/${abonement?.uuid}/${Routes.clients}/${abonement?.client?.uuid}/${Routes.companies}/${defaultCompanyUuid}`
      : undefined,
  });

  const rowSelection = useMemo(
    () => ({
      hideSelectAll: true,
      selectedRowKeys: selectedClientAbonements.map(({uuid}) => uuid),
      onChange: (
        selectedRowKeys: React.Key[],
        selectedRecords: ClientAbonementModel[],
      ) => {
        if (lessOrEqualThan(len(selectedRecords), 1)) {
          setSelectedClientAbonements(selectedRecords);
        } else {
          alert('error', t('Delete'), t('You can delete up to 1 at a time'));
        }
      },
    }),
    [alert, selectedClientAbonements, t],
  );

  const columns = [
    {
      title: t('Title'),
      key: 'title',
      render: (abonement: ClientAbonementModel) => (
        <TableLinkCell
          state={location?.state}
          tooltip={t('Open a detailed of the abonement')}
          fullPath={path(abonement)}>
          <StyledTitleContainer>
            {abonement?.companyAbonementModel?.abon_title}
            <ClientAbonementStatusTag
              status={abonement?.abon_status_text}
              title={abonement?.abon_status_text}
            />
            <StyledDescription
              $color={
                ClientAbonementColors(theme)[
                  abonement?.abon_status_text ||
                    ClientAbonementStatusesText.Initiated
                ]
              }>
              {
                clientAbonementStatusesDescription(abonement, t)[
                  abonement?.abon_status_text ||
                    ClientAbonementStatusesText.Initiated
                ]
              }
            </StyledDescription>
          </StyledTitleContainer>
        </TableLinkCell>
      ),
    },
    {
      title: t('Client'),
      key: 'client',
      render: (c: ScheduleCalendarModel) => (
        <DetailsItemView<ClientModel>
          item={clientId ? client! : c?.clientModel}
          fields={{
            fullNameClient: {
              link: 'Go to client details page',
              path: `/${Routes.app}/${Routes.clients}/${
                clientId ? client?.uuid : c?.clientModel?.uuid
              }`,
            },
            phone: {description: ''},
          }}
        />
      ),
    },
    {
      title: t('Created'),
      key: 'created',
      render: (c: ScheduleCalendarModel) => {
        return (
          <DetailsItemView<ProfileModel>
            item={c?.createdByModel}
            fields={{
              fullName: {
                title: '',
              },
              uuid: {
                description: (
                  <StyledDescription>
                    {toDateByFormat(c?.created_at, 'DD.MM.YYYY HH:mm')}
                  </StyledDescription>
                ),
              },
            }}
          />
        );
      },
    },
    {
      title: t('Price'),
      key: 'price',
      align: 'right' as any,
      render: (c: ClientAbonementModel) =>
        defaultCompany
          ? `${defaultCompany?.currency_symbol} ${
              c?.abon_final_price || '0.00'
            }`
          : '0.00',
    },
  ];

  const abonement = tariffModelGroupId
    ? ClientAbonementMapper.toClientAbonementFormDTO(
        ClientAbonementMapper.toClientAbonementModel({
          company_abonement_group: AbonementGroupMapper.toAbonementGroupDTO(
            (tariffModelGroup || {}) as AbonementGroupModel,
          ),
          company_abonement: AbonementMapper.toAbonementDTO(
            (tariffModelGroup?.company_abonement || {}) as AbonementModel,
          ),
        } as unknown as ClientAbonementDTO).abonement,
      )
    : tariffModel
    ? ClientAbonementMapper.toClientAbonementFormDTO(
        ClientAbonementMapper.toClientAbonementModel({
          company_abonement: AbonementMapper.toAbonementDTO(
            (tariffModel || {}) as AbonementModel,
          ),
        } as unknown as ClientAbonementDTO).abonement,
      )
    : ClientAbonementMapper.toClientAbonementFormDTO(
        ClientAbonementMapper.toClientAbonementModel({
          client: ClientMapper.toClientDTO((client || {}) as ClientModel),
        } as unknown as ClientAbonementDTO).abonement,
      );

  return (
    <ListLayout
      ref={listRef}
      moduleItem="abonement"
      aclItem={RoutesAcl[Routes.clientAbonements]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={title}
      headerRight={
        <>
          {selectedClientAbonements.length ? (
            <ClientAbonementsDeleteButton
              disabled={!manage}
              abonements={selectedClientAbonements}
              onSuccess={handleDeleteClientAbonements}
            />
          ) : (
            <ClientAbonementCreateButton
              disabledField={disabledField}
              abonement={abonement}
              disabled={!manage}
              loading={loading}
              onSuccess={handleCreateClientAbonement}
              className="client-abonement-create-event"
              onCancel={daleyFocus}
            />
          )}
        </>
      }
      routes={routes}
      empty={
        abonementList?.size ? null : (
          <Empty
            description={t(
              `It looks like you don't have any abonements at the moment.`,
            )}>
            <ClientAbonementCreateButton
              disabledField={disabledField}
              abonement={abonement}
              type="primary"
              disabled={!manage}
              loading={loading}
              onSuccess={handleCreateClientAbonement}
              onCancel={daleyFocus}>
              {t('Create client abonement')}
            </ClientAbonementCreateButton>
          </Empty>
        )
      }
      headerExtra={
        <ListActions
          withoutPicker={false}
          inputRef={inputRef}
          loading={loading}
          handleSearch={handleSearchClientAbonements}
          inputTooltip={t('Search client abonements')}
          inputLabel={t('Search client abonements')}
          searchText={keywords}
          withSearchContent={
            <StyledClientAbonementCreateButton
              disabled={!manage}
              loading={loading}
              type="primary"
              onSuccess={handleCreateClientAbonement as any}
              title={t('Create')}
              abonement={abonement}
              icon={<PlusOutlined />}
              onCancel={daleyFocus}
            />
          }
        />
      }
      loading={loading && !abonementList?.size}>
      <Table<ClientAbonementModel>
        rowSelection={rowSelection}
        total={total}
        pageSize={pageSize}
        dataSource={abonementList}
        onChange={handleRefreshClientAbonements}
        columns={columns}
        loading={loading}
        page={page}
      />
    </ListLayout>
  );
}
