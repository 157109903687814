import styled, {css} from 'styled-components';

export const successButtonStyle = css<{disabled?: boolean; loading?: boolean}>`
  background-color: ${({theme}) => theme.colors.success};

  &&&:hover,
  &&&:active,
  &&&:focus {
    background-color: ${({theme}) => theme.colors.success};
    opacity: 0.7;
  }

  ${({disabled, loading}) =>
    (disabled || loading) &&
    css`
      &&&:hover,
      &&&:active,
      &&&:focus {
        opacity: 1;
      }
    `}

  ${({disabled, loading}) =>
    (disabled || loading) &&
    css`
      &&& {
        display: flex !important;
      }

      &&& > * {
        color: ${({theme}) => theme.colors.white};
      }
    `}
`;

export const errorButtonStyle = css`
  background-color: ${({theme}) => theme.colors.error};

  &&&:hover,
  &&&:active,
  &&&:focus {
    background-color: ${({theme}) => theme.colors.error};
    opacity: 0.7;
  }
`;

export const StyledRowContainer = styled.div<{
  $align?: 'flex-start' | 'flex-end' | 'center';
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: ${({$align}) => $align || 'flex-end'};
  gap: 5px;

  width: 100%;
`;
