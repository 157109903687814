import {List} from 'immutable';
import {
  ClientAbonementModel,
  ClientVisitFormDTO,
  ClientVisitModel,
  IClientVisitDTOProps,
  ClientVisitDTO,
  ClientVisitListModel,
} from '../internal';
import {listToArray} from '../../services/helpers';
import {WithoutRecursion} from '../type';

export interface IClientVisitListDTO {
  visits: ClientVisitDTO[];
  total: number;
  abonement: ClientAbonementModel;
}

export class ClientVisitMapper {
  public static toClientVisitDTO(
    visit: ClientVisitModel | IClientVisitDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): ClientVisitDTO {
    return new ClientVisitDTO(visit, [
      WithoutRecursion.abonement,
      ...withoutRecursion,
    ]);
  }

  public static toClientVisitListDTO(
    visits: List<ClientVisitModel> | IClientVisitDTOProps[],
    abonement: ClientAbonementModel,
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): IClientVisitListDTO {
    const visitList = List.isList(visits)
      ? listToArray<ClientVisitModel>(visits)
      : visits;

    return {
      visits: visitList?.map((visit) =>
        ClientVisitMapper.toClientVisitDTO(visit, [
          WithoutRecursion.abonement,
          ...withoutRecursion,
        ]),
      ),
      abonement,
      total,
    };
  }

  public static toClientVisitModel(
    visitDTO: ClientVisitDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): ClientVisitModel {
    return new ClientVisitModel(visitDTO, [
      WithoutRecursion.visit,
      ...withoutRecursion,
    ]);
  }

  public static toClientVisitListModel(
    visitDTOs: ClientVisitDTO[],
    abonement: ClientAbonementModel,
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): ClientVisitListModel {
    return new ClientVisitListModel({visits: visitDTOs, abonement, total}, [
      WithoutRecursion.visit,
      ...withoutRecursion,
    ]);
  }

  public static toClientVisitFormDTO(
    visitModel: ClientVisitModel,
    editMode: boolean,
    abonement?: ClientAbonementModel,
  ): ClientVisitFormDTO {
    return new ClientVisitFormDTO(visitModel, editMode, abonement);
  }
}
