import React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {isFunction} from '../../../services/helpers';
import {ChildrenMustBeAFunction} from '../../../components/lib/Errors';
import {IDefaultTabPanelProps, TabItem} from '../../../components/lib/General';
import {isServicePersonalityIndividual} from '../../../services/api/services';
import {ServiceModel} from '../../../struture';
import {useNavigate} from 'react-router';
import {Routes} from '../../../services/types';

export enum SERVICE_TABS {
  GROUP_SESSION = 'Group session',
  EMPLOYEES = 'employees',
}

export default withTranslation()(ServiceTabPanel);

export interface IServiceTabPanelProps extends WithTranslation {
  service: ServiceModel | null;
  children: (value: Pick<IDefaultTabPanelProps, 'tabs'>) => React.ReactNode;
}

function ServiceTabPanel({
  t,
  children,
  service,
}: IServiceTabPanelProps): JSX.Element {
  const navigate = useNavigate();

  // const {
  //   refresh: groupSessionRefresh,
  //   loading: groupSessionLoading,
  //   error: groupSessionError,
  //   ...groupSessions
  // } = useStateServiceGroupSessionList({
  //   serviceUuid,
  //   loadOnInit: tabsId === SERVICE_TABS.GROUP_SESSION,
  // });

  // const groupSessionLoaded = useStopLoading({
  //   loading: groupSessionLoading,
  //   error: groupSessionError,
  //   message: 'An error occurred during group sessions loading',
  // });

  // const {refresh: employeesRefresh, ...serviceEmployeeParam} =
  //   useStateServiceEmployees({
  //     loadOnInit: tabsId === SERVICE_TABS.EMPLOYEES,
  //     companyUuid,
  //     service,
  //   });

  const serviceGroupTab = {
    key: SERVICE_TABS.GROUP_SESSION,
    title: t('Group sessions'),
    disabled: false,
    navigation: () => {
      navigate(Routes.groupSessions, {
        state: {serviceTitle: service?.title},
      });
    },
    renderer: () => (
      <></>
      // <GroupSessionPanel
      //   refresh={groupSessionRefresh}
      //   {...groupSessions}
      //   loading={groupSessionLoaded}
      //   error={groupSessionError}
      //   service={service}
      //   serviceUuid={serviceUuid}
      //   companyUuid={companyUuid}
      // />
    ),
  };

  const serviceTabs: TabItem[] = [
    serviceGroupTab,
    {
      key: SERVICE_TABS.EMPLOYEES,
      title: t('Employees'),
      disabled: false,
      navigation: () => {
        navigate(Routes.serviceEmployees, {
          state: {serviceTitle: service?.title},
        });
      },
      renderer: () => (
        <></>
        // <Suspense fallback={<SuspenseEmpty />}>
        //   <ServiceEmployeeListPanelLazy
        //     refresh={employeesRefresh}
        //     {...serviceEmployeeParam}
        //   />
        // </Suspense>
      ),
    },
  ];

  if (isServicePersonalityIndividual(service as ServiceModel)) {
    serviceTabs.shift();
  }

  if (!isFunction(children)) {
    throw new ChildrenMustBeAFunction('LoadingWithEmptyData');
  }

  return <>{children({tabs: serviceTabs})}</>;
}
