import {Record, List} from 'immutable';
import {
  SystemEventVariableDTO,
  SystemEventDTO,
  SystemEventVariableModel,
  SystemEventVariableMapper,
} from '../internal';

export interface ISystemEventModelReturnType {
  event_key: string;
  event_title: string;
  event_variables: SystemEventVariableDTO[] | List<SystemEventVariableModel>;
}

/**
 * @class Client
 * @desc Клієнт
 */
export class SystemEventModel extends Record<ISystemEventModelReturnType>({
  event_key: '',
  event_title: '',
  event_variables: List(),
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(props: SystemEventDTO = {} as SystemEventDTO) {
    const options: ISystemEventModelReturnType = {
      ...props,

      event_variables: SystemEventVariableMapper.toSystemEventVariableListModel(
        props?.event_variables || [],
      ).event_variables,
    };

    super(options);
  }

  get systemEventVariablesListModel(): List<SystemEventVariableModel> {
    return List.isList(this.event_variables)
      ? this.event_variables
      : SystemEventVariableMapper.toSystemEventVariableListModel(
          this.event_variables,
        ).event_variables;
  }
}
