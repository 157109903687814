import {
  EmployeeDTO,
  GroupDTO,
  IGroupDTOProps,
  IEmployeeServiceDTOProps,
  EmployeeServiceDTO,
  EmployeeMapper,
  EmployeeServiceMapper,
  GroupMapper,
  IServiceModelReturnType,
  IEmployeeDTOProps,
  GroupPaymentMapper,
  GroupPaymentDTO,
  IGroupPaymentDTOProps,
} from '../internal';
import {PERSONALITY_TYPE, TIME_LIST_SERVICES} from '../type';
import {WithoutRecursion} from '../type';
import {correctPrice} from '../../helpers';
import {COLOR_GREEN} from '../../constants';

export enum ServiceStatuses {
  'Blocked' = 0,
  'Active' = 1,
}

export interface IServiceDTOProps {
  uuid: string;
  title: string;
  description: string;
  status: ServiceStatuses;
  color_schema: string;
  personality: PERSONALITY_TYPE;
  period: TIME_LIST_SERVICES;
  created_at: string;
  updated_at: string;
  price: string;
  net_price: string;
  period_amount: number;
  employee_service?: IEmployeeServiceDTOProps;
  group: IGroupDTOProps;
  payment_group: IGroupPaymentDTOProps;
  manager: IEmployeeDTOProps;
  used_by_employees?: IEmployeeServiceDTOProps[];
  group_session: IGroupDTOProps[];
}

export class ServiceDTO {
  public uuid: string;

  public title: string;

  public description: string;

  public color_schema: string;

  public created_at: string;

  public updated_at: string;

  public period_amount: number;

  public price: string;

  public net_price: string;

  public status: ServiceStatuses;

  public personality: PERSONALITY_TYPE;

  public period: TIME_LIST_SERVICES;

  public employee_service?: EmployeeServiceDTO;

  public manager: EmployeeDTO;

  public group: GroupDTO;

  public payment_group: GroupPaymentDTO;

  public used_by_employees?: EmployeeServiceDTO[];

  public group_session: GroupDTO[];

  constructor(
    props: IServiceModelReturnType | IServiceDTOProps | ServiceDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';
    this.title = props?.title;
    this.description = props?.description || '';
    this.color_schema = props?.color_schema || COLOR_GREEN;
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
    this.personality = props?.personality || PERSONALITY_TYPE.INDIVIDUAL;
    this.period = props?.period || TIME_LIST_SERVICES.HOUR;
    this.period_amount = props?.period_amount || 0;
    this.price = correctPrice(props?.price);
    this.net_price = correctPrice(props?.net_price);
    this.status =
      typeof props?.status === 'number'
        ? props?.status
        : ServiceStatuses.Active;

    this.employee_service = props?.employee_service
      ? EmployeeServiceMapper.toEmployeeServiceDTO(props?.employee_service, [
          WithoutRecursion.service,
          ...withoutRecursion,
        ])
      : undefined;

    this.group =
      withoutRecursion.indexOf(WithoutRecursion.group) !== -1
        ? (props?.group as GroupDTO)
        : GroupMapper.toGroupDTO(props?.group, [
            WithoutRecursion.service,
            ...withoutRecursion,
          ]);

    this.payment_group =
      withoutRecursion.indexOf(WithoutRecursion.group) !== -1
        ? (props?.payment_group as GroupPaymentDTO)
        : GroupPaymentMapper.toGroupPaymentDTO(props?.payment_group, [
            WithoutRecursion.service,
            ...withoutRecursion,
          ]);
    this.group_session =
      withoutRecursion.indexOf(WithoutRecursion.group) !== -1
        ? ((props?.group_session || []) as GroupDTO[])
        : GroupMapper.toGroupListDTO(props?.group_session || [], [
            WithoutRecursion.service,
            ...withoutRecursion,
          ]);

    this.manager =
      withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
        ? (props?.manager as EmployeeDTO)
        : EmployeeMapper.toEmployeeDTO(props?.manager, [
            WithoutRecursion.service,
            ...withoutRecursion,
          ]);

    this.used_by_employees = props?.used_by_employees
      ? withoutRecursion.indexOf(WithoutRecursion.employeeService) !== -1
        ? ((props?.used_by_employees || []) as EmployeeServiceDTO[])
        : EmployeeServiceMapper.toEmployeeServiceListDTO(
            props?.used_by_employees || [],
            0,
            [WithoutRecursion.service, ...withoutRecursion],
          ).employeeServices
      : undefined;
  }
}
