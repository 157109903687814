import {Record, List} from 'immutable';
import {IListProps} from './List';
import {MaterialAndExpenseDTO, MaterialAndExpenseModel} from '../internal';
import {WithoutRecursion} from '../type';

export interface IMaterialAndExpenseListModelProps extends IListProps {
  schedule_expenses: MaterialAndExpenseDTO[];
  sum_total: string;
}

export interface IMaterialAndExpenseListModelReturnType extends IListProps {
  schedule_expenses: List<MaterialAndExpenseModel>;
  sum_total: string;
}

export class MaterialAndExpenseListModel extends Record<IMaterialAndExpenseListModelReturnType>(
  {
    schedule_expenses: List(),
    total: 0,
    sum_total: '0.00',
  },
) {
  constructor(
    {
      schedule_expenses = [],
      ...props
    }: IMaterialAndExpenseListModelProps = {} as IMaterialAndExpenseListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IMaterialAndExpenseListModelReturnType = {
      ...props,
      schedule_expenses: List(
        schedule_expenses.map(
          (expense) => new MaterialAndExpenseModel(expense, withoutRecursion),
        ),
      ),
    };

    super(options);
  }
}
