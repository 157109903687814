import {
  IntegrationAssociationDTO,
  IntegrationAssociationStatus,
} from '../internal';

export const CHECKBOX_ATTRIBUTES = {
  cashboxes: [],
};

export interface IntegrationAdditionalProps<T> {
  defaultAttributes: T;
}

export class IntegrationFormDTO<T> {
  public uuid: string;
  public attributes: T;
  public status: number;

  constructor(
    props: IntegrationAssociationDTO<T> | null,
    {defaultAttributes}: IntegrationAdditionalProps<T>,
  ) {
    this.uuid = props?.uuid || '';
    this.attributes = props?.attributes || defaultAttributes;
    this.status =
      props?.status === IntegrationAssociationStatus.ENABLED ? 1 : 0;
  }
}
