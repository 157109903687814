import {correctPrice} from '../../services/helpers';

export enum AlphaNameStatuses {
  Activated = 'activated',
  Paid = 'paid',
  Rejected = 'rejected',
  Completed = 'completed',
  Expired = 'expired',
  Unknown = '',
}

export interface IAlphaNameDTOProps {
  uuid: string;
  order_type: string;
  created_at: string;
  updated_at: string;
  order_price: string;
  order_status_text: AlphaNameStatuses;
  order_description: string;
  order_checkout_url: string;
}

export class AlphaNameDTO {
  public uuid: string;
  public order_type: string;
  public created_at: string;
  public updated_at: string;
  public order_price: string;
  public order_status_text: AlphaNameStatuses;
  public order_description: string;
  public order_checkout_url: string;

  constructor(props?: IAlphaNameDTOProps) {
    this.uuid = props?.uuid || '';
    this.order_type = props?.order_type || '';
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
    this.order_price = correctPrice(props?.order_price);
    this.order_status_text =
      props?.order_status_text || AlphaNameStatuses.Unknown;
    this.order_description = props?.order_description || '';
    this.order_checkout_url = props?.order_checkout_url || '';
  }
}
