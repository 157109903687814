import {Record, List} from 'immutable';
import ScheduleCalendar from './ScheduleCalendar';

export interface ScheduleCalendarListProps {
  schedules: any;
  total?: number;
}

/**
 * @class ScheduleCalendarList
 * @property {List} schedules - list of schedules
 * @classdesc ScheduleCalendar list model.
 */
export default class ScheduleCalendarList extends Record({
  schedules: List(),
  total: 0,
}) {
  /**
   * @constructor
   * @param {{schedules:[]}} param0
   */
  constructor({schedules = [], ...props}: ScheduleCalendarListProps) {
    super({
      schedules: List(
        schedules.map((schedule) => new ScheduleCalendar(schedule)),
      ),
      ...props,
    });
  }
}
