import React from 'react';
import {IntegrationModel} from '../../../struture';
import styled from 'styled-components';
import {StyledTitle, StyledDescription} from '../../../components/lib/Styled';
import {IntegrationSettingButton} from '../Buttons';
import {IUseStateIntegrationListReturnType} from '../../../hooks';

export interface IntegrationItemViewProps
  extends Pick<
    IUseStateIntegrationListReturnType,
    'handleAddOrUpdateIntegration' | 'handleGetIntegration'
  > {
  integration: IntegrationModel;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
  min-height: 80px;
  border-radius: 7px;
  margin-bottom: 10px;

  box-shadow: 0 0 5px ${({theme}) => theme.colors.lightDisable};

  &:last-child {
    margin-bottom: 15px;
  }
`;

const StyledImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 10px;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;

  max-width: 95%;
`;

const StyledImage = styled.img`
  width: 50px;
  height: 50px;

  border-radius: 7px;
`;

export function IntegrationItemView({
  integration,
  handleAddOrUpdateIntegration,
  handleGetIntegration,
}: IntegrationItemViewProps): React.JSX.Element {
  return (
    <StyledContainer>
      <StyledImageContainer>
        {integration?.icon_url ? (
          <StyledImage src={integration?.icon_url} />
        ) : null}
        <StyledTitleContainer>
          <StyledTitle fontSize={15} fontWeight={500}>
            {integration?.title}
          </StyledTitle>
          <StyledDescription style={{whiteSpace: 'unset'}}>
            {integration?.description}
            {integration?.description}
          </StyledDescription>
        </StyledTitleContainer>
      </StyledImageContainer>
      <IntegrationSettingButton
        integration={integration}
        onSuccess={(value) =>
          handleAddOrUpdateIntegration(value, integration?.uuid)
        }
        handleGetIntegration={handleGetIntegration}
      />
    </StyledContainer>
  );
}
