import * as React from 'react';
import {List} from 'immutable';
import {ApiError, IEntityProps, IListSearchProps} from '../services/types';
import {
  IRelatedServiceListDTO,
  RelatedServiceMapper,
  RelatedServiceModel,
} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {getRelatedServiceList} from '../services/api/relatedService';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../contex';

export interface IUseRelatedServiceListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  scheduleUuid: string;
}

export interface IUseRelatedServiceListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<RelatedServiceModel> | null;
  refresh: (
    value: Partial<IUseRelatedServiceListProps>,
  ) => Promise<List<RelatedServiceModel> | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  scheduleUuid: string;
}

export function useRelatedServiceList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    scheduleUuid,
  }: IUseRelatedServiceListProps = {} as IUseRelatedServiceListProps,
): IUseRelatedServiceListReturnType {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const {cancellablePromise, didCancel} = useCancellablePromise();
  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    scheduleUuid: listCompanyUuid,
    handleUpdate,
  } = useStateReducer<Omit<IUseRelatedServiceListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    scheduleUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      scheduleUuid = listCompanyUuid,
    }: Partial<IUseRelatedServiceListProps> = {}): Promise<List<RelatedServiceModel> | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          scheduleUuid,
        });

        const {services, total} =
          await cancellablePromise<IRelatedServiceListDTO>(
            getRelatedServiceList({
              scheduleUuid,
              limit,
              offset,
              keywords,
            }),
          );

        const relatedServiceListModel =
          RelatedServiceMapper.toRelatedServiceListModel(services, total);

        if (!didCancel.current) {
          handleUpdate({
            entityList: relatedServiceListModel?.services,
            total: relatedServiceListModel?.total,
            loading: false,
          });

          return relatedServiceListModel?.services;
        }
      } catch (e) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }

        alert(
          'error',
          t('Related services'),
          t('An error occurred during get related service list'),
        );
      }
    },
    [
      alert,
      cancellablePromise,
      didCancel,
      error,
      handleUpdate,
      listCompanyUuid,
      listKeywords,
      listLimit,
      listOffset,
      t,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && scheduleUuid) {
      (async () => {
        await refresh({scheduleUuid});
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, scheduleUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    scheduleUuid: listCompanyUuid,
  };
}
