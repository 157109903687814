import React from 'react';
import {StoreDocumentModel} from '../../../struture';
import {StyledTitle} from '../../../components/lib/Styled';
import styled, {useTheme} from 'styled-components';
import {correctPrice} from '../../../services/helpers';
import {Tooltip} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {useStoredCompanies} from '../../../hooks';

export interface IStoreProductPriceViewProps {
  document: StoreDocumentModel | null;
  isWarning?: boolean;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)<{$error?: boolean}>`
  color: ${({theme, $error}) =>
    $error ? theme.colors.error : theme.colors.success};
`;

const LineThroughTitle = styled(StyledTitle)`
  text-decoration: line-through;
`;

export function StoreProductPriceView({
  document,
  isWarning,
}: IStoreProductPriceViewProps): React.JSX.Element {
  const {t} = useTranslation();
  const theme: any = useTheme();
  const {defaultCompany} = useStoredCompanies();

  const isDiscount = Number(document?.doc_discount_value || 0) > 0;

  const paid = Number(document?.doc_sum_paid) || 0;
  const total =
    (isDiscount
      ? Number(document?.doc_sum_total_with_discount)
      : Number(document?.doc_sum_total)) || 0;

  const isPaid = paid > 0 && paid === total;
  const isUnpaid = total > 0 && total > paid;

  const isPartialPayment = paid > 0 && total > paid;
  const isOverpayment = paid > 0 && total > 0 && total < paid;

  return (
    <>
      {document ? (
        <StyledContainer>
          {isPartialPayment && document?.doc_is_paymentable ? (
            <>
              <LineThroughTitle $color={theme.colors.error}>
                {correctPrice(total)}
              </LineThroughTitle>
              <StyledTitle $color={theme.colors.error}>
                {correctPrice(total - paid)}
              </StyledTitle>
            </>
          ) : isOverpayment && document?.doc_is_paymentable ? (
            <>
              <LineThroughTitle $color={theme.colors.success}>
                {correctPrice(total)}
              </LineThroughTitle>
              <StyledTitle $color={theme.colors.success}>
                {correctPrice(paid)}
              </StyledTitle>
            </>
          ) : (
            <StyledTitle
              $color={
                isPaid
                  ? theme.colors.success
                  : isUnpaid
                  ? theme.colors.error
                  : isWarning
                  ? theme.colors.warning
                  : undefined
              }>
              {correctPrice(total)}
            </StyledTitle>
          )}

          {isPartialPayment ? (
            <Tooltip
              title={`${t('Partial payment')} - ${
                defaultCompany?.currency_symbol || ''
              }${correctPrice(paid)}`}>
              <StyledInfoCircleOutlined $error />
            </Tooltip>
          ) : isOverpayment ? (
            <Tooltip
              title={`${t('Overpayment')} - ${
                defaultCompany?.currency_symbol || ''
              }${correctPrice(paid - total)}`}>
              <StyledInfoCircleOutlined />
            </Tooltip>
          ) : isPaid ? (
            <Tooltip title={t('The document is paid for')}>
              <StyledInfoCircleOutlined />
            </Tooltip>
          ) : null}
        </StyledContainer>
      ) : null}
    </>
  );
}
