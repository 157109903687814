import {apiDelete, apiGet, apiPatch, apiPost, apiPut} from '../core/api';
import {
  head,
  dateToIsoString,
  toDateByFormat,
  isThereContent,
  notEmptyFields,
} from '../helpers';
import {ApiAnswer, IListSearchProps} from '../types';
import {
  InvoiceDTO,
  InvoiceFormDTO,
  InvoiceType,
  InvoiceStatus,
  InvoiceListModel,
  InvoiceModel,
  InvoiceItemDTO,
  InvoiceItemFormDTO,
  InvoiceItemListModel,
  InvoiceItemModel,
  InvoiceMapper,
  InvoiceMappedType,
  InvoiceStats,
  InvoiceStatsModel,
} from '../../struture';
import {contentApiUrl} from '../const';

const {...rest} = InvoiceMapper.toInvoiceModel({} as InvoiceDTO);

export const INVOICE_REQUIRED_FIELD = {
  rest,
};

export interface InvoiceAnswer extends ApiAnswer {
  invoice: InvoiceDTO;
  stats: InvoiceStats;
}

export interface InvoiceReturnType {
  invoice: InvoiceModel;
  stats?: InvoiceStatsModel;
}

export interface InvoiceListAnswer extends ApiAnswer, IListSearchProps {
  invoices: InvoiceDTO[];
  stats: InvoiceStats;
}

export interface InvoiceListProps extends IListSearchProps {
  companyUuid: string;
  inv_type: InvoiceType;
  inv_status?: InvoiceStatus;
  date_start?: string;
  date_end?: string;
  inv_resident_uuid?: string;
}

export async function getInvoiceList({
  offset = 0,
  limit = 10,
  keywords,
  companyUuid,
  inv_resident_uuid,
  inv_status,
  inv_type,
  date_start,
  date_end,
}: InvoiceListProps): Promise<InvoiceListModel> {
  const {invoices, total, stats} = await apiGet<
    Omit<InvoiceListProps, 'companyUuid'>,
    InvoiceListAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/invoices`, {
    offset,
    limit,
    keywords,
    inv_type:
      (inv_type as any) === InvoiceMappedType.EMPTY ? ('' as any) : inv_type,
    inv_status,
    date_start: date_start
      ? toDateByFormat(date_start, 'YYYY-MM-DDTHH:mm:ss')
      : '',
    date_end: date_end ? toDateByFormat(date_end, 'YYYY-MM-DDTHH:mm:ss') : '',
    inv_resident_uuid,
  });

  return InvoiceMapper.toInvoiceListModel(
    invoices,
    total || 0,
    [],
    InvoiceMapper.toInvoiceStatsModel(stats),
  );
}

export async function getInvoiceById(
  invoiceUuid: string,
): Promise<InvoiceReturnType> {
  const {invoice} = await apiGet<null, InvoiceAnswer>(
    `${contentApiUrl}/invoices/${invoiceUuid}`,
  );

  return {
    invoice: InvoiceMapper.toInvoiceModel(invoice),
  };
}

export async function createInvoice(
  {
    inv_client_requisites,
    inv_company_requisites,
    inv_type,
    inv_discount_type,
    inv_discount_source,
    inv_discount_value,
    inv_income_number,
    inv_multi_currencies,
    inv_client,
    inv_date,
    inv_comment,
  }: InvoiceFormDTO,
  companyUuid: string,
): Promise<InvoiceReturnType> {
  const client: any = (({uuid, ...rest}: any) =>
    uuid?.includes('create') ? notEmptyFields({...rest}) : {uuid})(inv_client);

  const {invoice, stats} = await apiPost<
    Omit<InvoiceFormDTO, 'uuid'>,
    InvoiceAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/invoices`, {
    inv_client_requisites: isThereContent(inv_client_requisites)
      ? inv_client_requisites
      : undefined,
    inv_company_requisites: isThereContent(inv_company_requisites)
      ? inv_company_requisites
      : undefined,
    inv_type,
    inv_discount_type,
    inv_discount_source,
    inv_discount_value,
    inv_income_number,
    inv_multi_currencies,
    inv_client: client?.uuid ? client : (null as any),
    inv_date: inv_date ? dateToIsoString(inv_date).toString() : '',
    inv_comment,
  });

  return {
    invoice: InvoiceMapper.toInvoiceModel(invoice),
    stats: InvoiceMapper.toInvoiceStatsModel(stats),
  };
}

export async function editInvoice({
  uuid: invoice_uuid,
  inv_client_requisites,
  inv_company_requisites,
  inv_type,
  inv_discount_type,
  inv_discount_source,
  inv_discount_value,
  inv_income_number,
  inv_multi_currencies,
  inv_client,
  inv_date,
  inv_comment,
}: Omit<InvoiceFormDTO, 'getTimingList'>): Promise<InvoiceReturnType> {
  const client: any = (({uuid, ...rest}: any) =>
    uuid?.includes('create') ? notEmptyFields({...rest}) : {uuid})(inv_client);

  const {invoice} = await apiPut<Omit<InvoiceFormDTO, 'uuid'>, InvoiceAnswer>(
    `${contentApiUrl}/invoices/${invoice_uuid}`,
    {
      inv_client_requisites: isThereContent(inv_client_requisites)
        ? inv_client_requisites
        : ('null' as any),
      inv_company_requisites: isThereContent(inv_company_requisites)
        ? inv_company_requisites
        : ('null' as any),
      inv_type,
      inv_discount_type,
      inv_discount_source,
      inv_discount_value,
      inv_income_number,
      inv_multi_currencies,
      inv_client: client?.uuid ? client : (null as any),
      inv_date: inv_date ? dateToIsoString(inv_date).toString() : '',
      inv_comment,
    },
  );

  return {
    invoice: InvoiceMapper.toInvoiceModel(invoice),
  };
}

export async function deleteInvoice(
  ids: string[] = [],
): Promise<InvoiceReturnType> {
  const {invoice, stats} = await apiDelete<{ids: string[]}, InvoiceAnswer>(
    `${contentApiUrl}/invoices/${head(ids)}`,
  );

  return {
    invoice: InvoiceMapper.toInvoiceModel(invoice),
    stats: InvoiceMapper.toInvoiceStatsModel(stats),
  };
}

/* ------------------- INVOICE PRINT DOCUMENTS ------------------- */

export interface IStorePrintDocumentAnswer extends ApiAnswer {
  html: string;
}

export async function getInvoiceForPrint(invoiceUuid: string): Promise<string> {
  const {html} = await apiGet<null, IStorePrintDocumentAnswer>(
    `${contentApiUrl}/invoices/${invoiceUuid}/print`,
  );

  return html;
}

export async function getInvoiceStoreRealizationForPrint(
  invoiceUuid: string,
): Promise<string> {
  const {html} = await apiGet<null, IStorePrintDocumentAnswer>(
    `${contentApiUrl}/invoices/${invoiceUuid}/print`,
  );

  return html;
}

export async function getInvoiceInputInvoiceForPrint(
  invoiceUuid: string,
): Promise<string> {
  const {html} = await apiGet<null, IStorePrintDocumentAnswer>(
    `${contentApiUrl}/invoices/${invoiceUuid}/print`,
  );

  return html;
}

/* ------------------- INVOICE COMMENT ------------------- */

export async function updateInvoiceComment(
  comment: string,
  invoiceUuid: string,
): Promise<InvoiceReturnType> {
  const {invoice} = await apiPatch<{comment: string}, InvoiceAnswer>(
    `${contentApiUrl}/invoices/${invoiceUuid}/comment`,
    {comment},
  );

  return {
    invoice: InvoiceMapper.toInvoiceModel(invoice),
  };
}

/* ------------------- INVOICE STATUS ------------------- */

export async function updateInvoiceStatus(
  status: InvoiceStatus,
  invoiceUuid: string,
): Promise<InvoiceReturnType> {
  const {invoice} = await apiPatch<{status: InvoiceStatus}, InvoiceAnswer>(
    `${contentApiUrl}/invoices/${invoiceUuid}/status`,
    {status},
  );

  return {
    invoice: InvoiceMapper.toInvoiceModel(invoice),
  };
}

/* ------------------- INVOICE ITEMS ------------------- */

export interface InvoiceItemListReturnType {
  total: number;
  items: InvoiceItemListModel;
  invoice: InvoiceModel;
}

export interface InvoiceItemReturnType {
  item: InvoiceItemModel;
  invoice: InvoiceModel;
}

export interface InvoiceItemListProps extends IListSearchProps {
  invoiceUuid: string;
}

export interface InvoiceItemProps {
  items: InvoiceItemFormDTO[];
}

export interface InvoiceItemAnswer {
  item: InvoiceItemDTO;
  invoice: InvoiceDTO;
}

export interface InvoiceItemListAnswer extends ApiAnswer, IListSearchProps {
  items: InvoiceItemDTO[];
  invoice: InvoiceDTO;
}

export async function getInvoiceItemList({
  offset = 0,
  limit = 10,
  keywords,
  invoiceUuid,
}: InvoiceItemListProps): Promise<InvoiceItemListReturnType> {
  const {items, invoice, total} = await apiGet<
    IListSearchProps,
    InvoiceItemListAnswer
  >(`${contentApiUrl}/invoices/${invoiceUuid}/items`, {
    offset,
    limit,
    keywords,
  });

  const invoiceModel = InvoiceMapper.toInvoiceModel(invoice);

  return {
    items: InvoiceMapper.toInvoiceItemListModel(items, total, [], invoiceModel),
    invoice: invoiceModel,
    total,
  };
}

export async function createInvoiceItem(
  itemsForm: InvoiceItemFormDTO[],
  invoiceUuid: string,
): Promise<InvoiceItemListReturnType> {
  const {items, invoice, total} = await apiPost<
    Omit<InvoiceItemProps, 'uuid'>,
    InvoiceItemListAnswer
  >(`${contentApiUrl}/invoices/${invoiceUuid}/items`, {
    items: itemsForm,
  });

  const invoiceModel = InvoiceMapper.toInvoiceModel(invoice);

  return {
    items: InvoiceMapper.toInvoiceItemListModel(items, total, [], invoiceModel),
    invoice: invoiceModel,
    total,
  };
}

export async function editInvoiceItem(
  {
    uuid,
    item_amount,
    item_comment,
    item_currency,
    item_positionable_type,
    item_positionable_uuid,
    item_price,
    item_base_price,
    item_title,
  }: InvoiceItemFormDTO,
  invoiceUuid: string,
): Promise<InvoiceItemReturnType> {
  const {item, invoice} = await apiPatch<
    Partial<InvoiceItemFormDTO>,
    InvoiceItemAnswer
  >(`${contentApiUrl}/invoices/${invoiceUuid}/items/${uuid}`, {
    item_amount,
    item_currency,
    item_price,
    item_base_price,
  });

  return {
    item: InvoiceMapper.toInvoiceItemModel(item),
    invoice: InvoiceMapper.toInvoiceModel(invoice),
  };
}

export async function deleteInvoiceItem(
  ids: string[] = [],
  invoiceUuid: string,
): Promise<InvoiceItemReturnType> {
  const {item, invoice} = await apiDelete<{ids: string[]}, InvoiceItemAnswer>(
    `${contentApiUrl}/invoices/${invoiceUuid}/items/${head(ids)}`,
  );

  return {
    item: InvoiceMapper.toInvoiceItemModel(item),
    invoice: InvoiceMapper.toInvoiceModel(invoice),
  };
}

export async function duplicateInvoice(
  invoiceUuid: string,
): Promise<InvoiceReturnType> {
  const {invoice} = await apiPost<null, InvoiceAnswer>(
    `${contentApiUrl}/invoices/${invoiceUuid}/duplicate`,
    null,
  );

  return {
    invoice: InvoiceMapper.toInvoiceModel(invoice),
  };
}
