import React, {useCallback, useMemo} from 'react';
import {Row, Col, Input, Form} from 'antd';
import {DefaultForm, IDefaultFormProps} from '../../../components/lib/General';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../../../contex';
import {StatusCommentFormDTO} from '../../../struture';

export interface ICommentOrderFormProps
  extends Pick<
    IDefaultFormProps<unknown, unknown>,
    | 'showFooter'
    | 'showNotify'
    | 'editMode'
    | 'className'
    | 'additionalValuesRequest'
  > {
  loading?: boolean;
  onCancel?: () => void;
  onSuccess: (value: StatusCommentFormDTO) => Promise<void>;
  onError?: () => Promise<void>;
  comment: StatusCommentFormDTO;
}

/**
 * @desc Створенння співробітника
 * */
export function CommentOrderForm({
  loading,
  editMode,
  comment,
  onCancel,
  ...rest
}: ICommentOrderFormProps) {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const additionalValues = useMemo(
    () => ({
      status_uuid: comment?.status_uuid,
    }),
    [comment?.status_uuid],
  );

  const notifyError = useCallback(
    (apiError: any): void => {
      alert(
        'error',
        t('Order close'),
        `${t('An error occurred during create order comment')} : ${
          apiError?.message
        }`,
      );
    },
    [t, alert],
  );

  return (
    <DefaultForm
      initialValues={comment}
      onCancel={onCancel}
      additionalValuesRequest={additionalValues}
      editMode={editMode}
      showNotify={false}
      notifyError={notifyError}
      {...rest}>
      {({loadingSubmit}) => (
        <Row gutter={20}>
          <Col span={24}>
            <Form.Item
              label={t('Note')}
              name="comment"
              rules={[
                {
                  required: true,
                  message: t('Note must be specified'),
                },
              ]}>
              <Input.TextArea
                allowClear
                autoSize={{minRows: 2, maxRows: 5}}
                disabled={loading || loadingSubmit}
                placeholder={t('Enter your note')}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </DefaultForm>
  );
}
