import {
  ICompanyDTOProps,
  CompanyDTO,
  IGroupPaymentModelReturnType,
  CompanyMapper,
} from '../internal';
import {WithoutRecursion} from '../type';

export interface IGroupPaymentDTOProps {
  uuid: string;
  title: string;
  description: string;
  updated_at: string;
  created_at: string;
  companies: ICompanyDTOProps[];
}

export class GroupPaymentDTO {
  public uuid: string;

  public title: string;

  public description: string;

  public updated_at: string;

  public created_at: string;

  public companies: CompanyDTO[];

  constructor(
    props: IGroupPaymentDTOProps | IGroupPaymentModelReturnType,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';
    this.title = props?.title || '';
    this.description = props?.description || '';
    this.updated_at = props?.updated_at || '';
    this.created_at = props?.created_at || '';
    this.companies =
      withoutRecursion.indexOf(WithoutRecursion.company) !== -1
        ? ((props?.companies || []) as CompanyDTO[])
        : CompanyMapper.toCompanyListDTO(props?.companies, 0, [
            WithoutRecursion.groupPayment,
            ...withoutRecursion,
          ]).companies;
  }
}
