import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {StyledTitle, StyledDescription} from '../../../components/lib/Styled';
import {ScheduleStatusModel} from '../../../struture';
import {toDateByFormat} from '../../../services/helpers';

export interface IOrderStatusListViewProps {
  statuses: ScheduleStatusModel[];
}

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({theme}) => theme.colors.white};
  margin: 5px 10px;
  padding: 5px;
  border-radius: 5px;
  align-items: center;
`;

const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 5px;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 5px;
`;

export function OrderStatusListView({
  statuses,
}: IOrderStatusListViewProps): JSX.Element {
  const {t} = useTranslation();

  return (
    <>
      {statuses.map(
        (
          {status_text, created_at, status_color, reason, created_by},
          index,
        ) => (
          <React.Fragment key={String(index)}>
            <StyledHeader>
              <StyledDescription $color={status_color || undefined}>
                {t(status_text)}
              </StyledDescription>
              <StyledDescription>
                {toDateByFormat(created_at, 'HH:mm')}
              </StyledDescription>
            </StyledHeader>
            <StyledTextContainer
              // style={styles.messageContainer}
              key={`${toDateByFormat(
                created_at,
                'HH:mm',
              )} ${status_text} ${reason}`}>
              {created_by?.first_name ? (
                <StyledContent>
                  <StyledDescription>{t('User')}</StyledDescription>
                  <StyledTitle>{created_by?.first_name}</StyledTitle>
                </StyledContent>
              ) : null}
              {reason ? (
                <StyledContent>
                  <StyledDescription>{t('Description')}</StyledDescription>
                  <StyledTitle>{reason}</StyledTitle>
                </StyledContent>
              ) : null}
            </StyledTextContainer>
          </React.Fragment>
        ),
      )}
    </>
  );
}

// const styles = StyleSheet.create({
//   messageContainer: {
//     shadowColor: '#000',
//     shadowOffset: {
//       width: 0,
//       height: 3,
//     },
//     shadowOpacity: 0.1,
//     shadowRadius: 5,
//
//     elevation: 3,
//   },
// });
