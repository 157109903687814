import React, {useState, useRef, useEffect} from 'react';
import {Form, Input, Select, SelectProps} from 'antd';
import {useDefaultForm} from '../../../../contex';
import {useTranslation} from 'react-i18next';
import {useOutsideClickHandler} from '../../../../hooks';
import {isRecordToObject, isFunction} from '../../../../services/helpers';
import styled, {css} from 'styled-components';
import {LoadingOutlined} from '@ant-design/icons';
import {ENTER_KEYS} from '../../../../services/const';
import {immerable, produce} from 'immer';
import {isObject} from '@sportix/sportix-common-modules';

export interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

export enum ItemType {
  Input = 'input',
  Select = 'select',
}

export interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean | ((value: Item) => boolean);
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
  type?: ItemType;
  options?: SelectProps['options'];
  rules: any[] | ((cellIndex?: number) => any[]);
  optionsName: string;
  disabled?: boolean;
  loading?: boolean;
  loadingItemId?: string;
  alignText?: string;
  validateList?: (string | number | (string | number)[])[];
  parent?: string;
  autoFocus?: boolean;
  cellIndex?: number;
  recordUuid?: string;
  fields?: (record: any) => React.ReactNode | React.ReactNode[];
  fontSize?: number;
}

const StyledContainer = styled.div<{isShow: boolean; $align: any}>`
  display: flex;
  min-height: 32px;
  align-items: flex-start;

  ${({isShow}) =>
    isShow &&
    css`
      display: flex;
      justify-content: space-between;
      width: 100%;
    `}

  ${({$align}) =>
    $align === 'right' &&
    css`
      justify-content: flex-end;
      text-align: right;
      width: 100%;
      display: inline-table;
    `}
`;

const StyledLoadingContainer = styled.div<{$loading: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: ${({$loading}) => ($loading ? 'space-between' : 'flex-end')};
  align-items: center;
  gap: 5px;

  width: 100%;
`;

const StyledContainerWithAnotherFields = styled.div<{$isActions: boolean}>`
  display: flex;
  flex-direction: column;

  ${({$isActions}) =>
    $isActions &&
    css`
      align-items: center;
    `}
`;

const StyledInput = styled(Input)<{$fontSize?: number}>`
  ${({$fontSize}) =>
    $fontSize &&
    css`
      font-size: ${`${$fontSize}px`};
    `}
`;

export function EditableCell({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  type = ItemType.Input,
  options = [],
  rules = [],
  disabled = false,
  loading,
  loadingItemId,
  alignText,
  validateList,
  parent,
  autoFocus,
  cellIndex,
  recordUuid,
  fields,
  fontSize,
  ...restProps
}: EditableCellProps): JSX.Element {
  const {t} = useTranslation();

  const [editing, setEditing] = useState(false);
  const {validateFields, setFieldsValue, loadingSubmit} = useDefaultForm();

  const inputRef = useRef<any>(null);
  const containerRef = useRef(null);
  const onceAutoFocus = useRef(autoFocus);

  useEffect(() => {
    if (editing || onceAutoFocus?.current) {
      if (type === ItemType.Select) {
        inputRef.current!.focus();

        const clickEvent = new MouseEvent('mousedown', {
          bubbles: true,
          cancelable: true,
        });

        const select = document.body.querySelector(
          '.editable-select-cell[data-edit="true"]',
        );

        if (select instanceof HTMLElement) {
          const input = select.querySelector(
            '.ant-select-selection-search-input',
          );

          if (input instanceof HTMLElement) {
            input?.dispatchEvent(clickEvent);
          }
        }
      } else {
        setFieldsValue({[dataIndex]: record[dataIndex]});
        inputRef?.current?.focus();
      }
    }
  }, [dataIndex, editing, record, setFieldsValue, type]);

  const toggleEdit = (e: any) => {
    const target = e?.target;

    if (target.closest('.editable-cell-value-wrap')) {
      setEditing(!editing);
      setFieldsValue({[dataIndex]: record[dataIndex]});
    }
  };

  const closeEdit = () => {
    onceAutoFocus.current = false;
    setEditing(false);
    setFieldsValue({[dataIndex]: record[dataIndex]});
  };

  const save = async () => {
    try {
      let values: any;

      if (validateList) {
        values = await validateFields(validateList);
      } else {
        values = await validateFields();
      }

      const isImmerable = (record as any)[immerable];

      const updated = isRecordToObject(record);

      let result;

      if (isImmerable) {
        result = produce(updated, (draft: any) => {
          Object.entries(values)
            .filter(([_, value]) => !isObject(value))
            .forEach(([key, value]) => {
              draft[key] = value;
            });
        });
      } else {
        result = {...updated, ...values};
      }

      handleSave(result);
    } catch (errInfo) {
      // console.log(errInfo);
    }
  };

  useOutsideClickHandler(
    containerRef,
    async () => {
      await save();
      closeEdit();
    },
    '.editable-select-popup',
  );

  const EditebleField = (
    <StyledContainerWithAnotherFields
      $isActions={(dataIndex as any) === 'actions'}>
      <StyledContainer
        $align={alignText}
        isShow={isFunction(editable) ? editable(record) : editable}
        className={
          ((isFunction(editable) ? editable(record) : editable) &&
          !disabled &&
          !(loading && loadingItemId === (record as any)?.uuid)
            ? 'editable-cell-value-wrap'
            : (isFunction(editable) ? editable(record) : editable)
            ? 'editable-cell-value'
            : '') +
          ` ${
            typeof (record as any)?.index === 'number'
              ? `editable-cell${(record as any)?.index}${cellIndex}`
              : ''
          }`
        }
        onClick={toggleEdit}>
        {children}
        {loading && loadingItemId === (record as any)?.uuid ? (
          <LoadingOutlined />
        ) : null}
      </StyledContainer>
      {isFunction(fields) ? fields(record) : null}
    </StyledContainerWithAnotherFields>
  );

  return (
    <td {...restProps}>
      {editing || onceAutoFocus?.current ? (
        <div ref={containerRef}>
          <StyledContainerWithAnotherFields
            $isActions={(dataIndex as any) === 'actions'}>
            <Form.Item
              style={{margin: 0}}
              name={dataIndex}
              rules={
                isFunction(rules)
                  ? rules(cellIndex)
                  : rules.length
                  ? rules
                  : [
                      {
                        required: true,
                        message: `${title} ${t('is required.')}`,
                      },
                    ]
              }>
              {type === ItemType.Input ? (
                <StyledInput
                  $fontSize={fontSize}
                  ref={inputRef}
                  onKeyDown={async (e) => {
                    if (ENTER_KEYS.includes(e?.code)) {
                      await save();
                      closeEdit();
                    }
                  }}
                  onBlur={save}
                  onFocus={(e) =>
                    setTimeout(() => {
                      e?.target?.select();
                    }, 100)
                  }
                  disabled={
                    loadingSubmit ||
                    (loading && loadingItemId === (record as any)?.uuid)
                  }
                  {...(typeof (record as any)?.index === 'number'
                    ? {
                        'data-cell-index': cellIndex,
                        'data-item-index': (record as any)?.index,
                      }
                    : {})}
                />
              ) : (
                <Select
                  className="editable-select-cell"
                  data-edit={editing}
                  ref={inputRef}
                  onBlur={save}
                  options={options.map(({label, value}) => ({
                    value,
                    label: t(label),
                  }))}
                  disabled={
                    loadingSubmit ||
                    (loading && loadingItemId === (record as any)?.uuid)
                  }
                />
              )}
            </Form.Item>
            {isFunction(fields) ? fields(record) : null}
          </StyledContainerWithAnotherFields>
        </div>
      ) : parent ? (
        <Form.Item
          style={{margin: 0}}
          name={
            parent ? [parent, (record as any)?.index, dataIndex] : dataIndex
          }
          rules={
            isFunction(rules)
              ? rules(cellIndex)
              : rules.length
              ? rules
              : [
                  {
                    required: true,
                    message: `${title} ${t('is required.')}`,
                  },
                ]
          }>
          {loading && recordUuid === (record as any)?.uuid ? (
            <StyledLoadingContainer $loading={loading}>
              {loading && recordUuid === (record as any)?.uuid ? (
                <LoadingOutlined />
              ) : null}

              {EditebleField}
            </StyledLoadingContainer>
          ) : (
            EditebleField
          )}
        </Form.Item>
      ) : (
        <>
          {loading && recordUuid === (record as any)?.uuid ? (
            <StyledLoadingContainer $loading={loading}>
              {loading && recordUuid === (record as any)?.uuid ? (
                <LoadingOutlined />
              ) : null}

              {EditebleField}
            </StyledLoadingContainer>
          ) : (
            EditebleField
          )}
        </>
      )}
    </td>
  );
}
