import React from 'react';
import {CashBoxModel} from '../../../struture';
import {StyledDescription, StyledTitle} from '../../../components/lib/Styled';
import {correctPrice} from '../../../services/helpers';
import styled, {useTheme} from 'styled-components';
import {useStoredCompanies} from '../../../hooks';
import {Image} from 'antd';

import './CashBoxTitleView.less';

export interface ICashBoxTitleViewProps {
  cashBox: CashBoxModel | null;
  disable: boolean;
}

const Title = styled(StyledTitle)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  gap: 5px;
`;

export function CashBoxTitleView({
  cashBox,
  disable,
}: ICashBoxTitleViewProps): React.JSX.Element {
  const theme: any = useTheme();
  const {defaultCompany} = useStoredCompanies();

  const {box_title, box_balance, box_fiscal_cashbox_id}: CashBoxModel =
    cashBox || ({} as CashBoxModel);

  return (
    <Title>
      {box_fiscal_cashbox_id ? (
        <Image
          src="/tax.png"
          alt="tax"
          width="18px"
          preview={false}
          className={disable ? 'cash-box-icon-view' : ''}
        />
      ) : null}
      {box_title}

      <StyledDescription
        $color={
          disable
            ? theme.colors.disable
            : Number(box_balance) >= 0
            ? theme.colors.success
            : theme.colors.error
        }>
        {`( ${defaultCompany?.currency_symbol || ''}, ${correctPrice(
          box_balance || 0,
        )} )`}
      </StyledDescription>
    </Title>
  );
}
