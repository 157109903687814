import React, {useEffect} from 'react';
import {Row} from 'antd';
import {
  PriceMarginModel,
  StoreDocumentFormDTO,
  StoreDocumentItemFormDTO,
  StoreDocumentItemListModel,
  StoreDocumentItemModel,
  StoreDocumentMapper,
  StoreDocumentType,
  StoreModel,
} from '../../../struture';
import {
  DocumentItemReturnType,
  IUseStateProductListReturnType,
  IUseStateProductReturnType,
  IUseStateStoredDocumentReturnType,
  IUseStoreDocumentItemListProps,
  UseStateProductListReturnType,
  UseStateStoreDocumentItemListReturnType,
} from '../../../hooks';
import {StoreProductListField} from '../Forms';
import {useDefaultForm} from '../../../contex';
import {List} from 'immutable';
import {listToArray} from '../../../services/helpers';

export interface IStorePostingFieldsProps
  extends Pick<
      IUseStateProductListReturnType,
      'productList' | 'handleSearchProducts'
    >,
    Pick<IUseStateStoredDocumentReturnType, 'handleDeleteStoreDocumentItem'>,
    Pick<IUseStateProductReturnType, 'handleCreateProduct'>,
    Pick<
      UseStateProductListReturnType,
      'isLoadingMore' | 'handleLoadingMoreProductList' | 'handleSetProductList'
    > {
  loading: boolean;
  editMode: boolean;
  productListLoading: boolean;
  productListLoadingMore: boolean;
  productListKeywords: string;
  defaultPriceMargin: PriceMarginModel | null;
  withoutStoreUuid: boolean;

  store: StoreModel | string;
  onSuccess: (
    value: StoreDocumentItemFormDTO,
  ) => Promise<DocumentItemReturnType | void>;
  storeDocumentItemList: List<StoreDocumentItemModel> | null;
  storeDocumentItemListRefresh: (
    value: Partial<IUseStoreDocumentItemListProps>,
  ) => Promise<StoreDocumentItemListModel | void>;
  storeDocumentItemListLimit: number;
  storeDocumentItemListTotal: number;
  storeDocumentItemListPage: number;
  withEditablePrice?: boolean;
  productWithBalance?: boolean;
  document: StoreDocumentFormDTO;
  isShowRelatedDocumentItems?: boolean;
  withoutCreateProduct?: boolean;
}

const AMOUNT_DOC_TYPE = [
  StoreDocumentType.MOVE,
  StoreDocumentType.ERASE,
  StoreDocumentType.OUT,
  StoreDocumentType.RETURN,
  StoreDocumentType.CRETURN,
];

export function StoreDocumentPageFields({
  loading,
  withoutStoreUuid,

  productList,
  productListLoading,
  productListLoadingMore,
  productListKeywords,
  handleSearchProducts,
  handleSetProductList,

  defaultPriceMargin,
  store,
  onSuccess,
  handleDeleteStoreDocumentItem,
  handleCreateProduct,

  isLoadingMore,
  handleLoadingMoreProductList,
  storeDocumentItemList,
  storeDocumentItemListRefresh,
  storeDocumentItemListLimit,
  storeDocumentItemListTotal,
  storeDocumentItemListPage,
  withEditablePrice,
  productWithBalance = true,
  document,

  isShowRelatedDocumentItems,
  withoutCreateProduct,
}: IStorePostingFieldsProps): React.JSX.Element {
  const {handlerUpdateFormState, formData} = useDefaultForm();

  useEffect(() => {
    if (store instanceof StoreModel) {
      handlerUpdateFormState({doc_store_uuid: store?.uuid});
    } else {
      handlerUpdateFormState({doc_store_uuid: store});
    }
  }, [handlerUpdateFormState, store]);

  useEffect(() => {
    if (List.isList(storeDocumentItemList)) {
      const docItem = storeDocumentItemList?.map((item: any) => {
        return StoreDocumentMapper.toStoreDocumentItemFormDTO(item, {
          editMode: true,
        });
      });

      handlerUpdateFormState({doc_items: listToArray(docItem)});
    }
  }, [handlerUpdateFormState, storeDocumentItemList]);

  return (
    <Row gutter={20}>
      <StoreProductListField
        isCenterCreateProduct={false}
        asAmountModal={AMOUNT_DOC_TYPE.includes(formData?.doc_type)}
        withoutStoreUuid={withoutStoreUuid}
        loading={loading}
        editMode={false}
        productList={productList}
        productListLoading={productListLoading}
        productListLoadingMore={productListLoadingMore}
        handleSearchProducts={handleSearchProducts}
        productListKeywords={productListKeywords}
        defaultPriceMargin={defaultPriceMargin}
        onSuccess={onSuccess}
        onDelete={handleDeleteStoreDocumentItem as any}
        handleCreateProduct={handleCreateProduct}
        isLoadingMore={isLoadingMore}
        handleLoadingMoreProductList={handleLoadingMoreProductList}
        storeDocumentItemListRefresh={storeDocumentItemListRefresh}
        storeDocumentItemListLimit={storeDocumentItemListLimit}
        storeDocumentItemListTotal={storeDocumentItemListTotal}
        storeDocumentItemListPage={storeDocumentItemListPage}
        withEditablePrice={withEditablePrice}
        productWithBalance={productWithBalance}
        document={document}
        isShowRelatedDocumentItems={isShowRelatedDocumentItems}
        withoutCreateProduct={withoutCreateProduct}
        handleSetProductList={handleSetProductList}
      />
    </Row>
  );
}
