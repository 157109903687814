import {List} from 'immutable';
import {
  SettingsModuleDependencyDTO,
  ISettingsModuleDependencyDTOProps,
  SettingsModuleDependencyModel,
  SettingsModuleDependencyListModel,
} from '../internal';
import {listToArray} from '../../helpers';

export class SettingsModuleDependencyMapper {
  public static toSettingsModuleDependencyDTO(
    dependency:
      | SettingsModuleDependencyModel
      | ISettingsModuleDependencyDTOProps,
  ): SettingsModuleDependencyDTO {
    return new SettingsModuleDependencyDTO(dependency);
  }

  public static toSettingsModuleDependencyListDTO(
    dependencies:
      | List<SettingsModuleDependencyModel>
      | ISettingsModuleDependencyDTOProps[],
  ): SettingsModuleDependencyDTO[] {
    const dependencyList = List.isList(dependencies)
      ? listToArray<SettingsModuleDependencyModel>(dependencies)
      : dependencies;

    return dependencyList?.map((dependency) =>
      SettingsModuleDependencyMapper.toSettingsModuleDependencyDTO(dependency),
    );
  }

  public static toSettingsModuleDependencyModel(
    dependencyDTO: SettingsModuleDependencyDTO,
  ): SettingsModuleDependencyModel {
    return new SettingsModuleDependencyModel(dependencyDTO);
  }

  public static toSettingsModuleDependencyListModel(
    dependencyDTOs: SettingsModuleDependencyDTO[],
  ): SettingsModuleDependencyListModel {
    return new SettingsModuleDependencyListModel({
      dependencies: dependencyDTOs,
    });
  }
}
