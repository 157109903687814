import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {IActionButtonProps} from '../../../components/lib/General';
import {useModal} from '../../../components/lib/libV2/hooks';
import {RewardFormDTO} from '../../../struture';
import {AddButton, SuspenseEmpty} from '../../../components/lib/DataDisplay';

const RewardSideWindowLazy = React.lazy(
  () => import('../Show/RewardSideWindow'),
);

export interface IRewardFineRecordButtonProps
  extends Omit<IActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: RewardFormDTO) => Promise<void>;
  onCancel?: () => void;
  reward: RewardFormDTO;
}

export function RewardFineRecordButton({
  children,
  onSuccess,
  onCancel,
  loading,
  reward,
  ...rest
}: IRewardFineRecordButtonProps): React.JSX.Element {
  const {t} = useTranslation();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <AddButton
        tooltipTitle={t('Add a fine')}
        onClick={handleOnInit}
        loading={loading}
        danger
        type="primary"
        {...rest}>
        {`-${t('Fine')}`}
      </AddButton>

      <Suspense fallback={<SuspenseEmpty />}>
        <RewardSideWindowLazy
          reward={reward}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
          title={t('Add a fine')}
        />
      </Suspense>
    </>
  );
}
