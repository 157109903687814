import React from 'react';
import {RewardModel} from '../../../struture';
import styled, {useTheme, css} from 'styled-components';
import {StyledTitle} from '../../../components/lib/Styled';
import {useTranslation} from 'react-i18next';
import {Tooltip} from 'antd';
import {useParams} from 'react-router';
import {correctPrice} from '../../../services/helpers';

export interface IRewardColumnSumFieldProps {
  reward: RewardModel;
}

const Title = styled(StyledTitle)<{$isTooltip: boolean}>`
  ${({$isTooltip}) =>
    $isTooltip &&
    css`
      cursor: pointer;
    `}
`;

export function RewardColumnSumField({
  reward,
}: IRewardColumnSumFieldProps): React.JSX.Element {
  const {t} = useTranslation();
  const {employeeId} = useParams();
  const theme: any = useTheme();

  const price = Number(reward?.price);
  const sumPaid = Number(reward?.sum_paid);

  const isPartialSumPaid = sumPaid > 0;

  const sum = sumPaid - price;
  const partial = price - sumPaid;

  return (
    <Tooltip
      title={
        isPartialSumPaid && price >= 0
          ? sumPaid > price
            ? `${t('Overpaid')} ${correctPrice(sum)}`
            : `${t('Balance due')} ${correctPrice(partial)}`
          : ''
      }>
      <Title
        $isTooltip={isPartialSumPaid && price >= 0}
        $color={sumPaid >= price ? theme.colors.success : theme.colors.primary}>
        {isPartialSumPaid && price >= 0
          ? `${t('Paid')} ${correctPrice(sumPaid)} ${t('with')} `
          : null}
        {price >= 0 ? (
          <StyledTitle $color={theme.colors.success}>
            {correctPrice(price)}
          </StyledTitle>
        ) : (
          <StyledTitle $color={theme.colors.error}>
            {correctPrice(price)}
          </StyledTitle>
        )}
      </Title>
    </Tooltip>
  );
}
