import * as React from 'react';
import {List} from 'immutable';
import moment from 'moment';
import {
  IUseCompanyClientListProps,
  IUseCompanyClientListReturnType,
  useCompanyClientList,
} from './useCompanyClientsList';
import {
  ClientDTO,
  ClientFormDTO,
  ClientGroupFormDTO,
  ClientLegalFormDTO,
  ClientMapper,
  ClientModel,
} from '../struture';
import {useStateEntityList} from '../components/lib/libV2/hooks';
import {
  createClient,
  createClientGroup,
  createClientLegal,
  deleteClient,
  editClient,
  editClientGroup,
  editClientLegal,
  IClientListStatsProps,
  ISendFieldsMappingProps,
  sendFieldsMapping,
} from '../services/api/client';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../contex';
import {ClientPersonalityType} from '../services/types';
import {useEffect, useState} from 'react';
import {isThereContent} from '@sportix/sportix-common-modules';

export interface IUseStateCompanyClientListProps
  extends IUseCompanyClientListProps {}

export interface IClientSearchProps {
  keywords: string;
  showLoading?: boolean;
  limit?: number;
  client_type?: ClientPersonalityType;
  companyUuid?: string;
}

export interface IUseStateCompanyClientListReturnType
  extends Omit<IUseCompanyClientListReturnType, 'entityList'> {
  clients: List<ClientModel> | null;
  handleCreateClient: (value: ClientFormDTO) => Promise<ClientModel | void>;
  handleCreateClientLegal: (
    value: ClientLegalFormDTO,
  ) => Promise<ClientModel | void>;
  handleCreateClientGroup: (value: ClientGroupFormDTO) => Promise<void>;
  handleUpdateClient: (value: ClientFormDTO) => Promise<void>;
  handleUpdateClientLegal: (value: ClientLegalFormDTO) => Promise<void>;
  handleUpdateClientGroup: (value: ClientGroupFormDTO) => Promise<void>;
  handleImportClientFields: (value: ISendFieldsMappingProps) => Promise<void>;
  handleDeleteClients: (ids: string[]) => Promise<void>;
  handleSearchClients: (
    value: Partial<IUseCompanyClientListProps>,
  ) => Promise<List<ClientModel> | void>;
  handleCreateLocalClient: (
    value: QuicklyCreateClient | ClientFormDTO | ClientLegalFormDTO,
  ) => ClientModel;
}

export type QuicklyCreateClient = Pick<
  ClientDTO,
  'first_name' | 'last_name' | 'phone' | 'uuid'
>;

export function useStateCompanyClientList(
  {
    companyUuid,
    client_type: clientType,
    ...rest
  }: IUseStateCompanyClientListProps = {} as IUseStateCompanyClientListProps,
): IUseStateCompanyClientListReturnType {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const [localStats, setLocalStats] = useState<IClientListStatsProps>(
    {} as IClientListStatsProps,
  );
  const [page, setPage] = useState(1);

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: clientLoading,
    total: clientListTotal,
    client_type,
    stats,
    keywords,
    ...clientsParams
  } = useCompanyClientList({
    companyUuid,
    client_type: clientType,
    ...rest,
  });

  const {
    entityList: clients,
    loading,
    handleCreate,
    handleDelete,
    handleUpdate,
    setEntityList,
    total,
  } = useStateEntityList<ClientModel>({
    entityList,
    refresh,
    limit,
    offset,
    total: clientListTotal,
  });

  const handleCreateClient = React.useCallback(
    async (value: ClientFormDTO) => {
      const clientFormDTO = ClientMapper.toClientFormDTO(value as any);

      const client = await createClient(clientFormDTO, companyUuid);
      const clientModel = ClientMapper.toClientModel(client);

      const updatedModel = clientModel.set(
        'created_at',
        moment(new Date()).toString(),
      );

      const clientType = client_type || ClientPersonalityType.Individual;

      if (clientType === ClientPersonalityType.Individual) {
        handleCreate(updatedModel, true);
      }

      setLocalStats((prevState) => ({
        ...prevState,
        [ClientPersonalityType.Individual]: `${
          (Number(prevState[ClientPersonalityType.Individual]) || 0) + 1
        }`,
      }));

      alert('success', t('Client'), t('Client created success'));

      return clientModel;
    },
    [alert, client_type, companyUuid, handleCreate, t],
  );

  const handleCreateClientLegal = React.useCallback(
    async (value: ClientLegalFormDTO) => {
      const client = await createClientLegal(value, companyUuid);

      const clientModel = ClientMapper.toClientModel(client);

      const updatedModel = clientModel.set(
        'created_at',
        moment(new Date()).toString(),
      );

      const clientType = client_type || ClientPersonalityType.Individual;

      if (clientType === ClientPersonalityType.Individual) {
        handleCreate(updatedModel, true);
      }

      setLocalStats((prevState) => ({
        ...prevState,
        [ClientPersonalityType.Individual]: `${
          (Number(prevState[ClientPersonalityType.Individual]) || 0) + 1
        }`,
      }));

      return clientModel;
    },
    [client_type, companyUuid, handleCreate],
  );

  const handleUpdateClient = React.useCallback(
    async (value: ClientFormDTO) => {
      const clientFormDTO = ClientMapper.toClientFormDTO(value as any);

      const client = await editClient(clientFormDTO as any);
      const clientModel = ClientMapper.toClientModel(client);

      handleUpdate(clientModel);

      alert('success', t('Client'), t('Client edited success'));
    },
    [alert, handleUpdate, t],
  );

  const handleUpdateClientLegal = React.useCallback(
    async (value: ClientLegalFormDTO) => {
      const client = await editClientLegal(value);
      const clientModel = ClientMapper.toClientModel(client);

      handleUpdate(clientModel);

      alert('success', t('Client'), t('Client edited success'));
    },
    [alert, handleUpdate, t],
  );

  const handleCreateClientGroup = React.useCallback(
    async (value: ClientGroupFormDTO) => {
      const client = await createClientGroup(value, companyUuid);
      const clientModel = ClientMapper.toClientModel(client);

      const updatedModel = clientModel.set(
        'created_at',
        moment(new Date()).toString(),
      );

      if (client_type === ClientPersonalityType.Group) {
        handleCreate(updatedModel, true);
      }

      setLocalStats((prevState) => ({
        ...prevState,
        [ClientPersonalityType.Group]: `${
          (Number(prevState[ClientPersonalityType.Group]) || 0) + 1
        }`,
      }));

      alert('success', t('Client group'), t('Client group created success'));
    },
    [alert, client_type, companyUuid, handleCreate, t],
  );

  const handleUpdateClientGroup = React.useCallback(
    async (value: ClientGroupFormDTO) => {
      const client = await editClientGroup(value as any);
      const clientModel = ClientMapper.toClientModel(client);

      handleUpdate(clientModel);

      alert('success', t('Client group'), t('Client group edited success'));
    },
    [alert, handleUpdate, t],
  );

  const handleDeleteClients = React.useCallback(
    async (ids: string[]) => {
      try {
        await deleteClient(ids);

        handleDelete(ids);

        if (client_type) {
          setLocalStats((prevState) => ({
            ...prevState,
            [client_type as ClientPersonalityType]: `${
              Number((prevState as any)[client_type]) > 0
                ? Number((prevState as any)[client_type]) - 1
                : 0
            }`,
          }));
        }

        alert('success', t('Client'), t('Client delete success'));
      } catch (error: any) {
        alert(
          'error',
          t('Client'),
          `${t('An error occurred during delete clients')}: ${error?.message}`,
        );
      }
    },
    [alert, client_type, handleDelete, t],
  );

  const handleSearchClients = React.useCallback(
    async ({
      keywords,
      limit = 10,
      showLoading = true,
      client_type: ClientType = client_type,
      ...rest
    }: Partial<IUseCompanyClientListProps>) => {
      const clients = await refresh({
        offset: 0,
        limit,
        keywords,
        showLoading,
        companyUuid,
        client_type: ClientType,
        ...rest,
      });

      if (clients) {
        setEntityList(clients);
        return clients;
      }
    },
    [client_type, companyUuid, refresh, setEntityList],
  );

  const handleImportClientFields = React.useCallback(
    async (value: ISendFieldsMappingProps) => {
      const {handled} = await sendFieldsMapping(value);
      await handleSearchClients({keywords: ''});

      alert(
        'success',
        t('Import'),
        `${t('Import success. Added clients')}: ${handled}`,
      );
    },
    [alert, handleSearchClients, t],
  );

  const handleCreateLocalClient = React.useCallback(
    (value: any) => {
      const client = ClientMapper.toClientModel(value as any);

      handleCreate(client);

      return client;
    },
    [handleCreate],
  );

  useEffect(() => {
    if (isThereContent(stats)) {
      setLocalStats(stats);
    }
  }, [stats]);

  useEffect(() => {
    if (client_type !== clientType) {
      (async () => {
        await handleSearchClients({
          keywords,
          client_type: clientType,
        });
      })();
    }
  }, [clientType, client_type, handleSearchClients, keywords]);

  return {
    clients,
    offset,
    limit,
    refresh,
    total,
    client_type,
    loading: clientLoading || loading,
    ...clientsParams,
    handleCreateClient,
    handleUpdateClient,
    handleDeleteClients,
    handleSearchClients,
    handleCreateLocalClient,
    handleCreateClientGroup,
    handleUpdateClientGroup,
    stats: localStats,
    keywords,
    handleCreateClientLegal,
    handleUpdateClientLegal,
    handleImportClientFields,
  };
}
