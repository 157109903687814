import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled, {css} from 'styled-components';
import {Select} from 'antd';
import {listToArray} from '../../../services/helpers';
import {IUseStateCompanyClientListReturnType} from '../../../hooks';
import {useTranslation} from 'react-i18next';
import {List} from 'immutable';
import {ClientModel} from '../../../struture';
import {DetailsItemView} from '../../../components/lib/DataDisplay';

export interface IStoreDocumentClientSearchViewProps
  extends Pick<
    IUseStateCompanyClientListReturnType,
    'clients' | 'handleSearchClients'
  > {
  loading: boolean;
  onClientChange: (clientUuid: string) => Promise<void>;
  clientUuid: string;
  clientListLoading: boolean;
  isProvider?: boolean;
}

export interface DocumentClientListSelect {
  value: string;
  label: React.ReactNode;
}

const StyledSelect = styled(Select)<{disabled: boolean}>`
  width: 200px;
  
  ${({disabled}) =>
    !disabled &&
    css`
      & * > .ant-select-selection-placeholder {
        color: rgba(0, 0, 0, 0.88) !important;
      }
    `}
}
`;

export function StoreDocumentClientSearchView({
  loading,
  onClientChange,
  clientUuid,
  clients,
  clientListLoading,
  handleSearchClients,
  isProvider,
}: IStoreDocumentClientSearchViewProps): React.JSX.Element {
  const {t} = useTranslation();

  const [value, setValue] = useState<string | undefined>(undefined);
  const [selectClients, setSelectClients] = useState<
    DocumentClientListSelect[]
  >([]);

  const once = useRef(false);

  const handleClientChange = useCallback(
    async (clientUuid: any = '') => {
      setValue(clientUuid || undefined);
      await onClientChange(clientUuid);
    },
    [onClientChange],
  );

  useEffect(() => {
    if (!once.current) {
      if (clientUuid) {
        setValue(clientUuid);
      }
    }
  }, [clientUuid]);

  useEffect(() => {
    if (List.isList(clients)) {
      const clientsSelectList = clients?.map((client) => ({
        label: (
          <DetailsItemView<ClientModel>
            item={client}
            fields={{
              fullNameClient: {
                title: '',
              },
              phone: {description: ''},
            }}
          />
        ),
        value: client?.uuid,
      }));

      setSelectClients(listToArray(clientsSelectList));
    }
  }, [clients]);

  return (
    <StyledSelect
      popupMatchSelectWidth={200}
      showSearch
      allowClear
      loading={loading || clientListLoading}
      disabled={clientListLoading || loading}
      onSearch={(keywords) => handleSearchClients({keywords})}
      placeholder={isProvider ? t('Select a provider') : t('Select a client')}
      options={selectClients}
      onChange={handleClientChange}
      value={value}
      size="middle"
    />
  );
}
