/**
 * @desc Повернення телефонного номеру без плюса (+) та пробілів
 * @param {String} str
 * @return {String}
 * */
export function correctPhoneNumber(str) {
  return str.replace(/\s+/g, '').trim();
}

/**
 * @desc Повернення рядка з першою заголовною буквою
 * @param {String} str
 * @return {String}
 * */

export function firstLetterToUppercase(str: string): string {
  if (!str) return str;

  return str[0].toUpperCase() + str.slice(1);
}

/**
 * @desc Повернення назви файлу з абсолютного шляху
 * @param {String} path
 * @return {String}
 * */

export function getFileNameInPath(path: string): string {
  if (typeof path !== 'string') return path;

  return path.split('/').splice(-1).join('');
}

/**
 * @desc Текст в верхньому регістрі
 * @param {String} string
 * @return {String}
 * */

export function textToUpperCase(string: string) {
  if (typeof string !== 'string') return string;

  return string.toUpperCase();
}

/**
 * @desc Отримання розміру тексту в залежності від вхідного параметру
 * @param {String} text
 * @return {Number}
 * */
export const getTextSize = (text: string) => {
  if (text === 'small') {
    return 0.8;
  }
  if (text === 'large') {
    return 1.2;
  }
  return 1;
};

/**
 * @desc Отримання імені користувача
 * @param {String} name
 * @param {String} surname
 * @return {Number}
 * */
export const userName = (name: string, surname: string): string =>
  `${firstLetterToUppercase(name.trim())} ${firstLetterToUppercase(
    surname.trim(),
  )}`;

/**
 * @desc Отримання імені файлу з url
 * @param {String} str
 * @param {String} splitOptions - опції розділення
 * @return {String}
 * */
export const getFileNameInUrl = (
  str: string,
  splitOptions: string = '/',
): string => {
  if (typeof str !== 'string') {
    return str;
  }

  return str.split(splitOptions).splice(-1, 1).join('');
};

/**
 * @desc Отримання об'єкта з JSON рядка
 * @param {Object} object
 * */
export function isJsonParse<T>(object: T): {[p: string]: unknown} {
  return Object.fromEntries(
    Object.entries(object).map(([key, value]) => {
      try {
        const parseValue =
          typeof value === 'string' ? JSON.parse(value) : !value ? '' : value;
        return [key, parseValue];
      } catch (e) {
        return [key, value];
      }
    }),
  );
}

/**
 * @desc Отримання числа зі строки '30%'
 * @param {Number | String} percent
 * */
export const getNumberOfStringPercent = (percent: string | number): number =>
  typeof percent === 'string' ? Number(percent.split('%')[0].trim()) : percent;
