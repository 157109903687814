import {
  EmployeeModel,
  AbonementGroupModel,
  AbonementFormDTO,
} from '../internal';

export class AbonementGroupFormDTO {
  public uuid: string;
  public title: string;
  public comment: string;
  public schedule: string;
  public manager_uuid: string | EmployeeModel;

  constructor(props?: AbonementGroupModel, editMode?: boolean) {
    this.uuid = props?.uuid || '';
    this.title = props?.group_title || '';
    this.comment = props?.group_comment || '';
    this.schedule = AbonementFormDTO.getTimingList(props?.group_schedule_list);

    this.manager_uuid = editMode
      ? props?.managerModel?.uuid
        ? props?.managerModel
        : ''
      : props?.managerModel?.uuid || '';
  }
}
