import {Record} from 'immutable';
import {
  CompanyDTO,
  CompanyMapper,
  CompanyModel,
  DepartmentDTO,
  DepartmentMapper,
  DepartmentModel,
  EmployeeDTO,
  EmployeeMapper,
  EmployeeModel,
  IStoreSettings,
  ProfileDTO,
  ProfileMapper,
  ProfileModel,
  StoreDTO,
  StoreStatusesText,
  StoreType,
} from '../internal';
import {WithoutRecursion} from '../type';

export interface IStoreModelReturnType {
  uuid: string;
  store_name: string;
  store_description: string;
  store_status_text: StoreStatusesText;
  created_at: string;
  updated_at: string;
  company_department: DepartmentModel | DepartmentDTO;
  created_by: ProfileModel | ProfileDTO;
  company: CompanyModel | CompanyDTO;
  manager: EmployeeModel | EmployeeDTO;
  store_settings: IStoreSettings;
  store_type: StoreType;
}

export class StoreModel extends Record<IStoreModelReturnType>({
  uuid: '',
  store_name: '',
  store_description: '',
  store_status_text: StoreStatusesText.DISABLED,
  created_at: '',
  updated_at: '',
  company_department: {} as DepartmentModel,
  company: {} as CompanyModel,
  created_by: {} as ProfileModel,
  manager: {} as EmployeeModel,
  store_settings: {} as IStoreSettings,
  store_type: StoreType.PRODUCT,
}) {
  constructor(
    props: StoreDTO = {} as StoreDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IStoreModelReturnType = {
      ...props,
      company_department:
        withoutRecursion.indexOf(WithoutRecursion.department) !== -1
          ? props?.company_department
          : DepartmentMapper.toDepartmentModel(props?.company_department, [
              WithoutRecursion.store,
              ...withoutRecursion,
            ]),
      created_by:
        withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
          ? props?.created_by
          : ProfileMapper.toProfileModel(props?.created_by, [
              WithoutRecursion.department,
              ...withoutRecursion,
            ]),
      company:
        withoutRecursion.indexOf(WithoutRecursion.company) !== -1
          ? props?.company
          : CompanyMapper.toCompanyModel(props?.company, [
              WithoutRecursion.department,
              ...withoutRecursion,
            ]),

      manager:
        withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
          ? props?.manager
          : EmployeeMapper.toEmployeeModel(props?.manager, [
              WithoutRecursion.department,
              ...withoutRecursion,
            ]),
    };

    super(options);
  }

  get companyModel(): CompanyModel {
    return this.company instanceof CompanyModel
      ? this.company
      : CompanyMapper.toCompanyModel(this.company);
  }

  get createdByModel(): ProfileModel {
    return this.created_by instanceof ProfileModel
      ? this.created_by
      : ProfileMapper.toProfileModel(this.created_by);
  }

  get companyDepartmentModel(): DepartmentModel {
    return this.company_department instanceof DepartmentModel
      ? this.company_department
      : DepartmentMapper.toDepartmentModel(this.company_department);
  }

  get managerModel(): EmployeeModel {
    return this.manager instanceof EmployeeModel
      ? this.manager
      : EmployeeMapper.toEmployeeModel(this.manager);
  }
}
