import {Record, List} from 'immutable';
import {IListProps} from './List';
import {ClientGroupSessionModel, ClientGroupSessionDTO} from '../internal';

export interface IClientsGroupSessionListModelProps extends IListProps {
  clients_group_sessions: ClientGroupSessionDTO[];
}

export interface IClientsGroupSessionListModelReturnType extends IListProps {
  clients_group_sessions: List<ClientGroupSessionModel>;
}

export class ClientsGroupSessionListModel extends Record<IClientsGroupSessionListModelReturnType>(
  {
    clients_group_sessions: List(),
    total: 0,
  },
) {
  constructor(
    {
      clients_group_sessions = [],
      ...props
    }: IClientsGroupSessionListModelProps = {} as IClientsGroupSessionListModelProps,
  ) {
    const options: IClientsGroupSessionListModelReturnType = {
      ...props,
      clients_group_sessions: List(
        clients_group_sessions.map(
          (session) => new ClientGroupSessionModel(session),
        ),
      ),
    };

    super(options);
  }
}
