import * as React from 'react';
import {ApiError, IEntityProps, IListSearchProps} from '../services/types';
import {StoreDocumentMapper, StoreDocumentItemListModel} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {
  getStoreDocumentItemList,
  IStoreDocumentItemListReturnType,
} from '../services/api/storeDocument';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../contex';

export interface IUseStoreDocumentItemListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  documentUuid: string;
  page?: number;
}

export interface IUseStoreDocumentItemListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: StoreDocumentItemListModel | null;
  refresh: (
    value: Partial<IUseStoreDocumentItemListProps>,
  ) => Promise<StoreDocumentItemListModel | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  documentUuid: string;
  page: number;
}

export function useStoreDocumentItemList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    documentUuid,
  }: IUseStoreDocumentItemListProps = {} as IUseStoreDocumentItemListProps,
): IUseStoreDocumentItemListReturnType {
  const {cancellablePromise, didCancel} = useCancellablePromise();
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    documentUuid: listCompanyUuid,
    page: listPage,
    handleUpdate,
  } = useStateReducer<Omit<IUseStoreDocumentItemListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    documentUuid,
    page: 1,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      documentUuid = listCompanyUuid,
      page = listPage,
    }: Partial<IUseStoreDocumentItemListProps> = {}): Promise<StoreDocumentItemListModel | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          documentUuid,
          page,
        });

        const {items, total, document} =
          await cancellablePromise<IStoreDocumentItemListReturnType>(
            getStoreDocumentItemList({
              documentUuid,
              limit,
              offset,
              keywords,
            }),
          );

        const documentListModel =
          StoreDocumentMapper.toStoreDocumentItemListModel(
            items,
            total,
            document,
          );

        handleUpdate({
          entityList: documentListModel,
          total: documentListModel?.total,
          loading: false,
        });

        return documentListModel;
      } catch (e) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }
        alert(
          'error',
          t('Store document'),
          `${t('An error occurred during get document items')} : ${
            error?.message
          }`,
        );
      }
    },
    [
      alert,
      cancellablePromise,
      didCancel,
      error,
      handleUpdate,
      listCompanyUuid,
      listKeywords,
      listLimit,
      listOffset,
      listPage,
      t,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && documentUuid) {
      (async () => {
        await refresh({documentUuid});
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, documentUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    documentUuid: listCompanyUuid,
    page: listPage,
  };
}
