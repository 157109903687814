export const COLOR_GREEN = '#42b351';
export const COLOR_GREEN_APPLE = '#0b8044';
export const COLOR_PURPLE = '#8d24aa';
export const COLOR_BLUE = '#3f51b5';
export const COLOR_YELLOW = '#f6bf26';
export const COLOR_GRAPEFRUIT = '#e67d73';
export const COLOR_RED = '#d50000';
export const COLOR_ORANGE = '#f4501e';
export const COLOR_KIWI = '#33b679';
export const COLOR_WATER = '#039ae5';
export const COLOR_LILAC = '#7987cb';
export const COLOR_GRAY = '#616161';
