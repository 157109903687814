import {Record} from 'immutable';
import {Timestamp, toTimestamp, toImmutableMap} from '../services/core';

export const userModulePrefix = 100;
export const agentModulePrefix = 200;

// User events
export const EventUserRegister = userModulePrefix + 0;
export const EventUserLogin = userModulePrefix + 1;
export const EventUserLogout = userModulePrefix + 2;
export const EventUserChangePassword = userModulePrefix + 3;
export const EventUserRestorePasswordStart = userModulePrefix + 4;
export const EventUserRestorePasswordConfirm = userModulePrefix + 5;
export const EventUserConfirmEmail = userModulePrefix + 6;
export const EventUserCreateContact = userModulePrefix + 7;
export const EventUserDeleteContact = userModulePrefix + 8;
export const EventUserDelete = userModulePrefix + 9;
export const EventUserResendVerificationEmail = userModulePrefix + 10;
export const EventUserChangeName = userModulePrefix + 11;

// Agent events
export const EventAgentInstall = agentModulePrefix + 0;
export const EventAgentRestart = agentModulePrefix + 1;
export const EventAgentUpgrade = agentModulePrefix + 2;
export const EventAgentDelete = agentModulePrefix + 3;
export const EventAgentUninstall = agentModulePrefix + 4;
export const EventAgentCreateCloudCredentials = agentModulePrefix + 5;
export const EventAgentDeleteCloudCredentials = agentModulePrefix + 6;

export interface LogEntryProps {
  id: string;
  event: string;
  userId: string;
  agentId: string;
  ip: string;
  data: Map<any, any>;
  created: any; // TODO: додати тип
}

/**
 * @class LogEntry
 * @classdesc LogEntry model
 */
export default class LogEntry extends Record({
  id: null,
  event: null,
  userId: null,
  agentId: null,
  ip: null,
  data: new Map(),
  created: new Timestamp(),
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(props: LogEntryProps = {} as LogEntryProps) {
    super({
      ...props,
      data: new Map(toImmutableMap(props.data)),
      // @ts-ignore
      created: toTimestamp(props.created),
    });
  }

  /**
   * @name getEventCode
   * @desc Return event code as string
   * @returns {string}
   */
  getEventCode(): string {
    switch (this.event) {
      // user events
      case EventUserRegister:
        return 'EventUserRegister';
      case EventUserLogin:
        return 'EventUserLogin';
      case EventUserLogout:
        return 'EventUserLogout';
      case EventUserChangePassword:
        return 'EventUserChangePassword';
      case EventUserRestorePasswordStart:
        return 'EventUserRestorePasswordStart';
      case EventUserRestorePasswordConfirm:
        return 'EventUserRestorePasswordConfirm';
      case EventUserConfirmEmail:
        return 'EventUserConfirmEmail';
      case EventUserCreateContact:
        return 'EventUserCreateContact';
      case EventUserDeleteContact:
        return 'EventUserDeleteContact';
      case EventUserDelete:
        return 'EventUserDelete';
      case EventUserResendVerificationEmail:
        return 'EventUserResendVerificationEmail';
      case EventUserChangeName:
        return 'EventUserChangeName';

      // agent events
      case EventAgentInstall:
        return 'EventAgentInstall';
      case EventAgentRestart:
        return 'EventAgentRestart';
      case EventAgentUpgrade:
        return 'EventAgentUpgrade';
      case EventAgentDelete:
        return 'EventAgentDelete';
      case EventAgentUninstall:
        return 'EventAgentUninstall';
      case EventAgentCreateCloudCredentials:
        return 'EventAgentCreateCloudCredentials';
      case EventAgentDeleteCloudCredentials:
        return 'EventAgentDeleteCloudCredentials';

      default:
        return 'UnknownEvent';
    }
  }

  /**
   * @name getEventDescription
   * @desc Return event description for each event
   * @returns {string}
   */
  getEventDescription(): string {
    switch (this.event) {
      // user events
      case EventUserRegister:
        return 'User registered';
      case EventUserLogin:
        return 'User signed in';
      case EventUserLogout:
        return 'User signed out';
      case EventUserChangePassword:
        return 'User updated the password';
      case EventUserRestorePasswordStart:
        return 'User requested a password reset';
      case EventUserRestorePasswordConfirm:
        return 'User confirmed the password reset';
      case EventUserConfirmEmail:
        return 'User verified the email address';
      case EventUserCreateContact:
        return 'New contact information was added';
      case EventUserDeleteContact:
        return 'User deleted contact information';
      case EventUserDelete:
        return 'User requested account deletion';
      case EventUserResendVerificationEmail:
        return 'User requested email verification to be re-sent';
      case EventUserChangeName:
        return 'User updated the profile information';

      // agent events
      case EventAgentInstall:
        return 'Installed new Agent';
      case EventAgentRestart:
        return 'Restart Agent';
      case EventAgentUpgrade:
        return 'Upgrade Agent';
      case EventAgentDelete:
        return 'Delete Agent';
      case EventAgentUninstall:
        return 'Agent uninstalled';
      case EventAgentCreateCloudCredentials:
        return 'New Cloud Credential created';
      case EventAgentDeleteCloudCredentials:
        return 'Cloud Credential deleted';

      default:
        return 'Unknown event';
    }
  }
}
