import React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {EditOutlined, DeleteOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import {Tooltip} from 'antd';
import {UpdateUserButton, DeleteUserButton} from '../Buttons';
import {
  ITableProps,
  Table,
  TableActionCell,
} from '../../../components/lib/DataDisplay';
import {isThereContent, isRecordToObject} from '../../../services/helpers';
import {useTableChange, IUseUserListProps} from '../../../hooks';
import {ICompanyUserParams} from '../../../services/api/companyUser';
import {User} from '../../../services/models';
import {Routes, RowSelection} from '../../../services/types';
import {FlexContainer} from '../../../components/lib/Styled';
import {COMPANY_TABS} from '../../Company';
import {TextFormat} from '../../../components/lib/Format';
import {PROFILE_TAB} from '../../Profile';
import {TableLinkCell} from '../../../components/lib/libV2/DataDisplay';

export default withTranslation()(UserList);

export interface IUserListProps
  extends WithTranslation,
    Omit<ITableProps<any>, 'columns'> {
  users: User[];
  loading: boolean;
  limit?: number;
  onRefresh: (value: Partial<IUseUserListProps>) => Promise<User[] | void>;
  rowSelection: RowSelection<User>;
  pageSize: number;
  total: number;
  handleUpdate: (value: ICompanyUserParams) => Promise<void>;
  handleDelete: (ids: string[]) => Promise<void>;
  defaultCompanyUuid: string;
}

function UserList({
  t,
  users,
  limit = 10,
  loading,
  onRefresh = async () => {},
  rowSelection,
  handleUpdate,
  handleDelete,
  total,
  defaultCompanyUuid,
  ...rest
}: IUserListProps) {
  const {currentPage, handleTableChange} = useTableChange({
    page: 1,
    pageSize: limit,
    callback: onRefresh,
    total,
  });

  const columns = [
    {
      title: t('Full Name'),
      key: 'fullName',
      render: (c: User) => (
        <TableLinkCell
          tooltip=""
          fullPath={`${location.pathname}/show/${c?.uuid}/${PROFILE_TAB.NOTIFICATIONS}`}>
          <Tooltip title={t('Open a detailed description of the user')}>
            {c?.fullName}
          </Tooltip>
        </TableLinkCell>
      ),
    },
    {
      title: t('Company'),
      key: 'company',
      render: (c: User) => (
        <>
          {c?.default_company?.uuid ? (
            <Link
              to={{
                pathname: `/${Routes.app}/companies/show/${c?.default_company?.uuid}/${COMPANY_TABS.CALENDAR}`,
              }}>
              <Tooltip title={t('Open a detailed description of the company')}>
                {c?.default_company?.title}
              </Tooltip>
            </Link>
          ) : (
            t('Not tied to any company')
          )}
        </>
      ),
    },
    {
      title: t('Subscriptions'),
      key: 'email',
      render: (c: User) => (
        <>
          {isThereContent(c?.profile_subscriptions)
            ? c?.profile_subscriptions.map((subscription) => (
                <FlexContainer
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column">
                  <TextFormat primary>
                    {subscription?.system_subscription?.subscription_title}
                  </TextFormat>
                </FlexContainer>
              ))
            : t('There are no subscriptions')}
        </>
      ),
    },
    {
      title: t('Status'),
      key: 'status',
      render: (c: User) => c?.status_text || 'No status',
    },
    {
      title: t('Actions'),
      key: 'user_actions',
      align: 'center',
      render: (record: any) => (
        <TableActionCell>
          <UpdateUserButton
            defaultCompanyUuid={defaultCompanyUuid}
            type="text"
            user={isRecordToObject(record)}
            onSuccess={handleUpdate}>
            <EditOutlined />
          </UpdateUserButton>

          <DeleteUserButton
            users={[isRecordToObject(record)]}
            onSuccess={handleDelete}>
            <DeleteOutlined />
          </DeleteUserButton>
        </TableActionCell>
      ),
    },
  ];

  return (
    <Table
      {...rest}
      tableLayout="fixed"
      loading={loading}
      rowKey="uuid"
      dataSource={users}
      columns={columns}
      pagination={{
        current: currentPage,
        pageSize: limit,
        hideOnSinglePage: true,
        total,
      }}
      size="small"
      onChange={handleTableChange}
      onRow={(record: User, index: number | undefined): any => ({
        'data-testid': `company-users-list-row-${index}`,
      })}
      rowSelection={rowSelection}
    />
  );
}
