import {
  ClientModel,
  StoreDocumentFormDTO,
  StoreDocumentModel,
  StoreModel,
} from '../../struture';
import {areDeeplyEqualIgnoreFields} from '../../services/helpers';

export interface StoreDocumentRefObject {
  document: StoreDocumentFormDTO;
  initStoreUuid: string;
}

export const isEqualStoreDocumentFields = (
  document: StoreDocumentFormDTO,
  refObject: StoreDocumentRefObject,
) => {
  const editedStoreDocument = refObject?.document;
  const initStoreUuid = refObject?.initStoreUuid;

  const isEqual = areDeeplyEqualIgnoreFields(document, refObject?.document, [
    'doc_store_uuid',
    'doc_client',
    'doc_destination_store_uuid',
    'doc_related_document_uuid',
  ]);

  const storeUuid =
    document?.doc_store_uuid instanceof StoreModel
      ? document?.doc_store_uuid?.uuid
      : document?.doc_store_uuid;

  const storeDestinationUuid =
    document?.doc_destination_store_uuid instanceof StoreModel
      ? document?.doc_destination_store_uuid?.uuid
      : document?.doc_destination_store_uuid;

  const clientUuid =
    document?.doc_client instanceof ClientModel
      ? document?.doc_client?.uuid
      : document?.doc_client?.uuid;

  const relatedDocumentUuid =
    document?.doc_related_document_uuid instanceof StoreDocumentModel
      ? document?.doc_related_document_uuid?.uuid
      : document?.doc_related_document_uuid;

  const isEqualStore =
    (initStoreUuid || storeUuid) === editedStoreDocument?.doc_store_uuid;
  const isEqualDestinationStore =
    storeDestinationUuid === editedStoreDocument?.doc_destination_store_uuid;

  const isEqualClient = clientUuid === editedStoreDocument?.doc_client?.uuid;

  const isEqualRelatedDocumentUuid =
    relatedDocumentUuid === editedStoreDocument?.doc_related_document_uuid;

  return {
    isEqual,
    isEqualStore,
    isEqualClient,
    isEqualDestinationStore,
    isEqualRelatedDocumentUuid,
  };
};
