/**
 * @module error
 */

/**
 * @class ApiError
 * @classdesc Api error class
 * @abstract
 */
export class ApiError extends Error {
  /**
   * @constructor
   * @param {{error:string,code:number}} param0
   */
  constructor({error = 'Unknown error', code = 500} = {}) {
    super(error);
    this.code = code;
  }
}

/**
 * @class InternalError
 * @classdesc Thrown when a request returns "500 Internal Server Error" status.
 * @abstract
 */
export class InternalError extends ApiError {
  /**
   * @constructor
   * @param {{error:string,code:number}} param0
   */
  constructor({error = 'Unauthorized', code = 500} = {}) {
    super({error, code});
  }
}

/**
 * @class UnauthorizedError
 * @classdesc Thrown when a request returns "401 Unauthorized" status.
 * @extends ApiError
 */
export class UnauthorizedApiError extends ApiError {
  /**
   * @constructor
   * @param {{error:string,code:number}} param0
   */
  constructor({error = 'Unauthorized', code = 401} = {}) {
    super({error, code});
  }
}

/**
 * @class ForbiddenApiError
 * @classdesc Thrown when a request returns "403 Forbidden" status.
 */
export class ForbiddenApiError extends ApiError {
  /**
   * @constructor
   * @param {{error:string,code:number}} param0
   */
  constructor({error = 'Forbidden', code = 403} = {}) {
    super({error, code});
  }
}

/**
 * @class NotFoundApiError
 * @classdesc Thrown when a request returns "404 NotFound" status.
 */
export class NotFoundApiError extends ApiError {
  /**
   * @constructor
   * @param {{error:string,code:number}} param0
   */
  constructor({error = 'NotFound', code = 404} = {}) {
    super({error, code});
  }
}

/**
 * @class ConfictApiError
 * @classdesc Thrown when a request returns "409 Conflict" status.
 */
export class ConfictApiError extends ApiError {
  /**
   * @constructor
   * @param {{error:string,code:number}} param0
   */
  constructor({error = 'Confict', code = 409} = {}) {
    super({error, code});
  }
}

/**
 * @class GeneralError
 * @classdesc General api error. Can be thrown anywhere.
 * @extends ApiError
 */
export class GeneralApiError extends ApiError {}
