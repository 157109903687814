import * as React from 'react';
import {getStoreDocumentById} from '../services/api/storeDocument';
import {
  StoreDocumentModel,
  StoreDocumentMapper,
  StoreDocumentDTO,
  ScheduleDocumentType,
} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {ApiAnswer, IEntityProps} from '../services/types';
import {useDropdownAlert} from '../contex';
import {useTranslation} from 'react-i18next';
import {apiGet} from '../services/core/api';
import {contentApiUrl} from '../services/const';

export interface IUseStoreDocumentProps extends IEntityProps {
  documentUuid: string;
}

export interface IUseStoreDocumentReturnType {
  error: null | any;
  loading: boolean;
  entity: StoreDocumentModel | null;
  refresh: (
    value: Partial<IUseStoreDocumentProps>,
  ) => Promise<StoreDocumentModel | void>;
  documentUuid: string;
}

export function useStoreDocument(
  {
    loadOnInit = true,
    documentUuid,
  }: IUseStoreDocumentProps = {} as IUseStoreDocumentProps,
): IUseStoreDocumentReturnType {
  const {alert} = useDropdownAlert();
  const {t} = useTranslation();
  const {cancellablePromise} = useCancellablePromise();
  const {
    entity,
    loading,
    error,
    handleUpdate,
    documentUuid: entityUuid,
  } = useStateReducer<Omit<IUseStoreDocumentReturnType, 'refresh'>>({
    entity: null,
    documentUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading,
      documentUuid = entityUuid,
    }: Partial<IUseStoreDocumentProps> = {}): Promise<StoreDocumentModel | void> => {
      try {
        handleUpdate({loading: showLoading, error: null, documentUuid});

        const document = await cancellablePromise<StoreDocumentDTO>(
          getStoreDocumentById(documentUuid),
        );

        const documentModel =
          StoreDocumentMapper.toStoreDocumentModel(document);

        handleUpdate({
          entity: documentModel,
          loading: false,
        });
        return documentModel;
      } catch (err: any) {
        handleUpdate({
          error: err,
          loading: false,
        });
        alert(
          'error',
          t('Store document'),
          `${t('An error occurred during get store document')} : ${
            err?.message
          }`,
        );
      }
    },

    [alert, cancellablePromise, entityUuid, handleUpdate, t],
  );

  React.useEffect(() => {
    if (loadOnInit && documentUuid) {
      (async () => {
        await refresh({documentUuid});
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, documentUuid]);

  return {
    error,
    loading,
    refresh,
    entity,
    documentUuid,
  };
}
