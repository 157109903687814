import {Record, List} from 'immutable';
import {ListModel, ListProps} from './List';
import Paymentable, {IPaymentableProps} from './Paymentable';

export interface PaymentableListProps extends ListProps {
  paymentables: IPaymentableProps[];
}

export default class PaymentableList extends Record({
  paymentables: List(),
  ...ListModel.toJS(),
}) {
  /**
   * @constructor
   * @param {{clients:[]}} param0
   */
  constructor({paymentables = [], ...props}: PaymentableListProps) {
    const options: any = {};
    if (Array.isArray(paymentables)) {
      options.paymentables = List(
        paymentables.map((client) => new Paymentable(client)),
      );
    }

    super({
      ...props,
      ...options,
    });
  }
}
