import {Record, List} from 'immutable';
import {IListProps} from './List';
import {WithoutRecursion} from '../type';
import {CashBoxModel, CashBoxDTO} from '../internal';

export interface ICashBoxListModelProps extends IListProps {
  cashboxes: CashBoxDTO[];
}

export interface ICashBoxListModelReturnType extends IListProps {
  cashboxes: List<CashBoxModel>;
}

export class CashBoxListModel extends Record<ICashBoxListModelReturnType>({
  cashboxes: List(),
  total: 0,
}) {
  constructor(
    {
      cashboxes = [],
      total,
    }: ICashBoxListModelProps = {} as ICashBoxListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: ICashBoxListModelReturnType = {
      cashboxes: List(
        cashboxes.map((box) => new CashBoxModel(box, withoutRecursion)),
      ),
      total,
    };

    super(options);
  }
}
