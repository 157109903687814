import {Record, List} from 'immutable';
import {ListModel, ListProps} from './List';
import Employee, {EmployeeProps} from './Employee';

export interface EmployeeListProps extends ListProps {
  employees: EmployeeProps[];
}

/**
 * @class EmployeeList
 * @classdesc Employee list model.
 * @extends {Record}
 * @property {List} employees - list of companies
 * @property {number} total - total amount of records on backend
 */
export default class EmployeeList extends Record({
  employees: List(),
  ...ListModel.toJS(),
}) {
  /**
   * @constructor
   * @param {{employees:[]}} param0
   */
  constructor({employees = [], ...props}: EmployeeListProps) {
    super({
      employees: List(employees.map((employee) => new Employee(employee))),
      ...props,
    });
  }
}
