import {Record, List} from 'immutable';
import {IListProps} from './List';
import {SubscriptionDTO, SubscriptionModel} from '../internal';

export interface ISubscriptionListModelProps extends IListProps {
  subscriptions: SubscriptionDTO[];
}

export interface ISubscriptionListModelReturnType extends IListProps {
  subscriptions: List<SubscriptionModel>;
}

export class SubscriptionListModel extends Record<ISubscriptionListModelReturnType>(
  {
    subscriptions: List(),
    total: 0,
  },
) {
  constructor(
    {
      subscriptions = [],
      total,
    }: ISubscriptionListModelProps = {} as ISubscriptionListModelProps,
  ) {
    const options: ISubscriptionListModelReturnType = {
      total: total,
      subscriptions: List(),
    };

    if (Array.isArray(subscriptions)) {
      options.subscriptions = List(
        subscriptions.map(
          (subscription) => new SubscriptionModel(subscription),
        ),
      );
    }

    super({
      ...options,
    });
  }
}
