import React, {
  JSX,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {ScheduleCalendarModel} from '../../../struture';
import {Table} from '../../../components/lib/libV2/DataDisplay';
import {useTranslation} from 'react-i18next';
import {useStateClientOrderList, useStoredCompanies} from '../../../hooks';
import {correctPrice, last} from '../../../services/helpers';
import {TableIndexField} from '../../../components/lib/DataDisplay';
import {OrderDetailsView} from '../../Orders';
import styled from 'styled-components';

import '../../Orders/List/OrderList.less';
import '../../Products/Pages/ProductList.less';
import '../../Stores/Forms/StoreProductListField.less';
import {GetCompanyScheduleState} from '../../../services/api/company';

export interface PaymentOrderTableProps {
  selectedItem: ScheduleCalendarModel | unknown;
  residentUuid: string;
  handleSelectDestination: (
    payment_destination_object_uuid: string,
    payment_sum: string,
    comment: string,
  ) => void;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`;

export function PaymentOrderTable({
  selectedItem,
  residentUuid,
  handleSelectDestination,
}: PaymentOrderTableProps): JSX.Element {
  const {t} = useTranslation();
  const {defaultCompanyUuid, defaultCompany} = useStoredCompanies();

  const [selectedSchedules, setSelectedSchedules] = useState<
    ScheduleCalendarModel[]
  >([]);

  const isSelectOnce = useRef(false);

  const getSum = useCallback((schedule: ScheduleCalendarModel) => {
    const paid = Number(schedule?.sum_paid) || 0;
    const total = Number(schedule?.sum_total) || 0;
    return correctPrice(total - paid);
  }, []);

  const {schedules, loading, total, limit, refresh} = useStateClientOrderList({
    clientUuid: residentUuid,
    loadOnInit: !!residentUuid,
    sort: 'createdAt',
    state: GetCompanyScheduleState.UNPAID,
  });

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: selectedSchedules?.map(({uuid}) => uuid),
      onChange: (
        selectedRowKeys: React.Key[],
        selectedRecords: ScheduleCalendarModel[],
      ) => {
        const schedule = last(selectedRecords);

        if (schedule) {
          isSelectOnce.current = true;
          setSelectedSchedules([schedule]);
          handleSelectDestination(
            schedule?.uuid,
            getSum(schedule),
            `${t('Payment of funds according to the order')} №${
              schedule?.schedule_number
            }${
              schedule?.schedule_title ? ` (${schedule?.schedule_title})` : ''
            }`,
          );
        } else {
          setSelectedSchedules([]);
          handleSelectDestination('', correctPrice(0), '');
        }
      },
    }),
    [getSum, handleSelectDestination, selectedSchedules, t],
  );

  const columns = [
    {
      ellipsis: true,
      title: `№`,
      key: 'index',
      align: 'center' as any,
      className: 'order-list-td order-list--middle-text',
      render: (schedule: ScheduleCalendarModel, items: any, index: number) => (
        <TableIndexField number={(1 - 1) * limit + index + 1} />
      ),
    },
    {
      ellipsis: true,
      title: t('Name'),
      key: 'name',
      render: (schedule: ScheduleCalendarModel) => (
        <OrderDetailsView schedule={schedule} withoutNavigate />
      ),
    },
    {
      ellipsis: true,
      title: `${t('Sum')}, ${defaultCompany?.currency_symbol || ''}`,
      key: 'amount',
      align: 'right' as any,
      render: (schedule: ScheduleCalendarModel) => (
        <StyledContainer>{getSum(schedule)}</StyledContainer>
      ),
    },
  ];

  // useEffect(() => {
  //   if (resident_uuid !== residentUuid) {
  //     (async () => {
  //       await handleRefreshSchedules({
  //         resident_uuid: residentUuid,
  //         page: 1,
  //         showLoading: true,
  //       });
  //     })();
  //   }
  // }, [handleRefreshSchedules, residentUuid, resident_uuid]);

  useEffect(() => {
    if (
      selectedItem instanceof ScheduleCalendarModel &&
      !selectedSchedules?.length &&
      !isSelectOnce.current
    ) {
      setSelectedSchedules([selectedItem]);
      isSelectOnce.current = true;
    }
  }, [selectedSchedules?.length, selectedItem]);

  return (
    <Table<ScheduleCalendarModel>
      pageSize={limit}
      total={total}
      onChange={refresh}
      rowSelection={rowSelection}
      dataSource={schedules}
      columns={columns}
      loading={loading}
    />
  );
}
