import * as React from 'react';
import {IEntityProps, ApiError, IListSearchProps} from '../services/types';
import {PlanModel, IPlanListDTO, PlanMapper, PlanListModel} from '../struture';
import {List} from 'immutable';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {getSubscriptionList} from '../services/api/subscriptions';
import {len} from '../services/helpers';

export interface IUsePlanListProps
  extends Partial<IListSearchProps>,
    IEntityProps {}

export interface IUsePlanListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<PlanModel> | null;
  refresh: (value: Partial<IUsePlanListProps>) => Promise<PlanListModel | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
}

export function usePlanList({
  loadOnInit = true,
  keywords = '',
  limit = 10,
  offset = 0,
}: IUsePlanListProps = {}): IUsePlanListReturnType {
  const {cancellablePromise, didCancel} = useCancellablePromise();
  const {
    entityList,
    loading,
    error,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    handleUpdate,
  } = useStateReducer<Omit<IUsePlanListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
    }: Partial<IUsePlanListProps> = {}): Promise<PlanListModel | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
        });

        const {plans, total} = await cancellablePromise<IPlanListDTO>(
          getSubscriptionList(),
        );

        const planListModel = PlanMapper.toPlanListModel(
          plans,
          total || len(plans) || 0,
        );

        if (!didCancel.current) {
          handleUpdate({
            entityList: planListModel?.plans,
            total: planListModel?.total,
            loading: false,
          });

          return planListModel;
        }
      } catch (e) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }
      }
    },
    [
      cancellablePromise,
      didCancel,
      error,
      handleUpdate,
      listKeywords,
      listLimit,
      listOffset,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit) {
      (async () => {
        await refresh();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
  };
}
