import * as React from 'react';
import {getPriceMarginById} from '../services/api/priceMargin';
import {PriceMarginModel, PriceMarginMapper, PriceMarginDTO} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {IEntityProps} from '../services/types';
import {useDropdownAlert} from '../contex';
import {useTranslation} from 'react-i18next';

export interface IUsePriceMarginProps extends IEntityProps {
  priceMarginUuid: string;
}

export interface IUsePriceMarginReturnType {
  error: null | any;
  loading: boolean;
  entity: PriceMarginModel | null;
  refresh: (
    value: Partial<IUsePriceMarginProps>,
  ) => Promise<PriceMarginModel | void>;
  priceMarginUuid: string;
}

export function usePriceMargin(
  {
    loadOnInit = true,
    priceMarginUuid,
  }: IUsePriceMarginProps = {} as IUsePriceMarginProps,
): IUsePriceMarginReturnType {
  const {alert} = useDropdownAlert();
  const {t} = useTranslation();
  const {cancellablePromise, didCancel} = useCancellablePromise();
  const {
    entity,
    loading,
    error,
    handleUpdate,
    priceMarginUuid: entityUuid,
  } = useStateReducer<Omit<IUsePriceMarginReturnType, 'refresh'>>({
    entity: null,
    priceMarginUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading,
      priceMarginUuid = entityUuid,
    }: Partial<IUsePriceMarginProps> = {}): Promise<PriceMarginModel | void> => {
      try {
        handleUpdate({loading: showLoading, error: null, priceMarginUuid});

        const priceMargin = await cancellablePromise<PriceMarginDTO>(
          getPriceMarginById(priceMarginUuid),
        );

        const priceMarginModel =
          PriceMarginMapper.toPriceMarginModel(priceMargin);

        if (!didCancel.current) {
          handleUpdate({
            entity: priceMarginModel,
            loading: false,
          });
          return priceMarginModel;
        }
      } catch (err: any) {
        if (!didCancel.current) {
          handleUpdate({
            error: err,
            loading: false,
          });
        }
        alert(
          'error',
          t('Price margin'),
          `${t('An error occurred during get price margin')} : ${err?.message}`,
        );
      }
    },

    [alert, cancellablePromise, didCancel, entityUuid, handleUpdate, t],
  );

  React.useEffect(() => {
    if (loadOnInit && priceMarginUuid) {
      (async () => {
        await refresh({priceMarginUuid});
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, priceMarginUuid]);

  return {
    error,
    loading,
    refresh,
    entity,
    priceMarginUuid,
  };
}
