import {Dispatch} from 'redux';
import {CLIENT_VISIT_LIST} from '../constants';
import {ClientVisitListModel, ClientVisitModel} from '../../struture';
import {ApiError} from '../../services/types';

export interface ISetClientVisitListParam {
  type: CLIENT_VISIT_LIST;
  visitList: ClientVisitListModel;
  abonementUuid: string;
  keywords?: string;
  total?: number;
  page?: number;
}

export interface IAddClientVisitToListParam {
  type: CLIENT_VISIT_LIST;
  visit: ClientVisitModel;
}

export interface IUpdateClientVisitFromListParam {
  type: CLIENT_VISIT_LIST;
  visit: ClientVisitModel;
}

export interface IDeleteClientVisitFromListParam {
  type: CLIENT_VISIT_LIST;
  visitUuid: string;
}

export interface IErrorClientVisitListParam {
  type: CLIENT_VISIT_LIST;
  error: ApiError | undefined;
}

export function setClientVisitList(
  visitList: ClientVisitListModel,
  abonementUuid: string,
  keywords?: string,
  total?: number,
  page?: number,
): (dispatch: Dispatch) => Promise<ISetClientVisitListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CLIENT_VISIT_LIST.SET_CLIENT_VISIT_LIST,
      visitList,
      keywords,
      total,
      page,
      abonementUuid,
    });
  };
}

export function loadMoreClientVisitList(
  visitList: ClientVisitListModel,
  abonementUuid: string,
): (dispatch: Dispatch) => Promise<ISetClientVisitListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CLIENT_VISIT_LIST.LOAD_MORE_CLIENT_VISIT_LIST,
      visitList,
      abonementUuid,
    });
  };
}

export function addClientVisitToList(
  visit: ClientVisitModel,
  abonementUuid: string,
): (dispatch: Dispatch) => Promise<IAddClientVisitToListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CLIENT_VISIT_LIST.ADD_CLIENT_VISIT,
      visit,
      abonementUuid,
    });
  };
}

export function updateClientVisitFromList(
  visit: ClientVisitModel,
  abonementUuid: string,
): (dispatch: Dispatch) => Promise<IUpdateClientVisitFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CLIENT_VISIT_LIST.UPDATE_CLIENT_VISIT,
      visit,
      abonementUuid,
    });
  };
}

export function deleteClientVisitFromList(
  visitUuid: string,
  abonementUuid: string,
): (dispatch: Dispatch) => Promise<IDeleteClientVisitFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CLIENT_VISIT_LIST.DELETE_CLIENT_VISIT,
      visitUuid,
      abonementUuid,
    });
  };
}

export function errorClientVisitInList(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorClientVisitListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CLIENT_VISIT_LIST.ERROR_CLIENT_VISIT_LIST,
      error,
    });
  };
}
