import {PRICE_MARGIN} from '../constants';
import {PriceMarginModel} from '../../struture';
import {ApiError} from '../../services/types';
import {Dispatch} from 'redux';

export interface ISetPriceMarginParam {
  type: PRICE_MARGIN;
  priceMargin: PriceMarginModel | null;
}

export interface IUpdatePriceMarginParam {
  type: PRICE_MARGIN;
  priceMargin: PriceMarginModel;
}

export interface IResetPriceMarginParam {
  type: PRICE_MARGIN;
}

export interface IErrorPriceMarginParam {
  type: PRICE_MARGIN;
  error: ApiError | undefined;
}

export function setPriceMargin(
  priceMargin: PriceMarginModel | null,
): (dispatch: Dispatch) => Promise<ISetPriceMarginParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRICE_MARGIN.SET_PRICE_MARGIN,
      priceMargin,
    });
  };
}

export function updatePriceMargin(
  priceMargin: PriceMarginModel,
): (dispatch: Dispatch) => Promise<IUpdatePriceMarginParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRICE_MARGIN.UPDATE_PRICE_MARGIN,
      priceMargin,
    });
  };
}

export function resetPriceMargin(): (
  dispatch: Dispatch,
) => Promise<IResetPriceMarginParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRICE_MARGIN.RESET_PRICE_MARGIN,
    });
  };
}

export function errorPriceMargin(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorPriceMarginParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRICE_MARGIN.ERROR_PRICE_MARGIN,
      error,
    });
  };
}
