import {MailingTemplateModel, MAILING_TEMPLATES_TYPE} from '../internal';

export class MailingTemplateFormDTO {
  public template_type: MAILING_TEMPLATES_TYPE;

  public template_uuid: string;

  public template_title: string;

  public template_event: string;

  public template_body: string;

  public template_comment: string;

  constructor(props?: MailingTemplateModel) {
    this.template_type = props?.template_type || MAILING_TEMPLATES_TYPE.EMAIL;

    this.template_uuid = props?.template_uuid || '';

    this.template_title = props?.template_title || '';

    this.template_event = props?.template_event || '';

    this.template_body = props?.template_body || '';

    this.template_comment = props?.template_comment || '';
  }
}
