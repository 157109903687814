import {Record} from 'immutable';

export interface CountryProps {
  id: string;
  name: string;
  code: string;
}

/**
 * @class Country
 * @classdesc Country model.
 * @property {string} id
 * @property {string} name
 * @property {string} code
 */
export default class Country extends Record({
  id: null,
  name: '',
  code: '',
}) {}
