import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {IActionButtonProps} from '../../../components/lib/General';
import {useModal} from '../../../components/lib/libV2/hooks';
import {MaterialAndExpenseFormDTO} from '../../../struture';
import {AddButton, SuspenseEmpty} from '../../../components/lib/DataDisplay';

const MaterialAndExpenseSideWindowLazy = React.lazy(
  () => import('../Show/MaterialAndExpenseSideWindow'),
);

export interface IMaterialAndExpenseCreateButtonProps
  extends Omit<IActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: MaterialAndExpenseFormDTO) => Promise<void>;
  onCancel?: () => void;
  materialAndExpense: MaterialAndExpenseFormDTO;
  materialAndExpenseUuid: string;
}

export default function RelatedMaterialAndExpenseCreateButton({
  children,
  onSuccess,
  onCancel,
  materialAndExpense,
  materialAndExpenseUuid,
  loading,
  ...rest
}: IMaterialAndExpenseCreateButtonProps): JSX.Element {
  const {t} = useTranslation();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <AddButton
        tooltipTitle={t('Create material and expense')}
        onClick={handleOnInit}
        loading={loading}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <MaterialAndExpenseSideWindowLazy
          materialAndExpense={materialAndExpense}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
          materialAndExpenseUuid={materialAndExpenseUuid}
        />
      </Suspense>
    </>
  );
}
