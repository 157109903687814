import React from 'react';
import {StoreDocumentItemFormDTO} from '../../../struture';
import styled, {css} from 'styled-components';
import {StyledDescription, StyledTitle} from '../../../components/lib/Styled';
import {correctPrice} from '../../../services/helpers';

export interface StoreDocItemsPriceViewProps {
  docItem: StoreDocumentItemFormDTO;
  isLineThrough?: boolean;
  multiplier?: number;
}

const StyledContainer = styled.div<{
  $isLineThrough: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 5px;

  ${({$isLineThrough}) =>
    $isLineThrough &&
    css`
      text-decoration: line-through;
    `}
`;

export function StoreDocItemsPriceView({
  docItem,
  isLineThrough,
  multiplier = 1,
}: StoreDocItemsPriceViewProps): React.JSX.Element {
  return (
    <StyledContainer $isLineThrough={!!isLineThrough}>
      <StyledTitle>
        {correctPrice(Number(docItem?.base_price || 0) * multiplier)}
      </StyledTitle>
      {!(docItem?.price_currency === '₴' || docItem?.price_currency === '') ? (
        <StyledDescription>{`${docItem?.price_currency}: ${correctPrice(
          Number(docItem?.price || 0) * multiplier,
        )}`}</StyledDescription>
      ) : null}
    </StyledContainer>
  );
}
