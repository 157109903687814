import React, {useCallback, useEffect, useRef} from 'react';
import {Col, Form, Input, InputNumber, Row} from 'antd';
import styled from 'styled-components';
import {SearchSelect} from '../../../components/lib/DataDisplay';
import {useTranslation} from 'react-i18next';
import {useSize, useStoredCompanies} from '../../../hooks';
import {useDefaultForm} from '../../../contex';
import {
  DEFAULT_UAH_CURRENCY,
  InvoiceItemPositionableType,
  ProductModel,
  StoreDocumentMultiCurrencyDTOProps,
} from '../../../struture';
import {phoneScreen} from '../../../services/const';
import {listToArray, correctPrice} from '../../../services/helpers';
import {useInvoiceManager} from '../Managers';

export interface InvoiceFieldsProps {
  loading: boolean;
  multiCurrencies: StoreDocumentMultiCurrencyDTOProps[];
  isShowCurrencySelect: boolean;
}

const FullWidthInputNumber = styled(InputNumber)`
  width: 100%;
`;

const StyledSearchSelect = styled(SearchSelect)`
  width: 100px;
`;

export function InvoiceItemFields({
  loading,
  multiCurrencies,
  isShowCurrencySelect,
}: InvoiceFieldsProps): React.JSX.Element {
  const {t} = useTranslation();
  const {loadingSubmit, formData, handlerUpdateFormState} = useDefaultForm();
  const {defaultCompany} = useStoredCompanies();
  const {priceMarginList, getProductPriceMargin} = useInvoiceManager();

  const contentRef = useRef(null);
  const amountRef = useRef<HTMLInputElement>(null);
  const {width: contentWidth} = useSize(contentRef);
  const isFullWidth = contentWidth <= phoneScreen ? 24 : 12;

  const handleUpdateAmount = useCallback(
    (item_amount: any) => {
      const currency = multiCurrencies?.find(
        ({title}) => title === formData?.item_currency,
      );
      const rate = Number(currency?.rate || 1);

      const price = formData?.item_price;
      const numPrice = Number(price || 1) * rate;

      handlerUpdateFormState({
        item_amount,
        total: correctPrice(numPrice * item_amount),
      });
    },
    [
      formData?.item_currency,
      formData?.item_price,
      handlerUpdateFormState,
      multiCurrencies,
    ],
  );

  const handleUpdatePrice = useCallback(
    (price: string) => {
      const currency = multiCurrencies?.find(
        ({title}) => title === formData?.item_currency,
      );

      const rate = Number(currency?.rate || 1);
      const numPrice = Number(price || 0) * rate;
      const amount = formData?.item_amount;

      handlerUpdateFormState({
        item_base_price: correctPrice(numPrice),
        item_price: price,
        total: correctPrice(numPrice * amount),
      });
    },
    [
      formData?.item_amount,
      formData?.item_currency,
      handlerUpdateFormState,
      multiCurrencies,
    ],
  );

  const handleUpdateCurrency = useCallback(
    (item_currency: string) => {
      const currency = multiCurrencies?.find(
        ({title}) => title === item_currency,
      );

      const price = formData?.item_price;
      const rate = Number(currency?.rate || 1);
      const numPrice = Number(price || 0) * rate;
      const amount = formData?.item_amount;

      handlerUpdateFormState({
        item_base_price: correctPrice(numPrice),
        item_currency,
        total: correctPrice(numPrice * amount),
      });
    },
    [
      formData?.item_amount,
      formData?.item_price,
      handlerUpdateFormState,
      multiCurrencies,
    ],
  );

  const handleUpdatePriceMargin = useCallback(
    (priceMarginUuid: string) => {
      const priceMargin = priceMarginList?.find(
        ({uuid}) => uuid === priceMarginUuid,
      );

      if (priceMargin) {
        const price = getProductPriceMargin(
          formData?.item_positionable_object as ProductModel,
          priceMargin,
        );

        handleUpdatePrice(price);
      }

      handlerUpdateFormState({
        price_margin: priceMarginUuid,
      });
    },
    [
      formData?.item_positionable_object,
      getProductPriceMargin,
      handleUpdatePrice,
      handlerUpdateFormState,
      priceMarginList,
    ],
  );

  useEffect(() => {
    if (amountRef?.current) {
      amountRef.current?.focus();
    }
  }, []);

  const isProductItem =
    formData?.item_positionable_type === InvoiceItemPositionableType.PRODUCT;

  return (
    <Row gutter={12} ref={contentRef}>
      <Col span={isProductItem ? 24 : isFullWidth}>
        <Form.Item
          tooltip={t('tAmount')}
          label={t('tAmount')}
          name="item_amount"
          rules={[
            ({}) => ({
              validator(_, amount) {
                if (Number.isNaN(Number(amount))) {
                  return Promise.reject(
                    new Error(t('tAmount must be number.')),
                  );
                }

                if (!amount) {
                  return Promise.reject(
                    new Error(t('tAmount must be specified.')),
                  );
                }

                if (amount < 1) {
                  return Promise.reject(
                    new Error(t('tAmount must not be less than 1')),
                  );
                }

                return Promise.resolve();
              },
            }),
          ]}>
          <FullWidthInputNumber
            onFocus={(e) => e?.target?.select()}
            ref={amountRef}
            type="number"
            disabled={loading || loadingSubmit}
            onChange={handleUpdateAmount}
            placeholder={t('Enter an amount')}
          />
        </Form.Item>
      </Col>

      {isProductItem ? (
        <Col span={isFullWidth}>
          <Form.Item
            tooltip={t('Price margin')}
            label={t('Price margin')}
            name="price_margin">
            <StyledSearchSelect
              popupMatchSelectWidth={false}
              name="price_margin"
              placeholder={'Select a price margin'}
              disable={loadingSubmit}
              getOptionValueTitle="price_name"
              data={listToArray(priceMarginList as any)}
              onChange={handleUpdatePriceMargin}
            />
          </Form.Item>
        </Col>
      ) : null}

      {isShowCurrencySelect ? (
        <Col span={isFullWidth}>
          <Form.Item
            label={t('Price')}
            name="item_price"
            required
            rules={[
              () => ({
                validator(_, price) {
                  if (Number.isNaN(Number(price))) {
                    return Promise.reject(
                      new Error(t('Price must be a number')),
                    );
                  }

                  if (!price) {
                    return Promise.reject(
                      new Error(t('Price must be a specified')),
                    );
                  }

                  return Promise.resolve();
                },
              }),
            ]}>
            <Input
              autoComplete="off"
              onFocus={(e) => e?.target?.select()}
              addonBefore={
                isShowCurrencySelect ? null : defaultCompany?.currency_symbol
              }
              addonAfter={
                isShowCurrencySelect ? (
                  <Form.Item noStyle name="item_currency">
                    <StyledSearchSelect
                      popupMatchSelectWidth={false}
                      name="item_currency"
                      placeholder={''}
                      disable={loadingSubmit}
                      getOptionValueProps="title"
                      data={[DEFAULT_UAH_CURRENCY, ...multiCurrencies]}
                      onChange={handleUpdateCurrency}
                    />
                  </Form.Item>
                ) : null
              }
              disabled={loading || loadingSubmit}
              onChange={(e) => handleUpdatePrice(e?.target?.value)}
              placeholder={t('Enter a price')}
            />
          </Form.Item>
        </Col>
      ) : (
        <Col span={isFullWidth}>
          <Form.Item
            label={t('Price')}
            name="item_price"
            required
            rules={[
              () => ({
                validator(_, price) {
                  if (Number.isNaN(Number(price))) {
                    return Promise.reject(
                      new Error(t('Price must be a number')),
                    );
                  }

                  if (!price) {
                    return Promise.reject(
                      new Error(t('Price must be a specified')),
                    );
                  }

                  return Promise.resolve();
                },
              }),
            ]}>
            <Input
              autoComplete="off"
              onFocus={(e) => e?.target?.select()}
              addonBefore={defaultCompany?.currency_symbol}
              disabled={loading || loadingSubmit}
              onChange={(e) => handleUpdatePrice(e?.target?.value)}
              placeholder={t('Enter a price')}
            />
          </Form.Item>
        </Col>
      )}

      <Col span={24}>
        <Form.Item label={t('Total')} name="total">
          <Input addonBefore={defaultCompany?.currency_symbol} disabled />
        </Form.Item>
      </Col>
    </Row>
  );
}
