import React, {useMemo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {MATERIAL_AND_EXPENSE_INITIAL_DATA} from '../../../services/api/materialAndExpense';
import {DefaultForm, IDefaultFormProps} from '../../../components/lib/General';
import {MaterialAndExpenseFormDTO} from '../../../struture';
import MaterialAndExpenseFields from './MaterialAndExpenseFields';
import {useDropdownAlert} from '../../../contex';

export interface IMaterialAndExpenseFormProps
  extends Omit<
    IDefaultFormProps<MaterialAndExpenseFormDTO, MaterialAndExpenseFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  materialAndExpense?: MaterialAndExpenseFormDTO;
  materialAndExpenseUuid: string;
}

export default function RelatedServiceForm({
  loading,
  materialAndExpense = MATERIAL_AND_EXPENSE_INITIAL_DATA,
  editMode,
  materialAndExpenseUuid,
  ...rest
}: IMaterialAndExpenseFormProps): JSX.Element {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const additionalValues = useMemo(
    () => ({
      uuid: materialAndExpense?.uuid,
    }),
    [materialAndExpense?.uuid],
  );

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Material and expense'),
        `${
          editMode
            ? t('An error occurred during edit material and expense')
            : t('An error occurred during create material and expense')
        } : ${apiError?.message}`,
      );
    },
    [alert, editMode, t],
  );

  return (
    <DefaultForm<MaterialAndExpenseFormDTO, MaterialAndExpenseFormDTO>
      initialValues={{
        ...materialAndExpense,
      }}
      showNotify={false}
      notifyError={notifyError}
      additionalValuesRequest={additionalValues}
      editMode={editMode}
      {...rest}>
      {() => <MaterialAndExpenseFields />}
    </DefaultForm>
  );
}
