import React, {useState} from 'react';
import {Modal, Upload, Image} from 'antd';
import {withTranslation, WithTranslation} from 'react-i18next';
import {UploadProps} from 'antd/lib/upload';
import {greaterOrEqualThan, len} from '../../../../services/helpers';
import {Text} from '../../Format';
import styled, {css} from 'styled-components';
import {UploadOutlined, LoadingOutlined} from '@ant-design/icons';

export default withTranslation()(FileUploadCoverWithModal);

export interface IFileUploadCoverWithModalProps
  extends WithTranslation,
    UploadProps {
  className?: string;
  title?: string;
  loading?: boolean;
  coverHeight?: number;
}

const StyledContainer = styled.div<{$coverHeight: number}>`
  display: flex;
  width: 100%;
  height: 200px;
  background-color: ${({theme}) => theme.colors.lightDisable};
  border-radius: 6px;

  ${({$coverHeight}) =>
    $coverHeight &&
    css`
      height: ${$coverHeight}px;
    `}
`;

const StyledUpload = styled(Upload)`
  overflow: hidden;

  && .ant-upload-list.ant-upload-list-picture-card {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .ant-upload-list-picture-card-container {
    width: 100%;
    height: 100%;
  }

  & .ant-upload-picture-card-wrapper {
    overflow: hidden;
  }

  & .ant-upload-list-item-info:before {
    content: '';
  }

  &&&& .ant-upload-list-item::before {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    opacity: 0;
    transition: all 0.3s;
    content: ' ';
  }

  & .ant-upload-list,
  &&&&& .ant-upload-list-item-container {
    width: 100%;
    height: 100%;
    margin: 0;

    img {
      object-fit: cover;
    }
  }

  &&& .ant-upload,
  &&& .ant-upload-list-item {
    width: 100%;
    height: 100%;
    background-color: transparent;
    padding: 0;
    border: none;
    overflow: hidden;
  }

  &&& .ant-upload-list-item-thumbnail img {
    object-fit: cover;
  }
`;

const StyledTitle = styled(Text)`
  color: ${({theme}) => theme.colors.white};
  font-size: 30px;
`;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  color: ${({theme}) => theme.colors.white};
  font-size: 30px;
`;

function FileUploadCoverWithModal({
  t,
  className,
  fileList,
  title,
  loading,
  coverHeight = 200,
  disabled,
  ...rest
}: IFileUploadCoverWithModalProps) {
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [previewTitle, setPreviewTitle] = useState<string>('');

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file: any): Promise<void> => {
    setPreviewImage(file.url);
    setPreviewVisible(true);
    setPreviewTitle(file.name);
  };

  return (
    <StyledContainer $coverHeight={coverHeight}>
      <StyledUpload
        listType="picture-card"
        accept="image/*"
        onPreview={handlePreview}
        fileList={fileList}
        disabled={disabled}
        {...rest}
        multiple={false}>
        {loading ? (
          <StyledLoadingOutlined />
        ) : greaterOrEqualThan(len(fileList as []), 1) || disabled ? null : (
          <StyledTitle>{title || <UploadOutlined />}</StyledTitle>
        )}
      </StyledUpload>

      <Modal
        open={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}>
        <img alt="example" style={{width: '100%'}} src={previewImage} />
      </Modal>
    </StyledContainer>
  );
}
