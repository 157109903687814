import React, {useCallback} from 'react';
import {useNavigate, useParams} from 'react-router';
import {withTranslation, WithTranslation} from 'react-i18next';
import {ScheduleCalendarProps, isFunction} from '../../../services/helpers';
import {ChildrenMustBeAFunction} from '../../../components/lib/Errors';
import {IDetailsHeaderProps} from '../../../components/lib/General';
import {EMPLOYEE_TABS} from './EmployeeTabPanel';
import {ScheduleCalendarModel} from '../../../struture';
import {Routes} from '../../../services/types';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import {Route} from '../../../components/lib/DataDisplay';

export default withTranslation()(EmployeeScheduleDetail);

export interface IEmployeeScheduleDetailProps
  extends ScheduleCalendarProps,
    WithTranslation {
  schedule?: ScheduleCalendarModel;
  employeeFullName?: string;
  children: (
    value: Pick<IDetailsHeaderProps, 'routes'> & {navigateGoBack: () => void},
  ) => React.ReactNode;
  routes: Route[];
}

function EmployeeScheduleDetail({
  schedule,
  routes,
  t,
  children,
  employeeFullName,
}: IEmployeeScheduleDetailProps): JSX.Element {
  const {scheduleId, employeeId} = useParams();
  const navigate = useNavigate();

  const routesEmployees = [
    ...(routes || []),
    {
      path: `${Routes.app}/calendar/entry/${scheduleId || ''}`,
      breadcrumbName: `${
        schedule?.schedule_number ? `#${schedule?.schedule_number}` : ''
      }`,
    },
  ];

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.employees}/${employeeId}`);
  }, [employeeId, navigate]);

  if (!isFunction(children)) {
    throw new ChildrenMustBeAFunction('CompanyScheduleDetail');
  }

  return <>{children({routes: routesEmployees, navigateGoBack})}</>;
}
