import {Dispatch} from 'redux';
import {List} from 'immutable';
import {CASH_BOX_LIST} from '../constants';
import {CashBoxModel} from '../../struture';
import {ApiError} from '../../services/types';
import {PaymentScheduleOperationType} from '../../services/api/orders';

export interface ISetCashBoxListParam {
  type: CASH_BOX_LIST;
  cashBoxList: List<CashBoxModel>;
  keywords?: string;
  total?: number;
  page?: number;
}

export interface IAddCashBoxToListParam {
  type: CASH_BOX_LIST;
  cashBox: CashBoxModel;
}

export interface IUpdateCashBoxFromListParam {
  type: CASH_BOX_LIST;
  cashBox: CashBoxModel;
}

export interface IDeleteCashBoxFromListParam {
  type: CASH_BOX_LIST;
  cashBoxUuid: string;
}

export interface IErrorCashBoxListParam {
  type: CASH_BOX_LIST;
  error: ApiError | undefined;
}

export interface IUpdateCashBoxBalanceFromListParam {
  type: CASH_BOX_LIST;
  cashBoxUuid: string;
  sum: string;
  method: PaymentScheduleOperationType;
}

export function setCashBoxList(
  cashBoxList: List<CashBoxModel>,
  keywords?: string,
  total?: number,
  page?: number,
): (dispatch: Dispatch) => Promise<ISetCashBoxListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CASH_BOX_LIST.SET_CASH_BOX_LIST,
      cashBoxList,
      keywords,
      total,
      page,
    });
  };
}

export function loadMoreCashBoxList(
  cashBoxList: List<CashBoxModel>,
): (dispatch: Dispatch) => Promise<ISetCashBoxListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CASH_BOX_LIST.LOAD_MORE_CASH_BOX_LIST,
      cashBoxList,
    });
  };
}

export function addCashBoxToList(
  cashBox: CashBoxModel,
): (dispatch: Dispatch) => Promise<IAddCashBoxToListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CASH_BOX_LIST.ADD_CASH_BOX,
      cashBox,
    });
  };
}

export function updateCashBoxFromList(
  cashBox: CashBoxModel,
): (dispatch: Dispatch) => Promise<IUpdateCashBoxFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CASH_BOX_LIST.UPDATE_CASH_BOX,
      cashBox,
    });
  };
}

export function deleteCashBoxFromList(
  cashBoxUuid: string,
): (dispatch: Dispatch) => Promise<IDeleteCashBoxFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CASH_BOX_LIST.DELETE_CASH_BOX,
      cashBoxUuid,
    });
  };
}

export function errorCashBoxInList(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorCashBoxListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CASH_BOX_LIST.ERROR_CASH_BOX_LIST,
      error,
    });
  };
}

export function updateCashBoxBalanceFromList(
  cashBoxUuid: string,
  sum: string,
  method: PaymentScheduleOperationType,
): (dispatch: Dispatch) => Promise<IUpdateCashBoxBalanceFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: CASH_BOX_LIST.UPDATE_CASH_BOX_BALANCE,
      cashBoxUuid,
      sum,
      method,
    });
  };
}
