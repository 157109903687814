import React from 'react';
import {Routes, Route} from 'react-router';
import {AppRouteLayout} from '../components/lib/Layout';
import {RewardListPage} from '../views/Rewards';
import {PaymentPage} from '../views/Payments';
import {Routes as RoutesType} from '../services/types';

export function RewardRoutesNavigator(): JSX.Element {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AppRouteLayout>
            <RewardListPage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`/${RoutesType.payments}/:paymentId`}
        element={
          <AppRouteLayout>
            <PaymentPage />
          </AppRouteLayout>
        }
      />
    </Routes>
  );
}
