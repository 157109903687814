import {correctPrice} from '../../services/helpers';
import {IEmployeeSalaryModelReturnType} from '../internal';

export interface IEmployeeSalaryAccrualAt {
  sum: string;
  day_of_month: number;
}
export interface IEmployeeSalaryDTOProps {
  sum: string;
  comment: string;
  start_date: string;
  enabled: boolean;
  accrual_at: IEmployeeSalaryAccrualAt[];
}

export class EmployeeSalaryDTO {
  public sum: string;
  public comment: string;
  public start_date: string;
  public enabled: boolean;
  public accrual_at: IEmployeeSalaryAccrualAt[];

  constructor(props: IEmployeeSalaryModelReturnType | IEmployeeSalaryDTOProps) {
    this.sum = correctPrice(props?.sum || 0);
    this.comment = props?.comment || '';
    this.start_date = props?.start_date || '';
    this.enabled = typeof props?.enabled === 'boolean' ? props?.enabled : true;
    this.accrual_at = (props?.accrual_at || []).map(({sum, ...rest}) => ({
      ...rest,
      sum: correctPrice(sum || 0),
    }));
  }
}
