import {getNumberOfStringPercent} from './string';

/**
 * @desc Отримання проценту одного числа від іншого
 * @param {Number} mainNumber
 * @param {Number} secondNumber
 * @return {Number}
 * */
export function percentageOfTwoNumbers(
  mainNumber: number,
  secondNumber: number,
): number | Error {
  if (typeof mainNumber !== 'number' || typeof secondNumber !== 'number') {
    return new Error('Both arguments must be numbers');
  }

  return Math.round((secondNumber / mainNumber) * 100);
}

export const toFloat = (value: string | number): string =>
  Number(value).toFixed(2);

/**
 * @desc Отримання проценту від числа
 * @param {Number} number
 * @param {Number} percent
 * @return {Number}
 * */
export const getPercentageOfTheNumber = (
  number: number,
  percent: string | number,
): number => Math.round((number / 100) * getNumberOfStringPercent(percent));
