import React from 'react';
import {StoreDocumentModel, StoreDocumentStatus} from '../../../struture';
import {StyledTitle, StyledDescription} from '../../../components/lib/Styled';
import styled, {useTheme, css} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {toDateByFormat} from '../../../services/helpers';
import {Link} from '../../../components/lib/DataDisplay';
import {Routes} from '../../../services/types';
import {MdOutlineCreditCard} from 'react-icons/md';

export interface IStoreDocumentPageHeaderProps {
  document: StoreDocumentModel | null;
  withoutIndent?: boolean;
  withoutPayment?: boolean;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const StyledWrapper = styled.div<{$withoutIndent: boolean}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;

  ${({$withoutIndent}) =>
    !$withoutIndent &&
    css`
      padding-left: 24px;
      padding-right: 24px;
    `}
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;

  width: fit-content;
  height: fit-content;
  margin-left: auto;
  padding: 10px;

  background-color: ${({theme}) => theme.background.primary};
  border: 1px solid ${({theme}) => theme.colors.disabled};
  border-radius: 7px;

  &:hover {
    border-color: ${({theme}) => theme.colors.link};
  }
`;

export function StoreDocumentPageHeader({
  document,
  withoutIndent,
  withoutPayment = false,
}: IStoreDocumentPageHeaderProps): React.JSX.Element {
  const {t} = useTranslation();
  const theme: any = useTheme();

  return (
    <StyledWrapper $withoutIndent={!!withoutIndent}>
      <StyledContainer>
        <StyledTitle
          bold
          fontSize={20}
          $color={
            document?.doc_status_text === StoreDocumentStatus.DRAFT
              ? theme.colors.warning
              : undefined
          }>
          {document?.doc_status_text === StoreDocumentStatus.DRAFT
            ? `№${document?.doc_local_number} ( ${t('Draft')} )`
            : `№${document?.doc_local_number}`}
        </StyledTitle>
        <StyledDescription>
          {`${t('From').toLowerCase()} ${toDateByFormat(
            document?.doc_date,
            'DD.MM.YYYY HH:mm',
          )}`}
        </StyledDescription>
      </StyledContainer>
      {document?.doc_is_paymentable && !withoutPayment ? (
        <StyledLink
          withoutRight
          to={`${Routes.payments}/${Routes.stores}`}
          tooltip={t('Go to payments details page')}
          state={{goBack: location.pathname}}>
          <MdOutlineCreditCard size={18} />
          <StyledTitle fontSize={16}>{t('Payments')}</StyledTitle>
        </StyledLink>
      ) : null}
    </StyledWrapper>
  );
}
