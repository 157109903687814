import * as React from 'react';
import {Dispatch, SetStateAction} from 'react';
import {
  filter,
  isNotEqualByUuid,
  map,
  every,
  updateBy,
  prop,
  eq,
  FunctionArgs,
  sub,
  head,
  len,
  add,
  last,
} from '../services/helpers';

export interface IUseDataStateReturnedType<T> {
  data: T[];
  handleDelete: (id: string[], prop?: string) => void;
  handleCreate: (item: T, insertInStart?: boolean) => void;
  handleUpdate: (item: T, prop?: string) => void;
  setData: Dispatch<SetStateAction<T[]>>;
  loading: boolean;
  total: number;
}

export default function useDataState<T>(
  data: T[],
  initialState?: any,
  dataTotal?: number,
  dataLimit?: number,
): IUseDataStateReturnedType<T> {
  const [inputData, setInputData] = React.useState<T[]>(
    eq(initialState, null) || initialState ? initialState : ([] as any),
  );
  const [loading, setLoading] = React.useState<boolean>(true);
  const [total, setTotal] = React.useState(0);
  const [dataListDidntFit, setDataListDidntFit] = React.useState<T[]>([]);

  React.useEffect(() => {
    if (dataTotal) {
      setTotal((prevState) =>
        prevState !== dataTotal ? dataTotal : prevState,
      );
    }
  }, [dataTotal]);

  React.useEffect(() => {
    let didCancel = false;

    if (data && !didCancel) {
      setInputData(data);
      setLoading(false);
    }
    return () => {
      didCancel = true;
    };
  }, [data]);

  const removeClassNameForNewEntity = React.useCallback(() => {
    const row = document.querySelector('.list__row--highlight');

    if (row) {
      row.classList.remove('list__row--highlight');
    }
  }, []);

  const addToEntityListDidntFit = React.useCallback((item: T): void => {
    setDataListDidntFit((prevState) => [...prevState, item]);
  }, []);

  const removeFromEntityListDidntFit = React.useCallback((): void => {
    setDataListDidntFit((prevState) => prevState.slice(1));
  }, []);

  const handleDelete = React.useCallback(
    (ids: string[], key: string = 'uuid'): void => {
      const item = head(dataListDidntFit);

      if (item) {
        removeFromEntityListDidntFit();
      }

      setInputData((prevState: T[]): T[] => {
        const filterData = filter<[FunctionArgs<T, boolean>, T[]], T[]>(
          (i: T) => every(isNotEqualByUuid(prop(key, i)), ids),
          prevState,
        );

        if (item) {
          return [...filterData, item];
        }

        return filterData;
      });

      setTotal((prevState) => sub(prevState, 1));
    },
    [dataListDidntFit, removeFromEntityListDidntFit],
  );

  const handleCreate = React.useCallback(
    (item: T | T[], insertInStart: boolean = false): void => {
      setInputData((prevState: T[]): T[] => {
        if (len(prevState) === dataLimit && insertInStart) {
          const updatedList = prevState.slice(0, 9);
          const lastItem = last(prevState);

          addToEntityListDidntFit(lastItem as T);

          return Array.isArray(item)
            ? insertInStart
              ? [...item, ...updatedList]
              : [...updatedList, ...item]
            : insertInStart
            ? [item, ...updatedList]
            : [...updatedList, item];
        }

        if (len(prevState) === dataLimit && !insertInStart) {
          addToEntityListDidntFit(item as T);

          return prevState as any;
        }

        return Array.isArray(item)
          ? insertInStart
            ? [...item, ...prevState]
            : [...prevState, ...item]
          : insertInStart
          ? [item, ...prevState]
          : [...prevState, item];
      });

      setTotal((prevState) => add(prevState, 1));
      setTimeout(removeClassNameForNewEntity, 5000);
    },
    [addToEntityListDidntFit, dataLimit, removeClassNameForNewEntity],
  );

  const handleUpdate = React.useCallback(
    (item: T, prop: string = 'uuid'): void => {
      setInputData((prevState: T[]): T[] =>
        map(updateBy(prop, item), prevState),
      );
    },
    [],
  );

  return {
    data: inputData,
    setData: setInputData,
    handleDelete,
    handleCreate,
    handleUpdate,
    loading,
    total,
  };
}
