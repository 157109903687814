import * as React from 'react';
import {List} from 'immutable';
import {
  useTariffModelClientsAbonementList,
  IUseTariffModelClientsAbonementListProps,
  IUseTariffModelClientsAbonementListReturnType,
} from './useTariffModelClientsAbonementList';
import {AbonementModel, ClientAbonementModel} from '../struture';
import {deleteClientAbonement} from '../services/api/client';
import {
  setClientAbonementList as storeSetClientAbonementList,
  deleteClientAbonementFromList as storeDeleteClientAbonement,
  loadMoreClientAbonementList as storeLoadMoreClientAbonementList,
} from '../store/actions';
import {useSelector, useDispatch} from 'react-redux';
import {RootState} from '../store/reducers';
import {REDUX_STATUS} from '../services/types';
import {head} from '../services/helpers';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../contex';

export interface IUseStateTariffModelClientsAbonementListProps
  extends IUseTariffModelClientsAbonementListProps {}

export interface IUseStateTariffModelClientsAbonementListReturnType
  extends Omit<IUseTariffModelClientsAbonementListReturnType, 'entityList'> {
  abonementList: List<ClientAbonementModel> | null;
  handleDeleteClientAbonements: (ids: string[]) => Promise<void>;
  handleSearchClientAbonements: (keywords: string) => Promise<void>;
  handleLoadMoreClientAbonements: () => Promise<void>;
  handleRefreshClientAbonements: (
    value: Partial<IUseTariffModelClientsAbonementListProps> & {page: number},
  ) => Promise<void>;
  status: REDUX_STATUS;
  loadingMore: boolean;
  page: number;
  tariffModel: AbonementModel | null;
}

export function useStoredTariffModelClientsAbonementList(
  {
    abonementUuid,
    loadOnInit = true,
    ...rest
  }: IUseStateTariffModelClientsAbonementListProps = {} as IUseStateTariffModelClientsAbonementListProps,
): IUseStateTariffModelClientsAbonementListReturnType {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const [loadingMore, setLoadingMore] = useState(false);
  const [abonementList, setAbonementListList] =
    useState<List<ClientAbonementModel> | null>(null);
  const [isStarLoading, setIsStartLoading] = React.useState<boolean>(false);

  const [storeKeywords, setStoreKeywords] = useState('');
  const [storePage, setStorePage] = useState(1);
  const [storeTotal, setStoreTotal] = useState(0);
  const [tariffModel, setTariffModel] = useState<AbonementModel | null>(null);

  const {
    status: storedClientAbonementListStatus,
    loading: storedClientAbonementListLoading,
    cachedAbonementList,
    ...storedClientAbonementListParams
  } = useSelector(({clientAbonementList}: RootState) => clientAbonementList);

  const dispatch = useDispatch<any>();

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: abonementListLoading,
    total,
    ...abonementsParams
  } = useTariffModelClientsAbonementList({
    abonementUuid,
    loadOnInit: false,
    ...rest,
  });

  React.useEffect(() => {
    if (cachedAbonementList?.size > 0) {
      const abonementList = cachedAbonementList.get(abonementUuid);

      if (abonementList) {
        setIsStartLoading(false);
        setAbonementListList(abonementList?.abonements);
        setTariffModel(abonementList?.company_abonement);
        setStorePage(abonementList?.page);
        setStoreKeywords(abonementList?.keywords);
        setStoreTotal(abonementList?.total || 0);
      } else {
        setIsStartLoading(true);
      }
    }
    if (cachedAbonementList?.size === 0 && !abonementList) {
      setIsStartLoading(true);
    }
  }, [dispatch, cachedAbonementList, abonementUuid, storeTotal, abonementList]);

  useEffect(() => {
    if (!List.isList(entityList) && isStarLoading && loadOnInit) {
      setIsStartLoading(false);
      (async () => {
        const entityList = await refresh({abonementUuid});

        if (entityList) {
          dispatch(storeSetClientAbonementList(entityList, abonementUuid, ''));
        }
      })();
    }
  }, [abonementUuid, dispatch, entityList, isStarLoading, loadOnInit, refresh]);

  const handleDeleteClientAbonements = React.useCallback(
    async (ids: string[]) => {
      try {
        await deleteClientAbonement(ids);

        setTimeout(() => {
          dispatch(storeDeleteClientAbonement(head(ids), abonementUuid));
        }, 100);
        alert(
          'success',
          t('Client abonement'),
          t('Client abonement delete success'),
        );
      } catch (error: any) {
        alert(
          'error',
          t('Client abonement'),
          `${t('An error occurred during delete client abonements')}: ${
            error?.message
          }`,
        );
      }
    },
    [alert, dispatch, t, abonementUuid],
  );

  const handleSearchClientAbonements = React.useCallback(
    async (keywords: string) => {
      setLoadingMore(true);
      const abonementListModel = await refresh({
        offset: 0,
        limit: 10,
        keywords,
      });

      if (abonementListModel && List.isList(abonementListModel?.abonements)) {
        dispatch(
          storeSetClientAbonementList(
            abonementListModel,
            abonementUuid,
            keywords,
            abonementListModel.total,
          ),
        );
      }
      setLoadingMore(false);
    },
    [abonementUuid, dispatch, refresh],
  );

  const handleLoadMoreClientAbonements = useCallback(async () => {
    if (
      List.isList(abonementList) &&
      abonementList?.size < storeTotal &&
      !loadingMore
    ) {
      setLoadingMore(true);
      const abonementListModel = await refresh({
        offset: abonementList?.size,
        limit: 10,
        showLoading: false,
      });

      if (abonementListModel && List.isList(abonementListModel?.abonements)) {
        dispatch(
          storeLoadMoreClientAbonementList(abonementListModel, abonementUuid),
        );
      }

      setLoadingMore(false);
    }
  }, [
    abonementList,
    storeTotal,
    loadingMore,
    refresh,
    dispatch,
    abonementUuid,
  ]);

  const handleRefreshClientAbonements = useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = false,
      page,
    }: Partial<IUseTariffModelClientsAbonementListProps> & {page: number}) => {
      const abonementListModel = await refresh({
        offset,
        limit,
        showLoading,
      });

      if (abonementListModel && List.isList(abonementListModel?.abonements)) {
        dispatch(
          storeSetClientAbonementList(
            abonementListModel,
            abonementUuid,
            storeKeywords,
            abonementListModel?.total,
            page,
          ),
        );
      }
    },
    [abonementUuid, dispatch, refresh, storeKeywords],
  );

  return {
    ...abonementsParams,
    ...storedClientAbonementListParams,
    abonementList,
    total: storeTotal,
    loadingMore,
    offset,
    limit,
    refresh,
    loading:
      (!List.isList(abonementList) && abonementListLoading) ||
      !storedClientAbonementListLoading,
    handleDeleteClientAbonements,
    handleSearchClientAbonements,
    handleLoadMoreClientAbonements,
    status: storedClientAbonementListStatus,
    handleRefreshClientAbonements,
    keywords: storeKeywords || '',
    page: storePage || 1,
    tariffModel,
  };
}
