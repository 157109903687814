import React from 'react';
import {isFunction} from '../../../services/helpers';
import {ChildrenMustBeAFunction} from '../../../components/lib/Errors';
import {IDefaultTabPanelProps, TabItem} from '../../../components/lib/General';
import {InvoiceModel} from '../../../struture';
import {useNavigate} from 'react-router';

export enum InvoiceTabs {}

export interface IInvoiceTabPanelProps {
  invoice: InvoiceModel | null;
  children: (value: Pick<IDefaultTabPanelProps, 'tabs'>) => React.ReactNode;
}

export function InvoiceTabPanel({
  children,
  invoice,
}: IInvoiceTabPanelProps): React.JSX.Element {
  const navigate = useNavigate();

  const storeTabs: TabItem[] = [];

  if (!isFunction(children)) {
    throw new ChildrenMustBeAFunction('LoadingWithEmptyData');
  }

  return <>{children({tabs: storeTabs})}</>;
}
