import {
  EmployeeFormDTO,
  EmployeeMapper,
  IScheduleCalendarModelReturnType,
} from '../internal';

export class ScheduleChangeTimeFormDTO {
  public uuid: string;

  public scheduled_worker_uuid: EmployeeFormDTO | string;

  public scheduled_date: string;

  public scheduled_send_notification: boolean;

  public scheduled_comment: string;

  constructor(
    props?: IScheduleCalendarModelReturnType,
    send_sms_notification?: boolean,
  ) {
    this.uuid = props?.uuid || '';
    this.scheduled_worker_uuid =
      EmployeeMapper.toEmployeeFormDTO(props?.employee as any) || '';
    this.scheduled_date = props?.scheduled_date || '';
    this.scheduled_send_notification = send_sms_notification || false;
    this.scheduled_comment = '';
  }
}
