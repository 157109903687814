import {ClientModel, InvoiceFormDTO} from '../../struture';
import {
  areDeeplyEqual,
  areDeeplyEqualIgnoreFields,
} from '../../services/helpers';

export interface InvoiceRefObject {
  invoice: InvoiceFormDTO;
}

export const isEqualInvoiceFields = (
  invoice: InvoiceFormDTO,
  refObject: InvoiceRefObject,
) => {
  const editedInvoice = refObject?.invoice;

  const isEqual = areDeeplyEqualIgnoreFields(invoice, editedInvoice, [
    'inv_client',
  ]);

  const clientUuid =
    invoice?.inv_client instanceof ClientModel
      ? invoice?.inv_client?.uuid
      : invoice?.inv_client;

  const clientEditUuid =
    editedInvoice?.inv_client instanceof ClientModel
      ? editedInvoice?.inv_client?.uuid
      : editedInvoice?.inv_client;

  const isEqualClient = clientUuid === clientEditUuid;

  return {
    isEqual,
    isEqualClient,
  };
};
