import {Record} from 'immutable';
import CompanyService, {CompanyServiceProps} from './CompanyService';
import Employee, {EmployeeProps} from './Employee';
import Client, {ClientProps} from './Client';

export interface IGoogleCalendarProps {
  uuid: string;
  calendar_name: string;
  calendar_token_exp: string;
  created_at: string;
  updated_at: string;
  default_service: CompanyServiceProps;
  default_client: ClientProps;
  default_employee: EmployeeProps;
}

export interface IGoogleCalendarReturnType
  extends Omit<
    IGoogleCalendarProps,
    'default_service' | 'default_client' | 'default_employee'
  > {
  default_service: CompanyService;
  default_client: Client;
  default_employee: Employee;
}

/**
 * @class Company
 * @classdesc
 * @extends {Record}
 * @property {string} id - backup id
 */
export default class GoogleCalendar extends Record<IGoogleCalendarReturnType>({
  uuid: '',
  calendar_name: '',
  calendar_token_exp: '',
  created_at: '',
  updated_at: '',
  default_service: {} as CompanyService,
  default_client: {} as Client,
  default_employee: {} as Employee,
}) {
  constructor(
    {
      default_service,
      default_client,
      default_employee,
      ...props
    }: IGoogleCalendarProps = {} as IGoogleCalendarProps,
  ) {
    const options: any = {};

    if (default_service) {
      options.default_service = new CompanyService(default_service);
    }

    if (default_client) {
      options.default_client = new Client(default_client);
    }
    if (default_employee) {
      options.default_employee = new Employee(default_employee);
    }

    super({
      ...props,
      ...options,
    });
  }
}
