import React from 'react';
import {ParametersTabPanel} from './Show';
import {OverviewLayout} from '../../components/lib/Layout';
import {useStoredCompanies, useSettings} from '../../hooks';
import {useModal} from '../../components/lib/libV2/hooks';

const PaymentsSideWindowLazy = React.lazy(
  () => import('./Show/PaymentsSideWindow'),
);

const LocalizationSideWindowLazy = React.lazy(
  () => import('./Show/LocalizationSideWindow'),
);

export function ParametersPage(): JSX.Element {
  const {settings, defaultCompanyUuid} = useStoredCompanies();

  const {handleUpdateFinancialReliability, loading} = useSettings({
    companyUuid: defaultCompanyUuid,
  });

  const {
    handleCancel: handleCancelPayments,
    handleOnInit: handleOnInitPayments,
    handleSuccess: handleSuccessPayments,
    visible: visiblePayments,
  } = useModal({
    onSuccess: handleUpdateFinancialReliability,
  });

  const {
    handleCancel: handleCancelLocalization,
    handleOnInit: handleOnInitLocalization,
    handleSuccess: handleSuccessLocalization,
    visible: visibleLocalization,
  } = useModal({
    onSuccess: handleUpdateFinancialReliability,
  });

  return (
    <>
      <ParametersTabPanel
        handleOnInitPayments={handleOnInitPayments}
        handleOnInitLocalization={handleOnInitLocalization}>
        {({tabs}) => (
          <OverviewLayout
            autoLayout
            tabs={tabs}
            data={null}
            loading={!settings}
          />
        )}
      </ParametersTabPanel>
      <PaymentsSideWindowLazy
        visible={visiblePayments}
        payments={settings?.settings}
        onSuccess={handleSuccessPayments}
        loading={loading}
        onCancel={handleCancelPayments}
      />
      <LocalizationSideWindowLazy
        visible={visibleLocalization}
        localization={settings?.settings}
        onSuccess={handleSuccessLocalization}
        loading={loading}
        onCancel={handleCancelLocalization}
      />
    </>
  );
}
