import {
  PaymentableDTO,
  IPaymentableDTOProps,
  PaymentableModel,
} from '../internal';

export class PaymentableMapper {
  public static toPaymentableDTO(
    paymentable: PaymentableModel | IPaymentableDTOProps,
  ): PaymentableDTO {
    return new PaymentableDTO({
      uuid: paymentable?.uuid,
      balance_before: paymentable?.balance_before,
      created_at: paymentable?.created_at,
      updated_at: paymentable?.updated_at,
      client: paymentable?.client as any,
    });
  }

  public static toPaymentableModel(
    paymentableDTO: PaymentableDTO,
  ): PaymentableModel {
    return new PaymentableModel(paymentableDTO);
  }
}
