import React from 'react';
import {useTranslation, WithTranslation, withTranslation} from 'react-i18next';
import {List} from 'immutable';
import {notify} from '../../../components/lib/Feedback';
import {
  EmployeeWorkScheduleList,
  AddScheduleToEmployeeButton,
} from '../../EmployeeWorkSchedule';
import {WorkScheduleModel} from '../../../struture';
import {Empty} from 'antd';
import {head} from '../../../services/helpers';
import {useDropdownAlert} from '../../../contex';

export interface IEmployeeWorkSchedulesPanelProps {
  workScheduleList: List<WorkScheduleModel> | null;
  loading: boolean;
  workScheduleUuid: string;
  changeWorkSchedule: (id: string) => Promise<void>;
  disabled?: boolean;
}

export default function EmployeeWorkScheduleSelect({
  workScheduleList,
  loading,
  workScheduleUuid,
  changeWorkSchedule,
  disabled,
  ...rest
}: IEmployeeWorkSchedulesPanelProps): JSX.Element {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const handleScheduleChange = async (uuid: string): Promise<void> => {
    try {
      await changeWorkSchedule(uuid);
      alert('error', t('Employee'), t('Employee work schedule change success'));
    } catch (err: any) {
      alert(
        'error',
        t('Employee'),
        `${t('An error occurred during change employee work schedule')}: ${
          err?.message
        }`,
      );

      throw err;
    }
  };

  return (
    <>
      {List.isList(workScheduleList) ? (
        <EmployeeWorkScheduleList
          {...rest}
          disabled={disabled}
          workScheduleList={workScheduleList}
          onChange={handleScheduleChange}
          loading={loading}
          value={workScheduleUuid || t('Select a work schedule')}
          notFoundContent={
            <Empty
              description={t(
                "It looks like you don't have any work schedule at the moment",
              )}
            />
          }
        />
      ) : (
        <AddScheduleToEmployeeButton data-testid="employee-create-schedule-redirect" />
      )}
    </>
  );
}
