import * as React from 'react';
import {List} from 'immutable';
import moment from 'moment';
import {useEffect, useState} from 'react';
import {
  useMaterialAndExpenseList,
  IUseMaterialAndExpenseListProps,
  IUseMaterialAndExpenseListReturnType,
} from './useMaterialAndExpenseList';
import {
  MaterialAndExpenseModel,
  MaterialAndExpenseFormDTO,
  MaterialAndExpenseMapper,
} from '../struture';
import {useStateEntityList} from '../components/lib/libV2/hooks';
import {
  createMaterialAndExpense,
  updateMaterialAndExpense,
  deleteMaterialsAndExpenses,
} from '../services/api/materialAndExpense';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../contex';
import {correctPrice, head} from '../services/helpers';

export interface IUseStateMaterialAndExpenseListProps
  extends IUseMaterialAndExpenseListProps {}

export interface IUseStateMaterialAndExpenseListReturnType
  extends Omit<IUseMaterialAndExpenseListReturnType, 'entityList'> {
  materialsAndExpenses: List<MaterialAndExpenseModel> | null;
  handleCreateMaterialAndExpense: (
    value: MaterialAndExpenseFormDTO,
  ) => Promise<void>;
  handleUpdateMaterialAndExpense: (
    value: MaterialAndExpenseFormDTO,
  ) => Promise<void>;
  handleDeleteMaterialAndExpenses: (ids: string[]) => Promise<void>;
  handleSearchMaterialAndExpenses: (value: any) => Promise<void>;
}

export function useStateMaterialAndExpenseList(
  {
    scheduleUuid,
    ...rest
  }: IUseStateMaterialAndExpenseListProps = {} as IUseStateMaterialAndExpenseListProps,
): IUseStateMaterialAndExpenseListReturnType {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const [sumTotal, setSumTotal] = useState<string>('');

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: materialAndExpenseListLoading,
    total,
    sumTotal: materialAndExpenseListSumTotal,
    ...clientsParams
  } = useMaterialAndExpenseList({
    scheduleUuid,
    ...rest,
  });

  const {
    entityList: materialsAndExpenses,
    loading,
    handleCreate,
    handleDelete,
    handleUpdate,
    setEntityList,
    total: materialsAndExpensesTotal,
  } = useStateEntityList<MaterialAndExpenseModel>({
    entityList,
    refresh,
    limit,
    offset,
    total,
  });

  useEffect(() => {
    if (List.isList(materialsAndExpenses) && !sumTotal) {
      setSumTotal(materialAndExpenseListSumTotal);
    }
  }, [materialAndExpenseListSumTotal, materialsAndExpenses, sumTotal]);

  const handleCreateMaterialAndExpense = React.useCallback(
    async (value: MaterialAndExpenseFormDTO) => {
      const materialAndExpense = await createMaterialAndExpense(
        value,
        scheduleUuid,
      );
      const materialAndExpenseModel =
        MaterialAndExpenseMapper.toMaterialAndExpenseModel(materialAndExpense);

      const updatedModel = materialAndExpenseModel.set(
        'created_at',
        moment(new Date()).toString(),
      );

      handleCreate(updatedModel, true);

      setSumTotal((prevState) =>
        correctPrice(
          Number.parseFloat(prevState) + Number.parseFloat(updatedModel?.total),
        ),
      );

      alert(
        'success',
        t('Material and expense'),
        t('Material and expense created success'),
      );
    },
    [alert, handleCreate, scheduleUuid, t],
  );

  const handleUpdateMaterialAndExpense = React.useCallback(
    async (value: MaterialAndExpenseFormDTO) => {
      const materialAndExpense = await updateMaterialAndExpense(value);
      const materialAndExpenseModel =
        MaterialAndExpenseMapper.toMaterialAndExpenseModel(materialAndExpense);

      const cachedMaterialAndExpense = materialsAndExpenses?.find(
        ({uuid}) => uuid === value?.uuid,
      );

      if (cachedMaterialAndExpense) {
        setSumTotal((prevState) =>
          correctPrice(
            Number.parseFloat(prevState) -
              Number.parseFloat(cachedMaterialAndExpense?.total) +
              Number.parseFloat(materialAndExpenseModel?.total),
          ),
        );
      }

      handleUpdate(materialAndExpenseModel);

      alert(
        'success',
        t('Material and expense'),
        t('Material and expense edited success'),
      );
    },
    [alert, handleUpdate, materialsAndExpenses, t],
  );

  const handleDeleteMaterialAndExpenses = React.useCallback(
    async (ids: string[]) => {
      try {
        await deleteMaterialsAndExpenses(ids);

        const cachedMaterialAndExpense = materialsAndExpenses?.find(
          ({uuid}) => uuid === head(ids),
        );

        if (cachedMaterialAndExpense) {
          setSumTotal((prevState) =>
            correctPrice(
              Number.parseFloat(prevState) -
                Number.parseFloat(cachedMaterialAndExpense?.total),
            ),
          );
        }

        handleDelete(ids);

        alert(
          'success',
          t('Materials and expenses'),
          t('Materials and expenses delete success'),
        );
      } catch (error: any) {
        alert(
          'error',
          t('Materials and expenses'),
          `${t('An error occurred during delete materials and expenses')}: ${
            error?.message
          }`,
        );
      }
    },
    [alert, handleDelete, materialsAndExpenses, t],
  );

  const handleSearchMaterialAndExpenses = React.useCallback(
    async (keywords: string) => {
      const materialsAndExpenses = await refresh({
        offset: 0,
        limit: 10,
        keywords,
        showLoading: false,
      });

      if (materialsAndExpenses) {
        setEntityList(materialsAndExpenses.schedule_expenses);
      }
    },
    [refresh, setEntityList],
  );

  return {
    materialsAndExpenses,
    offset,
    limit,
    refresh,
    total: materialsAndExpensesTotal,
    loading: materialAndExpenseListLoading || loading,
    ...clientsParams,
    handleCreateMaterialAndExpense,
    handleUpdateMaterialAndExpense,
    handleDeleteMaterialAndExpenses,
    handleSearchMaterialAndExpenses,
    sumTotal,
  };
}
