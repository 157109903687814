import React from 'react';
import {StoreDocumentModel} from '../../../../struture';
import {StyledTitle} from '../../../../components/lib/Styled';
import styled, {useTheme} from 'styled-components';
import {correctPrice} from '../../../../services/helpers';
import {Tooltip} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';

export interface IStoreSalePriceViewProps {
  document: StoreDocumentModel | null;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  color: ${({theme}) => theme.colors.success};
`;

export function StoreSalePriceView({
  document,
}: IStoreSalePriceViewProps): React.JSX.Element {
  const {t} = useTranslation();
  const theme: any = useTheme();

  const paid = Number(document?.doc_sum_paid) || 0;
  const total = Number(document?.doc_sum_total) || 0;

  const isPaid = paid > 0 && paid >= total;

  return (
    <>
      {document ? (
        <StyledContainer>
          <StyledTitle $color={isPaid ? theme.colors.success : undefined}>
            {correctPrice(document?.doc_sum_total || 0)}
          </StyledTitle>
          {isPaid ? (
            <Tooltip title={t('The document is paid for')}>
              <StyledInfoCircleOutlined />
            </Tooltip>
          ) : null}
        </StyledContainer>
      ) : null}
    </>
  );
}
