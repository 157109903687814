import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {Dropdown, MenuProps} from 'antd';
import {useModal} from '../../../components/lib/libV2/hooks';
import {
  ProductFormDTO,
  ProductCategoryFormDTO,
  ProductModel,
} from '../../../struture';
import {
  IAddButtonProps,
  AddButton,
  SuspenseEmpty,
} from '../../../components/lib/DataDisplay';
import {StyledTitle} from '../../../components/lib/Styled';
import {LoadingOutlined} from '@ant-design/icons';
import styled, {css, useTheme} from 'styled-components';
import {isFunction} from '../../../services/helpers';
import {IoMenu} from 'react-icons/io5';

const ProductSideWindowLazy = React.lazy(
  () => import('../Show/ProductSideWindow'),
);

const ProductCategorySideWindowLazy = React.lazy(
  () => import('../Show/ProductCategorySideWindow'),
);

type ProductSuccess = (value: ProductFormDTO) => Promise<ProductModel | void>;
type GroupSuccess = (
  value: ProductCategoryFormDTO,
) => Promise<ProductModel | void>;

export interface IProductCreateButtonProps extends IAddButtonProps {
  onSuccess?: ProductSuccess;
  onSuccessCategory?: GroupSuccess;
  onCancel?: () => void;
  product?: ProductFormDTO;
}

const StyledDropdown = styled(Dropdown)<{
  disabled?: boolean;
}>`
  cursor: pointer;

  margin-right: 15px;
  .ant-dropdown-menu-item {
    padding: 0 !important;
  }

  ${({disabled}) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

export default function ProductCreateButton({
  loading = false,
  onSuccess,
  onSuccessCategory,
  onCancel,
  product,
  onClick,
  ...rest
}: IProductCreateButtonProps) {
  const {t} = useTranslation();
  const theme: any = useTheme();

  const {
    handleCancel: productHandleCancel,
    handleOnInit: productHandleOnInit,
    handleSuccess: productHandleSuccess,
    visible: productVisible,
  } = useModal({
    onCancel,
    onSuccess: onSuccess,
  });

  const {
    handleCancel: categoryHandleCancel,
    handleOnInit: categoryHandleOnInit,
    handleSuccess: categoryHandleSuccess,
    visible: categoryVisible,
  } = useModal({
    onCancel,
    onSuccess: onSuccessCategory,
  });

  const items: MenuProps['items'] = [
    {
      key: 'product',
      label: <StyledTitle>{t('Create material')}</StyledTitle>,
      onClick: productHandleOnInit,
    },
    {
      key: 'category',
      label: <StyledTitle>{t('Create category')}</StyledTitle>,
      onClick: categoryHandleOnInit,
    },
  ];

  return (
    <>
      {isFunction(onSuccessCategory) && isFunction(onSuccess) ? (
        <StyledDropdown
          trigger={['hover']}
          disabled={!!loading}
          menu={{items}}
          placement="bottomLeft">
          {loading ? (
            <LoadingOutlined color={theme.colors.primary} />
          ) : (
            <IoMenu size={20} color={theme.colors.primary} />
          )}
        </StyledDropdown>
      ) : (
        <AddButton
          tooltipTitle={t('Create material')}
          onClick={(e: any) => {
            if (isFunction(onSuccessCategory)) {
              categoryHandleOnInit();
            } else {
              productHandleOnInit();
            }
            if (isFunction(onClick)) {
              onClick(e);
            }
          }}
          loading={loading}
          {...rest}
        />
      )}

      <Suspense fallback={<SuspenseEmpty />}>
        <ProductSideWindowLazy
          loading={loading as boolean}
          onSuccess={productHandleSuccess}
          onCancel={productHandleCancel}
          visible={productVisible}
          product={product}
        />
      </Suspense>

      <Suspense fallback={<SuspenseEmpty />}>
        <ProductCategorySideWindowLazy
          loading={loading as boolean}
          onSuccess={categoryHandleSuccess}
          onCancel={categoryHandleCancel}
          visible={categoryVisible}
        />
      </Suspense>
    </>
  );
}
