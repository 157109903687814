import {SystemSubscriptionDTO, ISystemSubscriptionDTOProps} from '../internal';

export interface IProfileSubscriptionDTOProps {
  status_txt: string;
  system_subscription: ISystemSubscriptionDTOProps;
  created_at: string;
  updated_at: string;
}

export class ProfileSubscriptionDTO {
  public status_txt: string;

  public system_subscription: SystemSubscriptionDTO;

  public created_at: string;

  public updated_at: string;

  constructor(props: IProfileSubscriptionDTOProps) {
    this.status_txt = props?.status_txt || '';
    this.system_subscription = new SystemSubscriptionDTO(
      props?.system_subscription,
    );
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
  }
}
