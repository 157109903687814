import {List} from 'immutable';
import {
  EmployeeDTO,
  EmployeeFormDTO,
  ServiceParametersFormDTO,
  IEmployeeDTOProps,
  EmployeeModel,
  EmployeeListModel,
  EmployeeInviteFormDTO,
  EmployeeUserDataFormDTO,
  EmployeeRoleFormDTO,
  ServiceModel,
  EmployeeSalaryDTO,
  EmployeeSalaryModel,
  EmployeeSalaryListModel,
  EmployeeSalaryFormDTO,
  IEmployeeSalaryDTOProps,
} from '../internal';
import {listToArray} from '../../helpers';
import {WithoutRecursion} from '../type';

export interface IEmployeeListDTO {
  employees: EmployeeDTO[];
  total: number;
}

export interface IEmployeeSalaryListDTO {
  salary: EmployeeSalaryDTO[];
  total: number;
}

export class EmployeeMapper {
  public static toEmployeeDTO(
    employee: EmployeeModel | IEmployeeDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): EmployeeDTO {
    return new EmployeeDTO(employee, [
      WithoutRecursion.employee,
      ...withoutRecursion,
    ]);
  }

  public static toEmployeeListDTO(
    employees: List<EmployeeModel> | IEmployeeDTOProps[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): IEmployeeListDTO {
    const employeeList = List.isList(employees)
      ? listToArray<EmployeeModel>(employees)
      : employees;

    return {
      employees: employeeList?.map((employee) =>
        EmployeeMapper.toEmployeeDTO(employee, [
          WithoutRecursion.employee,
          ...withoutRecursion,
        ]),
      ),
      total,
    };
  }

  public static toEmployeeModel(
    employeeDTO: EmployeeDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): EmployeeModel {
    return new EmployeeModel(employeeDTO, [
      WithoutRecursion.employee,
      ...withoutRecursion,
    ]);
  }

  public static toEmployeeListModel(
    employeeDTOs: EmployeeDTO[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): EmployeeListModel {
    return new EmployeeListModel({employees: employeeDTOs, total}, [
      WithoutRecursion.employee,
      ...withoutRecursion,
    ]);
  }

  public static toEmployeeFormDTO(
    employeeModel?: EmployeeModel,
  ): EmployeeFormDTO {
    return new EmployeeFormDTO(employeeModel);
  }

  public static toEmployeeInviteFormDTO(
    employeeModel?: EmployeeModel,
  ): EmployeeInviteFormDTO {
    return new EmployeeInviteFormDTO(employeeModel);
  }

  public static toEmployeeUserDataFormDTO(
    employeeModel?: EmployeeModel,
  ): EmployeeUserDataFormDTO {
    return new EmployeeUserDataFormDTO(employeeModel);
  }

  public static toEmployeeRoleFormDTO(
    employeeModel?: EmployeeModel,
  ): EmployeeRoleFormDTO {
    return new EmployeeRoleFormDTO(employeeModel);
  }

  public static toEmployeeServiceFormDTO(
    employee: EmployeeModel | ServiceModel,
  ): ServiceParametersFormDTO {
    return new ServiceParametersFormDTO(employee);
  }

  public static toEmployeeSalaryDTO(
    salary: EmployeeSalaryDTO | EmployeeSalaryModel,
  ): EmployeeSalaryDTO {
    return new EmployeeSalaryDTO(salary);
  }

  public static toEmployeeSalaryListDTO(
    salary: List<EmployeeSalaryModel> | IEmployeeSalaryDTOProps[],
    total: number,
  ): IEmployeeSalaryListDTO {
    const salaryList = List.isList(salary)
      ? listToArray<EmployeeSalaryModel>(salary)
      : salary;

    return {
      salary: salaryList?.map((sl) => EmployeeMapper.toEmployeeSalaryDTO(sl)),
      total,
    };
  }

  public static toEmployeeSalaryModel(
    salaryDTO: EmployeeSalaryDTO,
  ): EmployeeSalaryModel {
    return new EmployeeSalaryModel(salaryDTO);
  }

  public static toEmployeeSalaryListModel(
    salaryDTOs: EmployeeSalaryDTO[],
    total: number,
  ): EmployeeSalaryListModel {
    return new EmployeeSalaryListModel({salary: salaryDTOs, total});
  }

  public static toEmployeeSalaryFormDTO(
    salary: EmployeeSalaryModel,
  ): EmployeeSalaryFormDTO {
    return new EmployeeSalaryFormDTO(salary);
  }
}
