import {List, Map} from 'immutable';
import {APP_STATE, CLIENT_ABONEMENT_LIST} from '../constants';
import {compose, isEqualByUuid, not} from '../../services/helpers';
import {ApiError, REDUX_STATUS} from '../../services/types';
import {
  AbonementGroupModel,
  AbonementModel,
  ClientAbonementListModel,
  ClientAbonementMapper,
  ClientAbonementModel,
  ClientDTO,
  ClientMapper,
  ClientModel,
} from '../../struture';

interface IClientAbonementListState {
  abonementList: List<ClientAbonementModel> | null;
  cachedAbonementList: Map<
    string,
    ClientAbonementListModel<
      ClientModel | AbonementModel | AbonementGroupModel | unknown
    >
  >;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
  total: number;
}

interface SetActionList extends Pick<IClientAbonementListState, 'total'> {
  type: CLIENT_ABONEMENT_LIST.SET_CLIENT_ABONEMENT_LIST;
  clientUuid: string;
  abonementList: ClientAbonementListModel<
    ClientModel | AbonementModel | AbonementGroupModel | unknown
  >;
  keywords?: string;
  page: number;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface AddActionToList {
  type: CLIENT_ABONEMENT_LIST.ADD_CLIENT_ABONEMENT;
  abonement: ClientAbonementModel;
  clientUuid: string;
}

interface UpdateClientAbonementFromList {
  type: CLIENT_ABONEMENT_LIST.UPDATE_CLIENT_ABONEMENT;
  abonement: ClientAbonementModel;
  clientUuid: string;
}

interface DeleteActionFromList {
  type: CLIENT_ABONEMENT_LIST.DELETE_CLIENT_ABONEMENT;
  abonementUuid: string;
  clientUuid: string;
}

interface LoadingActionInList {
  type: CLIENT_ABONEMENT_LIST.LOADING_CLIENT_ABONEMENT_LIST;
}

interface ErrorActionInList extends Pick<IClientAbonementListState, 'error'> {
  type: CLIENT_ABONEMENT_LIST.ERROR_CLIENT_ABONEMENT_LIST;
}

interface Handlers {
  [CLIENT_ABONEMENT_LIST.SET_CLIENT_ABONEMENT_LIST]: (
    state: IClientAbonementListState,
    action: SetActionList,
  ) => IClientAbonementListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IClientAbonementListState,
    action: SetInitialStateAction,
  ) => IClientAbonementListState;

  [CLIENT_ABONEMENT_LIST.LOAD_MORE_CLIENT_ABONEMENT_LIST]: (
    state: IClientAbonementListState,
    action: SetActionList,
  ) => IClientAbonementListState;

  [CLIENT_ABONEMENT_LIST.ADD_CLIENT_ABONEMENT]: (
    state: IClientAbonementListState,
    action: AddActionToList,
  ) => IClientAbonementListState;

  [CLIENT_ABONEMENT_LIST.UPDATE_CLIENT_ABONEMENT]: (
    state: IClientAbonementListState,
    action: UpdateClientAbonementFromList,
  ) => IClientAbonementListState;

  [CLIENT_ABONEMENT_LIST.DELETE_CLIENT_ABONEMENT]: (
    state: IClientAbonementListState,
    action: DeleteActionFromList,
  ) => IClientAbonementListState;

  [CLIENT_ABONEMENT_LIST.ERROR_CLIENT_ABONEMENT_LIST]: (
    state: IClientAbonementListState,
    value: ErrorActionInList,
  ) => IClientAbonementListState;

  [CLIENT_ABONEMENT_LIST.LOADING_CLIENT_ABONEMENT_LIST]: (
    state: IClientAbonementListState,
    value?: LoadingActionInList,
  ) => IClientAbonementListState;
  DEFAULT: (state: IClientAbonementListState) => IClientAbonementListState;
}

const initState: IClientAbonementListState = {
  abonementList: null,
  cachedAbonementList: Map(),
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
  total: 0,
};

const handlers: Handlers = {
  [CLIENT_ABONEMENT_LIST.SET_CLIENT_ABONEMENT_LIST]: (
    state,
    {abonementList, clientUuid, keywords, page},
  ) => {
    const updatedList = abonementList
      .update('keywords', () => keywords || '')
      .update('page', () => page || 1);

    return {
      ...state,
      ...{
        abonementList: abonementList?.abonements || null,
        cachedAbonementList: abonementList
          ? state.cachedAbonementList.set(clientUuid, updatedList)
          : state.cachedAbonementList,
        status: REDUX_STATUS.SUCCEEDED,
        total: abonementList?.total! >= 0 ? abonementList?.total! : state.total,
      },
    };
  },

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [CLIENT_ABONEMENT_LIST.LOAD_MORE_CLIENT_ABONEMENT_LIST]: (
    state,
    {abonementList, clientUuid},
  ) => {
    const relaterScheduleListById = state.cachedAbonementList.get(clientUuid);

    return {
      ...state,
      ...{
        abonementList:
          List.isList(state.abonementList) && List.isList(abonementList)
            ? state.abonementList.merge(abonementList.abonements)
            : state.abonementList,
        cachedAbonementList: abonementList
          ? state.cachedAbonementList.set(
              clientUuid,
              relaterScheduleListById
                ? relaterScheduleListById.update('abonements', (abonements) =>
                    abonements.merge(abonementList.abonements),
                  )
                : abonementList,
            )
          : state.cachedAbonementList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [CLIENT_ABONEMENT_LIST.ADD_CLIENT_ABONEMENT]: (
    state,
    {abonement, clientUuid},
  ) => {
    const relaterScheduleListById = state.cachedAbonementList.get(clientUuid);

    const client = state.cachedAbonementList.first()?.client;

    return {
      ...state,
      ...{
        abonementList: List.isList(state.abonementList)
          ? state.abonementList.unshift(abonement)
          : List([abonement]),
        cachedAbonementList: relaterScheduleListById
          ? state.cachedAbonementList.update(clientUuid, (abonementList) => {
              if (abonementList) {
                return abonementList.update('abonements', (abonements) =>
                  abonements.push(abonement),
                );
              } else {
                return ClientAbonementMapper.toClientAbonementListModel(
                  [abonement] as any,
                  client || ClientMapper.toClientModel({} as ClientDTO),
                  1,
                );
              }
            })
          : Map({
              [clientUuid]: ClientAbonementMapper.toClientAbonementListModel(
                [abonement] as any,
                client || ClientMapper.toClientModel({} as ClientDTO),
                1,
              ),
            }),
        status: REDUX_STATUS.SUCCEEDED,
        total: state.total + 1,
      },
    };
  },

  [CLIENT_ABONEMENT_LIST.UPDATE_CLIENT_ABONEMENT]: (
    state: IClientAbonementListState,
    {abonement, clientUuid}: UpdateClientAbonementFromList,
  ) => {
    const relaterScheduleListById = state.cachedAbonementList.get(clientUuid);

    const client = state.cachedAbonementList.first()?.client;

    return {
      ...state,
      ...{
        abonementList: List.isList(state.abonementList)
          ? state.abonementList.map((stateClientAbonement) => {
              if (stateClientAbonement.uuid === abonement?.uuid) {
                return stateClientAbonement.merge(abonement);
              }
              return stateClientAbonement;
            })
          : List([abonement]),

        cachedAbonementList: relaterScheduleListById
          ? state.cachedAbonementList.update(clientUuid, (abonementList) => {
              if (abonementList) {
                return abonementList.update('abonements', (abonements) =>
                  abonements.map((abon: ClientAbonementModel) => {
                    if (abon.uuid === abonement.uuid) {
                      return abon.merge(abonement);
                    } else {
                      return abon;
                    }
                  }),
                );
              } else {
                return ClientAbonementMapper.toClientAbonementListModel(
                  [],
                  client || ClientMapper.toClientModel({} as ClientDTO),
                  0,
                );
              }
            })
          : state.cachedAbonementList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [CLIENT_ABONEMENT_LIST.DELETE_CLIENT_ABONEMENT]: (state, {abonementUuid}) => {
    return {
      ...state,
      ...{
        abonementList: List.isList(state.abonementList)
          ? state.abonementList.filter(
              compose(not, isEqualByUuid(abonementUuid)),
            )
          : null,
        cachedAbonementList: state.cachedAbonementList.map((abonements) =>
          abonements.update('abonements', (list) => {
            const index = list.findIndex(
              ({uuid}: ClientAbonementModel) => uuid === abonementUuid,
            );

            if (~index) {
              return list.delete(index);
            }

            return list;
          }),
        ),
        status: REDUX_STATUS.SUCCEEDED,
        total: state.total > 0 ? state.total - 1 : 0,
      },
    };
  },

  [CLIENT_ABONEMENT_LIST.ERROR_CLIENT_ABONEMENT_LIST]: (
    state: IClientAbonementListState,
    {error}: ErrorActionInList,
  ) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [CLIENT_ABONEMENT_LIST.LOADING_CLIENT_ABONEMENT_LIST]: (
    state: IClientAbonementListState,
  ) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IClientAbonementListState) => state,
};

export default function ClientAbonementList(
  state: any = initState,
  action: any,
): IClientAbonementListState {
  const handler =
    handlers[action.type as CLIENT_ABONEMENT_LIST] || handlers.DEFAULT;
  return handler(state, action);
}
