import {configureStore} from '@reduxjs/toolkit';
import {Routes} from '../services/types';
import companies from './reducers/companies';
import defaultCompany from './reducers/defaultCompany';
import profile from './reducers/profile';
import signupPageState from './reducers/signupPageState';
import employeeSignupPageState from './reducers/employeeSignupPageState';
import forgotPasswordPageState from './reducers/forgotPasswordPageState';
import approveEnterPageState from './reducers/approveEnterPageState';
import authData from './reducers/authData';
import employee from './reducers/employee';
import employeeServiceList from './reducers/employeeServiceList';
import employeeAttachedServiceList from './reducers/employeeAttachedServiceList';
import service from './reducers/service';
import serviceEmployeeList from './reducers/serviceEmployeeList';
import client from './reducers/client';
import settingsModule from './reducers/settingsModule';
import settingsModuleList from './reducers/settingsModuleList';
import report from './reducers/report';
import reportList from './reducers/reportList';

import abonement from './reducers/abonement';
import abonementList from './reducers/abonementList';

import clientAbonement from './reducers/clientAbonement';
import clientAbonementList from './reducers/clientAbonementList';
import clientAbonementSubscriptionList from './reducers/clientAbonementSubscriptionList';
import clientVisit from './reducers/clientVisit';
import clientVisitList from './reducers/clientVisitList';

import abonementGroup from './reducers/abonementGroup';
import abonementGroupList from './reducers/abonementGroupList';

import clientAbonementScheduleList from './reducers/clientAbonementScheduleList';

import planList from './reducers/planList';

import status from './reducers/status';
import statusList from './reducers/statusList';
import statusCategoryList from './reducers/statusCategoryList';

import product from './reducers/product';
import productList from './reducers/productList';
import productCategory from './reducers/productCategory';
import productCategoryList from './reducers/productCategoryList';

import cashBox from './reducers/cashBox';
import cashBoxList from './reducers/cashBoxList';

import fileList from './reducers/fileList';

import reward from './reducers/reward';
import rewardList from './reducers/rewardList';

import department from './reducers/department';
import departmentList from './reducers/departmentList';

import store from './reducers/store';
import storeList from './reducers/storeList';

import priceMargin from './reducers/priceMargin';
import priceMarginList from './reducers/priceMarginList';

import storeDocument from './reducers/storeDocument';
import storeDocumentList from './reducers/storeDocumentList';

import productCategoryProductList from './reducers/productCategoryProductList';
import productCategorySubCategoryList from './reducers/productCategorySubCategoryList';
import productLeftoversList from './reducers/productLeftoversList';

import priceTagList from './reducers/priceTagList';
import integrationListReducer from './features/integrationListSlice';
import invoiceListReducer from './features/invoiceListSlice';
import invoiceReducer from './features/invoiceSlice';
import photoListReducer from './features/photoListSlice';

import productCategoryListReducer from './features/productCategoryListSlice';
import productListReducer from './features/productListSlice';

// import storeDocumentRelatedDocumentList from './reducers/storeDocumentRelatedDocumentList';

export const defaultStore = configureStore({
  reducer: {
    companies,
    defaultCompany,
    profile,
    authData,
    employee,
    employeeServiceList,
    employeeAttachedServiceList,
    service,
    serviceEmployeeList,
    client,
    settingsModule,
    settingsModuleList,
    report,
    reportList,
    abonement,
    abonementList,
    clientAbonement,
    clientAbonementList,
    clientAbonementSubscriptionList,
    clientVisit,
    clientVisitList,
    abonementGroup,
    abonementGroupList,
    clientAbonementScheduleList,
    planList,
    status,
    statusList,
    statusCategoryList,
    product,
    productList,
    productCategory,
    productCategoryList,
    cashBox,
    cashBoxList,
    fileList,
    reward,
    rewardList,
    department,
    departmentList,
    store,
    storeList,
    priceMargin,
    priceMarginList,
    storeDocument,
    storeDocumentList,
    productCategoryProductList,
    productCategorySubCategoryList,
    productLeftoversList,
    priceTagList,
    invoiceReducer,
    integrationListReducer,
    invoiceListReducer,
    photoListReducer,
    productCategoryListReducer,
    productListReducer,
    [Routes.signup]: signupPageState,
    [Routes.employeeSignup]: employeeSignupPageState,
    [Routes.forgotPassword]: forgotPasswordPageState,
    [Routes.approveEnter]: approveEnterPageState,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});
