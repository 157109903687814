import {Record} from 'immutable';
import {toTimestamp} from '../services/core';

export const MESSAGE_TYPE_UNKNOWN = 0;

export interface JobMessageProps {
  id: string;
  type: number;
  message: string;
  createdAt: string;
}

/**
 * @class JobMessage
 * @classdesc
 * Storage location model.
 * @property {string} name The storage location name
 * @property {string} type The storage location type (default mysql)
 */
export default class JobMessage extends Record({
  id: '',
  type: MESSAGE_TYPE_UNKNOWN,
  message: '',
  createdAt: null,
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(props: JobMessageProps = {} as JobMessageProps) {
    // @ts-ignore
    super({...props, createdAt: toTimestamp(props.createdAt)});
  }
}
