import React from 'react';
import {Empty, Row} from 'antd';
import {withTranslation, WithTranslation} from 'react-i18next';
import styled from 'styled-components';
import {IUseStateClientSubscriptionsReturnType} from '../../../hooks';
import {isThereContent, head, last} from '../../../services/helpers';
import PageWrapper from '../../../components/lib/Layout/PageWrapper';
import {CreateClientSubscriptionButton} from '../Buttons';
import ClientSubscriptionCard from './ClientSubscriptionCard';

export default withTranslation()(ClientSubscriptionsTab);

export interface IClientSubscriptionsTabProps
  extends Omit<IUseStateClientSubscriptionsReturnType, 'refresh'>,
    WithTranslation {
  companyUuid: string;
  clientUuid: string;
}

const StyledPageWrapper = styled(PageWrapper)`
  & .PageWrapper_header {
    padding: 10px 0;
  }
`;

function ClientSubscriptionsTab({
  subscriptions,
  t,
  loading,
  handleSubscriptionCreate,
  handleSubscriptionUpdate,
  handleGetSubscriptionTransition,
  handleSubscriptionCancel,
  companyUuid,
  clientUuid,
}: IClientSubscriptionsTabProps) {
  const serviceHeaderButtons = [
    <CreateClientSubscriptionButton
      clientUuid={clientUuid}
      onSuccess={handleSubscriptionCreate}
      loading={loading}
      companyUuid={companyUuid}
    />,
  ];

  return (
    <StyledPageWrapper
      className="client-services"
      data-testid="client-services"
      title={t('Ordered services')}
      horizontalScroll
      headerMenu={
        isThereContent(subscriptions) && <>{head(serviceHeaderButtons)}</>
      }>
      {isThereContent(subscriptions) ? (
        <Row gutter={24}>
          {Object.values(subscriptions).map((groupedSubscription) => (
            <ClientSubscriptionCard
              key={last(groupedSubscription)?.uuid}
              groupedSubscription={groupedSubscription}
              handleUpdate={handleSubscriptionUpdate}
              handleCancel={handleSubscriptionCancel}
              handleTransition={handleGetSubscriptionTransition}
              companyUuid={companyUuid}
              clientUuid={clientUuid}
            />
          ))}
        </Row>
      ) : loading ? (
        <Empty description={`...${t('Loading')}`} />
      ) : (
        <Empty
          description={
            <span data-testid="service-no-session-message">
              {t('No services found')}
            </span>
          }>
          {head(serviceHeaderButtons)}
        </Empty>
      )}
    </StyledPageWrapper>
  );
}
