import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDefaultForm} from '../../../contex';
import {
  ClientModel,
  ScheduleCalendarDTO,
  ScheduleCalendarFormDTO,
  ScheduleCalendarMapper,
  ScheduleCalendarModel,
} from '../../../struture';
import moment, {Moment} from 'moment/moment';
import {correctOrderScheduleDate} from '../../../services/api/orders';
import {SelectValue} from 'antd/lib/select';
import {listToArray, toDateByFormat} from '../../../services/helpers';
import {List} from 'immutable';
import {Col, Form, Input, Row} from 'antd';
import {
  SearchSelect,
  Switch,
  Button,
  Collapse,
} from '../../../components/lib/DataDisplay';
import {StyledDescription} from '../../../components/lib/Styled';
import {
  DatePicker,
  PhoneInputComponent as PhoneInput,
} from '../../../components/lib/General';
import {OrderCalendarDayManager} from '../Show';
import {IGetListEmployeeScheduleProps} from './OrderForm';
import {IUseStateCompanyClientListReturnType, useSize} from '../../../hooks';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {ClientPersonalityType} from '../../../services/types';
import {EyeOutlined, EyeInvisibleOutlined} from '@ant-design/icons';
import {FaUserAlt, FaUsers} from 'react-icons/fa';
import {phoneScreen} from '../../../services/const';

import './OrderFormFields.less';

export interface IOrderFormFieldsProps
  extends Pick<
    IUseStateCompanyClientListReturnType,
    'clients' | 'handleSearchClients'
  > {
  loading?: boolean;
  clientsLoading: boolean;
  editMode: boolean;
  disabled?: boolean;
  disabledClient?: boolean;

  employeeScheduleList: List<ScheduleCalendarModel> | null;
  employeeScheduleListLoading: boolean;
  handleRefreshEmployeeScheduleList: (
    value: IGetListEmployeeScheduleProps,
  ) => Promise<void>;

  handleChangeClientType: (client_type: ClientPersonalityType) => Promise<void>;

  order?: ScheduleCalendarFormDTO;
}

const StyledSearchSelect = styled(SearchSelect)<{type: ClientPersonalityType}>`
  &&& {
    width: 60px;

    & .ant-select-selection-item {
      margin-top: 0;
      transition: none;

      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

const StyledHeader = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const CLIENT_SERVICES_PERSONALITY = [
  {
    uuid: ClientPersonalityType.Individual,
    title: ClientPersonalityType.Individual,
    description: <FaUserAlt size={14} />,
  },
  {
    uuid: ClientPersonalityType.Group,
    title: ClientPersonalityType.Group,
    description: <FaUsers size={18} />,
  },
];

export function OrderFormFields({
  loading,
  editMode,
  disabled,

  employeeScheduleList,
  employeeScheduleListLoading,
  handleRefreshEmployeeScheduleList,

  clients,
  clientsLoading,
  disabledClient,
  handleSearchClients,
  handleChangeClientType,

  order,
}: IOrderFormFieldsProps): JSX.Element {
  const {t} = useTranslation();

  const {loadingSubmit, handlerUpdateFormState, formData} = useDefaultForm();

  const [isCreateClient, setIsCreateClient] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);

  const onceClient = useRef(false);
  const once = useRef(false);
  const titleRef = useRef<any>(null);

  const contentRef = useRef(null);
  const {width: contentWidth} = useSize(contentRef);
  const isFullWidth = contentWidth <= phoneScreen ? 24 : 12;

  const [clientLoading, setClientLoading] = useState(false);

  const handleChangeScheduledDate = useCallback(
    async (e: Moment | null, withTime = true) => {
      handlerUpdateFormState({
        scheduled_date: withTime
          ? correctOrderScheduleDate({
              time: formData?.time || '',
              scheduled_date: e || moment(),
            })
          : e,
        time: withTime ? formData?.time : toDateByFormat(e, 'HH:mm'),
      });

      if (formData?.mode_date_and_time) {
        await handleRefreshEmployeeScheduleList({
          date: e,
          employeeUuid: formData.worker_uuid,
        });
      }
    },
    [
      formData?.time,
      formData.worker_uuid,
      handleRefreshEmployeeScheduleList,
      handlerUpdateFormState,
      formData?.mode_date_and_time,
    ],
  );

  const handleChangeClientTypeField = useCallback(
    async (client_type: ClientPersonalityType) => {
      titleRef.current = null;

      if (client_type !== formData?.client_type) {
        setClientLoading(true);
        handlerUpdateFormState({client_type, client: {uuid: ''}});
        await handleChangeClientType(client_type);
        setClientLoading(false);
      }
    },
    [formData?.client_type, handleChangeClientType, handlerUpdateFormState],
  );

  const handleChangeTime = useCallback(
    (time: string): void => {
      handlerUpdateFormState({
        time,
        scheduled_date: correctOrderScheduleDate({
          time,
          scheduled_date: formData?.scheduled_date,
        }),
      });
    },
    [formData?.scheduled_date, handlerUpdateFormState],
  );

  const onClientCreate = useCallback(
    (checked: boolean) => {
      handlerUpdateFormState({
        client: {uuid: ''},
        client_type: ClientPersonalityType.Individual,
      });
      setIsCreateClient(checked);
    },
    [handlerUpdateFormState],
  );

  const handleChangeClient = useCallback(
    (e: SelectValue): void => {
      onceClient.current = true;

      handlerUpdateFormState({client: {uuid: e}});
    },
    [handlerUpdateFormState],
  );

  const disabledClientField =
    loading || loadingSubmit || disabledClient || disabled || clientLoading;

  useEffect(() => {
    if (!formData?.time) {
      handlerUpdateFormState({
        time: toDateByFormat(formData?.scheduled_date, 'HH:mm'),
      });
    }
  }, [formData?.scheduled_date, formData?.time, handlerUpdateFormState]);

  useEffect(() => {
    if (titleRef?.current) {
      setTimeout(() => {
        titleRef?.current?.focus();
      }, 100);
    }
  }, [isCreateClient, formData?.client_type]);

  return (
    <Row gutter={20} justify="end" ref={contentRef}>
      <StyledHeader span={24}>
        <StyledDescription fontSize={15}>
          {t('Order details')}
        </StyledDescription>
        <Switch
          title={t('New client')}
          position="left"
          onChange={onClientCreate}
          checked={isCreateClient}
        />
      </StyledHeader>

      {isCreateClient ? (
        <>
          <Col span={24}>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: t('Client Phone must be specified.'),
                },
              ]}>
              <PhoneInput
                size="large"
                ref={titleRef}
                placeholder={t('Client phone')}
                id="field-phone"
                containerClass="field-phone-container"
                searchClass="field-phone-search"
                inputClass="ant-input field-phone"
                buttonClass="field-phone-flag"
                loading={loading || loadingSubmit}
                onChange={(phone) =>
                  handlerUpdateFormState(({client}) => ({
                    client: {...client, phone},
                  }))
                }
              />
            </Form.Item>
          </Col>

          <Col span={isFullWidth}>
            <Form.Item
              name="last_name"
              rules={[
                {
                  required: true,
                  message: t('Client last name must be specified.'),
                },
              ]}>
              <Input
                size="large"
                disabled={loading || loadingSubmit}
                placeholder={t('Client last name')}
                onChange={(e) =>
                  handlerUpdateFormState(({client}) => ({
                    client: {...client, last_name: e?.target?.value},
                  }))
                }
              />
            </Form.Item>
          </Col>
          <Col span={isFullWidth}>
            <Form.Item>
              <Input
                size="large"
                disabled={loading || loadingSubmit}
                placeholder={t('Client first name')}
                onChange={(e) =>
                  handlerUpdateFormState(({client}) => ({
                    client: {...client, first_name: e?.target?.value},
                  }))
                }
              />
            </Form.Item>
          </Col>
        </>
      ) : formData?.client_type === ClientPersonalityType.Individual ? (
        <Col span={24}>
          <Form.Item name="client">
            <SearchSelect
              ref={titleRef}
              size="large"
              asObject={
                editMode && formData?.client?.uuid ? onceClient.current : true
              }
              selectFirst={clients?.size === 1}
              valuePropName="uuid"
              name="client"
              disable={
                loading ||
                disabledClientField ||
                clientsLoading ||
                loadingSubmit
              }
              placeholder={t('Client')}
              data={listToArray(clients as any)}
              onChange={handleChangeClient}
              getOptionValueProps="uuid"
              getOptionValueTitle="fullNameClient"
              getOptionValueSecond="phone"
              onSearch={handleSearchClients as any}
              resetState={clientLoading}
              addonBefore={
                <Form.Item noStyle name="client_type">
                  <StyledSearchSelect
                    withOutSearch
                    popupClassName="order-select-clint-type"
                    size="large"
                    containerStyle={{width: 'auto'}}
                    type={ClientPersonalityType.Individual}
                    popupMatchSelectWidth={false}
                    name="client_type"
                    placeholder={''}
                    getOptionValueTitle="description"
                    disable={disabled || loading || loadingSubmit}
                    data={CLIENT_SERVICES_PERSONALITY}
                    onChange={handleChangeClientTypeField}
                  />
                </Form.Item>
              }
            />
          </Form.Item>
        </Col>
      ) : formData?.client_type === ClientPersonalityType.Group ? (
        <Col span={24}>
          <Form.Item name="client">
            <SearchSelect
              ref={titleRef}
              size="large"
              asObject={editMode ? onceClient.current : true}
              selectFirst={clients?.size === 1}
              valuePropName="uuid"
              name="client"
              disable={
                loading ||
                disabledClientField ||
                clientsLoading ||
                loadingSubmit
              }
              placeholder={t('Client group')}
              data={listToArray(clients as any)}
              onChange={handleChangeClient}
              getOptionValueProps="uuid"
              getOptionValueTitle={(client: ClientModel) => {
                return client?.client_group_name;
              }}
              onSearch={handleSearchClients as any}
              resetState={clientLoading}
              addonBefore={
                <Form.Item noStyle name="client_type">
                  <StyledSearchSelect
                    withOutSearch
                    popupClassName="order-select-clint-type"
                    size="large"
                    containerStyle={{width: 'auto'}}
                    type={ClientPersonalityType.Group}
                    popupMatchSelectWidth={false}
                    name="client_type"
                    placeholder={''}
                    getOptionValueTitle="description"
                    disable={loading || loadingSubmit}
                    data={CLIENT_SERVICES_PERSONALITY}
                    onChange={handleChangeClientTypeField}
                  />
                </Form.Item>
              }
            />
          </Form.Item>
        </Col>
      ) : null}

      <Col span={24}>
        <StyledDescription>{t('Order date and time')}</StyledDescription>
      </Col>

      <Col span={isFullWidth}>
        <Form.Item
          name="scheduled_date"
          rules={[
            {
              required: true,
              message: t('Date and time must be specified'),
            },
          ]}
          tooltip={t('The date and time for which the order is scheduled')}>
          <DatePicker
            showTime
            size="large"
            format={'YYYY-MM-DD HH:mm'}
            disabled={loading || loadingSubmit}
            placeholder={t('Select date and time')}
            onChange={(e) => handleChangeScheduledDate(e, false)}
          />
        </Form.Item>
      </Col>

      <Col span={isFullWidth}>
        <Form.Item>
          <Button
            disabled={loading || loadingSubmit}
            size="large"
            onClick={() => setShowCalendar((prevState) => !prevState)}
            icon={showCalendar ? <EyeOutlined /> : <EyeInvisibleOutlined />}>
            {showCalendar ? t('Hide calendar') : t('Show calendar')}
          </Button>
        </Form.Item>
      </Col>

      {showCalendar ? (
        <Col span={24}>
          <Form.Item
            name="time"
            rules={[
              {
                required: true,
                message: t('Date and time must be specified'),
              },
            ]}
            tooltip={t('The date and time for which the order is scheduled')}>
            <OrderCalendarDayManager
              limitedByDate={order?.parent_date}
              isEdit={editMode}
              schedules={employeeScheduleList || undefined}
              schedule={ScheduleCalendarMapper.toScheduleCalendarModel(
                {} as ScheduleCalendarDTO,
              )}
              handleChangeDate={handleChangeScheduledDate}
              handleChangeTime={handleChangeTime}
              scheduleDate={formData.scheduled_date}
              scheduleUuid={formData.uuid}
              editMode={editMode || !!order?.parent_date}
              scheduleLoading={employeeScheduleListLoading}
            />
          </Form.Item>
        </Col>
      ) : null}

      {order?.parent_uuid ? (
        <Col span={24}>
          <Form.Item
            label={t('The main order')}
            tooltip={t('The main order')}
            extra={
              <StyledDescription>
                {`${t('From')} ${toDateByFormat(
                  order?.scheduled_date,
                  'DD-MM-YYYY HH:mm',
                )}`}
              </StyledDescription>
            }
            name="parent_number">
            <Input size="large" disabled />
          </Form.Item>
        </Col>
      ) : null}

      <Col span={24}>
        <StyledDescription>
          {t('Brief description of the order')}
        </StyledDescription>
      </Col>

      <Col span={24}>
        <Form.Item
          name="schedule_title"
          rules={[
            {
              required: true,
              message: t('Details must be specified.'),
            },
          ]}>
          <Input
            size="large"
            disabled={loading || loadingSubmit}
            placeholder={t('Enter key order details')}
            onChange={(e) =>
              handlerUpdateFormState({schedule_title: e?.target?.value})
            }
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Collapse
          title={(isExpanded) =>
            isExpanded ? t('Hide') : t('Additional parameters')
          }
          height={150}>
          <Row>
            <Col span={24}>
              <StyledDescription>{t('Order note')}</StyledDescription>
            </Col>

            <Col span={24}>
              <Form.Item name="comment" noStyle>
                <Input.TextArea
                  size="large"
                  allowClear
                  autoSize={{minRows: 3, maxRows: 5}}
                  disabled={loading || loadingSubmit}
                  placeholder={t('Order description')}
                  onChange={(e) =>
                    handlerUpdateFormState({comment: e.target.value})
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Collapse>
      </Col>
    </Row>
  );
}
