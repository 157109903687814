import {Record} from 'immutable';

export interface IAttendanceProps {
  uuid: string;
  attendance_date: string;
  updated_at: string;
  created_at: string;
}

/**
 * @class Attendance
 * @desc Attendance for the Client's group session
 * @extends {Record}
 * @property {string} uuid - backup id
 */
export default class Attendance extends Record({
  uuid: '',
  attendance_date: '',
  updated_at: null,
  created_at: null,
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(props: IAttendanceProps = {} as IAttendanceProps) {
    super({...props});
  }
}
