import React, {forwardRef} from 'react';
import styled from 'styled-components';
import {StyledTitle} from '../../../components/lib/Styled';
import {useTranslation} from 'react-i18next';
import {useStoredCompanies} from '../../../hooks';
import {ScheduleCalendarStatsDTO} from '../../../struture';
import {OverviewBottomView} from '../../../components/lib/Layout';
import {correctPrice} from '../../../services/helpers';
import {GetCompanyScheduleState} from '../../../services/api/company';

export interface IScheduleBottomViewProps {
  stats: ScheduleCalendarStatsDTO;
  state: GetCompanyScheduleState;
}

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  gap: 2px;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 2px;
`;

const StyledPrice = styled(StyledTitle)`
  font-size: 30px;
`;

const StyledPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 5px;
`;

export const ScheduleBottomView = forwardRef(function ScheduleBottomView(
  {stats, state}: IScheduleBottomViewProps,
  ref: any,
): JSX.Element {
  const {t} = useTranslation();
  const {defaultCompany} = useStoredCompanies();

  const totals = {
    [GetCompanyScheduleState.ALL]: stats.totalSum,
    [GetCompanyScheduleState.OVERDUE]: stats.totalOverdueSum,
    [GetCompanyScheduleState.UNPAID]: stats.totalUnPaidSum,
    [GetCompanyScheduleState.CANCELLED]: stats.totalCancelledSum,
  };

  return (
    <>
      <div ref={ref}>
        <OverviewBottomView>
          <StyledWrapper>
            <StyledContainer>
              <StyledTitle fontSize={14}>{`${t(
                'Amount of orders',
              )}: `}</StyledTitle>
              <StyledPriceContainer>
                <StyledPrice>
                  {`${defaultCompany?.currency_symbol || ''} ${correctPrice(
                    totals[state] || 0,
                  )}`}
                </StyledPrice>
              </StyledPriceContainer>
            </StyledContainer>
          </StyledWrapper>
        </OverviewBottomView>
      </div>
    </>
  );
});
