import * as React from 'react';
import {getEmployeeById} from '../services/api/employee';
import {EmployeeModel, EmployeeMapper, EmployeeDTO} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {IEntityProps} from '../services/types';
import {useDropdownAlert} from '../contex';
import {useTranslation} from 'react-i18next';

export interface IUseEmployeeProps extends IEntityProps {
  employeeUuid: string;
}

export interface IUseEmployeeReturnType {
  error: null | any;
  loading: boolean;
  entity: EmployeeModel | null;
  refresh: (value: Partial<IUseEmployeeProps>) => Promise<EmployeeModel | void>;
  employeeUuid: string;
}

export function useEmployee(
  {
    loadOnInit = true,
    employeeUuid,
  }: IUseEmployeeProps = {} as IUseEmployeeProps,
): IUseEmployeeReturnType {
  const {t} = useTranslation();
  const {cancellablePromise, didCancel} = useCancellablePromise();
  const {alert} = useDropdownAlert();

  const {
    entity,
    loading,
    error,
    handleUpdate,
    employeeUuid: entityUuid,
  } = useStateReducer<Omit<IUseEmployeeReturnType, 'refresh'>>({
    entity: null,
    employeeUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading,
      employeeUuid = entityUuid,
    }: Partial<IUseEmployeeProps> = {}): Promise<EmployeeModel | void> => {
      try {
        handleUpdate({loading: showLoading, error: null, employeeUuid});

        const employee = await cancellablePromise<EmployeeDTO>(
          getEmployeeById(employeeUuid),
        );

        const employeeModel = EmployeeMapper.toEmployeeModel(employee);

        if (!didCancel.current) {
          handleUpdate({
            entity: employeeModel,
            loading: false,
          });
          return employeeModel;
        }
      } catch (err: any) {
        if (!didCancel.current) {
          handleUpdate({
            error: err,
            loading: false,
          });
        }

        alert(
          'error',
          t('Employee'),
          `${t('An error occurred during get employee')} : ${err?.message}`,
        );
      }
    },

    [alert, cancellablePromise, didCancel, entityUuid, handleUpdate, t],
  );

  React.useEffect(() => {
    if (loadOnInit && employeeUuid) {
      (async () => {
        await refresh({employeeUuid});
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, employeeUuid]);

  return {
    error,
    loading,
    refresh,
    entity,
    employeeUuid,
  };
}
