import {Record} from 'immutable';
import {
  ClientDTO,
  EmployeeDTO,
  ScheduleCalendarDTO,
  EmployeeModel,
  ClientMapper,
  EmployeeMapper,
  ScheduleCalendarParentDTO,
  StatusMapper,
  ScheduleCalendarLatestStatusDTO,
  ScheduleCalendarLatestStatusModel,
  ProfileModel,
  ProfileDTO,
  ProfileMapper,
  ClientModel,
  ScheduleStatusesText,
} from '../internal';
import {WithoutRecursion} from '../type';

export const SCHEDULE_CALENDAR_STATUS_ACTIVE = 1;

export interface IScheduleCalendarModelReturnType {
  scheduled_date: string;
  status: string;
  schedule_title: string;
  uuid: string;
  updated_at: string;
  created_at: string;
  status_text: ScheduleStatusesText;
  price: string;
  schedule_number: string;
  sum_total: string;
  sum_related: string;
  status_color: string;
  schedule_expenses_sum: string;
  comment: string;
  is_child: boolean;
  schedule_has_strict_mode: boolean;
  employee: EmployeeModel | EmployeeDTO;
  client: ClientModel | ClientDTO;
  latest_status:
    | ScheduleCalendarLatestStatusModel
    | ScheduleCalendarLatestStatusDTO;
  schedule_amount: number;
  schedule_priority: number;
  parent: ScheduleCalendarParentDTO;
  created_by: ProfileModel | ProfileDTO;
  sum_paid: string;
}

export class ScheduleCalendarModel extends Record<IScheduleCalendarModelReturnType>(
  {
    scheduled_date: '',
    schedule_title: '',
    status: '',
    uuid: '',
    updated_at: '',
    created_at: '',
    status_text: ScheduleStatusesText.NEW,
    sum_total: '',
    comment: '',
    sum_related: '0.00',
    status_color: '',
    price: '0.00',
    schedule_expenses_sum: '0.00',
    sum_paid: '0.00',
    schedule_number: '',
    schedule_amount: 0,
    schedule_priority: 0,
    is_child: false,
    schedule_has_strict_mode: false,
    employee: {} as EmployeeModel,
    client: {} as ClientModel,
    parent: {} as ScheduleCalendarParentDTO,
    latest_status: {} as ScheduleCalendarLatestStatusDTO,
    created_by: {} as ProfileModel,
  },
) {
  constructor(
    props: ScheduleCalendarDTO = {} as ScheduleCalendarDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IScheduleCalendarModelReturnType & any = {
      ...props,

      employee:
        withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
          ? props?.employee
          : EmployeeMapper.toEmployeeModel(props?.employee, [
              WithoutRecursion.scheduleCalendar,
              ...withoutRecursion,
            ]),

      client:
        withoutRecursion.indexOf(WithoutRecursion.client) !== -1
          ? props?.client
          : ClientMapper.toClientModel(props?.client, false, [
              WithoutRecursion.scheduleCalendar,
              ...withoutRecursion,
            ]),

      latest_status:
        withoutRecursion.indexOf(WithoutRecursion.status) !== -1
          ? props?.latest_status
          : StatusMapper.toScheduleCalendarLatestStatusModel(
              props?.latest_status,
            ),
      created_by:
        withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
          ? props?.created_by
          : ProfileMapper.toProfileModel(props?.created_by, [
              WithoutRecursion.clientAbonement,
              ...withoutRecursion,
            ]),
    };

    super(options);
  }

  get employeeModel(): EmployeeModel {
    return this.employee instanceof EmployeeModel
      ? this.employee
      : EmployeeMapper.toEmployeeModel(this.employee);
  }

  get clientModel(): ClientModel {
    return this.client instanceof ClientModel
      ? this.client
      : ClientMapper.toClientModel(this.client);
  }

  get latestStatusModel(): ScheduleCalendarLatestStatusModel {
    return this.latest_status instanceof ScheduleCalendarLatestStatusModel
      ? this.latest_status
      : StatusMapper.toScheduleCalendarLatestStatusModel(this.latest_status);
  }

  get createdByModel(): ProfileModel {
    return this.created_by instanceof ProfileModel
      ? this.created_by
      : ProfileMapper.toProfileModel(this.created_by);
  }
}
