export interface IResetPasswordDTOProps {
  password?: string;
  password_confirm?: string;
  verification_code?: string;
}

export class ResetPasswordDTO {
  public password_confirm: string;

  public password: string;

  public verification_code: string;

  constructor(props?: IResetPasswordDTOProps) {
    this.password_confirm = props?.password_confirm || '';
    this.password = props?.password || '';
    this.verification_code = props?.verification_code || '';
  }
}
