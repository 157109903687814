import * as React from 'react';
import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ClientDTO,
  ClientMapper,
  ClientModel,
  ProductModel,
  StoreDocumentFormDTO,
  StoreDocumentItemFormDTO,
  StoreDocumentMapper,
  StoreDocumentModel,
  StoreDocumentStatus,
  StoreDocumentType,
} from '../../../../struture';
import {
  useModal,
  useStateReducer,
} from '../../../../components/lib/libV2/hooks';
import {produce} from 'immer';
import {LocalStorageItems} from '../../../../services/const';
import {
  useDefaultPriceMargin,
  useStoredCompanies,
  useStoredStoreDocument,
} from '../../../../hooks';
import {SuspenseEmpty} from '../../../../components/lib/DataDisplay';
import {useNavigate} from 'react-router';
import {Routes} from '../../../../services/types';

const LeftoverPreorderSideWindowLazy = React.lazy(
  () => import('../Show/LeftoverPreorderSideWindow'),
);

export interface ILeftoverPreorderProviderProps {
  children: React.ReactNode;
}

export interface ILeftoverPreorderProviderContext {
  document: StoreDocumentFormDTO;
  handleAddProductPreorder: (product: ProductModel, storeUuid: string) => void;
  handleUpdatePreorderItem: (
    documentItem: StoreDocumentItemFormDTO,
    withProductAmount: boolean,
  ) => void;
  handleDeletePreorderItem: (documentItem: StoreDocumentItemFormDTO) => void;
  handleUpdatePreorderProvider: (client: ClientModel) => void;
  handleOpenLeftoverForm: () => void;
  handleClearLeftoverPreorder: () => void;
  countOfProducts: number;
  isInitPreorder: boolean;
}

export interface LeftoverPreorderManagerState {
  isInitPreorder: boolean;
}

export const LeftoverPreorderManagerContext =
  React.createContext<ILeftoverPreorderProviderContext>({
    document: StoreDocumentMapper.toStoreDocumentFormDTO(
      {} as StoreDocumentModel,
      {editMode: false},
    ),
    handleAddProductPreorder: () => {},
    handleUpdatePreorderItem: () => {},
    handleUpdatePreorderProvider: () => {},
    handleDeletePreorderItem: () => {},
    handleOpenLeftoverForm: () => {},
    handleClearLeftoverPreorder: () => {},
    countOfProducts: 0,
    isInitPreorder: false,
  });

export const useLeftoverPreorder = () =>
  React.useContext(LeftoverPreorderManagerContext);

export function LeftoverPreorderManager({
  children,
}: ILeftoverPreorderProviderProps): React.JSX.Element {
  const {defaultCompanyUuid} = useStoredCompanies();
  const navigate = useNavigate();

  const [documentForm, setDocumentForm] = useState<StoreDocumentFormDTO>(
    StoreDocumentMapper.toStoreDocumentFormDTO({} as StoreDocumentModel, {
      editMode: true,
    }),
  );

  const isStartSaveToLocalStorage = useRef(false);

  const {isInitPreorder, handleUpdate} =
    useStateReducer<LeftoverPreorderManagerState>({
      isInitPreorder: false,
    });

  const {getProductPriceMargin} = useDefaultPriceMargin({});

  const {handleCreateStoreDocument: onCreateStoreDocument} =
    useStoredStoreDocument({
      companyUuid: defaultCompanyUuid,
      documentUuid: '',
      documentType: StoreDocumentType.PREORDER,
      loadOnInit: false,
    });

  const handleUpdatePreorderProvider = useCallback((client: ClientModel) => {
    setDocumentForm((prevState) =>
      produce(prevState, (draft) => {
        draft.doc_client = {
          uuid: client?.uuid,
          first_name: client?.first_name,
          last_name: client?.last_name,
        };
      }),
    );
  }, []);

  const handleDeletePreorderItem = useCallback(
    (documentItemFormDTO: StoreDocumentItemFormDTO) => {
      setDocumentForm((prevState) =>
        produce(prevState, (draft) => {
          draft.doc_items = draft.doc_items.filter(
            ({product_uuid}) =>
              product_uuid !== documentItemFormDTO?.product_uuid,
          );
        }),
      );
    },
    [],
  );

  const handleUpdatePreorderItem = useCallback(
    (
      documentItemFormDTO: StoreDocumentItemFormDTO,
      withProductAmount: boolean,
      storeUuid?: string,
    ) => {
      const updatedDocumentItem: StoreDocumentItemFormDTO = (({
        product,
        ...rest
      }) => ({
        ...rest,
        product: {
          product_title: product?.product_title,
          product_prices: product?.product_prices,
          product_status: product?.product_status,
        } as any,
      }))(documentItemFormDTO);

      setDocumentForm((prevState) =>
        produce<StoreDocumentFormDTO>(prevState, (draft) => {
          if (storeUuid) {
            draft.doc_store_uuid = storeUuid;
          }

          const index = (draft.doc_items || [])?.findIndex(
            ({product_uuid}) =>
              product_uuid === updatedDocumentItem?.product_uuid,
          );

          if (~index) {
            const amount = draft.doc_items[index]?.product_amount;

            draft.doc_items[index].product_amount = withProductAmount
              ? Number(documentItemFormDTO?.product_amount || 0)
              : amount + 1;
          } else {
            if (!Array.isArray(draft.doc_items)) {
              draft.doc_items = [];
            }

            draft.doc_items.push(updatedDocumentItem as any);
          }
        }),
      );
    },
    [],
  );

  const handleAddProductPreorder = useCallback(
    (product: ProductModel, storeUuid: string) => {
      const documentItemFormDTO =
        StoreDocumentMapper.toStoreDocumentItemFormDTO(product, {
          editMode: false,
          base_price: getProductPriceMargin(product),
          price: getProductPriceMargin(product),
        });
      isStartSaveToLocalStorage.current = true;
      handleUpdate({isInitPreorder: true});

      handleUpdatePreorderItem(documentItemFormDTO, false, storeUuid);
    },
    [getProductPriceMargin, handleUpdate, handleUpdatePreorderItem],
  );

  const countOfProducts = useMemo(
    () =>
      (documentForm?.doc_items || []).reduce((acc, curr) => {
        acc += curr?.product_amount || 0;

        return acc;
      }, 0),
    [documentForm?.doc_items],
  );

  const handleClearLeftoverPreorder = useCallback(() => {
    setDocumentForm(
      StoreDocumentMapper.toStoreDocumentFormDTO({} as StoreDocumentModel, {
        editMode: true,
      }),
    );
    handleUpdate({isInitPreorder: false});
    isStartSaveToLocalStorage.current = false;

    localStorage.removeItem(LocalStorageItems.leftoverPreorder);
  }, [handleUpdate]);

  const handleCreateStoreDocument = useCallback(
    async (value: StoreDocumentFormDTO) => {
      const updatedValue = produce(value, (draft) => {
        draft.doc_items = draft?.doc_items?.map(
          ({
            product_uuid,
            product_amount,
            price,
            product_price,
            cell_identifier,
          }) => ({
            product_uuid,
            product_amount,
            price,
            product_price,
            cell_identifier,
          }),
        );

        draft.doc_status = StoreDocumentStatus.ACTIVE;
      });

      const documentModel = await onCreateStoreDocument(updatedValue);
      handleClearLeftoverPreorder();

      if (documentModel?.document) {
        navigate(
          `/${Routes.app}/${Routes.stores}/${Routes.storeOrderProvider}/${documentModel?.document?.uuid}`,
        );
      }
    },
    [handleClearLeftoverPreorder, navigate, onCreateStoreDocument],
  );

  const {visible, handleCancel, handleOnInit, handleSuccess} = useModal({
    onSuccess: handleCreateStoreDocument,
  });

  const handleOpenLeftoverForm = useCallback(() => {
    handleOnInit();
  }, [handleOnInit]);

  useEffect(() => {
    if (isStartSaveToLocalStorage?.current && documentForm) {
      const updatedDocumentForm = produce(documentForm, (draft) => {
        if (documentForm?.doc_client instanceof ClientModel) {
          draft.doc_client = {
            uuid: documentForm?.doc_client?.uuid,
            first_name: documentForm?.doc_client?.first_name,
            last_name: documentForm?.doc_client?.last_name,
          };
        }
      });

      localStorage.setItem(
        LocalStorageItems.leftoverPreorder,
        JSON.stringify(updatedDocumentForm),
      );
    }
  }, [documentForm]);

  useEffect(() => {
    const serializationLeftoverPreorder = localStorage.getItem(
      LocalStorageItems.leftoverPreorder,
    );

    if (serializationLeftoverPreorder && !isStartSaveToLocalStorage.current) {
      const leftoverPreorder: StoreDocumentFormDTO = JSON.parse(
        serializationLeftoverPreorder,
      );
      const updatedLeftoverPreorder = produce(
        leftoverPreorder,
        (draft: StoreDocumentFormDTO) => {
          if (leftoverPreorder.doc_client?.uuid) {
            draft.doc_client = ClientMapper.toClientModel(
              leftoverPreorder.doc_client as ClientDTO,
            );
          }
        },
      );

      setDocumentForm(updatedLeftoverPreorder);
      handleUpdate({isInitPreorder: true});
      isStartSaveToLocalStorage.current = true;
    }
  }, [handleUpdate]);

  const value = React.useMemo(
    () => ({
      document: documentForm,
      handleAddProductPreorder,
      handleUpdatePreorderItem,
      handleDeletePreorderItem,
      handleUpdatePreorderProvider,
      handleOpenLeftoverForm,
      handleClearLeftoverPreorder,
      countOfProducts,
      isInitPreorder,
    }),
    [
      documentForm,
      handleAddProductPreorder,
      countOfProducts,
      isInitPreorder,
      handleUpdatePreorderItem,
      handleDeletePreorderItem,
      handleUpdatePreorderProvider,
      handleOpenLeftoverForm,
      handleClearLeftoverPreorder,
    ],
  );

  return (
    <LeftoverPreorderManagerContext.Provider value={value}>
      {children}
      <Suspense fallback={<SuspenseEmpty />}>
        <LeftoverPreorderSideWindowLazy
          document={documentForm}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
        />
      </Suspense>
    </LeftoverPreorderManagerContext.Provider>
  );
}
