import {
  ClientAbonementModel,
  AbonementFormDTO,
  EmployeeModel,
} from '../internal';
import {toMoment} from '../../services/helpers';
import {Moment} from 'moment';

export class ClientAbonementScheduleFormDTO {
  public uuid: string;
  public schedule_start_date: string;
  public schedule_end_date: string;
  public schedule: string;
  public comment: string;
  public _date: Moment[];
  public manager_uuid: EmployeeModel | string;

  constructor(props?: ClientAbonementModel, editMode?: boolean) {
    this.uuid = props?.uuid || '';
    this.schedule_start_date = editMode
      ? (toMoment(props?.abon_schedule_end_date || new Date())
          .add(1, 'day')
          .toISOString() as string)
      : props?.abon_schedule_start_date
      ? props?.abon_schedule_start_date
      : (toMoment(new Date()).toISOString() as string);
    this.schedule_end_date = editMode
      ? (toMoment(props?.abon_schedule_end_date || new Date())
          .add(2, 'day')
          .toISOString() as string)
      : props?.abon_schedule_end_date
      ? props?.abon_schedule_end_date
      : (toMoment(new Date()).add(1, 'day').toISOString() as string);
    this.comment = props?.abon_schedule_comment || '';
    this.schedule = AbonementFormDTO.getTimingList(props?.abon_schedule_list);
    this.manager_uuid = editMode
      ? props?.managerModel?.uuid
        ? props?.managerModel
        : ''
      : props?.manager?.uuid || '';
    this._date = [
      toMoment(this.schedule_start_date),
      toMoment(this.schedule_end_date),
    ];
  }
}
