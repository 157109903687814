import {Map} from 'immutable';
import {APP_STATE, ABONEMENT} from '../constants';
import {REDUX_STATUS, ApiError} from '../../services/types';
import {AbonementModel} from '../../struture';

interface IAbonementListState {
  abonement: AbonementModel | null;
  cachedAbonementList: Map<string, AbonementModel>;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
}

interface SetAction extends Pick<IAbonementListState, 'abonement'> {
  type: ABONEMENT.SET_ABONEMENT;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface UpdateAbonement {
  type: ABONEMENT.UPDATE_ABONEMENT;
  abonement: AbonementModel;
}

interface LoadingAction {
  type: ABONEMENT.LOADING_ABONEMENT;
}

interface ResetAction {
  type: ABONEMENT.RESET_ABONEMENT;
}

interface ErrorAction extends Pick<IAbonementListState, 'error'> {
  type: ABONEMENT.ERROR_ABONEMENT;
}

interface Handlers {
  [ABONEMENT.SET_ABONEMENT]: (
    state: IAbonementListState,
    action: SetAction,
  ) => IAbonementListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IAbonementListState,
    action: SetInitialStateAction,
  ) => IAbonementListState;

  [ABONEMENT.RESET_ABONEMENT]: (
    state: IAbonementListState,
    action: ResetAction,
  ) => IAbonementListState;

  [ABONEMENT.UPDATE_ABONEMENT]: (
    state: IAbonementListState,
    action: UpdateAbonement,
  ) => IAbonementListState;

  [ABONEMENT.ERROR_ABONEMENT]: (
    state: IAbonementListState,
    value: ErrorAction,
  ) => IAbonementListState;

  [ABONEMENT.LOADING_ABONEMENT]: (
    state: IAbonementListState,
    value?: LoadingAction,
  ) => IAbonementListState;
  DEFAULT: (state: IAbonementListState) => IAbonementListState;
}

const initState: IAbonementListState = {
  abonement: null,
  cachedAbonementList: Map(),
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
};

const handlers: Handlers = {
  [ABONEMENT.SET_ABONEMENT]: (state, {abonement}) => ({
    ...state,
    ...{
      abonement,
      cachedAbonementList: abonement
        ? state.cachedAbonementList.set(abonement?.uuid, abonement)
        : state.cachedAbonementList,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [ABONEMENT.RESET_ABONEMENT]: (state) => ({
    ...state,
    ...{
      status: REDUX_STATUS.IDLE,
    },
  }),

  [ABONEMENT.UPDATE_ABONEMENT]: (state, {abonement}) => ({
    ...state,
    ...{
      abonement,
      cachedAbonementList: state.cachedAbonementList.update(
        abonement?.uuid,
        () => abonement,
      ),
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [ABONEMENT.ERROR_ABONEMENT]: (
    state: IAbonementListState,
    {error}: ErrorAction,
  ) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [ABONEMENT.LOADING_ABONEMENT]: (state: IAbonementListState) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IAbonementListState) => state,
};

export default function Abonement(
  state: any = initState,
  action: any,
): IAbonementListState {
  const handler = handlers[action.type as ABONEMENT] || handlers.DEFAULT;
  return handler(state, action);
}
