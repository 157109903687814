import {List, Record} from 'immutable';
import {
  EmployeeDTO,
  EmployeeServiceDTO,
  GroupDTO,
  ServiceDTO,
  EmployeeModel,
  GroupModel,
  EmployeeMapper,
  EmployeeServiceMapper,
  GroupMapper,
  EmployeeServiceModel,
  GroupPaymentModel,
  GroupPaymentMapper,
  ServiceStatuses,
  ServiceMultiSessionable,
} from '../internal';
import {PERSONALITY_TYPE, TIME_LIST_SERVICES} from '../../services/types';
import {WithoutRecursion} from '../type';
import {COLOR_GREEN, correctPrice} from '../../services/helpers';

export const SERVICES_STATUS_ACTIVE = 1;

export interface IServiceModelReturnType {
  uuid: string;
  title: string;
  description: string;
  comment: string;
  color_schema: string;
  price: string;
  net_price: string;
  created_at: string;
  updated_at: string;
  personality: PERSONALITY_TYPE;
  period: TIME_LIST_SERVICES;
  period_amount: number;
  manager: EmployeeModel | EmployeeDTO;
  group: GroupModel | GroupDTO;
  payment_group: GroupModel | GroupDTO;
  group_session: List<GroupModel> | GroupDTO[];

  status: ServiceStatuses;

  used_by_employees?: List<EmployeeServiceModel> | EmployeeServiceDTO[];
  employee_service?: EmployeeServiceModel;
  is_multi_sessionable: boolean;
  multi_sessions: ServiceMultiSessionable[];
}

export class ServiceModel extends Record<IServiceModelReturnType>({
  uuid: '',
  title: '',
  description: '',
  comment: '',
  color_schema: COLOR_GREEN,
  created_at: '',
  updated_at: '',
  personality: PERSONALITY_TYPE.INDIVIDUAL,
  period: TIME_LIST_SERVICES.HOUR,
  period_amount: 1,
  price: correctPrice(0),
  net_price: correctPrice(0),
  manager: {} as EmployeeModel,
  group: {} as GroupModel,
  payment_group: {} as GroupModel,
  used_by_employees: List(),
  group_session: List(),
  employee_service: undefined,
  status: ServiceStatuses.Active,
  is_multi_sessionable: false,
  multi_sessions: [],
}) {
  constructor(
    props: ServiceDTO = {} as ServiceDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IServiceModelReturnType = {
      ...props,

      employee_service: props?.employee_service
        ? EmployeeServiceMapper.toEmployeeServiceModel(props?.employee_service)
        : undefined,

      manager:
        withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
          ? props?.manager
          : EmployeeMapper.toEmployeeModel(props?.manager, [
              WithoutRecursion.service,
              ...withoutRecursion,
            ]),
      group:
        withoutRecursion.indexOf(WithoutRecursion.group) !== -1
          ? props?.group
          : GroupMapper.toGroupModel(props?.group, [
              WithoutRecursion.service,
              ...withoutRecursion,
            ]),

      payment_group:
        withoutRecursion.indexOf(WithoutRecursion.group) !== -1
          ? props?.payment_group
          : GroupMapper.toGroupModel(props?.payment_group, [
              WithoutRecursion.service,
              ...withoutRecursion,
            ]),

      used_by_employees: props?.used_by_employees
        ? withoutRecursion.indexOf(WithoutRecursion.employeeService) !== -1
          ? props?.used_by_employees
          : EmployeeServiceMapper.toEmployeeServiceListModel(
              props?.used_by_employees || [],
              0,
              [WithoutRecursion.service, ...withoutRecursion],
            ).employeeServices
        : undefined,

      group_session:
        withoutRecursion.indexOf(WithoutRecursion.groupSession) !== -1
          ? props?.group_session
          : GroupMapper.toGroupListModel(props?.group_session || [], [
              WithoutRecursion.service,
              ...withoutRecursion,
            ]).groups,
    };

    super(options);
  }

  get managerUuid(): string {
    return this.manager?.uuid;
  }

  get paymentGroupUuid(): string {
    return this.payment_group?.uuid;
  }

  get managerModel(): EmployeeModel {
    return this.manager instanceof EmployeeModel
      ? this.manager
      : EmployeeMapper.toEmployeeModel(this.manager);
  }

  get groupModel(): GroupModel {
    return this.group instanceof GroupModel
      ? this.group
      : GroupMapper.toGroupModel(this.group);
  }

  get paymentGroupModel(): GroupPaymentModel {
    return this.payment_group instanceof GroupPaymentModel
      ? this.payment_group
      : GroupPaymentMapper.toGroupPaymentModel(this.payment_group as any);
  }

  get usedByEmployeeListModel(): List<EmployeeServiceModel> {
    return List.isList(this.used_by_employees)
      ? this.used_by_employees
      : EmployeeServiceMapper.toEmployeeServiceListModel(
          this.used_by_employees || [],
          0,
        ).employeeServices;
  }

  get groupSessionListModel(): List<GroupModel> {
    return List.isList(this.group_session)
      ? this.group_session
      : GroupMapper.toGroupListModel(this.group_session).groups;
  }
}
