import {Record, List} from 'immutable';
import {ListModel, ListProps} from './List';
import Profile, {ProfileProps} from './Profile';

export interface IProfileListProps extends ListProps {
  user_profiles: ProfileProps[];
}

export default class ProfileList extends Record({
  user_profiles: List(),
  ...ListModel.toJS(),
}) {
  /**
     * @constructor
     // * @param {{userProfiles:[]}} param0
     */
  constructor({user_profiles = [], ...props}: IProfileListProps) {
    super({
      user_profiles: List(user_profiles.map((profile) => new Profile(profile))),
      ...props,
    });
  }
}
