import {Record, Map} from 'immutable';

export interface ICompanyCalendarTimeSlotsMapProps {
  timeslots:
    | {
        '10:00': any[];
        '11:00': any[];
        '12:00': any[];
        '13:00': any[];
        '14:00': any[];
        '15:00': any[];
        '16:00': any[];
        '17:00': any[];
        '18:00': any[];
        '19:00': any[];
        '20:00': any[];
      }
    | object;
}

/**
 * @class CompanyCalendarTimeSlotsMap
 * @classdesc CompanyCalendarTimeSlotsMap map model
 * @property {Map} timeslots - map of timeslots
 */
export default class CompanyCalendarTimeSlotsMap extends Record({
  timeslots: Map({
    '10:00': [],
    '11:00': [],
    '12:00': [],
    '13:00': [],
    '14:00': [],
    '15:00': [],
    '16:00': [],
    '17:00': [],
    '18:00': [],
    '19:00': [],
    '20:00': [],
  }),
}) {
  constructor({timeslots = {}}: ICompanyCalendarTimeSlotsMapProps) {
    super({
      timeslots: Map({
        ...timeslots,
      }),
    });
  }
}
