import React from 'react';
import {IDefaultTabPanelProps} from '../../../components/lib/General';
import {useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import {StatusModel} from '../../../struture';

export enum STATUS_TABS {}

export interface IStatusTabPanelProps {
  children: (value: Pick<IDefaultTabPanelProps, 'tabs'>) => React.ReactNode;
  status: StatusModel | null;
}

export function StatusTabPanel({children, status}: IStatusTabPanelProps) {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const statusTabs: any = [];

  return <>{children({tabs: statusTabs})}</>;
}
