import {Record} from 'immutable';
import {InvitationDTO} from '../internal';

export interface IInvitationReturnType {
  uuid: string;
  status: number;
  created_at: string;
  updated_at: string;
}

export class InvitationModel extends Record<IInvitationReturnType>({
  uuid: '',
  status: 0,
  created_at: '',
  updated_at: '',
}) {
  constructor(props: InvitationDTO = {} as InvitationDTO) {
    super(props);
  }
}
