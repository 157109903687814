import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {InvoiceFormDTO, InvoiceType} from '../../../struture';
import {useModal} from '../../../components/lib/libV2/hooks';
import {
  EditButton,
  IEditButtonProps,
  SuspenseEmpty,
} from '../../../components/lib/DataDisplay';
import {IUseStateInvoiceReturnType} from '../../../hooks';

const InvoiceSideWindowLazy = React.lazy(
  () => import('../Show/InvoiceSideWindow'),
);

export interface InvoiceUpdateButtonProps extends IEditButtonProps {
  children?: React.ReactNode;
  onSuccess: IUseStateInvoiceReturnType['handleUpdateInvoice'];
  onCancel?: () => void;
  invoice: InvoiceFormDTO;
}

export function InvoiceUpdateButton({
  children,
  onSuccess,
  onCancel,
  loading,
  invoice,
  ...rest
}: InvoiceUpdateButtonProps): React.JSX.Element {
  const {t} = useTranslation();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <EditButton
        tooltipTitle={
          invoice?.inv_type === InvoiceType.IN
            ? t('Update invoice')
            : t('Update input invoice')
        }
        onClick={handleOnInit}
        disabled={false}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <InvoiceSideWindowLazy
          editMode
          invoice={invoice}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}
