import React, {useCallback, useState} from 'react';
import {useTranslation, WithTranslation, withTranslation} from 'react-i18next';
import {Empty, message} from 'antd';
import {ServersIcon} from '../../../components/lib/General/icons';
import {ListLayout, PageWrapper} from '../../../components/lib/Layout';
import {CreateUserButton, DeleteUserButton} from '../Buttons';
import {useStateUserList, useStoredCompanies} from '../../../hooks';
import {UserList} from '../List';
import {
  getRootKey,
  head,
  len,
  lessOrEqualThan,
  eq,
  textToUpperCase,
} from '../../../services/helpers';
import {User} from '../../../services/models';
import UserSearchInput from '../Show/UserSearchInput';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '../../../services/types';
import {HeaderLeft} from '../../../components/lib/DataDisplay';
import {EmployeeCreateButton, EmployeesDeleteButton} from '../../Employees';
import ListSearchInput from '../../../components/lib/DataDisplay/ListActions/ListSearchInput';
import {Table} from '../../../components/lib/libV2/DataDisplay';
import {EmployeeModel} from '../../../struture';
import {useNavigate} from 'react-router';
import {useAcl} from '../../../contex';

/**
 * @desc User page component
 * */
export default function UserListPage(): JSX.Element {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {manage} = useAcl(({employee}) => employee);

  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const {defaultCompanyBreadcrumb, defaultCompanyUuid} = useStoredCompanies();
  const key = getRootKey(location.pathname);

  const {
    users,
    loading: usersListLoading,
    handleDeleteUsers,
    handleCreateUser,
    handleUpdateUser,
    handleSearchUsers,
    limit,
    total,
    refresh,
  } = useStateUserList();

  const rowSelection = {
    hideSelectAll: true,
    selectedRowKeys: selectedUsers.map(({uuid}) => uuid),
    onChange: (selectedRowKeys: React.Key[], selectedRecords: User[]) => {
      if (lessOrEqualThan(len(selectedRecords), 1)) {
        setSelectedUsers(selectedRecords);
      } else {
        message.error(t('You can delete up to 1 at a time'));
      }
    },
  };

  const routes = [
    {
      path: `/${key}/dashboard`,
      breadcrumbName: t('Home'),
    },
    defaultCompanyBreadcrumb,
    {
      path: `${key}/users}`,
      breadcrumbName: t('Users'),
    },
  ];

  const navigateGoBack = useCallback(() => {}, []);

  return (
    <>
      <PageWrapper
        showBreadcrumbs
        backIcon={<ServersIcon />}
        className="user-list"
        data-testid="user-list"
        title={t('Users')}
        routes={routes}
        extra={[
          <DeleteUserButton
            key="DeleteUserButton"
            disabled={eq(len(selectedUsers), 0)}
            users={selectedUsers}
            loading={usersListLoading}
            onSuccess={handleDeleteUsers}>
            {t('Delete user')}
          </DeleteUserButton>,
          // head(buttons),
        ]}
        horizontalScroll>
        <UserList
          defaultCompanyUuid={defaultCompanyUuid}
          underTableContent={[
            <UserSearchInput
              handleSearchUsers={handleSearchUsers}
              key="user__input--search"
            />,
          ]}
          renderEmpty={
            <Empty description={t('There are no user created.')}>
              {/*{head(buttons)}*/}
              <></>
            </Empty>
          }
          users={users}
          rowSelection={rowSelection}
          loading={usersListLoading}
          pageSize={limit}
          total={total}
          onRefresh={refresh}
          handleUpdate={handleUpdateUser}
          handleDelete={handleDeleteUsers}
        />
      </PageWrapper>

      {/*<ListLayout*/}
      {/*  aclItem={RoutesAcl[Routes.employees]}*/}
      {/*  headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}*/}
      {/*  headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.users]))}*/}
      {/*  headerRight={*/}
      {/*    <>*/}
      {/*      {selectedUsers.length ? (*/}
      {/*        <DeleteUserButton*/}
      {/*          disabled={!manage}*/}
      {/*          users={users}*/}
      {/*          onSuccess={handleDeleteUsers}*/}
      {/*        />*/}
      {/*      ) : (*/}
      {/*        <CreateUserButton*/}
      {/*          loading={usersListLoading}*/}
      {/*          onSuccess={handleCreateUser}*/}
      {/*        />*/}
      {/*      )}*/}
      {/*    </>*/}
      {/*  }*/}
      {/*  routes={routes}*/}
      {/*  empty={*/}
      {/*    users?.size ? null : (*/}
      {/*      <Empty description={t(`There are no user created.`)}>*/}
      {/*        <CreateUserButton*/}
      {/*          type="primary"*/}
      {/*          loading={usersListLoading}*/}
      {/*          onSuccess={handleCreateUser}*/}
      {/*          title={t('Create User')}*/}
      {/*        />*/}
      {/*      </Empty>*/}
      {/*    )*/}
      {/*  }*/}
      {/*  headerExtra={*/}
      {/*    <ListSearchInput*/}
      {/*      handleSearch={handleSearchUsers}*/}
      {/*      tooltipTitle={t('Search employees')}*/}
      {/*      label={t('Search employees')}*/}
      {/*    />*/}
      {/*  }*/}
      {/*  loading={loading && !employees?.size}>*/}
      {/*  <Table<EmployeeModel>*/}
      {/*    rowSelection={rowSelection}*/}
      {/*    total={total}*/}
      {/*    pageSize={pageSize}*/}
      {/*    dataSource={employees}*/}
      {/*    onChange={refresh}*/}
      {/*    columns={columns}*/}
      {/*    loading={loading}*/}
      {/*  />*/}
      {/*</ListLayout>*/}
    </>
  );
}
