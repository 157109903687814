import React from 'react';
import {StoreModel} from '../../../struture';
import styled, {useTheme, css} from 'styled-components';
import {StyledDescription} from '../../../components/lib/Styled';
import {useLocation} from 'react-router';
import {useTranslation} from 'react-i18next';
import {Link} from '../../../components/lib/DataDisplay';
import {Routes} from '../../../services/types';
import {StoreTitle} from './StoreTitle';

export interface IStoreDetailsViewProps {
  store: StoreModel | null;
  withoutGap?: boolean;
}
const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`;

const StyledSpace = styled.div<{$withoutGap: boolean}>`
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 5px;
  ${({$withoutGap}) =>
    $withoutGap &&
    css`
      gap: 0;
    `}
  a {
    font-weight: 500;
  }
`;
export function StoreDetailsView({
  store,
  withoutGap,
}: IStoreDetailsViewProps): JSX.Element {
  const {t} = useTranslation();
  const location = useLocation();

  return (
    <>
      {store ? (
        <StyledContainer>
          <StyledSpace $withoutGap={!!withoutGap}>
            <Link
              to={`/${Routes.app}/${Routes.stores}/${store?.uuid}`}
              tooltip={t('Open a detailed description of the store')}
              state={{goBack: location.pathname}}>
              <StoreTitle store={store} />
            </Link>
            {store?.store_type ? (
              <StyledDescription>{`${t(store?.store_type)}${
                store?.companyDepartmentModel?.uuid
                  ? ` / ${store?.companyDepartmentModel?.dep_name}`
                  : ''
              }`}</StyledDescription>
            ) : null}
          </StyledSpace>
        </StyledContainer>
      ) : null}
    </>
  );
}
