import React, {useCallback} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {Col} from 'antd';
import {CompanyForm} from './index';
import {ICreateCompanyParam} from '../../../services/api/company';
import {isFunction} from '../../../services/helpers';
import {
  useRequiredFields,
  useStoredCompanies,
  useStoredProfile,
} from '../../../hooks';
import {LoginFormDTO, ProfileMapper} from '../../../struture';

import './CreateCompanyForm.less';
import {AuthButton} from '../../Authorization/Buttons';
import {IDefaultFormProps} from '../../../components/lib/General';
import {useNavigate} from 'react-router';
import {Routes} from '../../../services/types';

export default withTranslation()(CreateCompanyForm);

export interface ICreateCompanyFormProps
  extends WithTranslation,
    Omit<
      IDefaultFormProps<LoginFormDTO, LoginFormDTO>,
      'children' | 'initialValues'
    > {
  handleSuccess?: (value: ICreateCompanyParam) => Promise<void>;
}

const SEARCH_PROPS = {phone: '', email: ''};

function CreateCompanyForm({
  t,

  handleSuccess,
  ...rest
}: ICreateCompanyFormProps): JSX.Element {
  const navigate = useNavigate();

  const {createCompany} = useStoredCompanies();

  const {profile, loading: loadingProfile} = useStoredProfile();

  const handleCreateCompany = useCallback(
    async (value: ICreateCompanyParam): Promise<void> => {
      if (isFunction(handleSuccess)) {
        await handleSuccess(value);
      } else {
        await createCompany(value);

        navigate(`/${Routes.app}/services`);
      }
    },
    [createCompany, handleSuccess, navigate],
  );

  const additionalValues = useRequiredFields(
    ProfileMapper.toProfileDTO(profile),
    SEARCH_PROPS,
  );

  return (
    <CompanyForm
      {...rest}
      showPhoneField={false}
      additionalValuesRequest={additionalValues}
      loading={loadingProfile}
      className="company-form--create"
      onSuccess={handleCreateCompany}
      showOnlyCompanyTitle
      showFooter={false}>
      {({loadingSubmit, submit}) => (
        <Col span={24}>
          <AuthButton
            size={'large'}
            disabled={false}
            data-testid="signup-form__button--submit"
            loading={loadingSubmit}
            type="primary"
            onClick={submit}
            block>
            {t('Create a Company')}
          </AuthButton>
        </Col>
      )}
    </CompanyForm>
  );
}
