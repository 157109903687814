import React, {useRef} from 'react';
import styled, {css} from 'styled-components';
import {RightOutlined} from '@ant-design/icons';
import {isFunction} from '../../../services/helpers';
import {useSize} from '../../../hooks';

export interface IListLayoutItemChildProps {
  isPhone: boolean;
}

export interface IListLayoutItemProps<T> {
  children:
    | React.ReactNode
    | ((value: IListLayoutItemChildProps) => React.ReactNode);
  item: T;
  handleNavigate?: (item: T) => void;
  icon?: (item: T) => React.ReactNode;
  disabledItem?: (item: T) => boolean;
  disabledItemNavigate?: (item: T) => boolean;
  withoutLine?: boolean;
}

export const SMALL_CONTAINER = 340;

const StyledContainer = styled.div<{
  withoutLine?: boolean;
}>`
  display: flex;
  border-bottom-color: ${({theme}) => theme.colors.disabled};
  border-bottom-width: 1px;
  border-bottom-style: solid;
  justify-content: center;
  min-height: 85px;

  ${({withoutLine}) =>
    withoutLine &&
    css`
      border-bottom-width: 0;
    `}
`;

const StyledIconContainer = styled.div<{$withSmallWidth: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 100%;

  ${({$withSmallWidth}) =>
    $withSmallWidth &&
    css`
      width: 10px;
    `}
`;

const StyledAntDesign = styled(RightOutlined)<{
  disabled: boolean;
}>`
  color: ${({theme}) => theme.colors.primary};
  font-size: 15px;

  ${({disabled}) =>
    disabled &&
    css`
      opacity: 0.5;
    `}
`;

const StyledButton = styled.div<{
  $pointer: boolean;
  $withSmallPadding: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  border-radius: 0;
  padding-right: 25px;
  line-height: 1.5714285714285714;

  ${({$pointer}) =>
    $pointer &&
    css`
      cursor: pointer;
    `}

  ${({$withSmallPadding}) =>
    $withSmallPadding &&
    css`
      padding-right: 5px;
    `}
`;

const StyledContent = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
`;

export function ListLayoutItem<T>({
  children,
  item,
  handleNavigate,
  icon,
  withoutLine = false,
  disabledItem = () => false,
  disabledItemNavigate = () => false,
}: IListLayoutItemProps<T>): JSX.Element {
  const contentRef = useRef(null);

  const isDisabledItemNavigate = disabledItemNavigate(item);

  const {width: containerWidth} = useSize(contentRef);

  return (
    <StyledContainer withoutLine={withoutLine} ref={contentRef}>
      <StyledButton
        $withSmallPadding={containerWidth < SMALL_CONTAINER}
        $pointer={isFunction(handleNavigate)}
        onClick={() => {
          if (isFunction(handleNavigate)) {
            handleNavigate(item);
          }
        }}>
        <StyledContent>
          {isFunction(children)
            ? children({isPhone: containerWidth < SMALL_CONTAINER})
            : children}
        </StyledContent>
        {isFunction(handleNavigate) && !isDisabledItemNavigate ? (
          <StyledIconContainer
            $withSmallWidth={containerWidth < SMALL_CONTAINER}>
            {isFunction(icon) ? (
              icon(item)
            ) : (
              <>
                <StyledAntDesign
                  disabled={!isFunction(handleNavigate) || disabledItem(item)}
                />
              </>
            )}
          </StyledIconContainer>
        ) : null}
      </StyledButton>
    </StyledContainer>
  );
}
