import {Record} from 'immutable';

export interface IUnreadProps {
  unread: number;
}

/**
 * @name ListModel
 * @desc Represents an abstract list response from the backend.
 * @constant
 */
export const UnreadModel = new (Record({
  unread: 0,
}))();
