import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {IActionButtonProps} from '../../../components/lib/General';
import {useModal} from '../../../components/lib/libV2/hooks';
import {RewardFormDTO} from '../../../struture';
import {AddButton, SuspenseEmpty} from '../../../components/lib/DataDisplay';
import styled, {css} from 'styled-components';

const RewardSideWindowLazy = React.lazy(
  () => import('../Show/RewardSideWindow'),
);

export interface IRewardAwardRecordButtonProps
  extends Omit<IActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: RewardFormDTO) => Promise<void>;
  onCancel?: () => void;
  reward: RewardFormDTO;
}

const StyledAddButton = styled(AddButton)<{disabled?: boolean}>`
  background-color: ${({theme}) => theme.colors.success};

  ${({disabled}) =>
    !disabled &&
    css`
      &&&:hover,
      &&&:active,
      &&&:focus {
        background-color: ${({theme}) => theme.colors.success};
        opacity: 0.7;
      }
    `}
`;

export function RewardAwardRecordButton({
  children,
  onSuccess,
  onCancel,
  loading,
  reward,
  ...rest
}: IRewardAwardRecordButtonProps): React.JSX.Element {
  const {t} = useTranslation();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <StyledAddButton
        tooltipTitle={t('Add premium')}
        onClick={handleOnInit}
        loading={loading}
        type="primary"
        {...rest}>
        {`+${t('tPremium')}`}
      </StyledAddButton>

      <Suspense fallback={<SuspenseEmpty />}>
        <RewardSideWindowLazy
          reward={reward}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
          title={t('Add premium')}
        />
      </Suspense>
    </>
  );
}
