import * as React from 'react';
import {List} from 'immutable';
import {Moment} from 'moment';
import {ApiError, IEntityProps, IListSearchProps} from '../services/types';
import {
  ClientDTO,
  ClientMapper,
  ClientModel,
  IPaymentListDTO,
  PaymentMapper,
  PaymentModel,
} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {getClientPaymentList} from '../services/api/client';
import {SessionStorageItems} from '../services/const';
import useStoredCompanies from './useStoredCompanies';
import {toMoment} from '../services/helpers';
import {
  IPaymentScheduleListStats,
  PaymentScheduleOperationType,
} from '../services/api/orders';

export interface IUseClientPaymentListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  clientUuid: string;
  date_start?: Date | Moment | string;
  date_end?: Date | Moment | string;
  isSearch?: boolean;
  payment_type?: PaymentScheduleOperationType;
  isClearDate?: boolean;
}

export interface IUseClientPaymentListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<PaymentModel> | null;
  refresh: (
    value: Partial<IUseClientPaymentListProps>,
  ) => Promise<List<PaymentModel> | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  clientUuid: string;
  date_start: Date | Moment | string;
  date_end?: Date | Moment | string;
  payment_type?: PaymentScheduleOperationType;
  stats: IPaymentScheduleListStats;
  client: ClientModel | null;
}

export function useClientPaymentList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    clientUuid,
    date_start,
    date_end,
    payment_type = PaymentScheduleOperationType.ALL,
  }: IUseClientPaymentListProps = {} as IUseClientPaymentListProps,
): IUseClientPaymentListReturnType {
  const {cancellablePromise, didCancel} = useCancellablePromise();
  const {settings} = useStoredCompanies();

  const paymentRangeDates = JSON.parse(
    sessionStorage.getItem(
      `${SessionStorageItems.clientPaymentRangeDates}${clientUuid}`,
    ) || JSON.stringify({}),
  );

  date_start = paymentRangeDates?.start
    ? toMoment(paymentRangeDates?.start)
    : date_start || '';
  date_end = paymentRangeDates?.end
    ? toMoment(paymentRangeDates?.end)
    : date_end || '';

  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    clientUuid: listClientUuid,
    date_start: listStartDate,
    date_end: listEndDate,
    stats: listStats,
    payment_type: listType,
    client: listClient,
    handleUpdate,
  } = useStateReducer<Omit<IUseClientPaymentListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    clientUuid,
    date_start,
    date_end,
    stats: {} as IPaymentScheduleListStats,
    payment_type,
    client: null,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      clientUuid = listClientUuid,
      date_start = listStartDate,
      date_end = listEndDate,
      isSearch = false,
      payment_type = listType,
      isClearDate,
    }: Partial<IUseClientPaymentListProps> = {}): Promise<List<PaymentModel> | void> => {
      try {
        let isCleared = paymentRangeDates?.isCleared;

        if (date_start && date_end && !isSearch) {
          sessionStorage.setItem(
            `${SessionStorageItems.clientPaymentRangeDates}${clientUuid}`,
            JSON.stringify({
              start: date_start,
              end: date_end,
              isCleared: false,
            }),
          );
          isCleared = false;
        }

        if (isClearDate || isCleared) {
          sessionStorage.setItem(
            `${SessionStorageItems.clientPaymentRangeDates}${clientUuid}`,
            JSON.stringify({start: '', end: '', isCleared: true}),
          );
          date_start = '';
          date_end = '';
        }

        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          clientUuid,
          date_start,
          date_end,
          payment_type,
        });

        const {payments: clientPayments, client} = await cancellablePromise<{
          payments: IPaymentListDTO;
          client: ClientDTO;
        }>(
          getClientPaymentList({
            clientUuid,
            limit,
            offset,
            keywords,
            date_start,
            date_end,
            payment_type,
          }),
        );

        const {payments, total, stats} = clientPayments;
        const clientModel = ClientMapper.toClientModel(client);

        const paymentListModel = PaymentMapper.toPaymentListModel(
          payments,
          total,
        );

        if (!didCancel.current) {
          handleUpdate({
            entityList: paymentListModel?.payments,
            total: paymentListModel?.total,
            loading: false,
            stats,
            client: clientModel,
          });

          return paymentListModel?.payments;
        }
      } catch (e) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }
      }
    },
    [
      cancellablePromise,
      didCancel,
      error,
      handleUpdate,
      listClientUuid,
      listEndDate,
      listKeywords,
      listLimit,
      listOffset,
      listStartDate,
      listType,
      paymentRangeDates?.isCleared,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && clientUuid && settings) {
      (async () => {
        await refresh({clientUuid});
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, clientUuid, settings]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    clientUuid: listClientUuid,
    date_start: listStartDate,
    date_end: listEndDate,
    stats: listStats,
    payment_type: listType,
    client: listClient,
  };
}
