import React, {useMemo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {IDefaultFormProps, DefaultForm} from '../../../components/lib/General';
import {CLIENT_ABONEMENT_INITIAL_PARAM} from '../../../services/api/client';
import {ClientAbonementFormDTO} from '../../../struture';
import {useDropdownAlert} from '../../../contex';
import {ClientAbonementFields} from './ClientAbonementFields';
import {
  useStateCompanyClientList,
  useStateEmployeeList,
  useStoredAbonementGroupList,
  useStoredAbonementList,
  useStoredCompanies,
} from '../../../hooks';

export interface IClientAbonementFormProps
  extends Omit<
    IDefaultFormProps<ClientAbonementFormDTO, ClientAbonementFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  abonement?: ClientAbonementFormDTO;
  disabledField?: 'clients' | 'abonements' | 'group';
}

export function ClientAbonementForm({
  loading,
  editMode,
  abonement = CLIENT_ABONEMENT_INITIAL_PARAM,
  onCancel,
  disabledField,
  ...rest
}: IClientAbonementFormProps) {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const {defaultCompanyUuid} = useStoredCompanies();

  const {
    clients,
    loading: clientsLoading,
    handleSearchClients,
  } = useStateCompanyClientList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const {
    abonementList,
    loading: abonementsLoading,
    handleSearchAbonements,
  } = useStoredAbonementList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const {
    groupList,
    loading: groupListLoading,
    handleSearchAbonementGroups,
    handleRefreshAbonementGroups,
  } = useStoredAbonementGroupList({
    loadOnInit: false,
    abonementUuid: '',
  });

  const {
    employees,
    loading: employeesLoading,
    handleSearchEmployees,
  } = useStateEmployeeList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const additionalValues = useMemo(
    () => ({
      uuid: abonement?.uuid,
    }),
    [abonement?.uuid],
  );

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Client abonements'),
        `${
          editMode
            ? t('An error occurred during edit client abonement')
            : t('An error occurred during create client abonement')
        } : ${apiError?.message}`,
      );
    },
    [alert, t, editMode],
  );

  return (
    <DefaultForm<any, any>
      withContext
      editMode={editMode}
      initialValues={{...abonement, price: '0.00'}}
      additionalValuesRequest={additionalValues}
      submitButtonText={t('Connect')}
      showNotify={false}
      onCancel={onCancel}
      notifyError={notifyError}
      {...rest}>
      {() => (
        <ClientAbonementFields
          abonementList={abonementList}
          abonementsLoading={abonementsLoading}
          handleSearchAbonements={handleSearchAbonements}
          employees={employees}
          employeesLoading={employeesLoading}
          handleSearchEmployees={handleSearchEmployees}
          clients={clients}
          clientsLoading={clientsLoading}
          handleSearchClients={handleSearchClients}
          groupList={groupList}
          groupsLoading={groupListLoading}
          handleSearchAbonementGroups={handleSearchAbonementGroups}
          handleRefreshAbonementGroups={handleRefreshAbonementGroups}
          disabledField={disabledField}
        />
      )}
    </DefaultForm>
  );
}
