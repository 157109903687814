import {Record} from 'immutable';
import SystemSubscription, {
  ISystemSubscriptionProps,
} from './SystemSubscription';

export interface IProfileSubscriptionProps {
  status_txt: string;
  system_subscription: ISystemSubscriptionProps;
  created_at: string;
  updated_at: string;
}

export interface IProfileSubscriptionRecord
  extends Omit<IProfileSubscriptionProps, 'system_subscription'> {
  system_subscription: SystemSubscription;
}

/**
 * @class Client
 * @desc Клієнт
 */
export default class ProfileSubscription extends Record<IProfileSubscriptionRecord>(
  {
    status_txt: '',
    system_subscription: {} as any,
    created_at: '',
    updated_at: '',
  },
) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(
    {
      system_subscription,
      ...rest
    }: IProfileSubscriptionProps = {} as IProfileSubscriptionProps,
  ) {
    const options: any = {};

    if (system_subscription) {
      options.system_subscription = new SystemSubscription(system_subscription);
    }

    super({...rest, ...options});
  }
}
