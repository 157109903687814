import * as React from 'react';
import {List} from 'immutable';
import {ApiError, IEntityProps, IListSearchProps} from '../services/types';
import {
  EmployeeModel,
  EmployeeListModel,
  IEmployeeListDTO,
  EmployeeMapper,
} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {getServiceEmployeeList} from '../services/api/services';
import {useDropdownAlert} from '../contex';
import {useTranslation} from 'react-i18next';

export interface IUseServiceEmployeeListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  serviceUuid: string;
}

export interface IUseServiceEmployeeListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<EmployeeModel> | null;
  refresh: (
    value: Partial<IUseServiceEmployeeListProps>,
  ) => Promise<EmployeeListModel | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  serviceUuid: string;
}

export function useServiceEmployeeList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    serviceUuid,
  }: IUseServiceEmployeeListProps = {} as IUseServiceEmployeeListProps,
): IUseServiceEmployeeListReturnType {
  const {cancellablePromise} = useCancellablePromise();
  const {alert} = useDropdownAlert();
  const {t} = useTranslation();

  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    serviceUuid: listCompanyUuid,
    handleUpdate,
  } = useStateReducer<Omit<IUseServiceEmployeeListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    serviceUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      serviceUuid = listCompanyUuid,
    }: Partial<IUseServiceEmployeeListProps> = {}): Promise<EmployeeListModel | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          serviceUuid,
        });

        const {employees, total} = await cancellablePromise<IEmployeeListDTO>(
          getServiceEmployeeList({
            serviceUuid,
            limit,
            offset,
            keywords,
          }),
        );

        const serviceEmployeeListModel = EmployeeMapper.toEmployeeListModel(
          employees,
          total,
        );

        handleUpdate({
          entityList: serviceEmployeeListModel?.employees,
          total: serviceEmployeeListModel?.total,
          loading: false,
        });

        return serviceEmployeeListModel;
      } catch (e: any) {
        alert(
          'error',
          t('Services employees'),
          `${t('An error occurred during get service employee list')} : ${
            e?.message
          }`,
        );

        handleUpdate({
          error,
          loading: false,
        });
      }
    },
    [
      alert,
      cancellablePromise,
      error,
      handleUpdate,
      listCompanyUuid,
      listKeywords,
      listLimit,
      listOffset,
      t,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && serviceUuid) {
      (async () => {
        await refresh({serviceUuid});
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, serviceUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    serviceUuid: listCompanyUuid,
  };
}
