export interface ICalendarItemFromDTOProps {
  employee_uuid: string;
  select_all: boolean;
}

export class CalendarItemFromDTO {
  public employee_uuid: string;

  public select_all: boolean;

  constructor(props?: ICalendarItemFromDTOProps) {
    this.employee_uuid = props?.employee_uuid || '';
    this.select_all =
      typeof props?.select_all === 'boolean' ? props?.select_all : false;
  }
}
