import React, {Suspense, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {
  CommentField,
  DetailsView,
  HeaderLeft,
  IDetailsViewItemProps,
  SuspenseEmpty,
} from '../../../../components/lib/DataDisplay';
import {
  useStateStoreDocumentItemList,
  useStopLoading,
  useStoredCompanies,
  useStoredProfile,
  useStoredStoreDocument,
  useStoredStoreDocumentList,
} from '../../../../hooks';
import {OverviewLayout} from '../../../../components/lib/Layout';
import {StoreReturnClientTabPanel} from '../Show';
import {
  StoreDocExtraServicesView,
  StoreDocItemsView,
  StoreDocumentBottomView,
  StoreDocumentPageHeader,
} from '../../Show';
import {useBreadcrumb, useModal} from '../../../../components/lib/libV2/hooks';
import {Routes, RoutesAcl} from '../../../../services/types';
import {
  StoreDocumentStatus,
  StoreDocumentType,
  StoreDocumentMapper,
  StoreDocumentModel,
  StorePaymentDocumentPostActionType,
  StorePaymentDocumentFormDTO,
} from '../../../../struture';
import {useAcl} from '../../../../contex';
import {useLocation, useNavigate, useParams} from 'react-router';
import {Col, Row} from 'antd';
import {ClientDetailsView} from '../../../Clients';
import {StoreDetailsView} from '../../Show';
import {toDateByFormat} from '../../../../services/helpers';
import {productStoreDocumentRoutes} from '../../../Products';
import StoreReturnClientMenuButton from '../Buttons/StoreReturnClientMenuButton';

const CloseDocumentSideWindowLazy = React.lazy(
  () => import('../../Show/StorePaymentDocumentSideWindow'),
);

export function StoreReturnClientPage() {
  const {documentId, productId} = useParams();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const location = useLocation();
  const {profile} = useStoredProfile();

  const {defaultCompanyUuid} = useStoredCompanies();

  const {store: storeAccess} = useAcl((acl) => acl);

  const {
    document,
    loading: storeLoading,
    error: storeError,
    handleUpdateStoreDocument,
    handlePaymentStoreDocument,
    handleCreateOrUpdateStoreDocumentItem,
    handleDeleteStoreDocumentItem,
    handlePrintStoreDocument,
    handlePrintStoreDocumentCheck,
    handleUpdateStoreDocumentComment,
  } = useStoredStoreDocument({
    documentUuid: documentId!,
    companyUuid: defaultCompanyUuid,
    documentType: StoreDocumentType.CRETURN,
  });

  const {
    storeDocumentItemList,
    loading: documentItemsLoading,
    refresh: storeDocumentItemListRefresh,
    limit: storeDocumentItemListLimit,
    total: storeDocumentItemListTotal,
    page: storeDocumentItemListPage,
  } = useStateStoreDocumentItemList({
    documentUuid: documentId!,
    limit: 100,
  });

  const storePayment = useMemo(
    () =>
      StoreDocumentMapper.toStorePaymentDocumentFormDTO(
        document || ({} as StoreDocumentModel),
        {
          cashier: profile?.defaultEmployeeModel,
          comment: `${t(
            'Reimbursement of funds to the client for returned goods on the invoice',
          )} №${document?.doc_local_number} ${t('From')} ${toDateByFormat(
            document?.doc_date,
            'DD.MM.YYYY HH:mm',
          )}`,
          payment_post_action: {
            action:
              StorePaymentDocumentPostActionType.SET_STORE_DOCUMENT_STATUS_COMMITTED,
          },
        },
      ),
    [document, profile?.defaultEmployeeModel, t],
  );

  const {
    handleCancel: storePaymentHandleCancel,
    visible: storePaymentVisible,
    handleSuccess: storePaymentHandleSuccess,
    handleOnInit: storePaymentHandleOnInit,
  } = useModal<StorePaymentDocumentFormDTO>({
    onSuccess: async (value) => {
      await handlePaymentStoreDocument(value, {with_document: true});
    },
  });

  const loading = useStopLoading({
    loading: storeLoading,
    error: storeError,
    message: 'An error occurred during store return client loading',
  });

  const {handleDeleteStoreDocuments: onDeleteStoreDocuments} =
    useStoredStoreDocumentList({
      companyUuid: defaultCompanyUuid,
      documentType: StoreDocumentType.CRETURN,
      loadOnInit: false,
    });

  const navigateGoBack = useCallback(() => {
    navigate(
      location?.state?.goBack ||
        `/${Routes.app}/${Routes.stores}/${Routes.storeReturnClient}`,
    );
  }, [location?.state?.goBack, navigate]);

  const handleDeleteStoreDocuments = useCallback(async () => {
    await onDeleteStoreDocuments([documentId!], navigateGoBack);
  }, [navigateGoBack, onDeleteStoreDocuments, documentId]);

  const routes = useBreadcrumb(
    productId
      ? productStoreDocumentRoutes(productId, (startUrl: string) => ({
          path: `${startUrl}/${Routes.stores}/${Routes.storeReturnClient}/${documentId}`,
          breadcrumbName: document?.doc_local_number || 'Show document',
        }))
      : [
          {
            path: `/${Routes.stores}/${Routes.storeReturnClient}`,
            breadcrumbName: 'Invoices for return to the client',
          },
          {
            path: `/${Routes.stores}/${Routes.storeReturnClient}/${documentId}`,
            breadcrumbName: document?.doc_local_number || 'Show return client',
          },
        ],
  );

  const detailViewItems: IDetailsViewItemProps[] = [
    {
      title: 'Client',
      className: 'client-details__view',
    },
    {
      title: 'Store',
      className: 'store-details__view',
    },
  ];

  return (
    <>
      <StoreReturnClientTabPanel document={document}>
        {({tabs}) => (
          <OverviewLayout
            aclItem={RoutesAcl[Routes.stores]}
            headerTitle={t('Invoice for return to the client')}
            headerLeft={
              <HeaderLeft absolute={false} onClick={navigateGoBack} />
            }
            headerRight={
              document?.doc_status_text === StoreDocumentStatus.DRAFT ? (
                <StoreReturnClientMenuButton
                  fromList={false}
                  handlePaymentStoreDocument={handlePaymentStoreDocument}
                  document={StoreDocumentMapper.toStoreDocumentFormDTO(
                    document!,
                    {
                      editMode: true,
                      client: document?.clientModel,
                    },
                  )}
                  onSuccess={handleUpdateStoreDocument}
                  handleDeleteStoreDocuments={handleDeleteStoreDocuments}
                />
              ) : null
            }
            underHeaderContent={
              document?.uuid ? (
                <StoreDocumentPageHeader document={document} />
              ) : null
            }
            header={
              <Row gutter={10}>
                <Col span={24}>
                  <DetailsView items={detailViewItems}>
                    {document?.clientModel?.uuid ? (
                      <ClientDetailsView client={document?.clientModel} />
                    ) : null}
                    {document?.companyStoreModel?.uuid ? (
                      <StoreDetailsView store={document?.companyStoreModel} />
                    ) : null}
                  </DetailsView>
                </Col>
                <Col span={24}>
                  <CommentField
                    comment={document?.doc_comment || t('Please enter note')}
                    handleUpdateComment={handleUpdateStoreDocumentComment}
                    disabled={!storeAccess?.manage}
                  />
                </Col>
                <Col span={24}>
                  <StoreDocItemsView
                    document={document!}
                    handleCreateOrUpdateStoreDocumentItem={
                      handleCreateOrUpdateStoreDocumentItem
                    }
                    handleDeleteStoreDocumentItem={
                      handleDeleteStoreDocumentItem
                    }
                    loading={documentItemsLoading}
                    storeDocumentItemList={storeDocumentItemList}
                    storeDocumentItemListRefresh={storeDocumentItemListRefresh}
                    storeDocumentItemListLimit={storeDocumentItemListLimit}
                    storeDocumentItemListTotal={storeDocumentItemListTotal}
                    storeDocumentItemListPage={storeDocumentItemListPage}
                  />
                </Col>
                {Array.isArray(document?.doc_extra_services) ? (
                  <Col span={24}>
                    <StoreDocExtraServicesView document={document!} />
                  </Col>
                ) : null}
              </Row>
            }
            outsideFooterContent={
              <StoreDocumentBottomView
                document={document}
                handlePrintStoreDocument={handlePrintStoreDocument}
                handlePaymentStoreDocument={storePaymentHandleOnInit}
                handlePrintStoreDocumentCheck={handlePrintStoreDocumentCheck}
              />
            }
            disabled={!storeAccess?.manage}
            editTooltip={t('Edit return to client')}
            loading={loading}
            data={null}
            routes={routes}
            tabs={tabs}
          />
        )}
      </StoreReturnClientTabPanel>
      <Suspense fallback={<SuspenseEmpty />}>
        <CloseDocumentSideWindowLazy
          title={`${t('Closing posting')}`}
          payment={storePayment!}
          loading={loading}
          onSuccess={storePaymentHandleSuccess}
          onCancel={storePaymentHandleCancel}
          visible={storePaymentVisible}
        />
      </Suspense>
    </>
  );
}
