import {STORE} from '../constants';
import {StoreModel} from '../../struture';
import {ApiError} from '../../services/types';
import {Dispatch} from 'redux';

export interface ISetStoreParam {
  type: STORE;
  store: StoreModel | null;
}

export interface IUpdateStoreParam {
  type: STORE;
  store: StoreModel;
}

export interface IResetStoreParam {
  type: STORE;
}

export interface IErrorStoreParam {
  type: STORE;
  error: ApiError | undefined;
}

export function setStore(
  store: StoreModel | null,
): (dispatch: Dispatch) => Promise<ISetStoreParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STORE.SET_STORE,
      store,
    });
  };
}

export function updateStore(
  store: StoreModel,
): (dispatch: Dispatch) => Promise<IUpdateStoreParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STORE.UPDATE_STORE,
      store,
    });
  };
}

export function resetStore(): (
  dispatch: Dispatch,
) => Promise<IResetStoreParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STORE.RESET_STORE,
    });
  };
}

export function errorStore(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorStoreParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STORE.ERROR_STORE,
      error,
    });
  };
}
