import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {List} from 'immutable';
import {
  IUseSettingsModuleListProps,
  IUseSettingsModuleListReturnType,
  useSettingsModuleList,
} from './useSettingsModuleList';
import {SettingsModuleModel, SettingsModuleStatuses} from '../struture';
import {
  loadMoreSettingsModuleList as storeLoadMoreSettingsModuleList,
  setSettingsModule as storeSetSettingsModule,
  setSettingsModuleList as storeSetSettingsModuleList,
  updateSettingsModule as storeUpdateSettingsModule,
  updateSettingsModuleFromList as storeUpdateSettingsModuleFromList,
} from '../store/actions';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../store/reducers';
import {REDUX_STATUS} from '../services/types';
import useStoredCompanies from './useStoredCompanies';
import {activeOrDeactivateModule} from '../services/api/system';
import {StatusError} from '../components/lib/Errors';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../contex';

export interface IUseStoredSettingsModuleListProps
  extends IUseSettingsModuleListProps {}

export interface IUseStoredSettingsModuleListReturnType
  extends Omit<IUseSettingsModuleListReturnType, 'entityList'> {
  settingsModuleList: List<SettingsModuleModel> | null;
  handleSearchSettingsModules: (keywords: string) => Promise<void>;
  handleLoadMoreSettingsModules: () => Promise<List<SettingsModuleModel> | void>;
  handleRefreshSettingsModules: () => Promise<List<SettingsModuleModel> | void>;
  handleSetSettingsModule: (settingsModule: SettingsModuleModel) => void;
  handleActivateModule: (settingsModule: SettingsModuleModel) => Promise<void>;
  handleUpdateSettingsModule: (
    settingsModuleModel: SettingsModuleModel,
  ) => void;
  handleDeactivateModule: (
    settingsModule: SettingsModuleModel,
  ) => Promise<void>;
  status: REDUX_STATUS;
  loadingMore: boolean;
  loadingActivateOrDeactivate: boolean;
}

export function useStoredSettingsModuleList(
  {
    loadOnInit,
    ...rest
  }: IUseStoredSettingsModuleListProps = {} as IUseStoredSettingsModuleListProps,
): IUseStoredSettingsModuleListReturnType {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingActivateOrDeactivate, setLoadingActivateOrDeactivate] =
    useState(false);

  const {settings, defaultCompanyUuid, setCompanySettings} =
    useStoredCompanies();

  const {
    status: storedSettingsModuleListStatus,
    loading: storedSettingsModuleListLoading,
    settingsModuleList,
    total: storedTotal,
    ...storedSettingsModuleListParams
  } = useSelector(({settingsModuleList}: RootState) => settingsModuleList);

  const dispatch = useDispatch<any>();

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: settingsModuleListLoading,
    total,
    ...clientsParams
  } = useSettingsModuleList({
    loadOnInit:
      loadOnInit || storedSettingsModuleListStatus !== REDUX_STATUS.SUCCEEDED,
    ...rest,
    companyUuid: defaultCompanyUuid,
  });

  useEffect(() => {
    if (
      List.isList(entityList) &&
      storedSettingsModuleListStatus !== REDUX_STATUS.SUCCEEDED
    ) {
      dispatch(storeSetSettingsModuleList(entityList, '', total));
    }
  }, [dispatch, entityList, storedSettingsModuleListStatus, total]);

  const handleSetSettingsModule = React.useCallback(
    (settingsModule: SettingsModuleModel) => {
      dispatch(storeSetSettingsModule(settingsModule));
    },
    [dispatch],
  );

  const handleSearchSettingsModules = React.useCallback(
    async (keywords: string) => {
      setLoadingMore(true);

      const settingsModuleListModel = await refresh({
        offset: 0,
        limit: 10,
        keywords,
        companyUuid: defaultCompanyUuid,
      });

      if (
        settingsModuleListModel &&
        List.isList(settingsModuleListModel?.settingsModules)
      ) {
        dispatch(
          storeSetSettingsModuleList(
            settingsModuleListModel.settingsModules,
            keywords,
            settingsModuleListModel?.total,
          ),
        );
      }
      setLoadingMore(false);
    },
    [dispatch, refresh, defaultCompanyUuid],
  );

  const handleLoadMoreSettingsModules = useCallback(async () => {
    if (
      List.isList(settingsModuleList) &&
      settingsModuleList?.size < storedTotal &&
      !loadingMore
    ) {
      setLoadingMore(true);

      const settingsModuleListModel = await refresh({
        offset: settingsModuleList?.size,
        limit: 10,
        showLoading: false,
      });

      if (
        settingsModuleListModel &&
        List.isList(settingsModuleListModel?.settingsModules)
      ) {
        dispatch(
          storeLoadMoreSettingsModuleList(
            settingsModuleListModel.settingsModules,
          ),
        );
      }

      setLoadingMore(false);

      return settingsModuleListModel?.settingsModules;
    }
  }, [dispatch, loadingMore, refresh, settingsModuleList, storedTotal]);

  const handleRefreshSettingsModules = useCallback(async () => {
    const settingsModuleListModel = await refresh({
      offset: 0,
      limit: 10,
      showLoading: false,
    });

    if (
      settingsModuleListModel &&
      List.isList(settingsModuleListModel?.settingsModules)
    ) {
      dispatch(
        storeSetSettingsModuleList(
          settingsModuleListModel?.settingsModules,
          '',
          settingsModuleListModel?.total,
        ),
      );

      return settingsModuleListModel?.settingsModules;
    }
  }, [dispatch, refresh]);

  const handleUpdateSettingsModule = React.useCallback(
    (settingsModuleModel: SettingsModuleModel) => {
      if (List.isList(settingsModuleList)) {
        dispatch(storeUpdateSettingsModuleFromList(settingsModuleModel));
        dispatch(storeUpdateSettingsModule(settingsModuleModel));
      }
    },
    [dispatch, settingsModuleList],
  );

  const handleActivateModule = React.useCallback(
    async (settingsModule: SettingsModuleModel) => {
      try {
        setLoadingActivateOrDeactivate(true);
        const apiSettings = await activeOrDeactivateModule({
          companyUuid: defaultCompanyUuid,
          moduleUuid: settingsModule?.uuid,
          status: SettingsModuleStatuses.Active,
        });

        const modules = apiSettings?.company_modules;

        if (modules?.size) {
          const companyModule = modules.find(
            (item) => item?.module === settingsModule?.module_name,
          );

          if (companyModule?.enabled) {
            const updatedSettingsModule = settingsModule.update(
              'module_enabled_for_company',
              () => true,
            );

            dispatch(storeUpdateSettingsModuleFromList(updatedSettingsModule));
            dispatch(storeUpdateSettingsModule(updatedSettingsModule));

            setCompanySettings({
              settings: apiSettings,
              alfa_name_order: settings?.alfa_name_order!,
            });
          }

          setLoadingActivateOrDeactivate(false);

          alert('success', t('Module'), t('Module active success'));
        }
      } catch (error: any) {
        setLoadingActivateOrDeactivate(false);

        alert(
          'error',
          t('Module'),
          `${t('An error occurred during active module')}: ${error?.message}`,
        );

        throw new StatusError(error?.message, error?.status);
      }
    },
    [
      alert,
      defaultCompanyUuid,
      dispatch,
      setCompanySettings,
      settings?.alfa_name_order,
      t,
    ],
  );

  const handleDeactivateModule = React.useCallback(
    async (settingsModule: SettingsModuleModel) => {
      try {
        setLoadingActivateOrDeactivate(true);
        const apiSettings = await activeOrDeactivateModule({
          companyUuid: defaultCompanyUuid,
          moduleUuid: settingsModule?.uuid,
          status: SettingsModuleStatuses.Disabled,
        });

        const modules = apiSettings?.company_modules;

        if (modules?.size) {
          const updatedSettingsModule = settingsModule.update(
            'module_enabled_for_company',
            () => false,
          );

          dispatch(storeUpdateSettingsModuleFromList(updatedSettingsModule));
          dispatch(storeUpdateSettingsModule(updatedSettingsModule));
          setCompanySettings({
            settings: apiSettings,
            alfa_name_order: settings?.alfa_name_order!,
          });
        }
        setLoadingActivateOrDeactivate(false);
        alert('success', t('Module'), t('Module deactivate success'));
      } catch (error: any) {
        setLoadingActivateOrDeactivate(false);

        alert(
          'error',
          t('Module'),
          `${t('An error occurred during deactivate module')}: ${
            error?.message
          }`,
        );

        throw new StatusError(error?.message, error?.status);
      }
    },
    [
      alert,
      defaultCompanyUuid,
      dispatch,
      setCompanySettings,
      settings?.alfa_name_order,
      t,
    ],
  );

  return {
    ...clientsParams,
    ...storedSettingsModuleListParams,
    settingsModuleList,
    offset,
    limit,
    total: storedTotal,
    refresh,
    loading:
      (!List.isList(settingsModuleList) && settingsModuleListLoading) ||
      !storedSettingsModuleListLoading,
    loadingActivateOrDeactivate,
    handleSearchSettingsModules,
    handleLoadMoreSettingsModules,
    status: storedSettingsModuleListStatus,
    loadingMore,
    handleRefreshSettingsModules,
    handleSetSettingsModule,
    handleActivateModule,
    handleDeactivateModule,
    handleUpdateSettingsModule,
  };
}
