import {Dispatch} from 'redux';
import {List} from 'immutable';
import {REPORT_LIST} from '../constants';
import {ReportModel} from '../../struture';
import {ApiError} from '../../services/types';

export interface ISetReportListParam {
  type: REPORT_LIST;
  reportList: List<ReportModel>;
  keywords?: string;
  total?: number;
  page?: number;
}

export interface IAddReportToListParam {
  type: REPORT_LIST;
  report: ReportModel;
}

export interface IDeleteReportFromListParam {
  type: REPORT_LIST;
  reportUuid: string;
}

export interface IErrorReportListParam {
  type: REPORT_LIST;
  error: ApiError | undefined;
}

export function setReportList(
  reportList: List<ReportModel>,
  keywords?: string,
  total?: number,
  startDate?: string,
  endDate?: string,
  page?: number,
): (dispatch: Dispatch) => Promise<ISetReportListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: REPORT_LIST.SET_REPORT_LIST,
      reportList,
      keywords,
      total,
      startDate,
      endDate,
      page,
    });
  };
}

export function loadMoreReportList(
  reportList: List<ReportModel>,
): (dispatch: Dispatch) => Promise<ISetReportListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: REPORT_LIST.LOAD_MORE_REPORT_LIST,
      reportList,
    });
  };
}

export function addReportToList(
  report: ReportModel,
): (dispatch: Dispatch) => Promise<IAddReportToListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: REPORT_LIST.ADD_REPORT,
      report,
    });
  };
}

export function deleteReportFromList(
  reportUuid: string,
): (dispatch: Dispatch) => Promise<IDeleteReportFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: REPORT_LIST.DELETE_REPORT,
      reportUuid,
    });
  };
}

export function errorReportInList(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorReportListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: REPORT_LIST.ERROR_REPORT_LIST,
      error,
    });
  };
}
