import * as React from 'react';
import {List} from 'immutable';
import moment from 'moment';
import {
  useEmployeeList,
  IUseEmployeeListProps,
  IUseEmployeeListReturnType,
} from './useEmployeeList';
import {EmployeeModel, EmployeeFormDTO, EmployeeMapper} from '../struture';
import {useStateEntityList} from '../components/lib/libV2/hooks';
import {
  createEmployee,
  editEmployee,
  deleteEmployees,
} from '../services/api/employee';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../contex';

export interface IUseStateEmployeeListProps extends IUseEmployeeListProps {}

export interface IEmployeeSearchProps {
  keywords: string;
  showLoading?: boolean;
  limit?: number;
  serviceUuid?: string;
}
export interface IUseStateEmployeeListReturnType
  extends Omit<IUseEmployeeListReturnType, 'entityList'> {
  employees: List<EmployeeModel> | null;
  handleCreateEmployee: (value: EmployeeFormDTO) => Promise<void>;
  handleUpdateEmployee: (value: EmployeeFormDTO) => Promise<void>;
  handleDeleteEmployees: (ids: string[]) => Promise<void>;
  handleSearchEmployees: (value: IEmployeeSearchProps) => Promise<void>;
  handleUpdate: (employee: EmployeeModel) => void;
}

export function useStateEmployeeList(
  {
    companyUuid,
    ...rest
  }: IUseStateEmployeeListProps = {} as IUseStateEmployeeListProps,
): IUseStateEmployeeListReturnType {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: clientLoading,
    total: clientListTotal,
    ...clientsParams
  } = useEmployeeList({
    companyUuid,
    ...rest,
  });

  const {
    entityList: employees,
    loading,
    handleCreate,
    handleDelete,
    handleUpdate,
    setEntityList,
    total,
  } = useStateEntityList<EmployeeModel>({
    entityList,
    refresh,
    limit,
    offset,
    total: clientListTotal,
  });

  const handleCreateEmployee = React.useCallback(
    async (value: EmployeeFormDTO) => {
      const employeeFormDTO = EmployeeMapper.toEmployeeFormDTO(value as any);

      const employee = await createEmployee(employeeFormDTO, companyUuid);
      const employeeModel = EmployeeMapper.toEmployeeModel(employee);

      const updatedModel = employeeModel.set(
        'created_at',
        moment(new Date()).toString(),
      );

      handleCreate(updatedModel, true);

      alert('success', t('Employee'), t('Employee created success'));
    },
    [companyUuid, handleCreate, alert, t],
  );

  const handleUpdateEmployee = React.useCallback(
    async (value: EmployeeFormDTO) => {
      const employeeFormDTO = EmployeeMapper.toEmployeeFormDTO(value as any);

      const employee = await editEmployee(employeeFormDTO, companyUuid);
      const employeeModel = EmployeeMapper.toEmployeeModel(employee);

      handleUpdate(employeeModel);

      alert('success', t('Employee'), t('Employee edited success'));
    },
    [companyUuid, handleUpdate, alert, t],
  );

  const handleDeleteEmployees = React.useCallback(
    async (ids: string[]) => {
      try {
        await deleteEmployees(ids, companyUuid);

        handleDelete(ids);
        alert('success', t('Employee'), t('Employee delete success'));
      } catch (error: any) {
        alert(
          'error',
          t('Employee'),
          `${t('An error occurred during delete employees')}: ${
            error?.message
          }`,
        );
      }
    },
    [companyUuid, handleDelete, alert, t],
  );

  const handleSearchEmployees = React.useCallback(
    async ({
      keywords,
      showLoading = true,
      limit = 10,
      serviceUuid,
    }: IEmployeeSearchProps) => {
      const employees = await refresh({
        offset: 0,
        keywords,
        showLoading,
        limit,
        serviceUuid,
      });

      if (employees) {
        setEntityList(employees);
      }
    },
    [refresh, setEntityList],
  );

  return {
    employees,
    offset,
    limit,
    refresh,
    total,
    loading: clientLoading || loading,
    ...clientsParams,
    handleCreateEmployee,
    handleUpdateEmployee,
    handleDeleteEmployees,
    handleSearchEmployees,
    handleUpdate,
  };
}
