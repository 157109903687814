import React from 'react';
import {AbonementFormDTO, AbonementGroupModel} from '../../../struture';
import styled, {useTheme} from 'styled-components';
import {StyledDescription, StyledTitle} from '../../../components/lib/Styled';
import {Routes} from '../../../services/types';
import {Link} from '../../../components/lib/DataDisplay';
import {useLocation} from 'react-router';
import {useTranslation} from 'react-i18next';
import {textToUpperCase} from '../../../services/helpers';
import {Table} from '../../../components/lib/libV2/DataDisplay';

export interface ITariffModelGroupViewProps {
  group: AbonementGroupModel | null;
  abonementUuid: string;
}
const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding-right: 15px;
  padding-bottom: 15px;
`;

const StyledSpace = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
`;

const StyledTable = styled(Table)`
  &&& {
    max-width: 200px;

    .ant-table-cell,
    .ant-table-tbody {
      background-color: ${({theme}) => theme.background.layout};
    }
  }
`;
export function TariffModelGroupView({
  group,
  abonementUuid,
}: ITariffModelGroupViewProps): JSX.Element {
  const {t} = useTranslation();
  const location = useLocation();
  const theme: any = useTheme();

  const timingListSize = Object.keys(group?.group_schedule_list || {}).length;

  const timingList = AbonementFormDTO.getTimingWeekList(
    group?.group_schedule_list,
  );

  const timingListArr = Object.entries(group?.group_schedule_list || {}).reduce(
    (acc: any, [key, value]) => {
      acc = [...acc, {day: t(key), time: `${value?.min} - ${value?.max}`}];
      return acc;
    },
    [],
  );

  const timingListField = timingListSize ? (
    timingList ? (
      <StyledTitle>{`${t('Mon-Sun')}: ${timingList}`}</StyledTitle>
    ) : (
      <StyledTable
        total={timingListArr.length}
        pageSize={timingListArr.length}
        loading={false}
        rowKey="day"
        dataSource={timingListArr}
        columns={[
          {
            width: 'fit-content',
            title: t('Weekday'),
            key: 'Weekday',
            render: (c: any) => c?.day,
          },
          {
            width: 'fit-content',
            title: t('Time'),
            key: 'time',
            render: (c: any) => c?.time,
          },
        ]}
        size="small"
      />
    )
  ) : null;

  return (
    <>
      {group ? (
        <StyledContainer>
          <StyledSpace>
            <Link
              to={`/${Routes.app}/${Routes.tariffModels}/${abonementUuid}/${Routes.tariffModelGroup}/${group?.uuid}`}
              tooltip={t('Go to tariff model details page')}
              state={{goBack: location.pathname}}>
              {textToUpperCase(group?.group_title)}
            </Link>
            <StyledDescription>{group?.group_comment}</StyledDescription>
            {timingListSize ? timingListField : null}
          </StyledSpace>
        </StyledContainer>
      ) : null}
    </>
  );
}
