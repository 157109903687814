import React from 'react';
import {useTranslation} from 'react-i18next';
import {IActionButtonProps} from '../../../../components/lib/General';
import {AddButton} from '../../../../components/lib/DataDisplay';
import {ArrowDownOutlined} from '@ant-design/icons';

export interface ILeftoverChangeButtonProps
  extends Omit<IActionButtonProps, 'children'> {
  children?: React.ReactNode;
  handleInitLeftover: () => void;
}

export function LeftoverChangeButton({
  children,
  loading,
  handleInitLeftover,
  ...rest
}: ILeftoverChangeButtonProps): React.JSX.Element {
  const {t} = useTranslation();

  return (
    <AddButton
      tooltipTitle={t('Enter balances')}
      onClick={handleInitLeftover}
      loading={loading}
      {...rest}>
      <ArrowDownOutlined />
    </AddButton>
  );
}
