import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Empty} from 'antd';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import {ListLayout} from '../../../components/lib/Layout';
import {successButtonStyle} from '../../../components/lib/Styled';
import {
  TariffModelCreateButton,
  TariffModelsDeleteButton,
  TariffModelEditButton,
} from '../Buttons';
import {
  useStoredAbonementList,
  useStopLoading,
  useStoredCompanies,
  useStoredAbonement,
  useKeyboardOpenForm,
  useSearchInputFocus,
} from '../../../hooks';
import {len, lessOrEqualThan, textToUpperCase} from '../../../services/helpers';
import {AbonementModel, AbonementMapper} from '../../../struture';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '../../../services/types';
import {
  TableActionCell,
  Table,
} from '../../../components/lib/libV2/DataDisplay';
import {useDropdownAlert, useAcl} from '../../../contex';
import {HeaderLeft, ListActions} from '../../../components/lib/DataDisplay';
import {useNavigate} from 'react-router';
import {TariffModelDetailsView} from '../Show';
import styled from 'styled-components';

const StyledTariffModelCreateButton = styled(TariffModelCreateButton)`
  ${successButtonStyle}
`;

export default function TariffModelListPage() {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {defaultCompanyUuid, defaultCompany, defaultCompanyLoading} =
    useStoredCompanies();
  const {manage} = useAcl(({abonement}) => abonement);
  const {alert} = useDropdownAlert();
  const {ref: listRef} = useKeyboardOpenForm({
    className: 'tariff-model-create-event',
    disabled: !manage,
  });

  const [selectedAbonements, setSelectedAbonements] = useState<
    AbonementModel[]
  >([]);

  const {
    abonementList,
    loading: abonementsListLoading,
    error: abonementsListError,
    limit: pageSize,
    total,
    loadingMore,
    keywords,
    page,

    handleRefreshAbonements,
    handleDeleteAbonements: onDeleteAbonements,
    handleSearchAbonements,
  } = useStoredAbonementList({companyUuid: defaultCompanyUuid});

  const {handleCreateAbonement, handleUpdateAbonement} = useStoredAbonement({
    companyUuid: defaultCompanyUuid,
    loadOnInit: false,
    abonementUuid: '',
  });

  const loading = useStopLoading({
    error: abonementsListError,
    loading: abonementsListLoading || loadingMore,
    message: 'An error occurred during tariff models loading',
  });

  const {inputRef, focus, daleyFocus} = useSearchInputFocus({loading});

  const handleDeleteAbonements = useCallback(
    async (value: string[]) => {
      await onDeleteAbonements(value);
      focus();
    },
    [focus, onDeleteAbonements],
  );

  const routes = useBreadcrumb([
    {
      path: `/${Routes.tariffModels}`,
      breadcrumbName: 'Tariff models',
    },
  ]);

  const rowSelection = useMemo(
    () => ({
      hideSelectAll: true,
      selectedRowKeys: selectedAbonements.map(({uuid}) => uuid),
      onChange: (
        selectedRowKeys: React.Key[],
        selectedRecords: AbonementModel[],
      ) => {
        if (lessOrEqualThan(len(selectedRecords), 1)) {
          setSelectedAbonements(selectedRecords);
        } else {
          alert('error', t('Delete'), t('You can delete up to 1 at a time'));
        }
      },
    }),
    [alert, selectedAbonements, t],
  );

  const columns = [
    {
      title: t('Title'),
      key: 'title',
      render: (abonement: AbonementModel) => (
        <TariffModelDetailsView tariffModel={abonement} />
      ),
    },
    {
      title: t('Amount'),
      key: 'amount',
      align: 'right' as any,
      render: (c: AbonementModel) =>
        defaultCompany
          ? `${defaultCompany?.currency_symbol} ${c?.abon_price || '0.00'}`
          : '0.00',
    },
    {
      title: t('Actions'),
      key: 'abonement_actions',
      align: 'center' as any,
      fixed: 'right' as any,
      render: (abonement: AbonementModel) => (
        <TableActionCell>
          <TariffModelEditButton
            disabled={!manage}
            type="text"
            abonement={AbonementMapper.toAbonementFormDTO(abonement, true)}
            data-testid="abonement-edit-button"
            onSuccess={handleUpdateAbonement}
            onCancel={daleyFocus}
          />
          <TariffModelsDeleteButton
            disabled={!manage}
            data-testid="abonement-delete-button"
            abonements={[abonement]}
            onSuccess={handleDeleteAbonements}>
            <DeleteOutlined />
          </TariffModelsDeleteButton>
        </TableActionCell>
      ),
    },
  ];

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.companies}/${defaultCompanyUuid}`);
  }, [defaultCompanyUuid, navigate]);

  return (
    <>
      <ListLayout
        ref={listRef}
        moduleItem="abonement"
        aclItem={RoutesAcl[Routes.tariffModels]}
        headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
        headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.tariffModels]))}
        headerRight={
          <>
            {selectedAbonements.length ? (
              <TariffModelsDeleteButton
                disabled={!manage}
                abonements={selectedAbonements}
                onSuccess={handleDeleteAbonements}
              />
            ) : (
              <TariffModelCreateButton
                disabled={!manage}
                loading={loading}
                onSuccess={handleCreateAbonement}
                className="tariff-model-create-event"
                onCancel={daleyFocus}
              />
            )}
          </>
        }
        routes={routes}
        empty={
          abonementList?.size ? null : (
            <Empty
              description={t(
                `It looks like you don't have any tariff models at the moment.`,
              )}>
              <TariffModelCreateButton
                type="primary"
                disabled={!manage}
                loading={loading}
                onSuccess={handleCreateAbonement}
                onCancel={daleyFocus}>
                {t('Create tariff model')}
              </TariffModelCreateButton>
            </Empty>
          )
        }
        headerExtra={
          <ListActions
            searchText={keywords}
            inputRef={inputRef}
            withoutPicker={true}
            loading={loading}
            handleSearch={(keywords) => handleSearchAbonements({keywords})}
            inputTooltip={t('Search tariff models')}
            inputLabel={t('Search tariff models')}
            withSearchContent={
              <StyledTariffModelCreateButton
                type="primary"
                disabled={!manage}
                loading={loading}
                onSuccess={handleCreateAbonement}
                title={t('Create')}
                icon={<PlusOutlined />}
                onCancel={daleyFocus}
              />
            }
          />
        }
        loading={loading && !abonementList?.size}>
        <Table<AbonementModel>
          rowSelection={rowSelection}
          total={total}
          pageSize={pageSize}
          dataSource={abonementList}
          onChange={handleRefreshAbonements}
          columns={columns}
          loading={loading || defaultCompanyLoading}
          page={page}
        />
      </ListLayout>
    </>
  );
}
