import {List} from 'immutable';
import {isListToArray} from '../../helpers';
import {GroupPaymentModel, CompanyModel, CompanyMapper} from '../internal';

export class GroupPaymentFormDTO {
  public uuid: string;

  public group_title: string;

  public group_description: string;

  public group_companies: CompanyModel[];

  constructor(props?: GroupPaymentModel & {defaultCompanyUuid?: string}) {
    this.uuid = props?.uuid || '';
    this.group_title = props?.title || '';
    this.group_description = props?.description || '';
    this.group_companies = props?.defaultCompanyUuid
      ? ([props?.defaultCompanyUuid as string] as any)
      : this.getCompaniesUuid((props?.companies as any) || []);
  }

  private getCompaniesUuid(companies: List<CompanyModel>): string[] {
    return isListToArray(
      companies.map((company) => CompanyMapper.toCompanyFormDTO(company)),
    );
  }
}
