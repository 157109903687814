import {
  EmployeeModel,
  ExpenseDiscountType,
  ExpenseWorkableType,
  ProductModel,
  ServiceModel,
} from '../internal';
import {correctPrice} from '../../services/helpers';

export class ExpenseCreateFormDTO {
  public uuid: string;
  public name?: string;
  public prevPrice?: string;
  public serviceUuid?: string;
  public price: string;
  public net_price: string;
  public comment: string;
  public amount: number;
  public discount: number;
  public discount_type: ExpenseDiscountType;
  public workable_type: ExpenseWorkableType;
  public workable_uuid: string;
  public manager_uuid: EmployeeModel | string;

  constructor(props: ProductModel | ServiceModel, manager: EmployeeModel) {
    this.uuid = props?.uuid || '';
    this.name =
      (props instanceof ProductModel ? props?.product_title : props?.title) ||
      '';
    this.comment = '';
    this.discount = 0;
    this.discount_type = ExpenseDiscountType.Fixed;
    this.workable_type =
      props instanceof ProductModel
        ? ExpenseWorkableType.Product
        : ExpenseWorkableType.Service;

    this.amount = 1;
    this.prevPrice = correctPrice(0);
    this.price = correctPrice(
      (props instanceof ProductModel
        ? props?.product_retail_price
        : props?.employee_service?.price || props?.price) || 0,
    );
    this.net_price = correctPrice(
      (props instanceof ProductModel
        ? props?.product_net_price
        : props?.net_price) || 0,
    );

    this.workable_uuid = props?.uuid || '';

    this.manager_uuid = manager?.uuid ? manager : '';

    this.serviceUuid = props instanceof ProductModel ? '' : props?.uuid;
  }
}
