import * as React from 'react';
import {RefObject} from 'react';
import {isDateGreater, isFunction} from '../services/helpers';

export interface IUseIntervalReturnProps<T> {
  intervalCallback: (value?: T) => void | Promise<void>;
  handleClearInterval: () => void;
  id: RefObject<null | NodeJS.Timeout>;
}

export function useInterval<T>(
  callback: any,
  delay: number | null,
  clearTime?: number,
  clearCallback?: () => void,
): IUseIntervalReturnProps<T> {
  const savedCallback = React.useRef(callback);
  const id = React.useRef<NodeJS.Timeout | null>(null);
  const clearTimeout = React.useRef<Date | null>(null);

  const handleClearInterval = React.useCallback((): void => {
    if (id?.current) {
      clearInterval(id?.current);
      id.current = null;
      clearTimeout.current = null;
    }
  }, []);

  const handleClearTimeout = React.useCallback((): boolean => {
    if (clearTimeout?.current) {
      return isDateGreater(new Date(), clearTimeout?.current);
    }
    return false;
  }, []);

  const intervalCallback = React.useCallback(
    (value?: T) => {
      if (!clearTimeout.current && clearTime) {
        clearTimeout.current = new Date(Date.now() + clearTime);
      }

      if (delay) {
        id.current = setInterval(() => {
          const isStop = handleClearTimeout();

          if (isStop) {
            if (isFunction(clearCallback)) {
              clearCallback();
            }

            handleClearInterval();
            return;
          }

          savedCallback.current(value);
        }, delay);
      }
    },
    [clearCallback, clearTime, delay, handleClearInterval, handleClearTimeout],
  );

  React.useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  return {
    intervalCallback,
    handleClearInterval: handleClearInterval,
    id,
  };
}
