export enum CASH_BOX_LIST {
  SET_CASH_BOX_LIST = 'set_cash_box_list',
  LOADING_CASH_BOX_LIST = 'loading_cash_box_list',
  ERROR_CASH_BOX_LIST = 'error_cash_box_list',
  LOAD_MORE_CASH_BOX_LIST = 'load_more_cash_box_list',
  ADD_CASH_BOX = 'add_cash_box',
  UPDATE_CASH_BOX = 'update_cash_box',
  DELETE_CASH_BOX = 'delete_cash_box',
  UPDATE_CASH_BOX_BALANCE = 'update_cash_box_balance',
}
