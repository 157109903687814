import {Button, Tooltip} from 'antd';
import styled from 'styled-components';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {Routes} from '../../../services/types';
import {useStoredProfile} from '../../../hooks';
import {ProfileInfo} from '../DataEntity/Profile';
import {InfoCircleOutlined} from '@ant-design/icons';
import {useLocation, useNavigate} from 'react-router';
import {UserHeaderDropdownMenu} from '../../../views/Users';

import {
  SettingsMenuRenderer,
  SettingsMenuRendererType,
} from '../../../views/Settings';

const StyledUserHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
`;

const StyledUserHeaderActionStart = styled.div`
  display: flex;
  align-items: center;
`;

const StyledUserHeaderActionEnd = styled.div`
  display: flex;
  align-items: center;
`;

const StyledUserHeaderActionItem = styled.div`
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  cursor: pointer;
  border-radius: 9999px;
  padding: 0.3rem;
`;

const StyledUserHeaderButton = styled(Button)`
  border: none;
  box-shadow: none;
  margin-top: 0.2rem;
  &:hover {
    background-color: ${({theme}) => theme.colorsV2.linkColor};
    color: ${({theme}) => theme.colors.white} !important;
  }
`;

export function AppUserHeaderBar(): React.JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigateAboutUs = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.appInfo}`, {
      state: {from: location?.pathname, prevTitle: 'Return to panel'},
    });
  }, [location?.pathname, navigate]);

  return (
    <StyledUserHeaderContainer>
      <StyledUserHeaderActionStart />
      <StyledUserHeaderActionEnd>
        <StyledUserHeaderActionItem>
          <Tooltip title={t('About us')}>
            <StyledUserHeaderButton
              type="default"
              onClick={handleNavigateAboutUs}
              icon={<InfoCircleOutlined style={{fontSize: 18}} />}
            />
          </Tooltip>
        </StyledUserHeaderActionItem>
        <StyledUserHeaderActionItem>
          <SettingsMenuRenderer menuType={SettingsMenuRendererType.DROPDOWN} />
        </StyledUserHeaderActionItem>
        <StyledUserHeaderActionItem>
          <UserHeaderDropdownMenu />
        </StyledUserHeaderActionItem>
      </StyledUserHeaderActionEnd>
    </StyledUserHeaderContainer>
  );
}
