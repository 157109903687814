import React, {useCallback} from 'react';
import {LocalizationMapper, SignupFormDTO, SignupFormMapper} from '../struture';
import {signup} from '../services/api/user';
import {Routes} from '../services/types';
import {useTranslation} from 'react-i18next';
import {
  IEmployeeSignupPageProps,
  ISignupPageProps,
  // ApproveEnterScreenProps,
} from '../views/Authorization';
import {
  useStoreVerificationFactory,
  IUseStoreVerificationFactoryProps,
  IStoreVerificationFactoryReturnType,
} from './useStoreVerificationFactory';
import {getRootKey, encryptObject} from '../services/helpers';
import {LocalStorageItems} from '../services/const';
import {useDropdownAlert} from '../contex';
import {useLocation, useNavigate} from 'react-router';
import {useLocalization} from './useLocalization';

export interface IUseStateSignupProps<T>
  extends Pick<IUseStoreVerificationFactoryProps<T>, 'getState'> {
  key: Routes.signup | Routes.employeeSignup | Routes.approveEnter;
}

export interface IUseStateSignupReturnType<T>
  extends IStoreVerificationFactoryReturnType<T> {
  handleSignup: (value: SignupFormDTO) => Promise<void>;
  notifyError: (error: any) => void;
  navigateToVerificationFormModal: () => void;
}

export function useStateSignup<T>({
  key,
  getState,
}: IUseStateSignupProps<T>): IUseStateSignupReturnType<T> & T {
  const navigate = useNavigate();

  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const locale = useLocalization();
  const location = useLocation();

  const {updateStateFactory, ...state} = useStoreVerificationFactory<T>({
    key,
    getState,
  });

  const navigateToVerificationFormModal = useCallback(() => {
    navigate(`/${Routes.auth}/${Routes.verificationForm}`, {
      state: {
        verificationStateName: key as Routes.signup | Routes.employeeSignup,
        from: location.pathname,
        prevState: location.state,
      },
    });
  }, [key, location.pathname, location.state, navigate]);

  const handleSignup = React.useCallback(
    async (value: SignupFormDTO): Promise<void> => {
      const signupFormDTO = SignupFormMapper.toSignupFormDTO(value);

      const {success, access_token, refresh_token} = await signup(
        signupFormDTO,
        locale.notEmptyFields,
      );

      if (success) {
        alert('success', t('Registration'), t('Registration success'));

        const signupData = {
          identifier: signupFormDTO.phone,
        };

        setTimeout(() => {
          updateStateFactory(signupData as T);
        }, 1000);

        if (key === Routes.signup) {
          await localStorage.setItem(
            LocalStorageItems.signupTokens,
            encryptObject({access_token, refresh_token}),
          );
        }

        if (key === Routes.employeeSignup) {
          const encryptEmployeeAuthData = encryptObject({
            access_token,
            refresh_token,
            identifier: value?.phone,
          });

          localStorage.setItem(
            LocalStorageItems.employeeAuthData,
            encryptEmployeeAuthData,
          );
        }

        navigateToVerificationFormModal();
      }
    },
    [
      locale.notEmptyFields,
      alert,
      t,
      key,
      navigateToVerificationFormModal,
      updateStateFactory,
    ],
  );

  const notifyError = React.useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Registration'),
        `${t('An error occurred during registration')} : ${apiError?.message}`,
      );
    },
    [alert, t],
  );

  return {
    ...(state as IStoreVerificationFactoryReturnType<T> & T),
    handleSignup,
    notifyError,
    navigateToVerificationFormModal,
    updateStateFactory,
  };
}
