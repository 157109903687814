import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {OverviewLayout} from '../../../components/lib/Layout';
import {
  useRequiredFields,
  useStopLoading,
  useStoredCompanies,
  useStoredProductCategory,
} from '../../../hooks';
import {ProductCategoryTabPanel, ProductCategoryPictures} from '../Show';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import {ProductType, Routes, RoutesAcl} from '../../../services/types';
import {useLocation, useNavigate, useParams} from 'react-router';
import {ProductCategoryEditButton} from '../Buttons';
import {HeaderLeft} from '../../../components/lib/DataDisplay';
import {textToUpperCase} from '../../../services/helpers';
import {
  CompanyMapper,
  ProductCategoryMapper,
  ProductCategoryModel,
} from '../../../struture';
import {StyledDescription, StyledTitle} from '../../../components/lib/Styled';
import {Row, Col} from 'antd';
import {PRODUCT_CATEGORY_REQUIRED_FIELD} from '../../../services/api/product';
import {useAcl} from '../../../contex';
import CompanyPictures from '../../Company/Show/CompanyPictures';

export function ProductCategoryPage(): JSX.Element {
  const {categoryId} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {t} = useTranslation();

  const {manage} = useAcl(({product}) => product);
  const {defaultCompanyUuid} = useStoredCompanies();

  const {
    category,
    loading: categoryLoading,
    error: categoryError,
    handleUpdateProductCategory,
    handleSetProductCategoryLogo,
    handleSetProductCategoryCover,
  } = useStoredProductCategory({
    categoryUuid: categoryId!,
    companyUuid: defaultCompanyUuid,
  });

  const loading = useStopLoading({
    loading: categoryLoading,
    error: categoryError,
    message: 'An error occurred during category loading',
  });

  const routes = useBreadcrumb([
    {
      path: `/${Routes.goods}/${Routes.categories}`,
      breadcrumbName: 'Categories',
      state: {type: ProductType.Category},
    },
    {
      path: `/${Routes.goods}/${Routes.categories}/${categoryId}`,
      breadcrumbName: category?.title || 'Show category',
    },
  ]);

  const navigateGoBack = useCallback(() => {
    navigate(
      location?.state?.goBack ||
        `/${Routes.app}/${Routes.goods}/${Routes.categories}`,
      {
        state: {type: ProductType.Category},
      },
    );
  }, [location?.state?.goBack, navigate]);

  const changeFields = useCallback(
    (
      {description, ...rest}: typeof PRODUCT_CATEGORY_REQUIRED_FIELD,
      category: ProductCategoryModel | null,
    ) => {
      return {
        ...rest,
        note: description,
      };
    },
    [],
  );

  const overviewData = useRequiredFields(
    category,
    PRODUCT_CATEGORY_REQUIRED_FIELD,
    changeFields,
  );

  return (
    <ProductCategoryTabPanel>
      {({tabs}) => (
        <OverviewLayout
          aclItem={RoutesAcl[Routes.products]}
          headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
          headerRight={
            <ProductCategoryEditButton
              category={ProductCategoryMapper.toProductCategoryFormDTO(
                category!,
              )}
              onSuccess={handleUpdateProductCategory}
              disabled={loading}
            />
          }
          headerTitle={textToUpperCase(t('Category of goods and materials'))}
          disabled={!manage}
          loading={loading}
          data={overviewData}
          routes={routes}
          tabs={tabs}
          header={
            <Row>
              <Col span={24}>
                <ProductCategoryPictures
                  disabled={!manage}
                  onUploadCoverFile={handleSetProductCategoryCover}
                  onUploadLogoFile={handleSetProductCategoryLogo}
                  logo={category?.logo_url}
                  cover={category?.cover_url}
                  title={category?.title}
                />
              </Col>

              <Col span={24}>
                <StyledTitle bold fontSize={20}>
                  {category?.title}
                </StyledTitle>
              </Col>

              {category?.parent?.uuid ? (
                <Col span={24}>
                  <StyledDescription>{`${t('Parent category')}: ${
                    category?.parent?.title
                  }`}</StyledDescription>
                </Col>
              ) : null}
            </Row>
          }
        />
      )}
    </ProductCategoryTabPanel>
  );
}
