import {PRICE_MARGIN_LIST} from '../constants';
import {PriceMarginListModel, PriceMarginModel} from '../../struture';
import {ApiError} from '../../services/types';
import {Dispatch} from 'redux';

export interface ISetPriceMarginListParam {
  type: PRICE_MARGIN_LIST;
  priceMarginList: PriceMarginListModel;
}

export interface IAddPriceMarginToListParam {
  type: PRICE_MARGIN_LIST;
  priceMargin: PriceMarginModel;
}

export interface IUpdatePriceMarginFromListParam {
  type: PRICE_MARGIN_LIST;
  priceMargin: PriceMarginModel;
}

export interface IDeletePriceMarginFromListParam {
  type: PRICE_MARGIN_LIST;
  priceMarginUuid: string;
}

export interface IErrorPriceMarginListParam {
  type: PRICE_MARGIN_LIST;
  error: ApiError | undefined;
}

export function setPriceMarginList(
  priceMarginList: PriceMarginListModel,
): (dispatch: Dispatch) => Promise<ISetPriceMarginListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRICE_MARGIN_LIST.SET_PRICE_MARGIN_LIST,
      priceMarginList,
    });
  };
}

export function loadMorePriceMarginList(
  priceMarginList: PriceMarginListModel,
): (dispatch: Dispatch) => Promise<ISetPriceMarginListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRICE_MARGIN_LIST.LOAD_MORE_PRICE_MARGIN_LIST,
      priceMarginList,
    });
  };
}

export function addPriceMarginToList(
  priceMargin: PriceMarginModel,
): (dispatch: Dispatch) => Promise<IAddPriceMarginToListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRICE_MARGIN_LIST.ADD_PRICE_MARGIN,
      priceMargin,
    });
  };
}

export function updatePriceMarginFromList(
  priceMargin: PriceMarginModel,
): (dispatch: Dispatch) => Promise<IUpdatePriceMarginFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRICE_MARGIN_LIST.UPDATE_PRICE_MARGIN,
      priceMargin,
    });
  };
}

export function deletePriceMarginFromList(
  priceMarginUuid: string,
): (dispatch: Dispatch) => Promise<IDeletePriceMarginFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRICE_MARGIN_LIST.DELETE_PRICE_MARGIN,
      priceMarginUuid,
    });
  };
}

export function errorPriceMarginInList(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorPriceMarginListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRICE_MARGIN_LIST.ERROR_PRICE_MARGIN_LIST,
      error,
    });
  };
}
