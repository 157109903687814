import * as React from 'react';
import {
  useStoredCashBoxList,
  IUseStateCashBoxListReturnType,
} from './useStoredCashBoxList';
import useStoredCompanies from './useStoredCompanies';
import {CashBoxModel} from '../struture';
import {List} from 'immutable';

export interface UseStoredStoreSaleCashBoxListReturnType
  extends IUseStateCashBoxListReturnType {}

export function useStoredStoreSaleCashBoxList(): UseStoredStoreSaleCashBoxListReturnType {
  const {defaultCompanyUuid} = useStoredCompanies();

  const [saleCahBoxList, setSaleCashBoxList] =
    React.useState<List<CashBoxModel> | null>(null);

  const {cashBoxList, ...cashBoxParams} = useStoredCashBoxList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  React.useEffect(() => {
    if (List.isList(cashBoxList)) {
      const saleCahBoxList = cashBoxList?.filter(
        ({box_is_locational, box_is_virtual}) =>
          box_is_locational && !box_is_virtual,
      );

      setSaleCashBoxList(saleCahBoxList);
    }
  }, [cashBoxList]);

  return {
    cashBoxList: saleCahBoxList,
    ...cashBoxParams,
  };
}
