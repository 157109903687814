import {Record, List} from 'immutable';
import {ListModel, ListProps} from './List';
import Template, {ITemplateProps} from './Template';

export interface ITemplateListProps extends ListProps {
  templates: ITemplateProps[];
}

export interface ITemplateListReturnType extends ListProps {
  templates: List<Template>;
}

export default class TemplateList extends Record<ITemplateListReturnType>({
  templates: List(),
  ...ListModel.toJS(),
} as any) {
  /**
   * @constructor
   * @param {{templates:[]}} param0
   */
  constructor({templates = [], ...props}: ITemplateListProps) {
    super({
      templates: List(templates.map((template) => new Template(template))),
      ...props,
    });
  }
}
