import * as React from 'react';
import {List} from 'immutable';
import {
  useClientAbonementSubscriptionList,
  IUseClientAbonementSubscriptionListProps,
  IUseClientAbonementSubscriptionListReturnType,
} from './useClientAbonementSubscriptionList';
import {
  ClientAbonementSubscriptionModel,
  ClientAbonementSubscriptionFormDTO,
  ClientAbonementMapper,
  ClientAbonementFormDTO,
  ClientAbonementDTO,
} from '../struture';
import {
  setClientAbonementSubscriptionList as storeSetClientAbonementSubscriptionList,
  loadMoreClientAbonementSubscriptionList as storeLoadMoreClientAbonementSubscriptionList,
} from '../store/actions';
import {useSelector, useDispatch} from 'react-redux';
import {RootState} from '../store/reducers';
import {REDUX_STATUS} from '../services/types';
import {useCallback, useEffect, useState} from 'react';
import {createClientAbonementSubscriptionLit} from '../services/api/client';
import {isFunction} from '../services/helpers';

export interface IUseStateClientAbonementSubscriptionListProps
  extends IUseClientAbonementSubscriptionListProps {
  updateStoreClientAbonement: (value: ClientAbonementDTO) => void;
}

export interface IUseStateClientAbonementSubscriptionListReturnType
  extends Omit<IUseClientAbonementSubscriptionListReturnType, 'entityList'> {
  subscriptionList: List<ClientAbonementSubscriptionModel> | null;
  handleSearchClientAbonementSubscriptions: (keywords: string) => Promise<void>;
  handleLoadMoreClientAbonementSubscriptions: () => Promise<void>;
  handleRefreshClientAbonementSubscriptions: (
    value: Partial<IUseClientAbonementSubscriptionListProps> & {page: number},
  ) => Promise<void>;
  handleCreateClientAbonementSubscription: (
    value: ClientAbonementSubscriptionFormDTO,
  ) => Promise<void>;
  status: REDUX_STATUS;
  loadingMore: boolean;
  page: number;
}

export function useStoredClientAbonementSubscriptionList(
  {
    abonementUuid,
    loadOnInit = true,
    updateStoreClientAbonement,
    ...rest
  }: IUseStateClientAbonementSubscriptionListProps = {} as IUseStateClientAbonementSubscriptionListProps,
): IUseStateClientAbonementSubscriptionListReturnType {
  const [loadingMore, setLoadingMore] = useState(false);
  const [subscriptionList, setAbonementListList] =
    useState<List<ClientAbonementSubscriptionModel> | null>(null);
  const [isStarLoading, setIsStartLoading] = React.useState<boolean>(false);

  const {
    status: storedClientAbonementSubscriptionListStatus,
    loading: storedClientAbonementSubscriptionListLoading,
    cachedSubscriptionList,
    total: storeTotal,
    keywords: storeKeywords,
    page: storedPage,
    ...storedClientAbonementSubscriptionListParams
  } = useSelector(
    ({clientAbonementSubscriptionList}: RootState) =>
      clientAbonementSubscriptionList,
  );

  const dispatch = useDispatch<any>();

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: subscriptionListLoading,
    total,
    ...subscriptionsParams
  } = useClientAbonementSubscriptionList({
    abonementUuid,
    loadOnInit: false,
    ...rest,
  });

  React.useEffect(() => {
    if (cachedSubscriptionList?.size > 0) {
      const subscriptionList = cachedSubscriptionList.get(abonementUuid);

      if (subscriptionList) {
        setIsStartLoading(false);
        setAbonementListList(subscriptionList?.subscriptions);
      } else {
        setIsStartLoading(true);
      }
    }
    if (cachedSubscriptionList?.size === 0 && !subscriptionList) {
      setIsStartLoading(true);
    }
  }, [
    dispatch,
    cachedSubscriptionList,
    abonementUuid,
    storeTotal,
    subscriptionList,
  ]);

  useEffect(() => {
    if (!List.isList(entityList) && isStarLoading && loadOnInit) {
      setIsStartLoading(false);
      (async () => {
        const entityList = await refresh({abonementUuid});

        if (entityList) {
          dispatch(
            storeSetClientAbonementSubscriptionList(
              entityList,
              abonementUuid,
              '',
            ),
          );
        }
      })();
    }
  }, [abonementUuid, dispatch, entityList, isStarLoading, loadOnInit, refresh]);

  const handleSearchClientAbonementSubscriptions = React.useCallback(
    async (keywords: string) => {
      setLoadingMore(true);
      const subscriptionListModel = await refresh({
        offset: 0,
        limit: 10,
        keywords,
      });

      if (
        subscriptionListModel &&
        List.isList(subscriptionListModel?.subscriptions)
      ) {
        dispatch(
          storeSetClientAbonementSubscriptionList(
            subscriptionListModel,
            abonementUuid,
            keywords,
            subscriptionListModel.total,
          ),
        );
      }
      setLoadingMore(false);
    },
    [abonementUuid, dispatch, refresh],
  );

  const handleLoadMoreClientAbonementSubscriptions = useCallback(async () => {
    if (
      List.isList(subscriptionList) &&
      subscriptionList?.size < storeTotal &&
      !loadingMore
    ) {
      setLoadingMore(true);
      const subscriptionListModel = await refresh({
        offset: subscriptionList?.size,
        limit: 10,
        showLoading: false,
      });

      if (
        subscriptionListModel &&
        List.isList(subscriptionListModel?.subscriptions)
      ) {
        dispatch(
          storeLoadMoreClientAbonementSubscriptionList(
            subscriptionListModel,
            abonementUuid,
          ),
        );
      }

      setLoadingMore(false);
    }
  }, [
    subscriptionList,
    storeTotal,
    loadingMore,
    refresh,
    dispatch,
    abonementUuid,
  ]);

  const handleRefreshClientAbonementSubscriptions = useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = false,
      page,
    }: Partial<IUseClientAbonementSubscriptionListProps> & {page: number}) => {
      const subscriptionListModel = await refresh({
        offset,
        limit,
        showLoading,
      });

      if (
        subscriptionListModel &&
        List.isList(subscriptionListModel?.subscriptions)
      ) {
        dispatch(
          storeSetClientAbonementSubscriptionList(
            subscriptionListModel,
            abonementUuid,
            '',
            subscriptionListModel?.total,
            page,
          ),
        );
      }
    },
    [abonementUuid, dispatch, refresh],
  );

  const handleCreateClientAbonementSubscription = React.useCallback(
    async (value: ClientAbonementSubscriptionFormDTO) => {
      const {abonement, subscriptions} =
        await createClientAbonementSubscriptionLit(value, abonementUuid);

      const {subscriptions: clientSubscriptionsAbonement, total} =
        subscriptions;

      if (isFunction(updateStoreClientAbonement)) {
        const {abonement: clientAbonement} = abonement;
        updateStoreClientAbonement(clientAbonement);
      }

      const abonementListModel =
        ClientAbonementMapper.toClientAbonementSubscriptionListModel(
          clientSubscriptionsAbonement,
          total,
        );

      dispatch(
        storeLoadMoreClientAbonementSubscriptionList(
          abonementListModel,
          abonementUuid,
        ),
      );
    },
    [abonementUuid, dispatch, updateStoreClientAbonement],
  );

  return {
    ...subscriptionsParams,
    ...storedClientAbonementSubscriptionListParams,
    subscriptionList,
    total: storeTotal,
    loadingMore,
    offset,
    limit,
    refresh,
    loading:
      (!List.isList(subscriptionList) && subscriptionListLoading) ||
      !storedClientAbonementSubscriptionListLoading,
    handleSearchClientAbonementSubscriptions,
    handleLoadMoreClientAbonementSubscriptions,
    status: storedClientAbonementSubscriptionListStatus,
    handleRefreshClientAbonementSubscriptions,
    keywords: storeKeywords || '',
    page: storedPage || 1,
    handleCreateClientAbonementSubscription,
  };
}
