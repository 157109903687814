import React, {useEffect, useState} from 'react';
import styled, {useTheme} from 'styled-components';
import {StyledDescription, StyledTitle} from '../../../components/lib/Styled';
import {useTranslation} from 'react-i18next';
import {textToUpperCase, toDateByFormat} from '../../../services/helpers';
import {IUseStateFileListReturnType} from '../../../hooks';
import EmptyLayout from '../../../components/lib/Layout/EmptyLayout';
import {Empty, Timeline, TimelineItemProps} from 'antd';
import {LoadingOutlined, CloseOutlined} from '@ant-design/icons';
import {OrderUploadFilesButton} from '../Buttons';
import {List} from 'immutable';
import {ScheduleCalendarModel} from '../../../struture';
import {FileListItem} from '../Show';
import {MdOutlineAttachFile} from 'react-icons/md';

export interface IFileListProps extends IUseStateFileListReturnType {
  schedule: ScheduleCalendarModel | null;

  onCloseDrawer: () => void;
  disabled?: boolean;
}

const StyledContainer = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  width: 100%;
  height: 100%;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;

  background-color: ${({theme}) => theme.background.primary};
`;

const StyledHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 99999999;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 70px;
  margin-bottom: 20px;

  background-color: ${({theme}) => theme.background.primary};
  border-bottom-color: ${({theme}) => theme.colors.lightDisable};
  border-bottom-width: 1px;
  border-bottom-style: solid;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  font-size: 50px;
`;

const StyledCloseOutlined = styled(CloseOutlined)`
  cursor: pointer;
`;

const Description = styled(StyledDescription)`
  position: absolute;
  top: 7px;
  right: 0;
`;

const StyledTimeline = styled(Timeline)`
  width: 100%;
`;

export function FileList({
  fileList,
  loading,
  handleDeleteFiles,
  handleUploadFiles,

  schedule,

  onCloseDrawer,

  disabled,
}: IFileListProps): React.JSX.Element {
  const {t} = useTranslation();
  const theme: any = useTheme();

  const [items, setItems] = useState<TimelineItemProps[]>([]);

  useEffect(() => {
    if (List.isList(fileList) && fileList?.size) {
      const groupBy = fileList
        .groupBy(({created_at}) =>
          toDateByFormat(created_at, 'YYYY.MM.DD HH:mm'),
        )
        .toArray()
        .flatMap(([label, files], index) => {
          return [
            {
              children: (
                <FileListItem
                  label={label}
                  files={files}
                  handleDeleteFiles={handleDeleteFiles}
                  disabled={disabled}
                />
              ),
              dot: <MdOutlineAttachFile />,
            },
          ];
        });

      setItems(groupBy);
    }
  }, [fileList, handleDeleteFiles, disabled]);

  return (
    <StyledContainer>
      {loading || !fileList?.size ? (
        <EmptyLayout headerHeight={200}>
          {loading ? (
            <StyledLoadingOutlined />
          ) : (
            <Empty description={t('There are no files found.')} />
          )}
        </EmptyLayout>
      ) : (
        <>
          <StyledHeader>
            {schedule ? (
              <Description $color={theme.colors.success}>{`${t('Order')} №${
                schedule?.schedule_number
              }`}</Description>
            ) : null}
            <StyledTitleContainer>
              <StyledCloseOutlined onClick={onCloseDrawer} />
              <StyledTitle>{textToUpperCase(t('Attached files'))}</StyledTitle>
            </StyledTitleContainer>

            <OrderUploadFilesButton
              disabled={disabled}
              type="text"
              handleUploadFiles={handleUploadFiles}
            />
          </StyledHeader>

          <StyledTimeline items={items} />
        </>
      )}
    </StyledContainer>
  );
}
