import Button from './Button';
import {Text} from '../Format';
import React, {useCallback} from 'react';
import {useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import styled, {css} from 'styled-components';
import {Breadcrumb as ANTDBreadcrumb} from 'antd';

export type Route = {
  path: string;
  state?: any;
  breadcrumbName: string;
};

export interface IBreadcrumbProps {
  routes: Route[];
}

const StyledANTDBreadcrumb = styled(ANTDBreadcrumb)`
  height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledText = styled(Text)``;

const StyledBackButton = styled(Button)<{$absolute?: boolean}>`
  width: fit-content;
  min-width: 0;
  height: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0;
  margin: 0;

  &&:hover,
  &&:active,
  &&:focus {
    background-color: transparent;
    border-color: transparent;
    opacity: 0.7;
  }
`;

export function Breadcrumb({routes}: IBreadcrumbProps): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const onNavigate = useCallback(
    (path: string, state = {}) => {
      navigate(path, {state});
    },
    [navigate],
  );

  return (
    <StyledANTDBreadcrumb
      items={routes.map(({path, breadcrumbName}, index) => ({
        title: breadcrumbName,
      }))}
      itemRender={(route: any, params: any, items: any) => {
        const last =
          items?.findIndex(({title}: any) => title === route?.title) ===
          routes.length - 1;

        const item = routes?.find(
          ({breadcrumbName}: any) => breadcrumbName === route?.title,
        );

        return (
          <StyledBackButton
            type="text"
            onClick={() =>
              onNavigate(last ? '' : item?.path || '', item?.state)
            }>
            <StyledText>{t(route?.title)}</StyledText>
          </StyledBackButton>
        );
      }}
    />
  );
}
