import {
  IClientDTOProps,
  ICompanyDTOProps,
  ICompanyRequisiteDTOProps,
  IProfileDTOProps,
  StoreDocumentMultiCurrencyDTOProps,
  StorePaymentDocumentDiscountSourceType,
  StorePaymentDocumentDiscountType,
} from '../internal';

export enum InvoiceType {
  OUT = 'OUT',
  IN = 'IN',
}

export enum InvoiceMappedType {
  EMPTY = 'EMPTY',
  PAYMENT_OUT = 'PAYMENT_OUT',
}

export const MAPPED_INVOICE_TYPES: {
  [K in InvoiceType]?: (InvoiceType | InvoiceMappedType)[];
} = {
  [InvoiceType.OUT]: [InvoiceType.OUT, InvoiceMappedType.EMPTY],
  [InvoiceType.IN]: [
    InvoiceType.IN,
    InvoiceMappedType.EMPTY,
    InvoiceMappedType.PAYMENT_OUT,
  ],
};

export interface InvoiceRequisite {
  title: string;
  enabled: boolean;
  requisites: string;
}

export enum InvoiceStatus {
  ACTIVE = 'active',
  DRAFT = 'draft',
  COMMITTED = 'committed',
  SENT = 'sent',
  UNKNOWN = '',
}

export interface InvoiceStats {
  in_total: number;
  out_total: number;
  sum_in_total: number;
  sum_out_total: number;
}

export interface InvoiceDTO {
  uuid: string;
  inv_type: InvoiceType;
  inv_date: string;
  inv_income_number: string;
  inv_internal_number: string;
  inv_company_requisites: ICompanyRequisiteDTOProps;
  inv_client_requisites: ICompanyRequisiteDTOProps;
  inv_multi_currencies: StoreDocumentMultiCurrencyDTOProps[];
  inv_discount_value: number;
  inv_sum_total: number;
  inv_sum_discount: number;
  inv_discount_type: StorePaymentDocumentDiscountType;
  inv_discount_source: StorePaymentDocumentDiscountSourceType;
  inv_status: InvoiceStatus;
  created_at: string;
  updated_at: string;
  inv_comment: string;
  created_by: IProfileDTOProps;
  client: IClientDTOProps;
  company: ICompanyDTOProps;
  has_order_operations: boolean;
  has_store_operations: boolean;
}

export interface InvoiceListDTO {
  invoices: InvoiceDTO[];
  total: number;
}
