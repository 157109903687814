import {Record, List} from 'immutable';
import {IListProps} from './List';
import {UserOperationDTO, UserOperationModel} from '../internal';

export interface UserOperationListProps extends IListProps {
  operations: UserOperationDTO[];
}

export interface UserOperationListReturnType extends IListProps {
  operations: List<UserOperationModel>;
}

export class UserOperationListModel extends Record<UserOperationListReturnType>(
  {
    operations: List(),
    total: 0,
  },
) {
  /**
   * @constructor
   * @param {{users:[]}} param0
   */
  constructor({operations = [], ...props}: UserOperationListProps) {
    const options: any = {};
    if (Array.isArray(operations)) {
      options.operations = List(
        operations.map((user) => new UserOperationModel(user)),
      );
    }

    super({
      ...props,
      ...options,
    });
  }
}
