import {Record} from 'immutable';
import {
  ExpenseMapper,
  ExpenseModel,
  IExpenseDTOProps,
  InvoiceItemDTO,
  InvoiceItemPositionableType,
  InvoiceItemStatus,
  IProductDTOProps,
  IServiceDTOProps,
  IStoreDocumentItemDTOProps,
  ProductMapper,
  ProductModel,
  ProfileMapper,
  ProfileModel,
  ServiceMapper,
  ServiceModel,
  StoreDocumentCurrency,
  StoreDocumentItemModel,
  StoreDocumentMapper,
} from '../internal';
import {WithoutRecursion} from '../type';
import {correctPrice} from '../../services/helpers';
import {immerable} from 'immer';

export interface InvoiceItemModelReturnType {
  uuid: string;
  item_title: string;
  item_comment: string;
  item_currency: StoreDocumentCurrency | string;
  item_positionable_type: InvoiceItemPositionableType;
  item_base_price: string;
  item_price: string;
  item_total: string;
  item_amount: number;
  item_status: InvoiceItemStatus;
  created_at: string;
  updated_at: string;
  item_positionable_object:
    | ServiceModel
    | ProductModel
    | ExpenseModel
    | StoreDocumentItemModel;
  created_by: ProfileModel;
}

export class InvoiceItemModel extends Record<InvoiceItemModelReturnType>({
  uuid: '',
  item_title: '',
  item_comment: '',
  item_currency: '',
  item_positionable_type: InvoiceItemPositionableType.CUSTOM,
  item_base_price: '',
  item_price: '',
  item_total: '',
  item_amount: 0,
  item_status: InvoiceItemStatus.ENABLE,
  created_at: '',
  updated_at: '',
  item_positionable_object: {} as ProductModel,
  created_by: {} as ProfileModel,
}) {
  [immerable]? = true;
  constructor(
    props: InvoiceItemDTO = {} as InvoiceItemDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: InvoiceItemModelReturnType = {
      ...props,

      item_base_price: correctPrice(props?.item_base_price || 0),
      item_price: correctPrice(props?.item_price || 0),
      item_total: correctPrice(props?.item_total || 0),

      created_by: ProfileMapper.toProfileModel(
        ProfileMapper.toProfileDTO(props?.created_by),
        [WithoutRecursion.invoice, ...withoutRecursion],
      ),
      item_positionable_object:
        InvoiceItemModel.toItemPositionableObjectModelFactory(
          props?.item_positionable_object,
          props?.item_positionable_type,
        ),
    };

    super(options);
  }

  private static toItemPositionableObjectModelFactory(
    item_positionable_object:
      | IServiceDTOProps
      | IProductDTOProps
      | IExpenseDTOProps
      | IStoreDocumentItemDTOProps,
    item_positionable_type: InvoiceItemPositionableType,
  ): ServiceModel | ProductModel | ExpenseModel | StoreDocumentItemModel {
    if (item_positionable_type === InvoiceItemPositionableType.SERVICE) {
      return ServiceMapper.toServiceModel(
        ServiceMapper.toServiceDTO(
          item_positionable_object as IServiceDTOProps,
        ),
      );
    }

    if (item_positionable_type === InvoiceItemPositionableType.PRODUCT) {
      return ProductMapper.toProductModel(
        ProductMapper.toProductDTO(
          item_positionable_object as IProductDTOProps,
        ),
      );
    }

    if (item_positionable_type === InvoiceItemPositionableType.ORDER) {
      return ExpenseMapper.toExpenseModel(
        ExpenseMapper.toExpenseDTO(
          item_positionable_object as IExpenseDTOProps,
        ),
      );
    }

    return StoreDocumentMapper.toStoreDocumentItemModel(
      StoreDocumentMapper.toStoreDocumentItemDTO(
        item_positionable_object as IStoreDocumentItemDTOProps,
      ),
    );
  }
}
