import React, {useEffect, useMemo} from 'react';
import {StyledDescription, StyledTitle} from '../../../components/lib/Styled';
import {AlphaNameStatuses} from '../../../struture';
import {useTranslation} from 'react-i18next';
import styled, {useTheme} from 'styled-components';
import {Button} from '../../../components/lib/DataDisplay';
import {Drawer} from 'antd';
import {textToUpperCase} from '../../../services/helpers';
import {useModal} from '../../../hooks';

export interface ISettingsModuleAlphaNameModalProps {
  isLinking?: boolean;
}

const StyledContent = styled.div`
  margin: 5px;
  width: 100%;
  background-color: ${({theme}) => theme.colors.background.primary};
  border-radius: 20px;
`;

const Title = styled(StyledTitle)`
  flex: 1;
  text-align: center;
`;

const Description = styled(StyledDescription)`
  width: auto;
  height: auto;
  margin-left: 5px;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
`;

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

const StyledDrawer = styled(Drawer)`
  &&& .ant-drawer-body {
    padding: 10px;
  }
`;

export function SettingsModuleAlphaNameModal({
  isLinking,
}: ISettingsModuleAlphaNameModalProps): JSX.Element {
  const theme: any = useTheme();
  const {t} = useTranslation();

  const {handleOnInit, handleCancel, visible} = useModal();

  const statusText = useMemo(
    () => ({
      [AlphaNameStatuses.Activated]: t(
        'The order for the registration of a personalized Alpha name for the formation of name mailings has been created and is awaiting payment',
      ),
      [AlphaNameStatuses.Paid]: t(
        'The order for the registration of a personalized Alpha name for the formation of name mailings has been created, paid for and is being registered with the provider of telecommunication services. Usually, the registration procedure takes from 3 to 14 days. Upon activation and connection of "Alpha-name", the User will be notified through notifications and a test SMS to the Company\'s registration number.',
      ),
      [AlphaNameStatuses.Completed]: t(
        'The order for the registration of a personalized Alpha name for the formation of name mailings has been completed and the name substitution function in SMS in the "From" field has been activated. Now, all SMS notifications will be personalized.',
      ),
      [AlphaNameStatuses.Expired]: t(
        'The validity period of the mailing list service is suspended. In order to restore the function, it is enough to pay the subscription for a new term. For existing registrants, the procedure takes up to 3 days.',
      ),
    }),
    [t],
  );

  const statusColor = useMemo(
    () => ({
      [AlphaNameStatuses.Activated]: theme.colors.warning,
      [AlphaNameStatuses.Paid]: theme.colors.link,
      [AlphaNameStatuses.Completed]: theme.colors.success,
      [AlphaNameStatuses.Expired]: theme.colors.error,
    }),
    [
      theme.colors.error,
      theme.colors.link,
      theme.colors.success,
      theme.colors.warning,
    ],
  );

  useEffect(() => {
    const drawer = document.querySelector('.personalization-sms');

    if (visible) {
      if (drawer instanceof HTMLElement) {
        const content = drawer.querySelector('.ant-drawer-content-wrapper');

        if (content instanceof HTMLElement) {
          content.classList.add('prevent-transform-drawer');
        }
      }
    } else {
      if (drawer instanceof HTMLElement) {
        const content = drawer.querySelector('.ant-drawer-content-wrapper');

        if (content instanceof HTMLElement) {
          content.classList.remove('prevent-transform-drawer');
        }
      }
    }
  }, [visible]);

  return (
    <>
      <Description
        $withoutEllipsis
        onClick={isLinking ? handleOnInit : undefined}
        $color={isLinking ? theme.colors.link : theme.colors.disable}>
        {t('About statuses')}
      </Description>
      <StyledDrawer
        title={<Title>{textToUpperCase(t('About statuses'))}</Title>}
        placement="bottom"
        width={500}
        onClose={handleCancel}
        open={visible}>
        <StyledContent>
          {Object.entries(statusText).map(([key, value]) => (
            <StyledTextContainer key={key}>
              <StyledTitle
                fontSize={16}
                $color={(statusColor as any)[key as AlphaNameStatuses]}>
                {`${t(key)}: `}{' '}
                <StyledTitle $color={theme.colors.primary} key={key}>
                  {t(value)}
                </StyledTitle>
              </StyledTitle>
            </StyledTextContainer>
          ))}
        </StyledContent>
      </StyledDrawer>
    </>
  );
}
