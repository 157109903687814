import React from 'react';
import styled, {css} from 'styled-components';

export type MessageType = 'danger' | 'primary' | 'success' | undefined;

export interface IMessageProps {
  messageType?: MessageType;
  message: string;
  children?: React.ReactNode;
}

const MessageText = styled.span<Pick<IMessageProps, 'messageType'>>`
  font-size: 1.1em;
  color: black;
  ${({messageType}) =>
    messageType === 'danger' &&
    css`
      color: ${({theme}) => theme.colors.error};
    `}
  ${({messageType}) =>
    messageType === 'primary' &&
    css`
      color: ${({theme}) => theme.colors.primary};
    `}

  ${({messageType}) =>
    messageType === 'success' &&
    css`
      color: ${({theme}) => theme.colors.success};
      font-weight: 600;
    `}
`;

export function Message({
  messageType,
  message,
  children,
}: IMessageProps): JSX.Element {
  return (
    <>
      <MessageText messageType={messageType}>{message}&nbsp;</MessageText>
      {children || null}
    </>
  );
}
