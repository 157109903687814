import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {useStateResetPassword} from '../../../hooks';
import {ResetPasswordTabPanel} from './Show';
import {AuthRouteLayout, FullWidthSpace, HeaderText} from '../Show';
import {ResetPasswordForm} from './Forms';
import AuthLayout from '../Show/AuthLayout';
import {ResetPasswordMapper} from '../../../struture';

export default withTranslation()(ResetPasswordPage);

export interface IResetPasswordPageProps extends WithTranslation {}

function ResetPasswordPage({t}: IResetPasswordPageProps): JSX.Element {
  const {
    loading,
    authData,
    identifier,

    handleResetPassword,
    notifyError,
  } = useStateResetPassword();

  return (
    <AuthLayout>
      <ResetPasswordTabPanel>
        {({routes}) => (
          <AuthRouteLayout
            header={
              <FullWidthSpace direction="vertical" size="middle">
                <HeaderText title={t('Restore access')} />
                <ResetPasswordForm
                  showNotify={false}
                  notifyError={notifyError}
                  resetPassword={ResetPasswordMapper.toResetPasswordDTO({
                    verification_code: authData?.verification_code || '',
                  })}
                  onSuccess={handleResetPassword}
                />
              </FullWidthSpace>
            }
            routes={routes}
          />
        )}
      </ResetPasswordTabPanel>
    </AuthLayout>
  );
}
