import * as React from 'react';
import {List} from 'immutable';
import {
  useStoreDocumentRelatedDocumentList,
  IUseStoreDocumentRelatedDocumentListProps,
  IUseStoreDocumentRelatedDocumentListReturnType,
} from './useStoreDocumentRelatedDocumentList';
import {
  StoreDocumentFormDTO,
  StoreDocumentListModel,
  StoreDocumentModel,
  StoreDocumentType,
  StorePaymentDocumentFormDTO,
} from '../struture';
import {useStateEntityList} from '../components/lib/libV2/hooks';
import {useStopLoading} from './useStopLoading';
import {useStoredStoreDocumentList} from './useStoredStoreDocumentList';
import {
  IUseStateStoredDocumentReturnType,
  useStoredCompanies,
  useStoredStoreDocument,
} from './index';
import {useCallback, useState} from 'react';

export interface IUseStateStoreDocumentRelatedDocumentListProps
  extends IUseStoreDocumentRelatedDocumentListProps {
  documentUuid: string;
}

export const RelatedReturnDocumentType: any = {
  [StoreDocumentType.IN]: StoreDocumentType.RETURN,
  [StoreDocumentType.OUT]: StoreDocumentType.CRETURN,
};

export const RelatedReturnDocumentTypeComment: any = {
  [StoreDocumentType.IN]: '',
  [StoreDocumentType.OUT]: 'Return to the customer on the invoice',
};

export interface IUseStateStoreDocumentRelatedDocumentListReturnType
  extends Omit<IUseStoreDocumentRelatedDocumentListReturnType, 'entityList'>,
    Pick<
      IUseStateStoredDocumentReturnType,
      | 'handleCreateStoreDocument'
      | 'handleUpdateStoreDocument'
      | 'handleCreateOrUpdateStoreDocument'
      | 'handlePaymentStoreDocument'
    > {
  documentList: List<StoreDocumentModel> | null;
  loading: boolean;
  handleRefreshStoreDocuments: (
    value: Partial<IUseStateStoreDocumentRelatedDocumentListProps>,
  ) => Promise<StoreDocumentListModel | void>;
  handleSearchStoreDocuments: (
    value: Partial<IUseStateStoreDocumentRelatedDocumentListProps>,
  ) => Promise<StoreDocumentListModel | void>;
  handleDeleteStoreDocuments: (document: StoreDocumentModel) => Promise<void>;
}

export default function useStateStoreDocumentRelatedDocumentList({
  documentUuid,
  ...rest
}: IUseStateStoreDocumentRelatedDocumentListProps): IUseStateStoreDocumentRelatedDocumentListReturnType {
  const {defaultCompanyUuid} = useStoredCompanies();

  const {
    entityList: storeDocumentList,
    loading: storeDocumentListLoading,
    error: storeDocumentListError,
    refresh,
    total: storeDocumentListTotal,
    document,
    ...storeDocumentListRest
  } = useStoreDocumentRelatedDocumentList({
    ...rest,
    documentUuid,
  });

  const loading = useStopLoading({
    loading: storeDocumentListLoading,
    error: storeDocumentListError,
    message: 'An error occurred during store document list loading',
  });

  const {
    entityList: stateStoreDocuments,
    setEntityList,
    handleUpdate,
    handleCreate,
    handleDelete,
    total,
  } = useStateEntityList<StoreDocumentModel>({
    entityList: storeDocumentList?.documents!,
    total: storeDocumentListTotal,
  });

  const {handleDeleteStoreDocuments: onDeleteStoreDocuments} =
    useStoredStoreDocumentList({
      companyUuid: defaultCompanyUuid,
      loadOnInit: false,
      documentType: RelatedReturnDocumentType[document?.doc_type!],
    });

  const {
    handleCreateStoreDocument: onCreateStoreDocument,
    handleUpdateStoreDocument: onUpdateStoreDocument,
    handleCreateOrUpdateStoreDocument: onCreateOrUpdateStoreDocument,
    handlePaymentStoreDocument: onPaymentStoreDocument,
  } = useStoredStoreDocument({
    companyUuid: defaultCompanyUuid,
    documentUuid: documentUuid!,
    documentType: RelatedReturnDocumentType[document?.doc_type!],
    loadOnInit: false,
  });

  const handleRefreshStoreDocuments = React.useCallback(
    async (value: Partial<IUseStateStoreDocumentRelatedDocumentListProps>) => {
      const storeDocumentList = await refresh(value);

      if (storeDocumentList && List.isList(storeDocumentList?.documents)) {
        setEntityList(storeDocumentList?.documents);
      }
    },
    [refresh, setEntityList],
  );

  const handleSearchStoreDocuments = React.useCallback(
    async ({
      offset = 0,
      limit = 10,
      ...rest
    }: Partial<IUseStateStoreDocumentRelatedDocumentListProps>) => {
      const storeDocumentList = await refresh({...rest});

      if (storeDocumentList && List.isList(storeDocumentList?.documents)) {
        setEntityList(storeDocumentList?.documents);
      }
    },
    [refresh, setEntityList],
  );

  const handleCreateStoreDocument = React.useCallback(
    async (value: StoreDocumentFormDTO): Promise<void> => {
      const documentModel = await onCreateStoreDocument(value);

      if (documentModel) {
        handleCreate(documentModel?.document);
      }
    },
    [handleCreate, onCreateStoreDocument],
  );

  const handleUpdateStoreDocument = React.useCallback(
    async (value: StoreDocumentFormDTO): Promise<void> => {
      const storeDocumentModel = await onUpdateStoreDocument(value);

      if (storeDocumentModel) {
        handleUpdate(storeDocumentModel);
      }
    },
    [handleUpdate, onUpdateStoreDocument],
  );

  const handleDeleteStoreDocuments = React.useCallback(
    async (document: StoreDocumentModel): Promise<void> => {
      await onDeleteStoreDocuments([document?.uuid]);

      handleDelete([document?.uuid]);
    },
    [handleDelete, onDeleteStoreDocuments],
  );

  const handleCreateOrUpdateStoreDocument = useCallback(
    async (value: StoreDocumentFormDTO) => {
      const document = await onCreateOrUpdateStoreDocument(value);

      if (document) {
        if (value?.uuid) {
          handleUpdate(document);
        } else {
          handleCreate(document);
        }
      }

      return document;
    },
    [handleCreate, handleUpdate, onCreateOrUpdateStoreDocument],
  );

  const handlePaymentStoreDocument = useCallback(
    async (value: StorePaymentDocumentFormDTO) => {
      const document = await onPaymentStoreDocument(value);

      if (document) {
        handleUpdate(document);
      }

      return document;
    },
    [handleUpdate, onPaymentStoreDocument],
  );

  return {
    ...storeDocumentListRest,
    loading: storeDocumentListLoading || loading,
    documentList: stateStoreDocuments,
    handleCreateStoreDocument,
    handleUpdateStoreDocument,
    handleDeleteStoreDocuments,
    refresh,
    handleRefreshStoreDocuments,
    handleSearchStoreDocuments,
    error: storeDocumentListError,
    total,
    handleCreateOrUpdateStoreDocument,
    handlePaymentStoreDocument,
    document,
  };
}
