import {List} from 'immutable';
import {APP_STATE, CASH_BOX_LIST} from '../constants';
import {
  compose,
  isEqualByUuid,
  not,
  correctPrice,
} from '../../services/helpers';
import {ApiError, REDUX_STATUS} from '../../services/types';
import {CashBoxModel} from '../../struture';
import {PaymentScheduleOperationType} from '../../services/api/orders';

interface ICashBoxListState {
  cashBoxList: List<CashBoxModel> | null;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
  keywords?: string;
  total: number;
  page?: number;
}

interface SetActionList
  extends Pick<
    ICashBoxListState,
    'cashBoxList' | 'keywords' | 'total' | 'page'
  > {
  type: CASH_BOX_LIST.SET_CASH_BOX_LIST;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface AddActionToList {
  type: CASH_BOX_LIST.ADD_CASH_BOX;
  cashBox: CashBoxModel;
}

interface UpdateCashBoxFromList {
  type: CASH_BOX_LIST.UPDATE_CASH_BOX;
  cashBox: CashBoxModel;
}

interface UpdateCashBoxBalanceFromList {
  type: CASH_BOX_LIST.UPDATE_CASH_BOX;
  cashBoxUuid: string;
  sum: string;
  method: PaymentScheduleOperationType;
}

interface DeleteActionFromList {
  type: CASH_BOX_LIST.DELETE_CASH_BOX;
  cashBoxUuid: string;
}

interface LoadingActionInList {
  type: CASH_BOX_LIST.LOADING_CASH_BOX_LIST;
}

interface ErrorActionInList extends Pick<ICashBoxListState, 'error'> {
  type: CASH_BOX_LIST.ERROR_CASH_BOX_LIST;
}

interface Handlers {
  [CASH_BOX_LIST.SET_CASH_BOX_LIST]: (
    state: ICashBoxListState,
    action: SetActionList,
  ) => ICashBoxListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: ICashBoxListState,
    action: SetInitialStateAction,
  ) => ICashBoxListState;

  [CASH_BOX_LIST.LOAD_MORE_CASH_BOX_LIST]: (
    state: ICashBoxListState,
    action: SetActionList,
  ) => ICashBoxListState;

  [CASH_BOX_LIST.ADD_CASH_BOX]: (
    state: ICashBoxListState,
    action: AddActionToList,
  ) => ICashBoxListState;

  [CASH_BOX_LIST.UPDATE_CASH_BOX]: (
    state: ICashBoxListState,
    action: UpdateCashBoxFromList,
  ) => ICashBoxListState;

  [CASH_BOX_LIST.UPDATE_CASH_BOX_BALANCE]: (
    state: ICashBoxListState,
    action: UpdateCashBoxBalanceFromList,
  ) => ICashBoxListState;

  [CASH_BOX_LIST.DELETE_CASH_BOX]: (
    state: ICashBoxListState,
    action: DeleteActionFromList,
  ) => ICashBoxListState;

  [CASH_BOX_LIST.ERROR_CASH_BOX_LIST]: (
    state: ICashBoxListState,
    value: ErrorActionInList,
  ) => ICashBoxListState;

  [CASH_BOX_LIST.LOADING_CASH_BOX_LIST]: (
    state: ICashBoxListState,
    value?: LoadingActionInList,
  ) => ICashBoxListState;
  DEFAULT: (state: ICashBoxListState) => ICashBoxListState;
}

const initState: ICashBoxListState = {
  cashBoxList: null,
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
  keywords: '',
  total: 0,
};

const handlers: Handlers = {
  [CASH_BOX_LIST.SET_CASH_BOX_LIST]: (
    state,
    {cashBoxList, keywords, total, page},
  ) => {
    const defaultBoxIndex = cashBoxList?.findIndex(
      ({box_is_default_for_company}) => box_is_default_for_company,
    );

    let box = cashBoxList;

    if (defaultBoxIndex && ~defaultBoxIndex) {
      const item = cashBoxList?.slice(defaultBoxIndex)?.first();

      const items = cashBoxList?.splice(defaultBoxIndex, 1);

      if (item) {
        box = items?.unshift(item) as any;
      }
    }

    return {
      ...state,
      ...{
        cashBoxList: box,
        status: REDUX_STATUS.SUCCEEDED,
        keywords: keywords || '',
        total: total >= 0 ? total : state.total,
        page: page || state?.page,
      },
    };
  },

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [CASH_BOX_LIST.LOAD_MORE_CASH_BOX_LIST]: (state, {cashBoxList}) => ({
    ...state,
    ...{
      cashBoxList:
        List.isList(state.cashBoxList) && List.isList(cashBoxList)
          ? state.cashBoxList.merge(cashBoxList)
          : state.cashBoxList,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [CASH_BOX_LIST.ADD_CASH_BOX]: (state, {cashBox}) => {
    const is_default_for_company = cashBox?.box_is_default_for_company;

    let updatedCashBoxes = state.cashBoxList;

    if (is_default_for_company && updatedCashBoxes?.size) {
      updatedCashBoxes = updatedCashBoxes?.map((cashBox) =>
        cashBox.update('box_is_default_for_company', () => false),
      );
    }

    return {
      ...state,
      ...{
        cashBoxList: (List.isList(state.cashBoxList)
          ? updatedCashBoxes?.size && !cashBox?.box_is_default_for_company
            ? updatedCashBoxes?.splice(1, 0, cashBox)
            : updatedCashBoxes?.unshift(cashBox)
          : List([cashBox])) as any,
        status: REDUX_STATUS.SUCCEEDED,
        total: state.total + 1,
      },
    };
  },

  [CASH_BOX_LIST.UPDATE_CASH_BOX]: (state, {cashBox}) => {
    const is_default_for_company = cashBox?.box_is_default_for_company;
    let cashBoxList = state.cashBoxList;

    if (cashBoxList) {
      if (is_default_for_company) {
        cashBoxList = cashBoxList
          ?.filter((stateCashBox) => stateCashBox.uuid !== cashBox?.uuid)
          .map((item) =>
            item.update('box_is_default_for_company', () => false),
          );

        cashBoxList = cashBoxList.unshift(cashBox);
      } else {
        cashBoxList = cashBoxList?.map((stateCashBox) => {
          if (stateCashBox.uuid === cashBox?.uuid) {
            return cashBox;
          }
          return stateCashBox;
        });
      }
    }

    return {
      ...state,
      ...{
        cashBoxList: List.isList(state.cashBoxList)
          ? cashBoxList
          : List([cashBox]),
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [CASH_BOX_LIST.UPDATE_CASH_BOX_BALANCE]: (
    state,
    {cashBoxUuid, sum, method},
  ) => {
    return {
      ...state,
      ...{
        cashBoxList: List.isList(state.cashBoxList)
          ? state.cashBoxList.map((cashBox) => {
              if (cashBox?.uuid === cashBoxUuid) {
                return cashBox.update('box_balance', (balance) => {
                  const numBalance = Number(balance) || 0;
                  const numSum = Number(sum) || 0;

                  if (method === PaymentScheduleOperationType.In) {
                    return correctPrice(numBalance + numSum);
                  }

                  if (method === PaymentScheduleOperationType.Out) {
                    return correctPrice(numBalance - numSum);
                  }

                  return balance;
                });
              }
              return cashBox;
            })
          : state.cashBoxList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [CASH_BOX_LIST.DELETE_CASH_BOX]: (state, {cashBoxUuid}) => ({
    ...state,
    ...{
      cashBoxList: List.isList(state.cashBoxList)
        ? state.cashBoxList.filter(compose(not, isEqualByUuid(cashBoxUuid)))
        : null,
      status: REDUX_STATUS.SUCCEEDED,
      total: state.total > 0 ? state.total - 1 : 0,
    },
  }),

  [CASH_BOX_LIST.ERROR_CASH_BOX_LIST]: (
    state: ICashBoxListState,
    {error}: ErrorActionInList,
  ) => ({
    ...state,
    ...{
      error,
      loading: false,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [CASH_BOX_LIST.LOADING_CASH_BOX_LIST]: (state: ICashBoxListState) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: ICashBoxListState) => state,
};

export default function CashBoxList(
  state: any = initState,
  action: any,
): ICashBoxListState {
  const handler = handlers[action.type as CASH_BOX_LIST] || handlers.DEFAULT;
  return handler(state, action);
}
