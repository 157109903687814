import React from 'react';
import {useTranslation} from 'react-i18next';
import {len, eq} from '../../../services/helpers';
import {
  DeleteButton,
  IDeleteButtonProps,
} from '../../../components/lib/DataDisplay';
import {TextFormat} from '../../../components/lib/Format';
import {useDelete} from '../../../components/lib/libV2/hooks';
import {CompanyModel} from '../../../struture';

export interface ICompaniesButtonProps extends IDeleteButtonProps {
  companies: CompanyModel[];
  onSuccess: (ids: string[]) => Promise<void>;
  onError?: () => void;
  children?: React.ReactNode;
  title?: string;
}

export function CompaniesDeleteButton({
  children,
  companies = [],
  onSuccess,
  onError,
  title,
  ...rest
}: ICompaniesButtonProps): JSX.Element {
  const {t} = useTranslation();

  const {handleDelete, loading} = useDelete({
    onSuccess,
    entity: companies,
    notifyTitle: 'Companies',
  });

  return (
    <DeleteButton
      title={title}
      tooltipTitle={t('Delete company')}
      confirmTitle={
        <TextFormat breakWord>
          {t('Are you sure you want to delete')} <b>{len(companies)}</b>
          {` ${eq(len(companies), 1) ? t('company') : t('companies')}`}?
        </TextFormat>
      }
      onConfirm={handleDelete}
      loading={loading}
      cancelButtonText={t('Cancel')}
      {...rest}
    />
  );
}
