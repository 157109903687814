import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {HiOutlineReceiptTax} from 'react-icons/hi';
import {OverviewLayout} from '../../../components/lib/Layout';
import {
  useStatePayment,
  useStopLoading,
  useStoredCompanies,
} from '../../../hooks';
import {ifElse, toDateByFormat} from '../../../services/helpers';
import {PaymentTabPanel} from '../Show/PaymentTabPanel';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import {OPERATION_TYPE, Routes, RoutesAcl} from '../../../services/types';
import {
  CashBoxModel,
  CashBoxType,
  ClientModel,
  EmployeeModel,
  EmployeeStatuses,
  PaymentMapper,
  ScheduleCalendarModel,
  StoreDocumentModel,
} from '../../../struture';
import {useLocation, useNavigate, useParams} from 'react-router';
import {UpdatePaymentMenuButton} from '../Buttons';
import {
  DetailsItemView,
  DetailsView,
  HeaderLeft,
} from '../../../components/lib/DataDisplay';
import styled, {useTheme} from 'styled-components';
import {StyledDescription, StyledTitle} from '../../../components/lib/Styled';
import {Col, Image, Row, Space} from 'antd';
import {PaymentBottomView} from '../Show';
import {EmployeeDetailsView} from '../../Employees';
import {ClientDetailsView} from '../../Clients';
import {OrderDetailsView} from '../../Orders';
import {PaymentScheduleOperationType} from '../../../services/api/orders';
import {storeDocumentRoute, storeDocumentRouteTitle} from '../../Stores';
import {useAcl} from '../../../contex';

import './PaymentPage.less';

const StyledSpace = styled(Space)`
  width: 100%;
`;

export function PaymentPage(): JSX.Element {
  const {paymentId, orderId, clientId, employeeId, documentId} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {t} = useTranslation();
  const theme: any = useTheme();
  const {manage} = useAcl(({payment}) => payment);

  const {defaultCompanyUuid} = useStoredCompanies();

  const from = clientId ? Routes.clients : orderId ? Routes.orders : undefined;

  const {
    payment,
    loading: paymentLoading,
    error: paymentError,
    handleUpdatePayment,
    handleUpdatePaymentCashBox,
  } = useStatePayment({
    paymentUuid: paymentId!,
  });

  const loading = useStopLoading({
    loading: paymentLoading,
    error: paymentError,
    message: 'An error occurred during payment loading',
  });

  const storeDocument = payment?.payment_trigger_object as StoreDocumentModel;

  const routes = useBreadcrumb(
    documentId
      ? [
          {
            path: `/${Routes.stores}/${
              storeDocumentRoute[storeDocument?.doc_type]
            }`,
            breadcrumbName:
              storeDocumentRouteTitle[storeDocument?.doc_type] || 'Documents',
          },
          {
            path: `/${Routes.stores}/${
              storeDocumentRoute[storeDocument?.doc_type]
            }/${documentId}`,
            breadcrumbName: storeDocument?.doc_local_number || 'Show document',
          },
          {
            path: `/${Routes.stores}/${
              storeDocumentRoute[storeDocument?.doc_type]
            }/${documentId}/${Routes.payments}/${Routes.stores}`,
            breadcrumbName: 'Payments',
          },
          {
            path: `/${Routes.stores}/${
              storeDocumentRoute[storeDocument?.doc_type]
            }/${documentId}/${Routes.payments}/${Routes.stores}/${paymentId}`,
            breadcrumbName: ifElse(
              !!payment?.payment_number,
              payment?.payment_number,
              'Show Payment',
            ),
          },
        ]
      : employeeId
      ? [
          {
            path: `/${Routes.employees}`,
            breadcrumbName: 'Employees',
          },
          {
            path: `/${Routes.employees}/${employeeId}`,
            breadcrumbName: location?.state?.fullName || t('Show Employee'),
          },
          {
            path: `/${Routes.employees}/${employeeId}/${Routes.rewards}`,
            breadcrumbName: 'Salary and bonuses',
          },
          {
            path: `/${Routes.clients}/${clientId}/${Routes.payments}/${paymentId}`,
            breadcrumbName: ifElse(
              !!payment?.payment_number,
              payment?.payment_number,
              'Show Payment',
            ),
          },
        ]
      : clientId
      ? [
          {
            path: `/${Routes.clients}`,
            breadcrumbName: 'Clients',
          },
          {
            path: `/${Routes.clients}/${clientId}`,
            breadcrumbName:
              payment?.payment_agent_object instanceof ClientModel
                ? payment?.payment_agent_object?.fullNameClient
                : 'Show Client',
          },
          {
            path: `/${Routes.clients}/${clientId}/${Routes.payments}/${Routes.clients}`,
            breadcrumbName: 'Cash flow',
          },
          {
            path: `/${Routes.clients}/${clientId}/${Routes.payments}/${paymentId}`,
            breadcrumbName: ifElse(
              !!payment?.payment_number,
              payment?.payment_number,
              'Show Payment',
            ),
          },
        ]
      : orderId
      ? [
          {
            path: `/${Routes.orders}`,
            breadcrumbName: 'Orders',
          },
          {
            path: `/${Routes.orders}/${orderId}/${Routes.schedules}/calendar/entry/${orderId}`,
            breadcrumbName: 'Show order',
          },
          {
            path: `/${Routes.orders}/${orderId}/${Routes.schedules}/calendar/entry/${orderId}/${Routes.payments}`,
            breadcrumbName:
              location?.state?.type === PaymentScheduleOperationType.Out
                ? 'tPayments'
                : location?.state?.type === PaymentScheduleOperationType.In
                ? 'Receipts'
                : 'Payments',
            state: {type: location?.state?.type},
          },
          {
            path: `/${Routes.orders}/${orderId}/${Routes.schedules}/calendar/entry/${orderId}/${Routes.payments}/${Routes.payments}/${paymentId}`,
            breadcrumbName: ifElse(
              !!payment?.payment_number,
              payment?.payment_number,
              'Show Payment',
            ),
          },
        ]
      : [
          {
            path: `/${Routes.payments}`,
            breadcrumbName: 'Cash flow',
          },
          {
            path: `/${Routes.payments}/${paymentId}`,
            breadcrumbName: ifElse(
              !!payment?.payment_number,
              payment?.payment_number,
              'Show Payment',
            ),
          },
        ],
  );

  const navigateGoBack = useCallback(() => {
    navigate(location?.state?.from || `/${Routes.app}/${Routes.payments}`, {
      state: {
        type: location?.state?.type,
        page: location?.state?.page,
      },
    });
  }, [
    location?.state?.from,
    location?.state?.page,
    location?.state?.type,
    navigate,
  ]);

  return (
    <PaymentTabPanel payment={payment}>
      {({tabs}) => (
        <OverviewLayout
          aclItem={RoutesAcl[Routes.payments]}
          headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
          disabled={!manage}
          headerRight={
            <UpdatePaymentMenuButton
              disabled={!manage}
              from={from}
              companyUuid={defaultCompanyUuid}
              payment={PaymentMapper.toPaymentFormDTO(payment!, {
                editMode: true,
              })}
              onSuccess={handleUpdatePayment}
              handleUpdatePaymentCashBox={handleUpdatePaymentCashBox}
              status={payment?.payment_status!}
            />
          }
          headerTitle={t('Payment')}
          loading={loading}
          data={null}
          routes={routes}
          tabs={tabs}
          header={
            <>
              <Row>
                <StyledSpace direction="vertical" size="small">
                  <Col span={24}>
                    <StyledTitle
                      bold
                      style={{
                        fontSize: 25,
                      }}>{`№ ${payment?.payment_number}`}</StyledTitle>
                  </Col>
                  <Col span={24}>
                    <StyledTitle>
                      {toDateByFormat(
                        payment?.payment_date,
                        'DD.MM.YYYY HH:mm',
                      )}
                    </StyledTitle>
                  </Col>
                  <Col span={24}>
                    <StyledTitle
                      $color={
                        payment?.payment_type === OPERATION_TYPE.IN
                          ? theme.colors.success
                          : theme.colors.error
                      }>
                      {t(payment?.payment_type)}
                    </StyledTitle>
                  </Col>
                  {payment?.payment_comment ? (
                    <Col span={24}>
                      <StyledDescription>
                        {payment?.payment_comment}
                      </StyledDescription>
                    </Col>
                  ) : null}
                  {payment?.payment_has_fiscal_receipt ? (
                    <Col span={24}>
                      <StyledDescription>
                        <HiOutlineReceiptTax size={12} />
                        {payment?.payment_fiscal_receipt?.fiscal_date
                          ? toDateByFormat(
                              payment?.payment_fiscal_receipt?.fiscal_date,
                              'DD.MM.YYYY HH:mm',
                            )
                          : null}
                        {payment?.payment_fiscal_receipt?.fiscal_code
                          ? ` / ${payment?.payment_fiscal_receipt?.fiscal_code}`
                          : null}
                      </StyledDescription>
                    </Col>
                  ) : null}
                  <Col span={24}>
                    <DetailsView
                      colors={[
                        undefined,
                        undefined,
                        undefined,
                        payment?.payment_agent_object instanceof
                          EmployeeModel &&
                        payment?.payment_agent_object?.status ===
                          EmployeeStatuses.DELETED
                          ? theme.colors.lightError
                          : undefined,
                      ]}
                      titles={['Cash box', 'Order', 'Client', 'Employee']}>
                      {payment?.company_cashbox?.uuid ? (
                        <DetailsItemView<CashBoxModel>
                          item={payment?.company_cashbox}
                          fields={{
                            box_title: {
                              link: 'Go to payment list page',
                              icon: (
                                <Image
                                  className="cash-box-icon"
                                  src="/tax.png"
                                  alt="tax"
                                  width="15px"
                                  preview={false}
                                />
                              ),
                              path:
                                location?.state?.from ||
                                `/${Routes.app}/${Routes.payments}`,
                              state: {
                                type: location?.state?.type,
                                cashBoxUuid: payment?.company_cashbox?.uuid,
                                date: payment?.payment_date,
                              },
                            },
                            box_type: {
                              description: (
                                <StyledDescription>
                                  {payment?.company_cashbox?.box_type ===
                                  CashBoxType.Cash
                                    ? t('tCash')
                                    : t('Cashless')}
                                </StyledDescription>
                              ),
                            },
                          }}
                        />
                      ) : null}
                      {payment?.payment_trigger_object instanceof
                        ScheduleCalendarModel &&
                      payment?.payment_trigger_object?.uuid ? (
                        <OrderDetailsView
                          schedulePage
                          schedule={payment?.payment_trigger_object}
                        />
                      ) : null}

                      {payment?.payment_agent_object instanceof ClientModel &&
                      payment?.payment_agent_object?.uuid ? (
                        <ClientDetailsView
                          client={payment?.payment_agent_object}
                        />
                      ) : null}

                      {payment?.payment_agent_object instanceof EmployeeModel &&
                      payment?.payment_agent_object?.uuid ? (
                        <EmployeeDetailsView
                          employee={payment?.payment_agent_object}
                        />
                      ) : null}
                    </DetailsView>
                  </Col>
                </StyledSpace>
              </Row>
            </>
          }
          outsideFooterContent={<PaymentBottomView payment={payment} />}
        />
      )}
    </PaymentTabPanel>
  );
}
