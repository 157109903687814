import {List} from 'immutable';
import {PRICE_MARGIN_LIST} from '../constants';
import {compose, isEqualByUuid, not} from '../../helpers';
import {ApiError, REDUX_STATUS, APP_STATE} from '../types';
import {
  PriceMarginListModel,
  PriceMarginMapper,
  PriceMarginModel,
} from '../../struture';

interface IPriceMarginListState {
  priceMarginList: PriceMarginListModel | null;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
}

interface SetActionList {
  type: PRICE_MARGIN_LIST.SET_PRICE_MARGIN_LIST;
  priceMarginList: PriceMarginListModel;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface AddActionToList {
  type: PRICE_MARGIN_LIST.ADD_PRICE_MARGIN;
  priceMargin: PriceMarginModel;
}

interface UpdatePriceMarginFromList {
  type: PRICE_MARGIN_LIST.UPDATE_PRICE_MARGIN;
  priceMargin: PriceMarginModel;
}

interface DeleteActionFromList {
  type: PRICE_MARGIN_LIST.DELETE_PRICE_MARGIN;
  priceMarginUuid: string;
}

interface LoadingActionInList {
  type: PRICE_MARGIN_LIST.LOADING_PRICE_MARGIN_LIST;
}

interface ErrorActionInList extends Pick<IPriceMarginListState, 'error'> {
  type: PRICE_MARGIN_LIST.ERROR_PRICE_MARGIN_LIST;
}

interface Handlers {
  [PRICE_MARGIN_LIST.SET_PRICE_MARGIN_LIST]: (
    state: IPriceMarginListState,
    action: SetActionList,
  ) => IPriceMarginListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IPriceMarginListState,
    action: SetInitialStateAction,
  ) => IPriceMarginListState;

  [PRICE_MARGIN_LIST.LOAD_MORE_PRICE_MARGIN_LIST]: (
    state: IPriceMarginListState,
    action: SetActionList,
  ) => IPriceMarginListState;

  [PRICE_MARGIN_LIST.ADD_PRICE_MARGIN]: (
    state: IPriceMarginListState,
    action: AddActionToList,
  ) => IPriceMarginListState;

  [PRICE_MARGIN_LIST.UPDATE_PRICE_MARGIN]: (
    state: IPriceMarginListState,
    action: UpdatePriceMarginFromList,
  ) => IPriceMarginListState;

  [PRICE_MARGIN_LIST.DELETE_PRICE_MARGIN]: (
    state: IPriceMarginListState,
    action: DeleteActionFromList,
  ) => IPriceMarginListState;

  [PRICE_MARGIN_LIST.ERROR_PRICE_MARGIN_LIST]: (
    state: IPriceMarginListState,
    value: ErrorActionInList,
  ) => IPriceMarginListState;

  [PRICE_MARGIN_LIST.LOADING_PRICE_MARGIN_LIST]: (
    state: IPriceMarginListState,
    value?: LoadingActionInList,
  ) => IPriceMarginListState;
  DEFAULT: (state: IPriceMarginListState) => IPriceMarginListState;
}

const initState: IPriceMarginListState = {
  priceMarginList: null,
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
};

const handlers: Handlers = {
  [PRICE_MARGIN_LIST.SET_PRICE_MARGIN_LIST]: (state, {priceMarginList}) => {
    return {
      ...state,
      ...{
        priceMarginList: priceMarginList || null,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [PRICE_MARGIN_LIST.LOAD_MORE_PRICE_MARGIN_LIST]: (
    state,
    {priceMarginList},
  ) => {
    return {
      ...state,
      ...{
        priceMarginList:
          List.isList(state.priceMarginList?.prices) &&
          List.isList(priceMarginList?.prices)
            ? state.priceMarginList.update('prices', (prices) =>
                prices.merge(priceMarginList.prices),
              )
            : state.priceMarginList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [PRICE_MARGIN_LIST.ADD_PRICE_MARGIN]: (state, {priceMargin}) => {
    return {
      ...state,
      ...{
        priceMarginList: List.isList(state.priceMarginList?.prices)
          ? state.priceMarginList
              .update('prices', (prices) => prices.unshift(priceMargin))
              .update('total', (t = 0) => t + 1)
          : PriceMarginMapper.toPriceMarginListModel(
              [PriceMarginMapper.toPriceMarginDTO(priceMargin)],
              1,
            ),
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [PRICE_MARGIN_LIST.UPDATE_PRICE_MARGIN]: (
    state: IPriceMarginListState,
    {priceMargin}: UpdatePriceMarginFromList,
  ) => {
    return {
      ...state,
      ...{
        priceMarginList: List.isList(state.priceMarginList?.prices)
          ? state.priceMarginList.update('prices', (prices) =>
              prices.map((statePriceMargin) => {
                if (statePriceMargin.uuid === priceMargin?.uuid) {
                  return statePriceMargin.merge(priceMargin);
                }
                return statePriceMargin;
              }),
            )
          : PriceMarginMapper.toPriceMarginListModel(
              [PriceMarginMapper.toPriceMarginDTO(priceMargin)],
              1,
            ),
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [PRICE_MARGIN_LIST.DELETE_PRICE_MARGIN]: (state, {priceMarginUuid}) => {
    return {
      ...state,
      ...{
        priceMarginList: List.isList(state.priceMarginList?.prices)
          ? state.priceMarginList
              .update('prices', (prices) =>
                prices.filter(compose(not, isEqualByUuid(priceMarginUuid))),
              )
              .update('total', (t = 0) => t - 1)
          : null,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [PRICE_MARGIN_LIST.ERROR_PRICE_MARGIN_LIST]: (
    state: IPriceMarginListState,
    {error}: ErrorActionInList,
  ) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [PRICE_MARGIN_LIST.LOADING_PRICE_MARGIN_LIST]: (
    state: IPriceMarginListState,
  ) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IPriceMarginListState) => state,
};

export default function PriceMarginList(
  state: any = initState,
  action: any,
): IPriceMarginListState {
  const handler =
    handlers[action.type as PRICE_MARGIN_LIST] || handlers.DEFAULT;
  return handler(state, action);
}
