import {STORE_DOCUMENT_LIST} from '../constants';
import {
  StoreDocumentListModel,
  StoreDocumentMappedType,
  StoreDocumentModel,
  StoreDocumentStatsDTO,
  StoreDocumentType,
} from '../../struture';
import {ApiError} from '../../services/types';
import {Dispatch} from 'redux';

export interface ISetStoreDocumentListParam {
  type: STORE_DOCUMENT_LIST;
  storeDocumentList: StoreDocumentListModel;
  documentType: StoreDocumentType | StoreDocumentMappedType;
}

export interface IAddStoreDocumentToListParam {
  type: STORE_DOCUMENT_LIST;
  storeDocument: StoreDocumentModel;
}

export interface IUpdateStoreDocumentStatsFromListParam {
  type: STORE_DOCUMENT_LIST;
  stats: StoreDocumentStatsDTO;
}

export interface IUpdateStoreDocumentFromListParam {
  type: STORE_DOCUMENT_LIST;
  storeDocument: StoreDocumentModel;
}

export interface IDeleteStoreDocumentFromListParam {
  type: STORE_DOCUMENT_LIST;
  storeDocumentUuid: string;
}

export interface IErrorStoreDocumentListParam {
  type: STORE_DOCUMENT_LIST;
  error: ApiError | undefined;
}

export function setStoreDocumentList(
  storeDocumentList: StoreDocumentListModel,
  documentType: StoreDocumentType | StoreDocumentMappedType,
): (dispatch: Dispatch) => Promise<ISetStoreDocumentListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STORE_DOCUMENT_LIST.SET_STORE_DOCUMENT_LIST,
      storeDocumentList,
      documentType,
    });
  };
}

export function loadMoreStoreDocumentList(
  storeDocumentList: StoreDocumentListModel,
  documentType: StoreDocumentType | StoreDocumentMappedType,
): (dispatch: Dispatch) => Promise<ISetStoreDocumentListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STORE_DOCUMENT_LIST.LOAD_MORE_STORE_DOCUMENT_LIST,
      storeDocumentList,
      documentType,
    });
  };
}

export function addStoreDocumentToList(
  storeDocument: StoreDocumentModel,
  documentType: StoreDocumentType | StoreDocumentMappedType,
): (dispatch: Dispatch) => Promise<IAddStoreDocumentToListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STORE_DOCUMENT_LIST.ADD_STORE_DOCUMENT,
      storeDocument,
      documentType,
    });
  };
}

export function updateStoreDocumentFromList(
  storeDocument: StoreDocumentModel,
  documentType: StoreDocumentType | StoreDocumentMappedType,
): (dispatch: Dispatch) => Promise<IUpdateStoreDocumentFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STORE_DOCUMENT_LIST.UPDATE_STORE_DOCUMENT,
      storeDocument,
      documentType,
    });
  };
}

export function deleteStoreDocumentFromList(
  storeDocumentUuid: string,
  documentType: StoreDocumentType | StoreDocumentMappedType,
): (dispatch: Dispatch) => Promise<IDeleteStoreDocumentFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STORE_DOCUMENT_LIST.DELETE_STORE_DOCUMENT,
      storeDocumentUuid,
      documentType,
    });
  };
}

export function updateStoreDocumentStatsFromList(
  stats: StoreDocumentStatsDTO,
  documentType: StoreDocumentType,
): (dispatch: Dispatch) => Promise<IUpdateStoreDocumentStatsFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STORE_DOCUMENT_LIST.UPDATE_STORE_DOCUMENT_STATS,
      stats,
      documentType,
    });
  };
}

export function errorStoreDocumentInList(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorStoreDocumentListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: STORE_DOCUMENT_LIST.ERROR_STORE_DOCUMENT_LIST,
      error,
    });
  };
}
