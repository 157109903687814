import React from 'react';
import {ProductModel, ProductStatus} from '../../../struture';
import styled, {useTheme, css} from 'styled-components';
import {StyledTitle, StyledDescription} from '../../../components/lib/Styled';
import {head} from '../../../services/helpers';
import {useTranslation} from 'react-i18next';
import {useStoredCompanies} from '../../../hooks';
import {LoadingOutlined} from '@ant-design/icons';
import {TbLockCancel} from 'react-icons/tb';

export interface IProductTableViewProps {
  product: ProductModel | null;
  price: string;
  selectedProductUuid?: string[];
  loadingProductUuid?: string;
  withIndent?: boolean;
}

const StyledContainer = styled.div<{
  $selected?: boolean;
  $withIndent?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  ${({$selected}) =>
    $selected &&
    css`
      background-color: rgba(93, 146, 114, 0.2);
    `}

  ${({$withIndent}) =>
    $withIndent &&
    css`
      padding: 10px !important;
    `}
`;

const StyledSelectedContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const Description = styled(StyledDescription)`
  align-self: flex-end;
`;

export function ProductTableView({
  product,
  price,
  selectedProductUuid,
  loadingProductUuid,
  withIndent,
}: IProductTableViewProps): React.JSX.Element {
  const {t} = useTranslation();
  const {defaultCompany} = useStoredCompanies();
  const theme: any = useTheme();

  const store = head(product?.product_balances || [])?.company_store
    ?.store_name;

  const isSelected = selectedProductUuid?.includes(product?.uuid || '');
  const isLoading = loadingProductUuid === product?.uuid;

  return (
    <StyledContainer $selected={isSelected} $withIndent={withIndent}>
      <StyledTitleWrapper>
        {product?.product_status === ProductStatus.ENABLE ? null : (
          <TbLockCancel fontSize={22} color={theme.colors.error} />
        )}
        <StyledTitle fontSize={22}>{product?.product_title}</StyledTitle>
      </StyledTitleWrapper>

      <StyledTitleWrapper>
        {product?.category?.title ? (
          <StyledDescription fontSize={17}>{`${t('Category')}: ${
            product?.category?.title
          }`}</StyledDescription>
        ) : null}
      </StyledTitleWrapper>

      <Description fontSize={23} $color={theme.colors.success}>{` ${
        defaultCompany?.currency_symbol || ''
      }${price}`}</Description>

      {isSelected && !isLoading ? (
        <StyledSelectedContainer>
          <StyledDescription $color={theme.colors.success}>
            {t('selected').toLowerCase()}
          </StyledDescription>
        </StyledSelectedContainer>
      ) : null}

      {isLoading ? (
        <StyledSelectedContainer>
          <LoadingOutlined />
        </StyledSelectedContainer>
      ) : null}
    </StyledContainer>
  );
}
