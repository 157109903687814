import React from 'react';
import {
  ProductModel,
  StoreDocumentItemFormDTO,
  StoreDocumentItemSaleType,
} from '../../../../struture';
import {Card, Badge} from 'antd';
import styled, {css, useTheme} from 'styled-components';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import {useStoredCompanies} from '../../../../hooks';
import {useStoreSale} from '../Managers';

const {Meta} = Card;

export interface StoreSaleProductCardProps {
  product: ProductModel;
  documentItem: StoreDocumentItemFormDTO | undefined;
  onClick: (product: ProductModel) => void;
  selected: boolean;
  disabled: boolean;
}

const StyledImg = styled.img`
  max-height: 160px;
  object-fit: contain;
`;

const StyledCard = styled(Card)<{selected: boolean; disabled: boolean}>`
  & .ant-card-cover {
    padding-top: 5px;
  }
`;

const iconStyle = css`
  position: absolute;
  top: 5px;
  left: 5px;

  font-size: 22px;
`;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  ${iconStyle};
  color: ${({theme}) => theme.colors.primary};
`;

const StyledWarningOutlined = styled(WarningOutlined)`
  ${iconStyle};
  color: ${({theme}) => theme.colors.warning};
`;

const StyledCloseCircleOutlined = styled(CloseCircleOutlined)`
  ${iconStyle};
  color: ${({theme}) => theme.colors.error};
`;

const StyledCheckCircleOutlined = styled(CheckCircleOutlined)`
  ${iconStyle};
  color: ${({theme}) => theme.colors.success};
`;

const StyledBadge = styled(Badge)`
  display: flex;

  width: auto;
  height: 100%;
`;

const StyledMeta = styled(Meta)`
  & .ant-card-meta-title {
    text-align: right;
    color: ${({theme}) => theme.colors.success};
    font-size: 20px;
  }

  & .ant-card-meta-description {
    color: ${({theme}) => theme.colors.primary};
  }
`;

export function StoreSaleProductCard({
  product,
  documentItem,
  onClick,
  selected,
  disabled,
  ...rest
}: StoreSaleProductCardProps): React.JSX.Element {
  const theme: any = useTheme();
  const {defaultCompany} = useStoredCompanies();

  const {getProductPriceMargin} = useStoreSale();

  return (
    <StyledBadge
      count={documentItem?.product_amount}
      color={theme.colors.success}>
      <StyledCard
        disabled={disabled}
        selected={selected}
        onClick={disabled ? undefined : () => onClick(product)}
        hoverable={!disabled}
        cover={
          <StyledImg
            alt={product?.product_title}
            src={product?.product_photo || '/images-empty.png'}
            {...rest}
          />
        }>
        {selected ? <StyledCheckCircleOutlined /> : null}
        <StyledMeta
          description={product?.product_title}
          title={`${
            defaultCompany?.currency_symbol || ''
          } ${getProductPriceMargin(product)}`}
        />
      </StyledCard>
    </StyledBadge>
  );
}
