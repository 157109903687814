import React from 'react';
import {Upload, Button, Tooltip} from 'antd';
import {LoadingOutlined, UploadOutlined} from '@ant-design/icons';
import {UploadProps} from 'antd/lib/upload';
import {withTranslation, WithTranslation} from 'react-i18next';

export default withTranslation()(FileUploaderButton);

export interface IFileUploaderButtonProps extends UploadProps, WithTranslation {
  title?: string;
  tooltip?: string;
  loading?: boolean;
  disabledButton?: boolean;
  hiddenButton?: boolean;
}

function FileUploaderButton({
  title = '',
  tooltip = '',
  loading,
  disabledButton = false,
  t,
  hiddenButton,
  className,
  ...rest
}: IFileUploaderButtonProps): JSX.Element {
  const defaultProps = {
    name: 'file',
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: (percent: any) => `${parseFloat(percent.toFixed(2))}%`,
    },
    onChange({file, fileList}: any) {
      // if (file.status !== 'uploading') {
      // }
    },
  };

  return (
    <Upload {...defaultProps} {...rest}>
      {hiddenButton ? null : (
        <Tooltip title={tooltip}>
          <Button
            className={className}
            size="small"
            type="primary"
            icon={loading ? <LoadingOutlined /> : <UploadOutlined />}
            disabled={disabledButton}>
            {title}
          </Button>
        </Tooltip>
      )}
    </Upload>
  );
}
