import {Record, List} from 'immutable';
import {ListModel, ListProps} from './List';
import WorkSchedule, {WorkScheduleProps} from './WorkSchedule';

export interface WorkSchedulesListProps extends ListProps {
  schedules: WorkScheduleProps[];
}

/**
 * @class WorkSchedulesList
 * @classdesc Work schedules list model
 */
export default class WorkSchedulesList extends Record({
  schedules: List(),
  ...ListModel.toJS(),
}) {
  /**
   * @constructor
   * @param {{schedules:[]}} param0
   */
  constructor({schedules = [], ...props}: WorkSchedulesListProps) {
    super({
      schedules: List(schedules.map((schedule) => new WorkSchedule(schedule))),
      ...props,
    });
  }
}
