export enum PRODUCT_LEFTOVERS_LIST {
  SET_PRODUCT_LEFTOVERS_LIST = 'set_product_leftovers_list',
  SET_PRODUCT_LEFTOVERS_LIST_STATS = 'set_product_leftovers_list_stats',
  ADD_PRODUCT_LEFTOVERS_LIST_STATS = 'add_product_leftovers_list_stats',
  SUB_PRODUCT_LEFTOVERS_LIST_STATS = 'sub_product_leftovers_list_stats',
  LOADING_PRODUCT_LEFTOVERS_LIST = 'loading_product_leftovers_list',
  ERROR_PRODUCT_LEFTOVERS_LIST = 'error_product_leftovers_list',
  LOAD_MORE_PRODUCT_LEFTOVERS_LIST = 'load_more_product_leftovers_list',
  ADD_PRODUCT_LEFTOVERS = 'add_product_leftovers',
  UPDATE_PRODUCT_LEFTOVERS = 'update_product_leftovers',
  DELETE_PRODUCT_LEFTOVERS = 'delete_product_leftovers',
}
