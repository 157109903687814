import {MailingModel, MailingType} from '../internal';
import {toDateByFormat, addToDate} from '../date';

export class MailingFormDTO {
  public uuid: string;

  public title: string;

  public body: string;

  public start_date: string;

  public clients: string[];

  public comment: string;

  public type: MailingType;

  public status: number;

  constructor(props?: MailingModel) {
    this.uuid = props?.uuid || '';

    this.title = props?.title || '';

    this.body = props?.title || '';

    this.start_date = props?.start_date
      ? toDateByFormat(props?.start_date, 'YYYY.MM.DD HH:mm')
      : toDateByFormat(addToDate(12, 'h', new Date()), 'YYYY.MM.DD HH:mm');

    this.clients = props?.clientListUuid || [];

    this.comment = props?.comment || '';

    this.type = (props as any)?.type || 'viber';

    this.status = props?.status || 1;
  }
}
