import * as React from 'react';
import {List} from 'immutable';
import {ApiError, IEntityProps, IListSearchProps} from '../services/types';
import {
  ClientAbonementListDTO,
  ClientAbonementMapper,
  ClientAbonementModel,
  ClientAbonementListModel,
  AbonementModel,
} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {getTariffModelClientsAbonementList} from '../services/api/company';
import {useDropdownAlert} from '../contex';
import {useTranslation} from 'react-i18next';

export interface IUseTariffModelClientsAbonementListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  abonementUuid: string;
}

export interface IUseTariffModelClientsAbonementListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<ClientAbonementModel> | null;
  refresh: (
    value: Partial<IUseTariffModelClientsAbonementListProps>,
  ) => Promise<ClientAbonementListModel<unknown> | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  abonementUuid: string;
}

export function useTariffModelClientsAbonementList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    abonementUuid,
  }: IUseTariffModelClientsAbonementListProps = {} as IUseTariffModelClientsAbonementListProps,
): IUseTariffModelClientsAbonementListReturnType {
  const {cancellablePromise} = useCancellablePromise();
  const {alert} = useDropdownAlert();
  const {t} = useTranslation();

  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    abonementUuid: listUuid,
    handleUpdate,
  } = useStateReducer<
    Omit<IUseTariffModelClientsAbonementListReturnType, 'refresh'>
  >({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    abonementUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      abonementUuid = listUuid,
    }: Partial<IUseTariffModelClientsAbonementListProps> = {}): Promise<ClientAbonementListModel<unknown> | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          abonementUuid,
        });

        const {abonements, company_abonement, total} = await cancellablePromise<
          ClientAbonementListDTO<AbonementModel>
        >(
          getTariffModelClientsAbonementList({
            limit,
            offset,
            keywords,
            abonementUuid,
          }),
        );

        const abonementListModel =
          ClientAbonementMapper.toClientAbonementListModel<AbonementModel>(
            abonements,
            total,
            company_abonement,
          );

        handleUpdate({
          entityList: abonementListModel?.abonements,
          total: abonementListModel?.total,
          loading: false,
        });

        return abonementListModel;
      } catch (e: any) {
        alert(
          'error',
          t('Tariff model client abonements'),
          `${t(
            'An error occurred during get tariff model client abonement list',
          )} : ${e?.message}`,
        );

        handleUpdate({
          error,
          loading: false,
        });
      }
    },
    [
      listLimit,
      listOffset,
      listKeywords,
      listUuid,
      handleUpdate,
      cancellablePromise,
      alert,
      t,
      error,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && abonementUuid) {
      (async () => {
        await refresh({abonementUuid});
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, abonementUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    abonementUuid: listUuid,
  };
}
