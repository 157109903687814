import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {
  CommentField,
  DetailsView,
  HeaderLeft,
} from '../../../../components/lib/DataDisplay';
import {
  useStateStoreDocumentItemList,
  useStopLoading,
  useStoredCompanies,
  useStoredStoreDocument,
  useStoredStoreDocumentList,
} from '../../../../hooks';
import {OverviewLayout} from '../../../../components/lib/Layout';
import {StoreMovingTabPanel} from '../Show';
import {
  StoreDetailsView,
  StoreDocExtraServicesView,
  StoreDocItemsView,
  StoreDocumentBottomView,
  StoreDocumentPageHeader,
} from '../../Show';
import {useBreadcrumb} from '../../../../components/lib/libV2/hooks';
import {Routes, RoutesAcl} from '../../../../services/types';
import {
  StoreDocumentMapper,
  StoreDocumentStatus,
  StoreDocumentType,
} from '../../../../struture';
import {useAcl} from '../../../../contex';
import {useLocation, useNavigate, useParams} from 'react-router';
import {Col, Row} from 'antd';
import {productStoreDocumentRoutes} from '../../../Products';
import StoreMovingMenuButton from '../Buttons/StoreMovingMenuButton';

export function StoreMovingPage() {
  const {documentId, productId} = useParams();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const location = useLocation();

  const {defaultCompanyUuid} = useStoredCompanies();

  const {store: storeAccess} = useAcl((acl) => acl);

  const {
    document,
    loading: storeLoading,
    error: storeError,
    handleUpdateStoreDocument,
    handleCreateOrUpdateStoreDocumentItem,
    handleDeleteStoreDocumentItem,
    handlePrintStoreDocument,
    handlePrintStoreDocumentCheck,
    handleUpdateStoreDocumentComment,
  } = useStoredStoreDocument({
    documentUuid: documentId!,
    companyUuid: defaultCompanyUuid,
    documentType: StoreDocumentType.MOVE,
  });

  const {
    storeDocumentItemList,
    loading: documentItemsLoading,
    refresh: storeDocumentItemListRefresh,
    limit: storeDocumentItemListLimit,
    total: storeDocumentItemListTotal,
    page: storeDocumentItemListPage,
  } = useStateStoreDocumentItemList({
    documentUuid: documentId!,
    limit: 100,
  });

  const loading = useStopLoading({
    loading: storeLoading,
    error: storeError,
    message: 'An error occurred during store moving loading',
  });

  const {handleDeleteStoreDocuments: onDeleteStoreDocuments} =
    useStoredStoreDocumentList({
      companyUuid: defaultCompanyUuid,
      documentType: StoreDocumentType.MOVE,
      loadOnInit: false,
    });

  const navigateGoBack = useCallback(() => {
    navigate(
      location?.state?.goBack ||
        `/${Routes.app}/${Routes.stores}/${Routes.storeMoving}`,
    );
  }, [location?.state?.goBack, navigate]);

  const handleDeleteStoreDocuments = useCallback(async () => {
    await onDeleteStoreDocuments([documentId!], navigateGoBack);
  }, [navigateGoBack, onDeleteStoreDocuments, documentId]);

  const routes = useBreadcrumb(
    productId
      ? productStoreDocumentRoutes(productId, (startUrl: string) => ({
          path: `${startUrl}/${Routes.stores}/${Routes.storeMoving}/${documentId}`,
          breadcrumbName: document?.doc_local_number || 'Show document',
        }))
      : [
          {
            path: `/${Routes.stores}/${Routes.storeMoving}`,
            breadcrumbName: 'Bill of lading for movements',
          },
          {
            path: `/${Routes.stores}/${Routes.storePosting}/${documentId}`,
            breadcrumbName: document?.doc_local_number || 'Show moving',
          },
        ],
  );

  return (
    <StoreMovingTabPanel document={document}>
      {({tabs}) => (
        <OverviewLayout
          aclItem={RoutesAcl[Routes.stores]}
          headerTitle={t('Bill of lading for movement')}
          headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
          headerRight={
            <StoreMovingMenuButton
              fromList={false}
              document={StoreDocumentMapper.toStoreDocumentFormDTO(document!, {
                editMode: true,
              })}
              onSuccess={handleUpdateStoreDocument}
              handleDeleteStoreDocuments={handleDeleteStoreDocuments}
            />
          }
          underHeaderContent={
            document?.uuid ? (
              <StoreDocumentPageHeader document={document} />
            ) : null
          }
          header={
            <Row gutter={10}>
              <Col span={24}>
                <DetailsView titles={['From store', 'In store']}>
                  {document?.companyStoreModel?.uuid ? (
                    <StoreDetailsView store={document?.companyStoreModel} />
                  ) : null}
                  {document?.companyDestinationStoreModel?.uuid ? (
                    <StoreDetailsView
                      store={document?.companyDestinationStoreModel}
                    />
                  ) : null}
                </DetailsView>
              </Col>
              <Col span={24}>
                <CommentField
                  comment={document?.doc_comment || t('Please enter note')}
                  handleUpdateComment={handleUpdateStoreDocumentComment}
                  disabled={!storeAccess?.manage}
                />
              </Col>
              <Col span={24}>
                <StoreDocItemsView
                  document={document!}
                  handleCreateOrUpdateStoreDocumentItem={
                    handleCreateOrUpdateStoreDocumentItem
                  }
                  loading={documentItemsLoading}
                  handleDeleteStoreDocumentItem={handleDeleteStoreDocumentItem}
                  storeDocumentItemList={storeDocumentItemList}
                  storeDocumentItemListRefresh={storeDocumentItemListRefresh}
                  storeDocumentItemListLimit={storeDocumentItemListLimit}
                  storeDocumentItemListTotal={storeDocumentItemListTotal}
                  storeDocumentItemListPage={storeDocumentItemListPage}
                />
              </Col>
              {Array.isArray(document?.doc_extra_services) ? (
                <Col span={24}>
                  <StoreDocExtraServicesView document={document!} />
                </Col>
              ) : null}
            </Row>
          }
          outsideFooterContent={
            <StoreDocumentBottomView
              document={document}
              handlePrintStoreDocument={handlePrintStoreDocument}
              handlePaymentStoreDocument={async () => {
                if (document) {
                  const updateDocument = document.update(
                    'doc_status_text',
                    () => StoreDocumentStatus.ACTIVE,
                  );

                  await handleUpdateStoreDocument(
                    StoreDocumentMapper.toStoreDocumentFormDTO(updateDocument, {
                      final: true,
                    }),
                  );
                }
              }}
              handlePrintStoreDocumentCheck={handlePrintStoreDocumentCheck}
            />
          }
          disabled={!storeAccess?.manage}
          editTooltip={t('Edit moving')}
          loading={loading}
          data={null}
          routes={routes}
          tabs={tabs}
        />
      )}
    </StoreMovingTabPanel>
  );
}
