import {Record} from 'immutable';

/**
 * @class NotificationsSetting
 * @classdesc NotificationsSetting model.
 * https://github.com/severalnines/bar-user-profile-api#NotificationSettings
 * @property {boolean} sendEventScheduleCreated
 * @property {boolean} sendEventScheduleRemoved
 * @property {boolean} sendEventSchedulePaused
 * @property {boolean} sendEventScheduleResumed
 * @property {boolean} sendEventAgentInstalled
 * @property {boolean} sendEventAgentRemoved
 * @property {boolean} sendEventAgentErrored
 * @property {boolean} sendEventAgentStarted
 * @property {boolean} sendEventAgentStopped
 */
export default class NotificationsSetting extends Record({
  sendEventScheduleCreated: null,
  sendEventScheduleRemoved: null,
  sendEventSchedulePaused: null,
  sendEventScheduleResumed: null,
  sendEventAgentInstalled: null,
  sendEventAgentRemoved: null,
  sendEventAgentErrored: null,
  sendEventAgentStarted: null,
  sendEventAgentStopped: null,
}) {}
