import {List, Record} from 'immutable';
import {IListProps} from './List';
import {
  IProductDTOProps,
  PhotoDTO,
  PhotoModel,
  ProductMapper,
  ProductModel,
} from '../internal';
import {WithoutRecursion} from '../type';

export interface PhotoListModelProps extends IListProps {
  photos: (PhotoDTO | PhotoModel)[];
  trigger_object: IProductDTOProps;
}

export interface PhotoListModelReturnType extends IListProps {
  photos: List<PhotoModel>;
  trigger_object: ProductModel;
}

export class PhotoListModel extends Record<PhotoListModelReturnType>({
  photos: List(),
  total: 0,
  trigger_object: {} as ProductModel,
}) {
  constructor(
    {
      photos = [],
      trigger_object,
      ...props
    }: PhotoListModelProps = {} as PhotoListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: PhotoListModelReturnType = {
      ...props,

      trigger_object: ProductMapper.toProductModel(
        ProductMapper.toProductDTO(trigger_object),
      ),
      photos: List(
        photos.map((photo) =>
          photo instanceof PhotoModel
            ? photo
            : new PhotoModel(photo, withoutRecursion),
        ),
      ),
    };

    super(options);
  }
}
