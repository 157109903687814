import {Record} from 'immutable';
import Services, {ServicesProps} from './Services';
import {ILiteralObj} from '../types';

export interface IClientSubscriptionableProps extends ILiteralObj {
  uuid: string;
  title: string;
  description: string;
  weekdays: string;
  duration: number;
  max_attenders: number;
  price: number;
  status: number;
  start_date: string;
  end_date: string;
  service: ServicesProps;
}

export interface IClientSubscriptionableReturnType
  extends Omit<IClientSubscriptionableProps, 'service'> {
  service: Services;
}

/**
 * @class Client
 * @desc Клієнт
 */
export default class ClientSubscriptionable extends Record<IClientSubscriptionableReturnType>(
  {
    uuid: '',
    title: '',
    description: '',
    weekdays: '',
    duration: 0,
    max_attenders: 0,
    price: 0,
    status: 0,
    start_date: '',
    end_date: '',
    service: {} as any,
  },
) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(
    {
      service,
      ...rest
    }: IClientSubscriptionableProps = {} as IClientSubscriptionableProps,
  ) {
    const options: Partial<IClientSubscriptionableReturnType> = {};

    if (service) {
      options.service = new Services(service);
    }

    super({...rest, ...options});
  }
}
