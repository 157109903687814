import React from 'react';
import {useTranslation} from 'react-i18next';
import {IConfirmActionButtonProps} from '../../../components/lib/General';
import {TextFormat} from '../../../components/lib/Format';
import {len} from '../../../services/helpers';
import {useDelete} from '../../../components/lib/libV2/hooks';
import {ClientVisitModel} from '../../../struture';
import {DeleteButton} from '../../../components/lib/DataDisplay';

export interface IClientVisitsDeleteButtonProps
  extends Omit<IConfirmActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: string[]) => Promise<void>;
  onError?: () => void;
  visits: ClientVisitModel[];
}

export default function ClientClientVisitsDeleteButton({
  children,
  visits,
  onSuccess,
  onError,
  title,
  ...rest
}: IClientVisitsDeleteButtonProps): JSX.Element {
  const {t} = useTranslation();

  const {handleDelete, loading} = useDelete({
    onSuccess,
    entity: visits,
    notifyTitle: 'Client visits',
  });

  return (
    <DeleteButton
      title={title}
      tooltipTitle={t('Delete client visit')}
      confirmTitle={
        <TextFormat breakWord>
          {t(`Are you sure you want to delete `)}
          <b>{`${len(visits)} ${
            len(visits) ? t('visit').toLowerCase() : t('visits').toLowerCase()
          }`}</b>
        </TextFormat>
      }
      onConfirm={handleDelete}
      loading={loading}
      cancelButtonText={t('Cancel')}
      {...rest}
    />
  );
}
