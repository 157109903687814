import React from 'react';
import {DetailsView} from '../../../components/lib/DataDisplay';
import {StyledTitle, StyledDescription} from '../../../components/lib/Styled';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useStoredCompanies} from '../../../hooks';
import {IPaymentScheduleListStats} from '../../../services/api/orders';
import {isThereContent, correctPrice} from '../../../services/helpers';
export interface IPaymentListStatsViewProps {
  stats: IPaymentScheduleListStats | null;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  padding-bottom: 10px;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
`;

const Title = styled(StyledTitle)<{$width?: number}>`
  min-width: 100px;
`;

const Description = styled(StyledDescription)`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  margin-right: 10px;
`;

const StyledDetailsView = styled(DetailsView)`
  box-shadow: none;
  border-color: #d9d9d9;
  text-align: right;
  margin-bottom: 40px;
`;

export function PaymentListStatsView({
  stats,
}: IPaymentListStatsViewProps): JSX.Element {
  const {t} = useTranslation();
  const {defaultCompany} = useStoredCompanies();

  return (
    <>
      {isThereContent(stats) ? (
        <StyledDetailsView titles={['Operations']}>
          <StyledContainer>
            <StyledTitleContainer>
              <Description fontSize={15} bold>{`${t('Total receipts')} ${
                defaultCompany?.currency_symbol
              }:`}</Description>
              <Title fontSize={15} bold>
                {correctPrice(stats?.totalInSum || 0)}
              </Title>
            </StyledTitleContainer>

            <StyledTitleContainer>
              <Description fontSize={15} bold>{`${t('Total payouts')} ${
                defaultCompany?.currency_symbol
              }:`}</Description>
              <Title fontSize={15} bold>
                {correctPrice(stats?.totalOutSum || 0)}
              </Title>
            </StyledTitleContainer>
          </StyledContainer>
        </StyledDetailsView>
      ) : null}
    </>
  );
}
