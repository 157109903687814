import {GroupPaymentDTO, PAYMENT_METHOD} from '../internal';
import {OPERATION_TYPE} from '../../types';
import {DateType, toMoment} from '../../helpers';

export class ClientPaymentFormDTO {
  public payment_date: DateType;

  public payment_sum: string;

  public payment_group_uuid: string | GroupPaymentDTO;

  public payment_comment: string;

  public payment_document: boolean;

  public payment_method: PAYMENT_METHOD;

  public payment_type: OPERATION_TYPE;

  constructor() {
    this.payment_date = ClientPaymentFormDTO.toDate(new Date() as any);
    this.payment_sum = '0.00';
    this.payment_comment = '';
    this.payment_method = PAYMENT_METHOD.Cash;
    this.payment_group_uuid = '';
    this.payment_type = OPERATION_TYPE.IN;
    this.payment_document = true;
  }

  private static toDate(birthday: string): any {
    return birthday ? toMoment(birthday) : '';
  }
}
