import {Record} from 'immutable';
import {
  ClientDTO,
  ClientModel,
  EmployeeDTO,
  EmployeeMapper,
  EmployeeModel,
  GroupPaymentMapper,
  GroupPaymentModel,
  PAYMENT_AGENT_TYPE,
  PAYMENT_METHOD,
  PAYMENT_STATUS,
  PAYMENT_DESTINATION,
  PaymentDTO,
  ScheduleCalendarDTO,
  ScheduleCalendarMapper,
  ScheduleCalendarModel,
  ClientMapper,
  CashBoxModel,
  CashBoxMapper,
} from '../internal';
import {OPERATION_TYPE} from '../../types';
import {WithoutRecursion} from '../type';

export interface IPaymentModelReturnType {
  uuid: string;
  payment_comment: string;
  payment_sum: string;
  payment_date: string;
  payment_status: PAYMENT_STATUS;
  created_by: number;
  created_at: string;
  updated_at: string;
  payment_number: string;
  payment_group: GroupPaymentModel;
  payment_type: OPERATION_TYPE;
  payment_method: PAYMENT_METHOD;
  payment_agent_type: PAYMENT_AGENT_TYPE;
  payment_trigger: PAYMENT_DESTINATION;
  payment_agent_object: ClientModel | EmployeeModel;
  cashier: EmployeeModel;
  company_cashbox: CashBoxModel;
  payment_trigger_object: ScheduleCalendarModel | '';
}

export class PaymentModel extends Record<IPaymentModelReturnType>({
  uuid: '',
  payment_comment: '',
  payment_type: OPERATION_TYPE.IN,
  payment_sum: '0.00',
  payment_date: '',
  payment_status: PAYMENT_STATUS.Draft,
  created_by: 0,
  created_at: '',
  updated_at: '',
  payment_group: {} as GroupPaymentModel,
  payment_number: '',
  payment_method: PAYMENT_METHOD.Account,
  payment_agent_type: PAYMENT_AGENT_TYPE.Client,
  payment_trigger: PAYMENT_DESTINATION.Operation,
  payment_agent_object: {} as ClientModel | EmployeeModel,
  cashier: {} as EmployeeModel,
  company_cashbox: {} as CashBoxModel,
  payment_trigger_object: '',
}) {
  constructor(
    props: PaymentDTO = {} as PaymentDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IPaymentModelReturnType = {
      ...props,
      payment_group: GroupPaymentMapper.toGroupPaymentModel(
        props?.payment_group,
      ),

      cashier: EmployeeMapper.toEmployeeModel(props?.cashier),

      company_cashbox:
        withoutRecursion.indexOf(WithoutRecursion.cashBox) !== -1
          ? (props?.company_cashbox as unknown as CashBoxModel)
          : CashBoxMapper.toCashBoxModel(props?.company_cashbox, [
              WithoutRecursion.payment,
              ...withoutRecursion,
            ]),

      payment_trigger_object:
        withoutRecursion.indexOf(WithoutRecursion.scheduleCalendar) !== -1
          ? (props?.payment_trigger_object as ScheduleCalendarModel)
          : props?.payment_trigger_object
          ? ScheduleCalendarMapper.toScheduleCalendarModel(
              props?.payment_trigger_object as ScheduleCalendarDTO,
              [WithoutRecursion.payment, ...withoutRecursion],
            )
          : '',
      payment_agent_object:
        withoutRecursion.indexOf(WithoutRecursion.scheduleCalendar) !== -1
          ? (props?.payment_agent_object as any)
          : props?.payment_agent_type === PAYMENT_AGENT_TYPE.Client
          ? ClientMapper.toClientModel(
              props?.payment_agent_object as ClientDTO,
              false,
              [WithoutRecursion.payment, ...withoutRecursion],
            )
          : EmployeeMapper.toEmployeeModel(
              props?.payment_agent_object as EmployeeDTO,
              [WithoutRecursion.payment, ...withoutRecursion],
            ),
    };
    super(options);
  }
}
