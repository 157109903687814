import {Record, List} from 'immutable';
import {IListProps} from './List';
import {StatusCategoryDTO, StatusCategoryModel} from '../internal';
import {WithoutRecursion} from '../type';

export interface IStatusCategoryListModelProps extends IListProps {
  categories: StatusCategoryDTO[];
}

export interface IStatusCategoryListModelReturnType extends IListProps {
  categories: List<StatusCategoryModel>;
}

export class StatusCategoryListModel extends Record<IStatusCategoryListModelReturnType>(
  {
    categories: List(),
    total: 0,
  },
) {
  constructor(
    {
      categories = [],
      total,
    }: IStatusCategoryListModelProps = {} as IStatusCategoryListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IStatusCategoryListModelReturnType = {
      categories: List(
        categories.map(
          (category) => new StatusCategoryModel(category, withoutRecursion),
        ),
      ),
      total,
    };

    super(options);
  }
}
