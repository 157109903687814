/**
 * @desc Повернення нового об'єкта з вказаною структурою
 * @param {String} color
 * */

export function genderTransformation(color) {
  return (item) => ({
    label: item.title,
    value: item.value,
    color,
  });
}

/**
 * @desc Повернення нового об'єкта з вказаною структурою
 * @param {String} color
 * */

export function monthTransformation(color) {
  return (item, index) => ({
    label: item.title,
    value: index + 1,
    color,
  });
}

/**
 * @desc Повернення нового об'єкта з вказаною структурою
 * @param {String} color
 * */
export function transformationsCountry(color) {
  return (country) => ({
    label: country.country_name,
    value: country.country_name,
    color,
  });
}

/**
 * @desc Повернення нового об'єкта з вказаною структурою
 * @param {Object} imagePosition
 * @param {Number} interval
 * @param {Number} phoneWidth
 * @return {Object}
 * */

export function deleteFromObject(imagePosition, interval, phoneWidth) {
  return Object.entries(imagePosition).reduce(
    (newObj, [key, value]: [any, any]) => {
      if (value.uuid !== imagePosition[interval - 1].uuid) {
        if (key > interval - 1) {
          newObj[key - 1] = {...value, ...{x: value.x - phoneWidth}};
        } else {
          newObj[key] = value;
        }
      }
      return newObj;
    },
    {},
  );
}
