import {apiGet, apiPost, apiPut, apiDelete} from '../core/api';
import {ListOptions, Template, TemplateList} from '../models';
import {ITemplateProps, ITemplateListProps, head, ifElse} from '../helpers';
import {contentApiUrl} from '../const';
import {ApiAnswer, IListSearchProps} from '../types';

export enum TEMPLATES_TYPE {
  SMS = 'sms',
  EMAIL = 'email',
  ALL = 'all',
}

export interface ITemplatesParams
  extends Pick<
    ITemplateProps,
    'template_body' | 'template_comment' | 'template_event' | 'template_title'
  > {
  template_type: TEMPLATES_TYPE;
  template_uuid?: string;
  companyUuid?: string;
}

export const TEMPLATES_INITIAL_PARAMS: ITemplatesParams = {
  template_type: TEMPLATES_TYPE.SMS,
  template_event: '',
  template_title: '',
  template_comment: '',
  template_body: '',
};

export const TEMPLATES_TYPE_LIST: (t: any) => {
  title: TEMPLATES_TYPE;
  uuid: TEMPLATES_TYPE;
}[] = (t) => [
  {title: t(TEMPLATES_TYPE.EMAIL), uuid: TEMPLATES_TYPE.EMAIL},
  {title: t(TEMPLATES_TYPE.SMS), uuid: TEMPLATES_TYPE.SMS},
  {title: t(TEMPLATES_TYPE.ALL), uuid: TEMPLATES_TYPE.ALL},
];

export const TEMPLATES_TYPE_LIST_FORM = (t: any) =>
  TEMPLATES_TYPE_LIST(t).filter(({uuid}) => uuid !== TEMPLATES_TYPE.ALL);

export interface IGetTemplateList extends ApiAnswer, ITemplateListProps {}

export interface IGetTemplateListProps extends IListSearchProps {
  companyUuid: string;
  type?: TEMPLATES_TYPE;
}

export async function getTemplateList({
  companyUuid,
  type,
  offset = 0,
  limit = 10,
  keywords,
}: IGetTemplateListProps): Promise<TemplateList> {
  const value = await apiGet<
    IListSearchProps & {type?: TEMPLATES_TYPE},
    IGetTemplateList
  >(`${contentApiUrl}/companies/${companyUuid}/templates`, {
    offset,
    type: !!type && type !== TEMPLATES_TYPE.ALL ? type : undefined,
    keywords,
    limit,
  });

  return new TemplateList(value as ITemplateListProps);
}

export interface ITemplateApi extends ApiAnswer {
  template: ITemplateProps;
}

export async function createTemplate({
  template_comment,
  template_title,
  template_type,
  template_event,
  template_body,
  companyUuid,
}: ITemplatesParams): Promise<Template> {
  const {template} = await apiPost<ITemplatesParams, ITemplateApi>(
    `${contentApiUrl}/companies/${companyUuid}/templates`,
    {
      template_comment,
      template_title,
      template_type,
      template_event,
      template_body,
    },
  );

  return new Template(template);
}

export async function updateTemplate({
  template_comment,
  template_title,
  template_type,
  template_event,
  template_body,
  template_uuid,
}: ITemplatesParams): Promise<Template> {
  const {template} = await apiPut<ITemplatesParams, ITemplateApi>(
    `${contentApiUrl}/templates/${template_uuid}`,
    {
      template_comment,
      template_title,
      template_type,
      template_event,
      template_body,
    },
  );

  return new Template(template);
}

export async function deleteTemplate(
  ids: string[] = [],
): Promise<ITemplateApi> {
  const template_uuid = head(ids);

  return await apiDelete<null, ITemplateApi>(
    `${contentApiUrl}/templates/${template_uuid}`,
  );
}
