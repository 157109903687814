import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {StoreDocumentFormDTO, StoreDocumentType} from '../../../../struture';
import {useModal} from '../../../../components/lib/libV2/hooks';
import {
  EditButton,
  IEditButtonProps,
  SuspenseEmpty,
} from '../../../../components/lib/DataDisplay';
import {IUseStateStoredDocumentReturnType} from '../../../../hooks';

const StoreReturnClientSideWindowLazy = React.lazy(
  () => import('../Show/StoreReturnClientSideWindow'),
);

export interface IStoreReturnClientUpdateButtonProps
  extends IEditButtonProps,
    Pick<IUseStateStoredDocumentReturnType, 'handlePaymentStoreDocument'> {
  children?: React.ReactNode;
  onSuccess: IUseStateStoredDocumentReturnType['handleUpdateStoreDocument'];
  onCancel?: () => void;
  document: StoreDocumentFormDTO;
  fromList: boolean;
  from?: StoreDocumentType;
}

export function StoreReturnClientUpdateButton({
  children,
  onSuccess,
  onCancel,
  loading,
  document,
  handlePaymentStoreDocument,
  fromList,
  from,
  ...rest
}: IStoreReturnClientUpdateButtonProps): React.JSX.Element {
  const {t} = useTranslation();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <EditButton
        tooltipTitle={t('Edit return to client')}
        onClick={handleOnInit}
        disabled={false}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <StoreReturnClientSideWindowLazy
          from={from}
          editMode
          document={document}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
          handlePaymentStoreDocument={handlePaymentStoreDocument}
          fromList={fromList}
        />
      </Suspense>
    </>
  );
}
