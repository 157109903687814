export const SRV_PERIOD_MINUTE = 'MINUTE';
export const SRV_PERIOD_HOUR = 'HOUR';
export const SRV_PERIOD_DAY = 'DAY';
export const SRV_PERIOD_WEEK = 'WEEK';
export const SRV_PERIOD_MONTH = 'MONTH';
export const SRV_PERIOD_YEAR = 'YEAR';
export const SRV_PERIOD_CUSTOM = 'CUSTOM';

export const SRV_PERSONALITY_INDIVIDUAL = 'INDIVIDUAL';
export const SRV_PERSONALITY_GROUP = 'GROUP';
