import * as React from 'react';
import {List} from 'immutable';
import {
  useCompanyList,
  IUseCompanyListProps,
  IUseCompanyListReturnType,
} from './useCompanyList';
import {CompanyModel, CompanyFormDTO} from '../struture';
import {useStateEntityList} from '../components/lib/libV2/hooks';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../contex';
import {useStoredCompanies} from './index';
import {useCallback, useState} from 'react';
import {
  CLEARED_SESSION_STORAGE_ITEMS,
  CLEARED_LOCAL_STORAGE_ITEMS,
} from '../services/const';

export interface IUseStateCompanyListProps extends IUseCompanyListProps {}

export interface IUseStateCompanyListReturnType
  extends Omit<IUseCompanyListReturnType, 'entityList'> {
  companies: List<CompanyModel> | null;
  defaultCompanyLoading: boolean;
  handleCreateCompany: (
    value: Partial<CompanyFormDTO>,
  ) => Promise<CompanyModel | void>;
  handleUpdateCompany: (
    value: CompanyFormDTO & {weekdays: string[]},
  ) => Promise<CompanyModel | void>;
  handleDeleteCompanies: (id: string) => Promise<void>;
  handleSearchCompanies: (value: string) => Promise<void>;
  handelSetDefaultCompany: (value: string) => Promise<void>;
  handleUpdate: (employee: CompanyModel) => void;
}

export function useStateCompanyList(
  {...rest}: IUseStateCompanyListProps = {} as IUseStateCompanyListProps,
): IUseStateCompanyListReturnType {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const [defaultCompanyLoading, setDefaultCompanyLoading] = useState(false);

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: clientLoading,
    total: clientListTotal,
    ...clientsParams
  } = useCompanyList({
    ...rest,
  });

  const {createCompany, updateCompany, deleteCompany, setDefaultCompany} =
    useStoredCompanies();

  const {
    entityList: companies,
    loading,
    handleCreate,
    handleDelete,
    handleUpdate,
    setEntityList,
    total,
  } = useStateEntityList<CompanyModel>({
    entityList,
    refresh,
    limit,
    offset,
    total: clientListTotal,
  });

  const handleCreateCompany = useCallback(
    async (value: Partial<CompanyFormDTO>) => {
      const company = await createCompany(value);

      if (company) {
        handleCreate(company);

        await setDefaultCompany({default_company: company?.uuid});
      }
    },
    [createCompany, handleCreate, setDefaultCompany],
  );

  const handleUpdateCompany = useCallback(
    async (value: CompanyFormDTO & {weekdays: string[]}) => {
      const company = await updateCompany(value);

      if (company) {
        handleUpdate(company);
      }
    },
    [handleUpdate, updateCompany],
  );

  const handleDeleteCompanies = useCallback(
    async (value: string) => {
      await deleteCompany(value);

      handleDelete([value]);
    },
    [deleteCompany, handleDelete],
  );

  const handleSearchCompanies = React.useCallback(
    async (keywords: string) => {
      const companies = await refresh({
        offset: 0,
        limit: 10,
        keywords,
      });

      if (companies) {
        setEntityList(companies);
      }
    },
    [refresh, setEntityList],
  );

  const handelSetDefaultCompany = useCallback(
    async (default_company: string) => {
      try {
        setDefaultCompanyLoading(true);
        await setDefaultCompany({default_company});

        alert('success', t('Company'), t('Set default company success'));

        CLEARED_LOCAL_STORAGE_ITEMS.forEach((item) =>
          localStorage.removeItem(item),
        );

        CLEARED_SESSION_STORAGE_ITEMS.forEach((item) =>
          sessionStorage.removeItem(item),
        );

        setDefaultCompanyLoading(false);
      } catch (error: any) {
        setDefaultCompanyLoading(false);
        alert(
          'error',
          t('Company'),
          `${t('An error occurred during set default company')}: ${
            error?.message
          }`,
        );
      }
    },
    [alert, setDefaultCompany, t],
  );

  return {
    companies,
    offset,
    limit,
    refresh,
    total,
    loading: clientLoading || loading,
    ...clientsParams,
    handleCreateCompany,
    handleUpdateCompany,
    handleDeleteCompanies,
    handleSearchCompanies,
    handleUpdate,
    handelSetDefaultCompany,
    defaultCompanyLoading,
  };
}
