import styled from 'styled-components';
import {WorkScheduleLabel} from '../Show';
import {useTranslation} from 'react-i18next';
import {useDefaultForm} from '../../../contex';
import {PlusOutlined} from '@ant-design/icons';
import {Col, Empty, Form, Input, Row} from 'antd';
import {phoneScreen} from '../../../services/const';
import {IOnChangeDaysOfWeekSchedule} from './CompanyForm';
import React, {useCallback, useRef, useState} from 'react';
import {StyledTitle} from '../../../components/lib/Styled';
import {DEFAULT_TIME_RANGE} from '../../../components/lib/const';
import {SearchSelect} from '../../../components/lib/DataDisplay';
import {IUseStateCategoryListReturnType, useSize} from '../../../hooks';
import {CompanyFormDTO, CompanyRequisiteDTO} from '../../../struture';

import {
  curry,
  head,
  split,
  ifElse,
  joinArr,
  findItemIndex,
  isListToArray,
  modifyWeekdays,
  checkWorkSchedule,
} from '../../../services/helpers';

import {
  PhoneInputComponent,
  WeekdayTimePickerForm,
} from '../../../components/lib/General';

import {
  ITableProps,
  Table,
  TableActionCell,
} from '../../../components/lib/libV2/DataDisplay';

import {
  CompanyRequisiteDeleteButton,
  CompanyRequisiteEditButton,
  CompanyRequisiteCreateButton,
} from '../Buttons';

import '../../Orders/List/OrderList.less';

export interface CompanyFormFieldsProps
  extends Pick<
    IUseStateCategoryListReturnType,
    'categories' | 'handleSearchCategories'
  > {
  company: CompanyFormDTO;
  editMode: boolean;
  loading: boolean;
  showOnlyCompanyTitle: boolean;
  showPhoneField: boolean;

  categoriesLoading: boolean;
  children: React.ReactNode | React.ReactNode[];
}

const StyledTable = styled(Table)`
  margin: 0;
` as React.ComponentType as React.FC<ITableProps<CompanyRequisiteDTO>>;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 15px;
`;

export function CompanyFormFields({
  company,
  editMode,
  loading,
  showOnlyCompanyTitle,
  showPhoneField,

  categories,
  categoriesLoading,
  handleSearchCategories,

  children,
}: CompanyFormFieldsProps): React.JSX.Element {
  const {t} = useTranslation();
  const {loadingSubmit, handlerUpdateFormState, formData} =
    useDefaultForm<CompanyFormDTO>();

  const contentRef = useRef(null);
  const {width: contentWidth} = useSize(contentRef);
  const isFullWidth = contentWidth <= phoneScreen ? 24 : 12;

  const [isFixedRange, setIsFixedRange] = useState(
    checkWorkSchedule(company?.work_schedules ? company?.work_schedules : ''),
  );

  const fixedRange = useRef<any>(
    ifElse<[boolean, string[], string[]], string>(
      checkWorkSchedule(company?.work_schedules || ''),
      split(',', company?.work_schedules || ''),
      split(',', DEFAULT_TIME_RANGE),
    ),
  );

  const weekRange = useRef(
    ifElse(
      checkWorkSchedule(company?.work_schedules || ''),
      [],
      split(',', company?.work_schedules || ''),
    ),
  );

  const updateWeekRange = useCallback(
    ({
      time,
      handlerUpdateFormState,
    }: Pick<IOnChangeDaysOfWeekSchedule, 'time'> & {
      handlerUpdateFormState: any;
    }): void => {
      const updatedWeekRange = [joinArr('-', time as string[])];

      fixedRange.current = updatedWeekRange;
      handlerUpdateFormState({weekdays: updatedWeekRange});
    },
    [fixedRange],
  );

  const updateCustomRange = useCallback(
    ({
      name,
      time,
      handlerUpdateFormState,
      fieldValue,
      ...rest
    }: IOnChangeDaysOfWeekSchedule & {
      handlerUpdateFormState: any;
    }): void => {
      const weekdays = Array.isArray(fieldValue) ? fieldValue : [];
      const index = findItemIndex(name, weekdays);

      const updatedWeekRange = modifyWeekdays({
        weekdays,
        index,
        name,
        time: Array.isArray(time) ? joinArr('-', time as string[]) : '',
        ...rest,
      });

      weekRange.current = updatedWeekRange;
      handlerUpdateFormState({weekdays: updatedWeekRange});
    },
    [weekRange],
  );

  /**
   * @desc Встановлення значень для поля - дні тижня
   * @param {string} day
   * @param {string} time
   * @param {string} checked
   * @return {Array}
   * */
  const onChangeDaysOfWeek = curry<any, any>(
    ({handlerUpdateFormState}, value: IOnChangeDaysOfWeekSchedule) => {
      if (isFixedRange) {
        updateWeekRange({time: value?.time, handlerUpdateFormState});
      }

      if (!isFixedRange) {
        updateCustomRange({...value, handlerUpdateFormState});
      }
    },
  );

  const handleChangeDateRangeView = useCallback(
    (handlerUpdateFormState: any): void => {
      setIsFixedRange((prevState) => {
        if (prevState) {
          handlerUpdateFormState({weekdays: weekRange.current});
        } else {
          handlerUpdateFormState({weekdays: fixedRange.current});
        }

        return !prevState;
      });
    },
    [fixedRange, weekRange],
  );

  const handleCreateRequisite = useCallback(
    async (value: CompanyRequisiteDTO) => {
      handlerUpdateFormState(({requisite_list}) => ({
        requisite_list: [value, ...(requisite_list || [])],
      }));
    },
    [handlerUpdateFormState],
  );

  const handleUpdateRequisite = useCallback(
    async (value: CompanyRequisiteDTO) => {
      handlerUpdateFormState(({requisite_list}) => ({
        requisite_list: (requisite_list || []).map((requisite) => {
          if (requisite?.uuid === value?.uuid) {
            return value;
          }
          return requisite;
        }),
      }));
    },
    [handlerUpdateFormState],
  );

  const handleDeleteRequisite = useCallback(
    async (ids: string[]) => {
      handlerUpdateFormState(({requisite_list}) => ({
        requisite_list: (requisite_list || []).filter(
          (requisite) => requisite?.uuid !== head(ids),
        ),
      }));
    },
    [handlerUpdateFormState],
  );

  const columns = [
    {
      ellipsis: true,
      title: t('Title'),
      key: 'Title',
      render: (requisite: CompanyRequisiteDTO) => requisite?.title,
    },
    {
      ellipsis: true,
      title: t('Actions'),
      key: 'actions',
      align: 'center' as any,
      fixed: 'right' as any,
      className: 'order-list-td',
      render: (requisite: CompanyRequisiteDTO) => {
        return (
          <TableActionCell>
            <CompanyRequisiteEditButton
              disabled={loadingSubmit || loading}
              requisite={requisite}
              onSuccess={handleUpdateRequisite}
            />
            <CompanyRequisiteDeleteButton
              disabled={loadingSubmit || loading}
              requisites={[requisite]}
              onSuccess={handleDeleteRequisite}
            />
          </TableActionCell>
        );
      },
    },
  ];

  return (
    <Row gutter={20} ref={contentRef}>
      <Col span={24}>
        <Form.Item
          tooltip={t('Company name')}
          label={t('Name')}
          name="title"
          rules={[
            {
              required: true,
              message: t('Company Name must be specified.'),
            },
            {
              max: 250,
              message: t(
                'Company Name must contain a maximum of 250 characters.',
              ),
            },
          ]}>
          <Input
            disabled={loadingSubmit}
            size={'large'}
            placeholder={t('Enter the company name')}
            onChange={(e) => handlerUpdateFormState({title: e.target.value})}
          />
        </Form.Item>
      </Col>

      {!showOnlyCompanyTitle ? (
        <Col span={24}>
          <Form.Item
            tooltip={t('The category the company belongs to')}
            label={t('Category')}
            name="category_uuid"
            rules={[
              {
                required: true,
                message: t('Company Category must be specified.'),
              },
            ]}>
            <SearchSelect
              disable={loading || loadingSubmit || categoriesLoading}
              size={'large'}
              name="category_uuid"
              placeholder={t('Select the category')}
              data={isListToArray(categories as any)}
              onChange={(e) => handlerUpdateFormState({category_uuid: e})}
              onSearch={handleSearchCategories}
            />
          </Form.Item>
        </Col>
      ) : null}
      {!showOnlyCompanyTitle || showPhoneField ? (
        <Col span={showPhoneField ? 24 : isFullWidth}>
          <Form.Item
            label={t('Phone')}
            name="phone"
            tooltip={t('Phone')}
            rules={[
              {
                required: true,
                message: t('Company Phone must be specified.'),
              },
            ]}>
            <PhoneInputComponent
              placeholder={t('Enter the company phone')}
              id="field-phone"
              size={'large'}
              containerClass="field-phone-container"
              searchClass="field-phone-search"
              inputClass="ant-input field-phone"
              buttonClass="field-phone-flag"
              loading={loading || loadingSubmit}
              onChange={(e) => handlerUpdateFormState({phone: e})}
            />
          </Form.Item>
        </Col>
      ) : null}
      {!showOnlyCompanyTitle ? (
        <>
          <Col span={isFullWidth}>
            <Form.Item
              tooltip={t('Your email')}
              label={t('Email')}
              name="email"
              rules={
                formData?.phone
                  ? []
                  : [
                      {
                        required: true,
                        message: t('Company Email must be specified.'),
                      },

                      {
                        type: 'email',
                        message: t('Email is not valid'),
                      },

                      {
                        max: 250,
                        message: t(
                          'Company Email must contain a maximum of 250 characters.',
                        ),
                      },
                    ]
              }>
              <Input
                size={'large'}
                data-testid="company-create-input-name"
                disabled={loading || loadingSubmit}
                placeholder={t('Enter the company email')}
                onChange={(e) =>
                  handlerUpdateFormState({email: e.target.value})
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              tooltip={t('Your address')}
              label={t('Address')}
              name="address"
              rules={[
                {
                  required: false,
                },

                {
                  max: 250,
                  message: t(
                    'Company Address must contain a maximum of 250 characters.',
                  ),
                },
              ]}>
              <Input
                size={'large'}
                data-testid="company-create-input-name"
                disabled={loading || loadingSubmit}
                placeholder={t('Enter the company address')}
                onChange={(e) =>
                  handlerUpdateFormState({address: e.target.value})
                }
              />
            </Form.Item>
          </Col>

          {!showOnlyCompanyTitle ? (
            <Col span={24}>
              <StyledFormItem
                label={t('Requisites')}
                name="requisites"
                tooltip={t('Requisites')}>
                <StyledTable
                  dataSource={formData?.requisite_list!}
                  columns={columns}
                  total={formData?.requisite_list?.length || 0}
                  pageSize={Infinity}
                  renderEmpty={<Empty description={t('No requisite added')} />}
                />
              </StyledFormItem>
              <CompanyRequisiteCreateButton
                type="default"
                disabled={loadingSubmit || loading}
                loading={loading}
                onSuccess={handleCreateRequisite}
                icon={<PlusOutlined />}>
                <StyledTitle>{t('Requisite')}</StyledTitle>
              </CompanyRequisiteCreateButton>
            </Col>
          ) : null}

          <Col span={24}>
            <Form.Item
              label={t('Description')}
              name="description"
              tooltip={t('Brief description of the company')}>
              <Input.TextArea
                allowClear
                size={'large'}
                autoSize={{minRows: 3, maxRows: 5}}
                data-testid="company-create-input-name"
                disabled={loading || loadingSubmit}
                placeholder={t('Enter the company description')}
                onChange={(e) =>
                  handlerUpdateFormState({description: e.target.value})
                }
              />
            </Form.Item>
          </Col>
          {editMode ? (
            <Col span={24}>
              <Form.Item
                className="company-form__work-schedule"
                label={
                  <WorkScheduleLabel
                    disabled={loading || loadingSubmit}
                    isFixedRange={isFixedRange}
                    handleChangeView={() =>
                      handleChangeDateRangeView(handlerUpdateFormState)
                    }
                  />
                }
                name="weekdays">
                <WeekdayTimePickerForm
                  isRangePicker
                  isFixedRange={isFixedRange}
                  editMode={editMode}
                  disabled={loading || loadingSubmit}
                  onChange={onChangeDaysOfWeek({
                    handlerUpdateFormState,
                  })}
                />
              </Form.Item>
            </Col>
          ) : null}
        </>
      ) : null}

      {children || null}
    </Row>
  );
}
