import React from 'react';
import styled from 'styled-components';
import {Dropdown, DropdownProps, MenuProps} from 'antd';

const StyledDropdown = styled(Dropdown)`
  display: block;
`;

interface IStyledDropdownProps extends DropdownProps {
  items: MenuProps['items'];
  children: React.ReactNode;
}

export function HeaderDropdown({
  items,
  children,
  ...rest
}: IStyledDropdownProps): React.JSX.Element {
  return (
    <StyledDropdown
      arrow
      trigger={['hover']}
      placement="bottomLeft"
      menu={{items}}
      {...rest}>
      {children}
    </StyledDropdown>
  );
}
