import {Record} from 'immutable';

export interface IUserOperationProps {
  uuid: string;
  date_operation: string;
  sum: string;
  description: string;
  created_at: string;
  updated_at: string;
}

/**
 * @class Client
 * @desc Клієнт
 */
export default class UserOperation extends Record({
  uuid: '',
  date_operation: '',
  sum: '',
  description: '',
  created_at: '',
  updated_at: '',
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(props: IUserOperationProps = {} as IUserOperationProps) {
    super({...props});
  }
}
