import {StoreDocumentType} from '../../struture';
import {Routes, RoutesTitle} from '../../services/types';

export const storeDocumentRoute = {
  [StoreDocumentType.MOVE]: Routes.storeMoving,
  [StoreDocumentType.IN]: Routes.storePosting,
  [StoreDocumentType.OUT]: Routes.storeRealization,
  [StoreDocumentType.RETURN]: Routes.storeReturn,
  [StoreDocumentType.ERASE]: Routes.storeWriteOff,
  [StoreDocumentType.SALE]: Routes.storeSale,
  [StoreDocumentType.CRETURN]: Routes.storeReturnClient,
  [StoreDocumentType.PREORDER]: Routes.storeOrderProvider,
};

export const storeDocumentRouteTitle = {
  [StoreDocumentType.MOVE]: RoutesTitle[Routes.storeMoving],
  [StoreDocumentType.IN]: RoutesTitle[Routes.storePosting],
  [StoreDocumentType.OUT]: RoutesTitle[Routes.storeRealization],
  [StoreDocumentType.RETURN]: RoutesTitle[Routes.storeReturn],
  [StoreDocumentType.ERASE]: RoutesTitle[Routes.storeWriteOff],
  [StoreDocumentType.SALE]: RoutesTitle[Routes.storeSale],
  [StoreDocumentType.CRETURN]: RoutesTitle[Routes.storeReturnClient],
  [StoreDocumentType.PREORDER]: RoutesTitle[Routes.storeOrderProvider],
};
