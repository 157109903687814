import React from 'react';
import {DetailsView} from '../../../components/lib/DataDisplay';
import {StyledTitle, StyledDescription} from '../../../components/lib/Styled';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useStoredCompanies} from '../../../hooks';
import {RewardStatsDTO} from '../../../struture';
import {
  isThereContent,
  correctPrice,
  toDateByFormat,
  toMoment,
} from '../../../services/helpers';
import {LocalStorageItems} from '../../../services/const';
export interface IRewardStatsViewProps {
  stats: RewardStatsDTO | null;
  month: number;
  year: number;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  padding-bottom: 10px;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
`;

const Title = styled(StyledTitle)<{$width?: number}>`
  min-width: 100px;
`;

const Description = styled(StyledDescription)`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  margin-right: 5px;
`;

const StyledDetailsView = styled(DetailsView)`
  box-shadow: none;
  border-color: #d9d9d9;
  text-align: right;
  margin-bottom: 50px;
`;

export function RewardStatsView({
  stats,
  month,
  year,
}: IRewardStatsViewProps): React.JSX.Element {
  const {t} = useTranslation();
  const {defaultCompany} = useStoredCompanies();

  const language =
    window.localStorage.getItem(LocalStorageItems.language) === 'ua'
      ? 'uk'
      : window.localStorage.getItem(LocalStorageItems.language);

  const date = toDateByFormat(
    toMoment(new Date())
      .locale(language || 'en')
      .set({month, year}),
    'MMMM YYYY',
  );

  return (
    <>
      {isThereContent(stats) ? (
        <StyledDetailsView titles={['Total sum']}>
          <StyledContainer>
            <StyledTitleContainer>
              <Description fontSize={15} bold>{`${t(
                'Amount for',
              )}`}</Description>
              <StyledDescription
                style={{textTransform: 'capitalize', marginRight: 10}}
                fontSize={15}
                bold>{` "${date}", ${
                defaultCompany?.currency_symbol || ''
              }:`}</StyledDescription>
              <Title fontSize={15} bold>
                {correctPrice(stats?.total || 0)}
              </Title>
            </StyledTitleContainer>
          </StyledContainer>
        </StyledDetailsView>
      ) : null}
    </>
  );
}
