import React, {useCallback} from 'react';
import {FileModel, FileStatusesText} from '../../../struture';
import styled, {css} from 'styled-components';
import {StyledDescription, StyledTitle} from '../../../components/lib/Styled';
import {BsFileEarmark} from 'react-icons/bs';
import {filesIcons} from '../../Orders/Show/FileGroupListItem';
import Color from 'color';

export interface IClientFileDetailsViewProps {
  file: FileModel | null;
}

const StyledTitleContainer = styled.div<{$status: FileStatusesText}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  overflow: hidden;
  

  & > * {
    ${({$status, theme}) =>
      $status === FileStatusesText.Enabled &&
      css`
        color: ${theme.colors.link};
        cursor: pointer;

        &:hover {
          color: ${Color(theme.colors.link).alpha(1).darken(0.3) as any};
        }
      `}

    ${({$status, theme}) =>
      $status === FileStatusesText.Disabled &&
      css`
        color: ${theme.colors.disabled};
      `}

    ${({$status, theme}) =>
      $status === FileStatusesText.Deleted &&
      css`
        color: ${theme.colors.disabled};
        text-decoration: line-through;
      `}

    ${({$status, theme}) =>
      $status === FileStatusesText.Error &&
      css`
        color: ${theme.colors.error};
      `}
`;

export function ClientFileDetailsView({
  file,
}: IClientFileDetailsViewProps): JSX.Element {
  const [_, fileType] = (file?.file_original_name || '')?.split('.');

  const handlePreview = useCallback(() => {
    window.open(file?.file_url, '_blank');
  }, [file?.file_url]);

  return (
    <>
      {file ? (
        <StyledTitleContainer
          $status={file?.file_status_text}
          onClick={
            file?.file_status_text === FileStatusesText.Enabled
              ? handlePreview
              : undefined
          }>
          {filesIcons[fileType] || <BsFileEarmark fontSize={15} />}
          <StyledTitle>{file?.file_original_name}</StyledTitle>
        </StyledTitleContainer>
      ) : null}
    </>
  );
}
