import React from 'react';
import {EmployeeModel, EmployeeStatuses} from '../../../struture';
import styled, {useTheme} from 'styled-components';
import {StyledDescription, StyledTitle} from '../../../components/lib/Styled';
import {Routes} from '../../../services/types';
import {Link} from '../../../components/lib/DataDisplay';
import {useLocation} from 'react-router';
import {useTranslation} from 'react-i18next';
import {useAcl} from '../../../contex';
import {textToUpperCase} from '../../../services/helpers';
import {DeleteOutlined} from '@ant-design/icons';

export interface IEmployeeDetailsViewProps {
  employee: EmployeeModel | null;
  staticMode?: boolean;
}

const StyledSpace = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 5px;
`;

const StyledDeleteContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const StyledDeleteOutlined = styled(DeleteOutlined)`
  color: ${({theme}) => theme.colors.error};
  font-size: 12px;
`;

export function EmployeeDetailsView({
  employee,
  staticMode = false,
}: IEmployeeDetailsViewProps): JSX.Element {
  const {t} = useTranslation();
  const location = useLocation();
  const {manage} = useAcl(({employee}) => employee);
  const theme: any = useTheme();

  return (
    <>
      {employee ? (
        <StyledSpace>
          {employee?.status === EmployeeStatuses.DELETED ? (
            <StyledDeleteContainer>
              <StyledDeleteOutlined />
              <StyledDescription $color={theme.colors.error}>
                {textToUpperCase(t('deleted'))}
              </StyledDescription>
            </StyledDeleteContainer>
          ) : null}
          {manage && !staticMode ? (
            <Link
              style={{color: '#aa6aaa', fontWeight: '500', fontSize: '22px'}}
              to={`/${Routes.app}/${Routes.employees}/${employee?.uuid}`}
              tooltip={t('Go to manager details page')}
              state={{goBack: location.pathname}}>
              {employee?.fullName}
            </Link>
          ) : (
            <StyledTitle
              style={{color: '#aa6aaa', fontWeight: '500', fontSize: '22px'}}>
              {employee?.fullName}
            </StyledTitle>
          )}

          {employee?.title ? (
            <StyledDescription>{employee?.title}</StyledDescription>
          ) : null}
          {employee?.phone ? (
            <StyledDescription>{employee?.phone}</StyledDescription>
          ) : null}
        </StyledSpace>
      ) : null}
    </>
  );
}
