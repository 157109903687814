import React, {
  Suspense,
  useCallback,
  useEffect,
  useState,
  forwardRef,
} from 'react';
import {
  ExecuteOnSet,
  PaymentMapper,
  PaymentModel,
  PaymentScheduleFormDTO,
  PaymentSchedulePostActionType,
  PaymentScheduleType,
  ScheduleCalendarMapper,
  ScheduleCalendarModel,
  ScheduleCloseDTO,
  StatusCategoryInternalCode,
  StatusCommentFormDTO,
  StatusMapper,
  StatusModel,
} from '../../../struture';
import {eq} from '../../../services/helpers';
import {List} from 'immutable';
import {Empty, Select, SelectProps} from 'antd';
import {StatusCategoryTag} from './StatusCategoryTag';
import {useTranslation} from 'react-i18next';
import styled, {css} from 'styled-components';
import './StatusListSelect.less';
import {DownOutlined, LoadingOutlined} from '@ant-design/icons';
import {useModal} from '../../../components/lib/libV2/hooks';
import {
  IUseStateSchedulePaymentListReturnType,
  IUseStateScheduleReturnType,
  useStoredProfile,
} from '../../../hooks';
import {useDropdownAlert} from '../../../contex';
import {SuspenseEmpty} from '../../../components/lib/DataDisplay';

const PaymentScheduleSideWindowLazy = React.lazy(
  () => import('../../Payments/Show/PaymentScheduleSideWindow'),
);

const CommentOrderSideWindowLazy = React.lazy(
  () => import('../../Orders/Show/CommentOrderSideWindow'),
);

const CloseOrderSideWindowLazy = React.lazy(
  () => import('../../Orders/Show/CloseOrderSideWindow'),
);

export interface IStatusListSelectProps
  extends Pick<
      IUseStateScheduleReturnType,
      'handleChangeScheduleStatus' | 'handleCloseSchedule'
    >,
    Pick<
      IUseStateSchedulePaymentListReturnType,
      'handleCreateSchedulePrepayment'
    >,
    Partial<SelectProps> {
  allowedStatusesTo: List<StatusModel> | null;
  selectedStatus: StatusModel | null;
  loading: boolean;
  schedule: ScheduleCalendarModel | null;
  className?: string;

  children?: React.ReactNode;
}

export interface IStatusOption {
  label: string | React.ReactNode;
  value: string;
  options?: IStatusOption[];
}

const StyledSelect = styled(Select)`
  &&& {
    width: auto !important;

    & > .ant-select-selector {
      border: none;
      box-shadow: none !important;
      height: auto !important;
    }

    & > .ant-select-arrow {
      display: none;
    }
  }
`;

const iconStyle = css`
  color: ${({theme}) => theme.colors.white};
  margin-left: 10px;
`;

const StyledDownOutlined = styled(DownOutlined)`
  ${iconStyle}
`;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  ${iconStyle}
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  gap: 5px 0;
`;

export const StatusListSelect = forwardRef(function StatusListSelect(
  {
    allowedStatusesTo = List(),
    selectedStatus,
    loading: listLoading,
    schedule,

    handleChangeScheduleStatus,
    handleCreateSchedulePrepayment,
    handleCloseSchedule,

    className,

    children,

    ...rest
  }: IStatusListSelectProps,
  ref: any,
): JSX.Element {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const {profile} = useStoredProfile();

  const [options, setOptions] = useState<IStatusOption[]>([]);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState<IStatusOption | null>(
    null,
  );
  const [payment, setPayment] = useState<PaymentScheduleFormDTO | null>(null);
  const [comment, setComment] = useState<StatusCommentFormDTO | null>(null);
  const [closeSchedule, setCloseSchedule] = useState<ScheduleCloseDTO | null>(
    null,
  );

  const {
    handleCancel: paymentHandleCancel,
    visible: paymentVisible,
    handleSuccess: paymentHandleSuccess,
    handleOnInit: paymentHandleOnInit,
  } = useModal({onSuccess: handleCreateSchedulePrepayment});

  const {
    handleCancel: commentHandleCancel,
    visible: commentVisible,
    handleSuccess: commentHandleSuccess,
    handleOnInit: commentHandleOnInit,
  } = useModal({onSuccess: handleChangeScheduleStatus});

  const {
    handleCancel: closeScheduleHandleCancel,
    visible: closeScheduleVisible,
    handleSuccess: closeScheduleHandleSuccess,
    handleOnInit: closeScheduleHandleOnInit,
  } = useModal({onSuccess: handleCloseSchedule});

  const loading = listLoading || optionsLoading;

  const toOption = useCallback(
    (status: StatusModel) => ({
      value: status?.uuid,
      label: (
        <StatusCategoryTag
          category={status?.category!}
          title={status?.title!}
          icon={loading ? <StyledLoadingOutlined /> : <StyledDownOutlined />}
        />
      ),
    }),
    [loading],
  );

  const handleChangeOption = useCallback(
    async (statusUuid: string) => {
      try {
        setOptionsLoading(true);
        const status = allowedStatusesTo?.find(({uuid}) =>
          eq(uuid, statusUuid),
        );

        if (status?.execute_on_set === ExecuteOnSet.Payment) {
          const payment = PaymentMapper.toPaymentScheduleFormDTO(
            {
              payment_agent_object: schedule?.clientModel,
              payment_comment: `${t('Advance payment for the order')} №${
                schedule?.schedule_number
              }${
                schedule?.schedule_title ? ` (${schedule?.schedule_title})` : ''
              }`,
            } as PaymentModel,
            {
              cashier: profile?.defaultEmployeeModel,
              payment_post_action: {
                action: PaymentSchedulePostActionType.SetScheduleStatus,
                value: statusUuid,
              },
              schedule: schedule!,
              type: PaymentScheduleType.Prepayment,
            },
          );

          setPayment(payment);

          paymentHandleOnInit();
          setOptionsLoading(false);
          return;
        }

        if (status?.execute_on_set === ExecuteOnSet.Comment) {
          setComment(StatusMapper.toStatusCommentFormDTO(status));

          commentHandleOnInit();
          setOptionsLoading(false);
          return;
        }

        const sumPaid = Number(schedule?.sum_paid || 0);
        const sumTotal = Number(schedule?.sum_total || 0);

        if (
          status?.execute_on_set === ExecuteOnSet.ClosePayment &&
          !(sumPaid >= sumTotal)
        ) {
          const closeSchedule = ScheduleCalendarMapper.toScheduleCloseDTO(
            schedule!,
            {
              cashier: profile?.defaultEmployeeModel,
              payment_post_action: {
                action: PaymentSchedulePostActionType.SetScheduleStatus,
                value: statusUuid,
              },
              client: schedule?.clientModel!,
              comment: `${t('Closing the order')} №${
                schedule?.schedule_number
              }`,
            },
          );

          setCloseSchedule(closeSchedule);

          closeScheduleHandleOnInit();
          setOptionsLoading(false);
          return;
        }

        await handleChangeScheduleStatus({status_uuid: statusUuid});

        if (status) {
          setSelectedOption(toOption(status));
        }
        setOptionsLoading(false);
      } catch (error: any) {
        setOptionsLoading(false);
        alert(
          'error',
          t('Status'),
          `${t('An error occurred during change status')} : ${error?.message}`,
        );
      }
    },
    [
      alert,
      allowedStatusesTo,
      closeScheduleHandleOnInit,
      commentHandleOnInit,
      handleChangeScheduleStatus,
      paymentHandleOnInit,
      profile?.defaultEmployeeModel,
      schedule,
      t,
      toOption,
    ],
  );

  useEffect(() => {
    if (List.isList(allowedStatusesTo) && selectedStatus) {
      setOptionsLoading(true);
      const updated = allowedStatusesTo
        .push(selectedStatus)
        .sort((a, b) => a?.category?.pos - b?.category?.pos);

      const groupBy = updated.groupBy(
        (value: any) => value?.category?.internal_code,
      );

      const options = groupBy
        .map((group, key) => {
          return {
            label: t(StatusCategoryInternalCode[key]),
            options: group
              .map((status: any) => ({
                label: (
                  <StatusCategoryTag
                    category={status?.category}
                    title={status?.title}
                  />
                ),
                value: status?.uuid,
              }))
              .toArray(),
          };
        })
        .reduce((acc: any, value) => {
          acc.push(value);

          return acc;
        }, []);

      setOptions(options);
      setTimeout(setOptionsLoading, 200, false);
    }
  }, [allowedStatusesTo, selectedStatus, t]);

  useEffect(() => {
    if (selectedStatus) {
      setSelectedOption(toOption(selectedStatus));
    }
  }, [loading, selectedStatus, toOption]);

  return (
    <StyledContainer>
      <StyledSelect
        {...(rest as any)}
        ref={ref}
        className={className}
        optionLabelProp="label"
        popupMatchSelectWidth={false}
        popupClassName={`status-select ${rest?.popupClassName || ''}`}
        size="small"
        value={selectedOption}
        placeholder={t('Status')}
        style={{width: 200}}
        onChange={handleChangeOption as any}
        options={options}
        loading={loading}
        disabled={loading}
        notFoundContent={
          <Empty
            description={t(
              'No statuses were found to which the current status can go',
            )}
          />
        }
      />

      {children || null}

      <Suspense fallback={<SuspenseEmpty />}>
        <PaymentScheduleSideWindowLazy
          payment={payment!}
          loading={loading}
          onSuccess={paymentHandleSuccess}
          onCancel={paymentHandleCancel}
          visible={paymentVisible}
          type={PaymentScheduleType.Prepayment}
        />
      </Suspense>

      <Suspense fallback={<SuspenseEmpty />}>
        <CommentOrderSideWindowLazy
          comment={comment!}
          loading={loading}
          onSuccess={commentHandleSuccess}
          onCancel={commentHandleCancel}
          visible={commentVisible}
        />
      </Suspense>

      <Suspense fallback={<SuspenseEmpty />}>
        <CloseOrderSideWindowLazy
          title={`${t('Closing the order')} №${schedule?.schedule_number}`}
          closeOrder={closeSchedule!}
          loading={loading}
          onSuccess={closeScheduleHandleSuccess}
          onCancel={closeScheduleHandleCancel}
          visible={closeScheduleVisible}
        />
      </Suspense>
    </StyledContainer>
  );
});
