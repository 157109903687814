import {Dispatch} from 'redux';
import {PRODUCT_LEFTOVERS_LIST} from '../constants';
import {
  IProductsListStatsProps,
  ProductModel,
  ProductListModel,
} from '../../struture';
import {ApiError} from '../../services/types';

export interface ISetProductLeftoversListParam {
  type: PRODUCT_LEFTOVERS_LIST;
  productList: ProductListModel;
  keywords?: string;
  total?: number;
  page?: number;
  stats?: IProductsListStatsProps;
}

export interface ISetProductLeftoversListStatsParam {
  type: PRODUCT_LEFTOVERS_LIST;
  stats: IProductsListStatsProps;
}

export interface IProductLeftoversListStatsParam {
  type: PRODUCT_LEFTOVERS_LIST;
}

export interface IAddProductLeftoversToListParam {
  type: PRODUCT_LEFTOVERS_LIST;
  product: ProductModel;
}

export interface IUpdateProductLeftoversFromListParam {
  type: PRODUCT_LEFTOVERS_LIST;
  product: ProductModel;
}

export interface IDeleteProductLeftoversFromListParam {
  type: PRODUCT_LEFTOVERS_LIST;
  productUuid: string;
}

export interface IErrorProductLeftoversListParam {
  type: PRODUCT_LEFTOVERS_LIST;
  error: ApiError | undefined;
}

export function setProductLeftoversList(
  productList: ProductListModel,
  keywords?: string,
  total?: number,
  page?: number,
  stats?: IProductsListStatsProps,
): (dispatch: Dispatch) => Promise<ISetProductLeftoversListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_LEFTOVERS_LIST.SET_PRODUCT_LEFTOVERS_LIST,
      productList,
      keywords,
      total,
      page,
      stats,
    });
  };
}

export function setProductLeftoversListStats(
  stats: IProductsListStatsProps,
): (dispatch: Dispatch) => Promise<ISetProductLeftoversListStatsParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_LEFTOVERS_LIST.SET_PRODUCT_LEFTOVERS_LIST_STATS,
      stats,
    });
  };
}

export function addProductLeftoversListStats(): (
  dispatch: Dispatch,
) => Promise<IProductLeftoversListStatsParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_LEFTOVERS_LIST.ADD_PRODUCT_LEFTOVERS_LIST_STATS,
    });
  };
}

export function subProductLeftoversListStats(): (
  dispatch: Dispatch,
) => Promise<IProductLeftoversListStatsParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_LEFTOVERS_LIST.SUB_PRODUCT_LEFTOVERS_LIST_STATS,
    });
  };
}

export function loadMoreProductLeftoversList(
  productList: ProductListModel,
): (dispatch: Dispatch) => Promise<ISetProductLeftoversListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_LEFTOVERS_LIST.LOAD_MORE_PRODUCT_LEFTOVERS_LIST,
      productList,
    });
  };
}

export function addProductLeftoversToList(
  product: ProductModel,
): (dispatch: Dispatch) => Promise<IAddProductLeftoversToListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_LEFTOVERS_LIST.ADD_PRODUCT_LEFTOVERS,
      product,
    });
  };
}

export function updateProductLeftoversFromList(
  product: ProductModel,
): (dispatch: Dispatch) => Promise<IUpdateProductLeftoversFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_LEFTOVERS_LIST.UPDATE_PRODUCT_LEFTOVERS,
      product,
    });
  };
}

export function deleteProductLeftoversFromList(
  productUuid: string,
): (dispatch: Dispatch) => Promise<IDeleteProductLeftoversFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_LEFTOVERS_LIST.DELETE_PRODUCT_LEFTOVERS,
      productUuid,
    });
  };
}

export function errorProductLeftoversInList(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorProductLeftoversListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_LEFTOVERS_LIST.ERROR_PRODUCT_LEFTOVERS_LIST,
      error,
    });
  };
}
