import {Record, List} from 'immutable';
import {
  ProductCategoryDTO,
  CompanyDTO,
  CompanyMapper,
  CompanyModel,
  EmployeeDTO,
  EmployeeMapper,
  EmployeeModel,
  ProductCategoryMapper,
  UnitPackaging,
} from '../internal';
import {WithoutRecursion} from '../type';
import {listToArray} from '@sportix/sportix-common-modules';

export interface IProductCategoryModelReturnType {
  uuid: string;
  title: string;
  description: string;
  pos: number;
  created_at: string;
  updated_at: string;
  created_by: EmployeeModel | EmployeeDTO;
  company: CompanyModel | CompanyDTO;
  base_unit: string;
  parent: ProductCategoryDTO | ProductCategoryModel;
  unit_packaging: UnitPackaging[];
  children: List<ProductCategoryModel> | ProductCategoryDTO[] | null;
  cover_url: string;
  logo_url: string;
}

/**
 * @class ProductCategory
 * @desc Клієнт
 */
export class ProductCategoryModel extends Record<IProductCategoryModelReturnType>(
  {
    uuid: '',
    title: '',
    description: '',
    pos: 0,
    created_at: '',
    updated_at: '',
    company: {} as CompanyModel,
    created_by: {} as EmployeeModel,
    base_unit: '',
    parent: {} as ProductCategoryDTO,
    unit_packaging: [],
    children: null,
    cover_url: '',
    logo_url: '',
  },
) {
  constructor(
    props: ProductCategoryDTO = {} as ProductCategoryDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IProductCategoryModelReturnType = {
      ...props,

      children:
        Array.isArray(props?.children) && props?.children?.length
          ? (listToArray(
              ProductCategoryMapper.toProductCategoryListModel(
                props?.children,
                props?.children?.length || 0,
              ).categories,
            ) as any)
          : null,
      created_by:
        withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
          ? props?.created_by
          : EmployeeMapper.toEmployeeModel(props?.created_by, [
              WithoutRecursion.employee,
              ...withoutRecursion,
            ]),
      company:
        withoutRecursion.indexOf(WithoutRecursion.company) !== -1
          ? props?.company
          : CompanyMapper.toCompanyModel(props?.company, [
              WithoutRecursion.profile,
              ...withoutRecursion,
            ]),
      parent:
        withoutRecursion.indexOf(WithoutRecursion.category) !== -1
          ? props?.parent
          : ProductCategoryMapper.toProductCategoryDTO(props?.parent, [
              WithoutRecursion.category,
              ...withoutRecursion,
            ]),
    };

    super(options);
  }

  get companyModel(): CompanyModel {
    return this.company instanceof CompanyModel
      ? this.company
      : CompanyMapper.toCompanyModel(this.company);
  }

  get createdByModel(): EmployeeModel {
    return this.created_by instanceof EmployeeModel
      ? this.created_by
      : EmployeeMapper.toEmployeeModel(this.created_by);
  }
  get parentCategoryModel(): ProductCategoryModel {
    return this.parent instanceof ProductCategoryModel
      ? this.parent
      : ProductCategoryMapper.toProductCategoryModel(this.parent);
  }
}
