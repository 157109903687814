import {Dispatch} from 'redux';
import {List} from 'immutable';
import {PLAN_LIST} from '../constants';
import {PlanModel} from '../../struture';
import {ApiError} from '../../services/types';

export interface ISetPlanListParam {
  type: PLAN_LIST;
  planList: List<PlanModel>;
  keywords?: string;
  total?: number;
}

export interface IAddPlanToListParam {
  type: PLAN_LIST;
  plan: PlanModel;
}

export interface IUpdatePlanFromListParam {
  type: PLAN_LIST;
  plan: PlanModel;
}

export interface IDeletePlanFromListParam {
  type: PLAN_LIST;
  planUuid: string;
}

export interface IErrorPlanListParam {
  type: PLAN_LIST;
  error: ApiError | undefined;
}

export function setPlanList(
  planList: List<PlanModel>,
  keywords?: string,
  total?: number,
): (dispatch: Dispatch) => Promise<ISetPlanListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PLAN_LIST.SET_PLAN_LIST,
      planList,
      keywords,
      total,
    });
  };
}

export function loadMorePlanList(
  planList: List<PlanModel>,
): (dispatch: Dispatch) => Promise<ISetPlanListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PLAN_LIST.LOAD_MORE_PLAN_LIST,
      planList,
    });
  };
}

export function addPlanToList(
  plan: PlanModel,
): (dispatch: Dispatch) => Promise<IAddPlanToListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PLAN_LIST.ADD_PLAN,
      plan,
    });
  };
}

export function updatePlanFromList(
  plan: PlanModel,
): (dispatch: Dispatch) => Promise<IUpdatePlanFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PLAN_LIST.UPDATE_PLAN,
      plan,
    });
  };
}

export function deletePlanFromList(
  planUuid: string,
): (dispatch: Dispatch) => Promise<IDeletePlanFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PLAN_LIST.DELETE_PLAN,
      planUuid,
    });
  };
}

export function errorPlanInList(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorPlanListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PLAN_LIST.ERROR_PLAN_LIST,
      error,
    });
  };
}
