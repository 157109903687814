import * as React from 'react';
import {useEffect, useState} from 'react';
import {List} from 'immutable';
import {Moment} from 'moment';
import {
  IUseStoreDocumentPaymentListProps,
  IUseStoreDocumentPaymentListReturnType,
  useStoreDocumentPaymentList,
} from './useStoreDocumentPaymentList';
import {
  PaymentFormDTO,
  PaymentMapper,
  PaymentModel,
  PaymentScheduleFormDTO,
  StoreDocumentModel,
} from '../struture';
import {useStateEntityList} from '../components/lib/libV2/hooks';
import {
  correctPrice,
  dateToIsoString,
  debounce,
  head,
  isFunction,
  isThereContent,
  last,
} from '../services/helpers';
import {useStatePaymentListActions} from './useStatePaymentListActions';
import {IPaymentScheduleListStats} from '../services/api/orders';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../contex';
import {
  createStoreDocumentPayment,
  IPaymentStoreDocumentReturnType,
} from '../services/api/storeDocument';
import {OPERATION_TYPE} from '../services/types';
import {
  updateStoreDocument as storeUpdateStoreDocument,
  updateStoreDocumentFromList as storeUpdateStoreDocumentFromList,
} from '../store/actions';
import {useDispatch} from 'react-redux';
import {
  useStoredCashBoxList,
  useStoredCompanies,
  useStoredStoreDocument,
} from './index';

export interface IUseStateStoreDocumentPaymentListProps
  extends IUseStoreDocumentPaymentListProps {}

export type StoreDocumentPaymentDateRangeParam = [Moment, Moment];

export interface IUseStateStoreDocumentPaymentListReturnType
  extends Omit<IUseStoreDocumentPaymentListReturnType, 'entityList'> {
  payments: List<PaymentModel> | null;
  handleCreatePayment: (value: PaymentFormDTO) => Promise<void>;
  handleCreateStoreDocumentPrepayment: (
    value: PaymentScheduleFormDTO,
  ) => Promise<void | IPaymentStoreDocumentReturnType>;
  handleCreateStoreDocumentRefunds: (
    value: PaymentScheduleFormDTO,
  ) => Promise<void | IPaymentStoreDocumentReturnType>;
  handleUpdatePayment: (value: PaymentFormDTO) => Promise<void>;
  handleDeletePayments: (ids: string[]) => Promise<void>;
  handleSearchPayments: (value: any) => Promise<void>;
  handleRefreshPayments: (
    value: Partial<IUseStoreDocumentPaymentListProps> & {
      page?: number;
    },
  ) => Promise<void>;
  handlePickPaymentRange: (
    value: StoreDocumentPaymentDateRangeParam,
  ) => Promise<void>;
  page: number;
}

export function useStateStoreDocumentPaymentList(
  {
    documentUuid,
    ...rest
  }: IUseStateStoreDocumentPaymentListProps = {} as IUseStateStoreDocumentPaymentListProps,
): IUseStateStoreDocumentPaymentListReturnType {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const {defaultCompanyUuid} = useStoredCompanies();

  const [page, setPage] = useState(1);

  const {handleRefreshCashBoxes} = useStoredCashBoxList({
    companyUuid: defaultCompanyUuid,
    loadOnInit: false,
  });

  const [document, setDocument] = useState<StoreDocumentModel | null>(null);

  const [stats, setStats] = React.useState<IPaymentScheduleListStats>(
    {} as IPaymentScheduleListStats,
  );

  const dispatch = useDispatch<any>();

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: paymentListLoading,
    total: paymentListTotal,
    stats: paymentStats,
    document: storeDocument,
    ...clientsParams
  } = useStoreDocumentPaymentList({
    documentUuid,
    ...rest,
  });

  const {
    entityList: payments,
    loading,
    handleCreate,
    handleDelete,
    handleUpdate,
    setEntityList,
    total,
  } = useStateEntityList<PaymentModel>({
    entityList,
    refresh,
    limit,
    offset,
    total: paymentListTotal,
  });

  const {document: initDocument} = useStoredStoreDocument({
    loadOnInit: false,
    documentUuid: documentUuid,
    companyUuid: defaultCompanyUuid,
    documentType: storeDocument?.doc_type,
  });

  const {handleDeletePayments, handleUpdatePayment, handleCreatePayment} =
    useStatePaymentListActions({
      handleCreate,
      handleDelete,
      handleUpdate,
    });

  const handlePickPaymentRange = React.useCallback(
    async (value: StoreDocumentPaymentDateRangeParam): Promise<void> => {
      if (value) {
        await refresh({
          date_start: dateToIsoString(head(value)),
          date_end: dateToIsoString(last(value)),
        });
      } else {
        await refresh({
          date_start: '',
          date_end: '',
          isClearDate: true,
        });
      }
    },
    [refresh],
  );

  const handleSearchPayments = React.useCallback(
    async (keywords: string): Promise<void> => {
      const payments = await refresh({
        keywords,
        date_start: '',
        date_end: '',
        isSearch: true,
      });

      if (payments) {
        setEntityList(payments);
      }
    },
    [refresh, setEntityList],
  );

  const handleRefreshPayments = React.useCallback(
    async ({
      offset = 0,
      limit = 10,
      page = 1,
      ...rest
    }: Partial<IUseStoreDocumentPaymentListProps> & {
      page?: number;
    }): Promise<void> => {
      const payments = await refresh({
        offset,
        limit,
        ...rest,
      });

      if (payments) {
        setEntityList(payments);
        setPage(page);
      }
    },
    [refresh, setEntityList],
  );

  const handleCreateStoreDocumentPrepayment = React.useCallback(
    async (value: PaymentScheduleFormDTO) => {
      const {payment, document} = await createStoreDocumentPayment(
        {...value, payment_type: OPERATION_TYPE.IN} as any,
        documentUuid,
        {with_document: true, with_stats: true},
      );
      const paymentModel = PaymentMapper.toPaymentModel(payment);

      if (isFunction(handleCreate)) {
        handleCreate(paymentModel, true);
      }

      if (initDocument && document) {
        const updatedInitDocument = initDocument
          ?.update('doc_status_text', () => document?.doc_status_text)
          ?.update('doc_sum_paid', () => document?.doc_sum_paid)
          .update(
            'doc_comment',
            (doc_comment) => document?.doc_comment || doc_comment,
          )
          .update(
            'doc_sum_total_with_discount',
            () => document?.doc_sum_total_with_discount,
          )
          .update(
            'doc_sum_services',
            (doc_sum_services) =>
              document?.doc_sum_services || doc_sum_services,
          )
          .update(
            'doc_discount_value',
            (doc_discount_value) =>
              document?.doc_discount_value || doc_discount_value,
          );

        dispatch(storeUpdateStoreDocument(updatedInitDocument));
        dispatch(
          storeUpdateStoreDocumentFromList(
            updatedInitDocument,
            updatedInitDocument?.doc_type,
          ),
        );
        setDocument(updatedInitDocument);
      } else {
        setDocument(document);
      }

      setStats((prevState) => ({
        totalInSum: correctPrice(
          Number(prevState?.totalInSum || 0) + Number(value?.payment_price),
        ),
        totalOutSum: correctPrice(prevState?.totalOutSum || 0),
      }));

      setTimeout(async () => {
        await handleRefreshCashBoxes({page: 1, limit: 100});
      }, 300);

      alert('success', t('Payment'), t('Payment created success'));

      return {payment, document};
    },
    [
      documentUuid,
      handleCreate,
      initDocument,
      alert,
      t,
      dispatch,
      handleRefreshCashBoxes,
    ],
  );

  const handleCreateStoreDocumentRefunds = React.useCallback(
    async (value: PaymentScheduleFormDTO) => {
      const {payment, document} = await createStoreDocumentPayment(
        {...value, payment_type: OPERATION_TYPE.OUT} as any,
        documentUuid,
        {with_document: true, with_stats: true},
      );

      const paymentModel = PaymentMapper.toPaymentModel(payment);

      if (isFunction(handleCreate)) {
        handleCreate(paymentModel, true);
      }

      if (initDocument && document) {
        const updatedInitDocument = initDocument
          ?.update('doc_status_text', () => document?.doc_status_text)
          ?.update('doc_sum_paid', () => document?.doc_sum_paid)
          .update(
            'doc_comment',
            (doc_comment) => document?.doc_comment || doc_comment,
          )
          .update(
            'doc_sum_total_with_discount',
            (doc_sum_total_with_discount) =>
              document?.doc_sum_total_with_discount ||
              doc_sum_total_with_discount,
          )
          .update(
            'doc_sum_services',
            (doc_sum_services) =>
              document?.doc_sum_services || doc_sum_services,
          )
          .update(
            'doc_discount_value',
            (doc_discount_value) =>
              document?.doc_discount_value || doc_discount_value,
          );

        dispatch(storeUpdateStoreDocument(updatedInitDocument));
        dispatch(
          storeUpdateStoreDocumentFromList(
            updatedInitDocument,
            updatedInitDocument?.doc_type,
          ),
        );
        setDocument(updatedInitDocument);
      } else {
        setDocument(document);
      }

      setStats((prevState) => ({
        totalInSum: correctPrice(prevState?.totalInSum || 0),
        totalOutSum: correctPrice(
          Number(prevState?.totalOutSum || 0) + Number(value?.payment_price),
        ),
      }));

      setTimeout(async () => {
        await handleRefreshCashBoxes({page: 1, limit: 100});
      }, 300);

      alert('success', t('Payment'), t('Payment created success'));

      return {payment, document};
    },
    [
      documentUuid,
      handleCreate,
      initDocument,
      alert,
      t,
      dispatch,
      handleRefreshCashBoxes,
    ],
  );

  useEffect(() => {
    if (isThereContent(paymentStats)) {
      setStats(paymentStats);
    }
  }, [paymentStats]);

  useEffect(() => {
    if (document === null && isThereContent(storeDocument)) {
      setDocument(storeDocument);
    }
  }, [document, storeDocument]);

  return {
    payments,
    offset,
    limit,
    refresh,
    total,
    loading: paymentListLoading || loading,
    ...clientsParams,
    handleCreatePayment,
    handleUpdatePayment,
    handleDeletePayments,
    handleSearchPayments,
    handlePickPaymentRange,
    handleCreateStoreDocumentPrepayment,
    handleCreateStoreDocumentRefunds,
    stats,
    document: document as any,
    handleRefreshPayments,
    page,
  };
}
