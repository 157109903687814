import Button from './Button';
import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import {StyledDescription} from '../Styled/Text';
import {DownOutlined, UpOutlined} from '@ant-design/icons';

export interface CollapseProps {
  children: React.ReactNode | React.ReactNode[];
  title: (isExpanded: boolean) => string;
  height?: number;
}

const CollapseContainer = styled.div<{$height?: number; $isExpanded: boolean}>`
  display: flex;
  flex-direction: column-reverse;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  width: 100%;

  max-height: ${({$isExpanded, $height}) =>
    $isExpanded ? `${$height}px` : '0'};
`;

const ToggleButton = styled(Button)`
  width: fit-content;
  display: flex;

  &&&:hover,
  &&&:active,
  &&&:focus {
    background-color: transparent;
  }
`;

const CollapseContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

const iconStyle = css`
  color: ${({theme}) => theme.colors.disabled};
`;

const StyledUpOutlined = styled(UpOutlined)`
  ${iconStyle}
`;
const StyledDownOutlined = styled(DownOutlined)`
  ${iconStyle}
`;

export function Collapse({
  children,
  title,
  height = 1000,
}: CollapseProps): React.JSX.Element {
  const [isExpanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!isExpanded);
  };

  return (
    <CollapseContent>
      <CollapseContainer $height={height} $isExpanded={isExpanded}>
        {children}
      </CollapseContainer>
      <ToggleButton
        type="text"
        onClick={handleToggle}
        icon={isExpanded ? <StyledUpOutlined /> : <StyledDownOutlined />}>
        <StyledDescription>{title(isExpanded)}</StyledDescription>
      </ToggleButton>
    </CollapseContent>
  );
}
