import {
  ProfileDTO,
  ProfileFormDTO,
  ProfileModel,
  ProfileMapper,
} from '../internal';

export class ProfileFormMapper {
  public static toProfileDTO(profileFormDTO: ProfileFormDTO): ProfileDTO {
    return ProfileMapper.toProfileDTO(profileFormDTO as any);
  }

  public static toProfileFormDTO(profileModel: ProfileModel): ProfileFormDTO {
    return new ProfileFormDTO(profileModel as any);
  }
}
