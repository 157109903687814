import React, {useState} from 'react';
import {Col, Space} from 'antd';
import {withTranslation, WithTranslation} from 'react-i18next';
import styled from 'styled-components';
import {ClientSubscriptionTransition} from '../../../services/models';
import {
  IClientSubscriptionParams,
  IClientSubscriptionTransitionParam,
  isSubscriptionEditable,
  isSubscriptionTypeGroup,
  getSubscriptionsPayments,
} from '../../../services/api/clientSubscription';
import {
  EditClientSubscriptionButton,
  CancelClientSubscriptionButton,
} from '../Buttons';
import {ISubscriptionCancelParam} from '../../../hooks';
import {
  IClientSubscriptionProps,
  head,
  SUBSCRIPTION_STATUS,
} from '../../../services/helpers';
import {
  FlexContainer,
  StatusContainer,
  FlexLabelText,
} from '../../../components/lib/Styled';
import {
  DateFormat,
  FORMAT_DATE,
  TextFormat,
} from '../../../components/lib/Format';
import ClientSubscriptionCardCollapse from './ClientSubscriptionCardCollapse';
import {StatusTitle} from '../../../components/lib/DataDisplay';

export default withTranslation()(ClientSubscriptionCard);

export interface IClientSubscriptionCardProps extends WithTranslation {
  groupedSubscription: IClientSubscriptionProps[];
  clientUuid: string;
  companyUuid: string;
  handleUpdate: (value: IClientSubscriptionParams) => Promise<void>;
  handleTransition: (
    value: IClientSubscriptionTransitionParam,
  ) => Promise<ClientSubscriptionTransition>;
  handleCancel: (ids: ISubscriptionCancelParam[]) => Promise<void>;
}

const SubscriptionCard = styled(FlexContainer)`
  background-color: ${({theme}) => theme.colors.app};
  margin-bottom: 15px;
  overflow: hidden;
`;

const SubscriptionCardBody = styled(FlexContainer)`
  padding: 15px;
`;

const ButtonFlexContainer = styled(FlexContainer)`
  width: auto;
`;

const StyledStatusTitle = styled(TextFormat)`
  color: ${({theme}) => theme.colors.app};
`;

function ClientSubscriptionCard({
  groupedSubscription,
  clientUuid,
  companyUuid,
  handleUpdate,
  handleTransition,
  handleCancel,
  t,
}: IClientSubscriptionCardProps) {
  const [subscription] = useState<IClientSubscriptionProps>(
    head(groupedSubscription),
  );

  const [subscriptionService] = useState<any>(
    isSubscriptionTypeGroup(subscription?.type)
      ? subscription.subscriptionable
      : subscription?.subscriptionable?.service,
  );

  return (
    <Col span={24} style={{marginBottom: '10px'}}>
      <SubscriptionCard flexDirection="column">
        <StatusContainer
          status={subscription?.status}
          justifyContent="space-between"
          alignItems="center">
          <StatusTitle
            statusPattern="[]"
            status={subscription?.status as SUBSCRIPTION_STATUS}>
            <StyledStatusTitle>
              {t(subscriptionService?.title)}
            </StyledStatusTitle>
          </StatusTitle>
          <ButtonFlexContainer justifyContent="center" alignItems="center">
            <Space>
              <EditClientSubscriptionButton
                handleTransition={handleTransition}
                subscription={subscription}
                clientUuid={clientUuid}
                companyUuid={companyUuid}
                onSuccess={handleUpdate}>
                {t('Edit')}
              </EditClientSubscriptionButton>

              <CancelClientSubscriptionButton
                editable={!isSubscriptionEditable(subscription?.status)}
                onSuccess={handleCancel}
                subscriptions={[subscription]}
              />
            </Space>
          </ButtonFlexContainer>
        </StatusContainer>
        <SubscriptionCardBody flexDirection="column">
          <FlexContainer flexWrap="wrap">
            <FlexLabelText label={`${t('Description')}:`}>
              <span>{subscriptionService?.description}</span>
            </FlexLabelText>
            <FlexLabelText label={`${t('Start date')}:`} alignItems="flex-end">
              <DateFormat format={FORMAT_DATE.FULL}>
                {subscription?.start_date}
              </DateFormat>
            </FlexLabelText>
            <FlexLabelText label={`${t('End date')}:`}>
              <DateFormat format={FORMAT_DATE.FULL}>
                {subscription?.end_date}
              </DateFormat>
            </FlexLabelText>
          </FlexContainer>
          <ClientSubscriptionCardCollapse
            payments={getSubscriptionsPayments(groupedSubscription)}
          />
        </SubscriptionCardBody>
      </SubscriptionCard>
    </Col>
  );
}
