import {Record, List} from 'immutable';
import {ListModel, ListProps} from './List';
import ClientsPersonalAccounts, {
  IClientsPersonalAccountsProps,
} from './ClientsPersonalAccounts';

export interface IClientsPersonalAccountListProps extends ListProps {
  clientAccountOperations: IClientsPersonalAccountsProps[];
}

export interface IClientsPersonalAccountListReturnType extends ListProps {
  clientAccountOperations: List<ClientsPersonalAccounts>;
}

/**
 * @class ClientsPersonalAccountList
 * @classdesc ClientsPersonalAccounts list model.
 * @extends {Record}
 * @property {List} clientAccountOperations - list of sessions
 */
export default class ClientsPersonalAccountList extends Record<IClientsPersonalAccountListReturnType>(
  {
    clientAccountOperations: List(),
    ...ListModel.toJS(),
  } as any,
) {
  /**
   * @constructor
   * @param {{clientAccountOperations:[]}} param0
   */
  constructor({
    clientAccountOperations = [],
    ...props
  }: IClientsPersonalAccountListProps) {
    super({
      clientAccountOperations: List(
        clientAccountOperations.map(
          (accounts) => new ClientsPersonalAccounts(accounts),
        ),
      ),
      ...props,
    });
  }
}
