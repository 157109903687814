export const STATUS_ACTIVE = 1;
export const STATUS_DISABLED = 0;
export const STATUS_DELETED = -1;
export const STATUS_JUST_CREATED = -2;

export const STATUS_CONFIRMED = 'confirmed';
export const STATUS_UNCONFIRMED = 'unconfirmed';

export const STATUS_CREATED_BY_THE_SYSTEM = 8;
export const STATUS_UNCONFIRMED_RESOURCE = -9;
export const STATUS_CONFIRMED_RESOURCE = 9;
