import React from 'react';
import {Image} from 'antd';
import styled from 'styled-components';
import {UserOutlined} from '@ant-design/icons';
import {ProfileModel} from '../../../../struture';

export interface ProfileInfoProps {
  profile: ProfileModel;
  withTitle?: boolean;
  withPhone?: boolean;
  withEmail?: boolean;
  useShortName?: boolean;
}

const StyledProfileContainer = styled.div`
   {
    width: 100%;
    display: flex;
  }
`;

const StyledImage = styled(Image)`
  border-radius: 10px;
  border-width: 0;
  transition: all 0.6s;
`;

const StyledUserOutlined = styled(UserOutlined)`
  transition: all 0.6s;
`;

const StyledPlaceholder = styled.div`
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  color: rgb(109, 110, 116);
  transition: all 0.6s;
`;

const StyledUserDetailsInfo = styled.div`
   {
    display: block;
    width: 100%;
  }
`;

const StyledUserName = styled.div`
   {
    font-weight: 700;
    --tw-text-opacity: 1;
    text-transform: capitalize;
    color: rgb(107 114 128 / var(--tw-text-opacity));
  }
`;

const StyledUserBlock = styled.div`
   {
    font-size: 0.65rem;
    line-height: 0.7rem;
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
  }
`;

const StyledUserTitle = styled(StyledUserBlock)`
   {
    font-size: 0.65rem;
    line-height: 0.7rem;
  }
`;

const StyledUserEmail = styled(StyledUserBlock)`
   {
    font-size: 0.65rem;
    line-height: 0.7rem;
  }
`;

export function ProfileInfo(props?: ProfileInfoProps): React.JSX.Element {
  const {
    profile,
    withTitle = false,
    withEmail = false,
    useShortName = false,
  } = props || {};

  const profileName = useShortName ? profile?.shortFullName : profile?.fullName;
  const showProfileName =
    useShortName && profileName && profileName?.length > 15
      ? `${profileName?.substring(0, 15)}...`
      : profileName;

  return (
    <StyledProfileContainer>
      <StyledPlaceholder className="image--placeholder ">
        {profile?.picture_url ? (
          <StyledImage
            width={40}
            height={40}
            src={profile?.picture_url || undefined}
            preview={false}
            placeholder={<StyledUserOutlined />}
          />
        ) : (
          <StyledUserOutlined style={{fontSize: 18}} />
        )}
      </StyledPlaceholder>
      <StyledUserDetailsInfo>
        {withTitle ? (
          <StyledUserTitle>
            {profile?.defaultEmployeeModel?.title || '...'}
          </StyledUserTitle>
        ) : null}
        <StyledUserName>{showProfileName}</StyledUserName>
        {withEmail && profile?.email ? (
          <StyledUserEmail>{profile?.email || ''}</StyledUserEmail>
        ) : null}
      </StyledUserDetailsInfo>
    </StyledProfileContainer>
  );
}
