import React, {useCallback, useState} from 'react';
import WizardV2, {WizardStepV2, IWizardV2Props, ChangeValue} from './WizardV2';
import {
  filter,
  isFunction,
  reduce,
  buildStepsFields,
  getErrorFields,
  compose,
  head,
} from '../../../services/helpers';
import {useDefaultForm, useDropdownAlert} from '../../../contex';

import './WizardForm.less';
import {useTranslation} from 'react-i18next';

export default WizardValidate;

export type FuncStepChange = (value: ChangeValue, formData: any) => void;
export type AsyncFuncStepChange = (
  value: ChangeValue,
  formData: any,
) => Promise<void>;

export interface IWizardValidateProps
  extends Omit<
    IWizardV2Props,
    't' | 'tReady' | 'i18n' | 'errorFields' | 'steps' | 'onBeforeStepChanging'
  > {
  onSubmit?: any;
  children: any;
  onBeforeStepChanging?: FuncStepChange | AsyncFuncStepChange;
}

export interface ValidateSteps {
  fields: any[];
  key: string;
}

/**
 * @desc Enhances Wizard functionality and validates your fields on each step
 * @param steps
 * @param doneButtonText
 * @param nextButtonDisabled
 * @param saveMode
 * @param onFinish
 * @param onStepChange
 * @param onSubmit
 * @param onCancel
 * @param rest
 * @returns {*}
 * @constructor
 */
function WizardValidate({
  children,
  doneButtonText,
  nextButtonDisabled,
  isClickableSteps,
  saveMode,
  onFinish,
  onStepChange,
  onBeforeStepChanging,
  onCancel,
  onSubmit,
  ...rest
}: IWizardValidateProps) {
  const {validateFields, submit, valid, handlerUpdateFormState, formData} =
    useDefaultForm();
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const steps: any = React.Children.toArray(children).filter(
    ({props}: any) => !props?.hidden,
  );

  const [errorFields, setErrorFields] = useState<string[]>([]);

  const getValidateStepsFields = useCallback(
    (stepIndex: number): ValidateSteps => ({
      key: steps[stepIndex]?.key,
      fields: steps[stepIndex]?.props?.checkFieldsOnStepChange,
    }),
    [steps],
  );

  const handleStepChange = useCallback(
    ({newStepIndex, currentIndex}: ChangeValue): void => {
      if (isFunction(onStepChange)) {
        onStepChange({newStepIndex, currentIndex});
      }
    },
    [onStepChange],
  );

  const handleValidateBeforeStepChanging = useCallback(
    async ({newStepIndex, currentIndex, isValidate = true}: ChangeValue) => {
      try {
        if (isValidate) {
          const {fields, key} = getValidateStepsFields(currentIndex);

          await validateFields(fields);

          setErrorFields((prevState) =>
            filter(((value: string) => value !== key) as any)(prevState),
          );
        }

        if (isFunction(onBeforeStepChanging)) {
          await onBeforeStepChanging({newStepIndex, currentIndex}, formData);
        }

        if (!saveMode) {
          if (newStepIndex < currentIndex) {
            return true;
          }
        }
      } catch (e: any) {
        // if (Array.isArray(e?.errorFields)) {
        //   e?.errorFields?.forEach((fields: any) => {
        //     alert('error', t('Fields validation'), head(fields?.errors));
        //   });
        // }

        handlerUpdateFormState({errorFields: e?.errorFields || []});
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onBeforeStepChanging, saveMode, validateFields],
  );

  const handleErrorCatch = useCallback(
    (e: any) => {
      const errors = e?.errorFields;

      const errorFields = compose<any>(
        reduce(getErrorFields(errors), []),
        buildStepsFields,
      )(steps);

      setErrorFields(errorFields);
    },
    [steps],
  );

  const handleFinish = async (currentIndex: number, ...args: any[]) => {
    try {
      await validateFields();

      if (isFunction(onFinish)) {
        // @ts-ignore
        onFinish(currentIndex, ...args);
      }

      if (isFunction(onSubmit)) {
        onSubmit();
      }
      submit();
    } catch (e: any) {
      handleErrorCatch(e);
    }
  };

  return (
    <>
      {valid ? (
        <div className="WizardForm">
          <WizardV2
            errorFields={errorFields}
            onBeforeStepChanging={handleValidateBeforeStepChanging}
            onStepChange={handleStepChange}
            onFinish={handleFinish}
            onCancel={onCancel}
            doneButtonText={doneButtonText}
            nextButtonDisabled={nextButtonDisabled}
            isClickableSteps={isClickableSteps}
            saveMode={saveMode}
            {...rest}>
            {children}
          </WizardV2>
        </div>
      ) : null}
    </>
  );
}

WizardValidate.Step = WizardStepV2;
