import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {StyledTitle} from '../../../components/lib/Styled';
import {textToUpperCase} from '../../../services/helpers';
import {
  VerificationInput,
  IVerificationInputProps,
} from '../../../components/lib/DataEntry';
import {Row, Col} from 'antd';
import {FullWidthSpace} from '../Show';

export default withTranslation()(VerificationView);

export interface IVerificationViewProps
  extends WithTranslation,
    Omit<IVerificationInputProps, 'onFullFill' | 'length'> {
  identifier: string | null;
  handlerVerifyCode: (code: string) => Promise<void>;
  length?: number;
  loading?: boolean;
}

function VerificationView({
  t,
  identifier,
  handlerVerifyCode,
  length = 6,
  ...rest
}: IVerificationViewProps): JSX.Element {
  return (
    <Row>
      <Col span={24}>
        <FullWidthSpace direction="vertical" size="large">
          {identifier ? (
            <StyledTitle
              fontSize={15}
              style={{
                textAlign: 'center',
                marginBottom: 10,
              }}>
              {textToUpperCase(
                identifier?.includes('@')
                  ? `${t(
                      'A confirmation code has been sent to the email address',
                    )}: ${identifier}`
                  : `${t(
                      'A confirmation code was sent via Viber/SMS to the phone number',
                    )}: ${identifier}`,
              )}
            </StyledTitle>
          ) : null}
          <VerificationInput
            {...rest}
            length={length}
            onFullFill={handlerVerifyCode}
          />
        </FullWidthSpace>
      </Col>
    </Row>
  );
}
