import {EMPLOYEE_SIGNUP_PAGE_STATE} from '../constants';
import {
  IEmployeeSignupPageState,
  employeeSignupPageInitialState,
} from '../actions';

interface SetAction {
  type: EMPLOYEE_SIGNUP_PAGE_STATE.SET_STATE;
  state: IEmployeeSignupPageState;
}

interface UpdateService {
  type: EMPLOYEE_SIGNUP_PAGE_STATE.UPDATE_STATE;
  state: Partial<IEmployeeSignupPageState>;
}

interface ResetAction {
  type: EMPLOYEE_SIGNUP_PAGE_STATE.RESET_STATE;
  state: IEmployeeSignupPageState;
}

interface Handlers {
  [EMPLOYEE_SIGNUP_PAGE_STATE.SET_STATE]: (
    state: IEmployeeSignupPageState,
    action: SetAction,
  ) => IEmployeeSignupPageState;

  [EMPLOYEE_SIGNUP_PAGE_STATE.RESET_STATE]: (
    state: IEmployeeSignupPageState,
    action: ResetAction,
  ) => IEmployeeSignupPageState;

  [EMPLOYEE_SIGNUP_PAGE_STATE.UPDATE_STATE]: (
    state: IEmployeeSignupPageState,
    action: UpdateService,
  ) => IEmployeeSignupPageState;

  DEFAULT: (state: IEmployeeSignupPageState) => IEmployeeSignupPageState;
}

const initState: IEmployeeSignupPageState = employeeSignupPageInitialState;

const handlers: Handlers = {
  [EMPLOYEE_SIGNUP_PAGE_STATE.SET_STATE]: (state, {state: value}) => ({
    ...state,
    ...value,
    loading: false,
  }),

  [EMPLOYEE_SIGNUP_PAGE_STATE.RESET_STATE]: (state, {state: value}) => ({
    ...state,
    ...value,
  }),

  [EMPLOYEE_SIGNUP_PAGE_STATE.UPDATE_STATE]: (state, {state: value}) => ({
    ...state,
    ...value,
  }),

  DEFAULT: (state: IEmployeeSignupPageState) => state,
};

export default function Service(
  state: any = initState,
  action: any,
): IEmployeeSignupPageState {
  const handler =
    handlers[action.type as EMPLOYEE_SIGNUP_PAGE_STATE] || handlers.DEFAULT;
  return handler(state, action);
}
