import {List, Record} from 'immutable';
import {
  CompanyDTO,
  EmployeeDTO,
  ProfileDTO,
  ServiceDTO,
  ProfileModel,
  CompanyModel,
  ServiceModel,
  WorkScheduleModel,
  DayOffModel,
  ProfileMapper,
  ServiceMapper,
  CompanyMapper,
  WorkScheduleMapper,
  DayOffMapper,
  InvitationDTO,
  InvitationMapper,
  InvitationModel,
  EmployeeRole,
  EmployeeStatuses,
  EmployeeServiceModel,
  EmployeeServiceMapper,
  EmployeeMapper,
  EmployeeSalaryModel,
  CompanyOrderBonusDTO,
} from '../internal';
import {WithoutRecursion, GENDER} from '../type';

export interface IEmployeeModelReturnType {
  uuid: string;
  title: string;
  picture_url: string;
  email: string;
  gender: GENDER;
  phone: string;
  birthday: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  balance: string;
  role: EmployeeRole;
  profile: ProfileModel | ProfileDTO;
  company: CompanyModel | CompanyDTO;
  services: List<ServiceModel> | ServiceDTO[];
  work_schedule: List<WorkScheduleModel>;
  days_off: List<DayOffModel>;
  invitation: List<InvitationModel> | InvitationDTO[];
  is_owner: boolean;
  status: EmployeeStatuses;
  status_text: string;
  created_at: string;
  updated_at: string;
  employee_service?: EmployeeServiceModel;
  salary_settings: List<EmployeeSalaryModel>;
  bonus_list: CompanyOrderBonusDTO[];
}

export class EmployeeModel extends Record<IEmployeeModelReturnType>({
  uuid: '',
  title: '',
  picture_url: '',
  email: '',
  gender: GENDER.MALE,
  phone: '',
  birthday: '',
  first_name: '',
  last_name: '',
  middle_name: '',
  balance: '0.00',
  role: 'manager' as EmployeeRole,
  profile: {} as ProfileModel,
  company: {} as CompanyModel,
  services: List(),
  work_schedule: List(),
  days_off: List(),
  invitation: List(),
  is_owner: false,
  status: EmployeeStatuses.Unknown,
  status_text: '',
  created_at: '',
  updated_at: '',
  employee_service: undefined,
  salary_settings: List(),
  bonus_list: [],
}) {
  constructor(
    props: EmployeeDTO = {} as EmployeeDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IEmployeeModelReturnType = {
      ...props,

      employee_service: props?.employee_service
        ? EmployeeServiceMapper.toEmployeeServiceModel(props?.employee_service)
        : undefined,
      invitation: InvitationMapper.toInvitationListModel(
        props?.invitation || [],
      ).invitation,
      services:
        withoutRecursion.indexOf(WithoutRecursion.service) !== -1
          ? props?.services
          : ServiceMapper.toServiceListModel(props?.services || [], 0, [
              WithoutRecursion.employee,
              ...withoutRecursion,
            ]).services,
      profile:
        withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
          ? props?.profile
          : ProfileMapper.toProfileModel(props?.profile, [
              WithoutRecursion.employee,
              ...withoutRecursion,
            ]),
      company:
        withoutRecursion.indexOf(WithoutRecursion.company) !== -1
          ? props?.company
          : CompanyMapper.toCompanyModel(props?.company, [
              WithoutRecursion.employee,
              ...withoutRecursion,
            ]),
      work_schedule: WorkScheduleMapper.toWorkScheduleListModel(
        props?.work_schedule || [],
      ).schedules,
      days_off: DayOffMapper.toDayOffListModel(props?.days_off, 0).days_off,
      salary_settings: EmployeeMapper.toEmployeeSalaryListModel(
        props?.salary_settings,
        props?.salary_settings?.length || 0,
      ).salary,
    };

    super(options);
  }

  get serviceListModel(): List<ServiceModel> {
    return List.isList(this.services)
      ? this.services
      : ServiceMapper.toServiceListModel(this.services).services;
  }

  get dayOffListModel(): List<DayOffModel> {
    return List.isList(this.days_off)
      ? this.days_off
      : DayOffMapper.toDayOffListModel(this.days_off, 0).days_off;
  }

  get invitationList(): List<InvitationModel> {
    return List.isList(this.invitation)
      ? this.invitation
      : InvitationMapper.toInvitationListModel(this.invitation).invitation;
  }

  get profileModel(): ProfileModel {
    return this.profile instanceof ProfileModel
      ? this.profile
      : ProfileMapper.toProfileModel(this.profile);
  }

  get companyModel(): CompanyModel {
    return this.company instanceof CompanyModel
      ? this.company
      : CompanyMapper.toCompanyModel(this.company);
  }

  get fullName(): string {
    return `${this.last_name} ${this.first_name}`;
  }

  get isAdmin(): boolean {
    return this.role === 'admin';
  }

  get isManager(): boolean {
    return this.role === 'manager';
  }
}
