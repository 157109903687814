import * as React from 'react';
import {List} from 'immutable';
import {
  useRelatedOrderList,
  IUseRelatedOrderListProps,
  IUseRelatedOrderListReturnType,
} from './useRelatedOrderList';
import {ScheduleCalendarModel} from '../struture';
import {useStateEntityList} from '../components/lib/libV2/hooks';
import useScheduleActions, {
  IUseScheduleActionsReturnType,
} from './useScheduleActions';
import {useCallback} from 'react';

export interface IUseStateRelatedOrderListProps
  extends IUseRelatedOrderListProps {}

export interface IUseStateRelatedOrderListReturnType
  extends Omit<IUseRelatedOrderListReturnType, 'entityList'>,
    IUseScheduleActionsReturnType {
  schedules: List<ScheduleCalendarModel> | null;
  handleSearchRelatedOrders: (
    value: Partial<IUseRelatedOrderListProps>,
  ) => Promise<void>;
}

export function useStateRelatedOrderList(
  {
    scheduleUuid,
    ...rest
  }: IUseStateRelatedOrderListProps = {} as IUseStateRelatedOrderListProps,
): IUseStateRelatedOrderListReturnType {
  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: serviceListLoading,
    total: serviceListTotal,
    ...relatedOrderParams
  } = useRelatedOrderList({
    scheduleUuid,
    ...rest,
  });

  const {
    entityList: schedules,
    loading,
    handleCreate,
    handleDelete,
    handleUpdate,
    setEntityList,
    total,
  } = useStateEntityList<ScheduleCalendarModel>({
    entityList,
    refresh,
    limit,
    offset,
    total: serviceListTotal,
  });

  const scheduleActions = useScheduleActions({
    handleUpdate,
    handleCreate,
    handleDelete,
  });

  const handleSearchRelatedOrders = React.useCallback(
    async ({
      offset = 0,
      limit = 10,
      keywords,
    }: Partial<IUseRelatedOrderListProps>) => {
      const relatedOrder = await refresh({offset, limit, keywords});

      if (relatedOrder) {
        setEntityList(relatedOrder.schedules);
      }
    },
    [refresh, setEntityList],
  );

  return {
    schedules,
    offset,
    limit,
    refresh,
    total,
    loading: serviceListLoading || loading,
    ...relatedOrderParams,
    ...scheduleActions,
    handleSearchRelatedOrders,
  };
}
