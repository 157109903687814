import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  Switch,
  HeaderLeft,
  CommentField,
} from '../../../components/lib/DataDisplay';
import {
  useRequiredFields,
  useStoreService,
  useStopLoading,
  useStoredCompanies,
} from '../../../hooks';
import {correctPrice, fromBooleanToNumber} from '../../../services/helpers';
import {SERVICES_REQUIRED_FIELDS} from '../../../services/api/services';
import {OverviewLayout} from '../../../components/lib/Layout';
import {StyledDescription, StyledTitle} from '../../../components/lib/Styled';
import ServiceTabPanel from '../Show/ServiceTabPanel';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import {Routes, RoutesAcl} from '../../../services/types';
import {
  EmployeeStatuses,
  ServiceMapper,
  ServiceModel,
  ServiceStatuses,
} from '../../../struture';
import {useAcl} from '../../../contex';
import {useLocation, useNavigate, useParams} from 'react-router';
import {ServicesUpdateButton} from '../Buttons';
import {Col, Row, Space} from 'antd';
import {Text} from '../../../components/lib/Format';
import styled, {useTheme} from 'styled-components';
import {IOrderRequiredFieldsWithFullName} from '../../../services/api/orders';
import {EmployeeDetailsView} from '../../Employees';
import {GroupPaymentDetailsView} from '../../Settings';
import {ServicePersonalityView, ServiceMultiSessionableView} from '../Show';
import {DetailsView} from '../../../components/lib/DataDisplay';

const StyledSwitchWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
`;

const StyledSwitch = styled(Switch)`
  margin-right: 10px;
`;

const Description = styled(StyledDescription)`
  display: block;
  width: 100%;
  text-align: right;
  margin-top: 5px;
`;

const StyledCost = styled(StyledTitle)`
  font-size: 18px;
`;

export default function ServicePage() {
  const {serviceId, tabsId} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {t} = useTranslation();
  const theme: any = useTheme();

  const {defaultCompanyUuid, defaultCompany} = useStoredCompanies();

  const {service: serviceAccess, employee: employeeAccess} = useAcl(
    (acl) => acl,
  );

  const [isServiceBlock, setIsServiceBlock] = useState<null | boolean>(null);
  const [isServiceBlockLoading, setIsServiceBlockLoading] = useState(false);

  const {
    service,
    loading: serviceLoading,
    error: serviceError,
    handleUpdateService,
    handleBlockService,
    handleUpdateServiceComment,
  } = useStoreService({
    serviceUuid: serviceId!,
    companyUuid: defaultCompanyUuid,
  });

  const handleChangeServiceStatus = useCallback(
    async (value: boolean) => {
      if (service) {
        setIsServiceBlockLoading(true);
        setIsServiceBlock(value);

        const serviceModel = await handleBlockService(
          service?.uuid,
          fromBooleanToNumber(value),
        );

        if (!(serviceModel instanceof ServiceModel)) {
          setIsServiceBlock(!value);
        }

        setIsServiceBlockLoading(false);
      }
    },
    [service, handleBlockService],
  );

  const loading = useStopLoading({
    loading: serviceLoading,
    error: serviceError,
    message: 'An error occurred during service loading',
  });

  const routes = useBreadcrumb([
    {
      path: `/${Routes.services}`,
      breadcrumbName: 'Services',
    },
    {
      path: `/${Routes.services}/${serviceId}`,
      breadcrumbName: 'Show Services',
    },
  ]);

  const changeFields = useCallback(
    (
      {manager, payment_group, price, net_price, multi_sessions, ...rest}: any,
      service: ServiceModel,
    ): IOrderRequiredFieldsWithFullName & any => {
      return {
        ...rest,
        price: (
          <StyledCost>
            {`${defaultCompany?.currency_symbol} ${correctPrice(price)}`}
          </StyledCost>
        ),
        ...(service?.net_price
          ? {
              net_price: (
                <StyledCost>
                  {`${defaultCompany?.currency_symbol} ${correctPrice(
                    net_price,
                  )}`}
                </StyledCost>
              ),
            }
          : {}),
        ...(service?.is_multi_sessionable
          ? {
              multi_sessions: (
                <ServiceMultiSessionableView multiSessions={multi_sessions} />
              ),
            }
          : {}),
      };
    },
    [defaultCompany?.currency_symbol],
  );

  const correctServiceRequiredFields = {
    ...SERVICES_REQUIRED_FIELDS,
  };

  const overviewData = useRequiredFields(
    service as any,
    correctServiceRequiredFields,
    changeFields,
  );

  const navigateGoBack = useCallback(() => {
    navigate(location?.state?.goBack || `/${Routes.app}/${Routes.services}`);
  }, [location?.state?.goBack, navigate]);

  useEffect(() => {
    if (service && isServiceBlock === null) {
      setIsServiceBlock(service?.status !== ServiceStatuses.Blocked);
    }
  }, [isServiceBlock, service]);

  return (
    <ServiceTabPanel service={service}>
      {({tabs}) => (
        <OverviewLayout
          aclItem={RoutesAcl[Routes.services]}
          headerTitle={t('Service')}
          headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
          headerRight={
            <ServicesUpdateButton
              service={ServiceMapper.toServiceFormDTO(service!, true)}
              onSuccess={handleUpdateService}
              companyUuid={defaultCompanyUuid}
            />
          }
          header={
            <Row gutter={10}>
              <Col span={24}>
                <Space direction="vertical" size="small">
                  <StyledTitle bold fontSize={20}>
                    {service?.title}
                  </StyledTitle>
                  <ServicePersonalityView service={service} />
                </Space>
              </Col>
              <Col span={24}>
                <StyledSwitchWrapper>
                  <StyledSwitch
                    onChange={handleChangeServiceStatus}
                    checked={!!isServiceBlock}
                    disabled={isServiceBlockLoading || !serviceAccess?.manage}
                  />
                  <Text>{t('Activated')}</Text>
                </StyledSwitchWrapper>
                {service?.status === ServiceStatuses.Active ? (
                  <Description>
                    {t('This option blocks the ability to use service')}
                  </Description>
                ) : null}
              </Col>
              <Col span={24}>
                <DetailsView
                  colors={[
                    service?.manager?.status === EmployeeStatuses.DELETED
                      ? theme.colors.lightError
                      : '#eae4ea',
                    '#f9dbc2',
                  ]}
                  titles={['Responsible person', 'Group payment']}>
                  {service?.manager?.uuid ? (
                    <EmployeeDetailsView employee={service?.managerModel} />
                  ) : null}

                  {service?.payment_group?.uuid ? (
                    <GroupPaymentDetailsView
                      groupPayment={service?.payment_group as any}
                    />
                  ) : null}
                </DetailsView>
              </Col>
              <Col span={24}>
                <CommentField
                  comment={service?.comment || t('Please enter note')}
                  handleUpdateComment={handleUpdateServiceComment}
                  disabled={!serviceAccess?.manage}
                />
              </Col>
            </Row>
          }
          disabled={!serviceAccess?.manage}
          editTooltip={t('Edit Service')}
          loading={loading}
          data={overviewData}
          routes={routes}
          tabsDefaultActiveKey={tabsId}
          tabs={tabs}
        />
      )}
    </ServiceTabPanel>
  );
}
