import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {ButtonProps} from 'antd/lib/button';
import {GROUP_SESSION_INITIAL_PARAM} from '../../../services/api/groupSessions';
import {useModal} from '../../../components/lib/libV2/hooks';
import {ServiceGroupSessionFormDTO, ServiceModel} from '../../../struture';
import {AddButton, SuspenseEmpty} from '../../../components/lib/DataDisplay';
import {correctPrice} from '../../../services/helpers';

const GroupSessionSideWindowLazy = React.lazy(
  () => import('../Show/GroupSessionSideWindow'),
);

export interface IGroupSessionCreateButtonProps extends ButtonProps {
  onSuccess: (value: ServiceGroupSessionFormDTO) => Promise<void>;
  loading?: boolean;
  service: ServiceModel | null;
  serviceUuid: string;
  companyUuid: string;
  onCancel?: () => void;
}

export default function GroupSessionCreateButton({
  onSuccess,
  loading,
  service,
  serviceUuid,
  companyUuid,
  onCancel,
  ...rest
}: IGroupSessionCreateButtonProps) {
  const {t} = useTranslation();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <AddButton
        tooltipTitle={t('Create group session')}
        onClick={handleOnInit}
        loading={loading}
        {...rest}
      />
      <Suspense fallback={<SuspenseEmpty />}>
        <GroupSessionSideWindowLazy
          groupSession={
            {
              ...GROUP_SESSION_INITIAL_PARAM,
              service_title: service?.title,
              manager_id: service?.manager?.uuid,
              price: correctPrice(service?.price) || '0.00',
              uuid: serviceUuid,
            } as any
          }
          onSuccess={handleSuccess}
          visible={visible}
          loading={loading}
          companyUuid={companyUuid}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}
