import React from 'react';
import styled from 'styled-components';
import {StyledDescription, StyledTitle} from '../../../components/lib/Styled';
import {useTranslation} from 'react-i18next';
import {AiOutlineUnorderedList} from 'react-icons/ai';
import {GiFamilyTree} from 'react-icons/gi';
import {ProductCategoryType} from '../../../struture';

export interface IProductCategoryViewProps {
  icon?: React.ReactNode;
  title?: string;
  view?: ProductCategoryType;
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const StyledTitleIconContainer = styled(StyledDescription)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & > :first-child {
    margin-right: 5px;
  }
`;

export function ProductCategoryView({
  title,
  icon,
  view,
  ...rest
}: IProductCategoryViewProps): React.JSX.Element {
  const {t} = useTranslation();

  return (
    <StyledTitleIconContainer {...rest}>
      {icon ? (
        icon
      ) : view === 'tree' ? (
        <GiFamilyTree size={15} />
      ) : (
        <AiOutlineUnorderedList size={15} />
      )}
      <StyledWrapper>
        <StyledTitle fontSize={13}>{t(title)}</StyledTitle>
      </StyledWrapper>
    </StyledTitleIconContainer>
  );
}
