import React from 'react';
import {HiTruck} from 'react-icons/hi';
import {StoreModel} from '../../../struture';
import {FaHandshakeSlash} from 'react-icons/fa';
import {AuditOutlined} from '@ant-design/icons';
import styled, {css, useTheme} from 'styled-components';
import {StyledTitle} from '../../../components/lib/Styled';

export interface IStoreTitleProps {
  store: StoreModel | null;
  fontSize?: number;
}

const StyledTitleContainer = styled.div<{
  $color?: string;
}>`
  display: flex;
  align-items: center;
  gap: 5px;
  ${({$color}) =>
    $color &&
    css`
      color: ${$color};
    `}
`;

export function StoreTitle({store, fontSize}: IStoreTitleProps): JSX.Element {
  return (
    <StyledTitleContainer>
      <StyledTitle fontSize={fontSize} bold>
        {store?.store_name}
      </StyledTitle>
    </StyledTitleContainer>
  );
}
