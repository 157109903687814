import {
  ClientModel,
  CompanyRequisiteDTO,
  InvoiceModel,
  InvoiceRequisite,
  invoiceRequisite,
  InvoiceType,
  StoreDocumentMultiCurrencyDTOProps,
  StorePaymentDocumentDiscountSourceType,
  StorePaymentDocumentDiscountType,
} from '../internal';
import {isThereContent, toMoment} from '@sportix/sportix-common-modules';
import {Moment} from 'moment';
import {immerable} from 'immer';

export interface InvoiceFormAdditionalParams {
  editMode?: boolean;
  client?: ClientModel | null;
}

export class InvoiceFormDTO {
  [immerable]? = true;
  public uuid: string;
  public inv_type: InvoiceType;
  public inv_date: string | Moment;
  public inv_income_number: string;
  public inv_comment: string;
  public inv_company_requisites: CompanyRequisiteDTO | undefined;
  public inv_client_requisites: CompanyRequisiteDTO | undefined;
  public inv_multi_currencies: StoreDocumentMultiCurrencyDTOProps[];
  public inv_discount_value: string;
  public inv_discount_type: StorePaymentDocumentDiscountType;
  public inv_discount_source: StorePaymentDocumentDiscountSourceType;
  public inv_client: ClientModel | string;
  public is_apply_inv_discount?: boolean;
  public is_inv_multi_currencies?: boolean;

  constructor(
    props?: InvoiceModel,
    {editMode, client}: InvoiceFormAdditionalParams = {},
  ) {
    this.uuid = props?.uuid || '';
    this.inv_type = props?.inv_type || InvoiceType.IN;
    this.inv_date = props?.inv_date
      ? toMoment(props?.inv_date)
      : toMoment(new Date());
    this.inv_income_number = props?.inv_income_number || '';
    this.inv_comment = props?.inv_comment || '';
    this.inv_discount_value = props?.inv_discount_value || '';
    this.inv_discount_source =
      props?.inv_discount_source ||
      StorePaymentDocumentDiscountSourceType.CUSTOM;
    this.inv_discount_type =
      props?.inv_discount_type || StorePaymentDocumentDiscountType.FIXED;
    this.inv_multi_currencies = props?.inv_multi_currencies || [];
    this.inv_company_requisites = isThereContent(props?.inv_company_requisites)
      ? props?.inv_company_requisites
      : undefined;
    this.inv_client_requisites = isThereContent(props?.inv_client_requisites)
      ? props?.inv_client_requisites
      : undefined;
    this.is_apply_inv_discount = !!Number(props?.inv_discount_value || 0);
    this.is_inv_multi_currencies = !!props?.inv_multi_currencies?.length;

    this.inv_client = client
      ? client
      : editMode
      ? props?.client?.uuid
        ? props?.client
        : ''
      : '';
  }
}
