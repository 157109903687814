import * as React from 'react';
import {List} from 'immutable';
import {
  useClientAbonementScheduleList,
  IUseClientAbonementScheduleListProps,
  IUseClientAbonementScheduleListReturnType,
} from './useClientAbonementScheduleList';
import {
  ScheduleCalendarModel,
  ClientAbonementScheduleFormDTO,
  ClientAbonementMapper,
  IClientAbonementModel,
  ScheduleCalendarListModel,
} from '../struture';
import {formingClientAbonementScheduleClasses} from '../services/api/client';
import {
  updateClientAbonement as storeUpdateClientAbonement,
  updateClientAbonementFromList as storeUpdateClientAbonementFromList,
} from '../store/actions';
import {useDispatch} from 'react-redux';
import {SetStateAction, useCallback, useEffect, useState} from 'react';
import {useStateEntityList} from '../components/lib/libV2/hooks';
import {DateValue} from '../services/types';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../contex';

export interface IUseStateClientAbonementScheduleListProps
  extends IUseClientAbonementScheduleListProps {
  clientUuid: string;
  isAbonementHasScheduleList: boolean;
}

export interface IClientAbonementScheduleRefreshProps
  extends Omit<
    Partial<IUseClientAbonementScheduleListProps>,
    'date_start' | 'date_end'
  > {
  isNext?: boolean;
  start: DateValue;
  end: DateValue;
}

export interface IUseStateClientAbonementScheduleListReturnType
  extends Omit<IUseClientAbonementScheduleListReturnType, 'entityList'> {
  scheduleList: List<ScheduleCalendarModel> | null;
  handleDeleteClientAbonementSchedules: (ids: string[]) => Promise<void>;
  handleSearchClientAbonementSchedules: (keywords: string) => Promise<void>;
  handleFormingClientAbonementSchedule: (
    value: ClientAbonementScheduleFormDTO,
  ) => Promise<IClientAbonementModel | void>;
  handleRefreshClientAbonementSchedules: (
    value: IClientAbonementScheduleRefreshProps,
  ) => Promise<List<ScheduleCalendarModel> | void>;
  updateClientAbonementSchedules: (
    value: IClientAbonementScheduleRefreshProps,
  ) => Promise<ScheduleCalendarListModel | void>;
  isShowClassSchedule: boolean;
  setTotal: React.Dispatch<SetStateAction<number>>;
}

export function useStateClientAbonementScheduleList(
  {
    abonementUuid,
    loadOnInit = true,
    clientUuid,
    isAbonementHasScheduleList,
    ...rest
  }: IUseStateClientAbonementScheduleListProps = {} as IUseStateClientAbonementScheduleListProps,
): IUseStateClientAbonementScheduleListReturnType {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const [total, setTotal] = useState(0);
  const [isShowClassSchedule, setIsShowClassSchedule] = useState(true);

  const dispatch = useDispatch<any>();

  const {
    entityList: scheduleList,
    loading,
    refresh,
    total: scheduleListTotal,
    limit: scheduleListLimit,
    offset,
    date_start,
    date_end,
    ...schedulesParams
  } = useClientAbonementScheduleList({
    abonementUuid,
    loadOnInit,
    ...rest,
  });

  const {
    entityList: stateSchedules,
    loading: stateLoading,
    handleUpdate,
    handleCreate,
    handleDelete,
    setEntityList,
  } = useStateEntityList<ScheduleCalendarModel>({
    entityList: scheduleList,
    refresh,
    limit: scheduleListLimit,
    offset,
    total: scheduleListTotal,
  });

  useEffect(() => {
    setIsShowClassSchedule(isAbonementHasScheduleList);
  }, [isAbonementHasScheduleList]);

  useEffect(() => {
    if (stateSchedules?.size && !isShowClassSchedule) {
      setIsShowClassSchedule(true);
    }
  }, [isShowClassSchedule, stateSchedules]);

  useEffect(() => {
    if (scheduleListTotal) {
      setTotal(scheduleListTotal);
    }
  }, [scheduleListTotal]);

  const handleDeleteClientAbonementSchedules = React.useCallback(
    async (ids: string[]) => {},
    [],
  );

  const handleSearchClientAbonementSchedules = React.useCallback(
    async (keywords: string) => {
      const scheduleListModel = await refresh({
        offset: 0,
        limit: 10,
        keywords,
      });

      if (scheduleListModel && List.isList(scheduleListModel?.schedules)) {
        setEntityList(scheduleListModel?.schedules);
        setTotal(scheduleListModel?.total || 0);
      }
    },
    [refresh, setEntityList],
  );

  const handleRefreshClientAbonementSchedules = useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = false,
      start,
      end,
    }: IClientAbonementScheduleRefreshProps) => {
      const scheduleListModel = await refresh({
        offset,
        limit,
        showLoading,
        date_start: start,
        date_end: end,
      });

      if (scheduleListModel && List.isList(scheduleListModel?.schedules)) {
        setEntityList(scheduleListModel?.schedules);
        setTotal(scheduleListModel?.total || 0);

        return scheduleListModel.schedules;
      }
    },
    [refresh, setEntityList],
  );

  const updateClientAbonementSchedules = useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = false,
      start,
      end,
    }: IClientAbonementScheduleRefreshProps) => {
      const scheduleListModel = await refresh({
        offset,
        limit,
        showLoading,
        date_start: start,
        date_end: end,
      });

      if (scheduleListModel && List.isList(scheduleListModel?.schedules)) {
        setEntityList(scheduleListModel?.schedules);
        setTotal(scheduleListModel?.total || 0);

        return scheduleListModel;
      }
    },
    [refresh, setEntityList],
  );

  const handleFormingClientAbonementSchedule = React.useCallback(
    async (value: ClientAbonementScheduleFormDTO) => {
      const clientAbonementDTO = await formingClientAbonementScheduleClasses(
        value,
        abonementUuid,
      );

      const clientAbonementModel = ClientAbonementMapper.toClientAbonementModel(
        clientAbonementDTO.abonement,
      );

      dispatch(storeUpdateClientAbonement(clientAbonementModel));
      dispatch(
        storeUpdateClientAbonementFromList(
          clientAbonementModel.abonement,
          clientUuid,
        ),
      );

      alert(
        'success',
        t('Client abonement schedule'),
        t('Client abonement schedule forming success'),
      );

      return clientAbonementModel;
    },
    [abonementUuid, alert, clientUuid, dispatch, t],
  );

  return {
    ...schedulesParams,
    scheduleList: stateSchedules,
    total,
    limit: scheduleListLimit,
    offset,
    refresh,
    loading: loading || stateLoading,
    handleDeleteClientAbonementSchedules,
    handleSearchClientAbonementSchedules,
    handleRefreshClientAbonementSchedules,
    handleFormingClientAbonementSchedule,
    date_start,
    date_end,
    isShowClassSchedule,
    setTotal,
    updateClientAbonementSchedules,
  };
}
