import moment, {Moment} from 'moment';
import {curry} from './base';
import {DateValue} from '../types';
/**
 * @desc Сортування по даті
 * @param {Object} a
 * @param {Object} b
 * @return {Number}
 * */

export function sortedNotificationByDate(a: any, b: any): number {
  const dateA: any = new Date(a.date);
  const dateB: any = new Date(b.date);
  return dateB - dateA;
}

/**
 * @desc Повернення початку та кінця вібраного періоду дати
 * @param {Array} arr
 * @return {Object}
 * */

export function getStartEndDay<T extends {dateString: string}>(arr: T[]): any {
  const sortedArr = arr.sort((a, b) => {
    if (a.dateString > b.dateString) {
      return 1;
    }
    if (a.dateString < b.dateString) {
      return -1;
    }
    return 0;
  });

  return {
    startDay: sortedArr[0],
    endDay: sortedArr[sortedArr.length - 1],
  };
}

/**
 * @desc Сортування по даті
 * @param {String} property
 * @return {Function}
 * */

export function sortedByDate(property) {
  return (a, b) => {
    const dateA: any = new Date(a[property]);
    const dateB: any = new Date(b[property]);
    return dateA - dateB;
  };
}

export const getPassedMinutesFromNow = (date: string | Date): any => {
  // @ts-ignore
  const checkHour: any = new Date() - new Date(date);

  return Number((+checkHour / (1000 * 60)) % 60);
};

export const buildDateByFormat = (date: Date | string, format = 'YYYY-MM-DD') =>
  moment(date, format);

export const toMoment = (
  date: string | Date | Moment,
  format?: string,
): Moment => moment(date, format);

export const toMomentString = curry<[string | Date | Moment, string], string>(
  (date: string | Date | Moment, format: string): string =>
    moment(date).format(format),
);

export const dateToIsoString = (date: string | Date | Moment): string =>
  moment(date).toISOString();

export const setMomentHour = ({
  hour,
  minute,
}: {
  hour: string | number;
  minute: string | number;
}): Moment => moment().set({hour: Number(hour), minute: Number(minute)});

export const addValueToDateString = (
  value: string | number,
  format: string,
  date: DateValue = new Date(),
  unit: moment.unitOfTime.DurationConstructor = 'millisecond',
) => toMomentString(moment(date).add(value, unit), format);
