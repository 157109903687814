import {Record, List} from 'immutable';
import {IListProps} from './List';
import {ClientSubscriptionDTO, ClientSubscriptionModel} from '../internal';
import {WithoutRecursion} from '../type';

export interface IClientSubscriptionListModelProps extends IListProps {
  subscriptions: ClientSubscriptionDTO[];
}

export interface IClientSubscriptionListModelReturnType extends IListProps {
  subscriptions: List<ClientSubscriptionModel>;
}

export class ClientSubscriptionListModel extends Record<IClientSubscriptionListModelReturnType>(
  {
    subscriptions: List(),
    total: 0,
  },
) {
  constructor(
    {
      subscriptions = [],
      total,
    }: IClientSubscriptionListModelProps = {} as IClientSubscriptionListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IClientSubscriptionListModelReturnType = {
      subscriptions: List(
        subscriptions.map(
          (subscription) =>
            new ClientSubscriptionModel(subscription, withoutRecursion),
        ),
      ),
      total,
    };

    super(options);
  }
}
