import React from 'react';
import {Menu, Divider} from 'antd';
import {Link} from 'react-router-dom';
import {HEADER_HEIGHT} from './const';
import {useAcl} from '../../../contex';
import {useLocation} from 'react-router';
import {LoadingOutlined} from '@ant-design/icons';
import styled, {useTheme} from 'styled-components';
import {Text} from '../../../components/lib/Format';
import {HiOutlineRectangleGroup} from 'react-icons/hi2';
import {textToUpperCase} from '../../../services/helpers';
import {MenuOpenProvider} from '../../../views/Settings/const';
import {withTranslation, WithTranslation} from 'react-i18next';
import {AclDTO, AclMapper, IAclDTOMap} from '../../../struture';
import {AclKey, Routes, RoutesAcl, RoutesTitle} from '../../../services/types';

import {
  StyledAiOutlineFileAdd,
  StyledAiOutlineOrderedList,
  StyledBsBag,
  StyledCiBoxes,
  StyledDocumentOutlined,
  StyledFaChartPie,
  StyledFaRegMoneyBill1,
  StyledIoIosMove,
  StyledLiaMoneyCheckAltSolid,
  StyledPiUserSwitchFill,
  StyledRiDeleteBin7Line,
  StyledRiPencilRulerLine,
  StyledSalesShopOutlined,
  StyledTbShoppingCartDollar,
  StyledTbTruckReturn,
  StyledTitle,
  StyledMoneyTransactions,
} from '../Styled';

import {
  StyledDashboardOutlined,
  StyledHomeOutlined,
  StyledIdcardOutlined,
  StyledOrderedListOutlined,
  StyledFileDoneOutlined,
  StyledContactsOutlined,
  StyledLockOutlined,
  StyledBsPersonVcard,
  StyledBsCardChecklist,
} from '../Styled';

import {
  IModulesMap,
  useActiveKey,
  useStoredCompanies,
  useSystemModules,
} from '../../../hooks';

import './AppMenu.less';

export default withTranslation()(AppMenu);

export interface IAppNavigationMenuProps extends WithTranslation {
  collapsed: boolean;
  companyUuid: string;
  companyTitle: string;
}

interface IMenuGroupItem {
  key?: string;
  title?: string;
}

export type MenuItem = {
  key: string;
  title: string;
  icon: React.ReactNode;
  children?: MenuItem[];
  aclItem?: AclKey;
  className?: string;
  link?: string;
  label?: any;
  group?: IMenuGroupItem;
  pos?: number;
  openWith?: MenuOpenProvider;
  withLine?: boolean;
};

export type MenuGroup = {
  title: string;
  key: string;
  children: MenuItem[];
};

interface IMenuGroupProps {
  t: any;
  companyTitle: string;
  companyUuid: string;
  acl: IAclDTOMap;
  modulesMap: IModulesMap | null;
}

const menuGroups = ({
  t,
  companyTitle,
  companyUuid,
  modulesMap,
}: IMenuGroupProps): MenuItem[] => [
  {
    key: `/${Routes.app}/${Routes.dashboard}`,
    aclItem: RoutesAcl[Routes.calendar],
    title: t('Dashboard'),
    icon: <StyledDashboardOutlined />,
  },
  {
    key: Routes.companies,
    title: t('My company'),
    icon: <HiOutlineRectangleGroup size={18} />,
    className: 'app-menu__company',
    children: [
      {
        key: `/${Routes.app}/${Routes.companies}`,
        link: `/${Routes.app}/${Routes.companies}/${companyUuid}`,
        aclItem: RoutesAcl[Routes.companies],
        title: companyTitle,
        className: 'app-menu__company',
        icon: <StyledHomeOutlined />,
      },
      {
        key: `/${Routes.app}/${Routes.clients}`,
        aclItem: RoutesAcl[Routes.clients],
        title: t('Counterparties'),
        icon: <StyledIdcardOutlined />,
      },
      {
        key: `/${Routes.app}/${Routes.services}`,
        aclItem: RoutesAcl[Routes.services],
        title: t('Services'),
        icon: <StyledFileDoneOutlined />,
      },
      ...(modulesMap?.abonement?.enabled
        ? [
            {
              key: `/${Routes.app}/${Routes.tariffModels}`,
              aclItem: RoutesAcl[Routes.tariffModels],
              title: t('Tariff models'),
              icon: <StyledBsPersonVcard />,
            },
          ]
        : []),
      {
        key: `/${Routes.app}/${Routes.goods}/${Routes.products}`,
        aclItem: RoutesAcl[Routes.products],
        title: t('Goods'),
        icon: <StyledRiPencilRulerLine />,
      },
      {
        key: `/${Routes.app}/${Routes.employees}`,
        aclItem: RoutesAcl[Routes.employees],
        title: t('Employees'),
        icon: <StyledContactsOutlined />,
      },
    ],
  },
  {
    key: `/${Routes.app}/${Routes.orders}`,
    aclItem: RoutesAcl[Routes.orders],
    title: t('Orders'),
    icon: <StyledOrderedListOutlined />,
  },

  {
    key: `/${Routes.app}/${Routes.payments}`,
    aclItem: RoutesAcl[Routes.payments],
    title: t('Cash flow'),
    icon: <StyledMoneyTransactions size={13} />,
  },

  ...(modulesMap?.sales?.enabled
    ? [
        {
          key: `/${Routes.app}/${Routes.stores}/${Routes.storeSale}`,
          aclItem: RoutesAcl[Routes.stores],
          title: t('Sales'),
          icon: <StyledSalesShopOutlined size={18} />,
        },
      ]
    : []),

  // {
  //   key: `Reporting`,
  //   title: t('Reporting'),
  //   icon: <StyledFundProjectionScreenOutlined />,
  //   items: [
  //     {
  //       key: `/${Routes.app}/reports`,
  //       title: t('Orders'),
  //       icon: <StyledOrderedListOutlined />,
  //     },
  //   ],
  // },
  // {
  //   key: `/${Routes.app}/gallery`,
  //   title: t('Gallery'),
  //   icon: <StyledPictureOutlined />,
  // },

  ...(modulesMap?.stock?.enabled
    ? [
        {
          key: Routes.stores,
          title: t('Store'),
          icon: <StyledCiBoxes size={18} />,
          className: 'app-menu__company',
          children: [
            {
              key: `Analytics`,
              aclItem: RoutesAcl[Routes.stores],
              title: t('Analytics'),
              icon: <StyledFaChartPie size={15} />,
            },
            {
              key: `/${Routes.app}/${Routes.stores}/${Routes.storePosting}`,
              aclItem: RoutesAcl[Routes.stores],
              title: t('Posting'),
              icon: <StyledAiOutlineFileAdd size={15} />,
            },
            {
              key: `/${Routes.app}/${Routes.stores}/${Routes.storeRealization}`,
              aclItem: RoutesAcl[Routes.stores],
              title: t('Expense'),
              icon: <StyledBsBag size={13} />,
            },
            {
              key: `/${Routes.app}/${Routes.stores}/${Routes.storeMoving}`,
              aclItem: RoutesAcl[Routes.stores],
              title: t('Moving'),
              icon: <StyledIoIosMove size={15} />,
            },
            {
              key: `/${Routes.app}/${Routes.stores}/${Routes.storeWriteOff}`,
              aclItem: RoutesAcl[Routes.stores],
              title: t('Write off'),
              icon: <StyledRiDeleteBin7Line />,
            },
            {
              key: `/${Routes.app}/${Routes.stores}/${Routes.storeReturnClient}`,
              aclItem: RoutesAcl[Routes.stores],
              title: t('Return to client'),
              icon: <StyledPiUserSwitchFill size={15} />,
            },
            {
              key: `/${Routes.app}/${Routes.stores}/${Routes.storeReturn}`,
              aclItem: RoutesAcl[Routes.stores],
              title: t('Return to supplier'),
              icon: <StyledTbTruckReturn size={15} />,
            },
            {
              key: `/${Routes.app}/${Routes.stores}/${Routes.storeOrderProvider}`,
              aclItem: RoutesAcl[Routes.stores],
              title: t('Order to supplier'),
              icon: <StyledTbShoppingCartDollar size={15} />,
            },
            {
              key: `/${Routes.app}/${Routes.stores}/${Routes.storeLeftovers}`,
              aclItem: RoutesAcl[Routes.stores],
              title: t('Leftovers'),
              icon: <StyledAiOutlineOrderedList size={15} />,
            },
          ],
        },
      ]
    : []),

  ...(modulesMap?.document?.enabled
    ? [
        {
          key: Routes.documents,
          title: t(RoutesTitle[Routes.documents]),
          icon: <StyledDocumentOutlined />,
          className: 'app-menu__company',
          children: [
            {
              key: `/${Routes.app}/${Routes.invoices}`,
              aclItem: RoutesAcl[Routes.invoices],
              title: t(RoutesTitle[Routes.invoices]),
              icon: <StyledLiaMoneyCheckAltSolid size={16} />,
            },
          ],
        },
      ]
    : []),

  // {
  //   key: `/${Routes.app}/${Routes.reports}`,
  //   aclItem: RoutesAcl[Routes.reports],
  //   title: t('Reports'),
  //   icon: <StyledFileProtectOutlined />,
  // },
  // {
  //   key: `/${Routes.app}/${Routes.categories}`,
  //   title: t('Categories'),
  //   icon: <StyledContainerOutlined />,
  // },
  // {
  //   key: `/${Routes.app}/${Routes.users}`,
  //   title: t('Users'),
  //   icon: <StyledUserOutlined />,
  // },
  // {
  //   key: `/${Routes.app}/${Routes.settings}`,
  //   link: `/${Routes.app}/${Routes.settings}/${SETTING_TABS.PARAMETERS}`,
  //   title: t('Settings'),
  //   icon: <StyledSettingOutlined />,
  // },

  // {
  //   key: `/${Routes.app}/stoke`,
  //   title: t('Stoke'),
  //   icon: <StyledProjectOutlined />,
  //   items: [
  //     {
  //       key: `/${Routes.app}/stoke/counterparties`,
  //       link: `/${Routes.app}/stoke/counterparties`,
  //       title: t('Counterparties'),
  //       icon: <StyledAppstoreOutlined />,
  //     },
  //     {
  //       key: `/${Routes.app}/stoke/goods`,
  //       link: `/${Routes.app}/stoke/goods`,
  //       title: t('Goods'),
  //       icon: <StyledCarryOutOutlined />,
  //     },
  //     {
  //       key: `/${Routes.app}/stoke/revenue-invoices`,
  //       link: `/${Routes.app}/stoke/revenue-invoices`,
  //       title: t('Revenue invoices'),
  //       icon: <StyledFileDoneOutlined />,
  //     },
  //     {
  //       key: `/${Routes.app}/stoke/expense-invoices`,
  //       link: `/${Routes.app}/stoke/expense-invoices`,
  //       title: t('Expense invoices'),
  //       icon: <StyledFileProtectOutlined />,
  //     },
  //     {
  //       key: `/${Routes.app}/stoke/bill-of-lading`,
  //       link: `/${Routes.app}/stoke/bill-of-lading`,
  //       title: t('Bill of lading'),
  //       icon: <StyledFileExclamationOutlined />,
  //     },
  //   ],
  // },

  ...(modulesMap?.abonement?.enabled
    ? [
        {
          key: `/${Routes.app}/${Routes.clientAbonements}`,
          link: `/${Routes.app}/${Routes.clientAbonements}/${Routes.companies}/${companyUuid}`,
          aclItem: RoutesAcl[Routes.clientAbonements],
          title: t('Abonements'),
          icon: <StyledBsCardChecklist />,
        },
      ]
    : []),
];

const StyledDivider = styled(Divider)`
  height: 1px;
  background-color: ${({theme}) => theme.colors.accent};
  margin: 10px 0;
`;

const StyledMenu = styled(Menu)`
  background-color: transparent;
  overflow-y: auto;

  &&& {
    border-inline-end: none;
  }

  &&& .ant-menu-item {
    display: flex;
    align-items: center;
  }

  &&& .ant-menu-item:hover {
    transition: none;

    & > .ant-menu-title-content {
      transition: none;
    }
  }

  &&& .ant-menu-submenu-selected > .ant-menu-submenu-title {
    background-color: ${({theme}) => theme.colors.link};
    opacity: 0.5;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledContainer = styled.div`
  padding-top: ${HEADER_HEIGHT}px;
`;

const StyledHeaderMenuTitle = styled.div``;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  font-size: 30px;
`;

const StyledLoadingContainer = styled.div`
  position: absolute;
  top: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

function AppMenu({
  t,
  collapsed,
  companyUuid,
  companyTitle,
}: IAppNavigationMenuProps) {
  const location = useLocation();
  const theme: any = useTheme();
  const {settings} = useStoredCompanies();
  const acl = useAcl((acl) => acl);
  const {modulesMap} = useSystemModules();
  const [activeKey] = useActiveKey();

  const getMenuNestedItem = (children: MenuItem[], isLower = false): any =>
    children.map(
      ({key, icon, className, link, title, children: nestedItem, aclItem}) => {
        const {read: r} = aclItem
          ? acl[aclItem]
          : AclMapper.toAclModel({} as AclDTO);

        const read = r && key !== 'Analytics';

        const isChildren =
          isLower && key !== `/${Routes.app}/${Routes.companies}`;

        return {
          title: title ? (
            collapsed ? (
              <StyledDivider />
            ) : isChildren ? (
              title
            ) : (
              textToUpperCase(title)
            )
          ) : null,
          key,
          disabled: !read,
          icon: icon ? read ? icon : <StyledLockOutlined /> : null,
          label: title ? (
            nestedItem ? (
              <Text muted={!read}>
                {isChildren ? <title /> : textToUpperCase(title)}
              </Text>
            ) : (
              <Link
                to={read ? link || key : location.pathname}
                className={className}
                state={{aclItem: aclItem ? acl[aclItem] : undefined}}>
                <Text muted={!read}>
                  {isChildren ? title : textToUpperCase(title)}
                </Text>
              </Link>
            )
          ) : null,
          children: nestedItem ? getMenuNestedItem(nestedItem, true) : null,
        };
      },
    );

  return (
    <StyledContainer>
      <StyledHeaderMenuTitle>
        {collapsed ? (
          <StyledDivider />
        ) : (
          <StyledTitle color={theme.colors.primary}>
            {textToUpperCase(t('Navigation'))}
          </StyledTitle>
        )}
      </StyledHeaderMenuTitle>
      {!settings?.settings ? (
        <StyledLoadingContainer>
          <StyledLoadingOutlined />
        </StyledLoadingContainer>
      ) : (
        <StyledMenu
          rootClassName="app-menu--popup"
          multiple
          mode="vertical"
          defaultOpenKeys={[]}
          defaultSelectedKeys={[activeKey]}
          selectedKeys={[activeKey]}
          items={getMenuNestedItem(
            menuGroups({t, companyUuid, companyTitle, acl, modulesMap}),
          )}
        />
      )}
    </StyledContainer>
  );
}
