import {RewardModel} from '../internal';
import {correctPrice, toMoment} from '../../helpers';
import {Moment} from 'moment';

export class RewardFormDTO {
  public uuid: string;

  public sum: string;

  public comment: string;

  public date: string | Moment;

  constructor(props?: RewardModel, editMode?: boolean) {
    this.uuid = props?.uuid || '';
    this.comment = '';
    this.date = toMoment(new Date());
    this.sum = correctPrice(0);
  }
}
