import React, {forwardRef, useEffect, useState} from 'react';
import styled from 'styled-components';
import {StyledTitle} from '../../../../components/lib/Styled';
import {useTranslation} from 'react-i18next';
import {OverviewBottomView} from '../../../../components/lib/Layout';
import {Button} from '../../../../components/lib/DataDisplay';
import {isFunction} from '../../../../services/helpers';
import {StoreDocumentStatus} from '../../../../struture';
import {useDefaultForm} from '../../../../contex';
import {LoadingOutlined, PrinterOutlined} from '@ant-design/icons';
import {Form, Select} from 'antd';
import {useStoredCompanies} from '../../../../hooks';

export interface IPriceTagBottomViewProps {}

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  gap: 2px;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 2px;
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 15px;
`;

const StyledPrice = styled(StyledTitle)`
  font-size: 30px;
`;

const StyledPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 5px;
`;

const StyledSubmitButton = styled(Button)`
  width: fit-content;
`;

const StyledSelect = styled(Select)`
  min-width: 200px;
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;
`;

export const PriceTagBottomView = forwardRef(function PriceTagBottomView(
  {}: IPriceTagBottomViewProps,
  ref: any,
): React.JSX.Element {
  const {t} = useTranslation();
  const {handlerUpdateFormState, submit, formData, loadingSubmit} =
    useDefaultForm();
  const {defaultCompany} = useStoredCompanies();

  const [priceTagSize, setPriceTagSize] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  useEffect(() => {
    if (defaultCompany?.price_tag_formats?.length) {
      const selectValues = defaultCompany?.price_tag_formats?.map((value) => {
        return {
          value,
          label: value,
        };
      });

      setPriceTagSize(selectValues);
    }
  }, [defaultCompany?.price_tag_formats]);

  return (
    <>
      <div ref={ref}>
        <OverviewBottomView>
          {formData?.items?.length ? (
            <StyledButtonWrapper>
              <StyledSubmitButton
                className="price-tag-submit"
                disabled={loadingSubmit}
                type="primary"
                onClick={() => {
                  if (isFunction(handlerUpdateFormState)) {
                    handlerUpdateFormState({
                      doc_status: StoreDocumentStatus.ACTIVE,
                      is_final_save: false,
                    });
                  }

                  if (isFunction(submit)) {
                    submit();
                  }
                }}
                icon={
                  loadingSubmit ? <LoadingOutlined /> : <PrinterOutlined />
                }>
                {t('Printing')}
              </StyledSubmitButton>
              <StyledFormItem name="tag_size">
                <StyledSelect
                  disabled={loadingSubmit}
                  allowClear
                  placeholder={t('Select price tag size')}
                  showSearch
                  options={priceTagSize}
                  onChange={(tag_size) => handlerUpdateFormState({tag_size})}
                />
              </StyledFormItem>
            </StyledButtonWrapper>
          ) : null}

          <StyledWrapper>
            <StyledContainer>
              <StyledTitle fontSize={14}>{`${t(
                'Number of selected products',
              )}: `}</StyledTitle>
              <StyledPriceContainer>
                <StyledPrice>{`${formData?.total || 0}`}</StyledPrice>
              </StyledPriceContainer>
            </StyledContainer>
          </StyledWrapper>
        </OverviewBottomView>
      </div>
    </>
  );
});
