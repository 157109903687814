import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {OverviewLayout} from '../../../components/lib/Layout';
import {
  useRequiredFields,
  useStopLoading,
  useStoredClient,
  useStoredCompanies,
} from '../../../hooks';
import {textToUpperCase} from '../../../services/helpers';
import {
  CLIENT_LEGAL_REQUIRED_FIELD,
  CLIENT_REQUIRED_FIELD,
} from '../../../services/api/client';
import ClientTabPanel from '../Show/ClientTabPanel';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import {
  ClientPersonalityType,
  Routes,
  RoutesAcl,
} from '../../../services/types';
import {useAcl} from '../../../contex';
import {useLocation, useNavigate, useParams} from 'react-router';
import {ClientEditButton} from '../Buttons';
import {CommentField, HeaderLeft} from '../../../components/lib/DataDisplay';
import {ClientHeader} from '../Show';
import {ClientMapper, ClientModel, ClientOrgType} from '../../../struture';
import {StyledTitle} from '../../../components/lib/Styled';
import {FaUsers} from 'react-icons/fa';
import styled from 'styled-components';

const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export default function ClientPage(): JSX.Element {
  const {clientId} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {t} = useTranslation();

  const {defaultCompanyUuid} = useStoredCompanies();
  const {manage} = useAcl(({client}) => client);
  const {client: clientAccess} = useAcl((acl) => acl);

  const {
    client,
    error: clientError,
    handleUpdateClient,
    loading: clientLoading,
    handleUploadClientImage,
    handleUpdateClientGroup,
    handleUpdateClientComment,
  } = useStoredClient({
    clientUuid: clientId!,
    companyUuid: defaultCompanyUuid,
  });

  const isIndividualType =
    client?.client_type === ClientPersonalityType.Individual;

  const isGroupType = client?.client_type === ClientPersonalityType.Group;

  const loading = useStopLoading({
    loading: clientLoading,
    error: clientError,
    message: 'An error occurred during client loading',
  });

  const routes = useBreadcrumb([
    {
      path: `/${Routes.clients}`,
      breadcrumbName: isIndividualType ? 'Counterparties' : 'Clients groups',
      state: {
        type: isIndividualType
          ? ClientPersonalityType.Individual
          : ClientPersonalityType.Group,
      },
    },
    {
      path: `/${Routes.clients}/${clientId}`,
      breadcrumbName: isIndividualType
        ? client?.fullNameClient || 'Show Client'
        : client?.client_group_name || 'Show clients group',
    },
  ]);

  const changeFields = useCallback(
    (
      {
        client_discount_type,
        client_discount_goods_type,
        client_org_type,
        client_comment,
        client_org_name,
        ...rest
      }: any,
      client: ClientModel | null,
    ) => {
      return {
        ...rest,
      };
    },
    [],
  );

  const overviewData = useRequiredFields(
    client,
    client?.client_org_type === ClientOrgType.PERSON
      ? CLIENT_REQUIRED_FIELD
      : CLIENT_LEGAL_REQUIRED_FIELD,
    changeFields,
  );

  const navigateGoBack = useCallback(() => {
    navigate(location?.state?.goBack || `/${Routes.app}/${Routes.clients}`, {
      state: {
        type: isIndividualType
          ? ClientPersonalityType.Individual
          : ClientPersonalityType.Group,
      },
    });
  }, [isIndividualType, location?.state?.goBack, navigate]);

  return (
    <ClientTabPanel
      client={client}
      isIndividualType={isIndividualType}
      isGroupType={isGroupType}
      clientFullName={client?.fullNameClient || ''}
      timeRange={client ? client?.company?.work_schedules : ''}
      handleUpdateClientGroup={handleUpdateClientGroup}>
      {({tabs}) => (
        <OverviewLayout
          aclItem={RoutesAcl[Routes.clients]}
          headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
          headerRight={
            client?.client_strict_mode ? null : (
              <ClientEditButton
                clientType={client?.client_type!}
                client={
                  isIndividualType
                    ? client?.client_org_type === ClientOrgType.PERSON
                      ? ClientMapper.toClientFormDTO(client!)
                      : ClientMapper.toClientLegalFormDTO(client)
                    : ClientMapper.toClientGroupFormDTO(client!, true)
                }
                onSuccess={handleUpdateClient}
                onSuccessGroup={handleUpdateClientGroup}
                disabled={loading}
              />
            )
          }
          headerTitle={textToUpperCase(
            isIndividualType ? t('Counterparties') : t('Client group'),
          )}
          disabled={!manage}
          loading={loading}
          data={isIndividualType ? overviewData : null}
          routes={routes}
          tabs={tabs}
          header={
            <>
              {isIndividualType ? (
                <ClientHeader
                  client={client}
                  handleUploadImage={handleUploadClientImage}
                />
              ) : (
                <StyledTitleContainer>
                  <FaUsers size={20} />
                  <StyledTitle bold fontSize={20}>
                    {client?.client_group_name}
                  </StyledTitle>
                </StyledTitleContainer>
              )}
              <CommentField
                comment={client?.client_comment || t('Please enter note')}
                handleUpdateComment={handleUpdateClientComment}
                disabled={!clientAccess?.manage}
              />
            </>
          }
        />
      )}
    </ClientTabPanel>
  );
}
