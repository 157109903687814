import {List} from 'immutable';
import {
  ScheduleStatusDTO,
  IScheduleStatusDTOProps,
  ScheduleStatusModel,
  ScheduleStatusListModel,
} from '../internal';
import {listToArray} from '../../services/helpers';

export interface IScheduleStatusListDTO {
  statuses: ScheduleStatusDTO[];
  total: number;
}

export class ScheduleStatusMapper {
  public static toScheduleStatusDTO(
    status: ScheduleStatusModel | IScheduleStatusDTOProps,
  ): ScheduleStatusDTO {
    return new ScheduleStatusDTO({
      reason: status?.reason,
      status_text: status?.status_text,
      status_color: status?.status_color,
      created_at: status?.created_at,
      created_by: status?.created_by,
    });
  }

  public static toScheduleStatusListDTO(
    statuses: List<ScheduleStatusModel> | IScheduleStatusDTOProps[],
    total?: number,
  ): IScheduleStatusListDTO {
    const statusList = List.isList(statuses)
      ? listToArray<ScheduleStatusModel>(statuses)
      : statuses;

    return {
      statuses: statusList?.map((status) =>
        ScheduleStatusMapper.toScheduleStatusDTO(status),
      ),
      total: total || 0,
    };
  }

  public static toScheduleStatusModel(
    statusDTO: ScheduleStatusDTO,
  ): ScheduleStatusModel {
    return new ScheduleStatusModel(statusDTO);
  }

  public static toScheduleStatusListModel(
    statusDTOs: ScheduleStatusDTO[],
    total: number,
  ): ScheduleStatusListModel {
    return new ScheduleStatusListModel({statuses: statusDTOs, total});
  }
}
