import {apiGet, apiPost} from '../core/api';
import {contentApiUrl} from '../const';
import {
  ListOptions,
  IGoogleCalendarProps,
  IGoogleCalendarListProps,
} from '../helpers';
import {GoogleCalendarList, GoogleCalendar} from '../models';
import {ApiAnswer} from '../types';
import Validate from '../validate/Validate';

export interface IGoogleCalendarParams
  extends Pick<GoogleCalendar, 'calendar_name'> {
  calendar_code: string;
  calendar_service_id: string;
  calendar_client_id: string;
  calendar_employee_id: string;
  companyUuid?: string;
}

export const GOOGLE_CALENDAR_PARAM: IGoogleCalendarParams = {
  calendar_name: '',
  calendar_code: '',
  calendar_service_id: '',
  calendar_client_id: '',
  calendar_employee_id: '',
};

export interface IGetGoogleCalendarAnswer
  extends ApiAnswer,
    IGoogleCalendarListProps {}

export interface IGetGoogleCalendarAuthTokenAnswer extends ApiAnswer {
  auth_url: string;
}

export interface IRegisterGoogleCalendarAnswer extends ApiAnswer {
  token: IGoogleCalendarProps;
}

export async function getGoogleCalendarList(
  companyUuid: string,
  listOptions = new ListOptions(),
): Promise<GoogleCalendarList> {
  Validate.string({value: companyUuid});

  const {offset = 0, limit = 100} = listOptions.toObject();

  const res = await apiGet<null, IGetGoogleCalendarAnswer>(
    `${contentApiUrl}/companies/${companyUuid}/calendar-tokens?limit=${limit}&offset=${offset}`,
  );

  return new GoogleCalendarList(res);
}

export async function getGoogleCalendarAuthToken(): Promise<string> {
  const {auth_url} = await apiGet<null, IGetGoogleCalendarAuthTokenAnswer>(
    `${contentApiUrl}/calendar/auth-url`,
  );

  return auth_url;
}

export async function registerGoogleCalendar({
  calendar_name,
  calendar_code,
  calendar_client_id,
  calendar_employee_id,
  calendar_service_id,
  companyUuid,
}: IGoogleCalendarParams): Promise<GoogleCalendar> {
  Validate.string({value: companyUuid});
  Validate.string({value: calendar_name});
  Validate.string({value: calendar_code});
  Validate.string({value: calendar_client_id});
  Validate.string({value: calendar_employee_id});
  Validate.string({value: calendar_service_id});

  const {token} = await apiPost<
    IGoogleCalendarParams,
    IRegisterGoogleCalendarAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/auth-calendar`, {
    calendar_name,
    calendar_code,
    calendar_client_id,
    calendar_employee_id,
    calendar_service_id,
  });

  return new GoogleCalendar(token);
}
