import React, {useMemo, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Col, Form, Input, Row} from 'antd';
import {
  IDefaultFormProps,
  DefaultForm,
  PhoneInputComponent as PhoneInput,
} from '../../../components/lib/General';
import {ScheduleMessageFormDTO} from '../../../struture';
import {useDropdownAlert} from '../../../contex';
import {
  IUseStateScheduleReturnType,
  useStateTemplateList,
  useStoredCompanies,
} from '../../../hooks';
import {SearchSelect} from '../../../components/lib/DataDisplay';
import {StyledDescription} from '../../../components/lib/Styled';
import {isListToArray} from '../../../services/helpers';
import {LoadingOutlined} from '@ant-design/icons';

export interface IOrderMessageFormProps
  extends Omit<
      IDefaultFormProps<ScheduleMessageFormDTO, ScheduleMessageFormDTO>,
      'children' | 'initialValues' | 'additionalValuesRequest'
    >,
    Pick<IUseStateScheduleReturnType, 'handleFormingScheduleMessage'> {
  loading?: boolean;
  message: ScheduleMessageFormDTO;
}

export function OrderMessageForm({
  loading,
  editMode,
  message,
  onCancel,
  handleFormingScheduleMessage,
  ...rest
}: IOrderMessageFormProps) {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const {defaultCompanyUuid} = useStoredCompanies();
  const [instance] = Form.useForm();

  const [searchLoading, setSearchLoading] = useState(false);

  const messageText = Form.useWatch('message', instance);

  const additionalValues = useMemo(
    () => ({
      uuid: message?.uuid,
      type: message?.type,
    }),
    [message?.type, message?.uuid],
  );

  const {
    templates: templateList,
    loading: templateListLoading,
    refresh,
  } = useStateTemplateList({
    companyUuid: defaultCompanyUuid,
    limit: 50,
  });

  const handleSearchTemplateList = useCallback(
    async (keywords: string) => {
      setSearchLoading(true);
      await refresh({keywords});
      setSearchLoading(false);
    },
    [refresh],
  );

  const formingScheduleMessage = useCallback(
    async (templateUuid: string) => {
      if (templateUuid) {
        setSearchLoading(true);
        const message = await handleFormingScheduleMessage(templateUuid);

        if (message) {
          instance.setFieldsValue({message});
        }

        setSearchLoading(false);
      }
    },
    [handleFormingScheduleMessage, instance],
  );

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Schedule message'),
        `${t('An error occurred during sending schedule message')} : ${
          apiError?.message
        }`,
      );
    },
    [alert, t],
  );

  return (
    <DefaultForm<any, any>
      instance={instance}
      editMode={editMode}
      initialValues={{
        ...message,
      }}
      additionalValuesRequest={additionalValues}
      showNotify={false}
      onCancel={onCancel}
      submitButtonText={t('Send')}
      notifyError={notifyError}
      {...rest}>
      {({loadingSubmit, setFieldsValue}) => (
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              label={t('Phone')}
              name="addressees"
              rules={[
                {
                  required: true,
                  message: t('Phone must be specified.'),
                },
              ]}>
              <PhoneInput
                placeholder={t('Enter a phone')}
                id="field-phone"
                containerClass="field-phone-container"
                searchClass="field-phone-search"
                inputClass="ant-input field-phone"
                buttonClass="field-phone-flag"
                loading={loading || loadingSubmit}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              tooltip={t('Template')}
              label={t('Template')}
              name="template_uuid"
              // rules={[
              //   {
              //     required: true,
              //     message: t('Template must be specified.'),
              //   },
              // ]}
            >
              <SearchSelect
                suffixIcon={searchLoading ? <LoadingOutlined /> : undefined}
                selectFirst
                name="template_uuid"
                placeholder={t('Select a template')}
                getOptionValueProps="template_uuid"
                getOptionValueTitle="template_title"
                disable={
                  loading ||
                  loadingSubmit ||
                  templateListLoading ||
                  searchLoading
                }
                data={isListToArray(
                  templateList.filter(
                    (item) => item.template_type === 'sms',
                  ) as any,
                )}
                onSearch={handleSearchTemplateList}
                onChange={formingScheduleMessage}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('Message')}
              name="message"
              rules={[
                {
                  required: true,
                  message: t('Message must be specified.'),
                },
              ]}
              extra={
                messageText?.length ? (
                  <StyledDescription style={{whiteSpace: 'unset'}}>
                    {`${t('Length')}: ${messageText?.length} ${t('symbols')}`}
                  </StyledDescription>
                ) : null
              }>
              <Input.TextArea
                allowClear
                autoSize={{minRows: 3, maxRows: 10}}
                disabled={loading || loadingSubmit}
                placeholder={t('Enter a message')}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </DefaultForm>
  );
}
