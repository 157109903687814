import {Record} from 'immutable';

export interface IVariable {
  variable_name: string;
  variable_comment: string;
}

export interface IEventProps {
  event_key: string;
  event_title: string;
  event_variables: IVariable[];
}

/**
 * @class Company
 * @classdesc
 * @extends {Record}
 * @property {string} id - backup id
 */
export default class Event extends Record({
  event_key: '',
  event_title: '',
  event_variables: [],
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(props: IEventProps = {} as IEventProps) {
    super({
      ...props,
    });
  }
}
