import {Form} from 'antd';
import {produce} from 'immer';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../../../../contex';
import React, {useCallback, useMemo, useRef} from 'react';
import {ClientPersonalityType} from '../../../../services/types';
import {StoreSaleConductFormFields} from './StoreSaleConductFormFields';
import {ENTER_KEYS} from '../../../../services/const';

import {
  DefaultForm,
  IDefaultFormProps,
} from '../../../../components/lib/General';

import {
  useStoredSaleForm,
  useStoredCompanies,
  useStoredCashBoxList,
  useStateCompanyClientList,
} from '../../../../hooks';

import {
  CashBoxModel,
  PAYMENT_METHOD,
  StorePaymentDocumentFormDTO,
} from '../../../../struture';

import {isFunction} from '../../../../services/helpers';

export interface IStoreSaleConductFormProps
  extends Pick<
    IDefaultFormProps<StorePaymentDocumentFormDTO, StorePaymentDocumentFormDTO>,
    | 'showFooter'
    | 'showNotify'
    | 'editMode'
    | 'className'
    | 'additionalValuesRequest'
  > {
  loading?: boolean;
  onCancel?: () => void;
  onSuccess: (value: StorePaymentDocumentFormDTO) => Promise<void>;
  onError?: () => Promise<void>;
  payment: StorePaymentDocumentFormDTO;
  isShowFiscalCashboxesWarning: boolean;
}

/**
 * @desc Створенння співробітника
 * */
export function StoreSaleConductForm({
  loading,
  editMode,
  payment,
  onCancel,
  onSuccess,
  isShowFiscalCashboxesWarning,
  ...rest
}: IStoreSaleConductFormProps) {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const {defaultCompanyUuid} = useStoredCompanies();
  const [instance] = Form.useForm();

  const cashBoxRef = useRef<any>(null);
  const clientRef = useRef<any>(null);
  const inputRef = useRef<any>(null);
  const commentRef = useRef<any>(null);
  const once = useRef(false);

  const paymentMethod = Form.useWatch('payment_method', instance);
  const paymentClientUuid = Form.useWatch('payment_client_uuid', instance);
  const paymentDocComment = Form.useWatch('payment_doc_comment', instance);

  const additionalValues = useMemo(
    () => ({
      uuid: payment?.uuid,
      payment_post_action: payment?.payment_post_action,
    }),
    [payment?.payment_post_action, payment?.uuid],
  );

  const {
    clients,
    loading: clientsLoading,
    handleSearchClients,
    handleCreateClient,
    handleCreateClientLegal,
  } = useStateCompanyClientList({
    loadOnInit: true,
    companyUuid: defaultCompanyUuid,
    limit: 100,
    client_type: ClientPersonalityType.Individual,
  });

  const {storedSalesFormToLocalStorage, setCompanySaleFormDataToLocalStorage} =
    useStoredSaleForm({
      companyUuid: defaultCompanyUuid || '',
    });

  const {
    cashBoxList,
    loading: cashBoxListLoading,
    handleSearchCashBoxes,
  } = useStoredCashBoxList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const notifyError = useCallback(
    (ApiError: any) => {
      once.current = false;
      alert(
        'error',
        t('Store'),
        `${
          editMode
            ? t('An error occurred during edit store sale')
            : t('An error occurred during create store sale')
        } : ${ApiError?.message}`,
      );
    },
    [alert, editMode, t],
  );

  const onEnterPress = useCallback(
    (e: KeyboardEvent) => {
      if (e?.target instanceof HTMLInputElement) {
        const field = e?.target?.id;
        const value = instance.getFieldValue(e?.target?.id);

        if (!value) {
          return false;
        }

        const popup = e?.target?.closest('.ant-select-open');

        if (!popup && e?.target?.type === 'search') {
          if (value && field !== 'payment_client_uuid') {
            e?.stopPropagation();
            e?.preventDefault();
          }

          if (field === 'payment_client_uuid' && value) {
            e?.stopPropagation();
            e?.preventDefault();
          }
        }

        if (field === 'payment_method' && value !== PAYMENT_METHOD.Account) {
          setTimeout(() => {
            if (isFunction(cashBoxRef?.current?.focus)) {
              cashBoxRef?.current?.focus();
            }
          }, 100);
        }

        if (field === 'payment_method' && value === PAYMENT_METHOD.Account) {
          setTimeout(() => {
            if (isFunction(clientRef?.current?.focus)) {
              clientRef?.current?.focus();
            }
          }, 100);
        }

        if (field === 'payment_cashbox_uuid' && value) {
          setTimeout(() => {
            if (isFunction(inputRef?.current?.focus)) {
              inputRef?.current?.focus();
            }
          }, 100);
        }

        if (field === 'payment_client_uuid' && value) {
          setTimeout(() => {
            if (isFunction(inputRef?.current?.focus)) {
              inputRef?.current?.focus();
            }
          }, 100);
        }

        if (field === 'price' && value) {
          e?.stopPropagation();
          e?.preventDefault();
          setTimeout(() => {
            if (isFunction(commentRef?.current?.focus)) {
              commentRef?.current?.focus();
            }
          }, 100);
        }

        if (field === 'payment_doc_comment' && !once.current) {
          once.current = true;
          setTimeout(instance.submit, 200);
        }
      }

      return false;
    },

    [instance],
  );

  const handleSuccess = useCallback(
    async (value: StorePaymentDocumentFormDTO) => {
      if (!isFunction(onSuccess)) return;

      const updatedValue = produce(value, (draft) => {
        if (!draft?.is_apply_payment_doc_discount) {
          draft.payment_doc_discount_value = undefined;
          draft.payment_doc_discount_source_type = undefined;
          draft.payment_doc_discount_type = undefined;
        }

        if (draft?.payment_cashbox_uuid instanceof CashBoxModel) {
          draft.payment_cashbox_uuid = draft.payment_cashbox_uuid?.uuid;
        }

        if (draft?.payment_method === PAYMENT_METHOD.Account) {
          draft.payment_cashbox_uuid = '';
        }
      });

      await onSuccess(updatedValue);

      // Save cashbox ID and payment method to the local storage
      setCompanySaleFormDataToLocalStorage(
        value?.payment_method as PAYMENT_METHOD,
        value?.payment_cashbox_uuid as string,
        !!value?.payment_is_fiscal_create,
      ).then(() => {});

      // if (value?.payment_method) {
      //   saveSaleFormDataToLocalStorage(value?.payment_method);
      // } else {
      //   localStorage.removeItem(LocalStorageItems.saleConductFormMethodData);
      // }
      //
      // if (value?.payment_cashbox_uuid) {
      //   saveStoreToLocalStorageStorageCashBox(
      //     value?.payment_cashbox_uuid as string,
      //   );
      // } else {
      //   localStorage.removeItem(LocalStorageItems.saleConductFormCashboxData);
      // }
    },
    [
      onSuccess,
      // saveStoreToLocalStorageStorageCashBox,
      // saveSaleFormDataToLocalStorage,
      setCompanySaleFormDataToLocalStorage,
    ],
  );

  return (
    <DefaultForm
      asModal
      stickyFooter
      instance={instance}
      className="store-sale-conduct-form"
      formKeyboardCodes={[...ENTER_KEYS]}
      onEnterPress={onEnterPress}
      initialValues={{
        ...payment,
        price: payment?.payment_price,
      }}
      onSuccess={handleSuccess}
      onCancel={onCancel}
      withContext
      submitButtonText={
        paymentMethod === PAYMENT_METHOD.Account
          ? t('Spend in debt')
          : t('Accept payment')
      }
      cancelButtonProps={{style: {width: 'fit-content'}}}
      submitButtonProps={{
        disabled:
          paymentMethod === PAYMENT_METHOD.Account &&
          !paymentClientUuid &&
          !paymentDocComment,
      }}
      additionalValuesRequest={additionalValues}
      editMode={editMode}
      showNotify={false}
      notifyError={notifyError}
      {...rest}>
      <StoreSaleConductFormFields
        loading={!!loading}
        clients={clients}
        clientsLoading={clientsLoading}
        handleSearchClients={handleSearchClients}
        handleCreateClient={handleCreateClient}
        handleCreateClientLegal={handleCreateClientLegal}
        cashBoxList={cashBoxList}
        cashBoxListLoading={cashBoxListLoading}
        handleSearchCashBoxes={handleSearchCashBoxes}
        cashBoxRef={cashBoxRef}
        clientRef={clientRef}
        inputRef={inputRef}
        commentRef={commentRef}
        preStoredSalesForm={storedSalesFormToLocalStorage}
        minSum={payment?.payment_price}
        isShowFiscalCashboxesWarning={isShowFiscalCashboxesWarning}
        disabledDiscount={Number(payment?.payment_doc_discount_value) > 0}
      />
    </DefaultForm>
  );
}
