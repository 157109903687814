import React, {useCallback, useEffect, useRef, useState} from 'react';
import {DefaultForm, IDefaultFormProps} from '../../../components/lib/General';
import {useTranslation} from 'react-i18next';
import {ProductBarCodeFormDTO} from '../../../struture';
import {Col, Row, Form, Select} from 'antd';
import {useDropdownAlert} from '../../../contex';
import {useStoredCompanies} from '../../../hooks';
import {ENTER_KEYS} from '../../../services/const';

export interface IProductBarCodeFormProps
  extends Pick<
    IDefaultFormProps<ProductBarCodeFormDTO, ProductBarCodeFormDTO>,
    | 'showFooter'
    | 'showNotify'
    | 'editMode'
    | 'className'
    | 'additionalValuesRequest'
  > {
  loading?: boolean;
  onCancel?: () => void;
  onSuccess: (value: ProductBarCodeFormDTO) => Promise<void>;
  onError?: () => Promise<void>;
  barCode: ProductBarCodeFormDTO;
}

export function ProductBarCodeForm({
  loading,
  editMode,
  barCode,
  onCancel,
  ...rest
}: IProductBarCodeFormProps) {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const [instance] = Form.useForm();
  const {defaultCompany} = useStoredCompanies();

  const once = useRef(false);
  const selectRef = useRef<any>(null);

  const [productBarcodeFormat, setProductBarcodeFormat] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  const notifyError = useCallback(
    (apiError: any) => {
      once.current = false;

      alert(
        'error',
        t('Bar code'),
        `${t('An error occurred during generate bar code')} : ${apiError}`,
      );
    },
    [alert, t],
  );

  const onEnterPress = useCallback(
    (e: KeyboardEvent) => {
      setTimeout(() => {
        if (e?.target instanceof HTMLInputElement) {
          const field = e?.target?.id;
          const value = instance.getFieldValue(e?.target?.id);

          if (!value) {
            return false;
          }

          const popup = e?.target?.closest('.ant-select-open');

          if (!popup && e?.target?.type === 'search') {
            if (field === 'bar_code_format' && value) {
              e?.stopPropagation();
              e?.preventDefault();
            }
          }

          if (field === 'bar_code_format' && !once?.current) {
            once.current = true;
            setTimeout(instance.submit, 200);
          }
        }
      }, 100);

      return false;
    },

    [instance],
  );

  useEffect(() => {
    if (selectRef?.current) {
      setTimeout(() => {
        selectRef?.current?.focus();
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (defaultCompany?.bar_code_formats?.length) {
      const selectValues = defaultCompany?.bar_code_formats?.map((value) => ({
        value,
        label: value,
      }));

      setProductBarcodeFormat(selectValues);
    }
  }, [defaultCompany?.bar_code_formats]);

  return (
    <DefaultForm
      instance={instance}
      className="product-bar-code-form"
      formKeyboardCodes={[...ENTER_KEYS]}
      onEnterPress={onEnterPress}
      initialValues={{
        ...barCode,
      }}
      onCancel={onCancel}
      submitButtonText={t('Generate')}
      editMode={editMode}
      showNotify={false}
      notifyError={notifyError}
      {...rest}>
      {({loadingSubmit}) => (
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              tooltip={t('Format')}
              label={t('Format')}
              name="bar_code_format"
              rules={[
                {
                  required: true,
                  message: t('Format must be specified.'),
                },
              ]}>
              <Select
                ref={selectRef}
                disabled={loadingSubmit}
                allowClear
                placeholder={t('Select a bar code format')}
                showSearch
                options={productBarcodeFormat}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </DefaultForm>
  );
}
