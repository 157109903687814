import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  DefaultForm,
  IDefaultFormProps,
} from '../../../../components/lib/General';
import {
  ClientModel,
  ProfileModel,
  StoreDocumentMappedType,
  StoreDocumentModel,
  StoreDocumentStatus,
  StoreDocumentType,
} from '../../../../struture';
import {ENTER_KEYS} from '../../../../services/const';
import {useTranslation} from 'react-i18next';
import {Col, Empty, Row} from 'antd';
import {
  useKeyboardOpenForm,
  useStoredCompanies,
  useStoredStoreDocumentList,
} from '../../../../hooks';
import {ITableProps, Table} from '../../../../components/lib/libV2/DataDisplay';
import {StyledDescription} from '../../../../components/lib/Styled';
import {
  DetailsItemView,
  ListActions,
  TableIndexField,
} from '../../../../components/lib/DataDisplay';
import styled from 'styled-components';
import {
  isFunction,
  toDateByFormat,
  last,
  head,
} from '../../../../services/helpers';
import {StoreProductPriceView, StyledListActionsContainer} from '../../Show';
import {
  StoreRealizationDetailsView,
  StoreRealizationDeleteButton,
} from '../../Realization';
import {DELETED_DOCUMENT_STATUSES} from '../../ReturnClients';
import {Routes} from '../../../../services/types';
import {StatusError} from '../../../../components/lib/Errors';
import {useAcl, useDropdownAlert} from '../../../../contex';
import {useStoreSale} from '../Managers';

import './StoreSaleStoreRealizationForm.less';
import '../../../Orders/List/OrderList.less';

export interface StoreSaleStoreRealizationFormProps
  extends Omit<
    IDefaultFormProps<unknown, unknown>,
    'children' | 'initialValues' | 'additionalValuesRequest' | 'onSuccess'
  > {
  loading?: boolean;
  onSuccess: (value: string) => Promise<void>;
}

const StyledTable = styled(Table)`
  margin: 0;

  & {
    .ant-pagination {
      position: sticky;
      bottom: 60px;
      left: 0;
      background: ${({theme}) => theme.background.primary};
      display: flex;
      padding: 10px 0;
      width: 100%;
    }
  }
` as React.ComponentType as React.FC<ITableProps<StoreDocumentModel>>;

const StyledRow = styled(Row)`
  padding-bottom: 40px;
  gap: 15px;
`;

const StyledSumContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
`;

export function StoreSaleStoreRealizationForm({
  loading,
  editMode,
  onCancel,
  onSuccess,
  ...rest
}: StoreSaleStoreRealizationFormProps) {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const {defaultCompany, defaultCompanyUuid} = useStoredCompanies();
  const {manage} = useAcl(({store}) => store);

  const {storeDocument, deletedStoreDocumentUuid} = useStoreSale();

  const [selectedDocuments, setSelectedDocuments] = useState<
    StoreDocumentModel[]
  >([]);

  const {ref: listRef} = useKeyboardOpenForm({
    className: '',
    disabled: false,
  });

  const {
    documentList,
    loading: documentListLoading,
    limit,
    total,
    page,
    loadingMore,
    keywords,

    handleRefreshStoreDocuments,
    handleSearchStoreDocuments,
    handleDeleteStoreDocuments,
  } = useStoredStoreDocumentList({
    companyUuid: defaultCompanyUuid,
    documentType: StoreDocumentType.OUT,
    withoutDates: true,
    mappedDocumentType: StoreDocumentMappedType.SALE_OUT_DRAFT_LIST,
    documentStatus: StoreDocumentStatus.DRAFT,
    alwaysSendRequest: true,
  });

  const notifyError = useCallback(
    (apiError: any) => {
      alert('error', t('Store document'), t(apiError?.message));
    },
    [alert, t],
  );

  const onAmountEnter = useCallback(
    (e: KeyboardEvent) => {
      if (e?.target instanceof HTMLElement) {
        e?.preventDefault();
        e?.stopPropagation();
      }

      return true;
    },

    [],
  );

  const handleSuccess = useCallback(async () => {
    if (isFunction(onSuccess)) {
      const document = head(selectedDocuments);

      if (document) {
        await onSuccess(document?.uuid);
      } else {
        throw new StatusError('No document has been selected', 400);
      }
    }
  }, [onSuccess, selectedDocuments]);

  const rowSelection = useMemo(
    () => ({
      hideSelectAll: true,
      type: 'radio' as any,
      selectedRowKeys: (selectedDocuments || []).map(({uuid}) => uuid),
      onChange: (
        selectedRowKeys: React.Key[],
        selectedRecords: StoreDocumentModel[],
      ) => {
        const lastDocument = last(selectedRecords);

        if (lastDocument) {
          setSelectedDocuments([lastDocument]);
        } else {
          setSelectedDocuments([]);
        }
      },
    }),
    [selectedDocuments],
  );

  const columns = [
    {
      ellipsis: true,
      title: t('Invoices'),
      key: 'sales-invoices',
      render: (document: StoreDocumentModel) => (
        <>
          <StoreRealizationDetailsView withoutDate document={document} />
          <DetailsItemView<ProfileModel>
            item={document?.createdByModel}
            fields={{
              fullName: {
                title: '',
              },
              uuid: {
                description: (
                  <StyledDescription>
                    {toDateByFormat(document?.created_at, 'DD.MM.YYYY HH:mm')}
                  </StyledDescription>
                ),
              },
            }}
          />
        </>
      ),
    },
    {
      ellipsis: true,
      title: `${t('Sum')}, ${defaultCompany?.currency_symbol || ''}`,
      key: 'sum',
      className: 'order-list-td',
      render: (document: StoreDocumentModel) => (
        <StyledSumContainer>
          <StoreProductPriceView document={document} />
          {!manage ||
          !DELETED_DOCUMENT_STATUSES.includes(
            document?.doc_status_text,
          ) ? null : (
            <StoreRealizationDeleteButton
              className="store-sale-realization-button--delete"
              size="small"
              type="primary"
              title={t('Delete')}
              documents={[document]}
              onSuccess={handleDeleteStoreDocuments}
            />
          )}
        </StyledSumContainer>
      ),
    },
  ];

  useEffect(() => {
    if (storeDocument?.uuid && !selectedDocuments?.length) {
      setSelectedDocuments([storeDocument]);
    }
  }, [selectedDocuments?.length, storeDocument]);

  return (
    <DefaultForm<any, any>
      formKeyboardCodes={['Tab', ...ENTER_KEYS]}
      footerClassName="store-realization-form"
      onEnterPress={onAmountEnter}
      className="invoice-item-form"
      formKeyboardEndSubmit
      withContext
      onSuccess={handleSuccess}
      editMode={editMode}
      submitButtonText={t('Select')}
      submitButtonProps={{disabled: !selectedDocuments?.length}}
      cancelButtonText={t('Close')}
      initialValues={{}}
      showNotify={false}
      onCancel={onCancel}
      notifyError={notifyError}
      {...rest}>
      <StyledRow gutter={12}>
        <Col span={24}>
          <StyledListActionsContainer>
            <ListActions
              inputRef={listRef}
              withoutPicker
              loading={documentListLoading}
              searchText={keywords}
              handleSearch={(keywords) =>
                handleSearchStoreDocuments({keywords})
              }
              inputTooltip={t('Search sales invoice')}
              inputLabel={t('Search sales invoice')}
            />
          </StyledListActionsContainer>
        </Col>
        <Col span={24}>
          <StyledTable
            loading={documentListLoading || loadingMore}
            dataSource={
              documentList?.filter(
                ({uuid}) =>
                  uuid !== storeDocument?.uuid &&
                  uuid !== deletedStoreDocumentUuid,
              ) || []
            }
            onChange={handleRefreshStoreDocuments}
            columns={columns}
            total={total}
            pageSize={limit}
            page={page}
            rowSelection={rowSelection}
            renderEmpty={
              <Empty
                description={t('There are no store sales invoices created')}
              />
            }
            onRow={(record) => ({
              className: 'store-sale-realization',
              onClick: () => setSelectedDocuments([record]),
            })}
          />
        </Col>
      </StyledRow>
    </DefaultForm>
  );
}
