import {Record} from 'immutable';
import {
  CompanyDTO,
  CompanyMapper,
  CompanyModel,
  DepartmentDTO,
  DepartmentIcon,
  DepartmentStatusesText,
  IAbonementTimingList,
  ProfileDTO,
  ProfileMapper,
  ProfileModel,
} from '../internal';
import {WithoutRecursion} from '../type';

export interface IDepartmentModelReturnType {
  uuid: string;
  dep_name: string;
  dep_address: string;
  dep_icon: DepartmentIcon;
  dep_description: string;
  dep_status_text: DepartmentStatusesText;
  created_at: string;
  updated_at: string;
  dep_schedules_list: IAbonementTimingList;
  created_by: ProfileModel | ProfileDTO;
  company: CompanyModel | CompanyDTO;
  dep_is_default: boolean;
  dep_colour: string;
}

export class DepartmentModel extends Record<IDepartmentModelReturnType>({
  uuid: '',
  dep_name: '',
  dep_address: '',
  dep_icon: DepartmentIcon.REGULAR,
  dep_description: '',
  dep_status_text: DepartmentStatusesText.DISABLED,
  created_at: '',
  updated_at: '',
  dep_schedules_list: {} as IAbonementTimingList,
  company: {} as CompanyModel,
  created_by: {} as ProfileModel,
  dep_is_default: false,
  dep_colour: '',
}) {
  constructor(
    props: DepartmentDTO = {} as DepartmentDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IDepartmentModelReturnType = {
      ...props,
      created_by:
        withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
          ? props?.created_by
          : ProfileMapper.toProfileModel(props?.created_by, [
              WithoutRecursion.department,
              ...withoutRecursion,
            ]),
      company:
        withoutRecursion.indexOf(WithoutRecursion.company) !== -1
          ? props?.company
          : CompanyMapper.toCompanyModel(props?.company, [
              WithoutRecursion.department,
              ...withoutRecursion,
            ]),
    };

    super(options);
  }

  get companyModel(): CompanyModel {
    return this.company instanceof CompanyModel
      ? this.company
      : CompanyMapper.toCompanyModel(this.company);
  }

  get createdByModel(): ProfileModel {
    return this.created_by instanceof ProfileModel
      ? this.created_by
      : ProfileMapper.toProfileModel(this.created_by);
  }
}
