import * as React from 'react';
import {getInvoiceById, InvoiceReturnType} from '../services/api/invoice';
import {InvoiceModel, InvoiceMapper, InvoiceDTO} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {IEntityProps} from '../services/types';
import {useDropdownAlert} from '../contex';
import {useTranslation} from 'react-i18next';

export interface IUseInvoiceProps extends IEntityProps {
  invoiceUuid: string;
}

export interface IUseInvoiceReturnType {
  error: null | any;
  loading: boolean;
  entity: InvoiceModel | null;
  refresh: (value: Partial<IUseInvoiceProps>) => Promise<InvoiceModel | void>;
  invoiceUuid: string;
}

export function useInvoice(
  {loadOnInit = true, invoiceUuid}: IUseInvoiceProps = {} as IUseInvoiceProps,
): IUseInvoiceReturnType {
  const {alert} = useDropdownAlert();
  const {t} = useTranslation();
  const {cancellablePromise} = useCancellablePromise();
  const {
    entity,
    loading,
    error,
    handleUpdate,
    invoiceUuid: entityUuid,
  } = useStateReducer<Omit<IUseInvoiceReturnType, 'refresh'>>({
    entity: null,
    invoiceUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading,
      invoiceUuid = entityUuid,
    }: Partial<IUseInvoiceProps> = {}): Promise<InvoiceModel | void> => {
      try {
        handleUpdate({loading: showLoading, error: null, invoiceUuid});

        const {invoice} = await cancellablePromise<InvoiceReturnType>(
          getInvoiceById(invoiceUuid),
        );

        handleUpdate({
          entity: invoice,
          loading: false,
        });
        return invoice;
      } catch (err: any) {
        handleUpdate({
          error: err,
          loading: false,
        });
        alert(
          'error',
          t('Bill'),
          `${t('An error occurred during get bill')} : ${err?.message}`,
        );
      }
    },

    [alert, cancellablePromise, entityUuid, handleUpdate, t],
  );

  React.useEffect(() => {
    if (loadOnInit && invoiceUuid) {
      (async () => {
        await refresh({invoiceUuid});
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, invoiceUuid]);

  return {
    error,
    loading,
    refresh,
    entity,
    invoiceUuid,
  };
}
