import React, {useState, useCallback, useEffect} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {
  isFunction,
  fileUploadStructure,
  eq,
  len,
} from '../../../../services/helpers';
import notify from '../../Feedback/notify';
import {FileUploader as FileUploaderType} from '../../../../services/types';
import {useConst} from '../../../../hooks';
import FileUploadWithModal from './FileUploadWithModal';
import FileUploadButton from './FileUploadButton';
import FileUploadCoverWithModal from './FileUploadCoverWithModal';

import './FileUploader.less';

export default withTranslation()(FileUploader);

export type OnUploadFiles = (files: File[]) => Promise<void | string>;
export type OnUploadFile = (files: File) => Promise<string | void>;

export interface IFileUploaderProps extends WithTranslation {
  className?: string;
  uploaderClassName?: string;
  file?: FileUploaderType | FileUploaderType[];
  onUpload?: OnUploadFiles | OnUploadFile;
  isButton?: boolean;
  isCover?: boolean;
  multiple?: boolean;
  buttonTitle?: string;
  buttonTooltip?: string;
  disabled?: boolean;
  title?: string;
  coverHeight?: number;
}

function FileUploader({
  t,
  className,
  file,
  onUpload,
  multiple = false,
  isButton = false,
  isCover = false,
  buttonTitle = '',
  buttonTooltip = '',
  disabled,
  title,
  uploaderClassName,
  coverHeight,
}: IFileUploaderProps) {
  const [fileList, setFileList] = useState<any>([]);
  const isFileListExist = useConst(false);
  const countFilesUpload = useConst(0);
  const uploadFileList = useConst([]);
  const [loading, setLoading] = useState(false);

  const handleDelete = () => setFileList([]);

  const beforeUpload = useCallback(
    async (file: any, fileList: any[]): Promise<any> => {
      const mediaFileIsBig = file.size / 1024 / 1024 < 100;

      if (!mediaFileIsBig) {
        notify.actionError(file.name, t('uploading'), 'File is big', t);
        return false;
      }

      countFilesUpload.current.value = len(fileList);

      return mediaFileIsBig;
    },
    [countFilesUpload, t],
  );

  const customRequest = useCallback(
    async ({onSuccess, onError, file}: any): Promise<void> => {
      setLoading(true);
      try {
        isFileListExist.current.value = true;
        uploadFileList.current.value = [...uploadFileList.current.value, file];

        if (
          isFunction(onUpload) &&
          eq(len(uploadFileList.current.value), countFilesUpload.current.value)
        ) {
          const image: any = await onUpload(
            multiple ? uploadFileList.current.value : file,
          );

          uploadFileList.current.value = [];

          setFileList((prevState: any) => {
            if (multiple) {
              return prevState;
            }

            return [...prevState, fileUploadStructure(image)];
          });
        }

        await onSuccess();
      } catch (e: any) {
        notify.actionError(file.name, t('uploading'), e, t);
        if (isFunction(onError)) {
          onError();
        }
      } finally {
        setLoading(false);
      }
    },
    [isFileListExist, uploadFileList, onUpload, countFilesUpload, multiple, t],
  );

  useEffect(() => {
    if (file && !isFileListExist.current.value) {
      setFileList(Array.isArray(file) ? file : [file]);
      isFileListExist.current.value = true;
    }
  }, [file, isFileListExist]);

  return (
    <>
      {isButton ? (
        <FileUploadButton
          disabledButton={disabled}
          tooltip={buttonTooltip}
          className={className}
          showUploadList={false}
          fileList={fileList}
          beforeUpload={beforeUpload}
          customRequest={customRequest}
          title={buttonTitle || t('Upload')}
          multiple={multiple}
        />
      ) : isCover ? (
        <FileUploadCoverWithModal
          title={title}
          loading={loading}
          disabled={disabled || loading}
          className={className}
          fileList={fileList}
          onRemove={handleDelete}
          beforeUpload={beforeUpload}
          customRequest={customRequest}
          multiple={multiple}
          coverHeight={coverHeight}
        />
      ) : (
        <FileUploadWithModal
          loading={loading}
          uploaderClassName={uploaderClassName}
          title={title}
          disabled={disabled || loading}
          className={className}
          fileList={fileList}
          onRemove={handleDelete}
          beforeUpload={beforeUpload}
          customRequest={customRequest}
          multiple={multiple}
        />
      )}
    </>
  );
}
