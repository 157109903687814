import * as React from 'react';
import {updateFinancialReliability} from '../services/api/setting';
import {StatusError} from '../components/lib/Errors';
import useStoredCompanies from './useStoredCompanies';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../contex';
import {CompanyMapper, FinancialSettingsDTO} from '../struture';

export interface IUseSettingsProps {
  companyUuid: string;
}

export interface IUseSettingsReturnType {
  handleUpdateFinancialReliability: (
    value: Partial<FinancialSettingsDTO>,
  ) => Promise<void>;
  loading: boolean;
}

export function useSettings({
  companyUuid,
}: IUseSettingsProps): IUseSettingsReturnType {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const [loading, setLoading] = React.useState(false);

  const {defaultCompany, storedUpdateCompany, settings} = useStoredCompanies();

  const handleUpdateFinancialReliability = React.useCallback(
    async (value: Partial<FinancialSettingsDTO>): Promise<void> => {
      try {
        setLoading(true);
        const settingsDTO = await updateFinancialReliability({
          ...value,
          companyUuid,
        });

        if (defaultCompany) {
          if (
            value?.company_currency_symbol &&
            defaultCompany?.currency_symbol !== value?.company_currency_symbol
          ) {
            const updatedCompany = defaultCompany.update(
              'currency_symbol',
              () => value?.company_currency_symbol || '',
            );

            storedUpdateCompany(
              updatedCompany,
              CompanyMapper.toCompanySettingsDTO({
                settings: settingsDTO,
                alfa_name_order: settings?.alfa_name_order!,
              }),
            );
          } else {
            storedUpdateCompany(
              defaultCompany,
              CompanyMapper.toCompanySettingsDTO({
                settings: settingsDTO,
                alfa_name_order: settings?.alfa_name_order!,
              }),
            );
          }
        }

        setLoading(false);
        alert('success', t('Parameters'), t('Parameters edit success'));
      } catch (e: any) {
        setLoading(false);
        throw new StatusError(e?.message, e?.status);
      }
    },
    [
      alert,
      companyUuid,
      defaultCompany,
      settings?.alfa_name_order,
      storedUpdateCompany,
      t,
    ],
  );

  return {
    handleUpdateFinancialReliability,
    loading,
  };
}
