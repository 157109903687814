import React, {forwardRef} from 'react';
import {List} from 'antd';
import SuggestionInput from './SuggestionInput';
import {ILiteralObj} from '../../../services/types';
import {isFunction, head, isThereContent} from '../../../services/helpers';

import './SearchSuggestionList.less';

export interface ISearchSuggestionListProps {
  suggestions?: ILiteralObj[];
  onSelectSuggestion?: (value: ILiteralObj) => void;
  selectedUuid?: string;
}

export default forwardRef(
  (
    {suggestions, onSelectSuggestion, selectedUuid}: ISearchSuggestionListProps,
    ref: any,
  ) => {
    const handleSelectSuggestion = (suggestion: ILiteralObj) => {
      if (isFunction(onSelectSuggestion)) {
        onSelectSuggestion(suggestion);
      }
    };

    const RenderList = () => (
      <List
        className="search-suggestions-list"
        itemLayout="vertical"
        size="large"
        dataSource={suggestions}
        renderItem={(suggestion) => (
          <SuggestionInput
            suggestion={suggestion}
            onSelect={handleSelectSuggestion}
            key={suggestion.uuid}
            select={
              selectedUuid ||
              (isThereContent(suggestions) ? head(suggestions)?.uuid : '')
            }
          />
        )}
        bordered
      />
    );

    return (
      <div className="search-suggestions-list-container" ref={ref}>
        <RenderList />
      </div>
    );
  },
);
