import React, {forwardRef} from 'react';
import {Tooltip, ButtonProps} from 'antd';
import Button from '../DataDisplay/Button';
import styled, {css} from 'styled-components';

export interface IActionButtonProps extends ButtonProps {
  children: React.ReactNode;
  tooltip?: string;
}

const AntButton = styled(Button)<{disabled?: boolean}>`
  &&& {
    height: 34px;
  }

  ${({disabled}) =>
    disabled &&
    css`
      &&& * {
        color: ${({theme}) => theme.colors.lightDisable};
      }
    `}
`;

const StyledTooltip = styled(Tooltip)<{width?: string | number}>`
  ${({width}) =>
    width &&
    css`
      width: ${typeof width === 'string' ? width : `${width}px`};
    `}
`;

const ActionButton = forwardRef(
  (
    {
      children,
      type,
      size = 'small',
      loading,
      disabled,
      tooltip,
      style,
      ...rest
    }: IActionButtonProps,
    ref,
  ): JSX.Element => {
    const {reportNS, tReady, defaultNS, i18nOptions, i18n, ...restProps}: any =
      rest;

    return (
      <StyledTooltip title={tooltip} width={style?.width || 'auto'}>
        <AntButton
          ref={ref}
          type={type}
          size={size}
          loading={loading}
          disabled={disabled || loading}
          style={style}
          {...restProps}>
          {children}
        </AntButton>
      </StyledTooltip>
    );
  },
);

export default ActionButton;
