import {Record, List} from 'immutable';
import Payment, {PaymentProps} from './Payment';
import PaymentList from './PaymentList';
import ClientSubscriptionable, {
  IClientSubscriptionableProps,
} from './ClientSubscriptionable';

export enum CLIENT_TYPE {
  PERSONAL = 'client_personal_session',
  GROUP = 'client_group_session',
}

export enum SUBSCRIPTION_STATUS {
  READY = 'ready',
  DRAFT = 'draft',
  PAYED = 'payed',
  COMPLETED = 'completed',
  WAITING_FOR_PAYMENT = 'waiting_for_payment',
}

export interface IClientSubscriptionProps {
  uuid: string;
  start_date: string;
  end_date: string;
  comment: string;
  period_amount: number;
  price: number;
  created_at: string;
  updated_at: string;
  payments: PaymentProps[];
  type: CLIENT_TYPE;
  status: SUBSCRIPTION_STATUS;
  subscriptionable: IClientSubscriptionableProps;
}

export interface IClientSubscriptionReturnType
  extends Omit<IClientSubscriptionProps, 'payments' | 'subscriptionable'> {
  payments: List<Payment[]> | [];
  subscriptionable: ClientSubscriptionable;
}

/**
 * @class Client
 * @desc Клієнт
 */
export default class ClientSubscription extends Record<IClientSubscriptionReturnType>(
  {
    uuid: '',
    start_date: '',
    end_date: '',
    comment: '',
    period_amount: 1,
    price: 0,
    payments: [],
    created_at: '',
    updated_at: '',
    type: CLIENT_TYPE.PERSONAL,
    status: SUBSCRIPTION_STATUS.DRAFT,
    subscriptionable: {} as any,
  },
) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(
    {
      payments,
      subscriptionable,
      ...rest
    }: IClientSubscriptionProps = {} as IClientSubscriptionProps,
  ) {
    const options: Partial<IClientSubscriptionReturnType> = {};

    if (payments) {
      const {payments: paymentsList} = new PaymentList({
        payments,
      });

      options.payments = paymentsList;
    }

    if (subscriptionable) {
      options.subscriptionable = new ClientSubscriptionable(subscriptionable);
    }

    super({...rest, ...options});
  }
}
