import React, {useRef} from 'react';
import styled, {css} from 'styled-components';
import {FlexContainer} from '../../lib/Styled';
import {TextFormat} from '../../lib/Format';
import {len, add, eq} from '../../../services/helpers';
import {phoneScreen, tableScreen} from '../../../services/const';
import {useSize} from '../../../hooks';

export interface ISpecification {
  title: string;
  blocks: React.ReactNode[];
}

export interface ICalendarHeaderSpecificationProps {
  specifications: ISpecification[];
}

const StyledSpecificationContainer = styled(FlexContainer)`
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
  //border-left: 1px solid ${({theme}) => theme.colors.primary};
  //border-right: 1px solid ${({theme}) => theme.colors.primary};
  //border-bottom: 1px solid ${({theme}) => theme.colors.primary};
  padding: 5px 5px;
`;

const StyledSpecification = styled.div<{isLast: boolean; $isMinView: boolean}>`
  width: 100px;
  height: 20px;
  margin-right: 10px;

  ${({isLast}) =>
    isLast &&
    css`
      margin-right: 0;
    `}

  ${({$isMinView}) =>
    $isMinView &&
    css`
      width: 45px;
    `}
`;

const StyledTextFormat = styled(TextFormat)`
  margin-right: 10px;
  margin-left: 5px;
`;

const StyledLeftBracket = styled.div`
  margin-top: -5px;
  width: 10px;
  height: 30px;
  //border-left: 1px solid ${({theme}) => theme.colors.primary};
  //border-top: 1px solid ${({theme}) => theme.colors.primary};
  //border-bottom: 1px solid ${({theme}) => theme.colors.primary};
`;

const StyledRightBracket = styled.div`
  margin-top: -5px;
  width: 10px;
  height: 30px;
  //border-right: 1px solid ${({theme}) => theme.colors.primary};
  //border-top: 1px solid ${({theme}) => theme.colors.primary};
  //border-bottom: 1px solid ${({theme}) => theme.colors.primary};
`;

const StyledBlockContainer = styled.div`
  position: relative;
  display: flex;
`;

const StyledSpecifications = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export default function CalendarHeaderSpecification({
  specifications,
}: ICalendarHeaderSpecificationProps): JSX.Element {
  const specificationRef = useRef(null);
  const {width: specificationRefWidth} = useSize(specificationRef);

  const isColumnView = specificationRefWidth < phoneScreen;

  return (
    <StyledSpecificationContainer
      ref={specificationRef}
      flexDirection={isColumnView ? 'column' : 'row'}
      flexWrap="wrap"
      alignContent={isColumnView ? 'flex-start' : 'center'}>
      {specifications.map(({title, blocks}) => (
        <StyledSpecifications key={title}>
          <StyledBlockContainer>
            <StyledLeftBracket />
            {blocks.map((block, index) => (
              <StyledSpecification
                $isMinView={
                  specificationRefWidth < tableScreen && blocks?.length > 1
                }
                key={String(`${index}`)}
                isLast={eq(len(blocks), add(index, 1))}>
                {block}
              </StyledSpecification>
            ))}
            <StyledRightBracket />
          </StyledBlockContainer>
          <StyledTextFormat primary>- {title}</StyledTextFormat>
        </StyledSpecifications>
      ))}
    </StyledSpecificationContainer>
  );
}
