import {ScheduleCalendarModel} from '../internal';
import DateJS, {toDateByFormat} from '../date';

export class AgendaItemDTO {
  public id: string;

  public start: string;

  public end: string;

  public title: string;

  public number: string;

  public summary: string;

  public color: string;

  public marked: boolean;

  // public service_title: string;
  // public client_full_name: string;
  // public employee_full_name: string;
  // public client_phone: string;
  // public parent: {
  //   uuid: string;
  //   schedule_number: string;
  // };

  constructor(props: ScheduleCalendarModel) {
    this.id = props?.uuid || '';
    this.start = toDateByFormat(props?.scheduled_date, 'YYYY-MM-DD HH:mm');
    this.end = toDateByFormat(
      DateJS(props?.scheduled_date).add(59, 'minute'),
      'YYYY-MM-DD HH:mm',
    );
    this.title = props?.schedule_title || '';
    this.number = props?.schedule_number || '';
    this.summary = props?.comment || '';
    this.marked = true;
    this.color = '#5d9272';
    // this.service_title = props?.serviceModel?.title || '';
    // this.client_full_name = props?.clientModel?.fullNameClient || '';
    // this.client_phone = props?.clientModel?.phone || '';
    // this.employee_full_name = props?.employeeModel?.fullName || '';
    // this.parent = {
    //   uuid: props?.parent?.uuid || '',
    //   schedule_number: props?.parent?.schedule_number || '',
    // };
  }
}
