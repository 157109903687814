import React from 'react';
import {Button, Card} from 'antd';
import {CheckCircleOutlined} from '@ant-design/icons';
import styled from 'styled-components';
import {CompanyProps, eq, fromBooleanToNumber} from '../../../services/helpers';
import {authLogoUrl} from '../../../services/const';

export interface IDefaultCompanyCardProps extends CompanyProps {
  handleSelectByDefault: (uuid: string) => void;
  selectCompanyUuid: string;
  dataTestId?: string;
}

const NativeButton = styled(Button)<{select: number}>`
  margin-right: 10px;
  margin-bottom: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: auto;
  padding: 10px;
  border: ${({select, theme}) =>
    select ? `1px solid ${theme.colors.primary} !important` : undefined};

  &:hover {
    border: ${({theme}) => `1px solid ${theme.colors.primary}`};
  }

  & .default-company__check {
    position: absolute;
    top: -13px;
    right: 13%;
    background-color: ${({theme}) => theme.colors.app};
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;

    &-icon {
      font-size: 15px;
      color: ${({theme}) => theme.colors.primary};
    }
  }

  & .default-company__card {
    position: relative;
    max-width: 200px;
    border-width: 0;

    &-image {
      width: 100%;
      height: 100%;
    }

    .ant-card-body {
      background-color: ${({theme}) => theme.colors.primary};
    }

    .ant-card-meta-detail {
      > * {
        color: ${({theme}) => theme.colors.app};
      }
    }
  }
`;

export default function DefaultCompanyCard({
  uuid,
  cover_url,
  handleSelectByDefault,
  selectCompanyUuid,
  title,
  description,
}: IDefaultCompanyCardProps): JSX.Element {
  return (
    <NativeButton
      select={fromBooleanToNumber(eq(selectCompanyUuid, uuid))}
      key={uuid}
      onClick={() => {
        handleSelectByDefault(uuid);
      }}
      type="text">
      <Card
        bordered={false}
        className="default-company__card"
        cover={
          <img
            className="default-company__card-image"
            alt="example"
            src={cover_url || authLogoUrl}
          />
        }>
        <Card.Meta title={title} description={description} />
      </Card>
      {eq(selectCompanyUuid, uuid) && (
        <div className="default-company__check">
          <CheckCircleOutlined className="default-company__check-icon" />
        </div>
      )}
    </NativeButton>
  );
}
