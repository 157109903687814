import * as React from 'react';
import {useCallback} from 'react';
import useDataState from './useDataState';
import useUserList, {
  IUseUserListProps,
  IUseUserListReturnType,
} from './useUserList';
import {
  createUser,
  editUser,
  deleteUser,
  ICompanyUserParams,
  IUserSearchProps,
} from '../services/api/companyUser';
import {User} from '../services/models';
import {correctBirthdayValue, debounce} from '../services/helpers';

export interface IUseStateUserListProps extends IUseUserListProps {}

export interface IUseStateUserListReturnType
  extends Omit<IUseUserListReturnType, 'list'> {
  users: User[];
  handleCreateUser: (value: ICompanyUserParams) => Promise<void>;
  handleUpdateUser: (value: ICompanyUserParams) => Promise<void>;
  handleDeleteUsers: (ids: string[]) => Promise<void>;
  handleSearchUsers: (value: IUserSearchProps) => Promise<void>;
}

export default function useStateUserList(
  {...rest}: IUseStateUserListProps = {} as IUseStateUserListProps,
): IUseStateUserListReturnType {
  const {
    list: users,
    loading,
    refresh,
    ...usersParams
  } = useUserList({
    ...rest,
  });

  const {
    data: stateUsers,
    loading: loadingUserList,
    handleCreate,
    handleUpdate,
    handleDelete,
    setData,
  } = useDataState<User>(users);

  const handleCreateUser = React.useCallback(
    async (value: ICompanyUserParams): Promise<void> => {
      const user = await createUser(
        correctBirthdayValue({
          ...value,
        }) as ICompanyUserParams,
      );

      handleCreate(user, true);
    },
    [handleCreate],
  );

  const handleDeleteUsers = React.useCallback(
    async (ids: string[]): Promise<void> => {
      const {success} = await deleteUser(ids);

      if (success) {
        handleDelete(ids);
      }
    },
    [handleDelete],
  );

  const handleUpdateUser = React.useCallback(
    async (value: ICompanyUserParams): Promise<void> => {
      const user = await editUser(
        correctBirthdayValue({
          ...value,
        }) as ICompanyUserParams,
      );

      handleUpdate(user);
    },
    [handleUpdate],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchUsers = useCallback(
    debounce<[IUserSearchProps]>(
      async (value: IUserSearchProps): Promise<void> => {
        const users = await refresh(value);

        setData(users || []);
      },
      500,
    ),
    [setData],
  );

  return {
    ...usersParams,
    refresh,
    loading: loading || loadingUserList,
    users: stateUsers,
    handleCreateUser,
    handleDeleteUsers,
    handleUpdateUser,
    handleSearchUsers,
  };
}
