import React from 'react';
import {StoreDocumentModel} from '../../../struture';
import {StyledTitle} from '../../../components/lib/Styled';
import styled, {useTheme} from 'styled-components';
import {head} from '../../../services/helpers';
import {InfoCircleOutlined} from '@ant-design/icons';
import {List} from 'immutable';
import {correctPrice} from '@sportix/sportix-common-modules';

export interface IProductStoreDocumentPriceViewProps {
  document: StoreDocumentModel | null;
  isWarning?: boolean;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`;

const BASE_CURRENCY = ['', '₴'];

export function ProductStoreDocumentPriceView({
  document,
  isWarning,
}: IProductStoreDocumentPriceViewProps): React.JSX.Element {
  const theme: any = useTheme();

  const isDiscount = Number(document?.doc_discount_value || 0) > 0;

  const paid = Number(document?.doc_sum_paid) || 0;
  const total =
    (isDiscount
      ? Number(document?.doc_sum_total_with_discount)
      : Number(document?.doc_sum_total)) || 0;

  const isPaid = paid > 0 && paid === total;
  const isUnpaid = total > 0 && total > paid;

  const docItem = List.isList(document?.doc_items)
    ? document?.doc_items?.first()
    : head(document?.doc_items || []);

  const amount = Number(docItem?.item_product_amount || 1);
  const itemPrice = Number(docItem?.item_price || 0);
  const itemBasePrice = Number(docItem?.item_base_price || 0);

  const price = BASE_CURRENCY.includes(docItem?.item_price_currency as any)
    ? itemPrice
    : itemPrice * itemBasePrice;

  return (
    <>
      {document ? (
        <StyledContainer>
          <StyledTitle
            $color={
              isPaid
                ? theme.colors.success
                : isUnpaid
                ? theme.colors.error
                : isWarning
                ? theme.colors.warning
                : undefined
            }>
            {correctPrice(price * amount)}
          </StyledTitle>
        </StyledContainer>
      ) : null}
    </>
  );
}
