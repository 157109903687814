export interface ILoginDTOProps {
  identifier?: string;
  password?: string;
}

export class LoginDTO {
  public identifier: string;

  public password: string;

  constructor(props?: ILoginDTOProps) {
    this.identifier = props?.identifier || '';
    this.password = props?.password || '';
  }
}
