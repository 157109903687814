import * as React from 'react';
import {MutableRefObject, useCallback, useEffect} from 'react';
import {
  InvoiceFormDTO,
  InvoiceItemFormDTO,
  InvoiceItemListModel,
  InvoiceItemModel,
  InvoiceMapper,
  InvoiceModel,
} from '../../../struture';
import {
  IUseDefaultPriceMarginReturnType,
  IUseInvoiceItemListProps,
  useDefaultPriceMargin,
  useStateInvoiceItemList,
  useStoredCompanies,
  useStoredInvoice,
} from '../../../hooks';
import {useTranslation} from 'react-i18next';
import {produce} from 'immer';
import {useStateReducer} from '../../../components/lib/libV2/hooks';
import {List} from 'immutable';
import {useDropdownAlert} from '../../../contex';
import {useParams} from 'react-router';
import {notEmptyFields, isRecordToObject} from '../../../services/helpers';

export interface InvoiceManagerProps {
  children: React.ReactNode;
}

export interface InvoiceManagerContext
  extends Pick<
    IUseDefaultPriceMarginReturnType,
    | 'defaultPriceMargin'
    | 'getProductPriceMargin'
    | 'setDefaultPriceMargin'
    | 'priceMarginList'
  > {
  invoiceCallbacks: MutableRefObject<{
    setInvoice: (invoice: InvoiceModel) => void;
  }>;
  invoiceForm: InvoiceFormDTO;
  invoice: InvoiceModel | null;
  invoiceItemList: List<InvoiceItemModel> | null;
  invoiceItemsLoading: boolean;
  invoiceItemListLimit: number;
  invoiceItemListTotal: number;
  invoiceItemListPage: number;

  handleAddOrUpdateInvoiceItems: (
    value: InvoiceItemFormDTO | InvoiceItemFormDTO[],
    withProductAmount?: boolean,
  ) => Promise<void>;
  handleDeleteInvoiceItem: (value: InvoiceItemModel) => Promise<void>;
  invoiceItemListRefresh: (
    value: Partial<IUseInvoiceItemListProps>,
  ) => Promise<InvoiceItemListModel | void>;
  handleChangeInvoice: (value: Partial<InvoiceFormDTO>) => void;
  loadingSubmit: boolean;
  priceMarginListLoading: boolean;
}

export interface InvoiceManagerState {
  invoiceForm: InvoiceFormDTO;
  loadingSubmit: boolean;
}

export const InvoiceManagerContext = React.createContext<InvoiceManagerContext>(
  {
    invoiceCallbacks: {
      current: {
        setInvoice: () => {},
      },
    },
    defaultPriceMargin: null,
    getProductPriceMargin: () => {
      return '';
    },
    setDefaultPriceMargin: () => {},

    invoiceForm: InvoiceMapper.toInvoiceFormDTO({} as InvoiceModel, {
      editMode: false,
    }),
    invoice: null,
    invoiceItemList: List(),
    invoiceItemsLoading: false,
    invoiceItemListLimit: 100,
    invoiceItemListTotal: 0,
    invoiceItemListPage: 0,

    handleDeleteInvoiceItem: async () => {},
    invoiceItemListRefresh: async () => {},
    handleAddOrUpdateInvoiceItems: async () => {},
    handleChangeInvoice: () => {},
    loadingSubmit: false,
    priceMarginListLoading: false,
    priceMarginList: null,
  },
);

export const useInvoiceManager = () => React.useContext(InvoiceManagerContext);

export const InvoiceManager = function InvoiceManager({
  children,
}: InvoiceManagerProps): React.JSX.Element {
  const {t} = useTranslation();
  const {defaultCompanyUuid} = useStoredCompanies();
  const {alert} = useDropdownAlert();
  const {invoiceId} = useParams();

  const invoiceCallbacks = React.useRef({
    setInvoice: (value: InvoiceModel) => {},
  });

  const {
    invoiceForm,
    loadingSubmit,

    handleUpdate,
  } = useStateReducer<InvoiceManagerState>({
    invoiceForm: InvoiceMapper.toInvoiceFormDTO({} as InvoiceModel, {
      editMode: true,
    }),
    loadingSubmit: false,
  });

  const {invoice: invoice, setInvoice} = useStoredInvoice({
    companyUuid: defaultCompanyUuid,
    invoiceUuid: invoiceId!,
    loadOnInit: false,
  });

  const {
    invoiceItemList,
    invoiceUuid: invoiceItemListInvoiceUuid,
    loading: invoiceItemsLoading,
    refresh: invoiceItemListRefresh,
    limit: invoiceItemListLimit,
    total: invoiceItemListTotal,
    page: invoiceItemListPage,
    setInvoiceItemList,
    handleCreateInvoiceItem: onCreateInvoiceItem,
    handleUpdateInvoiceItem: onUpdateInvoiceItem,
    handleDeleteInvoiceItem: onDeleteInvoiceItem,
  } = useStateInvoiceItemList({
    invoiceUuid: invoiceId!,
    limit: 100,
    invoice: invoice!,
    setInvoice,
  });

  const {
    defaultPriceMargin,
    getProductPriceMargin,
    setDefaultPriceMargin,
    priceMarginList,
    loading: priceMarginListLoading,
  } = useDefaultPriceMargin({
    loadOnInit: true,
  });

  const handleChangeInvoice = React.useCallback(
    (value: Partial<InvoiceFormDTO>) => {
      if (invoiceForm) {
        const updatedInvoice = produce(invoiceForm, (draft: any) => {
          Object.entries(value).forEach(([key, value]) => {
            draft[key] = value;
          });
        });

        handleUpdate({invoiceForm: updatedInvoice});
      }
    },
    [invoiceForm, handleUpdate],
  );

  useEffect(() => {
    if (invoice && invoice?.uuid !== invoiceForm?.uuid) {
      handleUpdate({
        invoiceForm: InvoiceMapper.toInvoiceFormDTO(
          invoice || ({} as InvoiceModel),
          {editMode: true},
        ),
      });
    }
  }, [invoice, invoiceForm, handleUpdate]);

  const notifyError = useCallback(
    (ApiError: any) => {
      alert(
        'error',
        t('Items'),
        `${t('An error occurred during delete item')} : ${ApiError?.message}`,
      );
    },
    [alert, t],
  );

  const addOrUpdateItems = useCallback(
    (item: InvoiceItemModel) => {
      setInvoiceItemList((prevState) => {
        if (List.isList(prevState)) {
          const index = prevState?.findIndex(({uuid}) => uuid === item?.uuid);

          if (~index) {
            return prevState.update(index, (prevItem) => {
              return prevItem?.merge(notEmptyFields(isRecordToObject(item)));
            });
          }

          return prevState.unshift(item);
        }

        return prevState;
      });
    },
    [setInvoiceItemList],
  );

  const handleCreateInvoiceItem = useCallback(
    async (items: InvoiceItemFormDTO[]) => {
      try {
        const {items: invoiceItems, invoice} = await onCreateInvoiceItem(items);

        if (invoiceItems?.items?.size) {
          invoiceItems?.items?.forEach(addOrUpdateItems);
        }

        if (invoice) {
          invoiceCallbacks.current.setInvoice(invoice);
        }
      } catch (error) {
        notifyError(error);
      }
    },
    [addOrUpdateItems, notifyError, onCreateInvoiceItem],
  );

  const handleUpdateInvoiceItem = useCallback(
    async (value: InvoiceItemFormDTO) => {
      try {
        const {item, invoice} = await onUpdateInvoiceItem(value);

        if (item) {
          addOrUpdateItems(item);
        }

        if (invoice) {
          invoiceCallbacks.current.setInvoice(invoice);
        }
      } catch (error) {
        notifyError(error);
      }
    },
    [addOrUpdateItems, notifyError, onUpdateInvoiceItem],
  );

  const handleAddOrUpdateInvoiceItems = useCallback(
    async (value: InvoiceItemFormDTO | InvoiceItemFormDTO[]): Promise<any> => {
      if (Array.isArray(value)) {
        return await handleCreateInvoiceItem(value);
      }

      await handleUpdateInvoiceItem(value);
    },
    [handleCreateInvoiceItem, handleUpdateInvoiceItem],
  );

  const handleDeleteInvoiceItem = useCallback(
    async (value: InvoiceItemModel) => {
      const {invoice} = await onDeleteInvoiceItem([value?.uuid!]);

      if (invoice) {
        invoiceCallbacks.current.setInvoice(invoice);
      }

      setInvoiceItemList((prevState) => {
        if (prevState) {
          return prevState.filter(({uuid}) => uuid !== value?.uuid);
        }
        return prevState;
      });
    },
    [onDeleteInvoiceItem, setInvoiceItemList],
  );

  const value = React.useMemo(
    () => ({
      invoice,
      invoiceForm,
      invoiceItemList: invoiceItemList,
      invoiceItemsLoading: invoiceItemsLoading && !!invoiceItemListInvoiceUuid,
      invoiceItemListLimit,
      invoiceItemListTotal,
      handleDeleteInvoiceItem,
      handleChangeInvoice,
      invoiceItemListRefresh,
      loadingSubmit,
      handleAddOrUpdateInvoiceItems,
      invoiceItemListPage,
      getProductPriceMargin,
      defaultPriceMargin,
      setDefaultPriceMargin,
      priceMarginList,
      priceMarginListLoading,
      invoiceCallbacks,
    }),
    [
      invoiceForm,
      invoiceItemList,
      invoiceItemsLoading,
      invoiceItemListInvoiceUuid,
      invoiceItemListLimit,
      invoiceItemListTotal,
      handleDeleteInvoiceItem,
      handleChangeInvoice,
      invoiceItemListRefresh,
      loadingSubmit,
      handleAddOrUpdateInvoiceItems,
      invoiceItemListPage,
      invoice,
      getProductPriceMargin,
      defaultPriceMargin,
      setDefaultPriceMargin,
      priceMarginList,
      priceMarginListLoading,
      invoiceCallbacks,
    ],
  );

  return (
    <InvoiceManagerContext.Provider value={value}>
      <div className="invoice-manager-container">{children}</div>
    </InvoiceManagerContext.Provider>
  );
};
