export const TIME_FRAMES = {
  '10:00': [],
  '11:00': [],
  '12:00': [],
  '13:00': [],
  '14:00': [],
  '15:00': [],
  '16:00': [],
  '17:00': [],
  '18:00': [],
  '19:00': [],
  '20:00': [],
};

export const STATUS_SCHEDULE_CANCELED_BY_ADMIN = -1;
export const STATUS_SCHEDULE_CANCELED_BY_OWNER = -2;
export const STATUS_SCHEDULE_CANCELED_BY_EMPLOYEE = -3;
