import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {ScheduleCalendarProps, isFunction} from '../../../services/helpers';
import {ChildrenMustBeAFunction} from '../../../components/lib/Errors';
import {IDetailsHeaderProps} from '../../../components/lib/General';
import {ScheduleCalendarModel} from '../../../struture';
import {useNavigate, useParams} from 'react-router';
import {Routes} from '../../../services/types';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';

export interface ICompanyScheduleDetailProps extends ScheduleCalendarProps {
  schedule: ScheduleCalendarModel;
  children: (
    value: Pick<IDetailsHeaderProps, 'routes'> & {navigateGoBack: () => void},
  ) => React.ReactNode;
}

export default function CompanyScheduleDetail({
  schedule,
  children,
}: ICompanyScheduleDetailProps): JSX.Element {
  const {scheduleId, companyId} = useParams();
  const navigate = useNavigate();

  const routes = useBreadcrumb([
    {
      path: `${Routes.app}/${Routes.calendar}/entry/${scheduleId || ''}`,
      breadcrumbName: `${
        schedule?.schedule_number ? `#${schedule?.schedule_number}` : ''
      }`,
    },
  ]);

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.companies}/${companyId}`);
  }, [companyId, navigate]);

  if (!isFunction(children)) {
    throw new ChildrenMustBeAFunction('CompanyScheduleDetail');
  }

  return <>{children({routes, navigateGoBack})}</>;
}
