export enum SERVICE_EMPLOYEE_LIST {
  SET_SERVICE_EMPLOYEE_LIST = 'set_service_employee_list',
  LOADING_SERVICE_EMPLOYEE_LIST = 'loading_service_employee_list',
  ERROR_SERVICE_EMPLOYEE_LIST = 'error_service_employee_list',
  LOAD_MORE_SERVICE_EMPLOYEE_LIST = 'load_more_service_employee_list',
  ADD_SERVICE_EMPLOYEE = 'add_service_employee',
  ADD_EMPLOYEE_TO_SERVICE = 'add_employee_to_service',
  UPDATE_SERVICE_EMPLOYEE = 'update_service_employee',
  UPDATE_EMPLOYEE_FOR_SERVICE = 'update_employee_for_service',
  DELETE_SERVICE_EMPLOYEE = 'delete_service_employee',
  DELETE_EMPLOYEE_FROM_SERVICE = 'delete_employee_from_service',
}
