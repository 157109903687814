import {Record} from 'immutable';

export interface IClientsGroupSessionsProps {
  uuid: string;
  status: number;
  created_at: string;
  updated_at: string;
}

/**
 * @class ClientsGroupSessions
 * @desc
 * @extends {Record}
 */
export default class ClientsGroupSessions extends Record({
  uuid: '',
  status: 0,
  created_at: '',
  updated_at: '',
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(
    props: IClientsGroupSessionsProps = {} as IClientsGroupSessionsProps,
  ) {
    super({...props});
  }
}
