import {Record, List} from 'immutable';
import {ListModel, ListProps} from './List';
import GroupPayment, {IGroupPaymentProps} from './GroupPayment';

export interface IGroupPaymentListProps extends Partial<ListProps> {
  groups: IGroupPaymentProps[];
}

export interface IGroupPaymentListReturnType
  extends Omit<IGroupPaymentListProps, 'groups'> {
  groups: List<GroupPayment[]>;
}

export default class GroupPaymentList extends Record<IGroupPaymentListReturnType>(
  {
    groups: List(),
    ...ListModel.toJS(),
  } as any,
) {
  constructor({groups = [], ...props}: IGroupPaymentListProps) {
    const options: any = {};

    if (Array.isArray(groups)) {
      options.groups = List(groups.map((group) => new GroupPayment(group)));
    }

    super({
      ...props,
      ...options,
    });
  }
}
