import {Record} from 'immutable';
import {WithoutRecursion} from '../type';
import {CounterpartyDTO} from '../internal';

export interface ICounterpartyModelReturnType {
  uuid: string;
  title: string;
  manager: string;
  phone: string;
  email: string;
  site: string;
}

export class CounterpartyModel extends Record<ICounterpartyModelReturnType>({
  uuid: '',
  title: '',
  manager: '',
  phone: '',
  email: '',
  site: '',
}) {
  constructor(
    props: CounterpartyDTO = {} as CounterpartyDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: ICounterpartyModelReturnType = {
      ...props,
    };

    super(options);
  }
}
