import React from 'react';
import {calculateTopIndentEvent} from '../../../services/helpers';

import './CalendarTodayTileLine.less';

export default function CalendarTodayTileLine() {
  return (
    <div className="time-line" style={{top: calculateTopIndentEvent()}}>
      <div className="time-line-circle" />
      <div className="time-line-divide" />
    </div>
  );
}
