import {
  IProfileDTOProps,
  ProfileDTO,
  CategoryDTO,
  ICategoryDTOProps,
  EmployeeDTO,
  IEmployeeDTOProps,
  ISettingDTOProps,
  SettingDTO,
  GroupDTO,
  IGroupDTOProps,
  CategoryMapper,
  EmployeeMapper,
  GroupMapper,
  ProfileMapper,
  SettingMapper,
  ICompanyModelReturnType,
} from '../internal';
import {WithoutRecursion} from '../type';
import {
  STATUS_ACTIVE,
  STATUS_UNCHECKED,
  STATUS_HANDLING,
} from '../../constants';
import {correctPrice} from '../../helpers';

export interface IWorkSchedules {
  [key: string]: {min: string; max: string};
}

export enum CompanyStatuses {
  Active = STATUS_ACTIVE,
  Unchecked = STATUS_UNCHECKED,
  Handling = STATUS_HANDLING,
}

export interface ICompanyDTOProps {
  id: string;
  uuid: string;
  title: string;
  phone: string;
  sms_notification_price: string;
  phone_checked: number;
  email: string;
  email_checked: number;
  description: string;
  cover_url: string;
  logo_url: string;
  qr_code_url: string;
  address: string;
  address_langitude: string;
  address_latitude: string;
  slogan: string;
  location_country: string;
  location_region: string;
  location_district: string;
  location_city: string;
  status: CompanyStatuses;
  joining_code: number;
  joining_url: string;
  created_at: string;
  updated_at: string;
  currency_symbol: string;
  notification_language: string;
  created_by: IProfileDTOProps;
  categories: ICategoryDTOProps[];
  employees: IEmployeeDTOProps[];
  work_schedules: string;
  work_schedules_list: IWorkSchedules;
  company_settings: ISettingDTOProps[];
  groups: IGroupDTOProps[];
  balance: string;
  requisites: string;
}

export class CompanyDTO {
  public id: string;

  public uuid: string;

  public title: string;

  public phone: string;

  public phone_checked: number;

  public email: string;

  public sms_notification_price: string;

  public email_checked: number;

  public description: string;

  public cover_url: string;

  public logo_url: string;

  public qr_code_url: string;

  public address: string;

  public address_langitude: string;

  public address_latitude: string;

  public slogan: string;

  public location_country: string;

  public location_region: string;

  public location_district: string;

  public location_city: string;

  public status: CompanyStatuses;

  public joining_code: number;

  public joining_url: string;

  public created_at: string;

  public updated_at: string;

  public balance: string;

  public currency_symbol: string;

  public notification_language: string;

  public created_by: ProfileDTO;

  public categories: CategoryDTO[];

  public employees: EmployeeDTO[];

  public work_schedules: string;

  public requisites: string;

  public work_schedules_list: IWorkSchedules;

  public company_settings: SettingDTO[];

  public groups: GroupDTO[];

  constructor(
    props: ICompanyModelReturnType | ICompanyDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.id = props?.id || '';
    this.uuid = props?.uuid || '';
    this.title = props?.title || '';
    this.phone = props?.phone || '';
    this.sms_notification_price = props?.sms_notification_price || '';
    this.phone_checked = props?.phone_checked || 0;
    this.email = props?.email || '';
    this.email_checked = props?.email_checked || 0;
    this.joining_code = props?.joining_code || 0;
    this.description = props?.description || '';
    this.joining_url = props?.joining_url || '';
    this.cover_url = props?.cover_url || '';
    this.logo_url = props?.logo_url || '';
    this.qr_code_url = props?.qr_code_url || '';
    this.address = props?.address || '';
    this.address_langitude = props?.address_langitude || '';
    this.address_latitude = props?.address_latitude || '';
    this.slogan = props?.slogan || '';
    this.location_country = props?.location_country || '';
    this.location_region = props?.location_region || '';
    this.location_district = props?.location_district || '';
    this.location_city = props?.location_city || '';
    this.status = props?.status || CompanyStatuses.Unchecked;
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
    this.currency_symbol = props?.currency_symbol || '₴';
    this.notification_language = props?.notification_language || '';
    this.work_schedules = props?.work_schedules || '';
    this.requisites = props?.requisites || '';
    this.work_schedules_list = props?.work_schedules_list || {};
    this.balance = correctPrice(props?.balance);

    this.company_settings = SettingMapper.toSettingListDTO(
      props?.company_settings || [],
    );

    this.created_by =
      withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
        ? (props?.created_by as ProfileDTO)
        : ProfileMapper.toProfileDTO(props?.created_by, [
            WithoutRecursion.company,
            ...withoutRecursion,
          ]);

    this.categories =
      withoutRecursion.indexOf(WithoutRecursion.category) !== -1
        ? ((props?.categories || []) as CategoryDTO[])
        : CategoryMapper.toCategoryListDTO(props?.categories || [], 0, [
            WithoutRecursion.company,
            ...withoutRecursion,
          ]).categories;
    this.employees =
      withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
        ? ((props?.employees || []) as EmployeeDTO[])
        : EmployeeMapper.toEmployeeListDTO(props?.employees || [], 0, [
            WithoutRecursion.company,
            ...withoutRecursion,
          ]).employees;

    this.groups =
      withoutRecursion.indexOf(WithoutRecursion.group) !== -1
        ? ((props?.groups || []) as GroupDTO[])
        : GroupMapper.toGroupListDTO(props?.groups || [], [
            WithoutRecursion.company,
            ...withoutRecursion,
          ]);
  }
}
