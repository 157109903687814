import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Empty} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import {ListLayout} from '../../../components/lib/Layout';
import {
  TariffModelGroupCreateButton,
  TariffModelGroupsDeleteButton,
  TariffModelGroupEditButton,
} from '../Buttons';
import {
  useStoredAbonementGroupList,
  useStopLoading,
  useStoredCompanies,
  useStoredAbonementGroup,
} from '../../../hooks';
import {len, lessOrEqualThan, textToUpperCase} from '../../../services/helpers';
import {AbonementGroupModel, AbonementGroupMapper} from '../../../struture';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '../../../services/types';
import {
  TableLinkCell,
  TableActionCell,
  Table,
} from '../../../components/lib/libV2/DataDisplay';
import {useDropdownAlert, useAcl} from '../../../contex';
import {HeaderLeft, ListActions} from '../../../components/lib/DataDisplay';
import ListSearchInput from '../../../components/lib/DataDisplay/ListActions/ListSearchInput';
import {useNavigate, useParams} from 'react-router';

export function TariffModelGroupListPage() {
  const {tariffModelId} = useParams();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {defaultCompanyLoading} = useStoredCompanies();
  const {manage} = useAcl(({abonement}) => abonement);
  const {alert} = useDropdownAlert();

  const [selectedAbonementGroups, setSelectedAbonementGroups] = useState<
    AbonementGroupModel[]
  >([]);

  const {
    groupList,
    loading: groupsListLoading,
    error: groupsListError,
    limit: pageSize,
    total,
    loadingMore,
    keywords,
    page,

    handleRefreshAbonementGroups,
    handleDeleteAbonementGroups,
    handleSearchAbonementGroups,
  } = useStoredAbonementGroupList({abonementUuid: tariffModelId!});

  const {handleCreateAbonementGroup, handleUpdateAbonementGroup} =
    useStoredAbonementGroup({
      abonementUuid: tariffModelId!,
      loadOnInit: false,
      groupUuid: '',
    });

  const loading = useStopLoading({
    error: groupsListError,
    loading: groupsListLoading || loadingMore,
    message: 'An error occurred during tariff model groups loading',
  });

  const routes = useBreadcrumb([
    {
      path: `/${Routes.tariffModels}`,
      breadcrumbName: 'Tariff models',
    },
    {
      path: `/${Routes.tariffModels}/${tariffModelId}`,
      breadcrumbName: 'Show tariff model',
    },
    {
      path: `/${Routes.tariffModels}/${tariffModelId}/${Routes.tariffModelGroup}`,
      breadcrumbName: 'Tariff model groups',
    },
  ]);

  const rowSelection = useMemo(
    () => ({
      hideSelectAll: true,
      selectedRowKeys: selectedAbonementGroups.map(({uuid}) => uuid),
      onChange: (
        selectedRowKeys: React.Key[],
        selectedRecords: AbonementGroupModel[],
      ) => {
        if (lessOrEqualThan(len(selectedRecords), 1)) {
          setSelectedAbonementGroups(selectedRecords);
        } else {
          alert('error', t('Delete'), t('You can delete up to 1 at a time'));
        }
      },
    }),
    [alert, selectedAbonementGroups, t],
  );

  const columns = [
    {
      title: t('Title'),
      key: 'title',
      render: (group: AbonementGroupModel) => (
        <TableLinkCell
          tooltip={t('Open a detailed of the tariff model group')}
          path={`${group?.uuid}`}>
          {group?.group_title}
        </TableLinkCell>
      ),
    },
    {
      title: t('Note'),
      key: 'note',
      render: (c: AbonementGroupModel) => c?.group_comment,
    },
    {
      title: t('Actions'),
      key: 'group_actions',
      align: 'center' as any,
      fixed: 'right' as any,
      render: (group: AbonementGroupModel) => (
        <TableActionCell>
          <TariffModelGroupEditButton
            disabled={!manage}
            type="text"
            group={AbonementGroupMapper.toAbonementGroupFormDTO(group, true)}
            data-testid="group-edit-button"
            onSuccess={handleUpdateAbonementGroup}
          />
          <TariffModelGroupsDeleteButton
            disabled={!manage}
            data-testid="group-delete-button"
            groups={[group]}
            onSuccess={handleDeleteAbonementGroups}>
            <DeleteOutlined />
          </TariffModelGroupsDeleteButton>
        </TableActionCell>
      ),
    },
  ];

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.tariffModels}/${tariffModelId}`);
  }, [tariffModelId, navigate]);

  return (
    <ListLayout
      moduleItem="abonement"
      aclItem={RoutesAcl[Routes.tariffModelGroup]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.tariffModelGroup]))}
      headerRight={
        <>
          {selectedAbonementGroups.length ? (
            <TariffModelGroupsDeleteButton
              disabled={!manage}
              groups={selectedAbonementGroups}
              onSuccess={handleDeleteAbonementGroups}
            />
          ) : (
            <TariffModelGroupCreateButton
              disabled={!manage}
              loading={loading}
              onSuccess={handleCreateAbonementGroup}
            />
          )}
        </>
      }
      routes={routes}
      empty={
        groupList?.size ? null : (
          <Empty
            description={t(
              `It looks like you don't have any tariff model groups at the moment.`,
            )}>
            <TariffModelGroupCreateButton
              type="primary"
              disabled={!manage}
              loading={loading}
              onSuccess={handleCreateAbonementGroup}>
              {t('Create tariff model group')}
            </TariffModelGroupCreateButton>
          </Empty>
        )
      }
      headerExtra={
        <ListActions
          withoutPicker
          loading={loading}
          handleSearch={(keywords) => handleSearchAbonementGroups({keywords})}
          inputTooltip={t('Search tariff model groups')}
          inputLabel={t('Search tariff model groups')}
        />
      }
      loading={loading && !groupList?.size}>
      <Table<AbonementGroupModel>
        rowSelection={rowSelection}
        total={total}
        pageSize={pageSize}
        dataSource={groupList}
        onChange={handleRefreshAbonementGroups}
        columns={columns}
        loading={loading || defaultCompanyLoading}
        page={page}
      />
    </ListLayout>
  );
}
