import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {LoadingOutlined} from '@ant-design/icons';
import {ButtonProps} from 'antd/lib/button';
import {ActionButton} from '../../../components/lib/General';
import {SuspenseEmpty} from '../../../components/lib/DataDisplay';
import {StyledTitle} from '../../../components/lib/Styled';
import {useModal} from '../../../components/lib/libV2/hooks';
import {EmployeeUserDataFormDTO} from '../../../struture';
import styled, {useTheme} from 'styled-components';
import {FaUserLock} from 'react-icons/fa';

const EmployeeUserDataSideWindowLazy = React.lazy(
  () => import('../Show/EmployeeUserDataSideWindow'),
);

export interface IEmployeeUserDataButton extends ButtonProps {
  loading: boolean;
  onSuccess: (value: EmployeeUserDataFormDTO) => Promise<void>;
  onCancel?: () => void;
  employee: EmployeeUserDataFormDTO;
}

const StyledTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  height: 100%;
  width: fit-content;
`;

const StyledActionButton = styled(ActionButton)`
  width: fit-content;

  &&& {
    line-height: 0 !important;
  }
`;

export default function EmployeeUserDataButton({
  loading = false,
  onSuccess,
  onCancel,
  employee,
  ...rest
}: IEmployeeUserDataButton) {
  const {t} = useTranslation();
  const theme: any = useTheme();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <StyledActionButton
        tooltip={t('Set password')}
        type="default"
        onClick={handleOnInit}
        {...rest}>
        {loading ? (
          <LoadingOutlined color={theme.colors.primary} />
        ) : (
          <StyledTextContainer>
            <FaUserLock size={20} color={theme.colors.primary} />
            <StyledTitle $color={theme.colors.primary}>
              {t('Set password')}
            </StyledTitle>
          </StyledTextContainer>
        )}
      </StyledActionButton>

      <Suspense fallback={<SuspenseEmpty />}>
        <EmployeeUserDataSideWindowLazy
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
          employee={employee}
        />
      </Suspense>
    </>
  );
}
