import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Col, Form, Input, Row} from 'antd';
import {
  isEqualByUuid,
  isListToArray,
  isRecordToObject,
  toDateByFormat,
} from '../../../services/helpers';
import {SearchSelect} from '../../../components/lib/DataDisplay';
import {
  EmployeeModel,
  EmployeeStatuses,
  ScheduleCalendarMapper,
  ScheduleCalendarModel,
  ServiceModel,
} from '../../../struture';
import {OrderCalendarDayManager} from '../Show';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {StyledDescription} from '../../../components/lib/Styled';
import {List} from 'immutable';
import {IGetListEmployeeScheduleProps} from './OrderForm';
import moment, {Moment} from 'moment/moment';
import {IRelatedOrderProps} from '../Pages/OrderListPage';
import {useDefaultForm} from '../../../contex';
import {correctOrderScheduleDate} from '../../../services/api/orders';
import {SelectValue} from 'antd/lib/select';

export interface IOrderChangeTimeFieldsProps {
  loading?: boolean;
  handleGetListEmployeeSchedule: (
    value: IGetListEmployeeScheduleProps,
  ) => Promise<void>;
  schedules: List<ScheduleCalendarModel>;
  employees: List<EmployeeModel> | null;
  scheduleLoading: boolean;
  employeesLoading: boolean;
  handleSearchEmployees: (keywords: string) => Promise<void>;
  relatedSchedule?: IRelatedOrderProps | undefined;
}

const activeItem = [
  EmployeeStatuses.Activated,
  EmployeeStatuses.Confirmed,
  EmployeeStatuses.Created,
];

const Description = styled(StyledDescription)`
  position: relative;
  top: -20px;
`;

export function OrderChangeTimeFields({
  relatedSchedule,
  schedules,
  handleGetListEmployeeSchedule,
  handleSearchEmployees,
  scheduleLoading,
  loading,
  employees,
  employeesLoading,
}: IOrderChangeTimeFieldsProps): JSX.Element {
  const {t} = useTranslation();
  const {loadingSubmit, formData, handlerUpdateFormState}: any =
    useDefaultForm();
  const [selectedEmployee, setSelectedEmployee] =
    useState<EmployeeModel | null>();

  const isEmployee = useRef(false);

  const handleChangeScheduledDate = useCallback(
    async (e: Moment | null) => {
      handlerUpdateFormState({
        scheduled_date: e,
        date: correctOrderScheduleDate({
          time: formData?.time || '',
          scheduled_date: e || moment(),
        }),
      });

      await handleGetListEmployeeSchedule({
        date: e,
        employeeUuid:
          typeof formData.scheduled_worker_uuid === 'object'
            ? formData.scheduled_worker_uuid?.uuid
            : formData.scheduled_worker_uuid,
      });
    },
    [
      formData?.time,
      formData.scheduled_worker_uuid,
      handleGetListEmployeeSchedule,
      handlerUpdateFormState,
    ],
  );

  const handleChangeTime = useCallback(
    (time: string): void => {
      if (formData.time !== time) {
        handlerUpdateFormState({
          time,
          date: correctOrderScheduleDate({
            time,
            scheduled_date: formData?.scheduled_date,
          }),
        });
      }
    },
    [formData?.scheduled_date, formData.time, handlerUpdateFormState],
  );

  const findEmployeeById = useCallback(
    (uuid: string) => {
      const employee = employees?.find(isEqualByUuid(uuid));

      setSelectedEmployee(employee);
    },
    [employees],
  );

  const handleChangeManger = useCallback(
    async (scheduled_worker_uuid: SelectValue): Promise<void> => {
      handlerUpdateFormState({scheduled_worker_uuid});

      await handleGetListEmployeeSchedule({
        date: formData.scheduled_date,
        employeeUuid: scheduled_worker_uuid as string,
      });

      findEmployeeById(scheduled_worker_uuid as string);
    },
    [
      findEmployeeById,
      formData.scheduled_date,
      handleGetListEmployeeSchedule,
      handlerUpdateFormState,
    ],
  );

  useEffect(() => {
    if (
      !isEmployee.current &&
      List.isList(employees) &&
      formData.scheduled_worker_uuid
    ) {
      if (typeof formData?.scheduled_worker_uuid === 'object') {
        setSelectedEmployee(formData?.scheduled_worker_uuid);
      }

      isEmployee.current = true;
    }
  }, [
    employees,
    formData.scheduled_worker_uuid,
    handlerUpdateFormState,
    formData.scheduled_date,
    findEmployeeById,
    handleChangeManger,
  ]);

  return (
    <Row gutter={20} justify="end">
      {relatedSchedule ? (
        <Col span={24}>
          <Form.Item
            label={t('The main order')}
            tooltip={{
              title: t('The main order'),
            }}
            name="schedule_number">
            <Input disabled value={relatedSchedule?.scheduleNumber} />
          </Form.Item>
          <Description>{`${t('From')} ${toDateByFormat(
            relatedSchedule?.scheduleDate,
            'DD-MM-YYYY HH:mm',
          )}`}</Description>
        </Col>
      ) : null}

      <Col span={24}>
        <Form.Item
          label={t('Manager')}
          name="scheduled_worker_uuid"
          rules={[
            {
              required: true,
              message: t('Manager must be specified'),
            },
          ]}
          tooltip={{
            title: t('Service manager'),
          }}>
          <SearchSelect
            name="scheduled_worker_uuid"
            disable={loading || loadingSubmit || employeesLoading}
            placeholder={t('Search a manager')}
            getOptionValueTitle="fullName"
            data={isListToArray(employees as any)}
            onChange={handleChangeManger}
            onSearch={handleSearchEmployees}
            itemDisabled={(employee: EmployeeModel) => {
              return !activeItem.includes(employee?.status);
            }}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          label={t('Scheduled date')}
          name="time"
          rules={[
            {
              required: true,
              message: t('Schedule of event must be specified'),
            },
          ]}
          tooltip={{
            title: t('Schedule of events'),
          }}>
          <OrderCalendarDayManager
            limitedByDate={relatedSchedule?.scheduleDate}
            isEdit
            schedules={schedules}
            schedule={
              selectedEmployee
                ? ScheduleCalendarMapper.toScheduleCalendarModel({
                    employee: isRecordToObject(selectedEmployee as any),
                  } as any)
                : null
            }
            handleChangeDate={handleChangeScheduledDate}
            handleChangeTime={handleChangeTime}
            scheduleDate={formData.scheduled_date}
            scheduleUuid={formData.uuid}
            editMode
            scheduleLoading={scheduleLoading}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item label={t('note')} name="schedule_comment">
          <Input.TextArea
            allowClear
            autoSize={{minRows: 3, maxRows: 5}}
            disabled={loadingSubmit}
            placeholder={t('Enter your note')}
            onChange={(e) =>
              handlerUpdateFormState({schedule_comment: e.target.value})
            }
          />
        </Form.Item>
      </Col>
    </Row>
  );
}
