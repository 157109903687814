import * as React from 'react';
import useUserDetails, {IUseUserDetailsReturnType} from './useUserDetails';
import useDataItemState from './useDataItemState';
import {correctBirthdayValue} from '../services/helpers';
import {
  editUser,
  ICompanyUserParams,
  IDisableOrEnableUserProps,
  disableOrEnableUser,
} from '../services/api/companyUser';
import {User} from '../services/models';

export interface IUseStateUserProps {
  userUuid: string;
  loadOnInit?: boolean;
}

export interface IUseStateUserReturnType extends IUseUserDetailsReturnType {
  handleUpdateUser: (value: ICompanyUserParams) => Promise<void>;
  handleDisabledAccount: (value: IDisableOrEnableUserProps) => Promise<void>;
}

export default function useStateUser({
  userUuid,
  loadOnInit,
}: IUseStateUserProps): IUseStateUserReturnType {
  /**
   * @desc Деталі клієнта
   * */
  const {user, ...rest} = useUserDetails({
    userUuid,
    loadOnInit,
  });

  const {data: stateUser, handleUpdate} = useDataItemState<User>(user);

  const handleUpdateUser = React.useCallback(
    async (value: ICompanyUserParams) => {
      const user = await editUser(
        correctBirthdayValue(value) as ICompanyUserParams,
      );

      handleUpdate(user);
    },
    [handleUpdate],
  );

  const handleDisabledAccount = React.useCallback(
    async (value: IDisableOrEnableUserProps) => {
      const user = await disableOrEnableUser(value);

      handleUpdate(user);
    },
    [handleUpdate],
  );

  return {
    handleUpdateUser,
    user: stateUser,
    handleDisabledAccount,
    ...rest,
  };
}
