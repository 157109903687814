import {Record, List} from 'immutable';
import {ListModel, ListProps} from './List';
import ClientSubscription, {
  IClientSubscriptionProps,
} from './ClientSubscription';

export interface IClientSubscriptionListProps extends ListProps {
  subscriptions: IClientSubscriptionProps[];
}

export interface IClientSubscriptionListReturnType extends ListProps {
  subscriptions: List<ClientSubscription>;
}

export default class ClientSubscriptionsList extends Record<IClientSubscriptionListReturnType>(
  {
    subscriptions: List(),
    ...ListModel.toJS(),
  } as any,
) {
  /**
   * @constructor
   * @param {{clients:[]}} param0
   */
  constructor({subscriptions = [], ...props}: IClientSubscriptionListProps) {
    const options: any = {};
    if (Array.isArray(subscriptions)) {
      options.subscriptions = List(
        subscriptions.map(
          (subscription) => new ClientSubscription(subscription),
        ),
      );
    }

    super({
      ...props,
      ...options,
    });
  }
}
