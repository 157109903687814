import * as React from 'react';
import {
  useAbonement,
  IUseAbonementProps,
  IUseAbonementReturnType,
} from './useAbonement';
import {
  AbonementFormDTO,
  AbonementModel,
  AbonementMapper,
  EmployeeModel,
  AbonementStatuses,
  ServiceModel,
} from '../struture';
import {
  createAbonement,
  editAbonement,
  changeAbonementStatus,
} from '../services/api/abonement';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../store/reducers';
import {REDUX_STATUS} from '../services/types';
import {useEffect} from 'react';
import {
  addAbonementToList as storeAddAbonement,
  setAbonement as storeSetAbonement,
  updateAbonement as storeUpdateAbonement,
  updateAbonementFromList as storeUpdateAbonementFromList,
  resetAbonement as storeResetAbonement,
} from '../store/actions';
import {toStringDate} from '../services/helpers';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../contex';

export interface IUseStateAbonementProps extends IUseAbonementProps {
  companyUuid: string;
}

export interface IUseStateAbonementReturnType
  extends Omit<IUseAbonementReturnType, 'entity'> {
  abonement: AbonementModel | null;
  handleUpdateAbonement: (value: AbonementFormDTO) => Promise<void>;
  handleCreateAbonement: (value: AbonementFormDTO) => Promise<void>;
  handleChangeAbonementStatus: (
    abonementUuid: string,
    status: AbonementStatuses,
  ) => Promise<AbonementModel | void>;
  handleRefreshAbonement: () => Promise<void>;
  handleResetAbonement: () => void;
  status: REDUX_STATUS;
}

export function useStoredAbonement({
  companyUuid,
  loadOnInit,
  abonementUuid,
  ...rest
}: IUseStateAbonementProps): IUseStateAbonementReturnType {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const [abonement, setAbonement] = React.useState<AbonementModel | null>(null);
  const [isStarLoading, setIsStartLoading] = React.useState<boolean>(false);

  const {
    status: storedAbonementStatus,
    cachedAbonementList,
    ...storedAbonementParams
  } = useSelector(({abonement}: RootState) => abonement);

  const dispatch = useDispatch<any>();
  const once = React.useRef(false);

  const {entity, refresh, ...abonementParams} = useAbonement({
    loadOnInit: loadOnInit || isStarLoading,
    abonementUuid,
    ...rest,
  });

  React.useEffect(() => {
    if (cachedAbonementList?.size > 0) {
      const service = cachedAbonementList.get(abonementUuid);

      if (service) {
        setIsStartLoading(false);
        setAbonement(service);
      } else {
        setIsStartLoading(true);
      }
    }
    if (cachedAbonementList?.size === 0 && !abonement) {
      setIsStartLoading(true);
    }
  }, [cachedAbonementList, dispatch, abonementUuid, abonement]);

  useEffect(() => {
    if (entity && isStarLoading && !once.current) {
      dispatch(storeSetAbonement(entity));
      setAbonement(entity);
    }
  }, [dispatch, entity, isStarLoading, storedAbonementStatus]);

  const handleUpdateAbonement = React.useCallback(
    async (value: AbonementFormDTO): Promise<void> => {
      const abonementDTO = await editAbonement({
        ...value,
        service_uuid:
          value?.service_uuid instanceof ServiceModel
            ? value?.service_uuid?.uuid
            : value?.service_uuid,
        manager_uuid:
          value?.manager_uuid instanceof EmployeeModel
            ? value?.manager_uuid?.uuid
            : value?.manager_uuid,
      });

      const abonementModel = AbonementMapper.toAbonementModel(abonementDTO);

      dispatch(storeUpdateAbonement(abonementModel));
      dispatch(storeUpdateAbonementFromList(abonementModel));

      setAbonement(abonementModel);
    },
    [dispatch],
  );

  const handleCreateAbonement = React.useCallback(
    async (value: AbonementFormDTO) => {
      const abonementDTO = await createAbonement(value, companyUuid);

      const abonementModel = AbonementMapper.toAbonementModel(abonementDTO);

      const updatedModel = abonementModel.set(
        'created_at',
        toStringDate(new Date()),
      );

      dispatch(storeAddAbonement(updatedModel));
    },
    [companyUuid, dispatch],
  );

  const handleResetAbonement = React.useCallback(() => {
    once.current = true;
    dispatch(storeResetAbonement());
  }, [dispatch]);

  const handleRefreshAbonement = React.useCallback(async () => {
    const abonementModel = await refresh({showLoading: false, abonementUuid});

    if (abonementModel) {
      dispatch(storeUpdateAbonement(abonementModel));
      dispatch(storeUpdateAbonementFromList(abonementModel));
    }
  }, [abonementUuid, dispatch, refresh]);

  const handleChangeAbonementStatus = React.useCallback(
    async (
      abonementUuid: string,
      status: AbonementStatuses,
    ): Promise<AbonementModel | void> => {
      try {
        const abonementDTO = await changeAbonementStatus(abonementUuid, status);
        const abonementModel = AbonementMapper.toAbonementModel(abonementDTO);

        dispatch(storeUpdateAbonement(abonementModel));

        alert(
          'success',
          status === AbonementStatuses.Disabled
            ? t('Abonement')
            : t('Abonement'),
          status === AbonementStatuses.Disabled
            ? t('Abonement disabled')
            : t('Abonement activated'),
        );

        return abonementModel;
      } catch (error: any) {
        alert(
          'error',
          status === AbonementStatuses.Disabled
            ? t('Abonement')
            : t('Abonement'),
          `${t(
            `An error occurred during ${
              status === AbonementStatuses.Disabled ? 'disabled' : 'activated'
            } abonement`,
          )} : ${error?.message}`,
        );
      }
    },
    [alert, dispatch, t],
  );

  return {
    ...abonementParams,
    ...storedAbonementParams,
    abonement,
    loading: !abonement,
    handleUpdateAbonement,
    handleCreateAbonement,
    status: storedAbonementStatus,
    handleResetAbonement,
    refresh,
    handleRefreshAbonement,
    handleChangeAbonementStatus,
  };
}
