import React from 'react';
import {Form, Row, Col} from 'antd';
import {useTranslation, withTranslation, WithTranslation} from 'react-i18next';
import {
  DefaultForm,
  IDefaultFormProps,
  PhoneInputComponent,
} from '../../../../components/lib/General';
import {Input, Password} from '../../../../components/lib/DataEntry';
import {SignupFormDTO} from '../../../../struture';
import {AuthButton} from '../../Buttons';
import {LockOutlined} from '@ant-design/icons';

import './SignupPhoneInput.less';

export default withTranslation()(SignupForm);

export interface ISignupFormProps
  extends WithTranslation,
    Omit<
      IDefaultFormProps<SignupFormDTO, SignupFormDTO>,
      'children' | 'initialValues'
    > {
  onCancel?: () => void;
  onSuccess: (value: SignupFormDTO) => Promise<void | boolean>;
  onError?: () => Promise<void>;
  signup: SignupFormDTO;
  withVerificationCode?: boolean;
}

function SignupForm({
  signup,
  withVerificationCode,
  ...rest
}: ISignupFormProps): JSX.Element {
  const {t} = useTranslation();

  return (
    <DefaultForm initialValues={{...signup}} showFooter={false} {...rest}>
      {({loadingSubmit, submit}) => (
        <Row gutter={24}>
          <Col xs={24} sm={12} xl={12} md={12} lg={12}>
            <Form.Item
              name="name"
              label={t('name')}
              rules={[
                {
                  required: true,
                  message: t('Name must be specified'),
                },
              ]}>
              <Input
                data-testid="signup-form__input--email"
                disabled={loadingSubmit}
                placeholder={t('Enter your name')}
              />
            </Form.Item>
          </Col>
          <Col xs={24} xl={12} sm={12} md={12} lg={12}>
            <Form.Item
              label={t('Phone')}
              name="phone"
              rules={[
                {
                  required: true,
                  message: t('Phone must be specified.'),
                },
              ]}>
              <PhoneInputComponent
                dataTestid="client-create-input-name"
                placeholder={t('Enter the phone')}
                id="signup-field-phone"
                containerClass="signup-field-phone-container"
                searchClass="signup-field-phone-search"
                inputClass="ant-input signup-field-phone"
                loading={loadingSubmit}
                buttonClass="signup-selected-flag"
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('Password')}
              name="password"
              rules={[
                {
                  required: true,
                  message: t('Please input your password!'),
                },
              ]}>
              <Password
                disabled={loadingSubmit}
                prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}} />}
                type="password"
                placeholder={t('Enter your password')}
                visibilityToggle={false}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t('Confirm Password')}
              name="password_confirmation"
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: t('Please confirm your password!'),
                },
                ({getFieldValue}) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        t('The two passwords that you entered do not match!'),
                      ),
                    );
                  },
                }),
              ]}>
              <Input
                disabled={loadingSubmit}
                prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}} />}
                type="password"
                placeholder={t('Confirm password')}
              />
            </Form.Item>
          </Col>

          {withVerificationCode ? (
            <Col span={24}>
              <Form.Item
                name="invitation_code"
                label={t('Enter invite code')}
                rules={[
                  {
                    required: true,
                    message: t('Invitation code must be specified'),
                  },
                ]}>
                <Input
                  data-testid="signup-form__input--invite-code"
                  disabled={loadingSubmit}
                  placeholder={t('Enter invite code')}
                />
              </Form.Item>
            </Col>
          ) : null}

          <Col span={24}>
            <AuthButton
              size={'large'}
              disabled={loadingSubmit}
              data-testid="signup-form__button--submit"
              loading={loadingSubmit}
              type="primary"
              onClick={submit}
              block>
              {t('Sing up')}
            </AuthButton>
          </Col>
        </Row>
      )}
    </DefaultForm>
  );
}
