import {List} from 'immutable';
import {
  ClientSubscriptionDTO,
  IClientSubscriptionDTOProps,
  ClientSubscriptionModel,
  ClientSubscriptionListModel,
} from '../internal';

import {listToArray} from '../../helpers';

export interface IClientSubscriptionListDTO {
  subscriptions: ClientSubscriptionDTO[];
  total: number;
}

export class ClientSubscriptionMapper {
  public static toClientSubscriptionDTO(
    props: ClientSubscriptionModel | IClientSubscriptionDTOProps,
  ): ClientSubscriptionDTO {
    return new ClientSubscriptionDTO({
      uuid: props?.uuid,
      start_date: props?.start_date,
      end_date: props?.end_date,
      comment: props?.comment,
      period_amount: props?.period_amount,
      price: props?.price,
      created_at: props?.created_at,
      updated_at: props?.updated_at,
      payments: props?.payments,
      type: props?.type,
      status: props?.status,
      subscriptionable: props?.subscriptionable as any,
    });
  }

  public static toClientSubscriptionListDTO(
    subscriptions:
      | List<ClientSubscriptionModel>
      | IClientSubscriptionDTOProps[],
    total?: number,
  ): IClientSubscriptionListDTO {
    const subscriptionList = List.isList(subscriptions)
      ? listToArray<ClientSubscriptionModel>(subscriptions)
      : subscriptions;

    return {
      subscriptions: subscriptionList?.map((subscription) =>
        ClientSubscriptionMapper.toClientSubscriptionDTO(subscription),
      ),
      total: total || 0,
    };
  }

  public static toClientSubscriptionModel(
    subscriptionDTO: ClientSubscriptionDTO,
  ): ClientSubscriptionModel {
    return new ClientSubscriptionModel(subscriptionDTO);
  }

  public static toClientSubscriptionListModel(
    subscriptionDTOs: ClientSubscriptionDTO[],
    total?: number,
  ): ClientSubscriptionListModel {
    return new ClientSubscriptionListModel({
      subscriptions: subscriptionDTOs,
      total,
    });
  }
}
