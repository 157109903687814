import {Record} from 'immutable';
import EmployeeList, {EmployeeListProps} from './EmployeeList';
import {COLOR_GREEN} from '../constants';

export const SERVICES_STATUS_ACTIVE = 1;

export interface ServicesProps {
  uuid: string;
  title: string;
  description: string;
  price: number;
  created_at: string;
  updated_at: string;
  color_schema: string;
  employee_service: any;
  personality: string;
  period: string;
  period_amount: string;
  employees: EmployeeListProps[];
}

/**
 * @class Company
 * @classdesc
 * @extends {Record}
 * @property {string} id - backup id
 */
export default class Services extends Record({
  uuid: '',
  title: '',
  description: '',
  price: 0,
  color_schema: COLOR_GREEN,
  created_at: '',
  updated_at: '',
  employee_service: {},
  personality: '',
  period: '',
  period_amount: '',
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(props: ServicesProps = {} as ServicesProps) {
    super({
      ...props,
      // @ts-ignore
      employees: new EmployeeList({
        // @ts-ignore
        employees: props.employees,
        total: props.employees ? props.employees.length : 0,
      }),
    });
  }
}
