import {ExecuteOnSet, StatusModel} from '../internal';

export class StatusFormDTO {
  public uuid: string;

  public title: string;

  public category_uuid: string;

  public execute_on_set: ExecuteOnSet | string;

  public is_execute_on_set?: boolean;

  public allowed_statuses_from: string[];

  public allowed_statuses_to: string[];

  constructor(props?: StatusModel, editMode?: boolean) {
    this.uuid = props?.uuid || (editMode ? '' : '');
    this.title = props?.title || '';
    this.category_uuid = props?.category?.uuid || '';
    this.execute_on_set = props?.execute_on_set || '';
    this.allowed_statuses_from = props?.allowed_statuses_from || [];
    this.allowed_statuses_to = props?.allowed_statuses_to || [];
    this.is_execute_on_set = props?.execute_on_set
      ? !!props?.execute_on_set
      : false;
  }
}
