import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {IActionButtonProps} from '../../../components/lib/General';
import {useModal} from '../../../components/lib/libV2/hooks';
import {EmployeeSalaryFormDTO} from '../../../struture';
import {AddButton, SuspenseEmpty} from '../../../components/lib/DataDisplay';

const RewardSalarySideWindowLazy = React.lazy(
  () => import('../Show/RewardSalarySideWindow'),
);

export interface IServicesCreateButtonProps
  extends Omit<IActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess: (value: EmployeeSalaryFormDTO) => Promise<void>;
  onCancel?: () => void;
  salary: EmployeeSalaryFormDTO;
  title?: any;
  salarySettingsDates: string[];
}

export function RewardSalaryCreateButton({
  children,
  onSuccess,
  onCancel,
  loading,
  salary,
  salarySettingsDates,
  ...rest
}: IServicesCreateButtonProps): JSX.Element {
  const {t} = useTranslation();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <AddButton
        tooltipTitle={t('Create a salary')}
        onClick={handleOnInit}
        loading={loading}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <RewardSalarySideWindowLazy
          salarySettingsDates={salarySettingsDates}
          salary={salary}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}
