import {Dispatch} from 'redux';
import {List} from 'immutable';
import {PRODUCT_LIST} from '../constants';
import {IProductsListStatsProps, ProductModel} from '../../struture';
import {ApiError} from '../../services/types';

export interface ISetProductListParam {
  type: PRODUCT_LIST;
  productList: List<ProductModel>;
  keywords?: string;
  total?: number;
  page?: number;
  stats?: IProductsListStatsProps;
}

export interface ISetProductListStatsParam {
  type: PRODUCT_LIST;
  stats: IProductsListStatsProps;
}

export interface IProductListStatsParam {
  type: PRODUCT_LIST;
}

export interface IAddProductToListParam {
  type: PRODUCT_LIST;
  product: ProductModel;
}

export interface IUpdateProductFromListParam {
  type: PRODUCT_LIST;
  product: ProductModel;
}

export interface IDeleteProductFromListParam {
  type: PRODUCT_LIST;
  productUuid: string;
}

export interface IErrorProductListParam {
  type: PRODUCT_LIST;
  error: ApiError | undefined;
}

export function setProductList(
  productList: List<ProductModel>,
  keywords?: string,
  total?: number,
  page?: number,
  stats?: IProductsListStatsProps,
): (dispatch: Dispatch) => Promise<ISetProductListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_LIST.SET_PRODUCT_LIST,
      productList,
      keywords,
      total,
      page,
      stats,
    });
  };
}

export function setProductListStats(
  stats: IProductsListStatsProps,
): (dispatch: Dispatch) => Promise<ISetProductListStatsParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_LIST.SET_PRODUCT_LIST_STATS,
      stats,
    });
  };
}

export function addProductListStats(): (
  dispatch: Dispatch,
) => Promise<IProductListStatsParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_LIST.ADD_PRODUCT_LIST_STATS,
    });
  };
}

export function subProductListStats(): (
  dispatch: Dispatch,
) => Promise<IProductListStatsParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_LIST.SUB_PRODUCT_LIST_STATS,
    });
  };
}

export function loadMoreProductList(
  productList: List<ProductModel>,
): (dispatch: Dispatch) => Promise<ISetProductListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_LIST.LOAD_MORE_PRODUCT_LIST,
      productList,
    });
  };
}

export function addProductToList(
  product: ProductModel,
  stats: IProductsListStatsProps,
): (dispatch: Dispatch) => Promise<IAddProductToListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_LIST.ADD_PRODUCT,
      product,
      stats,
    });
  };
}

export function updateProductFromList(
  product: ProductModel,
): (dispatch: Dispatch) => Promise<IUpdateProductFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_LIST.UPDATE_PRODUCT,
      product,
    });
  };
}

export function deleteProductFromList(
  productUuid: string,
  stats: IProductsListStatsProps,
): (dispatch: Dispatch) => Promise<IDeleteProductFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_LIST.DELETE_PRODUCT,
      productUuid,
      stats,
    });
  };
}

export function errorProductInList(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorProductListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_LIST.ERROR_PRODUCT_LIST,
      error,
    });
  };
}
