import React, {useCallback} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router';
import {withTranslation, WithTranslation} from 'react-i18next';
import {
  ScheduleCalendarProps,
  ScheduleCalendar,
  isFunction,
} from '../../../services/helpers';
import {useStoredCompanies} from '../../../hooks';
import {ChildrenMustBeAFunction} from '../../../components/lib/Errors';
import {IDetailsHeaderProps} from '../../../components/lib/General';
import {Routes} from '../../../services/types';

export default withTranslation()(OrderScheduleDetail);

export interface IOrderScheduleDetailProps
  extends ScheduleCalendarProps,
    WithTranslation {
  schedule?: ScheduleCalendar;
  children: (
    value: Pick<IDetailsHeaderProps, 'routes'> & {navigateGoBack: () => void},
  ) => React.ReactNode;
}

function OrderScheduleDetail({
  schedule,
  t,
  children,
}: IOrderScheduleDetailProps): JSX.Element {
  const {defaultCompanyBreadcrumb} = useStoredCompanies();
  const {scheduleId} = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // @ts-ignore
  const routes = [
    {
      path: `/${Routes.app}/${Routes.dashboard}`,
      breadcrumbName: t('Home'),
    },
    defaultCompanyBreadcrumb,
    {
      path: `/${Routes.app}/${Routes.orders}`,
      breadcrumbName: t('Orders'),
    },
    {
      path: `/${Routes.app}/calendar/entry/${scheduleId || ''}`,
      breadcrumbName: `${
        schedule?.schedule_number
          ? `#${schedule?.schedule_number}`
          : 'Show order'
      }`,
    },
  ];

  const navigateGoBack = useCallback(() => {
    navigate(location?.state?.from || `/${Routes.app}/${Routes.orders}`, {
      state: location?.state?.prevState
        ? location?.state?.prevState
        : location?.state,
    });
  }, [location?.state, navigate]);

  if (!isFunction(children)) {
    throw new ChildrenMustBeAFunction('OrderScheduleDetail');
  }

  return <>{children({routes, navigateGoBack})}</>;
}
