import {
  ModuleInputParameterDTO,
  IModuleInputParameterDTOProps,
} from '../internal';

export class ModuleInputParameterMapper {
  public static toModuleInputParameterDTO(
    inputParameter: IModuleInputParameterDTOProps,
  ): ModuleInputParameterDTO {
    return new ModuleInputParameterDTO(inputParameter);
  }

  public static toModuleInputParameterListDTO(
    inputParameters: IModuleInputParameterDTOProps[],
  ): ModuleInputParameterDTO[] {
    return inputParameters?.map((inputParameter) =>
      ModuleInputParameterMapper.toModuleInputParameterDTO(inputParameter),
    );
  }
}
