import React from 'react';
import styled, {css} from 'styled-components';
import {Tooltip} from 'antd';

export interface IMaskProps {
  showMask: boolean;
  className?: string;
  tooltipTitle?: string;
}

const OverlayMask = styled.div<IMaskProps>`
  position: absolute;
  width: 0;
  height: 100%;
  opacity: 0;
  background-color: ${({theme}) => theme.colors.mask};
  transition: opacity 0.6s cubic-bezier(0.645, 0.045, 0.355, 1),
    width 0.6s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.6s;
  z-index: 1000;

  ${({showMask}) =>
    showMask &&
    css`
      opacity: 1;
      width: 100%;
      transition: opacity 0.6s cubic-bezier(0.645, 0.045, 0.355, 1),
        width 0.6s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.6s;
    `}
`;

export default function Mask({
  showMask,
  className,
  tooltipTitle = '',
  ...rest
}: IMaskProps & any): JSX.Element {
  return (
    <Tooltip title={tooltipTitle}>
      <OverlayMask showMask={showMask} className={className} {...rest} />
    </Tooltip>
  );
}
