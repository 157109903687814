import {Empty} from 'antd';
import React, {useCallback} from 'react';
import {GroupPaymentTitle} from '../Show';
import {useTranslation} from 'react-i18next';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import {
  useKeyboardOpenForm,
  useSearchInputFocus,
  useStateGroupPaymentList,
  useStoredCompanies,
} from '../../../hooks';
import {
  CreateGroupPaymentButton,
  DeleteGroupPaymentButton,
  UpdateGroupPaymentButton,
} from '../Buttons';
import {GroupPaymentMapper, GroupPaymentModel} from '../../../struture';
import {
  TableActionCell,
  Table,
} from '../../../components/lib/libV2/DataDisplay';
import {ListLayout} from '../../../components/lib/Layout';
import {successButtonStyle} from '../../../components/lib/Styled';
import {useAcl} from '../../../contex';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '../../../services/types';
import {HeaderLeft, ListActions} from '../../../components/lib/DataDisplay';
import {textToUpperCase} from '../../../services/helpers';
import {useLocation, useNavigate} from 'react-router';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import styled from 'styled-components';

const StyledCreateGroupPaymentButton = styled(CreateGroupPaymentButton)`
  ${successButtonStyle}
`;

export function GroupPaymentPage(): JSX.Element {
  const {t} = useTranslation();
  const {defaultCompanyUuid, companies: companyList} = useStoredCompanies();
  const {manage} = useAcl(({payment_group}) => payment_group);
  const location = useLocation();
  const navigate = useNavigate();

  const {ref: listRef} = useKeyboardOpenForm({
    className: 'group-payment-create-event',
    disabled: !manage,
  });

  const {
    groupPayments,
    loading,
    total,
    limit,

    refresh,
    handleCreateGroupPayment,
    handleUpdateGroupPayment,
    handleSearchGroupPayments,
    handleDeleteGroupPayments: onDeleteGroupPayments,
  } = useStateGroupPaymentList({
    companyUuid: defaultCompanyUuid,
  });

  const {inputRef, focus, daleyFocus} = useSearchInputFocus({loading});

  const handleDeleteGroupPayments = useCallback(
    async (value: string[]) => {
      await onDeleteGroupPayments(value);
      focus();
    },
    [focus, onDeleteGroupPayments],
  );

  const routes = useBreadcrumb([
    {
      path: `/${Routes.settings}/${Routes.groupPayment}`,
      breadcrumbName: 'Group payments',
    },
  ]);

  const columns = [
    {
      title: t('Title'),
      key: 'title',
      width: '90%',
      render: (groupPayment: GroupPaymentModel) => (
        <GroupPaymentTitle group={groupPayment} />
      ),
    },
    {
      title: t('Actions'),
      key: 'payment_actions',
      align: 'center' as any,
      fixed: 'right' as any,
      render: (groupPayment: GroupPaymentModel) => (
        <TableActionCell>
          <UpdateGroupPaymentButton
            disabled={!manage}
            defaultCompanyUuid={defaultCompanyUuid}
            companyList={companyList}
            type="text"
            groupPayment={GroupPaymentMapper.toGroupPaymentFormDTO(
              groupPayment,
            )}
            data-testid="payment-edit-button"
            onSuccess={handleUpdateGroupPayment}
            onCancel={daleyFocus}
          />
          <DeleteGroupPaymentButton
            disabled={!manage || groupPayment?.is_system}
            data-testid="payment-delete-button"
            groupPayments={[groupPayment]}
            onSuccess={handleDeleteGroupPayments}>
            <DeleteOutlined />
          </DeleteGroupPaymentButton>
        </TableActionCell>
      ),
    },
  ];

  const from = location?.state?.prevState
    ? location?.state?.prevState?.from
    : location?.state?.from;

  const navigateGoBack = useCallback(() => {
    navigate(
      from ? from : `/${Routes.app}/${Routes?.companies}/${defaultCompanyUuid}`,
      {
        state: {prevState: location?.state},
      },
    );
  }, [defaultCompanyUuid, from, location?.state, navigate]);

  return (
    <ListLayout
      ref={listRef}
      aclItem={RoutesAcl[Routes.groupPayment]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.groupPayment]))}
      headerRight={
        <StyledCreateGroupPaymentButton
          key="create-payment-group"
          groupPayment={GroupPaymentMapper.toGroupPaymentFormDTO({
            defaultCompanyUuid,
          } as any)}
          disabled={loading || !manage}
          companyList={companyList}
          defaultCompanyUuid={defaultCompanyUuid}
          loading={loading}
          onSuccess={handleCreateGroupPayment}
          title={t('Create')}
          icon={<PlusOutlined />}
          type="primary"
          onCancel={daleyFocus}
        />
      }
      routes={routes}
      empty={
        groupPayments?.size ? null : (
          <Empty description={t('There are no group payments created')}>
            <StyledCreateGroupPaymentButton
              key="create-payment-group"
              groupPayment={GroupPaymentMapper.toGroupPaymentFormDTO({
                defaultCompanyUuid,
              } as any)}
              disabled={loading || !manage}
              companyList={companyList}
              defaultCompanyUuid={defaultCompanyUuid}
              loading={loading}
              onSuccess={handleCreateGroupPayment}
              title={t('Create')}
              icon={<PlusOutlined />}
              type="primary"
              onCancel={daleyFocus}
            />
          </Empty>
        )
      }
      headerExtra={
        <ListActions
          inputRef={inputRef}
          withoutPicker={true}
          loading={loading}
          handleSearch={(keywords) => handleSearchGroupPayments({keywords})}
          inputTooltip={t('Search group payments')}
          inputLabel={t('Search group payments')}
          withSearchContent={null}
        />
      }
      loading={loading && !groupPayments?.size}>
      <Table<GroupPaymentModel>
        loading={loading}
        dataSource={groupPayments}
        total={total}
        onChange={refresh}
        pageSize={limit}
        columns={columns}
      />
    </ListLayout>
  );
}
