import React from 'react';
import {Space} from 'antd';
import {useTranslation} from 'react-i18next';
import {textToUpperCase} from '../../../services/helpers';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '../../../services/types';
import {ListLayout} from '../../../components/lib/Layout';
import styled, {useTheme} from 'styled-components';
import {LockOutlined} from '@ant-design/icons';
import {Text} from '../../../components/lib/Format';
import {useLocation} from 'react-router';
import {LocalStorageItems} from '../../../services/const';

const StyledLockOutlined = styled(LockOutlined)`
  font-size: 50px;
  color: ${({theme}) => theme.colors.lightDisable};
`;

const StyledText = styled(Text)`
  display: block;
  width: 100%;
  font-size: 50px;
  text-align: center;
  color: ${({theme}) => theme.colors.lightDisable};

  @media (max-width: 475px) {
    font-size: 25px;
  }
`;

const StyledContainer = styled.div`
  width: 100%;
`;

const StyledHeaderTitle = styled(Text)`
  font-size: 20px;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-align: center;
  color: ${({theme}) => theme.colors.lightDisable};
`;

export function AccessDeniedPage(): React.JSX.Element {
  const {t} = useTranslation();
  const location = useLocation();

  const message =
    location?.state?.accessDeniedPageMessage ||
    localStorage.getItem(LocalStorageItems.accessDeniedPageMessage);

  return (
    <StyledContainer>
      <ListLayout
        loading={false}
        aclItem={RoutesAcl[Routes.services]}
        headerTitle={
          <StyledHeaderTitle>
            {textToUpperCase(t(RoutesTitle[RoutesType.accessDenied]))}
          </StyledHeaderTitle>
        }
        headerLeft={<></>}
        routes={[]}
        empty={
          <Space align="center" direction="vertical">
            <StyledLockOutlined />
            <StyledText>{message}</StyledText>
          </Space>
        }>
        <></>
      </ListLayout>
    </StyledContainer>
  );
}
