import React, {useCallback} from 'react';
import {Col, Form, Input, Row} from 'antd';
import {useTranslation} from 'react-i18next';
import {useDefaultForm} from '../../../contex';
import {
  FormSwitch,
  SearchSelect,
  Segmented,
} from '../../../components/lib/DataDisplay';
import {FormTable} from '../../../components/lib/DataDisplay/FormTable';
import {isListToArray} from '../../../services/helpers';
import {
  ExecuteOnSet,
  StatusCategoryModel,
  StatusModel,
} from '../../../struture';
import {List} from 'immutable';
import {STATS_EXECUTE_ON_SET} from '../../../services/api/status';
import {StyledTitle} from '../../../components/lib/Styled';
import {IUseStatusListProps} from '../../../hooks';
import {StatusCategoryTag} from '../Show';

export interface ITariffModelFieldsProps {
  editMode: boolean;
  loading: boolean;
  categoryList: List<StatusCategoryModel> | null;
  categoryListLoading: boolean;
  statusListFrom: StatusModel | null;
  statusListTo: StatusModel | null;
  statusListLoadingFrom: boolean;
  statusListLoadingTo: boolean;
  total?: number;
  limit?: number;
  handleRefreshStatusListFrom: (
    value: Partial<IUseStatusListProps> & {page: number},
  ) => Promise<void>;
  handleRefreshStatusListTo: (
    value: Partial<IUseStatusListProps> & {page: number},
  ) => Promise<void>;
}

export function StatusFields({
  editMode,
  loading,
  categoryList,
  categoryListLoading,

  statusListFrom,
  statusListTo,
  statusListLoadingFrom,
  statusListLoadingTo,
  total,
  limit,

  handleRefreshStatusListFrom,
  handleRefreshStatusListTo,
}: ITariffModelFieldsProps): JSX.Element {
  const {t} = useTranslation();
  const {
    loadingSubmit,
    handlerUpdateFormState,
    setFieldsValue,
    getFieldValue,
    formData,
  }: any = useDefaultForm();

  const columns = [
    {
      title: t('Title'),
      key: 'title',
      render: (status: StatusModel) => (
        <StatusCategoryTag category={status?.category} title={status?.title} />
      ),
    },
    {
      title: t('Description'),
      key: 'description',
      render: (status: StatusModel) => (
        <StyledTitle>{status?.category?.description}</StyledTitle>
      ),
    },
  ];

  const handleChangeIsExecuteOnSet = useCallback(
    (is_execute_on_set: boolean) => {
      if (is_execute_on_set) {
        handlerUpdateFormState({
          execute_on_set: ExecuteOnSet.Payment,
          is_execute_on_set,
        });
        return;
      }
      handlerUpdateFormState({execute_on_set: '', is_execute_on_set});
    },
    [handlerUpdateFormState],
  );

  return (
    <Row gutter={12}>
      <Col span={24}>
        <Form.Item
          label={t('Title')}
          name="title"
          rules={[
            {
              required: true,
              message: t('Title must be specified.'),
            },
          ]}>
          <Input
            data-testid="service-input-title"
            disabled={loading || loadingSubmit}
            placeholder={t('Enter the title')}
            onChange={(e) => handlerUpdateFormState({title: e?.target?.value})}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          tooltip={t('Category')}
          label={t('Category')}
          name="category_uuid"
          rules={[
            {
              required: true,
              message: t('Category must be specified.'),
            },
          ]}>
          <SearchSelect
            name="category_uuid"
            placeholder={t('Select a category')}
            disable={loading || loadingSubmit || categoryListLoading}
            data={isListToArray(categoryList as any)}
            onChange={(category_uuid) =>
              handlerUpdateFormState({category_uuid})
            }
          />
        </Form.Item>
      </Col>

      <FormSwitch
        disabled={loadingSubmit}
        loading={loadingSubmit}
        name="is_execute_on_set"
        label={t('When transitioning, perform an action')}
        tooltipTitle={t('When transitioning, perform an action')}
        setFieldsValue={setFieldsValue}
        getFieldValue={getFieldValue}
        onChange={handleChangeIsExecuteOnSet}
      />

      {formData?.is_execute_on_set ? (
        <Col span={24}>
          <Form.Item
            tooltip={t('Action')}
            label={t('Action')}
            name="execute_on_set">
            <Segmented
              options={STATS_EXECUTE_ON_SET}
              disabled={loading || loadingSubmit}
              onChange={(execute_on_set) =>
                handlerUpdateFormState({execute_on_set})
              }
            />
          </Form.Item>
        </Col>
      ) : null}

      <Col span={24}>
        <Form.Item
          tooltip={t('Statuses to which the current transitions')}
          label={t('Statuses to which the current transitions')}
          name="allowed_statuses_to"
          rules={[
            {
              required: true,
              message: t('Must be specified.'),
            },
          ]}>
          <FormTable
            dataSource={isListToArray(statusListTo as any)}
            loading={statusListLoadingTo}
            columns={columns}
            handleChange={(allowed_statuses_to) =>
              handlerUpdateFormState({allowed_statuses_to})
            }
            total={editMode ? (total === 0 ? 0 : (total || 0) - 1) : total}
            pageSize={limit}
            refresh={handleRefreshStatusListTo}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          tooltip={t(
            'Statuses that are allowed to change to the current status',
          )}
          label={t('Statuses that are allowed to change to the current status')}
          name="allowed_statuses_from"
          rules={[
            {
              required: true,
              message: t('Must be specified.'),
            },
          ]}>
          <FormTable
            dataSource={isListToArray(statusListFrom as any)}
            loading={statusListLoadingFrom}
            columns={columns}
            handleChange={(allowed_statuses_from) =>
              handlerUpdateFormState({allowed_statuses_from})
            }
            total={editMode ? (total === 0 ? 0 : (total || 0) - 1) : total}
            pageSize={limit}
            refresh={handleRefreshStatusListFrom}
          />
        </Form.Item>
      </Col>
    </Row>
  );
}
