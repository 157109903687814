import {apiGet, apiDelete, apiPut, apiPost} from '../core/api';
import {
  IRelatedServiceDTOProps,
  RelatedServiceDTO,
  RelatedServiceMapper,
  IRelatedServiceListDTO,
  RelatedServiceFormDTO,
} from '../../struture';
import {ifElse, head} from '../helpers';
import {contentApiUrl} from '../const';
import {ApiAnswer, DeletePrams, IEntityProps, IListSearchProps} from '../types';
import Validate from '../validate/Validate';

export const RELATED_SERVICES_INITIAL_DATA = new RelatedServiceFormDTO();

export interface IRelatedServiceListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  scheduleUuid: string;
}

export interface IRelatedServiceListAnswer extends ApiAnswer {
  services: IRelatedServiceDTOProps[];
}

export interface IRelatedServiceAnswer {
  service: IRelatedServiceDTOProps;
}

export async function getRelatedServiceList({
  limit = 10,
  offset = 0,
  keywords,
  scheduleUuid,
}: IRelatedServiceListProps): Promise<IRelatedServiceListDTO> {
  const isKeywords = ifElse(!!keywords, `&keywords=${keywords}`, '');

  const {services, count} = await apiGet<null, IRelatedServiceListAnswer>(
    `${contentApiUrl}/schedules/${scheduleUuid}/services?limit=${limit}&offset=${offset}${isKeywords}`,
  );

  return RelatedServiceMapper.toRelatedServiceListDTO(services, count);
}

export async function createRelatedService(
  {
    service_price,
    service_quantity,
    service_uuid,
    comment,
  }: RelatedServiceFormDTO,
  scheduleUuid: string,
): Promise<RelatedServiceDTO> {
  const {service} = await apiPost<
    Omit<RelatedServiceFormDTO, 'uuid'>,
    IRelatedServiceAnswer
  >(`${contentApiUrl}/schedules/${scheduleUuid}/services`, {
    service_price,
    service_quantity,
    service_uuid,
    comment,
  });

  return RelatedServiceMapper.toRelatedServiceDTO(service);
}

export async function updateRelatedService(
  {service_price, service_quantity, comment, uuid}: RelatedServiceFormDTO,
  scheduleUuid: string,
): Promise<RelatedServiceDTO> {
  const {service} = await apiPut<
    Omit<RelatedServiceFormDTO, 'uuid' | 'service_uuid'>,
    IRelatedServiceAnswer
  >(`${contentApiUrl}/schedules/${scheduleUuid}/services/${uuid}`, {
    service_price,
    service_quantity,
    comment,
  });

  return RelatedServiceMapper.toRelatedServiceDTO(service);
}

export async function deleteRelatedServices(
  ids: string[],
  scheduleUuid: string,
) {
  Validate.arrayOfStrings({value: ids});

  return await apiDelete<DeletePrams, any>(
    `${contentApiUrl}/schedules/${scheduleUuid}/services/${head(ids)}`,
    {
      ids,
    },
  );
}
