import React, {useCallback} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {
  useStateUser,
  useRequiredFields,
  useStoredCompanies,
} from '../../../hooks';
import {isRecordToObject, compose, eq} from '../../../services/helpers';
import {
  USER_REQUIRED_FIELD,
  correctUserFormParam,
  ICompanyUserParams,
} from '../../../services/api/companyUser';
import {TabItem} from '../../../components/lib/General';
import UserUnauthorizedView from '../Show/UserUnauthorizedView';
import {ErrorsStatus} from '../../../services/core/request';
import {useNavigate, useParams} from 'react-router';
import {Routes} from '../../../services/types';

const UserFormLazy = React.lazy(() => import('../Forms/UserForm'));

export default withTranslation()(UserPage);

export interface IUserPageProps extends WithTranslation {}

/**
 * @desc Компонент для відображення поточної компанії
 * */
function UserPage({t}: IUserPageProps): JSX.Element {
  const {userId, tabsId} = useParams();
  const navigate = useNavigate();

  const {defaultCompanyUuid, defaultCompanyBreadcrumb} = useStoredCompanies();

  const {
    user,
    loading: userLoading,
    handleUpdateUser,
    error: userError,
    handleDisabledAccount,
  } = useStateUser({
    userUuid: userId!,
  });

  const routes = [
    {
      path: `/${Routes.app}/dashboard`,
      breadcrumbName: t('Home'),
    },
    defaultCompanyBreadcrumb,
    {
      path: `${Routes.app}/users/${user?.uuid || ''}`,
      breadcrumbName: user?.fullName || t('Show User'),
    },
  ];

  const handleProfileChangeTab = useCallback(
    ({key: tabKey}: TabItem = {} as TabItem): void => {
      navigate(`/${Routes.app}/users/show/${userId}/${tabKey}`);
    },
    [navigate, userId],
  );

  const overviewData = useRequiredFields(user, USER_REQUIRED_FIELD);

  const correctUser =
    user &&
    compose<ICompanyUserParams>(
      (value) => correctUserFormParam(value, defaultCompanyUuid),
      isRecordToObject,
    )(user);

  const isError = userError && eq(userError?.status, ErrorsStatus.Forbidden);

  return (
    <>
      {isError ? (
        <UserUnauthorizedView title={t(userError?.message || 'Error')} />
      ) : (
        // <ProfileTabPanel>
        //   {({tabs}) => (
        //     <OverviewLayout
        //       showImageUploader={false}
        //       editTooltip={t('Edit User')}
        //       loading={userLoading}
        //       data={overviewData}
        //       routes={routes}
        //       headerTitle={user?.fullName}
        //       tabs={tabs}
        //       onTabChange={handleProfileChangeTab}
        //       tabsDefaultActiveKey={tabsId}
        //       underTheHeadingChildren={(children) => (
        //         <UserActionsButtons
        //           user={user}
        //           key="more"
        //           handleDisabledAccount={handleDisabledAccount}>
        //           {children}
        //         </UserActionsButtons>
        //       )}
        //       editForm={({onCancel}) => (
        //         <Suspense fallback={<SuspenseEmpty />}>
        //           <UserFormLazy
        //             overviewMode
        //             user={correctUser}
        //             onSuccess={async (value) => {
        //               await handleUpdateUser(value);
        //               onCancel();
        //             }}
        //             editMode
        //             onCancel={onCancel}
        //           />
        //         </Suspense>
        //       )}
        //     />
        //   )}
        // </ProfileTabPanel>
        <></>
      )}
    </>
  );
}
