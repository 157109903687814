import {Input, ImageProps} from 'antd';
import styled, {css} from 'styled-components';

export const InputDisabledView = styled(Input)<
  ImageProps & {fontSize?: number}
>`
  & .ant-input-disabled {
    color: ${({theme}) => theme.colors.black} !important;
    font-size: 17px;

    ${({fontSize}) =>
      fontSize &&
      css`
        font-size: ${fontSize}px;
      `}
  }
`;
