import moment, {Moment} from 'moment';
import {
  add,
  compose,
  curry,
  DateType,
  eq,
  len,
  ifElse,
  map,
  sub,
  DateValue,
  reduce,
  head,
  last,
  isFunction,
  divide,
  append,
  sortBy,
  flatMap,
  split,
  getPercentageOfTheNumber,
  firstLetterToUppercase,
  memoizeUnaryArity,
  multiply,
  prop,
  find,
  toLowerCase,
  checkWorkSchedule,
  toMomentString,
  filter,
  lessThan,
  concat,
  reverseV2,
  isThereContent,
} from './index';
import {ILiteralObj} from '../types';
import {MODE} from '../../contex';
import {
  CALENDAR,
  CALENDAR_ROW_HEIGHT,
  CALENDAR_ROW_HEIGHT_MINUTE,
  DEFAULT_CALENDAR_TIME_RANGE,
  WEEKDAY_TIME_RANGE,
} from '../../components/lib/const';
import {Record} from 'immutable';
import {isObject} from '@sportix/sportix-common-modules';

const WEEK_DAYS_NAME = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export type ScheduleDatePropFunc = <T extends ILiteralObj>(
  value: T,
) => string | Date;
export type ScheduleDateProp = string | ScheduleDatePropFunc;

export interface ScheduleAmount {
  schedule_amount: number;
}

export interface SchedulePriority {
  schedule_priority: number;
}

export interface MutationScheduleEvent
  extends ILiteralObj,
    ScheduleAmount,
    SchedulePriority {}

export interface IMonthYear {
  month: number;
  year: number;
}

export type EventType = {[key: string]: MutationScheduleEvent[]};

export interface IDayTimeRange {
  weekday: string;
  start: string;
  end: string;
  isHaveRange?: boolean;
}

export interface IMonthFormat {
  date: Date;
  isCurrentMonth: boolean;
  isToday: boolean;
  weekday?: string;
  weekdayInfo?: IDayTimeRange;
  events: EventType;
}

export enum DATE_SPECIFIC_FORMAT {
  URL = 'YYYY/MM/DD',
  MONTH_DATES = 'MM-DD-YYYY',
  STORE_DATE = 'YYYY-MM',
}

export interface ITimeInDay {
  time: string;
}

export interface ITimeRange {
  start: number;
  end: number;
}

export interface IDayRange {
  weekday?: string;
  start: string;
  end: string;
  isHaveRange?: boolean;
}

export enum WEEK_DAYS {
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday',
}

export const TIME_TO_HEIGHT_RATIO = 60;

// Calendar Math

/**
 * @desc Розрахунок висоти для івенту в px
 * */
export const calculateHeightEvent = (periodAmount: number): string =>
  compose<string>(
    append('px'),
    String,
    multiply(CALENDAR_ROW_HEIGHT),
    divide(periodAmount),
  )(TIME_TO_HEIGHT_RATIO);

const getDatePerMinute = (date: string | Date): string =>
  toUtcDateInFormat(date, 'mm');

/**
 * @desc Розрахунок відступу для івенту в px
 * */
export const calculateTopIndentEvent = (
  date: Date | string | Moment = moment().toDate(),
): string =>
  compose<string>(calculateHeightEvent, Number, getDatePerMinute)(date);

/**
 * @desc Розрахунок кількості span для Col - antd
 * */
export const calculateColSpan = <T extends any[]>(
  span: number,
  events: T,
): number => Math.floor(divide<[any, any], number>(span, len(events)));

/**
 * @desc Розрахунок кількості прихованих
 * івентів
 * */

export const getRestEventLength = <T>(arr: T, visibleItem: number): number =>
  sub(sub<[any, any], any>(len(arr as any), 1), visibleItem);

/**
 * @desc Розрахунок розміру для модального вікна
 * відображення івентів
 * */

export const calcMonthEventModalWidth = (
  parentWidth: number,
  numberGreater: 1.2,
): number => multiply(parentWidth, numberGreater);

/**
 * @desc Розрахунок зміщення для модального вікна
 * відображення івентів
 * */

export const calcMonthEventModalOffset = (
  elementHeight: number,
  percent: string | number,
): number => getPercentageOfTheNumber(elementHeight, percent);

// Calendar Date

export const getWeekdayFullName = (numberOfDay: number): string => {
  const localeData = moment.localeData('en');

  return localeData.weekdays(moment('2021-04-26').add(numberOfDay, 'day'));
};

/**
 * @desc Отримати Moment дату по вхідним параметрам
 * */

// buildDate

export const toMomentUtcDate = (
  day: DateType,
  month: DateType,
  year: number,
  format: DATE_SPECIFIC_FORMAT | string = DATE_SPECIFIC_FORMAT.MONTH_DATES,
): Moment => moment.utc(`${month}-${day}-${year}`, format);

// buildDateFormat
/**
 * @desc Отримати дату у виброном форматі
 * */
export const toUtcDateInFormat = (
  date: string | Date | Moment,
  format: DATE_SPECIFIC_FORMAT | string = DATE_SPECIFIC_FORMAT.URL,
): string => moment.utc(date).format(format);

/**
 * @desc Отримати коротку назву дня неділі
 * */
export const getShortWeekdayName = (numberOfDay: number): string =>
  moment.weekdaysShort(numberOfDay);

/**
 * @desc Побудувати об'єкт для івентів, для подальщої роботи
 * */
export const buildMonthDates = (
  day: number,
  month: number,
  year: number,
  index: number,
  isCurrentMonth = false,
): IMonthFormat => ({
  date: toMomentUtcDate(day, month, year).toDate(),
  isCurrentMonth,
  isToday: eq(
    toMomentUtcDate(day, month, year).format(DATE_SPECIFIC_FORMAT.MONTH_DATES),
    toUtcDateInFormat(new Date(), DATE_SPECIFIC_FORMAT.MONTH_DATES),
  ),
  events: {},
});

/**
 * @desc Отримати кількість днів вибраного місяця
 * */
export const getDaysInMonth = ({month, year}: IMonthYear): number =>
  moment(`${month}-${year}`, 'MM').daysInMonth();

/**
 * @desc Отримати години в одному дні
 * */
export const buildHourInDay = (
  range: ITimeRange = DEFAULT_CALENDAR_TIME_RANGE,
): ITimeInDay[] =>
  Array.from(Array(24 - (range.start - range.end + 23) || 24), (_, index) => ({
    time: `${`0${23 - (23 - range.end) - index}`.slice(-2)}:00`,
  })).reverse();

/**
 * @desc Отримати номер дня неділі початку вибраного місяця
 * */
export const getFirstWeekdayOfMonth = ({month, year}: IMonthYear): number =>
  moment(`${month}-${year}`, 'MM-YYYY').startOf('month').weekday();

/**
 * @desc Отримати попередній місяць
 * */
export const getPrevMonthYear = ({month, year}: IMonthYear): IMonthYear =>
  ifElse(
    eq(month, 1),
    {
      month: 12,
      year: sub(year, 1),
    },
    {
      month: sub(month, 1),
      year,
    },
  );

/**
 * @desc Отримати наступний місяць
 * */
export const getNextMonthYear = ({month, year}: IMonthYear): IMonthYear =>
  ifElse(
    month < 12,
    {
      month: add(month, 1),
      year,
    },
    {
      month: 1, // 12
      year: add(year, 1),
    },
  );

/**
 * @desc Функція для побудови об'єкту івентів для попереднього місяця
 * */
export const toPrevMonthDays = curry<any, any>(
  (
    prevDaysInMonth: number,
    {month, year}: IMonthYear,
    index: number,
  ): IMonthFormat =>
    buildMonthDates(sub(prevDaysInMonth, index), month, year, index),
);

export const getFillingArray = (count: number = 0, fill: any): any[] =>
  Array(count || 0).fill(fill);

/**
 * @desc Отримати дати попереднього місяця
 * */
export const getPrevMonthDays = memoizeUnaryArity(
  (value: IMonthYear): IMonthFormat[] => {
    const prev: any = getPrevMonthYear(value);
    const prevDayInMonth = getFirstWeekdayOfMonth(value);

    if (prevDayInMonth > 0) {
      const arrayFromPrevDays = getFillingArray(prevDayInMonth, prev);
      const buildPrevMonthDays = compose<any>(
        toPrevMonthDays,
        getDaysInMonth,
      )(prev);

      return compose<IMonthFormat[]>(
        reverseV2,
        map(buildPrevMonthDays),
      )(arrayFromPrevDays);
    }
    return [];
  },
);

/**
 * @desc Функція для побудови об'єкту івентів для поточного місяця
 * */
export const toCurrentMonthDays = (
  {month, year}: IMonthYear,
  index: number,
): IMonthFormat => buildMonthDates(add(index!, 1), month, year, index, true);

/**
 * @desc Отримати дати Поточного місяця
 * */
export const getCurrentMonthDays = memoizeUnaryArity(
  (value: IMonthYear): IMonthFormat[] =>
    map(toCurrentMonthDays as any)(
      getFillingArray(getDaysInMonth(value), value),
    ),
);

/**
 * @desc Функція для побудови об'єкту івентів для наступного місяця
 * */
export const toNextMonthDays = (
  {month, year}: IMonthYear,
  index: number,
): IMonthFormat => buildMonthDates(add(index, 1), month, year, index);

/**
 * @desc Отримати дати Наступного місяця
 * */
export const getNextMonthDays = memoizeUnaryArity(
  curry<any, any>(
    (value: IMonthYear, months: IMonthFormat[]): IMonthFormat[] => {
      if (lessThan(len(months), 42)) {
        const daysToAdd = sub<any, any>(42, len(months));
        const nextMonth = getNextMonthYear(value);

        const nexDates = map(toNextMonthDays as any)(
          getFillingArray(daysToAdd as any, nextMonth),
        );

        return [...months, ...nexDates];
      }

      return months;
    },
  ),
);

const isEqWeekdayRages = curry<any, any>(
  (weekdayName: string, timeRange: IDayRange): boolean =>
    toLowerCase(timeRange.weekday!) === toLowerCase(weekdayName),
);

const findDayRange = <T>(weekdayName: string, arr: T[]): T | undefined =>
  find(isEqWeekdayRages(weekdayName) as any)(arr) as any;

const checkIsArrayDayRange = (
  timeRange: IDayRange[] | undefined,
): IDayRange | [] => ifElse(Array.isArray(timeRange), timeRange, []);

const getDayRange = curry<any, any>(
  <T>(weekName: string, value: T[]): T | undefined =>
    findDayRange(weekName, value),
);

export const buildWeekdayInfo = (
  timeRange: IDayRange[] | undefined,
  defaultTimeRange: IDayRange | undefined,
  weekName: string,
): IDayTimeRange =>
  compose<IDayTimeRange>(
    (value: any) =>
      ifElse(!!value, value, defaultTimeRange || WEEKDAY_TIME_RANGE),
    getDayRange(weekName),
    checkIsArrayDayRange,
  )(timeRange);

/**
 * @desc Отримати назву дня неділі для кожної дати
 * */
const addShorWeekdayName = (
  value: IMonthFormat,
  index: number,
): IMonthFormat => ({
  ...value,
  weekday: getShortWeekdayName(index + 1),
});

export const addWeekdayRange = curry<any, any>(
  (
    timeRange: IDayRange[] | undefined,
    defaultTimeRange: IDayRange | undefined,
    value: IMonthFormat,
    index: number,
  ): IMonthFormat => ({
    ...value,
    weekdayInfo: {
      ...buildWeekdayInfo(
        timeRange,
        defaultTimeRange,
        getWeekdayFullName(index),
      ),
    },
  }),
);

/**
 * @desc Отримати дату для відображення
 * */
export const getMonthDayYear = (date: DateValue): string =>
  moment(date).utc().format(DATE_SPECIFIC_FORMAT.MONTH_DATES);

/**
 * @desc DEEP оновлення об'єктів
 * */
export const deepUpdateObject = <T extends ILiteralObj>(
  acc: T,
  [key, value]: any,
): T => {
  acc = acc[key]
    ? {...acc, [key]: [...acc[key], ...value]}
    : {...acc, [key]: value};

  return acc;
};

export const deepUpdateObjectV2 = <T extends ILiteralObj>(
  acc: any,
  [key, value]: any,
): T => {
  if (isObject(value) && acc[key]) {
    acc[key] = deepUpdateObjectV2(acc[key], head(Object.entries(value)));
  }

  acc = acc[key]
    ? {...acc, [key]: {...acc[key], ...value}}
    : {...acc, [key]: value};

  return acc;
};

/**
 * @desc DEEP оновлення івентів
 * */
export const deepUpdateEvents = <T>(
  prevEvents: ILiteralObj,
  currentEvents: ILiteralObj,
): T => reduce(deepUpdateObject, prevEvents)(Object.entries(currentEvents));

/**
 * @desc додавання/оновлення івентів в об'єкт Івентів
 * */
export const addMonthEvents = curry<[ILiteralObj, IMonthFormat], IMonthFormat>(
  (events: ILiteralObj, value: IMonthFormat): IMonthFormat => {
    const findEvents = events[getMonthDayYear(value?.date)] || {};

    if (isThereContent(findEvents)) {
      return {
        ...value,
        events: deepUpdateEvents(value?.events, findEvents),
      };
    }

    return {
      ...value,
    };
  },
);

/**
 * @desc Створення об'єкту Івентів
 * */

export const getDatesInMonthDisplay = (
  value: IMonthYear,
  timeRange: IDayRange[] | undefined = undefined,
  defaultTimeRange: IDayRange | undefined = undefined,
): IMonthFormat[] =>
  compose<IMonthFormat[]>(
    map(addWeekdayRange(timeRange, defaultTimeRange) as any),
    map(addShorWeekdayName as any),
    getNextMonthDays(value) as any,
    concat(getPrevMonthDays(value)),
    getCurrentMonthDays,
  )(value);

/**
 * @desc Отримати різницю в часі
 * */

export const getDiffOfTimes = (
  startTime: string | Date,
  endTime: string | Date,
  unitOfTime: any = 'minutes',
): number => moment(endTime).diff(moment(startTime), unitOfTime);

/**
 * @desc Доавання до об'єкту Schedule тривалості
 * */

export const addToEventsPeriodAmount = curry<any, any>(
  <T>(startProp: string, endProp: any, event: T): any => {
    const schedule_amount = isFunction(endProp)
      ? getDiffOfTimes(startProp, endProp(event))
      : getDiffOfTimes(startProp, event[endProp]);

    return Record.isRecord(event)
      ? (event as any).set('schedule_amount', schedule_amount)
      : {
          ...event,
          schedule_amount,
        };
  },
);

/**
 * @desc Додавання до об'єкту Schedule пріорітету відображення
 * */

export const addToEventsPriority = curry<any, any>(
  <T>(
    priority: number | string,
    addNumber: number,
    event: T | Record<any>,
  ): any => {
    const schedule_priority = add(Number(`${Number(priority)}00`), addNumber);

    return Record.isRecord(event)
      ? (event as any).set('schedule_priority', schedule_priority)
      : {...event, schedule_priority};
  },
);

export const buildEventByTime = ({
  startProp,
  endProp,
  eventByTime,
  addedEvent,
}: {
  startProp: string;
  endProp: ScheduleDateProp;
  eventByTime: any;
  addedEvent: any;
}) => {
  const eventHour: string = toUtcDateInFormat(addedEvent[startProp], 'HH');
  const eventTime: string = `${eventHour}:00`;

  const mutationEvent: MutationScheduleEvent = addToEventsPeriodAmount(
    addedEvent[startProp],
    endProp,
  )(addedEvent);

  if (!eventByTime) {
    return {[eventTime]: [addToEventsPriority(eventHour, 0)(mutationEvent)]};
  }

  return eventByTime[eventTime]
    ? {
        ...eventByTime,
        ...{
          [eventTime]: [
            ...eventByTime[eventTime],
            addToEventsPriority(
              eventHour,
              len(eventByTime[eventTime]),
            )(mutationEvent),
          ],
        },
      }
    : {
        ...eventByTime,
        ...{[eventTime]: [addToEventsPriority(eventHour, 0)(mutationEvent)]},
      };
};

/**
 * @desc Мутація об'єкту Schedule та повернення нової структури
 * */

export const buildEventByDate = curry<any, any>(
  <T extends ILiteralObj>(
    {startProp, endProp}: {startProp: string; endProp: ScheduleDateProp},
    acc: any,
    curr: T,
  ): MutationScheduleEvent => {
    const eventName: string = getMonthDayYear(curr[startProp]);

    acc[eventName] = buildEventByTime({
      eventByTime: acc[eventName],
      addedEvent: curr,
      startProp,
      endProp,
    });

    return acc;
  },
);

/**
 * @desc Сортування та Мутація об'єкту Schedule
 * */

export const sortedByMomentDate =
  (property: string) =>
  <T>(a: T, b: T): number => {
    const dateA: number = moment(prop(property, a)).valueOf();
    const dateB: number = moment(prop(property, b)).valueOf();

    return dateA - dateB;
  };

export const buildCalendarEventToMonthFormat = <T>(
  startProp: string,
  endProp: ScheduleDateProp,
  arr: T[] = [],
): IMonthFormat => {
  return compose<IMonthFormat>(
    reduce(buildEventByDate({startProp, endProp}), {}),
    sortBy(sortedByMomentDate(startProp)),
  )(arr);
};

/**
 * @desc Отримати день місяця
 * */

export const getDayOfMonth = (date: DateValue): number => moment(date).date();

/**
 * @desc Отримати години з дати
 * */

export const getHourOfDate = (date: DateValue): number => moment(date).hour();

/**
 * @desc Отримати місяць
 * */

export const getMonth = (date: DateValue): number => moment(date).month();

/**
 * @desc Отримати рік
 * */

export const getYear = (date: DateValue): number => moment(date).year();

/**
 * @desc Отрмати сьогоднішню дату
 * */

export const getToday = (): Date => moment().toDate();

export const getSpecificDate = (
  month: number,
  dayOfMonth: number,
  year: number,
): Date =>
  moment(
    `${month}-${dayOfMonth}-${year}`,
    DATE_SPECIFIC_FORMAT.MONTH_DATES,
  ).toDate();

export interface BuildDateChange {
  current: Date;
  prev: Date;
  next: Date;
}

/**
 * @desc Отримати попередню/наступню/поточну дату
 * */

export const buildMonthChange = curry<any, any>(
  (selectDate: Date, mode: MODE): BuildDateChange => ({
    current: selectDate,
    prev: moment(selectDate).subtract(1, mode).toDate(),
    next: moment(selectDate).add(1, mode).toDate(),
  }),
);

export interface IGetTimesOfDate {
  start: Moment;
  end: Moment;
  currentDate: Moment;
}

/**
 * @desc Отримати першу/останню дату поточного місяця в календарі та вибрану дату
 * */
export const getTimesOfDates = (
  dateInMonth: IMonthFormat[],
  date: string | Date,
): IGetTimesOfDate => {
  const start = moment(
    toUtcDateInFormat(head(dateInMonth)?.date, 'YYYY-MM-DD'),
  );
  const end = moment(toUtcDateInFormat(last(dateInMonth)?.date, 'YYYY-MM-DD'));
  const currentDate = moment(toUtcDateInFormat(date, 'YYYY-MM-DD'));

  return {start, end, currentDate};
};

/**
 * @desc Відображення періоду для івенту 10:00 - 11:30
 * */

export const buildDisplayLengthOfTime = (
  starDate: string | Date,
  periodAmount: number,
  format: string = 'HH:mm',
): string => {
  const endTime = moment
    .utc(starDate)
    .add(periodAmount, 'minute')
    .format(format);
  const startTime = toUtcDateInFormat(starDate, format);

  return `${startTime} - ${endTime}`;
};

function getWeekNums(momentObj: any) {
  const clonedMoment = moment(momentObj);

  let last;

  const first = clonedMoment.startOf('month').week();
  last = clonedMoment.endOf('month').week();

  if (first > last) {
    last = first + last;
  }
  return last - first + 1;
}

/**
 * @desc Отримати номер неділі в місяці
 * */
export const getWeekOfMonth = curry<any, any>(
  (correctWeekDate: DateValue, currentDate: string | Date): number => {
    const currentWeekNumber = sub(
      moment(currentDate).locale('uk').isoWeek() > 48
        ? moment(currentDate).locale('uk').isoWeek() - 48
        : moment(currentDate).locale('uk').isoWeek(),
      moment(currentDate).locale('uk').startOf('month').isoWeek() >= 48
        ? 0
        : moment(currentDate).locale('uk').startOf('month').isoWeek(),
    );

    if (moment(correctWeekDate).isBefore(currentDate, 'month')) {
      return add(currentWeekNumber, 4);
    }

    return currentWeekNumber;
  },
);

/**
 * @desc Отримати номер дня для розділення на неділі
 * */
export const getWeeksOfMonthDate = (
  currentDate: string | Date,
  monthDates: IMonthFormat[],
): number => {
  const dateIndex = monthDates.findIndex(({date}) => {
    return eq(
      toMomentString(date, 'YYYY-MM-DD'),
      toMomentString(currentDate, 'YYYY-MM-DD'),
    );
  });

  if (dateIndex >= 35) {
    return 35;
  }

  if (dateIndex >= 28 && dateIndex < 35) {
    return 28;
  }

  if (dateIndex >= 21 && dateIndex < 28) {
    return 21;
  }

  if (dateIndex >= 14 && dateIndex < 21) {
    return 14;
  }

  if (dateIndex > 7 && dateIndex < 14) {
    return 7;
  }

  return 0;
};

/**
 * @desc Перетворення об'єкту Event якій розділений на {hour: [events...]}, в масив Events
 * */
export const flattenTimeEvents = <T>(
  events: EventType,
): MutationScheduleEvent[] =>
  // @ts-ignore
  flatMap(([_, value]) => [...value])(Object.entries(events));

/**
 * @desc Отримання першого слова з рядка
 * */
export const getFirstSplittingString = (
  value: string,
  separator: string = '-',
): string => compose<string>(head, split(separator))(value);

export const getCalendarHeaderDate = (date: string | Date | Moment): string =>
  compose<string>(firstLetterToUppercase, () =>
    toUtcDateInFormat(date, 'MMMM YYYY'),
  )();

export const getUrlPathName = (url: string, separator: string = CALENDAR) =>
  compose(head, split(separator))(url);

export const START_OF_MONTH = moment().startOf('month').format('YYYY-MM-DD');

export const END_OF_MONTH = moment().endOf('month').format('YYYY-MM-DD');

export const START_OF_WEEK = moment()
  .startOf('week')
  .add(1, 'day')
  .format('YYYY-MM-DD');

export const END_OF_WEEK = moment()
  .endOf('week')
  .add(1, 'day')
  .format('YYYY-MM-DD');

export const buildTimeRange = (times: string[]): ITimeRange => ({
  start: head(times as any),
  end: last(times as any),
});

export const getTimeRange = compose<any>(buildTimeRange, split('-'));

export const buildDayRange = (date: string[]): IDayRange => ({
  weekday: head(date),
  isHaveRange: true,
  ...getTimeRange(last(date)),
});

export const getHourFromSchedule = compose(
  map(compose(buildDayRange, split('|'))),
  split(','),
);

export const buildMinMaxRange = (values: number[]): ITimeRange => ({
  start: Math.min(...values),
  end: Math.max(...values),
});

export const findMinMaxRange = compose(
  buildMinMaxRange,
  flatMap((value: any) => compose<number[]>(Number, head)(value)),
  map(split(':') as any),
  flatMap((value: any) => value),
  map(compose(split('-'), last, split('|'))),
  split(','),
);

export const getHourFromTime = compose<number>(Number, head, split(':'));

export const getMinMaxFromRanges = curry<any, any>(
  (
    isFixedRange: boolean,
    defaultRange: ITimeRange,
    updatedRange: ITimeRange,
  ): ITimeRange => {
    if (!updatedRange) {
      return defaultRange;
    }

    if (isFixedRange) {
      return updatedRange;
    }

    return {
      start: ifElse(
        updatedRange.start < defaultRange.start,
        updatedRange.start,
        defaultRange.start,
      ),
      end: ifElse(
        updatedRange.end > defaultRange.end,
        updatedRange.end,
        defaultRange.end,
      ),
    };
  },
);

export const getMonthDates = (
  calendarDate: string | Date,
  customDayTimeRange: string | undefined | null,
): IMonthFormat[] =>
  getDatesInMonthDisplay(
    {
      month: getMonth(moment(calendarDate)) + 1,
      year: getYear(moment(calendarDate)),
    },
    ifElse(
      !!customDayTimeRange,
      getHourFromSchedule(customDayTimeRange),
      customDayTimeRange,
    ),
    ifElse(
      checkWorkSchedule(customDayTimeRange || ''),
      compose(head, getHourFromSchedule)(`AllDays|${customDayTimeRange}`),
      undefined,
    ),
  );

export function snapToGrid(y: number): [number] {
  const snappedY =
    Math.round(y / (CALENDAR_ROW_HEIGHT / 60)) *
    Math.round(CALENDAR_ROW_HEIGHT / 60);
  return [snappedY];
}

export const snapToGridTime = (
  initialOffset: any,
  currentOffset: any,
  minutes: number,
) => {
  if (!initialOffset || !currentOffset) {
    return;
  }
  let {y} = currentOffset;

  y -= initialOffset.y;
  [y] = snapToGrid(y);
  y += initialOffset.y;

  const minute =
    Math.floor(divide(sub(y, initialOffset.y), CALENDAR_ROW_HEIGHT_MINUTE)) +
    minutes;

  const timeHour = minute / 60;
  const timeMinute = minute % 60;
  const time = moment.utc().hours(timeHour).minutes(timeMinute).toDate();

  return {
    time,
  };
};

export const destructuringTime = (time: string): [number, number] =>
  compose(
    map((item) => Number(item)),
    split(':'),
  )(time || '') as any;

export const setHourAndMinute = (
  hour: number,
  minute: number,
  date?: Date | string,
): Moment =>
  moment(date ? new Date(date) : undefined)
    .utc(true)
    .set('hour', hour)
    .set('minute', minute);

export const fromTimeToMinute = (time: string): number => {
  const [hour, minute] = destructuringTime(time);

  return compose<number>(add(minute), multiply(60))(hour);
};

export const isDateInThePastFromNow = (date: DateValue): boolean =>
  moment().isAfter(date, 'day');

const isCurrentMonth = ({isCurrentMonth}: IMonthFormat) => isCurrentMonth;

const getDateFromMonthFormat = (
  {date}: IMonthFormat = {} as IMonthFormat,
): Date => date;

export const getFromMonthFormatLastDate = (dateInMonth: IMonthFormat[]): Date =>
  compose<Date>(
    getDateFromMonthFormat,
    last,
    filter(isCurrentMonth as any),
  )(dateInMonth);
