import * as React from 'react';
import {getClientById} from '../services/api/client';
import {ClientModel, ClientMapper, ClientDTO} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {IEntityProps} from '../services/types';

export interface IUseClientProps extends IEntityProps {
  clientUuid: string;
}

export interface IUseClientReturnType {
  error: null | any;
  loading: boolean;
  entity: ClientModel | null;
  refresh: (value: Partial<IUseClientProps>) => Promise<ClientModel | void>;
  clientUuid: string;
}

export function useClient(
  {loadOnInit = true, clientUuid}: IUseClientProps = {} as IUseClientProps,
): IUseClientReturnType {
  const {cancellablePromise, didCancel} = useCancellablePromise();
  const {
    entity,
    loading,
    error,
    handleUpdate,
    clientUuid: entityUuid,
  } = useStateReducer<Omit<IUseClientReturnType, 'refresh'>>({
    entity: null,
    clientUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading,
      clientUuid = entityUuid,
    }: Partial<IUseClientProps> = {}): Promise<ClientModel | void> => {
      try {
        handleUpdate({loading: showLoading, error: null, clientUuid});

        const client = await cancellablePromise<ClientDTO>(
          getClientById(clientUuid),
        );

        const clientModel = ClientMapper.toClientModel(client);

        if (!didCancel.current) {
          handleUpdate({
            entity: clientModel,
            loading: false,
          });
          return clientModel;
        }
      } catch (err: any) {
        if (!didCancel.current) {
          handleUpdate({
            error: err,
            loading: false,
          });
        }
      }
    },

    [cancellablePromise, didCancel, entityUuid, handleUpdate],
  );

  React.useEffect(() => {
    if (loadOnInit && clientUuid) {
      (async () => {
        await refresh({clientUuid});
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, clientUuid]);

  return {
    error,
    loading,
    refresh,
    entity,
    clientUuid,
  };
}
