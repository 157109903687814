import {Dispatch} from 'redux';
import {List} from 'immutable';
import {ABONEMENT_LIST} from '../constants';
import {AbonementModel} from '../../struture';
import {ApiError} from '../../services/types';

export interface ISetAbonementListParam {
  type: ABONEMENT_LIST;
  abonementList: List<AbonementModel>;
  keywords?: string;
  total?: number;
  page?: number;
}

export interface IAddAbonementToListParam {
  type: ABONEMENT_LIST;
  abonement: AbonementModel;
}

export interface IUpdateAbonementFromListParam {
  type: ABONEMENT_LIST;
  abonement: AbonementModel;
}

export interface IDeleteAbonementFromListParam {
  type: ABONEMENT_LIST;
  abonementUuid: string;
}

export interface IErrorAbonementListParam {
  type: ABONEMENT_LIST;
  error: ApiError | undefined;
}

export function setAbonementList(
  abonementList: List<AbonementModel>,
  keywords?: string,
  total?: number,
  page?: number,
): (dispatch: Dispatch) => Promise<ISetAbonementListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: ABONEMENT_LIST.SET_ABONEMENT_LIST,
      abonementList,
      keywords,
      total,
      page,
    });
  };
}

export function loadMoreAbonementList(
  abonementList: List<AbonementModel>,
): (dispatch: Dispatch) => Promise<ISetAbonementListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: ABONEMENT_LIST.LOAD_MORE_ABONEMENT_LIST,
      abonementList,
    });
  };
}

export function addAbonementToList(
  abonement: AbonementModel,
): (dispatch: Dispatch) => Promise<IAddAbonementToListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: ABONEMENT_LIST.ADD_ABONEMENT,
      abonement,
    });
  };
}

export function updateAbonementFromList(
  abonement: AbonementModel,
): (dispatch: Dispatch) => Promise<IUpdateAbonementFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: ABONEMENT_LIST.UPDATE_ABONEMENT,
      abonement,
    });
  };
}

export function deleteAbonementFromList(
  abonementUuid: string,
): (dispatch: Dispatch) => Promise<IDeleteAbonementFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: ABONEMENT_LIST.DELETE_ABONEMENT,
      abonementUuid,
    });
  };
}

export function errorAbonementInList(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorAbonementListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: ABONEMENT_LIST.ERROR_ABONEMENT_LIST,
      error,
    });
  };
}
