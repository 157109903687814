import React from 'react';
import {StatusModel} from '../../../struture';
import styled from 'styled-components';
import {Routes} from '../../../services/types';
import {Link} from '../../../components/lib/DataDisplay';
import {useLocation} from 'react-router';
import {useTranslation} from 'react-i18next';
import {StatusCategoryTag} from './StatusCategoryTag';

export interface IStatusDetailsViewProps {
  status: StatusModel | null;
}
const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding-right: 15px;
  padding-bottom: 15px;
`;

const StyledSpace = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 5px;
`;
export function StatusDetailsView({
  status,
}: IStatusDetailsViewProps): JSX.Element {
  const {t} = useTranslation();
  const location = useLocation();

  return (
    <>
      {status ? (
        <StyledContainer>
          <StyledSpace>
            <Link
              tooltip={t('Go to status details page')}
              to={`/${Routes.app}/${Routes.customStatuses}/${status?.uuid}`}
              state={{goBack: location.pathname}}>
              {status?.title}
            </Link>
            <StatusCategoryTag
              category={status?.categoryModel}
              title={status?.categoryModel?.title}
            />
          </StyledSpace>
        </StyledContainer>
      ) : null}
    </>
  );
}
