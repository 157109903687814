import * as React from 'react';
import {
  useInvoice,
  IUseInvoiceProps,
  IUseInvoiceReturnType,
} from './useInvoice';
import {
  InvoiceFormDTO,
  InvoiceModel,
  InvoiceStatus,
  InvoiceType,
} from '../struture';
import {
  createInvoice,
  editInvoice,
  getInvoiceForPrint,
  getInvoiceStoreRealizationForPrint,
  getInvoiceInputInvoiceForPrint,
  updateInvoiceComment,
  updateInvoiceStatus,
  duplicateInvoice,
} from '../services/api/invoice';
import {useDispatch, useSelector} from 'react-redux';
import {ApiError} from '../services/types';
import {SetStateAction, useCallback, useEffect} from 'react';
import {
  addInvoiceToList as storeAddInvoice,
  updateInvoiceFromList as storeUpdateInvoiceFromList,
  updateInvoiceStatsInList as storeUpdateInvoiceStatsInList,
} from '../store/features/invoiceListSlice';
import {
  setInvoice as storeSetInvoice,
  updateInvoice as storeUpdateInvoice,
  selectInvoice,
} from '../store/features/invoiceSlice';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../contex';
import {printScheduleDocument} from '../services/helpers';
import {useStoredInvoiceList} from './useStoredInvoiceList';
import {List} from 'immutable';
import {StatusError} from '../components/lib/Errors';

export interface IUseStateInvoiceProps extends IUseInvoiceProps {
  companyUuid: string;
  invoiceType?: InvoiceType;
}

export interface ICreateInvoiceReturnType {
  invoice: InvoiceModel;
}

export interface IUseStateInvoiceReturnType
  extends Omit<IUseInvoiceReturnType, 'entity'> {
  handleUpdateInvoice: (value: InvoiceFormDTO) => Promise<InvoiceModel | void>;
  handleCreateInvoice: (
    value: InvoiceFormDTO,
    initCompanyUuid?: string,
  ) => Promise<ICreateInvoiceReturnType | void>;
  handleDuplicateInvoice: (
    initInvoiceUuid?: string,
  ) => Promise<ICreateInvoiceReturnType | void>;
  handleCreateOrUpdateInvoice: (
    value: InvoiceFormDTO,
  ) => Promise<InvoiceModel | void>;
  handleRefreshInvoice: () => Promise<void>;

  handlePrintInvoice: (invoiceUuid?: string) => Promise<void>;
  handlePrintInvoiceStoreRealization: (invoiceUuid?: string) => Promise<void>;
  handlePrintInputInvoice: (invoiceUuid?: string) => Promise<void>;

  refresh: (value: Partial<IUseInvoiceProps>) => Promise<InvoiceModel | void>;
  error: ApiError | null;
  loading: boolean;
  invoice: InvoiceModel | null;
  invoiceUuid: string;
  setInvoice: React.Dispatch<SetStateAction<InvoiceModel | null>>;
  handleUpdateInvoiceComment: (comment: string) => Promise<void>;
  handleUpdateInvoiceStatus: (status: InvoiceStatus) => Promise<void>;
  updateInvoice: (invoice: InvoiceModel) => void;
}

export function useStoredInvoice({
  companyUuid,
  loadOnInit = true,
  invoiceUuid,
  invoiceType,
  ...rest
}: IUseStateInvoiceProps): IUseStateInvoiceReturnType {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const [invoice, setInvoice] = React.useState<InvoiceModel | null>(null);
  const [isStartLoading, setIsStartLoading] = React.useState<boolean>(false);

  const {cachedInvoiceMap, status, ...invoiceInvoiceParams} =
    useSelector(selectInvoice);

  const dispatch = useDispatch<any>();
  const once = React.useRef(false);

  const {entity, refresh, ...invoiceParams} = useInvoice({
    loadOnInit: loadOnInit && isStartLoading,
    invoiceUuid,
    ...rest,
  });

  const {invoiceList} = useStoredInvoiceList({
    companyUuid: '',
    loadOnInit: false,
    invoiceType: invoice?.inv_type! || invoiceType,
  });

  React.useEffect(() => {
    if (invoiceUuid) {
      if (cachedInvoiceMap?.size > 0) {
        const invoiceModel = cachedInvoiceMap.get(invoiceUuid);

        if (invoiceModel && !invoiceModel.equals(invoice)) {
          setIsStartLoading(false);
          setInvoice(invoiceModel);
        }

        if (!invoiceModel) {
          setIsStartLoading(true);
        }
      }
      if (cachedInvoiceMap?.size === 0 && !invoice) {
        setIsStartLoading(true);
      }
    }
  }, [cachedInvoiceMap, invoice, invoiceUuid]);

  useEffect(() => {
    if (entity && isStartLoading && !once.current && loadOnInit) {
      dispatch(storeSetInvoice({invoice: entity}));
      setInvoice(entity);
    }
  }, [loadOnInit, dispatch, entity, isStartLoading]);

  const handleUpdateInvoice = React.useCallback(
    async (value: InvoiceFormDTO): Promise<InvoiceModel | void> => {
      const {invoice} = await editInvoice(value);

      dispatch(storeUpdateInvoice({invoice}));

      if (List.isList(invoiceList)) {
        dispatch(
          storeUpdateInvoiceFromList({invoice, invoiceType: invoice?.inv_type}),
        );
      }

      setInvoice(invoice);

      return invoice;
    },
    [dispatch, invoiceList],
  );

  const handleCreateInvoice = React.useCallback(
    async (
      value: InvoiceFormDTO,
      initCompanyUuid = companyUuid,
    ): Promise<ICreateInvoiceReturnType> => {
      const {invoice, stats} = await createInvoice(value, initCompanyUuid);

      if (List.isList(invoiceList)) {
        dispatch(storeAddInvoice({invoice, invoiceType: invoice?.inv_type}));

        if (stats) dispatch(storeUpdateInvoiceStatsInList({stats}));
      }

      setInvoice(invoice);

      return {
        invoice,
      };
    },
    [companyUuid, dispatch, invoiceList],
  );

  const handleDuplicateInvoice = React.useCallback(
    async (
      initInvoiceUuid = invoiceUuid,
    ): Promise<ICreateInvoiceReturnType | void> => {
      try {
        const {invoice} = await duplicateInvoice(initInvoiceUuid);

        if (List.isList(invoiceList)) {
          dispatch(storeAddInvoice({invoice, invoiceType: invoice?.inv_type}));
        }

        alert('success', t('Bill'), t('Invoice duplicate success'));

        return {
          invoice,
        };
      } catch (error: any) {
        alert(
          'error',
          t('Bill'),
          `${t('An error occurred during duplicate invoice')} : ${
            error?.message
          }`,
        );
      }
    },
    [alert, dispatch, invoiceList, invoiceUuid, t],
  );

  const handleRefreshInvoice = React.useCallback(async () => {
    const invoice = await refresh({showLoading: false, invoiceUuid});

    if (invoice) {
      dispatch(storeUpdateInvoice({invoice}));
      dispatch(
        storeUpdateInvoiceFromList({invoice, invoiceType: invoice?.inv_type}),
      );
    }
  }, [invoiceUuid, dispatch, refresh]);

  const handleCreateOrUpdateInvoice = React.useCallback(
    async (value: InvoiceFormDTO) => {
      if (!value?.uuid) {
        const {invoice} = await handleCreateInvoice(value);

        return invoice;
      }
      return handleUpdateInvoice(value);
    },
    [handleCreateInvoice, handleUpdateInvoice],
  );

  const handlePrintInvoice = React.useCallback(
    async (uuid?: string): Promise<void> => {
      try {
        const html = await getInvoiceForPrint(uuid || invoiceUuid);

        alert('success', t('Bill'), t('Get invoice success'));
        printScheduleDocument(html);
      } catch (error: any) {
        alert(
          'error',
          t('Bill'),
          `${t('An error occurred during get invoice')} : ${error?.message}`,
        );
      }
    },
    [alert, invoiceUuid, t],
  );

  const handlePrintInvoiceStoreRealization = React.useCallback(
    async (uuid?: string): Promise<void> => {
      try {
        const html = await getInvoiceStoreRealizationForPrint(
          uuid || invoiceUuid,
        );

        alert('success', t('Bill'), t('Get sale invoice success'));
        printScheduleDocument(html);
      } catch (error: any) {
        alert(
          'error',
          t('Bill'),
          `${t('An error occurred during get sale invoice')} : ${
            error?.message
          }`,
        );
      }
    },
    [alert, invoiceUuid, t],
  );

  const handlePrintInputInvoice = React.useCallback(
    async (uuid?: string): Promise<void> => {
      try {
        const html = await getInvoiceInputInvoiceForPrint(uuid || invoiceUuid);

        alert('success', t('Bill'), t('Get input invoice success'));
        printScheduleDocument(html);
      } catch (error: any) {
        alert(
          'error',
          t('Bill'),
          `${t('An error occurred during get input invoice')} : ${
            error?.message
          }`,
        );
      }
    },
    [alert, invoiceUuid, t],
  );

  const handleUpdateInvoiceComment = React.useCallback(
    async (comment: string): Promise<void> => {
      try {
        const {invoice} = await updateInvoiceComment(comment, invoiceUuid);

        dispatch(storeUpdateInvoice({invoice}));

        if (List.isList(invoiceList)) {
          dispatch(
            storeUpdateInvoiceFromList({
              invoice,
              invoiceType: invoice?.inv_type,
            }),
          );
        }

        setInvoice(invoice);

        alert('success', t('Bill'), t('Update bill comment'));
      } catch (error: any) {
        alert(
          'error',
          t('Bill'),
          `${t('An error occurred during edit bill comment')} : ${
            error?.message
          }`,
        );

        throw new StatusError(error?.message, error?.status);
      }
    },
    [alert, dispatch, invoiceList, invoiceUuid, t],
  );

  const handleUpdateInvoiceStatus = React.useCallback(
    async (status: InvoiceStatus): Promise<void> => {
      try {
        const {invoice} = await updateInvoiceStatus(status, invoiceUuid);

        dispatch(storeUpdateInvoice({invoice}));

        if (List.isList(invoiceList)) {
          dispatch(
            storeUpdateInvoiceFromList({
              invoice,
              invoiceType: invoice?.inv_type,
            }),
          );
        }

        setInvoice(invoice);

        alert('success', t('Bill'), t('Update bill status'));
      } catch (error: any) {
        alert(
          'error',
          t('Bill'),
          `${t('An error occurred during edit bill')} : ${error?.message}`,
        );

        throw new StatusError(error?.message, error?.status);
      }
    },
    [alert, dispatch, invoiceList, invoiceUuid, t],
  );

  const updateInvoice = useCallback(
    (updatedInvoice: InvoiceModel) => {
      if (invoice) {
        const updated = invoice.update(
          'inv_sum_total',
          () => updatedInvoice.inv_sum_total,
        );

        if (List.isList(invoiceList)) {
          dispatch(
            storeUpdateInvoiceFromList({
              invoice: updated,
              invoiceType: invoice?.inv_type,
            }),
          );
        }

        dispatch(storeUpdateInvoice({invoice: updated}));
      }
    },
    [dispatch, invoice, invoiceList],
  );

  return {
    ...invoiceParams,
    ...invoiceInvoiceParams,
    invoice,
    loading: !invoice,
    handleUpdateInvoice,
    handleCreateInvoice,
    refresh,
    handleRefreshInvoice,
    handleCreateOrUpdateInvoice,
    handlePrintInvoiceStoreRealization,
    handlePrintInvoice,
    handlePrintInputInvoice,
    handleUpdateInvoiceComment,
    setInvoice,
    handleUpdateInvoiceStatus,
    updateInvoice,
    handleDuplicateInvoice,
  };
}
