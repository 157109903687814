import {Record} from 'immutable';
import {
  RewardDTO,
  RewardStatusesText,
  ProfileDTO,
  ProfileModel,
  ScheduleCalendarDTO,
  ScheduleCalendarModel,
  RewardRewardableType,
  ProfileMapper,
  ScheduleCalendarMapper,
  IPaymentDTOProps,
  PaymentDTO,
  PaymentModel,
  PaymentMapper,
} from '../internal';
import {WithoutRecursion} from '../type';

export interface IRewardModelReturnType {
  uuid: string;
  date: string;
  hint: string;
  price: string;
  created_at: string;
  updated_at: string;
  status_text: RewardStatusesText;
  rewardable_type: RewardRewardableType;
  rewardable: ScheduleCalendarDTO | ScheduleCalendarModel;
  created_by: ProfileDTO | ProfileModel;
  payment?: PaymentDTO | PaymentModel;
  sum_paid: string;
}

/**
 * @class Product
 * @desc Клієнт
 */
export class RewardModel extends Record<IRewardModelReturnType>({
  uuid: '',
  date: '',
  hint: '',
  price: '',
  created_at: '',
  updated_at: '',
  status_text: RewardStatusesText.Error,
  rewardable_type: RewardRewardableType.Schedule,
  rewardable: {} as ScheduleCalendarModel,
  created_by: {} as ProfileModel,
  payment: {} as PaymentModel,
  sum_paid: '',
}) {
  constructor(
    props: RewardDTO = {} as RewardDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IRewardModelReturnType = {
      ...props,
      rewardable:
        withoutRecursion.indexOf(WithoutRecursion.scheduleCalendar) !== -1
          ? props?.rewardable
          : ScheduleCalendarMapper.toScheduleCalendarModel(props?.rewardable, [
              WithoutRecursion.reward,
              ...withoutRecursion,
            ]),
      created_by:
        withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
          ? props?.created_by
          : ProfileMapper.toProfileModel(props?.created_by, [
              WithoutRecursion.reward,
              ...withoutRecursion,
            ]),

      payment:
        withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
          ? props?.payment
          : PaymentMapper.toPaymentModel(props?.payment || ({} as PaymentDTO)),
    };

    super(options);
  }

  get createdByModel(): ProfileModel {
    return this.created_by instanceof ProfileModel
      ? this.created_by
      : ProfileMapper.toProfileModel(this.created_by);
  }
  get rewardableModel(): ScheduleCalendarModel {
    return this.rewardable instanceof ScheduleCalendarModel
      ? this.rewardable
      : ScheduleCalendarMapper.toScheduleCalendarModel(this.rewardable);
  }

  get paymentModel(): PaymentModel {
    return this.payment instanceof PaymentModel
      ? this.payment
      : PaymentMapper.toPaymentModel(this.payment || ({} as PaymentDTO));
  }
}
