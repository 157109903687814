import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {useModal} from '../../../components/lib/libV2/hooks';
import {
  ClientRefundsFormDTO,
  ClientAbonementModel,
  ClientRefundsDTO,
} from '../../../struture';
import {
  AddButton,
  IAddButtonProps,
  SuspenseEmpty,
} from '../../../components/lib/DataDisplay';
import {StyledTitle} from '../../../components/lib/Styled';
import styled, {useTheme, css} from 'styled-components';
import {ICalculationRefundsClientAbonementProps} from '../../../services/api/client';

const ClientRefundsSideWindowLazy = React.lazy(
  () => import('../Show/ClientRefundsSideWindow'),
);

export interface IClientRefundsButtonProps extends IAddButtonProps {
  onSuccess: (
    value: ClientRefundsFormDTO,
  ) => Promise<ClientAbonementModel | void>;
  onCancel?: () => void;
  refund: ClientRefundsFormDTO;
  title?: string;
  tooltipTitle?: string;
  editMode?: boolean;
  handleCalculateRefundsClientAbonement: (
    value: ICalculationRefundsClientAbonementProps,
  ) => Promise<ClientRefundsDTO>;
}

const StyledAddButton = styled(AddButton)<{disabled?: boolean}>`
  width: fit-content;
  background-color: #c0aea1;

  ${({disabled}) =>
    !disabled &&
    css`
      &&&:hover,
      &&&:active,
      &&&:focus {
        background-color: #c0aea1;
        opacity: 0.7;
      }
    `}
`;

export default function ClientRefundsButton({
  loading = false,
  onSuccess,
  onCancel,
  refund,
  title,
  tooltipTitle,
  editMode,
  disabled,
  handleCalculateRefundsClientAbonement,
  ...rest
}: IClientRefundsButtonProps) {
  const {t} = useTranslation();
  const theme: any = useTheme();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <StyledAddButton
        type="primary"
        tooltipTitle={tooltipTitle || t('Return funds')}
        onClick={handleOnInit}
        loading={loading}
        disabled={disabled}
        {...rest}>
        <StyledTitle
          $color={disabled ? theme.colors.disable : theme.colors.white}>
          {title || t('Return funds')}
        </StyledTitle>
      </StyledAddButton>

      <Suspense fallback={<SuspenseEmpty />}>
        <ClientRefundsSideWindowLazy
          refund={refund}
          loading={loading as boolean}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          visible={visible}
          editMode={editMode}
          handleCalculateRefundsClientAbonement={
            handleCalculateRefundsClientAbonement
          }
        />
      </Suspense>
    </>
  );
}
