import React, {forwardRef, useEffect, useRef, useState} from 'react';
import {IAclLayoutProps} from '../AclLayout';
import {AppHeader, IAppHeaderProps} from '../AppHeader';
import {IModulesMap} from '../../../../hooks';
import {ListOutsideContent} from './ListOutsideContent';
import {ListContent} from './ListContent';
import {ListHeader} from './ListHeader';
import {ListEmpty} from './ListEmpty';
import {ListLayoutManager} from '../../../../contex';
import styled, {css} from 'styled-components';
import {Keyboard} from '../../DataDisplay';

export interface IListLayoutProps
  extends Pick<IAclLayoutProps, 'aclItem'>,
    IAppHeaderProps {
  children: React.ReactNode | React.ReactNode[];
  empty: React.ReactNode;
  loading: boolean;
  headerExtra?: React.ReactNode | React.ReactNode[];
  moduleItem?: keyof IModulesMap;
  outsideFooterContent?: React.ReactNode;
  withPaddingBottom?: boolean;
  showWarning?: boolean;
  withKeyboard?: boolean;
  isFocusKeyboard?: boolean;
  headerLeftTitle?: string;
}

const StyledListContent = styled(ListContent)<{
  $withPaddingBottom: boolean;
  $withKeyboard?: boolean;
}>`
  ${({$withPaddingBottom}) =>
    $withPaddingBottom &&
    css`
      padding-bottom: 150px;
    `};

  ${({$withPaddingBottom, $withKeyboard}) =>
    $withPaddingBottom &&
    $withKeyboard &&
    css`
      padding-bottom: 340px;
    `};

  ${({$withKeyboard}) =>
    $withKeyboard &&
    css`
      padding-bottom: 290px;
    `};
`;

const StyledListOutsideContent = styled(ListOutsideContent)<{
  $withKeyboard?: boolean;
  $isFocus?: boolean;
}>`
  transition: bottom 0.5s;

  ${({$withKeyboard, $isFocus}) =>
    $withKeyboard &&
    $isFocus &&
    css`
      transition: bottom 0.5s;
      bottom: 290px;
    `};
`;

const StyledKeyboardListOutsideContent = styled(ListOutsideContent)<{
  $withKeyboard?: boolean;
  $isFocus?: boolean;
}>`
  background-color: ${({theme}) => theme.background.primary};
  padding-bottom: 15px;
  transition: bottom 0.5s;
  bottom: -290px;

  ${({$isFocus, $withKeyboard}) =>
    $withKeyboard &&
    $isFocus &&
    css`
      transition: bottom 0.5s;
      bottom: 0;
    `};
`;

export const ListLayout = forwardRef(function ListLayout(
  {
    children,
    aclItem,
    headerRight,
    headerTitle,
    headerLeft,
    routes,
    loading,
    empty,
    headerExtra,
    headerTitleDescription,
    isHeaderFlickering,
    moduleItem,
    outsideFooterContent,
    withPaddingBottom,
    showWarning,
    withKeyboard = false,
    isFocusKeyboard,
    headerLeftTitle,
  }: IListLayoutProps,
  ref: any,
): React.JSX.Element {
  const [withKeyboardTransition, setWithKeyboardTransition] = useState(false);

  const timeoutRef = useRef<any>(false);

  useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    if (withKeyboard) {
      setWithKeyboardTransition(true);
    } else {
      timeoutRef.current = setTimeout(setWithKeyboardTransition, 1001, false);
    }
  }, [withKeyboard]);

  return (
    <ListLayoutManager aclItem={aclItem} ref={ref}>
      <AppHeader
        headerRight={headerRight}
        headerTitle={headerTitle}
        headerLeft={headerLeft}
        leftTitle={headerLeftTitle}
        headerTitleDescription={headerTitleDescription}
        isHeaderFlickering={isHeaderFlickering}
        routes={routes}
        showWarning={showWarning}
      />

      <StyledListContent
        $withKeyboard={withKeyboard}
        $withPaddingBottom={!!outsideFooterContent && !!withPaddingBottom}>
        <ListHeader>{headerExtra}</ListHeader>
        <ListEmpty loading={loading} empty={empty}>
          {children}
        </ListEmpty>
      </StyledListContent>

      {outsideFooterContent ? (
        <StyledListOutsideContent
          $withKeyboard={withKeyboard}
          $isFocus={isFocusKeyboard}>
          {outsideFooterContent}
        </StyledListOutsideContent>
      ) : null}

      <StyledKeyboardListOutsideContent
        $withKeyboard={withKeyboard}
        $isFocus={isFocusKeyboard}>
        {withKeyboardTransition ? <Keyboard /> : null}
      </StyledKeyboardListOutsideContent>
    </ListLayoutManager>
  );
});
