import React from 'react';
import {Routes, Route} from 'react-router';
import {IntegrationListPage} from '../views/Integrations';
import {AppRouteLayout} from '../components/lib/Layout';

export function IntegrationRoutesNavigator() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AppRouteLayout>
            <IntegrationListPage />
          </AppRouteLayout>
        }
      />
    </Routes>
  );
}
