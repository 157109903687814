import {Record} from 'immutable';
import {ClientGroupSessionDTO} from '../internal';

export interface IClientGroupSessionModelReturnType {
  uuid: string;
  status: number;
  created_at: string;
  updated_at: string;
}

export class ClientGroupSessionModel extends Record<IClientGroupSessionModelReturnType>(
  {
    uuid: '',
    status: 0,
    created_at: '',
    updated_at: '',
  },
) {
  constructor({...props}: ClientGroupSessionDTO = {} as ClientGroupSessionDTO) {
    const options: IClientGroupSessionModelReturnType = {
      ...props,
    };

    super(options);
  }
}
