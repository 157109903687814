export interface IGalleryDTOProps {
  uuid: string;
  title: string;
  description: string;
  path_url: string;
  status: number;
  created_at: string;
  updated_at: string;
}

export class GalleryDTO {
  public uuid: string;

  public title: string;

  public description: string;

  public path_url: string;

  public status: number;

  public created_at: string;

  public updated_at: string;

  constructor(props?: IGalleryDTOProps) {
    this.uuid = props?.uuid || '';
    this.title = props?.title || '';
    this.description = props?.description || '';
    this.path_url = props?.path_url || '';
    this.status = props?.status || 0;
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
  }
}
