import React from 'react';
import {InvoiceModel, InvoiceStatus} from '../../../struture';
import styled, {useTheme, css} from 'styled-components';
import {StyledDescription} from '../../../components/lib/Styled';
import {useLocation} from 'react-router';
import {useTranslation} from 'react-i18next';
import {Link} from '../../../components/lib/DataDisplay';
import {Routes} from '../../../services/types';
import {toDateByFormat, textToUpperCase} from '../../../services/helpers';
import {MdEditDocument} from 'react-icons/md';
import {IoMdDocument} from 'react-icons/io';
import Color from 'color';
import {Tag} from 'antd';
import {InvoiceStatusTag} from './InvoiceStatusTag';

export interface InvoiceDetailsViewProps {
  invoice: InvoiceModel | null;
  to?: string;
  showTag?: boolean;
  withStatus?: boolean;
  withoutNavigate?: boolean;
}
const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`;

const StyledSpace = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 5px;
`;

const StyledDescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledLink = styled(Link)<{$draft: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
  gap: 5px;
  justify-content: flex-start;

  ${({$draft}) =>
    $draft &&
    css`
      color: ${({theme}) => theme.colors.warning};

      &:hover {
        color: ${({theme}) =>
          Color(theme.colors.warning).alpha(1).lighten(0.2).toString()};
      }
    `}
`;

const StyledNavigateContainer = styled.div<{$draft: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
  gap: 5px;

  ${({$draft}) =>
    $draft &&
    css`
      color: ${({theme}) => theme.colors.warning};

      &:hover {
        color: ${({theme}) =>
          Color(theme.colors.warning).alpha(1).lighten(0.2).toString()};
      }
    `}
`;

const StyledTag = styled(Tag)`
  height: 17px;
  line-height: 1.3;
`;

export function InvoiceDetailsView({
  invoice,
  to,
  showTag,
  withStatus,
  withoutNavigate,
}: InvoiceDetailsViewProps): JSX.Element {
  const {t} = useTranslation();
  const location = useLocation();
  const theme: any = useTheme();

  return (
    <>
      {invoice ? (
        <StyledContainer>
          <StyledSpace>
            <StyledTitleContainer>
              {showTag ? (
                <StyledTag
                  color={Color(theme.tabs.success)
                    .alpha(1)
                    .darken(0.1)
                    .toString()}>
                  <StyledDescription
                    fontSize={10}
                    $color={Color(theme.colors.success)
                      .alpha(1)
                      .darken(0.65)
                      .toString()}>
                    {textToUpperCase(t('Bills'))}
                  </StyledDescription>
                </StyledTag>
              ) : null}
              {withoutNavigate ? (
                <StyledNavigateContainer
                  $draft={invoice?.inv_status === InvoiceStatus.DRAFT}>
                  {invoice?.inv_status === InvoiceStatus.DRAFT ? (
                    <MdEditDocument color={theme.colors.warning} />
                  ) : (
                    <IoMdDocument color={theme.colors.link} />
                  )}
                  {invoice?.inv_internal_number}
                  {invoice?.inv_status === InvoiceStatus.DRAFT ? (
                    <StyledDescription $color={theme.colors.warning}>
                      {` ( ${t('Draft')} )`}
                    </StyledDescription>
                  ) : null}{' '}
                </StyledNavigateContainer>
              ) : (
                <StyledLink
                  $draft={invoice?.inv_status === InvoiceStatus.DRAFT}
                  to={
                    to || `/${Routes.app}/${Routes.invoices}/${invoice?.uuid}`
                  }
                  tooltip={t('Open a detailed description of the account')}
                  state={{goBack: location.pathname}}>
                  {invoice?.inv_status === InvoiceStatus.DRAFT ? (
                    <MdEditDocument color={theme.colors.warning} />
                  ) : (
                    <IoMdDocument color={theme.colors.link} />
                  )}
                  {invoice?.inv_internal_number}
                  {invoice?.inv_status === InvoiceStatus.DRAFT ? (
                    <StyledDescription $color={theme.colors.warning}>
                      {` ( ${t('Draft')} )`}
                    </StyledDescription>
                  ) : null}
                </StyledLink>
              )}
            </StyledTitleContainer>
            <StyledDescriptionContainer>
              {invoice?.inv_date ? (
                <StyledDescription>
                  {toDateByFormat(invoice?.inv_date, 'DD.MM.YYYY HH:mm')}
                </StyledDescription>
              ) : null}

              {withStatus ? (
                <InvoiceStatusTag status={invoice?.inv_status} />
              ) : null}
            </StyledDescriptionContainer>
          </StyledSpace>
        </StyledContainer>
      ) : null}
    </>
  );
}
