import React from 'react';
import {
  ProfilePage,
  ProfileOperationListPage,
  SubscriptionListPage,
  SubscriptionPage,
} from '../views/Profile';
import {CompanyRoutesNavigator} from './CompanyRoutesNavigator';
import {NotificationPage} from '../views/Notifications';
import {AppRouteLayout} from '../components/lib/Layout';
import {Routes, Route} from 'react-router';
import {Routes as RoutesType} from '../services/types';

export function ProfileRoutesNavigator() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AppRouteLayout>
            <ProfilePage />
          </AppRouteLayout>
        }
      />

      <Route
        path={`/${RoutesType.companies}/*`}
        element={<CompanyRoutesNavigator />}
      />

      <Route
        path={RoutesType.notifications}
        element={
          <AppRouteLayout>
            <NotificationPage />
          </AppRouteLayout>
        }
      />

      <Route
        path={RoutesType.operations}
        element={
          <AppRouteLayout>
            <ProfileOperationListPage />
          </AppRouteLayout>
        }
      />
    </Routes>
  );
}
