import React, {useMemo, useCallback, useState} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {Col, Row, FormInstance, Form, Input, InputNumber, Divider} from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import {
  IDefaultFormProps,
  DefaultForm,
  PhoneInputComponent,
  DatePicker,
} from '../../../components/lib/General';
import {
  ICompanyUserParams,
  USER_INITIAL_PARAM,
} from '../../../services/api/companyUser';
import {ifElse, eq, isListToArray} from '../../../services/helpers';
import {User} from '../../../services/models';
import {SearchSelect, Segmented} from '../../../components/lib/DataDisplay';
import {useStoredCompanies} from '../../../hooks';
import {TextFormat} from '../../../components/lib/Format';
import UserAuthFormFields from './UserAuthFormFields';
import {GENDER_SEGMENTED} from '../../../services/const';

export default withTranslation()(UserForm);

export interface IUserFormProps
  extends WithTranslation,
    Omit<
      IDefaultFormProps<ICompanyUserParams | Partial<User>, ICompanyUserParams>,
      'children' | 'initialValues' | 'additionalValuesRequest'
    > {
  loading?: boolean;
  user?: ICompanyUserParams | User;
  overviewMode?: boolean;
}

interface IEmailChangeProps extends Pick<FormInstance, 'setFieldsValue'> {
  value: string;
}

interface IEmailBlurProps extends Pick<FormInstance, 'getFieldValue'> {
  value: boolean;
}

const StyledTextFormat = styled(TextFormat)`
  font-size: 16px;
  margin-left: 24px;
  font-weight: 500;
`;

const StyledSearchSelect = styled(SearchSelect)`
  & * .ant-select-selection-item {
    color: ${({theme}) => theme.colors.formText};
  }
`;

function UserForm({
  t,
  loading,
  editMode,
  user = USER_INITIAL_PARAM,
  overviewMode,
  ...rest
}: IUserFormProps) {
  const {companies, companiesLoading} = useStoredCompanies();

  const [isEmailFieldBlur, setIsEmailFieldBlur] = useState(editMode);

  const additionalValues = useMemo(
    () => ({
      uuid: user?.uuid,
    }),
    [user?.uuid],
  );

  const birthday = useMemo(
    () => ({
      birthday: ifElse(!!user?.birthday, moment(user?.birthday), ''),
    }),
    [user?.birthday],
  );

  const getInputWithAddonRules = (message: string): any[] => [
    {
      transform: (value: string): number => Number(value),
      type: 'number',
      min: 0,
      max: 100,
      message,
    },
  ];

  const handleChangeEmailField = useCallback(
    ({value, setFieldsValue}: IEmailChangeProps): void => {
      if (!isEmailFieldBlur && !editMode) {
        setFieldsValue({login: value});
      }
    },
    [isEmailFieldBlur, editMode],
  );

  const handleBlurEmailField = useCallback(
    ({value, getFieldValue}: IEmailBlurProps): void => {
      if (!editMode) {
        const emailFieldValue = getFieldValue('email');

        if (value && !!emailFieldValue) {
          setIsEmailFieldBlur((prevState) => prevState || true);
        }
      }
    },
    [editMode],
  );

  return (
    <DefaultForm
      invertFooterButtons={overviewMode}
      editMode={overviewMode}
      notifyFormName={t('User')}
      initialValues={{
        ...user,
        ...birthday,
      }}
      additionalValuesRequest={additionalValues}
      {...rest}>
      {({loadingSubmit, setFieldsValue, getFieldValue}) => (
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              tooltip={t('User first name')}
              label={t('First Name')}
              name="first_name"
              rules={[
                {
                  required: true,
                  message: t('First Name must be specified.'),
                },
              ]}>
              <Input
                data-testid="user__input--first-name"
                placeholder={t('Enter the user first name')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              tooltip={t('User middle name')}
              label={t('Middle Name')}
              name="middle_name">
              <Input
                data-testid="user__input--middle-name"
                placeholder={t('Enter the user middle name')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              tooltip={t('User last name')}
              label={t('Last Name')}
              name="last_name"
              rules={[
                {
                  required: true,
                  message: t('Last Name must be specified.'),
                },
              ]}>
              <Input
                data-testid="user__input--last-name"
                disabled={loading || loadingSubmit}
                placeholder={t('Enter the user last name')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('Phone')}
              name="phone"
              rules={[
                {
                  required: true,
                  message: t('User Phone must be specified.'),
                },
              ]}>
              <PhoneInputComponent
                dataTestid="user__input--phone"
                placeholder={t('Enter the user phone')}
                id="field-phone"
                containerClass="field-phone-container"
                searchClass="field-phone-search"
                inputClass="field-phone ant-input"
                loading={loading || loadingSubmit}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('Email')}
              name="email"
              rules={[
                {
                  required: true,
                  message: t('Email must be specified.'),
                },
              ]}>
              <Input
                data-testid="user__input--email"
                disabled={loading || loadingSubmit}
                placeholder={t('Enter the user email')}
                onBlur={(e) =>
                  handleBlurEmailField({value: e.isTrusted, getFieldValue})
                }
                onChange={(e) =>
                  handleChangeEmailField({
                    value: e.target.value,
                    setFieldsValue,
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('Birthday')} name="birthday">
              <DatePicker
                data-testid="user__picker--birthday"
                disabled={loading || loadingSubmit}
                placeholder={t('Birthday')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('Address')}
              name="address"
              rules={[
                {
                  max: 250,
                  message: t(
                    'User Address must contain a maximum of 250 characters.',
                  ),
                },
              ]}>
              <Input
                data-testid="user__input--address"
                disabled={loading || loadingSubmit}
                placeholder={t('Enter the user address')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('Gender')} name="gender">
              <Segmented options={GENDER_SEGMENTED} disabled={loadingSubmit} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              tooltip={t('Discount on subscriptions')}
              label={t('Discount on subscriptions')}
              rules={getInputWithAddonRules(
                t(
                  'Discount on subscriptions must be a number and bigger than -1 and less 100',
                ),
              )}
              name="subscription_discount">
              <Input
                addonAfter="%"
                data-testid="user__input--discount-subscriptions"
                placeholder={t('Enter the discount on subscriptions')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              tooltip={t('Discount on goods')}
              label={t('Discount on goods')}
              rules={getInputWithAddonRules(
                t(
                  'Discount on goods must be a number and bigger than -1 and less 100',
                ),
              )}
              name="goods_discount">
              <Input
                addonAfter="%"
                data-testid="user__input--discount_goods"
                placeholder={t('Enter the discount on goods')}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              tooltip={t('Discount card number')}
              label={t('card_number')}
              name="discount_card_number">
              <InputNumber
                data-testid="user__input--discount-card-number"
                disabled={loading || loadingSubmit}
                placeholder={t('Enter the discount card number')}
              />
            </Form.Item>
          </Col>
          {editMode && (
            <Col span={24}>
              <Form.Item
                tooltip={t('Company to which the employee belongs')}
                label={t('Company')}
                name="default_company">
                <StyledSearchSelect
                  name="default_company"
                  placeholder={t('Select the Company')}
                  disable={
                    loading ||
                    loadingSubmit ||
                    companiesLoading ||
                    eq(companies?.size, 1)
                  }
                  data={isListToArray(companies as any)}
                />
              </Form.Item>
            </Col>
          )}
          {!editMode && (
            <>
              <Divider />
              <StyledTextFormat invert={overviewMode} primary>
                {t('Authorization data')}
              </StyledTextFormat>
              <Divider />

              <UserAuthFormFields
                loading={loading || loadingSubmit}
                overviewMode={overviewMode}
              />
            </>
          )}
        </Row>
      )}
    </DefaultForm>
  );
}
