import React from 'react';
import styled from 'styled-components';
import {withTranslation} from 'react-i18next';
import AppFooter from './AppFooter';

withTranslation()(FooterWithoutLayout);

const StyledFooter = styled(AppFooter)`
  background-color: transparent;

  & .footer__text {
    color: ${({theme}) => theme.colors.white};
  }
`;

export default function FooterWithoutLayout(): JSX.Element {
  return <StyledFooter />;
}
