import {List, Map} from 'immutable';
import {APP_STATE, REWARD_LIST} from '../constants';
import {compose, isEqualByUuid, not} from '../../services/helpers';
import {ApiError, REDUX_STATUS} from '../../services/types';
import {
  RewardListModel,
  RewardMapper,
  RewardModel,
  EmployeeMapper,
  EmployeeDTO,
  RewardStatsDTO,
} from '../../struture';

interface IRewardListState {
  rewardList: List<RewardModel> | null;
  cachedRewardList: Map<string, RewardListModel>;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
  total: number;
}

interface SetActionList extends Pick<IRewardListState, 'total'> {
  type: REWARD_LIST.SET_REWARD_LIST;
  employeeUuid: string;
  rewardList: RewardListModel;
  keywords?: string;
  page: number;
  stats: RewardStatsDTO;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface AddActionToList {
  type: REWARD_LIST.ADD_REWARD;
  reward: RewardModel;
  employeeUuid: string;
}

interface UpdateRewardFromList {
  type: REWARD_LIST.UPDATE_REWARD;
  reward: RewardModel;
  employeeUuid: string;
}

interface UpdateRewardStatsFromList {
  type: REWARD_LIST.UPDATE_REWARD;
  stats: RewardStatsDTO;
  employeeUuid: string;
}

interface DeleteActionFromList {
  type: REWARD_LIST.DELETE_REWARD;
  rewardUuid: string;
  employeeUuid: string;
}

interface LoadingActionInList {
  type: REWARD_LIST.LOADING_REWARD_LIST;
}

interface ErrorActionInList extends Pick<IRewardListState, 'error'> {
  type: REWARD_LIST.ERROR_REWARD_LIST;
}

interface Handlers {
  [REWARD_LIST.SET_REWARD_LIST]: (
    state: IRewardListState,
    action: SetActionList,
  ) => IRewardListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IRewardListState,
    action: SetInitialStateAction,
  ) => IRewardListState;

  [REWARD_LIST.LOAD_MORE_REWARD_LIST]: (
    state: IRewardListState,
    action: SetActionList,
  ) => IRewardListState;

  [REWARD_LIST.ADD_REWARD]: (
    state: IRewardListState,
    action: AddActionToList,
  ) => IRewardListState;

  [REWARD_LIST.UPDATE_REWARD]: (
    state: IRewardListState,
    action: UpdateRewardFromList,
  ) => IRewardListState;

  [REWARD_LIST.UPDATE_STATS]: (
    state: IRewardListState,
    action: UpdateRewardStatsFromList,
  ) => IRewardListState;

  [REWARD_LIST.DELETE_REWARD]: (
    state: IRewardListState,
    action: DeleteActionFromList,
  ) => IRewardListState;

  [REWARD_LIST.ERROR_REWARD_LIST]: (
    state: IRewardListState,
    value: ErrorActionInList,
  ) => IRewardListState;

  [REWARD_LIST.LOADING_REWARD_LIST]: (
    state: IRewardListState,
    value?: LoadingActionInList,
  ) => IRewardListState;
  DEFAULT: (state: IRewardListState) => IRewardListState;
}

const initState: IRewardListState = {
  rewardList: null,
  cachedRewardList: Map(),
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
  total: 0,
};

const handlers: Handlers = {
  [REWARD_LIST.SET_REWARD_LIST]: (
    state,
    {rewardList, employeeUuid, keywords, page, stats},
  ) => {
    const updatedList = rewardList
      .update('keywords', () => keywords || '')
      .update('page', () => page || 1)
      .update('stats', (prevStats) => stats || prevStats);

    return {
      ...state,
      ...{
        rewardList: rewardList?.rewards || null,
        cachedRewardList: rewardList
          ? state.cachedRewardList.set(employeeUuid, updatedList)
          : state.cachedRewardList,
        status: REDUX_STATUS.SUCCEEDED,
        total: rewardList?.total! >= 0 ? rewardList?.total! : state.total,
      },
    };
  },

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [REWARD_LIST.LOAD_MORE_REWARD_LIST]: (state, {rewardList, employeeUuid}) => {
    const relaterScheduleListById = state.cachedRewardList.get(employeeUuid);

    return {
      ...state,
      ...{
        rewardList:
          List.isList(state.rewardList) && List.isList(rewardList)
            ? state.rewardList.merge(rewardList.rewards)
            : state.rewardList,
        cachedRewardList: rewardList
          ? state.cachedRewardList.set(
              employeeUuid,
              relaterScheduleListById
                ? relaterScheduleListById.update('rewards', (rewards) =>
                    rewards.merge(rewardList.rewards),
                  )
                : rewardList,
            )
          : state.cachedRewardList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [REWARD_LIST.ADD_REWARD]: (state, {reward, employeeUuid}) => {
    const relaterScheduleListById = state.cachedRewardList.get(employeeUuid);

    const employee = state.cachedRewardList.first()?.stats?.employee;

    return {
      ...state,
      ...{
        rewardList: List.isList(state.rewardList)
          ? state.rewardList.unshift(reward)
          : List([reward]),
        cachedRewardList: relaterScheduleListById
          ? state.cachedRewardList.update(employeeUuid, (rewardList) => {
              if (rewardList) {
                return rewardList.update('rewards', (rewards) =>
                  rewards.unshift(reward),
                );
              } else {
                return RewardMapper.toRewardListModel([], 0, {
                  employee:
                    employee ||
                    EmployeeMapper.toEmployeeModel({} as EmployeeDTO),
                  total: '',
                  totalAccrued: '',
                  totalPaid: '',
                });
              }
            })
          : Map({
              [employeeUuid]: RewardMapper.toRewardListModel(
                [reward] as any,
                1,
                {
                  employee:
                    employee ||
                    EmployeeMapper.toEmployeeModel({} as EmployeeDTO),
                  total: '',
                  totalAccrued: '',
                  totalPaid: '',
                },
              ),
            }),
        status: REDUX_STATUS.SUCCEEDED,
        total: state.total + 1,
      },
    };
  },

  [REWARD_LIST.UPDATE_REWARD]: (
    state: IRewardListState,
    {reward, employeeUuid}: UpdateRewardFromList,
  ) => {
    const relaterScheduleListById = state.cachedRewardList.get(employeeUuid);

    const employee = state.cachedRewardList.first()?.stats?.employee;

    return {
      ...state,
      ...{
        rewardList: List.isList(state.rewardList)
          ? state.rewardList.map((stateReward) => {
              if (stateReward.uuid === reward?.uuid) {
                return stateReward.merge(reward);
              }
              return stateReward;
            })
          : List([reward]),

        cachedRewardList: relaterScheduleListById
          ? state.cachedRewardList.update(employeeUuid, (rewardList) => {
              if (rewardList) {
                return rewardList.update('rewards', (rewards) =>
                  rewards.map((abon: RewardModel) => {
                    if (abon.uuid === reward.uuid) {
                      return abon.merge(reward);
                    } else {
                      return abon;
                    }
                  }),
                );
              } else {
                return RewardMapper.toRewardListModel([], 0, {
                  employee:
                    employee ||
                    EmployeeMapper.toEmployeeModel({} as EmployeeDTO),
                  total: '',
                  totalAccrued: '',
                  totalPaid: '',
                });
              }
            })
          : state.cachedRewardList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [REWARD_LIST.UPDATE_STATS]: (
    state: IRewardListState,
    {stats, employeeUuid}: UpdateRewardStatsFromList,
  ) => {
    const relaterScheduleListById = state.cachedRewardList.get(employeeUuid);

    return {
      ...state,
      ...{
        cachedRewardList: relaterScheduleListById
          ? state.cachedRewardList.update(employeeUuid, (rewardList) => {
              if (rewardList) {
                return rewardList.update('stats', (prevStats) => {
                  return {
                    ...(prevStats || {}),
                    ...(stats || {}),
                  };
                });
              } else {
                return rewardList;
              }
            })
          : state.cachedRewardList,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [REWARD_LIST.DELETE_REWARD]: (state, {rewardUuid}) => {
    return {
      ...state,
      ...{
        rewardList: List.isList(state.rewardList)
          ? state.rewardList.filter(compose(not, isEqualByUuid(rewardUuid)))
          : null,
        cachedRewardList: state.cachedRewardList.map((rewards) =>
          rewards.update('rewards', (list) => {
            const index = list.findIndex(
              ({uuid}: RewardModel) => uuid === rewardUuid,
            );

            if (~index) {
              return list.delete(index);
            }

            return list;
          }),
        ),
        status: REDUX_STATUS.SUCCEEDED,
        total: state?.total > 0 ? state?.total - 1 : 0,
      },
    };
  },

  [REWARD_LIST.ERROR_REWARD_LIST]: (
    state: IRewardListState,
    {error}: ErrorActionInList,
  ) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [REWARD_LIST.LOADING_REWARD_LIST]: (state: IRewardListState) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IRewardListState) => state,
};

export default function RewardList(
  state: any = initState,
  action: any,
): IRewardListState {
  const handler = handlers[action.type as REWARD_LIST] || handlers.DEFAULT;
  return handler(state, action);
}
