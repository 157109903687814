import React from 'react';
import {useTranslation} from 'react-i18next';
import {IActionButtonProps} from '../../../../components/lib/General';
import {StoreFormDTO} from '../../../../struture';
import {AddButton} from '../../../../components/lib/DataDisplay';
import {ShoppingCartOutlined} from '@ant-design/icons';
import {Badge} from 'antd';
import {useLeftoverPreorder} from '../Managers';
import styled from 'styled-components';

export interface ILeftoverPreorderButtonProps
  extends Omit<IActionButtonProps, 'children'> {
  children?: React.ReactNode;
  onSuccess?: (value: StoreFormDTO) => Promise<void>;
  onCancel?: () => void;
  store?: StoreFormDTO;
}

const StyledShoppingCartOutlined = styled(ShoppingCartOutlined)`
  font-size: 20px;
`;

export function LeftoverPreorderButton({
  children,
  onSuccess,
  onCancel,
  loading,
  store,
  ...rest
}: ILeftoverPreorderButtonProps): React.JSX.Element {
  const {t} = useTranslation();
  const {countOfProducts} = useLeftoverPreorder();

  return (
    <>
      <AddButton tooltipTitle={t('Create store')} loading={loading} {...rest}>
        <Badge count={countOfProducts} showZero={false} offset={[-1, -1]}>
          <StyledShoppingCartOutlined />
        </Badge>
      </AddButton>
    </>
  );
}
