import React from 'react';
import {Form, Row, Col} from 'antd';
import {withTranslation, WithTranslation} from 'react-i18next';
import {
  DefaultForm,
  IDefaultFormProps,
} from '../../../../components/lib/General';
import {AuthButton} from '../../Buttons';
import {Switch} from '../../../../components/lib/DataDisplay';
import {Text} from '../../../../components/lib/Format';
import styled from 'styled-components';

export default withTranslation()(TermsPolicyForm);

interface IAcceptParams {
  accept: boolean;
}

const ACCEPT_PARAMS: IAcceptParams = {
  accept: false,
};

export interface ITermsPolicyFormProps
  extends WithTranslation,
    Omit<
      IDefaultFormProps<IAcceptParams, IAcceptParams>,
      'children' | 'initialValues'
    > {
  onCancel?: () => void;
  onSuccess: (value: IAcceptParams) => Promise<void>;
  onError?: () => Promise<void>;
  accept?: IAcceptParams;
  content?: React.ReactNode;
}

const StyledSwitchContainer = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;

  & > :first-child {
    margin-right: 10px;
  }
`;

const StyledCol = styled(Col)`
  margin-bottom: 20px;
`;

function TermsPolicyForm({
  accept = ACCEPT_PARAMS,
  t,
  content,
  ...rest
}: ITermsPolicyFormProps): JSX.Element {
  return (
    <DefaultForm initialValues={{...accept}} showFooter={false} {...rest}>
      {({loadingSubmit, submit, formData, handlerUpdateFormState}) => (
        <Row gutter={1}>
          <Col span={24}>
            <Form.Item noStyle name="accept">
              <StyledSwitchContainer>
                <Switch
                  disabled={loadingSubmit}
                  onChange={(accept) => handlerUpdateFormState({accept})}
                />
                <Text>
                  {t('Accept')} <Text color="red">*</Text>
                </Text>
              </StyledSwitchContainer>
            </Form.Item>
          </Col>
          {content && <StyledCol span={24}>{content}</StyledCol>}
          <Col span={24}>
            <AuthButton
              disabled={loadingSubmit || !formData?.accept}
              data-testid="signup-form__button--submit"
              loading={loadingSubmit}
              type="primary"
              onClick={submit}
              block>
              {t('Continue')}
            </AuthButton>
          </Col>
        </Row>
      )}
    </DefaultForm>
  );
}
