import {correctPrice} from '../../services/helpers';
import {
  RewardType,
  ServiceModel,
  EmployeeModel,
  CompanyOrderBonusDTO,
} from '../internal';

export class ServiceParametersFormDTO {
  public uuid: string;
  public price: string;
  public duration: number;
  public reward_value: number;
  public reward_type: RewardType;
  public bonuses: CompanyOrderBonusDTO[];

  constructor(props?: EmployeeModel | ServiceModel) {
    this.uuid = props?.uuid || '';
    this.reward_type =
      props?.employee_service?.reward_type || RewardType.Percentage;
    this.price = correctPrice(
      props instanceof EmployeeModel
        ? Number(props?.employee_service?.price)
        : Number(props?.employee_service?.price) === 0
        ? Number(props?.price)
        : Number(props?.employee_service?.price),
    );
    this.duration =
      (props instanceof EmployeeModel
        ? props?.employee_service?.duration
        : props?.employee_service?.duration || props?.period_amount) || 0;
    this.reward_value = props?.employee_service?.reward_value || 0;
    this.bonuses = props?.employee_service?.bonuses || [];
  }
}
