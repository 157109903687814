import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {useModal} from '../../../components/lib/libV2/hooks';
import {ClientAbonementFormDTO, ClientAbonementModel} from '../../../struture';
import {
  AddButton,
  IAddButtonProps,
  SuspenseEmpty,
} from '../../../components/lib/DataDisplay';

const ClientAbonementSideWindowLazy = React.lazy(
  () => import('../Show/ClientAbonementSideWindow'),
);

export interface IClientAbonementCreateButtonProps extends IAddButtonProps {
  onSuccess: (
    value: ClientAbonementFormDTO,
  ) => Promise<ClientAbonementModel | void>;
  onCancel?: () => void;
  abonement: ClientAbonementFormDTO;
  disabledField?: 'clients' | 'abonements' | 'group';
}

export default function ClientClientAbonementCreateButton({
  loading = false,
  onSuccess,
  onCancel,
  abonement,
  disabledField,
  ...rest
}: IClientAbonementCreateButtonProps) {
  const {t} = useTranslation();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <AddButton
        tooltipTitle={t('Create client abonement')}
        onClick={handleOnInit}
        loading={loading}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <ClientAbonementSideWindowLazy
          disabledField={disabledField}
          abonement={abonement}
          loading={loading as boolean}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          visible={visible}
        />
      </Suspense>
    </>
  );
}
