import {Dispatch} from 'redux';
import {REWARD_LIST} from '../constants';
import {RewardListModel, RewardModel, RewardStatsDTO} from '../../struture';
import {ApiError} from '../../services/types';

export interface ISetRewardListParam {
  type: REWARD_LIST;
  rewardList: RewardListModel;
  employeeUuid: string;
  keywords?: string;
  total?: number;
  page?: number;
  stats?: RewardStatsDTO;
}

export interface IAddRewardToListParam {
  type: REWARD_LIST;
  reward: RewardModel;
}

export interface IUpdateRewardFromListParam {
  type: REWARD_LIST;
  reward: RewardModel;
}

export interface IUpdateRewardStatsFromListParam {
  type: REWARD_LIST;
  stats: Partial<RewardStatsDTO>;
}

export interface IDeleteRewardFromListParam {
  type: REWARD_LIST;
  rewardUuid: string;
}

export interface IErrorRewardListParam {
  type: REWARD_LIST;
  error: ApiError | undefined;
}

export function setRewardList(
  rewardList: RewardListModel,
  employeeUuid: string,
  keywords?: string,
  total?: number,
  page?: number,
  stats?: RewardStatsDTO,
): (dispatch: Dispatch) => Promise<ISetRewardListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: REWARD_LIST.SET_REWARD_LIST,
      rewardList,
      keywords,
      total,
      page,
      stats,
      employeeUuid,
    });
  };
}

export function loadMoreRewardList(
  rewardList: RewardListModel,
  employeeUuid: string,
): (dispatch: Dispatch) => Promise<ISetRewardListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: REWARD_LIST.LOAD_MORE_REWARD_LIST,
      rewardList,
      employeeUuid,
    });
  };
}

export function addRewardToList(
  reward: RewardModel,
  employeeUuid: string,
): (dispatch: Dispatch) => Promise<IAddRewardToListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: REWARD_LIST.ADD_REWARD,
      reward,
      employeeUuid,
    });
  };
}

export function updateRewardFromList(
  reward: RewardModel,
  employeeUuid: string,
): (dispatch: Dispatch) => Promise<IUpdateRewardFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: REWARD_LIST.UPDATE_REWARD,
      reward,
      employeeUuid,
    });
  };
}

export function updateRewardStatsFromList(
  stats: Partial<RewardStatsDTO>,
  employeeUuid: string,
): (dispatch: Dispatch) => Promise<IUpdateRewardStatsFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: REWARD_LIST.UPDATE_STATS,
      stats,
      employeeUuid,
    });
  };
}

export function deleteRewardFromList(
  rewardUuid: string,
  employeeUuid: string,
): (dispatch: Dispatch) => Promise<IDeleteRewardFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: REWARD_LIST.DELETE_REWARD,
      rewardUuid,
      employeeUuid,
    });
  };
}

export function errorRewardInList(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorRewardListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: REWARD_LIST.ERROR_REWARD_LIST,
      error,
    });
  };
}
