import {reduce} from '../../services/helpers';

export interface ILocalizationDTOProps {
  timezone: string;
  region_code: string;
  language_code: string;
  currency_symbol: string;
}

export class LocalizationDTO {
  public timezone: string;
  public region_code: string;
  public language_code: string;
  public currency_symbol: string;

  constructor(locale: ILocalizationDTOProps | LocalizationDTO) {
    this.timezone = locale?.timezone || '';
    this.region_code = locale?.region_code || '';
    this.language_code = locale?.language_code || '';
    this.currency_symbol = locale?.currency_symbol || '';
  }

  get notEmptyFields(): Partial<ILocalizationDTOProps> {
    const notEmpty = reduce(
      (acc, [key, value]) => {
        acc = value !== '' ? {...acc, [key]: value} : acc;

        return acc;
      },
      {},
      Object.entries(this),
    );

    return notEmpty;
  }
}
