import {Record, List} from 'immutable';
import {ListModel, ListProps} from './List';
import Group, {GroupProps} from './Group';

export interface GroupListProps extends ListProps {
  groups: GroupProps[];
}

export default class GroupList extends Record({
  groups: List(),
  ...ListModel.toJS(),
}) {
  /**
   * @constructor
   * @param {{clients:[]}} param0
   */
  constructor({groups = [], ...props}: GroupListProps) {
    const options: any = {};

    if (Array.isArray(groups)) {
      options.groups = List(groups.map((group) => new Group(group)));
    }

    super({
      ...props,
      ...options,
    });
  }
}
