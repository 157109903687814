import CryptoJS from 'crypto-js';
import {cryptoSecret} from '../const';

export const encryptObject = <T>(data: T): string =>
  CryptoJS.AES.encrypt(JSON.stringify(data), cryptoSecret).toString();

export const decryptObject = <T>(encryptObject: string): T => {
  const bytes = CryptoJS.AES.decrypt(encryptObject, cryptoSecret);

  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};
