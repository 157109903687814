import {Record, List} from 'immutable';
import {IListProps} from './List';
import {PriceMarginDTO, PriceMarginModel} from '../internal';
import {WithoutRecursion} from '../type';

export interface IPriceMarginListModelProps extends IListProps {
  prices: PriceMarginDTO[];
  keywords?: string;
  page?: number;
}

export interface IPriceMarginListModelReturnType extends IListProps {
  prices: List<PriceMarginModel>;
  keywords?: string;
  page?: number;
}

export class PriceMarginListModel extends Record<IPriceMarginListModelReturnType>(
  {
    prices: List(),
    total: 0,
    keywords: '',
    page: 1,
  },
) {
  constructor(
    {
      prices = [],
      ...props
    }: IPriceMarginListModelProps = {} as IPriceMarginListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IPriceMarginListModelReturnType = {
      ...props,
      prices: List(
        prices.map((price) => new PriceMarginModel(price, withoutRecursion)),
      ),
    };

    super(options);
  }
}
