import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {PriceMarginModel, ProductModel} from '../struture';
import {List} from 'immutable';
import {
  IUseStatePriceMarginListReturnType,
  useStoredPriceMarginList,
} from './useStoredPriceMarginList';
import useStoredCompanies from './useStoredCompanies';
import {correctPrice} from '../services/helpers';

export interface IUseDefaultPriceMarginProps {
  instance?: any;
  loadOnInit?: boolean;
  fieldName?: string;
}

export interface IUseDefaultPriceMarginReturnType
  extends IUseStatePriceMarginListReturnType {
  defaultPriceMargin: PriceMarginModel | null;
  setDefaultPriceMargin: React.Dispatch<
    SetStateAction<PriceMarginModel | null>
  >;
  defaultPriceMarginRef: React.MutableRefObject<PriceMarginModel | null>;
  getProductPriceMargin: (
    product: ProductModel,
    priceMargin?: PriceMarginModel | null,
  ) => string;
}

export function useDefaultPriceMargin({
  instance,
  loadOnInit,
  fieldName = 'doc_margin_uuid',
}: IUseDefaultPriceMarginProps): IUseDefaultPriceMarginReturnType {
  const {defaultCompanyUuid} = useStoredCompanies();

  const [defaultPriceMargin, setDefaultPriceMargin] =
    useState<PriceMarginModel | null>(null);

  const defaultPriceMarginRef = useRef<PriceMarginModel | null>(null);

  const {
    priceMarginList,
    loading: priceMarginListLoading,
    ...rest
  } = useStoredPriceMarginList({
    companyUuid: defaultCompanyUuid,
    loadOnInit,
  });

  const getProductPriceMargin = useCallback(
    (
      product: ProductModel,
      priceMargin: PriceMarginModel | null = defaultPriceMargin,
    ) => {
      if (!priceMargin) {
        return correctPrice(product?.product_prices[0]?.price_value || 0);
      }

      const price = product?.product_prices?.find(
        ({price_uuid}) => price_uuid === priceMargin?.uuid,
      );

      return correctPrice(price?.price_value || 0);
    },
    [defaultPriceMargin],
  );

  useEffect(() => {
    if (List.isList(priceMarginList) && !priceMarginListLoading) {
      const defaultPriceMargin = priceMarginList?.find(
        ({price_is_default}) => price_is_default,
      );

      if (defaultPriceMargin) {
        setDefaultPriceMargin(defaultPriceMargin);

        if (instance) {
          instance.setFieldValue(fieldName, defaultPriceMargin?.uuid);
        }
      } else {
        const first = priceMarginList?.first();
        if (first) {
          setDefaultPriceMargin(first);

          if (instance) {
            instance.setFieldValue(fieldName, first?.uuid);
          }
        }
      }
    }
  }, [fieldName, instance, priceMarginList, priceMarginListLoading]);

  useEffect(() => {
    if (defaultPriceMargin) {
    }
    defaultPriceMarginRef.current = defaultPriceMargin;
  }, [defaultPriceMargin]);

  return {
    ...rest,
    priceMarginList,
    loading: priceMarginListLoading,
    defaultPriceMargin,
    setDefaultPriceMargin,
    getProductPriceMargin,
    defaultPriceMarginRef,
  };
}
