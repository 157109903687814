import * as React from 'react';
import {List} from 'immutable';
import {
  useStatusList,
  IUseStatusListProps,
  IUseStatusListReturnType,
} from './useStatusList';
import {StatusModel} from '../struture';
import {deleteStatus} from '../services/api/status';
import {
  setStatusList as storeSetStatusList,
  deleteStatusFromList as storeDeleteStatus,
  loadMoreStatusList as storeLoadMoreStatusList,
} from '../store/actions';
import {useSelector, useDispatch} from 'react-redux';
import {RootState} from '../store/reducers';
import {REDUX_STATUS} from '../services/types';
import {head, eq} from '../services/helpers';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../contex';

export interface IUseStateStatusListProps extends IUseStatusListProps {
  allowedStatusesTo?: string[];
  allowedStatusesFrom?: string[];
}

export interface IStatusSearchProps {
  keywords?: string;
  showLoading?: boolean;
  limit?: number;
  page?: number;
  category?: string;
}

export interface IUseStateStatusListReturnType
  extends Omit<IUseStatusListReturnType, 'entityList'> {
  statusList: List<StatusModel> | null;
  handleDeleteStatuses: (ids: string[]) => Promise<void>;
  handleSearchStatuses: (value: IStatusSearchProps) => Promise<void>;
  handleLoadMoreStatuses: () => Promise<void>;
  handleRefreshStatuses: (
    value: Partial<IUseStatusListProps> & {page: number},
  ) => Promise<void>;
  handleRefreshFormStatuses: (
    value: Partial<IUseStatusListProps> & {page: number},
  ) => Promise<List<StatusModel> | void>;
  status: REDUX_STATUS;
  loadingMore: boolean;
  page: number;
  allowedStatusesTo: List<StatusModel> | null;
  allowedStatusesFrom: List<StatusModel> | null;
}

export function useStoredStatusList(
  {
    companyUuid,
    loadOnInit = true,
    allowedStatusesTo: listAllowedStatusesTo,
    allowedStatusesFrom: listAllowedStatusesFrom,
    ...rest
  }: IUseStateStatusListProps = {} as IUseStateStatusListProps,
): IUseStateStatusListReturnType {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const [loadingMore, setLoadingMore] = useState(false);
  const [allowedStatusesTo, setAllowedStatusesTo] =
    useState<List<StatusModel> | null>(null);

  const [allowedStatusesFrom, setAllowedStatusesFrom] =
    useState<List<StatusModel> | null>(null);

  const {
    status: storedStatusListStatus,
    loading: storedStatusListLoading,
    statusList,
    total: storeTotal,
    keywords: storeKeywords,
    page: storedPage,
    category: storeCategory,
    ...storedStatusListParams
  } = useSelector(({statusList}: RootState) => statusList);

  const dispatch = useDispatch<any>();

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: statusListLoading,
    total,
    ...statusesParams
  } = useStatusList({
    companyUuid,
    loadOnInit: loadOnInit || storedStatusListStatus !== REDUX_STATUS.SUCCEEDED,
    ...rest,
  });

  useEffect(() => {
    if (
      List.isList(entityList) &&
      storedStatusListStatus !== REDUX_STATUS.SUCCEEDED
    ) {
      dispatch(storeSetStatusList(entityList, '', total));
    }
  }, [dispatch, entityList, storedStatusListStatus, total]);

  const handleDeleteStatuses = React.useCallback(
    async (ids: string[]) => {
      try {
        await deleteStatus(ids);

        setTimeout(() => {
          dispatch(storeDeleteStatus(head(ids)));
        }, 100);
        alert('success', t('Statuses'), t('Statuses delete success'));
      } catch (error: any) {
        alert(
          'error',
          t('Statuses'),
          `${t('An error occurred during delete statuses')}: ${error?.message}`,
        );
      }
    },
    [alert, dispatch, t],
  );

  const handleSearchStatuses = React.useCallback(
    async ({
      limit = 10,
      keywords,
      showLoading,
      category,
      page,
    }: IStatusSearchProps) => {
      setLoadingMore(true);
      const statusListModel = await refresh({
        offset: 0,
        limit,
        keywords,
        showLoading,
        category,
      });

      if (statusListModel && List.isList(statusListModel?.statuses)) {
        dispatch(
          storeSetStatusList(
            statusListModel.statuses,
            keywords,
            statusListModel.total,
            page,
            category,
          ),
        );
      }
      setLoadingMore(false);
    },
    [dispatch, refresh],
  );

  const handleLoadMoreStatuses = useCallback(async () => {
    if (
      List.isList(statusList) &&
      statusList?.size < storeTotal &&
      !loadingMore
    ) {
      setLoadingMore(true);
      const statusListModel = await refresh({
        offset: statusList?.size,
        limit: 10,
        showLoading: false,
      });

      if (statusListModel && List.isList(statusListModel?.statuses)) {
        dispatch(storeLoadMoreStatusList(statusListModel?.statuses));
      }

      setLoadingMore(false);
    }
  }, [dispatch, loadingMore, refresh, statusList, storeTotal]);

  const handleRefreshStatuses = useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = false,
      page,
      category,
    }: Partial<IUseStatusListProps> & {page: number}) => {
      const statusListModel = await refresh({
        offset,
        limit,
        showLoading,
      });

      if (statusListModel && List.isList(statusListModel?.statuses)) {
        dispatch(
          storeSetStatusList(
            statusListModel?.statuses,
            '',
            statusListModel?.total,
            page,
            category,
          ),
        );
      }
    },
    [dispatch, refresh],
  );

  const handleRefreshFormStatuses = useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = false,
    }: Partial<IUseStatusListProps> & {page: number}) => {
      const statusListModel = await refresh({
        offset,
        limit,
        showLoading,
      });

      if (statusListModel && List.isList(statusListModel?.statuses)) {
        return statusListModel?.statuses;
      }
    },
    [refresh],
  );

  useEffect(() => {
    if (listAllowedStatusesTo?.length && List.isList(statusList)) {
      const allowedStatusesToList = statusList.filter(({uuid}) =>
        listAllowedStatusesTo.includes(uuid),
      );

      setAllowedStatusesTo(allowedStatusesToList);
    }
  }, [listAllowedStatusesTo, statusList]);

  useEffect(() => {
    if (listAllowedStatusesFrom?.length && List.isList(statusList)) {
      const allowedStatusesFromList = statusList.filter(({uuid}) =>
        listAllowedStatusesFrom.includes(uuid),
      );

      setAllowedStatusesFrom(allowedStatusesFromList);
    }
  }, [listAllowedStatusesFrom, statusList]);

  return {
    ...statusesParams,
    ...storedStatusListParams,
    statusList,
    total: storeTotal,
    loadingMore,
    offset,
    limit,
    refresh,
    loading:
      (!List.isList(statusList) && statusListLoading) ||
      !storedStatusListLoading,
    handleDeleteStatuses,
    handleSearchStatuses,
    handleLoadMoreStatuses,
    status: storedStatusListStatus,
    handleRefreshStatuses,
    keywords: storeKeywords || '',
    page: storedPage || 1,
    handleRefreshFormStatuses,
    allowedStatusesTo,
    allowedStatusesFrom,
  };
}
