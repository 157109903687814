import React, {useCallback} from 'react';
import {Row} from 'antd';
import {
  getDayOfMonth,
  flattenTimeEvents,
  isThereContent,
} from '../../../services/helpers';
import {useCalendar} from '../../../contex';
import CalendarMonthCol from './CalendarMonthCol';

import './CalendarMonth.less';

export default function CalendarMonth(): JSX.Element {
  const {monthDates, handleChooseDay, loading} = useCalendar();

  const handleChoose = useCallback(
    (date: Date | string): void => {
      handleChooseDay({date});
    },
    [handleChooseDay],
  );

  return (
    <Row className="calendar-month">
      {isThereContent(monthDates) &&
        monthDates.map(({events, date, weekday, isToday}, index) => (
          <CalendarMonthCol
            key={date.toString()}
            events={flattenTimeEvents(events)}
            dateNumber={getDayOfMonth(date)}
            weekday={weekday}
            isToday={isToday}
            date={date}
            index={index}
            handleChooseDay={handleChoose}
            loading={loading}
          />
        ))}
    </Row>
  );
}
