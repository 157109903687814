import * as React from 'react';
import {useEffect} from 'react';
import {
  IUseProductCategoryProps,
  IUseProductCategoryReturnType,
  useProductCategory,
} from './useProductCategory';
import {
  ProductCategoryFormDTO,
  ProductCategoryMapper,
  ProductCategoryModel,
  ProductCategoryType,
} from '../struture';
import {
  createProductCategory,
  editProductCategory,
  setProductCategoryCover,
  setProductCategoryLogo,
} from '../services/api/product';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../store/reducers';
import {REDUX_STATUS} from '../services/types';
import {
  resetProductCategory as storeResetProductCategory,
  setProductCategory as storeSetProductCategory,
  setProductListStats as storeSetProductListStats,
  updateProductCategory as storeUpdateProductCategory,
} from '../store/actions';
import {toStringDate} from '../services/helpers';
import {useStoredProductCategoryList} from './useStoredProductCategoryList';
import {List} from 'immutable';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../contex';
import {
  addProductCategoryToList as storeAddProductCategory,
  updateProductCategoryFromList as storeUpdateProductCategoryFromList,
} from '../store/features/productCategoryListSlice';

export interface IUseStateProductCategoryProps
  extends IUseProductCategoryProps {
  companyUuid: string;
  view?: ProductCategoryType;
}

export interface IUseStateProductCategoryReturnType
  extends Omit<IUseProductCategoryReturnType, 'entity'> {
  category: ProductCategoryModel | null;
  handleUpdateProductCategory: (value: ProductCategoryFormDTO) => Promise<void>;
  handleCreateProductCategory: (value: ProductCategoryFormDTO) => Promise<void>;
  handleRefreshProductCategory: () => Promise<void>;
  handleSetProductCategoryLogo: (logo: File) => Promise<string | void>;
  handleSetProductCategoryCover: (cover: File) => Promise<string | void>;
  handleResetProductCategory: () => void;
  status: REDUX_STATUS;
}

export function useStoredProductCategory({
  companyUuid,
  loadOnInit = true,
  categoryUuid,
  view = ProductCategoryType.LIST,
  ...rest
}: IUseStateProductCategoryProps): IUseStateProductCategoryReturnType {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const [category, setProductCategory] =
    React.useState<ProductCategoryModel | null>(null);
  const [isStarLoading, setIsStartLoading] = React.useState<boolean>(false);

  const {
    status: storedProductCategoryStatus,
    cachedProductCategoryList,
    ...storedProductCategoryParams
  } = useSelector(({productCategory}: RootState) => productCategory);

  const {categoryList} = useStoredProductCategoryList({
    companyUuid,
    loadOnInit: false,
    productCategoryType: view,
  });

  const dispatch = useDispatch<any>();
  const once = React.useRef(false);

  const {entity, refresh, ...categoryParams} = useProductCategory({
    loadOnInit: loadOnInit || isStarLoading,
    categoryUuid,
    ...rest,
  });

  React.useEffect(() => {
    if (categoryUuid) {
      if (cachedProductCategoryList?.size > 0) {
        const service = cachedProductCategoryList.get(categoryUuid);

        if (service) {
          setIsStartLoading(false);
          setProductCategory(service);
        } else {
          setIsStartLoading(true);
        }
      } else if (cachedProductCategoryList?.size === 0 && !category) {
        setIsStartLoading(true);
      }
    }
  }, [cachedProductCategoryList, dispatch, categoryUuid, category]);

  useEffect(() => {
    if (entity && isStarLoading && !once.current) {
      dispatch(storeSetProductCategory(entity));
      setProductCategory(entity);
    }
  }, [dispatch, entity, isStarLoading, storedProductCategoryStatus]);

  const handleUpdateProductCategory = React.useCallback(
    async (value: ProductCategoryFormDTO): Promise<void> => {
      const categoryDTO = await editProductCategory(value, view);

      const categoryModel =
        ProductCategoryMapper.toProductCategoryModel(categoryDTO);

      dispatch(storeUpdateProductCategory(categoryModel));

      dispatch(
        storeUpdateProductCategoryFromList({
          productCategory: categoryModel,
        }),
      );

      setProductCategory(categoryModel);
    },
    [dispatch, view],
  );

  const handleCreateProductCategory = React.useCallback(
    async (value: ProductCategoryFormDTO) => {
      const {category: categoryDTO, stats} = await createProductCategory(
        value,
        companyUuid,
      );

      const categoryModel =
        ProductCategoryMapper.toProductCategoryModel(categoryDTO);

      const updatedModel = categoryModel.set(
        'created_at',
        toStringDate(new Date()),
      );

      if (List.isList(categoryList)) {
        dispatch(
          storeAddProductCategory({
            productCategory: updatedModel,
            stats,
          }),
        );
      }

      dispatch(storeSetProductListStats(stats));
    },
    [categoryList, companyUuid, dispatch],
  );

  const handleResetProductCategory = React.useCallback(() => {
    once.current = true;
    dispatch(storeResetProductCategory());
  }, [dispatch]);

  const handleRefreshProductCategory = React.useCallback(async () => {
    const categoryModel = await refresh({showLoading: false, categoryUuid});

    if (categoryModel) {
      dispatch(storeUpdateProductCategory(categoryModel));

      dispatch(
        storeUpdateProductCategoryFromList({
          productCategory: categoryModel,
        }),
      );
    }
  }, [categoryUuid, dispatch, refresh]);

  const handleSetProductCategoryLogo = React.useCallback(
    async (logo: File): Promise<string | void> => {
      try {
        const categoryDTO = await setProductCategoryLogo(logo, categoryUuid);

        const categoryModel =
          ProductCategoryMapper.toProductCategoryModel(categoryDTO);

        dispatch(storeUpdateProductCategory(categoryModel));

        dispatch(
          storeUpdateProductCategoryFromList({
            productCategory: categoryModel,
          }),
        );

        setProductCategory(categoryModel);
        alert(
          'success',
          t('Materials category'),
          t('Materials category logo set success'),
        );
        return categoryModel?.logo_url;
      } catch (error: any) {
        alert(
          'error',
          t('Materials category'),
          `${t('An error occurred during set materials category logo')}: ${
            error?.message
          }`,
        );
      }
    },
    [categoryUuid, dispatch, alert, t],
  );

  const handleSetProductCategoryCover = React.useCallback(
    async (cover: File): Promise<string | void> => {
      try {
        const categoryDTO = await setProductCategoryCover(cover, categoryUuid);

        const categoryModel =
          ProductCategoryMapper.toProductCategoryModel(categoryDTO);

        dispatch(storeUpdateProductCategory(categoryModel));

        dispatch(
          storeUpdateProductCategoryFromList({
            productCategory: categoryModel,
          }),
        );

        setProductCategory(categoryModel);
        alert(
          'success',
          t('Materials category'),
          t('Materials category cover set success'),
        );
        return categoryModel?.cover_url;
      } catch (error: any) {
        alert(
          'error',
          t('Materials category'),
          `${t('An error occurred during set materials category cover')}: ${
            error?.message
          }`,
        );
      }
    },
    [categoryUuid, dispatch, alert, t],
  );

  return {
    ...categoryParams,
    ...storedProductCategoryParams,
    category,
    loading: !category,
    handleUpdateProductCategory,
    handleCreateProductCategory,
    status: storedProductCategoryStatus,
    handleResetProductCategory,
    refresh,
    handleRefreshProductCategory,
    handleSetProductCategoryLogo,
    handleSetProductCategoryCover,
  };
}
