import {
  CashBoxModel,
  ClientModel,
  EmployeeModel,
  PAYMENT_METHOD,
  PaymentModel,
} from '../internal';
import {correctPrice, DateType, toMoment} from '../../helpers';

export enum PaymentScheduleType {
  Prepayment = 'Prepayment',
  Refunds = 'Refunds',
}

export enum PaymentSchedulePostActionType {
  SetScheduleStatus = 'set_schedule_status',
}

export interface IPaymentSchedulePostAction {
  action: PaymentSchedulePostActionType;
  value: string;
}

export interface IPaymentScheduleAdditionalParams
  extends Partial<IPaymentSchedulePostAction> {
  editMode?: boolean;
  type?: PaymentScheduleType;
  cashier: EmployeeModel;
  payment_post_action?: IPaymentSchedulePostAction | null;
}

export class PaymentScheduleFormDTO {
  public uuid: string;

  public payment_date: DateType;

  public payment_client_uuid: string | ClientModel;

  public payment_manager_uuid: string | EmployeeModel;

  public payment_price: string;

  public payment_cashbox_uuid: string | CashBoxModel;

  public payment_method: PAYMENT_METHOD;

  public payment_comment: string;

  public payment_post_action?: IPaymentSchedulePostAction | null;

  constructor(
    props: PaymentModel,
    {editMode, cashier, payment_post_action}: IPaymentScheduleAdditionalParams,
  ) {
    this.uuid = props?.uuid || '';
    this.payment_date = props?.payment_date
      ? PaymentScheduleFormDTO.toDate(props?.payment_date || '')
      : PaymentScheduleFormDTO.toDate(new Date() as any);
    this.payment_price = correctPrice(props?.payment_sum);
    this.payment_comment = props?.payment_comment || '';
    this.payment_cashbox_uuid = editMode ? props?.company_cashbox || '' : '';
    this.payment_method = props?.payment_method || PAYMENT_METHOD.Cash;
    this.payment_client_uuid =
      this.paymentAgentUuidFactory(props?.payment_agent_object, editMode) || '';
    this.payment_manager_uuid = cashier;
    this.payment_post_action = payment_post_action || null;
  }

  private static toDate(birthday: string): any {
    return birthday ? toMoment(birthday) : '';
  }

  private paymentAgentUuidFactory(
    paymentAgentObject: ClientModel | EmployeeModel | undefined,
    editMode?: boolean,
  ) {
    if (paymentAgentObject instanceof ClientModel) {
      return paymentAgentObject;
    }

    return '';
  }
}
