import * as React from 'react';
import {ApiError, IEntityProps, IListSearchProps} from '../services/types';
import {IntegrationListModel} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {getIntegrationList} from '../services/api/integration';

export interface IUseIntegrationListProps
  extends Partial<IListSearchProps>,
    IEntityProps {}

export interface IUseIntegrationListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: IntegrationListModel | null;
  refresh: (
    value: Partial<IUseIntegrationListProps>,
  ) => Promise<IntegrationListModel | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
}

export function useIntegrationList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
  }: IUseIntegrationListProps = {} as IUseIntegrationListProps,
): IUseIntegrationListReturnType {
  const {cancellablePromise, didCancel} = useCancellablePromise();
  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    handleUpdate,
  } = useStateReducer<Omit<IUseIntegrationListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
    }: Partial<IUseIntegrationListProps> = {}): Promise<IntegrationListModel | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
        });

        const departmentListModel =
          await cancellablePromise<IntegrationListModel>(
            getIntegrationList({
              limit,
              offset,
              keywords,
            }),
          );

        if (!didCancel.current) {
          handleUpdate({
            entityList: departmentListModel,
            total: departmentListModel?.total,
            loading: false,
          });

          return departmentListModel;
        }
      } catch (e) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }
      }
    },
    [
      cancellablePromise,
      didCancel,
      error,
      handleUpdate,
      listKeywords,
      listLimit,
      listOffset,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit) {
      (async () => {
        await refresh({});
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
  };
}
