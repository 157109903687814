import {Map} from 'immutable';
import {APP_STATE, ABONEMENT_GROUP} from '../constants';
import {REDUX_STATUS, ApiError} from '../../services/types';
import {AbonementGroupModel} from '../../struture';

interface IAbonementGroupListState {
  group: AbonementGroupModel | null;
  cachedAbonementGroupList: Map<string, AbonementGroupModel>;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
}

interface SetAction extends Pick<IAbonementGroupListState, 'group'> {
  type: ABONEMENT_GROUP.SET_ABONEMENT_GROUP;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface UpdateAbonementGroup {
  type: ABONEMENT_GROUP.UPDATE_ABONEMENT_GROUP;
  group: AbonementGroupModel;
}

interface LoadingAction {
  type: ABONEMENT_GROUP.LOADING_ABONEMENT_GROUP;
}

interface ResetAction {
  type: ABONEMENT_GROUP.RESET_ABONEMENT_GROUP;
}

interface ErrorAction extends Pick<IAbonementGroupListState, 'error'> {
  type: ABONEMENT_GROUP.ERROR_ABONEMENT_GROUP;
}

interface Handlers {
  [ABONEMENT_GROUP.SET_ABONEMENT_GROUP]: (
    state: IAbonementGroupListState,
    action: SetAction,
  ) => IAbonementGroupListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IAbonementGroupListState,
    action: SetInitialStateAction,
  ) => IAbonementGroupListState;

  [ABONEMENT_GROUP.RESET_ABONEMENT_GROUP]: (
    state: IAbonementGroupListState,
    action: ResetAction,
  ) => IAbonementGroupListState;

  [ABONEMENT_GROUP.UPDATE_ABONEMENT_GROUP]: (
    state: IAbonementGroupListState,
    action: UpdateAbonementGroup,
  ) => IAbonementGroupListState;

  [ABONEMENT_GROUP.ERROR_ABONEMENT_GROUP]: (
    state: IAbonementGroupListState,
    value: ErrorAction,
  ) => IAbonementGroupListState;

  [ABONEMENT_GROUP.LOADING_ABONEMENT_GROUP]: (
    state: IAbonementGroupListState,
    value?: LoadingAction,
  ) => IAbonementGroupListState;
  DEFAULT: (state: IAbonementGroupListState) => IAbonementGroupListState;
}

const initState: IAbonementGroupListState = {
  group: null,
  cachedAbonementGroupList: Map(),
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
};

const handlers: Handlers = {
  [ABONEMENT_GROUP.SET_ABONEMENT_GROUP]: (state, {group}) => ({
    ...state,
    ...{
      group,
      cachedAbonementGroupList: group
        ? state.cachedAbonementGroupList.set(group?.uuid, group)
        : state.cachedAbonementGroupList,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [ABONEMENT_GROUP.RESET_ABONEMENT_GROUP]: (state) => ({
    ...state,
    ...{
      status: REDUX_STATUS.IDLE,
    },
  }),

  [ABONEMENT_GROUP.UPDATE_ABONEMENT_GROUP]: (state, {group}) => ({
    ...state,
    ...{
      group,
      cachedAbonementGroupList: state.cachedAbonementGroupList.update(
        group?.uuid,
        () => group,
      ),
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [ABONEMENT_GROUP.ERROR_ABONEMENT_GROUP]: (
    state: IAbonementGroupListState,
    {error}: ErrorAction,
  ) => ({
    ...state,
    ...{
      error,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [ABONEMENT_GROUP.LOADING_ABONEMENT_GROUP]: (
    state: IAbonementGroupListState,
  ) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IAbonementGroupListState) => state,
};

export default function AbonementGroup(
  state: any = initState,
  action: any,
): IAbonementGroupListState {
  const handler = handlers[action.type as ABONEMENT_GROUP] || handlers.DEFAULT;
  return handler(state, action);
}
