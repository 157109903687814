import {Record} from 'immutable';
import {
  CompanyOrderBonusDTO,
  EmployeeDTO,
  EmployeeMapper,
  EmployeeModel,
  EmployeeServiceDTO,
  RewardType,
} from '../internal';
import {WithoutRecursion} from '../type';

export interface IEmployeeServiceModelReturnType {
  price: string;
  duration: number;
  reward_value: number;
  created_at: string;
  updated_at: string;
  reward_type: RewardType;
  employee?: EmployeeDTO | EmployeeModel;
  bonuses: CompanyOrderBonusDTO[];
}

export class EmployeeServiceModel extends Record<IEmployeeServiceModelReturnType>(
  {
    price: '',
    created_at: '',
    updated_at: '',
    duration: 0,
    reward_type: RewardType.Percentage,
    reward_value: 0,
    bonuses: [],
    employee: undefined,
  },
) {
  constructor(
    props: EmployeeServiceDTO = {} as EmployeeServiceDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IEmployeeServiceModelReturnType = {
      ...props,
      employee: props?.employee
        ? withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
          ? props?.employee
          : EmployeeMapper.toEmployeeModel(props?.employee, [
              WithoutRecursion.employeeService,
              ...withoutRecursion,
            ])
        : undefined,
    };

    super(options);
  }

  get getEmployeeModel(): EmployeeModel {
    return this.employee instanceof EmployeeModel
      ? this.employee
      : EmployeeMapper.toEmployeeModel(this.employee as EmployeeDTO);
  }
}
