import React from 'react';
import {Text} from '../Format';
import {ButtonProps, Tooltip} from 'antd';
import {MdModeEditOutline} from 'react-icons/md';
import {LoadingOutlined} from '@ant-design/icons';
import styled, {useTheme} from 'styled-components';
import {StyledIconButton} from '../Styled/Buttons';

export interface IEditButtonProps extends Omit<ButtonProps, 'title'> {
  title?: string | React.ReactNode;
  tooltipTitle?: string;
}

const StyledTooltip = styled(Tooltip)`
  width: auto;
`;

export function EditButton({
  title,
  tooltipTitle,
  style,
  type = 'text',
  loading,
  disabled,
  ...props
}: IEditButtonProps): JSX.Element {
  const theme: any = useTheme();

  return (
    <StyledTooltip title={tooltipTitle}>
      <StyledIconButton
        type={type}
        $useCustomStyle={type !== 'text'}
        style={style}
        loading={!!(title && loading)}
        disabled={disabled || !!loading}
        {...props}>
        {React.isValidElement(title) ? (
          title
        ) : title ? (
          <Text>{title}</Text>
        ) : loading ? (
          <LoadingOutlined color={theme.colors.success} />
        ) : (
          <MdModeEditOutline
            size={18}
            color={disabled ? theme.colors.disabled : theme.colors.success}
          />
        )}
      </StyledIconButton>
    </StyledTooltip>
  );
}
