import {Record, List} from 'immutable';
import {IListProps} from './List';
import {WorkScheduleDTO, WorkScheduleModel} from '../internal';

export interface IWorkSchedulesListModelProps extends IListProps {
  schedules: WorkScheduleDTO[];
}

export interface IWorkSchedulesListModelReturnType extends IListProps {
  schedules: List<WorkScheduleModel>;
}

/**
 * @class WorkScheduleListModel
 * @classdesc Work schedules list model
 */
export class WorkScheduleListModel extends Record<IWorkSchedulesListModelReturnType>(
  {
    schedules: List(),
    total: 0,
  },
) {
  /**
   * @constructor
   * @param {{schedules:[]}} param0
   */
  constructor(
    {
      schedules = [],
      ...props
    }: IWorkSchedulesListModelProps = {} as IWorkSchedulesListModelProps,
  ) {
    super({
      ...props,
      schedules: List(
        schedules.map((schedule) => new WorkScheduleModel(schedule)),
      ),
    });
  }
}
