import {correctPrice} from '../../services/helpers';

export interface IAggregatedSumDTOProps {
  total_sum?: string;
  total_profit?: string;
  total_discount?: string;
}

export class AggregatedSumDTO {
  public total_sum: string;
  public total_profit: string;
  public total_discount: string;

  constructor(props?: IAggregatedSumDTOProps) {
    this.total_sum = correctPrice(props?.total_sum || 0);
    this.total_profit = correctPrice(props?.total_profit || 0);
    this.total_discount = correctPrice(props?.total_discount || 0);
  }
}
