import {List, Record} from 'immutable';
import {
  SettingsModuleDependencyDTO,
  SettingsModuleDependencyMapper,
  SettingsModuleDependencyModel,
  SettingsModuleStatuses,
  CompanyModuleDTO,
} from '../internal';

export interface ICompanyModuleModelReturnType {
  module: string;
  enabled: SettingsModuleStatuses;
  dependencies:
    | List<SettingsModuleDependencyModel>
    | SettingsModuleDependencyDTO[];
}

export class CompanyModuleModel extends Record<ICompanyModuleModelReturnType>({
  module: '',
  enabled: SettingsModuleStatuses.Disabled,
  dependencies: [] as SettingsModuleDependencyDTO[],
}) {
  constructor(props: CompanyModuleDTO = {} as CompanyModuleDTO) {
    const options: ICompanyModuleModelReturnType = {
      ...props,
      dependencies:
        SettingsModuleDependencyMapper.toSettingsModuleDependencyListModel(
          props?.dependencies,
        )?.dependencies,
    };

    super(options);
  }

  get dependencyListModel(): List<SettingsModuleDependencyModel> {
    return List.isList(this.dependencies)
      ? this.dependencies
      : SettingsModuleDependencyMapper.toSettingsModuleDependencyListModel(
          this.dependencies,
        ).dependencies;
  }
}
