import {Record, List} from 'immutable';
import {IListProps} from './List';
import {EmployeeSalaryDTO, EmployeeSalaryModel} from '../internal';
import {WithoutRecursion} from '../type';

export interface IEmployeeSalaryListModelProps extends IListProps {
  salary: EmployeeSalaryDTO[];
}

export interface IEmployeeSalaryListModelReturnType extends IListProps {
  salary: List<EmployeeSalaryModel>;
  keywords?: string;
  page?: number;
}

export class EmployeeSalaryListModel extends Record<IEmployeeSalaryListModelReturnType>(
  {
    salary: List(),
    total: 0,
    page: 1,
    keywords: '',
  },
) {
  constructor(
    {
      salary = [],
      ...props
    }: IEmployeeSalaryListModelProps = {} as IEmployeeSalaryListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    super({
      ...props,
      salary: List(salary.map((sal) => new EmployeeSalaryModel(sal))),
    });
  }
}
