import React from 'react';
import {Routes, Route} from 'react-router';
import {
  ClientListPage,
  ClientPage,
  ClientCalendarPage,
  ClientOrderListPage,
  ClientAbonementListPage,
  ClientAbonementPage,
  ClientAbonementCalendarPage,
  ClientBalanceOperationListPage,
  ClientFileListPage,
} from '../views/Clients';
import {AppRouteLayout} from '../components/lib/Layout';
import {Routes as RoutesType} from '../services/types';
import {CalendarRoutes} from './CalendarRoutes';
import {PaymentRoutesNavigator} from './PaymentRoutesNavigator';

export function ClientRoutesNavigator() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AppRouteLayout>
            <ClientListPage />
          </AppRouteLayout>
        }
      />
      <Route
        path=":clientId"
        element={
          <AppRouteLayout>
            <ClientPage />
          </AppRouteLayout>
        }
      />

      <Route
        path={`:clientId/${RoutesType.clientBalanceOperations}`}
        element={
          <AppRouteLayout>
            <ClientBalanceOperationListPage />
          </AppRouteLayout>
        }
      />

      <Route
        path={`:clientId/${RoutesType.clientAbonements}`}
        element={
          <AppRouteLayout>
            <ClientAbonementListPage />
          </AppRouteLayout>
        }
      />

      <Route
        path={`:clientId/${RoutesType.clientAbonements}/:abonementId`}
        element={
          <AppRouteLayout>
            <ClientAbonementPage />
          </AppRouteLayout>
        }
      />

      <Route
        path={`:clientId/${RoutesType.clientAbonements}/:abonementId/${RoutesType.schedules}/*`}
        element={
          <CalendarRoutes CalendarComponent={ClientAbonementCalendarPage} />
        }
      />

      <Route
        path={`:clientId/${RoutesType.clientSchedules}`}
        element={
          <AppRouteLayout>
            <ClientOrderListPage />
          </AppRouteLayout>
        }
      />

      <Route
        path={`:clientId/${RoutesType.clientSchedules}/${RoutesType.schedules}/*`}
        element={<CalendarRoutes CalendarComponent={ClientCalendarPage} />}
      />

      <Route
        path={`:clientId/${RoutesType.schedules}/*`}
        element={<CalendarRoutes CalendarComponent={ClientCalendarPage} />}
      />

      <Route
        path={`:clientId/${RoutesType.payments}/*`}
        element={<PaymentRoutesNavigator />}
      />
      <Route
        path={`:clientId/${RoutesType.files}`}
        element={
          <AppRouteLayout>
            <ClientFileListPage />
          </AppRouteLayout>
        }
      />
    </Routes>
  );
}
