import {Record, List} from 'immutable';
import {IListProps} from './List';
import {GroupDTO, GroupModel} from '../internal';
import {WithoutRecursion} from '../type';

export interface IGroupListModelProps extends IListProps {
  groups: GroupDTO[];
}

export interface IGroupListModelReturnType extends IListProps {
  groups: List<GroupModel>;
}

export class GroupListModel extends Record<IGroupListModelReturnType>({
  groups: List(),
  total: 0,
}) {
  constructor(
    {groups = [], ...props}: IGroupListModelProps = {} as IGroupListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IGroupListModelReturnType = {
      ...props,
      groups: List(
        groups.map((group) => new GroupModel(group, withoutRecursion)),
      ),
    };

    super(options);
  }
}
