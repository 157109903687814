import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {useModal} from '../../../components/lib/libV2/hooks';
import {AbonementGroupFormDTO, AbonementGroupModel} from '../../../struture';
import {
  AddButton,
  IAddButtonProps,
  SuspenseEmpty,
} from '../../../components/lib/DataDisplay';

const TariffModelGroupSideWindowLazy = React.lazy(
  () => import('../Show/TariffModelGroupSideWindow'),
);

export interface ITariffModelGroupCreateButtonProps extends IAddButtonProps {
  onSuccess: (
    value: AbonementGroupFormDTO,
  ) => Promise<AbonementGroupModel | void>;
  onCancel?: () => void;
}

export default function TariffModelGroupCreateButton({
  loading = false,
  onSuccess,
  onCancel,
  ...rest
}: ITariffModelGroupCreateButtonProps) {
  const {t} = useTranslation();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <AddButton
        tooltipTitle={t('Create tariff model group')}
        onClick={handleOnInit}
        loading={loading}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <TariffModelGroupSideWindowLazy
          loading={loading as boolean}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          visible={visible}
        />
      </Suspense>
    </>
  );
}
