import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Empty} from 'antd';
import {ListLayout} from '../../../components/lib/Layout';
import {useStateCategoryList, useStopLoading} from '../../../hooks';
import {
  CategoriesDeleteButton,
  CategoryCreateButton,
  CategoryEditButton,
} from '../Buttons';
import {textToUpperCase} from '../../../services/helpers';
import {CategoryModel, CategoryMapper} from '../../../struture';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import {
  Table,
  TableActionCell,
} from '../../../components/lib/libV2/DataDisplay';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '../../../services/types';
import {HeaderLeft} from '../../../components/lib/DataDisplay';
import ListSearchInput from '../../../components/lib/DataDisplay/ListActions/ListSearchInput';
import {useNavigate} from 'react-router';
import {useAcl} from '../../../contex';

/**
 * @desc Category page component
 * */
export default function CategoryListPage(): JSX.Element {
  const navigate = useNavigate();
  const {manage} = useAcl(({company}) => company);
  const {t} = useTranslation();

  const [selectedCategories, setSelectedCategories] = useState<CategoryModel[]>(
    [],
  );

  const {
    categories,
    loading: categoryListLoading,
    error: categoryListError,
    limit,
    total,
    refresh,
    handleCreateCategory,
    handleUpdateCategory,
    handleDeleteCategories,
    handleSearchCategories,
  } = useStateCategoryList();

  const loading = useStopLoading({
    loading: categoryListLoading,
    error: categoryListError,
    message: 'An error occurred during categories loading',
  });

  const rowSelection = {
    selectedRowKeys: selectedCategories.map(({uuid}) => uuid),
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRecords: CategoryModel[],
    ) => {
      setSelectedCategories(selectedRecords);
    },
  };

  const routes = useBreadcrumb([
    {
      path: `/${Routes.categories}`,
      breadcrumbName: 'Categories',
    },
  ]);

  const columns = [
    {
      ellipsis: true,
      title: t('Title'),
      key: 'title',
      render: (category: CategoryModel) => category?.title,
    },
    {
      title: t('Status'),
      key: 'status',
      render: (category: CategoryModel) => category?.status,
    },
    {
      title: t('Actions'),
      key: 'actions',
      align: 'center' as any,
      fixed: 'right' as any,
      render: (category: CategoryModel) => (
        <TableActionCell>
          <CategoryEditButton
            type="text"
            category={CategoryMapper.toCategoryFormDTO(category)}
            data-testid="category-edit-button"
            onSuccess={handleUpdateCategory}
          />
          <CategoriesDeleteButton
            data-testid="company-actions-delete-button"
            categories={[category]}
            onSuccess={handleDeleteCategories}
          />
        </TableActionCell>
      ),
    },
  ];

  const navigateGoBack = useCallback(() => {}, []);

  return (
    <ListLayout
      aclItem={RoutesAcl[Routes.employees]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.categories]))}
      headerRight={
        <>
          {selectedCategories.length ? (
            <CategoriesDeleteButton
              disabled={!manage}
              categories={selectedCategories}
              onSuccess={handleDeleteCategories}
            />
          ) : (
            <CategoryCreateButton
              loading={loading}
              onSuccess={handleCreateCategory}
            />
          )}
        </>
      }
      routes={routes}
      empty={
        categories?.size ? null : (
          <Empty description={t(`There are no categories created.`)}>
            <CategoryCreateButton
              loading={loading}
              type="primary"
              title={t('Create Category')}
              onSuccess={handleCreateCategory}
            />
          </Empty>
        )
      }
      headerExtra={
        <ListSearchInput
          handleSearch={handleSearchCategories}
          tooltipTitle={t('Search categories')}
          label={t('Search categories')}
        />
      }
      loading={loading && !categories?.size}>
      <Table<CategoryModel>
        rowSelection={rowSelection}
        total={total}
        pageSize={limit}
        dataSource={categories}
        onChange={refresh}
        columns={columns}
        loading={loading}
      />
    </ListLayout>
  );
}
