import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {List} from 'immutable';
import {RootState} from '../store/reducers';
import {
  createCompany as createApiCompany,
  deleteCompanies as deleteApiCompany,
  editCompany,
  ISetCompanyImages,
  setCompanyCover as setApiCompanyCover,
  setCompanyLogo as setApiCompanyLogo,
  linkAlphaName,
} from '../services/api/company';
import {
  addCompany as addStoredCompany,
  deleteCompany as deleteStoredCompany,
  deleteDefaultCompany as deleteStoredDefaultCompany,
  setCompanies,
  setDefaultCompany as setStoredDefaultCompany,
  updateCompany as updateStoredCompany,
  updateDefaultCompany as updateStoredDefaultCompany,
  setCompanySettings as storeSetCompanySettings,
} from '../store/actions';
import useLocalStorage from './useLocalStorage';
import {ifElse, joinArr} from '../services/helpers';
import {IInitialState as ICompaniesInitialState} from '../store/reducers/companies';
import {IInitialState as ICompanyInitialState} from '../store/reducers/defaultCompany';
import {editUser} from '../services/api/user';
import {getCompanySettings} from '../services/api/setting';
import {Route} from '../components/lib/General';
import {useCompany} from './useCompany';
import {ApiError, REDUX_STATUS, Routes} from '../services/types';
import {
  CompanyFormDTO,
  CompanyMapper,
  CompanyModel,
  CompanySettingsDTO,
  EmployeeRole,
  ICompanySettingsDTOProps,
  ProfileMapper,
  ProfileModel,
  AlphaNameFormDTO,
} from '../struture';
import {useDropdownAlert} from '../contex/DropdownAlertManager';

export interface StoreCompanies {
  companies: List<CompanyModel>;
  defaultCompany: CompanyModel;
  settings: CompanySettingsDTO;
}

export interface IUseStoredCompaniesProps {
  companyUuid?: string;
}

export interface UseStoredCompaniesReturnType
  extends Omit<ICompaniesInitialState, 'error' | 'loading' | 'status'>,
    Omit<ICompanyInitialState, 'error' | 'loading' | 'status'> {
  localStorageCompanyUuid: string;
  storeCompanies: (value: StoreCompanies) => void;
  setCompanySettings: (settings: CompanySettingsDTO) => void;
  updateCompany: (
    value: CompanyFormDTO & {weekdays: string[]},
  ) => Promise<CompanyModel | void>;
  createCompany: (
    value: Partial<CompanyFormDTO>,
    token?: string,
  ) => Promise<CompanyModel | void>;
  setDefaultCompany: (value: {default_company: string}) => Promise<{
    profile: ProfileModel;
    settings: CompanySettingsDTO;
  } | void>;
  deleteCompany: (uuid: string) => Promise<void>;
  deleteDefaultCompany: (uuid: string) => Promise<void>;
  setCompanyLogo: (value: ISetCompanyImages) => Promise<string | void>;
  storedUpdateCompany: (
    company: CompanyModel,
    settings?: CompanySettingsDTO | null,
  ) => void;
  setCompanyCover: (value: ISetCompanyImages) => Promise<string | void>;
  defaultCompanyBreadcrumb: Route;
  defaultCompanyStatus: REDUX_STATUS;
  companiesStatus: REDUX_STATUS;
  defaultCompanyError: ApiError | null;
  companiesError: ApiError | null;
  defaultCompanyLoading: boolean;
  companiesLoading: boolean;
  handleLinkAlphaName: (value: AlphaNameFormDTO) => Promise<void>;
}

export default function useStoredCompanies(
  {
    companyUuid: searchCompanyUuid,
  }: IUseStoredCompaniesProps = {} as IUseStoredCompaniesProps,
): UseStoredCompaniesReturnType {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const [localStorageCompanyUuid, setLocalStorageCompanyUuid] =
    useLocalStorage('defaultCompanyUuid');

  const {
    defaultCompanyUuid,
    defaultCompany: storedDefaultCompany,
    status: defaultCompanyStatus,
    error: defaultCompanyError,
    loading: defaultCompanyLoading,
    settings: storedSettings,
    ...defaultCompanyParams
  } = useSelector(({defaultCompany}: RootState) => defaultCompany);

  const {
    status: companiesStatus,
    error: companiesError,
    loading: companiesLoading,
    ...companiesParams
  } = useSelector(({companies}: RootState) => companies);

  const {defaultCompany, loading} = useCompany({
    defaultCompanyLoading,
    searchCompanyUuid,
    defaultCompany: storedDefaultCompany,
    defaultCompanyUuid,
  });

  const dispatch = useDispatch<any>();

  const storedCompany = useCallback(
    (defaultCompany: CompanyModel, settings: CompanySettingsDTO): void => {
      dispatch(
        setStoredDefaultCompany(defaultCompany, defaultCompany?.uuid, settings),
      );
      setLocalStorageCompanyUuid(defaultCompany?.uuid);
    },
    [dispatch, setLocalStorageCompanyUuid],
  );

  const storedUpdateCompany = useCallback(
    (company: CompanyModel, settings?: CompanySettingsDTO | null): void => {
      if (company && settings) {
        dispatch(updateStoredDefaultCompany(company, settings));
      }
    },
    [dispatch],
  );

  const storeCompanies = React.useCallback(
    ({companies, defaultCompany, settings}: StoreCompanies): void => {
      dispatch(setCompanies(companies));

      storedCompany(defaultCompany, settings);
    },
    [dispatch, storedCompany],
  );

  const setDefaultCompany = React.useCallback(
    async (value: {
      default_company: string;
    }): Promise<{
      profile: ProfileModel;
      settings: CompanySettingsDTO;
    } | void> => {
      const profileDTO = await editUser(value);

      let settings = CompanyMapper.toCompanySettingsDTO(
        {} as ICompanySettingsDTOProps,
      );

      if (profileDTO?.default_employee?.role === EmployeeRole.Administrator) {
        settings = await getCompanySettings(value?.default_company);
      }

      const profileModel = ProfileMapper.toProfileModel(profileDTO);

      storedCompany(profileModel.default_company as CompanyModel, settings);

      return {profile: profileModel, settings};
    },
    [storedCompany],
  );

  const createCompany = React.useCallback(
    async (value: Partial<CompanyFormDTO>): Promise<CompanyModel | void> => {
      const companyDTO = await createApiCompany(value as any);

      const companyModel = CompanyMapper.toCompanyModel(companyDTO);

      dispatch(addStoredCompany(companyModel));
      // storedCompany(companyModel);

      return companyModel;
    },
    [dispatch],
  );

  const updateCompany = React.useCallback(
    async ({
      weekdays,
      ...rest
    }: CompanyFormDTO & {weekdays: string[]}): Promise<CompanyModel | void> => {
      const work_schedules = weekdays ? joinArr(',')(weekdays) : '';

      const companyDTO = await editCompany({
        ...rest,
        work_schedules,
      } as CompanyFormDTO);

      const companyModel = CompanyMapper.toCompanyModel(companyDTO);

      dispatch(updateStoredCompany(companyModel));

      storedUpdateCompany(companyModel, storedSettings);

      alert('success', t('Company'), t('Company edited success'));

      return companyModel;
    },

    [alert, dispatch, storedUpdateCompany, t, storedSettings],
  );

  const deleteCompany = React.useCallback(
    async (companyUuid: string): Promise<void> => {
      await deleteApiCompany([companyUuid]);

      await dispatch(deleteStoredCompany(companyUuid));
    },
    [dispatch],
  );

  const deleteDefaultCompany = React.useCallback(
    async (companyUuid: string): Promise<void> => {
      await deleteApiCompany([companyUuid]);

      dispatch(deleteStoredDefaultCompany(companyUuid));
      dispatch(deleteStoredCompany(companyUuid));
      setLocalStorageCompanyUuid('');
    },
    [dispatch, setLocalStorageCompanyUuid],
  );

  const setCompanyLogo = React.useCallback(
    async (value: ISetCompanyImages): Promise<string | void> => {
      try {
        const companyDTO = await setApiCompanyLogo(value);

        const companyModel = CompanyMapper.toCompanyModel(companyDTO);

        dispatch(updateStoredCompany(companyModel));

        storedUpdateCompany(companyModel, storedSettings);
        alert('success', t('Company'), t('Company logo set success'));
        return companyModel?.logo_url;
      } catch (error: any) {
        alert(
          'error',
          t('Company'),
          `${t('An error occurred during set company logo')}: ${
            error?.message
          }`,
        );
      }
    },
    [alert, dispatch, storedUpdateCompany, t, storedSettings],
  );

  const setCompanyCover = React.useCallback(
    async (value: ISetCompanyImages): Promise<string | void> => {
      try {
        const companyDTO = await setApiCompanyCover(value);

        const companyModel = CompanyMapper.toCompanyModel(companyDTO);

        dispatch(updateStoredCompany(companyModel));

        storedUpdateCompany(companyModel, storedSettings);

        alert('success', t('Company'), t('Company cover set success'));
        return companyModel?.cover_url;
      } catch (error: any) {
        alert(
          'error',
          t('Company'),
          `${t('An error occurred during set company cover')}: ${
            error?.message
          }`,
        );
      }
    },
    [alert, dispatch, storedUpdateCompany, t, storedSettings],
  );

  const setCompanySettings = React.useCallback(
    (settings: CompanySettingsDTO) => {
      dispatch(storeSetCompanySettings(settings));
    },
    [dispatch],
  );

  const defaultCompanyBreadcrumb = {
    path: `/${Routes.app}/companies/${ifElse(
      !!defaultCompany?.uuid,
      defaultCompany?.uuid,
      '',
    )}`,
    breadcrumbName: ifElse(
      !!defaultCompany?.title,
      defaultCompany?.title,
      t('Company'),
    ),
  };

  const handleLinkAlphaName = React.useCallback(
    async (value: AlphaNameFormDTO) => {
      const {alfa_name_order, settings} = await linkAlphaName(
        value,
        defaultCompanyUuid,
      );

      setCompanySettings({alfa_name_order, settings});

      window.open(alfa_name_order?.order_checkout_url, '_blank');
    },
    [defaultCompanyUuid, setCompanySettings],
  );

  return {
    ...defaultCompanyParams,
    ...companiesParams,
    defaultCompany,
    defaultCompanyUuid,
    localStorageCompanyUuid,
    storeCompanies,
    updateCompany,
    deleteCompany,
    deleteDefaultCompany,
    setDefaultCompany,
    createCompany,
    setCompanyLogo,
    setCompanyCover,
    defaultCompanyBreadcrumb,
    companiesStatus,
    defaultCompanyStatus,
    companiesError,
    defaultCompanyError,
    companiesLoading,
    defaultCompanyLoading: loading,
    settings: storedSettings,
    storedUpdateCompany,
    setCompanySettings,
    handleLinkAlphaName,
  };
}
