import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Col, Form, Input, Row} from 'antd';
import {useDefaultForm} from '../../../contex';
import {useTranslation} from 'react-i18next';
import {IUseStateEmployeeListReturnType} from '../../../hooks';
import {SearchSelect} from '../../../components/lib/DataDisplay';
import {
  checkWorkSchedule,
  curry,
  findItemIndex,
  head,
  ifElse,
  isListToArray,
  joinArr,
  modifyWeekdays,
  split,
} from '../../../services/helpers';
import {IOnChangeDaysOfWeekSchedule, WorkScheduleLabel} from '../../Company';
import {WeekdayTimePickerForm} from '../../../components/lib/General';
import {StyledDescription} from '../../../components/lib/Styled';

export interface ITariffModelGroupFieldsProps
  extends Pick<
    IUseStateEmployeeListReturnType,
    'employees' | 'handleSearchEmployees'
  > {
  employeesLoading: boolean;
}

export function TariffModelGroupFields({
  employees,
  employeesLoading,
  handleSearchEmployees,
}: ITariffModelGroupFieldsProps): JSX.Element {
  const {t} = useTranslation();
  const {loadingSubmit, handlerUpdateFormState, formData} = useDefaultForm();

  const [isFixedRange, setIsFixedRange] = useState(
    checkWorkSchedule(formData?.schedule ? formData?.schedule : ''),
  );

  const fixedRange = useRef<any>(
    ifElse<[boolean, string[], string[]], string>(
      checkWorkSchedule(formData?.schedule || ''),
      split(',', formData?.schedule || ''),
      [],
    ),
  );

  const weekRange = useRef(
    ifElse(
      checkWorkSchedule(formData?.schedule || ''),
      [],
      split(',', formData?.schedule || ''),
    ),
  );

  const handleChangeDateRangeView = useCallback((): void => {
    setIsFixedRange((prevState) => {
      return !prevState;
    });
  }, []);

  const updateWeekRange = useCallback(
    ({
      time,
      handlerUpdateFormState,
    }: Pick<IOnChangeDaysOfWeekSchedule, 'time'> & {
      handlerUpdateFormState: any;
    }): void => {
      const updatedWeekRange = [joinArr('-', time as string[])];

      fixedRange.current = head(updatedWeekRange) ? updatedWeekRange : [];
      handlerUpdateFormState({
        weekdays: updatedWeekRange,
        schedule:
          Array.isArray(updatedWeekRange) && updatedWeekRange.length
            ? updatedWeekRange.join(',')
            : '',
      });
    },
    [fixedRange],
  );

  const updateCustomRange = useCallback(
    ({
      name,
      time,
      handlerUpdateFormState,
      fieldValue,
      ...rest
    }: IOnChangeDaysOfWeekSchedule & {
      handlerUpdateFormState: any;
    }): void => {
      const weekdays = Array.isArray(fieldValue) ? fieldValue : [];
      const index = findItemIndex(name, weekdays);

      const updatedWeekRange = modifyWeekdays({
        weekdays,
        index,
        name,
        time: Array.isArray(time) ? joinArr('-', time as string[]) : '',
        ...rest,
      });

      weekRange.current = updatedWeekRange;

      handlerUpdateFormState({
        weekdays: updatedWeekRange,
        schedule:
          Array.isArray(updatedWeekRange) && updatedWeekRange.length
            ? updatedWeekRange.join(',')
            : '',
      });
    },
    [weekRange],
  );

  const onChangeDaysOfWeek = curry<any, any>(
    ({handlerUpdateFormState}, value: IOnChangeDaysOfWeekSchedule) => {
      if (isFixedRange) {
        updateWeekRange({time: value?.time, handlerUpdateFormState});
      }

      if (!isFixedRange) {
        updateCustomRange({...value, handlerUpdateFormState});
      }
    },
  );

  useEffect(() => {
    if (isFixedRange) {
      handlerUpdateFormState({
        weekdays: fixedRange.current,
        schedule: fixedRange.current ? fixedRange.current.join(',') : '',
      });
    } else {
      handlerUpdateFormState({
        weekdays: weekRange.current,
        schedule: weekRange.current ? weekRange.current.join(',') : '',
      });
    }
  }, [handlerUpdateFormState, isFixedRange]);

  return (
    <Row gutter={12}>
      <Col span={24}>
        <Form.Item
          label={t('Title')}
          name="title"
          rules={[
            {
              required: true,
              message: t('Title must be specified.'),
            },
          ]}
          extra={
            <StyledDescription style={{whiteSpace: 'unset'}}>
              {t('The name of the tariff model group')}
            </StyledDescription>
          }>
          <Input
            data-testid="service-input-title"
            disabled={loadingSubmit}
            placeholder={t('Enter the title')}
            onChange={(e) => handlerUpdateFormState({title: e?.target?.value})}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          tooltip={t('Manager')}
          label={t('Manager')}
          name="manager_uuid"
          extra={
            <StyledDescription style={{whiteSpace: 'unset'}}>
              {t('The default manager to serve the group')}
            </StyledDescription>
          }>
          <SearchSelect
            selectIfOnFirst={false}
            selectFirst={false}
            name="manager_uuid"
            getOptionValueTitle="fullName"
            placeholder={t('Select an employee')}
            disable={loadingSubmit || employeesLoading}
            data={isListToArray(employees as any)}
            onChange={(manager_uuid) => handlerUpdateFormState({manager_uuid})}
            onSearch={(keywords) =>
              handleSearchEmployees({
                keywords,
                showLoading: false,
                limit: 100,
              })
            }
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          tooltip={t('Class schedule')}
          className="company-form__work-schedule"
          label={
            <WorkScheduleLabel
              label={'Class schedule'}
              disabled={loadingSubmit}
              isFixedRange={isFixedRange}
              handleChangeView={handleChangeDateRangeView}
            />
          }
          name="weekdays">
          <WeekdayTimePickerForm
            fixedTitle={t('Mon-Sun')}
            checkboxDisable={false}
            isRangePicker
            isFixedRange={isFixedRange}
            editMode={true}
            disabled={loadingSubmit}
            onChange={onChangeDaysOfWeek({
              handlerUpdateFormState,
            })}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item label={t('Note')} name="comment">
          <Input.TextArea
            allowClear
            autoSize={{minRows: 2, maxRows: 5}}
            disabled={loadingSubmit}
            placeholder={t('Enter your note')}
            onChange={(e) => handlerUpdateFormState({comment: e.target.value})}
          />
        </Form.Item>
      </Col>
    </Row>
  );
}
