import React, {useEffect, useRef} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import Lottie from 'lottie-react';
import {useStoredAuthData} from '../../../hooks';
import {CompanyStatuses, ProfileMapper} from '../../../struture';
import {getProfile} from '../../../services/api/user';
import {getCompanyById} from '../../../services/api/company';
import {getCompanySettings} from '../../../services/api/setting';
import {AuthLayout, FullWidthSpace, HeaderText} from '../../Authorization';
import WaitingCompanyCreate from '../../assets/lottie/wait_company_create.json';
import {Routes} from '../../../services/types';
import {useLocation, useNavigate} from 'react-router';

export default withTranslation()(WaitingForCompanyCreatePage);

export interface IWaitingForCompanyCreatePageProps extends WithTranslation {}

function WaitingForCompanyCreatePage({
  t,
}: IWaitingForCompanyCreatePageProps): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  const interval = useRef<NodeJS.Timeout | null>(null);
  const {updateProfile} = useStoredAuthData();

  useEffect(() => {
    if (location?.state?.companyUuid) {
      interval.current = setInterval(async () => {
        const company = await getCompanyById(location?.state?.companyUuid);

        if (company?.status === CompanyStatuses.Active) {
          if (interval.current) {
            clearInterval(interval.current);
          }
          const profileDTO = await getProfile();
          const settings = await getCompanySettings(
            profileDTO?.default_company?.uuid || '',
          );
          const profileModel = ProfileMapper.toProfileModel(profileDTO);

          if (profileDTO) {
            updateProfile(profileModel, settings);
          }

          navigate(
            location?.state?.from || `/${Routes.app}/${Routes.dashboard}`,
          );
        }
      }, 1000);
    }
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [
    location?.state?.companyUuid,
    location?.state?.from,
    navigate,
    updateProfile,
  ]);

  return (
    <AuthLayout>
      <FullWidthSpace direction="vertical" size="middle">
        <HeaderText title={t('Company setup process')} />
        <Lottie
          // autoPlay
          // style={{
          //   width: 1000, //SCREEN_WIDTH - 20,
          //   marginBottom: 20,
          // }}
          // source={require('../../../../assets/lottie/waiting-in-a-queue.json')}
          animationData={WaitingCompanyCreate}
        />
      </FullWidthSpace>
    </AuthLayout>
  );
}
