import {Record, List} from 'immutable';
import {ListModel, ListProps} from './List';
import Attendance, {IAttendanceProps} from './Attendance';

export interface IAttendancesListProps extends ListProps {
  attendances: IAttendanceProps[];
  total: number;
}

export interface IAttendancesListReturnType extends ListProps {
  attendances: List<Attendance>;
  total: number;
}

/**
 * @class AttendancesList
 * @desc Attendances list model
 * @extends {Record}
 * @property {List} attendances - list of attendances
 * @property {number} total - total amount of records on backend
 */
export default class AttendancesList extends Record<IAttendancesListReturnType>(
  {
    attendances: List(),
    ...ListModel.toJS(),
  } as any,
) {
  /**
   * @constructor
   * @param {{attendances:[]}} param0
   */
  constructor({attendances = [], ...props}: IAttendancesListProps) {
    super({
      attendances: List(
        attendances.map((attendance) => new Attendance(attendance)),
      ),
      total: attendances ? attendances.length : 0,
      ...props,
    });
  }
}
