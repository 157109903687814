import React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {
  ActionButton,
  IActionButtonProps,
} from '../../../components/lib/General';

export default withTranslation()(EmployeeServicesButton);

export interface IEmployeeServicesAddButtonProps
  extends WithTranslation,
    IActionButtonProps {
  toolTipTitle?: string;
}

function EmployeeServicesButton({
  t,
  children,
  toolTipTitle,
  ...rest
}: IEmployeeServicesAddButtonProps): JSX.Element {
  const {reportNS, tReady, defaultNS, i18nOptions, ...restProps}: any = rest;

  return (
    <ActionButton
      type="primary"
      tooltip={toolTipTitle || t('Save')}
      data-testid="employee-service__button--default"
      {...restProps}>
      {children || t('Employee service button')}
    </ActionButton>
  );
}
