import ukrainian from './ukrainian';
import {useTranslation} from 'react-i18next';
import {useKeyboard} from '../../../../contex';
import RNKeyboard, {KeyboardReactInterface} from 'react-simple-keyboard';

import React, {
  forwardRef,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import english, {
  keyboardControlPad,
  keyboardArrows,
  keyboardNumPad,
  keyboardNumPadEnd,
  mappedKeyboardKey,
} from './english';

import 'react-simple-keyboard/build/css/index.css';
import './keyboard.less';

export interface KeyboardProps {}

const LANGUAGE_CHANGE_KEYS = ['{metaleft}'];

const SHIFT_KEYS = ['{shiftleft}', '{shiftright}'];
const UPPER_CASE_KEYS = ['{shift}', '{capslock}'];

export const Keyboard = forwardRef(function Keyboard(
  {}: KeyboardProps,
  ref: any,
): React.JSX.Element {
  const [layoutName, setLayoutName] = useState('default');
  const [language, setLanguage] = useState(english);
  const {i18n} = useTranslation();

  const {
    keyboardRef,
    inputRef,
    keyboardArrowsRef,
    keyboardControlPadRef,
    keyboardNumPadEndRef,
    keyboardNumPadRef,
    keywords,
  } = useKeyboard();

  const handleShift = useCallback(() => {
    setLayoutName((prevState) =>
      prevState === 'default' ? 'shift' : 'default',
    );
  }, []);

  const isShiftPress = useRef(false);

  const handleChangeLanguage = useCallback(() => {
    setLanguage((prevState) =>
      prevState.name === 'english' ? ukrainian : english,
    );
  }, []);

  const handleUpdateInput = useCallback(
    (value: string) => {
      const input = inputRef.current;

      const inputEvent = new KeyboardEvent('input', {
        bubbles: true,
        cancelable: true,
        key: value,
      });

      if (input instanceof HTMLElement) {
        input.dispatchEvent(inputEvent);
      }
    },
    [inputRef],
  );

  const handleChange = useCallback(
    (
      value: string,
      keyboardRef: MutableRefObject<KeyboardReactInterface | null>,
    ) => {
      keywords.current = keywords.current + value;
      keyboardRef.current?.setInput('');

      handleUpdateInput(keywords.current);
    },
    [handleUpdateInput, keywords],
  );

  const onKeyPress = useCallback(
    (button: string) => {
      if (button === '{backspace}') {
        keywords.current = keywords.current.slice(0, -1);
        handleUpdateInput(keywords.current);
      }

      const input = inputRef.current;

      const keyUpEvent = new KeyboardEvent('keyup', {
        bubbles: true,
        cancelable: true,
        key: (mappedKeyboardKey as any)[button] || button,
      });

      if (input instanceof HTMLElement) {
        input.dispatchEvent(keyUpEvent);
      }

      if (isShiftPress.current && layoutName === 'shift') {
        handleShift();
        isShiftPress.current = false;
        return;
      }

      if (
        SHIFT_KEYS.includes(button) &&
        !isShiftPress.current &&
        layoutName === 'default'
      ) {
        handleShift();
        isShiftPress.current = true;
      }

      if (UPPER_CASE_KEYS.includes(button)) handleShift();
      if (LANGUAGE_CHANGE_KEYS.includes(button)) handleChangeLanguage();
    },
    [
      handleChangeLanguage,
      handleShift,
      handleUpdateInput,
      inputRef,
      keywords,
      layoutName,
    ],
  );

  const props = {
    onKeyPress,
    syncInstanceInputs: true,
    mergeDisplay: true,
  };

  useEffect(() => {
    if ((i18n as any)?.language === 'ua' || (i18n as any)?.language === 'uk') {
      setLanguage(ukrainian);
    } else {
      setLanguage(english);
    }
  }, [i18n]);

  return (
    <div className="keyboardContainer">
      <RNKeyboard
        keyboardRef={(ref) => (keyboardRef.current = ref)}
        display={language.display}
        layout={language.layout}
        layoutName={layoutName}
        onChange={(value) => handleChange(value, keyboardRef)}
        {...props}
      />
      <div className="controlArrows">
        <RNKeyboard
          keyboardRef={(ref) => (keyboardControlPadRef.current = ref)}
          layout={keyboardControlPad.layout}
          onChange={(value) => handleChange(value, keyboardControlPadRef)}
          {...props}
        />
        <RNKeyboard
          keyboardRef={(ref) => (keyboardArrowsRef.current = ref)}
          layout={keyboardArrows.layout}
          onChange={(value) => handleChange(value, keyboardArrowsRef)}
          {...props}
        />
      </div>
      <div className="numPad">
        <RNKeyboard
          keyboardRef={(ref) => (keyboardNumPadRef.current = ref)}
          layout={keyboardNumPad.layout}
          onChange={(value) => handleChange(value, keyboardNumPadRef)}
          {...props}
        />
        <RNKeyboard
          keyboardRef={(ref) => (keyboardNumPadEndRef.current = ref)}
          theme="simple-keyboard hg-theme-default hg-layout-default num-pad-end"
          layout={keyboardNumPadEnd.layout}
          onChange={(value) => handleChange(value, keyboardNumPadEndRef)}
          {...props}
        />
      </div>
    </div>
  );
});
