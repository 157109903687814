import {Record} from 'immutable';
import {MomentDate} from '../../helpers';
import {SettingDTO} from '../internal';

export interface ICompanySettingReturnType {
  uuid: string;
  created_at: string;
  updated_at: string;
  setting_key: string;
  setting_value: string;
}

/**
 * @class Client
 * @desc Клієнт
 */
export class SettingModel extends Record<ICompanySettingReturnType>({
  uuid: '',
  created_at: MomentDate.format(MomentDate.currentDate()),
  updated_at: MomentDate.format(MomentDate.currentDate()),
  setting_key: '',
  setting_value: '',
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(props: SettingDTO = {} as SettingDTO) {
    super(props);
  }
}
