import * as React from 'react';
import {ILiteralObj, WithOptional} from '../../../../services/types';
import {isFunction, isThereContent} from '@sportix/sportix-common-modules';

export enum STATE_REDUCER_ACTIONS {
  UPDATE = 'update',
  DEFAULT = 'default',
}

export interface UpdateStateReducerAction {
  type: STATE_REDUCER_ACTIONS.UPDATE;
  update: ((value: any) => any) | ILiteralObj;
}

export type StateReducerActions = UpdateStateReducerAction;

interface IInitialSate extends ILiteralObj {
  error: null | any;
  loading: boolean;
}

export const handlers = {
  [STATE_REDUCER_ACTIONS.UPDATE]: <T>(
    state: T,
    {type, update}: StateReducerActions,
  ): T => {
    if (isFunction(update)) {
      return {
        ...state,
        ...update(state),
      };
    }

    if (isThereContent(update)) {
      return {
        ...state,
        ...update,
      };
    }

    return state;
  },
  [STATE_REDUCER_ACTIONS.DEFAULT]: <T>(state: T): T => state,
};

export type UseStateReducerReturnType<T> = T & {
  handleUpdate: (value: ((value: T) => Partial<T>) | Partial<T>) => void;
};

export default function useStateReducer<T extends Partial<IInitialSate>>(
  initialSate: WithOptional<T, 'error' | 'loading'> = {} as T,
): UseStateReducerReturnType<T> {
  const [stateDict, dispatch] = React.useReducer(
    (state: T, action: StateReducerActions) => {
      const handler =
        handlers[action.type] || handlers[STATE_REDUCER_ACTIONS.DEFAULT];
      return handler<any>(state, action);
    },
    {
      error: null,
      loading: true,
      ...initialSate,
    },
  );

  const handleUpdate = React.useCallback(
    (update: ((value: T) => Partial<T>) | Partial<T>): void => {
      dispatch({type: STATE_REDUCER_ACTIONS.UPDATE, update});
    },
    [dispatch],
  );

  return {
    ...stateDict,
    handleUpdate,
  };
}
