import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {IntegrationListModel} from '../../struture';
import {REDUX_STATUS} from '../../services/types';
import {RootState} from '../reducers';

export interface IntegrationListState {
  integrationList: IntegrationListModel | null;
  loading: boolean;
  status: REDUX_STATUS;
  keywords: string;
  total: number;
  page?: number;
}

export interface SetIntegrationList
  extends Pick<
    IntegrationListState,
    'integrationList' | 'keywords' | 'total' | 'page'
  > {}

const initialState: IntegrationListState = {
  integrationList: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
  keywords: '',
  total: 0,
  page: 1,
};

export const integrationListSlice = createSlice({
  name: 'integrationList',
  initialState,
  reducers: {
    setIntegrationList: (state, action: PayloadAction<SetIntegrationList>) => {
      if (action.payload.integrationList) {
        return {
          ...state,
          integrationList: action.payload.integrationList
            .update('keywords', () => action.payload.keywords)
            .update('page', () => action.payload.page),
          page: action.payload.page,
          keywords: action.payload.keywords,
          total: action.payload.total,
          status: REDUX_STATUS.SUCCEEDED,
          loading: false,
        };
      }
    },
  },
});

export const {setIntegrationList} = integrationListSlice.actions;

export const selectIntegrationList = ({integrationListReducer}: RootState) =>
  integrationListReducer;

export default integrationListSlice.reducer;
