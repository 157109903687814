import React, {useCallback, useEffect} from 'react';
import {isFunction} from '../../../../services/helpers';
import {ChildrenMustBeAFunction} from '../../../../components/lib/Errors';
import {IAuthRouteItemProps, StyledWarningOutlined} from '../../Show';
import {WithTranslation} from 'react-i18next';
import {SolutionOutlined} from '@ant-design/icons';
import {SlEnvolopeLetter} from 'react-icons/sl';
import {IoKeyOutline} from 'react-icons/io5';
import {Routes} from '../../../../services/types';
import {consoleAuthUrl} from '../../../../services/const';
import {useNavigate} from 'react-router';

interface ITabChildrenProps {
  routes: Omit<IAuthRouteItemProps, keyof WithTranslation>[];
}

export interface IAuthOfficeTabPanelProps {
  children: (value: ITabChildrenProps) => React.ReactNode;
  showForgotPasswordWarning?: boolean;
  showInviteWarning?: boolean;
}

export enum AUTH_OFFICE_TABS {
  FORGOT_PASSWORD = 'Forgot password',
  CONSOLE = 'Login to console',
  EMPLOYEE_REGISTRATION = 'Employee registration',
}

export function AuthOfficeTabPanel({
  children,
  showInviteWarning,
  showForgotPasswordWarning,
}: IAuthOfficeTabPanelProps) {
  const navigate = useNavigate();

  const handleOpenConsoleUrl = useCallback(async () => {
    window.open(consoleAuthUrl, '_blank');
  }, []);

  const [routes, setRoutes] = React.useState<
    Omit<IAuthRouteItemProps, keyof WithTranslation>[]
  >([]);

  useEffect(() => {
    setRoutes([
      // {
      //   key: AUTH_OFFICE_TABS.EMPLOYEE_REGISTRATION,
      //   title: AUTH_OFFICE_TABS.EMPLOYEE_REGISTRATION,
      //   icon: <SlEnvolopeLetter size={16} />,
      //   navigation: () => {
      //     navigate(`/${Routes.auth}/${Routes.employeeSignup}`);
      //   },
      //   buttonIcon: showInviteWarning ? (
      //     <StyledWarningOutlined size={19} />
      //   ) : undefined,
      // },
      // {
      //   key: AUTH_OFFICE_TABS.CONSOLE,
      //   title: AUTH_OFFICE_TABS.CONSOLE,
      //   icon: <SolutionOutlined style={{fontSize: 16}} />,
      //   navigation: handleOpenConsoleUrl,
      // },
      {
        key: AUTH_OFFICE_TABS.FORGOT_PASSWORD,
        title: AUTH_OFFICE_TABS.FORGOT_PASSWORD,
        icon: <IoKeyOutline size={18} />,
        textAlign: 'right',
        buttonIcon: showForgotPasswordWarning ? (
          <StyledWarningOutlined />
        ) : undefined,
        navigation: () => {
          navigate(`/${Routes.auth}/${Routes.forgotPassword}`, {
            state: {
              backPageName: 'Office',
              backPagePath: location.pathname,
            },
          });
        },
      },
    ]);
  }, [
    handleOpenConsoleUrl,
    navigate,
    showForgotPasswordWarning,
    showInviteWarning,
  ]);

  if (!isFunction(children)) {
    throw new ChildrenMustBeAFunction('AuthOfficeTabPanel');
  }

  return <>{children({routes})}</>;
}
