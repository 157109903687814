import * as React from 'react';
import {List} from 'immutable';
import {ApiError, IEntityProps, IListSearchProps} from '../services/types';
import {
  IExpenseListDTO,
  ExpenseMapper,
  ExpenseModel,
  AggregatedSumDTO,
} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {getExpenseList} from '../services/api/orders';

export interface IUseExpenseListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  scheduleUuid: string;
}

export interface IUseExpenseListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<ExpenseModel> | null;
  refresh: (
    value: Partial<IUseExpenseListProps>,
  ) => Promise<List<ExpenseModel> | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  scheduleUuid: string;
  aggregatedSum: AggregatedSumDTO;
}

export function useExpenseList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    scheduleUuid,
  }: IUseExpenseListProps = {} as IUseExpenseListProps,
): IUseExpenseListReturnType {
  const {cancellablePromise, didCancel} = useCancellablePromise();
  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    scheduleUuid: listScheduleUuid,
    aggregatedSum: aggregatedSumList,
    handleUpdate,
  } = useStateReducer<Omit<IUseExpenseListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    scheduleUuid,
    aggregatedSum: {} as AggregatedSumDTO,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      scheduleUuid = listScheduleUuid,
    }: Partial<IUseExpenseListProps> = {}): Promise<List<ExpenseModel> | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          scheduleUuid,
        });

        const {schedule_expenses, aggregated_sum, total} =
          await cancellablePromise<IExpenseListDTO>(
            getExpenseList({scheduleUuid, limit, offset, keywords}),
          );

        const expanseListModel = ExpenseMapper.toExpenseListModel(
          schedule_expenses,
          total,
        );

        if (!didCancel.current) {
          handleUpdate({
            entityList: expanseListModel?.schedule_expenses,
            total: expanseListModel?.total,
            loading: false,
            aggregatedSum: aggregated_sum,
          });

          return expanseListModel?.schedule_expenses;
        }
      } catch (e) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }
      }
    },
    [
      cancellablePromise,
      didCancel,
      error,
      handleUpdate,
      listScheduleUuid,
      listKeywords,
      listLimit,
      listOffset,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && scheduleUuid) {
      (async () => {
        await refresh({scheduleUuid});
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, scheduleUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    scheduleUuid: listScheduleUuid,
    aggregatedSum: aggregatedSumList,
  };
}
