import * as React from 'react';
import {useCallback, useMemo} from 'react';
import {List} from 'immutable';
import {
  IUseStoreDocumentItemListProps,
  IUseStoreDocumentItemListReturnType,
  useStoreDocumentItemList,
} from './useStoreDocumentItemList';
import {StoreDocumentItemModel, StoreDocumentModel} from '../struture';
import {useStateEntityList} from '../components/lib/libV2/hooks';
import {listToArray} from '../services/helpers';
import {IUseProductListProps} from './useProductList';

export interface UseStateStoreDocumentItemListProps
  extends IUseStoreDocumentItemListProps {}

export interface UseStateStoreDocumentItemListReturnType
  extends Omit<IUseStoreDocumentItemListReturnType, 'entityList'> {
  storeDocumentItemList: List<StoreDocumentItemModel> | null;
  handleSearchStoreDocumentItemList: (
    value: Omit<IUseStoreDocumentItemListProps, 'documentUuid'> & {
      documentUuid?: string;
    },
  ) => Promise<void>;
  handleLoadingMoreDocumentItemList: (
    value: Omit<IUseStoreDocumentItemListProps, 'documentUuid'>,
  ) => Promise<void>;
  handleLoadStoreDocumentItemList: (
    storeDocument: StoreDocumentModel,
    value: Omit<IUseStoreDocumentItemListProps, 'documentUuid'>,
  ) => Promise<StoreDocumentModel | void>;
  setStoreDocumentItemList: (
    storeDocumentItemList: List<StoreDocumentItemModel>,
  ) => void;
  isLoadingMore: boolean;
}

export function useStateStoreDocumentItemList(
  {
    documentUuid,
    ...rest
  }: UseStateStoreDocumentItemListProps = {} as UseStateStoreDocumentItemListProps,
): UseStateStoreDocumentItemListReturnType {
  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: storeDocumentLoading,
    total: storeDocumentItemListTotal,
    keywords: storeDocumentKeywords,
    ...storeDocumentsParams
  } = useStoreDocumentItemList({
    documentUuid,
    ...rest,
  });

  const {
    entityList: storeDocumentItemList,
    loading: stateLoading,
    setEntityList,
    total,
  } = useStateEntityList<StoreDocumentItemModel>({
    entityList: entityList?.doc_items!,
    refresh,
    limit,
    offset,
    total: storeDocumentItemListTotal,
  });

  const size = useMemo(
    () => storeDocumentItemList?.size || 0,
    [storeDocumentItemList?.size],
  );
  const isLoadingMore = useMemo(() => size < total, [size, total]);

  const loading = storeDocumentLoading || stateLoading;

  const handleSearchStoreDocumentItemList = React.useCallback(
    async ({
      keywords,
      offset = 0,
      limit = 10,
      showLoading = true,
      ...rest
    }: Omit<IUseStoreDocumentItemListProps, 'documentUuid'>) => {
      const storeDocumentItemList = await refresh({
        offset,
        keywords,
        showLoading,
        limit,
        ...rest,
      });

      if (storeDocumentItemList && storeDocumentItemList?.doc_items) {
        setEntityList(storeDocumentItemList?.doc_items);
      }
    },
    [refresh, setEntityList],
  );
  const handleLoadStoreDocumentItemList = useCallback(
    async (
      storeDocument: StoreDocumentModel,
      {
        limit = 10,
        offset = 0,
        ...rest
      }: Partial<IUseStoreDocumentItemListProps>,
    ) => {
      try {
        const documentItemsList = await refresh({
          documentUuid: storeDocument?.uuid,
          limit,
          offset,
          ...rest,
        });

        if (
          documentItemsList &&
          storeDocument &&
          List.isList(documentItemsList?.doc_items)
        ) {
          return storeDocument
            .update(
              'doc_items',
              (doc_items) =>
                (storeDocument?.is_add_doc_items
                  ? List.isList(doc_items)
                    ? (doc_items || List()).merge(documentItemsList?.doc_items)
                    : [
                        ...doc_items,
                        ...listToArray(documentItemsList?.doc_items),
                      ]
                  : documentItemsList?.doc_items) as any,
            )
            .update('is_add_doc_items', () => true);
        }
      } catch (error: any) {}
    },
    [refresh],
  );

  const handleLoadingMoreDocumentItemList = React.useCallback(
    async ({
      keywords = storeDocumentKeywords,
      showLoading = false,
      limit = 10,
      offset = storeDocumentItemList?.size,
      ...rest
    }: Omit<IUseProductListProps, 'companyUuid'> = {}) => {
      if (size < total) {
        const apiDocumentItemList = await refresh({
          keywords,
          showLoading,
          limit,
          offset,
          ...rest,
        });

        if (apiDocumentItemList && apiDocumentItemList?.doc_items) {
          setEntityList((prevState) =>
            (prevState || List()).merge(apiDocumentItemList?.doc_items),
          );
        }
      }
    },
    [
      refresh,
      setEntityList,
      size,
      storeDocumentItemList?.size,
      storeDocumentKeywords,
      total,
    ],
  );

  const setStoreDocumentItemList = useCallback(
    (storeDocumentItemList: List<StoreDocumentItemModel>) => {
      setEntityList(storeDocumentItemList);
    },
    [setEntityList],
  );

  return {
    storeDocumentItemList,
    offset,
    limit,
    refresh,
    total,
    loading,
    keywords: storeDocumentKeywords,
    ...storeDocumentsParams,
    handleSearchStoreDocumentItemList,
    handleLoadStoreDocumentItemList,
    setStoreDocumentItemList,
    isLoadingMore,
    handleLoadingMoreDocumentItemList,
  };
}
