export interface IAclDTOProps {
  read: boolean;
  manage: boolean;
}

export class AclDTO {
  public read: boolean;

  public manage: boolean;

  constructor(props?: IAclDTOProps) {
    this.read = typeof props?.read === 'boolean' ? props?.read : true;
    this.manage = typeof props?.manage === 'boolean' ? props?.manage : true;
  }
}
