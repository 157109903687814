import * as React from 'react';
import {ApiError, IEntityProps, IListSearchProps} from '../services/types';
import {
  IStoreDocumentListDTO,
  StoreDocumentMapper,
  StoreDocumentListModel,
  StoreDocumentType,
  StoreDocumentStatus,
  StoreDocumentShowOnly,
} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {getStoreDocumentList} from '../services/api/storeDocument';
import {SessionStorageItems} from '../services/const';
import {useMemo} from 'react';
import {head, last} from '../services/helpers';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../contex';

export interface IUseStoreDocumentListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  companyUuid: string;
  documentType: StoreDocumentType;
  documentStatus?: StoreDocumentStatus;
  start_date?: string;
  end_date?: string;
  resident_uuid?: string;
  show_only?: StoreDocumentShowOnly;
  show_with_credits_only?: boolean;
  withoutDates?: boolean;
  withoutSaveDates?: boolean;
  minKeywordLength?: number;
}

export interface IUseStoreDocumentListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: StoreDocumentListModel | null;
  refresh: (
    value: Partial<IUseStoreDocumentListProps>,
  ) => Promise<StoreDocumentListModel | void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  companyUuid: string;
  documentType: StoreDocumentType;
  documentStatus?: StoreDocumentStatus;
  start_date?: any;
  end_date?: any;
  resident_uuid?: string;
  show_with_credits_only?: boolean;
  minKeywordLength?: number;
}

export function useStoreDocumentList(
  {
    loadOnInit = true,
    keywords = '',
    limit = 10,
    offset = 0,
    companyUuid,
    documentType,
    documentStatus,
    start_date,
    end_date,
    resident_uuid,
    show_with_credits_only,
    withoutDates = false,
    withoutSaveDates,
    minKeywordLength = 0,
  }: IUseStoreDocumentListProps = {} as IUseStoreDocumentListProps,
): IUseStoreDocumentListReturnType {
  const {cancellablePromise, didCancel} = useCancellablePromise();
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const orderRangeDates = useMemo(
    () =>
      withoutDates
        ? []
        : JSON.parse(
            sessionStorage.getItem(
              `${SessionStorageItems.storeDocumentDates}${documentType}`,
            ) || JSON.stringify([]),
          ),
    [documentType, withoutDates],
  );

  const startDate = orderRangeDates ? head(orderRangeDates) : '';

  const endDate = orderRangeDates ? last(orderRangeDates) : '';

  const {
    entityList,
    loading,
    error,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    companyUuid: listCompanyUuid,
    documentType: listDocumentType,
    start_date: listStartDate,
    end_date: listEndDate,
    documentStatus: listDocumentStatus,
    resident_uuid: listResidentUuid,
    show_with_credits_only: listShowWithCreditsOnly,
    minKeywordLength: listMinKeywordLength,
    handleUpdate,
  } = useStateReducer<Omit<IUseStoreDocumentListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    companyUuid,
    documentType,
    documentStatus,
    start_date: start_date || startDate,
    end_date: end_date || endDate,
    resident_uuid,
    show_with_credits_only,
    minKeywordLength,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      companyUuid = listCompanyUuid,
      documentType = listDocumentType,
      start_date = listStartDate,
      end_date = listEndDate,
      documentStatus = listDocumentStatus,
      show_only,
      resident_uuid = listResidentUuid,
      show_with_credits_only = listShowWithCreditsOnly,
      minKeywordLength = listMinKeywordLength || 0,
    }: Partial<IUseStoreDocumentListProps> = {}): Promise<StoreDocumentListModel | void> => {
      try {
        if (!withoutSaveDates) {
          sessionStorage.setItem(
            `${SessionStorageItems.storeDocumentDates}${documentType}`,
            JSON.stringify([start_date, end_date]),
          );
        }

        handleUpdate({
          loading: showLoading,
          error: null,
          limit,
          offset,
          keywords,
          companyUuid,
          documentType,
          documentStatus,
          start_date,
          end_date,
          resident_uuid,
          show_with_credits_only,
          minKeywordLength,
        });

        if (keywords?.length >= minKeywordLength) {
          const {
            documents,
            total,
            stats,
            show_only: showOnly,
          } = await cancellablePromise<IStoreDocumentListDTO>(
            getStoreDocumentList({
              companyUuid,
              limit,
              offset,
              keywords,
              documentType,
              documentStatus,
              start_date,
              end_date,
              show_only,
              resident_uuid,
              show_with_credits_only,
            }),
          );

          const documentListModel =
            StoreDocumentMapper.toStoreDocumentListModel(
              documents,
              total,
              [],
              undefined,
              stats,
              showOnly,
              resident_uuid,
            );

          handleUpdate({
            entityList: documentListModel,
            total: documentListModel?.total,
            loading: false,
          });

          return documentListModel;
        }
      } catch (e: any) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }
        alert(
          'error',
          t('Store document'),
          `${t('An error occurred during store moving loading')} : ${
            e?.message
          }`,
        );
      }
    },
    [
      alert,
      cancellablePromise,
      didCancel,
      error,
      handleUpdate,
      listCompanyUuid,
      listDocumentStatus,
      listDocumentType,
      listEndDate,
      listKeywords,
      listLimit,
      listMinKeywordLength,
      listOffset,
      listResidentUuid,
      listShowWithCreditsOnly,
      listStartDate,
      t,
      withoutSaveDates,
    ],
  );

  React.useEffect(() => {
    if (loadOnInit && companyUuid && documentType) {
      (async () => {
        await refresh({companyUuid, documentType});
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, companyUuid]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    keywords: listKeywords,
    companyUuid: listCompanyUuid,
    documentType: listDocumentType,
    start_date: listStartDate,
    end_date: listEndDate,
    documentStatus: listDocumentStatus,
    resident_uuid: listResidentUuid,
    show_with_credits_only: listShowWithCreditsOnly,
    minKeywordLength: listMinKeywordLength,
  };
}
