import React, {useCallback, useEffect} from 'react';
import {Col, Row, Select} from 'antd';
import {isEqualByUuid, listToArray} from '../../../../services/helpers';
import {useTranslation} from 'react-i18next';
import styled, {css} from 'styled-components';
import {Label} from '../../../../components/lib/DataDisplay';
import {useStoreSale} from '../Managers';
import {LocalStorageItems, phoneScreen} from '../../../../services/const';
import {
  useDefaultPriceMargin,
  useStoredCompanies,
  useStoredStoreList,
} from '../../../../hooks';
import {StoreSaleListState} from '../Pages';
import {
  ProductCategoryMappedType,
  ProductMappedType,
  StoreDTO,
  StoreMapper,
  StoreModel,
} from '../../../../struture';
import {resetProductListCache as storeResetProductListCache} from '../../../../store/features/productListSlice';
import {useDispatch} from 'react-redux';

export interface StoreSaleFilterViewProps {
  contentWidth: number;
  handleSearchSelectDaleyFocus: (ms?: number) => void;

  isShowFilters: boolean;
  updateSaleContentState: (
    value:
      | ((value: StoreSaleListState) => Partial<StoreSaleListState>)
      | Partial<StoreSaleListState>,
  ) => void;
}

const StyledRow = styled(Row)<{$visible: boolean}>`
  display: flex;
  align-items: center;
  background-color: ${({theme}) => theme.background.layout};

  height: 0;
  transition: all 0.5s;
  overflow: hidden;

  ${({$visible}) =>
    $visible &&
    css`
      margin-bottom: 10px;
      padding: 10px;
      border-radius: 7px;
      height: 100px;
      transition: all 0.5s;
    `}
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const StyledLabel = styled(Label)`
  width: 100%;
`;

export function StoreSaleFilterView({
  isShowFilters,
  contentWidth,
  handleSearchSelectDaleyFocus,
  updateSaleContentState,
}: StoreSaleFilterViewProps): React.JSX.Element {
  const {t} = useTranslation();
  const {defaultCompanyUuid} = useStoredCompanies();

  const dispatch = useDispatch<any>();

  const {
    storeDocumentItemList,
    document: storeDocument,
    loadingSubmit,

    handleChangeDocument,
    defaultPriceMargin,
    setDefaultPriceMargin,
  } = useStoreSale();

  const isFullWidth = contentWidth <= phoneScreen ? 24 : 12;

  const {
    storeList,
    loading: storeListLoading,
    handleSearchStores,
  } = useStoredStoreList({companyUuid: defaultCompanyUuid, limit: 50});

  const {
    priceMarginList,
    loading: priceMarginListLoading,

    handleSearchPriceMargins,
  } = useDefaultPriceMargin({
    loadOnInit: true,
  });

  const onChangeStore = useCallback(
    (doc_store_uuid: string) => {
      updateSaleContentState({isShowStoreWarning: false});
      handleChangeDocument({doc_store_uuid});

      dispatch(
        storeResetProductListCache({
          productTypes: [ProductMappedType.SALE],
        }),
      );

      const selectedStore = storeList?.find(isEqualByUuid(doc_store_uuid));

      if (selectedStore) {
        updateSaleContentState({documentStoreName: selectedStore?.store_name});

        const store = {
          uuid: selectedStore?.uuid,
          store_name: selectedStore?.store_name,
        };

        const encryptStoreData = JSON.stringify(store);

        localStorage.setItem(
          LocalStorageItems.saleFormStoreData,
          encryptStoreData,
        );

        return selectedStore;
      }
    },
    [updateSaleContentState, handleChangeDocument, dispatch, storeList],
  );

  const onChangePriceMargin = useCallback(
    async (doc_margin_uuid: string) => {
      handleSearchSelectDaleyFocus(100);

      // await updateSaleContentStateStoreDocumentSalePriceMargin(doc_margin_uuid);

      const defaultPriceMargin = priceMarginList?.find(
        ({uuid}) => uuid === doc_margin_uuid,
      );

      if (defaultPriceMargin) {
        setDefaultPriceMargin(defaultPriceMargin);
      }
    },
    [handleSearchSelectDaleyFocus, priceMarginList, setDefaultPriceMargin],
  );

  useEffect(() => {
    if (!storeDocument?.doc_store_uuid) {
      const serializeStoreData = localStorage.getItem(
        LocalStorageItems.saleFormStoreData,
      );

      if (serializeStoreData && serializeStoreData.includes('store_name')) {
        const storeDTO: StoreDTO = JSON.parse(serializeStoreData);

        const storeModel = StoreMapper.toStoreModel(storeDTO);

        updateSaleContentState({
          documentStoreName: storeModel?.store_name,
          isShowStoreWarning: false,
        });
        handleChangeDocument({
          doc_store_uuid: storeModel?.uuid,
        });
      } else {
        updateSaleContentState({
          isShowFilters: true,
          isShowStoreWarning: true,
        });
      }
    }

    if (storeDocument?.doc_store_uuid instanceof StoreModel) {
      handleChangeDocument({
        doc_store_uuid: storeDocument?.doc_store_uuid?.uuid,
      });
    }
  }, [
    updateSaleContentState,
    handleChangeDocument,
    storeDocument?.doc_store_uuid,
  ]);

  return (
    <StyledRow gutter={20} $visible={isShowFilters}>
      <Col span={isFullWidth}>
        <StyledLabel required tooltipTitle={t('Store')} label={t('Store')}>
          <StyledSelect
            disabled={
              storeListLoading ||
              loadingSubmit ||
              !!storeDocumentItemList?.length
            }
            placeholder={t('Select a warehouse')}
            options={listToArray(storeList as any) as any}
            showSearch
            onSearch={async (keywords) => {
              await handleSearchStores({keywords});
            }}
            onChange={(doc_store_uuid: any) => {
              onChangeStore(doc_store_uuid);
              handleSearchSelectDaleyFocus(100);
            }}
            fieldNames={{
              label: 'store_name',
              value: 'uuid',
            }}
            value={storeDocument?.doc_store_uuid || undefined}
          />
        </StyledLabel>
      </Col>
      <Col span={isFullWidth}>
        <StyledLabel tooltipTitle={t('Price margin')} label={t('Price margin')}>
          <StyledSelect
            disabled={priceMarginListLoading || loadingSubmit}
            placeholder={t('Select a price margin')}
            options={listToArray(priceMarginList as any) as any}
            showSearch
            onSearch={(keywords) => handleSearchPriceMargins({keywords})}
            onChange={onChangePriceMargin as any}
            fieldNames={{
              label: 'price_name',
              value: 'uuid',
            }}
            value={defaultPriceMargin?.uuid}
          />
        </StyledLabel>
      </Col>
    </StyledRow>
  );
}
