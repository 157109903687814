/**
 * @module userProfile
 */
import {Record} from 'immutable';
import {contentApiUrl} from './const';
import {apiPost, apiPut} from './core/api';
import {userProfileServiceUrl} from './const';
import {LogEntries, NotificationsSetting} from './models';
import {Profile} from './models';

/**
 * Return the notifications setting for the user.
 * https://github.com/severalnines/bar-user-profile-api#post-notification-settingsget
 */
export async function getNotificationsSetting() {
  const res = await apiPost(
    `${userProfileServiceUrl}/notification-settings/get`,
    null,
  );
  return new NotificationsSetting(res);
}

/**
 * Update the notifications setting for the user and return the new notifications setting.
 * https://github.com/severalnines/bar-user-profile-api#post-notification-settingsset
 * @param {NotificationsSetting} settings
 * @returns {NotificationsSetting}
 */
export async function updateNotificationsSetting({
  sendEventBackupCreated,
  sendEventBackupFailed,
  sendEventBackupPurged,
  sendEventScheduleCreated,
  sendEventScheduleRemoved,
  sendEventSchedulePaused,
  sendEventScheduleResumed,
  sendEventAgentInstalled,
  sendEventAgentRemoved,
  sendEventAgentErrored,
  sendEventAgentStarted,
  sendEventAgentStopped,
}) {
  const res = await apiPost(
    `${userProfileServiceUrl}/notification-settings/set`,
    {
      sendEventBackupCreated,
      sendEventBackupFailed,
      sendEventBackupPurged,
      sendEventScheduleCreated,
      sendEventScheduleRemoved,
      sendEventSchedulePaused,
      sendEventScheduleResumed,
      sendEventAgentInstalled,
      sendEventAgentRemoved,
      sendEventAgentErrored,
      sendEventAgentStarted,
      sendEventAgentStopped,
    },
  );
  return new NotificationsSetting(res);
}

/**
 * Subscribe to newsletter
 * https://github.com/severalnines/bar-user-profile-api#post-usersubscribe
 * @returns {promise}
 */
export async function subscribe() {
  return await apiPost(`${userProfileServiceUrl}/users/subscribe`, {});
}

/**
 * Unsubscribe to newsletter
 * https://github.com/severalnines/bar-user-profile-api#post-userunsubscribe
 * @returns {promise}
 */
export async function unsubscribe() {
  return await apiPost(`${userProfileServiceUrl}/user/unsubscribe`, {});
}

/**
 * Resend email verification.
 * https://github.com/severalnines/bar-user-profile-api#post-userresendverificationemail
 * @returns {promise}
 */
export async function resendVerificationEmail() {
  return await apiPost(
    `${userProfileServiceUrl}/user/resendVerificationEmail`,
    {},
  );
}

/**
 * Delete a user. In fact, it will just send a request to delete the user (at least for now).
 * https://github.com/severalnines/bar-user-profile-api#post-userdelete
 * @returns {promise}
 */
export async function userDelete() {
  return await apiPost(`${userProfileServiceUrl}/users/me`, {});
}

/**
 * @name editUser
 * @param {String} uuid
 * @param {String} first_name
 * @param {String} email
 */
export async function editUser({
  first_name = '',
  last_name = '',
  phone = '',
  email,
  birthday,
}) {
  // Validate.string(title);
  // Validate.string(phone);
  const res = await apiPut(`${contentApiUrl}/users/me`, {
    first_name,
    last_name,
    phone,
    email,
    birthday,
  });

  return new Profile((res && res.profile) || {});
}

/**
 * Add a new contact for the user in the session.
 * https://github.com/severalnines/bar-user-profile-api#post-contactscreate
 * @param {number} type [1:phone, 2:email]
 * @param {string} value
 * @returns {Contact}
 */
export async function contactsCreate({type, value}) {
  const res = await apiPost(`${userProfileServiceUrl}/contacts/create`, {
    type,
    value,
  });
  return new Contact(res);
}

/**
 * Delete a contact
 * https://github.com/severalnines/bar-user-profile-api#post-contactsdelete
 * @param {string} id
 * @returns {promise}
 */
export async function contactsDelete({id}) {
  return await apiPost(`${userProfileServiceUrl}/contacts/delete`, {
    id,
  });
}

/**
 * Retrieve the contacts list for the user in the session.
 * https://github.com/severalnines/bar-user-profile-api#post-contactslist
 */
export async function contactsList() {
  const res = await apiPost(`${userProfileServiceUrl}/contacts/list`, null);
  return res.map((c) => new Contact(c));
}

/**
 * Retrieve the audit log for user.
 * @param {number} offset
 * @param {number} limit
 * @returns {Promise<LogEntries>}
 */
export async function userAuditLogList(offset = 0, limit = 100) {
  const res = await apiPost(`${userProfileServiceUrl}/audit-logs/user`, {
    offset,
    limit,
  });

  return new LogEntries(res);
}

/**
 * @class Contact
 * @classdesc Contact model
 */
export class Contact extends Record({
  id: null,
  userId: null,
  type: null,
  value: null,
}) {}

/**
 * @class ContactType
 * @classdesc ContactType model
 */
export class ContactType extends Record({
  id: null,
  constant: null,
  description: null,
}) {}
