import * as React from 'react';
import {isFunction} from '../../../../services/helpers';

export interface IUseDeleteProps<T> {
  onSuccess: (value: string[]) => Promise<void>;
  entity: T[];
  identifier?: string;
  notifyTitle: string;
}

export interface IUseDeleteReturnType {
  loading: boolean;
  handleDelete: () => Promise<void>;
}

export default function useDelete<T>({
  onSuccess,
  entity,
  identifier = 'uuid',
  notifyTitle,
}: IUseDeleteProps<T>): IUseDeleteReturnType {
  const [loading, setLoading] = React.useState(false);

  const handleDelete = React.useCallback(async (): Promise<void> => {
    setLoading(true);
    try {
      const list = entity.map((value: T) => (value as any)[identifier]);

      if (isFunction(onSuccess)) {
        await onSuccess(list);
      }

      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  }, [entity, identifier, onSuccess]);

  return {
    loading,
    handleDelete,
  };
}
