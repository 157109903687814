import {Dispatch} from 'redux';
import {List} from 'immutable';
import {PRODUCT_CATEGORY_LIST} from '../constants';
import {IProductsListStatsProps, ProductCategoryModel} from '../../struture';
import {ApiError} from '../../services/types';

export interface ISetProductCategoryListParam {
  type: PRODUCT_CATEGORY_LIST;
  categoryList: List<ProductCategoryModel>;
  keywords?: string;
  total?: number;
  page?: number;
  stats?: IProductsListStatsProps;
}

export interface ISetProductCategoryListTreeParam
  extends Omit<ISetProductCategoryListParam, 'categoryList'> {
  categoryListTree: List<ProductCategoryModel>;
}

export interface ISetProductCategoryListStatsParam {
  stats: IProductsListStatsProps;
}

export interface IProductCategoryListStatsParam {}

export interface IAddProductCategoryToListParam {
  type: PRODUCT_CATEGORY_LIST;
  category: ProductCategoryModel;
}

export interface IAddProductCategoryToListTreeParam
  extends IAddProductCategoryToListParam {
  parentCategoryUuid: string;
}

export interface IUpdateProductCategoryFromListParam {
  type: PRODUCT_CATEGORY_LIST;
  category: ProductCategoryModel;
}

export interface IUpdateProductCategoryFromListTreeParam
  extends IUpdateProductCategoryFromListParam {}

export interface IDeleteProductCategoryFromListParam {
  type: PRODUCT_CATEGORY_LIST;
  categoryUuid: string;
}

export interface IDeleteProductCategoryFromListTreeParam
  extends IDeleteProductCategoryFromListParam {}

export interface IErrorProductCategoryListParam {
  type: PRODUCT_CATEGORY_LIST;
  error: ApiError | undefined;
}

export function setProductCategoryList(
  categoryList: List<ProductCategoryModel>,
  keywords?: string,
  total?: number,
  page?: number,
  stats?: IProductsListStatsProps,
): (dispatch: Dispatch) => Promise<ISetProductCategoryListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_CATEGORY_LIST.SET_PRODUCT_CATEGORY_LIST,
      categoryList,
      keywords,
      total,
      page,
      stats,
    });
  };
}

export function setProductCategoryListTree(
  categoryListTree: List<ProductCategoryModel>,
  keywords?: string,
  total?: number,
  page?: number,
  stats?: IProductsListStatsProps,
): (dispatch: Dispatch) => Promise<ISetProductCategoryListTreeParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_CATEGORY_LIST.SET_PRODUCT_CATEGORY_LIST_TREE,
      categoryListTree,
      keywords,
      total,
      page,
      stats,
    });
  };
}

export function setProductCategoryListStats(
  stats: IProductsListStatsProps,
): (dispatch: Dispatch) => Promise<ISetProductCategoryListStatsParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_CATEGORY_LIST.SET_PRODUCT_CATEGORY_LIST_STATS,
      stats,
    });
  };
}
export function addProductCategoryListStats(): (
  dispatch: Dispatch,
) => Promise<IProductCategoryListStatsParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_CATEGORY_LIST.ADD_PRODUCT_CATEGORY_LIST_STATS,
    });
  };
}

export function subProductCategoryListStats(): (
  dispatch: Dispatch,
) => Promise<IProductCategoryListStatsParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_CATEGORY_LIST.SUB_PRODUCT_CATEGORY_LIST_STATS,
    });
  };
}

export function loadMoreProductCategoryList(
  categoryList: List<ProductCategoryModel>,
): (dispatch: Dispatch) => Promise<ISetProductCategoryListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_CATEGORY_LIST.LOAD_MORE_PRODUCT_CATEGORY_LIST,
      categoryList,
    });
  };
}

export function addProductCategoryToList(
  category: ProductCategoryModel,
  stats: IProductsListStatsProps,
): (dispatch: Dispatch) => Promise<IAddProductCategoryToListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_CATEGORY_LIST.ADD_PRODUCT_CATEGORY,
      category,
      stats,
    });
  };
}

export function addProductCategoryTreeToList(
  category: ProductCategoryModel,
  parentCategoryUuid: string,
  stats: IProductsListStatsProps,
): (dispatch: Dispatch) => Promise<IAddProductCategoryToListTreeParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_CATEGORY_LIST.ADD_PRODUCT_CATEGORY_TREE,
      category,
      parentCategoryUuid,
      stats,
    });
  };
}

export function updateProductCategoryFromList(
  category: ProductCategoryModel,
): (dispatch: Dispatch) => Promise<IUpdateProductCategoryFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_CATEGORY_LIST.UPDATE_PRODUCT_CATEGORY,
      category,
    });
  };
}

export function updateProductCategoryTreeFromList(
  category: ProductCategoryModel,
): (dispatch: Dispatch) => Promise<IUpdateProductCategoryFromListTreeParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_CATEGORY_LIST.UPDATE_PRODUCT_CATEGORY_TREE,
      category,
    });
  };
}

export function deleteProductCategoryFromList(
  categoryUuid: string,
  stats: IProductsListStatsProps,
): (dispatch: Dispatch) => Promise<IDeleteProductCategoryFromListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_CATEGORY_LIST.DELETE_PRODUCT_CATEGORY,
      categoryUuid,
      stats,
    });
  };
}

export function deleteProductCategoryTreeFromList(
  categoryUuid: string,
  stats: IProductsListStatsProps,
): (dispatch: Dispatch) => Promise<IDeleteProductCategoryFromListTreeParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_CATEGORY_LIST.DELETE_PRODUCT_CATEGORY_TREE,
      categoryUuid,
      stats,
    });
  };
}

export function errorProductCategoryInList(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorProductCategoryListParam> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: PRODUCT_CATEGORY_LIST.ERROR_PRODUCT_CATEGORY_LIST,
      error,
    });
  };
}
