import {
  IAlphaNameDTOProps,
  IFinancialSettingsDTOProps,
  AlphaNameDTO,
  FinancialSettingsDTO,
  AlphaNameMapper,
  FinancialSettingsMapper,
} from '../internal';

export interface ICompanySettingsDTOProps {
  alfa_name_order: IAlphaNameDTOProps;
  settings: IFinancialSettingsDTOProps;
}

export class CompanySettingsDTO {
  public alfa_name_order: AlphaNameDTO;
  public settings: FinancialSettingsDTO;

  constructor(props?: ICompanySettingsDTOProps) {
    this.alfa_name_order = AlphaNameMapper.toAlphaNameDTO(
      props?.alfa_name_order as IAlphaNameDTOProps,
    );
    this.settings = FinancialSettingsMapper.toFinancialSettings({
      ...{
        ...(props?.settings || {}),
        personalization_sms_enabled: Boolean(
          props?.settings?.company_alfa_name,
        ),
      },
    } as IFinancialSettingsDTOProps);
  }
}
