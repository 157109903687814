import React from 'react';
import {TextFormat} from '../Format';
import styled from 'styled-components';

import {
  FlexContainer,
  StyledOrderedListOutlined,
  StyledHomeOutlined,
  StyledFileDoneOutlined,
  StyledContactsOutlined,
  StyledDollarCircleOutlined,
  StyledContainerOutlined,
  StyledUserOutlined,
  StyledGroupOutlined,
  StyledFileOutlined,
  StyledGoogleOutlined,
  StyledCounterparty,
} from '../Styled';
export enum ICON_NAME {
  ORDER = 'order',
  SERVICE = 'service',
  COUNTERPARTY = 'counterparty',
  EMPLOYEE = 'employee',
  PAYMENT = 'payment',
  CATEGORY = 'category',
  USER = 'user',
  GROUP_PAYMENT = 'group_payment',
  TEMPLATE = 'template',
  GOOGLE_CALENDAR = 'google_calendar',
  DEFAULT = 'default',
}

export interface IIconTitleProps {
  iconName: ICON_NAME;
  title: string;
  className?: string;
}

const StatusFlexContainer = styled(FlexContainer)`
  height: fit-content;
`;

const StyledTextFormat = styled(TextFormat)`
  color: ${({theme}) => theme.colors.primary};
  margin-left: 10px;
`;

export default function IconTitle({
  iconName,
  className,
  title,
}: IIconTitleProps): JSX.Element {
  const statusIcon = {
    [ICON_NAME.ORDER]: <StyledOrderedListOutlined />,
    [ICON_NAME.SERVICE]: <StyledFileDoneOutlined />,
    [ICON_NAME.COUNTERPARTY]: <StyledCounterparty />,
    [ICON_NAME.EMPLOYEE]: <StyledContactsOutlined />,
    [ICON_NAME.PAYMENT]: <StyledDollarCircleOutlined />,
    [ICON_NAME.CATEGORY]: <StyledContainerOutlined />,
    [ICON_NAME.USER]: <StyledUserOutlined />,
    [ICON_NAME.GROUP_PAYMENT]: <StyledGroupOutlined />,
    [ICON_NAME.TEMPLATE]: <StyledFileOutlined />,
    [ICON_NAME.GOOGLE_CALENDAR]: <StyledGoogleOutlined />,
    [ICON_NAME.DEFAULT]: <StyledHomeOutlined />,
  };

  return (
    <StatusFlexContainer
      className={className}
      alignItems="center"
      flexDirection="row">
      {statusIcon[iconName || ICON_NAME.DEFAULT]}
      <StyledTextFormat>{title}</StyledTextFormat>
    </StatusFlexContainer>
  );
}
