import {
  IServiceDTOProps,
  ServiceDTO,
  ServiceMapper,
  IGroupModelReturnType,
} from '../internal';
import {WithoutRecursion} from '../type';

export interface IGroupDTOProps {
  uuid: string;
  description: string;
  title: string;
  created_at: string;
  updated_at: string;
  status: number;
  services: IServiceDTOProps[];
}

export class GroupDTO {
  public uuid: string;

  public description: string;

  public title: string;

  public created_at: string;

  public updated_at: string;

  public status: number;

  public services: ServiceDTO[];

  constructor(
    props: IGroupModelReturnType | IGroupDTOProps | GroupDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';
    this.description = props?.description || '';
    this.title = props?.title || '';
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
    this.status = props?.status || 0;
    this.services = withoutRecursion.indexOf(WithoutRecursion.service)
      ? (props?.services as ServiceDTO[])
      : ServiceMapper.toServiceListDTO(props?.services || [], 0, [
          WithoutRecursion.group,
          ...withoutRecursion,
        ]).services;
  }
}
