import React, {useCallback} from 'react';
import {UserOperationModel} from '../../../struture';
import {textToUpperCase, toMomentString} from '../../../services/helpers';
import {Table} from '../../../components/lib/libV2/DataDisplay';
import {Empty} from 'antd';
import styled, {css} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useStoredProfile, useUserOperationList} from '../../../hooks';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '../../../services/types';
import {HeaderLeft} from '../../../components/lib/DataDisplay';
import {ListLayout} from '../../../components/lib/Layout';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import {useNavigate} from 'react-router';
import {ProfileHeader} from '../Show';

const StyledColorView = styled.div<{operation?: string}>`
  color: ${({theme}) => theme.colors.green};

  ${({operation}) =>
    operation === 'OUT' &&
    css`
      color: ${({theme}) => theme.colors.error};
    `}
`;

const StyledHeader = styled.div`
  margin-bottom: 20px;
`;

export function ProfileOperationListPage() {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {profile} = useStoredProfile();

  const {
    entityList,
    limit = 10,
    loading,
    refresh = async () => {},
    total,
  } = useUserOperationList({
    loadOnInit: true,
  });

  const routes = useBreadcrumb([
    {
      path: `/${Routes.profile}`,
      breadcrumbName: 'Profile',
    },
    {
      path: `/${Routes.profile}`,
      breadcrumbName: profile?.fullName || 'Name',
    },
    {
      path: `/${Routes.operations}`,
      breadcrumbName: 'Operations on a personal account',
    },
  ]);

  const columns = [
    {
      title: t('Date'),
      key: 'date',
      render: (operation: UserOperationModel) => (
        <StyledColorView operation={operation?.operation_type}>
          {toMomentString(operation?.created_at, 'YYYY-MM-DD hh:mm')}
        </StyledColorView>
      ),
    },

    {
      title: t('Sum'),
      key: 'sum',
      render: (operation: UserOperationModel) => (
        <StyledColorView operation={operation?.operation_type}>
          {operation?.operation_type === 'OUT'
            ? `- ${operation?.operation_price} ${t('UAH')}`
            : `+ ${operation?.operation_price} ${t('UAH')}`}
        </StyledColorView>
      ),
    },

    {
      title: t('Description'),
      key: 'description',

      render: (operation: UserOperationModel) => (
        <StyledColorView operation={operation?.operation_type}>
          {operation?.operation_comment}
        </StyledColorView>
      ),
    },
  ];

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes?.profile}`);
  }, [navigate]);

  return (
    <ListLayout
      aclItem={RoutesAcl[Routes.employees]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.operations]))}
      routes={routes}
      empty={
        entityList?.size ? null : (
          <Empty
            description={t(
              `It looks like you don't have any operations at the moment.`,
            )}
          />
        )
      }
      loading={loading && !entityList?.size}>
      <StyledHeader>
        <ProfileHeader withoutUpload profile={profile} />
      </StyledHeader>
      <Table<UserOperationModel>
        loading={loading}
        dataSource={entityList}
        columns={columns}
        pageSize={limit}
        total={total}
        onChange={refresh}
      />
    </ListLayout>
  );
}
