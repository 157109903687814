import {List} from 'immutable';
import {
  EmployeeServiceDTO,
  IEmployeeServiceDTOProps,
  EmployeeServiceModel,
  EmployeeServiceListModel,
} from '../internal';
import {WithoutRecursion} from '../type';
import {listToArray} from '../../helpers';

export interface IEmployeeServiceListDTO {
  employeeServices: EmployeeServiceDTO[];
  total: number;
}

export class EmployeeServiceMapper {
  public static toEmployeeServiceDTO(
    employeeService: EmployeeServiceModel | IEmployeeServiceDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): EmployeeServiceDTO {
    return new EmployeeServiceDTO(employeeService as any, [
      WithoutRecursion.employeeService,
      ...withoutRecursion,
    ]);
  }

  public static toEmployeeServiceListDTO(
    employeeServices: List<EmployeeServiceModel> | IEmployeeServiceDTOProps[],
    total?: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): IEmployeeServiceListDTO {
    const employeeServiceList = List.isList(employeeServices)
      ? listToArray<EmployeeServiceModel>(employeeServices)
      : employeeServices;

    return {
      employeeServices: employeeServiceList?.map((service) =>
        EmployeeServiceMapper.toEmployeeServiceDTO(service, [
          WithoutRecursion.service,
          ...withoutRecursion,
        ]),
      ),
      total: total || 0,
    };
  }

  public static toEmployeeServiceModel(
    employeeServiceDTO: EmployeeServiceDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): EmployeeServiceModel {
    return new EmployeeServiceModel(employeeServiceDTO, [
      WithoutRecursion.employeeService,
      ...withoutRecursion,
    ]);
  }

  public static toEmployeeServiceListModel(
    employeeServiceDTOs: EmployeeServiceDTO[],
    total?: number,
    withoutRecursion: WithoutRecursion[] = [],
  ): EmployeeServiceListModel {
    return new EmployeeServiceListModel(
      {employeeServices: employeeServiceDTOs, total},
      [WithoutRecursion.service, ...withoutRecursion],
    );
  }
}
