import React from 'react';
import {useTranslation} from 'react-i18next';
import {ButtonProps} from 'antd/lib/button';
import styled from 'styled-components';
import ConfirmActionButton from '../../../components/lib/General/ConfirmActionButton';
import {TextFormat} from '../../../components/lib/Format';

export interface ICancelСlosingOrderButtonProps extends ButtonProps {
  onSuccess: () => Promise<void>;
  onCancel?: () => void;
  children?: React.ReactNode;
}

const StyledConfirmActionButton = styled(ConfirmActionButton)`
  background-color: ${({theme}) => theme.colors.warning};
  flex: 1;
`;

export default function CancelСlosingOrderButton({
  loading = false,
  onSuccess,
  children,
  ...rest
}: ICancelСlosingOrderButtonProps): JSX.Element {
  const {t} = useTranslation();

  return (
    <StyledConfirmActionButton
      tooltip={t('Cancel closing')}
      confirmTitle={
        <TextFormat breakWord>{t(`Are you sure cancel closing`)}</TextFormat>
      }
      onConfirm={onSuccess}
      loading={loading}
      cancelButtonText={t('Cancel')}
      data-testid="schedule__button--cancel-closing"
      {...rest}>
      {children || t('Cancel closing')}
    </StyledConfirmActionButton>
  );
}
