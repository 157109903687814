export enum IntegrationCategoryType {
  PRRO = 'prro',
}

export interface IntegrationCategoryDTO {
  uuid: string;
  title: string;
  description: string;
  help_html: string;
  created_at: string;
  updated_at: string;
}
