import {
  CompanyDTO,
  CompanyMapper,
  EmployeeDTO,
  EmployeeMapper,
  ICompanyDTOProps,
  IEmployeeDTOProps,
  IProductDTOProps,
  IServiceDTOProps,
  ProductDTO,
  ProductMapper,
  ServiceDTO,
  ServiceMapper,
  IExpenseModelReturnType,
} from '../internal';
import {WithoutRecursion} from '../type';
import {correctPrice} from '../../services/helpers';

export enum ExpenseDiscountType {
  Fixed = 'fixed',
  Percentage = 'percentage',
}

export enum ExpenseWorkableType {
  Product = 'Product',
  Service = 'Service',
}

export interface IExpenseDTOProps {
  uuid: string;
  name: string;
  amount: number;
  price: string;
  discount: number;
  total: string;
  discount_type: ExpenseDiscountType;
  comment: string;
  workable_type: ExpenseWorkableType;
  created_at: string;
  updated_at: string;
  workable: IProductDTOProps | IServiceDTOProps;
  created_by: IEmployeeDTOProps;
  manager: IEmployeeDTOProps;
  company: ICompanyDTOProps;
}

export class ExpenseDTO {
  public uuid: string;
  public name: string;
  public amount: number;
  public price: string;
  public discount: number;
  public total: string;
  public discount_type: ExpenseDiscountType;
  public comment: string;
  public workable_type: ExpenseWorkableType;
  public created_at: string;
  public updated_at: string;
  public workable: ProductDTO | ServiceDTO;
  public created_by: EmployeeDTO;
  public manager: EmployeeDTO;
  public company: ICompanyDTOProps;

  constructor(
    props: IExpenseModelReturnType | IExpenseDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';

    this.name = props?.name || '';
    this.amount = props?.amount || 1;
    this.price = correctPrice(props?.price || 0);
    this.discount = props?.discount || 0;
    this.total = correctPrice(props?.total || 0);
    this.discount_type = props?.discount_type || ExpenseDiscountType.Fixed;
    this.comment = props?.comment || '';
    this.workable_type = props?.workable_type || ExpenseWorkableType.Product;
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';

    this.workable =
      props?.workable_type === ExpenseWorkableType.Product
        ? ProductMapper.toProductDTO(props?.workable as IProductDTOProps)
        : ServiceMapper.toServiceDTO(props?.workable as IServiceDTOProps);

    this.created_by =
      withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
        ? (props?.created_by as EmployeeDTO)
        : EmployeeMapper.toEmployeeDTO(props?.created_by, [
            WithoutRecursion.product,
            ...withoutRecursion,
          ]);

    this.manager =
      withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
        ? (props?.manager as EmployeeDTO)
        : EmployeeMapper.toEmployeeDTO(props?.manager, [
            WithoutRecursion.product,
            ...withoutRecursion,
          ]);
    this.company =
      withoutRecursion.indexOf(WithoutRecursion.company) !== -1
        ? (props?.company as CompanyDTO)
        : CompanyMapper.toCompanyDTO(props?.company, [
            WithoutRecursion.product,
            ...withoutRecursion,
          ]);
  }
}
