export enum PRODUCT_CATEGORY_LIST {
  SET_PRODUCT_CATEGORY_LIST = 'set_product_category_list',
  SET_PRODUCT_CATEGORY_LIST_TREE = 'set_product_category_list_tree',
  SET_PRODUCT_CATEGORY_LIST_STATS = 'set_product_category_list_stats',
  ADD_PRODUCT_CATEGORY_LIST_STATS = 'add_product_category_list_stats',
  SUB_PRODUCT_CATEGORY_LIST_STATS = 'sub_product_category_list_stats',
  LOADING_PRODUCT_CATEGORY_LIST = 'loading_product_category_list',
  ERROR_PRODUCT_CATEGORY_LIST = 'error_product_category_list',
  LOAD_MORE_PRODUCT_CATEGORY_LIST = 'load_more_product_category_list',
  ADD_PRODUCT_CATEGORY = 'add_product_category',
  UPDATE_PRODUCT_CATEGORY = 'update_product_category',
  DELETE_PRODUCT_CATEGORY = 'delete_product_category',

  ADD_PRODUCT_CATEGORY_TREE = 'add_product_category_tree',
  UPDATE_PRODUCT_CATEGORY_TREE = 'update_product_category_tree',
  DELETE_PRODUCT_CATEGORY_TREE = 'delete_product_category_tree',
}
