import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {SideWindow} from '../../../components/lib/Navigation';
import {GroupPaymentForm, IGroupPaymentFormProps} from '../Forms';
import {GroupPaymentFormDTO} from '../../../struture';

export default withTranslation()(GroupPaymentSideWindow);

export interface IGroupPaymentSideWindowProps
  extends WithTranslation,
    IGroupPaymentFormProps {
  onSuccess: (value: GroupPaymentFormDTO) => Promise<void>;
  visible: boolean;
}

function GroupPaymentSideWindow({
  t,
  onSuccess,
  visible,
  onCancel,
  editMode,
  ...rest
}: IGroupPaymentSideWindowProps) {
  return (
    <SideWindow
      visible={visible}
      title={t(editMode ? 'Edit payment group' : 'Create payment group')}
      onClose={onCancel}
      data-testid="create-service-form-side-window">
      <GroupPaymentForm onSuccess={onSuccess} onCancel={onCancel} {...rest} />
    </SideWindow>
  );
}
