import {List} from 'immutable';
import {APP_STATE, PRODUCT_LIST} from '../constants';
import {compose, isEqualByUuid, not} from '../../services/helpers';
import {ApiError, ProductType, REDUX_STATUS} from '../../services/types';
import {IProductsListStatsProps, ProductModel} from '../../struture';

interface IProductListState {
  productList: List<ProductModel> | null;
  error: ApiError | null;
  loading: boolean;
  status: REDUX_STATUS;
  keywords?: string;
  total: number;
  page?: number;
  stats?: IProductsListStatsProps;
}

interface SetActionList
  extends Pick<
    IProductListState,
    'productList' | 'keywords' | 'total' | 'page' | 'stats'
  > {
  type: PRODUCT_LIST.SET_PRODUCT_LIST;
}

interface SetStatsActionList extends Pick<IProductListState, 'stats'> {
  type: PRODUCT_LIST.SET_PRODUCT_LIST_STATS;
}

interface StatsActionList {
  type:
    | PRODUCT_LIST.ADD_PRODUCT_LIST_STATS
    | PRODUCT_LIST.SUB_PRODUCT_LIST_STATS;
}

interface SetInitialStateAction {
  type: APP_STATE.SET_INITIAL_STATE;
}

interface AddActionToList {
  type: PRODUCT_LIST.ADD_PRODUCT;
  product: ProductModel;
  stats: IProductsListStatsProps;
}

interface UpdateProductFromList {
  type: PRODUCT_LIST.UPDATE_PRODUCT;
  product: ProductModel;
}

interface DeleteActionFromList {
  type: PRODUCT_LIST.DELETE_PRODUCT;
  productUuid: string;
  stats: IProductsListStatsProps;
}

interface LoadingActionInList {
  type: PRODUCT_LIST.LOADING_PRODUCT_LIST;
}

interface ErrorActionInList extends Pick<IProductListState, 'error'> {
  type: PRODUCT_LIST.ERROR_PRODUCT_LIST;
}

interface Handlers {
  [PRODUCT_LIST.SET_PRODUCT_LIST]: (
    state: IProductListState,
    action: SetActionList,
  ) => IProductListState;
  [PRODUCT_LIST.SET_PRODUCT_LIST_STATS]: (
    state: IProductListState,
    action: SetStatsActionList,
  ) => IProductListState;

  [PRODUCT_LIST.ADD_PRODUCT_LIST_STATS]: (
    state: IProductListState,
    action: StatsActionList,
  ) => IProductListState;

  [PRODUCT_LIST.SUB_PRODUCT_LIST_STATS]: (
    state: IProductListState,
    action: StatsActionList,
  ) => IProductListState;

  [APP_STATE.SET_INITIAL_STATE]: (
    state: IProductListState,
    action: SetInitialStateAction,
  ) => IProductListState;

  [PRODUCT_LIST.LOAD_MORE_PRODUCT_LIST]: (
    state: IProductListState,
    action: SetActionList,
  ) => IProductListState;

  [PRODUCT_LIST.ADD_PRODUCT]: (
    state: IProductListState,
    action: AddActionToList,
  ) => IProductListState;

  [PRODUCT_LIST.UPDATE_PRODUCT]: (
    state: IProductListState,
    action: UpdateProductFromList,
  ) => IProductListState;

  [PRODUCT_LIST.DELETE_PRODUCT]: (
    state: IProductListState,
    action: DeleteActionFromList,
  ) => IProductListState;

  [PRODUCT_LIST.ERROR_PRODUCT_LIST]: (
    state: IProductListState,
    value: ErrorActionInList,
  ) => IProductListState;

  [PRODUCT_LIST.LOADING_PRODUCT_LIST]: (
    state: IProductListState,
    value?: LoadingActionInList,
  ) => IProductListState;
  DEFAULT: (state: IProductListState) => IProductListState;
}

const initState: IProductListState = {
  productList: null,
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
  keywords: '',
  total: 0,
  stats: {} as IProductsListStatsProps,
};

const handlers: Handlers = {
  [PRODUCT_LIST.SET_PRODUCT_LIST]: (
    state,
    {productList, keywords, total, page, stats},
  ) => ({
    ...state,
    ...{
      productList,
      status: REDUX_STATUS.SUCCEEDED,
      keywords: keywords || '',
      total: total >= 0 ? total : state.total,
      page: page || state?.page,
      stats: stats || state?.stats,
    },
  }),

  [PRODUCT_LIST.SET_PRODUCT_LIST_STATS]: (state, {stats}) => ({
    ...state,
    ...{
      stats: stats || state?.stats,
    },
  }),

  [PRODUCT_LIST.ADD_PRODUCT_LIST_STATS]: (state) => ({
    ...state,
    ...{
      stats: {
        ...state?.stats,
        [ProductType.Category]:
          (Number((state?.stats || {})[ProductType.Category]) || 0) + 1,
      } as any,
    },
  }),

  [PRODUCT_LIST.SUB_PRODUCT_LIST_STATS]: (state) => ({
    ...state,
    ...{
      stats: {
        ...state?.stats,
        [ProductType.Category]:
          Number((state?.stats || {})[ProductType.Category]) &&
          Number((state?.stats || {})[ProductType.Category]) > 0
            ? Number((state?.stats || {})[ProductType.Category]) - 1
            : 0,
      } as any,
    },
  }),

  [APP_STATE.SET_INITIAL_STATE]: () => initState,

  [PRODUCT_LIST.LOAD_MORE_PRODUCT_LIST]: (state, {productList}) => ({
    ...state,
    ...{
      productList:
        List.isList(state.productList) && List.isList(productList)
          ? state.productList.merge(productList)
          : state.productList,
      status: REDUX_STATUS.SUCCEEDED,
    },
  }),

  [PRODUCT_LIST.ADD_PRODUCT]: (state, {product, stats}) => ({
    ...state,
    ...{
      productList: List.isList(state.productList)
        ? state.productList.size >= 10
          ? state.productList.pop().unshift(product)
          : state.productList.unshift(product)
        : List([product]),
      status: REDUX_STATUS.SUCCEEDED,
      total: state.total + 1,
      stats: stats || state?.stats,
    },
  }),

  [PRODUCT_LIST.UPDATE_PRODUCT]: (state, {product}) => {
    return {
      ...state,
      ...{
        productList: List.isList(state.productList)
          ? state.productList.map((stateProduct) => {
              if (stateProduct?.uuid === product?.uuid) {
                return product;
              }
              return stateProduct;
            })
          : null,
        status: REDUX_STATUS.SUCCEEDED,
      },
    };
  },

  [PRODUCT_LIST.DELETE_PRODUCT]: (state, {productUuid, stats}) => ({
    ...state,
    ...{
      productList: List.isList(state.productList)
        ? state.productList.filter(compose(not, isEqualByUuid(productUuid)))
        : state.productList,
      status: REDUX_STATUS.SUCCEEDED,
      total: state.total > 0 ? state.total - 1 : 0,
      stats: stats || state?.stats,
    },
  }),

  [PRODUCT_LIST.ERROR_PRODUCT_LIST]: (
    state: IProductListState,
    {error}: ErrorActionInList,
  ) => ({
    ...state,
    ...{
      error,
      loading: false,
      status: REDUX_STATUS.FAILED,
    },
  }),

  [PRODUCT_LIST.LOADING_PRODUCT_LIST]: (state: IProductListState) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IProductListState) => state,
};

export default function ProductList(
  state: any = initState,
  action: any,
): IProductListState {
  const handler = handlers[action.type as PRODUCT_LIST] || handlers.DEFAULT;
  return handler(state, action);
}
