import React from 'react';
import {Routes, Route} from 'react-router';
import {AppRouteLayout} from '../components/lib/Layout';
import {
  StoreListPage,
  StorePage,
  StorePostingListPage,
  StorePostingPage,
  StoreMovingListPage,
  StoreMovingPage,
  StoreWriteOffListPage,
  StoreWriteOffPage,
  StoreRealizationListPage,
  StoreRealizationPage,
  StoreReturnListPage,
  StoreReturnPage,
  LeftoverListPage,
  StoreSaleListPage,
  StoreReturnClientListPage,
  StoreReturnClientPage,
  StorePreorderListPage,
  StorePreorderPage,
} from '../views/Stores';
import {Routes as RoutesType} from '../services/types';
import {PaymentRoutesNavigator} from './PaymentRoutesNavigator';
import {StoreRelatedRoutesNavigator} from './StoreRelatedRoutesNavigator';
import {StoreSaleRoutesNavigator} from './StoreSaleRoutesNavigator';

export function StoreRoutesNavigator() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AppRouteLayout>
            <StoreListPage />
          </AppRouteLayout>
        }
      />
      <Route
        path=":storeId"
        element={
          <AppRouteLayout>
            <StorePage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`/${RoutesType.storePosting}`}
        element={
          <AppRouteLayout>
            <StorePostingListPage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`/${RoutesType.storePosting}/${RoutesType.storeShowOnly}/:show_only`}
        element={
          <AppRouteLayout>
            <StorePostingListPage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`/${RoutesType.storePosting}/:documentId`}
        element={
          <AppRouteLayout>
            <StorePostingPage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`/${RoutesType.storePosting}/:documentId/${RoutesType.payments}/*`}
        element={<PaymentRoutesNavigator />}
      />
      <Route
        path={`/${RoutesType.storePosting}/:documentId/${RoutesType.storeRelated}/*`}
        element={<StoreRelatedRoutesNavigator />}
      />

      <Route
        path={`/${RoutesType.storeOrderProvider}`}
        element={
          <AppRouteLayout>
            <StorePreorderListPage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`/${RoutesType.storeOrderProvider}/${RoutesType.storeShowOnly}/:show_only`}
        element={
          <AppRouteLayout>
            <StorePreorderListPage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`/${RoutesType.storeOrderProvider}/:documentId`}
        element={
          <AppRouteLayout>
            <StorePreorderPage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`/${RoutesType.storeOrderProvider}/:documentId/${RoutesType.payments}/*`}
        element={<PaymentRoutesNavigator />}
      />
      <Route
        path={`/${RoutesType.storeOrderProvider}/:documentId/${RoutesType.storeRelated}/*`}
        element={<StoreRelatedRoutesNavigator />}
      />

      <Route
        path={`/${RoutesType.storeMoving}`}
        element={
          <AppRouteLayout>
            <StoreMovingListPage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`/${RoutesType.storeMoving}/:documentId`}
        element={
          <AppRouteLayout>
            <StoreMovingPage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`/${RoutesType.storeMoving}/${RoutesType.storeShowOnly}/:show_only`}
        element={
          <AppRouteLayout>
            <StoreMovingListPage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`/${RoutesType.storeMoving}/:documentId/${RoutesType.payments}/*`}
        element={<PaymentRoutesNavigator />}
      />
      <Route
        path={`/${RoutesType.storeMoving}/:documentId/${RoutesType.storeRelated}/*`}
        element={<StoreRelatedRoutesNavigator />}
      />
      <Route
        path={`/${RoutesType.storeWriteOff}`}
        element={
          <AppRouteLayout>
            <StoreWriteOffListPage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`/${RoutesType.storeWriteOff}/:documentId`}
        element={
          <AppRouteLayout>
            <StoreWriteOffPage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`/${RoutesType.storeWriteOff}/${RoutesType.storeShowOnly}/:show_only`}
        element={
          <AppRouteLayout>
            <StoreWriteOffListPage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`/${RoutesType.storeWriteOff}/:documentId/${RoutesType.payments}/*`}
        element={<PaymentRoutesNavigator />}
      />
      <Route
        path={`/${RoutesType.storeWriteOff}/:documentId/${RoutesType.storeRelated}/*`}
        element={<StoreRelatedRoutesNavigator />}
      />
      <Route
        path={`/${RoutesType.storeRealization}`}
        element={
          <AppRouteLayout>
            <StoreRealizationListPage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`/${RoutesType.storeRealization}/:documentId`}
        element={
          <AppRouteLayout>
            <StoreRealizationPage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`/${RoutesType.storeRealization}/${RoutesType.storeShowOnly}/:show_only`}
        element={
          <AppRouteLayout>
            <StoreRealizationListPage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`/${RoutesType.storeRealization}/:documentId/${RoutesType.payments}/*`}
        element={<PaymentRoutesNavigator />}
      />
      <Route
        path={`/${RoutesType.storeRealization}/:documentId/${RoutesType.storeRelated}/*`}
        element={<StoreRelatedRoutesNavigator />}
      />
      <Route
        path={`/${RoutesType.storeReturn}`}
        element={
          <AppRouteLayout>
            <StoreReturnListPage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`/${RoutesType.storeReturn}/:documentId`}
        element={
          <AppRouteLayout>
            <StoreReturnPage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`/${RoutesType.storeReturn}/${RoutesType.storeShowOnly}/:show_only`}
        element={
          <AppRouteLayout>
            <StoreReturnListPage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`/${RoutesType.storeReturn}/:documentId/${RoutesType.payments}/*`}
        element={<PaymentRoutesNavigator />}
      />
      <Route
        path={`/${RoutesType.storeReturn}/:documentId/${RoutesType.storeRelated}/*`}
        element={<StoreRelatedRoutesNavigator />}
      />
      <Route
        path={`/${RoutesType.storeReturnClient}`}
        element={
          <AppRouteLayout>
            <StoreReturnClientListPage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`/${RoutesType.storeReturnClient}/:documentId`}
        element={
          <AppRouteLayout>
            <StoreReturnClientPage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`/${RoutesType.storeReturnClient}/${RoutesType.storeShowOnly}/:show_only`}
        element={
          <AppRouteLayout>
            <StoreReturnClientPage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`/${RoutesType.storeReturnClient}/:documentId/${RoutesType.payments}/*`}
        element={<PaymentRoutesNavigator />}
      />
      <Route
        path={`/${RoutesType.storeReturnClient}/:documentId/${RoutesType.storeRelated}/*`}
        element={<StoreRelatedRoutesNavigator />}
      />
      <Route
        path={`/${RoutesType.storeLeftovers}`}
        element={
          <AppRouteLayout>
            <LeftoverListPage />
          </AppRouteLayout>
        }
      />

      <Route
        path={`/${RoutesType.storeSale}/*`}
        element={<StoreSaleRoutesNavigator />}
      />
    </Routes>
  );
}
