import React, {useEffect} from 'react';
import {isFunction} from '../../../services/helpers';
import {ChildrenMustBeAFunction} from '../../../components/lib/Errors';
import {IAuthRouteItemProps} from '../../Authorization';
import {WithTranslation} from 'react-i18next';
import {Routes} from '../../../services/types';
import {useLocation, useNavigate} from 'react-router';

interface ITabChildrenProps {
  routes: Omit<IAuthRouteItemProps, keyof WithTranslation>[];
}

export interface IAppIntroTabPanelProps {
  children: (value: ITabChildrenProps) => React.ReactNode;
  showForgotPasswordWarning?: boolean;
  showSignupWarning?: boolean;
}

export enum APP_INTRO_TABS {
  DASHBOARD = 'Dashboard',
}

export function AppIntroTabPanel({
  children,
  showSignupWarning,
  showForgotPasswordWarning,
}: IAppIntroTabPanelProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const [routes, setRoutes] = React.useState<
    Omit<IAuthRouteItemProps, keyof WithTranslation>[]
  >([]);

  useEffect(() => {
    setRoutes([]);
  }, [
    location.pathname,
    location.state,
    navigate,
    showForgotPasswordWarning,
    showSignupWarning,
  ]);

  if (!isFunction(children)) {
    throw new ChildrenMustBeAFunction('AppIntroTabPanel');
  }

  return <>{children({routes})}</>;
}
