import React, {useMemo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {Form} from 'antd';
import {IDefaultFormProps, DefaultForm} from '../../../components/lib/General';
import {RewardPaymentFormDTO} from '../../../struture';
import {useDropdownAlert} from '../../../contex';
import {
  useStateEmployeeList,
  useStateGroupPaymentList,
  useStoredCashBoxList,
  useStoredCompanies,
} from '../../../hooks';
import {RewardPaymentFormFields} from './RewardPaymentFormFields';

export interface IRewardPaymentFormProps
  extends Omit<
    IDefaultFormProps<RewardPaymentFormDTO, RewardPaymentFormDTO>,
    'children' | 'initialValues' | 'additionalValuesRequest'
  > {
  loading?: boolean;
  payment: RewardPaymentFormDTO;
}

export function RewardPaymentForm({
  loading,
  editMode,
  payment,
  onCancel,
  ...rest
}: IRewardPaymentFormProps) {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const {defaultCompanyUuid} = useStoredCompanies();
  const [instance] = Form.useForm();

  const additionalValues = useMemo(
    () => ({
      uuid: payment?.uuid,
    }),
    [payment?.uuid],
  );

  const {
    employees,
    loading: employeeLoading,
    handleSearchEmployees,
  } = useStateEmployeeList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const {
    cashBoxList,
    loading: cashBoxListLoading,
    handleSearchCashBoxes,
  } = useStoredCashBoxList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const {
    groupPayments,
    loading: groupPaymentsLoading,
    handleSearchGroupPayments,
  } = useStateGroupPaymentList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
  });

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        t('Reward'),
        `${
          editMode
            ? t('An error occurred during edit payment')
            : t('An error occurred during create payment')
        } : ${apiError?.message}`,
      );
    },
    [alert, t, editMode],
  );

  return (
    <DefaultForm<any, any>
      withContext
      instance={instance}
      editMode={editMode}
      initialValues={{...payment}}
      additionalValuesRequest={additionalValues}
      showNotify={false}
      onCancel={onCancel}
      notifyError={notifyError}
      {...rest}>
      <RewardPaymentFormFields
        loading={!!loading}
        employees={employees}
        employeesLoading={employeeLoading}
        handleSearchEmployees={handleSearchEmployees}
        cashBoxList={cashBoxList}
        cashBoxListLoading={cashBoxListLoading}
        handleSearchCashBoxes={handleSearchCashBoxes}
        groupPayments={groupPayments}
        groupPaymentsLoading={groupPaymentsLoading}
        handleSearchGroupPayments={handleSearchGroupPayments}
      />
    </DefaultForm>
  );
}
