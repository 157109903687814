import React, {forwardRef, useCallback} from 'react';
import PhoneInput, {CountryData, PhoneInputProps} from 'react-phone-input-2';
import {isFunction} from '../../../services/helpers';
import styled, {css} from 'styled-components';

import 'react-phone-input-2/lib/style.css';
import './PhoneComponent.less';

export interface IPhoneInputComponentProps extends PhoneInputProps {
  loading?: boolean;
  id: string;
  dataTestid?: string;
  className?: string;
  size?: 'default' | 'large';
}

const StyledPhoneInput = styled(PhoneInput)<{$size?: 'default' | 'large'}>`
  ${({$size}) =>
    $size === 'large' &&
    css`
      height: 40px;

      & > input {
        height: 40px !important;
      }
    `}
`;

/**
 * @desc Компонент для виводу
 * */
export default forwardRef(
  (
    {
      loading = false,
      id = '',
      dataTestid = '',
      placeholder = '',
      searchClass = 'phone-input',
      inputClass = 'phone-input ant-input',
      containerClass = 'phone-routes',
      buttonClass = 'field-flag-dropdown',
      value = '',
      className,
      onChange,
      size = 'default',
      ...rest
    }: IPhoneInputComponentProps,
    ref,
  ): JSX.Element => {
    const handleOnChange = useCallback(
      (
        phone: string,
        data: CountryData | {},
        event: React.ChangeEvent<HTMLInputElement>,
        formattedValue: string,
      ) => {
        if (isFunction(onChange)) {
          if (phone.startsWith('+')) {
            onChange(phone, data, event, formattedValue);
          } else {
            onChange(`+${phone}`, data, event, formattedValue);
          }
        }
      },
      [onChange],
    );

    return (
      <StyledPhoneInput
        $size={size}
        autoFormat
        enableSearch
        disableSearchIcon
        searchClass={searchClass}
        searchPlaceholder={placeholder}
        inputProps={{
          id,
          'data-testid': dataTestid,
          disabled: loading,
          ref,
        }}
        disabled={loading}
        disableCountryCode={loading}
        disableDropdown={loading}
        inputClass={inputClass}
        containerClass={`${containerClass} ${className}`}
        country="ua"
        value={value}
        buttonClass={buttonClass}
        placeholder={placeholder}
        onChange={handleOnChange}
        {...rest}
      />
    );
  },
);
