import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Col, Form, Input, Row} from 'antd';
import {DatePicker} from '../../../components/lib/General';
import {
  DetailsView,
  IDetailsViewItemProps,
  SearchSelect,
  Segmented,
} from '../../../components/lib/DataDisplay';
import {isListToArray} from '../../../services/helpers';
import {FROM_PAYMENT_METHOD_WITH_IN_DEBT} from '../../Payments/Forms/PaymentScheduleFields';
import {convertedPaymentMethodToCashBox} from '../../../services/api/companyPayments';
import {
  CashBoxModel,
  ClientModel,
  EmployeeModel,
  PAYMENT_METHOD,
} from '../../../struture';
import {
  IUseStateCashBoxListReturnType,
  IUseStateEmployeeListReturnType,
  useStoredCompanies,
} from '../../../hooks';
import {useTranslation} from 'react-i18next';
import {useDefaultForm} from '../../../contex';
import {List} from 'immutable';
import {ClientDetailsView} from '../../Clients';
import styled, {css} from 'styled-components';
import {CashBoxTitleView} from '../../Payments/Show/CashBoxTitleView';

export interface ICloseDocumentFormFieldsProps
  extends Pick<
      IUseStateEmployeeListReturnType,
      'employees' | 'handleSearchEmployees'
    >,
    Pick<
      IUseStateCashBoxListReturnType,
      'cashBoxList' | 'handleSearchCashBoxes'
    > {
  loading: boolean;
  employeesLoading: boolean;
  cashBoxListLoading: boolean;
  cashierUuid: string;
  defaultComment: string;
}

const StyledClientCol = styled(Col)`
  margin-bottom: 20px;
`;

const StyledPriceInput = styled(Input)<{disabled: boolean}>`
  ${({disabled, theme}) =>
    disabled &&
    css`
      & .ant-input-group-addon,
      &&& input {
        color: ${theme.colors.black};
        font-size: 25px;
      }
    `}
`;

export function StorePaymentDocumentFormFields({
  loading,

  employees,
  employeesLoading,
  handleSearchEmployees,

  cashBoxList,
  cashBoxListLoading,
  handleSearchCashBoxes,
  cashierUuid,
  defaultComment,
}: ICloseDocumentFormFieldsProps): React.JSX.Element {
  const {t} = useTranslation();
  const {loadingSubmit, formData, handlerUpdateFormState}: any =
    useDefaultForm();
  const {defaultCompany} = useStoredCompanies();

  const [resetEmployeeState, setResetEmployeeState] = useState(false);
  const [resetCashBoxState, setResetCashBoxState] = useState(false);
  const [client, setClient] = useState<ClientModel | null>(null);

  const once = useRef(false);

  const handleChangePaymentMethod = useCallback(
    (payment_method: any) => {
      handlerUpdateFormState({payment_method});

      if (typeof formData?.payment_method === 'string') {
        handlerUpdateFormState({
          payment_cashbox_uuid: '',
        });

        if (defaultComment) {
          if (payment_method === PAYMENT_METHOD.Account) {
            const [_, ...endComment] = defaultComment?.split(' ');
            handlerUpdateFormState({
              payment_comment: `${t('Debt')} ${endComment.join(' ')}`,
            });
          } else {
            handlerUpdateFormState({payment_comment: defaultComment});
          }
        }

        if (once.current) {
          handlerUpdateFormState({
            payment_manager_uuid: cashierUuid,
          });
          setResetEmployeeState(true);
          setResetCashBoxState(true);
          setTimeout(() => {
            setResetEmployeeState(false);
            setResetCashBoxState(false);
          }, 100);
        }
      }
    },
    [
      cashierUuid,
      defaultComment,
      formData?.payment_method,
      handlerUpdateFormState,
      t,
    ],
  );

  const handleChangeCashBox = useCallback(
    (payment_cashbox_uuid: string) => {
      handlerUpdateFormState({payment_cashbox_uuid});

      if (List.isList(cashBoxList)) {
        const cashBox = cashBoxList?.find(
          ({uuid}) => uuid === payment_cashbox_uuid,
        );

        if (cashBox) {
          once.current = true;
          handlerUpdateFormState({
            payment_manager_uuid: cashBox?.cashier?.uuid || cashierUuid,
          });
          setResetEmployeeState(true);
          setTimeout(() => {
            setResetEmployeeState(false);
          }, 100);
        }
      }
    },
    [cashBoxList, cashierUuid, handlerUpdateFormState],
  );

  useEffect(() => {
    if (formData?.payment_client_uuid instanceof ClientModel) {
      setClient(formData?.payment_client_uuid);
      handlerUpdateFormState({
        payment_client_uuid: formData?.payment_client_uuid?.uuid,
      });
    }
  }, [formData?.payment_client_uuid, handlerUpdateFormState]);

  const detailViewItems: IDetailsViewItemProps[] = [
    {
      title: 'Client',
      className: 'client-details__view',
    },
  ];

  return (
    <Row gutter={12}>
      <Col span={24}>
        <Form.Item
          label={t('Date and time')}
          name="payment_date"
          rules={[
            {
              required: true,
              message: t('Date and time must be specified'),
            },
          ]}
          tooltip={t('Date and time')}>
          <DatePicker
            showTime
            format={'DD.MM.YYYY HH:mm'}
            disabled={loading || loadingSubmit}
            placeholder={t('Select date and time')}
            onChange={(payment_date) => handlerUpdateFormState({payment_date})}
          />
        </Form.Item>
      </Col>

      {client ? (
        <StyledClientCol span={24}>
          <DetailsView items={detailViewItems}>
            <ClientDetailsView client={client} />
          </DetailsView>
        </StyledClientCol>
      ) : null}

      <Col span={24}>
        <Form.Item label={t('Total due')} name="payment_price" required>
          <StyledPriceInput
            addonBefore={defaultCompany?.currency_symbol}
            disabled
            onChange={(e) =>
              handlerUpdateFormState({payment_price: e?.target?.value})
            }
          />
        </Form.Item>
      </Col>

      {Number(formData?.payment_price) > 0 ? (
        <>
          <Col span={24}>
            <Form.Item
              label={t('Form of payment')}
              name="payment_method"
              rules={[
                {
                  required: true,
                  message: t('Form of payment must be specified'),
                },
              ]}
              tooltip={t('Form of payment')}>
              <Segmented
                options={
                  client
                    ? FROM_PAYMENT_METHOD_WITH_IN_DEBT
                    : FROM_PAYMENT_METHOD_WITH_IN_DEBT.filter(
                        ({uuid}) => uuid !== PAYMENT_METHOD.Account,
                      )
                }
                disabled={loadingSubmit}
                onChange={handleChangePaymentMethod}
              />
            </Form.Item>
          </Col>

          {formData?.payment_method === PAYMENT_METHOD.Account ? null : (
            <Col span={24}>
              <Form.Item
                label={t('Cash boxes')}
                name="payment_cashbox_uuid"
                rules={[
                  {
                    required: true,
                    message: t('Cash boxes must be specified'),
                  },
                ]}
                tooltip={t('Cash boxes')}>
                <SearchSelect
                  resetState={resetCashBoxState}
                  selectFirst={false}
                  onSearch={(keywords) =>
                    handleSearchCashBoxes({
                      keywords,
                      limit: 100,
                      showLoading: false,
                    })
                  }
                  getOptionValueTitle={(cashBox: CashBoxModel) => (
                    <CashBoxTitleView
                      cashBox={cashBox}
                      disable={loadingSubmit || cashBoxListLoading}
                    />
                  )}
                  name="payment_cashbox_uuid"
                  placeholder={t('Select a cash box')}
                  disable={loadingSubmit || cashBoxListLoading}
                  data={isListToArray(
                    cashBoxList?.filter(
                      ({box_type, box_can_out_money_from}) => {
                        return (
                          box_type ===
                            convertedPaymentMethodToCashBox[
                              formData?.payment_method as PAYMENT_METHOD
                            ] && box_can_out_money_from
                        );
                      },
                    ) as any,
                  )}
                  onChange={handleChangeCashBox}
                />
              </Form.Item>
            </Col>
          )}
        </>
      ) : null}

      <Col span={24}>
        <Form.Item
          label={t('Cashier')}
          name="payment_manager_uuid"
          rules={[
            {
              required: true,
              message: t('Cashier must be specified'),
            },
          ]}
          tooltip={t('Cashier')}>
          <SearchSelect
            resetState={resetEmployeeState}
            onSearch={(keywords) =>
              handleSearchEmployees({
                keywords,
                limit: 100,
                showLoading: false,
              })
            }
            getOptionValueTitle="fullName"
            name="payment_manager_uuid"
            placeholder={t('Select a cashier')}
            disable={loadingSubmit || employeesLoading}
            data={isListToArray(employees as any)}
            onChange={(payment_manager_uuid) =>
              handlerUpdateFormState({payment_manager_uuid})
            }
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          label={t('Note')}
          name="payment_comment"
          rules={[
            {
              required: true,
              message: t('Note must be specified'),
            },
          ]}>
          <Input.TextArea
            allowClear
            autoSize={{minRows: 2, maxRows: 5}}
            disabled={loading || loadingSubmit}
            placeholder={t('Enter your note')}
            onChange={(e) =>
              handlerUpdateFormState({payment_comment: e?.target?.value})
            }
          />
        </Form.Item>
      </Col>
    </Row>
  );
}
