import React, {forwardRef, useRef} from 'react';
import styled from 'styled-components';
import {StyledTitle} from '../../../components/lib/Styled';
import {useTranslation} from 'react-i18next';
import {
  IUseStateInvoiceReturnType,
  useSize,
  useStoredCompanies,
} from '../../../hooks';
import {Space} from 'antd';
import {OverviewBottomView} from '../../../components/lib/Layout';
import {InvoicePrintButton} from '../Buttons';

export interface IInvoiceBottomViewProps
  extends Pick<
    IUseStateInvoiceReturnType,
    | 'invoice'
    | 'handlePrintInputInvoice'
    | 'handlePrintInvoiceStoreRealization'
    | 'handlePrintInvoice'
  > {}

const StyledPriceContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
`;

const StyledPaidContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 5px;
`;

const StyledPrice = styled(StyledTitle)`
  font-size: 30px;
`;

const StyledSpace = styled(Space)`
  display: flex;
  justify-content: flex-start;
`;

export const InvoiceBottomView = forwardRef(function InvoiceBottomView(
  {
    invoice,
    handlePrintInvoice,
    handlePrintInvoiceStoreRealization,
    handlePrintInputInvoice,
  }: IInvoiceBottomViewProps,
  ref: any,
): JSX.Element {
  const {t} = useTranslation();
  const {defaultCompany} = useStoredCompanies();

  const contentRef = useRef(null);
  const {width: contentWidth} = useSize(contentRef);

  return (
    <div ref={ref}>
      <OverviewBottomView ref={contentRef}>
        <StyledSpace direction="horizontal" size="middle">
          <InvoicePrintButton
            invoice={invoice}
            handlePrintInvoice={handlePrintInvoice}
            handlePrintInvoiceStoreRealization={
              handlePrintInvoiceStoreRealization
            }
            handlePrintInputInvoice={handlePrintInputInvoice}
            contentWidth={contentWidth}
          />
        </StyledSpace>
        <StyledPriceContainer>
          <StyledTitle fontSize={14}>{`${t('Total')}: `}</StyledTitle>
          <StyledPaidContainer>
            <StyledPrice>{`${defaultCompany?.currency_symbol || ''} ${
              invoice?.inv_sum_total
            }`}</StyledPrice>
          </StyledPaidContainer>
        </StyledPriceContainer>
      </OverviewBottomView>
    </div>
  );
});
