import {Record, List} from 'immutable';
import {IListProps} from './List';
import {StoreDTO, StoreModel} from '../internal';
import {WithoutRecursion} from '../type';

export interface IStoreListModelProps extends IListProps {
  stores: StoreDTO[];
  keywords?: string;
  page?: number;
}

export interface IStoreListModelReturnType extends IListProps {
  stores: List<StoreModel>;
  keywords?: string;
  page?: number;
}

export class StoreListModel extends Record<IStoreListModelReturnType>({
  stores: List(),
  total: 0,
  keywords: '',
  page: 1,
}) {
  constructor(
    {stores = [], ...props}: IStoreListModelProps = {} as IStoreListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IStoreListModelReturnType = {
      ...props,
      stores: List(
        stores.map((store) => new StoreModel(store, withoutRecursion)),
      ),
    };

    super(options);
  }
}
