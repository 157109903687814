import {contentApiUrl} from '../const';
import Validate from '../validate/Validate';
import {apiDelete, apiGet, apiPost, apiPut} from '../core/api';
import {ApiAnswer, IEntityProps, IListSearchProps} from '../types';
import {
  IServiceGroupSessionDTOProps,
  ServiceGroupSessionDTO,
  ServiceGroupSessionFormDTO,
  ServiceGroupSessionMapper,
  IServiceGroupSessionListDTO,
} from '../../struture';
import {head, joinArr} from '../helpers';

export const GROUP_SESSION_INITIAL_PARAM = new ServiceGroupSessionFormDTO();

export interface IGroupSessionAnswer extends ApiAnswer {
  group_session: IServiceGroupSessionDTOProps;
}

export interface IGroupSessionListAnswer
  extends ApiAnswer,
    Partial<IListSearchProps>,
    IEntityProps {
  group_sessions: IServiceGroupSessionDTOProps[];
}

export interface IGroupSessionListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  serviceUuid: string;
}

// export const correctWeekDays = ({
//   weekdays,
//   ...rest
// }: IGroupSessionParam): IGroupSessionParam => ({
//   ...rest,
//   weekdays: joinArr(',')(weekdays as string[]),
// });

export async function getGroupSessionList({
  serviceUuid,
  limit = 10,
  offset = 0,
  keywords,
}: IGroupSessionListProps): Promise<IServiceGroupSessionListDTO> {
  const {group_sessions, total} = await apiGet<
    Partial<IListSearchProps>,
    IGroupSessionListAnswer
  >(`${contentApiUrl}/services/${serviceUuid}/group-sessions`, {
    limit,
    offset,
    keywords,
  });

  return ServiceGroupSessionMapper.toServiceGroupSessionListDTO(
    group_sessions,
    total,
  );
}

export async function getGroupSessionById(
  session_uuid: string,
): Promise<ServiceGroupSessionDTO> {
  const {group_session} = await apiGet<null, IGroupSessionAnswer>(
    `${contentApiUrl}/group-sessions/${session_uuid}`,
  );
  return ServiceGroupSessionMapper.toServiceGroupSessionDTO(group_session);
}

export async function createGroupSession(
  value: ServiceGroupSessionFormDTO,
): Promise<ServiceGroupSessionDTO> {
  const {
    uuid,
    title,
    description,
    start_date,
    end_date,
    weekdays,
    duration,
    price,
    max_attenders,
    status = 1,
    manager_id,
  } = value;

  Validate.string({value: title});
  Validate.string({value: uuid});

  const {group_session} = await apiPost<
    Omit<
      ServiceGroupSessionFormDTO,
      'uuid' | 'dateToString' | 'service_title' | 'end_date'
    >,
    IGroupSessionAnswer
  >(`${contentApiUrl}/services/${uuid}/group-sessions`, {
    title,
    description,
    status,
    start_date: value.dateToString(start_date),
    weekdays: joinArr(',')(weekdays as any),
    duration,
    price,
    max_attenders,
    manager_id,
  });

  return ServiceGroupSessionMapper.toServiceGroupSessionDTO(group_session);
}

export async function editGroupSession(
  value: ServiceGroupSessionFormDTO,
): Promise<ServiceGroupSessionDTO> {
  const {
    uuid,
    title,
    description,
    start_date,
    end_date,
    manager_id,
    weekdays,
    duration,
    price,
    max_attenders,
    status = 1,
  } = value;

  Validate.string({value: uuid});
  Validate.string({value: title});

  const {group_session} = await apiPut<
    Omit<
      ServiceGroupSessionFormDTO,
      'uuid' | 'dateToString' | 'service_title' | 'end_date'
    >,
    IGroupSessionAnswer
  >(`${contentApiUrl}/group-sessions/${uuid}`, {
    title,
    description,
    start_date: value.dateToString(start_date),
    duration,
    manager_id,
    weekdays: joinArr(',')(weekdays as any),
    price,
    max_attenders,
    status,
  });

  return ServiceGroupSessionMapper.toServiceGroupSessionDTO(group_session);
}

export async function deleteSessionById(
  ids: string[],
): Promise<ServiceGroupSessionDTO> {
  Validate.string({value: head(ids)});

  const {group_session} = await apiDelete<null, IGroupSessionAnswer>(
    `${contentApiUrl}/group-sessions/${head(ids)}`,
  );

  return ServiceGroupSessionMapper.toServiceGroupSessionDTO(group_session);
}
