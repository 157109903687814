import React, {useCallback} from 'react';
import {useTranslation, WithTranslation, withTranslation} from 'react-i18next';
import {Empty} from 'antd';
import {textToUpperCase, toMomentString} from '../../../services/helpers';
import {
  IUseStateNotificationListReturnType,
  useStateNotificationList,
  useStoredProfile,
} from '../../../hooks';
import {NotificationModel} from '../../../struture';
import {Table} from '../../../components/lib/libV2/DataDisplay';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '../../../services/types';
import {HeaderLeft} from '../../../components/lib/DataDisplay';

import {ListLayout} from '../../../components/lib/Layout';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import {useNavigate} from 'react-router';
import {ProfileHeader} from '../../Profile';
import styled from 'styled-components';

export interface INotificationPageProps
  extends WithTranslation,
    IUseStateNotificationListReturnType {}

const StyledHeader = styled.div`
  margin-bottom: 20px;
`;

export default function NotificationPage(): JSX.Element {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {profile} = useStoredProfile();

  const {loading, limit, refresh, total, notifications} =
    useStateNotificationList({
      loadOnInit: true,
    });

  const routes = useBreadcrumb([
    {
      path: `/${Routes.profile}`,
      breadcrumbName: 'Profile',
    },
    {
      path: `/${Routes.profile}`,
      breadcrumbName: profile?.fullName || 'Name',
    },
    {
      path: `/${Routes.notifications}`,
      breadcrumbName: 'Notifications',
    },
  ]);

  const columns = [
    {
      title: t('Date'),
      key: 'date',
      render: (notification: NotificationModel) =>
        toMomentString(notification?.date, 'YYYY-MM-DD hh:mm'),
    },
    {
      title: t('Message'),
      key: 'message',
      render: (notification: NotificationModel) => notification?.message,
    },
  ];

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes?.profile}`);
  }, [navigate]);

  return (
    <ListLayout
      aclItem={RoutesAcl[Routes.employees]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.notifications]))}
      routes={routes}
      empty={
        notifications?.size ? null : (
          <Empty
            description={t(
              `It looks like you don't have any notifications at the moment.`,
            )}
          />
        )
      }
      loading={loading && !notifications?.size}>
      <StyledHeader>
        <ProfileHeader withoutUpload profile={profile} />
      </StyledHeader>

      <Table<NotificationModel>
        pageSize={limit}
        total={total}
        onChange={refresh}
        dataSource={notifications}
        loading={loading}
        columns={columns}
      />
    </ListLayout>
  );
}
