import React from 'react';
import {isFunction} from '../../../services/helpers';
import {ChildrenMustBeAFunction} from '../../../components/lib/Errors';
import {IDefaultTabPanelProps, TabItem} from '../../../components/lib/General';
import {StoreModel} from '../../../struture';
import {useNavigate} from 'react-router';

export enum StoreTabs {}

export interface IStoreTabPanelProps {
  store: StoreModel | null;
  children: (value: Pick<IDefaultTabPanelProps, 'tabs'>) => React.ReactNode;
}

export function StoreTabPanel({
  children,
  store,
}: IStoreTabPanelProps): React.JSX.Element {
  const navigate = useNavigate();

  const storeTabs: TabItem[] = [];

  if (!isFunction(children)) {
    throw new ChildrenMustBeAFunction('LoadingWithEmptyData');
  }

  return <>{children({tabs: storeTabs})}</>;
}
