/**
 * @desc Додавання або видалення елементу з масиву
 * @param {Array} checked
 * @param {Array} checked
 * @param {String} unChecked
 * @return {Array}
 * */

export function checkBoxActive(checked, unChecked) {
  const selectedIndexId = checked.findIndex(
    (i) => i.service_uuid === unChecked.uuid,
  );

  const current = {
    service_uuid: unChecked.uuid,
    service_price: unChecked.price,
  };

  let newSelected = [];

  if (selectedIndexId === -1) {
    newSelected = [...checked, current];
  } else if (selectedIndexId === 0) {
    newSelected = [...checked.slice(1)];
  } else if (selectedIndexId === checked.length - 1) {
    newSelected = [...checked.slice(0, -1)];
  } else if (selectedIndexId > 0) {
    newSelected = [
      ...checked.slice(0, selectedIndexId),
      ...checked.slice(selectedIndexId + 1),
    ];
  }

  return newSelected;
}
