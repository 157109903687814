export const HTTP_CODE_SUCCESS = 200;
export const HTTP_CODE_SUCCESFULLY_CREATED = 201;
export const HTTP_CODE_ACCEPTED = 202;
export const HTTP_CODE_NO_CONTENT = 204;
export const HTTP_CODE_UNAUTHORIZED = 401;
export const HTTP_CODE_FORBIDDEN = 403;
export const HTTP_CODE_NOT_FOUND = 404;
export const HTTP_CODE_METHOD_NOT_ALLOWED = 405;
export const HTTP_CODE_GONE = 410;
export const HTTP_CODE_AUTHENTICATION_TIMEOUT = 419;
export const HTTP_CODE_ANPROCESSABLE_ENTITY = 422;
export const HTTP_CODE_TOKEN_INVALID = 498;
export const HTTP_CODE_NOT_ACCEPTABLE = 406;
export const HTTP_CODE_INTERNAL_SERVER_ERROR = 500;

/**
 * @desc Формування кода помилки
 * @param {Number} code
 * @return {Number}
 * */
export const getErrorCode = (code: number): number => {
  const supportedCodes = [
    HTTP_CODE_SUCCESS,
    HTTP_CODE_SUCCESFULLY_CREATED,
    HTTP_CODE_UNAUTHORIZED,
    HTTP_CODE_FORBIDDEN,
    HTTP_CODE_NOT_FOUND,
    HTTP_CODE_METHOD_NOT_ALLOWED,
    HTTP_CODE_AUTHENTICATION_TIMEOUT,
    HTTP_CODE_ANPROCESSABLE_ENTITY,
    HTTP_CODE_TOKEN_INVALID,
    HTTP_CODE_NOT_ACCEPTABLE,
    HTTP_CODE_INTERNAL_SERVER_ERROR,
  ];

  return (
    supportedCodes.find((c) => c === code) || HTTP_CODE_INTERNAL_SERVER_ERROR
  );
};
