import React from 'react';
import {Row, Col} from 'antd';
import classNames from 'classnames';
import {
  IMonthFormat,
  getDayOfMonth,
  calculateColSpan,
} from '../../../services/helpers';

import './CalendarWeekDayHeader.less';

export interface ICalendarWeekDayHeaderProps {
  dates: IMonthFormat[];
  handleChooseDate?: (date: Date) => void;
}

export default function CalendarWeekDayHeader({
  dates,
  handleChooseDate = () => {},
}: ICalendarWeekDayHeaderProps): JSX.Element {
  return (
    <Row align="middle" className="calendar-item-header">
      <Col span={2} className="calendar-item-header-button-plus" />
      {dates.map(({date, weekday, isToday}) => (
        <Col
          key={`${date} ${weekday}`}
          span={calculateColSpan(22, dates)}
          className="calendar-item-header-col-container">
          <div className="calendar-item-header-col-date">
            <span>{weekday}</span>
            <div
              role="button"
              onClick={() => handleChooseDate(date)}
              className={classNames('calendar-item-header-date', {
                'calendar-item-header-date--today': isToday,
              })}>
              <h3 className="calendar-item-header-date-heading">
                {getDayOfMonth(date)}
              </h3>
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
}
