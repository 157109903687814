import * as React from 'react';
import {List} from 'immutable';
import {Moment} from 'moment';
import {
  useSchedulePaymentList,
  IUseSchedulePaymentListProps,
  IUseSchedulePaymentListReturnType,
} from './useSchedulePaymentList';
import {
  PaymentModel,
  PaymentFormDTO,
  PaymentMapper,
  PaymentScheduleFormDTO,
} from '../struture';
import {useStateEntityList} from '../components/lib/libV2/hooks';
import {
  debounce,
  head,
  last,
  dateToIsoString,
  isFunction,
  correctPrice,
} from '../services/helpers';
import {useStatePaymentListActions} from './useStatePaymentListActions';
import {
  createSchedulePrepayment,
  createScheduleRefunds,
  IPaymentScheduleListStats,
  IPaymentScheduleReturnType,
} from '../services/api/orders';
import moment from 'moment/moment';
import {useTranslation} from 'react-i18next';
import {useDropdownAlert} from '../contex';
import {useEffect, useState} from 'react';
import {isThereContent} from '@sportix/sportix-common-modules';
import {useStoredCashBoxList} from './useStoredCashBoxList';
import useStoredCompanies from './useStoredCompanies';

export interface IUseStateSchedulePaymentListProps
  extends IUseSchedulePaymentListProps {}

export type SchedulePaymentDateRangeParam = [Moment, Moment];

export interface IUseStateSchedulePaymentListReturnType
  extends Omit<IUseSchedulePaymentListReturnType, 'entityList'> {
  payments: List<PaymentModel> | null;
  handleCreatePayment: (value: PaymentFormDTO) => Promise<void>;
  handleCreateSchedulePrepayment: (
    value: PaymentScheduleFormDTO,
  ) => Promise<void | IPaymentScheduleReturnType>;
  handleCreateScheduleRefunds: (
    value: PaymentScheduleFormDTO,
  ) => Promise<void | IPaymentScheduleReturnType>;
  handleUpdatePayment: (value: PaymentFormDTO) => Promise<void>;
  handleDeletePayments: (ids: string[]) => Promise<void>;
  handleSearchPayments: (value: any) => Promise<void>;
  handleRefreshPayments: (
    value: Partial<IUseSchedulePaymentListProps> & {
      page?: number;
    },
  ) => Promise<void>;
  handlePickPaymentRange: (
    value: SchedulePaymentDateRangeParam,
  ) => Promise<void>;
  page: number;
}

export function useStateSchedulePaymentList(
  {
    scheduleUuid,
    ...rest
  }: IUseStateSchedulePaymentListProps = {} as IUseStateSchedulePaymentListProps,
): IUseStateSchedulePaymentListReturnType {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();
  const {defaultCompanyUuid} = useStoredCompanies();

  const [page, setPage] = useState(1);

  const {handleRefreshCashBoxes} = useStoredCashBoxList({
    companyUuid: defaultCompanyUuid,
    limit: 100,
    loadOnInit: false,
  });

  const [stats, setStats] = React.useState<IPaymentScheduleListStats>(
    {} as IPaymentScheduleListStats,
  );

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: paymentListLoading,
    total: paymentListTotal,
    stats: paymentStats,
    ...clientsParams
  } = useSchedulePaymentList({
    scheduleUuid,
    ...rest,
  });

  const {
    entityList: payments,
    loading,
    handleCreate,
    handleDelete,
    handleUpdate,
    setEntityList,
    total,
  } = useStateEntityList<PaymentModel>({
    entityList,
    refresh,
    limit,
    offset,
    total: paymentListTotal,
  });

  const {handleDeletePayments, handleUpdatePayment, handleCreatePayment} =
    useStatePaymentListActions({
      handleCreate,
      handleDelete,
      handleUpdate,
    });

  const handlePickPaymentRange = React.useCallback(
    async (value: SchedulePaymentDateRangeParam): Promise<void> => {
      if (value) {
        await refresh({
          date_start: dateToIsoString(head(value)),
          date_end: dateToIsoString(last(value)),
        });
      } else {
        await refresh({
          date_start: '',
          date_end: '',
          isClearDate: true,
        });
      }
    },
    [refresh],
  );

  const handleSearchPayments = React.useCallback(
    async (keywords: string): Promise<void> => {
      const payments = await refresh({
        keywords,
        date_start: '',
        date_end: '',
        isSearch: true,
      });

      if (payments) {
        setEntityList(payments);
      }
    },
    [refresh, setEntityList],
  );

  const handleRefreshPayments = React.useCallback(
    async ({
      offset = 0,
      limit = 10,
      page = 1,
    }: Partial<IUseSchedulePaymentListProps> & {
      page?: number;
    }): Promise<void> => {
      const payments = await refresh({offset, limit});

      if (payments) {
        setEntityList(payments);
        setPage(page);
      }
    },
    [refresh, setEntityList],
  );

  const handleCreateSchedulePrepayment = React.useCallback(
    async (value: PaymentScheduleFormDTO) => {
      const {payment, schedule} = await createSchedulePrepayment(
        value,
        scheduleUuid,
      );
      const paymentModel = PaymentMapper.toPaymentModel(payment);

      const updatedModel = paymentModel.set(
        'created_at',
        moment(new Date()).toString(),
      );

      if (isFunction(handleCreate)) {
        handleCreate(updatedModel, true);
      }

      setStats((prevState) => ({
        totalInSum: correctPrice(
          Number(prevState?.totalInSum || 0) + Number(value?.payment_price),
        ),
        totalOutSum: correctPrice(prevState?.totalOutSum || 0),
      }));

      setTimeout(async () => {
        await handleRefreshCashBoxes({page: 1, limit: 100});
      }, 300);

      alert('success', t('Payment'), t('Payment created success'));

      return {payment, schedule};
    },
    [scheduleUuid, handleCreate, handleRefreshCashBoxes, alert, t],
  );

  const handleCreateScheduleRefunds = React.useCallback(
    async (value: PaymentScheduleFormDTO) => {
      const {payment, schedule} = await createScheduleRefunds(
        value,
        scheduleUuid,
      );
      const paymentModel = PaymentMapper.toPaymentModel(payment);

      const updatedModel = paymentModel.set(
        'created_at',
        moment(new Date()).toString(),
      );

      if (isFunction(handleCreate)) {
        handleCreate(updatedModel, true);
      }

      setStats((prevState) => ({
        totalInSum: correctPrice(prevState?.totalInSum || 0),
        totalOutSum: correctPrice(
          Number(prevState?.totalOutSum || 0) + Number(value?.payment_price),
        ),
      }));

      setTimeout(async () => {
        await handleRefreshCashBoxes({page: 1, limit: 100});
      }, 300);

      alert('success', t('Payment'), t('Payment created success'));

      return {payment, schedule};
    },
    [scheduleUuid, handleCreate, handleRefreshCashBoxes, alert, t],
  );

  useEffect(() => {
    if (isThereContent(paymentStats)) {
      setStats(paymentStats);
    }
  }, [paymentStats]);

  return {
    payments,
    offset,
    limit,
    refresh,
    total,
    loading: paymentListLoading || loading,
    ...clientsParams,
    handleCreatePayment,
    handleUpdatePayment,
    handleDeletePayments,
    handleSearchPayments,
    handlePickPaymentRange,
    handleCreateScheduleRefunds,
    handleCreateSchedulePrepayment,
    stats,
    handleRefreshPayments,
    page,
  };
}
