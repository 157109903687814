import {ISubscriptionDTOProps, SubscriptionDTO} from '../internal';

export interface ISubscriptionListDTO {
  subscriptions: SubscriptionDTO[];
  total: number;
}

export class SubscriptionMapper {
  public static toSubscriptionDTO(
    subscription: ISubscriptionDTOProps,
  ): SubscriptionDTO {
    return new SubscriptionDTO(subscription);
  }

  public static toSubscriptionListDTO(
    subscriptions: ISubscriptionDTOProps[],
    total?: number,
  ): ISubscriptionListDTO {
    return {
      subscriptions: subscriptions.map((subscription) =>
        SubscriptionMapper.toSubscriptionDTO(subscription),
      ),
      total: total || 0,
    };
  }
}
