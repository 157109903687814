import React from 'react';
import {PAYMENT_STATUS} from '../../../struture';
import {Tag, Tooltip} from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  FileUnknownOutlined,
  WarningOutlined,
  FieldTimeOutlined,
} from '@ant-design/icons';
import styled, {css, useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';

export interface IPaymentStatusTagProps {
  status: PAYMENT_STATUS;
  title?: string;
  icon?: React.ReactNode;
}

const StyledTag = styled(Tag)<{$color: string}>`
  width: fit-content;
  border-color: ${({theme}) => theme.background.primary};
  padding: 0;

  &&& > span {
    margin-inline-start: 0;
  }

  ${({$color}) =>
    $color &&
    css`
      background-color: ${({theme}) => theme.background.primary};
      color: ${$color};
    `}
`;

export function PaymentStatusTag({
  status,
  title,
  icon,
  ...rest
}: IPaymentStatusTagProps): JSX.Element {
  const theme: any = useTheme();
  const {t} = useTranslation();

  const paymentStatusIcon = {
    [PAYMENT_STATUS.Completed]: <CheckCircleOutlined />,
    [PAYMENT_STATUS.Draft]: <WarningOutlined />,
    [PAYMENT_STATUS.Cancelled]: <CloseCircleOutlined />,
    [PAYMENT_STATUS.Deferred]: <FieldTimeOutlined />,
  };

  const paymentStatusColor = {
    [PAYMENT_STATUS.Completed]: theme.colors.success,
    [PAYMENT_STATUS.Draft]: theme.colors.warning,
    [PAYMENT_STATUS.Cancelled]: theme.colors.error,
    [PAYMENT_STATUS.Deferred]: theme.colors.link,
  };

  const paymentStatusTooltip = {
    [PAYMENT_STATUS.Completed]: t(
      'The payment has been made and the balance of the registers has been updated.',
    ),
    [PAYMENT_STATUS.Draft]: t(
      'Payment saved and waiting for posting at the end of the day. Registers - not updated!',
    ),
    [PAYMENT_STATUS.Cancelled]: t(
      'Payment - canceled or a posting error occurred. Registers are not updated',
    ),
    [PAYMENT_STATUS.Deferred]: t(
      'The payment is planned and will be made through the registers at the appointed time.',
    ),
  };

  return (
    <Tooltip title={paymentStatusTooltip[status] || ''}>
      <StyledTag
        {...rest}
        $color={paymentStatusColor[status] || theme.colors.primary}
        icon={paymentStatusIcon[status] || <FileUnknownOutlined />}>
        {title || null}
        {icon || null}
      </StyledTag>
    </Tooltip>
  );
}
