import React, {useEffect, useRef, useState} from 'react';
import {Tabs} from 'antd';
import styled from 'styled-components';
import {TabsProps} from 'antd/lib/tabs';
import {useAcl} from '../../../contex';
import {AclKey} from '../../../services/types';
import {AclDTO, AclMapper} from '../../../struture';
import EmptyLayout from '../Layout/EmptyLayout';

export type ColorsSchemaType =
  | 'success'
  | 'processing'
  | 'link'
  | 'error'
  | 'warning'
  | 'default'
  | 'abonement'
  | 'disabled';

export type TabItem = {
  key: string;
  title: string;
  description?: string | {text: string; color: string} | React.ReactNode;
  disabled?: boolean;
  refresh?: (...args: any[]) => any;
  renderer: (value?: {detailsWidth: number}) => React.ReactNode;
  aclItem?: AclKey;
  navigation?: () => void;
  type?: ColorsSchemaType;
  defaultActiveKey?: string;
  buttonIcon?: React.ReactNode;
  bold?: boolean;
  extraHeader?: React.ReactNode;
  stopPropagation?: boolean;
};

export interface IDefaultTabPanelProps extends TabsProps {
  tabs: TabItem[];
  onTabChange?: (item: TabItem | undefined) => void;
}

const StyledEmptyLayout = styled(EmptyLayout)``;

const StyledTabs = styled(Tabs)`
  width: 100%;
`;

/**
 * @decr Відображення вкладок Розділу
 * */
export default function DefaultTabPanel({
  tabs = [],
  onTabChange = () => {},
  ...rest
}: IDefaultTabPanelProps) {
  const acl = useAcl((acl) => acl);
  const isUploadData = useRef<string[]>([]);

  const [items, setItems] = useState<any[]>([]);

  /**
   * @desc Реакція на зміну вкладки
   * @param {String} key - ключ вкладки яка була оновлена
   * */
  const handleChangeTab = async (key: string) => {
    const tab = tabs.find((item) => item?.key === key);

    if (tab && !isUploadData.current.includes(key) && tab?.refresh) {
      await tab?.refresh();

      isUploadData.current = [...isUploadData.current, key];
    }

    onTabChange(tab);
  };

  useEffect(() => {
    if (Array.isArray(tabs)) {
      const updatedTabs = tabs.map((tab) => {
        const {read} = tab?.aclItem
          ? acl[tab?.aclItem]
          : AclMapper.toAclModel({} as AclDTO);

        return {
          ...tab,
          label: tab?.title,
          children: read ? tab?.renderer() : <StyledEmptyLayout />,
        };
      });
      setItems(updatedTabs);
    }
  }, [acl, tabs]);

  return <StyledTabs onChange={handleChangeTab} {...rest} items={items} />;
}
