import React, {SetStateAction, Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {useModal} from '../../../components/lib/libV2/hooks';
import {IClientAbonementModel} from '../../../struture';
import {
  AddButton,
  IAddButtonProps,
  SuspenseEmpty,
} from '../../../components/lib/DataDisplay';
import {StyledTitle} from '../../../components/lib/Styled';
import styled, {useTheme, css} from 'styled-components';

const ClientAbonementScheduleSideWindowLazy = React.lazy(
  () => import('../Show/ClientAbonementScheduleSideWindow'),
);

export interface IClientAbonementScheduleButtonProps extends IAddButtonProps {
  onSuccess: (value: any) => Promise<IClientAbonementModel | void>;
  onCancel?: () => void;
  schedule: any;
  title?: string;
  tooltipTitle?: string;
  editMode?: boolean;
  setVisible?: React.Dispatch<SetStateAction<boolean>>;
}

const StyledAddButton = styled(AddButton)<{disabled?: boolean}>`
  width: fit-content;

  ${({disabled}) =>
    !disabled &&
    css`
      &&&:hover,
      &&&:active,
      &&&:focus {
        opacity: 0.7;
      }
    `}
`;

export default function ClientAbonementScheduleButton({
  loading = false,
  onSuccess,
  onCancel,
  schedule,
  title,
  tooltipTitle,
  editMode,
  disabled,
  setVisible,
  ...rest
}: IClientAbonementScheduleButtonProps) {
  const {t} = useTranslation();
  const theme: any = useTheme();

  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
    handleChangeState: setVisible,
  });

  return (
    <>
      <StyledAddButton
        type="primary"
        tooltipTitle={tooltipTitle || t('Create a class schedule')}
        onClick={handleOnInit}
        loading={loading}
        disabled={disabled}
        {...rest}>
        <StyledTitle
          $color={disabled ? theme.colors.disable : theme.colors.white}>
          {title || t('Create a class schedule')}
        </StyledTitle>
      </StyledAddButton>

      <Suspense fallback={<SuspenseEmpty />}>
        <ClientAbonementScheduleSideWindowLazy
          schedule={schedule}
          loading={loading as boolean}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          visible={visible}
          editMode={editMode}
        />
      </Suspense>
    </>
  );
}
