import React from 'react';
import {Provider} from 'react-redux';
import ReactGA from 'react-ga';
import {createBrowserHistory} from 'history';
import {App} from './App';
import {googleAnalyticsTrackingId} from './services/const';
import {createRoot} from 'react-dom/client';
import {defaultStore} from './store';

import './i18n';
import './index.css';

ReactGA.initialize(googleAnalyticsTrackingId);

const history = createBrowserHistory();

history.listen((location) => {
  ReactGA.set({page: window.location.pathname});
  ReactGA.pageview(window.location.pathname);
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Provider store={defaultStore}>
    <App />
  </Provider>,
);
