import {Record, List} from 'immutable';
import {IListProps} from './List';
import {DepartmentDTO, DepartmentModel} from '../internal';
import {WithoutRecursion} from '../type';

export interface IDepartmentListModelProps extends IListProps {
  departments: DepartmentDTO[];
  keywords?: string;
  page?: number;
}

export interface IDepartmentListModelReturnType extends IListProps {
  departments: List<DepartmentModel>;
  keywords?: string;
  page?: number;
}

export class DepartmentListModel extends Record<IDepartmentListModelReturnType>(
  {
    departments: List(),
    total: 0,
    keywords: '',
    page: 1,
  },
) {
  constructor(
    {
      departments = [],
      ...props
    }: IDepartmentListModelProps = {} as IDepartmentListModelProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IDepartmentListModelReturnType = {
      ...props,
      departments: List(
        departments.map(
          (department) => new DepartmentModel(department, withoutRecursion),
        ),
      ),
    };

    super(options);
  }
}
