import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Empty} from 'antd';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import {ListLayout} from '../../../components/lib/Layout';
import {
  StatusCreateButton,
  StatusesDeleteButton,
  StatusEditButton,
} from '../Buttons';
import {
  useStoredStatusList,
  useStopLoading,
  useStoredCompanies,
  useStoredStatus,
  useStoredStatusCategoryList,
  useKeyboardOpenForm,
  useSearchInputFocus,
} from '../../../hooks';
import {
  len,
  lessOrEqualThan,
  textToUpperCase,
  isListToArray,
} from '../../../services/helpers';
import {
  StatusModel,
  StatusMapper,
  StatusCategoryModel,
} from '../../../struture';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '../../../services/types';
import {
  TableActionCell,
  Table,
} from '../../../components/lib/libV2/DataDisplay';
import {useDropdownAlert, useAcl} from '../../../contex';
import {HeaderLeft} from '../../../components/lib/DataDisplay';
import {successButtonStyle} from '../../../components/lib/Styled';
import {
  ListActions,
  ListSearchSelect,
} from '../../../components/lib/DataDisplay';
import {useNavigate} from 'react-router';
import {StatusDetailsView} from '../Show';
import styled from 'styled-components';

const StyledStatusCreateButton = styled(StatusCreateButton)`
  ${successButtonStyle}
`;

export default function StatusListPage() {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {defaultCompanyUuid, defaultCompanyLoading} = useStoredCompanies();
  const {manage} = useAcl(({order}) => order);
  const {alert} = useDropdownAlert();
  const {ref: listRef} = useKeyboardOpenForm({
    className: 'status-create-event',
    disabled: !manage,
  });

  const [selectedStatuses, setSelectedStatuses] = useState<StatusModel[]>([]);

  const {
    statusList,
    loading: statusesListLoading,
    error: statusesListError,
    limit: pageSize,
    total,
    loadingMore,
    keywords,
    page,
    category,

    handleRefreshStatuses,
    handleDeleteStatuses: onDeleteStatuses,
    handleSearchStatuses,
  } = useStoredStatusList({companyUuid: defaultCompanyUuid});

  const {
    categoryList,
    loading: categoryListLoading,
    error: categoryListError,
  } = useStoredStatusCategoryList();

  const {handleCreateStatus, handleUpdateStatus} = useStoredStatus({
    companyUuid: defaultCompanyUuid,
    loadOnInit: false,
    statusUuid: '',
  });

  const loading = useStopLoading({
    error: statusesListError,
    loading: statusesListLoading || loadingMore,
    message: 'An error occurred during statuses loading',
  });

  const {inputRef, focus, daleyFocus} = useSearchInputFocus({loading});

  const handleDeleteStatuses = useCallback(
    async (value: string[]) => {
      await onDeleteStatuses(value);
      focus();
    },
    [focus, onDeleteStatuses],
  );

  const loadingCategoryList = useStopLoading({
    error: categoryListError,
    loading: categoryListLoading,
    message: 'An error occurred during status categories loading',
  });

  const routes = useBreadcrumb([
    {
      path: `/${Routes.tariffModels}`,
      breadcrumbName: 'Statuses',
    },
  ]);

  const rowSelection = useMemo(
    () => ({
      hideSelectAll: true,
      selectedRowKeys: selectedStatuses.map(({uuid}) => uuid),
      onChange: (
        selectedRowKeys: React.Key[],
        selectedRecords: StatusModel[],
      ) => {
        if (lessOrEqualThan(len(selectedRecords), 1)) {
          setSelectedStatuses(selectedRecords);
        } else {
          alert('error', t('Delete'), t('You can delete up to 1 at a time'));
        }
      },
    }),
    [alert, selectedStatuses, t],
  );

  const columns = [
    {
      title: t('Title'),
      key: 'title',
      width: '80%',
      render: (status: StatusModel) => <StatusDetailsView status={status} />,
    },
    {
      title: t('Actions'),
      key: 'status_actions',
      align: 'center' as any,
      fixed: 'right' as any,
      render: (status: StatusModel) => (
        <TableActionCell>
          <StatusEditButton
            disabled={!manage}
            type="text"
            status={StatusMapper.toStatusFormDTO(status, true)}
            data-testid="status-edit-button"
            onSuccess={handleUpdateStatus}
            onCancel={daleyFocus}
          />
          <StatusesDeleteButton
            disabled={!manage}
            data-testid="status-delete-button"
            statuses={[status]}
            onSuccess={handleDeleteStatuses}>
            <DeleteOutlined />
          </StatusesDeleteButton>
        </TableActionCell>
      ),
    },
  ];

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.companies}/${defaultCompanyUuid}`);
  }, [defaultCompanyUuid, navigate]);

  return (
    <>
      <ListLayout
        ref={listRef}
        aclItem={RoutesAcl[Routes.customStatuses]}
        headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
        headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.customStatuses]))}
        headerRight={
          <>
            {selectedStatuses.length ? (
              <StatusesDeleteButton
                disabled={!manage}
                statuses={selectedStatuses}
                onSuccess={handleDeleteStatuses}
              />
            ) : (
              <StyledStatusCreateButton
                disabled={!manage}
                loading={loading}
                type="primary"
                onSuccess={handleCreateStatus}
                title={t('Create')}
                icon={<PlusOutlined />}
                onCancel={daleyFocus}
              />
            )}
          </>
        }
        routes={routes}
        empty={
          statusList?.size ? null : (
            <Empty
              description={t(
                `It looks like you don't have any statuses at the moment.`,
              )}>
              <StyledStatusCreateButton
                disabled={!manage}
                loading={loading}
                type="primary"
                onSuccess={handleCreateStatus}
                title={t('Create')}
                icon={<PlusOutlined />}
                onCancel={daleyFocus}
              />
            </Empty>
          )
        }
        headerExtra={
          <ListActions
            inputRef={inputRef}
            withoutPicker
            handleSearch={(keywords) => handleSearchStatuses({keywords})}
            inputTooltip={t('Search statuses')}
            inputLabel={t('Search statuses')}
            searchText={keywords}
            loading={loading}
            withSearchContent={null}>
            <ListSearchSelect
              data={isListToArray(categoryList as any)}
              placeholder={t('Select category')}
              loading={loadingCategoryList}
              getOptionValueProps={(category: StatusCategoryModel) =>
                category?.title
              }
              value={category || null}
              size="middle"
              onBlur={() => daleyFocus()}
            />
          </ListActions>
        }
        loading={loading && !statusList?.size}>
        <Table<StatusModel>
          rowSelection={rowSelection}
          total={total}
          pageSize={pageSize}
          dataSource={statusList}
          onChange={handleRefreshStatuses}
          columns={columns}
          loading={loading || defaultCompanyLoading}
          page={page}
        />
      </ListLayout>
    </>
  );
}
