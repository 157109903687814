import {curry} from './base';
import {ILiteralObj} from '../types';

export const isEqualById = curry<[string, any], boolean>(
  <T extends ILiteralObj>(id: string, target: T): boolean => target?.id !== id,
);

export const updateById = curry<[any, any], any>(
  <T extends ILiteralObj>(updated: T, target: T): T => {
    if (target?.id === updated?.id) {
      return updated;
    }
    return target;
  },
);
