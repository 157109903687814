import React from 'react';
import {ContentHidden} from './ContentHidden';

export interface IContentDetailsProps {
  children: React.ReactNode | React.ReactNode[];
  hidden: boolean;
  identifier?: string;
}

ContentDetails.Hidden = ({
  children,
  hidden,
}: Pick<IContentDetailsProps, 'children'> & {hidden: boolean}) => (
  <ContentHidden hidden={hidden}>{children}</ContentHidden>
);

export function ContentDetails({children, hidden}: IContentDetailsProps): any {
  return React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      if (hidden) {
        if (!child?.props?.hidden) {
          return child;
        }
      } else {
        return child;
      }
    } else {
      return null;
    }
  });
}
