import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {
  DefaultForm,
  IDefaultFormReturnedProps,
  IDefaultFormProps,
} from '../../../components/lib/General';
import {COMPANY_INITIAL_DATA} from '../../../services/api/company';
import {split, isFunction, notEmptyFields} from '../../../services/helpers';
import {DEFAULT_TIME_RANGE} from '../../../components/lib/const';
import {CompanyFormDTO} from '../../../struture';
import {useStateCategoryList} from '../../../hooks';
import {useDropdownAlert} from '../../../contex';
import {CompanyFormFields} from './CompanyFormFields';

import './CompanyForm.less';
import {produce} from 'immer';

export interface ICompanyFormProps<T, U>
  extends Pick<
    IDefaultFormProps<T, U>,
    | 'showFooter'
    | 'showNotify'
    | 'editMode'
    | 'className'
    | 'additionalValuesRequest'
  > {
  loading?: boolean;
  company?: CompanyFormDTO;
  onSuccess: (value: CompanyFormDTO) => Promise<void>;
  onCancel?: () => void;
  children?: (data: IDefaultFormReturnedProps) => React.ReactNode;
  showOnlyCompanyTitle?: boolean;
  showPhoneField?: boolean;
}

export interface IOnChangeDaysOfWeekSchedule {
  fieldValue: any;
  checked: boolean;
  name: string;
  time: string[] | string;
}

export default function CompanyForm<T, U>({
  loading,
  company = COMPANY_INITIAL_DATA,
  children,
  showOnlyCompanyTitle = false,
  editMode,
  additionalValuesRequest,
  showPhoneField = false,
  onCancel,
  onSuccess,
  ...rest
}: ICompanyFormProps<T, U>): JSX.Element {
  const {t} = useTranslation();
  const {alert} = useDropdownAlert();

  const {
    categories,
    loading: categoriesLoading,
    handleSearchCategories,
  } = useStateCategoryList({
    loadOnInit: !showOnlyCompanyTitle,
    limit: 100,
  });

  const additionalValues = useMemo(
    () => ({
      uuid: company?.uuid,
    }),
    [company?.uuid],
  );

  const weekdays = useMemo(
    () => ({
      weekdays: split(',', company?.work_schedules || DEFAULT_TIME_RANGE),
    }),
    [company?.work_schedules],
  );

  const notifyError = useCallback(
    (apiError: any) => {
      alert(
        'error',
        'Company',
        `${
          editMode
            ? t('An error occurred during edit company')
            : t('An error occurred during create company')
        } : ${apiError?.message}`,
      );

      // if (isFunction(onCancel)) {
      //   setTimeout(onCancel, 500);
      // }
    },
    [alert, editMode, t],
  );

  const handleSuccess = useCallback(
    async (value: CompanyFormDTO) => {
      const updatedValue = produce(value, (draft) => {
        draft.requisite_list = draft?.requisite_list?.map(({uuid, ...rest}) =>
          notEmptyFields(rest),
        );
      });

      if (isFunction(onSuccess)) {
        await onSuccess(updatedValue);
      }
    },
    [onSuccess],
  );

  return (
    <DefaultForm
      withContext
      initialValues={{...company, ...weekdays}}
      additionalValuesRequest={
        additionalValuesRequest || (editMode ? additionalValues : null)
      }
      editMode={editMode}
      {...rest}
      onCancel={onCancel}
      showNotify={false}
      notifyError={notifyError}
      onSuccess={handleSuccess}>
      {({loadingSubmit, handlerUpdateFormState, formData, ...form}) => {
        return (
          <CompanyFormFields
            company={company}
            editMode={!!editMode}
            loading={!!loading}
            showOnlyCompanyTitle={showOnlyCompanyTitle}
            showPhoneField={showPhoneField}
            categories={categories}
            categoriesLoading={categoriesLoading}
            handleSearchCategories={handleSearchCategories}>
            {children
              ? children({
                  loadingSubmit,
                  handlerUpdateFormState,
                  formData,
                  ...form,
                })
              : null}
          </CompanyFormFields>
        );
      }}
    </DefaultForm>
  );
}
