import {
  IExpenseDTOProps,
  IProductDTOProps,
  IProfileDTOProps,
  IServiceDTOProps,
  IStoreDocumentItemDTOProps,
  StoreDocumentCurrency,
} from '../internal';

export enum InvoiceItemPositionableType {
  SERVICE = 'service',
  PRODUCT = 'product',
  ORDER = 'order',
  STORE = 'store',
  CUSTOM = 'custom',
}

export enum InvoiceItemStatus {
  ENABLE = 'enable',
  DISABLE = 'disable',
}

export interface InvoiceItemDTO {
  uuid: string;
  item_title: string;
  item_comment: string;
  item_currency: StoreDocumentCurrency | string;
  item_positionable_type: InvoiceItemPositionableType;
  item_base_price: number;
  item_price: number;
  item_total: number;
  item_amount: number;
  item_status: InvoiceItemStatus;
  created_at: string;
  updated_at: string;
  item_positionable_object:
    | IServiceDTOProps
    | IProductDTOProps
    | IExpenseDTOProps
    | IStoreDocumentItemDTOProps;
  created_by: IProfileDTOProps;
}
