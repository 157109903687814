import React from 'react';
import styled from 'styled-components';
import {withTranslation, WithTranslation} from 'react-i18next';
import {FlexContainer} from '../Styled';
import {TextFormat} from '../Format';
import {footerAppName} from '../../../services/const';
// @ts-ignore
import packagejson from '../../../../package.json';

export default withTranslation()(FooterWithoutLayout);

export interface IFooterWithoutLayoutProps extends WithTranslation {
  className?: string;
}

const StyledFooter = styled(FlexContainer)`
  background-color: transparent;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const StyledText = styled(TextFormat)`
  color: rgb(109, 110, 116);
`;

function FooterWithoutLayout({
  t,
  className,
}: IFooterWithoutLayoutProps): JSX.Element {
  const year: number = new Date().getFullYear();
  return (
    <StyledFooter
      height="auto"
      justifyContent="center"
      alignItems="center"
      className={className}>
      <StyledText className="footer__text">
        © {year} {footerAppName} v{packagejson?.version}
        <br />
        {t('All Rights Reserved.')}
      </StyledText>
    </StyledFooter>
  );
}
