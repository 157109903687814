import React, {useCallback, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {OverviewHeader, OverviewLayout} from '../../../components/lib/Layout';
import {
  useStoredProfile,
  useRequiredFields,
  useUser,
  useInterval,
  useStoredCompanies,
} from '../../../hooks';
import {
  IProfileBalanceParam,
  REQUIRED_PROFILE_FIELDS,
} from '../../../services/api/user';
import {AccountActionButtons, ProfileEditButton} from '../Buttons';
import ProfileTabPanel from '../Show/ProfileTabPanel';
import {useBreadcrumb} from '../../../components/lib/libV2/hooks';
import {Routes} from '../../../services/types';
import {ProfileMapper} from '../../../struture';
import {useLocation, useNavigate, useParams} from 'react-router';
import {HeaderLeft} from '../../../components/lib/DataDisplay';
import {textToUpperCase} from '../../../services/helpers';
import {ProfileHeader} from '../Show';

export default function ProfilePage() {
  const {tabsId} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {t} = useTranslation();
  const {defaultCompanyUuid} = useStoredCompanies();

  const {
    profile,
    loading,
    error,
    updateProfile,
    setProfilePicture,
    handleRemoveAccount,
    updateProfileBalance,
    storeProfile,
  } = useStoredProfile();

  const {refresh} = useUser({loadingOnInit: false});

  const profilePrevBalance = useRef<number>('' as any);

  const {intervalCallback, handleClearInterval} = useInterval(async () => {
    const profileModel = await refresh(false);

    if (profileModel && profilePrevBalance.current !== profileModel?.balance) {
      storeProfile(profileModel as any);
    }
  }, 3000);

  const handleUpdateBalance = useCallback(
    async (value: IProfileBalanceParam) => {
      await updateProfileBalance(value);
      intervalCallback();
    },
    [intervalCallback, updateProfileBalance],
  );

  useEffect(() => {
    if (profile && !profilePrevBalance.current) {
      profilePrevBalance.current = profile?.balance;
    }

    if (profile?.balance !== profilePrevBalance.current || error) {
      handleClearInterval();
    }
  }, [handleClearInterval, profile, error]);

  const routes = useBreadcrumb([
    {
      path: `/${Routes.profile}`,
      breadcrumbName: 'Profile',
    },
  ]);

  const overviewData = useRequiredFields(
    ProfileMapper.toProfileDTO(profile),
    REQUIRED_PROFILE_FIELDS,
  );

  const navigateGoBack = useCallback(() => {
    navigate(
      location?.state?.goBack ||
        `/${Routes.app}/${Routes.companies}/${defaultCompanyUuid}`,
    );
  }, [location?.state?.goBack, navigate, defaultCompanyUuid]);

  return (
    <ProfileTabPanel
      handleDeleteProfile={handleRemoveAccount}
      profile={profile}>
      {({tabs, topTabs}) => (
        <OverviewLayout
          headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
          headerRight={
            <ProfileEditButton
              profile={ProfileMapper.toProfileFormDTO(profile!)}
              onSuccess={updateProfile}
            />
          }
          loading={loading}
          data={overviewData}
          image={profile?.picture_url}
          onUploadFile={setProfilePicture}
          routes={routes}
          headerTitle={textToUpperCase(t('Profile'))}
          tabs={tabs}
          topTabs={topTabs}
          tabsDefaultActiveKey={tabsId}
          header={
            <ProfileHeader
              profile={profile}
              handleUploadImage={(() => {}) as any}
              handleUpdateBalance={handleUpdateBalance}
            />
          }
        />
      )}
    </ProfileTabPanel>
  );
}
