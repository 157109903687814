import React from 'react';
import {Space, Image, Alert} from 'antd';
import {StyledTitle} from '../../../components/lib/Styled';
import {textToUpperCase} from '../../../services/helpers';
import styled from 'styled-components';

export interface IHeaderTextProps {
  title: string;
  description?: string;
  withoutLogo?: boolean;
  color?: string;
  upperCase?: boolean;
}

const StyledHeader = styled(Space)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: auto;
  margin-bottom: 10%;
  background-color: ${({theme}) => theme.colors.app};
`;

export function HeaderText({
  title,
  description,
  withoutLogo,
  color,
  upperCase = true,
}: IHeaderTextProps): JSX.Element {
  return (
    <StyledHeader size="small">
      {withoutLogo ? null : (
        <Image src="/icon.png" alt="logo" width="30px" preview={false} />
      )}
      <StyledTitle $color={color} fontSize={25} fontWeight={500}>
        {textToUpperCase(title)}
      </StyledTitle>
      {description ? (
        <Alert
          type="info"
          message={upperCase ? textToUpperCase(description) : description}
        />
      ) : null}
    </StyledHeader>
  );
}
