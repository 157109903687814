import React from 'react';
import {IDefaultTabPanelProps} from '../../../components/lib/General';
import {Routes} from '../../../services/types';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';

export enum PRODUCT_TABS {
  MOVEMENT_GOODS,
}

export interface IProductTabPanelProps {
  children: (value: Pick<IDefaultTabPanelProps, 'tabs'>) => React.ReactNode;
}

export function ProductTabPanel({children}: IProductTabPanelProps) {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const productTabs: any = [
    {
      key: PRODUCT_TABS.MOVEMENT_GOODS,
      title: t('Movement of goods'),
      disabled: false,
      navigation: () => {
        navigate(Routes.movementGoods);
      },
      renderer: () => <></>,
    },
  ];

  return <>{children({tabs: productTabs})}</>;
}
