import {EmployeeDTO, IEmployeeDTOProps, EmployeeMapper} from '../internal';
import {WithoutRecursion} from '../type';
import {correctPrice} from '../../helpers';

export enum RewardType {
  Percentage = 'PERCENTAGE',
  Price = 'PRICE',
}

export interface IEmployeeServiceDTOProps {
  price: string;
  duration: number;
  reward_value: number;
  reward_type: RewardType;
  created_at: string;
  updated_at: string;
  employee?: IEmployeeDTOProps;
}

export class EmployeeServiceDTO {
  public price: string;

  public duration: number;

  public reward_value: number;

  public created_at: string;

  public updated_at: string;

  public reward_type: RewardType;

  public employee?: EmployeeDTO;

  constructor(
    props: IEmployeeServiceDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.price = correctPrice(props?.price);
    this.created_at = props.created_at || '';
    this.updated_at = props.updated_at || '';
    this.duration = props.duration || 0;
    this.reward_type = props.reward_type || RewardType.Percentage;
    this.reward_value = props.reward_value || 0;

    this.employee = props?.employee
      ? withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
        ? props?.employee
        : EmployeeMapper.toEmployeeDTO(props?.employee, [
            WithoutRecursion.employeeService,
            ...withoutRecursion,
          ])
      : undefined;
  }
}
