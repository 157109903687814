import React from 'react';
import {Routes, RoutesAcl} from '../../../services/types';
import {MenuItem} from '../../../components/lib/Layout';

import {
  StyledFaBoxes,
  StyledMdPayment,
  StyledMdLanguage,
  StyledHiTemplate,
  StyledFaLayerGroup,
  StyledMdPriceChange,
  StyledFaMoneyBillAlt,
  StyledTbPlugConnected,
  StyledBsBuildingFillAdd,
  StyledPartitionOutlined,
  StyledRiSoundModuleFill,
} from '../../../components/lib/Styled';

export const MENU_KEY_SETTINGS = 'Settings';

export const MENU_GROUP_COMPANY_KEY = 'company';
export const MENU_GROUP_STORE_KEY = 'store';
export const MENU_GROUP_APPLICATION_KEY = 'app';

export enum MenuOpenProvider {
  LINK = 'link',
  MODAL = 'modal',
}

export enum ItemSideWindow {
  Localization = 'Localization',
  Payments = 'Payments',
  CompanyOrderBonuses = 'Company order bonuses',
}

export const SETTINGS_MENU_ITEMS: MenuItem[] = [
  {
    key: `/${Routes.app}/${Routes.departments}`,
    aclItem: RoutesAcl[Routes.departments],
    openWith: MenuOpenProvider.LINK,
    title: 'Departments / Locations',
    icon: <StyledBsBuildingFillAdd />,
    group: {
      key: MENU_GROUP_COMPANY_KEY,
      title: 'Company Settings',
    },
  },
  {
    key: `/${Routes.app}/${Routes.customStatuses}`,
    openWith: MenuOpenProvider.LINK,
    aclItem: RoutesAcl[Routes.customStatuses],
    title: 'Order statuses',
    icon: <StyledPartitionOutlined />,
    group: {
      key: MENU_GROUP_COMPANY_KEY,
      title: 'Company Settings',
    },
  },
  {
    key: `/${Routes.app}/${Routes.stores}`,
    openWith: MenuOpenProvider.LINK,
    aclItem: RoutesAcl[Routes.stores],
    title: 'Stores',
    icon: <StyledFaBoxes style={{fontSize: 16}} />,
    group: {
      key: MENU_GROUP_STORE_KEY,
      title: 'Store Settings',
    },
  },
  {
    key: `/${Routes.app}/${Routes.priceMargins}`,
    aclItem: RoutesAcl[Routes.priceMargins],
    openWith: MenuOpenProvider.LINK,
    title: 'Markups and discounts',
    icon: <StyledMdPriceChange style={{fontSize: 18}} />,
    group: {
      key: MENU_GROUP_STORE_KEY,
      title: 'Store Settings',
    },
  },
  {
    key: `/${Routes.app}/${Routes.groupPayment}`,
    // aclItem: RoutesAcl[Routes.clients],
    title: 'Group payments',
    openWith: MenuOpenProvider.LINK,
    icon: <StyledFaLayerGroup style={{fontSize: 14}} />,
    group: {
      key: MENU_GROUP_COMPANY_KEY,
      title: 'Company Settings',
    },
  },
  {
    key: `/${Routes.app}/${Routes.mailingTemplates}`,
    aclItem: RoutesAcl[Routes.template],
    openWith: MenuOpenProvider.LINK,
    title: 'Notification templates',
    icon: <StyledHiTemplate style={{fontSize: 16}} />,
    group: {
      key: MENU_GROUP_COMPANY_KEY,
      title: 'Company Settings',
    },
  },
  {
    key: `/${Routes.app}/${Routes.settings}/parameters/${Routes.modules}`,
    aclItem: RoutesAcl[Routes.modules],
    openWith: MenuOpenProvider.LINK,
    title: 'Modules',
    icon: <StyledRiSoundModuleFill />,
    group: {
      key: MENU_GROUP_APPLICATION_KEY,
      title: 'Application Settings',
    },
  },
  {
    key: `/${Routes.app}/${Routes.integrations}`,
    aclItem: RoutesAcl[Routes.integrations],
    openWith: MenuOpenProvider.LINK,
    title: 'Integrations',
    icon: <StyledTbPlugConnected style={{fontSize: 18}} />,
    group: {
      key: MENU_GROUP_APPLICATION_KEY,
      title: 'Application Settings',
    },
  },
  {
    key: ItemSideWindow.CompanyOrderBonuses,
    aclItem: RoutesAcl[Routes.settings],
    openWith: MenuOpenProvider.MODAL,
    title: 'Employee rewards',
    icon: <StyledFaMoneyBillAlt style={{fontSize: 18}} />,
    group: {
      key: MENU_GROUP_COMPANY_KEY,
      title: 'Company Settings',
    },
  },
  {
    key: ItemSideWindow.Payments,
    aclItem: RoutesAcl[Routes.settings],
    openWith: MenuOpenProvider.MODAL,
    title: 'Finances',
    icon: <StyledMdPayment style={{fontSize: 18}} />,
    group: {
      key: MENU_GROUP_COMPANY_KEY,
      title: 'Company Settings',
    },
  },
  {
    key: ItemSideWindow.Localization,
    aclItem: RoutesAcl[Routes.settings],
    openWith: MenuOpenProvider.MODAL,
    title: 'Localization',
    icon: <StyledMdLanguage style={{fontSize: 18}} />,
    group: {
      key: MENU_GROUP_APPLICATION_KEY,
      title: 'Application Settings',
    },
  },
];
