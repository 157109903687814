import * as React from 'react';
import {List} from 'immutable';
import {
  useClientFileList,
  IUseClientFileListProps,
  IUseClientFileListReturnType,
} from './useClientFileList';
import {ClientModel, FileListModel, FileModel} from '../struture';
import {useStateEntityList} from '../components/lib/libV2/hooks';
import {SetStateAction, useMemo, useState} from 'react';

export interface IUseStateClientFileListProps extends IUseClientFileListProps {}

export interface IUseStateClientFileListReturnType
  extends Omit<IUseClientFileListReturnType, 'entityList'> {
  files: List<FileModel> | null;
  handleSearchClientFiles: (
    value: Partial<IUseClientFileListProps>,
  ) => Promise<FileListModel | void>;
  handleRefreshClientFiles: (
    value: Partial<IUseClientFileListProps> & {page: number},
  ) => Promise<FileListModel | void>;
  handleLoadingMoreClientFileList: (
    value: Partial<IUseClientFileListProps>,
  ) => Promise<FileListModel | void>;
  loadingMore: boolean;
  handleSetClientFileList: React.Dispatch<
    SetStateAction<List<FileModel> | null>
  >;
  isLoadingMore: boolean;
  page: number;
  client: ClientModel | null;
}

export function useStateClientFileList(
  {
    clientUuid,
    ...rest
  }: IUseStateClientFileListProps = {} as IUseStateClientFileListProps,
): IUseStateClientFileListReturnType {
  const [page, setPage] = useState(1);

  const {
    entityList,
    offset,
    limit,
    refresh,
    loading: serviceListLoading,
    total: serviceListTotal,
    keywords: serviceKeywords,
    ...clientsParams
  } = useClientFileList({
    clientUuid,
    ...rest,
  });

  const {
    entityList: files,
    loading: stateLoading,
    setEntityList,
    total,
  } = useStateEntityList<FileModel>({
    entityList: entityList?.files!,
    refresh,
    limit,
    offset,
    total: serviceListTotal,
  });

  const size = useMemo(() => files?.size || 0, [files?.size]);
  const isLoadingMore = useMemo(() => size < total, [size, total]);

  const [searchLoading, setSearchLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const loading = serviceListLoading || stateLoading || searchLoading;

  const handleSearchClientFiles = React.useCallback(
    async ({
      limit = 10,
      keywords,
      showLoading = true,
    }: Partial<IUseClientFileListProps>) => {
      setSearchLoading(true);
      const fileList = await refresh({offset: 0, limit, keywords, showLoading});

      if (fileList && List.isList(fileList?.files)) {
        setEntityList(fileList?.files);

        setSearchLoading(false);
        return fileList;
      }
      setSearchLoading(false);
    },
    [refresh, setEntityList],
  );

  const handleRefreshClientFiles = React.useCallback(
    async ({
      limit = 10,
      keywords,
      showLoading = true,
      page = 1,
    }: Partial<IUseClientFileListProps> & {page: number}) => {
      setSearchLoading(true);
      const fileList = await refresh({offset: 0, limit, keywords, showLoading});

      if (fileList && List.isList(fileList?.files)) {
        setEntityList(fileList?.files);
        setPage(page);

        setSearchLoading(false);
        return fileList;
      }
      setSearchLoading(false);
    },
    [refresh, setEntityList],
  );

  const handleLoadingMoreClientFileList = React.useCallback(
    async ({
      keywords = serviceKeywords,
      showLoading = false,
      limit = 10,
      offset = files?.size,
      ...rest
    }: Omit<IUseClientFileListProps, 'clientUuid'> = {}) => {
      if (size < total) {
        setLoadingMore(true);

        const apiClientFileList = await refresh({
          keywords,
          showLoading,
          limit,
          offset,
          ...rest,
        });

        if (apiClientFileList && List.isList(apiClientFileList?.files)) {
          setEntityList((prevState) =>
            (prevState || List()).merge(apiClientFileList?.files),
          );
          setLoadingMore(false);

          return apiClientFileList;
        }
        setLoadingMore(false);
      }
    },
    [refresh, serviceKeywords, files?.size, setEntityList, size, total],
  );

  return {
    files,
    offset,
    limit,
    refresh,
    total,
    loading: serviceListLoading || loading,
    keywords: serviceKeywords,
    ...clientsParams,
    handleSearchClientFiles,
    handleLoadingMoreClientFileList,
    loadingMore,
    isLoadingMore,
    handleSetClientFileList: setEntityList,
    handleRefreshClientFiles,
    page,
    client: entityList?.trigger_object || null,
  };
}
