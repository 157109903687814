/**
 * @desc Генерування фейкового списку (для прелоадера)
 * @param {Number} amount - кількість фейкових елементів
 * @return {Array}
 * */
export const createPreloadingList = (amount = 1) =>
  [...Array(amount)].map((_, i) => i);

export const defaultNumberSymbol = (num = 50, str = '*') =>
  [...Array(num)]
    .map((_, i) => i)
    .fill(str as any)
    .join('');
