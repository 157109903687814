import React from 'react';
import {useTranslation} from 'react-i18next';
import {Form, Col} from 'antd';
import {List} from 'immutable';
import {
  DefaultForm,
  IDefaultFormProps,
  Button,
} from '../../../components/lib/General';
import {DefaultCompanySelect} from '../Show';
import {CompanyModel} from '../../../struture';

export interface IDefaultCompaniesFormProps<T, U>
  extends Omit<IDefaultFormProps<T, U>, 'children'> {
  companies: List<CompanyModel>;
}

export default function DefaultCompaniesForm<T, U>({
  companies,
  initialValues,
  ...rest
}: IDefaultCompaniesFormProps<T, U>) {
  const {t} = useTranslation();

  return (
    <DefaultForm initialValues={initialValues} showFooter={false} {...rest}>
      {({loadingSubmit, submit, ...from}) => (
        <>
          <Form.Item
            label={t('Default Company')}
            name="default_company"
            tooltip={t('Default Company')}
            rules={[
              {
                required: true,
                message: t('Default Company must be specified.'),
              },
            ]}>
            <DefaultCompanySelect
              dataTestId="default-company-form__select"
              companies={companies}
              loadingSubmit={loadingSubmit}
              submit={submit}
              defaultValue={(initialValues as any)?.default_company}
              {...from}
            />
          </Form.Item>
          <Col span={24}>
            <Button
              disabled={loadingSubmit}
              data-testid="default-company-form__button--submit"
              loading={loadingSubmit}
              type="primary"
              onClick={submit}
              block>
              {t('confirm')}
            </Button>
          </Col>
        </>
      )}
    </DefaultForm>
  );
}
