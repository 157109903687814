import * as React from 'react';
import {LocalStorageItems} from '../../../services/const';
import {ListFilterManagerProps} from '../../common/Managers';
import {useStateReducer} from '../../../components/lib/libV2/hooks';

import {
  ProductListFilter,
  DEFAULT_PRODUCT_FILTER,
} from '../../../services/api/product';

export interface IProductListFilterManagerProps
  extends ListFilterManagerProps {}

export interface IProductListFilterManagerContext extends ProductListFilter {
  handleUpdateProductListFilter: (value: UpdateProductList) => void;
  handleApplyProductListFilters: () => void;
  handleResetProductListFilters: () => void;
  handleCancelChangeProductListFilter: () => void;
  loading: boolean;
  count: number;
}

export type UpdateProductList = Partial<
  ProductListFilter &
    Pick<IProductListFilterManagerContext, 'loading' | 'count'>
>;

export const ProductListFilterManagerContext =
  React.createContext<IProductListFilterManagerContext>({
    handleUpdateProductListFilter: (value: UpdateProductList) => {},
    handleApplyProductListFilters: () => {},
    handleResetProductListFilters: () => {},
    handleCancelChangeProductListFilter: () => {},
    loading: false,
    count: 0,
  });

export const useProductListFilter = () =>
  React.useContext<IProductListFilterManagerContext>(
    ProductListFilterManagerContext,
  );

export function ProductListFilterManager({
  children,
}: IProductListFilterManagerProps): React.JSX.Element {
  const {
    loading,
    count,

    handleUpdate,
  } = useStateReducer<UpdateProductList>({
    loading: true,
    count: 0,
  });

  const getCount = React.useCallback(
    (value: UpdateProductList) => {
      const count = Object.entries(value).reduce((acc, [_, value]) => {
        acc += Number(!!value);

        return acc;
      }, 0);

      handleUpdate({count});
    },
    [handleUpdate],
  );

  const handleUpdateProductListFilter = React.useCallback(
    (value: UpdateProductList) => {
      handleUpdate((prevState) => {
        return {...prevState, ...value};
      });
    },
    [handleUpdate],
  );

  const handleApplyProductListFilters = React.useCallback(() => {
    const productFilters = {
      debug: true,
    };

    const serializationProductFilter = JSON.stringify(productFilters);

    localStorage.setItem(
      LocalStorageItems.productListFilter,
      serializationProductFilter,
    );

    getCount({loading: false});
  }, [getCount]);

  const handleResetProductListFilters = React.useCallback(() => {
    handleUpdate(DEFAULT_PRODUCT_FILTER);

    const serializationProductFilter = JSON.stringify(DEFAULT_PRODUCT_FILTER);

    localStorage.setItem(
      LocalStorageItems.productListFilter,
      serializationProductFilter,
    );

    handleUpdate({count: 0});
  }, [handleUpdate]);

  const handleCancelChangeProductListFilter = React.useCallback(() => {
    const serializationProductFilter = JSON.parse(
      localStorage.getItem(LocalStorageItems.productListFilter) || '{}',
    );

    const filters: any = {
      ...DEFAULT_PRODUCT_FILTER,
      ...serializationProductFilter,
    };

    handleUpdate((prevState) => ({...prevState, ...filters}));
  }, [handleUpdate]);

  React.useEffect(() => {
    const serializationProductFilter: ProductListFilter = JSON.parse(
      localStorage.getItem(LocalStorageItems.productListFilter) || '{}',
    );

    const {count = 0, ...filters} = {
      ...DEFAULT_PRODUCT_FILTER,
      ...serializationProductFilter,
    };

    const state = {...filters, count, loading: false};

    handleUpdateProductListFilter(state);

    getCount(filters);
  }, [getCount, handleUpdateProductListFilter]);

  const value: IProductListFilterManagerContext = React.useMemo(
    (): any => ({
      loading,
      count,

      handleUpdateProductListFilter,
      handleApplyProductListFilters,
      handleResetProductListFilters,
      handleCancelChangeProductListFilter,
    }),
    [
      loading,
      count,

      handleUpdateProductListFilter,
      handleApplyProductListFilters,
      handleResetProductListFilters,
      handleCancelChangeProductListFilter,
    ],
  );

  return (
    <ProductListFilterManagerContext.Provider value={value}>
      {children}
    </ProductListFilterManagerContext.Provider>
  );
}
