import {Record, List} from 'immutable';
import {
  ILiteralObj,
  IsFunction,
  isObjectOrArray,
  FunctionArgs,
  Curried,
  GroupBy,
  DateValue,
} from '../types';
import {toMomentString, toMoment} from './date';
import {sub} from './math';

export function isEqual(first: any, second: any): boolean {
  return first === second;
}

export const allPass =
  (...fns: ((...args: any) => any)[]): ((value: any) => boolean) =>
  (value: any): boolean =>
    fns.every((fn) => !!fn(value));

/**
 * @desc Отримання значення в межах min та max
 * @param {Number} val
 * @param {Number} min
 * @param {Number} max
 * @return {Number}
 * */
export const withinLimits = (val: number, min: number, max: number): number =>
  val > max ? max : val < min ? min : val;

/**
 * @desc Перевірка функції
 * @param {Function} value
 * @return {boolean}
 * */
export const isFunction = <T extends any>(value: T): value is IsFunction<T> =>
  typeof value === 'function';

/**
 * @desc Перевірка масива/об'єкута. Чи містять хоч один елемент
 * @param {Object | Array} data
 * @return {boolean}
 * */
export const isThereContent = <T extends {} | [] | any>(
  data: T,
): data is isObjectOrArray<T> =>
  (data && Array.isArray(data) && data.length > 0) ||
  (data && Object.keys(data).length > 0);

export const pipe =
  (...fns: any) =>
  (input: any) =>
    fns.reduce((mem, fn) => fn(mem), input);

export function curry<T extends any[], R>(
  fn: (...args: T) => R,
): Curried<T, R> {
  const arity = fn.length;

  return function $curry(...args: any[]): any {
    if (args.length < arity) {
      return $curry.bind(null, ...args);
    }

    return fn.call(null, ...args);
  };
}

export const compose =
  <T>(...fns: ((...args: any) => any)[]): ((...args: any[]) => T) =>
  (...args): T =>
    fns.reduceRight((res, fn) => [fn.call(null, ...res)], args)[0];

export const map = curry<[FunctionArgs<any, any>, any[]], any[]>(
  <T, U>(fn: FunctionArgs<T, U>, xs: T[]): U[] => xs.map(fn),
);

export const filter = curry<[FunctionArgs<any, boolean>, any[]], any | any[]>(
  <T>(fn: FunctionArgs<T, boolean>, xs: T[]): T[] => xs.filter(fn),
);
export const find = curry<[FunctionArgs<any, boolean>, any[]], any>(
  <T>(f: FunctionArgs<T, boolean>, xs: T[]): T => xs.find(f),
);

export const findIndex = curry<[FunctionArgs<any, boolean>, any[]], number>(
  <T>(f: FunctionArgs<T, boolean>, xs: T[]): number => xs.findIndex(f),
);

export const getByIndex = curry<[any[], number], any>(
  <T>(xs: T[], index: number): T => xs[index],
);

export const increment = curry<[number, number], number>(
  (a: number, b: number): number => a + b,
);

export const forEach = curry<[FunctionArgs<any, any>, any[]], void>(
  <T, U>(fn: FunctionArgs<T, U>, xs: T[]): void => xs.forEach(fn),
);

export const reduce = curry<[(acc: any, curr: any) => any, any, any[]], any>(
  (fn, zero, xs) => xs.reduce(fn, zero),
);

export const reverse = reduce<
  [(acc: any, curr: any) => any, any[]],
  (value: any[]) => any[]
>((acc, x) => [x].concat(acc), []);

export const join = (m) => m.join();

export const trace = curry<[string, any], any>(<T>(tag: string, x: T): T => {
  console.log(tag, x);
  return x;
});

export const sortBy = curry<[Function, any[]], any[]>(
  <T>(fn: Function, xs: T[]): T[] =>
    xs.sort((a, b) => {
      if (fn(a) === fn(b)) {
        return 0;
      }

      return fn(a) > fn(b) ? 1 : -1;
    }),
);

export const some = curry<[FunctionArgs<any, boolean>, any[]], boolean>(
  <T>(f: FunctionArgs<T, boolean>, xs: T[]): boolean => xs.some(f),
);

// (p: string) => <T>(obj: T) => any
export const prop = curry<[string, any], any>(
  <T>(p: string, obj: T): any => obj?.[p],
);

export const eq = curry<[any, any], boolean>(
  (a: any, b: any): boolean => a === b,
);

export const add = curry<[number, number], number>(
  (a: number, b: number): number => a + b,
);

/**
 * @desc Об'єднання значень об'єктів
 * @param {Object} initial
 * @param {Object} newValue
 * @return {Object}
 * */
export const combiningObjects = (
  initial: ILiteralObj,
  newValue: ILiteralObj,
): ILiteralObj => {
  if (initial && newValue) {
    const updateValue = (acc, key) => {
      acc[key] =
        prop(key, newValue) === undefined
          ? prop(key, acc)
          : prop(key, newValue);

      return acc;
    };

    return reduce(updateValue, {...initial}, Object.keys(initial));
  }
  return initial;
};

/**
 * @desc Отримання строки заповнених вибраним значенням та обраною довжиною
 * @param {Number} num
 * @param {String} str
 * @return {Object}
 * */
export const defaultNumberSymbol = (num = 50, str = '*') =>
  [...Array(num)]
    .map((_, i) => i)
    .fill(str as any)
    .join('');

export const sortByMultipleFields = curry<[Function, Function, any], any[]>(
  <T>(ifFn: Function, elseFn: Function, xs: T[]): T[] =>
    xs.sort((a, b) => {
      if (ifFn(a) === ifFn(b)) {
        return elseFn(a) - elseFn(b);
      }

      return ifFn(a) > ifFn(b) ? 1 : -1;
    }),
);

export const toDate = (date: string | Date): Date => new Date(date);

export const buildFormData = (
  name: string,
  file: FormDataEntryValue,
): FormData => {
  const formData = new FormData();
  formData.append(name, file);
  return formData;
};

export const every = curry<[FunctionArgs<any, any>, any[]], boolean>(
  <T, U>(f: FunctionArgs<T, U>, xs: T[]): boolean => xs.every(f),
);

export const joinArr = curry<[string, any[]], string>(
  <T>(separator: string, xs: T[]): string => xs.join(separator),
);

export const split = curry<[string, string], string[]>(
  (separator: string, xs: string): string[] => xs.split(separator),
);

export const slice = curry<[number, number, any[]], any[]>(
  <T>(start: number, end: number, xs: T[]): T[] => xs.slice(start, end),
);

export const neq = curry<[any, any], boolean>((a, b) => a !== b);

export const not = <T>(value: T): boolean => !value;

export const isEmpty = <T>(value: T): boolean => !!value;

export const isFunctionOrValue = <T>(value: T | ((...args: any[]) => T)) =>
  isFunction(value) ? value() : value;

export const ifElse = curry<
  [
    boolean | ((...args: any[]) => boolean),
    any | ((...args: any[]) => any),
    any | ((...args: any[]) => any),
  ],
  any
>(
  <T, U>(
    condition: boolean | ((...args: any[]) => boolean),
    onTrue: T | ((...args: any[]) => T),
    onFalse: U | ((...args: any[]) => U),
  ): any =>
    isFunctionOrValue(condition)
      ? isFunctionOrValue(onTrue)
      : isFunctionOrValue(onFalse),
);

export const concat = curry<[any, any], any>((a, b) => a.concat(b));

export const reverseV2 = (x: any) =>
  Array.isArray(x) ? x.reverse() : x.split('').reverse().join('');

export const last = <T extends any>(xs: T[]): T => xs[xs.length - 1];
export const head = <T extends any>(xs: T[]): T => xs[0];

export const isObject = (value: any): boolean =>
  ({}.toString.apply(value) === '[object Object]');

export const toLowerCase = (value: string): string => value.toLocaleLowerCase();

export const divide = curry<[number, number], number>(
  (a: number, b: number): number => a / b,
);

export const flip = curry<[FunctionArgs<any, any>, any, any], any>((fn, a, b) =>
  fn(b, a),
);

export const append = flip(concat);

export const flatMap = curry<[FunctionArgs<any, any[]>, any[]], any[]>(
  <T, U>(f: FunctionArgs<T, U>, xs: T[]): any[] => xs.flatMap(f),
);

export const getLastSplittingString = (
  value: string,
  separator: string = '-',
): string => compose<string>(last, split(separator))(value);

export const lessThan = curry<[number, number], boolean>(
  (a: number, b: number): boolean => a < b,
);
export const lessOrEqualThan = curry<[number, number], boolean>(
  (a: number, b: number): boolean => a <= b,
);
export const greaterOrEqualThan = curry<[number, number], boolean>(
  (a: number, b: number): boolean => a >= b,
);
export const greaterThan = curry<[number, number], boolean>(
  (a: number, b: number): boolean => a > b,
);

export const memoizeUnaryArity: <T>(
  fn: (...args: any[]) => any,
  store?: Map<any, any>,
  trace?: string,
) => (arg: any) => T =
  <T>(fn: (...args: any[]) => any, store = new Map(), trace?: string) =>
  (arg: any): T => {
    const inCache = store.has(JSON.stringify(arg));

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    trace === 'memoizeFilterByUuid' &&
      console.log('memoizeUnaryArity', inCache, 'TRACE', trace, store);

    if (!inCache) {
      const value = fn(arg);
      const result =
        typeof value === 'function' ? memoizeUnaryArity(value, store) : value;

      store.set(JSON.stringify(arg), result);
    }

    return store.get(JSON.stringify(arg));
  };

export const debounce = <T extends any[]>(
  fn: any,
  delay = 200,
): ((...args: T) => any) => {
  let timer: NodeJS.Timeout;
  return function (...args) {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(async () => {
      // @ts-ignore
      await fn.apply(this, args);
    }, delay);
  };
};

export const splittingTimeToMoment = compose<any>(
  map((value: DateValue) => toMoment(value, 'HH:mm')),
  split('-'),
);

export const splittingTimeToMomentString = compose<String[]>(
  map((value: DateValue) => toMomentString(value, 'HH:mm')),
  split('-'),
);

export const timeRangeSplitting = (weekTimes: string): any[] => {
  const splitting = split<[string, string], string[]>('|', weekTimes);

  return [head(splitting), compose(splittingTimeToMoment, last)(splitting)];
};

export const inc = (a: number): number => add(a, 1);
export const decr = (a: number): number => sub(a, 1);

/**
 * @desc Отримання числа зі строки '30%'
 * @param {Number | String} percent
 * */
const getNumberOfStringPercent = (percent: string | number): number =>
  typeof percent === 'string' ? Number(percent.split('%')[0].trim()) : percent;

/**
 * @desc Отримання проценту від числа
 * @param {Number} number
 * @param {Number} percent
 * @return {Number}
 * */
export const getPercentage = curry<[number, string | number], number>(
  (number: number, percent: string | number): number =>
    Math.round((number / 100) * getNumberOfStringPercent(percent)),
);

export const isRecordToObject = <T extends {}>(value: Record<T> | T): any =>
  Record.isRecord(value) ? value.toObject() : value;

export const isListToArray = <T>(value: List<T> | T[]): any =>
  List.isList(value) ? value.toArray() : value;

export function len<T extends any[]>(array: T): number | undefined {
  if (Array.isArray(array)) {
    return array?.length;
  }
}

export const emulateApiCall = () =>
  new Promise((resolve) => {
    setTimeout(() => resolve(true), 1000);
  });

export const fromBooleanToNumber = (value: boolean | undefined): number =>
  value ? 1 : 0;

export const groupByProp = curry<[string, any, any], GroupBy<any>>(
  <T>(prop: string, acc: GroupBy<T>, curr: T): GroupBy<T> => {
    acc = acc[prop]
      ? {...acc, [(curr as any)[prop]]: [...acc[prop], curr]}
      : {...acc, [(curr as any)[prop]]: [curr]};

    return acc;
  },
);

export const uniqueItems = <T>(arr: T[]): T[] => [...new Set(arr)];

export type IsString<T> = T extends string ? T : never;

export const isString = <T>(value: T): value is IsString<T> =>
  typeof value === 'string';

export const correctPrice = (price?: string | number, digits = 4) => {
  let updatedPrice: any = parseFloat(
    parseFloat(`${price || 0}`).toFixed(digits),
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, afterDecimalPoint] = `${updatedPrice}`.split('.');

  if ((afterDecimalPoint || '')?.length < 2) {
    updatedPrice = updatedPrice.toFixed(2);
  } else {
    updatedPrice = `${updatedPrice}`;
  }

  return updatedPrice;
};

export const getFirstLatter = (value: string): string => value.charAt(0);

export const capitalize = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const listToArray = <T>(value: List<T> | T[]): T[] =>
  List.isList(value) ? value.toArray() : value;

export const isEqualByUuid = curry<any, any>(
  <T extends ILiteralObj>(uuid: string, targetUuid: T): boolean =>
    eq(targetUuid?.uuid, uuid),
);
