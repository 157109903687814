import React, {Suspense, useCallback, useState} from 'react';
import {Empty} from 'antd';
import {useTranslation} from 'react-i18next';
import {
  useDefaultPriceMargin,
  useKeyboardOpenForm,
  useSearchInputFocus,
  useStopLoading,
  useStoredCompanies,
  useStoredProductLeftoversList,
  useStoredStoreList,
} from '../../../../hooks';
import {
  textToUpperCase,
  head,
  correctPrice,
} from '../../../../services/helpers';
import {Table} from '../../../../components/lib/libV2/DataDisplay';
import {StyledDescription} from '../../../../components/lib/Styled';
import {StyledTitle} from '../../../../components/lib/Styled';
import {useBreadcrumb, useModal} from '../../../../components/lib/libV2/hooks';
import {
  PriceMarginModel,
  ProductLeftoversFormDTO,
  ProductMapper,
  ProductModel,
  ProductStatus,
  StoreDocumentMapper,
  StoreDocumentModel,
  StoreModel,
} from '../../../../struture';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '../../../../services/types';
import {useAcl, useDropdownAlert} from '../../../../contex';
import {
  HeaderLeft,
  ListHeader,
  SuspenseEmpty,
  Button,
  TableActionCell,
  TableIndexField,
} from '../../../../components/lib/DataDisplay';
import {useNavigate} from 'react-router';
import {ListLayout} from '../../../../components/lib/Layout';
import {v4 as uuidv4} from 'uuid';
import {
  ProductCategoryTreeView,
  ProductDetailsView,
  ProductStoreSearchView,
} from '../../../Products';
import styled, {useTheme} from 'styled-components';
import {ProductSearchSelect} from '../Show';
import {SessionStorageItems} from '../../../../services/const';
import {successButtonStyle} from '../../../../components/lib/Styled';
import Switch from 'antd/es/switch';
import {
  LeftoverChangeButton,
  LeftoverPreorderButton,
  LeftoverAddProductButton,
  LeftoverMinusProductButton,
} from '../Buttons';
import {StopOutlined} from '@ant-design/icons';
import {LeftoverPreorderManager, useLeftoverPreorder} from '../Managers';

import '../../../Orders/List/OrderList.less';
import '../../Forms/StoreProductListField.less';
import './LeftoverList.less';

const LeftoversModalLazy = React.lazy(() => import('../Show/LeftoversModal'));

export interface ILeftoversPageProps {}

const StyledCreateButton = styled(Button)`
  ${successButtonStyle}
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 5px;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTitlePriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const StyledSwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  gap: 5px;

  height: 32px;
`;

const StyledStopOutlined = styled(StopOutlined)`
  color: ${({theme}) => theme.colors.error};
`;

function LeftoverList({}: ILeftoversPageProps): JSX.Element {
  const navigate = useNavigate();
  const {defaultCompanyUuid, defaultCompany} = useStoredCompanies();
  const {manage} = useAcl(({store}) => store);
  const {t} = useTranslation();
  const theme: any = useTheme();
  const {alert} = useDropdownAlert();

  const {ref: listRef} = useKeyboardOpenForm({
    className: 'moving-create-event',
    disabled: !manage,
  });

  const [selectedProduct, setSelectedProduct] =
    useState<ProductLeftoversFormDTO | null>(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [disabledSelectCategory, setDisabledSelectCategory] = useState(false);

  const {
    handleAddProductPreorder,
    handleOpenLeftoverForm,
    handleDeletePreorderItem,
    document,
    isInitPreorder,
  } = useLeftoverPreorder();

  const storeUuid =
    sessionStorage.getItem(SessionStorageItems.productLeftoversStoreUuid) ||
    (document?.doc_store_uuid instanceof StoreModel
      ? document?.doc_store_uuid?.uuid
      : document?.doc_store_uuid) ||
    '';

  const categoryUuid =
    sessionStorage.getItem(SessionStorageItems.productLeftoversCategoryUuid) ||
    '';

  const showRunningOutProducts =
    sessionStorage.getItem(
      SessionStorageItems.productLeftoversshowRunningOutProducts,
    ) || 'false';

  const addedDocItemProductUuids = (document?.doc_items || []).map(
    ({product_uuid}) => product_uuid,
  );

  const {
    productList,
    limit,
    total,
    keywords,
    loadingMore,
    page,

    loading: loadingStoreDocumentList,
    error: errorStoreDocumentList,
    handleRefreshProductLeftovers,
    handleUpdateProductLeftover,
    handleSearchProductLeftovers,
  } = useStoredProductLeftoversList({
    companyUuid: defaultCompanyUuid,
    store_uuid: storeUuid,
    category_uuid: categoryUuid,
    show_running_out_products: JSON.parse(showRunningOutProducts),
  });

  const {defaultPriceMargin} = useDefaultPriceMargin({
    loadOnInit: true,
  });

  const {
    storeList,
    loading: storeListLoading,
    handleSearchStores,
  } = useStoredStoreList({
    companyUuid: defaultCompanyUuid,
    loadOnInit: false,
    limit: 100,
  });

  const loading = useStopLoading({
    loading: loadingStoreDocumentList || searchLoading || loadingMore,
    error: errorStoreDocumentList,
    message: 'An error occurred during leftovers loading',
  });

  const {inputRef, daleyFocus} = useSearchInputFocus({loading});

  const {handleCancel, handleOnInit, handleSuccess, visible} =
    useModal<ProductLeftoversFormDTO>({
      onCancel: daleyFocus,
      onSuccess: (value) =>
        handleUpdateProductLeftover(value, {storeUuid, categoryUuid}),
    });

  const routes = useBreadcrumb([
    {
      path: `/${Routes.stores}/${Routes.storeLeftovers}`,
      breadcrumbName: 'Leftovers',
    },
  ]);

  const getProductPriceMargin = useCallback(
    (product: ProductModel, priceMargin: PriceMarginModel | null) => {
      if (!priceMargin) {
        return correctPrice(product?.product_prices[0]?.price_value || 0);
      }

      const price = product?.product_prices?.find(
        ({price_uuid}) => price_uuid === priceMargin?.uuid,
      );

      return correctPrice(price?.price_value || 0);
    },
    [],
  );

  const handleChangeOutProducts = useCallback(
    async (show_running_out_products: boolean) => {
      sessionStorage.setItem(
        SessionStorageItems.productLeftoversshowRunningOutProducts,
        `${show_running_out_products}`,
      );

      await handleSearchProductLeftovers({
        keywords,
        show_running_out_products,
      });
    },
    [handleSearchProductLeftovers, keywords],
  );

  const isErrorField = useCallback((product: ProductModel) => {
    const balance = head(product?.product_balances || [])?.balance_total;
    const minAmount = product?.product_min_amount;

    return balance <= 0 || balance < minAmount;
  }, []);

  const columns = [
    {
      ellipsis: true,
      title: `№`,
      key: 'index',
      align: 'center' as any,
      className: 'order-list-td order-list--middle-text',
      render: (document: StoreDocumentModel, items: any, index: number) => (
        <TableIndexField number={(page - 1) * limit + index + 1} />
      ),
    },
    {
      ellipsis: true,
      title: t('Title'),
      key: 'title',
      render: (product: ProductModel) => (
        <StyledTitleContainer>
          <ProductDetailsView
            onlyTitle
            product={product}
            withTour
            price={getProductPriceMargin(product, defaultPriceMargin)}
          />
          <StyledTitlePriceContainer>
            <StyledDescription $color={theme.colors.success}>
              {`${
                defaultCompany?.currency_symbol || ''
              } ${getProductPriceMargin(product, defaultPriceMargin)}`}
            </StyledDescription>
            <StyledDescription>
              {`/ ${t('Store')}: "${
                head(product?.product_balances || [])?.company_store?.store_name
              }"`}
            </StyledDescription>
            {product?.category?.title ? (
              <StyledDescription>
                {`/ ${t('Category')}: "${product?.category?.title}"`}
              </StyledDescription>
            ) : null}
          </StyledTitlePriceContainer>
          <StyledTitlePriceContainer />
        </StyledTitleContainer>
      ),
    },
    {
      ellipsis: true,
      title: t('tAmount'),
      key: 'amount',
      align: 'right' as any,
      render: (product: ProductModel) => (
        <StyledTitle
          $color={isErrorField(product) ? theme.colors.error : undefined}>
          {head(product?.product_balances || [])?.balance_total}
        </StyledTitle>
      ),
    },
    {
      title: t('Actions'),
      key: 'action',
      align: 'center' as any,
      className: 'order-list-td',
      render: (product: ProductModel) =>
        product?.product_status === ProductStatus.ENABLE ? (
          <TableActionCell>
            {storeUuid ? (
              <>
                {addedDocItemProductUuids.includes(product?.uuid) ? (
                  <LeftoverMinusProductButton
                    onClick={() =>
                      handleDeletePreorderItem(
                        StoreDocumentMapper.toStoreDocumentItemFormDTO(
                          product,
                          {
                            editMode: false,
                          },
                        ),
                      )
                    }
                  />
                ) : (
                  <LeftoverAddProductButton
                    onClick={() => handleAddProductPreorder(product, storeUuid)}
                  />
                )}
              </>
            ) : null}

            <LeftoverChangeButton
              handleInitLeftover={() => onProductClick(product)}
            />
          </TableActionCell>
        ) : (
          <StyledStopOutlined />
        ),
    },
  ];

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.companies}/${defaultCompanyUuid}`);
  }, [defaultCompanyUuid, navigate]);

  const onStoreSelect = useCallback(
    async (store_uuid: string) => {
      setSearchLoading(true);
      sessionStorage.setItem(
        SessionStorageItems.productLeftoversStoreUuid,
        store_uuid,
      );

      await handleSearchProductLeftovers({
        keywords,
        category_uuid: categoryUuid || (null as any),
        store_uuid: store_uuid || (null as any),
      });

      const store = storeList?.find(({uuid}) => uuid === store_uuid);

      if (store) {
        setSelectedProduct((prevState) => {
          if (prevState) {
            return {
              ...prevState,
              with_added_store: false,
              store_balances: [
                {
                  store_uuid: store?.uuid,
                  balance: 0,
                  store_name: store?.store_name,
                },
              ],
            } as any;
          }

          return selectedProduct;
        });
      } else {
        setSelectedProduct((prevState) => {
          if (prevState) {
            return {
              ...prevState,
              with_added_store: true,
              store_balances: [
                {
                  store_uuid: undefined,
                  balance: 0,
                  store_name: '',
                },
              ],
            } as any;
          }

          return selectedProduct;
        });
      }

      setSearchLoading(false);
    },
    [
      categoryUuid,
      handleSearchProductLeftovers,
      keywords,
      selectedProduct,
      storeList,
    ],
  );

  const onCategorySelect = useCallback(
    async (category_uuid: string) => {
      setSearchLoading(true);
      sessionStorage.setItem(
        SessionStorageItems.productLeftoversCategoryUuid,
        category_uuid,
      );

      await handleSearchProductLeftovers({
        keywords,
        category_uuid: category_uuid || (null as any),
        store_uuid: storeUuid || (null as any),
      });
      setSearchLoading(false);
    },
    [handleSearchProductLeftovers, keywords, storeUuid],
  );

  const onProductSelect = useCallback(
    async (product: ProductModel) => {
      setSearchLoading(true);
      sessionStorage.setItem(
        SessionStorageItems.productLeftoversCategoryUuid,
        '',
      );

      setDisabledSelectCategory(!!product?.uuid);

      await handleSearchProductLeftovers({
        keywords,
        category_uuid: null as any,
        store_uuid: storeUuid || (null as any),
        product_uuid: product?.uuid || (null as any),
      });

      if (storeUuid) {
        const store = storeList?.find(({uuid}) => uuid === storeUuid);

        setSelectedProduct({
          product_title: product?.product_title,
          uuid: product?.uuid,
          store_balances: [
            {
              store_uuid: store?.uuid,
              balance: 0,
              store_name: store?.store_name,
            },
          ],
        } as any);
      } else {
        setSelectedProduct(
          ProductMapper.toProductLeftoversFormDTO(
            {
              uuid: product?.uuid,
              product_title: product?.product_title,
              product_balances: [
                {
                  balance_total: 0,
                  company_store: {store_uuid: undefined, store_name: ''},
                },
              ] as any,
            } as ProductModel,
            {withAddedStore: true},
          ),
        );
      }

      setSearchLoading(false);
    },
    [handleSearchProductLeftovers, keywords, storeList, storeUuid],
  );

  const onProductClick = useCallback(
    (product: ProductModel) => {
      if (product?.product_status !== ProductStatus.ENABLE) {
        alert('error', t('Product'), t('Operations with goods are blocked.'));
        return;
      }

      const leftovers = ProductMapper.toProductLeftoversFormDTO(product, {
        storeUuid,
      });

      setSelectedProduct(leftovers);

      handleOnInit();
    },
    [alert, handleOnInit, storeUuid, t],
  );

  return (
    <>
      <ListLayout
        ref={listRef}
        aclItem={RoutesAcl[Routes.stores]}
        headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
        headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.storeLeftovers]))}
        headerRight={
          isInitPreorder ? (
            <LeftoverPreorderButton onClick={handleOpenLeftoverForm} />
          ) : null
        }
        routes={routes}
        empty={
          productList?.size ? null : (
            <Empty description={t('There are no leftovers found')}>
              {selectedProduct ? (
                <StyledCreateButton onClick={handleOnInit}>
                  <StyledTitle $color={theme.colors.white}>
                    {t('Enter balances')}
                  </StyledTitle>
                </StyledCreateButton>
              ) : null}
            </Empty>
          )
        }
        headerExtra={
          <ListHeader>
            <Header>
              <ProductSearchSelect
                ref={inputRef}
                loading={loading}
                defaultPriceMargin={defaultPriceMargin}
                onProductSelect={onProductSelect}
                daleyFocus={daleyFocus}
                getProductPriceMargin={getProductPriceMargin}
                productListTotal={total}
                storeUuid={isInitPreorder ? storeUuid : ''}
              />
              <ProductStoreSearchView
                loading={loading}
                onStoreChange={onStoreSelect}
                storeUuid={storeUuid}
                storeList={storeList}
                storeListLoading={storeListLoading}
                handleSearchStores={handleSearchStores}
                disabled={isInitPreorder}
                tooltipTitle={
                  isInitPreorder
                    ? t(
                        'To restore warehouse selection, create or clear a pre-order',
                      )
                    : ''
                }
              />
              <ProductCategoryTreeView
                loading={loading}
                onCategorySelect={onCategorySelect}
                categoryUuid={categoryUuid}
                disabled={disabledSelectCategory}
              />
              <StyledSwitchContainer>
                <Switch
                  size="default"
                  onChange={handleChangeOutProducts}
                  checked={JSON.parse(showRunningOutProducts)}
                  loading={loading}
                  disabled={loading}
                />
                <StyledTitle>{t('Expiring product')}</StyledTitle>
              </StyledSwitchContainer>
            </Header>
          </ListHeader>
        }
        loading={loading && !productList?.size}>
        <Table<ProductModel>
          rowKey={() => uuidv4()}
          pageSize={limit}
          total={total}
          onChange={handleRefreshProductLeftovers}
          dataSource={productList}
          columns={columns}
          loading={loading}
          onRow={(record: ProductModel) => ({
            className: `posting-list-td ${
              isErrorField(record) ? 'leftover-list--error' : ''
            } ${
              addedDocItemProductUuids.includes(record?.uuid)
                ? 'leftover-list--added'
                : ''
            }`,
          })}
        />
      </ListLayout>
      <Suspense fallback={<SuspenseEmpty />}>
        <LeftoversModalLazy
          product={selectedProduct!}
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}

export function LeftoverListPage() {
  return (
    <LeftoverPreorderManager>
      <LeftoverList />
    </LeftoverPreorderManager>
  );
}
