import * as React from 'react';
import {getSettingsModuleById} from '../services/api/system';
import {
  SettingsModuleModel,
  SettingsModuleMapper,
  SettingsModuleDTO,
} from '../struture';
import {
  useCancellablePromise,
  useStateReducer,
} from '../components/lib/libV2/hooks';
import {IEntityProps} from '../services/types';
import {useDropdownAlert} from '../contex';
import {useTranslation} from 'react-i18next';

export interface IUseSettingsModuleProps extends IEntityProps {
  settingsModuleUuid: string;
  companyUuid: string;
}

export interface IUseSettingsModuleReturnType {
  error: null | any;
  loading: boolean;
  entity: SettingsModuleModel | null;
  refresh: (
    value: Partial<IUseSettingsModuleProps>,
  ) => Promise<SettingsModuleModel | void>;
  settingsModuleUuid: string;
  companyUuid: string;
}

export function useSettingsModule(
  {
    loadOnInit = true,
    settingsModuleUuid,
    companyUuid,
  }: IUseSettingsModuleProps = {} as IUseSettingsModuleProps,
): IUseSettingsModuleReturnType {
  const {cancellablePromise} = useCancellablePromise();
  const {alert} = useDropdownAlert();
  const {t} = useTranslation();

  const {
    entity,
    loading,
    error,
    handleUpdate,
    settingsModuleUuid: entityUuid,
    companyUuid: entityCompanyUuid,
  } = useStateReducer<Omit<IUseSettingsModuleReturnType, 'refresh'>>({
    entity: null,
    settingsModuleUuid,
    companyUuid,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      settingsModuleUuid = entityUuid,
      companyUuid = entityCompanyUuid,
    }: Partial<IUseSettingsModuleProps> = {}): Promise<SettingsModuleModel | void> => {
      try {
        handleUpdate({loading: showLoading, error: null, settingsModuleUuid});

        const settingsModuleDTO = await cancellablePromise<SettingsModuleDTO>(
          getSettingsModuleById(companyUuid, settingsModuleUuid),
        );

        const settingsModuleModel =
          SettingsModuleMapper.toSettingsModuleModel(settingsModuleDTO);

        handleUpdate({
          entity: settingsModuleModel,
          loading: false,
        });
        return settingsModuleModel;
      } catch (err: any) {
        alert(
          'error',
          t('Settings module'),
          `${t('An error occurred during get settings module')} : ${
            err?.message
          }`,
        );

        handleUpdate({
          error: err,
          loading: false,
        });
      }
    },

    [alert, cancellablePromise, entityCompanyUuid, entityUuid, handleUpdate, t],
  );

  React.useEffect(() => {
    if (loadOnInit && settingsModuleUuid && companyUuid) {
      (async () => {
        await refresh({settingsModuleUuid, companyUuid});
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, settingsModuleUuid, companyUuid]);

  return {
    error,
    loading,
    refresh,
    entity,
    settingsModuleUuid,
    companyUuid: entityCompanyUuid,
  };
}
